import React, { useCallback, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import {
  DashboardOutlined,
  LogoutOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { Layout, Menu, MenuProps } from 'antd';

import {
  staticBranding,
  staticLogoLarge,
  staticLogoSmall,
} from '@totem/selectors/preferencesSelectors';
import colors from '@totem/styles/colors';
import { BrandingPreference } from '@totem/types/organization';
import accountUtilities from '@totem/utilities/accountUtilities';

import './base.css';

const { Sider } = Layout;

const styles = {
  sider: {
    background: colors.neutral.white,
    borderRight: `2px solid ${colors.neutral.typhoon}`,
  },
};

const getLogoStyle = (
  collapsed: boolean,
  branding: BrandingPreference,
): React.CSSProperties => {
  if (collapsed) {
    return branding === BrandingPreference.TOTEM
      ? { height: '100%' }
      : { height: '55px' };
  }

  return branding === BrandingPreference.TOTEM ? { height: '100%' } : {};
};

const LeftNav = () => {
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState<boolean>(false);

  const handleLogout = useCallback(() => {
    accountUtilities.logout();
    navigate('/login');
  }, []);

  const hanleCollapse = useCallback(() => {
    setCollapsed(!collapsed);
  }, [collapsed]);

  const onMenuClicked = ({ key }) => {
    // eslint-disable-next-line default-case
    switch (key) {
      case 'logout':
        handleLogout();
        break;
    }
  };

  const getMenuItems = () => {
    const items: MenuProps['items'] = [
      {
        key: 'dashboard',
        title: 'Your Work',
        label: <NavLink to="/vendor-dashboard">Your Work</NavLink>,
        icon: <DashboardOutlined />,
      },
      {
        key: 'userProfile',
        title: 'User Profile',
        label: (
          <NavLink to="/vendor-dashboard/userProfile">User Profile</NavLink>
        ),
        icon: <SettingOutlined />,
      },
      {
        key: 'logout',
        title: 'Sign Out',
        label: <span>Sign Out</span>,
        icon: <LogoutOutlined />,
      },
    ];

    return items;
  };

  return (
    <Sider
      collapsible
      style={styles.sider}
      collapsed={collapsed}
      onCollapse={hanleCollapse}
    >
      <div
        styleName="logo"
        onClick={() => navigate('/vendor-dashboard')}
        style={
          !collapsed &&
          //brandingSelector(userProfile) ===
          staticBranding === BrandingPreference.INTELLIGENT_BUILDINGS
            ? { margin: '0 0 4rem 0' }
            : {}
        }
      >
        <img
          src={collapsed ? staticLogoSmall : staticLogoLarge}
          style={getLogoStyle(collapsed, staticBranding)}
        />
      </div>
      <Menu
        theme="light"
        mode="inline"
        onClick={onMenuClicked}
        items={getMenuItems()}
      />
    </Sider>
  );
};

export default LeftNav;
