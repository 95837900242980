import React, {useCallback, useEffect, useState} from 'react';
import { CloseSquareOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import { debounce } from '@totem/utilities/debounce';

import '../users.css';

const DEBOUNCE_TIME = 300;

export interface Props {
  onEmailSearchTermUpdated: (string) => void;
  onFirstNameSearchTermUpdated: (string) => void;
  onLastNameSearchTermUpdated: (string) => void;
}

const UserSearch = ({
  onEmailSearchTermUpdated,
  onFirstNameSearchTermUpdated,
  onLastNameSearchTermUpdated,
}: Props) => {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const userSearch = params.get('searchEmail');
  const [emailSearchTerm, setEmailSearchTerm] = useState<string>(
    typeof userSearch !== 'undefined' && userSearch !== null ? userSearch : '',
  );
  const [firstNameSearchTerm, setFirstNameSearchTerm] = useState<string>('');
  const [lastNameSearchTerm, setLastNameSearchTerm] = useState<string>('');

  const sendEmailSearch = useCallback(
    debounce(
      (searchTerm: string) => onEmailSearchTermUpdated(searchTerm),
      DEBOUNCE_TIME,
    ),
    [],
  );

  useEffect(() => {
    if (userSearch !== '') {
      sendEmailSearch(userSearch);
    }
  }, []);

  const handleEmailSearch = (searchTerm: string) => {
    setEmailSearchTerm(searchTerm);
    sendEmailSearch(searchTerm);
  };

  const sendFirstNameSearch = useCallback(
    debounce(
      (searchTerm: string) => onFirstNameSearchTermUpdated(searchTerm),
      DEBOUNCE_TIME,
    ),
    [],
  );

  const handleFirstNameSearch = (searchTerm: string) => {
    setFirstNameSearchTerm(searchTerm);
    sendFirstNameSearch(searchTerm);
  };

  const sendLastNameSearch = useCallback(
    debounce(
      (searchTerm: string) => onLastNameSearchTermUpdated(searchTerm),
      DEBOUNCE_TIME,
    ),
    [],
  );

  const handleLastNameSearch = (searchTerm: string) => {
    setLastNameSearchTerm(searchTerm);
    sendLastNameSearch(searchTerm);
  };

  return (
    <table style={{ width: '100%' }}>
      <tbody>
        <tr>
          <td>
            <b>Email:</b> <br />
            <Input
              placeholder="Email Search"
              value={emailSearchTerm}
              onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                evt.target.value !== null
                  ? handleEmailSearch(evt.target.value)
                  : handleEmailSearch('')
              }
            />
          </td>
          <td>
            <br />
            <CloseSquareOutlined onClick={() => handleEmailSearch('')} />
          </td>
          <td>
            <b>First Name:</b> <br />
            <Input
              width="100%"
              placeholder="First Name Search"
              value={firstNameSearchTerm}
              onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                evt.target.value !== null
                  ? handleFirstNameSearch(evt.target.value)
                  : handleFirstNameSearch('')
              }
            />
          </td>
          <td>
            <br />
            <CloseSquareOutlined onClick={() => handleFirstNameSearch('')} />
          </td>
          <td>
            <b>Last Name:</b> <br />
            <Input
              width="100%"
              placeholder="Last Name Search"
              value={lastNameSearchTerm}
              onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                evt.target.value !== null
                  ? handleLastNameSearch(evt.target.value)
                  : handleLastNameSearch('')
              }
            />
          </td>
          <td>
            <br />
            <CloseSquareOutlined onClick={() => handleLastNameSearch('')} />
          </td>
          <td>&nbsp;</td>
        </tr>
      </tbody>
    </table>
  );
};

export default UserSearch;
