import React from 'react';
import { Spin } from 'antd';

import { isNotNull } from '@totem/utilities/common';

const styles = {
  spinContainer: {
    padding: '50px',
    background: 'rgba(0, 0, 0, 0.05)',
    borderRadius: 4,
  },
};

type Props = {
  message?: string;
};

const Loading = ({ message }: Props) => {
  return (
    <Spin
      tip={isNotNull(message) && message !== '' ? message : 'Loading'}
      size="large"
    >
      <div style={styles.spinContainer} />
    </Spin>
  );
};

export default Loading;
