import React from 'react';

import BasePane from '@totem/components/BasePane';
import ContentLayout from '@totem/components/ContentLayout';
import PrimaryContentPane from '@totem/components/PrimaryContentPane';
import TenantAddButton from '@totem/components/tenants/tenantsPage/TenantAddButton';
import TenantsContainer from '@totem/components/tenants/tenantsPage/TenantsContainer';
import TenantsTable from '@totem/components/tenants/tenantsPage/TenantsTable';

const TenantsPage = () => {
  return (
    <TenantsContainer>
      <ContentLayout contentRight={<TenantAddButton />}>
        <BasePane>
          <PrimaryContentPane>
            <TenantsTable />
          </PrimaryContentPane>
        </BasePane>
      </ContentLayout>
    </TenantsContainer>
  );
};

export default TenantsPage;
