import * as React from 'react';
import { useEffect, useState } from 'react';
import { Form, Input, Select } from 'antd';
import * as R from 'ramda';

import { Address } from '@totem/components/buildingsCommon/types';
import LocationAutoComplete from '@totem/components/common/autoCompletes/LocationAutoComplete';
import { Address as AcAddress, AddressFormErrors } from '@totem/types/address';
import { COUNTRIES } from '@totem/utilities/addressUtilities';
const FormItem = Form.Item;
const { Option } = Select;

import './buildingCommon.css';

const styles = {
  form: {
    width: '100%',
  },
  formItem: {
    paddingBottom: '0',
    marginBottom: '1rem',
    width: '100%',
  },
};

type Props = {
  address: Address;
  disabled: boolean;
  onChange: (newAddress: Address) => void;
  errors: AddressFormErrors;
};
const AddressEntry = ({ address, disabled, onChange, errors }: Props) => {
  const [editAddress, setEditAddress] = useState<Address>(address);

  useEffect(() => {
    onChange(editAddress);
  }, [editAddress]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setEditAddress({ ...editAddress, [name]: value });
  };

  const handleStringChange = (name: string, value: string) => {
    setEditAddress({ ...editAddress, [name]: value });
  };

  const handleLocationSelect = (updAddress: AcAddress) => {
    setEditAddress({
      ...editAddress,
      addressOne: updAddress.addressOne,
      addressTwo: updAddress.addressTwo,
      city: updAddress.city,
      state: updAddress.state,
      country: updAddress.country,
      zipCode: updAddress.zipCode,
      location: updAddress.location,
    });
  };

  return (
    <Form layout="vertical" style={styles.form}>
      <FormItem label="Location Search" colon={false} style={styles.formItem}>
        <LocationAutoComplete
          onSelect={handleLocationSelect}
          disabled={disabled}
        />
      </FormItem>
      <FormItem
        label="Address"
        colon={false}
        validateStatus={R.isNil(errors.addressOne) ? 'success' : 'error'}
        help={R.isNil(errors.addressOne) ? null : errors.addressOne}
        style={styles.formItem}
      >
        <Input
          disabled={disabled}
          value={editAddress.addressOne}
          name="addressOne"
          onChange={handleInputChange}
          type="search"
        />
      </FormItem>
      <FormItem
        colon={false}
        validateStatus={R.isNil(errors.addressTwo) ? 'success' : 'error'}
        help={R.isNil(errors.addressTwo) ? null : errors.addressTwo}
        style={styles.formItem}
      >
        <Input
          disabled={disabled}
          value={editAddress.addressTwo}
          name="addressTwo"
          onChange={handleInputChange}
          type="search"
        />
      </FormItem>
      <div styleName="horizontal">
        <FormItem
          label="City"
          colon={false}
          validateStatus={R.isNil(errors.city) ? 'success' : 'error'}
          help={R.isNil(errors.city) ? null : errors.city}
          style={styles.formItem}
        >
          <Input
            disabled={disabled}
            value={editAddress.city}
            name="city"
            onChange={handleInputChange}
            type="search"
          />
        </FormItem>
        <FormItem
          label="State"
          colon={false}
          validateStatus={R.isNil(errors.state) ? 'success' : 'error'}
          help={R.isNil(errors.state) ? null : errors.state}
          style={styles.formItem}
        >
          <Input
            disabled={disabled}
            value={editAddress.state}
            name="state"
            onChange={handleInputChange}
            type="search"
          />
        </FormItem>
      </div>
      <FormItem
        label="Zip Code"
        colon={false}
        validateStatus={R.isNil(errors.zipCode) ? 'success' : 'error'}
        help={R.isNil(errors.zipCode) ? null : errors.zipCode}
        style={styles.formItem}
      >
        <Input
          disabled={disabled}
          value={editAddress.zipCode}
          name="zipCode"
          onChange={handleInputChange}
          type="search"
        />
      </FormItem>
      <FormItem
        label="Country"
        colon={false}
        validateStatus={R.isNil(errors.country) ? 'success' : 'error'}
        help={R.isNil(errors.country) ? null : errors.country}
        style={styles.formItem}
      >
        <Select
          showSearch
          filterOption
          onChange={(country: string) => handleStringChange('country', country)}
          value={editAddress.country}
          optionFilterProp="children"
          disabled={disabled}
        >
          {COUNTRIES.map((chkCountry) => {
            return (
              <Option key={chkCountry} value={chkCountry}>
                {chkCountry}
              </Option>
            );
          })}
        </Select>
      </FormItem>
    </Form>
  );
};

export default AddressEntry;
