import React, { useContext } from 'react';
import { EditOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { TablePaginationConfig } from 'antd/es/table';
import { ColumnProps } from 'antd/lib/table';
import { SorterResult } from 'antd/lib/table/interface';

import Table from '@totem/components/common/table/Table';
import Tooltip from '@totem/components/common/tooltip/Tooltip';
import { sortStringAscending } from '@totem/components/events/eventsContainer/utilities';
import BuildingMapperContext from '@totem/components/settings/sections/buildingMapping/BuildingMapperContext';
import { SensorQueryResultItem } from '@totem/components/settings/sections/buildingMapping/types';
import authUtilities from '@totem/utilities/authUtilities';

import '../../settings.css';

const styles = {
  iconButton: {
    margin: '0 2px',
  },
};

const BuildingMappingTable = () => {
  const { input, setInput, data, loading, onAction } = useContext(
    BuildingMapperContext,
  );
  const columns: ColumnProps<SensorQueryResultItem>[] = [
    {
      title: 'Site',
      dataIndex: 'siteName',
      key: 'siteName',
      sorter: (compA, compB) =>
        sortStringAscending(compA.siteName, compB.siteName),
    },
    {
      title: 'Host',
      dataIndex: 'host',
      key: 'host',
      render: (text: string, item: SensorQueryResultItem) => {
        return item.sensor.host;
      },
      sorter: (compA, compB) =>
        sortStringAscending(compA.sensor.host, compB.sensor.host),
    },
    {
      title: 'Software',
      dataIndex: 'software',
      key: 'software',
      render: (text: string, item: SensorQueryResultItem) => {
        return item.sensor.software;
      },
      sorter: (compA, compB) =>
        sortStringAscending(compA.sensor.software, compB.sensor.software),
    },
    {
      title: 'Region',
      dataIndex: 'region',
      key: 'region',
      render: (text: string, item: SensorQueryResultItem) => {
        if (typeof item.region !== 'undefined' && item.region !== null) {
          return item.region.name;
        }
        return '-';
      },
      sorter: (compA, compB) =>
        sortStringAscending(
          typeof compA.region !== 'undefined' && compA.region !== null
            ? compA.region.name
            : '-',
          typeof compB.region !== 'undefined' && compB.region !== null
            ? compB.region.name
            : '-',
        ),
    },
    {
      title: 'Building',
      dataIndex: 'building',
      key: 'building',
      render: (text: string, item: SensorQueryResultItem) => {
        if (typeof item.building !== 'undefined' && item.building !== null) {
          return item.building.name;
        }
        return '-';
      },
      sorter: (compA, compB) =>
        sortStringAscending(
          typeof compA.building !== 'undefined' && compA.building !== null
            ? compA.building.name
            : '-',
          typeof compB.building !== 'undefined' && compB.building !== null
            ? compB.building.name
            : '-',
        ),
    },
    {
      title: 'Actions',
      width: 30,
      dataIndex: 'additionalOptions',
      key: 'action',
      render: (_, item: SensorQueryResultItem) => (
        <div className="center-table-cell">
          {authUtilities.minimumRequiredRole(
            authUtilities.ROLE_SETS.SYSTEMS_ADMINISTRATOR,
          ) && (
            <Tooltip title="Update Building Mapping" placement="top">
              <Button
                icon={<EditOutlined />}
                shape="circle"
                onClick={(): void => onAction('showMappingDialog', item)}
                style={styles.iconButton}
              />
            </Tooltip>
          )}
        </div>
      ),
    },
  ];

  const handleTableChange = (
    updatedPagination: TablePaginationConfig,
    filters: SorterResult<SensorQueryResultItem>,
    sorter,
  ) => {
    const { ...params } = filters;

    let sortDir: string = sorter.order === 'descend' ? '-1' : '1';
    if (typeof sorter.order === 'undefined' || sorter.order === null) {
      if (
        typeof input.sensor_sortDirection !== 'undefined' &&
        input.sensor_sortDirection !== null
      ) {
        sortDir = input.sensor_sortDirection;
      }
    }

    let sortField = input.sensor_sortField
      ? input.sensor_sortField
      : 'startTime';
    if (
      typeof sorter.field !== 'undefined' &&
      typeof sorter.order !== 'undefined'
    ) {
      sortField = sorter.field;
    }

    // @ts-ignore
    setInput({
      ...input,
      ...params,
      sensor_pageSize: updatedPagination.pageSize,
      sensor_page: updatedPagination.current,
      sensor_sortField: sortField,
      sensor_sortDirection: sortDir,
    });
  };

  return (
    <Table
      showSorterTooltip
      columns={columns}
      dataSource={typeof data !== 'undefined' && data !== null ? data : []}
      loading={loading}
      onChange={handleTableChange}
      pagination={{
        current: input.sensor_page,
        pageSize: input.sensor_pageSize,
        total: typeof data !== 'undefined' && data !== null ? data.length : 0,
        showSizeChanger: true,
      }}
      rowKey="sensor.id"
    />
  );
};

export default BuildingMappingTable;
