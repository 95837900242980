import { Criticality } from './criticality';
import { LevelOfEffort } from './levelOfEffort';
import { User } from './user';

export enum FindingGroup {
  'N/A',
  'System Users',
  'System Configuration',
  'Network',
  'Continuity',
  'Control System Host',
}

export interface Finding {
  id: string;
  region?: {
    id: string;
    name: string;
  };
  building?: {
    id: string;
    name: string;
  };
  controlSystem?: {
    id: string;
    name: string;
    systemType: number;
  };
  assignee?: User;
  criticality: Criticality;
  group: FindingGroup;
  levelOfEffort: LevelOfEffort;
  description: string;
  additionalData: Array<{
    label: string;
    text: string;
  }>;
}

export interface FindingCriticalityCounts {
  critical: number;
  high: number;
  medium: number;
  low: number;
  compliant: number;
  info: number;
}

export interface FindingCompliantCounts {
  name: string;
  compliant: number;
  nonCompliant: number;
}

export interface FindingLeastCompliantCounts {
  region: FindingCompliantCounts[];
  building: FindingCompliantCounts[];
  assignee: FindingCompliantCounts[];
}

export interface FindingGroupCounts {
  systemUsers: FindingCriticalityCounts;
  systemConfiguration: FindingCriticalityCounts;
  network: FindingCriticalityCounts;
  continuity: FindingCriticalityCounts;
  controlSystemHost: FindingCriticalityCounts;
}

export interface FindingLevelOfEffortCounts {
  minimal: number;
  moderate: number;
  maximum: number;
}

export interface FindingsConnectionInput {
  limit: number;
  offset: number;
  sortBy?: 'region' | 'building' | 'controlSystem' | 'assignee' | string;
  sortOrder?: 'ascend' | 'descend' | string;
  regionId?: string[];
  buildingId?: string[];
  policyAuditId?: string[];
  controlSystemType?: number[];
  assignee?: string[];
  criticality?: number[];
  group?: number[];
  levelOfEffort?: number[];
  subjectType?: number[];
  subjectId?: string[];
}

export enum FindingAdditionalData {
  SourceQuestion = 'Source Question',
  Answer = 'Answer',
  AdditionalContext = 'Additional Context',
  Policy = 'Policy',
  Resolution = 'Resolution',
}

export interface FindingsConnection {
  totalCount: number;
  findings: Finding[];
  criticalityCounts?: FindingCriticalityCounts;
  leastCompliantCounts?: FindingLeastCompliantCounts;
  groupCounts?: FindingGroupCounts;
  levelOfEffortCounts?: FindingLevelOfEffortCounts;
}
