import { createContext } from 'react';

import {
  ControlSystemReport,
  FilterOptions,
} from '@totem/components/controlSystemReport/dataTypes';
import { ReportInput } from '@totem/components/controlSystemReport/parameterTypes';

interface Context {
  input: ReportInput;
  setInput: (input: ReportInput) => void;
  onAction?: (action: string) => void;
  loading: boolean;
  reportData: ControlSystemReport;
  filterOptions: FilterOptions;
  totalRecords: number;
}

export default createContext<Context>({
  input: {},
  setInput: () => {},
  onAction: () => {},
  loading: false,
  reportData: null,
  filterOptions: {
    regions: [],
    buildings: [],
    controlSystemNames: [],
  },
  totalRecords: 0,
});
