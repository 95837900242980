import React from 'react';

import { ContractContact } from '@totem/types/contracts';

import '../contracts.css';

type Props = {
  contact: ContractContact;
  loading: boolean;
};

const Contact = ({ contact }: Props) => {
  return (
    <>
      {contact.name}
      {contact.phone !== null &&
        contact.phone.map(phone => {
          return (
            <span key={`PH:${phone.type}${phone.number}`}>
              <br />
              <b>{phone.type}</b>: {phone.number}
            </span>
          );
        })}
      {contact.email !== null &&
        contact.email.map(email => {
          return (
            <span key={`EM:${email.type}${email.address}`}>
              <br />
              <b>{email.type}</b>: {email.address}
            </span>
          );
        })}
    </>
  );
};

export default Contact;
