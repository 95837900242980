import { createContext } from 'react';

import { FilterAutoCompleteProps } from '@totem/components/common/filterAutoComplete/FilterAutoCompleteTypes';
import { TicketingConnectionInput } from '@totem/types/ticketing';

interface Context {
  input: TicketingConnectionInput;
  setInput: (input: TicketingConnectionInput) => void;
  loading: boolean;
  buildingFilter: FilterAutoCompleteProps;
}

export default createContext<Context>({
  input: {},
  setInput: () => {},
  loading: false,
  buildingFilter: null,
});
