import React from 'react';
import { Col, Row } from 'antd';

import CveCard from '@totem/components/software/cves/cveCard';
import CveInformation from '@totem/components/software/cves/cveInformation';
import CveProductsAffected from '@totem/components/software/cves/cveProductsAffected';
import CveReferences from '@totem/components/software/cves/cveReferences';
import CveTitle from '@totem/components/software/cves/cveTitle';
import CveVersions from '@totem/components/software/cves/cveVersions';
import CvssTabs from '@totem/components/software/cves/cvssTabs';
import { CVERecord } from '@totem/components/software/cves/types';

type Props = {
  cve: CVERecord;
};

const CvePanel = ({ cve }: Props) => {
  return (
    <Row gutter={[20, 0]}>
      <Col span={18}>
        <CveCard>
          <CveTitle cve={cve} />
        </CveCard>

        <CveCard>
          <CvssTabs cve={cve} />
        </CveCard>

        <CveCard>
          <CveReferences cve={cve} />
        </CveCard>
      </Col>
      <Col span={6}>
        <CveCard>
          <CveInformation cve={cve} />
        </CveCard>

        <CveCard>
          <CveProductsAffected cve={cve} />
        </CveCard>

        <CveCard>
          <CveVersions cve={cve} />
        </CveCard>
      </Col>
    </Row>
  );
};

export default CvePanel;
