import { createContext } from 'react';

import { TenantReportRecord } from '@totem/components/settings/sections/auvikMapping/types';

interface Context {
  data: TenantReportRecord[];
  loading: boolean;
  onAction?: (action: string, tenantRecord: TenantReportRecord) => void;
  onActionComplete?: (refresh: boolean) => void;
}

export default createContext<Context>({
  data: [],
  loading: false,
  onAction: null,
  onActionComplete: null,
});
