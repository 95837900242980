import React, { ReactNode } from 'react';

import '../survey.css';

type Props = {
  children?: ReactNode;
};
const Title = ({ children }: Props) => {
  return <div styleName="policy-title">{children}</div>;
};

export default Title;
