import React from 'react';
import { Checkbox, Menu, Select } from 'antd';

const { Item: MenuItem } = Menu;

import './multiSelect.css';

export type MultiSelectOption = {
  label: React.ReactNode;
  value: string;
};

export type MultiSelectSize = 'small' | 'default' | 'large';

export type Props = {
  style?: React.CSSProperties;
  options: MultiSelectOption[];
  defaultSelected?: string[];
  onChange: (selected: string[]) => void;
  size?: MultiSelectSize;
};

type State = {
  selected: string[];
};

const styles = {
  select: {
    width: '100%',
  },
};

class MultiSelect extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      selected: props.defaultSelected || [],
    };
  }

  //select : Select ;

  getMenu = () => {
    const { selected } = this.state;
    const { options } = this.props;

    return (
      <div onMouseDown={event => event.preventDefault()}>
        <Menu
          multiple
          onClick={this.handleMenuItemClick}
          selectedKeys={selected}
          // use antd internal dropdown menu styles
          prefixCls="ant-dropdown-menu"
          className="ant-dropdown-menu-without-submenu"
        >
          {options.map(({ label, value }) => (
            <MenuItem key={value}>
              <div styleName="menu-item">
                <Checkbox checked={selected.includes(value.toString())} />
                {label}
              </div>
            </MenuItem>
          ))}
          <div styleName="buttons">
            <a onClick={this.handleOk}>OK</a>
            <a onClick={this.handleReset}>Reset</a>
          </div>
        </Menu>
      </div>
    );
  };

  handleMenuItemClick = ({ key }: any) => {
    const { selected } = this.state;

    this.setState({
      selected: selected.includes(key)
        ? selected.filter(option => option !== key)
        : [...selected, key],
    });
  };

  handleReset = () => {
    const { onChange } = this.props;
    this.setState({ selected: [] });
    onChange([]);
    //this.select.blur();
    //miSelect.bind('blur');
  };

  handleOk = () => {
    const { onChange } = this.props;
    const { selected } = this.state;
    onChange(selected);
    //this.select.blur();
    //miSelect.bind('blur')
  };

  handleDropdownVisibleChange = (open: boolean) => {
    if (!open) {
      this.handleOk();
    }
  };

  render() {
    const { selected } = this.state;
    const { style, size } = this.props;

    return (
      <Select
        className="multiselect"
        placeholder={`${selected.length} items...`}
        dropdownRender={this.getMenu}
        onDropdownVisibleChange={this.handleDropdownVisibleChange}
        // @ts-ignore
        size={size || null}
        style={{
          ...styles.select,
          ...style,
        }}
      />
    );
  }
}

export default MultiSelect;
