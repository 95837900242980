import React, { useCallback, useContext } from 'react';
import {
  DeleteOutlined,
  DownSquareOutlined,
  UpSquareOutlined,
} from '@ant-design/icons';
import { Button, Collapse, CollapseProps, Modal } from 'antd';

import TemplateContext from '@totem/components/surveyV2/templateDetails/SurveyTemplateContext';
import SurveyTemplateQuestion from '@totem/components/surveyV2/templateDetails/SurveyTemplateQuestion';
import {
  Category,
  SurveyQuestion,
  SurveyTemplate,
} from '@totem/components/surveyV2/types';
import { getToken } from '@totem/utilities/accountUtilities';
import { V2_SURVEY_ENDPOINT } from '@totem/utilities/endpoints';
import { sortNumberAscending } from '@totem/utilities/tableUtilities';

type Props = {
  category: Category;
};

const SurveyTemplateCategoryQuestions = ({ category }: Props) => {
  const { surveyTemplateId, setData, setIsLoading } =
    useContext(TemplateContext);

  const sendQuestionDelete = useCallback(
    async (templateId: string, categoryId: string, questionId: string) => {
      fetch(
        `${V2_SURVEY_ENDPOINT}/template/${templateId}/categories/${categoryId}/questions/${questionId}`,
        {
          method: 'DELETE',
          headers: new Headers({
            Authorization: `Bearer ${getToken()}`,
          }),
        },
      )
        .then((res) => res.json())
        .then((result: SurveyTemplate) => {
          setData(result);
        })
        .then(() => {
          setIsLoading(false);
        });
    },
    [],
  );
  const handleDeleteQuestion = (item: SurveyQuestion) => {
    Modal.confirm({
      title: 'Are you sure?',
      content: `Please confirm that you would like to delete the question "${item.name}".`,
      okText: 'Delete',
      okType: 'danger',
      cancelText: 'Cancel',
      open: false,
      onOk: () => sendQuestionDelete(surveyTemplateId, category.id, item.id),
    });
  };

  const sendQuestionReorder = useCallback(
    async (
      templateId: string,
      categoryId: string,
      currentQuestion: SurveyQuestion,
      swapQuestion: SurveyQuestion,
    ) => {
      const payload = {
        templateId,
        categoryId,
        updates: [
          {
            questionId: currentQuestion.id,
            newIndex: swapQuestion.index,
          },
          {
            questionId: swapQuestion.id,
            newIndex: currentQuestion.index,
          },
        ],
      };

      fetch(`${V2_SURVEY_ENDPOINT}/templates/reorder/questions`, {
        method: 'POST',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
        body: JSON.stringify(payload),
      })
        .then((res) => res.json())
        .then((result: SurveyTemplate) => {
          setData(result);
        })
        .then(() => {
          setIsLoading(false);
        });
    },
    [],
  );

  const handleQuestionReorder = (
    current: SurveyQuestion,
    swap: SurveyQuestion,
  ) => {
    if (swap !== null) {
      sendQuestionReorder(surveyTemplateId, category.id, current, swap);
    }
  };

  const getQuestionItems = () => {
    const items: CollapseProps['items'] = [];

    if (
      typeof category !== 'undefined' &&
      category !== null &&
      typeof category.questions !== 'undefined' &&
      category.questions !== null
    ) {
      const sortedQuestions = category.questions.sort((compA, compB) =>
        sortNumberAscending(compA.index, compB.index),
      );
      return sortedQuestions.map((question, questionIndex) => {
        return {
          key: question.id,
          label: question.label,
          children: (
            <SurveyTemplateQuestion
              category={category}
              question={question}
              previous={
                questionIndex > 0 ? sortedQuestions[questionIndex - 1] : null
              }
              next={
                questionIndex !== sortedQuestions.length - 1
                  ? sortedQuestions[questionIndex + 1]
                  : null
              }
            />
          ),
          extra: (
            <div>
              <Button
                title="Move Up"
                icon={<UpSquareOutlined />}
                onClick={(event) => {
                  event.stopPropagation();
                  handleQuestionReorder(
                    question,
                    questionIndex > 0
                      ? sortedQuestions[questionIndex - 1]
                      : null,
                  );
                }}
                disabled={questionIndex === 0}
              />
              <Button
                title="Move Down"
                icon={<DownSquareOutlined />}
                onClick={(event) => {
                  event.stopPropagation();
                  handleQuestionReorder(
                    question,
                    questionIndex !== sortedQuestions.length - 1
                      ? sortedQuestions[questionIndex + 1]
                      : null,
                  );
                }}
                disabled={questionIndex === sortedQuestions.length - 1}
              />
              <Button
                title="Delete Question"
                icon={<DeleteOutlined />}
                onClick={(event) => {
                  event.stopPropagation();
                  handleDeleteQuestion(question);
                }}
              />
            </div>
          ),
        };
      });
    }

    return items;
  };

  return <Collapse items={getQuestionItems()} />;
};

export default SurveyTemplateCategoryQuestions;
