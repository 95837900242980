import React, { useContext } from 'react';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Tag } from 'antd';
import { TablePaginationConfig } from 'antd/es/table';
import { ColumnProps } from 'antd/lib/table';
import { SorterResult } from 'antd/lib/table/interface';

import Table from '@totem/components/common/table/Table';
import Tooltip from '@totem/components/common/tooltip/Tooltip';
import { sortStringAscending } from '@totem/components/events/eventsContainer/utilities';
import OrganizationContactsContext from '@totem/components/settings/sections/contacts/contactsContext';
import { PointOfContact } from '@totem/components/settings/sections/contacts/types';
import authUtilities from '@totem/utilities/authUtilities';

import './contacts.css';

const ContactsTable = () => {
  const {
    filteredData,
    loading,
    input,
    setInput,
    totalRecords,
    onAction,
  } = useContext(OrganizationContactsContext);

  const isSysAdmin = authUtilities.minimumRequiredRole(
    authUtilities.ROLE_SETS.SYSTEMS_ADMINISTRATOR,
  );

  const columns: ColumnProps<PointOfContact>[] = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (compA, compB) => sortStringAscending(compA.email, compB.email),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      sorter: (compA, compB) => sortStringAscending(compA.email, compB.email),
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
      sorter: (compA, compB) => sortStringAscending(compA.email, compB.email),
    },
    {
      title: 'Labels',
      dataIndex: 'labels',
      key: 'labels',
      render: (_, contact: PointOfContact) =>
        contact.labels.map(label => <Tag key={label}>{label}</Tag>),
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (_, contact: PointOfContact) => (
        <div className="center-table-cell">
          {isSysAdmin && (
            <Tooltip title="Edit" placement="top">
              <Button
                styleName="edit-button"
                disabled={false}
                shape="circle"
                icon={<EditOutlined />}
                onClick={() => onAction('EditContact', contact)}
              />
            </Tooltip>
          )}
          {isSysAdmin && (
            <Tooltip title="Delete" placement="top">
              <Button
                danger
                disabled={false}
                type="default"
                shape="circle"
                icon={<DeleteOutlined />}
                onClick={() => onAction('RemoveContact', contact)}
              />
            </Tooltip>
          )}
        </div>
      ),
    },
  ];

  const handleTableChange = (
    updatedPagination: TablePaginationConfig,
    filters: SorterResult<PointOfContact>,
    sorter,
  ) => {
    const { ...params } = filters;

    let sortDir: string = sorter.order === 'descend' ? '-1' : '1';
    if (typeof sorter.order === 'undefined' || sorter.order === null) {
      if (
        typeof input.sortDirection !== 'undefined' &&
        input.sortDirection !== null
      ) {
        sortDir = input.sortDirection;
      }
    }

    let sortField = input.sortField ? input.sortField : 'lastOccurrence';
    if (
      typeof sorter.field !== 'undefined' &&
      typeof sorter.order !== 'undefined'
    ) {
      sortField = sorter.field;
    }

    // @ts-ignore
    setInput({
      ...input,
      ...params,
      pageSize: updatedPagination.pageSize,
      page: updatedPagination.current,
      sortField,
      sortDirection: sortDir,
    });
  };

  return (
    <Table
      showSorterTooltip
      columns={columns}
      dataSource={filteredData}
      loading={loading}
      onChange={handleTableChange}
      pagination={{
        current: input.page,
        pageSize: input.pageSize,
        total: totalRecords,
        showSizeChanger: true,
      }}
      rowKey="id"
    />
  );
};

export default ContactsTable;
