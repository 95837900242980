import React, { ReactNode, useEffect, useState } from 'react';

import TenantsContext from '@totem/components/tenants/tenantsPage/TenantsContext';
import {
  TenantsFilter,
  TenantsInput,
} from '@totem/components/tenants/tenantsPage/types';
import {
  addTenantsFilters,
  buildTenantsFilters,
} from '@totem/components/tenants/tenantsPage/utilities';
import { Tenant, Tenants } from '@totem/components/tenants/types';
import { Params } from '@totem/types/common';
import { getToken } from '@totem/utilities/accountUtilities';
import { TENANTS_ENDPOINT } from '@totem/utilities/endpoints';
import { omitNilOrEmpty } from '@totem/utilities/objectUtilities';
type Props = {
  staticFilters?: TenantsFilter;
  children?: ReactNode;
};

const TenantsContainer = ({ children, staticFilters }: Props) => {
  const [refreshData, setRefreshData] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [data, setData] = useState<Tenants>();
  const [action, setAction] = useState<string>('');
  const [actionItem, setActionItem] = useState<Tenant>(null);
  const [input, updateInput] = useState<TenantsInput>({
    from: '',
    pageSize: 10,
    page: 1,
    sortField: 'name',
    sortDirection: '1',
  });

  const setInput = (updated: Partial<TenantsInput>) => {
    updateInput(omitNilOrEmpty({ ...input, ...updated }));
    setRefreshData(true);
  };

  const buildParameters = () => {
    const params: Params = {
      paging: {
        page: input.page,
        pageSize: input.pageSize,
      },
      sort: {
        field: input.sortField,
        direction: +input.sortDirection,
      },
      filters: buildTenantsFilters(input),
    };

    params.filters = addTenantsFilters(params.filters, staticFilters);

    return params;
  };

  useEffect(() => {
    if (refreshData) {
      setRefreshData(false);
      setIsLoading(true);

      const params: Params = buildParameters();

      const url = TENANTS_ENDPOINT;

      fetch(`${url}`, {
        method: 'POST',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
        body: JSON.stringify(params),
      })
        .then((res) => res.json())
        .then((result: Tenants) => {
          setData(result);
        })
        .then(() => {
          setIsLoading(false);
        });
    }
  }, [staticFilters, refreshData]);

  const handleAction = (newAction: string, item: Tenant) => {
    switch (newAction) {
      case 'refresh':
        setRefreshData(true);
        setAction('');
        setActionItem(null);
        break;
      default:
        setAction(newAction);
        setActionItem(item);
        break;
    }
  };

  return (
    <TenantsContext.Provider
      value={{
        input,
        setInput,
        loading: isLoading,
        data,
        action,
        actionItem,
        onAction: handleAction,
      }}
    >
      {children}
    </TenantsContext.Provider>
  );
};

export default TenantsContainer;
