import React, { useEffect, useState } from 'react';
import {
  ArrayParam,
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params';

import CardBody from '@totem/components/CardBody';
import useTableAutoCompleteFilter from '@totem/components/common/filterAutoComplete/TableFilter';
import ComponentHeader from '@totem/components/ComponentHeader';
import ContentLayout from '@totem/components/ContentLayout';
import DeviceBackupContext from '@totem/components/deviceBackups/deviceBackupContext';
import DeviceBackupFilters from '@totem/components/deviceBackups/DeviceBackupFilters';
import DeviceBackupTable from '@totem/components/deviceBackups/deviceBackupTable';
import SummaryContainer from '@totem/components/deviceBackups/SummaryContainer';
import { BackupReportResponse } from '@totem/components/deviceBackups/types';
import { buildParameters } from '@totem/components/deviceBackups/utilities';
import PrimaryContentPane from '@totem/components/PrimaryContentPane';
import RecordCountBadge from '@totem/components/RecordCountBadge';
import { Params } from '@totem/types/common';
import {
  DeviceBackupConnectionInput,
  DeviceLastBackupFilterOptions,
} from '@totem/types/devices';
import { getToken } from '@totem/utilities/accountUtilities';
import { DEVICES_ENDPOINT } from '@totem/utilities/endpoints';
import { omitNilOrEmpty } from '@totem/utilities/objectUtilities';

const DeviceBackups = () => {
  const [input, updateInput] = useQueryParams({
    from: withDefault(StringParam, ''),
    pageSize: withDefault(NumberParam, 10),
    page: withDefault(NumberParam, 1),
    sortField: withDefault(StringParam, 'device.displayName'),
    sortDirection: withDefault(StringParam, '1'),
    buildingId: withDefault(ArrayParam, []),
    regionId: withDefault(ArrayParam, []),
    controlSystemId: withDefault(ArrayParam, []),
    deviceId: withDefault(ArrayParam, []),
    systemType: withDefault(ArrayParam, []),
    deviceName: withDefault(StringParam, ''),
    controlSystemName: withDefault(StringParam, ''),
    backupStatus: withDefault(ArrayParam, []),
    isBackupStale: withDefault(ArrayParam, []),
    lastBackupStatus: withDefault(ArrayParam, []),
  });
  const [refreshData, setRefreshData] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isFilterOptionsLoaded, setIsFilterOptionsLoaded] =
    useState<boolean>(false);
  const [isSending, setIsSending] = useState(false);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [backupData, setBackupData] = useState<BackupReportResponse>(null);
  const [filterOptions, setFilterOptions] =
    useState<DeviceLastBackupFilterOptions>({
      region: [],
      building: [],
      controlSystemType: [],
      controlSystem: [],
      device: [],
      backupStatus: [],
    });

  const setInput = (updated: Partial<DeviceBackupConnectionInput>) => {
    updateInput(omitNilOrEmpty({ ...input, ...updated }), 'replace');
    setRefreshData(true);
  };

  const regionFilter = useTableAutoCompleteFilter(
    input.regionId,
    filterOptions.region,
    {
      onChange: (regionId) => {
        setInput({ ...input, regionId });
      },
    },
  );

  const buildingFilter = useTableAutoCompleteFilter(
    input.buildingId,
    filterOptions.building,
    {
      onChange: (buildingId) => {
        setInput({ ...input, buildingId });
      },
    },
  );

  const deviceFilter = useTableAutoCompleteFilter(
    input.deviceId,
    filterOptions.device,
    {
      onChange: (deviceId) => {
        setInput({ ...input, deviceId });
      },
    },
  );

  useEffect(() => {
    if (!isFilterOptionsLoaded) {
      fetch(`${DEVICES_ENDPOINT}/lastBackupReportFilterOptions`, {
        method: 'GET',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
      })
        .then((res) => res.json())
        .then((result: DeviceLastBackupFilterOptions) => {
          setFilterOptions(result);
        })
        .then(() => {
          setIsFilterOptionsLoaded(true);
        });
    }
  }, [isFilterOptionsLoaded]);

  useEffect(() => {
    if (refreshData) {
      if (isSending) {
        return;
      }
      setIsLoading(true);
      setIsSending(true);
      const params: Params = buildParameters(input);

      fetch(`${DEVICES_ENDPOINT}/backupReport`, {
        method: 'POST',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
        body: JSON.stringify(params),
      })
        .then((res) => res.json())
        .then((result: BackupReportResponse) => {
          setBackupData(result);
          setTotalRecords(result.paging.totalRecords);
        })
        .then(() => {
          setIsLoading(false);
          setIsSending(false);
        });

      setRefreshData(false);
    }
  }, [input, refreshData]);

  return (
    <ContentLayout>
      <DeviceBackupContext.Provider
        value={{
          input,
          setInput,
          loading: isLoading,
          buildingFilter,
          regionFilter,
          deviceFilter,
        }}
      >
        <PrimaryContentPane>
          <SummaryContainer />
          <DeviceBackupFilters />
          <ComponentHeader
            options={<RecordCountBadge count={totalRecords} />}
          />
          <CardBody>
            <DeviceBackupTable
              page={input.page}
              pageSize={input.pageSize}
              total={totalRecords}
              backupResults={backupData}
              filterOptions={filterOptions}
              loading={isLoading}
            />
          </CardBody>
        </PrimaryContentPane>
      </DeviceBackupContext.Provider>
    </ContentLayout>
  );
};

export default DeviceBackups;
