import React, { useContext, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import * as R from 'ramda';
import {
  ArrayParam,
  BooleanParam,
  DelimitedNumericArrayParam,
  NumberParam,
  useQueryParams,
  withDefault,
} from 'use-query-params';

import BasePane from '@totem/components/BasePane';
import PrimaryContentPane from '@totem/components/PrimaryContentPane';
import { CREATE_HEALTH } from '@totem/graph/health';
import {
  GET_POLICY_AUDIT_ASSIGNEES,
  GET_POLICY_AUDITS,
  UPDATE_POLICY_AUDIT,
} from '@totem/graph/policyAudit';
import { useBuildingFilter } from '@totem/hooks/useBuildingFilter';
import { useConnection } from '@totem/hooks/useConnection';
import { useRegionFilter } from '@totem/hooks/useRegionFilter';
import { auditAssigneesSelector } from '@totem/selectors/auditAssigneesSelectors';
import { HealthInput } from '@totem/types/health';
import {
  PolicyAudit,
  PolicyAuditsConnection,
  PolicyAuditsConnectionInput,
  PolicyAuditUpdateInput,
} from '@totem/types/policyAudit';
import { omitNilOrEmpty } from '@totem/utilities/objectUtilities';
import { isIBUser } from '@totem/utilities/security';

import { ContactAssigneeBanner } from '../common/contactAssigneeBanner';
import ContentLayout from '../ContentLayout';
import UserProfileContext from '../UserProfileContext';

import PolicyAuditFilterChips from './PolicyAuditFilterChips';
import PolicyAuditFilters from './PolicyAuditFilters';
import PolicyAuditsContext from './PolicyAuditsContext';
import PolicyAuditsExport from './PolicyAuditsExport';
import PolicyAuditsOverview from './PolicyAuditsOverview';
import PolicyAuditTable from './PolicyAuditTable';

import './policyAudits.css';

const PolicyAudits = () => {
  const [input, setInput] = useQueryParams({
    limit: withDefault(NumberParam, 50),
    offset: withDefault(NumberParam, 0),
    health: DelimitedNumericArrayParam,
    completionStatus: DelimitedNumericArrayParam,
    isLaunched: BooleanParam,
    startDate: BooleanParam,
    dueDate: BooleanParam,
    buildingId: ArrayParam,
    regionId: ArrayParam,
    controlSystemType: DelimitedNumericArrayParam,
  });

  const { userProfile } = useContext(UserProfileContext);
  const { policyAuditBcc } =
    userProfile?.organization?.preferences?.features || {};
  const isIB = isIBUser(userProfile);
  const [selectedAudits, setSelectedAudits] = useState<string[]>([]);
  const [showSelectOptions, setShowSelectOptions] = useState<boolean>(isIB);

  const buildingFilter = useBuildingFilter(input.buildingId, {
    onChange: (buildingId) => setInput({ ...input, buildingId }, 'replace'),
  });

  const regionFilter = useRegionFilter(input.regionId, {
    onChange: (regionId) => setInput({ ...input, regionId }, 'replace'),
  });

  const { data, loading, error, refetch } = useQuery(GET_POLICY_AUDITS, {
    variables: { input },
  });

  const { totalCount, policyAudits } = useConnection<PolicyAuditsConnection>({
    data,
    accessor: R.path(['policyAudits']),
    initialData: { totalCount: 0, policyAudits: [] },
  });

  const {
    data: assigneesData,
    loading: assigneesLoading,
    error: assigneesError,
    refetch: refetchAssignees,
  } = useQuery(GET_POLICY_AUDIT_ASSIGNEES, {
    fetchPolicy: 'no-cache',
    variables: { input: { ...R.omit(['limit', 'offset'], input) } },
  });

  const assigneeAudits = useConnection<PolicyAudit[]>({
    data: assigneesData,
    accessor: R.path(['policyAudits', 'policyAudits']),
    initialData: [],
  });

  const onCompleted = () => {
    refetch({ input });
    refetchAssignees({ input: { ...R.omit(['limit', 'offset'], input) } });
  };

  const [addHealth, { loading: addHealthLoading }] = useMutation(
    CREATE_HEALTH,
    {
      notifyOnNetworkStatusChange: true,
      onCompleted,
    },
  );

  const [updatePolicyAudit, { loading: updatePolicyAuditLoading }] =
    useMutation(UPDATE_POLICY_AUDIT, { onCompleted });

  const handleAddHealth = (health: HealthInput) => {
    addHealth({ variables: { health } });
  };

  const handlePolicyAuditUpdate = (policyAudit: PolicyAuditUpdateInput) => {
    updatePolicyAudit({ variables: { policyAudit } });
  };

  const handleFiltersChange = (
    updatedInput: Partial<PolicyAuditsConnectionInput>,
  ) => {
    setInput(omitNilOrEmpty({ ...input, ...updatedInput }), 'replace');
  };

  return (
    <ContentLayout>
      <PolicyAuditsContext.Provider
        value={{
          input,
          setInput,
          policyAudits,
          totalCount,
          buildingFilter,
          regionFilter,
          loading: loading || Boolean(error),
          showSelectOptions,
          selectedAudits,
          setShowSelectOptions,
          setSelectedAudits,
        }}
      >
        <BasePane>
          {!R.isEmpty(assigneeAudits) && policyAuditBcc && (
            <ContactAssigneeBanner
              assignees={auditAssigneesSelector(assigneeAudits)}
              loading={assigneesLoading || Boolean(assigneesError)}
            />
          )}
          <PrimaryContentPane>
            <PolicyAuditFilters onChange={handleFiltersChange} />
            <div styleName="title">Overview</div>
            <PolicyAuditsOverview input={input} />
            <div styleName="title middle">Audits</div>
            <div styleName="table-container">
              <div styleName="table-chips-container">
                <PolicyAuditFilterChips
                  input={input}
                  onChange={handleFiltersChange}
                />
              </div>
              <div styleName="export-button-container">
                <PolicyAuditsExport input={input} />
              </div>
              <PolicyAuditTable
                onChange={handleFiltersChange}
                onAddHealth={handleAddHealth}
                addHealthLoading={addHealthLoading}
                onPolicyAuditUpdate={handlePolicyAuditUpdate}
                updatePolicyAuditLoading={updatePolicyAuditLoading}
              />
            </div>
          </PrimaryContentPane>
        </BasePane>
      </PolicyAuditsContext.Provider>
    </ContentLayout>
  );
};

export default PolicyAudits;
