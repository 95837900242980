import React, { useContext } from 'react';

import DropdownAutoCompleteFilter from '@totem/components/common/autoCompleteFilter/DropdownAutoCompleteFilter';
import DropdownFilter from '@totem/components/common/dropdownFilter/DropdownFilter';
import FiltersChipsContainer from '@totem/components/FilterChipsContainer';
import FiltersBody from '@totem/components/FiltersBody';
import FiltersContainer from '@totem/components/FiltersContainer';
import FiltersTitle from '@totem/components/FiltersTitle';
import { Criticality } from '@totem/types/criticality';
import { toDropdownFilter } from '@totem/utilities/enumUtilities';

import PolicyAuditFindingDetailContext from './PolicyAuditFindingDetailContext';
import PolicyAuditFindingDetailFilterChips from './PolicyAuditFindingDetailFilterChips';

const styles = {
  dropdown: {
    marginRight: '2rem',
  },
};

const PolicyAuditFindingDetailFilters = () => {
  const { input, setInput, buildingFilter, regionFilter, assigneeFilter } =
    useContext(PolicyAuditFindingDetailContext);

  const { criticality } = input;

  const handleChange = (property: string, update: string[]) => {
    setInput({
      ...input,
      [property]: update.length ? update.map(Number) : null,
    });
  };

  return (
    <FiltersContainer>
      <FiltersTitle>Findings Report</FiltersTitle>
      <FiltersBody>
        <DropdownFilter
          label="Criticality"
          value={criticality || []}
          options={toDropdownFilter(Criticality)}
          onChange={(update) => handleChange('criticality', update)}
          style={styles.dropdown}
        />
        <DropdownAutoCompleteFilter
          style={styles.dropdown}
          label="Building"
          {...buildingFilter}
        />
        <DropdownAutoCompleteFilter
          style={styles.dropdown}
          label="Region"
          {...regionFilter}
        />
        <DropdownAutoCompleteFilter
          style={styles.dropdown}
          label="Assignee"
          {...assigneeFilter}
        />
      </FiltersBody>
      <FiltersChipsContainer>
        <PolicyAuditFindingDetailFilterChips />
      </FiltersChipsContainer>
    </FiltersContainer>
  );
};

export default PolicyAuditFindingDetailFilters;
