import { createContext } from 'react';

import { FilterAutoCompleteProps } from '@totem/components/common/filterAutoComplete/FilterAutoCompleteTypes';
import { DeviceBackupConnectionInput } from '@totem/types/devices';

interface Context {
  input: DeviceBackupConnectionInput;
  setInput: (input: DeviceBackupConnectionInput) => void;
  loading: boolean;
  buildingFilter: FilterAutoCompleteProps;
  regionFilter: FilterAutoCompleteProps;
  deviceFilter: FilterAutoCompleteProps;
}

export default createContext<Context>({
  input: {},
  setInput: () => {},
  loading: false,
  buildingFilter: null,
  regionFilter: null,
  deviceFilter: null,
});
