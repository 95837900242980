import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Collapse, Input, Modal, Select } from 'antd';

import { NmapDevice } from '@totem/types/nmap';

const { Panel } = Collapse;
const { Option } = Select;
const { TextArea } = Input;

import {
  getPortsDisplayFormat,
  getSoftwareTypeDisplayFormat,
} from '@totem/selectors/nmapAnalysisSelectors';
import criticalityUtilities from '@totem/utilities/criticalityUtilities';

import './nmapDetail.css';

type Props = {
  device: NmapDevice;
  loading: boolean;
  visible: boolean;
  setVisible: (visible: boolean) => void;
  updateDevice: (device: NmapDevice) => void;
};

const EditDeviceModal = ({
  device,
  updateDevice,
  visible,
  setVisible,
  loading,
}: Props) => {
  const {
    isFlagged,
    isManufacturerFlagged,
    isPortsFlagged,
    isSoftwareVersionsFlagged,
    isOtherFlagged,
    criticality,
    deviceManufacturer,
    ipAddress,
    macAddress,
    ports,
    softwareTypes,
    notes,
  } = device;

  const [deviceNotes, setNotes] = useState<string>(notes);

  useEffect(() => {
    setNotes(notes);
  }, [device.id, notes]);

  const listOfPorts = getPortsDisplayFormat(ports);
  const listOfSoftwareTypes = getSoftwareTypeDisplayFormat(softwareTypes);
  const manufacturer = deviceManufacturer
    ? deviceManufacturer
    : 'Unknown Manufacturer';

  const handleNotesOnChange = ({ target: { value } }) => {
    setNotes(value);
  };

  const updateDeviceProperties = (data: any) => {
    updateDevice({
      ...device,
      ...data,
    });
  };

  return (
    <Modal
      title={manufacturer}
      footer={
        <div styleName="modal-footer">
          <div>
            {!isFlagged ? (
              <Button
                type="default"
                onClick={() =>
                  updateDeviceProperties({
                    isFlagged: true,
                  })
                }
              >
                Flag Device
              </Button>
            ) : (
              <Button
                danger
                onClick={() =>
                  updateDeviceProperties({
                    isFlagged: false,
                  })
                }
              >
                Unflag Device
              </Button>
            )}
          </div>
          <div styleName="footer-offset">
            <Button type="primary" onClick={() => setVisible(false)}>
              Done
            </Button>
          </div>
        </div>
      }
      open={visible}
      bodyStyle={{ padding: 0 }}
      onCancel={() => setVisible(false)}
      confirmLoading={loading}
    >
      <>
        {isFlagged && (
          <div styleName="flagged-container">
            <div>Flag Type(s)</div>
            <div styleName="flagged-checkbox-container">
              <Checkbox
                checked={isPortsFlagged}
                onChange={() =>
                  updateDeviceProperties({
                    isPortsFlagged: !isPortsFlagged,
                  })
                }
              >
                <span styleName="checkbox-text">Port</span>
              </Checkbox>
              <Checkbox
                checked={isSoftwareVersionsFlagged}
                onChange={() =>
                  updateDeviceProperties({
                    isSoftwareVersionsFlagged: !isSoftwareVersionsFlagged,
                  })
                }
              >
                <span styleName="checkbox-text">OS</span>
              </Checkbox>
              <Checkbox
                checked={isManufacturerFlagged}
                onChange={() =>
                  updateDeviceProperties({
                    isManufacturerFlagged: !isManufacturerFlagged,
                  })
                }
              >
                <span styleName="checkbox-text">Manufacturer</span>
              </Checkbox>
              <Checkbox
                checked={isOtherFlagged}
                onChange={() =>
                  updateDeviceProperties({
                    isOtherFlagged: !isOtherFlagged,
                  })
                }
              >
                <span styleName="checkbox-text">Other</span>
              </Checkbox>
            </div>
            <div>Select Criticality</div>
            <div styleName="criticality-selection-container">
              <Select
                style={{ width: 130 }}
                value={criticality}
                onChange={(value: number) =>
                  updateDeviceProperties({
                    criticality: value,
                  })
                }
              >
                {criticalityUtilities.criticalityOptions.map((option: any) => (
                  <Option key={option.value} value={option.value}>
                    {option.text}
                  </Option>
                ))}
              </Select>
            </div>
            <div>
              <TextArea
                onChange={handleNotesOnChange}
                value={deviceNotes}
                autoSize={{ minRows: 4, maxRows: 10 }}
                placeholder="Add notes"
              />
            </div>
            <div styleName="notes-button-container">
              <Button
                type="primary"
                onClick={() =>
                  updateDeviceProperties({
                    notes: deviceNotes,
                  })
                }
              >
                Save
              </Button>
            </div>
          </div>
        )}
        <div styleName="modal-content">
          <div styleName="modal-content-heading">Device Attributes</div>
          <div styleName="modal-attribute-row">
            <div styleName="modal-attribute-label">IP Address</div>
            <div>{ipAddress}</div>
            <div styleName="modal-attribute-row">
              <div styleName="modal-attribute-label">MAC Address</div>
              <div>{macAddress}</div>
            </div>
            <div styleName="modal-attribute-row">
              <div styleName="modal-attribute-label">Manufacturer</div>
              <div>{manufacturer}</div>
            </div>
          </div>
          <div styleName="modal-content-heading offset">
            Ports & OS Versions
          </div>
          <Collapse>
            <Panel header="Ports" key="ports">
              {listOfPorts.map((port: string, index: number) => (
                <p key={`${port}-${index}`}>{port}</p>
              ))}
            </Panel>
            <Panel header="OS Versions" key="softwareVersions">
              {listOfSoftwareTypes.map((os: string, index: number) => (
                <p key={`${os}-${index}`}>{os}</p>
              ))}
            </Panel>
          </Collapse>
        </div>
      </>
    </Modal>
  );
};

export default EditDeviceModal;
