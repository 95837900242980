import React, { useContext } from 'react';
import { Col, Row } from 'antd';

import DeviceContext from '@totem/components/devices/deviceDetails/DeviceContext';
import { sortStringAscending } from '@totem/components/events/eventsContainer/utilities';
import HeaderLabel from '@totem/components/ticketing/details/headerLabel';

const NetworkDetails = () => {
  const { deviceData } = useContext(DeviceContext);

  return (
    <Row>
      <Col span={8}>
        <HeaderLabel title="MAC Addresses">
          {deviceData.device.keys
            .filter(key => key.type === 'MAC_ADDRESS')
            .sort((compA, compB) =>
              sortStringAscending(compA.value, compB.value),
            )
            .map(key => (
              <span key={key.value}>
                {key.value}
                <br />
              </span>
            ))}
        </HeaderLabel>
      </Col>
      <Col span={8}>
        <HeaderLabel title="IP Addresses">
          {deviceData.device.ipAddresses
            .sort((compA, compB) => sortStringAscending(compA, compB))
            .map(ip => (
              <span key={ip}>
                {ip}
                <br />
              </span>
            ))}
        </HeaderLabel>
      </Col>
      <Col span={8}>&nbsp;</Col>
    </Row>
  );
};

export default NetworkDetails;
