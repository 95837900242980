import React, { useEffect } from 'react';
import { Checkbox, Input, Menu, MenuProps } from 'antd';
import * as R from 'ramda';

import DetailSpinner from '@totem/components/common/DetailSpinner';
import {
  AutoCompleteFilter as AutoCompleteFilterType,
  AutoCompleteOptions,
  AutoCompleteSelected,
} from '@totem/types/hooks';

import './dropdownAutoCompleteFilter.css';

const { Search } = Input;

type Props = {
  label: string;
  style?: React.CSSProperties;
  visible: boolean;
  onClose: () => void;
} & AutoCompleteFilterType;

const styles = {
  menu: {
    width: '24rem',
  },
  menuItem: {
    whiteSpace: 'normal' as any,
  },
  checkbox: {
    marginRight: '1rem',
  },
  input: {
    width: '100%',
  },
};

const getPreviouslySelected = (
  selected: AutoCompleteSelected,
  options: AutoCompleteOptions,
): AutoCompleteSelected => {
  // @ts-ignore
  return R.omit(R.intersection(R.keys(selected), R.keys(options)), selected);
};

const AutoCompleteFilter = ({
  search,
  onSearch,
  loadingSearch,
  options,
  selected,
  onSelect,
  onApply,
  onReset,
  label,
  visible,
  onClose,
  loading,
}: Props) => {
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    onSearch(event.target.value);
  };

  const handleOk = () => {
    if (loading) {
      return;
    }

    onClose();
    onApply();
  };

  const handleReset = () => {
    if (loading) {
      return;
    }

    onClose();
    onReset();
  };

  useEffect(() => {
    if (!visible) {
      onSearch('');
      onApply();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const previouslySelected = getPreviouslySelected(selected, options);
  const numberPreviouslySelected = Object.keys(previouslySelected).length;
  const selectedKeys = Object.keys(selected);
  const numberSelected = selectedKeys.length;
  const numberOptions = Object.keys(options).length;

  const getMenuItems = () => {
    const menuItems: MenuProps['items'] = [];

    if (!loading) {
      const previousSelectedItems = Object.entries(previouslySelected).map(
        ([key, value]) => {
          return {
            key,
            label: (
              <div styleName="menu-item" onClick={() => onSelect(key, value)}>
                <Checkbox disabled={loading} style={styles.checkbox} checked />
                <div styleName="menu-item-text">{value}</div>
              </div>
            ),
          };
        },
      );

      menuItems.push(...previousSelectedItems);
    }

    if (Boolean(numberPreviouslySelected)) {
      menuItems.push({
        key: 'divider-1',
        type: 'divider',
      });
    }

    const foundItems = Object.entries(options).map(([key, value]) => {
      return {
        key,
        label: (
          <div styleName="menu-item" onClick={() => onSelect(key, value)}>
            <Checkbox style={styles.checkbox} checked={R.has(key, selected)} />
            <div styleName="menu-item-text">{value}</div>
          </div>
        ),
      };
    });

    menuItems.push(...foundItems);

    return menuItems;
  };

  return (
    <div style={styles.menu}>
      <div styleName="number-selected-container">
        {`${numberSelected} Selected`}
      </div>
      <div styleName="input-container">
        <Search
          value={search}
          onChange={handleSearch}
          loading={loadingSearch}
          style={styles.input}
          disabled={loading}
        />
      </div>
      {loading && (
        <div styleName="spinner-container">
          <DetailSpinner size={40} />
        </div>
      )}
      <Menu
        items={getMenuItems()}
        multiple
        style={styles.menu}
        selectedKeys={selectedKeys}
      />
      {!Boolean(numberOptions) && !loading && (
        <div styleName="dropdown-text">
          {search
            ? `Nothing was found for "${search}". Try searching something else.`
            : `Search ${label}`}
        </div>
      )}
      <div styleName="buttons">
        <a onClick={handleReset}>Reset</a>
        <a styleName="ok-button" onClick={handleOk}>
          OK
        </a>
      </div>
    </div>
  );
};

export default AutoCompleteFilter;
