import React, { useContext } from 'react';
import { EllipsisOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { Button, Dropdown, MenuProps, Modal } from 'antd';

import DeviceContext from '@totem/components/devices/devicesContainer/deviceContainerContext';
import UserProfileContext from '@totem/components/UserProfileContext';
import { DeviceQueryResult } from '@totem/types/devices';
import { getUserRole } from '@totem/utilities/userUtilities';

type Props = {
  device: DeviceQueryResult;
};

const DeviceActionMenu = ({ device }: Props) => {
  const { userProfile } = useContext(UserProfileContext);
  const { onAction } = useContext(DeviceContext);

  const isAdmin = getUserRole(userProfile) >= 2;
  const isSysAdmin = getUserRole(userProfile) >= 3;

  const handleRemoveDevice = () => {
    Modal.confirm({
      title: `Do you want to delete this device: ${device.device.displayName}?`,
      content: 'This action cannot be undone.',
      okText: 'Delete',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk: () => onAction('RemoveDevice', device),
      onCancel: () => {},
    });
  };

  const getActionMenuItems = (): MenuProps['items'] => {
    const menuItems: MenuProps['items'] = [];

    if (isAdmin) {
      menuItems.push({
        key: 'set_association',
        label: 'Set Association',
        title: 'Set Association',
        icon: <MenuUnfoldOutlined />,
      });
    }

    if (isSysAdmin) {
      menuItems.push({
        key: 'remove_device',
        label: 'Remove Device',
        title: 'Remove Device',
        icon: <MenuUnfoldOutlined />,
      });
    }

    return menuItems;
  };

  const onMenuClicked = ({ key }) => {
    // eslint-disable-next-line default-case
    switch (key) {
      case 'set_association':
        onAction('ShowReferenceModal', device);
        break;
      case 'remove_device':
        handleRemoveDevice();
        break;
    }
  };

  return (
    <Dropdown
      menu={{ items: getActionMenuItems(), onClick: onMenuClicked }}
      trigger={['click']}
    >
      <Button shape="circle" icon={<EllipsisOutlined />} />
    </Dropdown>
  );
};

export default DeviceActionMenu;
