import * as R from 'ramda';
import { call, fork, put, takeEvery } from 'redux-saga/effects';

import actionTypes from '@totem/actions/actionTypes';
import * as regionActions from '@totem/actions/regionActions';
import accountUtilities from '@totem/utilities/accountUtilities';
import api from '@totem/utilities/api';
import { REGIONS_ENDPOINT } from '@totem/utilities/endpoints';
import { TOTAL_COUNT_HEADER } from '@totem/utilities/requestUtilities';

export function* getRegions(action) {
  try {
    const { page, limit, isEnabled } = action.data;

    const params = {
      ...(page && { page }),
      ...(limit && { limit }),
      ...(!R.isNil(isEnabled) && { isEnabled }),
    };

    const { data, headers } = yield call(api.get, REGIONS_ENDPOINT, params);
    const total = Number(headers[TOTAL_COUNT_HEADER]) || 0;

    yield put(regionActions.getRegionsSuccess(data));
    yield put(
      regionActions.getRegionsTotalSuccess({
        total: Number(total),
      }),
    );
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      regionActions.getRegionsFailure,
    );
  }
}

export function* createRegion(action) {
  try {
    const { data: body } = action;
    // @ts-ignore
    const { data } = yield call(api.post, REGIONS_ENDPOINT, body);

    yield put(regionActions.createRegionSuccess(data));
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      regionActions.createRegionFailure,
    );
  }
}

export function* updateRegion(action) {
  try {
    const { data: body } = action;

    const endpoint = `${REGIONS_ENDPOINT}/${body.regionId}`;
    // @ts-ignore
    const { data } = yield call(api.patch, endpoint, body);

    yield put(regionActions.regionUpdateSuccess(data));
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      regionActions.regionUpdateFailure,
    );
  }
}

/* WATCHERS */
export function* retrieveRegions() {
  yield takeEvery(actionTypes.REQUEST_REGIONS, getRegions);
}

export function* requestRegionUpdate() {
  yield takeEvery(actionTypes.REQUEST_REGION_UPDATE, updateRegion);
}

export function* requestRegionCreation() {
  yield takeEvery(actionTypes.REQUEST_CREATE_REGION, createRegion);
}

export default [retrieveRegions, requestRegionUpdate, requestRegionCreation];
