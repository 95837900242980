import React, { useEffect, useState } from 'react';
import {
  Affix,
  Button,
  Checkbox,
  Divider,
  Menu,
  MenuProps,
  Radio,
  theme,
} from 'antd';
import * as R from 'ramda';

import colors from '@totem/styles/colors';
import { StringFilterOption } from '@totem/types/common';
import { isNotNull } from '@totem/utilities/common';

import './base.css';

const styles = {
  anchor: {
    position: 'relative' as any,
  },
  menu: {
    boxShadow: 'none',
    maxHeight: '25rem',
    overflow: 'scroll',
  },
  checkbox: {
    marginRight: '0.5rem',
  },
  radio: {
    marginRight: '0.5rem',
  },
  buttonSelected: {
    background: '#141238',
    color: colors.neutral.white,
    fontWeight: 700,
  },
};

type Props = {
  options: StringFilterOption[];
  multiple?: boolean;
  value?: Array<string | number>;
  onChange: (selected: string[]) => void;
  singleSelect?: boolean;
  defaultSelected?: Array<string | number>;
  filterDropdownOpen: boolean;
  onCloseFilter: () => void;
  padding?: number;
};
const TableFilterDropDown = ({
  options,
  multiple,
  value,
  onChange,
  defaultSelected,
  singleSelect,
  onCloseFilter,
  padding,
}: Props) => {
  const { useToken } = theme;
  const { token } = useToken();
  const [container, setContainer] = React.useState<HTMLDivElement | null>(null);
  const [maxOptionSize, setMaxOptionSize] = useState<number>(15);
  const [characterPadding] = useState<number>(isNotNull(padding) ? padding : 3);
  const [selected, setSelected] = useState<any[]>(defaultSelected || []);

  useEffect(() => {
    let maxSize = 5;
    for (let idx = 0; idx < options.length; idx++) {
      if (options[idx].text.length > maxSize) {
        maxSize = options[idx].text.length;
      }
    }
    setMaxOptionSize(maxSize + characterPadding);
  }, [options]);

  const handleClick = ({ key }: any) => {
    if (singleSelect) {
      setSelected([key]);
    } else {
      setSelected(
        selected.includes(key)
          ? selected.filter((val) => val !== key)
          : [...selected, key],
      );
    }
  };

  const handleSubmit = () => {
    onChange(selected);
    onCloseFilter();
  };

  const handleReset = () => {
    setSelected([]);
    onChange([]);
    onCloseFilter();
  };

  useEffect(() => {
    if (!R.isNil(value)) {
      setSelected(value.map((val) => val.toString()));
    }
  }, [value]);

  const contentStyle: React.CSSProperties = {
    minWidth: `${maxOptionSize}ch`,
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
  };

  const menuStyle: React.CSSProperties = {
    boxShadow: 'none',
  };

  const getMenuItems = () => {
    const menuItems: MenuProps['items'] = options.map((option) => {
      const optionValue = option.value.toString();

      const isSelected = selected
        .map((val) => val.toString())
        .includes(optionValue);

      return {
        key: optionValue,
        label: (
          <div styleName="menu-item">
            {singleSelect ? (
              <Radio style={styles.radio} checked={isSelected} />
            ) : (
              <Checkbox style={styles.checkbox} checked={isSelected} />
            )}
            {option.text}
          </div>
        ),
      };
    });

    return menuItems;
  };

  return (
    <div style={contentStyle} ref={setContainer}>
      <div>
        <Menu
          onClick={handleClick}
          style={menuStyle}
          multiple={isNotNull(multiple) ? multiple : true}
          items={getMenuItems()}
        />
      </div>
      <Divider style={{ margin: 0 }} />
      <Affix offsetBottom={0} target={() => container}>
        <div styleName="buttons">
          <Button
            type="link"
            onClick={handleReset}
            size="small"
            disabled={isNotNull(selected) ? selected.length === 0 : true}
          >
            Reset
          </Button>
          <Button type="primary" onClick={handleSubmit} size="small">
            OK
          </Button>
        </div>
      </Affix>
    </div>
  );
};

export default TableFilterDropDown;
