import { createContext } from 'react';

import {
  ConfigGetBuildingsResponse,
  EnergyAPIs,
  EnergyInput,
  MetricsBuildingTimeSeriesResponse,
  MetricsPortfolioChartResponse,
  MetricsPortfolioKpiResponse,
  PortfolioEndUsesResponse,
} from '@totem/components/energy/types';

interface Context {
  input: EnergyInput;
  setInput: (input: EnergyInput) => void;
  setApiUsage: (usage: EnergyAPIs) => void;
  loading: boolean;
  portfolioKpiData?: MetricsPortfolioKpiResponse;
  portfolioCarbonKpiData?: MetricsPortfolioKpiResponse;
  portfolioChartData?: MetricsPortfolioChartResponse;
  portfolioEndUses?: PortfolioEndUsesResponse;
  buildings?: ConfigGetBuildingsResponse;
  buildingChartData?: MetricsBuildingTimeSeriesResponse;
}

export default createContext<Context>({
  input: {},
  setInput: () => {},
  setApiUsage: () => {},
  loading: false,
  portfolioKpiData: null,
  portfolioCarbonKpiData: null,
  portfolioChartData: null,
  portfolioEndUses: null,
  buildings: null,
  buildingChartData: null,
});
