import { DeviceQueryResult } from '@totem/types/devices';
import { isNotNull } from '@totem/utilities/common';
export const hasVariables = (device: DeviceQueryResult) => {
  if (
    isNotNull(device) &&
    isNotNull(device.device) &&
    isNotNull(device.device.variableCollection) &&
    device.device.variableCollection.length > 0
  ) {
    return true;
  }

  return false;
};
