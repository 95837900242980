import * as React from 'react';
import { Form, Input, Select } from 'antd';
import * as R from 'ramda';

const FormItem = Form.Item;
const { Option } = Select;

import LocationAutoComplete from '@totem/components/common/autoCompletes/LocationAutoComplete';
import { Address, AddressFormErrors } from '@totem/types/address';
import { COUNTRIES } from '@totem/utilities/addressUtilities';

import './addressEntry.css';

export interface Props {
  value: Address;
  onChange: (address: Address) => void;
  disabled: boolean;
  errors: AddressFormErrors;
}

const styles = {
  form: {
    width: '100%',
  },
  formItem: {
    paddingBottom: '0',
    marginBottom: '1rem',
    width: '100%',
  },
};

export const EMPTY_LOCATION = {
  lat: 0,
  lng: 0,
};

class AddressEntry extends React.Component<Props> {
  handleChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const { onChange, value } = this.props;
    const { name, value: val } = target;

    onChange({
      ...value,
      [name]: val,
      ...this.getLocationOnChange(name, value),
    });
  };

  handleCountryChange = (country: string) => {
    const { value, onChange } = this.props;

    onChange({
      ...value,
      country,
      ...this.getLocationOnChange('country', value),
    });
  };

  handleLocationSelect = (address: Address) => {
    const { value, onChange } = this.props;
    onChange({ ...value, ...address });
  };

  getLocationOnChange(name: string, { location }: Address) {
    // invalidate location on any manual form change except address two
    return name === 'addressTwo' ? { location } : { location: EMPTY_LOCATION };
  }

  render() {
    const { value, disabled, errors } = this.props;
    const { addressOne, addressTwo, city, state, zipCode } = value;

    return (
      <Form layout="vertical" style={styles.form}>
        <FormItem label="Location Search" colon={false} style={styles.formItem}>
          <LocationAutoComplete
            onSelect={this.handleLocationSelect}
            disabled={disabled}
          />
        </FormItem>
        <FormItem
          label="Address"
          colon={false}
          validateStatus={R.isNil(errors.addressOne) ? 'success' : 'error'}
          help={R.isNil(errors.addressOne) ? null : errors.addressOne}
          style={styles.formItem}
        >
          <Input
            disabled={disabled}
            value={addressOne}
            name="addressOne"
            onChange={this.handleChange}
            type="search"
          />
        </FormItem>
        <FormItem
          colon={false}
          validateStatus={R.isNil(errors.addressTwo) ? 'success' : 'error'}
          help={R.isNil(errors.addressTwo) ? null : errors.addressTwo}
          style={styles.formItem}
        >
          <Input
            disabled={disabled}
            value={addressTwo}
            name="addressTwo"
            onChange={this.handleChange}
            type="search"
          />
        </FormItem>
        <div styleName="horizontal">
          <FormItem
            label="City"
            colon={false}
            validateStatus={R.isNil(errors.city) ? 'success' : 'error'}
            help={R.isNil(errors.city) ? null : errors.city}
            style={styles.formItem}
          >
            <Input
              disabled={disabled}
              value={city}
              name="city"
              onChange={this.handleChange}
              type="search"
            />
          </FormItem>
          <FormItem
            label="State"
            colon={false}
            validateStatus={R.isNil(errors.state) ? 'success' : 'error'}
            help={R.isNil(errors.state) ? null : errors.state}
            style={styles.formItem}
          >
            <Input
              disabled={disabled}
              value={state}
              name="state"
              onChange={this.handleChange}
              type="search"
            />
          </FormItem>
        </div>
        <FormItem
          label="Zip Code"
          colon={false}
          validateStatus={R.isNil(errors.zipCode) ? 'success' : 'error'}
          help={R.isNil(errors.zipCode) ? null : errors.zipCode}
          style={styles.formItem}
        >
          <Input
            disabled={disabled}
            value={zipCode}
            name="zipCode"
            onChange={this.handleChange}
            type="search"
          />
        </FormItem>
        <FormItem
          label="Country"
          colon={false}
          validateStatus={R.isNil(errors.country) ? 'success' : 'error'}
          help={R.isNil(errors.country) ? null : errors.country}
          style={styles.formItem}
        >
          <Select
            showSearch
            filterOption
            onChange={this.handleCountryChange}
            value={value.country}
            optionFilterProp="children"
            disabled={disabled}
          >
            {COUNTRIES.map(country => {
              return (
                <Option key={country} value={country}>
                  {country}
                </Option>
              );
            })}
          </Select>
        </FormItem>
      </Form>
    );
  }
}

export default AddressEntry;
