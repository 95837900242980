import * as R from 'ramda';
import { createSelector } from 'reselect';

import {
  BrandingPreference,
  OrganizationPreferences,
} from '@totem/types/organization';

import ibLogoLg from '../images/ib-logo.svg';
import ibLogoSm from '../images/intellinet_icon.svg';
//import ibLogin from '../images/intellinet.svg';
export const preferencesSelector = R.pathOr<OrganizationPreferences | null>(
  null,
  ['organization', 'preferences'],
);

export const userProfileRegionLabelSelector = createSelector(
  preferencesSelector,
  R.pathOr('Region', ['applicationLabels', 'region']),
);

const nav_logo_Small = ibLogoSm;
const nav_logo_Large = ibLogoLg;
//let login_logo = ibLogin;

export const staticBranding = BrandingPreference.INTELLIGENT_BUILDINGS;
export const staticLogoSmall = nav_logo_Small;
export const staticLogoLarge = nav_logo_Large;
export const staticLoginLogo = ibLogoSm;
export const staticAuthLoginLogo = ibLogoSm;
