/* eslint camelcase: 0 */

import React, { ReactNode, useEffect, useState } from 'react';

import EnergyContext from '@totem/components/energy/EnergyContext';
import {
  ConfigGetBuildingsRequest,
  ConfigGetBuildingsResponse,
  EnergyAPIs,
  EnergyInput, MetricsBuildingTimeSeriesRequest, MetricsBuildingTimeSeriesResponse,
  MetricsPortfolioChartRequest,
  MetricsPortfolioChartResponse,
  MetricsPortfolioKpiRequest,
  MetricsPortfolioKpiResponse,
  PortfolioEndUsesRequest,
  PortfolioEndUsesResponse,
} from '@totem/components/energy/types';
import { calculateDatesFromRange } from '@totem/components/energy/utilities';
import { getToken } from '@totem/utilities/accountUtilities';
import { ENERGY_ENDPOINT } from '@totem/utilities/endpoints';
import { omitNilOrEmpty } from '@totem/utilities/objectUtilities';
import dayjs from "dayjs";

type Props = {
  refresh?: boolean;
  onRecordTotalChanged?: (total: number) => void;
  onDataRefreshRequested?: () => void;
  children?: ReactNode;
  endPoint?: string;
  dateRangeType?: string;
  startDate?: string;
  endDate?: string;
  buildingId?: string;
  timezone?: string;
  metric?: string;
  soruceDevice?: string;
  aggregate?: string;
  aggregation_type?: string;
  minute?: number;
  apis?: EnergyAPIs;
  selfRegisterApis?: boolean;
};

const EnergyContainer = ({
  children,
  dateRangeType,
  startDate,
  endDate,
  refresh,
  buildingId,
  timezone,
  metric,
  soruceDevice,
  aggregate,
  aggregation_type,
  minute,
  apis,
  selfRegisterApis,
}: Props) => {
  const dateRange = calculateDatesFromRange({
    dateRangeType,
    startDate,
    endDate,
  });
  const selfRegister =
    typeof selfRegisterApis === 'boolean' ? selfRegisterApis : true;
  const [refreshData, setRefreshData] = useState<boolean>(true);
  const [apiUsage, updateApiUsage] = useState<EnergyAPIs>(
    typeof apis !== 'undefined' && apis !== null
      ? {
          portfolioKpi:
            typeof apis.portfolioKpi === 'boolean' ? apis.portfolioKpi : true,
          portfolioCarbonKpi:
            typeof apis.portfolioCarbonKpi === 'boolean'
              ? apis.portfolioCarbonKpi
              : true,
          portfolioChart:
            typeof apis.portfolioChart === 'boolean'
              ? apis.portfolioChart
              : true,
          portfolioEndUses:
            typeof apis.portfolioEndUses === 'boolean'
              ? apis.portfolioEndUses
              : true,
          buildings:
            typeof apis.buildings === 'boolean' ? apis.buildings : true,
          buildingChart:
            typeof apis.buildingChart === 'boolean' ? apis.buildingChart : true,
        }
      : !selfRegister
      ? {
          portfolioKpi: true,
          portfolioCarbonKpi: true,
          portfolioChart: true,
          portfolioEndUses: true,
          buildings: true,
          buildingChart: false,
        }
      : {
          portfolioKpi: false,
          portfolioCarbonKpi: false,
          portfolioChart: false,
          portfolioEndUses: false,
          buildings: false,
          buildingChart: false,
        },
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [input, updateInput] = useState<EnergyInput>({
    startDate: dateRange.startDate,
    endDate: dateRange.endDate,
    dateRangeType: dateRange.dateRangeType,
    buildingId,
    timezone:
      typeof timezone !== 'undefined' && timezone !== null && timezone !== ''
        ? timezone
        : 'America/New_York',
    metric:
      typeof metric !== 'undefined' && metric !== null && metric !== ''
        ? metric
        : 'energy',
    soruce_device: soruceDevice,
    aggregate:
      typeof aggregate !== 'undefined' && aggregate !== null
        ? aggregate
        : 'hour',
    aggregation_type:
      // eslint-disable-next-line camelcase
      typeof aggregation_type !== 'undefined' && aggregation_type !== null
        ? aggregation_type
        : 'sum',
    minute: typeof minute === 'number' ? minute : 1,
  });
  const [portfolioKpiData, setPortfolioKpiData] = useState<
    MetricsPortfolioKpiResponse
  >(null);
  const [portfolioCarbonKpiData, setPortfolioCarbonKpiData] = useState<
    MetricsPortfolioKpiResponse
  >(null);
  const [portfolioChartData, setPortfolioChartData] = useState<
    MetricsPortfolioChartResponse
  >(null);
  const [buildings, setBuildings] = useState<ConfigGetBuildingsResponse>(null);
  const [portfolioEndUses, setPortfolioEndUses] = useState<
    PortfolioEndUsesResponse
  >(null);
  const [buildingChartData, setBuildingChartData] = useState<
    MetricsBuildingTimeSeriesResponse
  >(null);
  const setInput = (updated: Partial<EnergyInput>) => {
    const newInput = { ...input, ...updated };
    updateInput(omitNilOrEmpty(newInput));
    if (newInput.startDate !== '' && newInput.endDate !== '') {
      setRefreshData(true);
    }
  };

  const setApiUsage = (updated: Partial<EnergyAPIs>) => {
    if (selfRegister) {
      updateApiUsage({ ...apiUsage, ...updated });
    }
  };

  // Buildings
  useEffect(() => {
    if (apiUsage.buildings) {
      const params: ConfigGetBuildingsRequest = {
        config: false,
      };

      fetch(`${ENERGY_ENDPOINT}/config/get_buildings`, {
        method: 'POST',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
        body: JSON.stringify(params),
      })
        .then(res => res.json())
        .then((result: ConfigGetBuildingsResponse) => {
          setBuildings(result);
        });
    }
  }, [apiUsage.buildings]);

  // Portfolio KPI
  useEffect(() => {
    if (refreshData && apiUsage.portfolioKpi) {
      setRefreshData(false);

      if (input.startDate !== '' && input.endDate !== '') {
        setIsLoading(true);
        const params: MetricsPortfolioKpiRequest = {
          date_from: input.startDate,
          date_to: input.endDate,
          building_id: input.buildingId,
          tz_info: input.timezone,
          metric: input.metric,
          source_device: input.soruce_device,
        };

        fetch(`${ENERGY_ENDPOINT}/portfolio/kpi`, {
          method: 'POST',
          headers: new Headers({
            Authorization: `Bearer ${getToken()}`,
          }),
          body: JSON.stringify(params),
        })
          .then(res => res.json())
          .then((result: MetricsPortfolioKpiResponse) => {
            setPortfolioKpiData(result);
          })
          .then(() => {
            setIsLoading(false);
          });
      }
    }
  }, [refreshData, refresh, apiUsage.portfolioKpi]);

  useEffect(() => {
    if (refreshData && apiUsage.portfolioCarbonKpi) {
      setRefreshData(false);

      if (input.startDate !== '' && input.endDate !== '') {
        setIsLoading(true);
        const params: MetricsPortfolioKpiRequest = {
          date_from: input.startDate,
          date_to: input.endDate,
          building_id: input.buildingId,
          tz_info: input.timezone,
          metric: 'carbon',
          source_device: input.soruce_device,
        };

        fetch(`${ENERGY_ENDPOINT}/portfolio/kpi`, {
          method: 'POST',
          headers: new Headers({
            Authorization: `Bearer ${getToken()}`,
          }),
          body: JSON.stringify(params),
        })
          .then((res) => res.json())
          .then((result: MetricsPortfolioKpiResponse) => {
            setPortfolioCarbonKpiData(result);
          })
          .then(() => {
            setIsLoading(false);
          });
      }
    }
  }, [refreshData, refresh, apiUsage.portfolioCarbonKpi]);

  // Portfolio Charts
  useEffect(() => {
    if (refreshData && apiUsage.portfolioChart) {
      setRefreshData(false);

      if (input.startDate !== '' && input.endDate !== '') {
        setIsLoading(true);
        const params: MetricsPortfolioChartRequest = {
          date_from: dayjs(input.startDate).format(),
          date_to: dayjs(input.endDate).format(),
          building_id: input.buildingId,
          tz_info: input.timezone,
          metric: input.metric,
          source_device: input.soruce_device,
          aggregate: input.aggregate,
          aggregation_type: input.aggregation_type,
          minute: input.minute,
        };

        fetch(`${ENERGY_ENDPOINT}/portfolio/chart`, {
          method: 'POST',
          headers: new Headers({
            Authorization: `Bearer ${getToken()}`,
          }),
          body: JSON.stringify(params),
        })
          .then(res => res.json())
          .then((result: MetricsPortfolioChartResponse) => {
            setPortfolioChartData(result);
          })
          .then(() => {
            setIsLoading(false);
          });
      }
    }
  }, [refreshData, refresh, apiUsage.portfolioChart]);

  // Portfolio End Uses
  useEffect(() => {
    if (refreshData && apiUsage.portfolioEndUses) {
      setRefreshData(false);

      if (input.startDate !== '' && input.endDate !== '') {
        setIsLoading(true);
        const params: PortfolioEndUsesRequest = {
          date_from: input.startDate,
          date_to: input.endDate,
          building_id: input.buildingId,
          tz_info: input.timezone,
          off_hours: false,
        };

        fetch(`${ENERGY_ENDPOINT}/portfolio/enduses`, {
          method: 'POST',
          headers: new Headers({
            Authorization: `Bearer ${getToken()}`,
          }),
          body: JSON.stringify(params),
        })
          .then(res => res.json())
          .then((result: PortfolioEndUsesResponse) => {
            setPortfolioEndUses(result);
          })
          .then(() => {
            setIsLoading(false);
          });
      }
    }
  }, [refreshData, refresh, apiUsage.portfolioEndUses]);

  // Building Charts
  useEffect(() => {
    if (refreshData && apiUsage.buildingChart && input.buildingId !== '') {
      setRefreshData(false);

      if (input.startDate !== '' && input.endDate !== '') {
        setIsLoading(true);
        const params: MetricsBuildingTimeSeriesRequest = {
          date_from: input.startDate,
          date_to: input.endDate,
          building_id: input.buildingId,
          tz_info: input.timezone,
          metric: input.metric,
          carbon_metric_unit: 'lb',
          source_device: input.soruce_device,
          aggregate: input.aggregate,
          minute: input.minute,
        };

        fetch(`${ENERGY_ENDPOINT}/building/timeseries`, {
          method: 'POST',
          headers: new Headers({
            Authorization: `Bearer ${getToken()}`,
          }),
          body: JSON.stringify(params),
        })
          .then(res => res.json())
          .then((result: MetricsBuildingTimeSeriesResponse) => {
            setBuildingChartData(result);
          })
          .then(() => {
            setIsLoading(false);
          });
      }
    }
  }, [refreshData, refresh, apiUsage.buildingChart]);

  return (
    <EnergyContext.Provider
      value={{
        input,
        setInput,
        setApiUsage,
        loading: isLoading,
        portfolioKpiData,
        portfolioCarbonKpiData,
        portfolioChartData,
        portfolioEndUses,
        buildings,
        buildingChartData,
      }}
    >
      <div>{children}</div>
    </EnergyContext.Provider>
  );
};

export default EnergyContainer;
