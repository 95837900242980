import React, { useCallback, useContext, useState } from 'react';
import { Badge, Form, Input, Modal, Select } from 'antd';
import * as R from 'ramda';

import SurveyInstanceContext from '@totem/components/surveyV2/instances/SurveyInstancesContext';
import { HEALTH_STATUS_ENUM, HealthStatusOptions } from '@totem/types/health';
import { getToken } from '@totem/utilities/accountUtilities';
import { V2_SURVEY_ENDPOINT } from '@totem/utilities/endpoints';
import { CheckResponseShowError } from '@totem/utilities/responseUtilities';
const FormItem = Form.Item;
const { Option } = Select;

const styles = {
  select: {
    width: '14rem',
  },
  formItem: {
    paddingBottom: '0',
    marginBottom: '2rem',
  },
};

const HealthDialog = () => {
  const { loading, action, onAction, selectedInstance, setIsLoading } =
    useContext(SurveyInstanceContext);
  const [comment, setComment] = useState<string>('');
  const [status, setStatus] = useState<number>(
    HEALTH_STATUS_ENUM[HealthStatusOptions.ON_TRACK],
  );

  const sendHealthHistory = useCallback(
    async (
      surveyInstanceID: string,
      statusUpdate: number,
      commentUpdate: string,
    ) => {
      const payload = {
        subjectId: surveyInstanceID,
        status: statusUpdate,
        comment: commentUpdate,
      };

      fetch(`${V2_SURVEY_ENDPOINT}/healthHistory`, {
        method: 'POST',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
        body: JSON.stringify(payload),
      })
        .then((res) => {
          CheckResponseShowError(res);
        })
        .then(() => {
          onAction('refresh', null);
          setIsLoading(false);
        });
    },
    [],
  );

  const getBadgeStatusByHealthStatus = (
    chkStatus: string,
  ): 'success' | 'warning' | 'error' => {
    if (chkStatus === HealthStatusOptions.ON_TRACK) {
      return 'success';
    }

    if (chkStatus === HealthStatusOptions.AT_RISK) {
      return 'warning';
    }

    return 'error';
  };

  const handleSubmit = () => {
    sendHealthHistory(selectedInstance.survey.id, status, comment);
  };

  return (
    <Modal
      title={`Update Health${selectedInstance.survey.name}`}
      open={action === 'show_health_update_dialog'}
      onOk={handleSubmit}
      onCancel={() => onAction('', null)}
      confirmLoading={loading}
      okText="Save"
      okButtonProps={{
        disabled: R.isEmpty(comment),
      }}
    >
      <Form layout="vertical">
        <FormItem
          colon={false}
          style={styles.formItem}
          label="Set current health"
        >
          <Select
            onChange={(newStatus: number) => setStatus(newStatus)}
            value={status}
            style={styles.select}
          >
            {Object.keys(HEALTH_STATUS_ENUM).map((key) => {
              const value: number = HEALTH_STATUS_ENUM[key];

              return (
                <Option key={key} value={value}>
                  <div>
                    <Badge status={getBadgeStatusByHealthStatus(key)} />
                    {key}
                  </div>
                </Option>
              );
            })}
          </Select>
        </FormItem>
        <FormItem colon={false} style={styles.formItem} label="Comment">
          <Input.TextArea
            placeholder="Comment..."
            value={comment}
            onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
              setComment(event.target.value)
            }
            autoSize={{
              minRows: 1,
            }}
          />
        </FormItem>
      </Form>
    </Modal>
  );
};

export default HealthDialog;
