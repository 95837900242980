import React, { useContext, useEffect } from 'react';
import { FallOutlined, RiseOutlined } from '@ant-design/icons';
import { Badge, Flex } from 'antd';

import EnergyContext from '@totem/components/energy/EnergyContext';
import EnergyTrend from '@totem/components/energy/EnergyTrend';
import { NewOldValueWithChange } from '@totem/components/energy/types';

const colorRiseBadge: string = '#FEE4E2';
const colorRiseText: string = '#B32419';
const colorFallBadge: string = '#D1FADF';
const colorFallText: string = '#2A744F';

const PortfolioKPIs = () => {
  const {
    portfolioKpiData,
    portfolioCarbonKpiData,
    buildings,
    setApiUsage,
  } = useContext(EnergyContext);

  useEffect(() => {
    setApiUsage({
      portfolioKpi: true,
      portfolioCarbonKpi: true,
      buildings: true,
    });
  }, []);

  const calculateTrend = (record: NewOldValueWithChange) => {
    if (record.change > 0) {
      return (
        <Badge
          color={colorRiseBadge}
          style={{ backgroundColor: colorRiseBadge, borderRadius: '20%' }}
          count={
            <span
              style={{ color: colorRiseText, padding: '2px', fontWeight: 700 }}
            >
              <RiseOutlined />
              &nbsp;
              {Math.round(record.change)}%
            </span>
          }
        />
      );
    } else if (record.change < 0) {
      return (
        <Badge
          color={colorFallBadge}
          style={{ backgroundColor: colorFallBadge, borderRadius: '20%' }}
          count={
            <span
              style={{ color: colorFallText, padding: '2px', fontWeight: 700 }}
            >
              <FallOutlined />
              &nbsp;
              {Math.round(record.change * -1)}%
            </span>
          }
        />
      );
    }
    return <span>No Change</span>;
  };

  return (
    <div
      style={{
        margin: '5px',
        backgroundColor: '#F9FAFB',
        width: 'fit-content',
        blockSize: 'fit-content',
        borderRadius: '0.625rem',
        padding: '0.187rem 0.375rem 0.187rem 0.375rem',
      }}
    >
      <Flex vertical={false} justify="flex-start" align="flex-start" gap={5}>
        {typeof buildings !== 'undefined' && buildings !== null && (
          <EnergyTrend
            title="Total Buildings"
            total={buildings.length.toString()}
            unit=""
            trend={<span />}
          />
        )}
        {typeof portfolioKpiData !== 'undefined' &&
          portfolioKpiData !== null && (
            <EnergyTrend
              title="Total Consumption"
              total={Math.round(portfolioKpiData.data.total.now).toString()}
              unit="kWh"
              trend={calculateTrend(portfolioKpiData.data.total)}
            />
          )}
        {typeof portfolioKpiData !== 'undefined' &&
          portfolioKpiData !== null && (
            <EnergyTrend
              title="Average Consumption / sq. ft."
              total={Math.round(portfolioKpiData.data.average.now).toString()}
              unit="kWh"
              trend={calculateTrend(portfolioKpiData.data.average)}
            />
          )}

        {typeof portfolioCarbonKpiData !== 'undefined' &&
          portfolioCarbonKpiData !== null && (
            <EnergyTrend
              title="Total Carbon Emissions"
              total={Math.round(
                portfolioCarbonKpiData.data.total.now,
              ).toString()}
              unit="lbs"
              trend={calculateTrend(portfolioCarbonKpiData.data.total)}
            />
          )}
        {typeof portfolioCarbonKpiData !== 'undefined' &&
          portfolioCarbonKpiData !== null && (
            <EnergyTrend
              title="Average Emissions / sq. ft."
              total={portfolioCarbonKpiData.data.average.now.toFixed(4)}
              unit="lbs"
              trend={calculateTrend(portfolioCarbonKpiData.data.average)}
            />
          )}
      </Flex>
    </div>
  );
};

export default PortfolioKPIs;
