import React, { useContext } from 'react';

import Count from '@totem/components/common/Count';
import AnalysisReportContext from '@totem/components/surveyV2/analysisReport/analysisReportContext';
import SubTitle from '@totem/components/surveyV2/SubTitle';
import colors from '@totem/styles/colors';
import { isNotNull } from '@totem/utilities/common';

import '../analysisReport.css';

const styles = {
  completedAuditsCounts: {
    background: colors.neutral.white,
    border: `2px solid ${colors.antd.blue}`,
    marginTop: '1rem',
    marginRight: '1rem',
    minWidth: '8rem',
  },
};

const PolicyResponses = () => {
  const { data } = useContext(AnalysisReportContext);
  const totalAudits = isNotNull(data) ? data.completedAudits.totalAudits : 0;

  return (
    <div styleName="overview-section">
      <SubTitle>Policy Responses</SubTitle>
      <div styleName="overview-content policy-responses">
        <div styleName="overview-content-container">
          <div styleName="graph-title">Total Completed Audits</div>
          <div styleName="completed-audits-content">
            <Count
              count={totalAudits}
              size="large"
              style={styles.completedAuditsCounts}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PolicyResponses;
