import React from 'react';
import { Badge } from 'antd';

import colors from '@totem/styles/colors';

import './nmapDetail.css';

interface Props {
  label: string;
  critical: number;
  high: number;
  medium: number;
  low: number;
}

const styles = {
  high: {
    backgroundColor: colors.criticality.high,
    fontWeight: 700,
    color: colors.neutral.white,
  },
  medium: {
    backgroundColor: colors.criticality.medium,
    fontWeight: 700,
    color: colors.neutral.white,
  },
  low: {
    backgroundColor: colors.criticality.low,
    fontWeight: 700,
    color: colors.neutral.white,
  },
};

const NmapReportScorecardRow = ({
  label,
  critical,
  high,
  medium,
  low,
}: Props) => {
  return (
    <div styleName="findings-table-heading row">
      <div styleName="finding-section">{label}</div>
      <div styleName="finding-section subsection">
        <div styleName="critical-finding-count-container">{critical}</div>
      </div>
      <div styleName="finding-section subsection">
        <Badge showZero style={styles.high} count={high} />
      </div>
      <div styleName="finding-section subsection">
        <Badge showZero style={styles.medium} count={medium} />
      </div>
      <div styleName="finding-section subsection">
        <Badge showZero style={styles.low} count={low} />
      </div>
    </div>
  );
};

export default NmapReportScorecardRow;
