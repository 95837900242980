import React from 'react';
import { useParams } from 'react-router-dom';

import BasePane from '@totem/components/BasePane';
import CardBody from '@totem/components/CardBody';
import CardHeader from '@totem/components/CardHeader';
import CardHeaderSmaller from '@totem/components/CardHeaderSmaller';
import ContentLayout from '@totem/components/ContentLayout';
import PrimaryContentPane from '@totem/components/PrimaryContentPane';
import RemoteAccessRoleUserContainer from '@totem/components/remoteAccess/roleUsers/byRole/RemoteAccessRoleUsersContainer';
import RoleTitle from '@totem/components/remoteAccess/roleUsers/byRole/RoleTitle';
import HeaderAction from '@totem/components/remoteAccess/roleUsers/rootRole/HeaderAction';
import RemoteAccessUsersTable from '@totem/components/remoteAccess/roleUsers/rootRole/RemoteAccessUserTable';
import SearchEntry from '@totem/components/remoteAccess/roleUsers/SearchEntry';
import { Breadcrumb } from '@totem/types/breadcrumb';
import { emptyId, normalizeId } from '@totem/utilities/userUtilities';

const RemoteAccessRolePage = () => {
  const { id } = useParams();
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const buildingId = params.get('buildingId');
  const controlSystemId = normalizeId(params.get('controlSystemId'));
  const getBreadcrumbs = (breadcrumbsIn: Breadcrumb) => {
    const breadcrumbs = [breadcrumbsIn];

    breadcrumbs.push({
      label: 'Buildings',
      link: '/dashboard/buildings',
    });

    breadcrumbs.push({
      label: 'Building Overview',
      link: `/dashboard/buildings/${buildingId}`,
    });

    if (controlSystemId !== emptyId) {
      breadcrumbs.push({
        label: 'Control Systems',
        link: '/dashboard/controlsystems?buildingId=${buildingId}&limit=10&offset=0',
      });

      breadcrumbs.push({
        label: 'Control System',
        link: `/dashboard/controlsystems/${controlSystemId}`,
      });
    }

    breadcrumbs.push({
      label: 'Remote Access Role',
    });

    return breadcrumbs;
  };

  return (
    <ContentLayout
      breadcrumbs={(breadcrumbs) => getBreadcrumbs(breadcrumbs[0])}
    >
      <RemoteAccessRoleUserContainer roleId={id}>
        <BasePane>
          <PrimaryContentPane>
            <CardHeader>
              <RoleTitle />
              <CardHeaderSmaller>
                <HeaderAction />
              </CardHeaderSmaller>
            </CardHeader>
            <CardBody>
              <SearchEntry />
              <br />
              <RemoteAccessUsersTable />
            </CardBody>
          </PrimaryContentPane>
        </BasePane>
      </RemoteAccessRoleUserContainer>
    </ContentLayout>
  );
};

export default RemoteAccessRolePage;
