import React, { useContext, useEffect, useState } from 'react';
import { Button, Form, Modal } from 'antd';

import AgreementContext from '@totem/components/agreements/AgreementReport/AgreementReportContext';
import { Agreement, Hierarchy } from '@totem/components/agreements/models';
import HierarchySelect from '@totem/components/common/hierarchy/HierarchySelect';
import { EMPTY_ID } from '@totem/components/common/referencesModal/ReferencesModal';
import ModalFooter from '@totem/components/ModalFooter';
import ModalTitle from '@totem/components/ModalTitle';
import { HierarchyValue } from '@totem/types/autoComplete';
import { getToken } from '@totem/utilities/accountUtilities';
import { AGREEMENTS_ENDPOINT } from '@totem/utilities/endpoints';

const styles = {
  form: {
    width: '100%',
  },
  formItem: {
    paddingBottom: '0',
    marginBottom: '1rem',
  },
};

export interface Props {
  visible: boolean;
  agreement: Agreement;
  hierarchy: Hierarchy;
}
const AddEditHierarchyDialog = ({ agreement, hierarchy, visible }: Props) => {
  const { loading, onAction } = useContext(AgreementContext);
  const [scratchHierarchy, setScratchHierarchy] =
    useState<Hierarchy>(hierarchy);

  const [requestType, setRequestType] = useState<string>('');

  useEffect(() => {
    if (requestType === 'putHierarchy') {
      setRequestType('');

      fetch(`${AGREEMENTS_ENDPOINT}/hierarchy/${agreement.id}`, {
        method: 'POST',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
        body: JSON.stringify(scratchHierarchy),
      }).then((res) => {
        if (res.status < 400) {
          onAction('AddEditHierarchyComplete', null, null);
        }
      });
    }
  }, [requestType, scratchHierarchy]);

  const handleSubmit = () => {
    if (
      scratchHierarchy.deviceId !== EMPTY_ID ||
      scratchHierarchy.controlSystemId !== EMPTY_ID ||
      scratchHierarchy.buildingId !== EMPTY_ID
    ) {
      setRequestType('putHierarchy');
    }
  };

  const handleHierarchyUpdated = (newHierarchy: HierarchyValue) => {
    setScratchHierarchy({
      id: EMPTY_ID,
      regionId:
        newHierarchy.region.value !== '' ? newHierarchy.region.value : EMPTY_ID,
      regionName: newHierarchy.region.display,
      buildingId:
        newHierarchy.building.value !== ''
          ? newHierarchy.building.value
          : EMPTY_ID,
      buildingName: newHierarchy.building.display,
      controlSystemId:
        newHierarchy.controlSystem.value !== ''
          ? newHierarchy.controlSystem.value
          : EMPTY_ID,
      controlSystemName: newHierarchy.controlSystem.display,
      deviceId:
        newHierarchy.device.value !== '' ? newHierarchy.device.value : EMPTY_ID,
      deviceName: newHierarchy.device.display,
      createdBy: '',
      createdAt: '2023-01-01T00:00:00.000+00:00',
    });
  };

  return (
    <Modal
      open={visible}
      onCancel={() => onAction('clear', null, null)}
      title={
        <ModalTitle>
          {scratchHierarchy.id === EMPTY_ID
            ? 'Add Item to Agreement'
            : 'Edit Reference'}
        </ModalTitle>
      }
      okText={scratchHierarchy.id === EMPTY_ID ? 'Add' : 'Edit'}
      confirmLoading={loading}
      footer={
        <ModalFooter>
          <Button onClick={() => onAction('clear', null, null)}>Cancel</Button>
          <Button onClick={handleSubmit} loading={loading} type="primary">
            Add
          </Button>
        </ModalFooter>
      }
    >
      <Form layout="vertical" style={styles.form}>
        <HierarchySelect
          showOrganization={false}
          showRegion={false}
          showBuilding
          showControlSystem
          showDevice
          isBuildingAutoComplete={false}
          isControlSystemAutoComplete={false}
          isDeviceAutoComplete
          onHierarchyChanged={handleHierarchyUpdated}
        />
      </Form>
    </Modal>
  );
};

export default AddEditHierarchyDialog;
