import React, { useContext } from 'react';

import DeviceContext from '@totem/components/devices/deviceDetails/DeviceContext';
import DriveCard from '@totem/components/devices/deviceDetails/DriveCard';

const DriveCards = () => {
  const { deviceData } = useContext(DeviceContext);

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      {typeof deviceData !== 'undefined' &&
        deviceData !== null &&
        typeof deviceData.device.drives !== 'undefined' &&
        deviceData.device.drives !== null &&
        deviceData.device.drives.map((drive) => <DriveCard drive={drive} />)}
    </div>
  );
};

export default DriveCards;
