import React, { useEffect, useState } from 'react';
import { Collapse, CollapseProps } from 'antd';

import QuestionDetail from '@totem/components/surveyV2/policies/QuestionDetail';
import Title from '@totem/components/surveyV2/policies/Title';
import { Category, SurveyQuestion } from '@totem/components/surveyV2/types';
import { isNotNull, isNull } from '@totem/utilities/common';

const styles = {
  questionLabel: {
    fontSize: 16,
  },
};

type QuestionGroup = {
  policyName: string;
  questions: SurveyQuestion[];
};

type Props = {
  category: Category;
};

const PolicyCategory = ({ category }: Props) => {
  const [questionGroups, setQuestionGroups] = useState<QuestionGroup[]>([]);
  useEffect(() => {
    const update: QuestionGroup[] = [];

    if (isNotNull(category) && isNotNull(category.questions)) {
      for (let idx = 0; idx < category.questions.length; idx++) {
        const chkQuestion = category.questions[idx];

        if (
          (chkQuestion.type === 'scoring' ||
            chkQuestion.type === 'enumsingle') &&
          chkQuestion.policy !== ''
        ) {
          const poilicyIdx = update.findIndex(
            (chk) => chk.policyName === chkQuestion.policyName,
          );
          // eslint-disable-next-line max-depth
          if (poilicyIdx < 0) {
            update.push({
              policyName: chkQuestion.policyName,
              questions: [chkQuestion],
            });
          } else {
            update[poilicyIdx].questions.push(chkQuestion);
          }
        }
      }
    }

    setQuestionGroups(update);
  }, [category]);

  return (
    <div>
      {questionGroups.map((group) => {
        const items: CollapseProps['items'] = isNull(group)
          ? []
          : group.questions.map((chk) => {
              return {
                key: chk.id,
                label: <span style={styles.questionLabel}>{chk.label}</span>,
                children: <QuestionDetail question={chk} />,
              };
            });

        return (
          <div key={group.policyName}>
            <Title>{group.policyName}</Title>
            <Collapse items={items} />
            <br />
            <br />
          </div>
        );
      })}
    </div>
  );
};

export default PolicyCategory;
