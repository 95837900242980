import * as React from 'react';
import { RightOutlined } from '@ant-design/icons';

import colors from '@totem/styles/colors';

import './score.css';

type Props = {
  id: string;
  label: string;
  type: string;
  indentation: number;
  selectedId: string;
  expandedIds: Set<string>;
  showExpandIcon?: boolean;
  onSelect: (arg1: string, arg2: string) => void;
  onExpand?: (string) => void;
};

const getIndentationStyle = (indentation: number): Object => {
  const indent = 2 * indentation;

  return {
    paddingLeft: `${indent}rem`,
  };
};

const TreeRow = ({
  id,
  label,
  type,
  indentation,
  selectedId,
  expandedIds,
  showExpandIcon,
  onSelect,
  onExpand,
}: Props) => {
  const rotation = expandedIds.has(id) ? 'up' : 'down';
  const isSelected = id === selectedId;
  const ident = getIndentationStyle(indentation);

  return (
    <div styleName={`tree-row-container ${isSelected ? 'selected' : ''}`}>
      <div
        styleName="tree-row-label"
        style={ident}
        onClick={(): void => onSelect(id, type)}
      >
        {label}
      </div>
      {showExpandIcon && (
        <div
          styleName="tree-row-icon-container"
          onClick={(): void => onExpand(id)}
        >
          <RightOutlined
            styleName={rotation}
            style={{
              fontSize: '14px',
              color: colors.neutral.dim,
            }}
          />
        </div>
      )}
    </div>
  );
};

export default TreeRow;
