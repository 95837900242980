import React, { ReactNode, useEffect, useState } from 'react';

import ControlSystemContext from '@totem/components/controlSystemDetail/container/ControlSystemContext';
import { ControlSystemDetails } from '@totem/components/controlSystemDetail/container/types';
import { getToken } from '@totem/utilities/accountUtilities';
import { CONTROL_SYSTEMS_ENDPOINT } from '@totem/utilities/endpoints';

type Props = {
  refresh?: boolean;
  onDataRefreshRequested?: () => void;
  children?: ReactNode;
  id: string;
};

const ControlSystemContainer = ({
  id,
  children,
  refresh,
  onDataRefreshRequested,
}: Props) => {
  const [data, setData] = useState<ControlSystemDetails>(null);
  const [refreshData, setRefreshData] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (
      refresh &&
      typeof onDataRefreshRequested !== 'undefined' &&
      onDataRefreshRequested !== null
    ) {
      setRefreshData(true);
      onDataRefreshRequested();
    }
  }, [refresh, onDataRefreshRequested]);

  useEffect(() => {
    if (refreshData) {
      setRefreshData(false);

      setIsLoading(true);

      fetch(`${CONTROL_SYSTEMS_ENDPOINT}/${id}`, {
        method: 'GET',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
      })
        .then(res => res.json())
        .then((result: ControlSystemDetails) => {
          setData(result);
        })
        .then(() => {
          setIsLoading(false);
        });
    }
  }, [refreshData, refresh]);

  return (
    <ControlSystemContext.Provider
      value={{
        id,
        data,
        isLoading,
        requestRefresh: () => setRefreshData(true),
      }}
    >
      <div>{children}</div>
    </ControlSystemContext.Provider>
  );
};

export default ControlSystemContainer;
