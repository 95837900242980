import React from 'react';

import BasePane from '@totem/components/BasePane';
import ContentLayout from '@totem/components/ContentLayout';
import PrimaryContentPane from '@totem/components/PrimaryContentPane';
import TemplatesContainer from '@totem/components/surveyV2/templates/TemplatesContainer';
import TemplatesTable from '@totem/components/surveyV2/templates/TemplatesTable';
const TemplatesPage = () => {
  return (
    <ContentLayout>
      <BasePane>
        <PrimaryContentPane>
          <TemplatesContainer>
            <TemplatesTable />
          </TemplatesContainer>
        </PrimaryContentPane>
      </BasePane>
    </ContentLayout>
  );
};

export default TemplatesPage;
