import React, { useContext } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Layout } from 'antd';

const { Header, Content } = Layout;

import NotFound from '@totem/components/notFound/NotFound';
import colors from '@totem/styles/colors';
import { getVendorRoutes } from '@totem/utilities/routes';

import OrganizationSwitcherDropdown from './common/organizationSwitcherDropdown/OrganizationSwitcherDropdown';
import ProfileIcon from './common/profileIcon/ProfileIcon';
import ProfileUpdateModal from './settings/sections/profile/ProfileUpdateModal';
import { RouteContextProvider } from './RouteContext';
import UserProfileContext from './UserProfileContext';
import VendorLeftNav from './VendorLeftNav';

import './base.css';

const styles = {
  content: {
    backgroundColor: colors.neutral.white,
    display: 'flex',
    flexDirection: 'column' as any,
  },
  header: {
    background: colors.neutral.white,
    padding: 0,
    display: 'flex',
    flexDirection: 'row' as any,
    justifyContent: 'flex-end',
    alignItems: 'center',
    borderBottom: `2px solid ${colors.neutral.typhoon}`,
  },
};

const VendorBase = () => {
  const { userProfile } = useContext(UserProfileContext);

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <VendorLeftNav />
      <Layout>
        <Header style={styles.header}>
          <OrganizationSwitcherDropdown />
          <ProfileIcon />
        </Header>
        <Content style={styles.content}>
          <Routes>
            {getVendorRoutes(userProfile).map((route) => {
              const enabled =
                typeof route.render === 'function'
                  ? route.render()
                  : route.render ?? true;

              return (
                enabled && (
                  <Route
                    key={route.path}
                    path={route.path}
                    element={
                      <RouteContextProvider value={route}>
                        <route.component />
                      </RouteContextProvider>
                    }
                  />
                )
              );
            })}
            <Route element={<NotFound />} />
          </Routes>
          <ProfileUpdateModal />
        </Content>
      </Layout>
    </Layout>
  );
};

export default VendorBase;
