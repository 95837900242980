import React from 'react';
import { Collapse } from 'antd';

import colors from '@totem/styles/colors';

import './assignedContentCollapse.css';

const { Panel } = Collapse;

const styles = {
  panelContainer: {
    padding: '1.6rem',
    borderBottom: `1px solid ${colors.antd.borderGray}`,
  },
};

interface AssignedContent {
  disabled: boolean;
  header: string;
  subheader?: string;
  details: React.ReactNode;
}

interface Props {
  assignedContents: AssignedContent[];
}

const AssignedContentCollapse = ({ assignedContents }: Props) => {
  return (
    <>
      <div styleName="content-container">
        <Collapse expandIconPosition="right">
          {assignedContents.map((assignedContent, index) => (
            <Panel
              header={assignedContent.header}
              key={index}
              disabled={assignedContent.disabled}
              showArrow={!assignedContent.disabled}
              className="assigned-work-panel"
            >
              <div style={styles.panelContainer}>
                {assignedContent.subheader && (
                  <div>
                    <strong>{assignedContent.subheader}</strong>
                  </div>
                )}
                <div>{assignedContent.details}</div>
              </div>
            </Panel>
          ))}
        </Collapse>
      </div>
    </>
  );
};

export default AssignedContentCollapse;
