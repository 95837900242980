import React from 'react';
import { Avatar } from 'antd';
import dayjs from 'dayjs';
import * as R from 'ramda';

import Tooltip from '@totem/components/common/tooltip/Tooltip';
import { Question } from '@totem/types/policyAudit';

import './policyAuditDetail.css';

type Props = {
  question: Question;
};

interface AvatarProps {
  size: number | 'small' | 'large' | 'default';
  style: any;
  children?: any;
  src?: string;
}

const styles = {
  avatar: {
    marginRight: '1.6rem',
  },
};

const getDate = (date: string): string => {
  return dayjs(date).format('MMM D, YYYY');
};

const getTime = (date: string): string => {
  return dayjs(date).format('h:mm A');
};

const UpdatedBy: React.FC<Props> = ({ question }: Props) => {
  const { answer } = question;

  if (!answer || !answer.updatedBy) {
    return null;
  }

  const { updatedBy } = answer;

  const isDefaultImage = updatedBy.imageUrl.includes('cdn.auth0.com');

  const firstLetter = R.pathOr('', ['name'], updatedBy).split(' ')[0][0];
  const secondLetter = R.pathOr('', ['name'], updatedBy).split(' ')[1][0];

  const name = updatedBy ? `${firstLetter}${secondLetter}` : 'A';

  const avatarProps: AvatarProps = {
    size: 'large',
    style: styles.avatar,
    ...(isDefaultImage && { children: name }),
    ...(!isDefaultImage && { src: updatedBy.imageUrl }),
  };

  return (
    <div>
      <div styleName="updated-by">Updated By</div>
      <div styleName="user">
        <Tooltip title={updatedBy.name} anchored>
          <Avatar {...avatarProps} />
        </Tooltip>
        <div styleName="date">
          {`${getTime(answer.updatedAt)} ${getDate(answer.updatedAt)}`}
        </div>
      </div>
    </div>
  );
};

export default UpdatedBy;
