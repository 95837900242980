import React, { ReactNode } from 'react';

import '../home.css';

type Props = {
  children?: ReactNode;
};

const WidgetContentPane = ({ children }: Props) => {
  return <div styleName="widget-content-pane">{children}</div>;
};

export default WidgetContentPane;
