import React, { useContext } from 'react';

import PointOfContacts from '@totem/components/common/pointOfContact/PointOfContacts';
import { PointOfContact } from '@totem/components/common/pointOfContact/types';
import ControlSystemContext from '@totem/components/controlSystemDetail/container/ControlSystemContext';
import {
  CONFIGURATION_ENDPOINT,
  CONTROL_SYSTEMS_ENDPOINT,
} from '@totem/utilities/endpoints';

const Contacts = () => {
  const { id, data, isLoading, requestRefresh } = useContext(
    ControlSystemContext,
  );

  const handleAction = (action: string, contact: PointOfContact) => {
    if (contact.id !== null) {
      // eslint-disable-next-line default-case
      switch (action) {
        case 'Update':
          requestRefresh();
          break;
        case 'Remove':
          requestRefresh();
          break;
      }
    }
  };

  return (
    <PointOfContacts
      objectType="Control System"
      objectId={id}
      loading={isLoading}
      title="Control System Contacts"
      data={
        typeof data !== 'undefined' &&
        data !== null &&
        data.controlSystem !== null
          ? data.controlSystem.pointsOfContact
          : []
      }
      canEdit
      onAction={handleAction}
      contactsBaseUrl={`${CONTROL_SYSTEMS_ENDPOINT}/contacts`}
      contactTypesUrl={`${CONFIGURATION_ENDPOINT}/controlSystemContactTypes`}
    />
  );
};

export default Contacts;
