import React, { ReactNode } from 'react';

import './cve.css';

type Props = {
  children?: ReactNode;
  contentStyle?: React.CSSProperties;
};
const CveCard = ({ children, contentStyle }: Props) => {
  return (
    <div styleName="cve-card" style={contentStyle}>
      {children}
    </div>
  );
};

export default CveCard;
