import React, { useCallback, useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { Collapse, CollapseProps, Flex, Modal } from 'antd';

import TenantBuildingAddButton from '@totem/components/tenants/tenantsPage/TenantBuildingAddButton';
import TenantsContext from '@totem/components/tenants/tenantsPage/TenantsContext';
import TenantTableBuildingLocations from '@totem/components/tenants/tenantsPage/TenantTableBuildingLocations';
import { Tenant, TenantBuilding } from '@totem/components/tenants/types';
import IBDIcon from '@totem/styles/Icon';
import { getToken } from '@totem/utilities/accountUtilities';
import { TENANTS_ENDPOINT } from '@totem/utilities/endpoints';

type Props = {
  tenant: Tenant;
};

const TenantTableBuildings = ({ tenant }: Props) => {
  const [isSending, setIsSending] = useState<boolean>(false);
  const { onAction } = useContext(TenantsContext);

  const sendBuildingDelete = useCallback(
    async (tenantId: string, building: TenantBuilding) => {
      if (isSending) {
        return;
      }
      setIsSending(true);
      fetch(`${TENANTS_ENDPOINT}/${tenantId}/buildings/${building.id}`, {
        method: 'DELETE',
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getToken()}`,
        }),
      }).then(() => {
        setIsSending(false);
        onAction('refresh', null);
      });
    },
    [isSending],
  );

  const handleDelete = (building: TenantBuilding) => {
    Modal.confirm({
      title: 'Are you sure?',
      content: `Please confirm that you would like to remove the building "${building.name}" from tenant "${tenant.name}"?".`,
      okText: 'Remove',
      okType: 'danger',
      cancelText: 'Cancel',
      open: false,
      onOk: () => sendBuildingDelete(tenant.id, building),
    });
  };

  const getItems = () => {
    const items: CollapseProps['items'] = tenant.buildings.map((building) => {
      return {
        key: building.id,
        label: (
          <Flex justify="space-between">
            <Link to={`/buildings/${building.id}`}>{building.name}</Link>
            <div>
              <IBDIcon
                name={'Delete'}
                title={`Delete ${building.name}`}
                onClick={() => handleDelete(building)}
              />
            </div>
          </Flex>
        ),
        children: (
          <TenantTableBuildingLocations
            tenantId={tenant.id}
            building={building}
          />
        ),
      };
    });
    return items;
  };

  return (
    <>
      <Collapse items={getItems()} />
      <br />
      <TenantBuildingAddButton tenant={tenant} />
    </>
  );
};

export default TenantTableBuildings;
