import React from 'react';

import {
  getSeverityAbbr,
  getSeverityColor,
  getSeverityTitle,
} from '@totem/utilities/events';

import './controlSystemReport.css';

type Props = {
  severity: number;
};
const EventLegendEntry = ({ severity }: Props) => {
  return (
    <span title={getSeverityTitle(severity)}>
      <span style={{ color: getSeverityColor(severity), fontSize: 'larger' }}>
        &#9632;
      </span>
      <span>{getSeverityAbbr(severity)}</span>
    </span>
  );
};
const EventLegend = () => {
  return (
    <span styleName="legendText">
      <EventLegendEntry severity={5} />
      {'  '}
      <EventLegendEntry severity={4} />
      {'  '}
      <EventLegendEntry severity={3} />
      {'  '}
      <EventLegendEntry severity={2} />
      {'  '}
      <EventLegendEntry severity={1} />
      {'  '}
      <EventLegendEntry severity={0} />
    </span>
  );
};

export default EventLegend;
