import React, { ReactNode, useEffect, useState } from 'react';
import { message } from 'antd';

import MergeDialog from '@totem/components/vendors/MergeDialog';
import { Vendor, VendorInput } from '@totem/components/vendors/types';
import VendorContext from '@totem/components/vendors/VendorContext';
import { getToken } from '@totem/utilities/accountUtilities';
import { isNotNull } from '@totem/utilities/common';
import { DEVICES_ENDPOINT } from '@totem/utilities/endpoints';
import { omitNilOrEmpty } from '@totem/utilities/objectUtilities';

type Props = {
  children?: ReactNode;
};
const VendorContainer = ({ children }: Props) => {
  const [messageApi, contextHolder] = message.useMessage();
  const [refreshData, setRefreshData] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [data, setData] = useState<Vendor[]>([]);
  const [rawData, setRawData] = useState<Vendor[]>([]);
  const [action, setAction] = useState<string>('');
  const [actionItem, setActionItem] = useState<Vendor>(null);
  const [selectedVendors, setSelectedVendors] = useState<Vendor[]>([]);
  const [input, updateInput] = useState<VendorInput>({ searchName: '' });

  const filterData = (chkData: Vendor[]) => {
    if (isNotNull(chkData)) {
      let filtered: Vendor[] = [...chkData];
      let clearSelections = false;

      if (isNotNull(input.searchName) && input.searchName !== '') {
        filtered = filtered.filter((chk) =>
          chk.name.toLowerCase().startsWith(input.searchName.toLowerCase()),
        );
        clearSelections = true;
      }

      if (clearSelections) {
        setSelectedVendors([]);
      }

      setData(filtered);
    } else {
      setData([]);
    }
  };

  const setVendorSelected = (vendor: Vendor, selected: boolean) => {
    if (selected) {
      if (selectedVendors.findIndex((chk) => chk.name === vendor.name) <= -1) {
        setSelectedVendors([...selectedVendors, vendor]);
      }
    } else {
      const filtered = selectedVendors.filter(
        (chk) => chk.name !== vendor.name,
      );
      setSelectedVendors(filtered);
    }
  };

  const setInput = (updated: Partial<VendorInput>) => {
    updateInput(omitNilOrEmpty({ ...input, ...updated }));
    //setRefreshData(true);
  };

  useEffect(() => {
    filterData(rawData);
  }, [input]);

  useEffect(() => {
    if (refreshData) {
      setRefreshData(false);
      setIsLoading(true);

      const uri = `${DEVICES_ENDPOINT}/vendors`;

      fetch(uri, {
        method: 'GET',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
      })
        .then((res) => res.json())
        .then((result: Vendor[]) => {
          setRawData(result);
          filterData(result);
        })
        .then(() => {
          setIsLoading(false);
        });
    }
  }, [refreshData]);

  const handleAction = (newAction: string, item: Vendor) => {
    switch (newAction) {
      case 'merge':
        if (selectedVendors.length > 1) {
          setAction(newAction);
          setActionItem(item);
        } else {
          messageApi.error('Select two or more vendors before merging!');
        }
        break;
      case 'clear_vendor_selections':
        setSelectedVendors([]);
        break;
      case 'refresh':
        setRefreshData(true);
        setAction('');
        setActionItem(null);
        break;
      default:
        setAction(newAction);
        setActionItem(item);
    }
  };

  return (
    <VendorContext.Provider
      value={{
        loading: isLoading,
        data,
        rawData,
        action,
        actionItem,
        onAction: handleAction,
        input,
        setInput,
        selectedVendors,
        setSelectedVendor: setVendorSelected,
      }}
    >
      {contextHolder}
      <div>{children}</div>
      {action === 'merge' && <MergeDialog />}
    </VendorContext.Provider>
  );
};

export default VendorContainer;
