import React, { ReactNode, useEffect, useState } from 'react';

import { EMPTY_ID } from '@totem/components/common/reference/ReferenceSelector';
import RemoteAccessActionHandler from '@totem/components/remoteAccess/roleUsers/RemoteAccessActionHandler';
import RemoteAccessUserContext from '@totem/components/remoteAccess/roleUsers/RemoteAccessUserContext';
import { getFlagStatus } from '@totem/components/remoteAccess/roleUsers/rootRole/utilities';
import {
  RemoteAccessOrgRole,
  RemoteAccessRoleUsers,
  RemoteAccessUser,
  RemoteAccessUserAction,
  RemoteAccessUsersConnectionInput,
} from '@totem/components/remoteAccess/types';
import { sortRemoteAccess } from '@totem/components/remoteAccess/utilities';
import { RemoteAccessMaxAccountInactivity } from '@totem/types/remoteAccess';
import { getToken } from '@totem/utilities/accountUtilities';
import { isNotNull } from '@totem/utilities/common';
import {
  REMOTE_ACCESS_PROVISIONING_MAX_ACCOUNT_INACTIVITY,
  REMOTE_ACCESS_USERS_ENDPOINT,
} from '@totem/utilities/endpoints';
import { omitNilOrEmpty } from '@totem/utilities/objectUtilities';

import '../../remoteAccess.css';

type Props = {
  refresh?: boolean;
  onRecordTotalChanged?: (total: number) => void;
  onDataRefreshRequested?: () => void;
  actions?: RemoteAccessUserAction[];
  children?: ReactNode;
};

const RemoteAccessUserContainer = ({
  refresh,
  onRecordTotalChanged,
  onDataRefreshRequested,
  actions,
  children,
}: Props) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const paramFlag = urlParams.get('flags');

  const [input, updateInput] = useState<RemoteAccessUsersConnectionInput>({
    from: '',
    pageSize: 10,
    page: 1,
    sortField: 'email',
    sortDirection: '1',
    emailSearchTerm: '',
    nameSearchTerm: '',
    group: [],
    flags: isNotNull(paramFlag) && paramFlag !== '' ? [paramFlag] : [],
  });
  const [refreshData, setRefreshData] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [isSending, setIsSending] = useState(false);
  const [data, setData] = useState<RemoteAccessRoleUsers>(null);
  const [roleData, setRoleData] = useState<RemoteAccessOrgRole>(null);
  const [filteredData, setFilteredData] = useState<RemoteAccessUser[]>([]);
  const [maxInactivityDays, setMaxInactivityDays] = useState<number>(0);
  const [selectedUser, setSelectedUser] = useState<RemoteAccessUser>(null);
  const [selectedAction, setSelectedAction] = useState<string>('');

  const filterRemoteAccessUsers = (user: RemoteAccessUser) => {
    if (
      typeof input.nameSearchTerm !== 'undefined' &&
      input.nameSearchTerm !== null &&
      input.nameSearchTerm !== ''
    ) {
      if (user.name.indexOf(input.nameSearchTerm) < 0) {
        return false;
      }
    }
    if (
      typeof input.emailSearchTerm !== 'undefined' &&
      input.emailSearchTerm !== null &&
      input.emailSearchTerm !== ''
    ) {
      if (user.email.indexOf(input.emailSearchTerm) < 0) {
        return false;
      }
    }
    if (
      typeof input.flags !== 'undefined' &&
      input.flags !== null &&
      input.flags.length > 0
    ) {
      if (!input.flags.includes(getFlagStatus(user))) {
        return false;
      }
    }
    return true;
  };

  const getFilteredData = (dataSet: RemoteAccessRoleUsers) => {
    if (
      typeof dataSet !== 'undefined' &&
      dataSet !== null &&
      typeof dataSet.users !== 'undefined' &&
      dataSet.users !== null
    ) {
      return dataSet.users
        .filter(filterRemoteAccessUsers)
        .sort((compA, compB) =>
          sortRemoteAccess(input.sortField, input.sortDirection, compA, compB),
        );
    }
    return [];
  };

  const setInput = (updated: Partial<RemoteAccessUsersConnectionInput>) => {
    updateInput(omitNilOrEmpty({ ...input, ...updated }));
    setRefreshData(true);
  };

  useEffect(() => {
    const newFilteredData = getFilteredData(data);
    setFilteredData(newFilteredData);
    setTotalRecords(newFilteredData.length);
  }, [input, data]);

  useEffect(() => {
    if (
      typeof onRecordTotalChanged !== 'undefined' &&
      onRecordTotalChanged !== null
    ) {
      onRecordTotalChanged(totalRecords);
    }
  }, [onRecordTotalChanged, totalRecords]);

  useEffect(() => {
    if (isSending) {
      return;
    }
    const endpoint = `${REMOTE_ACCESS_PROVISIONING_MAX_ACCOUNT_INACTIVITY}`;

    setIsSending(true);
    fetch(`${endpoint}`, {
      method: 'GET',
      headers: new Headers({
        Authorization: `Bearer ${getToken()}`,
      }),
    })
      .then((res) => res.json())
      .then((result: RemoteAccessMaxAccountInactivity) => {
        setMaxInactivityDays(result.maxInactivityDays);
      })
      .then(() => {
        setIsSending(false);
      });
  }, []);

  useEffect(() => {
    if (
      refresh &&
      typeof onDataRefreshRequested !== 'undefined' &&
      onDataRefreshRequested !== null
    ) {
      setRefreshData(true);
      onDataRefreshRequested();
    }
  }, [refresh, onDataRefreshRequested]);

  useEffect(() => {
    if (refreshData) {
      setRefreshData(false);
      if (isSending) {
        return;
      }
      setIsSending(true);
      setIsLoading(true);

      const endpoint = REMOTE_ACCESS_USERS_ENDPOINT;

      fetch(`${endpoint}`, {
        method: 'GET',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
      })
        .then((res) => res.json())
        .then((result: RemoteAccessRoleUsers[]) => {
          if (result.length > 0) {
            setData(result[0]);
            const newFilteredData = getFilteredData(result[0]);
            setFilteredData(newFilteredData);
            setTotalRecords(newFilteredData.length);
            setRoleData({
              roleId: result[0].orgRoleId,
              name: result[0].orgRole,
              description: 'Primary Access Group',
              regionId: EMPTY_ID,
              buildingId: EMPTY_ID,
              controlSystemId: EMPTY_ID,
            });
          }
        })
        .then(() => {
          setIsLoading(false);
          setIsSending(false);
        });
    }
  }, [refreshData]);

  const handleAction = (action: string, user: RemoteAccessUser) => {
    let actionHandled = false;
    if (typeof actions !== 'undefined' && actions !== null) {
      for (let idx = 0; idx < actions.length; idx++) {
        if (actions[idx].name === action) {
          // eslint-disable-next-line max-depth
          if (actions[idx].replaceDefault === true) {
            actionHandled = true;
            actions[idx].action(action, user);
          }
        }
      }
    }
    if (!actionHandled) {
      setSelectedAction(action);
      setSelectedUser(user);
    }
  };

  const handleActionComplete = (doRefresh: boolean) => {
    setSelectedAction('');
    setSelectedUser(null);
    if (doRefresh) {
      setRefreshData(true);
    }
  };

  return (
    <RemoteAccessUserContext.Provider
      value={{
        input,
        setInput,
        loading: isLoading,
        data,
        roleData,
        filteredData,
        totalRecords,
        onAction: handleAction,
        maxInactivityDays,
      }}
    >
      <div>{children}</div>
      {selectedAction !== null && selectedAction !== '' && (
        <RemoteAccessActionHandler
          action={selectedAction}
          selectedRoleId={
            typeof data !== 'undefined' && data !== null ? data.orgRoleId : ''
          }
          selectedUser={selectedUser}
          onActionComplete={handleActionComplete}
        />
      )}
    </RemoteAccessUserContext.Provider>
  );
};

export default RemoteAccessUserContainer;
