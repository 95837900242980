import React, { useContext } from 'react';

import FilterChip from '@totem/components/common/FilterChip';
import ContractContext from '@totem/components/contracts/details/ContractContext';
import { ContractInput } from '@totem/components/contracts/details/types';

const styles = {
  filterChip: {
    marginLeft: '2rem',
    marginTop: '2rem',
  },
};

const ContractFilterChips = () => {
  const { input, setInput, buildings } = useContext(ContractContext);

  const handleMultiFilterDelete = (
    property: keyof ContractInput,
    value: string,
  ) => {
    // @ts-ignore
    const updatedValue = input[property].filter((val) => val !== value);
    setInput({
      ...input,
      [property]: updatedValue.length ? updatedValue : null,
    });
  };

  const getBuildingNameFromId = (buildingId) => {
    if (typeof buildings !== 'undefined' && buildings !== null) {
      for (let idx = 0; idx < buildings.length; idx++) {
        if (buildings[idx].value === buildingId) {
          return buildings[idx].label;
        }
      }
    }
    return '*****';
  };

  return (
    <>
      {input.status &&
        input.status.map((status) => {
          return (
            <FilterChip
              key={status}
              label={`Status: ${status}`}
              style={styles.filterChip}
              onClose={() => handleMultiFilterDelete('status', status)}
            />
          );
        })}
      {input.buildingId &&
        input.buildingId.map((bid) => {
          return (
            <FilterChip
              key={status}
              label={`Building: ${getBuildingNameFromId(bid)}`}
              style={styles.filterChip}
              onClose={() => handleMultiFilterDelete('buildingId', bid)}
            />
          );
        })}
      {input.service &&
        input.service.map((service) => {
          return (
            <FilterChip
              key={status}
              label={`Service: ${service}`}
              style={styles.filterChip}
              onClose={() => handleMultiFilterDelete('service', service)}
            />
          );
        })}
    </>
  );
};

export default ContractFilterChips;
