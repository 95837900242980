import React, { ReactNode, useEffect, useState } from 'react';

import AgreementContext from '@totem/components//agreements/AgreementReport/AgreementReportContext';
import AddEditHierarchyDialog from '@totem/components/agreements/AgreementReport/AddEditHierarchyDialog';
import {
  Agreement,
  AgreementQueryResults,
  AgreementReportFilters,
  AgreementReportInput,
  Hierarchy,
} from '@totem/components/agreements/models';
import { getEmptyHierarcy } from '@totem/components/agreements/utilities';
import { ReportInput } from '@totem/components/controlSystemReport/parameterTypes';
import {
  addReportFilters,
  buildReportFilters,
} from '@totem/components/controlSystemReport/utilities';
import { Params } from '@totem/types/common';
import { getToken } from '@totem/utilities/accountUtilities';
import { AGREEMENTS_ENDPOINT } from '@totem/utilities/endpoints';
import { omitNilOrEmpty } from '@totem/utilities/objectUtilities';

type Props = {
  refresh?: boolean;
  children?: ReactNode;
  defaultFilters?: AgreementReportFilters;
  staticFilters?: AgreementReportFilters;
};

const AgreementContainer = ({
  children,
  refresh,
  defaultFilters,
  staticFilters,
}: Props) => {
  const [reportData, setReportData] = useState<Agreement[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [refreshData, setRefreshData] = useState<boolean>(true);
  const [input, updateInput] = useState<AgreementReportInput>({
    pageSize: 10,
    page: 1,
    sortField: 'building.name',
    sortDirection: '1',
    contractNumber:
      typeof defaultFilters !== 'undefined' &&
      defaultFilters !== null &&
      typeof defaultFilters.contractNumber !== 'undefined' &&
      defaultFilters.contractNumber !== null
        ? defaultFilters.contractNumber
        : [],
    vendor:
      typeof defaultFilters !== 'undefined' &&
      defaultFilters !== null &&
      typeof defaultFilters.vendor !== 'undefined' &&
      defaultFilters.vendor !== null
        ? defaultFilters.vendor
        : [],
  });
  const [selectedAction, setSelectedAction] = useState<string>('');
  const [selectedAgreement, setSelectedAgreement] = useState<Agreement>(null);
  const [selectedHierarchy, setSelectedHierarchy] = useState<Hierarchy>(null);

  const setInput = (updated: Partial<ReportInput>) => {
    updateInput(omitNilOrEmpty({ ...input, ...updated }));
    setRefreshData(true);
  };

  const handleAction = (
    action: string,
    agreement: Agreement,
    hierarchy: Hierarchy,
  ) => {
    switch (action) {
      case 'addHierarchy':
        setSelectedAction(action);
        setSelectedAgreement(agreement);
        setSelectedHierarchy(getEmptyHierarcy());
        break;
      case 'editHierarchy':
        setSelectedAction(action);
        setSelectedAgreement(agreement);
        setSelectedHierarchy(hierarchy);
        break;
      case 'removeHierarchy':
        setSelectedAction(action);
        setSelectedAgreement(agreement);
        setSelectedHierarchy(hierarchy);
        break;
      case 'AddEditHierarchyComplete':
        setSelectedAction('');
        setSelectedAgreement(agreement);
        setSelectedHierarchy(getEmptyHierarcy());
        setRefreshData(true);
      default:
        setSelectedAction('');
        setSelectedAgreement(null);
        setSelectedHierarchy(null);
    }
  };

  const buildParameters = () => {
    const params: Params = {
      paging: {
        page: input.page,
        pageSize: input.pageSize,
      },
      sort: {
        field: input.sortField,
        direction: +input.sortDirection,
      },
      filters: buildReportFilters(input),
    };

    params.filters = addReportFilters(params.filters, staticFilters);

    return params;
  };

  useEffect(() => {
    if (selectedAction === 'removeHierarchy') {
      setSelectedAction('');

      const url = `${AGREEMENTS_ENDPOINT}/hierarchy/${selectedAgreement.id}/${selectedHierarchy.id}`;

      setSelectedAgreement(null);
      setSelectedHierarchy(null);

      fetch(url, {
        method: 'DELETE',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
      }).then(res => {
        if (res.status < 400) {
          setRefreshData(true);
        }
      });
    }
  }, [selectedAction, selectedAgreement, selectedHierarchy]);

  useEffect(() => {
    if (refreshData) {
      setRefreshData(false);

      setIsLoading(true);
      const params: Params = buildParameters();

      fetch(`${AGREEMENTS_ENDPOINT}`, {
        method: 'POST',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
        body: JSON.stringify(params),
      })
        .then(res => res.json())
        .then((result: AgreementQueryResults) => {
          setReportData(result.agreements);
          setTotalRecords(result.paging.totalRecords);
        })
        .then(() => {
          setIsLoading(false);
        });
    }
  }, [refreshData, refresh]);

  return (
    <AgreementContext.Provider
      value={{
        loading: isLoading,
        reportData,
        totalRecords,
        input,
        setInput,
        onAction: handleAction,
      }}
    >
      <div>{children}</div>
      {(selectedAction === 'addHierarchy' ||
        selectedAction === 'editHierarchy') && (
        <AddEditHierarchyDialog
          visible={
            selectedAction === 'addHierarchy' ||
            selectedAction === 'editHierarchy'
          }
          agreement={selectedAgreement}
          hierarchy={selectedHierarchy}
        />
      )}
    </AgreementContext.Provider>
  );
};

export default AgreementContainer;
