import React from 'react';
import { Flex } from 'antd';

import Count from '@totem/components/common/Count';
import { NameValuePair } from '@totem/components/surveyV2/findingsReport/types';
import colors from '@totem/styles/colors';
import { sortNumberDescending } from '@totem/utilities/tableUtilities';

import './findingsReport.css';

const styles = {
  headerIcon: {
    fontSize: '22px',
  },
  leastCompliantCount: {
    background: colors.neutral.ash,
  },
  fullWidth: {
    width: '100%',
    marginBottom: '5px',
    paddingLeft: '20px',
    paddingRight: '20px',
  },
  ninetyPercent: {
    width: '90%',
  },
};

type Props = {
  data: NameValuePair[];
};

const TopFiveList = ({ data }: Props) => {
  const getReportValues = () => {
    return (
      <div style={styles.fullWidth}>
        {data
          .sort((compA, compB) =>
            sortNumberDescending(compA.value, compB.value),
          )
          .slice(0, 4)
          .map((entity, index) => {
            return (
              <Flex
                justify={'space-between'}
                align={'flex-start'}
                key={index}
                style={styles.fullWidth}
              >
                <div
                  style={styles.ninetyPercent}
                  styleName="least-compliant-entity"
                >
                  {entity.name}
                </div>
                <Count
                  size="small"
                  count={entity.value}
                  style={styles.leastCompliantCount}
                />
              </Flex>
            );
          })}
      </div>
    );
  };

  return getReportValues();
};

export default TopFiveList;
