import pace from '../node_modules/pace-progress/pace.min';

import rootSaga from './sagas/rootSaga';
import { configureStore } from './store/configureStore';
import { instantiateFullStory } from './utilities/fullstoryUtilities';

//import '../node_modules/antd/dist/antd.min.css';
// To load styles globally without CSS modules, use the !style!css!{{path}} format.
// https://github.com/css-modules/css-modules/pull/65#issuecomment-248280248
// import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/font-awesome/css/font-awesome.min.css';
import '../node_modules/animate.css/animate.min.css';
import '../node_modules/dashboard-styles/dist/dashboard.min.css';
import './styles/iconfont/material-icons.min.css';

import 'babel-polyfill';
import './images/favicon.ico';
import './images/favicon-16x16.png';
import './images/favicon-32x32.png';
import './images/mstile-150x150.png';
import './images/android-chrome-192x192.png';
import './images/android-chrome-256x256.png';
import './images/apple-touch-icon.png';
import './images/safari-pinned-tab.svg';
import './images/site.webmanifest';
import './images/logo.svg';
import './images/beaconCapitalPartners.png';

pace.start();

// @ts-ignore
const store = configureStore();
// @ts-ignore
store.runSaga(rootSaga);

import configuration from '@totem/utilities/configuration';

import client from './graph/client';
import Root from './Root';

import './styles/iconfont/styles.override.css';

if (configuration.environment === configuration.constants.LOCAL) {
  document.title = `LOCAL - ${document.title}`;
} else if (
  configuration.environment !== configuration.constants.PRODUCTION &&
  configuration.environment !== configuration.constants.PRODUCTION_NEW
) {
  document.title = `DEVELOPMENT - ${document.title}`;
}

// note that full story can also be enabled/disabled by domain in the web UI
if (configuration.flags.useFullStory) {
  instantiateFullStory();
}

Root.initializeApplication(store, client);
