import React, { useContext, useEffect, useState } from 'react';

import BuildingSummaryContext from '@totem/components/buildingSummary/buildingSummaryContext';
import buildingMarker from '@totem/components/common/svgIcons/buildingMarker.svg';

const styles = {
  icon: {
    fontSize: '1rem',
    marginLeft: '0.5rem',
    marginBottom: '1px',
  },
  markerIcon: {
    url: buildingMarker,
    anchor: {
      x: 24,
      y: 24,
    },
  },
  zoom: 2,
};

import { BuildingLocation } from '@totem/components/buildingSummary/types';
import Map from '@totem/components/common/map/Map';
import { isNotNull } from '@totem/utilities/common';
const BuildingsMap = () => {
  const { reportData } = useContext(BuildingSummaryContext);
  const [buildings, setBuildings] = useState<BuildingLocation[]>([]);

  useEffect(() => {
    if (isNotNull(reportData)) {
      const buildingArray: BuildingLocation[] = [];
      if (isNotNull(reportData.locations)) {
        for (let idx = 0; idx < reportData.locations.length; idx++) {
          buildingArray.push(reportData.locations[idx]);
        }
        setBuildings(buildingArray);
      }
    }
  }, [reportData]);

  const getBuildingMarkers = () => {
    if (isNotNull(buildings)) {
      return buildings
        .filter(({ location }) => location.lat !== 0 || location.lng !== 0)
        .map(({ id, location }) => {
          return {
            location,
            key: id,
            icon: styles.markerIcon,
            size: {
              x: 24,
              y: 24,
            },
            onClick() {},
          };
        });
    }
    return [];
  };

  const getMapCenter = () => {
    if (isNotNull(buildings)) {
      const [building] = buildings.filter(
        ({ location }) => location.lat !== 0 || location.lng !== 0,
      );

      return building ? building.location : null;
    }
    return null;
  };

  return (
    <Map
      markers={getBuildingMarkers()}
      zoom={styles.zoom}
      center={getMapCenter()}
    />
  );
};

export default BuildingsMap;
