import React from 'react';

import DeviceOrgTable from '@totem/components/devices/devicesContainer/DeviceOrgTable';
import DeviceSearch from '@totem/components/devices/devicesContainer/DeviceSearch';

const DetailsTabLayout = () => {
  return (
    <>
      <DeviceSearch />
      <br />
      <DeviceOrgTable />
    </>
  );
};

export default DetailsTabLayout;
