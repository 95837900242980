/* eslint-disable camelcase */

const COLOR_BRANDING_DARK_BLUE = '#122048';
const COLOR_BRANDING_LIGHT_BLUE = '#42b9ea';
const COLOR_BRANDING_ORANGE = '#f04b24';

const COLOR_EVENT_CRITICAL = '#f44336';
const COLOR_EVENT_MAJOR = '#ed7d31';
const COLOR_EVENT_MINOR = '#d79d80';
const COLOR_EVENT_WARNING = '#ffc600';
const COLOR_EVENT_INFO = '#088fa3';
const COLOR_EVENT_OK = '#3fa142';
const COLOR_EVENT_UNKNOWN = '#606060';

export default {
  branding: {
    primaryLight: COLOR_BRANDING_LIGHT_BLUE,
    primaryDark: COLOR_BRANDING_DARK_BLUE,
    accent: COLOR_BRANDING_ORANGE,
  },
  event: {
    criticality: {
      background: {
        critical: COLOR_EVENT_CRITICAL,
        major: COLOR_EVENT_MAJOR,
        minor: COLOR_EVENT_MINOR,
        warning: COLOR_EVENT_WARNING,
        info: COLOR_EVENT_INFO,
        ok: COLOR_EVENT_OK,
        unknown: COLOR_EVENT_UNKNOWN,
      },
      foreground: {
        critical: '#fff',
        major: '#fff',
        minor: '#fff',
        warning: '#fff',
        info: '#fff',
        ok: '#fff',
        unknown: '#fff',
      },
    },
  },
  badges: {
    information: {
      background: '#088fa3',
      foreground: '#fff',
    },
    tableTotals: {
      background: COLOR_BRANDING_ORANGE,
      foreground: '#fff',
    },
  },
  backupStatusDetailed: {
    inProcess: COLOR_EVENT_INFO,
    failed: COLOR_EVENT_CRITICAL,
    aborted: COLOR_EVENT_MAJOR,
    completed: COLOR_EVENT_OK,
    interupted: COLOR_EVENT_MINOR,
    notStarted: COLOR_EVENT_INFO,
    completedWithErrors: COLOR_EVENT_WARNING,
    inProgressWithFaults: COLOR_EVENT_WARNING,
    overQuota: COLOR_EVENT_CRITICAL,
    noSelection: COLOR_EVENT_WARNING,
    restarted: COLOR_EVENT_INFO,
  },
  graphs: [
    '#ceb6bf',
    '#5188bb',
    '#0d629a',
    '#6eaec6',
    '#66414d',
    '#5a6c70',
    '#091833',
    '#6b7997',
    '#1f476d',
    '#aa798a',
    '#c9714a',
    '#d79d80',
    '#372940',
    '#284953',
    '#0d3157',
    '#103645',
    '#0f2195',

//    '#708090',
//    '#5F9EA0',
//    '#4682B4',

  ],
  brand: {
    red: '#ED3620',
    blue: '#3498DB',
    turquoise: '#00A89A',
    orange: '#FF8F00',
    green: '#3FA142',
    purple: '#9209AE',
    violet: '#3E4EB8',
    navy: '#0048C7',
  },
  antd: {
    blue: '#1890FF',
    lightBlue: '#40A9FF',
    borderGray: '#D9D9D9',
  },
  neutral: {
    white: '#FFF',
    mist: '#FAFAFA',
    fog: '#F5F5F5',
    typhoon: '#EEE',
    ash: '#DDD',
    silver: '#CCC',
    gray: '#999',
    dim: '#555',
    dark: '#333',
    black: '#000',
    lightGray: '#F3F3F3',
    inactive: '#BBB',
    antd: 'rgba(240, 242, 245)',
  },
  gradient: {
    turquoiseDark: '#3AAEA0',
    turquoiseLight: '#72E0D1',
    turquoise: 'linear-gradient(90deg, #3AAEA0, #72E0D1)',
    primary: 'linear-gradient(90deg, #122048, #777F96)',
    accent: 'linear-gradient(90deg, #f04b24, #F69881)',
    blueDark: '#309CD9',
    blueLight: '#5ECBEE',
    blue: 'linear-gradient(90deg, #309CD9, #5ECBEE)',
  },
  opacity: {
    black0_8: 'rgba(0, 0, 0, 0.8)',
    black0_6: 'rgba(0, 0, 0, 0.6)',
    black0_4: 'rgba(0, 0, 0, 0.4)',
    black0_2: 'rgba(0, 0, 0, 0.2)',
    white0_8: 'rgba(255, 255, 255, 0.8)',
    white0_6: 'rgba(255, 255, 255, 0.6)',
    white0_4: 'rgba(255, 255, 255, 0.4)',
    white0_2: 'rgba(255, 255, 255, 0.2)',
  },
  utility: {
    highlight: 'rgba(0,0,0,0.05)',
    link: '#0283EA',
    positive: '#3FA142',
    neutral: '#757575',
    negative: '#ED3620',
    boxShadow: 'rgba(0,0,0,0.3)',
    error: '#FF4D4F',
    warning: '#FFC600',
  },
  findings: {
    info: '#CCC',
    low: '#FF8F00',
    medium: '#FC6C1A',
    high: '#F44336',
    very_high: '#FF0041',
    critical: '#970000',
    extremely_critical: '#9A061D',
  },
  criticality: {
    info: '#5B5B5B',
    low: '#FFC600',
    medium: '#FC6C1A',
    high: '#F44336',
    critical: '#970000',
    positive: '#3FA142',
    compliant: '#1890FF',
  },
  finding: '#7F150A',
  health: {
    needed: '#D9D9D9',
    offTrack: '#FF4D4F',
    atRisk: '#FAAD14',
    onTrack: '#3FA142',
  },
  apiKeyStatus: {
    unused: '#FFC600',
    connected: '#3FA142',
    error: '#F44336',
    disconnected: '#555',
  },
};
