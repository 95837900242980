export default {
  UPDATE_BREADCRUMB: 'UPDATE_BREADCRUMB',

  REQUEST_VERSIONS: 'REQUEST_VERSIONS',
  GET_VERSIONS_SUCCESS: 'GET_VERSIONS_SUCCESS',
  GET_VERSIONS_FAILURE: 'GET_VERSIONS_FAILURE',

  REQUEST_RESEND_EMAIL_VERIFICATION: 'REQUEST_RESEND_EMAIL_VERIFICATION',
  RESEND_EMAIL_VERIFICATION_SUCCESS: 'RESEND_EMAIL_VERIFICATION_SUCCESS',
  RESEND_EMAIL_VERIFICATION_FAILURE: 'RESEND_EMAIL_VERIFICATION_FAILURE',

  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  REQUEST_LOGOUT: 'REQUEST_LOGOUT',

  REQUEST_LOGIN: 'REQUEST_LOGIN',
  LOGIN_FAILURE: 'LOGIN_FAILURE',
  TRIGGER_LOGOUT: 'TRIGGER_LOGOUT',

  REQUEST_UPLOAD_FILES: 'REQUEST_UPLOAD_FILES',
  UPLOAD_FILES_SUCCESS: 'UPLOAD_FILES_SUCCESS',
  UPLOAD_FILES_FAILURE: 'UPLOAD_FILES_FAILURE',

  REQUEST_MEDIA: 'REQUEST_MEDIA',
  GET_MEDIA_SUCCESS: 'GET_MEDIA_SUCCESS',
  GET_MEDIA_FAILURE: 'GET_MEDIA_FAILURE',

  REQUEST_UPLOAD_ADDITIONAL_DEVICE_CONFIGURATION:
    'REQUEST_UPLOAD_ADDITIONAL_DEVICE_CONFIGURATION',
  UPLOAD_ADDITIONAL_DEVICE_CONFIGURATION_SUCCESS:
    'UPLOAD_ADDITIONAL_DEVICE_CONFIGURATION_SUCCESS',
  UPLOAD_ADDITIONAL_DEVICE_CONFIGURATION_FAILURE:
    'UPLOAD_ADDITIONAL_DEVICE_CONFIGURATION_FAILURE',

  REQUEST_REGIONS: 'REQUEST_REGIONS',
  REGIONS_GET_SUCCESS: 'REGIONS_GET_SUCCESS',
  REGIONS_GET_FAILURE: 'REGIONS_GET_FAILURE',
  REGIONS_TOTAL_GET_SUCCESS: 'REGIONS_TOTAL_GET_SUCCESS',

  REQUEST_CREATE_REGION: 'REQUEST_CREATE_REGION',
  CREATE_REGION_SUCCESS: 'CREATE_REGION_SUCCESS',
  CREATE_REGION_FAILURE: 'CREATE_REGION_FAILURE',

  REQUEST_REGION_UPDATE: 'REQUEST_REGION_UPDATE',
  REGION_UPDATE_SUCCESS: 'REGION_UPDATE_SUCCESS',
  REGION_UPDATE_FAILURE: 'REGION_UPDATE_FAILURE',

  REQUEST_BUILDINGS: 'REQUEST_BUILDINGS',
  BUILDINGS_GET_SUCCESS: 'BUILDINGS_GET_SUCCESS',
  BUILDINGS_GET_FAILURE: 'BUILDINGS_GET_FAILURE',
  BUILDINGS_TOTAL_GET_SUCCESS: 'BUILDINGS_TOTAL_GET_SUCCESS',

  REQUEST_BUILDING_UPDATE: 'REQUEST_BUILDING_UPDATE',
  BUILDING_UPDATE_SUCCESS: 'BUILDING_UPDATE_SUCCESS',
  BUILDING_UPDATE_FAILURE: 'BUILDING_UPDATE_FAILURE',

  REQUEST_BUILDING_DELETE: 'REQUEST_BUILDING_DELETE',
  BUILDING_DELETE_SUCCESS: 'BUILDING_DELETE_SUCCESS',
  BUILDING_DELETE_FAILURE: 'BUILDING_DELETE_FAILURE',

  DISMISS_NOTIFICATION: 'DISMISS_NOTIFICATION',
  CREATE_NOTIFICATION: 'CREATE_NOTIFICATION',

  REQUEST_APPLICATION_CONFIGURATION: 'REQUEST_APPLICATION_CONFIGURATION',
  GET_CONFIGURATION_SUCCESS: 'GET_CONFIGURATION_SUCCESS',
  GET_CONFIGURATION_FAILURE: 'GET_CONFIGURATION_FAILURE',

  REQUEST_QUESTIONNAIRE_TEMPLATES: 'REQUEST_QUESTIONNAIRE_TEMPLATES',
  REQUEST_QUESTIONNAIRE_TEMPLATES_SUCCESS:
    'REQUEST_QUESTIONNAIRE_TEMPLATES_SUCCESS',
  REQUEST_QUESTIONNAIRE_TEMPLATES_FAILURE:
    'REQUEST_QUESTIONNAIRE_TEMPLATES_FAILURE',

  REQUEST_QUESTIONNAIRE_TEMPLATE_CONFIGURATION:
    'REQUEST_QUESTIONNAIRE_TEMPLATE_CONFIGURATION',
  QUESTIONNAIRE_TEMPLATE_CONFIGURATION_SUCCESS:
    'QUESTIONNAIRE_TEMPLATE_CONFIGURATION_SUCCESS',
  QUESTIONNAIRE_TEMPLATE_CONFIGURATION_FAILURE:
    'QUESTIONNAIRE_TEMPLATE_CONFIGURATION_FAILURE',

  REQUEST_CREATE_QUESTIONNAIRE_TEMPLATE:
    'REQUEST_CREATE_QUESTIONNAIRE_TEMPLATE',
  CREATE_QUESTIONNAIRE_TEMPLATE_SUCCESS:
    'CREATE_QUESTIONNAIRE_TEMPLATE_SUCCESS',
  CREATE_QUESTIONNAIRE_TEMPLATE_FAILURE:
    'CREATE_QUESTIONNAIRE_TEMPLATE_FAILURE',

  REQUEST_QUESTIONNAIRE_TEMPLATE_DETAIL:
    'REQUEST_QUESTIONNAIRE_TEMPLATE_DETAIL',
  QUESTIONNAIRE_TEMPLATE_DETAIL_SUCCESS:
    'QUESTIONNAIRE_TEMPLATE_DETAIL_SUCCESS',
  QUESTIONNAIRE_TEMPLATE_DETAIL_FAILURE:
    'QUESTIONNAIRE_TEMPLATE_DETAIL_FAILURE',

  REQUEST_QUESTIONNAIRE_TEMPLATE_UPDATE:
    'REQUEST_QUESTIONNAIRE_TEMPLATE_UPDATE',
  QUESTIONNAIRE_TEMPLATE_UPDATE_SUCCESS:
    'QUESTIONNAIRE_TEMPLATE_UPDATE_SUCCESS',
  QUESTIONNAIRE_TEMPLATE_UPDATE_FAILURE:
    'QUESTIONNAIRE_TEMPLATE_UPDATE_FAILURE',

  REQUEST_QUESTIONNAIRE_TEMPLATE_DELETE:
    'REQUEST_QUESTIONNAIRE_TEMPLATE_DELETE',
  QUESTIONNAIRE_TEMPLATE_DELETE_SUCCESS:
    'QUESTIONNAIRE_TEMPLATE_DELETE_SUCCESS',
  QUESTIONNAIRE_TEMPLATE_DELETE_FAILURE:
    'QUESTIONNAIRE_TEMPLATE_DELETE_FAILURE',

  REQUEST_QUESTIONNAIRE_INSTANCES: 'REQUEST_QUESTIONNAIRE_INSTANCES',
  GET_QUESTIONNAIRE_INSTANCES_SUCCESS: 'GET_QUESTIONNAIRE_INSTANCES_SUCCESS',
  GET_QUESTIONNAIRE_INSTANCES_FAILURE: 'GET_QUESTIONNAIRE_INSTANCES_FAILURE',
  GET_QUESTIONNAIRE_INSTANCES_TOTAL_SUCCESS:
    'GET_QUESTIONNAIRE_INSTANCES_TOTAL_SUCCESS',

  REQUEST_CREATE_QUESTIONNAIRE_INSTANCE:
    'REQUEST_CREATE_QUESTIONNAIRE_INSTANCE',
  CREATE_QUESTIONNAIRE_INSTANCE_SUCCESS:
    'CREATE_QUESTIONNAIRE_INSTANCE_SUCCESS',
  CREATE_QUESTIONNAIRE_INSTANCE_FAILURE:
    'CREATE_QUESTIONNAIRE_INSTANCE_FAILURE',

  REQUEST_QUESTIONNAIRE_INSTANCE_DETAIL:
    'REQUEST_QUESTIONNAIRE_INSTANCE_DETAIL',
  GET_QUESTIONNAIRE_INSTANCE_DETAIL_SUCCESS:
    'GET_QUESTIONNAIRE_INSTANCE_DETAIL_SUCCESS',
  GET_QUESTIONNAIRE_INSTANCE_DETAIL_FAILURE:
    'GET_QUESTIONNAIRE_INSTANCE_DETAIL_FAILURE',

  REQUEST_QUESTIONNAIRE_INSTANCE_UPDATE:
    'REQUEST_QUESTIONNAIRE_INSTANCE_UPDATE',
  QUESTIONNAIRE_INSTANCE_UPDATE_SUCCESS:
    'QUESTIONNAIRE_INSTANCE_UPDATE_SUCCESS',
  QUESTIONNAIRE_INSTANCE_UPDATE_FAILURE:
    'QUESTIONNAIRE_INSTANCE_UPDATE_FAILURE',

  REQUEST_QUESTIONNAIRE_INSTANCE_SUBMIT:
    'REQUEST_QUESTIONNAIRE_INSTANCE_SUBMIT',
  QUESTIONNAIRE_INSTANCE_SUBMIT_SUCCESS:
    'QUESTIONNAIRE_INSTANCE_SUBMIT_SUCCESS',
  QUESTIONNAIRE_INSTANCE_SUBMIT_FAILURE:
    'QUESTIONNAIRE_INSTANCE_SUBMIT_FAILURE',

  REQUEST_QUESTIONNAIRE_INSTANCE_PATCH: 'REQUEST_QUESTIONNAIRE_INSTANCE_PATCH',
  QUESTIONNAIRE_INSTANCE_PATCH_SUCCESS: 'QUESTIONNAIRE_INSTANCE_PATCH_SUCCESS',
  QUESTIONNAIRE_INSTANCE_PATCH_FAILURE: 'QUESTIONNAIRE_INSTANCE_PATCH_FAILURE',

  REQUEST_QUESTIONNAIRE_INSTANCE_STATISTICS:
    'REQUEST_QUESTIONNAIRE_INSTANCE_STATISTICS',
  GET_QUESTIONNAIRE_INSTANCE_STATISTICS_SUCCESS:
    'GET_QUESTIONNAIRE_INSTANCE_STATISTICS_SUCCESS',
  GET_QUESTIONNAIRE_INSTANCE_STATISTICS_FAILURE:
    'GET_QUESTIONNAIRE_INSTANCE_STATISTICS_FAILURE',

  REQUEST_QUESTIONNAIRE_INSTANCE_EXPORT:
    'REQUEST_QUESTIONNAIRE_INSTANCE_EXPORT',
  REQUEST_QUESTIONNAIRE_INSTANCE_EXPORT_DETAILS:
    'REQUEST_QUESTIONNAIRE_INSTANCE_EXPORT_DETAILS',
  REQUEST_QUESTIONNAIRE_INSTANCE_EXPORT_SUMMARY:
    'REQUEST_QUESTIONNAIRE_INSTANCE_EXPORT_SUMMARY',
  QUESTIONNAIRE_INSTANCE_EXPORT_SUCCESS:
    'QUESTIONNAIRE_INSTANCE_EXPORT_SUCCESS',
  QUESTIONNAIRE_INSTANCE_EXPORT_FAILURE:
    'QUESTIONNAIRE_INSTANCE_EXPORT_FAILURE',

  REQUEST_INDIVIDUAL_QUESTIONNAIRE_INSTANCE_EXPORT:
    'REQUEST_INDIVIDUAL_QUESTIONNAIRE_INSTANCE_EXPORT',
  INDIVIDUAL_QUESTIONNAIRE_INSTANCE_EXPORT_SUCCESS:
    'INDIVIDUAL_QUESTIONNAIRE_INSTANCE_EXPORT_SUCCESS',
  INDIVIDUAL_QUESTIONNAIRE_INSTANCE_EXPORT_FAILURE:
    'INDIVIDUAL_QUESTIONNAIRE_INSTANCE_EXPORT_FAILURE',

  REQUEST_SURVEY_TEMPLATES: 'REQUEST_SURVEY_TEMPLATES',
  REQUEST_SURVEY_TEMPLATES_SUCCESS: 'REQUEST_SURVEY_TEMPLATES_SUCCESS',
  REQUEST_SURVEY_TEMPLATES_FAILURE: 'REQUEST_SURVEY_TEMPLATES_FAILURE',

  REQUEST_SURVEY_TEMPLATE_CONFIGURATION:
    'REQUEST_SURVEY_TEMPLATE_CONFIGURATION',
  SURVEY_TEMPLATE_CONFIGURATION_SUCCESS:
    'SURVEY_TEMPLATE_CONFIGURATION_SUCCESS',
  SURVEY_TEMPLATE_CONFIGURATION_FAILURE:
    'SURVEY_TEMPLATE_CONFIGURATION_FAILURE',

  REQUEST_CREATE_SURVEY_TEMPLATE: 'REQUEST_CREATE_SURVEY_TEMPLATE',
  CREATE_SURVEY_TEMPLATE_SUCCESS: 'CREATE_SURVEY_TEMPLATE_SUCCESS',
  CREATE_SURVEY_TEMPLATE_FAILURE: 'CREATE_SURVEY_TEMPLATE_FAILURE',

  REQUEST_SURVEY_TEMPLATE_DETAIL: 'REQUEST_SURVEY_TEMPLATE_DETAIL',
  SURVEY_TEMPLATE_DETAIL_SUCCESS: 'SURVEY_TEMPLATE_DETAIL_SUCCESS',
  SURVEY_TEMPLATE_DETAIL_FAILURE: 'SURVEY_TEMPLATE_DETAIL_FAILURE',

  REQUEST_SURVEY_TEMPLATE_UPDATE: 'REQUEST_SURVEY_TEMPLATE_UPDATE',
  SURVEY_TEMPLATE_UPDATE_SUCCESS: 'SURVEY_TEMPLATE_UPDATE_SUCCESS',
  SURVEY_TEMPLATE_UPDATE_FAILURE: 'SURVEY_TEMPLATE_UPDATE_FAILURE',

  REQUEST_SURVEY_TEMPLATE_DELETE: 'REQUEST_SURVEY_TEMPLATE_DELETE',
  SURVEY_TEMPLATE_DELETE_SUCCESS: 'SURVEY_TEMPLATE_DELETE_SUCCESS',
  SURVEY_TEMPLATE_DELETE_FAILURE: 'SURVEY_TEMPLATE_DELETE_FAILURE',

  REQUEST_SURVEY_INSTANCES: 'REQUEST_SURVEY_INSTANCES',
  GET_SURVEY_INSTANCES_SUCCESS: 'GET_SURVEY_INSTANCES_SUCCESS',
  GET_SURVEY_INSTANCES_FAILURE: 'GET_SURVEY_INSTANCES_FAILURE',
  GET_SURVEY_INSTANCES_TOTAL_SUCCESS: 'GET_SURVEY_INSTANCES_TOTAL_SUCCESS',

  REQUEST_SURVEY_NOTIFICATION_RESEND: 'REQUEST_SURVEY_NOTIFICATION_RESEND',
  SURVEY_NOTIFICATION_RESEND_SUCCESS: 'SURVEY_NOTIFICATION_RESEND_SUCCESS',
  SURVEY_NOTIFICATION_RESEND_FAILURE: 'SURVEY_NOTIFICATION_RESEND_FAILURE',

  REQUEST_CREATE_SURVEY_INSTANCE: 'REQUEST_CREATE_SURVEY_INSTANCE',
  CREATE_SURVEY_INSTANCE_SUCCESS: 'CREATE_SURVEY_INSTANCE_SUCCESS',
  CREATE_SURVEY_INSTANCE_FAILURE: 'CREATE_SURVEY_INSTANCE_FAILURE',

  REQUEST_SURVEY_INSTANCE_DETAIL: 'REQUEST_SURVEY_INSTANCE_DETAIL',
  GET_SURVEY_INSTANCE_DETAIL_SUCCESS: 'GET_SURVEY_INSTANCE_DETAIL_SUCCESS',
  GET_SURVEY_INSTANCE_DETAIL_FAILURE: 'GET_SURVEY_INSTANCE_DETAIL_FAILURE',

  REQUEST_SURVEY_INSTANCE_UPDATE: 'REQUEST_SURVEY_INSTANCE_UPDATE',
  SURVEY_INSTANCE_UPDATE_SUCCESS: 'SURVEY_INSTANCE_UPDATE_SUCCESS',
  SURVEY_INSTANCE_UPDATE_FAILURE: 'SURVEY_INSTANCE_UPDATE_FAILURE',

  REQUEST_SURVEY_INSTANCE_SUBMIT: 'REQUEST_SURVEY_INSTANCE_SUBMIT',
  SURVEY_INSTANCE_SUBMIT_SUCCESS: 'SURVEY_INSTANCE_SUBMIT_SUCCESS',
  SURVEY_INSTANCE_SUBMIT_FAILURE: 'SURVEY_INSTANCE_SUBMIT_FAILURE',

  REQUEST_SURVEY_INSTANCE_PATCH: 'REQUEST_SURVEY_INSTANCE_PATCH',
  SURVEY_INSTANCE_PATCH_SUCCESS: 'SURVEY_INSTANCE_PATCH_SUCCESS',
  SURVEY_INSTANCE_PATCH_FAILURE: 'SURVEY_INSTANCE_PATCH_FAILURE',

  REQUEST_SURVEY_INSTANCE_STATISTICS: 'REQUEST_SURVEY_INSTANCE_STATISTICS',
  GET_SURVEY_INSTANCE_STATISTICS_SUCCESS:
    'GET_SURVEY_INSTANCE_STATISTICS_SUCCESS',
  GET_SURVEY_INSTANCE_STATISTICS_FAILURE:
    'GET_SURVEY_INSTANCE_STATISTICS_FAILURE',

  REQUEST_SURVEY_INSTANCE_EXPORT: 'REQUEST_SURVEY_INSTANCE_EXPORT',
  REQUEST_SURVEY_INSTANCE_EXPORT_DETAILS:
    'REQUEST_SURVEY_INSTANCE_EXPORT_DETAILS',
  REQUEST_SURVEY_INSTANCE_EXPORT_SUMMARY:
    'REQUEST_SURVEY_INSTANCE_EXPORT_SUMMARY',
  SURVEY_INSTANCE_EXPORT_SUCCESS: 'SURVEY_INSTANCE_EXPORT_SUCCESS',
  SURVEY_INSTANCE_EXPORT_FAILURE: 'SURVEY_INSTANCE_EXPORT_FAILURE',

  REQUEST_INDIVIDUAL_SURVEY_INSTANCE_EXPORT:
    'REQUEST_INDIVIDUAL_SURVEY_INSTANCE_EXPORT',
  INDIVIDUAL_SURVEY_INSTANCE_EXPORT_SUCCESS:
    'INDIVIDUAL_SURVEY_INSTANCE_EXPORT_SUCCESS',
  INDIVIDUAL_SURVEY_INSTANCE_EXPORT_FAILURE:
    'INDIVIDUAL_SURVEY_INSTANCE_EXPORT_FAILURE',

  REQUEST_CREATE_HEALTH: 'REQUEST_CREATE_HEALTH',
  CREATE_HEALTH_SUCCESS: 'CREATE_HEALTH_SUCCESS',
  CREATE_HEALTH_FAILURE: 'CREATE_HEALTH_FAILURE',

  REQUEST_HEALTH: 'REQUEST_HEALTH',
  GET_HEALTH_SUCCESS: ' GET_HEALTH_SUCCESS',
  GET_HEALTH_FAILURE: 'GET_HEALTH_FAILURE',

  UPDATE_QUESTIONNAIRE_CATEGORY: 'UPDATE_QUESTIONNAIRE_CATEGORY',
  UPDATE_QUESTIONNAIRE_QUESTION: 'UPDATE_QUESTIONNAIRE_QUESTION',

  UPDATE_SURVEY_CATEGORY: 'UPDATE_SURVEY_CATEGORY',
  UPDATE_SURVEY_QUESTION: 'UPDATE_SURVEY_QUESTION',
  UPDATE_SURVEY_CONDITION: 'UPDATE_SURVEY_CONDITION',

  REQUEST_POLICY_AUDIT: 'REQUEST_POLICY_AUDIT',
  GET_POLICY_AUDIT_SUCCESS: 'GET_POLICY_AUDIT_SUCCESS',
  GET_POLICY_AUDIT_FAILURE: 'GET_POLICY_AUDIT_FAILURE',

  REQUEST_POLICY_AUDIT_PATCH: 'REQUEST_POLICY_AUDIT_PATCH',
  POLICY_AUDIT_PATCH_SUCCESS: 'POLICY_AUDIT_PATCH_SUCCESS',
  POLICY_AUDIT_PATCH_FAILURE: 'POLICY_AUDIT_PATCH_FAILURE',

  UPDATE_POLICY_AUDIT_QUESTIONS: 'UPDATE_POLICY_AUDIT_QUESTIONS',
  UPDATE_POLICY_AUDIT_ANSWER: 'UPDATE_POLICY_AUDIT_ANSWER',
  UPDATE_POLICY_AUDIT_MORE_INFO: 'UPDATE_POLICY_AUDIT_MORE_INFO',

  RESET_STORE: 'RESET_STORE',

  REQUEST_USER_RESTRICTIONS: 'REQUEST_USER_RESTRICTIONS',
  REQUEST_USER_RESTRICTIONS_SUCCESS: 'REQUEST_USER_RESTRICTIONS_SUCCESS',
  REQUEST_USER_RESTRICTIONS_FAILURE: 'REQUEST_USER_RESTRICTIONS_FAILURE',
  REQUEST_UPDATE_RESTRICTIONS: 'REQUEST_UPDATE_RESTRICTIONS',
  UPDATE_USER_RESTRICTIONS_SUCCESS: 'UPDATE_USER_RESTRICTIONS_SUCCESS',
  UPDATE_USER_RESTRICTIONS_FAILURE: 'UPDATE_USER_RESTRICTIONS_FAILURE',

  REQUEST_REMOTE_ACCESS_USERS: 'REQUEST_REMOTE_ACCESS_USERS',
  REQUEST_REMOTE_ACCESS_USERS_SUCCESS: 'REQUEST_REMOTE_ACCESS_USERS_SUCCESS',
  REQUEST_REMOTE_ACCESS_USERS_FAILED: 'REQUEST_REMOTE_ACCESS_USERS_FAILED',

  REQUEST_REMOTE_ACCESS_PASSWORD_RESET: 'REQUEST_REMOTE_ACCESS_PASSWORD_RESET',
  REQUEST_REMOTE_ACCESS_PASSWORD_RESET_SUCCESS:
    'REQUEST_REMOTE_ACCESS_PASSWORD_RESET_SUCCESS',
  REQUEST_REMOTE_ACCESS_PASSWORD_RESET_FAILED:
    'REQUEST_REMOTE_ACCESS_PASSWORD_RESET_FAILED',

  REQUEST_REMOTE_ACCESS_USER_ADD_TO_ROLE:
    'REQUEST_REMOTE_ACCESS_USER_ADD_TO_ROLE',
  REQUEST_REMOTE_ACCESS_USER_ADD_TO_ROLE_SUCCESS:
    'REQUEST_REMOTE_ACCESS_USER_ADD_TO_ROLE_SUCCESS',
  REQUEST_REMOTE_ACCESS_USER_ADD_TO_ROLE_FAILED:
    'REQUEST_REMOTE_ACCESS_USER_ADD_TO_ROLE_FAILED',

  REQUEST_REMOTE_ACCESS_USER_REMOVE_FROM_ROLE:
    'REQUEST_REMOTE_ACCESS_USER_REMOVE_FROM_ROLE',
  REQUEST_REMOTE_ACCESS_USER_REMOVE_FROM_ROLE_SUCCESS:
    'REQUEST_REMOTE_ACCESS_USER_REMOVE_FROM_ROLE_SUCCESS',
  REQUEST_REMOTE_ACCESS_USER_REMOVE_FROM_ROLE_FAILED:
    'REQUEST_REMOTE_ACCESS_USER_REMOVE_FROM_ROLE_FAILED',

  REQUEST_REMOTE_ACCESS_ORG_ROLES: 'REQUEST_REMOTE_ACCESS_ORG_ROLES',
  REQUEST_REMOTE_ACCESS_ORG_ROLES_SUCCESS:
    'REQUEST_REMOTE_ACCESS_ORG_ROLES_SUCCESS',
  REQUEST_REMOTE_ACCESS_ORG_ROLES_FAILED:
    'REQUEST_REMOTE_ACCESS_ORG_ROLES_FAILED',

  REQUEST_REMOTE_ACCESS_ROLE_USERS: 'REQUEST_REMOTE_ACCESS_ROLE_USERS',
  REQUEST_REMOTE_ACCESS_ROLE_USERS_SUCCESS:
    'REQUEST_REMOTE_ACCESS_ROLE_USERS_SUCCESS',
  REQUEST_REMOTE_ACCESS_ROLE_USERS_FAILED:
    'REQUEST_REMOTE_ACCESS_ROLE_USERS_FAILED',

  REQUEST_REMOTE_ACCESS_USER_ROLES: 'REQUEST_REMOTE_ACCESS_USER_ROLES',
  REQUEST_REMOTE_ACCESS_USER_ROLES_SUCCESS:
    'REQUEST_REMOTE_ACCESS_USER_ROLES_SUCCESS',
  REQUEST_REMOTE_ACCESS_USER_ROLES_FAILED:
    'REQUEST_REMOTE_ACCESS_USER_ROLES_FAILED',

  REQUEST_REMOTE_ACCESS_ROLE_REMOVE_FROM_USER:
    'REQUEST_REMOTE_ACCESS_ROLE_REMOVE_FROM_USER',
  REQUEST_REMOTE_ACCESS_ROLE_REMOVE_FROM_USER_SUCCESS:
    'REQUEST_REMOTE_ACCESS_ROLE_REMOVE_FROM_USER_SUCCESS',
  RREQUEST_REMOTE_ACCESS_ROLE_REMOVE_FROM_USER_FAILED:
    'RREQUEST_REMOTE_ACCESS_ROLE_REMOVE_FROM_USER_FAILED',

  REQUEST_REMOTE_ACCESS_ROLE_ADD_TO_USER:
    'REQUEST_REMOTE_ACCESS_ROLE_ADD_TO_USER',
  REQUEST_REMOTE_ACCESS_ROLE_ADD_TO_USER_SUCCESS:
    'REQUEST_REMOTE_ACCESS_ROLE_ADD_TO_USER_SUCCESS',
  REQUEST_REMOTE_ACCESS_ROLE_ADD_TO_USER_FAILED:
    'REQUEST_REMOTE_ACCESS_ROLE_ADD_TO_USER_FAILED',

  REQUEST_REMOTE_ACCESS_ROLE_ADD: 'REQUEST_REMOTE_ACCESS_ROLE_ADD',
  REQUEST_REMOTE_ACCESS_ROLE_ADD_SUCCESS:
    'REQUEST_REMOTE_ACCESS_ROLE_ADD_SUCCESS',
  REQUEST_REMOTE_ACCESS_ROLE_ADD_FAILED:
    'REQUEST_REMOTE_ACCESS_ROLE_ADD_FAILED',

  REQUEST_USER_SECURITY_GROUP_ADD: 'REQUEST_USER_SECURITY_GROUP_ADD',
  REQUEST_USER_SECURITY_GROUP_ADD_SUCCESS:
    'REQUEST_USER_SECURITY_GROUP_ADD_SUCCESS',
  REQUEST_USER_SECURITY_GROUP_ADD_FAILED:
    'REQUEST_USER_SECURITY_GROUP_ADD_FAILED',

  REQUEST_USER_SECURITY_GROUP_REMOVE: 'REQUEST_USER_SECURITY_GROUP_REMOVE',
  REQUEST_USER_SECURITY_GROUP_REMOVE_SUCCESS:
    'REQUEST_USER_SECURITY_GROUP_REMOVE_SUCCESS',
  REQUEST_USER_SECURITY_GROUP_REMOVE_FAILED:
    'REQUEST_USER_SECURITY_GROUP_REMOVE_FAILED',
};
