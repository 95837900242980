import React, {useCallback, useContext, useState} from 'react';
import { EllipsisOutlined } from '@ant-design/icons';
import { Button, Dropdown, MenuProps } from 'antd';

import DeviceTypeContext from '@totem/components/deviceTypes/DeviceTypeContext';
import RenameDialog from '@totem/components/common/renameDialog/RenameDialog';
import { DeviceType } from '@totem/components/deviceTypes/types';
import IBDIcon from '@totem/styles/Icon';
import { getToken } from '@totem/utilities/accountUtilities';
import { DEVICES_ENDPOINT } from '@totem/utilities/endpoints';
import { CheckResponseShowError } from '@totem/utilities/responseUtilities';

type Props = {
  deviceType: DeviceType;
};

const ActionMenu = ({ deviceType }: Props) => {
  const { onAction } = useContext(DeviceTypeContext);
  const [showRename, setShowRename] = useState<boolean>(false);
  const [isSending, setIsSending] = useState<boolean>(false);

  const sendUpdateRequest = useCallback(async (payload: any) => {
    if (isSending) {
      return;
    }
    setIsSending(true);

    fetch(`${DEVICES_ENDPOINT}/deviceTypes`, {
      method: 'POST',
      headers: new Headers({
        Authorization: `Bearer ${getToken()}`,
      }),
      body: JSON.stringify(payload),
    }).then((res) => {
      CheckResponseShowError(res);
      setIsSending(false);
      setShowRename(false);
      onAction('refresh', null);
    });
  }, []);

  const handleRename = (newName: string) => {
    const payload = {
      action: 'Rename',
      name: deviceType.name,
      alias: newName,
    };
    sendUpdateRequest(payload);
  };

  const getActionMenuItems = (): MenuProps['items'] => {
    const menuItems: MenuProps['items'] = [
      {
        key: 'rename',
        label: 'Rename',
        title: 'Rename',
        icon: <IBDIcon name={'Edit'} />,
      },
    ];

    return menuItems;
  };

  const onMenuClicked = ({ key }) => {
    // eslint-disable-next-line default-case
    switch (key) {
      case 'rename':
        setShowRename(true);
        break;
    }
  };

  return (
    <>
      <Dropdown
        menu={{ items: getActionMenuItems(), onClick: onMenuClicked }}
        trigger={['click']}
      >
        <Button shape="circle" icon={<EllipsisOutlined />} />
      </Dropdown>
      <RenameDialog
        name={deviceType.name}
        open={showRename}
        onCancel={() => setShowRename(false)}
        onSubmit={handleRename}
        allowEmptyString={false}
      />
    </>
  );
};

export default ActionMenu;
