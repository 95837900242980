import React, { useContext } from 'react';

import FilterChip from '@totem/components/common/FilterChip';
import AnalysisReportContext from '@totem/components/surveyV2/analysisReport/analysisReportContext';
import { AnalysisReportInput } from '@totem/components/surveyV2/analysisReport/types';

const styles = {
  filterChip: {
    marginLeft: '2rem',
    marginTop: '2rem',
  },
};

const AnalysisReportFilterChips = () => {
  const { setInput, input } = useContext(AnalysisReportContext);

  const handleMultiFilterDelete = (
    property: keyof AnalysisReportInput,
    value: string,
  ) => {
    // @ts-ignore
    const updatedValue = input[property].filter((val) => val !== value);
    setInput({
      ...input,
      [property]: updatedValue.length ? updatedValue : null,
    });
  };

  return (
    <>
      {input.policyName &&
        input.policyName.map((option) => {
          return (
            <FilterChip
              key={option}
              label={`Policy Name: ${option}`}
              style={styles.filterChip}
              onClose={() => handleMultiFilterDelete('policyName', option)}
            />
          );
        })}

      {input.policyCategory &&
        input.policyCategory.map((option) => {
          return (
            <FilterChip
              key={option}
              label={`Policy Category: ${option}`}
              style={styles.filterChip}
              onClose={() => handleMultiFilterDelete('policyCategory', option)}
            />
          );
        })}

      {input.nist &&
        input.nist.map((option) => {
          return (
            <FilterChip
              key={option}
              label={`NIST: ${option}`}
              style={styles.filterChip}
              onClose={() => handleMultiFilterDelete('nist', option)}
            />
          );
        })}

      {input.levelOfEffort &&
        input.levelOfEffort.map((option) => {
          return (
            <FilterChip
              key={option}
              label={`Level of Effort: ${option}`}
              style={styles.filterChip}
              onClose={() => handleMultiFilterDelete('levelOfEffort', option)}
            />
          );
        })}
    </>
  );
};

export default AnalysisReportFilterChips;
