import {
  HealthRiskSurvey,
  HealthRiskSurveyErrors,
  Question,
} from '@totem/types/healthRiskSurvey';

export const isQuestionAnswered = (question: Question): boolean => {
  return Boolean(question.answer?.value);
};

export const validateHealthRiskSurvey = ({
  questionsOrder,
  questions,
}: HealthRiskSurvey): HealthRiskSurveyErrors =>
  questionsOrder.reduce((acc, key) => {
    const question: Question = questions[key];

    return isQuestionAnswered(question)
      ? acc
      : {
          ...acc,
          [key]: 'This question is required.',
        };
  }, {});
