import React from 'react';
import { Avatar } from 'antd';
import * as R from 'ramda';

import { Assignee, User } from '@totem/types/user';
import { isNotNull, isNull } from '@totem/utilities/common';

interface Props {
  size?: number | 'large' | 'small' | 'default';
  user?: User | Assignee;
  style?: any;
}

const UserAvatar = ({ size = 'large', user, style }: Props) => {
  const isDefaultImage = user
    ? isNull(user.imageUrl) ||
      user.imageUrl.includes('cdn.auth0.com') ||
      R.isEmpty(user.imageUrl)
    : true;

  const initials =
    user &&
    isNotNull(user.firstName) &&
    isNotNull(user.lastName) &&
    !R.isEmpty(user.firstName) &&
    !R.isEmpty(user.lastName)
      ? `${user.firstName[0]}${user.lastName[0]}`
      : 'NU';

  const avatarProps = {
    size,
    ...(isDefaultImage && { children: initials }),
    ...(!isDefaultImage && { src: user.imageUrl }),
    ...(style && { style }),
  };

  return <Avatar {...avatarProps} />;
};

export default UserAvatar;
