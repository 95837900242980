import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button, Form, Input, Modal, Select } from 'antd';
import * as R from 'ramda';

import ReportContext from '@totem/components/controlSystemReport/ControlSystemReportContext';
import { ControlSystemReportRecord } from '@totem/components/controlSystemReport/dataTypes';
import ModalFooter from '@totem/components/ModalFooter';
import ModalTitle from '@totem/components/ModalTitle';
import {
  ControlSystemType,
  ControlSystemUpdateInput,
} from '@totem/types/controlSystem';
import { getToken } from '@totem/utilities/accountUtilities';
import {
  controlSystemTypeMap,
  controlSystemTypeOptions,
} from '@totem/utilities/controlSystemsUtilities';
import { CONTROL_SYSTEMS_ENDPOINT } from '@totem/utilities/endpoints';
import { CheckResponseShowError } from '@totem/utilities/responseUtilities';

const FormItem = Form.Item;
const { Option } = Select;

interface FormErrors {
  name?: string;
  systemType?: string;
  email?: string;
}

interface Props {
  open: boolean;
  onClose: () => void;
  controlSystem: ControlSystemReportRecord;
}

const initialState = {
  input: {
    id: '',
    name: '',
    systemType: 0,
  },
};

const styles = {
  form: {
    width: '100%',
  },
  formItem: {
    paddingBottom: '0',
    marginBottom: '1rem',
    width: '100%',
  },
  checkbox: {
    fontSize: '12px',
    padding: '1rem 0',
  },
  datePicker: {
    width: '100%',
  },
};

const mapControlSystemToInput = (
  controlSystem?: ControlSystemReportRecord,
): ControlSystemUpdateInput => {
  return {
    id: controlSystem?.id || '',
    name: controlSystem?.name || '',
    systemType: controlSystem?.systemType || ControlSystemType.Unset,
    manufacturer: controlSystem?.manufacturer || '',
    model: controlSystem?.model || '',
  };
};

const ControlSystemEditModal = ({ open, onClose, controlSystem }: Props) => {
  const [errors, setErrors] = useState<FormErrors>({});
  const [isSending, setIsSending] = useState<boolean>(false);
  const [input, setInput] = useState<ControlSystemUpdateInput>(
    initialState.input,
  );
  const { loading, onAction } = useContext(ReportContext);

  const sendUpdateControlSystem = useCallback(
    async (payload: ControlSystemUpdateInput) => {
      if (isSending) {
        return;
      }
      setIsSending(true);

      fetch(`${CONTROL_SYSTEMS_ENDPOINT}/${payload.id}`, {
        method: 'POST',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
        body: JSON.stringify(payload),
      }).then((res) => {
        CheckResponseShowError(res);
        setIsSending(false);
        onAction('refresh');
      });
    },
    [],
  );

  useEffect(() => {
    if (!open) {
      setErrors({});
      setInput(initialState.input);
    }
  }, [open]);

  useEffect(() => {
    if (controlSystem) {
      setErrors({});
      setInput(mapControlSystemToInput(controlSystem));
    }
  }, [controlSystem]);

  const isValidForm = (): boolean => {
    const formErrors: FormErrors = {};

    if (!input.name) {
      formErrors.name = 'Please enter a name';
    }

    if (R.isNil(input.systemType)) {
      formErrors.systemType = 'Please select a control system type';
    }

    setErrors(formErrors);
    return R.isEmpty(formErrors);
  };

  const handleChange = ({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setInput({ ...input, [name]: value });
  };

  const handleTypeChange = (systemType: ControlSystemType) => {
    const type = controlSystemTypeMap[systemType];
    setInput({ ...input, name: type.text, systemType });
  };

  const handleSubmit = async () => {
    if (!isValidForm()) {
      return;
    }

    sendUpdateControlSystem(input);
    onClose();
  };

  return (
    <Modal
      open={open}
      onCancel={onClose}
      title={<ModalTitle>Edit Control System</ModalTitle>}
      footer={
        <ModalFooter>
          <Button onClick={onClose}>Cancel</Button>
          <Button type="primary" loading={loading} onClick={handleSubmit}>
            Save
          </Button>
        </ModalFooter>
      }
      confirmLoading={loading}
    >
      <Form layout="vertical" style={styles.form}>
        <FormItem
          label="System Type"
          validateStatus={!errors.systemType ? 'success' : 'error'}
          help={!errors.systemType ? null : errors.systemType}
          colon={false}
          style={styles.formItem}
        >
          <Select
            showSearch
            filterOption
            onChange={handleTypeChange}
            value={input.systemType}
            optionFilterProp="children"
            disabled={loading}
          >
            {controlSystemTypeOptions.map((option) => {
              return (
                <Option key={option.value} value={option.value}>
                  {option.text}
                </Option>
              );
            })}
          </Select>
        </FormItem>
        <FormItem
          label="System Name"
          colon={false}
          validateStatus={!errors.name ? 'success' : 'error'}
          help={!errors.name ? null : errors.name}
          style={styles.formItem}
        >
          <Input
            disabled={loading}
            value={input.name}
            name="name"
            onChange={handleChange}
            type="search"
          />
        </FormItem>
        <FormItem label="Manufacturer" colon={false} style={styles.formItem}>
          <Input
            disabled={loading}
            value={input.manufacturer}
            name="manufacturer"
            onChange={handleChange}
            type="search"
          />
        </FormItem>
        <FormItem label="Model" colon={false} style={styles.formItem}>
          <Input
            disabled={loading}
            value={input.model}
            name="model"
            onChange={handleChange}
            type="search"
          />
        </FormItem>
      </Form>
    </Modal>
  );
};

export default ControlSystemEditModal;
