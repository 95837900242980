import React, { ReactNode } from 'react';

import '../home.css';

type Props = {
  children?: ReactNode;
};

const WidgetContentPaneNoTitle = ({ children }: Props) => {
  return (
    <div
      styleName="widget-content-pane-no-title"
      className={'dashboardWidgetDraggable'}
    >
      {children}
    </div>
  );
};

export default WidgetContentPaneNoTitle;
