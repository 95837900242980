import React from 'react';

import Count from '@totem/components/common/Count';
import { Criticality } from '@totem/types/criticality';
import { FindingCriticalityCounts } from '@totem/types/finding';
import { criticalityMap } from '@totem/utilities/criticalityUtilities';
import { getExactPercentage } from '@totem/utilities/numberUtilities';

import './findings.css';

interface Props {
  counts: FindingCriticalityCounts;
}

const getTotal = ({
  critical,
  high,
  medium,
  low,
}: FindingCriticalityCounts): number => critical + high + medium + low;

const renderBar = (count: number, total: number, criticality: Criticality) => {
  const option = criticalityMap[criticality];

  return (
    <div styleName="findings" key={criticality}>
      <div styleName="finding-label criticality">{option.text}</div>
      <div styleName="finding-bar-container">
        <div styleName="finding-bar">
          <div
            style={{
              width: `${getExactPercentage(total, count)}%`,
              background: option.color,
              height: '100%',
            }}
          />
        </div>
      </div>
      <Count count={count} size="small" />
    </div>
  );
};

const FindingsCriticalityCounts = ({ counts }: Props) => {
  const { critical, high, medium, low } = counts;
  const total = getTotal(counts);

  return (
    <div styleName="findings-container">
      <div styleName="finding-header-container">
        <div styleName="finding-header">Findings By Criticality</div>
        <Count count={total} size="large" />
      </div>
      <div>
        {renderBar(critical, total, Criticality.Critical)}
        {renderBar(high, total, Criticality.High)}
        {renderBar(medium, total, Criticality.Medium)}
        {renderBar(low, total, Criticality.Low)}
      </div>
    </div>
  );
};

export default FindingsCriticalityCounts;
