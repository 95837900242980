import React, { useContext } from 'react';
import { Progress, Tabs } from 'antd';
import { TabsProps } from 'antd/lib/tabs';

import Category from '@totem/components/surveyV2/instance/Category';
import SurveyContext from '@totem/components/surveyV2/instanceDetail/SurveyInstanceContext';
import TabTitle from '@totem/components/TabTitle';
import { ScoredCategory } from '@totem/types/survey';

import '../survey.css';
import { sortNumberAscending } from '@totem/utilities/tableUtilities';

const styles = {
  progress: {
    width: '32rem',
  },
};

const SurveyInstance = () => {
  const { data, errors, filterAnswered } = useContext(SurveyContext);
  const { survey } = data;

  const categoryHasError = ({ id }: ScoredCategory) => {
    return errors.some(({ categoryId }) => categoryId === id);
  };

  const getCategoryName = (category: ScoredCategory): string => {
    const { name, completedQuestions, totalQuestions } = category;
    return `${name} (${completedQuestions}/${totalQuestions})`;
  };

  const renderTabBar = (props: TabsProps, DefaultTabBar) => {
    const { completedQuestions, totalQuestions } = survey;
    const percent = Math.round((completedQuestions / totalQuestions) * 100);

    return (
      <div styleName="tabs-container">
        <div styleName="tab-bar">
          <DefaultTabBar {...props} />
        </div>
        <div>
          <div styleName="progress-label">Progress</div>
          <Progress percent={percent} style={styles.progress} />
        </div>
      </div>
    );
  };

  const getTabItems = () => {
    const { categories } = survey;
    const visibleCategories = categories.filter((cat) => cat.visible === true);

    const sortedCategories = visibleCategories.sort((compA, compB) =>
      sortNumberAscending(compA.index, compB.index),
    );

    return sortedCategories.map((category) => {
      return {
        key: category.id,
        label: (
          <div styleName={categoryHasError(category) ? 'category-error' : ''}>
            <TabTitle>{getCategoryName(category)}</TabTitle>
          </div>
        ),
        children: (
          <div>
            <Category
              category={category}
              disabled={survey.isSubmitted}
              filterAnswered={filterAnswered}
            />
          </div>
        ),
      };
    });
  };

  return (
    <Tabs
      hideAdd
      renderTabBar={renderTabBar}
      size="large"
      items={getTabItems()}
    />
  );
};

export default SurveyInstance;
