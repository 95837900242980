import React from 'react';

import BasePane from '@totem/components/BasePane';
import ContentLayout from '@totem/components/ContentLayout';
import PrimaryContentPane from '@totem/components/PrimaryContentPane';
import VulnerabilityReportContainer from '@totem/components/software/vulnerabilityReport/vulnerabilityReportContainer';
import VulnerabilityReportTable from '@totem/components/software/vulnerabilityReport/vulnerabilityReportTable';

const VulnerabilityReportPage = () => {
  return (
    <ContentLayout>
      <BasePane>
        <PrimaryContentPane>
          <VulnerabilityReportContainer>
            <VulnerabilityReportTable />
          </VulnerabilityReportContainer>
        </PrimaryContentPane>
      </BasePane>
    </ContentLayout>
  );
};

export default VulnerabilityReportPage;
