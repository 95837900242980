import React, { useEffect, useState } from 'react';
import { Select } from 'antd';

import { EMPTY_ID } from '@totem/components/common/reference/ReferenceSelector';
import {
  AutoCompleteResponse,
  AutoCompleteValue,
} from '@totem/types/autoComplete';
import { Filter } from '@totem/types/common';
import { getToken } from '@totem/utilities/accountUtilities';
import { AUTO_COMPLETE_ENDPOINT } from '@totem/utilities/endpoints';
const { Option } = Select;

const styles = {
  input: {
    width: '100%',
  },
};

export interface Props {
  label: string;
  type: string;
  limit: number;
  filters?: Filter[];
  selectedValue?: AutoCompleteValue;
  unselectedLabel?: string;
  unselectedValue?: string;
  style?: React.CSSProperties;
  onSelect: (selectedValue: AutoCompleteValue) => void;
  disabled?: boolean;
}

const GenericSelect = ({
  type,
  label,
  limit,
  filters,
  selectedValue,
  style,
  onSelect,
  disabled,
  unselectedLabel,
  unselectedValue,
}: Props) => {
  const [selectedOption, setSelectedOption] = useState<AutoCompleteValue>(
    typeof selectedValue !== 'undefined' && selectedValue !== null
      ? selectedValue
      : {
          display:
            typeof unselectedLabel !== 'undefined' && unselectedLabel !== null
              ? unselectedLabel
              : 'ALL',
          value:
            typeof unselectedValue !== 'undefined' && unselectedValue !== null
              ? unselectedValue
              : EMPTY_ID,
        },
  );
  const [options, setOptions] = useState<AutoCompleteValue[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const buildRequest = () => {
    return {
      type,
      searchTerms: '*',
      limit,
      filters,
    };
  };

  useEffect(() => {
    setLoading(true);
    fetch(`${AUTO_COMPLETE_ENDPOINT}/`, {
      method: 'POST',
      headers: new Headers({
        Authorization: `Bearer ${getToken()}`,
      }),
      body: JSON.stringify(buildRequest()),
    })
      .then((res) => res.json())
      .then((result: AutoCompleteResponse) => {
        setOptions(result.values);

        const noValue =
          typeof unselectedValue !== 'undefined' && unselectedValue !== null
            ? unselectedValue
            : EMPTY_ID;

        if (selectedOption.value !== noValue) {
          const foundOptionIndex = result.values.findIndex(
            (opt) => opt.value === selectedOption.value,
          );
          if (foundOptionIndex === -1) {
            setSelectedOption({
              display:
                typeof unselectedLabel !== 'undefined' &&
                unselectedLabel !== null
                  ? unselectedLabel
                  : 'ALL',
              value:
                typeof unselectedValue !== 'undefined' &&
                unselectedValue !== null
                  ? unselectedValue
                  : EMPTY_ID,
            });
          }
        }
      })
      .then(() => {
        setLoading(false);
      });
  }, [type, filters, limit]);

  const getOptions = () =>
    options.map(({ display, value }) => (
      <Option key={value} value={value}>
        {display}
      </Option>
    ));

  const handleSelect = (_, option: any) => {
    const noValue =
      typeof unselectedValue !== 'undefined' && unselectedValue !== null
        ? unselectedValue
        : EMPTY_ID;

    if (option.value === noValue) {
      const emptyValue = {
        display:
          typeof unselectedLabel !== 'undefined' && unselectedLabel !== null
            ? unselectedLabel
            : 'ALL',
        value:
          typeof unselectedValue !== 'undefined' && unselectedValue !== null
            ? unselectedValue
            : EMPTY_ID,
      };
      setSelectedOption(emptyValue);
      onSelect(emptyValue);
    } else {
      const pickedOption = options.find(
        (values) => values.value === option.value,
      );
      setSelectedOption(pickedOption);
      onSelect(pickedOption);
    }
  };

  return (
    <Select
      value={selectedOption.value}
      style={{ ...styles.input, ...style }}
      onSelect={handleSelect}
      placeholder={label}
      disabled={disabled ?? false}
      loading={loading}
    >
      <Option
        key="*"
        value={
          typeof unselectedValue !== 'undefined' && unselectedValue !== null
            ? unselectedValue
            : EMPTY_ID
        }
      >
        {typeof unselectedLabel !== 'undefined' && unselectedLabel !== null
          ? unselectedLabel
          : 'ALL'}
      </Option>
      {getOptions()}
    </Select>
  );
};

export default GenericSelect;
