import { gql } from '@apollo/client';

export const GET_POLICY_AUDIT_TEMPLATE_QUESTION = gql`
  query GetPolicyAuditTemplateQuestion($id: ID!) {
    policyAuditTemplateQuestion(id: $id) {
      id
      label
    }
  }
`;

export const GET_POLICY_AUDIT_TEMPLATE_QUESTIONS = gql`
  query GetPolicyAuditTemplateQuestions(
    $input: PolicyAuditTemplateQuestionsInput!
  ) {
    policyAuditTemplateQuestions(input: $input) {
      id
      label
      policyName
      policyCategory
      nistFunction
      levelOfEffort
      compliantFindingsCount
      nonCompliantFindingsCount
    }
  }
`;
