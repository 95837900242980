import React from 'react';
import { useQuery } from '@apollo/client';
import * as R from 'ramda';

import { GET_FINDING_COUNTS } from '@totem/graph/finding';
import { useConnection } from '@totem/hooks/useConnection';
import {
  FindingCriticalityCounts,
  FindingGroupCounts,
  FindingLevelOfEffortCounts,
  FindingsConnection,
  FindingsConnectionInput,
} from '@totem/types/finding';

import FindingsCriticalityCounts from './FindingsCriticalityCounts';
import FindingsGroupCounts from './FindingsGroupCounts';
import FindingsLevelOfEffortCounts from './FindingsLevelOfEffortCounts';

import './findings.css';

interface Props {
  input: FindingsConnectionInput;
}

const initialCriticalityCounts: FindingCriticalityCounts = {
  critical: 0,
  low: 0,
  medium: 0,
  high: 0,
  compliant: 0,
  info: 0,
};

const initialGroupCounts: FindingGroupCounts = {
  systemUsers: { ...initialCriticalityCounts },
  systemConfiguration: { ...initialCriticalityCounts },
  network: { ...initialCriticalityCounts },
  continuity: { ...initialCriticalityCounts },
  controlSystemHost: { ...initialCriticalityCounts },
};

const initialLevelOfEffortCounts: FindingLevelOfEffortCounts = {
  minimal: 0,
  moderate: 0,
  maximum: 0,
};

const FindingsOverview = ({ input }: Props) => {
  const { data } = useQuery(GET_FINDING_COUNTS, {
    variables: { input },
  });

  const { groupCounts, criticalityCounts, levelOfEffortCounts } = useConnection<
    FindingsConnection
  >({
    data,
    accessor: R.path(['findings']),
    initialData: {
      totalCount: 0,
      findings: [],
      criticalityCounts: initialCriticalityCounts,
      groupCounts: initialGroupCounts,
      levelOfEffortCounts: initialLevelOfEffortCounts,
    },
  });

  return (
    <div styleName="overview-container">
      <div styleName="overview-section">
        <FindingsGroupCounts counts={groupCounts} />
      </div>
      <div styleName="overview-section">
        <FindingsCriticalityCounts counts={criticalityCounts} />
      </div>
      <div styleName="overview-section">
        <FindingsLevelOfEffortCounts counts={levelOfEffortCounts} />
      </div>
    </div>
  );
};

export default FindingsOverview;
