import React from 'react';

import { UserProfile } from '@totem/types/user';

export interface Context {
  refetch: () => Promise<any>;
  loading: boolean;
  userProfile: UserProfile;
}

export const initialState: Context = {
  refetch: () => Promise.resolve({}),
  loading: true,
  // @ts-ignore
  userProfile: {},
};

const UserProfileContext = React.createContext<Context>(initialState);

export default UserProfileContext;
