import {
  ReportFilters,
  ReportInput,
} from '@totem/components/controlSystemReport/parameterTypes';
import { Filter } from '@totem/types/common';

export const buildReportFilters = (input: ReportInput) => {
  const paramFilters: Filter[] = [];

  if (
    typeof input.regionId !== 'undefined' &&
    input.regionId !== null &&
    input.regionId.length > 0
  ) {
    paramFilters.push({
      field: 'region.id',
      values: input.regionId,
    });
  }
  if (
    typeof input.buildingId !== 'undefined' &&
    input.buildingId !== null &&
    input.buildingId.length > 0
  ) {
    paramFilters.push({
      field: 'building.id',
      values: input.buildingId,
    });
  }
  if (
    typeof input.controlSystemId !== 'undefined' &&
    input.controlSystemId !== null &&
    input.controlSystemId.length > 0
  ) {
    paramFilters.push({
      field: 'id',
      values: input.controlSystemId,
    });
  }
  if (
    typeof input.name !== 'undefined' &&
    input.name !== null &&
    input.name.length > 0
  ) {
    paramFilters.push({
      field: 'name',
      values: input.name,
    });
  }
  if (
    typeof input.type !== 'undefined' &&
    input.type !== null &&
    input.type.length > 0
  ) {
    paramFilters.push({
      field: 'systemType',
      values: input.type,
    });
  }
  if (
    typeof input.vendor !== 'undefined' &&
    input.vendor !== null &&
    input.vendor.length > 0
  ) {
    paramFilters.push({
      field: 'manufacturer',
      values: input.vendor,
    });
  }
  if (
    typeof input.operatingSystem !== 'undefined' &&
    input.operatingSystem !== null &&
    input.operatingSystem.length > 0
  ) {
    paramFilters.push({
      field: 'operatingSystem',
      values: input.operatingSystem,
    });
  }
  if (
    typeof input.searchName !== 'undefined' &&
    input.searchName !== null &&
    input.searchName.trim() !== ''
  ) {
    paramFilters.push({
      field: 'name',
      operator: 'cscontains',
      values: [input.searchName],
    });
  }

  return paramFilters;
};

export const addReportFilters = (
  currentFilters: Filter[],
  input: ReportFilters,
) => {
  if (typeof input !== 'undefined') {
    if (
      typeof input.regionId !== 'undefined' &&
      input.regionId !== null &&
      input.regionId.length > 0
    ) {
      currentFilters.push({
        field: 'region.id',
        values: input.regionId,
      });
    }
    if (
      typeof input.buildingId !== 'undefined' &&
      input.buildingId !== null &&
      input.buildingId.length > 0
    ) {
      currentFilters.push({
        field: 'building.id',
        values: input.buildingId,
      });
    }
    if (
      typeof input.controlSystemId !== 'undefined' &&
      input.controlSystemId !== null &&
      input.controlSystemId.length > 0
    ) {
      currentFilters.push({
        field: 'id',
        values: input.controlSystemId,
      });
    }
    if (
      typeof input.vendor !== 'undefined' &&
      input.vendor !== null &&
      input.vendor.length > 0
    ) {
      currentFilters.push({
        field: 'manufacturer',
        values: input.vendor,
      });
    }
    if (
      typeof input.name !== 'undefined' &&
      input.name !== null &&
      input.name.length > 0
    ) {
      currentFilters.push({
        field: 'name',
        values: input.name,
      });
    }
    if (
      typeof input.type !== 'undefined' &&
      input.type !== null &&
      input.type.length > 0
    ) {
      currentFilters.push({
        field: 'systemType',
        values: input.type,
      });
    }
    if (
      typeof input.operatingSystem !== 'undefined' &&
      input.operatingSystem !== null &&
      input.operatingSystem.length > 0
    ) {
      currentFilters.push({
        field: 'operatingSystem',
        values: input.operatingSystem,
      });
    }
  }

  return currentFilters;
};
