import * as R from 'ramda';
import { call, fork, put, takeEvery } from 'redux-saga/effects';

import actionTypes from '@totem/actions/actionTypes';
import * as notificationActions from '@totem/actions/notificationActions';
import * as remoteAccessUserActions from '@totem/actions/remoteAccessUserActions';
import accountUtilities from '@totem/utilities/accountUtilities';
import api from '@totem/utilities/api';
import {
  REMOTE_ACCESS_ORG_ROLES_ENDPOINT,
  REMOTE_ACCESS_PASSWORD_RESET,
  REMOTE_ACCESS_ROLES_ENDPOINT,
  REMOTE_ACCESS_USERS_ENDPOINT,
} from '@totem/utilities/endpoints';

export function* getRemoteAccessUsers(action) {
  try {
    const { page, limit, isEnabled } = action.data;

    const params = {
      ...(page && { page }),
      ...(limit && { limit }),
      ...(!R.isNil(isEnabled) && { isEnabled }),
    };

    const { data } = yield call(api.get, REMOTE_ACCESS_USERS_ENDPOINT, params);

    yield put(remoteAccessUserActions.getRemoteAccessUsersSuccess(data));
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      remoteAccessUserActions.getRemoteAccessUsersFailure(),
    );
  }
}

export function* addRemoteAccessUserToRole(action) {
  try {
    const { data: body } = action;
    // @ts-ignore
    const { data } = yield call(api.post, REMOTE_ACCESS_ROLES_ENDPOINT, body);

    yield put(
      remoteAccessUserActions.getRemoteAccessAddUserToRoleSuccess(data),
    );
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      remoteAccessUserActions.getRemoteAccessAddUserToRoleFailure,
    );

    if (error.response.status === 403) {
      yield put(
        notificationActions.createNotification({
          isError: true,
          message: 'User Add to Role Failed:  UNAUTHORIZED!',
          autoHideDuration: 2000,
        }),
      );
    }
  }
}

export function* removeRemoteAccessUserFromRole(action) {
  try {
    const { data: body } = action;
    // @ts-ignore
    const { data } = yield call(
      api.delete,
      `${REMOTE_ACCESS_ROLES_ENDPOINT}/${body.roleId}/${body.email}`,
      body,
    );

    yield put(
      remoteAccessUserActions.getRemoteAccessRemoveUserFromRoleSuccess(data),
    );
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      remoteAccessUserActions.getRemoteAccessRemoveUserFromRoleFailure,
    );

    if (error.response.status === 403) {
      yield put(
        notificationActions.createNotification({
          isError: true,
          message: 'User Remove from Role Failed:  UNAUTHORIZED!',
          autoHideDuration: 2000,
        }),
      );
    }
  }
}

export function* getRemoteAccessOrgRoles() {
  try {
    // @ts-ignore
    const { data } = yield call(api.get, REMOTE_ACCESS_ORG_ROLES_ENDPOINT);

    yield put(remoteAccessUserActions.getRemoteAccessOrgRoleSuccess(data));
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      remoteAccessUserActions.getRemoteAccessOrgRoleFailure,
    );
  }
}

export function* getRemoteAccessRoleUsers(action) {
  try {
    const { roleId } = action.data;

    const { data } = yield call(
      api.get,
      `${REMOTE_ACCESS_USERS_ENDPOINT}/${roleId}`,
    );

    yield put(remoteAccessUserActions.getRemoteAccessRoleUsersSuccess(data));
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      remoteAccessUserActions.getRemoteAccessRoleUsersFailure(),
    );
  }
}

export function* getRemoteAccessUserRoles(action) {
  try {
    const { userId } = action.data;

    const { data } = yield call(
      api.get,
      `${REMOTE_ACCESS_ROLES_ENDPOINT}/${userId}`,
    );

    yield put(remoteAccessUserActions.getRemoteAccessUserRolesSuccess(data));
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      remoteAccessUserActions.getRemoteAccessUserRolesFailure(),
    );
  }
}

export function* removeRemoteAccessRoleFromUser(action) {
  try {
    const { data: body } = action;
    // @ts-ignore
    const { data } = yield call(
      api.delete,
      `${REMOTE_ACCESS_USERS_ENDPOINT}/${body.email}/${body.roleId}`,
      body,
    );

    yield put(
      remoteAccessUserActions.getRemoteAccessRemoveRoleFromUserSuccess(data),
    );
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      remoteAccessUserActions.getRemoteAccessRemoveRoleFromUserFailure,
    );

    if (error.response.status === 403) {
      yield put(
        notificationActions.createNotification({
          isError: true,
          message: 'Role Remove from User Failed:  UNAUTHORIZED!',
          autoHideDuration: 2000,
        }),
      );
    }
  }
}

export function* addRemoteAccessRoleToUser(action) {
  try {
    const { data: body } = action;
    // @ts-ignore
    const { data } = yield call(api.post, REMOTE_ACCESS_USERS_ENDPOINT, body);

    yield put(
      remoteAccessUserActions.getRemoteAccessAddRoleToUserSuccess(data),
    );
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      remoteAccessUserActions.getRemoteAccessAddRoleToUserFailure,
    );

    if (error.response.status === 403) {
      yield put(
        notificationActions.createNotification({
          isError: true,
          message: 'Role Add to User Failed:  UNAUTHORIZED!',
          autoHideDuration: 2000,
        }),
      );
    }
  }
}

export function* addRemoteAccessRoleToOrganization(action) {
  try {
    const { data: body } = action;
    // @ts-ignore
    const { data } = yield call(
      api.post,
      REMOTE_ACCESS_ORG_ROLES_ENDPOINT,
      body,
    );

    yield put(
      remoteAccessUserActions.getRemoteAccessAddRoleToOrganizationSuccess(data),
    );
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      remoteAccessUserActions.getRemoteAccessAddRoleToOrganizationFailure,
    );
  }
}

export function* sendPasswordReset(action) {
  try {
    const { data: body } = action;
    // @ts-ignore
    yield call(api.post, REMOTE_ACCESS_PASSWORD_RESET, body);
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      remoteAccessUserActions.getRemoteAccessAddRoleToOrganizationFailure,
    );
  }
}

/* WATCHERS */
export function* retrieveRemoteAccessUsers() {
  yield takeEvery(
    actionTypes.REQUEST_REMOTE_ACCESS_USERS,
    getRemoteAccessUsers,
  );
}

export function* requestRemoteAccessAddUserToRole() {
  yield takeEvery(
    actionTypes.REQUEST_REMOTE_ACCESS_USER_ADD_TO_ROLE,
    addRemoteAccessUserToRole,
  );
}

export function* requestRemoteAccessRemoveUserFromRole() {
  yield takeEvery(
    actionTypes.REQUEST_REMOTE_ACCESS_USER_REMOVE_FROM_ROLE,
    removeRemoteAccessUserFromRole,
  );
}

export function* retrieveRemoteAccessOrgRoles() {
  yield takeEvery(
    actionTypes.REQUEST_REMOTE_ACCESS_ORG_ROLES,
    getRemoteAccessOrgRoles,
  );
}

export function* retrieveRemoteAccessOrgRolesAuto() {
  yield takeEvery(actionTypes.GET_VERSIONS_SUCCESS, getRemoteAccessOrgRoles);
}

export function* retrieveRemoteAccessRoleUsers() {
  yield takeEvery(
    actionTypes.REQUEST_REMOTE_ACCESS_ROLE_USERS,
    getRemoteAccessRoleUsers,
  );
}

export function* retrieveRemoteAccessUserRoles() {
  yield takeEvery(
    actionTypes.REQUEST_REMOTE_ACCESS_USER_ROLES,
    getRemoteAccessUserRoles,
  );
}

export function* requestRemoteAccessRemoveRoleFromUser() {
  yield takeEvery(
    actionTypes.REQUEST_REMOTE_ACCESS_ROLE_REMOVE_FROM_USER,
    removeRemoteAccessRoleFromUser,
  );
}

export function* requestRemoteAccessAddRoleToUser() {
  yield takeEvery(
    actionTypes.REQUEST_REMOTE_ACCESS_ROLE_ADD_TO_USER,
    addRemoteAccessRoleToUser,
  );
}

export function* requestRemoteAccessAddRoleToOrganization() {
  yield takeEvery(
    actionTypes.REQUEST_REMOTE_ACCESS_ROLE_ADD,
    addRemoteAccessRoleToOrganization,
  );
}

export function* requestSendPasswordReset() {
  yield takeEvery(
    actionTypes.REQUEST_REMOTE_ACCESS_PASSWORD_RESET,
    sendPasswordReset,
  );
}

export default [
  retrieveRemoteAccessUsers,
  requestRemoteAccessAddUserToRole,
  requestRemoteAccessRemoveUserFromRole,
  retrieveRemoteAccessOrgRoles,
  retrieveRemoteAccessOrgRolesAuto,
  retrieveRemoteAccessRoleUsers,
  retrieveRemoteAccessUserRoles,
  requestRemoteAccessRemoveRoleFromUser,
  requestRemoteAccessAddRoleToUser,
  requestRemoteAccessAddRoleToOrganization,
  requestSendPasswordReset,
];
