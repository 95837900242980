import {
  Product,
  ProductReportFilters,
  ProductReportInput,
} from '@totem/components/endoflife/types';
import { Filter } from '@totem/types/common';

export const buildFilters = (input: ProductReportInput) => {
  const paramFilters: Filter[] = [];

  if (
    typeof input.productType !== 'undefined' &&
    input.productType !== null &&
    input.productType.length > 0
  ) {
    paramFilters.push({
      field: 'productType',
      values: input.productType,
    });
  }
  if (
    typeof input.product !== 'undefined' &&
    input.product !== null &&
    input.product.length > 0
  ) {
    paramFilters.push({
      field: 'product',
      values: input.product,
    });
  }
  if (
    typeof input.name !== 'undefined' &&
    input.name !== null &&
    input.name.length > 0
  ) {
    paramFilters.push({
      field: 'name',
      values: input.name,
    });
  }
  if (
    typeof input.cycle !== 'undefined' &&
    input.cycle !== null &&
    input.cycle.length > 0
  ) {
    paramFilters.push({
      field: 'cycle',
      values: input.cycle,
    });
  }
  if (
    typeof input.latestVersion !== 'undefined' &&
    input.latestVersion !== null &&
    input.latestVersion.length > 0
  ) {
    paramFilters.push({
      field: 'latestVersion',
      values: input.latestVersion,
    });
  }
  if (
    typeof input.releaseDate !== 'undefined' &&
    input.releaseDate !== null &&
    input.releaseDate.length > 0
  ) {
    paramFilters.push({
      field: 'releaseDate',
      values: input.releaseDate,
    });
  }
  if (
    typeof input.endOfSupportDate !== 'undefined' &&
    input.endOfSupportDate !== null &&
    input.endOfSupportDate.length > 0
  ) {
    paramFilters.push({
      field: 'endOfSupportDate',
      values: input.endOfSupportDate,
    });
  }
  if (
    typeof input.endOfLifeDate !== 'undefined' &&
    input.endOfLifeDate !== null &&
    input.endOfLifeDate.length > 0
  ) {
    paramFilters.push({
      field: 'endOfLifeDate',
      values: input.endOfLifeDate,
    });
  }
  if (
    typeof input.discontinuedDate !== 'undefined' &&
    input.discontinuedDate !== null &&
    input.discontinuedDate.length > 0
  ) {
    paramFilters.push({
      field: 'discontinuedDate',
      values: input.discontinuedDate,
    });
  }

  if (
    typeof input.isPastEndOfSupport !== 'undefined' &&
    input.isPastEndOfSupport !== null &&
    input.isPastEndOfSupport !== ''
  ) {
    paramFilters.push({
      field: 'isPastEndOfSupport',
      values: [input.isPastEndOfSupport],
    });
  }
  if (
    typeof input.isPastEndOfLife !== 'undefined' &&
    input.isPastEndOfLife !== null &&
    input.isPastEndOfLife !== ''
  ) {
    paramFilters.push({
      field: 'isPastEndOfLife',
      values: [input.isPastEndOfLife],
    });
  }
  if (
    typeof input.isDiscontinued !== 'undefined' &&
    input.isDiscontinued !== null &&
    input.isDiscontinued !== ''
  ) {
    paramFilters.push({
      field: 'isDiscontinued',
      values: [input.isDiscontinued],
    });
  }

  return paramFilters;
};

export const addFilters = (
  currentFilters: Filter[],
  input: ProductReportFilters,
) => {
  if (typeof input !== 'undefined') {
    if (
      typeof input.productType !== 'undefined' &&
      input.productType !== null &&
      input.productType.length > 0
    ) {
      currentFilters.push({
        field: 'productType',
        values: input.productType,
      });
    }
    if (
      typeof input.product !== 'undefined' &&
      input.product !== null &&
      input.product.length > 0
    ) {
      currentFilters.push({
        field: 'product',
        values: input.product,
      });
    }
    if (
      typeof input.name !== 'undefined' &&
      input.name !== null &&
      input.name.length > 0
    ) {
      currentFilters.push({
        field: 'name',
        values: input.name,
      });
    }
    if (
      typeof input.cycle !== 'undefined' &&
      input.cycle !== null &&
      input.cycle.length > 0
    ) {
      currentFilters.push({
        field: 'cycle',
        values: input.cycle,
      });
    }
    if (
      typeof input.latestVersion !== 'undefined' &&
      input.latestVersion !== null &&
      input.latestVersion.length > 0
    ) {
      currentFilters.push({
        field: 'latestVersion',
        values: input.latestVersion,
      });
    }
    if (
      typeof input.releaseDate !== 'undefined' &&
      input.releaseDate !== null &&
      input.releaseDate.length > 0
    ) {
      currentFilters.push({
        field: 'releaseDate',
        values: input.releaseDate,
      });
    }
    if (
      typeof input.endOfSupportDate !== 'undefined' &&
      input.endOfSupportDate !== null &&
      input.endOfSupportDate.length > 0
    ) {
      currentFilters.push({
        field: 'endOfSupportDate',
        values: input.endOfSupportDate,
      });
    }
    if (
      typeof input.endOfLifeDate !== 'undefined' &&
      input.endOfLifeDate !== null &&
      input.endOfLifeDate.length > 0
    ) {
      currentFilters.push({
        field: 'endOfLifeDate',
        values: input.endOfLifeDate,
      });
    }
    if (
      typeof input.discontinuedDate !== 'undefined' &&
      input.discontinuedDate !== null &&
      input.discontinuedDate.length > 0
    ) {
      currentFilters.push({
        field: 'discontinuedDate',
        values: input.discontinuedDate,
      });
    }

    if (
      typeof input.isPastEndOfSupport !== 'undefined' &&
      input.isPastEndOfSupport !== null &&
      input.isPastEndOfSupport !== ''
    ) {
      currentFilters.push({
        field: 'isPastEndOfSupport',
        values: [input.isPastEndOfSupport],
      });
    }
    if (
      typeof input.isPastEndOfLife !== 'undefined' &&
      input.isPastEndOfLife !== null &&
      input.isPastEndOfLife !== ''
    ) {
      currentFilters.push({
        field: 'isPastEndOfLife',
        values: [input.isPastEndOfLife],
      });
    }
    if (
      typeof input.isDiscontinued !== 'undefined' &&
      input.isDiscontinued !== null &&
      input.isDiscontinued !== ''
    ) {
      currentFilters.push({
        field: 'isDiscontinued',
        values: [input.isDiscontinued],
      });
    }
  }

  return currentFilters;
};

export const getEmptyRecord = (): Product => {
  return {
    id: '',
    productType: '',
    product: '',
    name: '',
    codeName: '',
    releaseLabel: '',
    cycle: '',
    latestVersion: '',
    releaseDate: '',
    endOfSupportDate: '',
    isPastEndOfSupport: false,
    endOfLifeDate: '',
    isPastEndOfLife: false,
    discontinuedDate: '',
    isDiscontinued: false,
    links: [],
    aliases: [],
  };
};
