import React, { useContext } from 'react';

import DropdownFilter from '@totem/components/common/dropdownFilter/DropdownFilter';
import FiltersChipsContainer from '@totem/components/FilterChipsContainer';
import FiltersBody from '@totem/components/FiltersBody';
import FiltersContainer from '@totem/components/FiltersContainer';
import FiltersTitle from '@totem/components/FiltersTitle';
import AnalysisReportContext from '@totem/components/surveyV2/analysisReport/analysisReportContext';
import { AnalysisReportInput } from '@totem/components/surveyV2/analysisReport/types';
import AnalysisReportFilterChips from '@totem/components/surveyV2/findingsReport/AnalysisReportFilterChips';
import { isNotNull } from '@totem/utilities/common';

const styles = {
  dropdown: {
    marginRight: '2rem',
  },
};

type Props = {
  title?: string;
};

const AnalysisReportFilterPanel = ({ title }: Props) => {
  const { filterOptions, setInput, input } = useContext(AnalysisReportContext);

  const handleMultiStringFilterChange = (
    property: keyof AnalysisReportInput,
    update: string[],
  ) => {
    setInput({
      ...input,
      [property]: update.map(String),
    });
  };

  return (
    <FiltersContainer>
      <FiltersTitle>
        {typeof title !== 'undefined' && title !== null && title !== ''
          ? title
          : 'Analysis Report'}
      </FiltersTitle>
      {isNotNull(filterOptions) && (
        <FiltersBody>
          <DropdownFilter
            label="Policy Name"
            options={filterOptions.policyNameOptions}
            value={input.policyName || []}
            onChange={(update) =>
              handleMultiStringFilterChange('policyName', update)
            }
            style={styles.dropdown}
          />
          <DropdownFilter
            label="Category"
            options={filterOptions.policyCategoryOptions}
            value={input.policyCategory || []}
            onChange={(update) =>
              handleMultiStringFilterChange('policyCategory', update)
            }
            style={styles.dropdown}
          />
          <DropdownFilter
            label="NIST"
            options={filterOptions.nistOptions}
            value={input.nist || []}
            onChange={(update) => handleMultiStringFilterChange('nist', update)}
            style={styles.dropdown}
          />
          <DropdownFilter
            label="Level of Effort"
            options={filterOptions.levelOfEffortOptions}
            value={input.levelOfEffort || []}
            onChange={(update) =>
              handleMultiStringFilterChange('levelOfEffort', update)
            }
            style={styles.dropdown}
          />
        </FiltersBody>
      )}
      <FiltersChipsContainer>
        <AnalysisReportFilterChips />
      </FiltersChipsContainer>
    </FiltersContainer>
  );
};

export default AnalysisReportFilterPanel;
