import React, { useCallback, useState } from 'react';
import { Button, message } from 'antd';
import Modal from 'antd/lib/modal/Modal';

import ModalFooter from '@totem/components/ModalFooter';
import ModalTitle from '@totem/components/ModalTitle';
import { getToken } from '@totem/utilities/accountUtilities';
import { AUTH_PROFILE_ENDPOINT } from '@totem/utilities/endpoints';

interface Props {
  userID: string;
  visible: boolean;
  isEnrolled: boolean;
  onEnrollmentUpdated: () => void;
  onClose: () => void;
}

interface EnrollmentResponse {
  ticket_id: string;
  ticket_url: string;
}

const EnrollmentDialog = ({
  userID,
  isEnrolled,
  onEnrollmentUpdated,
  onClose,
  visible,
}: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const sendRemoveMultiFactorEnrollment = useCallback(async () => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);

    fetch(`${AUTH_PROFILE_ENDPOINT}/multifactor/${userID}`, {
      method: 'DELETE',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      }),
    }).then((res) => {
      setIsLoading(false);
      if (res.status >= 400) {
        message.error(
          'A problem occurred while removing multi-factor enrollment!',
          3,
        );
      } else {
        message.info('Your multi-factor enrollment has been removed!', 3);
        onEnrollmentUpdated();
      }
    });
  }, []);

  const sendStartMultiFactorEnrollment = useCallback(async () => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);

    fetch(`${AUTH_PROFILE_ENDPOINT}/multifactor/${userID}`, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      }),
    })
      .then((res) => {
        setIsLoading(false);
        if (res.status >= 400) {
          message.error(
            'A problem occurred while requesting multi-factor enrollment!',
            3,
          );
        }
        return res;
      })
      .then((res) => res.json())
      .then((result: EnrollmentResponse) => {
        if (
          typeof result.ticket_id !== 'undefined' &&
          result.ticket_id !== null &&
          result.ticket_id !== ''
        ) {
          message.info('Multi-Factor enrollment email has been sent!', 3);
          onEnrollmentUpdated();
        }
      });
  }, []);

  const handleRemoveMultiFactorEnrollment = () => {
    sendRemoveMultiFactorEnrollment();
  };

  const handleBeginMultiFactorEnrollment = () => {
    sendStartMultiFactorEnrollment();
  };

  return (
    <>
      {isEnrolled ? (
        <Modal
          title={<ModalTitle>Remove Multi-Factor Enrollment?</ModalTitle>}
          onCancel={onClose}
          open={visible && isEnrolled}
          footer={
            <ModalFooter>
              <Button onClick={onClose}>Cancel</Button>
              <Button
                onClick={handleRemoveMultiFactorEnrollment}
                loading={isLoading}
                type="primary"
              >
                Yes, Remove Enrollment
              </Button>
            </ModalFooter>
          }
        >
          Remove current multi-factor enrollment? &nbsp;After removing
          enrollment, you will have the option to re-enroll selecting either SMS
          Authenticator application.
        </Modal>
      ) : (
        <Modal
          title={<ModalTitle>Begin Multi-Factor Enrollment?</ModalTitle>}
          onCancel={onClose}
          open={visible && !isEnrolled}
          footer={
            <ModalFooter>
              <Button onClick={onClose}>Cancel</Button>
              <Button
                onClick={handleBeginMultiFactorEnrollment}
                loading={isLoading}
                type="primary"
              >
                Yes, Begin Enrollment
              </Button>
            </ModalFooter>
          }
        >
          Enroll in multi-factor authentication? If you choose to enroll, you
          will be sent an enrollment email to the address matching your login.
        </Modal>
      )}
    </>
  );
};

export default EnrollmentDialog;
