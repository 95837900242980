import { createContext } from 'react';

import {
  Analysis,
  AnalysisInput,
  AnalysisPolicy,
} from '@totem/components/surveyV2/analysis/types';

interface Context {
  input: AnalysisInput;
  setInput: (input: AnalysisInput) => void;
  data: Analysis;
  filteredData: AnalysisPolicy[];
  loading: boolean;
  setIsLoading: (isLoading: boolean) => void;
}

export default createContext<Context>({
  input: {},
  setInput: () => {},
  data: null,
  filteredData: null,
  loading: false,
  setIsLoading: () => {},
});
