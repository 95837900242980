import React, { useContext, useState } from 'react';
import { Button } from 'antd';

import AddEditDialog from '@totem/components/buildingsCommon/AddEditDialog';
import { getNewBuildingData } from '@totem/components/buildingsCommon/types';
import BuildingSummaryContext from '@totem/components/buildingSummary/buildingSummaryContext';
import authUtilities from '@totem/utilities/authUtilities';

const AddBuildingButton = () => {
  const [open, setOpen] = useState<boolean>(false);
  const { loading, onAction } = useContext(BuildingSummaryContext);
  const isDisabled = !authUtilities.minimumRequiredRole(
    authUtilities.ROLE_SETS.ORGANIZATION_ADMINISTRATOR,
  );

  const handleCloseDialog = (refresh: boolean) => {
    setOpen(false);
    if (refresh) {
      onAction('refresh');
    }
  };

  return (
    <>
      <Button type="primary" onClick={() => setOpen(true)}>
        Add Building
      </Button>
      {open && !isDisabled && (
        <AddEditDialog
          open={open}
          loading={loading}
          buildingData={getNewBuildingData()}
          onClose={handleCloseDialog}
        />
      )}
    </>
  );
};

export default AddBuildingButton;
