import React, { useContext, useEffect, useState } from 'react';
import { Card } from 'antd';

import Count from '@totem/components/common/Count';
import SummaryContext from '@totem/components/portfolio/summary/summaryContext';
import colors from '@totem/styles/colors';
import { isNotNull } from '@totem/utilities/common';

import './summary.css';

const styles = {
  card: {
    margin: '2rem 0',
    width: '100%',
  },
  cardHeader: {
    borderTop: `5px solid`,
    borderImage: `${colors.gradient.accent} 100% 0 0 0`,
  },
  cardBody: {
    paddingTop: 0,
    borderTop: `1px solid ${colors.antd.borderGray}`,
    height: '34rem',
  },
};

const PortfolioCounts = () => {
  const { filteredRegions } = useContext(SummaryContext);
  const [regionCount, setRegionCount] = useState<number>(0);
  const [buildingCount, setBuildingCount] = useState<number>(0);
  const [controlSystemCount, setControlSystemCount] = useState<number>(0);

  useEffect(() => {
    if (isNotNull(filteredRegions)) {
      setRegionCount(filteredRegions.length);
      let totalBuildings = 0;
      let totalControlSystems = 0;
      for (let idx = 0; idx < filteredRegions.length; idx++) {
        totalBuildings += filteredRegions[idx].buildings.length;
        for (let bIdx = 0; bIdx < filteredRegions[idx].buildings.length; bIdx++) {
          totalControlSystems +=
            filteredRegions[idx].buildings[bIdx].controlSystems.length;
        }
      }
      setBuildingCount(totalBuildings);
      setControlSystemCount(totalControlSystems);
    }
  }, [filteredRegions]);

  return (
    <Card
      title={<div styleName="card-header-title">Summary</div>}
      style={styles.card}
      bodyStyle={styles.cardBody}
    >
      <div styleName="details-container">
        <div styleName="details-label">Regions</div>
        <Count count={regionCount} size="large" />
      </div>
      <div styleName="details-container">
        <div styleName="details-label">Buildings</div>
        <Count count={buildingCount} size="large" />
      </div>
      <div styleName="details-container">
        <div styleName="details-label">Control Systems</div>
        <Count count={controlSystemCount} size="large" />
      </div>
    </Card>
  );
};

export default PortfolioCounts;
