import { createContext } from 'react';

import {
  BuildingsReport,
  BuildingSummaryInput,
} from '@totem/components/buildingSummary/types';

interface Context {
  input: BuildingSummaryInput;
  setInput: (input: {
    sortDirection?: string;
    regionId?: string[];
    searchName?: string;
    sortField?: string;
    name?: string[];
    pageSize?: number;
    page?: number;
    type?: string[];
    buildingId?: string[];
  }) => void;
  onAction?: (action: string) => void;
  loading: boolean;
  reportData: BuildingsReport;
  totalRecords: number;
}

export default createContext<Context>({
  input: {},
  setInput: () => {},
  onAction: () => {},
  loading: false,
  reportData: null,
  totalRecords: 0,
});
