import React from 'react';
import { CloseOutlined, FileTwoTone } from '@ant-design/icons';
import * as R from 'ramda';

import colors from '@totem/styles/colors';

import './fileTile.css';

type Props = {
  name: string;
  removeFile?: () => void;
  onClick?: () => void;
  small?: boolean;
  rightIcon?: any;
};

const FileTile = ({ name, onClick, small, rightIcon, removeFile }: Props) => {
  const fileIconStyle = {
    ...(small && { width: 14, height: 14 }),
  };

  const closeIconStyle = {
    color: colors.neutral.dim,
  };

  return (
    <div onClick={onClick} styleName={`tile-container ${small ? 'small' : ''}`}>
      <div styleName={`tile-icon-container ${small ? 'small' : ''}`}>
        <FileTwoTone style={fileIconStyle} twoToneColor={colors.brand.blue} />
      </div>
      <div styleName={`tile-title ${small ? 'small' : ''}`}>{name}</div>
      {!R.isNil(removeFile) && (
        <div styleName={`tile-icon-container ${small ? 'small' : ''}`}>
          <CloseOutlined onClick={removeFile} style={closeIconStyle} />
        </div>
      )}
      {rightIcon && (
        <div styleName={`tile-icon-container ${small ? 'small' : ''}`}>
          {rightIcon}
        </div>
      )}
    </div>
  );
};

export default FileTile;
