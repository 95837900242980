import React from 'react';
import { Checkbox } from 'antd';
import * as R from 'ramda';

import UserAvatar from '@totem/components/common/avatar/UserAvatar';
import { Assignee } from '@totem/types/user';

import './index.css';

type Props = {
  assignee: Assignee;
  selectedEmails: Set<string>;
  toggleEmail: (email: string) => void;
};

const AuditAssigneeRow = ({ assignee, toggleEmail, selectedEmails }: Props) => {
  const hasName =
    !R.isEmpty(assignee.firstName) && !R.isEmpty(assignee.lastName);

  return (
    <div styleName="assignee-row">
      <div styleName="left-content">
        <UserAvatar user={assignee} />
        <div styleName="row-content">
          {hasName && <div>{`${assignee.firstName} ${assignee.lastName}`}</div>}
          <div styleName="assignee-subtext">{assignee.email}</div>
        </div>
      </div>
      <Checkbox
        checked={selectedEmails.has(assignee.email)}
        onChange={() => toggleEmail(assignee.email)}
      />
    </div>
  );
};

export default AuditAssigneeRow;
