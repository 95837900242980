import React, { useContext } from 'react';

import RemoteAccessUserContext from '@totem/components/remoteAccess/roleUsers/RemoteAccessUserContext';

const RoleTitle = () => {
  const { data } = useContext(RemoteAccessUserContext);

  const getRoleName = () => {
    if (typeof data !== 'undefined' && data !== null) {
      return data.orgRole;
    }
    return '';
  };

  return <div>Remote Access Users - {getRoleName()}</div>;
};

export default RoleTitle;
