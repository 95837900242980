/* eslint-disable no-fallthrough */
import actionTypes from '@totem/actions/actionTypes';

import initialState from './initialState';

export default function loadingReducer(state = initialState.loading, action) {
  switch (action.type) {
    case actionTypes.REQUEST_BUILDING_DELETE: {
      return { ...state, loadingBuildingDelete: true };
    }
    case actionTypes.BUILDING_DELETE_SUCCESS: {
      return { ...state, loadingBuildingDelete: false };
    }
    case actionTypes.BUILDING_DELETE_FAILURE: {
      return {
        ...state,
        loadingBuildingDelete: false,
      };
    }
    case actionTypes.REQUEST_UPLOAD_ADDITIONAL_DEVICE_CONFIGURATION: {
      return { ...state, loadingAnalysisDetail: true };
    }
    case actionTypes.UPLOAD_ADDITIONAL_DEVICE_CONFIGURATION_SUCCESS: {
      return { ...state, loadingAnalysisDetail: false };
    }
    case actionTypes.UPLOAD_ADDITIONAL_DEVICE_CONFIGURATION_FAILURE: {
      return { ...state, loadingAnalysisDetail: false };
    }
    case actionTypes.REQUEST_BUILDINGS: {
      return { ...state, loadingBuildings: true };
    }
    case actionTypes.BUILDINGS_GET_SUCCESS:
    case actionTypes.BUILDINGS_GET_FAILURE: {
      return { ...state, loadingBuildings: false };
    }
    case actionTypes.REQUEST_BUILDING_UPDATE: {
      return { ...state, loadingBuildingUpdate: true };
    }
    case actionTypes.BUILDING_UPDATE_SUCCESS:
    case actionTypes.BUILDING_UPDATE_FAILURE: {
      return { ...state, loadingBuildingUpdate: false };
    }
    case actionTypes.REQUEST_REGIONS: {
      return { ...state, loadingRegions: true };
    }
    case actionTypes.REGIONS_GET_SUCCESS:
    case actionTypes.REGIONS_GET_FAILURE: {
      return { ...state, loadingRegions: false };
    }
    case actionTypes.REQUEST_REGION_UPDATE: {
      return { ...state, loadingRegionUpdate: true };
    }
    case actionTypes.REGION_UPDATE_SUCCESS:
    case actionTypes.REGION_UPDATE_FAILURE: {
      return { ...state, loadingRegionUpdate: false };
    }
    case actionTypes.REQUEST_CREATE_REGION: {
      return { ...state, loadingRegionCreation: true };
    }
    case actionTypes.CREATE_REGION_SUCCESS:
    case actionTypes.CREATE_REGION_FAILURE: {
      return { ...state, loadingRegionCreation: false };
    }
    case actionTypes.REQUEST_LOGIN: {
      return { ...state, loadingUserLogin: true };
    }
    // @ts-ignore
    case actionTypes.LOGIN_ERROR: {
      return { ...state, loadingUserLogin: false };
    }
    // @ts-ignore
    case actionTypes.REQUEST_REGISTRATION: {
      return { ...state, loadingRegistration: true };
    }
    // @ts-ignore
    case actionTypes.REGISTRATION_ERROR: {
      return { ...state, loadingRegistration: false };
    }

    case actionTypes.REQUEST_QUESTIONNAIRE_TEMPLATES: {
      return { ...state, loadingQuestionnaireTemplates: true };
    }
    case actionTypes.REQUEST_QUESTIONNAIRE_TEMPLATES_SUCCESS:
    case actionTypes.REQUEST_QUESTIONNAIRE_TEMPLATES_FAILURE: {
      return { ...state, loadingQuestionnaireTemplates: false };
    }
    case actionTypes.REQUEST_CREATE_QUESTIONNAIRE_TEMPLATE: {
      return { ...state, loadingQuestionnaireTemplateCreation: true };
    }
    case actionTypes.CREATE_QUESTIONNAIRE_TEMPLATE_SUCCESS:
    case actionTypes.CREATE_QUESTIONNAIRE_TEMPLATE_FAILURE: {
      return { ...state, loadingQuestionnaireTemplateCreation: false };
    }
    case actionTypes.REQUEST_QUESTIONNAIRE_TEMPLATE_DETAIL: {
      return { ...state, loadingQuestionnaireTemplateDetail: true };
    }
    case actionTypes.QUESTIONNAIRE_TEMPLATE_DETAIL_SUCCESS:
    case actionTypes.QUESTIONNAIRE_TEMPLATE_DETAIL_FAILURE: {
      return { ...state, loadingQuestionnaireTemplateDetail: false };
    }
    case actionTypes.REQUEST_QUESTIONNAIRE_TEMPLATE_DELETE: {
      return { ...state, loadingQuestionnaireTemplateDeletion: true };
    }
    case actionTypes.QUESTIONNAIRE_TEMPLATE_DELETE_SUCCESS:
    case actionTypes.QUESTIONNAIRE_TEMPLATE_DELETE_FAILURE: {
      return { ...state, loadingQuestionnaireTemplateDeletion: false };
    }
    case actionTypes.REQUEST_QUESTIONNAIRE_TEMPLATE_CONFIGURATION: {
      return { ...state, loadingQuestionnaireTemplateConfiguration: true };
    }
    case actionTypes.QUESTIONNAIRE_TEMPLATE_CONFIGURATION_SUCCESS:
    case actionTypes.QUESTIONNAIRE_TEMPLATE_CONFIGURATION_FAILURE: {
      return { ...state, loadingQuestionnaireTemplateConfiguration: false };
    }
    case actionTypes.REQUEST_QUESTIONNAIRE_INSTANCES: {
      return { ...state, loadingQuestionnaireInstances: true };
    }
    case actionTypes.GET_QUESTIONNAIRE_INSTANCES_SUCCESS:
    case actionTypes.GET_QUESTIONNAIRE_INSTANCES_FAILURE: {
      return { ...state, loadingQuestionnaireInstances: false };
    }
    case actionTypes.REQUEST_CREATE_QUESTIONNAIRE_INSTANCE: {
      return { ...state, loadingQuestionnaireInstanceCreation: true };
    }
    case actionTypes.CREATE_QUESTIONNAIRE_INSTANCE_SUCCESS:
    case actionTypes.CREATE_QUESTIONNAIRE_INSTANCE_FAILURE: {
      return { ...state, loadingQuestionnaireInstanceCreation: false };
    }
    case actionTypes.REQUEST_QUESTIONNAIRE_INSTANCE_DETAIL: {
      return { ...state, loadingQuestionnaireInstanceDetail: true };
    }
    case actionTypes.GET_QUESTIONNAIRE_INSTANCE_DETAIL_SUCCESS:
    case actionTypes.GET_QUESTIONNAIRE_INSTANCE_DETAIL_FAILURE: {
      return { ...state, loadingQuestionnaireInstanceDetail: false };
    }
    case actionTypes.REQUEST_QUESTIONNAIRE_INSTANCE_SUBMIT: {
      return { ...state, loadingQuestionnaireInstanceSubmit: true };
    }
    case actionTypes.QUESTIONNAIRE_INSTANCE_SUBMIT_SUCCESS:
    case actionTypes.QUESTIONNAIRE_INSTANCE_SUBMIT_FAILURE: {
      return { ...state, loadingQuestionnaireInstanceSubmit: false };
    }
    case actionTypes.REQUEST_QUESTIONNAIRE_INSTANCE_UPDATE: {
      return { ...state, loadingQuestionnaireInstanceUpdate: true };
    }
    case actionTypes.QUESTIONNAIRE_INSTANCE_UPDATE_SUCCESS:
    case actionTypes.QUESTIONNAIRE_INSTANCE_UPDATE_FAILURE: {
      return { ...state, loadingQuestionnaireInstanceUpdate: false };
    }

    case actionTypes.REQUEST_SURVEY_TEMPLATES: {
      return { ...state, loadingSurveyTemplates: true };
    }
    case actionTypes.REQUEST_SURVEY_TEMPLATES_SUCCESS:
    case actionTypes.REQUEST_SURVEY_TEMPLATES_FAILURE: {
      return { ...state, loadingSurveyTemplates: false };
    }
    case actionTypes.REQUEST_CREATE_SURVEY_TEMPLATE: {
      return { ...state, loadingSurveyTemplateCreation: true };
    }
    case actionTypes.CREATE_SURVEY_TEMPLATE_SUCCESS:
    case actionTypes.CREATE_SURVEY_TEMPLATE_FAILURE: {
      return { ...state, loadingSurveyTemplateCreation: false };
    }
    case actionTypes.REQUEST_SURVEY_TEMPLATE_DETAIL: {
      return { ...state, loadingSurveyTemplateDetail: true };
    }
    case actionTypes.SURVEY_TEMPLATE_DETAIL_SUCCESS:
    case actionTypes.SURVEY_TEMPLATE_DETAIL_FAILURE: {
      return { ...state, loadingSurveyTemplateDetail: false };
    }
    case actionTypes.REQUEST_SURVEY_TEMPLATE_DELETE: {
      return { ...state, loadingSurveyTemplateDeletion: true };
    }
    case actionTypes.SURVEY_TEMPLATE_DELETE_SUCCESS:
    case actionTypes.SURVEY_TEMPLATE_DELETE_FAILURE: {
      return { ...state, loadingSurveyTemplateDeletion: false };
    }
    case actionTypes.REQUEST_SURVEY_TEMPLATE_CONFIGURATION: {
      return { ...state, loadingSurveyTemplateConfiguration: true };
    }
    case actionTypes.SURVEY_TEMPLATE_CONFIGURATION_SUCCESS:
    case actionTypes.SURVEY_TEMPLATE_CONFIGURATION_FAILURE: {
      return { ...state, loadingSurveyTemplateConfiguration: false };
    }
    case actionTypes.REQUEST_SURVEY_INSTANCES: {
      return { ...state, loadingSurveyInstances: true };
    }
    case actionTypes.GET_SURVEY_INSTANCES_SUCCESS:
    case actionTypes.GET_SURVEY_INSTANCES_FAILURE: {
      return { ...state, loadingSurveyInstances: false };
    }
    case actionTypes.REQUEST_CREATE_SURVEY_INSTANCE: {
      return { ...state, loadingSurveyInstanceCreation: true };
    }
    case actionTypes.CREATE_SURVEY_INSTANCE_SUCCESS:
    case actionTypes.CREATE_SURVEY_INSTANCE_FAILURE: {
      return { ...state, loadingSurveyInstanceCreation: false };
    }
    case actionTypes.REQUEST_SURVEY_INSTANCE_DETAIL: {
      return { ...state, loadingSurveyInstanceDetail: true };
    }
    case actionTypes.GET_SURVEY_INSTANCE_DETAIL_SUCCESS:
    case actionTypes.GET_SURVEY_INSTANCE_DETAIL_FAILURE: {
      return { ...state, loadingSurveyInstanceDetail: false };
    }
    case actionTypes.REQUEST_SURVEY_INSTANCE_SUBMIT: {
      return { ...state, loadingSurveyInstanceSubmit: true };
    }
    case actionTypes.SURVEY_INSTANCE_SUBMIT_SUCCESS:
    case actionTypes.SURVEY_INSTANCE_SUBMIT_FAILURE: {
      return { ...state, loadingSurveyInstanceSubmit: false };
    }
    case actionTypes.REQUEST_SURVEY_INSTANCE_UPDATE: {
      return { ...state, loadingSurveyInstanceUpdate: true };
    }
    case actionTypes.SURVEY_INSTANCE_UPDATE_SUCCESS:
    case actionTypes.SURVEY_INSTANCE_UPDATE_FAILURE: {
      return { ...state, loadingSurveyInstanceUpdate: false };
    }

    case actionTypes.REQUEST_MEDIA: {
      return { ...state, loadingMedia: true };
    }
    case actionTypes.GET_MEDIA_SUCCESS:
    case actionTypes.GET_MEDIA_FAILURE: {
      return { ...state, loadingMedia: false };
    }
    case actionTypes.REQUEST_UPLOAD_FILES: {
      return { ...state, loadingFileUpload: true };
    }
    case actionTypes.UPLOAD_FILES_SUCCESS:
    case actionTypes.UPLOAD_FILES_FAILURE: {
      return { ...state, loadingFileUpload: false };
    }

    case actionTypes.REQUEST_QUESTIONNAIRE_INSTANCE_PATCH: {
      return { ...state, loadingQuestionnaireInstancePatch: true };
    }
    case actionTypes.QUESTIONNAIRE_INSTANCE_PATCH_SUCCESS:
    case actionTypes.QUESTIONNAIRE_INSTANCE_PATCH_FAILURE: {
      return { ...state, loadingQuestionnaireInstancePatch: false };
    }

    case actionTypes.REQUEST_SURVEY_INSTANCE_PATCH: {
      return { ...state, loadingSurveyInstancePatch: true };
    }
    case actionTypes.SURVEY_INSTANCE_PATCH_SUCCESS:
    case actionTypes.SURVEY_INSTANCE_PATCH_FAILURE: {
      return { ...state, loadingSurveyInstancePatch: false };
    }

    case actionTypes.REQUEST_CREATE_HEALTH: {
      return { ...state, loadingHealthCreation: true };
    }
    case actionTypes.CREATE_HEALTH_SUCCESS:
    case actionTypes.CREATE_HEALTH_FAILURE: {
      return { ...state, loadingHealthCreation: false };
    }
    case actionTypes.REQUEST_HEALTH: {
      return { ...state, loadingHealth: true };
    }
    case actionTypes.GET_HEALTH_SUCCESS:
    case actionTypes.GET_HEALTH_FAILURE:
      return { ...state, loadingHealth: false };
    case actionTypes.REQUEST_QUESTIONNAIRE_INSTANCE_STATISTICS: {
      return { ...state, loadingQuestionnaireInstanceStatistics: true };
    }
    case actionTypes.GET_QUESTIONNAIRE_INSTANCE_STATISTICS_SUCCESS:
    case actionTypes.GET_QUESTIONNAIRE_INSTANCE_STATISTICS_FAILURE: {
      return { ...state, loadingQuestionnaireInstanceStatistics: false };
    }
    case actionTypes.REQUEST_QUESTIONNAIRE_INSTANCE_EXPORT: {
      return { ...state, loadingQuestionnaireCSVExport: true };
    }
    case actionTypes.QUESTIONNAIRE_INSTANCE_EXPORT_SUCCESS:
    case actionTypes.QUESTIONNAIRE_INSTANCE_EXPORT_FAILURE: {
      return { ...state, loadingQuestionnaireCSVExport: false };
    }
    case actionTypes.REQUEST_SURVEY_INSTANCE_STATISTICS: {
      return { ...state, loadingSurveyInstanceStatistics: true };
    }
    case actionTypes.GET_SURVEY_INSTANCE_STATISTICS_SUCCESS:
    case actionTypes.GET_SURVEY_INSTANCE_STATISTICS_FAILURE: {
      return { ...state, loadingSurveyInstanceStatistics: false };
    }
    case actionTypes.REQUEST_SURVEY_INSTANCE_EXPORT: {
      return { ...state, loadingSurveyCSVExport: true };
    }
    case actionTypes.SURVEY_INSTANCE_EXPORT_SUCCESS:
    case actionTypes.SURVEY_INSTANCE_EXPORT_FAILURE: {
      return { ...state, loadingSurveyCSVExport: false };
    }
    case actionTypes.REQUEST_POLICY_AUDIT: {
      return { ...state, loadingPolicyAuditDetail: true };
    }
    case actionTypes.GET_POLICY_AUDIT_SUCCESS:
    case actionTypes.GET_POLICY_AUDIT_FAILURE: {
      return { ...state, loadingPolicyAuditDetail: false };
    }
    case actionTypes.REQUEST_POLICY_AUDIT_PATCH: {
      return { ...state, loadingPolicyAuditDetailPatch: true };
    }
    case actionTypes.POLICY_AUDIT_PATCH_SUCCESS:
    case actionTypes.POLICY_AUDIT_PATCH_FAILURE: {
      return { ...state, loadingPolicyAuditDetailPatch: false };
    }
    case actionTypes.REQUEST_REMOTE_ACCESS_USERS:
    case actionTypes.REQUEST_REMOTE_ACCESS_USER_ADD_TO_ROLE:
    case actionTypes.REQUEST_REMOTE_ACCESS_USER_REMOVE_FROM_ROLE: {
      return { ...state, loadingRemoteAccessUsers: true };
    }
    case actionTypes.REQUEST_REMOTE_ACCESS_USERS_SUCCESS:
    case actionTypes.REQUEST_REMOTE_ACCESS_USERS_FAILED:
    case actionTypes.REQUEST_REMOTE_ACCESS_USER_ADD_TO_ROLE_SUCCESS:
    case actionTypes.REQUEST_REMOTE_ACCESS_USER_ADD_TO_ROLE_FAILED:
    case actionTypes.REQUEST_REMOTE_ACCESS_USER_REMOVE_FROM_ROLE_SUCCESS:
    case actionTypes.REQUEST_REMOTE_ACCESS_USER_REMOVE_FROM_ROLE_FAILED: {
      return { ...state, loadingRemoteAccessUsers: false };
    }
    default: {
      return state;
    }
  }
}
