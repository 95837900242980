import React from 'react';

import ContactSearchTiny from '@totem/components/contactSearch/ContactSearchTiny';
import { ComponentInstance } from '@totem/components/home/lib/types';
import EventSummary from '@totem/components/ui_components/info_widget/EventSummary';
import WidgetAuditsAndSurveys from '@totem/components/widgets/AuditsAndSurveys';
import WidgetCommissioningReport from '@totem/components/widgets/Commissioning/commissioningReport';
import WidgetContactSearch from '@totem/components/widgets/ContactSearch';
import WidgetControlSystemReport from '@totem/components/widgets/ControlSystemReport';
import WidgetEventSummary from '@totem/components/widgets/EventSummary';
import AssetsByManufacturer from '@totem/components/widgets/infoWidgets/AssetsByManufacturer';
import AssetsByOperatingSystem from '@totem/components/widgets/infoWidgets/AssetsByOperatingSystem';
import AssetsByType from '@totem/components/widgets/infoWidgets/AssetsByType';
import BuildingsSquareFootage from '@totem/components/widgets/infoWidgets/BuildingsSquareFootage';
import ControlSystemsByType from '@totem/components/widgets/infoWidgets/ControlSystemsByType';
import ProjectsByBuilding from '@totem/components/widgets/infoWidgets/ProjectByBuilding';
import TestWidget from '@totem/components/widgets/infoWidgets/TestWidget';
import TicketsByBuilding from '@totem/components/widgets/infoWidgets/TicketsByBuilding';
import TicketsByCategory from '@totem/components/widgets/infoWidgets/TicketsByCategory';
import TicketsByType from '@totem/components/widgets/infoWidgets/TicketsByType';
import WidgetMyTickets from '@totem/components/widgets/MyTickets';

type Props = {
  componentInstance: ComponentInstance;
};

const WidgetInstance = ({ componentInstance }: Props) => {
  const getWidget = () => {
    switch (componentInstance.componentId) {
      case '64f77fcfe54952d3bcffd343':
        return <WidgetEventSummary />;
      case '64f86ffd92f4b49844eea91b':
        return <WidgetControlSystemReport />;
      case '64f889e9a35676469f2d3985':
        return <WidgetMyTickets />;
      case '64f89a9e32ae165dedc2eca9':
        return <WidgetAuditsAndSurveys />;
      case '650deec71d139b4d301b1386':
        return <WidgetCommissioningReport />;
      case '651321729b8f61fe7ce1a567':
        return <TestWidget />;
      case '65147d94b095c8a5e8fdcc9b':
        return <EventSummary />;
      case '6515a80f80b0b26cce540e23':
        return <BuildingsSquareFootage />;
      case '6515ab7b2c1c8c43ecb12f53':
        return <ControlSystemsByType />;
      case '6515ca683716a194cef6bb5c':
        return <TicketsByType />;
      case '6515cb7557cc1d472b03ca20':
        return <TicketsByCategory />;
      case '6515e6fb8c113520d428ebac':
        return <TicketsByBuilding />;
      case '6515e7060bb85b0f5dbe6b3a':
        return <ProjectsByBuilding />;
      case '651d6b1e7db2b0463fecbcc7':
        return <AssetsByType />;
      case '651d6b789d6c1a327b3253d7':
        return <AssetsByOperatingSystem />;
      case '651d6bb585caaf1bec92527a':
        return <AssetsByManufacturer />;
      case '652de26cff396fa7e70b5d4a':
        return <WidgetContactSearch />;
      case '652eadcff96fe16b100fc05f':
        return <ContactSearchTiny />;
      default:
        return (
          <div>{componentInstance.title} implementation not available.</div>
        );
    }
  };

  return getWidget();
};

export default WidgetInstance;
