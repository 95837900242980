import React, { useContext } from 'react';

import CveListSmall from '@totem/components/software/cves/cveListSmall';
import CvesContainer from '@totem/components/software/cves/cvesContainer';
import PackageContext from '@totem/components/software/software_package/packageContext';

type Props = {
  version?: string;
};

const PackageVulnerabilities = ({ version }: Props) => {
  const { data } = useContext(PackageContext);

  return (
    <CvesContainer
      ids={
        typeof data !== 'undefined' && data !== null && data.package !== null
          ? data.package.vulnerabilities
          : []
      }
      version={version}
    >
      <CveListSmall />
    </CvesContainer>
  );
};

export default PackageVulnerabilities;
