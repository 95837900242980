import React, { ReactNode, useContext } from 'react';

import SquareFootageContainer from '@totem/components/buildings/squareFootage/SquareFootageContainer';
import BuildingSummaryContext from '@totem/components/buildingSummary/buildingSummaryContext';
import { BuildingSummaryFilters } from '@totem/components/buildingSummary/types';

type Props = {
  staticFilters?: BuildingSummaryFilters;
  children?: ReactNode;
};

const SquareFootageWrapper = ({ staticFilters, children }: Props) => {
  const { input } = useContext(BuildingSummaryContext);
  return (
    <SquareFootageContainer staticFilters={staticFilters} input={input}>
      {children}
    </SquareFootageContainer>
  );
};

export default SquareFootageWrapper;
