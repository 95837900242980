import React from 'react';
import { Col, Row } from 'antd';

import BasePane from '@totem/components/BasePane';
import ContentLayout from '@totem/components/ContentLayout';
import PrimaryContentPane from '@totem/components/PrimaryContentPane';
import OverviewPane from '@totem/components/surveyV2/instances/OverviewPane';
import SurveyHealthPane from '@totem/components/surveyV2/instances/SurveyHealthPane';
import SurveyInstanceContainer from '@totem/components/surveyV2/instances/SurveyInstanceContainer';
import SurveyInstanceHeader from '@totem/components/surveyV2/instances/SurveyInstanceHeader';
import SurveyInstanceTable from '@totem/components/surveyV2/instances/SurveyInstanceTable';
import TemplatesContainer from '@totem/components/surveyV2/templates/TemplatesContainer';

import '../survey.css';
const SurveyInstancesPage = () => {
  return (
    <ContentLayout>
      <TemplatesContainer>
        <SurveyInstanceContainer>
          <BasePane>
            <Row gutter={[16, 16]}>
              <Col span={8}>
                <SurveyHealthPane />
              </Col>
              <Col span={16}>
                <OverviewPane />
              </Col>
            </Row>
            <br />
            <PrimaryContentPane>
              <SurveyInstanceHeader />
              <SurveyInstanceTable />
            </PrimaryContentPane>
          </BasePane>
        </SurveyInstanceContainer>
      </TemplatesContainer>
    </ContentLayout>
  );
};

export default SurveyInstancesPage;
