import { useEffect, useState } from 'react';
import * as R from 'ramda';

interface UseConnectionParams<T> {
  data: any;
  // accessor is an optional function to return the connection from arbitrary data
  accessor?: (data: any) => T;
  initialData?: T;
}

// useConnection is a hook that can be used in conjunction with a GQL query that returns a connection
// its main use is to ensure that data is stored in state until a new set of data is returned by useQuery
// this solves a problem where useQuery returns undefined data when loading a new paginated data set
export const useConnection = <T>({
  data,
  accessor,
  initialData,
}: UseConnectionParams<T>): T | undefined => {
  const [connection, setConnection] = useState<T>(initialData);

  useEffect(() => {
    if (data && !R.isEmpty(data)) {
      setConnection(accessor ? accessor(data) : data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return connection;
};
