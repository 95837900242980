import React, { useContext, useState } from 'react';
import { Button, Form, Input } from 'antd';

import ReferenceSelector, {
  EMPTY_ID,
} from '@totem/components/common/reference/ReferenceSelector';
import UserProfileContext from '@totem/components/UserProfileContext';
import colors from '@totem/styles/colors';
import { ObjectReference, ValidationStatus } from '@totem/types/common';
import { APIConfiguration } from '@totem/types/nozomi';
const FormItem = Form.Item;
import '../../services.css';

const styles = {
  button: {
    background: colors.utility.error,
    color: colors.neutral.white,
  },
  errorIcon: {
    color: colors.utility.error,
    marginRight: '1rem',
    fontSize: '22px',
  },
  form: {
    width: '100%',
  },
  formItem: {
    paddingBottom: '0',
    marginBottom: '1rem',
  },
};

export interface Props {
  apiConfig: APIConfiguration;
  onSubmit: (APIConfiguration) => void;
  onCancel: () => void;
}

export interface FormStatus {
  baseUrl: ValidationStatus;
  username: ValidationStatus;
  password: ValidationStatus;
}

const defaultStatus = {
  baseUrl: {
    help: '',
    hasFeedback: false,
  },
  username: {
    help: '',
    hasFeedback: false,
  },
  password: {
    help: '',
    hasFeedback: false,
  },
};

const NozomiEditForm = ({ apiConfig, onSubmit, onCancel }: Props) => {
  const { userProfile } = useContext(UserProfileContext);

  const [editApiConfig, setEditApiConfig] = useState<APIConfiguration>(
    apiConfig,
  );
  const [formStatus, setFormStatus] = useState<FormStatus>(defaultStatus);
  const [isFormSubmitted, setIsFormSubmitted] = useState<boolean>(false);

  const validateForm = () => {
    let isValid = true;

    if (!editApiConfig.data.connection.baseUrl.startsWith('http')) {
      isValid = false;
      setFormStatus({
        ...formStatus,
        baseUrl: {
          ...formStatus.baseUrl,
          hasFeedback: true,
          help: 'Base URL must be a valid URL',
        },
      });
    }
    if (editApiConfig.data.connection.username === '') {
      isValid = false;
      setFormStatus({
        ...formStatus,
        username: {
          ...formStatus.baseUrl,
          hasFeedback: true,
          help: 'Must provide a username',
        },
      });
    }
    if (editApiConfig.data.connection.password === '') {
      isValid = false;
      setFormStatus({
        ...formStatus,
        password: {
          ...formStatus.baseUrl,
          hasFeedback: true,
          help: 'Must provide a password',
        },
      });
    }

    return isValid;
  };

  const handleSubmit = () => {
    if (!isFormSubmitted) {
      setIsFormSubmitted(true);
    }
    if (validateForm()) {
      onSubmit(editApiConfig);
    }
  };

  const handleRegionChanged = (ref: ObjectReference) => {
    setEditApiConfig({
      ...editApiConfig,
      data: {
        ...editApiConfig.data,
        region: { ...editApiConfig.data.region, id: ref.id, name: ref.name },
      },
    });
  };

  const handleBuildingChanged = (ref: ObjectReference) => {
    setEditApiConfig({
      ...editApiConfig,
      data: {
        ...editApiConfig.data,
        building: {
          ...editApiConfig.data.building,
          id: ref.id,
          name: ref.name,
        },
      },
    });
  };

  const handleChange = ({
    target: { value, name },
  }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setEditApiConfig({
      ...editApiConfig,
      data: {
        ...editApiConfig.data,
        connection: {
          ...editApiConfig.data.connection,
          [name]: value,
          confirmed: false,
        },
      },
    });

    if (isFormSubmitted) {
      validateForm();
    }
  };

  return (
    <Form layout="vertical" style={styles.form}>
      <ReferenceSelector
        showOrganization={false}
        showRegion
        showBuilding
        showControlSystem={false}
        onRegionChanged={handleRegionChanged}
        onBuildingChanged={handleBuildingChanged}
        references={{
          organizationId: userProfile.organization.id,
          regionId: editApiConfig.data.region.id,
          buildingId: editApiConfig.data.building.id,
          controlSystemId: EMPTY_ID,
        }}
      />
      <FormItem
        label="Url"
        colon={false}
        style={styles.formItem}
        hasFeedback={formStatus.baseUrl.hasFeedback}
        validateStatus={formStatus.baseUrl.hasFeedback ? 'error' : 'success'}
        help={formStatus.baseUrl.help}
      >
        <Input
          name="baseUrl"
          type="text"
          value={editApiConfig.data.connection.baseUrl}
          onChange={handleChange}
        />
      </FormItem>
      <FormItem
        label="Username"
        colon={false}
        style={styles.formItem}
        hasFeedback={formStatus.username.hasFeedback}
        validateStatus={formStatus.username.hasFeedback ? 'error' : 'success'}
        help={formStatus.username.help}
      >
        <Input
          name="username"
          type="text"
          value={editApiConfig.data.connection.username}
          onChange={handleChange}
        />
      </FormItem>
      <FormItem
        label="Password"
        colon={false}
        style={styles.formItem}
        hasFeedback={formStatus.password.hasFeedback}
        validateStatus={formStatus.password.hasFeedback ? 'error' : 'success'}
        help={formStatus.password.help}
      >
        <Input
          name="password"
          type="text"
          value={editApiConfig.data.connection.password}
          onChange={handleChange}
        />
      </FormItem>
      <Button onClick={() => onCancel()}>Cancel</Button>
      &nbsp;
      <Button onClick={handleSubmit} type="primary">
        Submit
      </Button>
    </Form>
  );
};

export default NozomiEditForm;
