import React from 'react';

import ZoneMappingContainer from '@totem/components/administration/zoneMappings/ZoneMappingContainer';
import ZoneMappingTable from '@totem/components/administration/zoneMappings/ZoneMappingTable';

import '../../settings/settings.css';

const ZoneMappingPanel = () => {
  return (
    <div styleName="container">
      <div styleName="header-container">
        <div styleName="header">Zone Mapping</div>
      </div>
      <ZoneMappingContainer>
        <ZoneMappingTable />
      </ZoneMappingContainer>
    </div>
  );
};

export default ZoneMappingPanel;
