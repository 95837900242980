import React, { useContext } from 'react';
import { MergeCellsOutlined } from '@ant-design/icons';
import { Button } from 'antd';

import DeviceTypeContext from '@totem/components/deviceTypes/DeviceTypeContext';
import MergeDialog from '@totem/components/deviceTypes/MergeDialog';
import { isNotNull, isNull } from '@totem/utilities/common';

export interface Props {
  isPrimary?: boolean;
}
const MergeButton = ({ isPrimary }: Props) => {
  const { selectedDeviceTypes, action, onAction } =
    useContext(DeviceTypeContext);

  return (
    <>
      <Button
        icon={<MergeCellsOutlined />}
        type={
          typeof isPrimary !== 'undefined' && isPrimary ? 'primary' : 'default'
        }
        disabled={
          action === 'merge' ||
          isNull(selectedDeviceTypes) ||
          selectedDeviceTypes.length < 2
        }
        onClick={() => onAction('merge', null)}
      >
        Merge
      </Button>
      {action === 'merge' &&
        isNotNull(selectedDeviceTypes) &&
        selectedDeviceTypes.length > 1 && <MergeDialog />}
    </>
  );
};

export default MergeButton;
