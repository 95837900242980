import React from 'react';

import BasePane from '@totem/components/BasePane';
import CommissioningReport from '@totem/components/commissionReport/CommissioningReport';
import ContentLayout from '@totem/components/ContentLayout';
import PageSectionBody from '@totem/components/PageSectionBody';
import PrimaryContentPane from '@totem/components/PrimaryContentPane';

const CommissioningReportPage = () => {
  return (
    <ContentLayout>
      <BasePane>
        <PrimaryContentPane>
          <PageSectionBody>
            <br />
            <CommissioningReport />
          </PageSectionBody>
        </PrimaryContentPane>
      </BasePane>
    </ContentLayout>
  );
};

export default CommissioningReportPage;
