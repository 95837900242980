import React, { useContext, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import { TablePaginationConfig } from 'antd/lib/table';
import { SorterResult } from 'antd/lib/table/interface';
import * as R from 'ramda';
import { ColumnProps } from 'rc-table/lib/sugar/Column';

import Table from '@totem/components/common/table/Table';
import colors from '@totem/styles/colors';
import { ControlSystem, ControlSystemType } from '@totem/types/controlSystem';
import authUtilities from '@totem/utilities/authUtilities';
import controlSystemUtilities, {
  controlSystemTypeMap,
} from '@totem/utilities/controlSystemsUtilities';
import { removeIntegerKeys } from '@totem/utilities/enumUtilities';
import {
  getLimitAndOffsetFromTablePagination,
  getTablePagination,
} from '@totem/utilities/paginationUtilities';
import { stringifyArray } from '@totem/utilities/tableFilterUtilities';

import AutoCompleteFilter from '../common/autoCompleteFilter/AutoCompleteFilter';
import DeleteControlSystemModal from '../common/deleteControlSystemModal/DeleteControlSystemModal';

import ControlSystemEditModal from './ControlSystemEditModal';
import ControlSystemsContext from './ControlSystemsContext';

const styles = {
  criticalCount: {
    background: colors.neutral.white,
    border: `2px solid ${colors.brand.red}`,
  },
  grade: {
    margin: '0 auto',
  },
  button: {
    marginRight: '4px',
  },
  launched: {
    fontSize: '20px',
  },
  inactive: {
    color: colors.neutral.inactive,
  },
  tooltip: {
    width: '60rem',
  },
};

const CONTROL_SYSTEM_TYPE_FILTERS = Object.values(
  removeIntegerKeys(ControlSystemType),
).map((type) => ({
  text: controlSystemTypeMap[type]?.text,
  value: type,
}));

const ControlSystemsTable = () => {
  const {
    controlSystems,
    loading,
    totalCount,
    input,
    setInput,
    refetch,
    regionFilter,
    buildingFilter,
  } = useContext(ControlSystemsContext);

  const [selectedForDelete, setSelectedForDelete] = useState<
    ControlSystem | {}
  >({});

  const [selectedForEdit, setSelectedForEdit] = useState<ControlSystem | {}>(
    {},
  );

  const [regionFilterVisible, setRegionFilterVisible] =
    useState<boolean>(false);
  const [buildingFilterVisible, setBuildingFilterVisible] =
    useState<boolean>(false);

  // @ts-ignore
  const pagination = useMemo(
    () => getTablePagination(input, totalCount),
    [input, totalCount],
  );

  const handlePagination = (
    updatedPagination: TablePaginationConfig,
    filters: SorterResult<ControlSystem>,
  ) => {
    const { field, order, ...params } = filters;

    const sortBy = !order ? null : field;
    const sortOrder = !order ? null : order;

    const { limit, offset } =
      getLimitAndOffsetFromTablePagination(updatedPagination);

    // @ts-ignore
    setInput({ ...input, ...params, limit, offset, sortBy, sortOrder });
  };

  const getColumns = (): ColumnProps<ControlSystem>[] => {
    const isDisabled = !authUtilities.minimumRequiredRole(
      authUtilities.ROLE_SETS.ORGANIZATION_ADMINISTRATOR,
    );

    return [
      {
        title: 'Name',
        dataIndex: 'name',
        render: (text: string, controlSystem: ControlSystem) => (
          <Link to={`/dashboard/controlsystems/${controlSystem.id}`}>
            {text}
          </Link>
        ),
      },
      {
        title: 'Control System Type',
        key: 'systemType',
        dataIndex: 'systemType',
        // @ts-ignore
        filters: CONTROL_SYSTEM_TYPE_FILTERS,
        filterMultiple: true,
        filteredValue: stringifyArray(input.systemType),
        render: (type: ControlSystemType) => {
          const option = controlSystemUtilities.controlSystemTypeMap[type];
          return option ? option.text : '';
        },
      },
      {
        title: 'Manufacturer',
        dataIndex: 'manufacturer',
      },
      {
        title: 'Model',
        dataIndex: 'model',
      },
      {
        title: 'Building',
        dataIndex: 'building',
        render: (building: { name: string }) => building.name,
        // @ts-ignore
        filterDropdown: () => (
          <AutoCompleteFilter
            label="Building"
            visible={buildingFilterVisible}
            onClose={() => setBuildingFilterVisible(false)}
            {...buildingFilter}
          />
        ),
        filterDropdownOpen: buildingFilterVisible,
        onFilterDropdownOpenChange: setBuildingFilterVisible,
        filteredValue: R.keys(buildingFilter.applied),
      },
      {
        title: 'Region',
        dataIndex: 'region',
        render: (region: { name: string }) => region.name,
        // @ts-ignore
        filterDropdown: () => (
          <AutoCompleteFilter
            label="Region"
            visible={regionFilterVisible}
            onClose={() => setRegionFilterVisible(false)}
            {...regionFilter}
          />
        ),
        filterDropdownOpen: regionFilterVisible,
        onFilterDropdownOpenChange: setRegionFilterVisible,
        filteredValue: R.keys(regionFilter.applied),
      },
      {
        title: 'Actions',
        dataIndex: '',
        width: '84px',
        render: (_, controlSystem) => (
          <div className="center-table-cell">
            <Tooltip title="Edit" placement="top">
              <Button
                shape="circle"
                icon={<EditOutlined />}
                style={styles.button}
                onClick={() => setSelectedForEdit(controlSystem)}
              />
            </Tooltip>
            <Tooltip title="Delete" placement="top">
              <Button
                shape="circle"
                icon={<DeleteOutlined color={colors.neutral.gray} />}
                disabled={isDisabled}
                onClick={() => setSelectedForDelete(controlSystem)}
              />
            </Tooltip>
          </div>
        ),
      },
    ];
  };

  return (
    <>
      <Table
        rowKey="id"
        columns={getColumns()}
        loading={loading}
        dataSource={controlSystems}
        onChange={handlePagination}
        pagination={pagination}
      />
      <DeleteControlSystemModal
        open={!R.isEmpty(selectedForDelete)}
        setOpen={() => setSelectedForDelete({})}
        controlSystem={selectedForDelete as ControlSystem}
        onComplete={() => {
          setSelectedForDelete({});
          refetch();
        }}
      />
      <ControlSystemEditModal
        open={!R.isEmpty(selectedForEdit)}
        onClose={() => setSelectedForEdit({})}
        controlSystem={selectedForEdit as ControlSystem}
      />
    </>
  );
};

export default ControlSystemsTable;
