import actionTypes from '@totem/actions/actionTypes';

import initialState from './initialState';

const replace = (predicate, value, collection) => {
  return collection.map(item => (predicate(item, value) ? value : item));
};

export default function regionsReducer(state = initialState.regions, action) {
  switch (action.type) {
    case actionTypes.REGIONS_GET_SUCCESS: {
      return action.data ? [...action.data] : [];
    }
    case actionTypes.CREATE_REGION_SUCCESS: {
      return [...state, action.data];
    }
    case actionTypes.REGION_UPDATE_SUCCESS: {
      return replace(
        region => region.id === action.data.id,
        action.data,
        state,
      );
    }
    default: {
      return state;
    }
  }
}
