import React from 'react';

import './settings.css';

interface Props {
  title: string;
}

const GenericCardTitle = ({ title }: Props) => {
  return (
    <div styleName="card-title-container">
      <div styleName="card-title-label">{title}</div>
    </div>
  );
};

export default GenericCardTitle;
