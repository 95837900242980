import React, { useCallback, useContext, useState } from 'react';

import AdditionalContext from '@totem/components/surveyV2/instance/AdditionalContext';
import AnsweredBy from '@totem/components/surveyV2/instance/AnsweredBy';
import DateQuestion from '@totem/components/surveyV2/instance/DateQuestion';
import EnumMultipleQuestion from '@totem/components/surveyV2/instance/EnumMultipleQuestion';
import EnumSingleQuestion from '@totem/components/surveyV2/instance/EnumSingleQuestion';
import TextQuestion from '@totem/components/surveyV2/instance/TextQuestion';
import UploadQuestion from '@totem/components/surveyV2/instance/UploadQuestion';
import SurveyContext from '@totem/components/surveyV2/instanceDetail/SurveyInstanceContext';
import {
  Category,
  QuestionValue,
  SurveyQueryResult,
  SurveyQuestion,
} from '@totem/components/surveyV2/types';
import { getToken } from '@totem/utilities/accountUtilities';
import { V2_SURVEY_ENDPOINT } from '@totem/utilities/endpoints';

import '../survey.css';

export type Props = {
  category: Category;
  question: SurveyQuestion;
  disabled: boolean;
};

const Question = ({ category, question, disabled }: Props) => {
  const { data, setData, setIsLoading } = useContext(SurveyContext);
  const [additionalContext, setAdditionalContext] = useState<string>(
    question.additionalContext,
  );
  const { label, hint, requiresAdditionalContext, required, updatedAt, type } =
    question;

  const sendQuestionUpdateRequest = useCallback(
    async (
      surveyId: string,
      categoryId: string,
      questionId: string,
      questionValue: QuestionValue,
      additionalContextString: string,
    ) => {
      const payload = {
        surveyInstanceId: surveyId,
        categoryId,
        questionId,
        answer: questionValue,
        additionalContext: additionalContextString,
      };

      fetch(`${V2_SURVEY_ENDPOINT}/instance/question`, {
        method: 'POST',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
        body: JSON.stringify(payload),
      })
        .then((res) => res.json())
        .then((result: SurveyQueryResult) => {
          setData(result);
        })
        .then(() => {
          setIsLoading(false);
        });
    },
    [],
  );

  const handleQuestionValueChange = (value: QuestionValue) => {
    sendQuestionUpdateRequest(
      data.survey.id,
      category.id,
      question.id,
      value,
      additionalContext,
    );
  };

  const handleAdditionalContextChange = () => {
    if (question.additionalContext !== additionalContext) {
      sendQuestionUpdateRequest(
        data.survey.id,
        category.id,
        question.id,
        question.value,
        additionalContext,
      );
    }
  };

  const renderQuestionType = (): React.ReactNode => {
    const props = {
      question,
      disabled,
      onChange: handleQuestionValueChange,
    };

    switch (type) {
      case 'shorttext':
      case 'longtext': {
        return <TextQuestion {...props} />;
      }
      case 'scoring':
      case 'enumsingle': {
        return <EnumSingleQuestion {...props} />;
      }
      case 'enummultiple': {
        return <EnumMultipleQuestion {...props} />;
      }
      case 'date': {
        return <DateQuestion {...props} />;
      }
      case 'upload': {
        return <UploadQuestion {...props} />;
      }
      default: {
        return <div />;
      }
    }
  };

  const showMoreInfo = () => {
    if (data.survey.type === 'policyAudit') {
      if (question.value.value !== '') {
        const selectedOption = question.options.find(
          (chk) => chk.label === question.value.value,
        );
        if (typeof selectedOption !== 'undefined') {
          return selectedOption.moreInfoVisible;
        }
      }
    }
    return false;
  };

  return (
    <div styleName="question-user-container">
      <div styleName="question">
        <div styleName="question-label">{required ? `${label} *` : label}</div>
        <div styleName="question-hint">{hint}</div>
        <div styleName="question-type">{renderQuestionType()}</div>
        {(showMoreInfo() || requiresAdditionalContext) && (
          <AdditionalContext
            question={question}
            disabled={disabled}
            value={additionalContext}
            onChange={(newValue: string) => setAdditionalContext(newValue)}
            onBlur={handleAdditionalContextChange}
          />
        )}
      </div>
      {Boolean(updatedAt) && <AnsweredBy question={question} />}
    </div>
  );
};

export default Question;
