import React from 'react';
import { Card, Divider } from 'antd';

import { Nmap, NmapDevice } from '@totem/types/nmap';

import NmapReportModal from './NmapReportModal';

import './nmapDetail.css';

interface Props {
  nmap: Nmap;
  flaggedDevices: NmapDevice[];
  totalDevices: number;
  reviewedDevices: number;
}

const styles = {
  divider: {
    height: '6rem',
  },
};

const ScannedDevicesOverview = ({
  totalDevices,
  reviewedDevices,
  nmap,
  flaggedDevices,
}: Props) => {
  return (
    <Card
      title={
        <div styleName="overview-header">
          <div>Scanned Devices</div>
          <NmapReportModal nmap={nmap} flaggedDevices={flaggedDevices} />
        </div>
      }
      bordered={false}
    >
      <div styleName="overview-card-body">
        <div styleName="overview-card">
          <div styleName="overview-title">Total Devices</div>
          <div styleName="stat">{totalDevices}</div>
        </div>
        <Divider type="vertical" style={styles.divider} />
        <div styleName="overview-card">
          <div styleName="overview-title">Reviewed Devices</div>
          <div styleName="stat">{reviewedDevices}</div>
        </div>
      </div>
    </Card>
  );
};

export default ScannedDevicesOverview;
