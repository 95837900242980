import React, { ReactNode } from 'react';
import { Flex } from 'antd';

const styles = {
  container: {
    //height: '63px',
    padding: '0.5rem 0.625rem 0.5rem 0.625rem',
  },
  title: {
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '15px',
    height: '21px',
  },
  total: {
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: '26px',
    margin: '0px',
  },
  unit: {
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '12px',
    margin: 'auto 0px 0px 0.25rem',
    paddingBottom: '4px',
  },
  trend: {
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '18px',
    margin: '0px 0px 0px 0.5rem',
    paddingBottom: '2px',
  },
};

type Props = {
  title: string | ReactNode;
  total: string | ReactNode;
  unit: string | ReactNode;
  trend: string | ReactNode;
};

const EnergyTrend = ({ title, total, unit, trend }: Props) => {
  return (
    <div style={styles.container}>
      <Flex gap={0} vertical>
        <div style={styles.title}>{title}</div>
        <Flex vertical={false} align="flex-end">
          <div style={styles.total}>{total}</div>
          <div style={styles.unit}>{unit}</div>
          <div style={styles.trend}>{trend}</div>
        </Flex>
      </Flex>
    </div>
  );
};

export default EnergyTrend;
