import React, { useContext, useMemo } from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { ColumnProps, TablePaginationConfig } from 'antd/lib/table';
import * as R from 'ramda';

import UserAvatar from '@totem/components/common/avatar/UserAvatar';
import Table from '@totem/components/common/table/Table';
import Tooltip from '@totem/components/common/tooltip/Tooltip';
import UserStatus from '@totem/components/common/userStatus/UserStatus';
import colors from '@totem/styles/colors';
import {
  User,
  UserAccess,
  UserStatus as UserStatusEnum,
} from '@totem/types/user';
import { getRoleString } from '@totem/utilities/accountUtilities';
import { toTableFilter } from '@totem/utilities/enumUtilities';
import { getTablePagination } from '@totem/utilities/paginationUtilities';
import {
  nullifyIfEmpty,
  stringifyArray,
} from '@totem/utilities/tableFilterUtilities';
import {
  USER_ACCESS_TOOLTIP,
  USER_STATUS_TOOLTIP,
} from '@totem/utilities/tooltipUtilities';
import {
  getUserAccess,
  getUserActiveStatus,
  getUserRole,
  isUserDeactivated,
} from '@totem/utilities/userUtilities';

import UserActionMenu from './UserActionMenu';
import UsersContext from './UsersContext';

import './users.css';

const mapUsers = (users: User[]) => {
  return users
    .filter(
      user =>
        user.organization.role >= 0 &&
        user.organization.isActive === true &&
        !user.email.toLowerCase().endsWith('@intelligentbuildings.com'),
    )
    .map(user => ({
      ...user,
      key: user.id,
      role: getUserRole(user),
    }));
};

export const getRowClassName = (user: User) =>
  isUserDeactivated(user) ? 'disabled' : '';

const styles = {
  infoIcon: {
    marginLeft: '0.5rem',
    color: colors.neutral.gray,
    cursor: 'pointer',
  },
};

const UsersTable = () => {
  const { totalCount, users, loading, input, setInput } = useContext(
    UsersContext,
  );

  const handleChange = (
    { current, pageSize: limit }: TablePaginationConfig,
    filters,
  ) => {
    const offset = (current - 1) * limit;
    const statuses = nullifyIfEmpty(filters.status);
    const accesses = nullifyIfEmpty(filters.access);
    setInput({ ...input, limit, offset, statuses, accesses });
  };

  const pagination = useMemo(() => getTablePagination(input, totalCount), [
    input,
    totalCount,
  ]);

  const { statuses, accesses } = input;

  const columns: ColumnProps<User>[] = [
    {
      title: '',
      dataIndex: 'imageUrl',
      key: 'imageUrl',
      width: 5,
      render: (text, record) => {
        return (
          <div className="center-table-cell">
            <UserAvatar size="large" user={record} />
          </div>
        );
      },
    },

    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'First',
      dataIndex: 'firstName',
      key: 'firstName',
    },
    {
      title: 'Last',
      dataIndex: 'lastName',
      key: 'lastName',
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      render: (text: string, user: User) => {
        const role = getUserRole(user);
        const roleString = getRoleString(role);
        return <div>{roleString}</div>;
      },
    },
    {
      title: 'Active',
      dataIndex: 'isActive',
      key: 'isActive',
      render: (_, user) => getUserActiveStatus(user),
    },
    {
      title: () => (
        <div className="tooltip-table-header">
          <div>Site Access</div>
          <Tooltip title={USER_ACCESS_TOOLTIP}>
            <InfoCircleOutlined style={styles.infoIcon} />
          </Tooltip>
        </div>
      ),
      dataIndex: 'access',
      key: 'access',
      filters: toTableFilter(UserAccess),
      filterMultiple: true,
      filteredValue: stringifyArray(accesses),
      render: (_, user) => UserAccess[getUserAccess(user)] || 'Unknown',
    },
    {
      title: () => (
        <div className="tooltip-table-header">
          <div>Status</div>
          <Tooltip title={USER_STATUS_TOOLTIP}>
            <InfoCircleOutlined style={styles.infoIcon} />
          </Tooltip>
        </div>
      ),
      dataIndex: 'status',
      key: 'status',
      filters: toTableFilter(UserStatusEnum),
      filterMultiple: true,
      filteredValue: stringifyArray(statuses),
      render: (status?: UserStatusEnum) =>
        !R.isNil(status) && <UserStatus status={status} />,
    },
    {
      title: 'Last Login',
      dataIndex: 'lastLoginDate',
      render: date => (date ? new Date(date).toLocaleDateString() : '-'),
    },
    {
      title: 'Actions',
      width: 30,
      dataIndex: 'additionalOptions',
      key: 'additionalOptions',
      render: (value, user: User) => (
        <div className="center-table-cell">
          <UserActionMenu user={user} />
        </div>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      loading={loading}
      dataSource={mapUsers(users)}
      rowClassName={getRowClassName}
      onChange={handleChange}
      pagination={pagination}
    />
  );
};

export default UsersTable;
