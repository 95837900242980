import React, { useContext } from 'react';
import {
  CloseCircleOutlined,
  EllipsisOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import { Button, Dropdown, MenuProps } from 'antd';

import { EMPTY_ID } from '@totem/components/common/reference/ReferenceSelector';
import PendingContext from '@totem/components/internalOnly/netSuitePending/PendingContext';
import { PendingImportRecord } from '@totem/components/internalOnly/netSuitePending/types';
import UserProfileContext from '@totem/components/UserProfileContext';
import { getUserRole } from '@totem/utilities/userUtilities';

type Props = {
  record: PendingImportRecord;
};

const PendingActionMenu = ({ record }: Props) => {
  const { userProfile } = useContext(UserProfileContext);
  const isSysAdmin = getUserRole(userProfile) >= 3;
  const { onAction } = useContext(PendingContext);

  const getActionMenuItems = (): MenuProps['items'] => {
    const menuItems: MenuProps['items'] = [];

    if (
      isSysAdmin &&
      (record.organization === null ||
        record.organization.id === '' ||
        record.organization.id === EMPTY_ID)
    ) {
      menuItems.push({
        key: 'create_organization',
        label: 'Create Organization',
        title: 'Create Organization',
        icon: <PlusCircleOutlined />,
      });
    }

    if (isSysAdmin) {
      menuItems.push({
        key: 'reprocess_record',
        label: 'Reprocess Import',
        title: 'Reprocess Import',
        icon: <CloseCircleOutlined />,
      });
    }

    if (isSysAdmin) {
      menuItems.push({
        key: 'remove_pending_import',
        label: 'Remove Pending Import',
        title: 'Remove Pending Import',
        icon: <CloseCircleOutlined />,
      });
    }

    return menuItems;
  };

  const onMenuClicked = ({ key }) => {
    // eslint-disable-next-line default-case
    switch (key) {
      case 'create_organization':
        onAction('create_organization', record);
        break;
      case 'reprocess_record':
        onAction('reprocess_record', record);
        break;
      case 'remove_pending_import':
        onAction('remove_pending_import', record);
        break;
    }
  };

  return (
    <Dropdown
      menu={{ items: getActionMenuItems(), onClick: onMenuClicked }}
      trigger={['click']}
    >
      <Button shape="circle" icon={<EllipsisOutlined />} />
    </Dropdown>
  );
};

export default PendingActionMenu;
