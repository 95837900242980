import React, { useEffect, useState } from 'react';

import { Question } from '@totem/types/healthRiskSurvey';

import HealthRiskSurveyDetailAnswer from './HealthRiskSurveyDetailAnswer';

import './healthRiskSurveyDetail.css';

interface Props {
  question: Question;
  disabled?: boolean;
  onChange: (id: string, value: any) => void;
}

const getStyleName = (selected: boolean, disabled: boolean): string => {
  let styleName = 'enum-option';

  if (selected) {
    styleName += ' selected';
  }

  if (disabled) {
    styleName += ' disabled';
  } else {
    styleName += ' enabled';
  }

  return styleName;
};

const HealthRiskSurveyDetailQuestion = ({
  question,
  disabled,
  onChange,
}: Props) => {
  const { label, options, answer } = question;

  const [value, setValue] = useState<string>(answer?.value);

  useEffect(() => {
    setValue(answer?.value);
  }, [answer]);

  const handleChange = (id: string, val: string) => {
    if (!disabled) {
      const update = value === val ? '' : val;
      setValue(update);
      onChange(id, update);
    }
  };

  return (
    <div styleName="question-user-container">
      <div styleName="question">
        <div styleName="question-label">{label}</div>
        <div styleName="question-type">
          {options.map(option => {
            const selected = value === option.value;

            return (
              <button
                styleName={getStyleName(selected, disabled)}
                key={option.value}
                onClick={() => handleChange(question.id, option.value)}
              >
                {option.value}
              </button>
            );
          })}
        </div>
      </div>
      {answer && <HealthRiskSurveyDetailAnswer question={question} />}
    </div>
  );
};

export default HealthRiskSurveyDetailQuestion;
