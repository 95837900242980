import React, { useContext, useEffect, useState } from 'react';

import buildingMarker from '@totem/components/common/svgIcons/buildingMarker.svg';
import SummaryContext from '@totem/components/portfolio/summary/summaryContext';

const styles = {
  icon: {
    fontSize: '1rem',
    marginLeft: '0.5rem',
    marginBottom: '1px',
  },
  markerIcon: {
    url: buildingMarker,
    anchor: {
      x: 24,
      y: 24,
    },
  },
  zoom: 2,
};

import Map from '@totem/components/common/map/Map';
import { Building } from '@totem/components/portfolio/summary/types';
import { isNotNull } from '@totem/utilities/common';
const SummaryMap = () => {
  const { filteredRegions } = useContext(SummaryContext);
  const [buildings, setBuildings] = useState<Building[]>([]);

  useEffect(() => {
    if (isNotNull(filteredRegions)) {
      const buildingArray: Building[] = [];
      for (let idx = 0; idx < filteredRegions.length; idx++) {
        buildingArray.push(...filteredRegions[idx].buildings);
      }
      setBuildings(buildingArray);
    }
  }, [filteredRegions]);

  const getBuildingMarkers = () => {
    if (isNotNull(buildings)) {
      return buildings
        .filter(({ location }) => location.lat !== 0 || location.lng !== 0)
        .map(({ id, location }) => {
          return {
            location,
            key: id,
            icon: styles.markerIcon,
            size: {
              x: 24,
              y: 24,
            },
            onClick() {
            },
          };
        });
    }
    return [];
  };

  const getMapCenter = () => {
    if (isNotNull(buildings)) {
      const [building] = buildings.filter(
        ({ location }) => location.lat !== 0 || location.lng !== 0,
      );

      return building ? building.location : null;
    }
    return null;
  };

  return (
    <Map
      markers={getBuildingMarkers()}
      zoom={styles.zoom}
      center={getMapCenter()}
    />
  );
};

export default SummaryMap;
