import { useEffect, useRef } from 'react';

// returns the previous value of the given variable after component update
// its useful for comparing updated values of state or props
export const usePreviousValue = <T>(value: T): T => {
  const ref = useRef<T>();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
};
