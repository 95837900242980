import React, { useContext } from 'react';
import { Card, Collapse, CollapseProps } from 'antd';

import DetailsSpinner from '@totem/components/common/DetailSpinner';
import SurveyTemplateCategories from '@totem/components/surveyV2/templateDetails/SurveyTemplateCategories';
import SurveyTemplateContext from '@totem/components/surveyV2/templateDetails/SurveyTemplateContext';
import SurveyTemplateDetailsPane from '@totem/components/surveyV2/templateDetails/SurveyTemplateDetailsPane';
import SurveyTemplateLevels from '@totem/components/surveyV2/templateDetails/SurveyTemplateLevels';
import SurveyTemplateLevelTypesPane from '@totem/components/surveyV2/templateDetails/SurveyTemplateLevelTypesPane';

import ContentLayout from '../../ContentLayout';

import '../survey.css';

const SurveyTemplateDetails = () => {
  const { data } = useContext(SurveyTemplateContext);

  const hasData = typeof data !== 'undefined' && data !== null;

  const getSectionItems = () => {
    const items: CollapseProps['items'] = [
      {
        key: 'templateDetails',
        label: 'Template Details',
        children: <SurveyTemplateDetailsPane />,
      },
      {
        key: 'templateLevelTypes',
        label: 'Level Types',
        children: <SurveyTemplateLevelTypesPane />,
      },
      {
        key: 'templateLevels',
        label: 'Levels',
        children: <SurveyTemplateLevels />,
      },
    ];

    return items;
  };

  return (
    <>
      {hasData && (
        <ContentLayout
          breadcrumbs={(breadcrumbs) => [...breadcrumbs, { label: data.name }]}
          contentRight={data.name}
        >
          <Card>
            <Collapse items={getSectionItems()} />
          </Card>
          <SurveyTemplateCategories />
        </ContentLayout>
      )}
      {!hasData && (
        <div styleName="spinner-container">
          <DetailsSpinner />
        </div>
      )}
    </>
  );
};

export default SurveyTemplateDetails;
