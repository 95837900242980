import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { ColumnProps } from 'antd/es/table';

import Table from '@totem/components/common/table/Table';
import ContactSearchContext from '@totem/components/contactSearch/contactSearchContext';
import { ContactSearchResultEntry } from '@totem/components/contactSearch/types';
import UserProfileContext from '@totem/components/UserProfileContext';
import authUtilities from '@totem/utilities/authUtilities';

const SearchResultsTable = () => {
  const { userProfile } = useContext(UserProfileContext);
  const { data, loading } = useContext(ContactSearchContext);

  const getData = () => {
    if (typeof data !== 'undefined' && data !== null) {
      return data;
    }
    return [];
  };

  const getLabels = (record: ContactSearchResultEntry) => {
    return (
      <>
        {typeof record.labels !== 'undefined' &&
          record.labels !== null &&
          record.labels.map(label => {
            return (
              <span key={record.id + label}>
                {label}
                <br />
              </span>
            );
          })}
      </>
    );
  };

  const getOwnerDisplay = (record: ContactSearchResultEntry) => {
    switch (record.objectType) {
      case 'Building':
        return (
          <Link to={`/dashboard/buildings/${record.objectID}`}>
            {record.objectName}
          </Link>
        );
      case 'Control System':
        return (
          <Link to={`/dashboard/controlsystems/${record.objectID}`}>
            {record.objectName}
          </Link>
        );
      case 'Organization':
        switch (record.type) {
          case 'User':
            if (
              authUtilities.minimumRequiredRole(
                authUtilities.ROLE_SETS.ORGANIZATION_ADMINISTRATOR,
              )
            ) {
              return (
                <Link
                  to={`/dashboard/usermanagement?searchEmail=${record.email}`}
                >
                  {userProfile.organization.name}
                </Link>
              );
            }
            break;
          case 'Contact':
            if (
              authUtilities.minimumRequiredRole(
                authUtilities.ROLE_SETS.SYSTEMS_ADMINISTRATOR,
              )
            ) {
              return (
                <Link to={`/dashboard/settings?st=contacts`}>
                  {userProfile.organization.name}
                </Link>
              );
            }

            break;
          default:
            return <span>{userProfile.organization.name}</span>;
        }
        break;
      default:
        return <span>{record.objectType}</span>;
    }
    return <span>{record.objectType}</span>;
  };

  const columns: ColumnProps<ContactSearchResultEntry>[] = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      showSorterTooltip: true,
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        compA.name === compB.name ? 0 : compA.name > compB.name ? 1 : -1,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      showSorterTooltip: true,
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        compA.email === compB.email ? 0 : compA.email > compB.email ? 1 : -1,
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
      showSorterTooltip: true,
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        compA.phone === compB.phone ? 0 : compA.phone > compB.phone ? 1 : -1,
    },
    {
      title: 'Labels',
      dataIndex: 'labels',
      key: 'labels',
      render: (_, record) => getLabels(record),
    },
    {
      title: 'Owner Type',
      dataIndex: 'objectType',
      key: 'objectType',
      showSorterTooltip: true,
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        compA.objectType === compB.objectType
          ? 0
          : compA.objectType > compB.objectType
          ? 1
          : -1,
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      showSorterTooltip: true,
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        compA.type === compB.type ? 0 : compA.type > compB.type ? 1 : -1,
    },
    {
      title: 'Owner',
      dataIndex: 'objectName',
      key: 'objectName',
      render: (_, record) => getOwnerDisplay(record),
      showSorterTooltip: true,
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        compA.objectName === compB.objectName
          ? 0
          : compA.objectName > compB.objectName
          ? 1
          : -1,
    },
  ];

  return (
    <Table
      showSorterTooltip
      columns={columns}
      dataSource={getData()}
      loading={loading}
      rowKey={record => record.id}
    />
  );
};

export default SearchResultsTable;
