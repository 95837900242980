import React, { useContext } from 'react';

import UserProfileContext from '@totem/components/UserProfileContext';
import { staticLogoSmall } from '@totem/selectors/preferencesSelectors';

import tjbcSmall from '../../images/johnBuckCompanySmall.png';

import './logos.css';

const LeftNavLogoSmall = () => {
  const { userProfile } = useContext(UserProfileContext);
  const organizationId = userProfile.organization.id;

  let logo = staticLogoSmall;
  switch (organizationId) {
    case '66a294300a1b3e41854b7887': // The John Buck Company
      logo = tjbcSmall;
      break;
    default:
      break;
  }

  return <img alt="Logo" src={logo} styleName="navLogoSmall" />;
};

export default LeftNavLogoSmall;
