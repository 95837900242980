import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Modal } from 'antd';
import * as R from 'ramda';

import { AddEditRegion, RegionAction } from '@totem/types/region';
const FormItem = Form.Item;
import './regions.css';

export interface Props {
  visible: boolean;
  action: RegionAction;
  regionLabel: string;
  region: AddEditRegion;
  loading: boolean;
  onCancel: () => void;
  onSubmit: (region: AddEditRegion) => void;
}

interface Errors {
  name?: string;
}

const styles = {
  content: {
    display: 'flex',
    justifyContent: 'center',
  },
  form: {
    width: '100%',
  },
  formItem: {
    paddingBottom: '0',
    marginBottom: '2rem',
  },
  modal: {
    title: {
      fontSize: '1.8rem',
      fontWeight: 700,
    },
    footer: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
};

const AddEditRegionModal = ({
  loading,
  visible,
  region,
  regionLabel,
  action,
  onCancel,
  onSubmit,
}: Props) => {
  const [name, setName] = useState<string>(region.name);
  const [errors, setErrors] = useState<Errors>({});

  useEffect(() => {
    if (!visible) {
      setName('');
      setErrors({});
    } else {
      setName(region.name);
    }
  }, [visible, region]);

  const isValid = (): boolean => {
    const err: Errors = {};

    if (!name) {
      err.name = 'Name is required.';
    }

    setErrors(err);
    return R.isEmpty(err);
  };

  const handleSubmit = () => {
    if (isValid()) {
      onSubmit({ ...region, name });
    }
  };

  return (
    <Modal
      open={visible}
      title={
        <div style={styles.modal.title}>
          {action === RegionAction.EDIT
            ? `Update ${regionLabel}`
            : `Add ${regionLabel}`}
        </div>
      }
      footer={
        <div style={styles.modal.footer}>
          <Button onClick={onCancel}>Cancel</Button>
          <Button type="primary" onClick={handleSubmit}>
            {action === RegionAction.EDIT ? 'Update' : 'Create'}
          </Button>
        </div>
      }
      onCancel={onCancel}
      confirmLoading={loading}
    >
      <div style={styles.content}>
        <Form layout="vertical" style={styles.form}>
          <FormItem
            label="Name"
            colon={false}
            validateStatus={R.isNil(errors.name) ? 'success' : 'error'}
            help={R.isNil(errors.name) ? null : errors.name}
            style={styles.formItem}
          >
            <Input
              placeholder="Enter Region Name"
              value={name}
              onChange={event => setName(event.target.value)}
            />
          </FormItem>
        </Form>
      </div>
    </Modal>
  );
};

export default AddEditRegionModal;
