import React, { ReactNode, useEffect, useState } from 'react';

import BackupSummaryContext from '@totem/components/deviceBackups/backupSummary/backupSummaryContext';
import { BackupSummary } from '@totem/components/deviceBackups/backupSummary/types';
import { buildParameters } from '@totem/components/deviceBackups/backupSummary/utilities';
import { getToken } from '@totem/utilities/accountUtilities';
import { DEVICES_ENDPOINT } from '@totem/utilities/endpoints';

type Props = {
  regionId?: string[];
  buildingId?: string[];
  controlSystemId?: string[];
  children?: ReactNode;
};

const BackupSummaryContainer = ({
  regionId,
  buildingId,
  controlSystemId,
  children,
}: Props) => {
  const [refreshData, setRefreshData] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [data, setData] = useState<BackupSummary>(null);
  const [action, setAction] = useState<string>('');

  useEffect(() => {
    setRefreshData(true);
  }, [regionId, buildingId, controlSystemId]);

  useEffect(() => {
    if (refreshData) {
      setRefreshData(false);
      setIsLoading(true);

      const uri = `${DEVICES_ENDPOINT}/backupSummary`;

      const payload = buildParameters(regionId, buildingId, controlSystemId);

      fetch(uri, {
        method: 'POST',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
        body: JSON.stringify(payload),
      })
        .then((res) => res.json())
        .then((result: BackupSummary) => {
          setData(result);
        })
        .then(() => {
          setIsLoading(false);
        });
    }
  }, [refreshData]);

  const handleAction = (newAction: string) => {
    switch (newAction) {
      case 'refresh':
        setRefreshData(true);
        setAction('');
        break;
      default:
        setAction(newAction);
    }
  };

  return (
    <BackupSummaryContext.Provider
      value={{
        loading: isLoading,
        data,
        action,
        onAction: handleAction,
      }}
    >
      <div>{children}</div>
    </BackupSummaryContext.Provider>
  );
};

export default BackupSummaryContainer;
