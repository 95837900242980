import React from 'react';
import { useParams } from 'react-router-dom';

import SurveyTemplateContainer from '@totem/components/surveyV2/templateDetails/SurveyTemplateContainer';
import SurveyTemplateDetails from '@totem/components/surveyV2/templateDetails/SurveyTemplateDetails';

const SurveyTemplatePage = () => {
  const { id } = useParams();

  return (
    <SurveyTemplateContainer surveyTemplateId={id}>
      <SurveyTemplateDetails />
    </SurveyTemplateContainer>
  );
};

export default SurveyTemplatePage;
