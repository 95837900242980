import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';

import SubTitle from '@totem/components/surveyV2/SubTitle';
import colors from '@totem/styles/colors';

import './findingsReport.css';

const styles = {
  icon: {
    marginRight: '0.5rem',
  },
};

const SourceQuestion = () => {
  const { question } = useParams();
  const navigate = useNavigate();

  const navigateBack = () => {
    navigate(-1);
  };

  return (
    <div styleName="source-question-container">
      <span styleName="source-question-back" onClick={navigateBack}>
        <ArrowLeftOutlined style={styles.icon} color={colors.antd.blue} />
        Back to Policies
      </span>
      <div styleName="source-question-content">
        <SubTitle>Selected Policy:</SubTitle>
        <span styleName="source-question">{question}</span>
      </div>
    </div>
  );
};

export default SourceQuestion;
