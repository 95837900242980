import React from 'react';

import DropdownFilter from '@totem/components/common/dropdownFilter/DropdownFilter';
import FilterChipsContainer from '@totem/components/FilterChipsContainer';
import FiltersBody from '@totem/components/FiltersBody';
import FiltersContainer from '@totem/components/FiltersContainer';
import FiltersTitle from '@totem/components/FiltersTitle';
import { FindingGroup, FindingsConnectionInput } from '@totem/types/finding';
import { LevelOfEffort } from '@totem/types/levelOfEffort';
import { controlSystemTypeOptions } from '@totem/utilities/controlSystemsUtilities';
import { criticalityOptions } from '@totem/utilities/criticalityUtilities';

import FindingFilterChips from './FindingFilterChips';

interface Props {
  input: FindingsConnectionInput;
  onChange: (input: Partial<FindingsConnectionInput>) => void;
}

const styles = {
  dropdown: {
    marginRight: '2rem',
  },
};

const CONTROL_SYSTEM_FILTERS = controlSystemTypeOptions.map(
  ({ text: label, value }) => ({
    label,
    value,
  }),
);

const CRITICALITY_FILTERS = criticalityOptions.map(
  ({ text: label, value }) => ({
    label,
    value,
  }),
);

const FINDING_FILTERS = Object.keys(FindingGroup)
  .filter((key) => Number.isNaN(Number(key)))
  .map((key) => ({
    label: key,
    value: FindingGroup[key],
  }));

const LOE_FILTERS = Object.keys(LevelOfEffort)
  .filter((key) => Number.isNaN(Number(key)))
  .map((key) => ({
    label: key,
    value: LevelOfEffort[key],
  }));

const FindingFilters = ({ input, onChange }: Props) => {
  const { controlSystemType, criticality, group, levelOfEffort } = input;

  const handleMultiFilterChange = (property: string, update: string[]) => {
    onChange({
      [property]: update.length ? update.map(Number) : null,
    });
  };

  return (
    <FiltersContainer>
      <FiltersTitle>Findings Report</FiltersTitle>
      <FiltersBody>
        <DropdownFilter
          label="Control System Type"
          value={controlSystemType || []}
          options={CONTROL_SYSTEM_FILTERS}
          onChange={(update) =>
            handleMultiFilterChange('controlSystemType', update)
          }
          style={styles.dropdown}
        />
        <DropdownFilter
          label="Criticality"
          value={criticality || []}
          options={CRITICALITY_FILTERS}
          defaultSelected={criticality}
          onChange={(update) => handleMultiFilterChange('criticality', update)}
          style={styles.dropdown}
        />
        <DropdownFilter
          label="Findings Group"
          value={group || []}
          options={FINDING_FILTERS}
          defaultSelected={group}
          onChange={(update) => handleMultiFilterChange('group', update)}
          style={styles.dropdown}
        />
        <DropdownFilter
          label="Level Of Effort"
          value={levelOfEffort || []}
          options={LOE_FILTERS}
          defaultSelected={levelOfEffort}
          onChange={(update) =>
            handleMultiFilterChange('levelOfEffort', update)
          }
          style={styles.dropdown}
        />
      </FiltersBody>
      <FilterChipsContainer>
        <FindingFilterChips input={input} onChange={onChange} />
      </FilterChipsContainer>
    </FiltersContainer>
  );
};

export default FindingFilters;
