import React, { useEffect, useState } from 'react';
import { CloseCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Col, Form, Row } from 'antd';

import { Filter, ObjectReference } from '@totem/types/common';

const FormItem = Form.Item;

import GenericAutoComplete from '@totem/components/common/autoCompletes/GenericAutoComplete';
import { EMPTY_ID } from '@totem/components/common/reference/ReferenceSelector';
import { AutoCompleteValue } from '@totem/types/autoComplete';

import '../contracts.css';

const styles = {
  form: {
    width: '100%',
  },
  formItem: {
    paddingBottom: '0',
    marginBottom: '1rem',
  },
  addIcon: {
    marginLeft: '15px',
    color: '#1890ff',
  },
  removeIcon: {
    color: '#1890ff',
  },
};

export interface Props {
  contractLineId: string;
  buildingId: string;
  devices: ObjectReference[];
  onDevicesChanged: (devices: ObjectReference[]) => void;
}

const ContractLineDevicesEditPanelAC = ({
  contractLineId,
  buildingId,
  devices,
  onDevicesChanged,
}: Props) => {
  const [deviceData, setDeviceData] = useState<ObjectReference[]>(
    typeof devices !== 'undefined' && devices !== null ? devices : [],
  );

  const [selectedDevice, setSelectedDevice] = useState<AutoCompleteValue>({
    value: '',
    display: '',
  });

  useEffect(() => {
    onDevicesChanged(deviceData);
  }, [deviceData]);

  const handleDeviceAdd = () => {
    if (selectedDevice.value !== '') {
      const newServiceItem = {
        id: selectedDevice.value,
        name: selectedDevice.display,
      };

      setDeviceData((current) => [...current, newServiceItem]);
      setSelectedDevice({
        value: '',
        display: '',
      });
    }
  };

  const handleDeviceRemove = (deviceToRemove: ObjectReference) => {
    setDeviceData(
      deviceData.filter((device) => device.id !== deviceToRemove.id),
    );
  };

  const handleAutoCompleteChanged = (value: string) => {
    setSelectedDevice({
      value: '',
      display: value,
    });
  };

  const getAutoCompleteFilters = () => {
    const acFilters: Filter[] = [];

    if (buildingId !== EMPTY_ID) {
      acFilters.push({
        field: 'buildingId',
        values: [EMPTY_ID, buildingId],
      });
    }

    return acFilters;
  };

  return (
    <Row>
      <Col span={12}>
        <b>Assigned Devices:</b>
        <br />
        {deviceData.map((device) => (
          <span key={`${contractLineId}:${device.id}`}>
            <CloseCircleOutlined
              title="Remove Device"
              onClick={() => handleDeviceRemove(device)}
              style={styles.removeIcon}
            />
            &nbsp;
            {device.name}
            <br />
          </span>
        ))}
      </Col>
      <Col span={12}>
        <FormItem
          label="Available Devices"
          colon={false}
          style={styles.formItem}
        >
          <Row>
            <Col span={20}>
              <GenericAutoComplete
                label="Select Device"
                type="Device"
                limit={15}
                onSelect={setSelectedDevice}
                onChange={handleAutoCompleteChanged}
                filters={getAutoCompleteFilters()}
                selectedValue={selectedDevice}
              />
            </Col>
            <Col span={4}>
              <PlusCircleOutlined
                title="Add Device"
                onClick={handleDeviceAdd}
                style={styles.addIcon}
              />
            </Col>
          </Row>
        </FormItem>
      </Col>
    </Row>
  );
};

export default ContractLineDevicesEditPanelAC;
