import { gql } from '@apollo/client';

export const GET_ORGANIZATION = gql`
  query GetOrganization {
    organization {
      id
      name
      domain
      startedQuestionnaires
      completedQuestionnaires
      nmapScans
      acronisTenantId
      numberOfUsers
    }
  }
`;

export const SWITCH_ORGANIZATION = gql`
  mutation SwitchOrganization($organizationId: String!) {
    organizationSwitch(organizationId: $organizationId) {
      token
    }
  }
`;

export const CREATE_ORGANIZATION = gql`
  mutation CreateOrganization($input: OrganizationCreateInput!) {
    organizationCreate(input: $input) {
      id
      name
      domain
    }
  }
`;

export const GET_ORGANIZATION_USERS_AND_QUESTIONNAIRE_TEMPLATES = gql`
  query GetOrganizationUsersAndQuestionnaire_TEMPLATES(
    $usersInput: UsersConnectionInput!
    $questionnaireTemplatesInput: QuestionnaireTemplatesConnectionInput!
  ) {
    users(input: $usersInput) {
      users {
        id
        firstName
        lastName
        email
      }
    }
    questionnaireTemplates(input: $questionnaireTemplatesInput) {
      questionnaireTemplates {
        id
        name
      }
    }
  }
`;
