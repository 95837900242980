import React from 'react';

import Count from '@totem/components/common/Count';
import colors from '@totem/styles/colors';
import { FindingLevelOfEffortCounts } from '@totem/types/finding';
import { getExactPercentage } from '@totem/utilities/numberUtilities';
import { toTitleCase } from '@totem/utilities/stringUtilities';

import './findings.css';

interface Props {
  counts: FindingLevelOfEffortCounts;
}

const getTotal = ({
  minimal,
  moderate,
  maximum,
}: FindingLevelOfEffortCounts): number => {
  return minimal + moderate + maximum;
};

const renderBar = (
  property: string,
  counts: FindingLevelOfEffortCounts,
  total: number,
) => {
  const count = counts[property];

  return (
    <div styleName="findings">
      <div styleName="finding-label level-of-effort">
        {toTitleCase(property)}
      </div>
      <div styleName="finding-bar-container">
        <div styleName="finding-bar">
          <div
            style={{
              width: `${getExactPercentage(total, count)}%`,
              background: colors.neutral.gray,
              height: '100%',
            }}
          />
        </div>
      </div>
      <Count count={count} size="small" />
    </div>
  );
};

const FindingsLevelOfEffortCounts = ({ counts }: Props) => {
  const total = getTotal(counts);

  return (
    <div styleName="findings-container">
      <div styleName="finding-header-container">
        <div styleName="finding-header">Findings By Level Of Effort</div>
      </div>
      <div>{renderBar('minimal', counts, total)}</div>
      <div>{renderBar('moderate', counts, total)}</div>
      <div>{renderBar('maximum', counts, total)}</div>
    </div>
  );
};

export default FindingsLevelOfEffortCounts;
