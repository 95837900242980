import React, { useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';

import AddSurveyDialog from '@totem/components/surveyV2/instances/AddSurveyDialog';
const AddSurveyButton = () => {
  const [showCreateDialog, setShowCreateDialog] = useState<boolean>(false);

  return (
    <>
      <Button
        type="primary"
        icon={<PlusOutlined />}
        onClick={() => setShowCreateDialog(true)}
      >
        Add Survey
      </Button>
      {showCreateDialog && (
        <AddSurveyDialog
          open={showCreateDialog}
          onClose={() => setShowCreateDialog(false)}
        />
      )}
    </>
  );
};

export default AddSurveyButton;
