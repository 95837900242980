import React, { useContext, useMemo, useState } from 'react';
import { ColumnProps } from 'antd/lib/table';

import Table from '@totem/components/common/table/Table';
import DeviceContext from '@totem/components/devices/deviceDetails/DeviceContext';
import { DeviceAttribute } from '@totem/types/devices';
import { getTablePagination } from '@totem/utilities/paginationUtilities';
import { sortStringAscending } from '@totem/utilities/tableUtilities';

const AttributesTable = () => {
  const { deviceData, loading } = useContext(DeviceContext);
  const [limit, setLimit] = useState<number>(10);
  const [offset, setOffset] = useState<number>(0);

  const handlePagination = (pagination: any) => {
    const { current, pageSize } = pagination;
    setOffset((current - 1) * limit);
    setLimit(pageSize);
  };

  const totalCount =
    typeof deviceData !== 'undefined' &&
    deviceData !== null &&
    deviceData.device.attributes !== null
      ? deviceData.device.attributes.length
      : 0;

  const pagination = useMemo(() => {
    return getTablePagination({ limit, offset }, totalCount);
  }, [limit, offset, totalCount]);

  const columns: ColumnProps<DeviceAttribute>[] = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (compA, compB) =>
        compA.name === compB.name ? 0 : compA.name > compB.name ? 1 : -1,
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value',
      sorter: (compA, compB) =>
        compA.value === compB.value ? 0 : compA.value > compB.value ? 1 : -1,
    },
  ];

  return (
    <Table
      showSorterTooltip
      columns={columns}
      pagination={pagination}
      onChange={handlePagination}
      dataSource={
        typeof deviceData !== 'undefined' &&
        deviceData !== null &&
        deviceData.device !== null &&
        deviceData.device.attributes !== null
          ? deviceData.device.attributes.sort((compA, compB) =>
              sortStringAscending(compA.name, compB.name),
            )
          : []
      }
      loading={loading}
      rowKey={record => record.name}
    />
  );
};

export default AttributesTable;
