import React, { useCallback, useContext, useState } from 'react';
import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { ColumnProps } from 'antd/lib/table';

import Table from '@totem/components/common/table/Table';
import PackageContext from '@totem/components/software/software_package/packageContext';
import { ForeignKey } from '@totem/types/common';
import { getToken } from '@totem/utilities/accountUtilities';
import { DEVICES_ENDPOINT } from '@totem/utilities/endpoints';
import { CheckResponseShowError } from '@totem/utilities/responseUtilities';
import { sortStringAscending } from '@totem/utilities/tableUtilities';

const KeysTable = () => {
  const { data, loading, action, onAction } = useContext(PackageContext);
  const [isSending, setIsSending] = useState<boolean>(false);

  const getData = () => {
    if (typeof data !== 'undefined' && data !== null) {
      return data.package.keys;
    }
    return [];
  };

  const removeKey = useCallback(async (packageID: string, payload: any) => {
    if (isSending) {
      return;
    }
    setIsSending(true);

    fetch(`${DEVICES_ENDPOINT}/software/catalog/${packageID}/keys`, {
      method: 'PUT',
      headers: new Headers({
        Authorization: `Bearer ${getToken()}`,
      }),
      body: JSON.stringify(payload),
    }).then((res) => {
      CheckResponseShowError(res);
      setIsSending(false);
      onAction('refresh');
    });
  }, []);

  const handleDelete = (deleteKey: ForeignKey) => {
    removeKey(data.package.id, deleteKey);
  };

  const columns: ColumnProps<ForeignKey>[] = [
    {
      title: 'System',
      dataIndex: 'system',
      key: 'system',
      sorter: (compA, compB) => sortStringAscending(compA.system, compB.system),
    },
    {
      title: 'Instance',
      dataIndex: 'instance',
      key: 'instance',
      sorter: (compA, compB) =>
        sortStringAscending(compA.instance, compB.instance),
    },
    {
      title: 'Key Value',
      dataIndex: 'key',
      key: 'key',
      sorter: (compA, compB) => sortStringAscending(compA.key, compB.key),
    },
    {
      title: (
        <PlusCircleOutlined
          disabled={action === 'key_add'}
          onClick={() => onAction('key_add')}
        />
      ),
      dataIndex: 'actions',
      key: 'actions',
      render: (_, record: ForeignKey) => (
        <DeleteOutlined onClick={() => handleDelete(record)} />
      ),
    },
  ];

  return (
    <Table
      showSorterTooltip
      columns={columns}
      dataSource={getData()}
      loading={loading}
      rowKey={(record) => `${record.system}_${record.instance}`}
    />
  );
};

export default KeysTable;
