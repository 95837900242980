import React, { useContext } from 'react';
import { Col, Row } from 'antd';

import ComponentHeader from '@totem/components/ComponentHeader';
import PageSection from '@totem/components/PageSection';
import ComplianceContext from '@totem/components/surveyV2/complianceSummary/complianceContext';
import PolicyCompliance from '@totem/components/surveyV2/complianceSummary/policyCompliance/policyCompliance';
import PortfolioHealthComplianceSummary from '@totem/components/surveyV2/complianceSummary/portfolioHealthOverview/portfolioHealthComplianceSummary';
import { isNotNull } from '@totem/utilities/common';

const ComplianceSection = () => {
  const { data } = useContext(ComplianceContext);
  const showCompliance = isNotNull(data);

  return (
    <>
      {showCompliance && (
        <PageSection>
          <ComponentHeader title={'Compliance'} />
          <Row gutter={16}>
            <Col span={12}>
              <PortfolioHealthComplianceSummary />
            </Col>
            <Col span={12}>
              <PolicyCompliance />
            </Col>
          </Row>
        </PageSection>
      )}
    </>
  );
};

export default ComplianceSection;
