import React, { useContext } from 'react';
import * as R from 'ramda';

import FilterChip from '@totem/components/common/FilterChip';
import { Criticality } from '@totem/types/criticality';

import PolicyAuditFindingDetailContext from './PolicyAuditFindingDetailContext';

const styles = {
  dropdown: {
    marginRight: '2rem',
  },
  filterChip: {
    marginLeft: '2rem',
    marginTop: '2rem',
  },
};

const PolicyAuditFindingDetailFilterChips = () => {
  const {
    input,
    setInput,
    buildingFilter,
    regionFilter,
    assigneeFilter,
  } = useContext(PolicyAuditFindingDetailContext);

  const { criticality } = input;

  const handleFilterDelete = (property: string, value: number) => {
    const update = input[property].filter(val => val !== value);

    setInput({
      ...input,
      [property]: update.length ? update : null,
    });
  };

  return (
    <>
      {!R.isNil(criticality) &&
        criticality.map(val => (
          <FilterChip
            key={val}
            label={`Criticality: ${Criticality[val]}`}
            style={styles.filterChip}
            onClose={() => handleFilterDelete('criticality', val)}
          />
        ))}
      {!buildingFilter.loading &&
        Object.entries(buildingFilter.applied).map(([id, name]) => (
          <FilterChip
            key={id}
            label={`Building: ${name}`}
            style={styles.filterChip}
            onClose={() => buildingFilter.onRemove(id)}
          />
        ))}
      {!regionFilter.loading &&
        Object.entries(regionFilter.applied).map(([id, name]) => (
          <FilterChip
            key={id}
            label={`Region: ${name}`}
            style={styles.filterChip}
            onClose={() => regionFilter.onRemove(id)}
          />
        ))}
      {!assigneeFilter.loading &&
        Object.entries(assigneeFilter.applied).map(([id, email]) => (
          <FilterChip
            key={id}
            label={`Assignee: ${email}`}
            style={styles.filterChip}
            onClose={() => assigneeFilter.onRemove(id)}
          />
        ))}
    </>
  );
};

export default PolicyAuditFindingDetailFilterChips;
