import React from 'react';
import { useParams } from 'react-router-dom';
import { Col, Row } from 'antd';

import BasePane from '@totem/components/BasePane';
import ContentLayout from '@totem/components/ContentLayout';
import PrimaryContentPane from '@totem/components/PrimaryContentPane';
import FindingsContainer from '@totem/components/surveyV2/findings/FindingsContainer';
import FindingsFilter from '@totem/components/surveyV2/findings/FindingsFilter';
import FindingsTable from '@totem/components/surveyV2/findings/FindingsTable';
import LeastCompliantByCategory from '@totem/components/surveyV2/findings/LeastCompliantByCategory';
import PolicyResults from '@totem/components/surveyV2/findings/PolicyResults';
import SourceQuestion from '@totem/components/surveyV2/findings/SourceQuestion';

import '../survey.css';

const styles = {
  title: {
    fontWeight: 700,
    padding: '2rem',
  },
};

const FindingPage = () => {
  const { questionId } = useParams();

  return (
    <ContentLayout>
      <FindingsContainer questionId={questionId}>
        <BasePane>
          <PrimaryContentPane>
            <FindingsFilter />
            <div style={styles.title} styleName="title middle">
              Findings Overview
            </div>
            <SourceQuestion />
            <Row>
              <Col span={6}>
                <PolicyResults />
              </Col>
              <Col span={18}>
                <LeastCompliantByCategory />
              </Col>
            </Row>
            <div style={styles.title} styleName="title middle">
              Findings
            </div>
            <FindingsTable />
          </PrimaryContentPane>
        </BasePane>
      </FindingsContainer>
    </ContentLayout>
  );
};

export default FindingPage;
