import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  ArrowLeftOutlined,
  EnvironmentOutlined,
  IdcardOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import * as R from 'ramda';

import Count from '@totem/components/common/Count';
import { GET_POLICY_AUDIT_TEMPLATE_QUESTION } from '@totem/graph/policyAuditTemplate';
import colors from '@totem/styles/colors';
import {
  FindingCompliantCounts,
  FindingCriticalityCounts,
  FindingLeastCompliantCounts,
} from '@totem/types/finding';
import { sumNonCompliantCounts } from '@totem/utilities/findingsUtilities';

import './policyAuditFindingDetail.css';

interface Props {
  id: string;
  location: {
    state: any;
  };
  leastCompliantCounts: FindingLeastCompliantCounts;
  criticalityCounts: FindingCriticalityCounts;
}

const styles = {
  icon: {
    marginRight: '0.5rem',
  },
  nonCompliantCount: {
    border: `2px solid ${colors.criticality.high}`,
    background: colors.neutral.white,
    minWidth: '7rem',
    marginBottom: '3rem',
  },
  compliantCount: {
    background: colors.neutral.ash,
    minWidth: '7rem',
    marginBottom: '3rem',
  },
  leastCompliantCount: {
    background: colors.neutral.ash,
  },
  headerIcon: {
    fontSize: '22px',
  },
};

const renderLeastCompliantSection = (
  counts: FindingCompliantCounts[],
  title: string,
  icon: React.ReactNode,
) => {
  return (
    <div>
      <div styleName="least-compliant-header-container">
        {icon}
        <div styleName="least-compliant-header">{title}</div>
      </div>
      <div>
        {counts
          .filter((entity) => Boolean(entity.nonCompliant))
          .map((entity, index) => {
            const name = entity?.name || '--';

            return (
              <div styleName="least-compliant-entity-container" key={index}>
                <div styleName="least-compliant-entity">{name}</div>
                <Count
                  size="small"
                  count={entity.nonCompliant}
                  style={styles.leastCompliantCount}
                />
              </div>
            );
          })}
      </div>
    </div>
  );
};

const PolicyAuditFindingDetailHeader = ({
  id,
  leastCompliantCounts,
  criticalityCounts,
}: Props) => {
  const navigate = useNavigate();
  const { data, loading } = useQuery(GET_POLICY_AUDIT_TEMPLATE_QUESTION, {
    variables: { id },
  });

  const navigateBack = () => {
    navigate(-1);
  };

  const label = loading
    ? ''
    : R.pathOr('', ['policyAuditTemplateQuestion', 'label'], data);

  const totalCompliantCounts = criticalityCounts.compliant;
  const totalNonCompliantCounts = sumNonCompliantCounts(criticalityCounts);

  return (
    <>
      <div styleName="source-question-container">
        <span styleName="source-question-back" onClick={navigateBack}>
          <ArrowLeftOutlined style={styles.icon} color={colors.antd.blue} />
          Back to Policies
        </span>
        <div styleName="source-question-content">
          <div styleName="subtitle">Selected Policy:</div>
          <span styleName="source-question">{label}</span>
        </div>
      </div>
      <div styleName="overview-container">
        <div styleName="overview-content">
          <div styleName="subtitle graph">Policy Results</div>
          <div styleName="policy-results-container">
            <div styleName="graph-title">Non-Compliant Responses</div>
            <Count
              count={totalNonCompliantCounts}
              style={styles.nonCompliantCount}
              size="large"
            />
            <div styleName="graph-title">Compliant Responses</div>
            <Count
              count={totalCompliantCounts}
              style={styles.compliantCount}
              size="large"
            />
          </div>
        </div>
        <div styleName="overview-content">
          <div styleName="subtitle graph">Least Compliant by Category</div>
          {renderLeastCompliantSection(
            leastCompliantCounts.assignee,
            'Assignee',
            <UserOutlined style={styles.headerIcon} />,
          )}
        </div>
        <div styleName="overview-content">
          {renderLeastCompliantSection(
            leastCompliantCounts.building,
            'Building',
            <EnvironmentOutlined style={styles.headerIcon} />,
          )}
        </div>
        <div styleName="overview-content">
          {renderLeastCompliantSection(
            leastCompliantCounts.region,
            'Region',
            <IdcardOutlined style={styles.headerIcon} />,
          )}
        </div>
      </div>
    </>
  );
};

export default PolicyAuditFindingDetailHeader;
