import React from 'react';

import RichTextInputQuill from '@totem/components/common/richText/RichTextInputQuill';

type Props = {
  value: string;
  onChange: (string) => void;
};

const RichTextInput = ({ value, onChange }: Props) => {
  return <RichTextInputQuill value={value} onChange={onChange} />;
};
export default RichTextInput;
