import React from 'react';

import Count from '@totem/components/common/Count';
import colors from '@totem/styles/colors';
import { HealthStatusCounts as HealthStatusCountsType } from '@totem/types/health';
import { getExactPercentage } from '@totem/utilities/numberUtilities';

import './policyAudits.css';

interface Props {
  healthStatusCounts: HealthStatusCountsType;
}

const getTotal = (counts: HealthStatusCountsType) => {
  return counts.onTrack + counts.atRisk + counts.offTrack + counts.needed;
};

const PolicyAuditHealthStatusCounts = ({ healthStatusCounts }: Props) => {
  const { onTrack, atRisk, offTrack, needed } = healthStatusCounts;
  const total = getTotal(healthStatusCounts);

  return (
    <>
      <div styleName="graph-header">Audit Health Breakdown</div>
      <div styleName="graph-container">
        <div styleName="graph-bar-container">
          <div styleName="graph-label health">On Track</div>
          <div styleName="graph-bar">
            <div
              style={{
                width: `${getExactPercentage(total, onTrack)}%`,
                background: colors.criticality.positive,
                height: '100%',
              }}
            />
          </div>
          <Count count={onTrack} size="small" />
        </div>
        <div styleName="graph-bar-container">
          <div styleName="graph-label health">At Risk</div>
          <div styleName="graph-bar">
            <div
              style={{
                width: `${getExactPercentage(total, atRisk)}%`,
                background: colors.criticality.low,
                height: '100%',
              }}
            />
          </div>
          <Count count={atRisk} size="small" />
        </div>
        <div styleName="graph-bar-container">
          <div styleName="graph-label health">Off Track</div>
          <div styleName="graph-bar">
            <div
              style={{
                width: `${getExactPercentage(total, offTrack)}%`,
                background: colors.criticality.critical,
                height: '100%',
              }}
            />
          </div>
          <Count count={offTrack} size="small" />
        </div>
        <div styleName="graph-bar-container">
          <div styleName="graph-label health">Needed</div>
          <div styleName="graph-bar">
            <div
              style={{
                width: `${getExactPercentage(total, needed)}%`,
                background: colors.criticality.info,
                height: '100%',
              }}
            />
          </div>
          <Count count={needed} size="small" />
        </div>
      </div>
    </>
  );
};

export default PolicyAuditHealthStatusCounts;
