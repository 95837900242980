import React, { useContext } from 'react';
import { TablePaginationConfig } from 'antd/es/table';
import { ColumnProps } from 'antd/lib/table';
import { SorterResult } from 'antd/lib/table/interface';

import AgreementContext from '@totem/components//agreements/AgreementReport/AgreementReportContext';
import HierarcyTable from '@totem/components/agreements/AgreementReport/HierarcyTable';
import { Agreement } from '@totem/components/agreements/models';
import Table from '@totem/components/common/table/Table';
import { DateStringToLocalDate } from '@totem/utilities/dateUtilities';

import '../agreements.css';

const AgreementTable = () => {
  const { reportData, input, setInput, totalRecords, loading } = useContext(
    AgreementContext,
  );

  const columns: ColumnProps<Agreement>[] = [
    {
      title: 'Agreement ID',
      dataIndex: 'contractNumber',
      key: 'contractNumber',
      className: 'tableAlignTop',
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        compA.contractNumber === compB.contractNumber
          ? 0
          : compA.contractNumber > compB.contractNumber
          ? 1
          : -1,
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      key: 'startDate',
      className: 'tableAlignTop',
      sortDirections: ['ascend', 'descend'],
      render: (_, record: Agreement) => (
        <span>{DateStringToLocalDate(record.startDate)}</span>
      ),
      sorter: (compA, compB) =>
        compA.startDate === compB.startDate
          ? 0
          : compA.startDate > compB.startDate
          ? 1
          : -1,
    },
    {
      title: 'End Date',
      dataIndex: 'endDate',
      key: 'endDate',
      className: 'tableAlignTop',
      sortDirections: ['ascend', 'descend'],
      render: (_, record: Agreement) => (
        <span>{DateStringToLocalDate(record.endDate)}</span>
      ),
      sorter: (compA, compB) =>
        compA.endDate === compB.endDate
          ? 0
          : compA.endDate > compB.endDate
          ? 1
          : -1,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      className: 'tableAlignTop',
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        compA.description === compB.description
          ? 0
          : compA.description > compB.description
          ? 1
          : -1,
    },
    {
      title: 'Vender',
      dataIndex: 'vendor',
      key: 'vendor',
      className: 'tableAlignTop',
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        compA.vendor === compB.vendor
          ? 0
          : compA.vendor > compB.vendor
          ? 1
          : -1,
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
      className: 'tableAlignTop',
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        compA.phone === compB.phone ? 0 : compA.phone > compB.phone ? 1 : -1,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      className: 'tableAlignTop',
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        compA.email === compB.email ? 0 : compA.email > compB.email ? 1 : -1,
    },
  ];
  const getData = () => {
    if (typeof reportData !== 'undefined' && reportData !== null) {
      return reportData;
    }
    return [];
  };

  const handleTableChange = (
    updatedPagination: TablePaginationConfig,
    filters: SorterResult<Agreement>,
    sorter,
  ) => {
    const { ...params } = filters;

    let sortDir: string = sorter.order === 'descend' ? '-1' : '1';
    if (typeof sorter.order === 'undefined' || sorter.order === null) {
      if (
        typeof input.sortDirection !== 'undefined' &&
        input.sortDirection !== null
      ) {
        sortDir = input.sortDirection;
      }
    }

    let sortField = input.sortField ? input.sortField : 'contractNumber';
    if (
      typeof sorter.field !== 'undefined' &&
      typeof sorter.order !== 'undefined'
    ) {
      sortField = sorter.field;
    }

    // @ts-ignore
    setInput({
      ...input,
      ...params,
      pageSize: updatedPagination.pageSize,
      page: updatedPagination.current,
      sortField,
      sortDirection: sortDir,
    });
  };

  return (
    <Table
      showSorterTooltip
      columns={columns}
      dataSource={getData()}
      onChange={handleTableChange}
      loading={loading}
      rowKey={record => record.id}
      pagination={{
        current: input.page,
        pageSize: input.pageSize,
        total: totalRecords,
        showSizeChanger: true,
      }}
      expandable={{
        expandedRowRender: record => <HierarcyTable data={record} />,
        defaultExpandAllRows: false,
      }}
    />
  );
};

export default AgreementTable;
