import React, { useContext } from 'react';
import { Collapse, CollapseProps } from 'antd';

import PolicyContext from '@totem/components/surveyV2/policies/PoliciesContext';
import PolicyCategories from '@totem/components/surveyV2/policies/PolicyCategories';
import { isNull } from '@totem/utilities/common';
const Policies = () => {
  const { data } = useContext(PolicyContext);
  const items: CollapseProps['items'] = isNull(data)
    ? []
    : data.map((chk) => {
        return {
          key: chk.id,
          label: chk.name,
          children: <PolicyCategories template={chk} />,
        };
      });

  return <Collapse items={items} />;
};

export default Policies;
