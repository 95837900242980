import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ColumnProps } from 'antd/lib/table';

import Table from '@totem/components/common/table/Table';
import { Filter, ObjectReference } from '@totem/types/common';
import { FilterValue } from '@totem/types/events';
import {
  TicketingFilterOptions,
  TicketQueryResult,
  TicketQueryResults,
} from '@totem/types/ticketing';
import { formatDate } from '@totem/utilities/common';
import {
  buildAffiliatedFilter,
  getFilterOptions,
  getPriorityFilter,
  getStatusFilter,
  getTicketTypeFilter,
} from '@totem/utilities/ticketing';

import '../ticketing.css';

type Props = {
  page: number;
  pageSize: number;
  total: number;
  sortField: string;
  sortDirection: number;
  ticketResults: TicketQueryResults;
  filterOptions: TicketingFilterOptions;
  affiliatedQueues: ObjectReference[];
  currentUserEmail: string;
  loading: boolean;
  onPagingChange: (page: number, pageSize: number) => void;
  onSortChange: (field: string, direction: number) => void;
  onFilterChange: (filter: Filter[]) => void;
};

const AffiliatedTicketsTable = ({
  ticketResults,
  filterOptions,
  affiliatedQueues,
  loading,
  currentUserEmail,
  page,
  pageSize,
  total,
  sortField,
  sortDirection,
  onPagingChange,
  onSortChange,
  onFilterChange,
}: Props) => {
  const [lastFilter, setLastFilter] = useState<string>('{}');
  const [buildingFilterOptions, setBuildingFilterOptions] = useState<
    FilterValue[]
  >([]);
  const [organizationFilterOptions, setOrganizationFilterOptions] = useState<
    FilterValue[]
  >([]);

  useEffect(() => {
    if (typeof filterOptions !== 'undefined' && filterOptions !== null) {
      if (
        typeof filterOptions.building !== 'undefined' &&
        filterOptions.building !== null
      ) {
        const options: FilterValue[] = [];
        filterOptions.building.forEach(item => {
          options.push({
            text: item.name,
            value: item.value,
          });
        });
        setBuildingFilterOptions(options);
      }
      if (
        typeof filterOptions.organization !== 'undefined' &&
        filterOptions.organization !== null
      ) {
        const options: FilterValue[] = [];
        filterOptions.organization.forEach(item => {
          options.push({
            text: item.name,
            value: item.value,
          });
        });
        setOrganizationFilterOptions(options);
      }
    }
  }, [filterOptions]);

  const queueIndex = (queueId: string) => {
    return affiliatedQueues.findIndex(aq => aq.id === queueId);
  };

  const getInvolvement = (ticket: TicketQueryResult) => {
    const partiesInvolved = ticket.ticket.partiesInvolved.filter(
      pi => pi.email === currentUserEmail && pi.status === 'ACTIVE',
    );

    const queues = ticket.ticket.queueAssignments.filter(
      qa => qa.status === 'ACTIVE' && queueIndex(qa.queueId) >= 0,
    );

    return (
      <>
        {partiesInvolved.map(pi => (
          <div key={ticket.ticket.id + pi.id}>{pi.type}</div>
        ))}
        {queues.map(qu => (
          <div key={ticket.ticket.id + qu.id}>{qu.name}</div>
        ))}
      </>
    );
  };

  const columns: ColumnProps<TicketQueryResult>[] = [
    {
      title: 'Number',
      dataIndex: 'ticket.number',
      key: 'ticket.number',
      render: (_, ticket: TicketQueryResult) => (
        <Link to={`/dashboard/ticket/${ticket.ticket.id}`}>
          {ticket.ticket.number}
        </Link>
      ),
      showSorterTooltip: true,
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        compA.ticket.number === compB.ticket.number
          ? 0
          : compA.ticket.number > compB.ticket.number
          ? 1
          : -1,
      filters: getTicketTypeFilter(),
    },
    {
      title: 'Category',
      dataIndex: 'ticket.category',
      key: 'ticket.category',
      render: (_, ticket: TicketQueryResult) => ticket.ticket.category,
      showSorterTooltip: true,
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        compA.ticket.category === compB.ticket.category
          ? 0
          : compA.ticket.category > compB.ticket.category
          ? 1
          : -1,
      filters: getFilterOptions(
        typeof filterOptions !== 'undefined' && filterOptions !== null
          ? filterOptions.categories
          : null,
      ),
    },
    {
      title: 'Organization',
      dataIndex: 'organization.name',
      key: 'organization.name',
      render: (_, ticket: TicketQueryResult) => ticket.organization.name,
      filters: organizationFilterOptions,
    },
    {
      title: 'Building',
      dataIndex: 'building.name',
      key: 'building.name',
      render: (_, ticket: TicketQueryResult) => ticket.building.name,
      filters: buildingFilterOptions,
    },
    {
      title: 'Summary',
      dataIndex: 'ticket.summary',
      key: 'ticket.summary',
      render: (_, ticket: TicketQueryResult) => ticket.ticket.summary,
      showSorterTooltip: true,
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        compA.ticket.summary === compB.ticket.summary
          ? 0
          : compA.ticket.summary > compB.ticket.summary
          ? 1
          : -1,
    },
    {
      title: 'Priority',
      dataIndex: 'ticket.priority',
      key: 'ticket.priority',
      render: (_, ticket: TicketQueryResult) => ticket.ticket.priority,
      showSorterTooltip: true,
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        compA.ticket.priority === compB.ticket.priority
          ? 0
          : compA.ticket.priority > compB.ticket.priority
          ? 1
          : -1,
      filters: getPriorityFilter(),
    },
    {
      title: 'Status',
      dataIndex: 'ticket.status',
      key: 'ticket.status',
      render: (_, ticket: TicketQueryResult) => ticket.ticket.status,
      showSorterTooltip: true,
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        compA.ticket.status === compB.ticket.status
          ? 0
          : compA.ticket.status > compB.ticket.status
          ? 1
          : -1,
      filters: getStatusFilter(),
    },
    {
      title: 'Involvement',
      dataIndex: 'involvement',
      key: 'involvement',
      render: (_, ticket: TicketQueryResult) => getInvolvement(ticket),
    },
    {
      title: 'Created',
      dataIndex: 'ticket.createdAt',
      key: 'ticket.createdAt',
      render: (_, ticket: TicketQueryResult) =>
        formatDate(ticket.ticket.createdAt),
      sorter: (compA, compB) =>
        compA.ticket.createdAt === compB.ticket.createdAt
          ? 0
          : compA.ticket.createdAt > compB.ticket.createdAt
          ? 1
          : -1,
      showSorterTooltip: true,
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Updated',
      dataIndex: 'ticket.updatedAt',
      key: 'ticket.updatedAt',
      render: (_, ticket: TicketQueryResult) =>
        formatDate(ticket.ticket.updatedAt),
      sorter: (compA, compB) =>
        compA.ticket.updatedAt === compB.ticket.updatedAt
          ? 0
          : compA.ticket.updatedAt > compB.ticket.updatedAt
          ? 1
          : -1,
      showSorterTooltip: true,
      sortDirections: ['ascend', 'descend'],
    },
  ];

  const getTickets = () => {
    if (ticketResults !== null) {
      if (ticketResults.tickets !== null) {
        return ticketResults.tickets;
      }
    }
    return [];
  };

  const handleTableChange = (pagination, filters, sorter) => {
    if (page !== pagination.current || pageSize !== pagination.pageSize) {
      onPagingChange(pagination.current, pagination.pageSize);
    }

    const sortDir: number = sorter.order === 'descend' ? -1 : 1;
    if (
      typeof sorter.field !== 'undefined' &&
      typeof sorter.order !== 'undefined' &&
      (sortField !== sorter.field || sortDirection !== sortDir)
    ) {
      onSortChange(sorter.field.replace('ticket.', ''), sortDir);
    }

    const currentFilterString = JSON.stringify(filters);
    if (currentFilterString !== lastFilter) {
      const filter = buildAffiliatedFilter(filters);
      onFilterChange(filter);
      setLastFilter(currentFilterString);
    }
  };

  return (
    <Table
      showSorterTooltip
      columns={columns}
      dataSource={getTickets()}
      loading={loading}
      onChange={handleTableChange}
      pagination={{ current: page, pageSize, total, showSizeChanger: true }}
      rowKey={record => record.ticket.id}
    />
  );
};

export default AffiliatedTicketsTable;
