import { BuildingsConnection } from './building';
import { ControlSystemsConnection } from './controlSystem';
import { OrganizationType } from './organization';

export interface Customer {
  id: string;
  name: string;
  type: OrganizationType;
  domain: string;
  buildings: BuildingsConnection;
  controlSystems: ControlSystemsConnection;
  createdBy: string;
  createdAt: string;
  updatedBy: string;
  updatedAt: string;
}

export interface CustomersConnection {
  totalCount: number;
  customers: Customer[];
}

export interface CustomersConnectionInput {
  limit?: number;
  offset?: number;
  id?: string[];
  name?: string;
  domain?: string;
  sortOrder?: 'ascend' | 'descend' | string;
  sortBy?: 'name' | 'domain' | string;
}

export interface AddEditCustomer {
  id?: string;
  name: string;
  domain: string;
}

export interface DeleteCustomer {
  id: string;
  name?: string;
  domain?: string;
}

export enum CustomerAction {
  Add,
  Edit,
  Delete,
}
