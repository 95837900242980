import React from 'react';

import FilterChip from '@totem/components/common/FilterChip';
import { Criticality } from '@totem/types/criticality';
import { FindingGroup, FindingsConnectionInput } from '@totem/types/finding';
import { LevelOfEffort } from '@totem/types/levelOfEffort';
import { controlSystemTypeMap } from '@totem/utilities/controlSystemsUtilities';

import './findings.css';

const styles = {
  dropdown: {
    marginRight: '2rem',
  },
  filterChip: {
    marginLeft: '2rem',
    marginTop: '2rem',
  },
};

interface Props {
  input: FindingsConnectionInput;
  onChange: (input: Partial<FindingsConnectionInput>) => void;
}

const FindingFilterChips = ({ input, onChange }: Props) => {
  const { controlSystemType, criticality, group, levelOfEffort } = input;

  const handleFilterDelete = (property: string, value: number) => {
    const updatedValue = input[property].filter(val => val !== value);
    onChange({ [property]: updatedValue.length ? updatedValue : null });
  };

  return (
    <>
      {controlSystemType &&
        controlSystemType.map(type => {
          const label = controlSystemTypeMap[type]?.text || 'N/A';

          return (
            <FilterChip
              key={type}
              label={`Control System Type: ${label}`}
              style={styles.filterChip}
              onClose={() => handleFilterDelete('controlSystemType', type)}
            />
          );
        })}
      {criticality &&
        criticality.map(crit => {
          const label = Criticality[crit] || 'N/A';

          return (
            <FilterChip
              key={crit}
              label={`Criticality: ${label}`}
              style={styles.filterChip}
              onClose={() => handleFilterDelete('criticality', crit)}
            />
          );
        })}
      {group &&
        group.map(grp => {
          const label = FindingGroup[grp] || 'N/A';

          return (
            <FilterChip
              key={grp}
              label={`Findings Group: ${label}`}
              style={styles.filterChip}
              onClose={() => handleFilterDelete('group', grp)}
            />
          );
        })}
      {levelOfEffort &&
        levelOfEffort.map(loe => {
          const label = LevelOfEffort[loe] || 'N/A';

          return (
            <FilterChip
              key={loe}
              label={`Level Of Effort: ${label}`}
              style={styles.filterChip}
              onClose={() => handleFilterDelete('levelOfEffort', loe)}
            />
          );
        })}
    </>
  );
};

export default FindingFilterChips;
