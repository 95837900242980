import { createContext } from 'react';

import { CVERecord } from '@totem/components/software/cves/types';

interface Context {
  loading: boolean;
  ids: string[];
  data: CVERecord[];
  selectedItem: CVERecord;
  action: string;
  onAction?: (action: string, item: CVERecord) => void;
}

export default createContext<Context>({
  loading: false,
  ids: [],
  data: null,
  selectedItem: null,
  action: '',
  onAction: () => {},
});
