import React from 'react';
import { useParams } from 'react-router-dom';

import SurveyInstanceContainer from '@totem/components/surveyV2/instanceDetail/SurveyInstanceContainer';
import SurveyInstanceDetail from '@totem/components/surveyV2/instanceDetail/SurveyInstanceDetail';
const SurveyDetailPage = () => {
  const { id } = useParams();

  return (
    <SurveyInstanceContainer surveyInstanceId={id}>
      <SurveyInstanceDetail />
    </SurveyInstanceContainer>
  );
};

export default SurveyDetailPage;
