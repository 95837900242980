import React from 'react';
import { useParams } from 'react-router-dom';

import BasePane from '@totem/components/BasePane';
import CardBody from '@totem/components/CardBody';
import ContentLayout from '@totem/components/ContentLayout';
import DeviceDetailsLayout from '@totem/components/devices/deviceDetails/DeviceDetailsLayout';
import PrimaryContentPane from '@totem/components/PrimaryContentPane';

const DeviceDetailsPage = () => {
  const { id } = useParams();
  return (
    <ContentLayout>
      <BasePane>
        <PrimaryContentPane>
          <CardBody>
            <DeviceDetailsLayout deviceId={id} />
          </CardBody>
        </PrimaryContentPane>
      </BasePane>
    </ContentLayout>
  );
};

export default DeviceDetailsPage;
