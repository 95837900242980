/* eslint-disable */
export const validateEmail = email => {
  const regularExpression = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regularExpression.test(email);
};

export const validatePhoneNumber = phoneNumber => {
  const regularExpression = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
  return regularExpression.test(phoneNumber);
};

export const validateURL = url => {
  const regularExpression = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
  return regularExpression.test(url);
};

const validation = {
  validateEmail,
  validatePhoneNumber,
  validateURL,
};

export default validation;
