import React, { useContext } from 'react';
import {
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
} from '@ant-design/icons';
import { Button, Dropdown, MenuProps } from 'antd';

import UserProfileContext from '@totem/components/UserProfileContext';
import { APIConfiguration } from '@totem/types/nozomi';
import {
  isMemberOf,
  securityGroupCheckConstraintEmpty,
} from '@totem/utilities/userUtilities';

import '../../services.css';

export interface Props {
  apiConfig: APIConfiguration;
  onEdit: (apiConfig: APIConfiguration) => void;
  onCopy: (apiConfig: APIConfiguration) => void;
  onDelete: (apiConfig: APIConfiguration) => void;
}

const NozomiProvisioningActionMenu = ({
  apiConfig,
  onEdit,
  onCopy,
  onDelete,
}: Props) => {
  const { userProfile } = useContext(UserProfileContext);

  const isConfigEditor = isMemberOf(
    userProfile,
    'threat_detection_configuration_admin',
    securityGroupCheckConstraintEmpty,
  );

  const getActionMenuItems = (): MenuProps['items'] => {
    const menuItems: MenuProps['items'] = [];

    if (isConfigEditor) {
      menuItems.push({
        key: 'edit',
        label: 'Edit',
        title: 'Edit',
        icon: <EditOutlined />,
      });

      menuItems.push({
        key: 'copy',
        label: 'Copy',
        title: 'Copy',
        icon: <CopyOutlined />,
      });

      menuItems.push({
        key: 'delete',
        label: 'Delete',
        title: 'Delete',
        icon: <DeleteOutlined />,
      });
    }

    return menuItems;
  };

  const onMenuClicked = ({ key }) => {
    // eslint-disable-next-line default-case
    switch (key) {
      case 'edit':
        onEdit(apiConfig);
        break;
      case 'copy':
        onCopy(apiConfig);
        break;
      case 'delete':
        onDelete(apiConfig);
        break;
    }
  };

  return (
    <>
      <Dropdown
        menu={{ items: getActionMenuItems(), onClick: onMenuClicked }}
        trigger={['click']}
      >
        <Button shape="circle" icon={<EllipsisOutlined />} />
      </Dropdown>
    </>
  );
};

export default NozomiProvisioningActionMenu;
