import React, { ReactNode, useContext, useEffect, useState } from 'react';
import dayjs from 'dayjs';

import CommissioningReportContext from '@totem/components/commissionReport/CommissioningReportContext';
import {
  CommissionContractRecord,
  CommissioningReportInput,
} from '@totem/components/commissionReport/types';
import ContractLineEditModal from '@totem/components/contracts/edits/ContractLineEditModal';
import TicketCreateModal from '@totem/components/ticketing/create/TicketCreateModal';
import UserProfileContext from '@totem/components/UserProfileContext';
import { getToken } from '@totem/utilities/accountUtilities';
import { COMMISSION_CONTRACT_LINES_MISSING_DEVICES } from '@totem/utilities/endpoints';

type Props = {
  refresh?: boolean;
  onRecordTotalChanged?: (total: number) => void;
  children?: ReactNode;
};

const CommissioningReportContainer = ({
  refresh,
  onRecordTotalChanged,
  children,
}: Props) => {
  const { userProfile } = useContext(UserProfileContext);
  const [refreshData, setRefreshData] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [reportData, setReportData] =
    useState<CommissionContractRecord[]>(null);
  const [action, setAction] = useState<string>('');
  const [selectedRecord, setSelectedRecord] =
    useState<CommissionContractRecord>(null);
  const [input, updateInput] = useState<CommissioningReportInput>({
    pageSize: 10,
    page: 1,
    sortField: 'contract.billingContainers[0].lines[0].serviceAddress.name',
    sortDirection: '1',
    status: [],
    organization: [userProfile.organization.id],
  });
  const setInput = (updated: Partial<CommissioningReportInput>) => {
    updateInput({ ...input, ...updated });
    setRefreshData(true);
  };

  useEffect(() => {
    if (refreshData) {
      setRefreshData(false);
      setIsLoading(true);

      fetch(`${COMMISSION_CONTRACT_LINES_MISSING_DEVICES}`, {
        method: 'GET',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
      })
        .then((res) => res.json())
        .then((result: CommissionContractRecord[]) => {
          setReportData(result);
          setTotalRecords(result.length);

          if (
            typeof onRecordTotalChanged !== 'undefined' &&
            onRecordTotalChanged !== null
          ) {
            onRecordTotalChanged(result.length);
          }
        })
        .then(() => {
          setIsLoading(false);
        });
    }
  }, [refreshData, refresh]);

  const handleAction = (
    actionName: string,
    actionRecord: CommissionContractRecord,
  ) => {
    setAction(actionName);
    setSelectedRecord(actionRecord);
  };
  const handleLineEditChanged = () => {
    setRefreshData(true);
  };

  const handleLineEditClose = () => {
    setSelectedRecord(null);
    setAction('');
  };

  const getTicketSummary = (): string => {
    if (typeof selectedRecord !== 'undefined' && selectedRecord !== null) {
      let ticketSummary = 'Install / Assign Device';
      if (
        selectedRecord.contract.billingContainers.lines.serviceAddress.name ===
          null ||
        selectedRecord.contract.billingContainers.lines.serviceAddress.name ===
          ''
      ) {
        ticketSummary = `${ticketSummary} / Assign Building`;
      }
      return ticketSummary;
    }
    return 'Commissioning Report Issue';
  };

  const getSalesOrderNumber = () => {
    if (typeof selectedRecord !== 'undefined' && selectedRecord !== null) {
      let serviceOrderNumber = '';
      for (
        let idx = 0;
        idx < selectedRecord.contract.billingContainers.lines.keys.length;
        idx++
      ) {
        const lineKey =
          selectedRecord.contract.billingContainers.lines.keys[idx];
        if (
          lineKey.system === 'NetSuite' &&
          lineKey.instance === 'NETSUITE_SALES_ORDER_NUMBER'
        ) {
          serviceOrderNumber = lineKey.key;
        }
      }

      return `Sales Order: ${serviceOrderNumber} / Line: ${selectedRecord.contract.billingContainers.lines.number}`;
    }
    return '';
  };

  const getServicesForSelectedRecord = () => {
    if (typeof selectedRecord !== 'undefined' && selectedRecord !== null) {
      const services =
        selectedRecord.contract.billingContainers.lines.services.map((svc) => {
          return `${svc.name}\n`;
        });
      return services;
    }
    return '';
  };

  const getTicketDescription = () => {
    const description = `Commissioning Report Issue: \n\n${getSalesOrderNumber()}\n\nServices:\n${getServicesForSelectedRecord()}\n\n${getTicketSummary()}`;
    return description;
  };

  return (
    <CommissioningReportContext.Provider
      value={{
        loading: isLoading,
        input,
        setInput,
        reportData,
        totalRecords,
        onAction: handleAction,
      }}
    >
      <div>{children}</div>
      {action === 'EditLine' &&
        typeof selectedRecord !== 'undefined' &&
        selectedRecord !== null && (
          <ContractLineEditModal
            contractLine={selectedRecord.contract.billingContainers.lines}
            visible={action === 'EditLine'}
            contractId={selectedRecord.contract.id}
            billingContainerId={selectedRecord.contract.billingContainers.id}
            onContractLineChanged={handleLineEditChanged}
            onClose={handleLineEditClose}
          />
        )}
      {action === 'CreateTicket' &&
        typeof selectedRecord !== 'undefined' &&
        selectedRecord !== null && (
          <TicketCreateModal
            onClose={(doRefresh) => {
              handleAction('', null);
              if (doRefresh) {
                setRefreshData(true);
              }
            }}
            visible={
              action === 'CreateTicket' &&
              typeof selectedRecord !== 'undefined' &&
              selectedRecord !== null
            }
            keys={[
              {
                system: 'CONTRACT_LINE',
                instance: 'IBD',
                key: selectedRecord.contract.billingContainers.lines.id,
                uid: `CONTRACT_LINE:IBD:${selectedRecord.contract.billingContainers.lines.id}`,
                createdByType: 'SYSTEM',
                createdByName: userProfile.email,
                createdById: userProfile.id,
                createdAt: dayjs().toISOString(),
              },
              {
                system: 'BILLING_CONTAINER',
                instance: 'IBD',
                key: selectedRecord.contract.billingContainers.id,
                uid: `BILLING_CONTAINER:IBD:${selectedRecord.contract.billingContainers.id}`,
                createdByType: 'SYSTEM',
                createdByName: userProfile.email,
                createdById: userProfile.id,
                createdAt: dayjs().toISOString(),
              },
              {
                system: 'CONTRACT',
                instance: 'IBD',
                key: selectedRecord.contract.id,
                uid: `CONTRACT:IBD:${selectedRecord.contract.id}`,
                createdByType: 'SYSTEM',
                createdByName: userProfile.email,
                createdById: userProfile.id,
                createdAt: dayjs().toISOString(),
              },
            ]}
            defaultSummary={getTicketSummary()}
            defaultDescription={getTicketDescription()}
            defaultCategory="IB Dashboard"
            defaultSubCategory="Provisioning"
          />
        )}
    </CommissioningReportContext.Provider>
  );
};

export default CommissioningReportContainer;
