import React, { useContext, useEffect, useState } from 'react';
import { Button, Form, Input, Modal, Select } from 'antd';
const FormItem = Form.Item;
const { Option } = Select;
import * as R from 'ramda';

import ModalFooter from '@totem/components/ModalFooter';
import ModalTitle from '@totem/components/ModalTitle';
import OrganizationContactsContext from '@totem/components/settings/sections/contacts/contactsContext';
import { PointOfContact } from '@totem/components/settings/sections/contacts/types';
import { getToken } from '@totem/utilities/accountUtilities';
import { CONFIGURATION_ENDPOINT } from '@totem/utilities/endpoints';

const styles = {
  form: {
    width: '100%',
  },
  formItem: {
    paddingBottom: '0',
    marginBottom: '1rem',
  },
};

type Errors = {
  name?: string;
  email?: string;
  phone?: string;
};

type Props = {
  open: boolean;
  pointOfContact: PointOfContact;
};

const ContactEditModal = ({ open, pointOfContact }: Props) => {
  const { onAction } = useContext(OrganizationContactsContext);
  const [currentContact, setCurrentContact] = useState<PointOfContact>(
    pointOfContact !== null
      ? pointOfContact
      : {
          id: '',
          name: '',
          email: '',
          phone: '',
          labels: [],
        },
  );
  const [errors, setErrors] = useState<Errors>({});
  const [labelOptions, setLabelOptions] = useState<string[]>([
    'Engagement Director',
  ]);

  useEffect(() => {
    fetch(`${CONFIGURATION_ENDPOINT}/organizationContactTypes`, {
      method: 'GET',
      headers: new Headers({
        Authorization: `Bearer ${getToken()}`,
      }),
    })
      .then((res) => res.json())
      .then((result: string[]) => {
        setLabelOptions(result);
      });
  }, []);

  const isValid = (): boolean => {
    const { name, email } = currentContact;

    const err: Errors = {};

    if (!name) {
      err.name = 'Please enter a name';
    }

    if (!email) {
      err.email = 'Please enter an email address';
    }

    setErrors(err);
    return R.isEmpty(err);
  };

  const handleCancel = () => {
    onAction('CancelEditContact', null);
  };

  const handleSubmit = () => {
    if (isValid()) {
      onAction('PutContact', currentContact);
    }
  };

  return (
    <Modal
      open={open}
      title={
        <ModalTitle>
          {pointOfContact.id === '' ? 'Create Contact' : 'Edit Contact'}
        </ModalTitle>
      }
      onCancel={handleCancel}
      footer={
        <ModalFooter>
          <Button onClick={handleCancel}>Close</Button>
          <Button type="primary" onClick={handleSubmit}>
            Save
          </Button>
        </ModalFooter>
      }
    >
      <Form layout="vertical" style={styles.form}>
        <FormItem
          label="Contact Name"
          colon={false}
          style={styles.formItem}
          validateStatus={R.isNil(errors.name) ? 'success' : 'error'}
          help={R.isNil(errors.name) ? null : errors.name}
        >
          <Input
            placeholder="Enter Contact Name"
            value={currentContact.name}
            onChange={(event) =>
              setCurrentContact({
                ...currentContact,
                name: event.target.value,
              })
            }
          />
        </FormItem>
        <FormItem
          label="Contact Email"
          colon={false}
          style={styles.formItem}
          validateStatus={R.isNil(errors.email) ? 'success' : 'error'}
          help={R.isNil(errors.email) ? null : errors.email}
        >
          <Input
            placeholder="Enter Contact Email"
            value={currentContact.email}
            onChange={(event) =>
              setCurrentContact({
                ...currentContact,
                email: event.target.value,
              })
            }
          />
        </FormItem>
        <FormItem
          label="Contact Phone"
          colon={false}
          style={styles.formItem}
          validateStatus={R.isNil(errors.phone) ? 'success' : 'error'}
          help={R.isNil(errors.phone) ? null : errors.phone}
        >
          <Input
            placeholder="Enter Contact Phone"
            value={currentContact.phone}
            onChange={(event) =>
              setCurrentContact({
                ...currentContact,
                phone: event.target.value,
              })
            }
          />
        </FormItem>
        <FormItem
          label="Labels"
          colon={false}
          style={styles.formItem}
          validateStatus={R.isNil(errors.phone) ? 'success' : 'error'}
          help={R.isNil(errors.phone) ? null : errors.phone}
        >
          <Select
            mode="multiple"
            allowClear
            placeholder="labels"
            value={currentContact.labels}
            onChange={(labels) =>
              setCurrentContact({
                ...currentContact,
                labels,
              })
            }
          >
            {labelOptions.map((label) => (
              <Option key={label} value={label}>
                {label}
              </Option>
            ))}
          </Select>
        </FormItem>
      </Form>
    </Modal>
  );
};

export default ContactEditModal;
