import { createContext, ReactNode } from 'react';

import { ComponentInstance } from '@totem/components/home/lib/types';

interface Context {
  componentInstance: ComponentInstance;
  onSetTitle: (titleNode: ReactNode) => void;
}

export default createContext<Context>({
  componentInstance: null,
  onSetTitle: () => {},
});
