import { createContext } from 'react';

import { User, UsersConnectionInput } from '@totem/types/user';

export interface UsersContext {
  totalCount: number;
  users: User[];
  loading: boolean;
  input: UsersConnectionInput;
  setInput: (input: UsersConnectionInput) => void;
}

export const initialContext: UsersContext = {
  totalCount: 0,
  users: [],
  loading: false,
  input: { limit: null, offset: null },
  setInput: () => {},
};

export default createContext<UsersContext>(initialContext);
