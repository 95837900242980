import React, { useContext } from 'react';
import * as R from 'ramda';

import DropdownAutoCompleteFilter from '@totem/components/common/autoCompleteFilter/DropdownAutoCompleteFilter';
import DropdownFilter from '@totem/components/common/dropdownFilter/DropdownFilter';
import FiltersBody from '@totem/components/FiltersBody';
import FiltersContainer from '@totem/components/FiltersContainer';
import FiltersTitle from '@totem/components/FiltersTitle';
import { ControlSystemType } from '@totem/types/controlSystem';
import { HEALTH_STATUS_ENUM } from '@totem/types/health';
import {
  PolicyAuditCompletionStatus,
  PolicyAuditsConnectionInput,
} from '@totem/types/policyAudit';
import { controlSystemTypeMap } from '@totem/utilities/controlSystemsUtilities';
import { removeIntegerKeys } from '@totem/utilities/enumUtilities';

import PolicyAuditFilterChips from './PolicyAuditFilterChips';
import PolicyAuditsContext from './PolicyAuditsContext';

import './policyAudits.css';

interface Props {
  onChange: (input: Partial<PolicyAuditsConnectionInput>) => void;
}

const styles = {
  dropdown: {
    marginRight: '2rem',
  },
};

const HEALTH_FILTERS = Object.keys(HEALTH_STATUS_ENUM)
  .map((key) => ({
    label: key,
    value: HEALTH_STATUS_ENUM[key],
  }))
  .concat({ label: 'Needed', value: -1 });

const COMPLETION_FILTERS = Object.keys(
  removeIntegerKeys(PolicyAuditCompletionStatus),
).map((key) => ({
  label: key,
  value: PolicyAuditCompletionStatus[key],
}));

const CONTROL_SYSTEM_TYPE_FILTERS = Object.values(
  removeIntegerKeys(ControlSystemType),
).map((type) => ({
  label: controlSystemTypeMap[type]?.text,
  value: type,
}));

const START_DATE_FILTERS = [
  {
    label: 'Has Start Date',
    value: 1,
  },
  {
    label: 'No Start Date',
    value: 0,
  },
];

const DUE_DATE_FILTERS = [
  {
    label: 'Has Due Date',
    value: 1,
  },
  {
    label: 'No Due Date',
    value: 0,
  },
];

const IS_LAUNCHED_FILTERS = [
  {
    label: 'Launched',
    value: 1,
  },
  {
    label: 'Not Launched',
    value: 0,
  },
];

const PolicyAuditFilters = ({ onChange }: Props) => {
  const { input, regionFilter, buildingFilter } =
    useContext(PolicyAuditsContext);

  const handleMultiNumberFilterChange = (
    property: string,
    update: string[],
  ) => {
    onChange({
      [property]: update.map(Number),
    });
  };

  const handleSingleBoolFilterChange = (property: string, update: string[]) => {
    onChange({
      [property]: update.length ? Boolean(Number(update[0])) : null,
    });
  };

  const startDate = R.isNil(input.startDate) ? [] : [input.startDate ? 1 : 0];
  const dueDate = R.isNil(input.dueDate) ? [] : [input.dueDate ? 1 : 0];

  const isLaunched = R.isNil(input.isLaunched)
    ? []
    : [input.isLaunched ? 1 : 0];

  return (
    <FiltersContainer>
      <FiltersTitle>Audit Dashboard</FiltersTitle>
      <FiltersBody>
        <DropdownFilter
          label="Health"
          options={HEALTH_FILTERS}
          value={input.health || []}
          onChange={(update) => handleMultiNumberFilterChange('health', update)}
          style={styles.dropdown}
        />
        <DropdownFilter
          label="Progress"
          options={COMPLETION_FILTERS}
          value={input.completionStatus || []}
          onChange={(update) =>
            handleMultiNumberFilterChange('completionStatus', update)
          }
          style={styles.dropdown}
        />
        <DropdownFilter
          label="Launched"
          options={IS_LAUNCHED_FILTERS}
          value={isLaunched || []}
          onChange={(update) =>
            handleSingleBoolFilterChange('isLaunched', update)
          }
          style={styles.dropdown}
          singleSelect
        />
        <DropdownFilter
          label="Start Date"
          options={START_DATE_FILTERS}
          value={startDate || []}
          onChange={(update) =>
            handleSingleBoolFilterChange('startDate', update)
          }
          style={styles.dropdown}
          singleSelect
        />
        <DropdownFilter
          label="Due Date"
          options={DUE_DATE_FILTERS}
          value={dueDate || []}
          onChange={(update) => handleSingleBoolFilterChange('dueDate', update)}
          style={styles.dropdown}
          singleSelect
        />
        <DropdownAutoCompleteFilter
          style={styles.dropdown}
          label="Region"
          {...regionFilter}
        />
        <DropdownAutoCompleteFilter
          style={styles.dropdown}
          label="Building"
          {...buildingFilter}
        />
        <DropdownFilter
          label="Control System Type"
          options={CONTROL_SYSTEM_TYPE_FILTERS}
          value={input.controlSystemType || []}
          onChange={(update) =>
            handleMultiNumberFilterChange('controlSystemType', update)
          }
          style={styles.dropdown}
        />
      </FiltersBody>
      <div styleName="filter-chips-container">
        <PolicyAuditFilterChips input={input} onChange={onChange} />
      </div>
    </FiltersContainer>
  );
};

export default PolicyAuditFilters;
