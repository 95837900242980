import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { TablePaginationConfig } from 'antd/es/table';
import { ColumnProps } from 'antd/lib/table';
import { SorterResult } from 'antd/lib/table/interface';

import OrganizationContext from '@totem/components/common/organizationContext/organizationContext';
import Table from '@totem/components/common/table/Table';
import { getCvssColorFromScore } from '@totem/components/software/cves/utilities';
import { SoftwareVulnerabilityReportRecord } from '@totem/components/software/vulnerabilityReport/types';
import VulnerabilityReportContext from '@totem/components/software/vulnerabilityReport/vulnerabilityReportContext';
import { isNotNull } from '@totem/utilities/common';
import { stringifyArray } from '@totem/utilities/tableFilterUtilities';
import { sortStringAscending } from '@totem/utilities/tableUtilities';
const VulnerabilityReportTable = () => {
  const { data, loading, input, filterOptions, setInput } = useContext(
    VulnerabilityReportContext,
  );
  const { regions, buildings } = useContext(OrganizationContext);
  const totalRecords =
    isNotNull(data) && isNotNull(data.paging) ? data.paging.totalRecords : 0;

  const getData = () => {
    if (isNotNull(data) && isNotNull(data.data)) {
      return data.data;
    }
    return [];
  };

  const getCvss = (rec: SoftwareVulnerabilityReportRecord) => {
    if (
      typeof rec.cveV3BaseScore !== 'undefined' &&
      rec.cveV2BaseScore !== null &&
      rec.cveV3BaseScore > 0
    ) {
      return 'CVSS V3';
    }
    if (
      typeof rec.cveV2BaseScore !== 'undefined' &&
      rec.cveV2BaseScore !== null &&
      rec.cveV2BaseScore > 0
    ) {
      return 'CVSS V3';
    }
    return '';
  };

  const getCvssBaseScore = (rec: SoftwareVulnerabilityReportRecord) => {
    if (
      typeof rec.cveV3BaseScore !== 'undefined' &&
      rec.cveV2BaseScore !== null &&
      rec.cveV3BaseScore > 0
    ) {
      return rec.cveV3BaseScore;
    }
    if (
      typeof rec.cveV2BaseScore !== 'undefined' &&
      rec.cveV2BaseScore !== null &&
      rec.cveV2BaseScore > 0
    ) {
      return rec.cveV2BaseScore;
    }
    return '';
  };

  const getCvssBaseScoreNumeric = (rec: SoftwareVulnerabilityReportRecord) => {
    if (
      typeof rec.cveV3BaseScore !== 'undefined' &&
      rec.cveV2BaseScore !== null &&
      rec.cveV3BaseScore > 0
    ) {
      return rec.cveV3BaseScore;
    }
    if (
      typeof rec.cveV2BaseScore !== 'undefined' &&
      rec.cveV2BaseScore !== null &&
      rec.cveV2BaseScore > 0
    ) {
      return rec.cveV2BaseScore;
    }
    return 0;
  };

  const getCvssBaseSeverity = (rec: SoftwareVulnerabilityReportRecord) => {
    if (
      typeof rec.cveV3BaseScore !== 'undefined' &&
      rec.cveV2BaseScore !== null &&
      rec.cveV3BaseScore > 0
    ) {
      return rec.cveV3BaseSeverity;
    }
    if (
      typeof rec.cveV2BaseScore !== 'undefined' &&
      rec.cveV2BaseScore !== null &&
      rec.cveV2BaseScore > 0
    ) {
      return rec.cveV2BaseSeverity;
    }
    return '';
  };

  const columns: ColumnProps<SoftwareVulnerabilityReportRecord>[] = [
    {
      title: 'Software Package',
      key: 'packageName',
      dataIndex: 'packageName',
      render: (_, record: SoftwareVulnerabilityReportRecord) => {
        return (
          <Link to={`/dashboard/software/${record.packageId}`}>
            {record.packageName}
          </Link>
        );
      },
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        sortStringAscending(compA.packageName, compB.packageName),
      filterMultiple: true,
      filteredValue: stringifyArray(input.packageName),
      filters:
        isNotNull(filterOptions) && isNotNull(filterOptions.packageName)
          ? filterOptions.packageName
              .sort((compA, compB) =>
                sortStringAscending(compA.name, compB.name),
              )
              .map((chk) => {
                return { text: chk.name, value: chk.value };
              })
          : [],
    },
    {
      title: 'Version',
      dataIndex: 'packageVersion',
      key: 'packageVersion',
      render: (_, record: SoftwareVulnerabilityReportRecord) => {
        return record.packageVersion;
      },
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        sortStringAscending(compA.packageVersion, compB.packageVersion),
      filterMultiple: true,
      filteredValue: stringifyArray(input.packageVersion),
      filters:
        isNotNull(filterOptions) && isNotNull(filterOptions.packageVersion)
          ? filterOptions.packageVersion
              .sort((compA, compB) =>
                sortStringAscending(compA.name, compB.name),
              )
              .map((chk) => {
                return { text: chk.name, value: chk.value };
              })
          : [],
    },
    {
      title: 'Publisher',
      dataIndex: 'packagePublisher',
      key: 'packagePublisher',
      render: (_, record: SoftwareVulnerabilityReportRecord) => {
        return record.packagePublisher;
      },
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        sortStringAscending(compA.packagePublisher, compB.packagePublisher),
      filterMultiple: true,
      filteredValue: stringifyArray(input.packagePublisher),
      filters:
        isNotNull(filterOptions) && isNotNull(filterOptions.packagePublisher)
          ? filterOptions.packagePublisher
              .sort((compA, compB) =>
                sortStringAscending(compA.name, compB.name),
              )
              .map((chk) => {
                return { text: chk.name, value: chk.value };
              })
          : [],
    },
    {
      title: 'Vulnerability',
      dataIndex: 'vulnerability',
      key: 'vulnerability',
      render: (_, record: SoftwareVulnerabilityReportRecord) => {
        return (
          <Link to={`/dashboard/software/cve/${record.vulnerability}`}>
            {record.vulnerability}
          </Link>
        );
      },
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        sortStringAscending(compA.vulnerability, compB.vulnerability),
      filterMultiple: true,
      filteredValue: stringifyArray(input.vulnerability),
      filters:
        isNotNull(filterOptions) && isNotNull(filterOptions.cve)
          ? filterOptions.cve
              .sort((compA, compB) =>
                sortStringAscending(compA.name, compB.name),
              )
              .map((chk) => {
                return { text: chk.name, value: chk.value };
              })
          : [],
    },
    {
      title: 'CVSS',
      dataIndex: 'cvss',
      render: (_, record: SoftwareVulnerabilityReportRecord) => {
        return getCvss(record);
      },
    },
    {
      title: 'Base Score',
      dataIndex: 'score',
      render: (_, record: SoftwareVulnerabilityReportRecord) => {
        return (
          <span
            style={{
              color: getCvssColorFromScore(getCvssBaseScoreNumeric(record)),
            }}
          >
            {getCvssBaseScore(record)}
          </span>
        );
      },
    },
    {
      title: 'Severity',
      dataIndex: 'severity',
      render: (_, record: SoftwareVulnerabilityReportRecord) => {
        return (
          <span
            style={{
              color: getCvssColorFromScore(getCvssBaseScoreNumeric(record)),
            }}
          >
            {getCvssBaseSeverity(record)}
          </span>
        );
      },
    },
    {
      title: 'Region',
      dataIndex: 'regionName',
      key: 'regionId',
      render: (_, record: SoftwareVulnerabilityReportRecord) => {
        return record.regionName;
      },
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        sortStringAscending(compA.regionName, compB.regionName),
      filterMultiple: true,
      filteredValue: stringifyArray(input.regionId),
      filters:
        typeof regions !== 'undefined' && regions !== null
          ? regions.map((region) => {
              return { text: region.name, value: region.id };
            })
          : [],
    },
    {
      title: 'Building',
      dataIndex: 'buildingName',
      key: 'buildingId',
      render: (_, record: SoftwareVulnerabilityReportRecord) => {
        return (
          <Link to={`/dashboard/buildings/${record.buildingId}`}>
            {record.buildingName}
          </Link>
        );
      },
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        sortStringAscending(compA.buildingName, compB.buildingName),
      filterMultiple: true,
      filteredValue: stringifyArray(input.buildingId),
      filters:
        typeof buildings !== 'undefined' && buildings !== null
          ? buildings.map((building) => {
              return { text: building.name, value: building.id };
            })
          : [],
    },
    {
      title: 'Control System',
      dataIndex: 'controlSystemName',
      key: 'controlSystemId',
      render: (_, record: SoftwareVulnerabilityReportRecord) => {
        return (
          <Link to={`/dashboard/controlsystems/${record.controlSystemId}`}>
            {record.controlSystemName}
          </Link>
        );
      },
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        sortStringAscending(compA.controlSystemName, compB.controlSystemName),
    },
    {
      title: 'Device Name',
      dataIndex: 'deviceName',
      key: 'deviceId',
      render: (_, record: SoftwareVulnerabilityReportRecord) => {
        return (
          <Link to={`/dashboard/devices/${record.deviceId}`}>
            {record.deviceName}
          </Link>
        );
      },
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        sortStringAscending(compA.deviceName, compB.deviceName),
    },
  ];

  const handleTableChange = (
    updatedPagination: TablePaginationConfig,
    filters: SorterResult<SoftwareVulnerabilityReportRecord>,
    sorter,
  ) => {
    const { ...params } = filters;

    const sortDir: string = sorter.order === 'descend' ? '-1' : '1';
    let sortField = 'packageName';
    if (
      typeof sorter.field !== 'undefined' &&
      typeof sorter.order !== 'undefined' &&
      sorter.field !== ''
    ) {
      sortField = sorter.field;
    }

    // @ts-ignore
    setInput({
      ...input,
      ...params,
      pageSize: updatedPagination.pageSize,
      page: updatedPagination.current,
      sortField,
      sortDirection: sortDir,
    });
  };

  return (
    <Table
      showSorterTooltip
      columns={columns}
      dataSource={getData()}
      loading={loading}
      onChange={handleTableChange}
      pagination={{
        current: input.page,
        pageSize: input.pageSize,
        total: totalRecords,
        showSizeChanger: true,
      }}
      rowKey={(record) =>
        record.deviceId +
        record.packageId +
        record.packageVersion +
        record.vulnerability
      }
    />
  );
};

export default VulnerabilityReportTable;
