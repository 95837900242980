import React, { useContext } from 'react';

import FilterChip from '@totem/components/common/FilterChip';
import RemoteAccessSessionContext from '@totem/components/remoteAccess/sessions/RemoteAccessSessionContext';
import { RemoteAccessSessionsInput } from '@totem/components/remoteAccess/types';

const styles = {
  filterChip: {
    marginLeft: '2rem',
    marginTop: '2rem',
  },
};

const RemoteAccessFilterChips = () => {
  const { input, setInput } = useContext(RemoteAccessSessionContext);

  const handleMultiFilterDelete = (
    property: keyof RemoteAccessSessionsInput,
    value: string,
  ) => {
    // @ts-ignore
    const updatedValue = input[property].filter((val) => val !== value);
    setInput({
      ...input,
      [property]: updatedValue.length ? updatedValue : null,
    });
  };

  return (
    <>
      {input.group &&
        input.group.map((grp) => {
          return (
            <FilterChip
              key={grp}
              label={`Group: ${grp}`}
              style={styles.filterChip}
              onClose={() => handleMultiFilterDelete('group', grp)}
            />
          );
        })}
    </>
  );
};

export default RemoteAccessFilterChips;
