import { VulnerabilitySummaryInput } from '@totem/components/software/vulnerabilitySummary/types';
import { Filter, Params } from '@totem/types/common';

export const buildVulnerabilitySummaryFilters = (
  input: VulnerabilitySummaryInput,
) => {
  const paramFilters: Filter[] = [];

  if (
    typeof input.regionId !== 'undefined' &&
    input.regionId !== null &&
    input.regionId.length > 0
  ) {
    paramFilters.push({
      field: 'regionId',
      values: input.regionId,
    });
  }
  if (
    typeof input.buildingId !== 'undefined' &&
    input.buildingId !== null &&
    input.buildingId.length > 0
  ) {
    paramFilters.push({
      field: 'buildingId',
      values: input.buildingId,
    });
  }
  if (
    typeof input.controlSystemId !== 'undefined' &&
    input.controlSystemId !== null &&
    input.controlSystemId.length > 0
  ) {
    paramFilters.push({
      field: '_id',
      values: input.controlSystemId,
    });
  }

  return paramFilters;
};

export const buildParameters = (input: VulnerabilitySummaryInput) => {
  const params: Params = {
    paging: {
      page: 1,
      pageSize: 50000,
    },
    sort: {
      field: '',
      direction: 1,
    },
    filters: buildVulnerabilitySummaryFilters(input),
  };

  return params;
};
