import React, { useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Alert, Button, Card } from 'antd';

import DetailSpinner from '@totem/components/common/DetailSpinner';
import CriticalityFindings from '@totem/components/common/findings/CriticalityFindings';
import GroupFindings from '@totem/components/common/findings/GroupFindings';
import LevelOfEffortFindings from '@totem/components/common/findings/LevelOfEffortFindings';
import Grade from '@totem/components/common/Grade';
import PolicyAuditFindingsExport from '@totem/components/common/policyAuditFindingsExport/PolicyAuditFindingsExport';
import { GET_POLICY_AUDIT } from '@totem/graph/policyAudit';
import colors from '@totem/styles/colors';
import {
  Criticality,
  NON_COMPLIANT_CRITICALITIES,
} from '@totem/types/criticality';
import { FindingGroup, FindingsConnectionInput } from '@totem/types/finding';
import { LevelOfEffort } from '@totem/types/levelOfEffort';
import { PolicyAudit, Scores, ScoresKey } from '@totem/types/policyAudit';
import authUtilities, { isVendorUser } from '@totem/utilities/authUtilities';
import { isSameLetterGrade } from '@totem/utilities/gradeUtilities';

import ContentLayout from '../ContentLayout';

import FindingsTable from './FindingsTable';
import PercentageBar from './PercentageBar';

import './policyAuditDetail.css';

export interface Filters {
  criticality: Criticality[];
  group: FindingGroup[];
  levelOfEffort: LevelOfEffort[];
}

type ScoreType = 'group' | 'loe';

const styles = {
  card: {
    flex: 1,
    margin: '0rem 1.6rem 1rem 1.6rem',
  },
  cardHeader: {
    borderTop: `5px solid`,
    borderBottom: `1px solid ${colors.antd.borderGray}`,
    borderImage: `${colors.gradient.blue} 100% 0 0 0`,
  },
  cardBody: {
    borderTop: `1px solid ${colors.antd.borderGray}`,
  },
  grade: {
    display: 'inline-flex',
  },
};

const initialFilters: Filters = {
  criticality: [],
  group: [],
  levelOfEffort: [],
};

export const getFindingsConnectionInput = (
  id: string,
  { criticality, group, levelOfEffort }: Filters,
): FindingsConnectionInput => {
  return {
    limit: null,
    offset: null,
    policyAuditId: [id],
    criticality,
    group,
    levelOfEffort,
  };
};

export const renderScore = (
  scores: Scores,
  key: ScoresKey,
  title: string,
  type?: ScoreType,
) => {
  return (
    <div styleName="percentage-container" key={key}>
      <div styleName={type === 'loe' ? 'loe-score' : 'group-score'}>
        {title}
      </div>
      <PercentageBar score={scores[key]} />
      <Grade score={scores[key]} size="small" />
    </div>
  );
};

const Report = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [isMinimalModeledScoreView, setMinimalModeledScoreView] =
    useState<boolean>(false);

  const [filters, setFilters] = useState<Filters>(initialFilters);
  const [visible, setVisible] = useState<boolean>(true);
  const table = useRef();

  const handleMinimalModeledScoreClick = () => {
    setMinimalModeledScoreView(true);

    setFilters({
      group: [],
      levelOfEffort: [LevelOfEffort.Minimal],
      criticality: NON_COMPLIANT_CRITICALITIES,
    });

    // @ts-ignore
    table.current.scrollIntoView({
      behavior: 'smooth',
    });
  };

  const handleFiltersChange = (updatedFilters: Filters) => {
    setMinimalModeledScoreView(false);
    setFilters(updatedFilters);
  };

  const { loading, error, data } = useQuery<{ policyAudit: PolicyAudit }>(
    GET_POLICY_AUDIT,
    {
      variables: { id },
    },
  );

  if (loading || error) {
    return (
      <div styleName="spinner-container">
        <DetailSpinner />
      </div>
    );
  }

  const { policyAudit } = data;
  const { scores, findings } = policyAudit;

  const handleUpdateAudit = () => {
    const endpoint = authUtilities.isVendorUser()
      ? '/vendor-dashboard'
      : '/dashboard';

    navigate(`${endpoint}/policyaudits/${policyAudit.id}`);
  };

  const renderAlertDescription = () => {
    const count = findings.filter(
      ({ criticality, levelOfEffort }) =>
        NON_COMPLIANT_CRITICALITIES.includes(criticality) &&
        levelOfEffort === LevelOfEffort.Minimal,
    ).length;

    return (
      <>
        <span>
          Bring your audit score up to a
          <span styleName="inline-grade">
            <Grade
              score={scores.modeledMinimum}
              size="small"
              style={styles.grade}
            />
          </span>
          by resolving these {count} items rated for minimal effort.
        </span>
        <div styleName="alert-button-container">
          <Button size="small" onClick={handleMinimalModeledScoreClick}>
            View Items
          </Button>
        </div>
      </>
    );
  };

  // embedded systems will have no control system host findings
  const hasControlSystemHostFindings = findings.some(
    ({ group }) => group === FindingGroup['Control System Host'],
  );

  return (
    <ContentLayout
      pageTitle={`${policyAudit.controlSystem.name} Report`}
      pageSubtitle={[
        {
          label: policyAudit.organization.name,
        },
        {
          label: policyAudit.region.name,
        },
        {
          label: policyAudit.building.name,
          link: isVendorUser()
            ? ''
            : `/dashboard/buildings/${policyAudit.building.id}`,
        },
      ]}
      contentRight={
        <Button type="primary" onClick={handleUpdateAudit}>
          Update Audit Responses
        </Button>
      }
      breadcrumbs={(breadcrumbs) => [
        ...breadcrumbs,
        {
          label: policyAudit.controlSystem?.name,
          link: isVendorUser()
            ? ''
            : `/dashboard/controlsystems/${policyAudit.controlSystem.id}`,
        },
        {
          label: 'Audit Report',
        },
      ]}
    >
      <>
        <div styleName="base-pane">
          <div styleName="card-container">
            <Card
              bordered
              headStyle={styles.cardHeader}
              bodyStyle={styles.cardBody}
              title={
                <div styleName="card-header-container">
                  <div styleName="card-header-title">Score Summary</div>
                  <div styleName="grade-container">
                    <div styleName="overall-grade">{`overall - ${scores.summary}%`}</div>
                    <Grade score={scores.summary} />
                  </div>
                </div>
              }
              style={styles.card}
            >
              {renderScore(scores, 'systemUsers', 'System Users')}
              {renderScore(
                scores,
                'systemConfiguration',
                'System Configuration',
              )}
              {renderScore(scores, 'network', 'Network')}
              {renderScore(scores, 'continuity', 'Continuity')}
              {hasControlSystemHostFindings &&
                renderScore(scores, 'controlSystemHost', 'Control System Host')}
            </Card>
            <Card
              bordered
              headStyle={styles.cardHeader}
              title={
                <div styleName="card-header-title">
                  Score By Level Of Effort
                </div>
              }
              style={styles.card}
            >
              {renderScore(scores, 'minimal', 'Minimal', 'loe')}
              {renderScore(scores, 'moderate', 'Moderate', 'loe')}
              {renderScore(scores, 'maximum', 'Maximum', 'loe')}
            </Card>
          </div>
          {visible &&
            !isSameLetterGrade(scores.summary, scores.modeledMinimum) && (
              <div styleName="grade-alert">
                <Alert
                  type="info"
                  showIcon
                  message="Improve your score"
                  description={renderAlertDescription()}
                  closable
                  onClose={() => setVisible(false)}
                />
              </div>
            )}
          <Card
            bordered
            headStyle={styles.cardHeader}
            bodyStyle={styles.cardBody}
            title={
              <div styleName="card-header-title">
                {isMinimalModeledScoreView
                  ? 'Audit Findings'
                  : 'Audit Findings Overview'}
              </div>
            }
          >
            {!isMinimalModeledScoreView && (
              <>
                <div styleName="findings-container">
                  <div styleName="finding">
                    <GroupFindings findings={findings} />
                  </div>
                  <div styleName="finding">
                    <CriticalityFindings findings={findings} />
                  </div>
                  <div styleName="finding">
                    <LevelOfEffortFindings findings={findings} />
                  </div>
                </div>
                <div styleName="findings-title-container">Audit Findings</div>
              </>
            )}
            <div styleName="findings-table-container" ref={table}>
              <div styleName="table-buttons-container">
                <Button
                  onClick={() => {
                    setMinimalModeledScoreView(false);
                    setFilters(initialFilters);
                  }}
                >
                  Reset Filters
                </Button>
                <PolicyAuditFindingsExport
                  input={getFindingsConnectionInput(id, filters)}
                />
              </div>
              <FindingsTable
                findings={findings}
                filters={filters}
                onFilterChange={handleFiltersChange}
              />
            </div>
          </Card>
        </div>
      </>
    </ContentLayout>
  );
};

export default Report;
