import React, { ReactNode } from 'react';

import './cve.css';

type Props = {
  children?: ReactNode;
};
const CveCardTitle = ({ children }: Props) => {
  return <div styleName="cve-card-title">{children}</div>;
};

export default CveCardTitle;
