//import actionTypes from '@totem/actions/actionTypes';


import actionTypes from '../actions/actionTypes'
import initialState from './initialState';

export default function versionReducer(state = initialState.versions, action) {
  switch (action.type) {
    case actionTypes.GET_VERSIONS_SUCCESS: {
      return action.data;
    }
    default: {
      return state;
    }
  }
}
