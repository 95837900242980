import React, { useContext } from 'react';
import { Empty } from 'antd';

import SectionContext from '@totem/components/devices/variables/presentation/layout/layoutSection/SectionContext';
import CurrentValuesWithLastUpdateTable from '@totem/components/devices/variables/presentation/layout/templates/components/CurrentValuesWithLastUpdateTable';
import { isNotNull } from '@totem/utilities/common';

const CurrentValueWithLastUpdateTablesSection = () => {
  const { sectionLayout } = useContext(SectionContext);

  const sectionVariables = isNotNull(sectionLayout)
    ? sectionLayout.variables.filter(
        (element) => element.type === 'variablePurpose',
      )
    : [];

  return (
    <>
      {sectionVariables.length > 0 &&
        sectionVariables.map((sectionVariable) => (
          <CurrentValuesWithLastUpdateTable
            key={sectionVariable.id}
            purpose={sectionVariable.id}
            maxDecimalPlaces={sectionVariable.max_decimal_places}
          />
        ))}
      {sectionVariables.length === 0 && <Empty />}
    </>
  );
};

export default CurrentValueWithLastUpdateTablesSection;
