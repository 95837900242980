import React, { useContext, useState } from 'react';
import { Tabs, TabsProps } from 'antd';

import DetailsTabLayout from '@totem/components/administration/devices/CrossOrgDeviceManagement/DetailsTabLayout';
import BasePane from '@totem/components/BasePane';
import ContentLayout from '@totem/components/ContentLayout';
import DeviceContainer from '@totem/components/devices/devicesContainer/DeviceContainer';
import DeviceExportWebSockets from '@totem/components/devices/devicesContainer/DeviceExportWebSockets';
import DeviceFilters from '@totem/components/devices/devicesContainer/DeviceFilters';
import PrimaryContentPane from '@totem/components/PrimaryContentPane';
import TabTitle from '@totem/components/TabTitle';
import UserProfileContext from '@totem/components/UserProfileContext';
import { DEVICES_CROSS_ORG_PAGINATION_ENDPOINT } from '@totem/utilities/endpoints';
import { getUserRole } from '@totem/utilities/userUtilities';

const styles = {
  totals: {
    fontSize: '16px',
    marginRight: '20px',
  },
  tabContainer: {
    paddingRight: '2rem',
    paddingLeft: '2rem',
  },
};

const CrossOrgDevicesPage = () => {
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const { userProfile } = useContext(UserProfileContext);
  const isSysAdmin = getUserRole(userProfile) === 3;

  const tabItems: TabsProps['items'] = [
    {
      key: 'details',
      label: <TabTitle>Details</TabTitle>,
      children: <DetailsTabLayout />,
    },
  ];

  return (
    <ContentLayout>
      <BasePane>
        <PrimaryContentPane>
          <DeviceContainer
            deviceEndPoint={DEVICES_CROSS_ORG_PAGINATION_ENDPOINT}
            deviceFiltersEndPoint={`${DEVICES_CROSS_ORG_PAGINATION_ENDPOINT}/filters`}
            onRecordTotalChanged={setTotalRecords}
          >
            <DeviceFilters />
            <Tabs
              defaultActiveKey="Report"
              tabBarExtraContent={
                <span>
                  <b style={styles.totals}>{totalRecords} Devices</b>
                  {isSysAdmin && (
                    <DeviceExportWebSockets
                      deviceExportEndPoint={
                        DEVICES_CROSS_ORG_PAGINATION_ENDPOINT
                      }
                    />
                  )}
                </span>
              }
              style={styles.tabContainer}
              items={tabItems}
            />
          </DeviceContainer>
        </PrimaryContentPane>
      </BasePane>
    </ContentLayout>
  );
};

export default CrossOrgDevicesPage;
