import React, { useContext, useState } from 'react';

import DetailSpinner from '@totem/components/common/DetailSpinner';
import EvaluationAreas from '@totem/components/surveyV2/instanceDetail/score/EvaluationAreas';
import ReportComponent from '@totem/components/surveyV2/instanceDetail/score/ReportComponent';
import SurveyContext from '@totem/components/surveyV2/instanceDetail/SurveyInstanceContext';

import './score.css';

const ScoringSection = () => {
  const { data, loading } = useContext(SurveyContext);
  const [selectedId, setSelectedId] = useState<string>('');
  const [selectedType, setSelectedType] = useState<string>('');

  const onRowSelect = (
    newSelectedId: string,
    newSelectedType: string,
  ): void => {
    setSelectedId(newSelectedId);
    setSelectedType(newSelectedType);
  };

  const getSelectedItem = (): Object => {
    const { survey } = data;
    if (!selectedId) {
      return {};
    }

    if (selectedType === 'summary') {
      return {
        label: 'Summary',
        totalScore: survey.totalScore,
        maxScore: survey.maxScore,
        children: survey.categories.map((category: any): any => ({
          label: category.name,
          totalScore: category.totalScore,
          maxScore: category.maxScore,
          children: category.multiInstance
            ? category.multiInstanceScores.map(
                (categoryInstanceScore: any): any => ({
                  label: categoryInstanceScore.displayName,
                  totalScore: categoryInstanceScore.totalScore,
                  maxScore: categoryInstanceScore.maxScore,
                }),
              )
            : [],
        })),
      };
    }

    if (selectedType === 'category') {
      const category = survey.categories.find(
        (cat: any): boolean => cat.id === selectedId,
      );

      if (!category) {
        return {};
      }

      return {
        label: category.name,
        totalScore: category.totalScore,
        maxScore: category.maxScore,
        children: category.multiInstance
          ? category.multiInstanceScores.map(
              (categoryInstanceScore: any): any => ({
                label: categoryInstanceScore.displayName,
                totalScore: categoryInstanceScore.totalScore,
                maxScore: categoryInstanceScore.maxScore,
              }),
            )
          : [],
      };
    }

    if (selectedType === 'category-instance') {
      const categoryInstance = survey.categories.reduce(
        (acc: any, category: any): Object => {
          if (!category.multiInstance) {
            return acc;
          }

          const matchingInstance = category.multiInstanceScores.find(
            (instance: any): boolean =>
              `${instance.instance}-${instance.displayName}` === selectedId,
          );

          return matchingInstance ? matchingInstance : acc;
        },
        {},
      );

      if (!categoryInstance) {
        return {};
      }

      return {
        label: categoryInstance.displayName,
        totalScore: categoryInstance.totalScore,
        maxScore: categoryInstance.maxScore,
        children: [],
      };
    }

    if (selectedType === 'level-type') {
      const levelType = survey.levelTypes.find(
        (type: any): boolean =>
          `${type.typeName}-${type.displayName}` === selectedId,
      );

      if (!levelType) {
        return {};
      }

      const levels = survey.levels.filter(
        (level: any): boolean => level.type === levelType.type,
      );

      return {
        label: levelType.displayName,
        totalScore: levelType.totalScore,
        maxScore: levelType.maxScore,
        children: levels.map((level: any): any => ({
          label: level.name,
          totalScore: level.totalScore,
          maxScore: level.maxScore,
        })),
      };
    }

    if (selectedType === 'level') {
      const level = survey.levels.find(
        (lvl: any): boolean => lvl.id === selectedId,
      );

      if (!level) {
        return {};
      }

      return {
        label: level.name,
        totalScore: level.totalScore,
        maxScore: level.maxScore,
        children: [],
      };
    }

    return {};
  };

  return (
    <div styleName="results-content-pane">
      {(typeof data === 'undefined' || data === null || loading) && (
        <div styleName="spinner-container">
          <DetailSpinner />
        </div>
      )}
      {typeof data !== 'undefined' && data !== null && !loading && (
        <>
          <EvaluationAreas selectedId={selectedId} onRowSelect={onRowSelect} />
          <ReportComponent
            // @ts-ignore
            selectedItem={getSelectedItem()}
            selectedId={selectedId}
            selectedType={selectedType}
          />
        </>
      )}
    </div>
  );
};

export default ScoringSection;
