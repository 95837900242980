import { createContext } from 'react';

import {
  VulnerabilitySummary,
  VulnerabilitySummaryInput,
} from '@totem/components/software/vulnerabilitySummary/types';

interface Context {
  input: VulnerabilitySummaryInput;
  setInput: (input: VulnerabilitySummaryInput) => void;
  loading: boolean;
  data: VulnerabilitySummary;
  action: string;
  onAction?: (action: string) => void;
}

export default createContext<Context>({
  input: {},
  setInput: () => {},
  loading: false,
  data: null,
  action: '',
  onAction: () => {},
});
