import React, { ReactNode, useCallback, useEffect, useState } from 'react';

import CreateOrganizationModal from '@totem/components/internalOnly/netSuitePending/CreateOrganizationModal';
import PendingContext from '@totem/components/internalOnly/netSuitePending/PendingContext';
import { PendingImportRecord } from '@totem/components/internalOnly/netSuitePending/types';
import { getToken } from '@totem/utilities/accountUtilities';
import { NETSUITE_PENDING_ENDPOINT } from '@totem/utilities/endpoints';
import { CheckResponseShowError } from '@totem/utilities/responseUtilities';

type Props = {
  children?: ReactNode;
};

const PendingContainer = ({ children }: Props) => {
  const [refreshData, setRefreshData] = useState<boolean>(true);
  const [isSending, setIsSending] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [data, setData] = useState<PendingImportRecord[]>([]);
  const [selectedAction, setSelectedAction] = useState<string>('');
  const [selectedData, setSelectedData] = useState<PendingImportRecord>(null);

  useEffect(() => {
    if (refreshData) {
      setRefreshData(false);
      if (isSending) {
        return;
      }
      const endpoint = `${NETSUITE_PENDING_ENDPOINT}`;

      fetch(`${endpoint}`, {
        method: 'GET',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
      })
        .then((res) => res.json())
        .then((result: PendingImportRecord[]) => {
          setData(result);
        })
        .then(() => {
          setIsSending(false);
          setIsLoading(false);
        });

      setIsSending(true);
    }
  }, [refreshData]);

  const removeRecord = useCallback(
    async (id: string) => {
      if (isSending) {
        return;
      }

      setIsSending(true);

      fetch(`${NETSUITE_PENDING_ENDPOINT}/${id}`, {
        method: 'DELETE',
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getToken()}`,
        }),
      }).then((res) => {
        setRefreshData(true);
        CheckResponseShowError(res);
      });
    },
    [isSending],
  );

  const reprocessRecord = useCallback(
    async (id: string) => {
      if (isSending) {
        return;
      }

      setIsSending(true);

      fetch(`${NETSUITE_PENDING_ENDPOINT}`, {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getToken()}`,
        }),
        body: JSON.stringify({ salesOrderID: id }),
      }).then((res) => {
        setRefreshData(true);
        CheckResponseShowError(res);
      });
    },
    [isSending],
  );

  const handleAction = (action: string, record: PendingImportRecord) => {
    switch (action) {
      case 'remove_pending_import':
        removeRecord(record.id);
        break;
      case 'remove':
        removeRecord(record.id);
        break;
      case 'create_organization':
        setSelectedAction(action);
        setSelectedData(record);
        break;
      case 'reprocess_record':
        reprocessRecord(record.salesOrder.id);
        break;
      case 'process_organization':
        {
          const customerID = record.customer.id;
          const matchingRecords = data.filter(
            (chk) => chk.customer.id === customerID,
          );
          for (let idx = 0; idx < matchingRecords.length; idx++) {
            const salesOrderID = matchingRecords[idx].salesOrder.id;
            console.log(`Sales Order ID: ${salesOrderID}`);
            reprocessRecord(salesOrderID);
          }
          setSelectedAction('');
          setSelectedData(null);
        }
        break;
      default:
        setSelectedAction(action);
        setSelectedData(record);
        break;
    }
  };

  const handleActionComplete = (doRefresh: boolean) => {
    setSelectedAction('');
    setSelectedData(null);
    if (doRefresh) {
      setRefreshData(true);
    }
  };

  return (
    <PendingContext.Provider
      value={{
        data,
        selectedRecord: selectedData,
        action: selectedAction,
        loading: isLoading,
        onAction: handleAction,
        onActionComplete: handleActionComplete,
      }}
    >
      <div>{children}</div>
      {selectedAction === 'create_organization' && selectedData !== null && (
        <CreateOrganizationModal />
      )}
    </PendingContext.Provider>
  );
};

export default PendingContainer;
