import React, { useCallback, useContext, useState } from 'react';
import { DatePicker, Modal } from 'antd';

import SurveyInstanceContext from '@totem/components/surveyV2/instances/SurveyInstancesContext';
import { getToken } from '@totem/utilities/accountUtilities';
import { ParseDate } from '@totem/utilities/dateUtilities';
import { V2_SURVEY_ENDPOINT } from '@totem/utilities/endpoints';
import { CheckResponseShowError } from '@totem/utilities/responseUtilities';

import '../survey.css';

const styles = {
  picker: {
    width: '22rem',
  },
};

const DateDialog = () => {
  const { loading, action, onAction, selectedInstance, setIsLoading } =
    useContext(SurveyInstanceContext);
  const { startDate, dueDate } = selectedInstance.survey;
  const [date, setDate] = useState<number>(
    action === 'show_start_date_dialog' ? startDate : dueDate,
  );

  const typeString =
    action === 'show_start_date_dialog'
      ? 'Start'
      : action === 'show_due_date_dialog'
      ? 'Due'
      : '';

  const sendDateUpdate = useCallback(
    async (surveyInstanceID: string, dateField: string, dateValue: number) => {
      const payload = {
        dateField,
        dateValue,
      };

      fetch(`${V2_SURVEY_ENDPOINT}/instance/${surveyInstanceID}/date`, {
        method: 'POST',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
        body: JSON.stringify(payload),
      })
        .then((res) => {
          CheckResponseShowError(res);
        })
        .then(() => {
          onAction('refresh', null);
          setIsLoading(false);
        });
    },
    [],
  );

  const handleSubmit = () => {
    switch (action) {
      case 'show_start_date_dialog':
        sendDateUpdate(selectedInstance.survey.id, 'startDate', date);
        break;
      case 'show_due_date_dialog':
        sendDateUpdate(selectedInstance.survey.id, 'dueDate', date);
        break;
      default:
        break;
    }
  };

  const currentDate = date ? new Date(date).toLocaleDateString() : '';

  return (
    <Modal
      title={`Set New ${typeString} Date: ${name}`}
      open={
        (action === 'show_start_date_dialog' ||
          action === 'show_due_date_dialog') &&
        selectedInstance !== null
      }
      onOk={handleSubmit}
      onCancel={() => onAction('', null)}
      confirmLoading={loading}
      okText="Save"
      okButtonProps={{
        disabled: !date,
      }}
    >
      <div>
        {currentDate && (
          <React.Fragment>
            <div styleName="current-date-label">{`Current ${typeString} Date`}</div>
            <div styleName="current-date">{currentDate}</div>
          </React.Fragment>
        )}
        <div styleName="date-field-label">
          {` Set a new ${typeString.toLowerCase()} date`}
        </div>
        <DatePicker
          // @ts-ignore
          value={ParseDate(date)}
          onChange={(value) => {
            const newDate = value ? value.valueOf() : 0;
            setDate(newDate);
          }}
          style={styles.picker}
          placeholder="Select Date"
        />
      </div>
    </Modal>
  );
};

export default DateDialog;
