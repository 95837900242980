import React, { useContext, useState } from 'react';
import { EllipsisOutlined } from '@ant-design/icons';
import { Badge, Button, Card } from 'antd';

import CardHeader from '@totem/components/CardHeader';
import CardTitle from '@totem/components/CardTitle';
import CardBody from '@totem/components/surveyV2/instanceDetail/CardBody';
import SurveyContext from '@totem/components/surveyV2/instanceDetail/SurveyInstanceContext';
import colors from '@totem/styles/colors';
import fonts from '@totem/styles/fonts';
import {
  getBadgeStatus,
  getHealthStatusString,
} from '@totem/utilities/healthUtilities';

import '../survey.css';

const styles = {
  icon: {
    cursor: 'pointer',
    color: colors.opacity.black0_4,
    fontSize: fonts.fontLg,
  },
};

const HealthCard = () => {
  const [showHealthModal, setShowHealthModal] = useState<boolean>(false);
  const [showHealthHistoryModal, setShowHealthHistoryModal] =
    useState<boolean>(false);

  const { data } = useContext(SurveyContext);
  const { health } = data;
  return (
    <>
      <Card
        title={
          <CardHeader>
            <CardTitle>Health</CardTitle>
          </CardHeader>
        }
        bordered={false}
      >
        <CardBody>
          <div styleName="current-health">
            <div styleName="current-health-status">
              <Badge
                status={getBadgeStatus(health)}
                className="health-status-badge-sm"
              />
              <div styleName="health-status-option">
                {getHealthStatusString(health)}
              </div>
            </div>
            <EllipsisOutlined
              style={styles.icon}
              onClick={() => setShowHealthModal(!showHealthModal)}
            />
          </div>
          <div styleName="current-health-comment">
            {health ? health.comment : ''}
          </div>
          <div styleName="health-history-button">
            <Button
              onClick={() => setShowHealthHistoryModal(!showHealthHistoryModal)}
            >
              View all health history
            </Button>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default HealthCard;
