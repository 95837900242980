import React, { useContext } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Layout } from 'antd';
const { Header, Content } = Layout;
import ProfileIcon from '@totem/components/common/profileIcon/ProfileIcon';
import NotFound from '@totem/components/notFound/NotFound';
import { RouteContextProvider } from '@totem/components/RouteContext';
import ProfileUpdateModal from '@totem/components/settings/sections/profile/ProfileUpdateModal';
import TenantLeftNav from '@totem/components/TenantLeftNav';
import ReportsContainer from '@totem/components/ui_components/reports/ReportsContainer';
import UserProfileContext from '@totem/components/UserProfileContext';
import colors from '@totem/styles/colors';
import { getTenantRoutes } from '@totem/utilities/routes';

const styles = {
  content: {
    backgroundColor: colors.neutral.white,
    display: 'flex',
    flexDirection: 'column' as any,
  },
  header: {
    background: colors.neutral.white,
    padding: 0,
    display: 'flex',
    flexDirection: 'row' as any,
    justifyContent: 'flex-end',
    alignItems: 'center',
    borderBottom: `2px solid ${colors.neutral.typhoon}`,
  },
};

const TenantBase = () => {
  const { userProfile } = useContext(UserProfileContext);

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <ReportsContainer>
        <TenantLeftNav />
      </ReportsContainer>
      <Layout>
        <Header style={styles.header}>
          <ProfileIcon />
        </Header>
        <Content style={styles.content}>
          <Routes>
            {getTenantRoutes(userProfile).map((route) => {
              const enabled =
                typeof route.render === 'function'
                  ? route.render()
                  : route.render ?? true;

              return (
                enabled && (
                  <Route
                    key={route.path}
                    path={route.path}
                    element={
                      <RouteContextProvider value={route}>
                        <route.component />
                      </RouteContextProvider>
                    }
                  />
                )
              );
            })}
            <Route element={<NotFound />} />
          </Routes>
          <ProfileUpdateModal />
        </Content>
      </Layout>
    </Layout>
  );
};

export default TenantBase;
