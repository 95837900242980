import React, { useContext, useEffect, useState } from 'react';
import {
  EnvironmentOutlined,
  IdcardOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Card, Col, Flex, Row, Space } from 'antd';

import Count from '@totem/components/common/Count';
import FindingsContext from '@totem/components/surveyV2/findings/FindingsContext';
import SubTitle from '@totem/components/surveyV2/SubTitle';
import colors from '@totem/styles/colors';
import { isNotNull } from '@totem/utilities/common';

import '../survey.css';

const styles = {
  headerIcon: {
    fontSize: '22px',
  },
  leastCompliantCount: {
    background: colors.neutral.ash,
  },
  fullWidth: {
    width: '100%',
  },
  ninetyPercent: {
    width: '90%',
  },
};

type GroupAggregator = {
  id: string;
  label: string;
  count: number;
};

const LeastCompliantByCategory = () => {
  const { filteredData } = useContext(FindingsContext);
  const [topAssignees, setTopAssignees] = useState<GroupAggregator[]>([]);
  const [topBuilding, setTopBuilding] = useState<GroupAggregator[]>([]);
  const [topRegion, setTopRegion] = useState<GroupAggregator[]>([]);

  useEffect(() => {
    const assignees: GroupAggregator[] = [];
    const buildings: GroupAggregator[] = [];
    const regions: GroupAggregator[] = [];

    if (isNotNull(filteredData)) {
      for (let idx = 0; idx < filteredData.length; idx++) {
        const chk = filteredData[idx];
        if (chk.compliant === false) {
          // Assignees
          const assigneeFoundIdx = assignees.findIndex(
            (rec) => rec.label === chk.assignedUser.email,
          );
          // eslint-disable-next-line max-depth
          if (assigneeFoundIdx === -1) {
            assignees.push({
              id: chk.assignedUser.id,
              label: chk.assignedUser.email,
              count: 1,
            });
          } else {
            assignees[assigneeFoundIdx].count =
              assignees[assigneeFoundIdx].count + 1;
          }

          // Buildings
          const buildingFoundIdx = buildings.findIndex(
            (rec) => rec.id === chk.building.id,
          );
          // eslint-disable-next-line max-depth
          if (buildingFoundIdx === -1) {
            buildings.push({
              id: chk.building.id,
              label: chk.building.name,
              count: 1,
            });
          } else {
            buildings[buildingFoundIdx].count =
              buildings[buildingFoundIdx].count + 1;
          }

          // Regions
          const regionFoundIdx = regions.findIndex(
            (rec) => rec.id === chk.region.id,
          );
          // eslint-disable-next-line max-depth
          if (regionFoundIdx === -1) {
            regions.push({
              id: chk.region.id,
              label: chk.region.name,
              count: 1,
            });
          } else {
            regions[regionFoundIdx].count = regions[regionFoundIdx].count + 1;
          }
        }
      }
    }

    const sortedAssignees = assignees.sort((compA, compB) =>
      compA.count > compB.count ? -1 : 1,
    );
    setTopAssignees(sortedAssignees.filter((chk) => chk.count > 0).slice(0, 5));

    const sortedBuildings = buildings.sort((compA, compB) =>
      compA.count > compB.count ? -1 : 1,
    );
    setTopBuilding(sortedBuildings.filter((chk) => chk.count > 0).slice(0, 5));

    const sortedRegions = regions.sort((compA, compB) =>
      compA.count > compB.count ? -1 : 1,
    );
    setTopRegion(sortedRegions.filter((chk) => chk.count > 0).slice(0, 5));
  }, [filteredData]);

  const renderLeastCompliantSection = (
    counts: GroupAggregator[],
    title: string,
    icon: React.ReactNode,
  ) => {
    return (
      <Space direction={'vertical'} style={styles.fullWidth}>
        <Flex justify={'flex-start'} align={'flex-start'}>
          {icon}
          <div styleName="least-compliant-header">{title}</div>
        </Flex>
        <div style={styles.fullWidth}>
          {counts
            .filter((entity) => Boolean(entity.count))
            .map((entity, index) => {
              const name = entity?.label || '--';

              return (
                <Flex
                  justify={'space-between'}
                  align={'flex-start'}
                  key={index}
                  style={styles.fullWidth}
                >
                  <div
                    style={styles.ninetyPercent}
                    styleName="least-compliant-entity"
                  >
                    {name}
                  </div>
                  <Count
                    size="small"
                    count={entity.count}
                    style={styles.leastCompliantCount}
                  />
                </Flex>
              );
            })}
        </div>
      </Space>
    );
  };

  // @ts-ignore
  return (
    <Card title={<SubTitle>Least Compliant by Category</SubTitle>}>
      <Row gutter={32}>
        <Col span={8}>
          <div styleName={'rightBorderFullWidth'}>
            {renderLeastCompliantSection(
              topAssignees,
              'Assignee',
              <UserOutlined style={styles.headerIcon} />,
            )}
          </div>
        </Col>
        <Col span={8}>
          <div styleName={'rightBorderFullWidth'}>
            {renderLeastCompliantSection(
              topBuilding,
              'Building',
              <EnvironmentOutlined style={styles.headerIcon} />,
            )}
          </div>
        </Col>
        <Col span={8}>
          {renderLeastCompliantSection(
            topRegion,
            'Region',
            <IdcardOutlined style={styles.headerIcon} />,
          )}
        </Col>
      </Row>
    </Card>
  );
};

export default LeastCompliantByCategory;
