import React from 'react';
import { Flex } from 'antd';

import {
  getCvssColorFromScore,
  getCvssRatingFromScore,
} from '@totem/components/software/cves/utilities';

import './cve.css';

type Props = {
  title: string;
  score: number;
  maxScore: number;
};

const CvssScore = ({ title, score, maxScore }: Props) => {
  const containerStyle = {
    backgroundColor: getCvssColorFromScore(score),
  };

  return (
    <div style={containerStyle} styleName="scoreContainer">
      <Flex justify={'flex-start'} align={'flex-start'} vertical>
        <Flex justify={'flex-start'} align={'flex-start'}>
          <div styleName="score">{score}</div>
          <div styleName="maxScore">/{maxScore}</div>
        </Flex>
        <div styleName="scoreLabel">
          {title}: {getCvssRatingFromScore(score).toUpperCase()}
        </div>
      </Flex>
    </div>
  );
};

export default CvssScore;
