import React, { useContext, useEffect, useState } from 'react';

import DropdownFilter from '@totem/components/common/dropdownFilter/DropdownFilter';
import OrganizationContext from '@totem/components/common/organizationContext/organizationContext';
import FiltersChipsContainer from '@totem/components/FilterChipsContainer';
import FiltersBody from '@totem/components/FiltersBody';
import FiltersContainer from '@totem/components/FiltersContainer';
import FiltersTitle from '@totem/components/FiltersTitle';
import FindingsContext from '@totem/components/surveyV2/findings/FindingsContext';
import FindingsFilterChips from '@totem/components/surveyV2/findings/FindingsFilterChips';
import { FindingsInput } from '@totem/components/surveyV2/findings/types';
import { StringDropDownFilterOption } from '@totem/types/common';
import { Criticality } from '@totem/types/criticality';
import { isNotNull } from '@totem/utilities/common';
import { toDropdownFilter } from '@totem/utilities/enumUtilities';
import { sortStringAscending } from '@totem/utilities/tableUtilities';

const styles = {
  dropdown: {
    marginRight: '2rem',
  },
};

const FindingsFilter = () => {
  const { data, input, setInput } = useContext(FindingsContext);
  const { regions, buildings } = useContext(OrganizationContext);
  const [assigneeFilterOptions, setAssigneeFilterOptions] = useState<
    StringDropDownFilterOption[]
  >([]);

  const getRegionFilterOptions = () => {
    if (typeof regions !== 'undefined' && regions !== null) {
      return regions.map((region) => {
        return {
          label: region.name,
          value: region.id,
        };
      });
    }
    return [];
  };

  const getBuildingFilterOptions = () => {
    if (typeof buildings !== 'undefined' && buildings !== null) {
      return buildings.map((building) => {
        return {
          label: building.name,
          value: building.id,
        };
      });
    }
    return [];
  };

  useEffect(() => {
    if (isNotNull(data)) {
      const optionsAssignee = [];
      for (let idx = 0; idx < data.length; idx++) {
        if (
          optionsAssignee.findIndex(
            (chk) => chk.text === data[idx].assignedUser.email,
          ) === -1
        ) {
          optionsAssignee.push({
            label: data[idx].assignedUser.email,
            value: data[idx].assignedUser.email,
          });
        }
      }
      setAssigneeFilterOptions(
        optionsAssignee.sort((compA, compB) =>
          sortStringAscending(compA.text, compB.text),
        ),
      );
    }
  }, [data]);

  const handleMultiStringFilterChange = (
    property: keyof FindingsInput,
    update: string[],
  ) => {
    setInput({
      ...input,
      [property]: update.map(String),
    });
  };

  const handleMultiNumericFilterChange = (
    property: keyof FindingsInput,
    update: string[],
  ) => {
    setInput({
      ...input,
      [property]: update.map(Number),
    });
  };

  return (
    <FiltersContainer>
      <FiltersTitle>{'Findings Report'}</FiltersTitle>
      <FiltersBody>
        <DropdownFilter
          label="Criticality"
          options={toDropdownFilter(Criticality)}
          value={input.criticality || []}
          onChange={(update) =>
            handleMultiNumericFilterChange('criticality', update)
          }
          style={styles.dropdown}
        />
        <DropdownFilter
          label="Building"
          options={getBuildingFilterOptions()}
          value={input.building || []}
          onChange={(update) =>
            handleMultiStringFilterChange('building', update)
          }
          style={styles.dropdown}
        />
        <DropdownFilter
          label="Region"
          options={getRegionFilterOptions()}
          value={input.region || []}
          onChange={(update) => handleMultiStringFilterChange('region', update)}
          style={styles.dropdown}
        />
        <DropdownFilter
          label="Assignee"
          options={assigneeFilterOptions}
          value={input.assignee || []}
          onChange={(update) =>
            handleMultiStringFilterChange('assignee', update)
          }
          style={styles.dropdown}
        />
      </FiltersBody>
      <FiltersChipsContainer>
        <FindingsFilterChips />
      </FiltersChipsContainer>
    </FiltersContainer>
  );
};

export default FindingsFilter;
