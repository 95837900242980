import { useCallback, useEffect, useState } from 'react';
import { gql } from '@apollo/client';
import { debounce } from '@totem/utilities/debounce';
import * as R from 'ramda';

import client from '@totem/graph/client';
import {
  AutoCompleteApplied,
  AutoCompleteFilter,
  AutoCompleteFilterOptions,
  AutoCompleteOptions,
  AutoCompleteSelected,
} from '@totem/types/hooks';

const GET_BUILDINGS = gql`
  query getBuildings($input: BuildingsConnectionInput!) {
    buildings(input: $input) {
      buildings {
        id
        name
      }
    }
  }
`;

export const useBuildingFilter = (
  initialSelected: string[] = [],
  filterOptions?: AutoCompleteFilterOptions,
): AutoCompleteFilter => {
  const LIMIT = filterOptions?.limit || 5;
  const DEBOUNCE_TIME = filterOptions?.debounce || 500;

  const [search, onSearch] = useState<string>('');
  const [loadingSearch, setLoadingSearch] = useState<boolean>(false);
  const [selected, setSelected] = useState<AutoCompleteSelected>({});
  const [applied, setApplied] = useState<AutoCompleteApplied>({});
  const [options, setOptions] = useState<AutoCompleteOptions>({});
  const [loading, setLoading] = useState<boolean>(false);

  const onSelect = (id: string, name: string) => {
    if (R.has(id, selected)) {
      setSelected(R.dissoc(id, selected));
    } else {
      setSelected({ ...(selected as AutoCompleteSelected), [id]: name });
    }
  };

  const onApply = () => {
    setApplied(selected);
  };

  const onReset = () => {
    setOptions({});
    setSelected({});
    setApplied({});
    onSearch('');
  };

  const onRemove = (id: string) => {
    const update = R.dissoc(id, selected);
    setSelected(update);
    setApplied(update);
  };

  const searchBuildings = async (
    name: string,
    currentlySelected: AutoCompleteSelected,
  ) => {
    if (!name) {
      setOptions({});
      setLoadingSearch(false);
      return;
    }

    const { data } = await client.query({
      query: GET_BUILDINGS,
      variables: {
        input: {
          name,
          limit: LIMIT + Object.keys(currentlySelected).length,
        },
      },
      fetchPolicy: 'no-cache',
    });

    const buildings = data?.buildings?.buildings || [];

    setOptions(
      buildings
        .filter(building => !R.has(building.id, currentlySelected))
        .slice(0, LIMIT)
        .reduce(
          (acc, building) => ({ ...acc, [building.id]: building.name }),
          {},
        ),
    );

    setLoadingSearch(false);
  };

  const handleSearch = useCallback(
    debounce(searchBuildings, DEBOUNCE_TIME),
    [],
  );

  useEffect(() => {
    setLoadingSearch(true);
    handleSearch(search, selected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    const getBuildings = async () => {
      setLoading(true);

      const { data } = await client.query({
        query: GET_BUILDINGS,
        variables: {
          input: { id: initialSelected },
        },
      });

      const buildings = (data?.buildings?.buildings || []).reduce(
        (acc, building) => ({ ...acc, [building.id]: building.name }),
        {},
      );

      setSelected(buildings);
      setApplied(buildings);
      setLoading(false);
    };

    if (initialSelected.length) {
      getBuildings();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (filterOptions?.onChange) {
      // @ts-ignore
      filterOptions.onChange(R.keys(applied));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applied]);

  return {
    search,
    onSearch,
    loadingSearch,
    options,
    selected,
    onSelect,
    applied,
    onRemove,
    onApply,
    onReset,
    loading,
  };
};
