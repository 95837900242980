import React from 'react';
import ReactApexChart from 'react-apexcharts';
import dayjs from 'dayjs';

import { GraphMultiIntSeries } from '@totem/components/events/graphs/types';

import '../events.css';
import {defaultChartColors} from "@totem/utilities/charts";

type Props = {
  series: GraphMultiIntSeries;
  chartOptions?: ApexCharts.ApexOptions;
  startTime: number;
  endTime: number;
};

const StackedTimeBarChart = ({
  series,
  chartOptions,
  startTime,
  endTime,
}: Props) => {
  const getSeries = () => {
    return series.series.map((ser) => {
      return { name: ser.name, data: ser.y };
    });
  };

  const getCategories = () => {
    return series.x.map((strDate) => dayjs(strDate).valueOf());
  };

  const options: ApexCharts.ApexOptions = {
    chart: {
      type: 'bar',
      stacked: true,
      toolbar: {
        show: true,
        tools: {
          download: true,
          selection: false,
          pan: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
        },
      },
      zoom: { enabled: true },
      selection: { enabled: true },
    },
    legend: { show: true, showForSingleSeries: true, showForZeroSeries: true },
    series: getSeries(),
    colors: defaultChartColors,
    // colors: [
    //   '#B0E0E6',
    //   '#ADD8E6',
    //   '#87CEFA',
    //   '#87CEEB',
    //   '#00BFFF',
    //   '#B0C4DE',
    //   '#1E90FF',
    //   '#6495ED',
    //   '#5F9EA0',
    //   '#4169E1',
    // ],
    plotOptions: {
      bar: {
        columnWidth: series.x.length < 2 ? '5%' : '90%',
      },
    },
    xaxis: {
      type: 'datetime',
      min: startTime,
      max: endTime,
      categories: getCategories(),
      stepSize: 3600000,
      axisTicks: {
        show: true,
      },
    },
    yaxis: [
      {
        axisTicks: {
          show: true,
        },
        tooltip: {
          enabled: true,
        },
      },
    ],
    tooltip: {
      fixed: {
        enabled: true,
        position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
        offsetY: 30,
        offsetX: 60,
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
  };

  const getChartOptions = () => {
    if (typeof chartOptions !== 'undefined' && chartOptions !== null) {
      return { ...options, ...chartOptions };
    }
    return options;
  };

  return (
    <div className="app">
      <div className="row">
        <div className="mixed-chart">
          <ReactApexChart
            options={getChartOptions()}
            series={getSeries()}
            type="bar"
            width="100%"
            height="400px"
          />
        </div>
      </div>
    </div>
  );
};

export default StackedTimeBarChart;
