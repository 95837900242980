import React, { useContext } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';

import AssetInventoryContext from '@totem/components/assetInventory/statistics/AssetInventoryContext';
import { EMPTY_ID } from '@totem/components/common/reference/ReferenceSelector';
import colors from '@totem/styles/colors';
import { DevicesConnectionInput } from '@totem/types/devices';
import { isNotNull } from '@totem/utilities/common';

type Props = {
  chartOptions?: ApexOptions;
  onClick?: (updated: Partial<DevicesConnectionInput>) => void;
};

const StatisticsChart = ({ chartOptions, onClick }: Props) => {
  const { statistics } = useContext(AssetInventoryContext);

  const handleClick = (event: any, chart?: any, options?: any) => {
    const dataPointIndex = options.dataPointIndex;

    switch (dataPointIndex) {
      case 0: // No Backup
        onClick({ noBackup: ['True'], isManaged: ['ACTIVE'] });
        break;
      case 1: // Stale Backup
        onClick({ staleBackups: ['True'], isManaged: ['ACTIVE'] });
        break;
      case 2: // No Anti-Virus
        onClick({ noAntiVirus: ['True'], isManaged: ['ACTIVE'] });
        break;
      case 3: // No Building Assignment
        onClick({ buildingId: [EMPTY_ID], isManaged: ['ACTIVE'] });
        break;
      case 4: // Incomplete Device Type
        onClick({
          type: ['Device', 'Other'],
          isManaged: ['ACTIVE'],
        });
        break;
      case 5: // OS Past End of Support
        onClick({
          operatingSystemPastEndOfSupport: ['True'],
          isManaged: ['ACTIVE'],
        });
        break;
      case 6: // OS Near End of Support
        onClick({
          operatingSystemApproachingEndOfSupport: ['True'],
          isManaged: ['ACTIVE'],
        });
        break;
      case 7: // Low Disk Space
        onClick({
          lowDiskSpace: ['True'],
          isManaged: ['ACTIVE'],
        });
        break;
      default:
        break;
    }

    event.stopPropagation();
    try {
      event.nativeEvent.stopImmediatePropagation();
    } catch (ex) {
      /* empty */
    }
  };

  const options: ApexOptions = {
    chart: {
      type: 'bar',
      toolbar: { show: true, tools: { download: true } },
      events: {
        dataPointSelection: handleClick,
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        borderRadiusApplication: 'end',
        horizontal: true,
      },
    },
    tooltip: {
      enabled: false,
    },
    xaxis: {
      categories: [
        'No Backup',
        'Stale Backups',
        'No Anti-Virus',
        'No Building Assignment',
        'Incomplete Device Type',
        'OS Past End of Support',
        'OS Near End of Support',
        'Low Disk Space',
      ],
    },
    dataLabels: {
      enabled: true,
    },
    colors: [colors.utility.error],
    legend: {
      width: 200,
    },
  };

  const getChartOptions = () => {
    if (isNotNull(statistics)) {
      return { ...options, ...chartOptions };
    }
    return options;
  };

  const getSeriesData = () => {
    if (isNotNull(statistics)) {
      const data = [];

      data.push(statistics.backups.noBackup);
      data.push(statistics.backups.staleBackups);
      data.push(statistics.virusProtection.notInstalled);
      data.push(statistics.buildingAssignment.notAssigned);
      data.push(statistics.deviceType.notFound);
      data.push(statistics.operatingSystemEndOfSupport.pastEndOfSupport);
      data.push(statistics.operatingSystemEndOfSupport.approachingEndOfSupport);
      data.push(statistics.volumesWithLowFreeSpace);

      return [{ data }];
    }

    return [];
  };

  return (
    <div className="app">
      <div className="row">
        <div className="mixed-chart">
          {isNotNull(statistics) && (
            <>
              <ReactApexChart
                options={getChartOptions()}
                series={getSeriesData()}
                type="bar"
                width="100%"
                height="300px"
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default StatisticsChart;
