import React, { ReactNode } from 'react';

import './controlSystemReport.css';

type Props = {
  children?: ReactNode;
};

const TableTitle = ({ children }: Props) => {
  return <div styleName="tableTitle">{children}</div>;
};

export default TableTitle;
