import React, { ReactNode } from 'react';

import './base.css';

type Props = {
  children?: ReactNode;
};

const PageSectionFilled = ({ children }: Props) => {
  return <div styleName="page-section-filled">{children}</div>;
};

export default PageSectionFilled;
