import React from 'react';
import { connect } from 'react-redux';
import { UploadOutlined } from '@ant-design/icons';
import { Button, Upload } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import { extname } from 'path';
import { bindActionCreators } from 'redux';

import * as fileActionCreators from '@totem/actions/fileActions';
import {
  getPolicyAuditMediaSelector,
  policyAuditIdsSelector,
  policyAuditSelector,
} from '@totem/selectors/policyAuditSelectors';
import { FileActions } from '@totem/types/actions';
import {
  PolicyAudit,
  PolicyAuditIds,
  Question,
  QuestionKey,
} from '@totem/types/policyAudit';
import { MediaItem, ReduxStore } from '@totem/types/store';
import accountUtilities from '@totem/utilities/accountUtilities';
import { FILES_ENDPOINT } from '@totem/utilities/endpoints';
import { POLICY_AUDIT_SUBJECT_TYPE } from '@totem/utilities/mediaUtilities';

interface PassedProps {
  onChange: (key: QuestionKey, value: any) => void;
  question: Question;
  disabled: boolean;
}

interface ReduxDispatchProps {
  fileActions: FileActions;
}

interface ReduxStateProps {
  policyAudit: PolicyAudit;
  ids: PolicyAuditIds;
  media: MediaItem[];
}

type MapStateToProps = (
  state: ReduxStore,
  props: PassedProps,
) => ReduxStateProps;

type Props = PassedProps & ReduxDispatchProps & ReduxStateProps;

class UploadQuestion extends React.Component<Props> {
  componentDidUpdate(prevProps: Props) {
    const { media, onChange, question } = this.props;
    const { key } = question;

    if (prevProps.media.length !== media.length) {
      onChange(
        key,
        media.map(({ originalFileName }) => originalFileName),
      );
    }
  }

  handleUpload = (file: File): boolean => {
    const { ids, question, fileActions } = this.props;

    const {
      id: policyAuditId,
      organizationId,
      buildingId,
      controlSystemId,
    } = ids;

    const { key: subjectId } = question;
    const subjectType = POLICY_AUDIT_SUBJECT_TYPE;

    fileActions.requestUploadFiles({
      file,
      organizationId,
      buildingId,
      controlSystemId,
      policyAuditId,
      subjectType,
      subjectId,
    });

    return false;
  };

  mapFileList = (): UploadFile[] => {
    const { media } = this.props;

    return media.map(
      (item): UploadFile => ({
        uid: item.id,
        name: item.originalFileName,
        url: `${FILES_ENDPOINT}/${item.name}?originalFileName=${
          item.originalFileName
        }&token=${accountUtilities.getToken()}`,
        size: 0,
        type: extname(item.originalFileName),
      }),
    );
  };

  render() {
    const { disabled } = this.props;

    return (
      <Upload
        beforeUpload={this.handleUpload}
        fileList={this.mapFileList()}
        disabled={disabled}
        showUploadList={{
          showRemoveIcon: false,
        }}
      >
        <Button icon={<UploadOutlined />} disabled={disabled}>
          Upload
        </Button>
      </Upload>
    );
  }
}

const mapDispatchToProps = (dispatch): ReduxDispatchProps => ({
  fileActions: bindActionCreators(fileActionCreators, dispatch),
});

const mapStateToProps = (): MapStateToProps => (
  state: ReduxStore,
  props: PassedProps,
): ReduxStateProps => ({
  policyAudit: policyAuditSelector(state),
  ids: policyAuditIdsSelector(state),
  media: getPolicyAuditMediaSelector(props)(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadQuestion);
