import React, { useContext } from 'react';

import ControlSystemReportContainer from '@totem/components/controlSystemReport/ControlSystemReportContainer';
import ReportTable from '@totem/components/controlSystemReport/ReportTable';
import WidgetContext from '@totem/components/home/widgets/WidgetContext';
import RecordCountBadge from '@totem/components/RecordCountBadge';
import TableHeadersTop from '@totem/components/TableHeadersTop';

const WidgetControlSystemReport = () => {
  const { onSetTitle } = useContext(WidgetContext);
  return (
    <ControlSystemReportContainer
      onRecordTotalChanged={(recordCount: number) =>
        onSetTitle(<RecordCountBadge count={recordCount} />)
      }
    >
      <TableHeadersTop>
        <ReportTable />
      </TableHeadersTop>
    </ControlSystemReportContainer>
  );
};

export default WidgetControlSystemReport;
