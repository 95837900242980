import React, { useContext, useState } from 'react';
import {
  ContactsOutlined,
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
  LockOutlined,
  QuestionCircleOutlined,
  UserAddOutlined,
} from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Dropdown, MenuProps, Modal } from 'antd';

import UserProfileContext from '@totem/components/UserProfileContext';
import DataAccessPermissionsTreeModal from '@totem/components/users/dataPermissionsTree/dataAccessPermissionsTree';
import SecurityGroupsModal from '@totem/components/users/securityGroups/SecurityGroupsModal';
import { ACTIVATE_USER } from '@totem/graph/user';
import { useErrorNotification } from '@totem/hooks/useErrorNotification';
import { User } from '@totem/types/user';
import { getUserRole, isUserDeactivated } from '@totem/utilities/userUtilities';

import UserRemoveModal from './UserRemoveModal';
import UserUpdateModal from './UserUpdateModal';
import UserViewDetailsModal from './UserViewDetailsModal';

export interface Props {
  user: User;
}

const UserActionMenu = ({ user }: Props) => {
  const [updateModalOpen, setUpdateModalOpen] = useState<boolean>(false);
  const [removeModalOpen, setRemoveModalOpen] = useState<boolean>(false);
  const [dataPermissionOpen, setDataPermissionsModalOpen] =
    useState<boolean>(false);
  const [securityGroupsModalOpen, setSecurityGroupsModalOpen] =
    useState<boolean>(false);
  const [viewDetailsModalOpen, setViewDetailsModalOpen] =
    useState<boolean>(false);
  const { userProfile } = useContext(UserProfileContext);
  const isActiveUser = user.organization?.isActive;
  const isUserAdmin = getUserRole(userProfile) >= 2;
  const isDeactivatedUser = isUserDeactivated(user);

  const [activateUser, { error: activateUserError }] =
    useMutation(ACTIVATE_USER);

  useErrorNotification(activateUserError, 'Error Granting User Access');

  const handleActivateUser = () =>
    Modal.confirm({
      title: 'Grant Site Access?',
      icon: <QuestionCircleOutlined />,
      content: `Are you sure you want to grant site access to ${user.email}?`,
      cancelText: 'No',
      okText: 'Yes, Grant',
      onOk: () => activateUser({ variables: { id: user.id } }),
    });

  const getActionMenuItems = (): MenuProps['items'] => {
    if (isUserAdmin) {
      if (isActiveUser) {
        const menuItems: MenuProps['items'] = [
          {
            key: 'edit_user',
            label: 'Edit User',
            title: 'Edit User',
            icon: <EditOutlined />,
          },
          {
            key: 'security_groups',
            label: 'Security Groups',
            title: 'Security Groups',
            icon: <LockOutlined />,
          },
          {
            key: 'data_permissions',
            label: 'Data Permissions',
            title: 'Data Permissions',
            icon: <LockOutlined />,
          },
          {
            key: 'remove_user',
            label: 'Remove User',
            title: 'Remove User',
            icon: <DeleteOutlined />,
          },
        ];

        return menuItems;
      }

      const menuItems: MenuProps['items'] = [];
      if (isDeactivatedUser) {
        menuItems.push({
          key: 'view_details',
          label: 'View Details',
          title: 'View Details',
          icon: <ContactsOutlined />,
        });
      }

      if (isUserAdmin) {
        menuItems.push({
          key: 'grant_access',
          label: 'Grant Access',
          title: 'Grant Access',
          icon: <UserAddOutlined />,
        });
      }

      return menuItems;
    }

    return [];
  };

  const onMenuClicked = ({ key }) => {
    // eslint-disable-next-line default-case
    switch (key) {
      case 'edit_user':
        setUpdateModalOpen(true);
        break;
      case 'security_groups':
        setSecurityGroupsModalOpen(true);
        break;
      case 'data_permissions':
        setDataPermissionsModalOpen(true);
        break;
      case 'remove_user':
        setRemoveModalOpen(true);
        break;
      case 'view_details':
        setViewDetailsModalOpen(true);
        break;
      case 'grant_access':
        handleActivateUser();
        break;
    }
  };

  return (
    <>
      <Dropdown
        menu={{ items: getActionMenuItems(), onClick: onMenuClicked }}
        trigger={['click']}
      >
        <Button shape="circle" icon={<EllipsisOutlined />} />
      </Dropdown>
      {removeModalOpen && (
        <UserRemoveModal
          user={user}
          open={removeModalOpen}
          onClose={() => setRemoveModalOpen(false)}
        />
      )}
      {viewDetailsModalOpen && (
        <UserViewDetailsModal
          user={user}
          open={viewDetailsModalOpen}
          onClose={() => setViewDetailsModalOpen(false)}
        />
      )}
      {updateModalOpen && (
        <UserUpdateModal
          user={{ ...user, role: getUserRole(user) }}
          open={updateModalOpen}
          onClose={() => setUpdateModalOpen(false)}
        />
      )}
      {dataPermissionOpen && (
        <DataAccessPermissionsTreeModal
          user={user}
          visible={dataPermissionOpen}
          onClose={() => setDataPermissionsModalOpen(false)}
        />
      )}
      {securityGroupsModalOpen && (
        <SecurityGroupsModal
          user={user}
          open={securityGroupsModalOpen}
          onClose={() => setSecurityGroupsModalOpen(false)}
        />
      )}
    </>
  );
};

export default UserActionMenu;
