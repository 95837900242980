import React, { useContext, useMemo, useState } from 'react';
import { ColumnProps } from 'antd/lib/table';

import Table from '@totem/components/common/table/Table';
import DeviceContext from '@totem/components/devices/deviceDetails/DeviceContext';
import { VideoCard } from '@totem/types/devices';
import { getTablePagination } from '@totem/utilities/paginationUtilities';

const VideoCardsTable = () => {
  const { deviceData, loading } = useContext(DeviceContext);
  const [limit, setLimit] = useState<number>(10);
  const [offset, setOffset] = useState<number>(0);

  const handlePagination = (pagination: any) => {
    const { current, pageSize } = pagination;
    setOffset((current - 1) * limit);
    setLimit(pageSize);
  };

  const totalCount =
    typeof deviceData !== 'undefined' &&
    deviceData !== null &&
    deviceData.device.videoCards !== null
      ? deviceData.device.videoCards.length
      : 0;

  const pagination = useMemo(() => {
    return getTablePagination({ limit, offset }, totalCount);
  }, [limit, offset, totalCount]);

  const columns: ColumnProps<VideoCard>[] = [
    {
      title: 'Video Processor',
      dataIndex: 'videoProcessor',
      key: 'videoProcessor',
      sorter: (compA, compB) =>
        compA.videoProcessor === compB.videoProcessor
          ? 0
          : compA.videoProcessor > compB.videoProcessor
          ? 1
          : -1,
    },
    {
      title: 'Mode',
      dataIndex: 'mode',
      key: 'mode',
      sorter: (compA, compB) =>
        compA.mode === compB.mode ? 0 : compA.mode > compB.mode ? 1 : -1,
    },
    {
      title: 'Memory',
      dataIndex: 'memory',
      key: 'memory',
      sorter: (compA, compB) =>
        compA.memory === compB.memory
          ? 0
          : compA.memory > compB.memory
          ? 1
          : -1,
    },
    {
      title: 'Architecture',
      dataIndex: 'architecture',
      key: 'architecture',
      sorter: (compA, compB) =>
        compA.architecture === compB.architecture
          ? 0
          : compA.architecture > compB.architecture
          ? 1
          : -1,
    },
    {
      title: 'Memory Type',
      dataIndex: 'memoryType',
      key: 'memoryType',
      sorter: (compA, compB) =>
        compA.memoryType === compB.memoryType
          ? 0
          : compA.memoryType > compB.memoryType
          ? 1
          : -1,
    },
    {
      title: 'Bits / Pixel',
      dataIndex: 'bitsPerPixel',
      key: 'bitsPerPixel',
      sorter: (compA, compB) =>
        compA.bitsPerPixel === compB.bitsPerPixel
          ? 0
          : compA.bitsPerPixel > compB.bitsPerPixel
          ? 1
          : -1,
    },
  ];

  return (
    <Table
      showSorterTooltip
      columns={columns}
      pagination={pagination}
      onChange={handlePagination}
      dataSource={
        typeof deviceData !== 'undefined' &&
        deviceData !== null &&
        deviceData.device !== null &&
        deviceData.device.videoCards !== null
          ? deviceData.device.videoCards
          : []
      }
      loading={loading}
      rowKey={record => record.videoProcessor}
    />
  );
};

export default VideoCardsTable;
