import actionTypes from '@totem/actions/actionTypes';
import { updateQuestionsWithMoreInfo } from '@totem/utilities/policyAuditUtilities';

import initialState from './initialState';

const policyAuditDetailReducer = (
  state = initialState.policyAuditDetail,
  action,
) => {
  switch (action.type) {
    case actionTypes.GET_POLICY_AUDIT_SUCCESS: {
      return action.data;
    }

    case actionTypes.UPDATE_POLICY_AUDIT_QUESTIONS: {
      const { questions } = action.data;
      return { ...state, questions };
    }

    case actionTypes.UPDATE_POLICY_AUDIT_MORE_INFO: {
      const { questions } = state;
      const { key, value } = action.data;

      return {
        ...state,
        questions: { ...updateQuestionsWithMoreInfo(questions, key, value) },
      };
    }

    case actionTypes.POLICY_AUDIT_PATCH_SUCCESS: {
      const {
        totalQuestions,
        completedQuestions,
        outstandingQuestions,
        submitted,
      } = action.data;

      return {
        ...state,
        totalQuestions,
        completedQuestions,
        outstandingQuestions,
        submitted,
      };
    }

    default: {
      return state;
    }
  }
};

export default policyAuditDetailReducer;
