import { useEffect, useState } from 'react';

import {
  AutoCompleteFilterOptions,
  containsValue,
  FilterAutoCompleteProps,
  FilterOption,
  FilterOptions,
} from '@totem/components/common/filterAutoComplete/FilterAutoCompleteTypes';

export const useTableAutoCompleteFilter = (
  initialSelected: string[] = [],
  availableOptions: FilterOptions = [],
  filterOptions?: AutoCompleteFilterOptions,
): FilterAutoCompleteProps => {
  const LIMIT = filterOptions?.limit || 10;
  const [search, onSearch] = useState<string>('');
  const [selected, setSelected] = useState<FilterOptions>([]);
  const [allOptions, setAllOptions] = useState<FilterOptions>(availableOptions);
  const [options, setOptions] = useState<FilterOptions>([]);
  const [foundOptions, setFoundOptions] = useState<FilterOptions>([]);
  const [applied, setApplied] = useState<FilterOptions>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const defaultSelections = allOptions.filter(
      (filterOption) => initialSelected.indexOf(filterOption.value) >= 0,
    );
    setSelected(defaultSelections);
    setLoading(false);
  }, []);

  useEffect(() => {
    setAllOptions(availableOptions);
  }, [availableOptions]);

  const onSelect = (filterOption: FilterOption) => {
    if (containsValue(selected, filterOption.value)) {
      setSelected(
        selected.filter((option) => option.value !== filterOption.value),
      );
    } else {
      setSelected([...selected, filterOption]);
    }
  };

  const onApply = () => {
    setApplied(selected);
    onSearch('');
  };

  const onReset = () => {
    setOptions([]);
    setSelected([]);
    setApplied([]);
    onSearch('');
  };

  const onRemove = (id: string) => {
    const update = selected.filter(option => option.value !== id);
    setSelected(update);
    setApplied(update);
  };

  const searchFilterOptions = (
    name: string,
    currentlySelected: FilterOptions,
  ) => {
    if (!name) {
      setOptions([]);
      return;
    }

    const newOptions = allOptions.filter(
      (filterOption) => !containsValue(currentlySelected, filterOption.value),
    );
    //.slice(0, LIMIT);

    setOptions(newOptions);

    if (newOptions !== null) {
      setFoundOptions(
        newOptions
          .filter(
            (filterOption) =>
              filterOption.name.toLowerCase().indexOf(name.toLowerCase()) >= 0,
          )
          .slice(0, LIMIT),
      );
    } else {
      setFoundOptions([]);
    }
  };

  useEffect(() => {
    searchFilterOptions(search, selected);
  }, [search]);

  useEffect(() => {
    if (filterOptions?.onChange) {
      const values = applied.map((filterOption) => filterOption.value);
      filterOptions.onChange(values);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applied]);

  return {
    search,
    onSearch,
    options,
    foundOptions,
    availableOptions,
    selected,
    applied,
    onSelect,
    onRemove,
    onApply,
    onReset,
    loading,
  };
};

export default useTableAutoCompleteFilter;
