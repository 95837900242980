import React, { ReactNode, useEffect, useState } from 'react';

import CoveDataProtectionBuildingSelectModal from '@totem/components/settings/sections/coveDataProtectionMapping/BuildingSelectModal';
import CoveDataProtectionContext from '@totem/components/settings/sections/coveDataProtectionMapping/CoveDataProtectionContext';
import { TenantReportRecord } from '@totem/components/settings/sections/coveDataProtectionMapping/types';
import { getToken } from '@totem/utilities/accountUtilities';
import { COVE_DATA_PROTECTION_ENDPOING } from '@totem/utilities/endpoints';

type Props = {
  children?: ReactNode;
};

const CoveDataProtectionMappingContainer = ({ children }: Props) => {
  const [refreshData, setRefreshData] = useState<boolean>(true);
  const [isSending, setIsSending] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [mappingData, setMappingData] = useState<TenantReportRecord[]>([]);
  const [selectedAction, setSelectedAction] = useState<string>('');
  const [selectedData, setSelectedData] = useState<TenantReportRecord>(null);

  useEffect(() => {
    if (refreshData) {
      setRefreshData(false);
      if (isSending) {
        return;
      }
      const endpoint = `${COVE_DATA_PROTECTION_ENDPOING}/tenants`;

      fetch(`${endpoint}`, {
        method: 'GET',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
      })
        .then((res) => res.json())
        .then((result: TenantReportRecord[]) => {
          setMappingData(result);
        })
        .then(() => {
          setIsSending(false);
          setIsLoading(false);
        });

      setIsSending(true);
    }
  }, [refreshData]);

  const handleAction = (action: string, tenantRecord: TenantReportRecord) => {
    setSelectedAction(action);
    setSelectedData(tenantRecord);
  };

  const handleActionComplete = (doRefresh: boolean) => {
    setSelectedAction('');
    setSelectedData(null);
    if (doRefresh) {
      setRefreshData(true);
    }
  };

  return (
    <CoveDataProtectionContext.Provider
      value={{
        data: mappingData,
        loading: isLoading,
        onAction: handleAction,
        onActionComplete: handleActionComplete,
      }}
    >
      <div>{children}</div>
      {selectedAction === 'showMappingDialog' && selectedData !== null && (
        <CoveDataProtectionBuildingSelectModal
          visible={selectedAction === 'showMappingDialog'}
          tenantRecord={selectedData}
        />
      )}
    </CoveDataProtectionContext.Provider>
  );
};

export default CoveDataProtectionMappingContainer;
