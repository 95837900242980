import React from 'react';
import { Col, Row } from 'antd';

import LevelOfCompliance from '@totem/components/surveyV2/analysisReport/levelOfCompliance/levelOfCompliance';
import MostCriticalPolicies from '@totem/components/surveyV2/analysisReport/mostCriticalPolicies/mostCriticalPolicies';
import PolicyResponses from '@totem/components/surveyV2/analysisReport/policyResponses/policyResponses';
import colors from '@totem/styles/colors';

const styles = {
  borderRight: {
    borderRight: `1px solid ${colors.neutral.ash}`,
  },
};

const Overview = () => {
  return (
    <>
      <Row>
        <Col span={7} style={styles.borderRight}>
          <PolicyResponses />
        </Col>
        <Col span={10} style={styles.borderRight}>
          <LevelOfCompliance />
        </Col>
        <Col span={7}>
          <MostCriticalPolicies />
        </Col>
      </Row>
    </>
  );
};

export default Overview;
