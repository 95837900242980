import React, { useState } from 'react';
import { Button } from 'antd';

import TenantAddDialog from '@totem/components/tenants/tenantsPage/TenantAddDialog';
import IBDIcon from '@totem/styles/Icon';

const TenantAddButton = () => {
  const [showAdd, setShowAdd] = useState(false);

  return (
    <>
      <Button icon={<IBDIcon name="Add" />} onClick={() => setShowAdd(true)}>
        Add Tenant
      </Button>
      {showAdd === true && (
        <TenantAddDialog open={showAdd} onClose={() => setShowAdd(false)} />
      )}
    </>
  );
};

export default TenantAddButton;
