import React from 'react';

import CommissioningReport from '@totem/components/commissionReport/CommissioningReport';

const WidgetCommissioningReport = () => {
  return (
    <CommissioningReport />
  );
};

export default WidgetCommissioningReport;
