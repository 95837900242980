import actionTypes from './actionTypes';

export const requestUserSecurityGroupAdd = data => ({
  type: actionTypes.REQUEST_USER_SECURITY_GROUP_ADD,
  data,
});

export const requestUserSecurityGroupAddSuccess = data => ({
  type: actionTypes.REQUEST_USER_SECURITY_GROUP_ADD_SUCCESS,
  data,
});

export const requestUserSecurityGroupAddFailed = () => ({
  type: actionTypes.REQUEST_USER_SECURITY_GROUP_ADD_FAILED,
});

export const requestUserSecurityGroupRemove = data => ({
  type: actionTypes.REQUEST_USER_SECURITY_GROUP_REMOVE,
  data,
});

export const requestUserSecurityGroupRemoveSuccess = data => ({
  type: actionTypes.REQUEST_USER_SECURITY_GROUP_REMOVE_SUCCESS,
  data,
});

export const requestUserSecurityGroupRemoveFailed = () => ({
  type: actionTypes.REQUEST_USER_SECURITY_GROUP_REMOVE_FAILED,
});
