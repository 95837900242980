import React, { useContext } from 'react';
import { PlusCircleOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { ColumnProps } from 'antd/lib/table';

import { EMPTY_ID } from '@totem/components/common/reference/ReferenceSelector';
import Table from '@totem/components/common/table/Table';
import Tooltip from '@totem/components/common/tooltip/Tooltip';
import NozomiProvisioningActionMenu from '@totem/components/settings/sections/services/provisioning/threat_detection/NozomiProvisioningActionMenu';
import UserProfileContext from '@totem/components/UserProfileContext';
import { APIConfiguration } from '@totem/types/nozomi';
import { MIN_DATE_TIME_STRING } from '@totem/types/ticketing';

import '../../services.css';

const styles = {
  iconButton: {
    margin: '0 2px',
  },
};

export interface Props {
  configs: APIConfiguration[];
  loading: boolean;
  onEdit: (apiConfig: APIConfiguration) => void;
  onCopy: (apiConfig: APIConfiguration) => void;
  onDelete: (apiConfig: APIConfiguration) => void;
}

const NozomiApiTable = ({
  configs,
  loading,
  onEdit,
  onDelete,
  onCopy,
}: Props) => {
  const { userProfile } = useContext(UserProfileContext);

  const handleNewRecordRequest = () => {
    const emptyEntry: APIConfiguration = {
      id: EMPTY_ID,
      type: 'Guardian',
      data: {
        organizationId: userProfile.organization.id,
        region: {
          id: EMPTY_ID,
          name: '',
        },
        building: {
          id: EMPTY_ID,
          name: '',
        },
        syncTimes: [],
        connection: {
          username: '',
          password: '',
          baseUrl: '',
          confirmed: false,
        },
      },
      createdByType: 'USER',
      createdByName: `${userProfile.lastName}, ${userProfile.firstName}`,
      createdById: userProfile.id,
      createdAt: MIN_DATE_TIME_STRING,
    };

    onEdit(emptyEntry);
  };

  const columns: ColumnProps<any>[] = [
    {
      title: 'Region',
      dataIndex: 'data.region.id',
      key: 'data.region.id',
      render: (_, config: APIConfiguration) => (
        <span>{config.data.region.name}</span>
      ),
    },
    {
      title: 'Building',
      dataIndex: 'data.building.id',
      key: 'data.building.id',
      render: (_, config: APIConfiguration) => (
        <span>{config.data.building.name}</span>
      ),
    },
    {
      title: (
        <Tooltip title="New Entry" placement="top">
          <Button
            icon={<PlusCircleOutlined />}
            shape="circle"
            onClick={handleNewRecordRequest}
            style={styles.iconButton}
          />
        </Tooltip>
      ),
      width: 30,
      dataIndex: 'additionalOptions',
      key: 'additionalOptions',
      render: (_, config: APIConfiguration) => (
        <div className="center-table-cell">
          <NozomiProvisioningActionMenu
            apiConfig={config}
            onEdit={onEdit}
            onCopy={onCopy}
            onDelete={onDelete}
          />
        </div>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={configs}
      loading={loading}
      rowKey="id"
    />
  );
};

export default NozomiApiTable;
