import colors from '@totem/styles/colors';
export const origDefaultChartColors: string[] = [
  '#B0E0E6',
  '#ADD8E6',
  '#87CEFA',
  '#87CEEB',
  '#00BFFF',
  '#B0C4DE',
  '#1E90FF',
  '#6495ED',
  '#5F9EA0',
  '#4169E1',
];

export const ibColors: string[] = [
  '#4E66AD',
  '#6A4FB0',
  '#3E929E',
  '#3D56A1',
  '#5A3EA4',
  '#2E8692',
  '#314273',
  '#443275',
  '#266068',
];

export const urbanColors_dominate: string[] = [
  '#72829f',
  '#ced9e5',
  '#b1c0bc',
  '#7e93a7',
  '#c1b2b6',
  '#605d75',
  '#285780',
  '#898d8d',
  '#7e7f74',
  '#94a9cb',
  '#a192b2',
  '#2e4d58',
];

export const urbanColors_subordinate: string[] = [
  '#5a8d84',
  '#5b618f',
  '#5c4e63',
  '#506d85',
  '#6b8d73',
  '#8a8d8f',
  '#a7a2c3',
  '#bdc5db',
  '#b6ada5',
  '#002855',
  '#68593c',
  '#919d9d',
];

export const urbanColors_accent: string[] = [
  '#c6c4d2',
  '#9db0ac',
  '#333f48',
  '#003d4c',
  '#3e5d58',
  '#453536',
  '#a7aca2',
  '#244c5a',
  '#9bb8d3',
  '#92aca0',
  '#6e7ca0',
  '#3e2b2e',
];

export const defaultChartColors = colors.graphs;
export const vibrantColors: string[] = [
  '#5a8d84',
  '#c1b2b6',
  '#5b618f',
  '#a192b2',
  '#244c5a',
  '#5c4e63',
];

export const defaultDonutChartDataLabelsColor: string[] = ['#FFFFFF'];
export const defaultDonutChartTotalColor: string = '#000000';

export const shuffle = (paramArray: string[]) => {
  const array = [...paramArray];
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex > 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex = currentIndex - 1;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
};
