import React, { useContext, useEffect, useState } from 'react';

import Count from '@totem/components/common/Count';
import AnalysisContext from '@totem/components/surveyV2/analysis/AnalysisContext';
import SubTitle from '@totem/components/surveyV2/SubTitle';
import colors from '@totem/styles/colors';
import { isNotNull } from '@totem/utilities/common';

import '../survey.css';

const styles = {
  count: {
    background: colors.neutral.ash,
  },
};

type CriticalPolicies = {
  policyName: string;
  policies: string[];
  nonCompliantTotal: number;
};

const MostCriticalPolicies = () => {
  const { filteredData } = useContext(AnalysisContext);
  const [topCriticalPolicies, setTopCriticalPolicies] = useState<
    CriticalPolicies[]
  >([]);

  useEffect(() => {
    const criticalPolicies: CriticalPolicies[] = [];
    if (isNotNull(filteredData)) {
      for (let idx = 0; idx < filteredData.length; idx++) {
        const chk = filteredData[idx];
        const foundIdx = criticalPolicies.findIndex(
          (rec) => rec.policyName === chk.policyName,
        );
        if (foundIdx === -1) {
          criticalPolicies.push({
            policyName: chk.policyName,
            policies: [chk.policy],
            nonCompliantTotal: chk.noncompliant,
          });
        } else {
          criticalPolicies[foundIdx].nonCompliantTotal += chk.noncompliant;
          // eslint-disable-next-line max-depth
          if (!criticalPolicies[foundIdx].policies.includes(chk.policy)) {
            criticalPolicies[foundIdx].policies.push(chk.policy);
          }
        }
      }

      const sorted = criticalPolicies.sort((compA, compB) =>
        compA.nonCompliantTotal > compB.nonCompliantTotal ? -1 : 1,
      );
      setTopCriticalPolicies(
        sorted.filter((chk) => chk.nonCompliantTotal > 0).slice(0, 5),
      );
    }
  }, [filteredData]);

  const handlePolicyNameSelected = (policyName: string) => {
    console.log(`Policy Name: ${policyName}`);
  };

  return (
    <div styleName="overview-section">
      <SubTitle>Most Critical Policies</SubTitle>
      <div styleName="overview-content">
        <div styleName="overview-content-container">
          <div styleName="findings">
            <div styleName="finding-labels">
              <div styleName="graph-title finding">By Policy Name</div>
              {topCriticalPolicies.map((topPolicy, index) => (
                <div key={index} styleName="finding">
                  <div>
                    <div
                      styleName="finding-label"
                      onClick={() =>
                        handlePolicyNameSelected(topPolicy.policyName)
                      }
                    >
                      {topPolicy.policyName}
                    </div>
                    <div styleName="finding-label-subtext">
                      {topPolicy.policies.length} Policies
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div>
              <div styleName="graph-title no-bold finding">
                Number of Non-Compliant
              </div>
              {topCriticalPolicies.map((topPolicy, index) => (
                <div key={index} styleName="finding">
                  <Count
                    count={topPolicy.nonCompliantTotal}
                    size="small"
                    style={styles.count}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MostCriticalPolicies;
