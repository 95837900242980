import React, { useEffect, useState } from 'react';

import CardBody from '@totem/components/CardBody';
import useTableAutoCompleteFilter from '@totem/components/common/filterAutoComplete/TableFilter';
import ComponentHeader from '@totem/components/ComponentHeader';
import DeviceBackupContext from '@totem/components/deviceBackups/deviceBackupContext';
import DeviceBackupTable from '@totem/components/deviceBackups/deviceBackupTable';
import { buildParameters } from '@totem/components/deviceBackups/utilities';
import RecordCountBadge from '@totem/components/RecordCountBadge';
import { Params } from '@totem/types/common';
import {
  DeviceBackupConnectionInput,
  DeviceLastBackupFilterOptions,
} from '@totem/types/devices';
import { getToken } from '@totem/utilities/accountUtilities';
import { DEVICES_ENDPOINT } from '@totem/utilities/endpoints';

import { BackupReportResponse } from './types';

type Props = {
  pRegionId: string[];
  pBuildingId: string[];
  pControlSystemId: string[];
  pDeviceId: string[];
};

const DeviceBackupPanel = ({
  pRegionId,
  pBuildingId,
  pControlSystemId,
  pDeviceId,
}: Props) => {
  const [input, updateInput] = useState<DeviceBackupConnectionInput>({
    from: '',
    pageSize: 20,
    page: 1,
    sortField: 'createdAt',
    sortDirection: '1',
    buildingId: pBuildingId,
    regionId: pRegionId,
    controlSystemId: pControlSystemId,
    deviceId: pDeviceId,
    systemType: [],
    deviceName: '',
    controlSystemName: '',
    backupStatus: [],
    lastBackupStatus: [],
    isBackupStale: [],
  });

  const [refreshData, setRefreshData] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isFilterOptionsLoaded, setIsFilterOptionsLoaded] =
    useState<boolean>(false);
  const [isSending, setIsSending] = useState(false);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [backupData, setBackupData] = useState<BackupReportResponse>(null);
  const [filterOptions, setFilterOptions] =
    useState<DeviceLastBackupFilterOptions>({
      region: [],
      building: [],
      controlSystemType: [],
      controlSystem: [],
      device: [],
      backupStatus: [],
    });

  const setInput = (updated: Partial<DeviceBackupConnectionInput>) => {
    updateInput({ ...input, ...updated });
    setRefreshData(true);
  };

  const regionFilter = useTableAutoCompleteFilter(
    input.regionId,
    filterOptions.region,
    {
      onChange: (regionId) => {
        setInput({ ...input, regionId });
      },
    },
  );

  const buildingFilter = useTableAutoCompleteFilter(
    input.buildingId,
    filterOptions.building,
    {
      onChange: (buildingId) => {
        setInput({ ...input, buildingId });
      },
    },
  );

  const deviceFilter = useTableAutoCompleteFilter(
    input.deviceId,
    filterOptions.device,
    {
      onChange: (deviceId) => {
        setInput({ ...input, deviceId });
      },
    },
  );

  useEffect(() => {
    if (!isFilterOptionsLoaded) {
      fetch(`${DEVICES_ENDPOINT}/lastBackupReportFilterOptions`, {
        method: 'GET',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
      })
        .then((res) => res.json())
        .then((result: DeviceLastBackupFilterOptions) => {
          setFilterOptions(result);
        })
        .then(() => {
          setIsFilterOptionsLoaded(true);
        });
    }
  }, [isFilterOptionsLoaded]);

  useEffect(() => {
    if (refreshData) {
      if (isSending) {
        return;
      }
      setIsLoading(true);
      const params: Params = buildParameters(input);

      fetch(`${DEVICES_ENDPOINT}/backupReport`, {
        method: 'POST',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
        body: JSON.stringify(params),
      })
        .then((res) => res.json())
        .then((result: BackupReportResponse) => {
          setBackupData(result);
          setTotalRecords(result.paging.totalRecords);
        })
        .then(() => {
          setIsLoading(false);
          setIsSending(false);
        });

      setIsSending(true);
      setRefreshData(false);
    }
  }, [refreshData]);

  return (
    <DeviceBackupContext.Provider
      value={{
        input,
        setInput,
        loading: isLoading,
        buildingFilter,
        regionFilter,
        deviceFilter,
      }}
    >
      <ComponentHeader
        title={'Device Backups'}
        options={<RecordCountBadge count={totalRecords} />}
      />
      <CardBody>
        <DeviceBackupTable
          page={input.page}
          pageSize={input.pageSize}
          total={totalRecords}
          backupResults={backupData}
          filterOptions={filterOptions}
          loading={isLoading}
        />
      </CardBody>
    </DeviceBackupContext.Provider>
  );
};

export default DeviceBackupPanel;
