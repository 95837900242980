import React from 'react';
import {
  AlertOutlined,
  ApiOutlined,
  BankOutlined,
  BulbOutlined,
  CarOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  ClusterOutlined,
  DashboardOutlined,
  ExclamationCircleOutlined,
  KeyOutlined,
  ReconciliationOutlined,
  ToolOutlined,
  VideoCameraOutlined,
} from '@ant-design/icons';
import { AntdIconProps } from '@ant-design/icons/lib/components/AntdIcon';

import colors from '@totem/styles/colors';
import {
  BackupStatus,
  ControlSystem,
  ControlSystemType,
} from '@totem/types/controlSystem';
import { ControlSystemBackupsConnection } from '@totem/types/controlSystemBackup';
import { getSeverityColor } from '@totem/utilities/events';

export type ControlSystemTypeOption = {
  text: string;
  value: ControlSystemType;
  icon: React.ComponentType<AntdIconProps>;
};

export const controlSystemTypeMap: {
  [key: number]: ControlSystemTypeOption;
} = {
  [ControlSystemType.Unset]: {
    text: 'N/A',
    value: ControlSystemType.Unset,
    icon: ToolOutlined,
  },
  [ControlSystemType.BuildingAutomation]: {
    text: 'Building Automation',
    value: ControlSystemType.BuildingAutomation,
    icon: ClusterOutlined,
  },
  [ControlSystemType.LightingControl]: {
    text: 'Lighting Control',
    value: ControlSystemType.LightingControl,
    icon: BulbOutlined,
  },
  [ControlSystemType.Elevator]: {
    text: 'Elevator',
    value: ControlSystemType.Elevator,
    icon: BankOutlined,
  },
  [ControlSystemType.PowerManagement]: {
    text: 'Power Management',
    value: ControlSystemType.PowerManagement,
    icon: ApiOutlined,
  },
  [ControlSystemType.AccessControl]: {
    text: 'Access Control',
    value: ControlSystemType.AccessControl,
    icon: KeyOutlined,
  },
  [ControlSystemType.VideoSurveillance]: {
    text: 'Video Surveillance',
    value: ControlSystemType.VideoSurveillance,
    icon: VideoCameraOutlined,
  },
  [ControlSystemType.IntrusionDetection]: {
    text: 'Intrusion Detection',
    value: ControlSystemType.IntrusionDetection,
    icon: AlertOutlined,
  },
  [ControlSystemType.Metering]: {
    text: 'Metering',
    value: ControlSystemType.Metering,
    icon: DashboardOutlined,
  },
  [ControlSystemType.Irrigation]: {
    text: 'Irrigation',
    value: ControlSystemType.Irrigation,
    icon: ToolOutlined,
  },
  [ControlSystemType.FuelManagement]: {
    text: 'Fuel Management',
    value: ControlSystemType.FuelManagement,
    icon: ReconciliationOutlined,
  },
  [ControlSystemType.LeakDetection]: {
    text: 'Leak Detection',
    value: ControlSystemType.LeakDetection,
    icon: ToolOutlined,
  },
  [ControlSystemType.Parking]: {
    text: 'Parking',
    value: ControlSystemType.Parking,
    icon: CarOutlined,
  },
  [ControlSystemType.Other]: {
    text: 'Other',
    value: ControlSystemType.Other,
    icon: ToolOutlined,
  },
  [ControlSystemType.SecuritySystem]: {
    text: 'Security System',
    value: ControlSystemType.SecuritySystem,
    icon: ToolOutlined,
  },
  [ControlSystemType.VendorSystemNetwork]: {
    text: 'Vendor System Network',
    value: ControlSystemType.VendorSystemNetwork,
    icon: ToolOutlined,
  },
  [ControlSystemType.BuildingNetwork]: {
    text: 'Building Network',
    value: ControlSystemType.BuildingNetwork,
    icon: ToolOutlined,
  },
  [ControlSystemType.IncomingServiceISP]: {
    text: 'Incoming Service / ISP',
    value: ControlSystemType.IncomingServiceISP,
    icon: ToolOutlined,
  },
};

export const controlSystemTypeOptions: ControlSystemTypeOption[] =
  Object.values(controlSystemTypeMap);

export const backupStatusLabels: {
  [key in BackupStatus]: string;
} = Object.freeze({
  [BackupStatus.BACKED_UP]: 'Yes',
  [BackupStatus.BACKED_UP_EXPIRED]: 'Yes',
  [BackupStatus.NOT_BACKED_UP]: 'No',
  [BackupStatus.UNKNOWN]: 'Unknown',
});

export const backupStatusColors: {
  [key in BackupStatus]: string;
} = Object.freeze({
  [BackupStatus.BACKED_UP]: colors.utility.positive,
  [BackupStatus.BACKED_UP_EXPIRED]: colors.utility.warning,
  [BackupStatus.NOT_BACKED_UP]: colors.utility.error,
  [BackupStatus.UNKNOWN]: colors.neutral.gray,
});

export const backupStatusDescriptions: {
  [key in BackupStatus]: string;
} = Object.freeze({
  [BackupStatus.BACKED_UP]:
    'Indicates that a backup has been reported within the past 3 days.',
  [BackupStatus.BACKED_UP_EXPIRED]:
    'Indicates that a backup is present but is older than 3 days',
  [BackupStatus.NOT_BACKED_UP]:
    'No backup exists or an API connection is showing no backup.',
  [BackupStatus.UNKNOWN]:
    'Someone auditing this system was unsure if there was a backup.',
});

export const getBackupStatusIcon = (
  backupStatus: BackupStatus,
  styles: React.CSSProperties = {},
): React.ReactNode => {
  if (backupStatus) {
    let Icon = ExclamationCircleOutlined;
    switch (backupStatus) {
      case BackupStatus.BACKED_UP:
        Icon = CheckCircleOutlined;
        break;
      case BackupStatus.NOT_BACKED_UP:
        Icon = CloseCircleOutlined;
        break;
      default:
        Icon = ExclamationCircleOutlined;
    }

    return (
      // @ts-ignore
      <Icon
        style={{
          ...{
            fontSize: '20px',
            color: backupStatusColors[backupStatus] ?? colors.neutral.gray,
          },
          ...styles,
        }}
      />
    );
  }

  return null;
};

export const getControlSystemBackupStatus = (controlSystem: ControlSystem) => {
  if (controlSystem !== null) {
    if (controlSystem.backups !== null) {
      if (controlSystem.backups.controlSystemBackups !== null) {
        // eslint-disable-next-line max-depth
        if (controlSystem.backups.controlSystemBackups.length > 0) {
          return controlSystem.backups.controlSystemBackups[0].eventSeverity;
        }
      }
    }
  }
  return 0;
};

export const getControlSystemBackupStatusFromBackups = (
  backups: ControlSystemBackupsConnection,
) => {
  if (backups !== null) {
    if (backups.controlSystemBackups !== null) {
      // eslint-disable-next-line max-depth
      if (backups.controlSystemBackups.length > 0) {
        return backups.controlSystemBackups[0].eventSeverity;
      }
    }
  }
  return 0;
};

export const getBackupStatusIcon2 = (
  backupStatus: BackupStatus,
  severity: number,
  styles: React.CSSProperties = {},
): React.ReactNode => {
  if (backupStatus) {
    let Icon = ExclamationCircleOutlined;
    switch (backupStatus) {
      case BackupStatus.BACKED_UP:
        Icon = CheckCircleOutlined;
        break;
      case BackupStatus.NOT_BACKED_UP:
        Icon = CloseCircleOutlined;
        break;
      default:
        Icon = ExclamationCircleOutlined;
    }

    return (
      // @ts-ignore
      <Icon
        style={{
          ...{
            fontSize: '20px',
            color: getSeverityColor(severity),
          },
          ...styles,
        }}
      />
    );
  }

  return null;
};

const controlSystemUtilities = {
  controlSystemTypeMap,
  controlSystemTypeOptions,
  backupStatusLabels,
  backupStatusColors,
};

export default controlSystemUtilities;
