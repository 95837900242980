import React, { useContext, useState } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Button, Col, Modal, Row } from 'antd';

import ContactSearchContext from '@totem/components/contactSearch/contactSearchContext';
import SearchInput from '@totem/components/contactSearch/searchInput';
import SearchResultsTableSelectable from '@totem/components/contactSearch/SearchResultsTableSelectable';
import ModalFooter from '@totem/components/ModalFooter';
import ModalTitle from '@totem/components/ModalTitle';

const ContactSearchTinyPanels = () => {
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const { selectedContact } = useContext(ContactSearchContext);
  const showContactDetails =
    typeof selectedContact !== 'undefined' && selectedContact !== null;

  const getSelectedContactDisplay = () => {
    if (selectedContact !== null) {
      return (
        <>
          {selectedContact.name}
          {selectedContact.email !== null ? (
            <>
              <br />
              {selectedContact.email}
            </>
          ) : (
            <span />
          )}
          {selectedContact.phone !== null ? (
            <>
              <br />
              {selectedContact.phone}
            </>
          ) : (
            <span />
          )}
          {selectedContact.labels !== null ? (
            <>
              <br />
              {selectedContact.labels.map(label => (
                <span key={selectedContact.id + label}>
                  {label}
                  <br />
                </span>
              ))}
            </>
          ) : (
            <span />
          )}
        </>
      );
    }
    return <span />;
  };

  return (
    <>
      {showContactDetails && (
        <Row align={'middle'} style={{ marginTop: '10px' }}>
          <Col
            span={18}
            style={{
              verticalAlign: 'top',
              textAlign: 'left',
              paddingLeft: '5px',
            }}
          >
            {getSelectedContactDisplay()}
          </Col>
          <Col span={6} style={{ verticalAlign: 'top', textAlign: 'center' }}>
            <a onClick={() => setShowDialog(true)}>
              <SearchOutlined style={{ cursor: 'pointer' }} />
            </a>
          </Col>
        </Row>
      )}
      {!showContactDetails && (
        <Row align={'middle'} style={{ marginTop: '10px' }}>
          <Col
            span={18}
            style={{ verticalAlign: 'middle', textAlign: 'center' }}
          >
            <div style={{ fontSize: '24px' }}>Search Contacts</div>
          </Col>
          <Col
            span={6}
            style={{ verticalAlign: 'middle', textAlign: 'center' }}
          >
            <a onClick={() => setShowDialog(true)}>
              <SearchOutlined style={{ cursor: 'pointer', fontSize: '48px' }} />
            </a>
          </Col>
        </Row>
      )}
      <Modal
        open={showDialog}
        onCancel={() => setShowDialog(false)}
        title={<ModalTitle>Search Contacts</ModalTitle>}
        confirmLoading={false}
        width={'60%'}
        footer={
          <ModalFooter>
            <Button onClick={() => setShowDialog(false)}>Close</Button>
          </ModalFooter>
        }
      >
        <SearchInput />
        <br />
        <br />
        <SearchResultsTableSelectable
          onSelection={() => setShowDialog(false)}
        />
      </Modal>
    </>
  );
};

export default ContactSearchTinyPanels;
