import React, { useContext } from 'react';

import UserProfileContext from '@totem/components/UserProfileContext';
import { staticLogoLarge } from '@totem/selectors/preferencesSelectors';

import beaconLogoLg from '../../images/beaconCapitalPartners.png';
import tjbcLarge from '../../images/johnBuckCompanyLarge.png';

import './logos.css';

const LeftNavLogoLarge = () => {
  const { userProfile } = useContext(UserProfileContext);
  const organizationId = userProfile.organization.id;

  let logo = staticLogoLarge;
  switch (organizationId) {
    case '5f2c6593a679f910caaa4343': // Example:  Shouldn't match actual org.
      logo = beaconLogoLg;
      break;
    case '66a294300a1b3e41854b7887': // The John Buck Company
      logo = tjbcLarge;
      return (
        <img
          alt="Logo"
          src={logo}
          styleName="navLogoLarge"
          style={{ height: '55px' }}
        />
      );
    default:
      break;
  }

  return <img alt="Logo" src={logo} styleName="navLogoLarge" />;
};

export default LeftNavLogoLarge;
