import React, { useCallback, useContext, useState } from 'react';
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Switch,
  Tabs,
  TabsProps,
} from 'antd';

import { Product } from '@totem/components/endoflife/types';

const FormItem = Form.Item;

import { CloseCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';

import GenericAutoComplete from '@totem/components/common/autoCompletes/GenericAutoComplete';
import EndOfLifeContext from '@totem/components/endoflife/EndOfLifeContext';
import ModalFooter from '@totem/components/ModalFooter';
import ModalTitle from '@totem/components/ModalTitle';
import TabTitle from '@totem/components/TabTitle';
import { AutoCompleteValue } from '@totem/types/autoComplete';
import { getToken } from '@totem/utilities/accountUtilities';
import { ParseStringDate } from '@totem/utilities/dateUtilities';
import { END_OF_LIFE_ENDPOINT } from '@totem/utilities/endpoints';
import { CheckResponseShowError } from '@totem/utilities/responseUtilities';

const styles = {
  form: {
    width: '100%',
  },
  formItem: {
    paddingBottom: '0',
    marginBottom: '1rem',
  },
  iconButton: {
    margin: '0 2px',
  },
  removeIcon: {
    color: '#1890ff',
  },
};

type Props = {
  visible: boolean;
  product: Product;
};
const EditModal = ({ visible, product }: Props) => {
  const [isSending, setIsSending] = useState(false);
  const [data, setData] = useState<Product>(product);
  const [aliasInput, setAliasInput] = useState<string>('');
  const { onAction } = useContext(EndOfLifeContext);

  const saveEndOfLifeData = useCallback(async (eolData) => {
    if (isSending) {
      return;
    }
    setIsSending(true);

    fetch(`${END_OF_LIFE_ENDPOINT}`, {
      method: 'PUT',
      headers: new Headers({
        Authorization: `Bearer ${getToken()}`,
      }),
      body: JSON.stringify(eolData),
    }).then((res) => {
      setIsSending(false);
      const success = CheckResponseShowError(res);
      if (success) {
        onAction('closeEdit_Refresh', null);
      }
    });
  }, []);

  const handleSubmit = () => {
    saveEndOfLifeData(data);
  };

  const isFormValid = () => {
    if (typeof data === 'undefined') {
      return false;
    }

    if (data === null) {
      return false;
    }

    if (data.productType === '') {
      return false;
    }

    if (data.product === '') {
      return false;
    }

    if (data.cycle === '') {
      return false;
    }

    return true;
  };

  const handleChange = ({
    target: { value, name },
  }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setData({ ...data, [name]: value });
  };

  const handleDateChange = (date, property: string) => {
    if (date === null) {
      setData({ ...data, [property]: '' });
    } else {
      setData({ ...data, [property]: date.toISOString().slice(0, 10) });
    }
  };

  const addAlias = () => {
    if (aliasInput !== '') {
      let aliasExists = false;
      for (let idx = 0; idx < data.aliases.length; idx++) {
        if (data.aliases[idx].alias === aliasInput) {
          aliasExists = true;
        }
      }
      if (!aliasExists) {
        setData({
          ...data,
          aliases: [...data.aliases, { source: 'manual', alias: aliasInput }],
        });
      }
    }
  };

  const handleRemoveAlias = (aliasToRemove: string) => {
    setData({
      ...data,
      aliases: data.aliases.filter((alias) => alias.alias !== aliasToRemove),
    });
  };

  const tabItems: TabsProps['items'] = [
    {
      key: 'description',
      label: <TabTitle>Description</TabTitle>,
      children: (
        <>
          <FormItem
            label="Product Type"
            required
            colon={false}
            style={styles.formItem}
          >
            <GenericAutoComplete
              type="EOLProductType"
              label="Product Type"
              allowFreeform
              limit={5}
              filters={[]}
              selectedValue={{
                display:
                  typeof data !== 'undefined' &&
                  data !== null &&
                  typeof data.productType !== 'undefined' &&
                  data.productType !== null
                    ? data.productType
                    : '',
                value:
                  typeof data !== 'undefined' &&
                  data !== null &&
                  typeof data.productType !== 'undefined' &&
                  data.productType !== null
                    ? data.productType
                    : '',
              }}
              onChange={(newValue: string) => {
                setData({ ...data, productType: newValue });
              }}
              onSelect={(newValue: AutoCompleteValue) => {
                setData({ ...data, productType: newValue.value });
              }}
            />
          </FormItem>
          <FormItem
            label="Product"
            required
            colon={false}
            style={styles.formItem}
          >
            <GenericAutoComplete
              type="EOLProduct"
              label="Product"
              allowFreeform
              limit={5}
              filters={[
                {
                  field: 'productType',
                  values: [
                    typeof data !== 'undefined' &&
                    data !== null &&
                    typeof data.productType !== 'undefined' &&
                    data.productType !== null
                      ? data.productType
                      : '',
                  ],
                },
              ]}
              selectedValue={{
                display:
                  typeof data !== 'undefined' &&
                  data !== null &&
                  typeof data.product !== 'undefined' &&
                  data.product !== null
                    ? data.product
                    : '',
                value:
                  typeof data !== 'undefined' &&
                  data !== null &&
                  typeof data.product !== 'undefined' &&
                  data.product !== null
                    ? data.product
                    : '',
              }}
              onChange={(newValue: string) => {
                setData({ ...data, product: newValue });
              }}
              onSelect={(newValue: AutoCompleteValue) => {
                setData({ ...data, product: newValue.value });
              }}
            />
          </FormItem>
          <FormItem label="Name" colon={false} style={styles.formItem}>
            <Input
              name="name"
              type="text"
              value={
                typeof data !== 'undefined' &&
                data !== null &&
                typeof data.name !== 'undefined' &&
                data.name !== null
                  ? data.name
                  : ''
              }
              onChange={handleChange}
              maxLength={50}
            />
          </FormItem>
          <FormItem
            label="Cycle"
            required
            colon={false}
            style={styles.formItem}
          >
            <Input
              name="cycle"
              type="text"
              value={
                typeof data !== 'undefined' &&
                data !== null &&
                typeof data.cycle !== 'undefined' &&
                data.cycle !== null
                  ? data.cycle
                  : ''
              }
              onChange={handleChange}
              maxLength={50}
            />
          </FormItem>
        </>
      ),
    },
    {
      key: 'dates',
      label: <TabTitle>Dates</TabTitle>,
      children: (
        <Row>
          <Col span={12}>
            <FormItem
              label="Is Past End-of-Support"
              colon={false}
              style={styles.formItem}
            >
              <Switch
                checked={
                  typeof data !== 'undefined' &&
                  data !== null &&
                  typeof data.isPastEndOfSupport !== 'undefined' &&
                  data.isPastEndOfSupport !== null
                    ? data.isPastEndOfSupport
                    : false
                }
                onChange={(checked: boolean) =>
                  setData({ ...data, isPastEndOfSupport: checked })
                }
              />
            </FormItem>
            <FormItem
              label="End-of-Support Date"
              colon={false}
              style={styles.formItem}
            >
              <DatePicker
                name="endOfSupportDate"
                // @ts-ignore
                value={ParseStringDate(
                  typeof data !== 'undefined' &&
                    data !== null &&
                    typeof data.endOfSupportDate !== 'undefined'
                    ? data.endOfSupportDate
                    : '',
                )}
                format={'YYYY-MM-DD'}
                onChange={(date) => handleDateChange(date, 'endOfSupportDate')}
              />
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem
              label="Is Past End-of-Life"
              colon={false}
              style={styles.formItem}
            >
              <Switch
                checked={
                  typeof data !== 'undefined' &&
                  data !== null &&
                  typeof data.isPastEndOfLife !== 'undefined' &&
                  data.isPastEndOfLife !== null
                    ? data.isPastEndOfLife
                    : false
                }
                onChange={(checked: boolean) =>
                  setData({ ...data, isPastEndOfLife: checked })
                }
              />
            </FormItem>
            <FormItem
              label="End-of-Life Date"
              colon={false}
              style={styles.formItem}
            >
              <DatePicker
                name="endOfLifeDate"
                // @ts-ignore
                value={ParseStringDate(
                  typeof data !== 'undefined' &&
                    data !== null &&
                    typeof data.endOfLifeDate !== 'undefined'
                    ? data.endOfLifeDate
                    : '',
                )}
                format={'YYYY-MM-DD'}
                onChange={(date) => handleDateChange(date, 'endOfLifeDate')}
              />
            </FormItem>
          </Col>
        </Row>
      ),
    },
    {
      key: 'aliases',
      label: <TabTitle>Aliases</TabTitle>,
      children: (
        <>
          <FormItem label="Alias" colon={false} style={styles.formItem}>
            <Row>
              <Col span={20}>
                <Input
                  name="alias"
                  type="text"
                  value={aliasInput}
                  onChange={({
                    target: { value },
                  }: React.ChangeEvent<
                    HTMLInputElement | HTMLTextAreaElement
                  >) => {
                    setAliasInput(value);
                  }}
                  maxLength={50}
                />
              </Col>
              <Col span={4}>
                <Button
                  type="primary"
                  icon={<PlusCircleOutlined />}
                  shape="circle"
                  onClick={() => addAlias()}
                  style={styles.iconButton}
                />
              </Col>
            </Row>
          </FormItem>
          <br />
          {data.aliases.map((alias) => (
            <span key={`${alias.alias}`}>
              <CloseCircleOutlined
                title="Remove Device"
                onClick={() => handleRemoveAlias(alias.alias)}
                style={styles.removeIcon}
              />
              &nbsp;
              {alias.alias}
              <br />
            </span>
          ))}
        </>
      ),
    },
  ];

  return (
    <Modal
      open={visible}
      onCancel={() => onAction('closeEdit_NoRefresh', null)}
      title={<ModalTitle>Product End-of-Life</ModalTitle>}
      confirmLoading={false}
      width={'40%'}
      footer={
        <ModalFooter>
          <Button onClick={() => onAction('closeEdit_NoRefresh', null)}>
            Close
          </Button>
          <Button
            type="primary"
            onClick={handleSubmit}
            disabled={!isFormValid()}
          >
            Save
          </Button>
        </ModalFooter>
      }
    >
      <Form layout="vertical" style={styles.form}>
        <Tabs defaultActiveKey="description" items={tabItems} />
      </Form>
    </Modal>
  );
};

export default EditModal;
