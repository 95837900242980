import React from 'react';

import CveCardTitle from '@totem/components/software/cves/cveCardTitle';
import CveReferencesTable from '@totem/components/software/cves/CveReferencesTable';
import { CVERecord } from '@totem/components/software/cves/types';

type Props = {
  cve: CVERecord;
};

const CveReferences = ({ cve }: Props) => {
  return (
    <>
      <CveCardTitle>References</CveCardTitle>
      <CveReferencesTable cve={cve} />
    </>
  );
};

export default CveReferences;
