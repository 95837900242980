import React, { useEffect } from 'react';
import { Checkbox, Input, Menu } from 'antd';

import {
  containsValue,
  FilterAutoCompleteProps,
  FilterOption,
  FilterOptions,
} from '@totem/components/common/filterAutoComplete/FilterAutoCompleteTypes';
const { Search } = Input;
const MenuItem = Menu.Item;
import DetailSpinner from '@totem/components/common/DetailSpinner';

import './filterAutoComplete.css';

type Props = {
  label: string;
  style?: React.CSSProperties;
  visible: boolean;
  onClose: () => void;
} & FilterAutoCompleteProps;

const styles = {
  menu: {
    width: '24rem',
  },
  menuItem: {
    whiteSpace: 'normal' as any,
  },
  checkbox: {
    marginRight: '1rem',
  },
  input: {
    width: '100%',
  },
};

const FilterAutoComplete = ({
  label,
  visible,
  foundOptions,
  selected,
  loading,
  onSelect,
  search,
  onApply,
  onClose,
  onReset,
  onSearch,
}: Props) => {
  const filterOptionKeys = (filterOptions: FilterOptions) => {
    return filterOptions.map((filterOption) => filterOption.value);
  };
  const numberSelected = selected.length;
  const previouslySelected = (): FilterOption[] => {
    if (selected !== null) {
      return selected.filter(
        (option) => !containsValue(foundOptions, option.value),
      );
    }
    return [];
  };
  const numberPreviouslySelected = previouslySelected().length;
  const numberOptions = foundOptions.length;

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    onSearch(event.target.value);
  };

  const handleOk = () => {
    if (loading) {
      return;
    }

    onClose();
    onApply();
  };

  const handleReset = () => {
    if (loading) {
      return;
    }

    onClose();
    onReset();
  };

  useEffect(() => {
    if (!visible) {
      onApply();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return (
    <Menu
      multiple
      // use antd internal dropdown menu styles
      prefixCls="ant-dropdown-menu"
      className="ant-dropdown-menu-without-submenu"
      style={styles.menu}
      selectedKeys={filterOptionKeys(selected)}
    >
      <div styleName="number-selected-container">
        {`${numberSelected} Selected`}
      </div>
      <div styleName="input-container">
        <Search
          value={search}
          onChange={handleSearch}
          style={styles.input}
          disabled={loading}
        />
      </div>
      {loading ? (
        <div styleName="spinner-container">
          <DetailSpinner size={40} />
        </div>
      ) : (
        previouslySelected().map((filterOption) => (
          <MenuItem key={filterOption.value} style={styles.menuItem}>
            <div styleName="menu-item" onClick={() => onSelect(filterOption)}>
              <Checkbox disabled={loading} style={styles.checkbox} checked />
              <div styleName="menu-item-text">{filterOption.name}</div>
            </div>
          </MenuItem>
        ))
      )}
      {Boolean(numberPreviouslySelected) && <div styleName="divider" />}
      {foundOptions.map((filterOption) => (
        <MenuItem key={filterOption.value} style={styles.menuItem}>
          <div styleName="menu-item" onClick={() => onSelect(filterOption)}>
            <Checkbox
              style={styles.checkbox}
              checked={containsValue(selected, filterOption.value)}
            />
            <div styleName="menu-item-text">{filterOption.name}</div>
          </div>
        </MenuItem>
      ))}
      {!Boolean(numberOptions) && !loading && (
        <div styleName="dropdown-text">
          {search
            ? `Nothing was found for "${search}". Try searching something else.`
            : `Search ${label}`}
        </div>
      )}
      <div styleName="buttons">
        <a onClick={handleReset}>Reset</a>
        <a styleName="ok-button" onClick={handleOk}>
          OK
        </a>
      </div>
    </Menu>
  );
};

export default FilterAutoComplete;
