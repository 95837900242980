import React, { useContext, useEffect, useState } from 'react';
import { Flex } from 'antd';

import Count from '@totem/components/common/Count';
import SurveyContext from '@totem/components/surveyV2/instanceDetail/SurveyInstanceContext';
import colors from '@totem/styles/colors';
import { LevelOfEffort } from '@totem/types/levelOfEffort';
import { isNotNull } from '@totem/utilities/common';
import { getExactPercentage } from '@totem/utilities/numberUtilities';

import './auditReport.css';

const INCLUDED_LEVELS_OF_EFFORT = [
  LevelOfEffort.Minimal,
  LevelOfEffort.Moderate,
  LevelOfEffort.Maximum,
];

const styles = {
  fullWidthCentered: {
    width: '100%',
    alignItems: 'center',
  },
  findingHeaderContainer: {
    marginBottom: '16px',
  },
};

const FindingsByLevelOfEffort = () => {
  const { data } = useContext(SurveyContext);
  const [loeCount, setLoeCount] = useState<number[]>([0, 0, 0, 0]);

  useEffect(() => {
    const loeGrouping: number[] = [0, 0, 0, 0];

    if (isNotNull(data)) {
      for (let cIdx = 0; cIdx < data.survey.categories.length; cIdx++) {
        const category = data.survey.categories[cIdx];
        if (category.visible) {
          const questionsWithFindings = category.questions.filter(
            (chk) =>
              (chk.type === 'enumsingle' || chk.type === 'scored') &&
              chk.policy !== '' &&
              chk.visible,
          );

          // eslint-disable-next-line max-depth
          for (let qIdx = 0; qIdx < questionsWithFindings.length; qIdx++) {
            const question = questionsWithFindings[qIdx];
            const optionIdx = question.options.findIndex(
              (chk) => chk.label === question.value.value,
            );
            // eslint-disable-next-line max-depth
            if (optionIdx >= 0) {
              const criticality = question.options[optionIdx].criticality;
              // eslint-disable-next-line max-depth
              if (criticality > 0 && criticality < 5) {
                const levelOfEffort = question.levelOfEffort;
                // eslint-disable-next-line max-depth
                if (levelOfEffort > 0) {
                  loeGrouping[levelOfEffort] += 1;
                  // eslint-disable-next-line max-depth
                  if (levelOfEffort > 0) {
                    loeGrouping[0] += 1;
                  }
                }
              }
            }
          }
        }
      }
    }

    setLoeCount(loeGrouping);
  }, [data]);

  return (
    <div>
      <Flex
        justify={'space-between'}
        align={'flex-start'}
        style={styles.findingHeaderContainer}
      >
        <div styleName="finding-header">Findings By Level Of Effort</div>
      </Flex>
      <div>
        {INCLUDED_LEVELS_OF_EFFORT.map((loe) => {
          const name = LevelOfEffort[loe];

          const percentage = getExactPercentage(loeCount[0], loeCount[loe]);

          return (
            <Flex justify={'flex-start'} align={'flex-start'} key={name}>
              <div styleName="finding-label level-of-effort">{name}</div>
              <Flex style={styles.fullWidthCentered}>
                <div styleName="finding-bar">
                  <div
                    style={{
                      width: `${percentage}%`,
                      background: colors.neutral.gray,
                      height: '100%',
                    }}
                  />
                </div>
              </Flex>
              <Count count={loeCount[loe]} size="small" />
            </Flex>
          );
        })}
      </div>
    </div>
  );
};

export default FindingsByLevelOfEffort;
