import React, { useContext, useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import dayjs from 'dayjs';

import DeviceSoftwareEndOfSupportContext from '@totem/components/devices/softwareEndOfLife/summary/DeviceSoftwareEndOfSupportContext';
import { isNotNull } from '@totem/utilities/common';

type Props = {
  chartOptions?: ApexCharts.ApexOptions;
};

const EndOfSupportChart = ({ chartOptions }: Props) => {
  const { data } = useContext(DeviceSoftwareEndOfSupportContext);
  const [categoryData, setCategoryData] = useState<string[]>([]);
  const [seriesData, setSeriesData] = useState<number[]>([]);

  useEffect(() => {
    const categories = ['', '', '', '', '', '', '', '', '', '', '', '', ''];
    const series = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

    for (let idx = 0; idx < categories.length; idx++) {
      categories[idx] = dayjs().add(idx, 'month').format('MMM YYYY');
    }

    if (isNotNull(data)) {
      if (isNotNull(data.futureEndOfSupport)) {
        for (let dIdx = 0; dIdx < data.futureEndOfSupport.length; dIdx++) {
          const cat = dayjs(
            data.futureEndOfSupport[dIdx].softwareEndOfSupportDate,
          ).format('MMM YYYY');
          const catIdx = categories.findIndex((chk) => chk === cat);
          // eslint-disable-next-line max-depth
          if (catIdx >= 0) {
            series[catIdx] = series[catIdx] + 1;
          }
        }
      }
    }

    setCategoryData(categories);
    setSeriesData(series);
  }, [data]);

  const options: ApexCharts.ApexOptions = {
    chart: {
      type: 'bar',
      stacked: true,
      toolbar: {
        show: true,
        tools: {
          download: true,
          selection: false,
          pan: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
        },
      },
      zoom: { enabled: true },
      selection: { enabled: true },
    },
    legend: { show: false },
    series: [{ name: 'End of Support', data: seriesData }],
    colors: [
      '#B0E0E6',
      '#ADD8E6',
      '#87CEFA',
      '#87CEEB',
      '#00BFFF',
      '#B0C4DE',
      '#1E90FF',
      '#6495ED',
      '#5F9EA0',
      '#4169E1',
    ],
    plotOptions: {
      bar: {
        columnWidth: '90%',
      },
    },
    xaxis: {
      categories: categoryData,
      axisTicks: {
        show: true,
      },
    },
    yaxis: [
      {
        axisTicks: {
          show: true,
        },
        tooltip: {
          enabled: true,
        },
      },
    ],
    tooltip: {
      fixed: {
        enabled: true,
        position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
        offsetY: 30,
        offsetX: 60,
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
  };

  const getChartOptions = () => {
    if (typeof chartOptions !== 'undefined' && chartOptions !== null) {
      return { ...options, ...chartOptions };
    }
    return options;
  };

  return (
    <div className="app">
      <div className="row">
        <div className="mixed-chart">
          <ReactApexChart
            options={getChartOptions()}
            series={[{ name: 'End of Support', data: seriesData }]}
            type="bar"
            width="100%"
            height="180px"
          />
        </div>
      </div>
    </div>
  );
};

export default EndOfSupportChart;
