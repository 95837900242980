import React, { useCallback, useState } from 'react';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Button, notification } from 'antd';
import Modal from 'antd/lib/modal/Modal';

import ModalFooter from '@totem/components/ModalFooter';
import ModalTitle from '@totem/components/ModalTitle';
import { User } from '@totem/components/users/container/types';
import colors from '@totem/styles/colors';
import { getToken } from '@totem/utilities/accountUtilities';
import { USERS_ENDPOINT } from '@totem/utilities/endpoints';

const styles = {
  button: {
    background: colors.utility.error,
    color: colors.neutral.white,
  },
};

interface Props {
  user: User;
  open: boolean;
  onClose: () => void;
}

const UserActivateModal = ({ open, onClose, user }: Props) => {
  const [isSending, setIsSending] = useState(false);

  const sendActivateUser = useCallback(
    async (userId: string) => {
      if (isSending) {
        return;
      }

      setIsSending(true);

      fetch(`${USERS_ENDPOINT}/${userId}/activate`, {
        method: 'PATCH',
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getToken()}`,
        }),
      })
        .then(res => {
          if (res.status < 400) {
            onClose();
          } else {
            notification.error({
              message: 'Error Granting User Access!',
              description:
                'An error occurred while attempting to grant user access.',
              duration: 0,
            });
          }
        })
        .then(() => {
          setIsSending(false);
        });
    },
    [isSending],
  );

  const handleActivateUser = () => {
    sendActivateUser(user.id);
  };

  return (
    <Modal
      title={
        <ModalTitle>
          <QuestionCircleOutlined />${'  '}
          Grant Site Access?
        </ModalTitle>
      }
      onCancel={onClose}
      open={open}
      footer={
        <ModalFooter>
          <Button onClick={onClose}>No</Button>
          <Button
            style={styles.button}
            loading={false}
            onClick={handleActivateUser}
          >
            Yes, Grant
          </Button>
        </ModalFooter>
      }
    >
      <div>Are you sure you want to grant site access to {user.email}?</div>
    </Modal>
  );
};

export default UserActivateModal;
