import React from 'react';
import { Input } from 'antd';

import { SurveyQuestion } from '@totem/components/surveyV2/types';

const { TextArea } = Input;

import '../survey.css';

const styles = {
  required: {
    color: '#FF0000',
  },
};

type Props = {
  question: SurveyQuestion;
  disabled: boolean;
  value: string;
  onChange: (value: string) => void;
  onBlur: () => void;
};

const AdditionalContext = ({
  question,
  disabled,
  onChange,
  value,
  onBlur,
}: Props) => {
  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = event.target.value;
    onChange(newValue);
  };

  const handleBlur = () => {
    if (question.additionalContext !== value) {
      onBlur();
    }
  };

  const getLabel = () => {
    if (question.value.value !== '') {
      const selectedOption = question.options.find(
        (chk) => chk.label === question.value.value,
      );
      if (
        typeof selectedOption !== 'undefined' &&
        selectedOption.moreInfoLabel !== ''
      ) {
        return (
          <span style={styles.required}>{selectedOption.moreInfoLabel}</span>
        );
      }
    }

    if (question.requiresAdditionalContext) {
      return <span style={styles.required}>Provide further context</span>;
    }

    return <span>Provide further context</span>;
  };

  return (
    <div styleName="additional-context-container">
      <div styleName="additional-context-label">{getLabel()}</div>
      <TextArea
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={disabled}
        spellCheck
        autoSize={{ minRows: 6 }}
      />
    </div>
  );
};

export default AdditionalContext;
