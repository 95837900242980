import { sortStringAscending } from '@totem/components/events/eventsContainer/utilities';
import { Filter } from '@totem/types/common';
import {
  DEVICE_COLOR_CRITICAL,
  DEVICE_COLOR_INFORMATION,
  DEVICE_COLOR_MAJOR,
  DEVICE_COLOR_MINOR,
  DEVICE_COLOR_OK,
  DEVICE_COLOR_UNKNOWN,
  DEVICE_COLOR_WARNING,
  DeviceFilterOptions,
} from '@totem/types/devices';

export const getSeverityColor = (severity: number) => {
  switch (severity) {
    case 5:
      return DEVICE_COLOR_CRITICAL;
    case 4:
      return DEVICE_COLOR_MAJOR;
    case 3:
      return DEVICE_COLOR_MINOR;
    case 2:
      return DEVICE_COLOR_WARNING;
    case 1:
      return DEVICE_COLOR_INFORMATION;
    case 0:
      return DEVICE_COLOR_OK;
    default:
      return DEVICE_COLOR_UNKNOWN;
  }
};

export const getSeverityFilter = () => {
  return [
    {
      text: 'Critical',
      value: '5',
    },
    {
      text: 'Major',
      value: '4',
    },
    {
      text: 'Minor',
      value: '3',
    },
    {
      text: 'Warning',
      value: '2',
    },
    {
      text: 'Information',
      value: '1',
    },
    {
      text: 'OK',
      value: '0',
    },
    {
      text: 'Unknown',
      value: '-1',
    },
  ];
};

export const getRegionFilter = (filterOptions: DeviceFilterOptions) => {
  if (filterOptions === null) {
    return [];
  }

  return filterOptions.region
    .sort((compA, compB) => sortStringAscending(compA.name, compB.name))
    .map((dev) => {
      return {
        text: dev.name,
        value: dev.value,
      };
    });
};

export const getBuildingFilter = (filterOptions: DeviceFilterOptions) => {
  if (filterOptions === null) {
    return [];
  }

  return filterOptions.building
    .sort((compA, compB) => sortStringAscending(compA.name, compB.name))
    .map((dev) => {
      return {
        text: dev.name,
        value: dev.value,
      };
    });
};

export const getTypeFilter = (filterOptions: DeviceFilterOptions) => {
  if (filterOptions === null) {
    return [];
  }

  return filterOptions.type
    .sort((compA, compB) => sortStringAscending(compA.name, compB.name))
    .map((sts) => {
      return {
        text: sts.name,
        value: sts.value,
      };
    });
};

export const getVendorFilterAdDropDownFilterOptions = (
  filterOptions: DeviceFilterOptions,
) => {
  if (filterOptions === null) {
    return [];
  }

  return filterOptions.vendor
    .sort((compA, compB) => sortStringAscending(compA.name, compB.name))
    .map((sts) => {
      return {
        label: sts.name,
        value: sts.value,
      };
    });
};

export const getVendorFilter = (filterOptions: DeviceFilterOptions) => {
  if (filterOptions === null) {
    return [];
  }

  return filterOptions.vendor
    .sort((compA, compB) => sortStringAscending(compA.name, compB.name))
    .map((sts) => {
      return {
        text: sts.name,
        value: sts.value,
      };
    });
};

export const getOperatingSystemFilter = (
  filterOptions: DeviceFilterOptions,
) => {
  if (filterOptions === null) {
    return [];
  }

  return filterOptions.operatingSystem
    .sort((compA, compB) => sortStringAscending(compA.name, compB.name))
    .map((sts) => {
      return {
        text: sts.name,
        value: sts.value,
      };
    });
};

export const buildFilter = (filters) => {
  const paramFilters: Filter[] = [];

  if (filters.operatingSystem !== null) {
    paramFilters.push({
      field: 'operatingSystem',
      values: filters.operatingSystem,
    });
  }
  if (filters.vendor !== null) {
    paramFilters.push({
      field: 'vendor',
      values: filters.vendor,
    });
  }
  if (filters.type !== null) {
    paramFilters.push({
      field: 'type',
      values: filters.type,
    });
  }
  if (filters.regionId !== null) {
    paramFilters.push({
      field: 'regionId',
      values: filters.regionId,
    });
  }
  if (filters.buildingId !== null) {
    paramFilters.push({
      field: 'buildingId',
      values: filters.buildingId,
    });
  }

  return paramFilters;
};
