import React from 'react';
import { Flex, Layout } from 'antd';
const { Header, Footer, Sider, Content } = Layout;
import LeftNav from '@totem/components/remoteAccessServices/components/public/LeftNav';

import backgroundImage from './components/public/building_background.png';
import PageHeader from './components/public/Header';
import image from './components/public/intellinet_ib.svg';
import PageFooter from './components/public/PageFooter';
import RequestPasswordReset from './components/public/RequestPasswordReset';

import './components/public/ras.css';

const layoutStyle = {
  height: '100vh',
};
const headerStyle = {
  textAlign: 'center',
  color: '#000000',
  height: 90,
  paddingInline: 50,
  lineHeight: '64px',
  backgroundColor: '#ffffff',
  borderBottomColor: '#CCCCCC',
  borderBottomStyle: 'solid',
};

const contentStyle = {
  textAlign: 'center',
  height: '100%',
  minHeight: 120,
  color: '#000000',
  backgroundColor: '#ffffff',
  paddingTop: '20px',
  paddingLeft: '16px',
  paddingRight: '16px',
};

const contentStyleDesktop = {
  textAlign: 'center',
  height: '100%',
  minHeight: 120,
  color: '#000000',
  backgroundImage: `url(${backgroundImage})`,
  backgroundSize: '100%',
  backgroundRepeat: 'no-repeat',
  backgroundColor: '#ffffff',
  paddingTop: '20px',
};

const footerStyle = {
  textAlign: 'center',
  color: '#000',
  backgroundColor: '#fff',
  borderTopColor: '#CCCCCC',
  borderTopStyle: 'solid',
};

const imageStyle = {
  maxWidth: '600px',
  width: '90%',
  marginTop: '24px',
  marginLeft: '24px',
  marginRight: '24px',
};

const siderStyle = {
  height: '100vh',
  textAlign: 'center',
  lineHeight: '120px',
  color: '#000000',
  borderRightColor: '#CCCCCC',
  borderRightStyle: 'solid',
  backgroundColor: '#ffffff',
};

const entryBox = {
  backgroundColor: 'rgb(255, 255, 255, .65)',
  borderColor: '#CCCCCC',
  borderStyle: 'solid',
  borderWidth: '2px',
  padding: '24px',
};

function RemoteAccessServicesRoot() {
  return (
    <>
      <div styleName={'displayMobile'}>
        <Layout style={layoutStyle}>
          <Header style={headerStyle}>
            <PageHeader />
          </Header>
          <Content style={contentStyle}>
            <RequestPasswordReset />
            <img src={image} style={imageStyle} />
          </Content>
          <Footer style={footerStyle}>
            <PageFooter />
          </Footer>
        </Layout>
      </div>
      <div styleName={'displayDesktop'}>
        <Layout style={layoutStyle}>
          <Layout>
            <Header style={headerStyle}>
              <Flex justify={'space-between'}>
                <LeftNav />
                <PageHeader />
                <div>&nbsp;</div>
              </Flex>
            </Header>
            <Content style={contentStyleDesktop}>
              <Flex justify={'center'} align={'flex-start'}>
                <div style={entryBox}>
                  <RequestPasswordReset />
                </div>
              </Flex>
            </Content>
            <Footer style={footerStyle}>
              <PageFooter />
            </Footer>
          </Layout>
        </Layout>
      </div>
    </>
  );
}

export default RemoteAccessServicesRoot;
