import * as React from 'react';
import { useContext, useState } from 'react';
import { Card } from 'antd';
import * as R from 'ramda';

import TreeRow from '@totem/components/surveyV2/instanceDetail/score/TreeRow';
import SurveyContext from '@totem/components/surveyV2/instanceDetail/SurveyInstanceContext';

import './score.css';

const styles = {
  card: {
    width: '40rem',
  },
};

type Props = {
  selectedId: string;
  onRowSelect: (arg1: string, arg2: string) => void;
};

const EvaluationAreas = ({ selectedId, onRowSelect }: Props) => {
  const { data } = useContext(SurveyContext);
  const { survey } = data;
  const [expandedIds, setExpandedIds] = useState<Set<string>>(new Set());

  const onRowExpand = (id: string) => {
    const expandedItemsSet = new Set([...expandedIds]);

    if (expandedItemsSet.has(id)) {
      expandedItemsSet.delete(id);
      const expandedItemsWithRemovals = new Set(
        [...expandedItemsSet].filter(
          (item: string): boolean => !item.startsWith(id),
        ),
      );
      // @ts-ignore
      setExpandedIds(expandedItemsWithRemovals);
      return;
    }
    expandedItemsSet.add(id);
    // @ts-ignore
    setExpandedIds(expandedItemsSet);
  };

  return (
    <Card
      style={styles.card}
      bordered={false}
      title={<div>Evaluation Areas</div>}
    >
      <Card.Meta
        style={{ width: '100%' }}
        description={
          <div>
            <div styleName="results-evaluation-subtext">
              Select the results area below to review its report and responses.
            </div>
            <div styleName="tree-container">
              <TreeRow
                id="summary"
                label="Summary"
                type="summary"
                indentation={0}
                selectedId={selectedId}
                // @ts-ignore
                expandedIds={expandedIds}
                onSelect={onRowSelect}
                // @ts-ignore
                onExpand={onRowExpand}
                showExpandIcon={!R.isEmpty(survey.categories)}
              />
              {expandedIds.has('summary') &&
                survey.categories.map((category: any) => {
                  return (
                    <React.Fragment key={category.id}>
                      <TreeRow
                        id={category.id}
                        label={category.name}
                        type="category"
                        indentation={1}
                        selectedId={selectedId}
                        // @ts-ignore
                        expandedIds={expandedIds}
                        onSelect={onRowSelect}
                        // @ts-ignore
                        onExpand={onRowExpand}
                        showExpandIcon={category.multiInstance}
                      />
                      {expandedIds.has(category.id) &&
                        category.multiInstanceScores.map(
                          (categoryInstanceScore: any, index: number) => (
                            <TreeRow
                              key={`${categoryInstanceScore.instance}-${categoryInstanceScore.displayName}-${index}`}
                              id={`${categoryInstanceScore.instance}-${categoryInstanceScore.displayName}`}
                              label={categoryInstanceScore.displayName}
                              type="category-instance"
                              indentation={2}
                              selectedId={selectedId}
                              // @ts-ignore
                              expandedIds={expandedIds}
                              onSelect={onRowSelect}
                            />
                          ),
                        )}
                    </React.Fragment>
                  );
                })}
              {!R.isEmpty(survey.levelTypes) &&
                survey.levelTypes.map((levelType: any) => {
                  const levels = survey.levels.filter(
                    (level: any): boolean => level.type === levelType.type,
                  );
                  const hasLevels = !R.isEmpty(levels);
                  return (
                    <React.Fragment
                      key={`${levelType.typeName}-${levelType.displayName}`}
                    >
                      <TreeRow
                        key={`${levelType.typeName}-${levelType.displayName}`}
                        id={`${levelType.typeName}-${levelType.displayName}`}
                        label={levelType.displayName}
                        type="level-type"
                        indentation={0}
                        selectedId={selectedId}
                        // @ts-ignore
                        expandedIds={expandedIds}
                        onSelect={onRowSelect}
                        // @ts-ignore
                        onExpand={onRowExpand}
                        showExpandIcon={hasLevels}
                      />
                      {expandedIds.has(
                        `${levelType.typeName}-${levelType.displayName}`,
                      ) &&
                        levels.map((level: any) => {
                          return (
                            <TreeRow
                              key={level.id}
                              id={level.id}
                              label={level.name}
                              type="level"
                              indentation={1}
                              selectedId={selectedId}
                              // @ts-ignore
                              expandedIds={expandedIds}
                              onSelect={onRowSelect}
                              showExpandIcon={false}
                            />
                          );
                        })}
                    </React.Fragment>
                  );
                })}
            </div>
          </div>
        }
      />
    </Card>
  );
};

export default EvaluationAreas;
