import React, { useContext } from 'react';

import DropdownFilter from '@totem/components/common/dropdownFilter/DropdownFilter';
import DeviceContext from '@totem/components/devices/devicesContainer/deviceContainerContext';
import DevicesFilterChips from '@totem/components/devices/devicesContainer/DevicesFilterChips';
import FiltersChipsContainer from '@totem/components/FilterChipsContainer';
import FiltersBody from '@totem/components/FiltersBody';
import FiltersContainer from '@totem/components/FiltersContainer';
import FiltersTitle from '@totem/components/FiltersTitle';
import { DevicesConnectionInput } from '@totem/types/devices';
import { isNotNull } from '@totem/utilities/common';

import '../devices.css';

const styles = {
  dropdown: {
    marginRight: '2rem',
  },
};

type Props = {
  title?: string;
};

const DeviceFilters = ({ title }: Props) => {
  const { input, setInput, filterOptions } = useContext(DeviceContext);

  const handleMultiStringFilterChange = (
    property: keyof DevicesConnectionInput,
    update: string[],
  ) => {
    setInput({
      ...input,
      [property]: update.map(String),
    });
  };

  const getFilterOptions = (name: string) => {
    if (isNotNull(filterOptions)) {
      switch (name) {
        case 'type':
          return filterOptions.type.map((option) => ({
            label: option.name,
            value: option.value,
          }));
        case 'vendor':
          return filterOptions.vendor.map((option) => ({
            label: option.name,
            value: option.value,
          }));
        case 'regionId':
          return filterOptions.region.map((option) => ({
            label: option.name,
            value: option.value,
          }));
        case 'buildingId':
          return filterOptions.building.map((option) => ({
            label: option.name,
            value: option.value,
          }));
        default:
          return [];
      }
    }
    return [];
  };

  return (
    <FiltersContainer>
      <FiltersTitle>
        {typeof title !== 'undefined' && title !== null && title !== ''
          ? title
          : 'Device Management'}
      </FiltersTitle>
      <FiltersBody>
        <DropdownFilter
          label="Type"
          options={getFilterOptions('type')}
          value={input.type || []}
          onChange={(update) => handleMultiStringFilterChange('type', update)}
          style={styles.dropdown}
        />
        <DropdownFilter
          label="Vendor"
          options={getFilterOptions('vendor')}
          value={input.vendor || []}
          onChange={(update) => handleMultiStringFilterChange('vendor', update)}
          style={styles.dropdown}
        />
        <DropdownFilter
          label="Region"
          options={getFilterOptions('regionId')}
          value={input.regionId || []}
          onChange={(update) =>
            handleMultiStringFilterChange('regionId', update)
          }
          style={styles.dropdown}
        />
        <DropdownFilter
          label="Building"
          options={getFilterOptions('buildingId')}
          value={input.buildingId || []}
          onChange={(update) =>
            handleMultiStringFilterChange('buildingId', update)
          }
          style={styles.dropdown}
        />
      </FiltersBody>
      <FiltersChipsContainer>
        <DevicesFilterChips />
      </FiltersChipsContainer>
    </FiltersContainer>
  );
};

export default DeviceFilters;
