import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Card, Input } from 'antd';

import FormFieldLabel from '@totem/components/FormFieldLabel';
import SurveyTemplateContext from '@totem/components/surveyV2/templateDetails/SurveyTemplateContext';
import { LevelType, SurveyTemplate } from '@totem/components/surveyV2/types';
import { getToken } from '@totem/utilities/accountUtilities';
import { debounce } from '@totem/utilities/debounce';
import { V2_SURVEY_ENDPOINT } from '@totem/utilities/endpoints';

const DEBOUNCE_TIME = 750;

const getLevel = (levels: LevelType[], level: number): LevelType => {
  for (let idx = 0; idx < levels.length; idx++) {
    if (levels[idx].type === level) {
      return levels[idx];
    }
  }

  return {
    type: level,
    typeName: `Level ${level}`,
    displayName: `Level ${level}`,
    defaultScoreBy: 'questions',
  };
};

const SurveyTemplateLevelTypesPane = () => {
  const { surveyTemplateId, data, setData, setIsLoading } = useContext(
    SurveyTemplateContext,
  );
  const [level1Updated, setLevel1Updated] = useState<boolean>(false);
  const [level2Updated, setLevel2Updated] = useState<boolean>(false);
  const [level3Updated, setLevel3Updated] = useState<boolean>(false);
  const [localLevel1, setLocalLevel1] = useState<LevelType>(
    getLevel(data.levelTypes, 1),
  );
  const [localLevel2, setLocalLevel2] = useState<LevelType>(
    getLevel(data.levelTypes, 2),
  );
  const [localLevel3, setLocalLevel3] = useState<LevelType>(
    getLevel(data.levelTypes, 3),
  );

  const sendLevelTypeUpdate = useCallback(
    async (templateId: string, levelType: LevelType) => {
      const payload = {
        type: levelType.type,
        displayName: levelType.displayName,
      };

      fetch(`${V2_SURVEY_ENDPOINT}/template/${templateId}/levelType`, {
        method: 'POST',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
        body: JSON.stringify(payload),
      })
        .then((res) => res.json())
        .then((result: SurveyTemplate) => {
          setData(result);
        })
        .then(() => {
          setIsLoading(false);
        });
    },
    [],
  );

  useEffect(() => {
    if (level1Updated) {
      setLevel1Updated(false);
      debounce(() => {
        sendLevelTypeUpdate(surveyTemplateId, localLevel1);
      }, DEBOUNCE_TIME);
    }
  }, [localLevel1]);

  useEffect(() => {
    if (level2Updated) {
      setLevel2Updated(false);
      debounce(() => {
        sendLevelTypeUpdate(surveyTemplateId, localLevel2);
      }, DEBOUNCE_TIME);
    }
  }, [localLevel2]);

  useEffect(() => {
    if (level3Updated) {
      setLevel3Updated(false);
      debounce(() => {
        sendLevelTypeUpdate(surveyTemplateId, localLevel3);
      }, DEBOUNCE_TIME);
    }
  }, [localLevel3]);

  return (
    <Card>
      <FormFieldLabel>Level 1</FormFieldLabel>
      <Input
        placeholder="Level 1"
        value={localLevel1.displayName}
        onChange={({
          target: { value },
        }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
          setLocalLevel1({ ...localLevel1, displayName: value })
        }
      />

      <FormFieldLabel>Level 2</FormFieldLabel>
      <Input
        placeholder="Level 2"
        value={localLevel2.displayName}
        onChange={({
          target: { value },
        }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
          setLocalLevel2({ ...localLevel2, displayName: value })
        }
      />

      <FormFieldLabel>Level 3</FormFieldLabel>
      <Input
        placeholder="Level 3"
        value={localLevel3.displayName}
        onChange={({
          target: { value },
        }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
          setLocalLevel3({ ...localLevel3, displayName: value })
        }
      />
    </Card>
  );
};

export default SurveyTemplateLevelTypesPane;
