import { createContext } from 'react';

import {
  RemoteAccessRole,
  RemoteAccessSessions,
  RemoteAccessUserDetailsInput,
  RemoteAccessUserRoles,
} from '@totem/components/remoteAccess/types';

interface Context {
  userId: string;
  userEmail: string;
  input: RemoteAccessUserDetailsInput;
  setInput: (input: RemoteAccessUserDetailsInput) => void;
  roleData: RemoteAccessUserRoles;
  sessionData: RemoteAccessSessions;
  onAction?: (action: string, role: RemoteAccessRole) => void;
  onActionComplete?: (refresh: boolean) => void;
}

export default createContext<Context>({
  userId: '',
  userEmail: '',
  input: null,
  setInput: () => {},
  roleData: null,
  sessionData: null,
  onAction: null,
});
