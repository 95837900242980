import React, { ReactNode, useContext, useEffect, useState } from 'react';

import TitledJson from '@totem/components/common/TitledJson';
import LayoutContext from '@totem/components/devices/variables/presentation/layout/LayoutContext';
import SectionContext from '@totem/components/devices/variables/presentation/layout/layoutSection/SectionContext';
import PageContext from '@totem/components/devices/variables/presentation/layout/PageContext';
import {
  LayoutSection,
  LayoutSectionResponse,
  VariableDataResponseExtended,
} from '@totem/components/devices/variables/presentation/layout/types';
import { isNotNull } from '@totem/utilities/common';

type Props = {
  sectionName: string;
  children?: ReactNode;
};

const SectionContainer = ({ children, sectionName }: Props) => {
  const { isDebug } = useContext(PageContext);
  const { loading, layoutData, variableData, changeCount, filterOptions } =
    useContext(LayoutContext);
  const [sectionLayout, setSectionLayout] = useState<LayoutSection>();
  const [sectionData, setSectionData] = useState<LayoutSectionResponse>(null);
  const [localChangeCount, setLocalChangeCount] = useState<number>(changeCount);
  const [localData, setLocalData] = useState<VariableDataResponseExtended[]>(
    [],
  );

  useEffect(() => {
    const workingSectionLayout = layoutData.layout.sections.find(
      (chk) => chk.name === sectionName,
    );

    if (isNotNull(workingSectionLayout)) {
      setSectionLayout(workingSectionLayout);
    }

    const newSectionData =
      isNotNull(layoutData) && isNotNull(layoutData.sectionResponses)
        ? layoutData.sectionResponses.find(
            (section) => section.name === sectionName,
          )
        : null;
    if (typeof newSectionData !== 'undefined') {
      if (isNotNull(filterOptions) && isNotNull(workingSectionLayout)) {
        if (
          workingSectionLayout.filter.findIndex((chk) => chk === 'location') >=
            0 &&
          filterOptions.location.filterable &&
          filterOptions.location.filter.length > 0
        ) {
          newSectionData.variables = newSectionData.variables.filter(
            (chkVar) =>
              filterOptions.location.filter.findIndex(
                (chk) => chk === chkVar.metaData.location,
              ) >= 0,
          );
        }
      }
      setSectionData(newSectionData);
    }
  }, [sectionName, layoutData]);

  useEffect(() => {
    if (isNotNull(variableData) && isNotNull(sectionData)) {
      let newLocalData = [...localData];
      let localDataChanged = false;
      if (localChangeCount !== changeCount) {
        newLocalData = [];
        localDataChanged = true;
      }

      variableData.forEach((element) => {
        if (element.status === 'Fetched') {
          if (
            // Check to see if the variable belongs to this section.
            sectionData.variables.findIndex(
              (elem) => elem.variableId === element.id,
            ) >= 0
          ) {
            if (
              localChangeCount !== changeCount ||
              newLocalData.findIndex((elem) => elem.id === element.id) === -1
            ) {
              newLocalData.push(element);
              localDataChanged = true;
            }
          }
        }
      });

      if (localDataChanged) {
        setLocalData(newLocalData);
      }
      if (localChangeCount !== changeCount) {
        setLocalChangeCount(changeCount);
      }
    }
  }, [sectionData, variableData, localChangeCount]);

  return (
    <SectionContext.Provider
      value={{
        sectionLayout,
        sectionData,
        sectionVariableData: localData,
        loading,
        onAction: () => {},
      }}
    >
      {children}
      {isDebug && (
        <TitledJson title="Section sectionLayout:" value={sectionLayout} />
      )}
      {isDebug && (
        <TitledJson title="Section sectionData:" value={sectionData} />
      )}
      {isDebug && <TitledJson title="Section localData:" value={localData} />}
    </SectionContext.Provider>
  );
};

export default SectionContainer;
