export const getCvssRatingFromScore = (score: number) => {
  if (score >= 9.0) {
    return 'Critical';
  }
  if (score >= 7.0) {
    return 'High';
  }
  if (score >= 4.0) {
    return 'Medium';
  }
  if (score >= 0.1) {
    return 'Low';
  }
  return 'None';
};

export const getCvssColorFromScore = (score: number) => {
  if (score >= 9.0) {
    return '#7A151B';
  }
  if (score >= 7.0) {
    return '#E41716';
  }
  if (score >= 4.0) {
    return '#FF942A';
  }
  if (score >= 0.1) {
    return '#FFD73F';
  }
  return '#2AC672';
};

export const colorUnknown = '#BDCBD2';

export const getAttackVectorLongDescription = (value: string) => {
  switch (value) {
    case 'Network':
      return 'The vulnerable system is bound to the network stack and the set of possible attackers extends beyond the other options listed below, up to and including the entire Internet. Such a vulnerability is often termed “remotely exploitable” and can be thought of as an attack being exploitable at the protocol level one or more network hops away (e.g., across one or more routers). An example of a network attack is an attacker causing a denial of service (DoS) by sending a specially crafted TCP packet across a wide area network (e.g., CVE-2004-0230).';
    case 'Adjacent':
      return 'The vulnerable system is bound to a protocol stack, but the attack is limited at the protocol level to a logically adjacent topology. This can mean an attack must be launched from the same shared proximity (e.g., Bluetooth, NFC, or IEEE 802.11) or logical network (e.g., local IP subnet), or from within a secure or otherwise limited administrative domain (e.g., MPLS, secure VPN within an administrative network zone). One example of an Adjacent attack would be an ARP (IPv4) or neighbor discovery (IPv6) flood leading to a denial of service on the local LAN segment (e.g., CVE-2013-6014).';
    case 'Local':
      return 'The vulnerable system is not bound to the network stack and the attacker’s path is via read/write/execute capabilities. Either: the attacker exploits the vulnerability by accessing the target system locally (e.g., keyboard, console), or through terminal emulation (e.g., SSH); or the attacker relies on User Interaction by another person to perform actions required to exploit the vulnerability (e.g., using social engineering techniques to trick a legitimate user into opening a malicious document).';
    case 'Physical':
      return 'The attack requires the attacker to physically touch or manipulate the vulnerable system. Physical interaction may be brief (e.g., evil maid attack1) or persistent. An example of such an attack is a cold boot attack in which an attacker gains access to disk encryption keys after physically accessing the target system. Other examples include peripheral attacks via FireWire/USB Direct Memory Access (DMA).';
    default:
      return '';
  }
};

export const getAttackComplexityLogDescription = (value: string) => {
  switch (value) {
    case 'Low':
      return 'The attacker must take no measurable action to exploit the vulnerability. The attack requires no target-specific circumvention to exploit the vulnerability. An attacker can expect repeatable success against the vulnerable system.';
    case 'High':
      return 'The successful attack depends on the evasion or circumvention of security-enhancing techniques in place that would otherwise hinder the attack. These include: Evasion of exploit mitigation techniques. The attacker must have additional methods available to bypass security measures in place. For example, circumvention of address space randomization (ASLR) or data execution prevention (DEP) must be performed for the attack to be successful. Obtaining target-specific secrets. The attacker must gather some target-specific secret before the attack can be successful. A secret is any piece of information that cannot be obtained through any amount of reconnaissance. To obtain the secret the attacker must perform additional attacks or break otherwise secure measures (e.g. knowledge of a secret key may be needed to break a crypto channel). This operation must be performed for each attacked target.';
    default:
      return '';
  }
};

export const getGeneric = (value: string) => {
  switch (value) {
    case '':
      return '';
    default:
      return '';
  }
};
