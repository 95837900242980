import React, { useContext } from 'react';
import { Collapse, Flex } from 'antd';

import BasePane from '@totem/components/BasePane';
import ContentLayout from '@totem/components/ContentLayout';
import DeviceSoftwareEndOfLifeSummaryLayout from '@totem/components/devices/softwareEndOfLife/summary/DeviceSoftwareEndOfLifeSummaryLayout';
import DeviceSoftwareEndOfSupportContainer from '@totem/components/devices/softwareEndOfLife/summary/DeviceSoftwareEndOfSupportContainer';
import DeviceSoftwareEndOfSupportContext from '@totem/components/devices/softwareEndOfLife/summary/DeviceSoftwareEndOfSupportContext';
import FutureEndOfLifeReport from '@totem/components/devices/softwareEndOfLife/summary/FutureEndOfLifeReport';
import PastEndOfLifeReport from '@totem/components/devices/softwareEndOfLife/summary/PastEndOfLifeReport';
import RecordCountBadge from '@totem/components/RecordCountBadge';
import { isNotNull } from '@totem/utilities/common';

const DeviceSoftwareEndOfLifePage = () => {
  const { data } = useContext(DeviceSoftwareEndOfSupportContext);
  return (
    <ContentLayout>
      <BasePane>
        <DeviceSoftwareEndOfSupportContainer>
          <DeviceSoftwareEndOfLifeSummaryLayout />
          <Collapse
            items={[
              {
                key: '1',
                label: (
                  <Flex justify={'space-between'} align={'center'}>
                    <div>
                      <b>Past End-of-Support</b>
                    </div>
                    <RecordCountBadge
                      count={
                        isNotNull(data) && isNotNull(data.pastEndOfSupport)
                          ? data.pastEndOfSupport.length
                          : 0
                      }
                    />
                  </Flex>
                ),
                children: <PastEndOfLifeReport />,
              },
              {
                key: '2',
                label: (
                  <Flex justify={'space-between'} align={'center'}>
                    <div>
                      <b>Near End-of-Support</b>
                    </div>
                    <RecordCountBadge
                      count={
                        isNotNull(data) && isNotNull(data.futureEndOfSupport)
                          ? data.futureEndOfSupport.length
                          : 0
                      }
                    />
                  </Flex>
                ),
                children: <FutureEndOfLifeReport />,
              },
            ]}
            defaultActiveKey={['1', '2']}
          />
        </DeviceSoftwareEndOfSupportContainer>
      </BasePane>
    </ContentLayout>
  );
};

export default DeviceSoftwareEndOfLifePage;
