import React, { useContext } from 'react';
import { Card, Col, Flex, Row, Space } from 'antd';

import FindingsReportContext from '@totem/components/surveyV2/findingsReport/findingsReportContext';
import TopFiveList from '@totem/components/surveyV2/findingsReport/topFiveList';
import SubTitle from '@totem/components/surveyV2/SubTitle';
import colors from '@totem/styles/colors';
import IBDIcon from '@totem/styles/Icon';
import { isNotNull } from '@totem/utilities/common';

const styles = {
  headerIcon: {
    fontSize: '22px',
  },
  icon: {
    marginRight: '16px',
    marginTop: '3px',
  },
  leastCompliantCount: {
    background: colors.neutral.ash,
  },
  fullWidth: {
    width: '100%',
  },
  borderLeft: {
    borderLeftColor: colors.neutral.silver,
    borderLeftStyle: 'solid' as 'solid',
    borderLeftWidth: 1,
  },
  noBottomBorder: {
    borderBottomStyle: 'none' as 'none',
  },
  ninetyPercent: {
    width: '90%',
  },
};

const LeastCompliantByCategory = () => {
  const { data } = useContext(FindingsReportContext);

  return (
    <Card
      title={<SubTitle>Least Compliant by Category</SubTitle>}
      style={styles.noBottomBorder}
    >
      <Row>
        <Col span={8}>
          <Space direction={'vertical'} style={styles.fullWidth}>
            <SubTitle>
              <Flex justify={'flex-start'} align={'flex-start'}>
                <IBDIcon name={'User'} style={styles.icon} />
                <div>Assignee</div>
              </Flex>
            </SubTitle>
            {isNotNull(data) && (
              <TopFiveList
                data={data.leastComplianceByAssignee
                  .filter((chk) => chk.assignee !== '')
                  .map((obj) => {
                    return {
                      name: obj.assignee,
                      value: obj.nonCompliant,
                    };
                  })}
              />
            )}
          </Space>
        </Col>
        <Col span={8}>
          <Space
            direction={'vertical'}
            style={{ ...styles.fullWidth, ...styles.borderLeft }}
          >
            <SubTitle>
              <Flex justify={'flex-start'} align={'flex-start'}>
                <IBDIcon name={'Building'} style={styles.icon} />
                <div>Building</div>
              </Flex>
            </SubTitle>
            {isNotNull(data) && (
              <TopFiveList
                data={data.leastComplianceByBuilding.map((obj) => {
                  return {
                    name: obj.building,
                    value: obj.nonCompliant,
                  };
                })}
              />
            )}
          </Space>
        </Col>
        <Col span={8}>
          <Space
            direction={'vertical'}
            style={{ ...styles.fullWidth, ...styles.borderLeft }}
          >
            <SubTitle>
              <Flex justify={'flex-start'} align={'flex-start'}>
                <IBDIcon name={'Region'} style={styles.icon} />
                <div>Region</div>
              </Flex>
            </SubTitle>
            {isNotNull(data) && (
              <TopFiveList
                data={data.leastComplianceByRegion.map((obj) => {
                  return {
                    name: obj.region,
                    value: obj.nonCompliant,
                  };
                })}
              />
            )}
          </Space>
        </Col>
      </Row>
    </Card>
  );
};

export default LeastCompliantByCategory;
