import { Filter } from '@totem/types/common';
import { EventFilters, EventsConnectionInput } from '@totem/types/events';

export const sortStringAscending = (compA: string, compB: string) => {
  return compA === compB ? 0 : compA > compB ? 1 : -1;
};
export const sortStringDescending = (compA: string, compB: string) => {
  return compA === compB ? 0 : compA > compB ? -1 : 1;
};

export const sortNumberAscending = (compA: number, compB: number) => {
  return compA === compB ? 0 : compA > compB ? 1 : -1;
};
export const sortNumberDescending = (compA: number, compB: number) => {
  return compA === compB ? 0 : compA > compB ? -1 : 1;
};

export const buildEventFilters = (input: EventsConnectionInput) => {
  const paramFilters: Filter[] = [];

  if (typeof input !== 'undefined' && input !== null) {
    if (
      typeof input.regionId !== 'undefined' &&
      input.regionId !== null &&
      input.regionId.length > 0
    ) {
      paramFilters.push({
        field: 'regionId',
        values: input.regionId,
      });
    }
    if (
      typeof input.buildingId !== 'undefined' &&
      input.buildingId !== null &&
      input.buildingId.length > 0
    ) {
      paramFilters.push({
        field: 'buildingId',
        values: input.buildingId,
      });
    }
    if (
      typeof input.controlSystemId !== 'undefined' &&
      input.controlSystemId !== null &&
      input.controlSystemId.length > 0
    ) {
      paramFilters.push({
        field: 'controlSystemId',
        values: input.controlSystemId,
      });
    }
    if (
      typeof input.deviceId !== 'undefined' &&
      input.deviceId !== null &&
      input.deviceId.length > 0
    ) {
      paramFilters.push({
        field: 'deviceId',
        values: input.deviceId,
      });
    }
    if (
      typeof input.severity !== 'undefined' &&
      input.severity !== null &&
      input.severity.length > 0
    ) {
      paramFilters.push({
        field: 'severity',
        values: input.severity,
      });
    }
    if (
      typeof input.sourceType !== 'undefined' &&
      input.sourceType !== null &&
      input.sourceType.length > 0
    ) {
      paramFilters.push({
        field: 'sourceType',
        values: input.sourceType,
      });
    }
    if (
      typeof input.eventType !== 'undefined' &&
      input.eventType !== null &&
      input.eventType.length > 0
    ) {
      paramFilters.push({
        field: 'eventType',
        values: input.eventType,
      });
    }
    if (
      typeof input.status !== 'undefined' &&
      input.status !== null &&
      input.status.length > 0
    ) {
      paramFilters.push({
        field: 'status',
        values: input.status,
      });
    }
    if (
      typeof input.reference !== 'undefined' &&
      input.reference !== null &&
      input.reference.length > 0
    ) {
      paramFilters.push({
        field: 'keys',
        values: input.reference,
      });
    }
  }

  return paramFilters;
};

export const addEventFilters = (
  currentFilters: Filter[],
  input: EventFilters,
) => {
  if (typeof input !== 'undefined' && input !== null) {
    if (
      typeof input.regionId !== 'undefined' &&
      input.regionId !== null &&
      input.regionId.length > 0
    ) {
      currentFilters.push({
        field: 'regionId',
        values: input.regionId,
      });
    }
    if (
      typeof input.buildingId !== 'undefined' &&
      input.buildingId !== null &&
      input.buildingId.length > 0
    ) {
      currentFilters.push({
        field: 'buildingId',
        values: input.buildingId,
      });
    }
    if (
      typeof input.controlSystemId !== 'undefined' &&
      input.controlSystemId !== null &&
      input.controlSystemId.length > 0
    ) {
      currentFilters.push({
        field: 'controlSystemId',
        values: input.controlSystemId,
      });
    }
    if (
      typeof input.deviceId !== 'undefined' &&
      input.deviceId !== null &&
      input.deviceId.length > 0
    ) {
      currentFilters.push({
        field: 'deviceId',
        values: input.deviceId,
      });
    }
    if (
      typeof input.severity !== 'undefined' &&
      input.severity !== null &&
      input.severity.length > 0
    ) {
      currentFilters.push({
        field: 'severity',
        values: input.severity,
      });
    }
    if (
      typeof input.sourceType !== 'undefined' &&
      input.sourceType !== null &&
      input.sourceType.length > 0
    ) {
      currentFilters.push({
        field: 'sourceType',
        values: input.sourceType,
      });
    }
    if (
      typeof input.eventType !== 'undefined' &&
      input.eventType !== null &&
      input.eventType.length > 0
    ) {
      currentFilters.push({
        field: 'eventType',
        values: input.eventType,
      });
    }
    if (
      typeof input.status !== 'undefined' &&
      input.status !== null &&
      input.status.length > 0
    ) {
      currentFilters.push({
        field: 'status',
        values: input.status,
      });
    }
    if (
      typeof input.reference !== 'undefined' &&
      input.reference !== null &&
      input.reference.length > 0
    ) {
      currentFilters.push({
        field: 'key',
        values: input.reference,
      });
    }
  }

  return currentFilters;
};
