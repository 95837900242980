import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Alert, Progress, Switch } from 'antd';
import * as R from 'ramda';

import BasePane from '@totem/components/BasePane';
import DetailSpinner from '@totem/components/common/DetailSpinner';
import PrimaryContentPane from '@totem/components/PrimaryContentPane';
import {
  GET_HEALTH_RISK_SURVEY,
  UPDATE_HEALTH_RISK_SURVEY,
} from '@totem/graph/healthRiskSurvey';
import {
  HealthRiskSurvey as HealthRiskSurveyType,
  HealthRiskSurveyErrors,
} from '@totem/types/healthRiskSurvey';
import {
  isQuestionAnswered,
  validateHealthRiskSurvey,
} from '@totem/utilities/healthRiskSurveyUtilities';
import { getExactPercentage } from '@totem/utilities/numberUtilities';

import HealthRiskSurveyDetailHeader from './HealthRiskSurveyDetailHeader';
import HealthRiskSurveyDetailQuestion from './HealthRiskSurveyDetailQuestion';
import HealthRiskSurveyDetailResults from './HealthRiskSurveyDetailResults';
import HealthRiskSurveyDetailSubmit from './HealthRiskSurveyDetailSubmit';

import './healthRiskSurveyDetail.css';

interface Props {
  match: {
    params: {
      id: string;
    };
  };
}

const styles = {
  progress: {
    width: '32rem',
  },
  alert: {
    margin: '1rem 0',
  },
};

const HealthRiskSurveyDetail = ({
  match: {
    params: { id },
  },
}: Props) => {
  const [unansweredOnly, setUnansweredOnly] = useState<boolean>(false);
  const [errors, setErrors] = useState<HealthRiskSurveyErrors>({});
  const [alertVisible, setAlertVisible] = useState<boolean>(false);

  const { loading, data } = useQuery<{
    healthRiskSurvey: HealthRiskSurveyType;
  }>(GET_HEALTH_RISK_SURVEY, {
    variables: { id },
  });

  const [updateHealthRiskSurvey] = useMutation(UPDATE_HEALTH_RISK_SURVEY);

  useEffect(() => {
    if (!R.isEmpty(errors)) {
      setErrors(validateHealthRiskSurvey(data?.healthRiskSurvey));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (loading || !data) {
    return (
      <div styleName="spinner-container">
        <DetailSpinner />
      </div>
    );
  }

  const { healthRiskSurvey } = data;
  const {
    questionsOrder,
    questions,
    totalQuestions,
    completedQuestions,
    isSubmitted,
  } = healthRiskSurvey;

  const handleChange = (key: string, value: any) => {
    updateHealthRiskSurvey({
      variables: {
        input: {
          id,
          questions: JSON.stringify({
            [key]: { answer: { value } },
          }),
        },
      },
    });
  };

  return (
    <>
      <HealthRiskSurveyDetailHeader survey={healthRiskSurvey} />
      <BasePane>
        {isSubmitted && (
          <HealthRiskSurveyDetailResults survey={healthRiskSurvey} />
        )}
        {alertVisible && !R.isEmpty(errors) && (
          <Alert
            message="It looks like you didn't answer all the required questions. Please review your answers."
            type="error"
            closable
            onClose={() => setAlertVisible(false)}
            showIcon
            style={styles.alert}
          />
        )}
        <PrimaryContentPane>
          <div styleName="submit-container">
            <HealthRiskSurveyDetailSubmit
              survey={healthRiskSurvey}
              loading={loading}
              onError={err => {
                setAlertVisible(true);
                setErrors(err);
              }}
            />
          </div>
          <div styleName="progress-container">
            <div styleName="show-unanswered-container">
              <Switch
                disabled={isSubmitted}
                size="small"
                onChange={() => setUnansweredOnly(!unansweredOnly)}
                checked={unansweredOnly}
              />
              <div styleName="show-unanswered">
                Show Unanswered Questions Only
              </div>
            </div>
            <div>
              <div styleName="progress-label">Progress</div>
              <Progress
                showInfo={false}
                percent={getExactPercentage(totalQuestions, completedQuestions)}
                style={styles.progress}
              />
            </div>
          </div>
          <div styleName="questions-container">
            {questionsOrder
              .map(key => questions[key])
              .filter(
                question => !unansweredOnly || !isQuestionAnswered(question),
              )
              .map(question => {
                const error = errors[question.id];

                return (
                  <div
                    styleName={`question-container ${error ? 'error' : ''}`}
                    key={question.id}
                  >
                    <HealthRiskSurveyDetailQuestion
                      question={question}
                      onChange={handleChange}
                      disabled={healthRiskSurvey.isSubmitted}
                    />
                  </div>
                );
              })}
          </div>
        </PrimaryContentPane>
      </BasePane>
    </>
  );
};

export default HealthRiskSurveyDetail;
