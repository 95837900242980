import React, { useContext, useState } from 'react';
import { Tabs, TabsProps } from 'antd';

import ContractsComponent from '@totem/components/contracts/list/ContractsComponent';
import PrimaryContentPane from '@totem/components/PrimaryContentPane';
import Contacts from '@totem/components/settings/sections/contacts/contacts';
import Services from '@totem/components/settings/sections/services/Services';
import TabTitle from '@totem/components/TabTitle';
import UserProfileContext from '@totem/components/UserProfileContext';
import authUtilities from '@totem/utilities/authUtilities';
import {
  isMemberOfAny,
  securityGroupCheckConstraintEmpty,
} from '@totem/utilities/userUtilities';

import ContentLayout from '../ContentLayout';

import Account from './sections/account/Account';
import { SettingType } from './constants';

import './settings.css';

const Settings = () => {
  const { userProfile } = useContext(UserProfileContext);
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const settingsTab = params.get('st');
  const [currentKey, setCurrentKey] = useState<any>(
    typeof settingsTab !== 'undefined' && settingsTab !== null
      ? settingsTab
      : SettingType.ACCOUNT,
  );

  const userCanSeeContractManagement = isMemberOfAny(
    userProfile,
    ['contract_creator', 'contract_viewer'],
    securityGroupCheckConstraintEmpty,
  );

  const Header = (
    <div styleName="content-header">
      <div>Account Settings</div>
    </div>
  );

  const getTabItems = () => {
    const tabItems: TabsProps['items'] = [];

    if (
      authUtilities.minimumRequiredRole(
        authUtilities.ROLE_SETS.SYSTEMS_ADMINISTRATOR,
      )
    ) {
      tabItems.push({
        key: SettingType.ACCOUNT,
        label: <TabTitle>Account Settings</TabTitle>,
        children: <Account />,
      });

      tabItems.push({
        key: 'contacts',
        label: <TabTitle>Contacts</TabTitle>,
        children: <Contacts />,
      });

      tabItems.push({
        key: SettingType.SERVICES,
        label: <TabTitle>Services</TabTitle>,
        children: <Services />,
      });

      if (userCanSeeContractManagement) {
        tabItems.push({
          key: 'contracts',
          label: <TabTitle>Contracts</TabTitle>,
          children: <ContractsComponent />,
        });
      }
    }

    return tabItems;
  };

  return (
    <ContentLayout>
      <PrimaryContentPane>
        {Header}
        <div styleName="content-container">
          <Tabs
            activeKey={currentKey}
            onChange={(key) => setCurrentKey(key)}
            items={getTabItems()}
          />
        </div>
      </PrimaryContentPane>
    </ContentLayout>
  );
};

export default Settings;
