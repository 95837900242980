import React, { useContext } from 'react';
import { MergeCellsOutlined } from '@ant-design/icons';
import { Button } from 'antd';

import VendorContext from '@totem/components/vendors/VendorContext';
import { isNull } from '@totem/utilities/common';

export interface Props {
  isPrimary?: boolean;
}
const MergeButton = ({ isPrimary }: Props) => {
  const { selectedVendors, action, onAction } = useContext(VendorContext);

  return (
    <Button
      icon={<MergeCellsOutlined />}
      type={
        typeof isPrimary !== 'undefined' && isPrimary ? 'primary' : 'default'
      }
      disabled={
        action === 'merge' ||
        isNull(selectedVendors) ||
        selectedVendors.length < 2
      }
      onClick={() => onAction('merge', null)}
    >
      Merge
    </Button>
  );
};

export default MergeButton;
