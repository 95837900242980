import React, { useContext, useEffect, useState } from 'react';
import { Button } from 'antd';

import BasePane from '@totem/components/BasePane';
import CardBody from '@totem/components/CardBody';
import CardHeader from '@totem/components/CardHeader';
import CardHeaderSmaller from '@totem/components/CardHeaderSmaller';
import ContentLayout from '@totem/components/ContentLayout';
import ContractEditModal from '@totem/components/contracts/edits/ContractEditModal';
import ContractsTable from '@totem/components/contracts/list/ContractsTable';
import PrimaryContentPane from '@totem/components/PrimaryContentPane';
import UserProfileContext from '@totem/components/UserProfileContext';
import { Params } from '@totem/types/common';
import { Contract } from '@totem/types/contracts';
import { getToken } from '@totem/utilities/accountUtilities';
import { CONTRACTS_ENDPOINT } from '@totem/utilities/endpoints';
import {
  isMemberOfAny,
  securityGroupCheckConstraintEmpty,
} from '@totem/utilities/userUtilities';

const Contracts = () => {
  const { userProfile } = useContext(UserProfileContext);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showNewContractModel, setShowNewContractModal] = useState<boolean>(
    false,
  );
  const [contractData, setContractData] = useState<Contract[]>(null);

  const isContractCreator = isMemberOfAny(
    userProfile,
    ['contract_creator'],
    securityGroupCheckConstraintEmpty,
  );

  const buildParameters = () => {
    const params: Params = {
      paging: {
        page: 1,
        pageSize: 100,
      },
      sort: {
        field: 'createdAt',
        direction: 1,
      },
      filters: [],
    };

    return params;
  };

  useEffect(() => {
    if (showNewContractModel === false) {
      setIsLoading(true);
      const params: Params = buildParameters();

      fetch(`${CONTRACTS_ENDPOINT}`, {
        method: 'POST',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
        body: JSON.stringify(params),
      })
        .then(res => res.json())
        .then((result: Contract[]) => {
          setContractData(result);
        })
        .then(() => {
          setIsLoading(false);
        });
    }
  }, [showNewContractModel]);

  const handleEditModalClosed = () => {
    setShowNewContractModal(false);
  };

  return (
    <ContentLayout>
      <BasePane>
        <PrimaryContentPane>
          <CardHeader>
            <div>Contract Management</div>
            <CardHeaderSmaller>
              {contractData !== null ? contractData.length : 0} Contracts
            </CardHeaderSmaller>
            {isContractCreator && (
              <Button
                type="primary"
                onClick={() => setShowNewContractModal(true)}
              >
                New Contract
              </Button>
            )}
          </CardHeader>
          <CardBody>
            <ContractsTable constractsData={contractData} loading={isLoading} />
          </CardBody>
        </PrimaryContentPane>
      </BasePane>
      {showNewContractModel && (
        <ContractEditModal
          onClose={handleEditModalClosed}
          visible={showNewContractModel}
        />
      )}
    </ContentLayout>
  );
};

export default Contracts;
