import { createContext } from 'react';

import { EMPTY_ID } from '@totem/components/common/reference/ReferenceSelector';
import {
  QuestionValue,
  SurveyError,
  SurveyQueryResult,
} from '@totem/components/surveyV2/types';

interface Context {
  surveyInstanceId: string;
  loading: boolean;
  setIsLoading: (isLoading: boolean) => void;
  data: SurveyQueryResult;
  setData: (data: SurveyQueryResult) => void;
  updateQuestionAnswer: (
    categoryId: string,
    questionId: string,
    newQuestionValue: QuestionValue,
    newAdditionalContext: string,
  ) => void;
  errorAlertVisible: boolean;
  setErrorAlertVisible: (isVisible: boolean) => void;
  errors: SurveyError[];
  setErrors: (errors: SurveyError[]) => void;
  filterAnswered: boolean;
  setFilterAnswered: (value: boolean) => void;
  onAction: (action: string) => void;
}

export default createContext<Context>({
  surveyInstanceId: EMPTY_ID,
  loading: false,
  setIsLoading: () => {},
  data: null,
  setData: () => {},
  updateQuestionAnswer: () => {},
  errorAlertVisible: false,
  setErrorAlertVisible: () => {},
  errors: [],
  setErrors: () => {},
  filterAnswered: false,
  setFilterAnswered: () => {},
  onAction: () => {},
});
