import React, { useCallback, useContext, useEffect, useState } from 'react';
import { CloseSquareOutlined } from '@ant-design/icons';
import { Button, Col, Input, Modal, Radio, Row } from 'antd';

import DeviceTypeContext from '@totem/components/deviceTypes/DeviceTypeContext';
import { DeviceType } from '@totem/components/deviceTypes/types';
import ModalFooter from '@totem/components/ModalFooter';
import ModalTitle from '@totem/components/ModalTitle';
import { getToken } from '@totem/utilities/accountUtilities';
import { isNotNull } from '@totem/utilities/common';
import { DEVICES_ENDPOINT } from '@totem/utilities/endpoints';
import { CheckResponseShowError } from '@totem/utilities/responseUtilities';

const styles = {
  dialog: {
    maxHeight: '80%',
    maxWidth: '850px',
  },
  label: {
    fontSize: '12px',
    fontWeight: 600,
  },
};

const MergeDialog = () => {
  const { selectedDeviceTypes, setSelectedDeviceType, action, onAction } =
    useContext(DeviceTypeContext);
  const [isSending, setIsSending] = useState<boolean>(false);
  const [mergedName, setMergedName] = useState<string>(
    isNotNull(selectedDeviceTypes) && selectedDeviceTypes.length > 0
      ? selectedDeviceTypes[0].name
      : '',
  );
  const [primaryPackage, setPrimaryPackage] = useState<DeviceType>(
    isNotNull(selectedDeviceTypes) && selectedDeviceTypes.length > 0
      ? selectedDeviceTypes[0]
      : null,
  );

  useEffect(() => {
    setMergedName(primaryPackage.name);
  }, [primaryPackage]);

  const sendMergeRequest = useCallback(async (payload: any) => {
    if (isSending) {
      return;
    }
    setIsSending(true);

    fetch(`${DEVICES_ENDPOINT}/deviceTypes/merge`, {
      method: 'POST',
      headers: new Headers({
        Authorization: `Bearer ${getToken()}`,
      }),
      body: JSON.stringify(payload),
    }).then((res) => {
      CheckResponseShowError(res);
      setIsSending(false);
      onAction('clear_vendor_selections', null);
      onAction('refresh', null);
    });
  }, []);

  const handleSubmit = () => {
    if (mergedName !== '') {
      const aliases = selectedDeviceTypes.map((chk) => chk.name);

      const payload = {
        action: 'merge',
        name: primaryPackage.name,
        aliases,
      };
      sendMergeRequest(payload);
    } else {
      // Invalid Merged Name
    }
  };

  return (
    <Modal
      open={action === 'merge'}
      onCancel={() => onAction('', null)}
      title={<ModalTitle>Merge Vendors</ModalTitle>}
      confirmLoading={false}
      style={styles.dialog}
      footer={
        <ModalFooter>
          <Button onClick={() => onAction('', null)}>Close</Button>
          <Button onClick={handleSubmit} type="primary">
            Submit
          </Button>
        </ModalFooter>
      }
    >
      <div style={styles.label}>Name the merged package:</div>
      <Input
        value={mergedName}
        onChange={({ target: { value } }) => setMergedName(value)}
      />
      <br />
      <br />

      <div style={styles.label}>
        Select the record that the rest will be merged into:
      </div>
      <Radio.Group value={primaryPackage}>
        {selectedDeviceTypes.map((pkg) => (
          <Row key={pkg.name}>
            <Col span={23}>
              <Radio value={pkg} onClick={() => setPrimaryPackage(pkg)}>
                {pkg.name}
              </Radio>
            </Col>
            <Col span={1}>
              <CloseSquareOutlined
                onClick={() => {
                  setSelectedDeviceType(pkg, false);
                }}
              />
            </Col>
          </Row>
        ))}
      </Radio.Group>
    </Modal>
  );
};

export default MergeDialog;
