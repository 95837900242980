import { call, fork, put, takeEvery } from 'redux-saga/effects';

import actionTypes from '@totem/actions/actionTypes';
import * as configurationActions from '@totem/actions/configurationActions';
import * as fileActions from '@totem/actions/fileActions';
import * as notificationActions from '@totem/actions/notificationActions';
import accountUtilities from '@totem/utilities/accountUtilities';
import api from '@totem/utilities/api';
import { ANALYSIS_ENDPOINT, FILES_ENDPOINT } from '@totem/utilities/endpoints';
import {
  appendFormData,
  sanitizeFile,
} from '@totem/utilities/formDataUtilities';
const FormData = require('form-data');

export function* uploadAdditionalConfigurationFile({ data: payload }) {
  const formData = new FormData();
  const { file, analysisId, configType } = payload;

  formData.append('file', file);
  formData.append('configType', configType);

  try {
    yield put(
      notificationActions.createNotification({
        message: `Uploading ${name}...`,
      }),
    );

    const endpoint = `${ANALYSIS_ENDPOINT}/${analysisId}`;
    // @ts-ignore
    const { data } = yield call(api.patch, endpoint, formData);

    yield put(
      configurationActions.uploadAdditionalDeviceConfigurationSuccess(data),
    );

    yield put(
      notificationActions.createNotification({
        message: 'Uploaded Additional Configuration Successfully',
      }),
    );
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      configurationActions.uploadAdditionalDeviceConfigurationFailure,
    );
    yield put(
      notificationActions.createNotification({
        message: 'Invalid Configuration File',
        isError: true,
      }),
    );
  }
}

export function* uploadFile(action) {
  const formData = new FormData();

  const {
    file,
    questionnaireId,
    controlSystemId,
    deviceId,
    userId,
    subjectId,
    organizationId,
    buildingId,
    policyAuditId,
    subjectType,
  } = action.data;

  const { name } = file;

  appendFormData(formData, 'file', sanitizeFile(file));
  appendFormData(formData, 'organizationId', organizationId);
  appendFormData(formData, 'buildingId', buildingId);
  appendFormData(formData, 'controlSystemId', controlSystemId);
  appendFormData(formData, 'deviceId', deviceId);
  appendFormData(formData, 'userId', userId);
  appendFormData(formData, 'questionnaireId', questionnaireId);
  appendFormData(formData, 'policyAuditId', policyAuditId);
  appendFormData(formData, 'subjectId', subjectId);
  appendFormData(formData, 'subjectType', subjectType);

  try {
    yield put(
      notificationActions.createNotification({
        message: `Uploading ${name}...`,
      }),
    );

    // @ts-ignore
    const { data } = yield call(api.post, FILES_ENDPOINT, formData);

    yield put(fileActions.uploadFilesSuccess(data));

    yield put(
      notificationActions.createNotification({
        message: 'Uploaded Successfully',
      }),
    );
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      fileActions.uploadFilesFailure,
    );

    yield put(
      notificationActions.createNotification({
        message: 'Failed to upload. Please try again.',
        isError: true,
      }),
    );
  }
}

/* WATCHERS */

export function* requestUploadAdditionalDeviceConfiguration() {
  // @ts-ignore
  yield takeEvery(
    actionTypes.REQUEST_UPLOAD_ADDITIONAL_DEVICE_CONFIGURATION,
    uploadAdditionalConfigurationFile,
  );
}

export function* requestFileUpload() {
  yield takeEvery(actionTypes.REQUEST_UPLOAD_FILES, uploadFile);
}

export default [requestUploadAdditionalDeviceConfiguration, requestFileUpload];
