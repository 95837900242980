import React from 'react';
import { CloseCircleOutlined } from '@ant-design/icons';
import { ColumnProps } from 'antd/lib/table';

import Table from '@totem/components/common/table/Table';
import { PartyInvolved, TicketQueryResult } from '@totem/types/ticketing';
import { formatDate } from '@totem/utilities/common';

import '../ticketing.css';

type Props = {
  ticket: TicketQueryResult;
  onEndPartyInvolvement: (partyInvolved: PartyInvolved) => void;
};

const PartiesInvolvedTable = ({ ticket, onEndPartyInvolvement }: Props) => {
  const columns: ColumnProps<PartyInvolved>[] = [
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      showSorterTooltip: true,
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        compA.type === compB.type ? 0 : compA.type > compB.type ? 1 : -1,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      showSorterTooltip: true,
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        compA.name === compB.name ? 0 : compA.name > compB.name ? 1 : -1,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      showSorterTooltip: true,
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        compA.email === compB.email ? 0 : compA.email > compB.email ? 1 : -1,
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
      showSorterTooltip: true,
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        compA.phone === compB.phone ? 0 : compA.phone > compB.phone ? 1 : -1,
    },
    {
      title: 'Involvement Start',
      dataIndex: 'involvementStart',
      key: 'involvementStart',
      render: (_, partyInvolved: PartyInvolved) =>
        formatDate(partyInvolved.involvementStart),
      sorter: (compA, compB) =>
        compA.involvementStart === compB.involvementStart
          ? 0
          : compA.involvementStart > compB.involvementStart
          ? 1
          : -1,
      showSorterTooltip: true,
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Involvement End',
      dataIndex: 'involvementEnd',
      key: 'involvementEnd',
      render: (_, partyInvolved: PartyInvolved) =>
        formatDate(partyInvolved.involvementEnd),
      sorter: (compA, compB) =>
        compA.involvementEnd === compB.involvementEnd
          ? 0
          : compA.involvementEnd > compB.involvementEnd
          ? 1
          : -1,
      showSorterTooltip: true,
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: (compA, compB) =>
        compA.status === compB.status
          ? 0
          : compA.status > compB.status
          ? 1
          : -1,
      showSorterTooltip: true,
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Actions',
      width: 30,
      dataIndex: 'additionalOptions',
      key: 'additionalOptions',
      render: (_, partyInvolved: PartyInvolved) =>
        partyInvolved.status === 'ACTIVE' && (
          <div className="center-table-cell">
            <CloseCircleOutlined
              onClick={() => onEndPartyInvolvement(partyInvolved)}
            />
          </div>
        ),
    },
  ];

  const getPartiesInvolved = () => {
    if (ticket !== null) {
      if (ticket.ticket !== null) {
        return ticket.ticket.partiesInvolved;
      }
    }
    return [];
  };

  return (
    <Table
      showSorterTooltip
      columns={columns}
      dataSource={getPartiesInvolved()}
      loading={false}
      rowKey="id"
    />
  );
};

export default PartiesInvolvedTable;
