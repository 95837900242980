import React, { useContext, useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';

import { sortStringAscending } from '@totem/components/events/eventsContainer/utilities';
import RemoteAccessSessionContext from '@totem/components/remoteAccess/sessions/RemoteAccessSessionContext';
import colors from '@totem/styles/colors';

type DataValue = {
  date: string;
  count: number;
  duration: number;
};

const getDateKey = (val: Date) => {
  const month = `${val.getMonth() + 1}`.padStart(2, '0');
  const day = `${val.getDate()}`.padStart(2, '0');
  return `${val.getFullYear()}-${month}-${day}`;
};

const getDuration = (start: string, end: string) => {
  const startDate = new Date(start);
  const endDate = new Date(end);
  const durationHours =
    (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60);
  return durationHours;
};

const incrementValue = (
  arr: DataValue[],
  date: string,
  countInc: number,
  durationInc: number,
) => {
  for (let idx = 0; idx < arr.length; idx++) {
    if (arr[idx].date === date) {
      arr[idx].count = arr[idx].count + countInc;
      arr[idx].duration = arr[idx].duration + durationInc;
      return arr;
    }
  }
  return arr;
};

const SessionComboChart = () => {
  const { sessionData, input, setInput } = useContext(
    RemoteAccessSessionContext,
  );
  const [dataCategories, setDataCategories] = useState<any>([]);
  const [dataCountSeries, setDataCountSeries] = useState<any>([]);
  //const [dataTotalDurationSeries, setDataTotalDurationSeries] = useState<any>([]);
  const [dataAvgDurationSeries, setDataAvgDurationSeries] = useState<any>([]);

  useEffect(() => {
    if (
      typeof sessionData !== 'undefined' &&
      sessionData !== null &&
      sessionData.length > 0
    ) {
      const startDate = new Date(input.startTime);
      const endDate = new Date(input.endTime);

      const sortedSessionData = sessionData.sort((compA, compB) =>
        sortStringAscending(compA.personEmail, compB.personEmail),
      );

      startDate.setHours(0, 0, 0);
      endDate.setHours(23, 59, 59);
      const currentDate = new Date(startDate.getTime());
      let data: DataValue[] = [];
      while (currentDate < endDate) {
        data.push({
          date: getDateKey(currentDate),
          count: 0,
          duration: 0,
        });
        currentDate.setDate(currentDate.getDate() + 1);
      }

      for (let userIdx = 0; userIdx < sortedSessionData.length; userIdx++) {
        for (
          let sessionIdx = 0;
          sessionIdx < sortedSessionData[userIdx].sessions.length;
          sessionIdx++
        ) {
          // eslint-disable-next-line max-depth
          if (
            sortedSessionData[userIdx].sessions[sessionIdx].endTime !== null
          ) {
            const sessionStart = getDateKey(
              new Date(
                sortedSessionData[userIdx].sessions[sessionIdx].startTime,
              ),
            );
            const durationHours = getDuration(
              sortedSessionData[userIdx].sessions[sessionIdx].startTime,
              sortedSessionData[userIdx].sessions[sessionIdx].endTime,
            );

            data = incrementValue(data, sessionStart, 1, durationHours);
          }
        }
      }

      const chartData = data.sort((compA, compB) =>
        sortStringAscending(compA.date, compB.date),
      );

      const chartCategories = [];
      const countSeries = [];
      const totalDurationSeries = [];
      const avgDurationSeries = [];

      for (let idx = 0; idx < chartData.length; idx++) {
        chartCategories.push(chartData[idx].date);
        countSeries.push(chartData[idx].count);
        totalDurationSeries.push(chartData[idx].duration);
        if (chartData[idx].count > 0) {
          avgDurationSeries.push(
            chartData[idx].duration / chartData[idx].count,
          );
        } else {
          avgDurationSeries.push(0);
        }
      }
      setDataCategories(chartCategories);
      setDataCountSeries(countSeries);
      //setDataTotalDurationSeries(totalDurationSeries);
      setDataAvgDurationSeries(avgDurationSeries);
    }
  }, [sessionData]);

  const handleDataPointClicked = (event, chartContext, config) => {
    const selectedDate = dataCategories[config.dataPointIndex];
    if (input.selectedDate === selectedDate) {
      setInput({ ...input, selectedDate: '' });
    } else {
      setInput({ ...input, selectedDate });
    }
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
  };

  const options: ApexCharts.ApexOptions = {
    chart: {
      type: 'line',
      stacked: false,
      events: {
        dataPointSelection: handleDataPointClicked,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: [1, 4],
      curve: 'smooth',
    },
    title: {
      text: 'Remote Access Session Summary',
      align: 'left',
      offsetX: 110,
    },
    xaxis: {
      categories: dataCategories,
      title: { text: 'Session Start Date' },
    },
    yaxis: [
      {
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: colors.brand.blue,
        },
        labels: {
          style: {
            colors: colors.brand.blue,
          },
          formatter: val => {
            return val.toFixed(2);
          },
        },
        title: {
          text: 'Number of Sessions',
          style: {
            color: colors.brand.blue,
          },
        },
        tooltip: {
          enabled: true,
        },
      },
      {
        seriesName: 'Avg Duration (Hours)',
        opposite: true,
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: '#FEB019',
        },
        labels: {
          style: {
            colors: '#FEB019',
          },
          formatter: val => {
            return val.toFixed(2);
          },
        },
        title: {
          text: 'Average Session Duration (Hours)',
          style: {
            color: '#FEB019',
          },
        },
      },
    ],
    tooltip: {
      fixed: {
        enabled: true,
        position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
        offsetY: 30,
        offsetX: 60,
      },
    },
    legend: {
      horizontalAlign: 'left',
      offsetX: 40,
    },
  };

  const series = [
    {
      name: 'Session Count',
      type: 'column',
      color: colors.brand.blue,
      data: dataCountSeries,
    },
    {
      name: 'Avg Duration (Hours)',
      type: 'line',
      color: '#FEB019',
      data: dataAvgDurationSeries,
    },
  ];

  return (
    <div className="app">
      <div className="row">
        <div className="mixed-chart">
          {dataCategories.length > 0 && (
            <ReactApexChart
              options={options}
              series={series}
              type="line"
              width="100%"
              height="400px"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default SessionComboChart;
