import React from 'react';

import ContactSearchEmbeddedContainer from '@totem/components/contactSearch/contactSearchEmbeddedContainer';
import SearchInput from '@totem/components/contactSearch/searchInput';
import SearchResultsTable from '@totem/components/contactSearch/SearchResultsTable';

const ContactSearchComponent = () => {
  return (
    <ContactSearchEmbeddedContainer>
      <SearchInput />
      <br />
      <br />
      <SearchResultsTable />
    </ContactSearchEmbeddedContainer>
  );
};

export default ContactSearchComponent;
