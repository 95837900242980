import { createContext } from 'react';

import {
  ComplianceSummary,
  ComplianceSummaryInput,
} from '@totem/components/surveyV2/complianceSummary/types';

interface Context {
  input: ComplianceSummaryInput;
  setInput: (input: ComplianceSummaryInput) => void;
  loading: boolean;
  data: ComplianceSummary;
  action: string;
  onAction?: (action: string) => void;
}

export default createContext<Context>({
  input: {},
  setInput: () => {},
  loading: false,
  data: null,
  action: '',
  onAction: () => {},
});
