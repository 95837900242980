import * as React from 'react';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Button, Card } from 'antd';
import * as R from 'ramda';

import ExportModal from '@totem/components/surveyV2/instanceDetail/score/ExportModal';
import ResultGrade from '@totem/components/surveyV2/instanceDetail/score/ResultGrade';
import ResultsBar from '@totem/components/surveyV2/instanceDetail/score/ResultsBar';
import { SelectedItem } from '@totem/components/surveyV2/instanceDetail/score/types';
import SurveyContext from '@totem/components/surveyV2/instanceDetail/SurveyInstanceContext';
import { isNull } from '@totem/utilities/common';

import './score.css';

const styles = {
  card: {
    width: '60rem',
    margin: '0 2rem',
  },
};

type Props = {
  selectedItem: SelectedItem;
  selectedId: string;
  selectedType: string;
};

const ReportComponent = ({ selectedId, selectedType, selectedItem }: Props) => {
  const { data } = useContext(SurveyContext);
  const { survey } = data;

  const getNumericalScore = (totalScore: number, maxScore: number): number =>
    Number(((totalScore / maxScore) * 100).toFixed(0));

  const getGrade = (score: number): string => {
    if (!Number(score) || score < 60) {
      return 'F';
    }

    if (score >= 60 && score < 70) {
      return 'D';
    }

    if (score >= 70 && score < 80) {
      return 'C';
    }

    if (score >= 80 && score < 90) {
      return 'B';
    }

    if (score >= 90) {
      return 'A';
    }

    return 'F';
  };

  const getChildren = () => {
    if (isNull(selectedItem) || isNull(selectedItem.children)) {
      return <div />;
    }

    let allSubChildrenEmpty = true;
    selectedItem.children.map((next: SelectedItem): any => {
      if (!R.isNil(next.children) && R.isEmpty(next.children)) {
        allSubChildrenEmpty = false;
      }
      return {};
    });

    if (allSubChildrenEmpty) {
      return (
        <React.Fragment>
          {selectedItem.children.map((item: SelectedItem) => {
            const score = getNumericalScore(item.totalScore, item.maxScore);
            const grade = getGrade(score);

            return (
              <div styleName="line-results-container">
                <div styleName="line-results-top-row">
                  <div styleName="line-results-label">{item.label}</div>
                  {!isNaN(score) && (
                    <div styleName="line-results-top-end-container">
                      <div styleName="line-results-top-end-score">{score}%</div>
                      <div styleName="line-results-top-end-grade">{grade}</div>
                    </div>
                  )}
                </div>
                <ResultsBar
                  totalScore={item.totalScore}
                  maxScore={item.maxScore}
                />
              </div>
            );
          })}
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        {selectedItem.children.map((item: SelectedItem, index: number) => {
          const score = getNumericalScore(item.totalScore, item.maxScore);

          return (
            <React.Fragment key={`${item.label}-${index}`}>
              <div styleName="results-title-row">
                <div styleName="results-title sub-title">{item.label}</div>
                {!isNaN(score) && (
                  <div styleName="results-title-score-container">
                    <div styleName="results-title-subtext">{score}%</div>
                    <ResultGrade
                      small
                      totalScore={item.totalScore}
                      maxScore={item.maxScore}
                    />
                  </div>
                )}
              </div>
              {!R.isEmpty(item.children) && (
                <div styleName="line-results-container-offset">
                  {item.children.map((subItem: SelectedItem, idx: number) => {
                    const subItemScore = getNumericalScore(
                      subItem.totalScore,
                      subItem.maxScore,
                    );
                    const grade = getGrade(subItemScore);

                    return (
                      <div
                        key={`${subItem.label}-${idx}`}
                        styleName="line-results-container"
                      >
                        <div styleName="line-results-top-row">
                          <div styleName="line-results-label">
                            {subItem.label}
                          </div>
                          {!isNaN(subItemScore) && (
                            <div styleName="line-results-top-end-container">
                              <div styleName="line-results-top-end-score">
                                {subItemScore}%
                              </div>
                              <div styleName="line-results-top-end-grade">
                                {grade}
                              </div>
                            </div>
                          )}
                        </div>
                        <ResultsBar
                          totalScore={subItem.totalScore}
                          maxScore={subItem.maxScore}
                        />
                      </div>
                    );
                  })}
                </div>
              )}
              <div styleName="results-line-break" />
            </React.Fragment>
          );
        })}
      </React.Fragment>
    );
  };

  const viewSurveyButton = (
    <Button>
      <Link to={`/dashboard/surveyinstances/${survey.id}`}>View Survey</Link>
    </Button>
  );

  const topLevelScore = getNumericalScore(
    selectedItem.totalScore,
    selectedItem.maxScore,
  );

  return (
    <Card
      style={styles.card}
      bordered={false}
      title={
        <div styleName="results-heading-container">
          <div>Report Component</div>
          <div styleName="results-heading-modal-label-container">
            <ExportModal
              title={selectedItem.label}
              selectedType={selectedType}
              selectedId={selectedId}
              selectedItem={selectedItem}
            />
            {viewSurveyButton}
          </div>
        </div>
      }
    >
      <Card.Meta
        style={{ width: '100%' }}
        description={
          <React.Fragment>
            <div styleName="results-title-row">
              <div styleName="results-title">{selectedItem.label}</div>
              {!isNaN(topLevelScore) && (
                <div styleName="results-title-score-container">
                  <div styleName="results-title-subtext">
                    overall: {topLevelScore}%
                  </div>
                  <ResultGrade
                    totalScore={selectedItem.totalScore}
                    maxScore={selectedItem.maxScore}
                  />
                </div>
              )}
            </div>
            <div styleName="results-line-break" />
            {getChildren()}
          </React.Fragment>
        }
      />
    </Card>
  );
};

export default ReportComponent;
