import React from 'react';

import ContactsContainer from '@totem/components/settings/sections/contacts/contactsContainer';
import ContactsHeaderActions from '@totem/components/settings/sections/contacts/contactsHeaderActions';
import ContactsTable from '@totem/components/settings/sections/contacts/contactsTable';

import './contacts.css';

const Contacts = () => {
  return (
    <div styleName="container">
      <ContactsContainer>
        <div styleName="header-container">
          <div styleName="header">Organizational Contacts</div>
          <ContactsHeaderActions />
        </div>
        <ContactsTable />
      </ContactsContainer>
    </div>
  );
};

export default Contacts;
