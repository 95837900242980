import React, { useContext } from 'react';

import CvePanel from '@totem/components/software/cves/cvePanel';
import CvesContext from '@totem/components/software/cves/cvesContext';
import { isNotNull } from '@totem/utilities/common';
const CveList = () => {
  const { data } = useContext(CvesContext);

  return (
    <>
      {isNotNull(data) &&
        data.map((cve) => <CvePanel key={cve.id} cve={cve} />)}
    </>
  );
};

export default CveList;
