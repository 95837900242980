import { gql } from '@apollo/client';

export const GET_BUILDING = gql`
  query GetBuilding(
    $buildingId: ID!
    $policyAuditsInput: PolicyAuditsConnectionInput!
  ) {
    building(id: $buildingId) {
      id
      name
      addressOne
      addressTwo
      county
      city
      state
      zipCode
      country
      type
      squareFootage
      location {
        lat
        lng
      }
      region {
        id
        name
      }
      pointsOfContact {
        name
        email
        phone
        labels
      }
      policyAudits(input: $policyAuditsInput) {
        totalCount
        averageScore
        completionCounts {
          complete
        }
      }
    }
  }
`;

export const GET_BUILDINGS = gql`
  query GetBuildings(
    $input: BuildingsConnectionInput!
    $policyAuditsInput: PolicyAuditsConnectionInput!
    $controlSystemsInput: ControlSystemsConnectionInput!
  ) {
    buildings(input: $input) {
      totalCount
      buildings {
        id
        name
        addressOne
        addressTwo
        country
        city
        state
        zipCode
        controlSystems(input: $controlSystemsInput) {
          totalCount
        }
        policyAudits(input: $policyAuditsInput) {
          totalCount
          averageScore
          completionCounts {
            complete
          }
        }
        region {
          id
          name
        }
        location {
          lat
          lng
        }
      }
    }
  }
`;

export const CREATE_BUILDING = gql`
  mutation CreateBuilding($input: BuildingCreateInput!) {
    buildingCreate(input: $input) {
      id
      name
      region {
        id
        name
      }
      addressOne
      city
      state
      zipCode
      location {
        lat
        lng
      }
      type
      squareFootage
    }
  }
`;

export const DELETE_BUILDING = gql`
  mutation BuildingDelete($id: ID!) {
    buildingDelete(id: $id)
  }
`;

export const UPDATE_BUILDING = gql`
  mutation UpdateBuilding($input: BuildingUpdateInput!) {
    buildingUpdate(input: $input) {
      id
      name
      addressOne
      addressTwo
      city
      state
      zipCode
      country
      type
      squareFootage
      pointsOfContact {
        name
        email
        phone
        labels
      }
      location {
        lat
        lng
      }
    }
  }
`;

export const BULK_CREATE_BUILDINGS = gql`
  mutation BulkCreateBuildings($input: [BuildingCreateInput!]!) {
    buildingBulkCreate(input: $input) {
      id
      name
      region {
        id
        name
      }
      addressOne
      city
      state
      zipCode
      location {
        lat
        lng
      }
    }
  }
`;
