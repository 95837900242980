import React, { ReactNode, useContext, useEffect, useRef } from 'react';
import { Graph } from '@antv/g6';

import NetworkContext from '@totem/components/buildingDetail/network/networkContext';
import { isNotNull } from '@totem/utilities/common';

const NetworkGraphJSX = ({graphType}) => {
  const { data } = useContext(NetworkContext);
  const containerRef = useRef(null);
  const graphRef = useRef(null);
  const selectedGraphType = isNotNull(graphType) ? graphType : 'combo-combined';

  useEffect(() => {
    if (isNotNull(data)) {
      const graph = new Graph({
        container: containerRef.current,
        data,
        layout: {
          type: selectedGraphType,
          comboPadding: 2,
          nodeSize: 10,
          preventOverlap: true,
          kr: 60,
        },
        node: {
          style: (model) => {
            return {
              size: 10,
              label: true,
              labelFontSize: 7,
              labelOffsetY: -4,
              labelText: model.label,
              fill: model.data.deviceId !== "" ? '#5FA052' : '#83A1CD'
            }
          },
          palette: {
            type: 'group',
            field: (chk) => chk.combo,
          },
        },
        edge: {
          style: (model) => {
            const { size, color, label, showEndArrow, showLabel, direction} = model.data;

            const edgeColor = color || direction === 'TO' ? '#D98B7A' : direction==='FROM' ? '#8DAE92' : '#99ADD1'

            return {
              stroke: '#99ADD1',
              endArrowFill: '#99ADD1',
              endArrowStroke: edgeColor,
              lineWidth: size || 1,
              label: showLabel,
              labelOffsetY: direction === 'FROM' ? -4 : 4,
              labelFontSize: 6,
              labelFill: edgeColor,
              labelText: label,
              endArrow: showEndArrow,
            };
          },
          labelCfg: {
            style: {
              fontSize: 6,       // font size in pixels
              fill: '#CCCCCC',   // font color
              background: {
                fill: '#ffffff',   // Optional background color for label
                stroke: '#cccccc', // Optional border color for label
                padding: [2, 2, 2, 2] // Optional padding around label
              },
            },
            position: 'middle'
          },
        },
        combo: {
          style: {
            label: true,
            labelText: (chk) => chk.label,
          },
        },
        behaviors: ['drag-element', 'drag-canvas', 'zoom-canvas'],
        autoFit: 'view',
      });

      graph.render();
      graphRef.current = graph;
    }
  }, [data, selectedGraphType]);

  const handleResize = () => {
    graphRef.current?.resize();
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize);
  }, []);

  return <div ref={containerRef} style={{ height: '100vh', width: '100vh' }} />;
};

export default NetworkGraphJSX;
