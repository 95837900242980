import React from 'react';

import DisplayAttribute from '@totem/components/ticketing/details/displayAttribute';
import { Attribute, Note } from '@totem/types/common';

import '../ticketing.css';

type Props = {
  note: Note;
  attributes: Attribute[];
};

const ActivityNote = ({ note, attributes }: Props) => {
  const getDisplayAttributes = () => {
    if (attributes !== null) {
      return attributes.filter(attrib => attrib.display === true);
    }
    return [];
  };

  return (
    <div styleName="activityNote">
      {getDisplayAttributes().map(attrib => {
        return <DisplayAttribute key={attrib.name} attribute={attrib} />;
      })}
      <div dangerouslySetInnerHTML={{ __html: note.htmlText }} />
    </div>
  );
};

export default ActivityNote;
