import React, { useCallback, useEffect, useState } from 'react';
import { IdcardOutlined } from '@ant-design/icons';
import { InputNumber, List } from 'antd';
import { debounce } from '@totem/utilities/debounce';

import { RemoteAccessMaxAccountInactivity } from '@totem/types/remoteAccess';
import { getToken } from '@totem/utilities/accountUtilities';
import { REMOTE_ACCESS_PROVISIONING_MAX_ACCOUNT_INACTIVITY } from '@totem/utilities/endpoints';
const DEBOUNCE_TIME = 500;

import colors from '@totem/styles/colors';

import '../account.css';

const IconStyle = {
  fontSize: '24px',
  color: colors.antd.blue,
};

const RemoteAccessSettings = () => {
  const [maxInactivityDays, setMaxInactivityDays] = useState<number>(0);
  const [isSending, setIsSending] = useState<boolean>(false);
  const [userProvidedInput, setUserProvidedInput] = useState<boolean>(false);

  const handleMaxInactivityChanged = useCallback(
    debounce((maxDays: number) => {
      setMaxInactivityDays(maxDays);
      setUserProvidedInput(true);
    }, DEBOUNCE_TIME),
    [],
  );

  useEffect(() => {
    if (isSending) {
      return;
    }
    const endpoint = `${REMOTE_ACCESS_PROVISIONING_MAX_ACCOUNT_INACTIVITY}`;

    setIsSending(true);
    fetch(`${endpoint}`, {
      method: 'GET',
      headers: new Headers({
        Authorization: `Bearer ${getToken()}`,
      }),
    })
      .then(res => res.json())
      .then((result: RemoteAccessMaxAccountInactivity) => {
        setMaxInactivityDays(result.maxInactivityDays);
      })
      .then(() => {
        setIsSending(false);
      });
  }, []);

  useEffect(() => {
    if (userProvidedInput) {
      const endpoint = `${REMOTE_ACCESS_PROVISIONING_MAX_ACCOUNT_INACTIVITY}`;

      const payload: RemoteAccessMaxAccountInactivity = {
        maxInactivityDays,
      };

      fetch(`${endpoint}`, {
        method: 'POST',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
        body: JSON.stringify(payload),
      });
    }
  }, [maxInactivityDays, userProvidedInput]);

  return (
    <React.Fragment>
      <List.Item>
        <List.Item.Meta
          key="remoteAccessMaxInactivity"
          style={{ alignItems: 'inherit' }}
          avatar={<IdcardOutlined style={IconStyle} />}
          title={
            <div styleName="item-title">
              Remote Access Account Maximum Inactivity (Days)
            </div>
          }
          description="Maximum number of days a remote access account can be inactive before being flagged for removal.  (Zero = accounts have no inactivity limitation)"
        />
        <div>
          <InputNumber
            min={0}
            value={maxInactivityDays}
            onChange={handleMaxInactivityChanged}
            style={{ maxWidth: '75px' }}
          />
        </div>
      </List.Item>
    </React.Fragment>
  );
};

export default RemoteAccessSettings;
