import actionTypes from './actionTypes';

export const requestRegionCreation = data => ({
  type: actionTypes.REQUEST_CREATE_REGION,
  data,
});

export const createRegionSuccess = data => ({
  type: actionTypes.CREATE_REGION_SUCCESS,
  data,
});

export const createRegionFailure = () => ({
  type: actionTypes.CREATE_REGION_FAILURE,
});

export const retrieveRegions = data => ({
  type: actionTypes.REQUEST_REGIONS,
  data,
});

export const getRegionsSuccess = data => ({
  type: actionTypes.REGIONS_GET_SUCCESS,
  data,
});

export const getRegionsFailure = () => ({
  type: actionTypes.REGIONS_GET_FAILURE,
});

export const getRegionsTotalSuccess = data => ({
  type: actionTypes.REGIONS_TOTAL_GET_SUCCESS,
  data,
});

export const requestRegionUpdate = data => ({
  type: actionTypes.REQUEST_REGION_UPDATE,
  data,
});

export const regionUpdateSuccess = data => ({
  type: actionTypes.REGION_UPDATE_SUCCESS,
  data,
});

export const regionUpdateFailure = () => ({
  type: actionTypes.REGION_UPDATE_FAILURE,
});
