import React, { useCallback, useContext, useState } from 'react';
import { CloseSquareOutlined } from '@ant-design/icons';
import { Input } from 'antd';

import DeviceTypeContext from '@totem/components/deviceTypes/DeviceTypeContext';
import { debounce } from '@totem/utilities/debounce';
const DEBOUNCE_TIME = 300;
const DeviceTypeFilters = () => {
  const { input, setInput } = useContext(DeviceTypeContext);
  const [nameSearchTerm, setNameSearchTerm] = useState<string>('');

  const updateName = useCallback(
    debounce(
      (newSearchTerm: string) =>
        setInput({ ...input, searchName: newSearchTerm }),
      DEBOUNCE_TIME,
    ),
    [],
  );

  const handleNameSearch = (newNameSearch: string) => {
    setNameSearchTerm(newNameSearch);
    updateName(newNameSearch);
  };

  return (
    <table>
      <tbody>
        <tr>
          <td style={{ width: '300px' }}>
            <b>Name:</b>&nbsp;
            <Input
              placeholder="Search"
              value={nameSearchTerm}
              onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                evt.target.value !== null
                  ? handleNameSearch(evt.target.value)
                  : handleNameSearch('')
              }
            />
          </td>
          <td>
            <br />
            <CloseSquareOutlined onClick={() => handleNameSearch('')} />
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default DeviceTypeFilters;
