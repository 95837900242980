import React, { useContext } from 'react';
import { Card, Col, Row, Tabs, TabsProps } from 'antd';

import ComponentHeader from '@totem/components/ComponentHeader';
import PageSectionBody from '@totem/components/PageSectionBody';
import ComplianceContext from '@totem/components/surveyV2/complianceSummary/complianceContext';
import PolicyCompliance from '@totem/components/surveyV2/complianceSummary/policyCompliance/policyCompliance';
import PortfolioHealthComplianceSummary from '@totem/components/surveyV2/complianceSummary/portfolioHealthOverview/portfolioHealthComplianceSummary';
import FindingsReportContainer from '@totem/components/surveyV2/findingsReport/findingsReportContainer';
import FindingsTable from '@totem/components/surveyV2/findingsReport/FindingsTable';
import LeastCompliantByCategory from '@totem/components/surveyV2/findingsReport/leastCompliantByCategory';
import PolicyResults from '@totem/components/surveyV2/findingsReport/PolicyResults';
import TabTitle from '@totem/components/TabTitle';
import colors from '@totem/styles/colors';
import { isNotNull } from '@totem/utilities/common';

import './controlSystemDetail.css';

const styles = {
  cardHeader: {
    borderBottom: `1px solid ${colors.antd.borderGray}`,
  },
  cardBody: {
    borderTop: `1px solid ${colors.antd.borderGray}`,
    padding: '0 0 3rem 0',
  },
};
enum TabType {
  OVERVIEW = 'Overview',
  CONTINUITY = 'System Continuity',
  AUDIT = 'Policy Audit',
  FINDINGS = 'Findings',
}

const ComplianceSelector = () => {
  const { data, input } = useContext(ComplianceContext);

  const renderTabBar = (props: TabsProps, DefaultTabBar: any) => {
    return (
      <div styleName="tabs-container">
        <DefaultTabBar {...props} />
      </div>
    );
  };

  const getTabItems = () => {
    const tabItems: TabsProps['items'] = [
      {
        key: TabType.OVERVIEW,
        label: <TabTitle>{TabType.OVERVIEW}</TabTitle>,
        children: (
          <>
            <PortfolioHealthComplianceSummary title={'Health Audit Summary'} />
            <br />
            <div style={{ padding: '2em' }}>
              <PolicyCompliance />
            </div>
          </>
        ),
      },
    ];

    if (isNotNull(data) && data.auditedControlSystems > 0) {
      tabItems.push({
        key: TabType.AUDIT,
        label: <TabTitle>{TabType.FINDINGS}</TabTitle>,
        children: (
          <FindingsReportContainer
            regionIds={input.regionId}
            buildingIds={input.buildingId}
            controlSystemIds={input.controlSystemId}
          >
            <ComponentHeader title={'Findings Overview'} />
            <PageSectionBody>
              <Row>
                <Col span={6}>
                  <PolicyResults />
                </Col>
                <Col span={18}>
                  <LeastCompliantByCategory />
                </Col>
              </Row>
            </PageSectionBody>
            <ComponentHeader title={'Findings'} />
            <FindingsTable />
          </FindingsReportContainer>
        ),
      });
    }
    return tabItems;
  };

  return (
    <Card
      title={<div styleName="card-header-title">Policy Compliance</div>}
      headStyle={styles.cardHeader}
      bodyStyle={styles.cardBody}
    >
      <Tabs
        defaultActiveKey={TabType.OVERVIEW}
        renderTabBar={renderTabBar}
        items={getTabItems()}
      />
    </Card>
  );
};

export default ComplianceSelector;
