import React, { useCallback, useContext, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import {
  FileOutlined,
  FileProtectOutlined,
  LogoutOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { Layout, Menu, MenuProps } from 'antd';

import ReportsContext from '@totem/components/ui_components/reports/ReportsContext';
import {
  staticBranding,
  staticLogoLarge,
  staticLogoSmall,
} from '@totem/selectors/preferencesSelectors';
import colors from '@totem/styles/colors';
import { BrandingPreference } from '@totem/types/organization';
import accountUtilities from '@totem/utilities/accountUtilities';
import { isNotNull } from '@totem/utilities/common';

import './base.css';

const { Sider } = Layout;

const styles = {
  sider: {
    background: colors.neutral.white,
    borderRight: `2px solid ${colors.neutral.typhoon}`,
  },
};

const getLogoStyle = (
  collapsed: boolean,
  branding: BrandingPreference,
): React.CSSProperties => {
  if (collapsed) {
    return branding === BrandingPreference.TOTEM
      ? { height: '100%' }
      : { height: '55px' };
  }

  return branding === BrandingPreference.TOTEM ? { height: '100%' } : {};
};

const LeftNav = () => {
  const navigate = useNavigate();
  const { reports } = useContext(ReportsContext);
  const [collapsed, setCollapsed] = useState<boolean>(false);

  const hasReports = isNotNull(reports) && reports.length > 0;

  const handleLogout = useCallback(() => {
    accountUtilities.logout();
    navigate('/login');
  }, []);

  const hanleCollapse = useCallback(() => {
    setCollapsed(!collapsed);
  }, [collapsed]);

  const onMenuClicked = ({ key }) => {
    // eslint-disable-next-line default-case
    switch (key) {
      case 'logout':
        handleLogout();
        break;
    }
  };

  const getSubMenuReports = () => {
    const items: MenuProps['items'] = [];

    if (hasReports) {
      for (let idx = 0; idx < reports.length; idx++) {
        items.push({
          key: reports[idx].id,
          title: reports[idx].name,
          label: (
            <NavLink to={`/tenant-dashboard/${reports[idx].link}`}>
              {reports[idx].name}
            </NavLink>
          ),
          icon: <FileOutlined />,
        });
      }
    }

    return items;
  };

  const getMenuItems = () => {
    const items: MenuProps['items'] = [];

    if (hasReports) {
      items.push({
        key: 'reports',
        title: 'Reports',
        label: 'Reports',
        icon: <FileProtectOutlined />,
        children: getSubMenuReports(),
      });
    }

    items.push({
      key: 'userProfile',
      title: 'User Profile',
      label: <NavLink to="/tenant-dashboard/userProfile">User Profile</NavLink>,
      icon: <SettingOutlined />,
    });

    items.push({
      key: 'logout',
      title: 'Sign Out',
      label: <span>Sign Out</span>,
      icon: <LogoutOutlined />,
    });

    return items;
  };

  return (
    <Sider
      collapsible
      style={styles.sider}
      collapsed={collapsed}
      onCollapse={hanleCollapse}
    >
      <div
        styleName="logo"
        onClick={() => navigate('/tenant-dashboard')}
        style={
          !collapsed &&
          //brandingSelector(userProfile) ===
          staticBranding === BrandingPreference.INTELLIGENT_BUILDINGS
            ? { margin: '0 0 4rem 0' }
            : {}
        }
      >
        <img
          src={collapsed ? staticLogoSmall : staticLogoLarge}
          style={getLogoStyle(collapsed, staticBranding)}
        />
      </div>
      <Menu
        theme="light"
        mode="inline"
        onClick={onMenuClicked}
        items={getMenuItems()}
      />
    </Sider>
  );
};

export default LeftNav;
