import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Card } from 'antd';

import SummaryContext from '@totem/components/portfolio/summary/summaryContext';
import colors from '@totem/styles/colors';
import IBDIcon from '@totem/styles/Icon';
import { isNotNull, isNull } from '@totem/utilities/common';

import './summary.css';

const styles = {
  card: {
    marginTop: '2rem',
    width: '100%',
  },
  cardHeader: {
    borderTop: `5px solid`,
    borderBottom: `1px solid ${colors.antd.borderGray}`,
    borderImage: `${colors.gradient.accent} 100% 0 0 0`,
  },
  cardBody: {
    overflow: 'scroll',
    height: '34rem',
    borderTop: `1px solid ${colors.antd.borderGray}`,
  },
};

const Regions = () => {
  const { data, selectedRegionIds, setSelectedRegionIds } =
    useContext(SummaryContext);

  return (
    <Card
      title={
        <div styleName="card-header-container">
          <div styleName="card-header-title">Regions</div>
          <div>
            <Link to={'/dashboard/regions'}>
              <IBDIcon name="Edit" />
            </Link>
          </div>
        </div>
      }
      style={styles.card}
      bodyStyle={styles.cardBody}
    >
      <>
        <div
          onClick={() => setSelectedRegionIds([])}
          styleName={`portfolio-item ${
            isNull(selectedRegionIds) || selectedRegionIds.length === 0
              ? 'selected'
              : ''
          }`}
        >
          Portfolio
        </div>
        {isNotNull(data) &&
          data.map(({ id, name }) => {
            return (
              <React.Fragment key={id}>
                <div
                  onClick={() => setSelectedRegionIds([id])}
                  styleName={`portfolio-item portfolio-indent ${
                    selectedRegionIds.findIndex((chk) => chk === id) >= 0
                      ? 'selected'
                      : ''
                  }`}
                >
                  {name}
                </div>
              </React.Fragment>
            );
          })}
      </>
    </Card>
  );
};

export default Regions;
