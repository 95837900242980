import React, { useContext, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  DownloadOutlined,
  EllipsisOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import { Button, Dropdown, MenuProps, Tooltip } from 'antd';
import { ColumnProps, TablePaginationConfig } from 'antd/es/table';
import { SorterResult } from 'antd/lib/table/interface';
import { filesize } from 'filesize';
import * as R from 'ramda';

import Table from '@totem/components/common/table/Table';
import colors from '@totem/styles/colors';
import { ControlSystem, ControlSystemType } from '@totem/types/controlSystem';
import {
  ControlSystemBackup,
  ControlSystemBackupSource,
} from '@totem/types/controlSystemBackup';
import { getToken } from '@totem/utilities/accountUtilities';
import { getDashboardPath } from '@totem/utilities/authUtilities';
import controlSystemUtilities, {
  controlSystemTypeMap,
  getBackupStatusIcon2,
  getControlSystemBackupStatus,
} from '@totem/utilities/controlSystemsUtilities';
import { CONTROL_SYSTEM_BACKUPS_ENDPOINT } from '@totem/utilities/endpoints';
import { removeIntegerKeys } from '@totem/utilities/enumUtilities';
import { createLink } from '@totem/utilities/fileUtilities';
import {
  getLimitAndOffsetFromTablePagination,
  getTablePagination,
} from '@totem/utilities/paginationUtilities';
import { stringifyArray } from '@totem/utilities/tableFilterUtilities';
import { CONTROL_SYSTEM_BACKUP_STATUS_TOOLTIP } from '@totem/utilities/tooltipUtilities';

import AutoCompleteFilter from '../common/autoCompleteFilter/AutoCompleteFilter';

import ControlSystemBackupsContext from './ControlSystemBackupsContext';

import './controlSystemBackups.css';

const getLatestBackup = (controlSystem: ControlSystem) => {
  return R.path<ControlSystemBackup>(
    ['backups', 'controlSystemBackups', 0],
    controlSystem,
  );
};

const getFileSize = (size: number): string => {
  if (!size) {
    return '-';
  }

  return filesize(size, { standard: 'jedec' });
};

// non-downloadable backups
const DISABLED_BACKUP_SOURCE = new Set<ControlSystemBackupSource>([
  ControlSystemBackupSource.ACRONIS,
]);

const CONTROL_SYSTEM_TYPE_FILTERS = Object.values(
  removeIntegerKeys(ControlSystemType),
).map((type) => ({
  text: controlSystemTypeMap[type]?.text,
  value: type,
}));

const styles = {
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  tooltip: {
    width: '60rem',
  },
};

const ControlSystemBackupsTable = () => {
  const {
    input,
    setInput,
    controlSystems,
    totalCount,
    loading,
    buildingFilter,
    regionFilter,
  } = useContext(ControlSystemBackupsContext);

  const [regionFilterVisible, setRegionFilterVisible] =
    useState<boolean>(false);
  const [buildingFilterVisible, setBuildingFilterVisible] =
    useState<boolean>(false);

  const dataSource = useMemo(() => {
    return controlSystems.map((system) => ({ ...system, key: system.id }));
  }, [controlSystems]);

  const pagination = useMemo(() => {
    return getTablePagination(input, totalCount);
  }, [input, totalCount]);

  const handleTableChange = (
    updatedPagination: TablePaginationConfig,
    filters: SorterResult<ControlSystem>,
  ) => {
    const { field, order, ...params } = filters;

    const sortBy = !order ? null : field;
    const sortOrder = !order ? null : order;

    const { limit, offset } =
      getLimitAndOffsetFromTablePagination(updatedPagination);

    // @ts-ignore
    setInput({ ...input, ...params, limit, offset, sortBy, sortOrder });
  };

  const getActionMenuItems = (
    backup: ControlSystemBackup,
  ): MenuProps['items'] => {
    return [
      {
        key: 'download_file',
        label: 'Download File',
        title: 'Download File',
        icon: <DownloadOutlined />,
        disabled: !backup.size || DISABLED_BACKUP_SOURCE.has(backup.source),
      },
    ];
  };

  const onMenuClicked = (backupId: string, key: string | number) => {
    // eslint-disable-next-line default-case
    switch (key) {
      case 'download_file':
        createLink(
          `${CONTROL_SYSTEM_BACKUPS_ENDPOINT}/${backupId}/download?token=${getToken()}`,
        );
        break;
    }
  };

  const columns: ColumnProps<ControlSystem>[] = [
    {
      title: 'Control System',
      dataIndex: 'name',
      render: (name: string, controlSystem) => (
        <Link to={`${getDashboardPath()}/controlsystems/${controlSystem.id}`}>
          {name}
        </Link>
      ),
      showSorterTooltip: true,
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        compA.name === compB.name ? 0 : compA.name > compB.name ? 1 : -1,
    },
    {
      title: 'Control System Type',
      key: 'systemType',
      dataIndex: 'systemType',
      // @ts-ignore
      filters: CONTROL_SYSTEM_TYPE_FILTERS,
      filterMultiple: true,
      filteredValue: stringifyArray(input.systemType),
      render: (systemType: ControlSystemType) => {
        const option = controlSystemUtilities.controlSystemTypeMap[systemType];
        return option ? option.text : '';
      },
    },
    // {
    //   title: 'Source',
    //   dataIndex: 'reporter',
    //   render: (_, controlSystem) => {
    //     const backup = getLatestBackup(controlSystem);
    //     return backup ? controlSystemBackupSourceLabels[backup.source] : null;
    //   },
    // },
    {
      title: 'Date',
      dataIndex: 'date',
      render: (_, controlSystem) => {
        const backup = getLatestBackup(controlSystem);
        return backup ? new Date(backup.createdAt).toLocaleDateString() : null;
      },
    },
    {
      title: 'Time',
      dataIndex: 'time',
      render: (_, controlSystem) => {
        const backup = getLatestBackup(controlSystem);
        return backup ? new Date(backup.createdAt).toLocaleTimeString() : null;
      },
    },
    {
      title: 'Backup Size',
      dataIndex: 'size',
      render: (_, controlSystem) => {
        const backup = getLatestBackup(controlSystem);
        return backup ? getFileSize(backup.size) : null;
      },
    },
    {
      title: (
        <div className="tooltip-table-header">
          <div>Status</div>
          <Tooltip
            title={CONTROL_SYSTEM_BACKUP_STATUS_TOOLTIP}
            overlayStyle={styles.tooltip}
          >
            <InfoCircleOutlined
              style={{
                color: colors.neutral.gray,
                cursor: 'pointer',
                marginLeft: '0.5rem',
              }}
            />
          </Tooltip>
        </div>
      ),
      dataIndex: 'backupStatus',
      width: '66px',
      align: 'center',
      render: (_, controlSystem) =>
        getBackupStatusIcon2(
          controlSystem.backupStatus,
          getControlSystemBackupStatus(controlSystem),
        ),
    },
    {
      title: 'Building',
      dataIndex: 'building',
      render: (building: { name: string }) => building.name,
      // @ts-ignore
      filterDropdown: () => (
        <AutoCompleteFilter
          label="Building"
          visible={buildingFilterVisible}
          onClose={() => setBuildingFilterVisible(false)}
          {...buildingFilter}
        />
      ),
      filterDropdownOpen: buildingFilterVisible,
      onFilterDropdownOpenChange: setBuildingFilterVisible,
      filteredValue: R.keys(buildingFilter.applied),
    },
    {
      title: 'Region',
      dataIndex: 'region',
      render: (region: { name: string }) => region.name,
      // @ts-ignore
      filterDropdown: () => (
        <AutoCompleteFilter
          label="Region"
          visible={regionFilterVisible}
          onClose={() => setRegionFilterVisible(false)}
          {...regionFilter}
        />
      ),
      filterDropdownOpen: regionFilterVisible,
      onFilterDropdownOpenChange: setRegionFilterVisible,
      filteredValue: R.keys(regionFilter.applied),
    },
    {
      title: 'Actions',
      dataIndex: 'source',
      width: 50,
      render: (_, controlSystem) => {
        const backup = getLatestBackup(controlSystem);

        return !backup ? null : (
          <div style={styles.center}>
            <Dropdown
              menu={{
                items: getActionMenuItems(backup),
                onClick: ({ key }) => onMenuClicked(backup.id, key),
              }}
              trigger={['click']}
            >
              <Button shape="circle" icon={<EllipsisOutlined />} />
            </Dropdown>
          </div>
        );
      },
    },
  ];

  return (
    <Table
      dataSource={dataSource}
      columns={columns}
      pagination={pagination}
      loading={loading}
      onChange={handleTableChange}
    />
  );
};

export default ControlSystemBackupsTable;
