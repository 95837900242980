import { ReduxStore } from '@totem/types/store';

const initialState: ReduxStore = {
  breadcrumb: ['Building Security'],
  media: [],
  auth: {
    isVerified: false,
    isLoggedIn: false,
    permissions: [],
  },
  loading: {
    loadingRegions: true,
    loadingRegionCreation: false,
    loadingRegionUpdate: false,
    loadingBuildings: true,
    loadingBuildingUpdate: false,
    loadingBuildingDelete: false,
    loadingFileUpload: false,
    loadingNmapAnalysisUpdate: false,
    loadingSurveyTemplates: false,
    loadingSurveyTemplateCreation: false,
    loadingSurveyTemplateDetail: false,
    loadingSurveyTemplateDeletion: false,
    loadingSurveyTemplateConfiguration: false,
    loadingSurveyInstances: false,
    loadingSurveyInstanceCreation: false,
    loadingSurveyInstanceDetail: true,
    loadingSurveyInstanceSubmit: false,
    loadingSurveyInstanceUpdate: false,
    loadingSurveyInstancePatch: false,
    loadingSurveyInstanceStatistics: false,
    loadingMedia: false,
    loadingHealth: false,
    loadingHealthCreation: false,
    loadingSurveyCSVExport: false,
    loadingPolicyAuditDetail: false,
    loadingPolicyAuditDetailPatch: false,
    loadingRemoteAccessUsers: false,
  },
  regions: [],
  buildings: [],
  notification: {
    open: false,
  },
  regionsTotal: 0,
  buildingsTotal: 0,
  versions: {},
  surveyTemplateConfiguration: {
    questionTypes: [],
    levelScoreOptions: {},
  },
  surveyTemplates: [],
  surveyTemplateDetail: {
    id: '',
    name: '',
    title: '',
    description: '',
    helpText: '',
    levelTypes: [],
    levels: [],
    categories: [],
    version: 0,
    isPublished: false,
  },
  surveyInstances: [],
  surveyInstancesTotal: 0,
  surveyInstanceDetail: {
    id: '',
    templateId: '',
    organizationId: '',
    buildingId: '',
    name: '',
    title: '',
    description: '',
    helpText: '',
    levels: [],
    levelTypes: [],
    categories: [],
    maxScore: 0,
    totalScore: 0,
    totalQuestions: 0,
    completedQuestions: 0,
    criticalFindings: 0,
    assignee: '',
    isLaunched: false,
    isSubmitted: false,
    isDeleted: false,
    templateVersion: 0,
  },
  surveyInstanceStatistics: {
    total: 0,
    offTrack: 0,
    atRisk: 0,
    onTrack: 0,
    criticalFindings: 0,
    inProgress: 0,
    completed: 0,
    notStarted: 0,
    overdue: 0,
  },
  health: [],
  policyAuditDetail: {
    id: '',
    organization: {
      id: '',
      name: '',
    },
    region: {
      id: '',
      name: '',
    },
    building: {
      id: '',
      name: '',
    },
    controlSystem: {
      id: '',
      name: '',
      email: '',
    },
    assignee: null,
    questionGroups: {},
    questions: {},
    completedQuestions: 0,
    totalQuestions: 0,
    outstandingQuestions: 0,
    startDate: 0,
    dueDate: 0,
    submitted: false,
    isLaunched: false,
  },
  userRestrictions: {
    restrictions: [],
    hierarchy: {
      id: '',
      name: '',
      children: [],
    },
  },
  remoteAccessUsers: [],
  remoteAccessOrgRoles: null,
  remoteAccessRoleUsers: null,
  remoteAccessUserRoles: null,
};

export default initialState;
