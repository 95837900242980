import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  CheckCircleOutlined,
  EditOutlined,
  EllipsisOutlined,
} from '@ant-design/icons';
import { Badge } from 'antd';
import { TablePaginationConfig } from 'antd/es/table';
import { ColumnProps } from 'antd/lib/table';
import * as R from 'ramda';

import AuditHealthModal from '@totem/components/common/auditHealthModal/AuditHealthModal';
import AutoCompleteFilter from '@totem/components/common/autoCompleteFilter/AutoCompleteFilter';
import CriticalFindingsTooltip from '@totem/components/common/findings/CriticalFindingsTooltip';
import Table from '@totem/components/common/table/Table';
import Tooltip from '@totem/components/common/tooltip/Tooltip';
import colors from '@totem/styles/colors';
import fonts from '@totem/styles/fonts';
import { Building } from '@totem/types/building';
import { ControlSystem, ControlSystemType } from '@totem/types/controlSystem';
import { HEALTH_STATUS_ENUM, HealthInput } from '@totem/types/health';
import {
  PolicyAudit,
  PolicyAuditCompletionStatus,
  PolicyAuditsConnectionInput,
  PolicyAuditUpdateInput,
  Scores,
} from '@totem/types/policyAudit';
import { Region } from '@totem/types/region';
import controlSystemUtilities, {
  controlSystemTypeMap,
} from '@totem/utilities/controlSystemsUtilities';
import {
  removeIntegerKeys,
  toTableFilter,
} from '@totem/utilities/enumUtilities';
import {
  getBadgeStatus,
  getHealthStatusString,
} from '@totem/utilities/healthUtilities';
import {
  getLimitAndOffsetFromTablePagination,
  getTablePagination,
} from '@totem/utilities/paginationUtilities';
import {
  parseBooleanString,
  stringifyArray,
  stringifyBooleanValue,
} from '@totem/utilities/tableFilterUtilities';
import { isFutureDate } from '@totem/utilities/timeUtilities';
import { isUserDeactivated } from '@totem/utilities/userUtilities';

import PolicyAuditDateModal, { DateModalType } from './PolicyAuditDateModal';
import PolicyAuditsContext from './PolicyAuditsContext';

import './policyAudits.css';

interface Props {
  onChange: (input: Partial<PolicyAuditsConnectionInput>) => void;
  onAddHealth: (input: HealthInput) => void;
  addHealthLoading: boolean;
  onPolicyAuditUpdate: (input: PolicyAuditUpdateInput) => void;
  updatePolicyAuditLoading: boolean;
}

const styles = {
  criticalFindings: {
    backgroundColor: colors.neutral.gray,
  },
  criticalFindingsDanger: {
    backgroundColor: colors.brand.red,
  },
  submittedIcon: {
    color: colors.brand.green,
    marginRight: '0.5rem',
    fontSize: '20px',
  },
  icon: {
    cursor: 'pointer',
    color: colors.opacity.black0_4,
    fontSize: fonts.fontLg,
    marginLeft: '1rem',
  },
  pastDueDate: {
    background: 'rgba(237, 54, 32, 0.25)',
  },
  inactive: {
    color: colors.neutral.inactive,
  },
};

const HEALTH_FILTERS = Object.keys(HEALTH_STATUS_ENUM)
  .map((key) => ({
    text: key,
    value: HEALTH_STATUS_ENUM[key],
  }))
  .concat({ text: 'Needed', value: -1 });

const CONTROL_SYSTEM_TYPE_FILTERS = Object.values(
  removeIntegerKeys(ControlSystemType),
).map((type) => ({
  text: controlSystemTypeMap[type]?.text,
  value: type,
}));

const START_DATE_FILTERS = [
  {
    text: 'Has Start Date',
    value: 1,
  },
  {
    text: 'No Start Date',
    value: 0,
  },
];

const DUE_DATE_FILTERS = [
  {
    text: 'Has Due Date',
    value: 1,
  },
  {
    text: 'No Due Date',
    value: 0,
  },
];

const IS_LAUNCHED_FILTERS = [
  {
    text: 'Launched',
    value: 1,
  },
  {
    text: 'Not Launched',
    value: 0,
  },
];

const SINGLE_BOOLEAN_FILTERS = ['isLaunched', 'startDate', 'dueDate'];

const PolicyAuditTable = ({
  onChange,
  onAddHealth,
  addHealthLoading,
  onPolicyAuditUpdate,
  updatePolicyAuditLoading,
}: Props) => {
  const {
    input,
    policyAudits,
    totalCount,
    loading,
    regionFilter,
    buildingFilter,
  } = useContext(PolicyAuditsContext);

  const [regionFilterVisible, setRegionFilterVisible] =
    useState<boolean>(false);
  const [buildingFilterVisible, setBuildingFilterVisible] =
    useState<boolean>(false);
  const [healthModalVisible, setHealthModalVisible] = useState<boolean>(false);
  const [dateModalVisible, setDateModalVisible] = useState<boolean>(false);
  const [selectedAudit, setSelectedAudit] = useState<PolicyAudit>(null);
  const [dateModalType, setDateModalType] = useState<DateModalType>(
    DateModalType.START,
  );

  const handleHealthClick = (audit: PolicyAudit) => {
    setSelectedAudit(audit);
    setHealthModalVisible(true);
  };

  useEffect(() => {
    if (!addHealthLoading) {
      setHealthModalVisible(false);
    }
  }, [addHealthLoading]);

  useEffect(() => {
    if (!updatePolicyAuditLoading) {
      setDateModalVisible(false);
    }
  }, [updatePolicyAuditLoading]);

  const columns: ColumnProps<PolicyAudit>[] = [
    {
      title: 'Name',
      key: 'controlSystem',
      dataIndex: 'controlSystem',
      render: ({ name }: ControlSystem, { id, submitted }: PolicyAudit) => {
        const link = submitted
          ? `/dashboard/policyaudits/${id}/report`
          : `/dashboard/policyaudits/${id}/`;

        return <Link to={link}>{name}</Link>;
      },
    },
    {
      title: 'Health',
      dataIndex: 'health',
      // @ts-ignore
      filters: HEALTH_FILTERS,
      filterMultiple: true,
      filteredValue: stringifyArray(input.health),
      render: (text: string, audit: PolicyAudit) => {
        const { health } = audit;

        return (
          <div styleName="health-cell" onClick={() => handleHealthClick(audit)}>
            <div styleName="table-health-status-container">
              <Badge
                status={getBadgeStatus(health)}
                className="health-status-badge-sm"
              />
              <div styleName="table-health-status">
                {getHealthStatusString(health)}
              </div>
            </div>
            <EllipsisOutlined style={styles.icon} />
          </div>
        );
      },
    },
    {
      title: 'Launched',
      key: 'isLaunched',
      filters: IS_LAUNCHED_FILTERS,
      filterMultiple: false,
      filteredValue: stringifyBooleanValue(input.isLaunched),
      render: (text, { isLaunched }: PolicyAudit) => {
        return (
          <div
            styleName={isLaunched ? 'submitted' : ''}
            className="center-table-cell"
          >
            {isLaunched && (
              <Tooltip title="Launched">
                <CheckCircleOutlined style={styles.submittedIcon} />
              </Tooltip>
            )}
          </div>
        );
      },
    },
    {
      title: 'Progress',
      key: 'completionStatus',
      filters: toTableFilter(PolicyAuditCompletionStatus),
      filterMultiple: true,
      filteredValue: stringifyArray(input.completionStatus),
      render: (
        text,
        { totalQuestions, completedQuestions, submitted }: PolicyAudit,
      ) => {
        const progress = Math.floor(
          (completedQuestions / totalQuestions) * 100,
        );

        return (
          <div styleName={submitted ? 'submitted' : ''}>
            {submitted && (
              <Tooltip title="Submitted">
                <CheckCircleOutlined style={styles.submittedIcon} />
              </Tooltip>
            )}
            {progress}%
          </div>
        );
      },
    },
    {
      title: 'Score',
      dataIndex: 'scores',
      render: (scores: Scores) => `${scores.summary}%`,
    },
    {
      dataIndex: 'criticalFindingsCount',
      title: (): React.ReactNode => (
        <div styleName="critical-findings-title">
          <div>CFs</div>
          <div styleName="critical-findings-icon">
            <CriticalFindingsTooltip />
          </div>
        </div>
      ),
      render: (criticalFindingsCount) => (
        <Badge
          count={criticalFindingsCount}
          style={
            criticalFindingsCount
              ? styles.criticalFindingsDanger
              : styles.criticalFindings
          }
          showZero
        />
      ),
    },
    {
      title: 'Assignee',
      key: 'assignee',
      dataIndex: 'assignee',
      render: (text, { assignee }: PolicyAudit) =>
        isUserDeactivated(assignee) ? (
          <div style={styles.inactive}>{assignee?.email} (Inactive)</div>
        ) : (
          <div>{assignee?.email}</div>
        ),
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      filters: START_DATE_FILTERS,
      filterMultiple: false,
      filteredValue: stringifyBooleanValue(input.startDate),
      render: (date: number, policyAudit: PolicyAudit) => (
        <div
          styleName="date-table-cell"
          onClick={() => {
            setSelectedAudit(policyAudit);
            setDateModalType(DateModalType.START);
            setDateModalVisible(true);
          }}
        >
          <div>{date ? new Date(date).toLocaleDateString() : ''}</div>
          <EditOutlined style={styles.icon} />
        </div>
      ),
    },
    {
      title: 'Due Date',
      dataIndex: 'dueDate',
      filters: DUE_DATE_FILTERS,
      filterMultiple: false,
      filteredValue: stringifyBooleanValue(input.dueDate),
      render: (date: number, policyAudit: PolicyAudit) => {
        const { submitted } = policyAudit;

        return {
          props: {
            style: isFutureDate(date) && !submitted ? styles.pastDueDate : {},
          },
          children: (
            <div
              styleName="date-table-cell"
              onClick={() => {
                setSelectedAudit(policyAudit);
                setDateModalType(DateModalType.DUE);
                setDateModalVisible(true);
              }}
            >
              <div>{date ? new Date(date).toLocaleDateString() : ''}</div>
              <EditOutlined style={styles.icon} />
            </div>
          ),
        };
      },
    },
    {
      title: 'Region',
      dataIndex: 'region',
      render: ({ name }: Region) => name,
      filterDropdown: () => (
        <AutoCompleteFilter
          label="Region"
          visible={regionFilterVisible}
          onClose={() => setRegionFilterVisible(false)}
          {...regionFilter}
        />
      ),
      filterDropdownOpen: regionFilterVisible,
      onFilterDropdownOpenChange: setRegionFilterVisible,
      filteredValue: R.keys(regionFilter.applied),
    },
    {
      title: 'Building',
      dataIndex: 'building',
      render: ({ name }: Building) => name,
      filterDropdown: () => (
        <AutoCompleteFilter
          label="Building"
          visible={buildingFilterVisible}
          onClose={() => setBuildingFilterVisible(false)}
          {...buildingFilter}
        />
      ),
      filterDropdownOpen: buildingFilterVisible,
      onFilterDropdownOpenChange: setBuildingFilterVisible,
      filteredValue: R.keys(buildingFilter.applied),
    },
    {
      title: 'Control System Type',
      key: 'controlSystemType',
      dataIndex: 'controlSystem',
      // @ts-ignore
      filters: CONTROL_SYSTEM_TYPE_FILTERS,
      filterMultiple: true,
      filteredValue: stringifyArray(input.controlSystemType),
      render: ({ systemType }: ControlSystem) => {
        const option = controlSystemUtilities.controlSystemTypeMap[systemType];
        return option ? option.text : '';
      },
    },
  ];

  const handleChange = (pagination: TablePaginationConfig, filters: object) => {
    const update = Object.entries(filters).reduce((acc, [key, value]) => {
      if (SINGLE_BOOLEAN_FILTERS.includes(key)) {
        return { ...acc, [key]: parseBooleanString(value) };
      }

      return { ...acc, [key]: value };
    }, {});

    const { limit, offset } = getLimitAndOffsetFromTablePagination(pagination);

    onChange({ ...update, limit, offset });
  };

  const pagination = useMemo(() => {
    return getTablePagination(input, totalCount);
  }, [input, totalCount]);

  return (
    <>
      <Table
        rowKey="id"
        columns={columns}
        loading={loading}
        dataSource={policyAudits}
        pagination={pagination}
        onChange={handleChange}
      />
      {selectedAudit && (
        <>
          <AuditHealthModal
            subjectId={selectedAudit.id}
            visible={healthModalVisible}
            loading={addHealthLoading}
            onClose={() => setHealthModalVisible(false)}
            onSubmit={onAddHealth}
            title="Update Health: Policy Audit"
          />
          <PolicyAuditDateModal
            policyAudit={selectedAudit}
            visible={dateModalVisible}
            loading={updatePolicyAuditLoading}
            type={dateModalType}
            onSubmit={onPolicyAuditUpdate}
            onCancel={() => setDateModalVisible(false)}
          />
        </>
      )}
    </>
  );
};

export default PolicyAuditTable;
