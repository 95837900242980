import { createContext } from 'react';

import {
  FilterOptions,
  LayoutDataResponse,
  VariableDataResponseExtended,
} from '@totem/components/devices/variables/presentation/layout/types';

interface Context {
  loading: boolean;
  layoutData: LayoutDataResponse;
  variableData: VariableDataResponseExtended[];
  changeCount: number;
  onAction?: (action: string) => void;
  filterOptions: FilterOptions;
  setFilterOptions: (filterOptions: FilterOptions) => void;
}

export default createContext<Context>({
  loading: false,
  layoutData: null,
  variableData: [],
  changeCount: 0,
  onAction: null,
  filterOptions: {
    location: {
      filterable: false,
      options: [],
      filter: [],
    },
  },
  setFilterOptions: () => {},
});
