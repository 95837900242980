import { createContext } from 'react';

import { SoftwarePatchInstall } from '@totem/components/devices/softrware-patch-installs/types';

interface Context {
  deviceId: string;
  loading: boolean;
  data: SoftwarePatchInstall[];
  action: string;
  actionItem: SoftwarePatchInstall;
  onAction?: (action: string, item: SoftwarePatchInstall) => void;
}

export default createContext<Context>({
  deviceId: '',
  loading: false,
  data: null,
  action: '',
  actionItem: null,
  onAction: () => {},
});
