import { createContext } from 'react';

import { EventGraphData } from '@totem/components/events/graphs/types';

interface Context {
  graphData: EventGraphData;
}

export default createContext<Context>({
  graphData: null,
});
