import React, { useState } from 'react';
import { Button, Input, Modal } from 'antd';

import ModalFooter from '@totem/components/ModalFooter';
import ModalTitle from '@totem/components/ModalTitle';
import { isNotNull } from '@totem/utilities/common';

const styles = {
  dialog: {
    maxHeight: '80%',
    maxWidth: '850px',
  },
  label: {
    fontSize: '12px',
    fontWeight: 600,
  },
};

type Props = {
  name: string;
  open: boolean;
  onCancel: () => void;
  onSubmit: (newName: string) => void;
  allowEmptyString?: boolean;
};

const RenameDialog = ({
  name,
  open,
  onCancel,
  onSubmit,
  allowEmptyString,
}: Props) => {
  const [editName, setEditName] = useState<string>(name);
  const allowEmpty = isNotNull(allowEmptyString) ? allowEmptyString : false;

  return (
    <Modal
      open={open}
      onCancel={() => onCancel()}
      title={<ModalTitle>Rename: {name}</ModalTitle>}
      confirmLoading={false}
      style={styles.dialog}
      footer={
        <ModalFooter>
          <Button onClick={() => onCancel()}>Close</Button>
          <Button
            onClick={() => onSubmit(editName)}
            type="primary"
            disabled={(editName === '' && !allowEmpty) || name === editName}
          >
            Submit
          </Button>
        </ModalFooter>
      }
    >
      <div style={styles.label}>New Name:</div>
      <Input
        value={editName}
        onChange={({ target: { value } }) => setEditName(value)}
      />
    </Modal>
  );
};

export default RenameDialog;
