import React, { ReactNode } from 'react';
import { TeamOutlined } from '@ant-design/icons';
import { Flex } from 'antd';

import './totals.css';

type Props = {
  title: string;
  value: ReactNode;
  iconColor?: string;
  valueColor?: string;
  backgroundColor?: string;
};

const TotalValue = ({
  title,
  value,
  iconColor,
  valueColor,
  backgroundColor,
}: Props) => {
  const styles = {
    icon: {
      color: iconColor,
    },
    title: {
      color: valueColor,
    },
    value: {
      color: valueColor,
    },
    container: {
      backgroundColor,
    },
  };

  return (
    <Flex
      justify={'space-between'}
      align={'center'}
      style={styles.container}
      styleName="container"
    >
      <Flex vertical justify={'center'} align={'center'}>
        <TeamOutlined style={styles.icon} styleName="icon" />
      </Flex>
      <Flex vertical justify={'space-around'} align={'center'}>
        <div style={styles.title} styleName="title">
          {title}
        </div>
        <div style={styles.value} styleName="value">
          {value}
        </div>
      </Flex>
    </Flex>
  );
};

export default TotalValue;
