import React, { useContext } from 'react';
import { Button } from 'antd';

import RemoteAccessUserDetailsContext from '@totem/components/remoteAccess/userDetails/RemoteAccessUserDetailsContext';
import UserProfileContext from '@totem/components/UserProfileContext';
import {
  isMemberOf,
  securityGroupCheckConstraintEmpty,
} from '@totem/utilities/userUtilities';

const HeaderAction = () => {
  const { userProfile } = useContext(UserProfileContext);
  const { roleData, onAction } = useContext(RemoteAccessUserDetailsContext);

  const remoteAccessAdmin = isMemberOf(
    userProfile,
    'remote_access_admin',
    securityGroupCheckConstraintEmpty,
  );

  return (
    <div>
      {typeof roleData !== 'undefined' &&
      roleData !== null &&
      roleData.roles !== null
        ? roleData.roles.length
        : 0}{' '}
      Roles
      {remoteAccessAdmin && (
        <Button
          type="primary"
          onClick={() => onAction('addRoleToUser', null)}
          style={{ marginLeft: '25px' }}
        >
          Add Role
        </Button>
      )}
    </div>
  );
};

export default HeaderAction;
