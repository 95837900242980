import * as React from 'react';
import { DeleteOutlined, SwapOutlined } from '@ant-design/icons';

import '../survey.css';

type Props = {
  title: string;
  children: any;
  titleClicked: () => void;
  onRemove: () => void;
};
const SurveyTemplateBracketCard = ({
  title,
  children,
  titleClicked,
  onRemove,
}: Props) => {
  const titleClickHandler = () => {
    titleClicked();
  };

  const conditionDeleteHandler = () => {
    onRemove();
  };

  return (
    <div styleName="bracket-container">
      <div styleName="bracket-child">
        <div styleName="vertical-icons">
          <SwapOutlined title="Swap Operator" onClick={titleClickHandler} />
          <DeleteOutlined
            title="Delete Group"
            onClick={conditionDeleteHandler}
          />
        </div>
      </div>
      <div styleName="bracket-child bracket">
        <div styleName="vertical" onClick={titleClickHandler}>
          {title}
        </div>
      </div>
      <div styleName="bracket-child content">{children}</div>
    </div>
  );
};

export default SurveyTemplateBracketCard;
