import React, { ReactNode, useEffect, useState } from 'react';

import PolicyContext from '@totem/components/surveyV2/policies/PoliciesContext';
import { SurveyTemplate } from '@totem/components/surveyV2/types';
import { getToken } from '@totem/utilities/accountUtilities';
import { V2_SURVEY_ENDPOINT } from '@totem/utilities/endpoints';

type Props = {
  children?: ReactNode;
};

const PoliciesContainer = ({ children }: Props) => {
  const [refreshData, setRefreshData] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [data, setData] = useState<SurveyTemplate[]>([]);

  useEffect(() => {
    if (refreshData) {
      setRefreshData(false);

      setIsLoading(true);

      fetch(`${V2_SURVEY_ENDPOINT}/policies`, {
        method: 'GET',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
      })
        .then((res) => res.json())
        .then((result: SurveyTemplate[]) => {
          setData(result);
        })
        .then(() => {
          setIsLoading(false);
        });
    }
  }, [refreshData]);

  return (
    <PolicyContext.Provider
      value={{
        data,
        setData,
        loading: isLoading,
        setIsLoading,
      }}
    >
      <div>{children}</div>
    </PolicyContext.Provider>
  );
};

export default PoliciesContainer;
