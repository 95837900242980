import React, { ReactNode, useEffect, useState } from 'react';

import EventFilterContext from '@totem/components/events/eventFilters/EventFilterContext';
import {
  EventFilterInput,
  EventFilterOptions,
  EventFilters,
} from '@totem/components/events/eventFilters/types';
import {
  getDefaultEndDate,
  getDefaultStartDate,
} from '@totem/components/events/graphs/utilities';
import { getToken } from '@totem/utilities/accountUtilities';
import { EVENTS_ENDPOINT } from '@totem/utilities/endpoints';

type Props = {
  children?: ReactNode;
  startTime?: number;
  endTime?: number;
  defaultFilters?: EventFilters;
  staticFilters?: EventFilters;
};

const EventEmbeddedFilterContainer = ({
  children,
  startTime,
  endTime,
  defaultFilters,
  staticFilters,
}: Props) => {
  const [filterOptions, setFilterOptions] = useState<EventFilterOptions>(null);
  const [isSending, setIsSending] = useState(false);
  const [input, updateInput] = useState<EventFilterInput>({
    startTime: startTime !== null ? startTime : getDefaultStartDate().valueOf(),
    endTime: endTime !== null ? endTime : getDefaultEndDate().valueOf(),
    eventType:
      typeof defaultFilters !== 'undefined' &&
      defaultFilters !== null &&
      typeof defaultFilters.eventType !== 'undefined' &&
      defaultFilters.eventType !== null
        ? defaultFilters.eventType
        : [],
    sourceSystem:
      typeof defaultFilters !== 'undefined' &&
      defaultFilters !== null &&
      typeof defaultFilters.sourceSystem !== 'undefined' &&
      defaultFilters.sourceSystem !== null
        ? defaultFilters.sourceSystem
        : [],
    sourceType:
      typeof defaultFilters !== 'undefined' &&
      defaultFilters !== null &&
      typeof defaultFilters.sourceType !== 'undefined' &&
      defaultFilters.sourceType !== null
        ? defaultFilters.sourceType
        : [],
    status:
      typeof defaultFilters !== 'undefined' &&
      defaultFilters !== null &&
      typeof defaultFilters.status !== 'undefined' &&
      defaultFilters.status !== null
        ? defaultFilters.status
        : [],
    severity:
      typeof defaultFilters !== 'undefined' &&
      defaultFilters !== null &&
      typeof defaultFilters.severity !== 'undefined' &&
      defaultFilters.severity !== null
        ? defaultFilters.severity
        : [],
    buildingId:
      typeof defaultFilters !== 'undefined' &&
      defaultFilters !== null &&
      typeof defaultFilters.buildingId !== 'undefined' &&
      defaultFilters.buildingId !== null
        ? defaultFilters.buildingId
        : [],
    regionId:
      typeof defaultFilters !== 'undefined' &&
      defaultFilters !== null &&
      typeof defaultFilters.regionId !== 'undefined' &&
      defaultFilters.regionId !== null
        ? defaultFilters.regionId
        : [],
    deviceId:
      typeof defaultFilters !== 'undefined' &&
      defaultFilters !== null &&
      typeof defaultFilters.deviceId !== 'undefined' &&
      defaultFilters.deviceId !== null
        ? defaultFilters.deviceId
        : [],
    controlSystemId:
      typeof defaultFilters !== 'undefined' &&
      defaultFilters !== null &&
      typeof defaultFilters.controlSystemId !== 'undefined' &&
      defaultFilters.controlSystemId !== null
        ? defaultFilters.controlSystemId
        : [],
    reference:
      typeof defaultFilters !== 'undefined' &&
      defaultFilters !== null &&
      typeof defaultFilters.reference !== 'undefined' &&
      defaultFilters.reference !== null
        ? defaultFilters.reference
        : [],
    searchTerm:
      typeof defaultFilters !== 'undefined' &&
      defaultFilters !== null &&
      typeof defaultFilters.searchTerm !== 'undefined' &&
      defaultFilters.searchTerm !== null
        ? defaultFilters.searchTerm
        : '',
  });

  const setInput = (updated: Partial<EventFilterInput>) => {
    updateInput({ ...input, ...updated });
  };

  useEffect(() => {
    if (isSending) {
      return;
    }
    const endpoint = `${EVENTS_ENDPOINT}/filterOptions`;

    fetch(`${endpoint}`, {
      method: 'GET',
      headers: new Headers({
        Authorization: `Bearer ${getToken()}`,
      }),
    })
      .then((res) => res.json())
      .then((result: EventFilterOptions) => {
        setFilterOptions(result);
      })
      .then(() => {
        setIsSending(false);
      });

    setIsSending(true);
  }, []);

  return (
    <EventFilterContext.Provider
      value={{
        input,
        setInput,
        filterOptions,
        staticFilters,
      }}
    >
      <div>{children}</div>
    </EventFilterContext.Provider>
  );
};

export default EventEmbeddedFilterContainer;
