import React, { ReactNode, useEffect, useState } from 'react';

import QueueTotalsContext from '@totem/components/ticketing/queueTotals/QueueTotalsContext';
import { QueueSummary } from '@totem/components/ticketing/queueTotals/types';
import { getToken } from '@totem/utilities/accountUtilities';
import { TICKETS_ENDPOINT } from '@totem/utilities/endpoints';

type Props = {
  children?: ReactNode;
};
const QueueTotalsContainer = ({ children }: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [data, setData] = useState<QueueSummary[]>([]);

  useEffect(() => {
    setIsLoading(true);
    fetch(`${TICKETS_ENDPOINT}/queue/totals`, {
      method: 'GET',
      headers: new Headers({
        Authorization: `Bearer ${getToken()}`,
      }),
    })
      .then((res) => res.json())
      .then((result: QueueSummary[]) => {
        setData(result);
      })
      .then(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <QueueTotalsContext.Provider
      value={{
        loading: isLoading,
        data,
      }}
    >
      {children}
    </QueueTotalsContext.Provider>
  );
};

export default QueueTotalsContainer;
