import React, { useContext, useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { CopyOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { ColumnProps } from 'antd/lib/table';

import Table from '@totem/components/common/table/Table';
import Tooltip from '@totem/components/common/tooltip/Tooltip';
import FindingsReportContext from '@totem/components/surveyV2/findingsReport/findingsReportContext';
import { PolicyDetail } from '@totem/components/surveyV2/findingsReport/types';
import { getPlainTextFinding } from '@totem/components/surveyV2/findingsReport/utilities';
import { isNotNull } from '@totem/utilities/common';
import { getCriticalityBadge } from '@totem/utilities/criticalityUtilities';

import './findingsReport.css';

const FindingsTable = () => {
  const { data, loading, input } = useContext(FindingsReportContext);
  const [filteredData, setFilteredData] = useState<PolicyDetail[]>([]);

  useEffect(() => {
    if (isNotNull(data)) {
      setFilteredData(data.details);
    } else {
      setFilteredData([]);
    }
  }, [data]);

  const columns: ColumnProps<PolicyDetail>[] = [
    {
      title: 'Findings Description',
      dataIndex: 'description',
      render: (_, finding) => finding.findingDescription || 'N//A',
    },
    {
      title: 'Criticality',
      dataIndex: 'criticality',
      render: (_, finding) => getCriticalityBadge(finding.criticality),
      width: '17rem',
    },
    {
      title: 'Control System',
      dataIndex: 'controlSystemName',
      render: (_, finding) =>
        isNotNull(finding.controlSystemName) ? finding.controlSystemName : '',
      sorter: true,
      // @ts-ignore
      sortOrder: input.sortBy === 'controlSystemName' && input.sortOrder,
    },
    {
      title: 'Assignee',
      dataIndex: 'assignee',
      render: (_, finding) =>
        isNotNull(finding.assignee) ? <div>{finding.assignee}</div> : '',
      sorter: true,
      // @ts-ignore
      sortOrder: input.sortBy === 'assignee' && input.sortOrder,
    },
    {
      title: 'Building',
      dataIndex: 'building',
      render: (_, finding) =>
        isNotNull(finding.building) ? finding.building : '',
      sorter: true,
      // @ts-ignore
      sortOrder: input.sortBy === 'building' && input.sortOrder,
    },
    {
      title: 'Region',
      dataIndex: 'region',
      render: (_, finding) => (isNotNull(finding.region) ? finding.region : ''),
      sorter: true,
      // @ts-ignore
      sortOrder: input.sortBy === 'region' && input.sortOrder,
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      render: (_, finding: PolicyDetail) => (
        <div
          className="center-table-cell"
          onClick={(event) => event.stopPropagation()}
        >
          <CopyToClipboard text={getPlainTextFinding(finding)}>
            <Tooltip title="Copy Finding" placement="left">
              <Button shape="circle" icon={<CopyOutlined />} />
            </Tooltip>
          </CopyToClipboard>
        </div>
      ),
    },
  ];

  const renderDescription = (finding: PolicyDetail) => (
    <div styleName="finding-additional-data-container">
      <div styleName="finding-additional-data-label">Source Question</div>
      <div styleName="finding-additional-data-text">{finding.question}</div>

      <div styleName="finding-additional-data-label">Answer</div>
      <div styleName="finding-additional-data-text">{finding.answer}</div>

      {/*{isNotNull(finding.moreInfo) && finding.moreInfo !== '' && (*/}
      {/*  <>*/}
      {/*    <div styleName="finding-additional-data-label">*/}
      {/*      Additional Information*/}
      {/*    </div>*/}
      {/*    <div styleName="finding-additional-data-text">{finding.moreInfo}</div>*/}
      {/*  </>*/}
      {/*)}*/}

      <div styleName="finding-additional-data-label">Policy</div>
      <div styleName="finding-additional-data-text">{finding.policy}</div>

      <div styleName="finding-additional-data-label">Resolution</div>
      <div styleName="finding-additional-data-text">{finding.resolution}</div>
    </div>
  );

  return (
    <Table
      showSorterTooltip
      columns={columns}
      dataSource={isNotNull(filteredData) ? filteredData : []}
      loading={loading}
      rowKey={(record) =>
        record.region +
        record.building +
        record.controlSystemName +
        record.assignee +
        record.findingDescription
      }
      pagination={{
        showSizeChanger: true,
      }}
      expandable={{
        expandedRowRender: renderDescription,
      }}
    />
  );
};

export default FindingsTable;
