import React from 'react';
import { useNavigate } from 'react-router-dom';
import { TeamOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { ColumnProps } from 'antd/lib/table';

import Table from '@totem/components/common/table/Table';
import Tooltip from '@totem/components/common/tooltip/Tooltip';
import { RemoteAccessAuthTenantRole } from '@totem/types/remoteAccess';
import { emptyId } from '@totem/utilities/userUtilities';

import './remoteAccessRoles.css';

const styles = {
  iconButton: {
    margin: '0 2px',
  },
  launched: {
    fontSize: '20px',
  },
};

export interface Props {
  roles: RemoteAccessAuthTenantRole[];
  regionId?: string;
  buildingId?: string;
  buildingName?: string;
  controlSystemId?: string;
  controlSystemName?: string;
}

const RemoteAccessRoleTable = ({
  roles,
  buildingId,
  buildingName,
  regionId,
  controlSystemId,
  controlSystemName,
}: Props) => {
  const navigate = useNavigate();
  let buildingQueryString = '';
  if (buildingId !== null) {
    buildingQueryString = `?buildingId=${buildingId}&buildingName=${buildingName}`;
  }
  if (regionId !== null) {
    buildingQueryString = `${buildingQueryString}&regionId=${regionId}`;
  }
  if (controlSystemId !== null && controlSystemId !== emptyId) {
    buildingQueryString = `${buildingQueryString}&controlSystemId=${controlSystemId}&controlSystemName=${controlSystemName}`;
  }

  const getColumns = (): ColumnProps<RemoteAccessAuthTenantRole>[] => {
    return [
      {
        title: 'Name',
        dataIndex: 'name',
      },
      {
        title: 'Description',
        dataIndex: 'description',
      },
      {
        title: 'Actions',
        dataIndex: 'roleId',
        width: '84',
        render: (roleId: string) => (
          <div className="center-table-cell">
            <Tooltip title="Edit Role Membership" placement="top">
              <Button
                type="primary"
                icon={<TeamOutlined />}
                shape="circle"
                onClick={(): void =>
                  navigate(
                    `/dashboard/remoteAccessUsers/role/${roleId}${buildingQueryString}`,
                  )
                }
                style={styles.iconButton}
              />
            </Tooltip>
          </div>
        ),
      },
    ];
  };

  return (
    <Table
      rowKey="roleId"
      columns={getColumns()}
      loading={false}
      dataSource={roles}
    />
  );
};

export default RemoteAccessRoleTable;
