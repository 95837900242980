export enum OrganizationType {
  Vendor = 'VENDOR',
  'Customer' = 'CUSTOMER_OWNED',
  'Customer (Unowned)' = 'CUSTOMER_UNOWNED',
}

export enum BrandingPreference {
  TOTEM = 'TOTEM',
  INTELLIGENT_BUILDINGS = 'INTELLIGENT_BUILDINGS',
}

export interface ApplicationLabels {
  region: string;
}

export interface Features {
  healthRisk: boolean;
  nmap: boolean;
  questionnaireBcc: boolean;
  questionnaire: boolean;
  region: boolean;
  policy: boolean;
  policyAuditBcc: boolean;
  buildingContact: boolean;
  buildingNote: boolean;
  remoteAccess: boolean;
}

export interface Service {
  id: string;
  serviceId: string;
  name: string;
  displayName: string;
  description: string;
  status: string;
  createdBy: string;
  updatedBy: string;
  createdAt: string;
  updatedAt: string;
  isDeleted: boolean;
}

export interface OrganizationPreferences {
  branding: BrandingPreference;
  applicationLabels: ApplicationLabels;
  defaultRegion: string;
  features: Features;
}

export interface Organization {
  id: string;
  name: string;
  organizationKey: string;
  type: OrganizationType;
  acronisTenantId?: number;
  domain: string;
  preferences: OrganizationPreferences;
}

export interface LinkedCustomerOrganization {
  id: string;
  name: string;
  domain: string;
  type: OrganizationType;
}

export interface OrganizationCreateInput {
  name: string;
  userIds?: string[];
  questionnaireTemplateIds?: [];
  type: OrganizationType;
  domain: string;
  features?: Array<{
    feature: string;
    enabled: boolean;
  }>;
}

export interface OrganizationHierarchy {
  id: string;
  name: string;
  children?: OrganizationHierarchy[];
}

export interface AddKeyRequest {
  objectType: string;
  objectID: string;
  system: string;
  instance: string;
  key: string;
}
