import React, { ReactNode, useEffect, useState } from 'react';

import BuildingMapperContext from '@totem/components/settings/sections/buildingMapping/BuildingMapperContext';
import BuildingSelectModal from '@totem/components/settings/sections/buildingMapping/BuildingSelectModal';
import {
  BuildingMapperInput,
  BuildingMappingAction,
  SensorQueryResultItem,
} from '@totem/components/settings/sections/buildingMapping/types';
import { getToken } from '@totem/utilities/accountUtilities';
import { NOZOMI_ENDPOINT } from '@totem/utilities/endpoints';

import '../../settings.css';

type Props = {
  children?: ReactNode;
  actions?: BuildingMappingAction[];
};

const BuildingMappingContainer = ({ actions, children }: Props) => {
  const [refreshData, setRefreshData] = useState<boolean>(true);
  const [isSending, setIsSending] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [sensorData, setSensorData] = useState<SensorQueryResultItem[]>([]);
  const [selectedAction, setSelectedAction] = useState<string>('');
  const [selectedSensorData, setSelectedSensorData] = useState<
    SensorQueryResultItem
  >(null);
  const [input, updateInput] = useState<BuildingMapperInput>({
    regionId: [],
    buildingId: [],
    sensor_page: 1,
    sensor_pageSize: 20,
    sensor_sortField: '',
    sensor_sortDirection: '1',
  });

  const setInput = (updated: Partial<BuildingMapperInput>) => {
    updateInput({ ...input, ...updated });
    setRefreshData(true);
  };

  useEffect(() => {
    if (refreshData) {
      setRefreshData(false);
      if (isSending) {
        return;
      }
      const endpoint = `${NOZOMI_ENDPOINT}/sensors`;

      fetch(`${endpoint}`, {
        method: 'GET',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
      })
        .then(res => res.json())
        .then((result: SensorQueryResultItem[]) => {
          setSensorData(result);
        })
        .then(() => {
          setIsSending(false);
          setIsLoading(false);
        });

      setIsSending(true);
    }
  }, [refreshData, input]);

  const handleAction = (
    action: string,
    selSensorData: SensorQueryResultItem,
  ) => {
    let actionHandled = false;
    if (typeof actions !== 'undefined' && actions !== null) {
      for (let idx = 0; idx < actions.length; idx++) {
        if (actions[idx].name === action) {
          // eslint-disable-next-line max-depth
          if (actions[idx].replaceDefault === true) {
            actionHandled = true;
            actions[idx].action(action, selSensorData);
          }
        }
      }
    }
    if (!actionHandled) {
      setSelectedAction(action);
      setSelectedSensorData(selSensorData);
    }
  };

  const handleActionComplete = (doRefresh: boolean) => {
    setSelectedAction('');
    setSelectedSensorData(null);
    if (doRefresh) {
      setRefreshData(true);
    }
  };

  return (
    <BuildingMapperContext.Provider
      value={{
        input,
        setInput,
        data: sensorData,
        loading: isLoading,
        onAction: handleAction,
        onActionComplete: handleActionComplete,
      }}
    >
      <div>{children}</div>
      {selectedAction === 'showMappingDialog' &&
        selectedSensorData !== null && (
          <BuildingSelectModal
            visible={selectedAction === 'showMappingDialog'}
            sensor={selectedSensorData}
            defaultBuilding={selectedSensorData.building}
          />
        )}
    </BuildingMapperContext.Provider>
  );
};

export default BuildingMappingContainer;
