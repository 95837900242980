import React from 'react';
import { LoginOutlined } from '@ant-design/icons';
import { Button } from 'antd';

import './login.css';

interface Props {
  onClick: (event: React.MouseEvent) => void;
}

const styles = {
  button: {
    width: '100%',
  },
};

const LoginForm = ({ onClick }: Props) => {
  return (
    <form className="m-t">
      <div styleName="login-container">
        <div styleName="login-field-container" />
        <Button
          style={styles.button}
          type="primary"
          onClick={onClick}
          size="large"
          icon={<LoginOutlined />}
        >
          Login
        </Button>
      </div>
    </form>
  );
};

export default LoginForm;
