export type AutoCompleteSelected = { [id: string]: string };
export type AutoCompleteApplied = AutoCompleteSelected;
export type AutoCompleteOptions = AutoCompleteSelected;

export interface AutoCompleteFilterOptions {
  limit?: number;
  debounce?: number;
  onChange?: (ids: string[]) => void;
}

export interface AutoCompleteFilter {
  search: string;
  onSearch: (search: string) => void;
  loadingSearch: boolean;
  options: AutoCompleteOptions;
  selected: AutoCompleteSelected;
  onSelect: (id: string, name: string) => void;
  applied: AutoCompleteApplied;
  onRemove: (id: string) => void;
  onApply: () => void;
  onReset: () => void;
  loading: boolean;
}

export enum GQLErrorType {
  DEVELOPER,
  USER,
}

export interface ErrorNotification {
  message: string;
  description: string;
}
