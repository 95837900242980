import React, { useContext, useState } from 'react';
import { Col, Flex, Row } from 'antd';

import SoftwarePackageEditDialog from '@totem/components/software/edits/SoftwarePackageEditDialog';
import PackageContext from '@totem/components/software/software_package/packageContext';
import SoftwareEndOfSupport from '@totem/components/software/software_package/softwareEndOfSupport';
import SoftwareTags from '@totem/components/software/software_package/softwareTags';
import HeaderLabel from '@totem/components/ticketing/details/headerLabel';
import UserProfileContext from '@totem/components/UserProfileContext';
import IBDIcon from '@totem/styles/Icon';
import { isNotNull } from '@totem/utilities/common';
import { isIBUser } from '@totem/utilities/security';
import { getUserRole } from '@totem/utilities/userUtilities';

const PackageHeader = () => {
  const { userProfile } = useContext(UserProfileContext);
  const { data, onAction } = useContext(PackageContext);
  const [showEdit, setShowEdit] = useState<boolean>(false);
  const canEdit = getUserRole(userProfile) === 3 && isIBUser(userProfile);

  const handleCancelEdit = (refresh: boolean) => {
    setShowEdit(false);
    if (refresh) {
      onAction('refresh');
    }
  };

  return (
    <div>
      <Row gutter={20}>
        <Col span={8}>
          <HeaderLabel
            title={
              <Flex justify={'space-between'} align={'center'}>
                <div>Package Name</div>
                <div>
                  {canEdit && !showEdit && (
                    <IBDIcon
                      name="Edit"
                      title="Edit Name / Publisher"
                      onClick={() => setShowEdit(true)}
                    />
                  )}
                </div>
              </Flex>
            }
          >
            {typeof data !== 'undefined' && data !== null
              ? data.package.name
              : 'Unknown'}
          </HeaderLabel>
        </Col>
        <Col span={8}>
          <HeaderLabel
            title={
              <Flex justify={'space-between'} align={'center'}>
                <div>Publisher</div>
                <div>
                  {canEdit && !showEdit && (
                    <IBDIcon
                      name="Edit"
                      title="Edit Name / Publisher"
                      onClick={() => setShowEdit(true)}
                    />
                  )}
                </div>
              </Flex>
            }
          >
            {typeof data !== 'undefined' &&
            data !== null &&
            data.package.publisher !== ''
              ? data.package.publisher
              : 'Unknown'}
          </HeaderLabel>
          <br />
          <SoftwareEndOfSupport />
        </Col>
        <Col span={8}>
          <SoftwareTags />
        </Col>
      </Row>
      {canEdit && showEdit && isNotNull(data) && (
        <SoftwarePackageEditDialog
          visible={canEdit && showEdit && isNotNull(data)}
          onClose={handleCancelEdit}
          packageId={data.package.id}
          packageName={data.package.name}
          packagePublisher={data.package.publisher}
        />
      )}
    </div>
  );
};

export default PackageHeader;
