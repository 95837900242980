import React, { useEffect, useState } from 'react';
import { Alert, Form, Modal, Select, Tag } from 'antd';
import * as R from 'ramda';

import colors from '@totem/styles/colors';
import fonts from '@totem/styles/fonts';
import { validateEmail } from '@totem/utilities/validation';

import './inviteModal.css';

const { Option } = Select;
const FormItem = Form.Item;

interface Props {
  visible: boolean;
  loading: boolean;
  onCancel: () => void;
  onConfirm: (emails: string[]) => void;
  title: string;
  message: string;
}

const styles = {
  alert: {
    marginBottom: '2rem',
  },
  form: {
    width: '100%',
  },
  formItem: {
    paddingBottom: '0',
    marginBottom: '1rem',
  },
  tag: {
    height: '2.5rem',
    fontSize: fonts.fontMd,
    display: 'flex',
    alignItems: 'center',
  },
};

const ENTER_KEY = 'Enter';

const renderTag = (props: any) => {
  const { label, closable, onClose } = props;

  return (
    <Tag
      style={styles.tag}
      color={colors.antd.blue}
      closable={closable}
      onClose={onClose}
    >
      {label}
    </Tag>
  );
};

const InviteModal = ({
  visible,
  loading,
  onCancel,
  onConfirm,
  title,
  message,
}: Props) => {
  const [input, setInput] = useState<string>('');
  const [emails, setEmails] = useState<string[]>([]);
  const [error, setError] = useState<string>('');

  useEffect(() => {
    if (!visible) {
      setInput('');
      setEmails([]);
      setError('');
    }
  }, [visible]);

  const emailIsDuplicate = (newEmail: string) =>
    emails.some(email => email === newEmail.toLowerCase());

  const handleSelect = (email: string) => {
    if (validateEmail(email)) {
      setError('');
      setEmails([...emails, email.toLowerCase()]);
    } else {
      setError('Must be a valid email.');
    }
  };

  const handleDeselect = (emailToRemove: string) => {
    setEmails(emails.filter(email => email !== emailToRemove));
  };

  const handleKeydown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === ENTER_KEY && !validateEmail(input)) {
      setError('Must be a valid email.');
    }

    if (event.key === ENTER_KEY && emailIsDuplicate(input)) {
      setError('Email has already been entered.');
    }
  };

  return (
    <Modal
      open={visible}
      title={<div styleName="title">{title}</div>}
      onCancel={onCancel}
      okText="Send"
      onOk={() => onConfirm(emails)}
      confirmLoading={loading}
    >
      <Alert message={message} type="info" style={styles.alert} showIcon />
      <Form layout="vertical" style={styles.form}>
        <FormItem
          label="Add Emails"
          colon={false}
          style={styles.formItem}
          validateStatus={R.isEmpty(error) ? 'success' : 'error'}
          help={R.isEmpty(error) ? null : error}
        >
          <Select
            suffixIcon={null}
            mode="multiple"
            placeholder="name@example.com, name@example.com"
            tagRender={renderTag}
            value={emails}
            onSearch={setInput}
            onSelect={handleSelect}
            onDeselect={handleDeselect}
            onInputKeyDown={handleKeydown}
            notFoundContent={null}
          >
            {validateEmail(input) && !emailIsDuplicate(input) && (
              <Option key={input} value={input}>
                {input}
              </Option>
            )}
          </Select>
        </FormItem>
      </Form>
    </Modal>
  );
};

export default InviteModal;
