import React, { useCallback, useContext, useState } from 'react';
import { Button, Form, Modal, notification } from 'antd';

import BuildingAutoComplete from '@totem/components/common/autoCompletes/BuildingAutoComplete';
import { EMPTY_ID } from '@totem/components/common/reference/ReferenceSelector';
import { LookupReference } from '@totem/components/settings/sections/buildingMapping/types';
import BuildingMapperContext from '@totem/components/settings/sections/sapientBuildingMapping/BuildingMapContext';
import { BuildingsMap } from '@totem/components/settings/sections/sapientBuildingMapping/types';
import { AddKeyRequest } from '@totem/types/organization';
import { getToken } from '@totem/utilities/accountUtilities';
import { ORGANIZATION_ENDPOINT } from '@totem/utilities/endpoints';
const FormItem = Form.Item;

import ModalFooter from '@totem/components/ModalFooter';
import ModalTitle from '@totem/components/ModalTitle';

const styles = {
  form: {
    width: '100%',
  },
  formItem: {
    paddingBottom: '0',
    marginBottom: '1rem',
  },
  indent: {
    marginLeft: '20px',
  },
};

interface Building {
  id: string;
  name: string;
  region: {
    id: string;
    name: string;
  };
}

export interface Props {
  visible: boolean;
  buildingMap: BuildingsMap;
}

const BuildingSelectModal = ({ visible, buildingMap }: Props) => {
  const { onActionComplete } = useContext(BuildingMapperContext);
  const [isSending, setIsSending] = useState(false);
  const [selectedBuilding, setSelectedBuilding] = useState<LookupReference>(
    typeof buildingMap !== 'undefined' &&
      buildingMap !== null &&
      typeof buildingMap.ibd_building !== 'undefined' &&
      buildingMap.ibd_building !== null
      ? { id: buildingMap.ibd_building.id, name: buildingMap.ibd_building.name }
      : { id: EMPTY_ID, name: '' },
  );

  const sendBuildingUpdate = useCallback(
    async (sapientBuildingID: string, building: LookupReference) => {
      if (isSending) {
        return;
      }

      setIsSending(true);

      const payload: AddKeyRequest = {
        objectType: 'building',
        objectID: building.id,
        system: 'Sapient',
        instance: 'BuildingID',
        key: sapientBuildingID,
      };

      fetch(`${ORGANIZATION_ENDPOINT}/keys`, {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getToken()}`,
        }),
        body: JSON.stringify(payload),
      })
        .then((res) => {
          if (res.status < 400) {
            onActionComplete(true);
          } else {
            notification.error({
              message: 'Error building mapping!',
              description: 'An error occurred while updating building mapping.',
              duration: 0,
            });
            onActionComplete(false);
          }
        })
        .then(() => {
          setIsSending(false);
        });
    },
    [isSending],
  );

  const handleSubmit = () => {
    if (selectedBuilding.id === EMPTY_ID && selectedBuilding.name !== '') {
      // ERROR - Building Not Selected
      return;
    }
    sendBuildingUpdate(
      buildingMap.sapient_building.building_id,
      selectedBuilding,
    ).then(() => onActionComplete(true));
  };

  const handleBuildingChange = (building: string) => {
    const newBuilding: LookupReference = {
      id: EMPTY_ID,
      name: building,
    };

    setSelectedBuilding(newBuilding);
  };

  const handleBuildingSelect = (buildingData: Building) => {
    const newBuilding: LookupReference = {
      id: buildingData.id,
      name: buildingData.name,
    };

    setSelectedBuilding(newBuilding);
  };

  return (
    <Modal
      open={visible}
      onCancel={() => onActionComplete(false)}
      title={<ModalTitle>Map to Building</ModalTitle>}
      confirmLoading={false}
      width={'40%'}
      footer={
        <ModalFooter>
          <Button onClick={() => onActionComplete(false)}>Close</Button>
          <Button onClick={handleSubmit} type="primary">
            Submit
          </Button>
        </ModalFooter>
      }
    >
      <div>
        <b>Name: {buildingMap.sapient_building.building_name}</b>
      </div>
      <div>
        <b>Address: {buildingMap.sapient_building.street_address}</b>
      </div>
      <br />
      <br />
      <Form layout="vertical" style={styles.form}>
        <FormItem
          label="Building"
          colon={false}
          style={styles.formItem}
          required
        >
          <BuildingAutoComplete
            value={
              typeof selectedBuilding !== 'undefined' &&
              selectedBuilding !== null &&
              selectedBuilding.name !== null
                ? selectedBuilding.name
                : ''
            }
            onChange={handleBuildingChange}
            onSelect={handleBuildingSelect}
          />
        </FormItem>
      </Form>
    </Modal>
  );
};

export default BuildingSelectModal;
