import * as React from 'react';
import {
  GoogleMap,
  Marker,
  TrafficLayer,
  withGoogleMap,
} from 'react-google-maps';

import mapUtilities from '@totem/utilities/mapUtilities';

interface Props {
  defaultViewPort: any;
  components?: any;
  center: any;
  zoom: any;
  onMapClick: any;
  onMapLoad: any;
  traffic: any;
  displayClusters: any;
  markers: any;
  mapTypeId: string;
  googleMapURL: string;
  loadingElement: React.ReactNode;
}

const Configuration = withGoogleMap(
  ({
    // @ts-ignore
    defaultViewPort,
    components,
    center,
    zoom,
    onMapClick,
    onMapLoad,
    traffic,
    markers,
    ...additionalProps
  }: Props) => {
    const { mapStyles, draggableCursor, draggingCursor } = mapUtilities;
    const viewportControl = {
      defaultZoom: defaultViewPort.zoom,
      defaultCenter: defaultViewPort.center,
    };

    if (center && zoom) {
      // @ts-ignore
      viewportControl.center = center;
      // @ts-ignore
      viewportControl.zoom = zoom;
    }

    const defaultOptions = {
      styles: [mapStyles],
      // @ts-ignore
      mapTypeId: additionalProps.mapTypeId,
      mapTypeControl: false,
      zoomControl: true,
      streetViewControl: true,
      draggableCursor,
      draggingCursor,
      gestureHandling: 'greedy',
    };

    const mapMarkers =
      markers &&
      markers.map((marker, index) => {
        return (
          <Marker
            icon={marker.icon}
            key={index}
            position={marker.location}
            onClick={marker.onClick}
          />
        );
      });

    return (
      <>
        {
          // @ts-ignore
          <GoogleMap
            {...additionalProps}
            {...viewportControl}
            // @ts-ignore
            defaultOptions={defaultOptions}
            // @ts-ignore
            options={{
              ...defaultOptions,
              // @ts-ignore
              draggableCursor: additionalProps.cursor,
              styles: [
                {
                  elementType: 'geometry',
                  stylers: [
                    {
                      color: '#f5f5f5',
                    },
                  ],
                },
                {
                  elementType: 'labels.icon',
                  stylers: [
                    {
                      visibility: 'off',
                    },
                  ],
                },
                {
                  elementType: 'labels.text.fill',
                  stylers: [
                    {
                      color: '#616161',
                    },
                  ],
                },
                {
                  elementType: 'labels.text.stroke',
                  stylers: [
                    {
                      color: '#f5f5f5',
                    },
                  ],
                },
                {
                  featureType: 'administrative.land_parcel',
                  elementType: 'labels.text.fill',
                  stylers: [
                    {
                      color: '#bdbdbd',
                    },
                  ],
                },
                {
                  featureType: 'poi',
                  elementType: 'geometry',
                  stylers: [
                    {
                      color: '#eeeeee',
                    },
                  ],
                },
                {
                  featureType: 'poi',
                  elementType: 'labels.text.fill',
                  stylers: [
                    {
                      color: '#757575',
                    },
                  ],
                },
                {
                  featureType: 'poi.park',
                  elementType: 'geometry',
                  stylers: [
                    {
                      color: '#e5e5e5',
                    },
                  ],
                },
                {
                  featureType: 'poi.park',
                  elementType: 'labels.text.fill',
                  stylers: [
                    {
                      color: '#9e9e9e',
                    },
                  ],
                },
                {
                  featureType: 'road',
                  elementType: 'geometry',
                  stylers: [
                    {
                      color: '#ffffff',
                    },
                  ],
                },
                {
                  featureType: 'road.arterial',
                  elementType: 'labels.text.fill',
                  stylers: [
                    {
                      color: '#757575',
                    },
                  ],
                },
                {
                  featureType: 'road.highway',
                  elementType: 'geometry',
                  stylers: [
                    {
                      color: '#dadada',
                    },
                  ],
                },
                {
                  featureType: 'road.highway',
                  elementType: 'labels.text.fill',
                  stylers: [
                    {
                      color: '#616161',
                    },
                  ],
                },
                {
                  featureType: 'road.local',
                  elementType: 'labels.text.fill',
                  stylers: [
                    {
                      color: '#9e9e9e',
                    },
                  ],
                },
                {
                  featureType: 'transit.line',
                  elementType: 'geometry',
                  stylers: [
                    {
                      color: '#e5e5e5',
                    },
                  ],
                },
                {
                  featureType: 'transit.station',
                  elementType: 'geometry',
                  stylers: [
                    {
                      color: '#eeeeee',
                    },
                  ],
                },
                {
                  featureType: 'water',
                  elementType: 'geometry',
                  stylers: [
                    {
                      color: '#c9c9c9',
                    },
                  ],
                },
                {
                  featureType: 'water',
                  elementType: 'labels.text.fill',
                  stylers: [
                    {
                      color: '#9e9e9e',
                    },
                  ],
                },
              ],
            }}
            ref={onMapLoad}
            onClick={onMapClick}
          >
            {traffic && <TrafficLayer />}
            {mapMarkers}
            {components}
          </GoogleMap>
        }
      </>
    );
  },
);

export default Configuration;
