import { gql } from '@apollo/client';

import { API_KEY_FRAGMENT } from './apiKey';
import { CONTROL_SYSTEM_BACKUP_FRAGMENT } from './controlSystemBackup';
import { NOTE_FRAGMENT } from './note';
import { POLICY_AUDIT_DETAIL_FRAGMENT } from './policyAudit';

export const CONTROL_SYSTEM_DETAIL_FRAGMENT = gql`
  fragment ControlSystemDetail on ControlSystem {
    id
    organization {
      id
      name
    }
    region {
      id
      name
    }
    building {
      id
      name
    }
    policyAudit {
      ...PolicyAuditDetail
    }
    name
    systemType
    manufacturer
    model
    acronisMachines {
      id
      name
      userDefinedName
    }
    backupStatus
    notes(input: $notesConnectionInput) {
      totalCount
      notes {
        ...NoteDetail
      }
    }
    backups(input: $controlSystemBackupsConnectionInput) {
      totalCount
      controlSystemBackups {
        ...ControlSystemBackupDetail
      }
    }
    apiKeys(input: $apiKeysConnectionInput) {
      totalCount
      apiKeys {
        ...APIKeyDetail
      }
    }
  }
  ${POLICY_AUDIT_DETAIL_FRAGMENT}
  ${CONTROL_SYSTEM_BACKUP_FRAGMENT}
  ${API_KEY_FRAGMENT}
  ${NOTE_FRAGMENT}
`;

export const GET_CONTROL_SYSTEM = gql`
  query GetControlSystem(
    $id: ID!
    $apiKeysConnectionInput: APIKeysConnectionInput!
    $controlSystemBackupsConnectionInput: ControlSystemBackupsConnectionInput!
    $notesConnectionInput: NotesConnectionInput!
  ) {
    controlSystem(id: $id) {
      ...ControlSystemDetail
    }
  }
  ${CONTROL_SYSTEM_DETAIL_FRAGMENT}
`;

export const GET_CONTROL_SYSTEMS = gql`
  query GetControlSystems(
    $input: ControlSystemsConnectionInput!
    $apiKeysConnectionInput: APIKeysConnectionInput!
    $controlSystemBackupsConnectionInput: ControlSystemBackupsConnectionInput!
    $notesConnectionInput: NotesConnectionInput!
  ) {
    controlSystems(input: $input) {
      totalCount
      controlSystems {
        ...ControlSystemDetail
      }
    }
  }
  ${CONTROL_SYSTEM_DETAIL_FRAGMENT}
`;

export const CREATE_CONTROL_SYSTEM = gql`
  mutation CreateControlSystem($input: ControlSystemCreateInput!) {
    controlSystemCreate(input: $input) {
      id
    }
  }
`;

export const UPDATE_CONTROL_SYSTEM = gql`
  mutation UpdateControlSystem(
    $input: ControlSystemUpdateInput!
    $apiKeysConnectionInput: APIKeysConnectionInput!
    $controlSystemBackupsConnectionInput: ControlSystemBackupsConnectionInput!
    $notesConnectionInput: NotesConnectionInput!
  ) {
    controlSystemUpdate(input: $input) {
      ...ControlSystemDetail
    }
  }
  ${CONTROL_SYSTEM_DETAIL_FRAGMENT}
`;

export const DELETE_CONTROL_SYSTEM = gql`
  mutation DeleteControlSystem($id: ID!) {
    controlSystemDelete(id: $id)
  }
`;

export const CREATE_CONTROL_SYSTEM_API_KEY = gql`
  mutation CreateControlSystemApiKey($input: ControlSystemAPIKeyCreateInput!) {
    controlSystemApiKeyCreate(input: $input) {
      ...APIKeyDetail
    }
  }
  ${API_KEY_FRAGMENT}
`;
