import * as React from 'react';

import { getGradeColor } from '@totem/utilities/gradeUtilities';

import './policyAuditDetail.css';

type Props = {
  score: number;
};

const ResultsBar = ({ score }: Props) => {
  const color = getGradeColor(score);

  return (
    <div styleName="percentage-container">
      <div styleName="percentage">
        <div
          style={{
            // @ts-ignore
            width: `${!isNaN(score) ? score : 0}%`,
            backgroundColor: color,
            height: '100%',
          }}
        />
      </div>
      <div styleName="percentage-text">
        {isNaN(score) ? 'N/A' : `${score.toFixed(0)}%`}
      </div>
    </div>
  );
};

export default ResultsBar;
