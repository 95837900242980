import React, { useContext, useEffect, useState } from 'react';
import { AutoComplete, Input } from 'antd';

import OrganizationContext from '@totem/components/common/organizationContext/organizationContext';
import { EMPTY_ID } from '@totem/components/common/reference/ReferenceSelector';
import { OrganizationHierarchy } from '@totem/types/organization';
import { sortStringAscending } from '@totem/utilities/tableUtilities';
const { Search } = Input;

export interface ControlSystem {
  id: string;
  name: string;
}

export interface Props {
  value: string;
  onChange: (value: string) => void;
  onSelect: (controlSystem: ControlSystem) => void;
  placeholder?: string;
  allowClear?: boolean;
  notFoundContent?: string;
  style?: React.CSSProperties;
  disabled?: boolean;
  limit?: number;
  regionId?: string;
  buildingId?: string;
}

const styles = {
  input: {
    width: '100%',
  },
};

const getData = (controlSystems: ControlSystem[]) =>
  controlSystems.map(({ id, name }) => ({
    id,
    value: name,
  }));

const getControlSystems = (
  orgHierarchy: OrganizationHierarchy,
  regionId: string,
  buildingId: string,
) => {
  let checkRegionId = regionId;
  let checkBuildingId = buildingId;
  if (typeof checkRegionId === 'undefined') {
    checkRegionId = EMPTY_ID;
  }
  if (typeof checkBuildingId === 'undefined') {
    checkBuildingId = EMPTY_ID;
  }

  let controlSystemArray: OrganizationHierarchy[] = [];
  if (
    typeof orgHierarchy !== 'undefined' &&
    orgHierarchy !== null &&
    typeof orgHierarchy.children !== 'undefined' &&
    orgHierarchy.children !== null
  ) {
    for (let rIdx = 0; rIdx < orgHierarchy.children.length; rIdx++) {
      if (
        typeof orgHierarchy.children[rIdx].children !== 'undefined' &&
        orgHierarchy.children[rIdx].children !== null &&
        (orgHierarchy.children[rIdx].id === checkRegionId ||
          checkRegionId === EMPTY_ID)
      ) {
        // eslint-disable-next-line max-depth
        for (
          let bIdx = 0;
          bIdx < orgHierarchy.children[rIdx].children.length;
          bIdx++
        ) {
          // eslint-disable-next-line max-depth
          if (
            typeof orgHierarchy.children[rIdx].children[bIdx].children !==
              'undefined' &&
            (orgHierarchy.children[rIdx].children[bIdx].id ===
              checkBuildingId ||
              checkBuildingId === EMPTY_ID)
          ) {
            controlSystemArray = controlSystemArray.concat(
              orgHierarchy.children[rIdx].children[bIdx].children,
            );
          }
        }
      }
    }
  }
  return controlSystemArray.sort((compA, compB) =>
    sortStringAscending(compA.name, compB.name),
  );
};

const ControlSystemAutoCompleteFromOrgContext = ({
  value,
  onChange,
  onSelect,
  style,
  disabled,
  regionId,
  buildingId,
  allowClear,
  notFoundContent = 'No results found',
  placeholder = 'Search Control Systems',
}: Props) => {
  const { loading, data } = useContext(OrganizationContext);

  const [name, setName] = useState<string>(value);
  const [controlSystems, setControlSystems] = useState<OrganizationHierarchy[]>(
    [],
  );

  useEffect(() => {
    const availableControlSystems = getControlSystems(
      data,
      regionId,
      buildingId,
    );
    setControlSystems(availableControlSystems);
  }, [regionId, buildingId, data]);

  const matchingControlSystems =
    typeof controlSystems !== 'undefined' && controlSystems !== null
      ? controlSystems.filter((cs) => cs.name.includes(name))
      : [];

  const handleSelect = (_, option: any) => {
    setName(option.value);
    onSelect(
      controlSystems.find((controlSystem) => controlSystem.id === option.id),
    );
  };

  return (
    <AutoComplete
      value={name}
      onChange={onChange}
      options={getData(matchingControlSystems)}
      style={{ ...styles.input, ...style }}
      onSelect={handleSelect}
      onSearch={setName}
      placeholder={placeholder}
      disabled={disabled ?? false}
      notFoundContent={notFoundContent}
      children={
        <Search allowClear={allowClear} type="search" loading={loading} />
      }
    />
  );
};

export default ControlSystemAutoCompleteFromOrgContext;
