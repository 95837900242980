import React from 'react';
import { useQuery } from '@apollo/client';
import { Tabs, TabsProps } from 'antd';
import * as R from 'ramda';

import BasePane from '@totem/components/BasePane';
import DetailSpinner from '@totem/components/common/DetailSpinner';
import PrimaryContentPane from '@totem/components/PrimaryContentPane';
import TabTitle from '@totem/components/TabTitle';
import { GET_POLICY } from '@totem/graph/policies';
import { Policy, PolicyQuestion } from '@totem/types/policyAudit';

import ContentLayout from '../ContentLayout';

import Question from './Question';

import './policies.css';

const showPolicyName = (
  question: PolicyQuestion,
  previous?: PolicyQuestion,
): boolean => {
  return Boolean(!previous || previous.policyName !== question.policyName);
};

const getGroups = ({ groups, questions }: Policy) => {
  return groups
    .filter((group) =>
      group.questions.some((key) => {
        const question: PolicyQuestion = questions[key];
        return !R.isNil(question?.policy) || !R.isNil(question?.resolution);
      }),
    )
    .map((group) => ({
      ...group,
      questions: group.questions.filter((key) => {
        const question: PolicyQuestion = questions[key];
        return !R.isNil(question?.policy) || !R.isNil(question?.resolution);
      }),
    }));
};

const Policies = () => {
  const { loading, error, data } = useQuery(GET_POLICY);

  if (loading || error) {
    return (
      <div styleName="spinner-container">
        <DetailSpinner />
      </div>
    );
  }

  const { policy } = data;
  const { id, questions } = policy;

  // TODO: Migrate to Tab items model.
  const tabItems: TabsProps['items'] = getGroups(policy).map((group) => {
    return {
      key: group.name,
      label: <TabTitle>{group.name}</TabTitle>,
      children: (
        <>
          {group.questions.map((key, index) => {
            const question: PolicyQuestion = questions[key];
            const previousKey = group.questions[index - 1];
            const previousQuestion: PolicyQuestion = questions[previousKey];

            return (
              <div key={key}>
                {showPolicyName(question, previousQuestion) && (
                  <div styleName="policy-name-label">{question.policyName}</div>
                )}
                <Question id={id} question={question} />
              </div>
            );
          })}
        </>
      ),
    };
  });

  return (
    <ContentLayout>
      <>
        <BasePane>
          <PrimaryContentPane>
            <div style={{ padding: '15px' }}>
              <Tabs items={tabItems} />
            </div>
          </PrimaryContentPane>
        </BasePane>
      </>
    </ContentLayout>
  );
};

export default Policies;
