import { createContext } from 'react';

import { QueueSummary } from '@totem/components/ticketing/queueTotals/types';

interface Context {
  loading: boolean;
  data: QueueSummary[];
}

export default createContext<Context>({
  loading: false,
  data: [],
});
