import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Col, Row } from 'antd';

import { EMPTY_ID } from '@totem/components/common/reference/ReferenceSelector';
import DeviceContext from '@totem/components/devices/deviceDetails/DeviceContext';
import DeviceHealthConditional from '@totem/components/devices/health/deviceHealthConditional';
import DeviceHealthContainer from '@totem/components/devices/health/deviceHealthContainer';
import HealthGrid from '@totem/components/devices/health/HealthGrid';
import { hasHealth } from '@totem/components/devices/health/utilities';
import HeaderLabel from '@totem/components/ticketing/details/headerLabel';
import { DeviceQueryResult } from '@totem/types/devices';
import { Params } from '@totem/types/common';
import { addDeviceFilters, buildDeviceFilters } from '@totem/components/devices/devicesContainer/utilities';
import { ASSET_INVENTORY_ENDPOINT } from '@totem/utilities/endpoints';
import { getToken } from '@totem/utilities/accountUtilities';
import { StatisticsItem } from '@totem/components/assetInventory/statistics/types';
import { isNotNull } from '@totem/utilities/common';
import { FlagOutlined } from '@ant-design/icons';
import colors from '@totem/styles/colors';
import DeviceHealth from '@totem/components/devices/deviceDetails/DeviceHealth';

const DeviceHeader = () => {
  const { deviceData } = useContext(DeviceContext);
  const [statistics, setStatistics] = useState<StatisticsItem>(null);

  const buildParameters = () => {
    const params: Params = {
      paging: {
        page: 1,
        pageSize: 1000,
      },
      sort: {
        field: "displayName",
        direction: 1,
      },
      filters: [
        {
          field: "_id",
          values: [deviceData.device.id]
        }
      ],
    };

    params.filters = addDeviceFilters(params.filters, null);

    return params;
  };

  useEffect(() => {
    if (isNotNull(deviceData)) {
      const params: Params = buildParameters();

      fetch(`${ASSET_INVENTORY_ENDPOINT}/statistics`, {
        method: 'POST',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
        body: JSON.stringify(params),
      })
        .then((res) => res.json())
        .then((result: StatisticsItem) => {
          setStatistics(result);
        })
    }
  }, [deviceData]);

  const formatDateTIme = (dateIn: string) => {
    if (dateIn !== null) {
      if (!isNaN(Date.parse(dateIn))) {
        const dateVal = new Date(dateIn);
        return <>{dateVal.toLocaleString()}</>;
      }
    }
    return <span>-</span>;
  };

  const getBuildingDisplay = (device: DeviceQueryResult) => {
    if (
      device.building !== null &&
      device.building.id !== null &&
      device.building.id !== EMPTY_ID
    ) {
      return (
        <Link to={`/dashboard/buildings/${device.building.id}`}>
          {device.building.name}
        </Link>
      );
    } else if (device.building !== null) {
      return <span>{device.building.name}</span>;
    }
    return <span>-</span>;
  };

  const getControlSystemDisplay = (device: DeviceQueryResult) => {
    if (
      device.controlSystem !== null &&
      device.controlSystem.id !== null &&
      device.controlSystem.id !== EMPTY_ID
    ) {
      return (
        <Link to={`/dashboard/controlsystems/${device.controlSystem.id}`}>
          {device.controlSystem.name}
        </Link>
      );
    } else if (device.controlSystem !== null) {
      return <span>{device.controlSystem.name}</span>;
    }
    return <span>-</span>;
  };

  const getOSEndOfSupportFlag = () => {
    if (isNotNull(statistics)) {
      if (statistics.operatingSystemEndOfSupport.pastEndOfSupport > 0) {
        return (
          <>
            &nbsp;
            <FlagOutlined
              title={`Past End of Support`}
              style={{
                color: colors.event.criticality.background.critical,
                fontSize: '20px',
              }}
            />
          </>
        );
      }
      if (statistics.operatingSystemEndOfSupport.approachingEndOfSupport > 0) {
        return (
          <>
            &nbsp;
            <FlagOutlined
              title={`Near End of Support`}
              style={{
                color: colors.event.criticality.background.warning,
                fontSize: '20px',
              }}
            />
          </>
        );
      }
    }
    return <span />
  }

  return (
    <div>
      {typeof deviceData !== 'undefined' && deviceData !== null && (
        <>
          <Row>
            <Col span={8}>
              <HeaderLabel title="Device Name">
                {deviceData.device.displayName}
              </HeaderLabel>
              {deviceData.device.type !== null &&
                deviceData.device.type !== '' && (
                  <HeaderLabel title="Type">
                    {deviceData.device.type}
                  </HeaderLabel>
                )}
              {deviceData.device.vendor !== null &&
                deviceData.device.vendor !== '' && (
                  <HeaderLabel title="Vendor">
                    {deviceData.device.vendor}
                  </HeaderLabel>
                )}
            </Col>
            <Col span={8}>
              {deviceData.device.operatingSystem !== null &&
                deviceData.device.operatingSystem !== '' && (
                  <HeaderLabel title="Operating System">
                    {deviceData.device.operatingSystem}
                    {getOSEndOfSupportFlag()}
                  </HeaderLabel>
                )}
              {deviceData.device.lastBackup !== null && (
                <HeaderLabel title="Last Backup">
                  {formatDateTIme(deviceData.device.lastBackup.backedUpAt)}
                  <br />
                  {deviceData.device.lastBackup.status}
                </HeaderLabel>
              )}
            </Col>
            <Col span={8}>
              <HeaderLabel title="Organization">
                {deviceData.organization.name}
              </HeaderLabel>
              {deviceData.region.name !== '' && (
                <HeaderLabel title="Region">
                  {deviceData.region.name}
                </HeaderLabel>
              )}
              {deviceData.building.name !== '' && (
                <HeaderLabel title="Building">
                  {getBuildingDisplay(deviceData)}
                </HeaderLabel>
              )}
              {deviceData.controlSystem.name !== '' && (
                <HeaderLabel title="Control System">
                  {getControlSystemDisplay(deviceData)}
                </HeaderLabel>
              )}
            </Col>
          </Row>
          {hasHealth(deviceData) && (
            <DeviceHealthContainer id={deviceData.device.id}>
              <DeviceHealthConditional>
                <br />
                <DeviceHealth />
                <br />
                <br />
              </DeviceHealthConditional>
            </DeviceHealthContainer>
          )}
        </>
      )}
    </div>
  );
};

export default DeviceHeader;
