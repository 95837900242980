import * as R from 'ramda';

export const toTitleCase = (val: string) =>
  R.replace(
    /^./,
    R.toUpper,
  )(val)
    .split(/(?=[A-Z])/)
    .join(' ');

export default {
  toTitleCase,
};
