export enum Criticality {
  Info = 0,
  Low = 1,
  Medium = 2,
  High = 3,
  Critical = 4,
  Compliant = 5,
}

export type CriticalityLabel = keyof typeof Criticality;

export const NON_COMPLIANT_CRITICALITIES = [
  Criticality.Low,
  Criticality.Medium,
  Criticality.High,
  Criticality.Critical,
];
