import actionTypes from './actionTypes';

export const requestVersions = () => ({
  type: actionTypes.REQUEST_VERSIONS,
});

export const getVersionsSuccess = data => ({
  type: actionTypes.GET_VERSIONS_SUCCESS,
  data,
});

export const getVersionsFailure = () => ({
  type: actionTypes.GET_VERSIONS_FAILURE,
});
