import React from 'react';

import BasePane from '@totem/components/BasePane';
import ContentLayout from '@totem/components/ContentLayout';
import PrimaryContentPane from '@totem/components/PrimaryContentPane';
import PolicyAuditReportContainer from '@totem/components/surveyV2/policyAuditReport/policyAuditReportContainer';
import ReportTable from '@totem/components/surveyV2/policyAuditReport/ReportTable';
const PolicyAuditReportPage = () => {
  return (
    <ContentLayout>
      <PolicyAuditReportContainer>
        <BasePane>
          <PrimaryContentPane>
            <ReportTable />
          </PrimaryContentPane>
        </BasePane>
      </PolicyAuditReportContainer>
    </ContentLayout>
  );
};

export default PolicyAuditReportPage;
