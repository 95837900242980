import React, { useCallback, useContext, useEffect, useState } from 'react';

import BasePane from '@totem/components/BasePane';
import CardBody from '@totem/components/CardBody';
import CardHeader from '@totem/components/CardHeader';
import CardHeaderSmaller from '@totem/components/CardHeaderSmaller';
import PrimaryContentPane from '@totem/components/PrimaryContentPane';
import AffiliatedTicketsTable from '@totem/components/ticketing/affiliatedList/AffiliatedTicketsTable';
import TicketCreateModal from '@totem/components/ticketing/create/TicketCreateModal';
import UserProfileContext from '@totem/components/UserProfileContext';
import { Filter, ObjectReference, Params } from '@totem/types/common';
import {
  TicketingFilterOptions,
  TicketQueryResults,
} from '@totem/types/ticketing';
import { getToken } from '@totem/utilities/accountUtilities';
import { TICKETS_ENDPOINT } from '@totem/utilities/endpoints';

import '../ticketing.css';

const AffiliatedTickets = () => {
  const { userProfile } = useContext(UserProfileContext);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [isSending, setIsSending] = useState(false);
  const [sortField, setSortField] = useState<string>('createdAt');
  const [sortDirection, setSortDirection] = useState<number>(1);
  const [filters, setFilters] = useState<Filter[]>([]);
  const [filterOptions, setFilterOptions] = useState<TicketingFilterOptions>(
    null,
  );
  const [ticketData, setTicketData] = useState<TicketQueryResults>(null);
  const [showNewTicketModal, setShowNewTicketModal] = useState<boolean>(false);
  const [affiliatedQueues, setAffiliatedQueues] = useState<ObjectReference[]>(
    [],
  );

  const buildParameters = () => {
    const params: Params = {
      paging: {
        page,
        pageSize,
      },
      sort: {
        field: sortField,
        direction: sortDirection,
      },
      filters,
    };

    return params;
  };

  useEffect(() => {
    fetch(`${TICKETS_ENDPOINT}/affiliated/filterOptions`, {
      method: 'GET',
      headers: new Headers({
        Authorization: `Bearer ${getToken()}`,
      }),
    })
      .then(res => res.json())
      .then((result: TicketingFilterOptions) => {
        setFilterOptions(result);
      });
  }, []);

  useEffect(() => {
    fetch(`${TICKETS_ENDPOINT}/affiliated/queues`, {
      method: 'GET',
      headers: new Headers({
        Authorization: `Bearer ${getToken()}`,
      }),
    })
      .then(res => res.json())
      .then((result: ObjectReference[]) => {
        setAffiliatedQueues(result);
      });
  }, []);

  const refreshTickets = useCallback(async (params: Params) => {
    if (isSending) {
      return;
    }
    setIsLoading(true);

    fetch(`${TICKETS_ENDPOINT}/affiliated`, {
      method: 'POST',
      headers: new Headers({
        Authorization: `Bearer ${getToken()}`,
      }),
      body: JSON.stringify(params),
    })
      .then(res => {
        if (res.status >= 400) {
          return null;
        }
        return res.json();
      })
      .then((result: TicketQueryResults) => {
        setTicketData(result);
        if (result !== null) {
          setTotalRecords(result.paging.totalRecords);
        }
      })
      .then(() => {
        setIsLoading(false);
        setIsSending(false);
      });

    setIsSending(true);
  }, []);

  useEffect(() => {
    if (isSending) {
      return;
    }
    setIsLoading(true);
    const params = buildParameters();

    fetch(`${TICKETS_ENDPOINT}/affiliated`, {
      method: 'POST',
      headers: new Headers({
        Authorization: `Bearer ${getToken()}`,
      }),
      body: JSON.stringify(params),
    })
      .then(res => {
        if (res.status >= 400) {
          return null;
        }
        return res.json();
      })
      .then((result: TicketQueryResults) => {
        setTicketData(result);
        if (result !== null) {
          setTotalRecords(result.paging.totalRecords);
        }
      })
      .then(() => {
        setIsLoading(false);
        setIsSending(false);
      });

    setIsSending(true);
  }, [page, pageSize, sortField, sortDirection, filters]);

  const handlePagingRequest = (newPage: number, newPageSize: number) => {
    setPageSize(newPageSize);
    setPage(newPage);
  };

  const handleSortRequest = (field: string, direction: number) => {
    setSortField(field);
    setSortDirection(direction);
    setPage(1);
  };

  const handleFilterRequest = (newFilters: Filter[]) => {
    setFilters(newFilters);
  };

  const handleTicketCreateClosed = (ticketCreated: boolean) => {
    setShowNewTicketModal(false);
    if (ticketCreated) {
      const params: Params = buildParameters();
      refreshTickets(params);
    }
  };

  return (
    <>
      {ticketData !== null &&
        (ticketData.paging.totalRecords > 0 || filters.length > 0) && (
          <div styleName="widget-panel">
            <BasePane>
              <PrimaryContentPane>
                <CardHeader>
                  <div>My Tickets</div>
                  <CardHeaderSmaller>{totalRecords} Tickets</CardHeaderSmaller>
                </CardHeader>
                <CardBody>
                  <AffiliatedTicketsTable
                    currentUserEmail={userProfile.email}
                    page={page}
                    pageSize={pageSize}
                    total={totalRecords}
                    sortField={sortField}
                    sortDirection={sortDirection}
                    ticketResults={ticketData}
                    filterOptions={filterOptions}
                    affiliatedQueues={affiliatedQueues}
                    loading={isLoading}
                    onPagingChange={handlePagingRequest}
                    onSortChange={handleSortRequest}
                    onFilterChange={handleFilterRequest}
                  />
                </CardBody>
              </PrimaryContentPane>
            </BasePane>
            {showNewTicketModal && (
              <TicketCreateModal
                visible={showNewTicketModal}
                onClose={handleTicketCreateClosed}
              />
            )}
          </div>
        )}
    </>
  );
};

export default AffiliatedTickets;
