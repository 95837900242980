import { Filter, Params } from '@totem/types/common';

export const buildEventTotalsFilters = (
  regionId: string[],
  buildingId: string[],
  controlSystemId: string[],
) => {
  const paramFilters: Filter[] = [];

  if (
    typeof regionId !== 'undefined' &&
    regionId !== null &&
    regionId.length > 0
  ) {
    paramFilters.push({
      field: 'regionId',
      values: regionId,
    });
  }
  if (
    typeof buildingId !== 'undefined' &&
    buildingId !== null &&
    buildingId.length > 0
  ) {
    paramFilters.push({
      field: 'buildingId',
      values: buildingId,
    });
  }
  if (
    typeof controlSystemId !== 'undefined' &&
    controlSystemId !== null &&
    controlSystemId.length > 0
  ) {
    paramFilters.push({
      field: 'controlSystemId',
      values: controlSystemId,
    });
  }

  return paramFilters;
};

export const buildParameters = (
  regionId: string[],
  buildingId: string[],
  controlSystemId: string[],
) => {
  const params: Params = {
    paging: {
      page: 1,
      pageSize: 50000,
    },
    sort: {
      field: '',
      direction: 1,
    },
    filters: buildEventTotalsFilters(regionId, buildingId, controlSystemId),
  };

  return params;
};
