import React, { useCallback, useContext, useState } from 'react';
import { CloseSquareOutlined } from '@ant-design/icons';
import { Input } from 'antd';

import DeviceContext from '@totem/components/devices/devicesContainer/deviceContainerContext';
import { debounce } from '@totem/utilities/debounce';

const DEBOUNCE_TIME = 300;

const DeviceSearch = () => {
  const { input, setInput } = useContext(DeviceContext);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [searchIpAddress, setSearchIpAddress] = useState<string>('');

  const sendDeviceSearch = useCallback(
    debounce(
      (newSearchTerm: string) =>
        setInput({ ...input, searchTerm: newSearchTerm }),
      DEBOUNCE_TIME,
    ),
    [],
  );

  const sendIpAddressSearch = useCallback(
    debounce(
      (newSearchTerm: string) =>
        setInput({ ...input, searchIpAddress: newSearchTerm }),
      DEBOUNCE_TIME,
    ),
    [],
  );

  const handleDeviceSearch = (newSearchTerm: string) => {
    setSearchTerm(newSearchTerm);
    sendDeviceSearch(newSearchTerm);
  };

  const handleIPAddressSearch = (newSearchTerm: string) => {
    setSearchIpAddress(newSearchTerm);
    sendIpAddressSearch(newSearchTerm);
  };

  return (
    <table style={{ width: '100%' }}>
      <tbody>
        <tr>
          <td style={{ width: '300px' }}>
            <b>Device Name:</b>&nbsp;
            <Input
              placeholder="Device Search"
              value={searchTerm}
              onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                evt.target.value !== null
                  ? handleDeviceSearch(evt.target.value)
                  : handleDeviceSearch('')
              }
            />
          </td>
          <td>
            <br />
            <CloseSquareOutlined onClick={() => handleDeviceSearch('')} />
          </td>
          <td style={{ width: '300px' }}>
            <b>Device IP Address:</b>&nbsp;
            <Input
              placeholder="Search IP Address"
              value={searchIpAddress}
              onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                evt.target.value !== null
                  ? handleIPAddressSearch(evt.target.value)
                  : handleIPAddressSearch('')
              }
            />
          </td>
          <td>
            <br />
            <CloseSquareOutlined onClick={() => handleIPAddressSearch('')} />
          </td>
          <td>&nbsp;</td>
        </tr>
      </tbody>
    </table>
  );
};

export default DeviceSearch;
