import React, { useState } from 'react';
import { FileAddOutlined, FolderAddOutlined } from '@ant-design/icons';
import { v4 as uuidv4 } from 'uuid';

import ConditionBuilderModal from '@totem/components/surveyV2/templateDetails/ConditionBuilderModal';
import SurveyTemplateBracketCard from '@totem/components/surveyV2/templateDetails/SurveyTemplateBracketCard';
import SurveyTemplateConditionRule from '@totem/components/surveyV2/templateDetails/SurveyTemplateConditionRule';
import {
  Condition,
  ConditionActionPayload,
} from '@totem/components/surveyV2/types';
import { cloneCondition } from '@totem/components/surveyV2/utilities/SurveyUtilities';

import '../survey.css';

type Props = {
  condition: Condition;
  questionId: string;
  onRemoveItem: (item: Condition) => void;
  onConditionChange: (change: ConditionActionPayload) => void;
};

const SurveyTemplateConditionCard = ({
  condition,
  questionId,
  onConditionChange,
  onRemoveItem,
}: Props) => {
  //const [localState, setLocalState] = useState<Condition>(condition);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const groups =
    condition.subconditions === null
      ? []
      : condition.subconditions.filter(
          (sc) => sc.operation === 'AND' || sc.operation === 'OR',
        );
  const rules =
    condition.subconditions === null
      ? []
      : condition.subconditions.filter(
          (sc) => sc.operation !== 'AND' && sc.operation !== 'OR',
        );
  const titleClickedHandler = () => {
    const newCondition = cloneCondition(condition);
    newCondition.operation = newCondition.operation === 'AND' ? 'OR' : 'AND';
    const change: ConditionActionPayload = {
      method: 'Update',
      condition: newCondition,
    };

    onConditionChange(change);
  };

  const handleAddGroup = () => {
    const newCondition = {
      id: uuidv4(),
      operation: 'AND',
      fieldType: '',
      fieldId: '',
      value: '',
      subconditions: [],
    };

    onConditionChange({
      method: 'New',
      condition: newCondition,
    });
  };

  const handleAddRule = () => {
    setShowEditModal(true);
  };

  const handleRemove = (item: Condition) => {
    const updatedCondition = cloneCondition(condition);
    updatedCondition.subconditions = updatedCondition.subconditions.filter(
      (chk) => chk.id !== item.id,
    );
    onConditionChange({
      method: 'Edit',
      condition: updatedCondition,
    });
  };

  const conditionChangeHandler = (change: ConditionActionPayload) => {
    const updatedCondition = cloneCondition(condition);
    switch (change.method) {
      case 'New':
        updatedCondition.subconditions.push(change.condition);
        break;
      default:
        for (let idx = 0; idx < updatedCondition.subconditions.length; idx++) {
          if (updatedCondition.subconditions[idx].id === change.condition.id) {
            updatedCondition.subconditions[idx] = change.condition;
          }
        }
        break;
    }
    onConditionChange({
      method: 'Update',
      condition: updatedCondition,
    });
  };

  return (
    <SurveyTemplateBracketCard
      title={condition.operation}
      titleClicked={titleClickedHandler}
      onRemove={() => onRemoveItem(condition)}
    >
      {rules.map((rule) => (
        <SurveyTemplateConditionRule
          key={rule.id}
          condition={rule}
          onRemoveRule={handleRemove}
        />
      ))}
      {groups.map((group) => (
        <SurveyTemplateConditionCard
          key={group.id}
          condition={group}
          questionId={questionId}
          onRemoveItem={handleRemove}
          onConditionChange={conditionChangeHandler}
        />
      ))}
      <br />
      <div styleName="iconsBottomAligned">
        <FolderAddOutlined title="Add Group" onClick={handleAddGroup} />
        <FileAddOutlined title="Add Rule" onClick={handleAddRule} />
      </div>
      {showEditModal && (
        <ConditionBuilderModal
          questionId={questionId}
          onConditionChange={conditionChangeHandler}
          onClose={() => setShowEditModal(false)}
          open={showEditModal}
        />
      )}
    </SurveyTemplateBracketCard>
  );
};

export default SurveyTemplateConditionCard;
