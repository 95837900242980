import React, { useContext, useEffect, useState } from 'react';
import { Form, Select } from 'antd';

import UserProfileContext from '@totem/components/UserProfileContext';
import { ObjectReference, Reference } from '@totem/types/common';
import { OrganizationHierarchy } from '@totem/types/organization';
import { getToken } from '@totem/utilities/accountUtilities';
import { ORGANIZATION_HIERARCHY_ENDPOINT } from '@totem/utilities/endpoints';
import { getRegions } from '@totem/utilities/organization';

import '../referencesModal/referencesModal.css';

const { Option } = Select;
const FormItem = Form.Item;

export const EMPTY_ID = '000000000000000000000000';

const styles = {
  form: {
    width: '100%',
  },
  formItem: {
    paddingBottom: '0',
    marginBottom: '1rem',
  },
};

type Props = {
  onChanged?: (ref: Reference) => void;
  onRegionChanged?: (ref: ObjectReference) => void;
  onBuildingChanged?: (ref: ObjectReference) => void;
  onControlSystemChanged?: (ref: ObjectReference) => void;
  references?: Reference;
  showOrganization: boolean;
  showRegion: boolean;
  showBuilding: boolean;
  showControlSystem: boolean;
};

const ReferencesSelector = ({
  onChanged,
  references,
  showOrganization,
  showRegion,
  showBuilding,
  showControlSystem,
  onRegionChanged,
  onBuildingChanged,
  onControlSystemChanged,
}: Props) => {
  const { userProfile } = useContext(UserProfileContext);
  const [refRegionLoaded, setrefRegionLoaded] = useState<boolean>(false);
  const [refBuildingLoaded, setrefBuildingLoaded] = useState<boolean>(false);
  const [refControlSystemLoaded, setrefControlSystemLoaded] =
    useState<boolean>(false);

  const [orgHierarchy, setOrgHierarchy] = useState<OrganizationHierarchy>(null);
  const [loadingCount, setLoadingCount] = useState<number>(0);
  const [organizationId, setOrganizationId] = useState<string>(
    typeof references !== 'undefined' &&
      typeof references.organizationId !== 'undefined' &&
      references.organizationId !== null
      ? references.organizationId
      : EMPTY_ID,
  );
  const [regionId, setRegionId] = useState<string>(EMPTY_ID);
  const [buildingId, setBuildingId] = useState<string>(EMPTY_ID);
  const [controlSystemId, setControlSystemId] = useState<string>(EMPTY_ID);

  const [regions, setRegions] = useState<OrganizationHierarchy[]>([]);
  const [buildings, setBuildings] = useState<OrganizationHierarchy[]>([]);
  const [controlSystems, setControlSystems] = useState<OrganizationHierarchy[]>(
    [],
  );

  const [selectedRegion, setSelectedRegion] =
    useState<OrganizationHierarchy>(null);
  const [selectedBuilding, setSelectedBuilding] =
    useState<OrganizationHierarchy>(null);
  const [selectedControlSystem, setSelectedControlSystem] =
    useState<OrganizationHierarchy>(null);

  useEffect(() => {
    const ref: Reference = {
      organizationId,
      regionId,
      buildingId,
      controlSystemId,
    };

    if (typeof onChanged !== 'undefined' && onChanged !== null) {
      onChanged(ref);
    }
  }, [organizationId, regionId, buildingId, controlSystemId]);

  const findRegionFromId = (
    regionsList: OrganizationHierarchy[],
    findRegionId: string,
  ) => {
    return regionsList.find((region) => region.id === findRegionId);
  };

  const findBuildingFromId = (
    buildingList: OrganizationHierarchy[],
    findBuildingId: string,
  ) => {
    return buildingList.find((building) => building.id === findBuildingId);
  };

  const findControlSystemFromId = (
    controlSystemList: OrganizationHierarchy[],
    findControlSystemId: string,
  ) => {
    return controlSystemList.find(
      (building) => building.id === findControlSystemId,
    );
  };

  const handleRegionChanged = (selectedRegionId: string) => {
    if (selectedRegionId === '' || selectedRegionId === EMPTY_ID) {
      setRegionId(EMPTY_ID);
      setSelectedRegion(null);
      setSelectedBuilding(null);
      setSelectedControlSystem(null);
      setBuildingId(EMPTY_ID);
      setControlSystemId(EMPTY_ID);
      if (typeof onRegionChanged !== 'undefined' && onRegionChanged !== null) {
        onRegionChanged({
          id: EMPTY_ID,
          name: '',
        });
      }
    } else {
      const foundRegion = regions.find(
        (region) => region.id === selectedRegionId,
      );
      setRegionId(selectedRegionId);
      setSelectedRegion(foundRegion);
      setSelectedBuilding(null);
      setSelectedControlSystem(null);
      setBuildings(
        foundRegion.children.sort((option1, option2) => {
          if (option1.name < option2.name) {
            return -1;
          }
          if (option1.name > option2.name) {
            return 1;
          }
          return 0;
        }),
      );
      setControlSystems(null);
      if (typeof onRegionChanged !== 'undefined' && onRegionChanged !== null) {
        onRegionChanged({
          id: foundRegion.id,
          name: foundRegion.name,
        });
      }
    }
  };

  const handleBuildingChanged = (selectedBuildingId: string) => {
    if (selectedBuildingId === '' || selectedBuildingId === EMPTY_ID) {
      setBuildingId(EMPTY_ID);
      setSelectedBuilding(null);
      setSelectedControlSystem(null);
      if (
        typeof onBuildingChanged !== 'undefined' &&
        onBuildingChanged !== null
      ) {
        onBuildingChanged({
          id: EMPTY_ID,
          name: '',
        });
      }
    } else {
      const foundBuilding = buildings.find(
        (building) => building.id === selectedBuildingId,
      );
      setBuildingId(selectedBuildingId);
      setSelectedBuilding(foundBuilding);
      setSelectedControlSystem(null);
      setControlSystems(
        foundBuilding.children.sort((option1, option2) => {
          if (option1.name < option2.name) {
            return -1;
          }
          if (option1.name > option2.name) {
            return 1;
          }
          return 0;
        }),
      );
      if (
        typeof onBuildingChanged !== 'undefined' &&
        onBuildingChanged !== null
      ) {
        onBuildingChanged({
          id: foundBuilding.id,
          name: foundBuilding.name,
        });
      }
    }
  };

  const handleControlSystemChanged = (selectedControlSystemId: string) => {
    if (
      selectedControlSystemId === '' ||
      selectedControlSystemId === EMPTY_ID
    ) {
      setControlSystemId(EMPTY_ID);
      setSelectedControlSystem(null);
      if (
        typeof onControlSystemChanged !== 'undefined' &&
        onControlSystemChanged !== null
      ) {
        onControlSystemChanged({
          id: EMPTY_ID,
          name: '',
        });
      }
    } else {
      const foundControlSystem = controlSystems.find(
        (controlSystem) => controlSystem.id === selectedControlSystemId,
      );
      setControlSystemId(selectedControlSystemId);
      setSelectedControlSystem(foundControlSystem);
      if (
        typeof onControlSystemChanged !== 'undefined' &&
        onControlSystemChanged !== null
      ) {
        onControlSystemChanged({
          id: foundControlSystem.id,
          name: foundControlSystem.name,
        });
      }
    }
  };

  useEffect(() => {
    if (
      typeof references !== 'undefined' &&
      references !== null &&
      orgHierarchy !== null &&
      refRegionLoaded === true &&
      refBuildingLoaded === false &&
      selectedRegion !== null &&
      selectedRegion.children !== null &&
      selectedRegion.children.length > 0
    ) {
      setrefBuildingLoaded(true);
      //handleBuildingChanged(references.buildingId);
      setBuildingId(references.buildingId);
      const foundBuilding = findBuildingFromId(
        selectedRegion.children,
        references.buildingId,
      );
      // eslint-disable-next-line max-depth
      if (typeof foundBuilding !== 'undefined') {
        setSelectedBuilding(foundBuilding);
        setControlSystems(
          foundBuilding.children.sort((option1, option2) => {
            if (option1.name < option2.name) {
              return -1;
            }
            if (option1.name > option2.name) {
              return 1;
            }
            return 0;
          }),
        );
      }
    }
  }, [
    references,
    selectedRegion,
    orgHierarchy,
    refRegionLoaded,
    refBuildingLoaded,
  ]);

  useEffect(() => {
    if (
      typeof references !== 'undefined' &&
      references !== null &&
      orgHierarchy !== null &&
      refBuildingLoaded === true &&
      refControlSystemLoaded === false &&
      selectedBuilding !== null &&
      selectedBuilding.children !== null &&
      selectedBuilding.children.length > 0
    ) {
      setrefControlSystemLoaded(true);
      //handleControlSystemChanged(references.controlSystemId);
      setControlSystemId(references.controlSystemId);
      const foundControlSystem = findControlSystemFromId(
        selectedBuilding.children,
        references.controlSystemId,
      );
      // eslint-disable-next-line max-depth
      if (typeof foundControlSystem !== 'undefined') {
        setSelectedControlSystem(foundControlSystem);
      }
    }
  }, [
    references,
    selectedBuilding,
    orgHierarchy,
    refBuildingLoaded,
    refControlSystemLoaded,
  ]);

  useEffect(() => {
    if (organizationId === EMPTY_ID) {
      if (
        typeof references !== 'undefined' &&
        typeof references.organizationId !== 'undefined' &&
        references.organizationId !== null
      ) {
        setOrganizationId(references.organizationId);
      }
    }
    if (
      typeof references !== 'undefined' &&
      references !== null &&
      orgHierarchy !== null &&
      orgHierarchy.children !== null &&
      orgHierarchy.children.length > 0 &&
      refRegionLoaded === false
    ) {
      if (references.organizationId !== null) {
        if (orgHierarchy.children.length > 0) {
          setrefRegionLoaded(true);
          // eslint-disable-next-line max-depth
          if (references.regionId !== null) {
            //handleRegionChanged(references.regionId);
            setRegionId(references.regionId);
            const foundRegion = findRegionFromId(
              orgHierarchy.children,
              references.regionId,
            );
            // eslint-disable-next-line max-depth
            if (typeof foundRegion !== 'undefined') {
              setSelectedRegion(foundRegion);
              setBuildings(
                foundRegion.children.sort((option1, option2) => {
                  if (option1.name < option2.name) {
                    return -1;
                  }
                  if (option1.name > option2.name) {
                    return 1;
                  }
                  return 0;
                }),
              );
            }
          }
        }
      }
    }
  }, [references, refRegionLoaded, orgHierarchy]);

  useEffect(() => {
    setLoadingCount(loadingCount + 1);
    fetch(`${ORGANIZATION_HIERARCHY_ENDPOINT}/${organizationId}`, {
      headers: new Headers({
        Authorization: `Bearer ${getToken()}`,
      }),
    })
      .then((res) => res.json())
      .then((result) => {
        setOrgHierarchy(result.hierarchy);
      })
      .then(() => setLoadingCount(loadingCount - 1));
  }, [organizationId]);

  useEffect(() => {
    const regionOptions = getRegions(orgHierarchy);
    if (regionOptions !== null) {
      setRegions(
        regionOptions.sort((option1, option2) => {
          if (option1.name < option2.name) {
            return -1;
          }
          if (option1.name > option2.name) {
            return 1;
          }
          return 0;
        }),
      );
    } else {
      setRegions([]);
    }
  }, [orgHierarchy]);

  const handleOrganizationChanged = (selectedOrganizationId: string) => {
    setOrganizationId(selectedOrganizationId);
    setRegionId(EMPTY_ID);
    setBuildingId(EMPTY_ID);
    setControlSystemId(EMPTY_ID);
    setSelectedRegion(null);
    setSelectedBuilding(null);
    setSelectedControlSystem(null);
  };

  return (
    <>
      <FormItem
        label="Organization"
        colon={false}
        style={styles.formItem}
        hidden={!showOrganization}
      >
        <Select
          onChange={handleOrganizationChanged}
          defaultValue={organizationId !== null ? organizationId : EMPTY_ID}
          value={organizationId !== null ? organizationId : EMPTY_ID}
          style={{ width: '100%' }}
        >
          <Option key="*" value={EMPTY_ID}>
            All
          </Option>
          {userProfile.organizations !== null &&
            userProfile.organizations.map((org) => (
              <Option key={org.id} value={org.id}>
                {org.name}
              </Option>
            ))}
        </Select>
      </FormItem>
      <FormItem
        label="Region"
        colon={false}
        style={styles.formItem}
        hidden={!showRegion}
      >
        <Select
          onChange={handleRegionChanged}
          defaultValue={selectedRegion !== null ? selectedRegion.id : EMPTY_ID}
          value={selectedRegion !== null ? selectedRegion.id : EMPTY_ID}
          style={{ width: '100%' }}
        >
          <Option key="*" value={EMPTY_ID}>
            All
          </Option>
          {regions !== null &&
            regions.map((region) => (
              <Option key={region.id} value={region.id}>
                {region.name}
              </Option>
            ))}
        </Select>
      </FormItem>
      <FormItem
        label="Building"
        colon={false}
        style={styles.formItem}
        hidden={!showBuilding}
      >
        <Select
          onChange={handleBuildingChanged}
          defaultValue={
            selectedBuilding !== null ? selectedBuilding.id : EMPTY_ID
          }
          value={selectedBuilding !== null ? selectedBuilding.id : EMPTY_ID}
          style={{ width: '100%' }}
        >
          <Option key="*" value={EMPTY_ID}>
            All
          </Option>
          {buildings !== null &&
            buildings.map((building) => (
              <Option key={building.id} value={building.id}>
                {building.name}
              </Option>
            ))}
        </Select>
      </FormItem>
      <FormItem
        label="Control System"
        colon={false}
        style={styles.formItem}
        hidden={!showControlSystem}
      >
        <Select
          onChange={handleControlSystemChanged}
          defaultValue={
            selectedControlSystem !== null ? selectedControlSystem.id : EMPTY_ID
          }
          value={
            selectedControlSystem !== null ? selectedControlSystem.id : EMPTY_ID
          }
          style={{ width: '100%' }}
        >
          <Option key="*" value={EMPTY_ID}>
            All
          </Option>
          {controlSystems !== null &&
            controlSystems.map((controlSystem) => (
              <Option key={controlSystem.id} value={controlSystem.id}>
                {controlSystem.name}
              </Option>
            ))}
        </Select>
      </FormItem>
    </>
  );
};

export default ReferencesSelector;
