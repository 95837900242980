import React, { useContext, useEffect } from 'react';
import { Col, Row } from 'antd';

import FilterPanel from '@totem/components/devices/variables/presentation/layout/FilterPanel';
import LayoutContext from '@totem/components/devices/variables/presentation/layout/LayoutContext';
import SectionContainer from '@totem/components/devices/variables/presentation/layout/layoutSection/SectionContainer';
import CurrentValues from '@totem/components/devices/variables/presentation/layout/templates/components/CurrentValues';
import CurrentValueWithStatusTablesSection from '@totem/components/devices/variables/presentation/layout/templates/components/CurrentValueWithStatusTablesSection';
import LineChart from '@totem/components/devices/variables/presentation/layout/templates/components/LineChart';
import Loading from '@totem/components/Loading';
import { isNotNull } from '@totem/utilities/common';
import CurrentValueWithLastUpdateTablesSection
  from '@totem/components/devices/variables/presentation/layout/templates/components/CurrentValuesWith LastUpdateTableSection';

const BeaconTenantTemplate = () => {
  const { loading, layoutData, filterOptions, setFilterOptions } =
    useContext(LayoutContext);

  // Set Initial Filter Location Value
  useEffect(() => {
    if (
      isNotNull(filterOptions) &&
      filterOptions.location.options.length > 0 &&
      filterOptions.location.filter.length === 0
    ) {
      setFilterOptions({
        ...filterOptions,
        location: {
          ...filterOptions.location,
          filter: [filterOptions.location.options[0].value],
        },
      });
    }
  }, [layoutData, filterOptions, setFilterOptions]);

  return (
    <>
      {!loading && (
        <>
          <FilterPanel />
          <br />
          <Row>
            <Col span={12}>
              <SectionContainer sectionName="currentValue">
                <CurrentValues />
              </SectionContainer>
              <br />
              <br />
              <SectionContainer sectionName="details_status">
                <CurrentValueWithStatusTablesSection />
              </SectionContainer>
              <SectionContainer sectionName="details_lastUpdate">
                <CurrentValueWithLastUpdateTablesSection />
              </SectionContainer>
            </Col>
            <Col span={12}>
              <SectionContainer sectionName="chart">
                <LineChart title="Temperature Trend" />
              </SectionContainer>
            </Col>
          </Row>
        </>
      )}
      {loading && (
        <Row>
          <Col span={12}>
            <Loading message="Loading Data" />
          </Col>
          <Col span={12}>
            <Loading message="Loading Data" />
          </Col>
        </Row>
      )}
    </>
  );
};

export default BeaconTenantTemplate;
