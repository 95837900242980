import { createContext } from 'react';

import {
  AnalysisReport,
  AnalysisReportInput,
  FilterOptions,
} from '@totem/components/surveyV2/analysisReport/types';

interface Context {
  input: AnalysisReportInput;
  setInput: (input: AnalysisReportInput) => void;
  loading: boolean;
  data: AnalysisReport;
  filterOptions: FilterOptions;
  action: string;
  onAction?: (action: string) => void;
}

export default createContext<Context>({
  input: {},
  setInput: () => {},
  loading: false,
  data: null,
  filterOptions: null,
  action: '',
  onAction: () => {},
});
