import React, { ReactNode } from 'react';

import './base.css';

type Props = {
  children?: ReactNode;
};

const FiltersChipsContainer = ({ children }: Props) => {
  return <div styleName="filter-chips-container">{children}</div>;
};

export default FiltersChipsContainer;
