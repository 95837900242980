import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { TablePaginationConfig } from 'antd/es/table';
import { ColumnProps } from 'antd/lib/table';
import { SorterResult } from 'antd/lib/table/interface';

import Table from '@totem/components/common/table/Table';
import TicketingContext from '@totem/components/ticketing/listContainer/ticketingContainerContext';
import { TicketQueryResult } from '@totem/types/ticketing';
import { formatDate } from '@totem/utilities/common';

import '../../ticketing/ticketing.css';

const TicketList = () => {
  const {
    input,
    setInput,
    ticketData,
    loading,
    totalRecords,
    onTicketSelected,
  } = useContext(TicketingContext);

  const callTicketAction = (ticket: TicketQueryResult) => {
    if (typeof onTicketSelected === 'function') {
      onTicketSelected(ticket.ticket.id, ticket);
    }
  };

  const getTicketClickAction = (ticket: TicketQueryResult) => {
    if (typeof onTicketSelected === 'function') {
      return (
        <span
          onClick={() => {
            callTicketAction(ticket);
          }}
        >
          {ticket.ticket.number}
        </span>
      );
    }

    return (
      <Link
        to={`/dashboard/ticket/${ticket.ticket.id}`}
        target={ticket.ticket.id}
      >
        {ticket.ticket.number}
      </Link>
    );
  };

  const columns: ColumnProps<TicketQueryResult>[] = [
    {
      title: 'Number',
      dataIndex: 'ticket.number',
      key: 'type',
      render: (_, ticket: TicketQueryResult) => getTicketClickAction(ticket),
      showSorterTooltip: true,
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        compA.ticket.number === compB.ticket.number
          ? 0
          : compA.ticket.number > compB.ticket.number
          ? 1
          : -1,
    },
    {
      title: 'Category',
      dataIndex: 'ticket.category',
      key: 'category',
      render: (_, ticket: TicketQueryResult) => ticket.ticket.category,
      showSorterTooltip: true,
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        compA.ticket.category === compB.ticket.category
          ? 0
          : compA.ticket.category > compB.ticket.category
          ? 1
          : -1,
    },
    {
      title: 'Summary',
      dataIndex: 'ticket.summary',
      key: 'ticket.summary',
      render: (_, ticket: TicketQueryResult) => ticket.ticket.summary,
      showSorterTooltip: true,
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        compA.ticket.summary === compB.ticket.summary
          ? 0
          : compA.ticket.summary > compB.ticket.summary
          ? 1
          : -1,
    },
    {
      title: 'Priority',
      dataIndex: 'ticket.priority',
      key: 'priority',
      render: (_, ticket: TicketQueryResult) => ticket.ticket.priority,
      showSorterTooltip: true,
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        compA.ticket.priority === compB.ticket.priority
          ? 0
          : compA.ticket.priority > compB.ticket.priority
          ? 1
          : -1,
    },
    {
      title: 'Status',
      dataIndex: 'ticket.status',
      key: 'status',
      render: (_, ticket: TicketQueryResult) => ticket.ticket.status,
      showSorterTooltip: true,
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        compA.ticket.status === compB.ticket.status
          ? 0
          : compA.ticket.status > compB.ticket.status
          ? 1
          : -1,
    },
    {
      title: 'Created',
      dataIndex: 'ticket.createdAt',
      key: 'ticket.createdAt',
      render: (_, ticket: TicketQueryResult) =>
        formatDate(ticket.ticket.createdAt),
      sorter: (compA, compB) =>
        compA.ticket.createdAt === compB.ticket.createdAt
          ? 0
          : compA.ticket.createdAt > compB.ticket.createdAt
          ? 1
          : -1,
      showSorterTooltip: true,
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Updated',
      dataIndex: 'ticket.updatedAt',
      key: 'ticket.updatedAt',
      render: (_, ticket: TicketQueryResult) =>
        formatDate(ticket.ticket.updatedAt),
      sorter: (compA, compB) =>
        compA.ticket.updatedAt === compB.ticket.updatedAt
          ? 0
          : compA.ticket.updatedAt > compB.ticket.updatedAt
          ? 1
          : -1,
      showSorterTooltip: true,
      sortDirections: ['ascend', 'descend'],
    },
  ];

  const getTickets = () => {
    if (ticketData !== null) {
      if (ticketData.tickets !== null) {
        return ticketData.tickets;
      }
    }
    return [];
  };

  const handleTableChange = (
    updatedPagination: TablePaginationConfig,
    filters: SorterResult<TicketQueryResult>,
    sorter,
  ) => {
    const { ...params } = filters;

    let sortDir: string = sorter.order === 'descend' ? '-1' : '1';
    if (typeof sorter.order === 'undefined' || sorter.order === null) {
      if (
        typeof input.sortDirection !== 'undefined' &&
        input.sortDirection !== null
      ) {
        sortDir = input.sortDirection;
      }
    }

    let sortField = input.sortField ? input.sortField : 'createdAt';
    if (
      typeof sorter.field !== 'undefined' &&
      typeof sorter.order !== 'undefined'
    ) {
      sortField = sorter.field;
    }

    // @ts-ignore
    setInput({
      ...input,
      ...params,
      pageSize: updatedPagination.pageSize,
      page: updatedPagination.current,
      sortField,
      sortDirection: sortDir,
    });
  };

  return (
    <Table
      showSorterTooltip
      columns={columns}
      dataSource={getTickets()}
      loading={loading}
      onChange={handleTableChange}
      pagination={{
        current: input.page,
        pageSize: input.pageSize,
        total: totalRecords,
        showSizeChanger: true,
      }}
      rowKey={record => record.ticket.id}
    />
  );
};

export default TicketList;
