import * as R from 'ramda';

import { User } from '@totem/types/user';

import accountUtilities from './accountUtilities';

let currentUserRole = null;
let currentUserRoleLevel = null;

const setUserRole = (roleId) => {
  return (currentUserRole = accountUtilities.getRoleString(roleId));
};

const setUserRoleLevel = (roleId) => {
  return (currentUserRoleLevel = roleId);
};

/**
 * ASSIGNED_VENDOR_ROLE represents the value that belongs to
 * the assigned vendor role.  This isn't a user that belongs
 * to a vendor organization type, instead they are a different
 * role altogether from a ReadOnly, Team Member, etc user which
 * begins the enumeration at 0.
 */
const ASSIGNED_VENDOR_ROLE = -1;
const ASSIGNED_TENANT_ROLE = -2;

/**
 * ROLES
 * To add a new role insert it below roles that have a higher permission.
 * Update the role immediately below to inherit properly.
 * Add the new role to the available ROLE_SETS.
 * Ex.
 */
const SYSTEMS_ADMIN_ROLE = 'Systems Administrator';
const ADMIN_ROLE = 'Admin';
const TEAM_MEMBER_ROLE = 'Team Member';
const COLLABORATOR_ROLE = 'Collaborator';
const VENDOR_ROLE = 'Vendor';
const TENANT_ROLE = 'Tenant';

const SYSTEMS_ADMINISTRATOR = [SYSTEMS_ADMIN_ROLE];
const ORGANIZATION_ADMINISTRATOR = [...SYSTEMS_ADMINISTRATOR, ADMIN_ROLE];
const TEAM_MEMBER = [...ORGANIZATION_ADMINISTRATOR, TEAM_MEMBER_ROLE];
const COLLABORATOR = [...TEAM_MEMBER, COLLABORATOR_ROLE];
const VENDOR = [...COLLABORATOR, VENDOR_ROLE];
const TENANT = [...VENDOR_ROLE, TENANT_ROLE];

export const ROLE_SETS = {
  SYSTEMS_ADMINISTRATOR,
  ORGANIZATION_ADMINISTRATOR,
  TEAM_MEMBER,
  COLLABORATOR,
  VENDOR,
  TENANT,
};

export const minimumRequiredRole = (roleSet) => {
  return roleSet.includes(currentUserRole);
};

export const hasLowerRoleLevel = (testRoleId) => {
  return currentUserRoleLevel > testRoleId;
};

export const isVendorUser = () => {
  return currentUserRoleLevel === ASSIGNED_VENDOR_ROLE;
};

export const isTenantUser = () => {
  return currentUserRoleLevel === ASSIGNED_TENANT_ROLE;
};

export const isSystemsAdmin = () =>
  minimumRequiredRole(ROLE_SETS.SYSTEMS_ADMINISTRATOR);

export const getDashboardPath = () => {
  return isTenantUser()
    ? '/tenant-dashboard'
    : isVendorUser()
      ? '/vendor-dashboard'
      : '/dashboard';
};

export const getUserRole = () => currentUserRole;

export const registerFullStory = (user: User) => {
  // @ts-ignore
  if (!R.isNil(window.FS)) {
    // @ts-ignore
    window.FS.identify(user.id, {
      displayName: `${user.firstName} ${user.lastName}`,
      email: user.email,
    });
  }
};

export default {
  ASSIGNED_VENDOR_ROLE,
  ROLE_SETS,
  currentUserRole,
  hasLowerRoleLevel,
  minimumRequiredRole,
  isVendorUser,
  isTenantUser,
  setUserRole,
  setUserRoleLevel,
  isSystemsAdmin,
  getDashboardPath,
  registerFullStory,
};
