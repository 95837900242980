import React, { useCallback, useEffect, useState } from 'react';
import { Button, Flex, Input, notification } from 'antd';

import configuration from '@totem/utilities/configuration';

const inputStyle = {
  width: '400px',
};

const textStyle = {
  fontSize: '24px',
  fontWeight: 600,
  marginTop: '50px',
};

const RequestPasswordReset = () => {
  const [isSending, setIsSending] = useState(false);
  const [inputEmail, setInputEmail] = useState('');
  const [notifyTitle, setNotifyTitle] = useState('');
  const [notifyDescription, setNotifyDescription] = useState('');
  const [api, contextHolder] = notification.useNotification();

  useEffect(() => {
    if (notifyTitle !== '') {
      api.info({
        message: notifyTitle,
        description: notifyDescription,
        placement: 'top',
      });

      setNotifyTitle('');
      setNotifyDescription('');
    }
  }, [notifyTitle, notifyDescription]);
  const sendPasswordResetRequest = useCallback(
    async (emailAddress) => {
      if (isSending) {
        return;
      }

      const baseUrl = configuration.API_DOMAIN;
      const payload = {
        email: emailAddress,
      };

      setIsSending(true);

      fetch(`${baseUrl}/ras/passwordReset`, {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
        body: JSON.stringify(payload),
      }).then((res) => {
        setIsSending(false);
        if (res.status < 400) {
          // Success
          setInputEmail('');
          setNotifyTitle('Success');
          setNotifyDescription(
            'Request for password reset email has been successfully sent.',
          );
        } else {
          // Failed
          setNotifyTitle('Error');
          setNotifyDescription(
            'An error occurred requesting password reset email.',
          );
        }
      });
    },
    [isSending],
  );

  const handleClick = () => {
    sendPasswordResetRequest(inputEmail);
  };

  const handleChange = ({ target: { value } }) => {
    setInputEmail(value);
  };

  return (
    <Flex gap="middle" align="center" justify="center" vertical>
      <div style={textStyle}>
        Enter email address to request a password reset email.
      </div>
      <Input style={inputStyle} value={inputEmail} onChange={handleChange} />
      <Button title="Submit" onClick={handleClick} type="primary">
        Submit
      </Button>
      {contextHolder}
    </Flex>
  );
};

export default RequestPasswordReset;
