import React, { useContext } from 'react';

import LayoutContext from '@totem/components/devices/variables/presentation/layout/LayoutContext';
import BeaconTenantTemplate from '@totem/components/devices/variables/presentation/layout/templates/beacon_tenant';
import Loading from '@totem/components/Loading';
import { isNotNull } from '@totem/utilities/common';

const LayoutTemplateSelector = () => {
  const { layoutData } = useContext(LayoutContext);

  const getTemplate = () => {
    if (isNotNull(layoutData) && isNotNull(layoutData.layout)) {
      switch (layoutData.layout.template) {
        case 'beacon_tenant':
          return <BeaconTenantTemplate />;
        default:
          return <Loading message="Loading Layout" />;
      }
    }

    return <Loading message="Loading Layout" />;
  };

  return getTemplate();
};

export default LayoutTemplateSelector;
