import { createContext } from 'react';

import {
  Region,
  SummaryInput,
} from '@totem/components/portfolio/summary/types';

interface Context {
  input: SummaryInput;
  setInput: (input: SummaryInput) => void;
  loading: boolean;
  data: Region[];
  filteredRegions: Region[],
  action: string;
  onAction?: (action: string) => void;
  selectedRegionIds?: string[];
  selectedBuildingIds?: string[];
  setSelectedRegionIds?: (selected: string[]) => void;
  setSelectedBuildingIds?: (selected: string[]) => void;
}

export default createContext<Context>({
  input: {},
  setInput: () => {},
  loading: false,
  data: [],
  filteredRegions: [],
  action: '',
  onAction: () => {},
});
