import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { LinkOutlined } from '@ant-design/icons';
import { Button, Input, Modal, Steps } from 'antd';

import { EMPTY_ID } from '@totem/components/common/referencesModal/ReferencesModal';
import TicketTable from '@totem/components/events/ticketLinkModal/TicketTable';
import ModalFooter from '@totem/components/ModalFooter';
import ModalTitle from '@totem/components/ModalTitle';
import TicketEmbeddedContainer from '@totem/components/ticketing/listContainer/TicketEmbeddedContainer';
import { Event } from '@totem/types/events';
import { TicketingFilters, TicketQueryResult } from '@totem/types/ticketing';

import '../events.css';

export interface Props {
  event: Event;
  visible: boolean;
  onClose: () => void;
  onAssociateTicket: (eventId: string, ticket: TicketQueryResult) => void;
}

const TicketAssociateModal = ({
  event,
  visible,
  onClose,
  onAssociateTicket,
}: Props) => {
  const [selectedTicket, setSelectedTicket] = useState<TicketQueryResult>(null);
  const [staticFilters, setStaticFilters] = useState<TicketingFilters>({
    ticketNumber: '',
  });
  const handleTicketSelected = (
    ticketId: string,
    ticket: TicketQueryResult,
  ) => {
    setSelectedTicket(ticket);
  };

  const getTicketStatus = () => {
    if (typeof selectedTicket !== 'undefined' && selectedTicket !== null) {
      return 'finish';
    }

    return 'process';
  };

  const getTicketTitle = () => {
    if (typeof selectedTicket !== 'undefined' && selectedTicket !== null) {
      return 'Ticket';
    }
    return 'Select Ticket (use ticket table below)';
  };

  const getTicketContent = () => {
    if (typeof selectedTicket !== 'undefined' && selectedTicket !== null) {
      return (
        <span>
          <Link
            to={`/dashboard/ticket/${selectedTicket.ticket.id}`}
            target="eventTicketDetails"
          >
            {selectedTicket.ticket.number}
          </Link>
          : {selectedTicket.ticket.summary}
        </span>
      );
    }
    return '';
  };

  const getAssociationStatus = () => {
    if (typeof selectedTicket !== 'undefined' && selectedTicket !== null) {
      return 'process';
    }

    return 'wait';
  };

  const handleCreateAssociation = () => {
    if (typeof onAssociateTicket === 'function') {
      onAssociateTicket(event.id, selectedTicket);
    }
  };

  const handleSearch = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = evt.target.value;
    if (searchTerm !== null) {
      setStaticFilters({ ticketNumber: searchTerm });
    } else {
      setStaticFilters({ ticketNumber: '' });
    }
  };

  return (
    <Modal
      open={visible}
      onCancel={onClose}
      title={<ModalTitle>Associate Event to Ticket</ModalTitle>}
      confirmLoading={false}
      width={'60%'}
      footer={
        <ModalFooter>
          <Button onClick={onClose}>Close</Button>
        </ModalFooter>
      }
    >
      <Steps direction="vertical" current={1}>
        <Steps.Step
          title="Event"
          status="finish"
          description={event.description}
        />
        <Steps.Step
          title={getTicketTitle()}
          status={getTicketStatus()}
          description={getTicketContent()}
        />
        <Steps.Step
          title="Create Association"
          status={getAssociationStatus()}
          description={
            <Button
              type="primary"
              shape="round"
              icon={<LinkOutlined />}
              onClick={handleCreateAssociation}
              disabled={getAssociationStatus() === 'wait'}
            >
              Associate
            </Button>
          }
        />
      </Steps>
      <br />
      <Input
        placeholder="Filter by Ticket Number"
        style={{ width: '50%', maxWidth: '150px' }}
        onChange={handleSearch}
      />
      <TicketEmbeddedContainer
        defaultFilters={{
          status: ['OPEN', 'RESOLVED'],
          category: [event.sourceType],
        }}
        staticFilters={staticFilters}
        onTicketSelected={handleTicketSelected}
      >
        <div styleName="card-body">
          <TicketTable
            selectedTicketId={
              typeof selectedTicket !== 'undefined' && selectedTicket !== null
                ? selectedTicket.ticket.id
                : EMPTY_ID
            }
          />
        </div>
      </TicketEmbeddedContainer>
    </Modal>
  );
};

export default TicketAssociateModal;
