/* eslint-disable id-length */
import { useEffect } from 'react';
import { ApolloError } from '@apollo/client';
import { notification } from 'antd';

import { ErrorNotification, GQLErrorType } from '@totem/types/hooks';

const GENERIC_DESCRIPTION = 'An unknown error occurred. Please try again.';

const getErrors = (
  err: ApolloError,
  title: string,
  description?: string,
): ErrorNotification[] => {
  const userFriendlyErrors = err.graphQLErrors.filter(
    e => e?.extensions?.code === GQLErrorType.USER,
  );

  if (userFriendlyErrors.length) {
    return userFriendlyErrors.map(e => ({
      message: e.extensions?.title || title,
      description: e.extensions?.message || description || GENERIC_DESCRIPTION,
    }));
  }

  return [
    {
      message: title,
      description: GENERIC_DESCRIPTION,
    },
  ];
};

export const useErrorNotification = (
  err: ApolloError,
  title: string,
  description?: string,
) => {
  useEffect(() => {
    if (err) {
      const errors = getErrors(err, title, description);

      errors.forEach(e => {
        notification.error({
          message: e.message,
          description: e.description,
          duration: 0,
        });
      });
    }
  }, [err, title, description]);
};
