import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Checkbox, Input, InputNumber } from 'antd';
import { debounce } from '@totem/utilities/debounce';

import FormFieldLabel from '@totem/components/FormFieldLabel';
import QuestionOptionCriticality from '@totem/components/surveyV2/templateDetails/QuestionOptionCriticality';
import TemplateContext from '@totem/components/surveyV2/templateDetails/SurveyTemplateContext';
import {
  Category,
  QuestionOption,
  SurveyQuestion,
  SurveyTemplate,
} from '@totem/components/surveyV2/types';
import { Criticality } from '@totem/types/criticality';
import { getToken } from '@totem/utilities/accountUtilities';
import { V2_SURVEY_ENDPOINT } from '@totem/utilities/endpoints';

const DEBOUNCE_TIME = 750;

type Props = {
  category: Category;
  question: SurveyQuestion;
  option: QuestionOption;
};

const SurveyTemplateQuestionOption = ({
  category,
  question,
  option,
}: Props) => {
  const { surveyTemplateId, data, setData, setIsLoading } =
    useContext(TemplateContext);
  const [localState, setLocalState] = useState<QuestionOption>(option);
  const [optionUpdated, setOptionUpdated] = useState<boolean>(false);

  const sendQuestionOptionChanged = useCallback(
    debounce(
      (
        templateId: string,
        categoryId: string,
        questionId: string,
        update: QuestionOption,
      ) => {
        setIsLoading(true);

        const payload = {
          action: 'update',
          option: update,
        };

        fetch(
          `${V2_SURVEY_ENDPOINT}/template/${templateId}/categories/${categoryId}/questions/${questionId}/options`,
          {
            method: 'POST',
            headers: new Headers({
              Authorization: `Bearer ${getToken()}`,
            }),
            body: JSON.stringify(payload),
          },
        )
          .then((res) => res.json())
          .then((result: SurveyTemplate) => {
            setData(result);
          })
          .then(() => {
            setIsLoading(false);
          });
      },
      DEBOUNCE_TIME,
    ),
    [],
  );

  useEffect(() => {
    if (optionUpdated) {
      setOptionUpdated(false);
      sendQuestionOptionChanged(
        surveyTemplateId,
        category.id,
        question.id,
        localState,
      );
    }
  }, [localState]);

  const handleCriticallityChange = (newCriticallity: Criticality) => {
    setOptionUpdated(true);
    setLocalState({ ...localState, criticality: newCriticallity });
  };

  const handleScoreChange = (newScore: number) => {
    setOptionUpdated(true);
    setLocalState({
      ...localState,
      score: {
        value: localState.label,
        valueInt: newScore,
        valueFloat: newScore,
        valueArray: [],
      },
    });
  };

  return (
    <div>
      <FormFieldLabel>Option</FormFieldLabel>
      <Input
        placeholder="Option"
        value={localState.label}
        onChange={({
          target: { value },
        }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
          setOptionUpdated(true);
          setLocalState({
            ...localState,
            label: value,
            score: { ...localState.score, value },
          });
        }}
      />

      {data.type !== 'policyAudit' && (
        <>
          <FormFieldLabel>Recommendation</FormFieldLabel>
          <Input
            placeholder="Recommendation"
            value={localState.recommendation}
            onChange={({
              target: { value },
            }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
              setOptionUpdated(true);
              setLocalState({ ...localState, recommendation: value });
            }}
          />
        </>
      )}

      {data.type === 'policyAudit' && (
        <>
          <FormFieldLabel>Finding Description</FormFieldLabel>
          <Input
            placeholder="Finding Description"
            value={localState.findingDescription}
            onChange={({
              target: { value },
            }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
              setOptionUpdated(true);
              setLocalState({ ...localState, findingDescription: value });
            }}
          />
        </>
      )}

      <FormFieldLabel>Criticality</FormFieldLabel>
      <QuestionOptionCriticality
        option={option}
        onChange={handleCriticallityChange}
      />

      {question.scored && (
        <>
          <FormFieldLabel>Score</FormFieldLabel>
          <InputNumber
            defaultValue={localState.score.valueInt}
            value={localState.score.valueInt}
            onChange={handleScoreChange}
          />
        </>
      )}

      {data.type === 'policyAudit' && (
        <>
          <br />
          <br />
          <Checkbox
            checked={localState.moreInfoVisible}
            onChange={(evt) => {
              setOptionUpdated(true);
              setLocalState({
                ...localState,
                moreInfoVisible: evt.target.checked,
              });
            }}
          >
            More Information Visible
          </Checkbox>
        </>
      )}

      {data.type === 'policyAudit' && (
        <>
          <br />
          <br />
          <Checkbox
            checked={localState.moreInfoRequired}
            onChange={(evt) => {
              setOptionUpdated(true);
              setLocalState({
                ...localState,
                moreInfoRequired: evt.target.checked,
              });
            }}
          >
            More Information Required
          </Checkbox>
        </>
      )}

      {data.type === 'policyAudit' &&
        (localState.moreInfoRequired || localState.moreInfoVisible) && (
          <>
            <FormFieldLabel>More Information Label</FormFieldLabel>
            <Input
              placeholder="More Information Label"
              value={localState.moreInfoLabel}
              onChange={({
                target: { value },
              }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                setOptionUpdated(true);
                setLocalState({ ...localState, moreInfoLabel: value });
              }}
            />
          </>
        )}
    </div>
  );
};

export default SurveyTemplateQuestionOption;
