import { createContext } from 'react';

import { SurveyInstanceConnectionInput } from '@totem/components/surveyV2/instances/types';
import {
  SurveyInstanceStatistics,
  SurveyQueryResult,
} from '@totem/components/surveyV2/types';
interface Context {
  input: SurveyInstanceConnectionInput;
  setInput: (input: SurveyInstanceConnectionInput) => void;
  loading: boolean;
  setIsLoading: (isLoading: boolean) => void;
  data: SurveyQueryResult[];
  totalRecords: number;
  action: string;
  selectedInstance: SurveyQueryResult;
  onAction: (newAction: string, item: SurveyQueryResult) => void;
  selectedForLaunch: string[];
  setSelectedForLaunch: (selected: string[]) => void;
  statistics: SurveyInstanceStatistics;
  setStatistics: (stats: SurveyInstanceStatistics) => void;
}

export default createContext<Context>({
  input: {},
  setInput: () => {},
  loading: false,
  setIsLoading: () => {},
  data: [],
  totalRecords: 0,
  action: '',
  selectedInstance: null,
  onAction: () => {},
  selectedForLaunch: [],
  statistics: null,
  setStatistics: () => {},
  setSelectedForLaunch: () => {},
});
