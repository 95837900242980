import React, { useContext } from 'react';

import FilterChip from '@totem/components/common/FilterChip';
import EventFilterContext from '@totem/components/events/eventFilters/EventFilterContext';
import { EventFilterInput } from '@totem/components/events/eventFilters/types';

import '../events.css';
import { toPascalCase } from '@totem/utilities/common';
const styles = {
  filterChip: {
    marginLeft: '2rem',
    marginTop: '2rem',
  },
};

const EventsFilterChips = () => {
  const { input, setInput, filterOptions } = useContext(EventFilterContext);

  const handleMultiFilterDelete = (
    property: keyof EventFilterInput,
    value: string,
  ) => {
    // @ts-ignore
    const updatedValue = input[property].filter(val => val !== value);
    setInput({
      ...input,
      [property]: updatedValue.length ? updatedValue : null,
    });
  };

  const GetOptionNameFromValue = (option: string, value: string) => {
    switch (option) {
      case 'Region':
        for (let idx = 0; idx < filterOptions.region.length; idx++) {
          if (filterOptions.region[idx].value === value) {
            return filterOptions.region[idx].name;
          }
        }
        break;
      case 'Building':
        for (let idx = 0; idx < filterOptions.building.length; idx++) {
          if (filterOptions.building[idx].value === value) {
            return filterOptions.building[idx].name;
          }
        }
        break;
      case 'Device':
        for (let idx = 0; idx < filterOptions.device.length; idx++) {
          if (filterOptions.device[idx].value === value) {
            return filterOptions.device[idx].name;
          }
        }
        break;
      case 'Severity':
        switch (value) {
          case '0':
            return 'OK';
          case '1':
            return 'Information';
          case '2':
            return 'Warning';
          case '3':
            return 'Minor';
          case '4':
            return 'Major';
          case '5':
            return 'Critical';
          default:
            return 'Unknown';
        }
      default:
        return '';
    }
    return '';
  };

  return (
    <>
      {input.severity &&
        input.severity.map(option => {
          return (
            <FilterChip
              key={option}
              label={`Severity: ${GetOptionNameFromValue('Severity', option)}`}
              style={styles.filterChip}
              onClose={() => handleMultiFilterDelete('severity', option)}
            />
          );
        })}
      {input.sourceSystem &&
        input.sourceSystem.map(option => {
          return (
            <FilterChip
              key={option}
              label={`Source System: ${option}`}
              style={styles.filterChip}
              onClose={() => handleMultiFilterDelete('sourceSystem', option)}
            />
          );
        })}
      {input.sourceType &&
        input.sourceType.map(option => {
          return (
            <FilterChip
              key={option}
              label={`Source Type: ${option}`}
              style={styles.filterChip}
              onClose={() => handleMultiFilterDelete('sourceType', option)}
            />
          );
        })}
      {input.eventType &&
        input.eventType.map(option => {
          return (
            <FilterChip
              key={option}
              label={`Event Type: ${option}`}
              style={styles.filterChip}
              onClose={() => handleMultiFilterDelete('eventType', option)}
            />
          );
        })}
      {input.deviceId &&
        input.deviceId.map(option => {
          return (
            <FilterChip
              key={option}
              label={`Device: ${GetOptionNameFromValue('Device', option)}`}
              style={styles.filterChip}
              onClose={() => handleMultiFilterDelete('deviceId', option)}
            />
          );
        })}
      {input.regionId &&
        input.regionId.map(option => {
          return (
            <FilterChip
              key={option}
              label={`Region: ${GetOptionNameFromValue('Region', option)}`}
              style={styles.filterChip}
              onClose={() => handleMultiFilterDelete('regionId', option)}
            />
          );
        })}
      {input.buildingId &&
        input.buildingId.map(option => {
          return (
            <FilterChip
              key={option}
              label={`Building: ${GetOptionNameFromValue('Building', option)}`}
              style={styles.filterChip}
              onClose={() => handleMultiFilterDelete('buildingId', option)}
            />
          );
        })}
      {input.status &&
        input.status.map(option => {
          return (
            <FilterChip
              key={option}
              label={`Status: ${toPascalCase(option)}`}
              style={styles.filterChip}
              onClose={() => handleMultiFilterDelete('status', option)}
            />
          );
        })}
    </>
  );
};

export default EventsFilterChips;
