import React from 'react';

import BasePane from '@totem/components/BasePane';
import CardBody from '@totem/components/CardBody';
import ContentLayout from '@totem/components/ContentLayout';
import PrimaryContentPane from '@totem/components/PrimaryContentPane';
import QueueTabs from '@totem/components/ticketing/queueTotals/QueueTabs';
import QueueTotalsContainer from '@totem/components/ticketing/queueTotals/QueueTotalsContainer';

const QueueTotalsPage = () => {
  return (
    <ContentLayout>
      <QueueTotalsContainer>
        <BasePane>
          <PrimaryContentPane>
            <PrimaryContentPane>
              <CardBody>
                <QueueTabs />
              </CardBody>
            </PrimaryContentPane>
          </PrimaryContentPane>
        </BasePane>
      </QueueTotalsContainer>
    </ContentLayout>
  );
};

export default QueueTotalsPage;
