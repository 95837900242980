import React, { ReactNode } from 'react';

import './base.css';

type Props = {
  children?: ReactNode;
};
const ChartTitle = ({ children }: Props) => {
  return <div styleName="chart-title ">{children}</div>;
};

export default ChartTitle;
