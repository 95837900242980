import React from 'react';

import { Nmap } from '@totem/types/nmap';

export interface Context {
  refetch: () => void;
  loading: boolean;
  nmaps: Nmap[];
  limit: number;
  page: number;
  handlePagination: (pagination: any) => void;
  total: number;
}

export const initialState: Context = {
  refetch: () => {},
  loading: true,
  nmaps: [],
  limit: 10,
  page: 1,
  handlePagination: () => {},
  total: 0,
};

const NmapContext = React.createContext<Context>(initialState);

export default NmapContext;
