import { UserProfile } from '@totem/types/user';

export const isIBUser = (userProfile: UserProfile) => {
  if (userProfile !== null && userProfile.email !== null) {
    return (
      userProfile.email.toLowerCase().endsWith('@intelligentbuildings.com') ||
      userProfile.email.toLowerCase().startsWith('shannon.smith')
    );
  }
  return false;
};
