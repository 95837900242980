import React, { useState } from 'react';
import { Avatar, Card, Col, Row, Tabs, TabsProps } from 'antd';

import TicketList from '@totem/components/events/detailView/TicketList';
import TabTitle from '@totem/components/TabTitle';
import TicketEmbeddedContainer from '@totem/components/ticketing/listContainer/TicketEmbeddedContainer';
import { Event, EventFilters, EventOccurrence } from '@totem/types/events';

import '../events.css';

const styles = {
  selectable: {
    cursor: 'pointer',
    color: 'blue',
    textDecoration: 'underline',
  },
  detailsSection: {
    maxHeight: '70%',
    overflowY: 'scroll',
  },
};
export interface Props {
  event: Event;
}

const EventDetailView = ({ event }: Props) => {
  const [selectedOccurrence, setSelectedOccurrence] = useState<EventOccurrence>(
    event.occurrences !== null
      ? event.occurrences[event.occurrences.length - 1]
      : null,
  );
  const getSeverity = (severity: number) => {
    switch (severity) {
      case 5:
        return (
          <Avatar
            alt="Critical"
            style={{ backgroundColor: '#FF0000', color: '#FFFFFF' }}
          >
            <b>CRIT</b>
          </Avatar>
        );
      case 4:
        return (
          <Avatar
            alt="Major"
            style={{ backgroundColor: '#FF8C00', color: '#000000' }}
          >
            <b>MAJ</b>
          </Avatar>
        );
      case 3:
        return (
          <Avatar
            alt="Minor"
            style={{ backgroundColor: '#FFA500', color: '#000000' }}
          >
            <b>MIN</b>
          </Avatar>
        );
      case 2:
        return (
          <Avatar
            alt="Warning"
            style={{ backgroundColor: '#FFD700', color: '#000000' }}
          >
            <b>WARN</b>
          </Avatar>
        );
      case 1:
        return (
          <Avatar
            alt="Information"
            style={{ backgroundColor: '#0000FF', color: '#FFFFFF' }}
          >
            <b>INFO</b>
          </Avatar>
        );
      case 0:
        return (
          <Avatar
            alt="Ok"
            style={{ backgroundColor: '#00FF00', color: '#000000' }}
          >
            <b>OK</b>
          </Avatar>
        );
      default:
        return (
          <Avatar
            alt="Unknown"
            style={{ backgroundColor: '#606060', color: '#FFFFFF' }}
          >
            <b>UNK</b>
          </Avatar>
        );
    }
  };

  const formatDate = (dateIn: string) => {
    return dateIn ? new Date(dateIn).toLocaleString() : '-';
  };

  const getOccurrences = (evt: Event) => {
    if (evt.occurrences !== null) {
      return (
        <span>
          {evt.occurrences
            .sort((compA, compB) =>
              compA.eventTime === compB.eventTime
                ? 0
                : compA.eventTime > compB.eventTime
                ? -1
                : 1,
            )
            .map((evtOc, idx) => (
              <span
                style={styles.selectable}
                key={`${evtOc.eventTime}-${idx}`}
                onClick={() => setSelectedOccurrence(evtOc)}
              >
                {formatDate(evtOc.eventTime)}
                <br />
              </span>
            ))}
        </span>
      );
    }
    return <span />;
  };

  const getContacts = (evt: Event) => {
    if (evt.building !== null && evt.building.pointsOfContact !== null) {
      return (
        <>
          {evt.building.pointsOfContact.map((poc, idx) => (
            <Col span={12} key={`contact-card-${idx}`}>
              <Card title={poc.name} styleName="contact-card">
                {poc.phone !== null && poc.phone !== '' && (
                  <>
                    Phone: {poc.phone}
                    <br />
                  </>
                )}
                {poc.email !== null && poc.email !== '' && (
                  <>
                    Email: <a href={`mailto:${poc.email}`}>{poc.email}</a>
                    <br />
                  </>
                )}
              </Card>
            </Col>
          ))}
        </>
      );
    }
    return <span />;
  };

  const getTicketCount = () => {
    let count = 0;
    if (event !== null && event.keys !== null) {
      for (let idx = 0; idx < event.keys.length; idx++) {
        if (
          event.keys[idx].system === 'Ticketing' &&
          event.keys[idx].instance === 'IBD'
        ) {
          count = count + 1;
        }
      }
    }
    return count;
  };

  const getEventFilter = () => {
    const filters: EventFilters = {
      reference: [`Event Management:IBD:${event.id}`],
    };
    return filters;
  };

  const getTabItems = () => {
    const tabItems: TabsProps['items'] = [
      {
        key: '1',
        label: <TabTitle>Details</TabTitle>,
        children: (
          <Row>
            <Col span={6}>
              <div
                style={{
                  width: '100%',
                  height: '340px',
                  overflow: 'auto',
                  position: 'absolute',
                  top: 0,
                }}
              >
                <b>Occurrences:</b>
                <br />
                {getOccurrences(event)}
              </div>
            </Col>
            <Col span={18}>
              <div
                style={{
                  marginLeft: '20px',
                  height: '340px',
                  overflow: 'auto',
                  position: 'absolute',
                  top: 0,
                }}
              >
                <pre
                  style={{
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  {selectedOccurrence !== null
                    ? selectedOccurrence.details
                    : event.details}
                </pre>
              </div>
            </Col>
          </Row>
        ),
      },
    ];

    if (
      event.building !== null &&
      event.building.pointsOfContact !== null &&
      event.building.pointsOfContact.length > 0
    ) {
      tabItems.push({
        key: '3',
        label: <TabTitle>Contacts</TabTitle>,
        children: <Row>{getContacts(event)}</Row>,
      });
    }

    if (getTicketCount() > 0) {
      tabItems.push({
        key: 'tickets',
        label: <TabTitle>Tickets</TabTitle>,
        children: (
          <TicketEmbeddedContainer staticFilters={getEventFilter()}>
            <div styleName="card-body">
              <TicketList />
            </div>
          </TicketEmbeddedContainer>
        ),
      });
    }

    return tabItems;
  };

  return (
    <>
      <Row>
        <Col span={2}>{getSeverity(event.severity)}</Col>
        <Col span={7}>
          <b>Source:</b>
          <br />
          {event.sourceType}
          <br />
          <b>Type:</b>
          <br />
          {event.eventType}
          <br />
          <b>Status:</b>
          <br />
          {event.status.charAt(0).toUpperCase() +
            event.status.toLowerCase().slice(1)}
        </Col>
        <Col span={7}>
          <b>Created:</b>
          <br />
          {formatDate(event.eventTime)}
          <br />
          <b>Last Occurrence:</b>
          <br />
          {formatDate(event.lastOccurrence)}
        </Col>
        <Col span={7}>
          {event.region !== null && event.region.name !== '' && (
            <>
              <b>Region:</b>
              <br />
              {event.region.name}
              <br />
            </>
          )}
          {event.building !== null && event.building.name !== '' && (
            <>
              <b>Building:</b>
              <br />
              {event.building.name}
              <br />
            </>
          )}
          {event.controlSystem !== null && event.controlSystem.name !== '' && (
            <>
              <b>Control System:</b>
              <br />
              {event.controlSystem.name}
              <br />
            </>
          )}
          {event.device !== null && event.device.displayName !== '' && (
            <>
              <b>Device:</b>
              <br />
              {event.device.displayName}
            </>
          )}
        </Col>
        <Col span={1} />
      </Row>
      <Row>
        <Col span={2} />
        <Col span={21}>
          <b>Description:</b>
          <br />
          {selectedOccurrence !== null
            ? selectedOccurrence.description
            : event.description}
        </Col>
        <Col span={1} />
      </Row>
      <Tabs
        defaultActiveKey="1"
        style={{ height: '400px' }}
        items={getTabItems()}
      />
    </>
  );
};

export default EventDetailView;
