import React from 'react';
import { Col, Flex, Row } from 'antd';
import dayjs from 'dayjs';

import { VariableDataResponseExtended } from '@totem/components/devices/variables/presentation/layout/types';
import colors from '@totem/styles/colors';
import { isNotNull } from '@totem/utilities/common';

type Props = {
  value: VariableDataResponseExtended;
  maxDecimalPlaces: number;
};

const styles = {
  variableValue: {
    fontSize: '24px',
    fontWeight: 'bold',
  },
  underline: {
    borderBottomStyle: 'solid' as 'solid',
    borderBottomWidth: '2px',
    borderBottomColor: colors.neutral.black,
  },
  valueSection: {
    paddingTop: '16px',
  },
};

const CurrentValue = ({ value, maxDecimalPlaces }: Props) => {
  const lastUpdateTime = dayjs(+value.lastUpdateTime);
  return (
    <div style={{ padding: '16px' }}>
      <Row style={styles.underline}>
        <Col span={12}>
          <Flex justify="center">
            <div>
              {value.metaData.label}{' '}
              {isNotNull(value.metaData.location) &&
              value.metaData.location !== ''
                ? `(${value.metaData.location})`
                : ''}
            </div>
          </Flex>
        </Col>
        <Col span={12}>
          <Flex justify="center">
            <div>Last Update</div>
          </Flex>
        </Col>
      </Row>
      <Row style={styles.valueSection}>
        <Col span={12}>
          <Flex justify="center">
            <div>
              <span style={styles.variableValue}>
                {value.isNumeric
                  ? value.value.toFixed(maxDecimalPlaces)
                  : value.stringValue}
              </span>
            </div>
          </Flex>
        </Col>
        <Col span={12}>
          <Flex justify="center">
            <div>{lastUpdateTime.format('L LT')}</div>
          </Flex>
        </Col>
      </Row>
    </div>
  );
};

export default CurrentValue;
