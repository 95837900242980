import { applyMiddleware, compose, createStore } from 'redux';
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';
import createSagaMiddleware, { END } from 'redux-saga';
import thunk from 'redux-thunk';

import rootReducer from '../reducers/rootReducer';

export function configureStore(initialState) {
  const sagaMiddleware = createSagaMiddleware();
  const middlewares = [reduxImmutableStateInvariant(), sagaMiddleware, thunk];

  const composeEnhancers =
    ((window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ as typeof compose) ||
    compose;

  const store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(
      applyMiddleware(...middlewares),
      // @ts-ignore
      window.devToolsExtension ? window.devToolsExtension() : format => format, // add support for Redux dev tools
    ),
  );

  // @ts-ignore
  if (module.hot) {
    // @ts-ignore
    module.hot.accept('../reducers/rootReducer', () => {
      const nextReducer = require('../reducers/rootReducer').default;
      store.replaceReducer(nextReducer);
    });
  }

  // @ts-ignore
  store.runSaga = sagaMiddleware.run;
  // @ts-ignore
  store.close = () => store.dispatch(END);

  return store;
}
