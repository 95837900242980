import React, { useContext } from 'react';
import { ColumnProps } from 'antd/lib/table';

import Table from '@totem/components/common/table/Table';
import { Volume } from '@totem/components/devices/volumes/types';
import { FormatBytes } from '@totem/components/devices/volumes/utilities';
import VolumeContext from '@totem/components/devices/volumes/volumeContext';

const VolumeTable = () => {
  const { data, loading } = useContext(VolumeContext);

  const getData = () => {
    if (typeof data !== 'undefined' && data !== null) {
      return data;
    }
    return [];
  };

  const columns: ColumnProps<Volume>[] = [
    {
      title: 'Volume Name',
      dataIndex: 'volumeName',
      key: 'volumeName',
      defaultSortOrder: 'ascend',
      sorter: (compA, compB) =>
        compA.volumeName === compB.volumeName
          ? 0
          : compA.volumeName > compB.volumeName
          ? 1
          : -1,
    },
    {
      title: 'File System',
      dataIndex: 'fileSystem',
      key: 'fileSystem',
      sorter: (compA, compB) =>
        compA.fileSystem === compB.fileSystem
          ? 0
          : compA.fileSystem > compB.fileSystem
          ? 1
          : -1,
    },
    {
      title: 'Type',
      dataIndex: 'deviceType',
      key: 'deviceType',
      sorter: (compA, compB) =>
        compA.deviceType === compB.deviceType
          ? 0
          : compA.deviceType > compB.deviceType
          ? 1
          : -1,
    },
    {
      title: 'Capacity',
      dataIndex: 'size',
      key: 'size',
      render: (_, date: Volume) => FormatBytes(date.size),
      sorter: (compA, compB) =>
        compA.size === compB.size ? 0 : compA.size > compB.size ? 1 : -1,
    },
    {
      title: 'Free Space',
      dataIndex: 'freeSpace',
      key: 'freeSpace',
      render: (_, date: Volume) => FormatBytes(date.freeSpace),
      sorter: (compA, compB) =>
        compA.freeSpace === compB.freeSpace
          ? 0
          : compA.freeSpace > compB.freeSpace
          ? 1
          : -1,
    },
  ];

  return (
    <Table
      showSorterTooltip
      columns={columns}
      dataSource={getData()}
      loading={loading}
      rowKey={(record) => record.volumeName}
    />
  );
};

export default VolumeTable;
