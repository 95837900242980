import { createContext } from 'react';

import {
  LayoutSection,
  LayoutSectionResponse,
  VariableDataResponseExtended,
} from '@totem/components/devices/variables/presentation/layout/types';

interface Context {
  loading: boolean;
  sectionLayout: LayoutSection;
  sectionData: LayoutSectionResponse;
  sectionVariableData: VariableDataResponseExtended[];
  onAction?: (action: string) => void;
}

export default createContext<Context>({
  loading: false,
  sectionLayout: null,
  sectionData: null,
  sectionVariableData: [],
  onAction: null,
});
