import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Modal, Select } from 'antd';
import * as R from 'ramda';

import EmailAutoComplete from '@totem/components/common/autoCompletes/EmailAutoComplete';
import ModalFooter from '@totem/components/ModalFooter';
import ModalTitle from '@totem/components/ModalTitle';
import { AddEditUser } from '@totem/types/user';
import accountUtilities, {
  getAccountPayloadSync,
} from '@totem/utilities/accountUtilities';
import { validateEmail } from '@totem/utilities/validation';

const FormItem = Form.Item;
const { Option } = Select;

interface FormErrors {
  email?: string;
  firstName?: string;
  lastName?: string;
}

export interface Props {
  visible: boolean;
  loading: boolean;
  onClose: () => void;
  onSubmit: (user: AddEditUser) => void;
}

const initialUser: AddEditUser = {
  role: 0,
  email: '',
  firstName: '',
  lastName: '',
};

const styles = {
  form: {
    width: '100%',
  },
  formItem: {
    paddingBottom: '0',
    marginBottom: '1rem',
  },
};

export const isValidForm = (user: AddEditUser): FormErrors => {
  const formErrors: FormErrors = {};

  if (R.isEmpty(user.email)) {
    formErrors.email = 'Please enter an email';
  }

  if (user.email && !validateEmail(user.email)) {
    formErrors.email = 'Please enter a valid email';
  }

  if (R.isEmpty(user.firstName)) {
    formErrors.firstName = 'Please enter a first name';
  }

  if (R.isEmpty(user.lastName)) {
    formErrors.lastName = 'Please enter a last name';
  }

  return formErrors;
};

const UserAddModal = (props: Props) => {
  const { visible, loading, onClose, onSubmit } = props;

  const [user, setUser] = useState<AddEditUser>(initialUser);
  const [errors, setErrors] = useState<FormErrors>({});

  const userProfile = getAccountPayloadSync();

  useEffect(() => {
    if (!visible) {
      setErrors({});
      setUser(initialUser);
    }
  }, [visible]);

  const handleChange = ({
    target: { value, name },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setUser({ ...user, [name]: value });
  };

  const handleSubmit = () => {
    const formErrors = isValidForm(user);

    if (R.isEmpty(formErrors)) {
      onSubmit(user);
    }

    setErrors(formErrors);
  };

  const handleEmailChange = (email: string) => {
    setUser({ ...user, email });
  };

  return (
    <Modal
      open={visible}
      onCancel={onClose}
      title={<ModalTitle>Add User</ModalTitle>}
      okText="Add"
      confirmLoading={loading}
      footer={
        <ModalFooter>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={handleSubmit} loading={loading} type="primary">
            Add
          </Button>
        </ModalFooter>
      }
    >
      <Form layout="vertical" style={styles.form}>
        <FormItem label="Role" colon={false} style={styles.formItem}>
          <Select
            onChange={(role) => setUser({ ...user, role })}
            value={user.role}
          >
            {accountUtilities.USER_ROLES.filter((role) => {
              // TODO: Remove @ts-ignore : Token
              // @ts-ignore
              return role.value <= userProfile.role;
            }).map((role) => (
              <Option key={role.value} value={role.value}>
                {role.label}
              </Option>
            ))}
          </Select>
        </FormItem>
        <FormItem
          label="Email"
          colon={false}
          style={styles.formItem}
          validateStatus={R.isNil(errors.email) ? 'success' : 'error'}
          help={R.isNil(errors.email) ? null : errors.email}
        >
          <EmailAutoComplete
            value={user.email}
            onChange={handleEmailChange}
            placeholder="email@example.com"
          />
        </FormItem>
        <FormItem
          label="First Name"
          colon={false}
          style={styles.formItem}
          validateStatus={R.isNil(errors.firstName) ? 'success' : 'error'}
          help={R.isNil(errors.firstName) ? null : errors.firstName}
        >
          <Input
            name="firstName"
            value={user.firstName}
            onChange={handleChange}
            type="search"
          />
        </FormItem>
        <FormItem
          label="Last Name"
          colon={false}
          style={styles.formItem}
          validateStatus={R.isNil(errors.lastName) ? 'success' : 'error'}
          help={R.isNil(errors.lastName) ? null : errors.lastName}
        >
          <Input
            name="lastName"
            value={user.lastName}
            onChange={handleChange}
            type="search"
          />
        </FormItem>
      </Form>
    </Modal>
  );
};

export default UserAddModal;
