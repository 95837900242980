import React, { useContext } from 'react';
import { ColumnProps } from 'antd/lib/table';
import dayjs from 'dayjs';

import Table from '@totem/components/common/table/Table';
import OsPatchInstallContext from '@totem/components/devices/os-patch-installs/osPatchInstallContext';
import { OSPatchInstall } from '@totem/components/devices/os-patch-installs/types';
import { formatDateTimeDisplay } from '@totem/utilities/timeUtilities';

const OSPatchInstallsTable = () => {
  const { data, loading } = useContext(OsPatchInstallContext);

  const getData = () => {
    if (typeof data !== 'undefined' && data !== null) {
      return data;
    }
    return [];
  };

  const columns: ColumnProps<OSPatchInstall>[] = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (compA, compB) =>
        compA.name === compB.name ? 0 : compA.name > compB.name ? 1 : -1,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: (compA, compB) =>
        compA.status === compB.status
          ? 0
          : compA.status > compB.status
          ? 1
          : -1,
    },
    {
      title: 'KB Number',
      dataIndex: 'kbNumber',
      key: 'kbNumber',
      sorter: (compA, compB) =>
        compA.kbNumber === compB.kbNumber
          ? 0
          : compA.kbNumber > compB.kbNumber
          ? 1
          : -1,
    },
    {
      title: 'Installed At',
      dataIndex: 'installedAt',
      key: 'installedAt',
      defaultSortOrder: 'descend',
      render: (_, record: OSPatchInstall) =>
        formatDateTimeDisplay(dayjs.unix(record.installedAt).toISOString()),
      sorter: (compA, compB) =>
        compA.installedAt === compB.installedAt
          ? 0
          : compA.installedAt > compB.installedAt
          ? 1
          : -1,
    },
  ];

  return (
    <Table
      showSorterTooltip
      columns={columns}
      dataSource={getData()}
      loading={loading}
      rowKey={(record) => record.id}
    />
  );
};

export default OSPatchInstallsTable;
