import React, { ReactNode } from 'react';

import './base.css';

type Props = {
  children?: ReactNode;
};
const FormFieldLabel = ({ children }: Props) => {
  return <div styleName="form-field-label">{children}</div>;
};

export default FormFieldLabel;
