import React, { useContext } from 'react';
import { Card } from 'antd';

import Count from '@totem/components/common/Count';
import FindingsContext from '@totem/components/surveyV2/findings/FindingsContext';
import SubTitle from '@totem/components/surveyV2/SubTitle';
import colors from '@totem/styles/colors';
import { isNotNull } from '@totem/utilities/common';

import '../survey.css';

const styles = {
  nonCompliantCount: {
    border: `2px solid ${colors.criticality.high}`,
    background: colors.neutral.white,
    minWidth: '7rem',
    marginBottom: '3rem',
  },
  compliantCount: {
    background: colors.neutral.ash,
    minWidth: '7rem',
    marginBottom: '3rem',
  },
  subTitle: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
};

const PolicyResults = () => {
  const { filteredData } = useContext(FindingsContext);

  const totalCompliant = isNotNull(filteredData)
    ? filteredData.filter((chk) => chk.compliant === true).length
    : 0;
  const totalNonCompliant = isNotNull(filteredData)
    ? filteredData.filter((chk) => chk.compliant === false).length
    : 0;

  return (
    <Card title={<SubTitle>Policy Results</SubTitle>}>
      <div styleName="policy-results-container">
        <div styleName="graph-title">Non-Compliant Responses</div>
        <Count
          count={totalNonCompliant}
          style={styles.nonCompliantCount}
          size="large"
        />
        <div styleName="graph-title">Compliant Responses</div>
        <Count
          count={totalCompliant}
          style={styles.compliantCount}
          size="large"
        />
      </div>
    </Card>
  );
};

export default PolicyResults;
