import { call, fork, put, takeEvery } from 'redux-saga/effects';

import actionTypes from '@totem/actions/actionTypes';
import * as versionActions from '@totem/actions/versionActions';
import accountUtilities from '@totem/utilities/accountUtilities';
import api from '@totem/utilities/api';
import {
  CLIENTSIDE_VERSIONS_ENDPOINT,
  SERVERSIDE_VERSIONS_ENDPOINT,
} from '@totem/utilities/endpoints';

export function* getVersions() {
  try {
    // @ts-ignore
    const { data: web } = yield call(api.get, CLIENTSIDE_VERSIONS_ENDPOINT);
    // @ts-ignore
    const { data: server } = yield call(api.get, SERVERSIDE_VERSIONS_ENDPOINT);

    const data = {
      web,
      server: server.version,
    };

    yield put(versionActions.getVersionsSuccess(data));
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      versionActions.getVersionsFailure,
    );
  }
}

export function* retrieveVersions() {
  yield takeEvery(actionTypes.REQUEST_VERSIONS, getVersions);
}

export default [retrieveVersions];
