import React, { useCallback, useContext } from 'react';
import {
  ContactsOutlined,
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
  LockOutlined,
  UserAddOutlined,
  UserSwitchOutlined,
} from '@ant-design/icons';
import { Button, Dropdown, MenuProps } from 'antd';
import Cookies from 'universal-cookie';

import UserProfileContext from '@totem/components/UserProfileContext';
import { User } from '@totem/components/users/container/types';
import { getToken } from '@totem/utilities/accountUtilities';
import { isNotNull } from '@totem/utilities/common';
import { USERS_ENDPOINT } from '@totem/utilities/endpoints';
import { isIBUser } from '@totem/utilities/security';
import { getUserRole, isUserDeactivated } from '@totem/utilities/userUtilities';

interface ImpersonationResponse {
  access_token: string;
}

export interface Props {
  user: User;
  onAction: (action: string, user: User) => void;
}

const UserActionMenu = ({ user, onAction }: Props) => {
  const { userProfile } = useContext(UserProfileContext);
  const isActiveUser = user.organization?.isActive;
  const isUserAdmin = getUserRole(userProfile) >= 2;
  const isSysAdmin = getUserRole(userProfile) >= 3;
  const isDeactivatedUser = isUserDeactivated(user);
  const isIB = isIBUser(userProfile);
  const isIbUser =
    userProfile.organization.id === '5b2f1be53911bd000175043a' ||
    userProfile.organization.id === '5b0cf2f679e1b20fd9bb4993';
  const getCookies = () => new Cookies();
  const getActionMenuItems = (): MenuProps['items'] => {
    if (isUserAdmin) {
      if (isActiveUser) {
        const menuItems: MenuProps['items'] = [
          {
            key: 'edit_user',
            label: 'Edit User',
            title: 'Edit User',
            icon: <EditOutlined />,
          },
          {
            key: 'security_groups',
            label: 'Security Groups',
            title: 'Security Groups',
            icon: <LockOutlined />,
          },
          {
            key: 'data_permissions',
            label: 'Data Permissions',
            title: 'Data Permissions',
            icon: <LockOutlined />,
          },
        ];

        if (isIbUser && isSysAdmin) {
          menuItems.push({
            key: 'remove_ib_user',
            label: 'Remove User',
            title: 'Remove User',
            icon: <DeleteOutlined />,
          });
        } else {
          menuItems.push({
            key: 'remove_user',
            label: 'Remove User',
            title: 'Remove User',
            icon: <DeleteOutlined />,
          });
        }
        if (isIB && isSysAdmin) {
          menuItems.push({
            key: 'impersonate',
            label: 'Impersonate',
            title: 'Impersonate',
            icon: <UserSwitchOutlined />,
          });
        }

        return menuItems;
      }

      const menuItems: MenuProps['items'] = [];
      if (isDeactivatedUser) {
        menuItems.push({
          key: 'view_details',
          label: 'View Details',
          title: 'View Details',
          icon: <ContactsOutlined />,
        });
      }

      if (isUserAdmin) {
        menuItems.push({
          key: 'grant_access',
          label: 'Grant Access',
          title: 'Grant Access',
          icon: <UserAddOutlined />,
        });
      }

      return menuItems;
    }

    return [];
  };

  const handleImpersonation = useCallback(async (impUser: User) => {
    const payload = {
      userId: impUser.id,
    };

    fetch(`${USERS_ENDPOINT}/impersonate/${impUser.id}`, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      }),
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((result: ImpersonationResponse) => {
        if (
          isNotNull(result) &&
          isNotNull(result.access_token) &&
          result.access_token !== ''
        ) {
          getCookies().set('access_token', result.access_token, { path: '/' });
          window.location.replace(window.location.origin);
          //navigate('/');
        }
      });
  }, []);

  const onMenuClicked = ({ key }) => {
    // eslint-disable-next-line default-case
    switch (key) {
      case 'edit_user':
        onAction('editUser', user);
        break;
      case 'security_groups':
        onAction('securityGroups', user);
        break;
      case 'data_permissions':
        onAction('dataPermissions', user);
        break;
      case 'remove_ib_user':
        onAction('removeIBUser', user);
        break;
      case 'remove_user':
        onAction('removeUser', user);
        break;
      case 'view_details':
        onAction('viewDetails', user);
        break;
      case 'grant_access':
        onAction('activateUser', user);
        break;
      case 'impersonate':
        handleImpersonation(user);
        break;
    }
  };

  return (
    <>
      <Dropdown
        menu={{ items: getActionMenuItems(), onClick: onMenuClicked }}
        trigger={['click']}
      >
        <Button shape="circle" icon={<EllipsisOutlined />} />
      </Dropdown>
    </>
  );
};

export default UserActionMenu;
