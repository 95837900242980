import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { EditOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { ColumnProps } from 'antd/lib/table';

import Table from '@totem/components/common/table/Table';
import Tooltip from '@totem/components/common/tooltip/Tooltip';
import UserProfileContext from '@totem/components/UserProfileContext';
import { Contract } from '@totem/types/contracts';
import { formatDate } from '@totem/utilities/common';
import {
  isMemberOfAny,
  securityGroupCheckConstraintEmpty,
} from '@totem/utilities/userUtilities';

import '../contracts.css';

const styles = {
  iconButton: {
    margin: '0 2px',
  },
};

type Props = {
  constractsData: Contract[];
  loading: boolean;
};

const ContractsTable = ({ constractsData, loading }: Props) => {
  const { userProfile } = useContext(UserProfileContext);
  const getContracts = () => {
    if (typeof constractsData !== 'undefined' && constractsData !== null) {
      return constractsData;
    }
    return [];
  };

  const userCanEditContracts = isMemberOfAny(
    userProfile,
    ['contract_creator', 'contract_viewer'],
    securityGroupCheckConstraintEmpty,
  );

  const columns: ColumnProps<Contract>[] = [
    {
      title: 'Number',
      dataIndex: 'number',
      key: 'number',
      render: (_, contract: Contract) => (
        <Link to={`/dashboard/contract/${contract.id}`}>{contract.number}</Link>
      ),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Start Date',
      dataIndex: 'contractStart',
      key: 'contractStart',
      render: (_, contract: Contract) => formatDate(contract.contractStart),
    },
    {
      title: 'End Date',
      dataIndex: 'contractEnd',
      key: 'contractEnd',
      render: (_, contract: Contract) => formatDate(contract.contractEnd),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Actions',
      width: 30,
      dataIndex: 'additionalOptions',
      key: 'additionalOptions',
      render: (_, contract: Contract) => (
        <div className="center-table-cell">
          {userCanEditContracts && (
            <Tooltip title="Edit Contract" placement="top">
              <Link to={`/dashboard/contract/${contract.id}?edit=true`}>
                <Button
                  icon={<EditOutlined />}
                  shape="circle"
                  style={styles.iconButton}
                />
              </Link>
            </Tooltip>
          )}
        </div>
      ),
    },
  ];

  return (
    <Table
      showSorterTooltip
      columns={columns}
      dataSource={getContracts()}
      loading={loading}
      rowKey={record => record.id}
    />
  );
};

export default ContractsTable;
