import { gql } from '@apollo/client';

export const BASE_USER_FRAGMENT = gql`
  fragment BaseUser on User {
    id
    authId
    email
    firstName
    lastName
    imageUrl
    phoneNumber
    isEmailVerified
    lastLoginDate
    status
    createdAt
    organization {
      id
      name
      type
      role
      isActive
      deactivatedAt
      deactivatedBy {
        email
        firstName
        lastName
      }
      deactivatedFor
      preferences {
        branding
        applicationLabels {
          region
        }
        features {
          healthRisk
          nmap
          questionnaire
          questionnaireBcc
          region
          policy
          policyAuditBcc
          buildingContact
          buildingNote
          remoteAccess
        }
      }
      securityGroups {
        id
        name
        description
        regionId
        buildingId
        controlSystemId
      }
      services {
        id
        serviceId
        name
        status
      }
    }
    organizations {
      id
      name
      type
      role
      isActive
      deactivatedAt
      deactivatedBy {
        email
        firstName
        lastName
      }
      deactivatedFor
      preferences {
        applicationLabels {
          region
        }
        features {
          healthRisk
          nmap
          questionnaire
          questionnaireBcc
          region
          policy
          policyAuditBcc
          buildingContact
          buildingNote
          remoteAccess
        }
      }
      securityGroups {
        id
        name
        description
        regionId
        buildingId
        controlSystemId
      }
      services {
        id
        serviceId
        name
        status
      }
    }
  }
`;

export const GET_USER = gql`
  query GetUser($id: ID!) {
    user(id: $id) {
      ...BaseUser
    }
  }
  ${BASE_USER_FRAGMENT}
`;

export const GET_USER_BY_EMAIL = gql`
  query GetUserByEmail($email: String!) {
    userByEmail(email: $email) {
      ...BaseUser
    }
  }
  ${BASE_USER_FRAGMENT}
`;

export const GET_USER_WITH_BUILDINGS = gql`
  query GetUser($id: ID!) {
    user(id: $id) {
      ...BaseUser
      createdBuildings {
        totalCount
      }
    }
  }
  ${BASE_USER_FRAGMENT}
`;

export const GET_USERS = gql`
  query GetUsers($input: UsersConnectionInput!) {
    users(input: $input) {
      totalCount
      users {
        ...BaseUser
      }
    }
  }
  ${BASE_USER_FRAGMENT}
`;

export const UPDATE_USER = gql`
  mutation UpdateUser($input: UserUpdateInput!) {
    userUpdate(input: $input) {
      ...BaseUser
    }
  }
  ${BASE_USER_FRAGMENT}
`;

export const CREATE_USER = gql`
  mutation CreateUser($input: UserCreateInput!) {
    userCreate(input: $input) {
      ...BaseUser
    }
  }
  ${BASE_USER_FRAGMENT}
`;

export const SEARCH_USERS = gql`
  query SearchUsers($input: UsersSearchInput!) {
    usersSearch(input: $input)
  }
`;

export const GET_USER_ASSIGNED_WORK = gql`
  query GetUserAssignedWork(
    $id: ID!
    $policyAuditsInput: PolicyAuditsConnectionInput!
    $questionnaireInstancesInput: QuestionnaireInstancesConnectionInput!
  ) {
    user(id: $id) {
      id
      email
      firstName
      lastName
      assignedPolicyAudits(input: $policyAuditsInput) {
        totalCount
        policyAudits {
          id
          building {
            name
          }
          controlSystem {
            name
          }
        }
      }
      assignedQuestionnaireInstances(input: $questionnaireInstancesInput) {
        totalCount
        questionnaireInstances {
          id
          name
          building {
            name
          }
        }
      }
    }
  }
`;

export const DEACTIVATE_USER = gql`
  mutation DeactivateUser($input: UserDeactivateInput!) {
    userDeactivate(input: $input) {
      id
      organizations {
        isActive
        deactivatedFor
        deactivatedAt
        deactivatedBy {
          id
          email
          firstName
          lastName
        }
      }
    }
  }
`;

export const ACTIVATE_USER = gql`
  mutation UserActivated($id: ID!) {
    userActivate(id: $id) {
      id
      organizations {
        isActive
        deactivatedFor
        deactivatedAt
        deactivatedBy {
          id
          email
          firstName
          lastName
        }
      }
    }
  }
`;

export const INVITE_USERS = gql`
  mutation InviteUsers($input: UsersInviteInput!) {
    usersInvite(input: $input)
  }
`;
