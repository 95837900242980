import React, { useContext } from 'react';
import { ColumnProps } from 'antd/lib/table';

import Table from '@totem/components/common/table/Table';
import ServiceContext from '@totem/components/devices/services/serviceContext';
import { DeviceService } from '@totem/components/devices/services/types';

const ServicesTable = () => {
  const { data, loading } = useContext(ServiceContext);

  const getData = () => {
    if (typeof data !== 'undefined' && data !== null) {
      return data;
    }
    return [];
  };

  const columns: ColumnProps<DeviceService>[] = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      defaultSortOrder: 'ascend',
      sorter: (compA, compB) =>
        compA.name === compB.name ? 0 : compA.name > compB.name ? 1 : -1,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      sorter: (compA, compB) =>
        compA.description === compB.description
          ? 0
          : compA.description > compB.description
          ? 1
          : -1,
    },
    {
      title: 'State',
      dataIndex: 'state',
      key: 'state',
      sorter: (compA, compB) =>
        compA.state === compB.state ? 0 : compA.state > compB.state ? 1 : -1,
    },
    {
      title: 'Startup',
      dataIndex: 'startup',
      key: 'startup',
      sorter: (compA, compB) =>
        compA.startup === compB.startup
          ? 0
          : compA.startup > compB.startup
          ? 1
          : -1,
    },
    {
      title: 'Service Type',
      dataIndex: 'serviceType',
      key: 'serviceType',
      sorter: (compA, compB) =>
        compA.serviceType === compB.serviceType
          ? 0
          : compA.serviceType > compB.serviceType
          ? 1
          : -1,
    },
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
      sorter: (compA, compB) =>
        compA.username === compB.username
          ? 0
          : compA.username > compB.username
          ? 1
          : -1,
    },
  ];

  return (
    <Table
      showSorterTooltip
      columns={columns}
      dataSource={getData()}
      loading={loading}
      rowKey={(record) => record.name}
    />
  );
};

export default ServicesTable;
