import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';

import logo from '../../images/logo.ib.svg';

import './registration.css';

const styles = {
  button: {
    width: '100%',
  },
};

const EmailVerified = () => {
  const navigate = useNavigate();
  return (
    <div styleName="root-container">
      <div className="middle-box text-center loginscreen animate__animated animate__fadeInDown">
        <div>
          <div styleName="login-header">
            <img src={logo} styleName="logo" />
          </div>
          <div>
            <h3 styleName="heading" style={{ paddingTop: '3em' }}>
              Thank You!
            </h3>
            <p
              style={{
                paddingTop: '3em',
                paddingBottom: '3em',
                textAlign: 'center',
              }}
            >
              Your email account has been verified! Please proceed to the
              application.
            </p>
            <Button
              size="large"
              type="primary"
              style={styles.button}
              onClick={() => navigate('/login')}
            >
              Back To Login
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailVerified;
