import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'react-simple-snackbar';

import { dismissNotification } from '@totem/actions/notificationActions';
import colors from '@totem/styles/colors';
import { NotificationState, ReduxStore } from '@totem/types/store';

const AUTOHIDE_DURATION = 3000;

const styles = {
  error: {
    background: colors.brand.red,
    color: colors.neutral.white,
  },
};

const Notification = () => {
  const dispatch = useDispatch();

  const { message, isError } = useSelector<ReduxStore, NotificationState>(
    (state) => state.notification,
  );

  const [openSnackbar] = useSnackbar();
  const [openErrorSnackbar] = useSnackbar({ style: styles.error });

  useEffect(() => {
    if (message) {
      if (isError) {
        openErrorSnackbar(message, AUTOHIDE_DURATION);
      } else {
        openSnackbar(message, AUTOHIDE_DURATION);
      }

      dispatch(dismissNotification());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);

  // <SnackbarProvider /> handles actual JSX rendering
  return null;
};

export default Notification;
