const ILLEGAL_FILENAME_CHARACTERS = [
  '<!--',
  '-->',
  '<',
  '>',
  "'",
  '"',
  '&',
  '$',
  '#',
  '{',
  '}',
  '[',
  ']',
  '=',
  ';',
  '?',
];

export const appendFormData = (
  formData: FormData,
  property: string,
  value: any,
) => {
  if (value) {
    formData.append(property, value);
  }
};

const escapeRegexp = (str: string): string =>
  // eslint-disable-next-line
  str.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');

export const sanitizeFilename = (name: string): string => {
  const exp = `[${escapeRegexp(ILLEGAL_FILENAME_CHARACTERS.join(''))}]`;
  return name.replace(new RegExp(exp, 'g'), '');
};

export const sanitizeFile = (file: File): File =>
  new File([file], sanitizeFilename(file.name), { type: file.type });
