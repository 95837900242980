import React, { useCallback, useEffect, useState } from 'react';
import { Button, Form, Input, Select, Tabs, TabsProps } from 'antd';
import Modal from 'antd/lib/modal/Modal';

import ModalFooter from '@totem/components/ModalFooter';
import ModalTitle from '@totem/components/ModalTitle';
import {
  AirwallGroup,
  AirwallTag,
  AuthTenant,
  AuthTenantConfig,
  ConductorConfig,
  RemoteAccessConfigurationRequest,
  RemoteAccessRole,
} from '@totem/components/settings/sections/services/provisioning/remote_access/types';
import TabTitle from '@totem/components/TabTitle';
import colors from '@totem/styles/colors';
import { Service } from '@totem/types/organization';
import { RemoteAccessOrgRoles } from '@totem/types/remoteAccess';
import { getToken } from '@totem/utilities/accountUtilities';
import {
  REMOTE_ACCESS_AUTH_TENANTS,
  REMOTE_ACCESS_CONDUCTOR_AIRWALL_GROUPS,
  REMOTE_ACCESS_CONDUCTOR_AIRWALL_TAGS,
  REMOTE_ACCESS_CONDUCTOR_VALIDATION,
  REMOTE_ACCESS_PROVISIONING_CONFIGURATION,
  REMOTE_ACCESS_ROLES_UNASSIGNED,
} from '@totem/utilities/endpoints';

const FormItem = Form.Item;
const { Option } = Select;

const styles = {
  button: {
    background: colors.utility.error,
    color: colors.neutral.white,
  },
  errorIcon: {
    color: colors.utility.error,
    marginRight: '1rem',
    fontSize: '22px',
  },
  form: {
    width: '100%',
  },
  formItem: {
    paddingBottom: '0',
    marginBottom: '1rem',
  },
};

export interface Props {
  visible: boolean;
  onClose: (boolean) => void;
  service: Service;
}

const RemoteAccessProvisioningModal = ({
  visible,
  onClose,
  service,
}: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [authTenants, setAuthTenants] = useState<AuthTenant[]>([]);
  const [selectedAuthTenantId, setSelectedAuthTenantId] = useState<string>('');
  const [selectedRoleId, setSelectedRoleId] = useState<string>('');
  const [activeTab, setActiveTab] = useState<string>('1');
  const [isConductorValidating, setIsConductorValidating] =
    useState<boolean>(false);
  const [isConductorValidated, setIsConductorValidated] =
    useState<boolean>(false);
  const [conductorConfig, setConductorConfig] = useState<ConductorConfig>({
    apiHostName: '',
    apiClientId: '',
    apiToken: '',
    profileName: '',
    hostName: '',
    airwallGroupIds: [],
    tags: [],
  });
  const [airwallGroups, setAirwallGroups] = useState<AirwallGroup[]>([]);
  const [airwallTags, setAirwallTags] = useState<AirwallTag[]>([]);
  const [unassignedRoles, setUnassignedRoles] = useState<RemoteAccessRole[]>(
    [],
  );
  const [assignedRole, setAssignedRole] = useState<RemoteAccessRole>(null);

  const defaultAirwallGroup: string =
    conductorConfig.airwallGroupIds.length > 0
      ? conductorConfig.airwallGroupIds[0]
      : '';

  const defaultAirwallTags: string =
    conductorConfig.tags.length > 0 ? conductorConfig.tags[0] : '';

  const canValidateConductor: boolean =
    conductorConfig.apiHostName !== '' &&
    conductorConfig.apiClientId !== '' &&
    conductorConfig.apiToken !== '';

  const canSubmitForm: boolean =
    isConductorValidated &&
    canValidateConductor &&
    conductorConfig.profileName !== '' &&
    conductorConfig.hostName !== '' &&
    conductorConfig.airwallGroupIds.length > 0 &&
    conductorConfig.tags.length > 0 &&
    selectedAuthTenantId !== '';

  useEffect(() => {
    fetch(REMOTE_ACCESS_PROVISIONING_CONFIGURATION, {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('HTTP Failed');
        }
        return response.json();
      })
      .then((result: RemoteAccessOrgRoles) => {
        if (
          typeof result.conductor !== 'undefined' &&
          result.conductor !== null &&
          result.conductor.apiClientId !== '' &&
          result.conductor.apiToken !== '' &&
          result.conductor.apiHostName !== ''
        ) {
          if (result.conductor.airwallGroupIds === null) {
            result.conductor.airwallGroupIds = [];
          }
          if (result.conductor.tags === null) {
            result.conductor.tags = [];
          }
          setConductorConfig(result.conductor);
          setIsConductorValidated(true);
          setActiveTab('2');
        }
        if (
          typeof result.authTenant !== 'undefined' &&
          result.authTenant !== null &&
          result.authTenant.orgRoleId !== '' &&
          result.authTenant.orgRole !== '' &&
          result.authTenant.tenantId !== ''
        ) {
          setSelectedAuthTenantId(result.authTenant.tenantId);
          setAssignedRole({
            roleId: result.authTenant.orgRoleId,
            name: result.authTenant.orgRole,
            description: result.authTenant.orgRole,
            authConfigId: result.authTenant.tenantId,
          });
          setSelectedRoleId(result.authTenant.orgRoleId);
        }
      })
      .then(() => setIsLoading(false));
  }, []);

  const saveRemoteAccessConfiguration = useCallback(
    async (request: RemoteAccessConfigurationRequest) => {
      if (isSaving) {
        return;
      }

      setIsSaving(true);

      fetch(REMOTE_ACCESS_PROVISIONING_CONFIGURATION, {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getToken()}`,
        }),
        body: JSON.stringify(request),
      })
        .then((response) => {
          if (response.status === 200) {
            onClose(true);
          }
        })
        .then(() => {
          setIsSaving(false);
        });
    },
    [],
  );

  const handleSubmit = () => {
    let foundRole = unassignedRoles.find(
      (role) => role.roleId === selectedRoleId,
    );
    if (typeof foundRole === 'undefined') {
      if (assignedRole.roleId === selectedRoleId) {
        foundRole = assignedRole;
      }
    }

    if (typeof foundRole !== 'undefined') {
      const authTenant: AuthTenantConfig = {
        tenantId: selectedAuthTenantId,
        orgRoleId: foundRole.roleId,
        orgRole: foundRole.name,
      };

      const request: RemoteAccessConfigurationRequest = {
        serviceId: service.serviceId,
        conductor: conductorConfig,
        authTenant,
      };
      saveRemoteAccessConfiguration(request);
    }
  };

  const handleAuthTenantChanged = (authTenantId: string) => {
    setSelectedAuthTenantId(authTenantId);
  };

  const setConductorApiValue = (name: string, value: string) => {
    setConductorConfig({ ...conductorConfig, [name]: value });

    if (isConductorValidated) {
      setIsConductorValidated(false);
    }
  };

  const setConductorValue = (name: string, value: string) => {
    setConductorConfig({ ...conductorConfig, [name]: value });
  };

  const setConductorArrayValue = (name: string, value: string) => {
    const arrayValues = [value];
    setConductorConfig({ ...conductorConfig, [name]: arrayValues });
  };

  useEffect(() => {
    if (!isConductorValidated) {
      setAirwallGroups([]);
    } else {
      fetch(REMOTE_ACCESS_CONDUCTOR_AIRWALL_GROUPS, {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getToken()}`,
        }),
        body: JSON.stringify(conductorConfig),
      })
        .then((res) => res.json())
        .then((result) => {
          setAirwallGroups(result);
        });
    }
  }, [isConductorValidated]);

  useEffect(() => {
    if (!isConductorValidated) {
      setAirwallTags([]);
    } else {
      fetch(REMOTE_ACCESS_CONDUCTOR_AIRWALL_TAGS, {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getToken()}`,
        }),
        body: JSON.stringify(conductorConfig),
      })
        .then((res) => res.json())
        .then((result) => {
          setAirwallTags(result);
        });
    }
  }, [isConductorValidated]);

  useEffect(() => {
    if (isConductorValidated && authTenants.length === 0) {
      fetch(REMOTE_ACCESS_AUTH_TENANTS, {
        method: 'GET',
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getToken()}`,
        }),
      })
        .then((res) => res.json())
        .then((result) => {
          setAuthTenants(result);
        });
    }
  }, [isConductorValidated]);

  useEffect(() => {
    if (
      selectedAuthTenantId !== '' &&
      authTenants.length > 0 &&
      unassignedRoles.length === 0
    ) {
      fetch(`${REMOTE_ACCESS_ROLES_UNASSIGNED}/${selectedAuthTenantId}`, {
        method: 'GET',
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getToken()}`,
        }),
      })
        .then((res) => res.json())
        .then((result: RemoteAccessRole[]) => {
          setUnassignedRoles(result);
        });
    }
  }, [selectedAuthTenantId, authTenants]);

  const validateConductorApi = useCallback(async (config: ConductorConfig) => {
    if (isConductorValidating) {
      return;
    }

    setIsConductorValidating(true);

    fetch(REMOTE_ACCESS_CONDUCTOR_VALIDATION, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      }),
      body: JSON.stringify(config),
    })
      .then((response) => {
        if (response.status === 200) {
          setIsConductorValidated(true);
          setActiveTab('2');
        } else {
          setIsConductorValidated(false);
        }
      })
      .then(() => {
        setIsConductorValidating(false);
      });
  }, []);

  const tabItems: TabsProps['items'] = [
    {
      key: '1',
      label: <TabTitle>Conductor API</TabTitle>,
      children: (
        <>
          <FormItem label="API Host Name" colon={false} style={styles.formItem}>
            <Input
              name="conductor_api_host_name"
              value={conductorConfig.apiHostName}
              onChange={(event: any) =>
                setConductorApiValue('apiHostName', event.target.value)
              }
              disabled={isConductorValidated}
              type="text"
              placeholder="Ex: <client>.tempered.network"
            />
          </FormItem>
          <FormItem label="API Client Id" colon={false} style={styles.formItem}>
            <Input
              name="conductor_api_client_id"
              value={conductorConfig.apiClientId}
              onChange={(event: any) =>
                setConductorApiValue('apiClientId', event.target.value)
              }
              disabled={isConductorValidated}
              type="text"
            />
          </FormItem>
          <FormItem label="API Token" colon={false} style={styles.formItem}>
            <Input
              name="conductor_api_token"
              value={conductorConfig.apiToken}
              onChange={(event: any) =>
                setConductorApiValue('apiToken', event.target.value)
              }
              disabled={isConductorValidated}
              type="text"
            />
          </FormItem>
          {!isConductorValidated && (
            <Button
              type="primary"
              disabled={!canValidateConductor}
              onClick={() => validateConductorApi(conductorConfig)}
            >
              Validate Conductor API
            </Button>
          )}
        </>
      ),
    },
    {
      key: '2',
      label: <TabTitle>Conductor Invite</TabTitle>,
      disabled: !isConductorValidated,
      children: (
        <>
          <FormItem label="Profile Name" colon={false} style={styles.formItem}>
            <Input
              name="conductor_profile_name"
              value={conductorConfig.profileName}
              onChange={(event: any) => {
                setConductorValue('profileName', event.target.value);
              }}
              onBlur={() => {
                if (conductorConfig.hostName === '') {
                  setConductorValue(
                    'hostName',
                    `${conductorConfig.profileName}.ram.ib-services.net`,
                  );
                }
              }}
              disabled={false}
              type="text"
            />
          </FormItem>
          <FormItem label="Hostname" colon={false} style={styles.formItem}>
            <Input
              name="conductor_hostname"
              value={conductorConfig.hostName}
              onChange={(event: any) =>
                setConductorValue('hostName', event.target.value)
              }
              disabled={false}
              type="text"
            />
          </FormItem>
          <FormItem label="Airwall Group" colon={false} style={styles.formItem}>
            <Select
              disabled={!isConductorValidated}
              defaultValue={defaultAirwallGroup}
              onChange={(group: string) =>
                setConductorArrayValue('airwallGroupIds', group)
              }
              style={{ width: '100%' }}
            >
              {airwallGroups !== null &&
                airwallGroups.map((ag) => (
                  <Option key={ag.id} value={ag.id}>
                    {ag.name}
                  </Option>
                ))}
            </Select>
          </FormItem>
          <FormItem label="Airwall Tag" colon={false} style={styles.formItem}>
            <Select
              disabled={!isConductorValidated}
              defaultValue={defaultAirwallTags}
              onChange={(tag: string) => setConductorArrayValue('tags', tag)}
              style={{ width: '100%' }}
            >
              {airwallTags !== null &&
                airwallTags.map((ag) => (
                  <Option key={ag.name} value={ag.name}>
                    {ag.name}
                  </Option>
                ))}
            </Select>
          </FormItem>
        </>
      ),
    },
    {
      key: '3',
      label: <TabTitle>Auth Tenant</TabTitle>,
      disabled: !isConductorValidated,
      children: (
        <FormItem label="Auth Tenant" colon={false} style={styles.formItem}>
          <Select
            onChange={handleAuthTenantChanged}
            defaultValue={selectedAuthTenantId}
            style={{ width: '100%' }}
          >
            {authTenants !== null &&
              authTenants.map((tenant) => (
                <Option key={tenant.id} value={tenant.id}>
                  {tenant.provider}: {tenant.name}
                </Option>
              ))}
          </Select>
        </FormItem>
      ),
    },
    {
      key: '4',
      label: <TabTitle>Org Role</TabTitle>,
      disabled: !isConductorValidated || selectedAuthTenantId === '',
      children: (
        <FormItem
          label="Organization Role"
          colon={false}
          style={styles.formItem}
        >
          <Select
            onChange={(roleId: string) => setSelectedRoleId(roleId)}
            defaultValue={selectedRoleId}
            style={{ width: '100%' }}
          >
            {assignedRole !== null && (
              <Option key={assignedRole.roleId} value={assignedRole.roleId}>
                {assignedRole.name}
              </Option>
            )}
            {unassignedRoles !== null &&
              unassignedRoles.map((role) => (
                <Option key={role.roleId} value={role.roleId}>
                  {role.name}
                </Option>
              ))}
          </Select>
        </FormItem>
      ),
    },
  ];

  return (
    <Modal
      title={<ModalTitle>Remote Access Service Configuration</ModalTitle>}
      onCancel={onClose}
      open={visible}
      footer={
        <ModalFooter>
          <Button onClick={() => onClose(false)}>Cancel</Button>
          <Button
            onClick={handleSubmit}
            loading={isLoading}
            disabled={!canSubmitForm}
            type="primary"
          >
            Add
          </Button>
        </ModalFooter>
      }
    >
      <Form layout="vertical" style={styles.form}>
        <Tabs
          activeKey={activeTab}
          onTabClick={(key) => setActiveTab(key)}
          items={tabItems}
        />
      </Form>
    </Modal>
  );
};

export default RemoteAccessProvisioningModal;
