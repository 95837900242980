import { createContext } from 'react';

import {
  Agreement,
  AgreementReportInput, Hierarchy,
} from '@totem/components/agreements/models';

interface Context {
  input: AgreementReportInput;
  setInput: (input: AgreementReportInput) => void;
  loading: boolean;
  reportData: Agreement[];
  totalRecords: number;
  onAction: (
    action: string,
    agreement: Agreement,
    hierarchy: Hierarchy,
  ) => void;
}

export default createContext<Context>({
  loading: false,
  reportData: [],
  totalRecords: 0,
  input: {},
  setInput: () => {},
  onAction: () => {},
});
