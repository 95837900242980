import React, { useContext } from 'react';
import { Card } from 'antd';

import Count from '@totem/components/common/Count';
import FindingsReportContext from '@totem/components/surveyV2/findingsReport/findingsReportContext';
import SubTitle from '@totem/components/surveyV2/SubTitle';
import colors from '@totem/styles/colors';
import { isNotNull } from '@totem/utilities/common';

import './findingsReport.css';

const styles = {
  nonCompliantCount: {
    border: `2px solid ${colors.criticality.high}`,
    background: colors.neutral.white,
    minWidth: '7rem',
    marginBottom: '3rem',
  },
  compliantCount: {
    background: colors.neutral.ash,
    minWidth: '7rem',
    marginBottom: '3rem',
  },
  noBottomBorder: {
    borderBottomStyle: 'none' as 'none',
  },
  subTitle: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
};
const PolicyResults = () => {
  const { data } = useContext(FindingsReportContext);

  const totalCompliant = isNotNull(data)
    ? data.complianceOverview.compliant
    : 0;
  const totalNonCompliant = isNotNull(data)
    ? data.complianceOverview.nonCompliant
    : 0;

  return (
    <Card
      title={<SubTitle>Policy Results</SubTitle>}
      style={styles.noBottomBorder}
    >
      <div styleName="policy-results-container">
        <div styleName="graph-title">Non-Compliant Responses</div>
        <Count
          count={totalNonCompliant}
          style={styles.nonCompliantCount}
          size="large"
        />
        <div styleName="graph-title">Compliant Responses</div>
        <Count
          count={totalCompliant}
          style={styles.compliantCount}
          size="large"
        />
      </div>
    </Card>
  );
};

export default PolicyResults;
