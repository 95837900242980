import React, { useContext } from 'react';

import FilterChip from '@totem/components/common/FilterChip';
import AnalysisContext from '@totem/components/surveyV2/analysis/AnalysisContext';
import { AnalysisInput } from '@totem/components/surveyV2/analysis/types';
import { LevelOfEffortToString } from '@totem/components/surveyV2/utilities/SurveyEnumerations';

const styles = {
  filterChip: {
    marginLeft: '2rem',
    marginTop: '2rem',
  },
};

const AnalysisFilterChips = () => {
  const { input, setInput } = useContext(AnalysisContext);

  const handleMultiFilterDelete = (
    property: keyof AnalysisInput,
    value: string,
  ) => {
    // @ts-ignore
    const updatedValue = input[property].filter((val) => val !== value);
    setInput({
      ...input,
      [property]: updatedValue.length ? updatedValue : null,
    });
  };

  const handleNumericMultiFilterDelete = (
    property: keyof AnalysisInput,
    value: number,
  ) => {
    // @ts-ignore
    const updatedValue = input[property].filter((val) => val !== value);
    setInput({
      ...input,
      [property]: updatedValue.length ? updatedValue : null,
    });
  };

  return (
    <>
      {input.policyName &&
        input.policyName.map((option) => {
          return (
            <FilterChip
              key={option}
              label={`Policy Name: ${option}`}
              style={styles.filterChip}
              onClose={() => handleMultiFilterDelete('policyName', option)}
            />
          );
        })}
      {input.category &&
        input.category.map((option) => {
          return (
            <FilterChip
              key={option}
              label={`Category: ${option}`}
              style={styles.filterChip}
              onClose={() => handleMultiFilterDelete('category', option)}
            />
          );
        })}
      {input.nist &&
        input.nist.map((option) => {
          return (
            <FilterChip
              key={option}
              label={`NIST: ${option}`}
              style={styles.filterChip}
              onClose={() => handleMultiFilterDelete('nist', option)}
            />
          );
        })}
      {input.levelOfEffort &&
        input.levelOfEffort.map((option) => {
          return (
            <FilterChip
              key={option}
              label={`Level of Effort: ${LevelOfEffortToString(option)}`}
              style={styles.filterChip}
              onClose={() =>
                handleNumericMultiFilterDelete('levelOfEffort', option)
              }
            />
          );
        })}
    </>
  );
};

export default AnalysisFilterChips;
