import React, { useCallback, useContext, useEffect, useState } from 'react';
import { notification } from 'antd';

import { RemoteAccessRole } from '@totem/components/remoteAccess/types';
import RemoteAccessUserDetailsContext from '@totem/components/remoteAccess/userDetails/RemoteAccessUserDetailsContext';
import RemoteAccessUserRoleAddModal from '@totem/components/remoteAccessUserRoles/RemoteAccessUserRolesAddModal';
import UserProfileContext from '@totem/components/UserProfileContext';
import { RemoteAccessAuthTenantRole } from '@totem/types/remoteAccess';
import { getToken } from '@totem/utilities/accountUtilities';
import {
  REMOTE_ACCESS_ROLES_ENDPOINT,
  REMOTE_ACCESS_USERS_ENDPOINT,
} from '@totem/utilities/endpoints';
import {
  isMemberOf,
  securityGroupCheckConstraintEmpty,
} from '@totem/utilities/userUtilities';

export interface Props {
  action: string;
  selectedRole: RemoteAccessRole;
}

const UserDetailsActionHandler = ({ action, selectedRole }: Props) => {
  const { userProfile } = useContext(UserProfileContext);
  const { userEmail, onActionComplete } = useContext(
    RemoteAccessUserDetailsContext,
  );
  const [isSending, setIsSending] = useState(false);
  const remoteAccessAdmin = isMemberOf(
    userProfile,
    'remote_access_admin',
    securityGroupCheckConstraintEmpty,
  );

  const sendRemoveUserFromRole = useCallback(
    async (email: string, roleToRemove: RemoteAccessRole) => {
      if (isSending) {
        return;
      }

      setIsSending(true);

      const payload = {
        email,
        givenName: '',
        familyName: '',
        roleId: roleToRemove.id,
        roleName: roleToRemove.name,
      };

      fetch(`${REMOTE_ACCESS_ROLES_ENDPOINT}/${payload.roleId}/${email}`, {
        method: 'DELETE',
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getToken()}`,
        }),
        body: JSON.stringify(payload),
      })
        .then(res => {
          if (res.status < 400) {
            onActionComplete(true);
          } else {
            notification.error({
              message: 'Error removing user from role!',
              description: 'An error occurred while removing user from role.',
              duration: 0,
            });
            onActionComplete(false);
          }
        })
        .then(() => {
          setIsSending(false);
        });
    },
    [isSending],
  );

  const sendAddRoleToUser = useCallback(
    async (role: RemoteAccessRole, email: string) => {
      if (isSending) {
        return;
      }

      setIsSending(true);

      const payload = {
        email,
        givenName: '',
        familyName: '',
        roleId: role.id,
        roleName: role.name,
      };

      fetch(`${REMOTE_ACCESS_USERS_ENDPOINT}`, {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getToken()}`,
        }),
        body: JSON.stringify(payload),
      })
        .then(res => {
          if (res.status < 400) {
            onActionComplete(true);
          } else {
            notification.error({
              message: 'Error adding role to user!',
              description: 'An error occurred while adding role to user.',
              duration: 0,
            });
            onActionComplete(false);
          }
        })
        .then(() => {
          setIsSending(false);
        });
    },
    [isSending],
  );

  const handleAddRole = (role: RemoteAccessAuthTenantRole) => {
    const newRole: RemoteAccessRole = {
      id: role.roleId,
      name: role.name,
      description: role.description,
    };
    sendAddRoleToUser(newRole, userEmail).then(() => onActionComplete(true));
  };

  useEffect(() => {
    if (typeof action !== 'undefined' && action !== null && action !== '') {
      // eslint-disable-next-line default-case
      switch (action) {
        case 'removeUser':
          if (selectedRole !== null && userEmail !== '') {
            sendRemoveUserFromRole(userEmail, selectedRole).then(() =>
              onActionComplete(true),
            );
          }
          break;
      }
    }
  }, [action, userEmail, selectedRole, onActionComplete]);

  return (
    <>
      {action === 'addRoleToUser' && userEmail !== '' && remoteAccessAdmin && (
        <RemoteAccessUserRoleAddModal
          loading={false}
          visible={action === 'addRoleToUser'}
          onClose={() => onActionComplete(false)}
          onSubmit={handleAddRole}
        />
      )}
    </>
  );
};

export default UserDetailsActionHandler;
