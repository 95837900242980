import React from 'react';

import { Attribute } from '@totem/types/common';

type Props = {
  attribute: Attribute;
};

const DisplayAttribute = ({ attribute }: Props) => {
  return (
    <div>
      <b>{attribute.label}</b>: {attribute.value}
    </div>
  );
};

export default DisplayAttribute;
