import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ArrowRightOutlined } from '@ant-design/icons';
import { Alert, Button, Progress, Switch, Tabs } from 'antd';
import { TabsProps } from 'antd/lib/tabs';
import * as R from 'ramda';

import * as mediaActionCreators from '@totem/actions/mediaActions';
import * as policyAuditActionCreators from '@totem/actions/policyAuditActions';
import DetailSpinner from '@totem/components/common/DetailSpinner';
import PrimaryContentPane from '@totem/components/PrimaryContentPane';
import TabTitle from '@totem/components/TabTitle';
import UserProfileContext from '@totem/components/UserProfileContext';
import { completionPercentageSelector } from '@totem/selectors/policyAuditSelectors';
import {
  PolicyAuditErrors,
  QuestionGroup,
  QuestionKey,
  Questions,
} from '@totem/types/policyAudit';
import { ReduxStore } from '@totem/types/store';
import { isVendorUser } from '@totem/utilities/authUtilities';
import { POLICY_AUDIT_SUBJECT_TYPE } from '@totem/utilities/mediaUtilities';
import {
  getGroupName,
  getPatchFromQuestionsUpdate,
  groupHasError,
  isQuestionAnswered,
  updateQuestionsWithAnswer,
  validatePolicyAudit,
} from '@totem/utilities/policyAuditUtilities';

import ContentLayout from '../ContentLayout';

import Question from './Question';
import SubmitButton from './SubmitButton';

import './policyAuditDetail.css';

interface State {
  showUnansweredOnly: boolean;
  errors: PolicyAuditErrors;
  alertVisible: boolean;
  selectedGroup: string;
}

const styles = {
  progress: {
    width: '32rem',
  },
  alert: {
    margin: '1rem 0',
  },
};

const PolicyAuditDetail = () => {
  const { userProfile } = useContext(UserProfileContext);
  const dispatch = useDispatch<any>();
  const { id } = useParams();
  const policyAudit = useSelector(
    (state: ReduxStore) => state.policyAuditDetail,
  );
  const completionPercentage = useSelector((state: ReduxStore) =>
    completionPercentageSelector(state),
  );
  const loading = useSelector(
    (state: ReduxStore) => state.loading.loadingPolicyAuditDetail,
  );
  const [state, setState] = useState<State>({
    showUnansweredOnly: false,
    errors: {},
    alertVisible: false,
    selectedGroup: 'group1',
  });

  const paneRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    dispatch(policyAuditActionCreators.requestPolicyAudit({ id }));

    dispatch(
      mediaActionCreators.requestMedia({
        policyAuditId: id,
        subjectType: POLICY_AUDIT_SUBJECT_TYPE,
      }),
    );
  }, []);

  useEffect(() => {
    if (!R.isEmpty(state.errors)) {
      setState((prevState) => ({
        ...prevState,
        errors: validatePolicyAudit(policyAudit),
      }));
    }
  }, [policyAudit]);

  useEffect(() => {
    paneRef.current.scrollIntoView({ behavior: 'auto' });
  }, [state.selectedGroup]);

  useEffect(() => {
    if (!R.isEmpty(state.errors)) {
      paneRef.current.scrollIntoView({ behavior: 'auto' });
    }
  }, [state.errors]);
  // pane: HTMLDivElement;

  const toggleShowUnanswered = () => {
    const { showUnansweredOnly } = state;
    setState((prevState) => ({
      ...prevState,
      showUnansweredOnly: !showUnansweredOnly,
    }));
  };

  const groupHasVisibleQuestions = (
    group: QuestionGroup,
    questions: Questions,
  ): boolean => {
    return group.questions.some(
      (questionKey) => questions[questionKey]?.visible,
    );
  };

  const hasNextGroup = (): boolean => {
    const { selectedGroup } = state;
    const { questionGroups, questions } = policyAudit;

    const groups = Object.values(questionGroups);

    const nextGroup = R.path<QuestionGroup>(
      [groups.findIndex(({ key }) => key === selectedGroup) + 1],
      groups,
    );

    return nextGroup && groupHasVisibleQuestions(nextGroup, questions);
  };

  const handleNextGroupClick = () => {
    const { selectedGroup } = state;
    const keys = Object.keys(policyAudit.questionGroups);
    const selectedIndex = keys.findIndex((key) => key === selectedGroup);

    setState((prevState) => ({
      ...prevState,
      selectedGroup: keys[selectedIndex + 1],
    }));
  };

  const handleChange = (key: QuestionKey, value: any) => {
    const { questions } = policyAudit;

    const updatedQuestions = updateQuestionsWithAnswer(
      questions,
      key,
      value,
      userProfile,
    );

    const questionsPatch = getPatchFromQuestionsUpdate(
      questions,
      updatedQuestions,
    );

    dispatch(
      policyAuditActionCreators.updatePolicyAuditQuestions({
        questions: updatedQuestions,
      }),
    );

    if (!R.isEmpty(questionsPatch)) {
      dispatch(
        policyAuditActionCreators.requestPolicyAuditPatch({
          id,
          questions: questionsPatch,
        }),
      );
    }
  };

  const handleMoreInfoChange = (key: string, value: string) => {
    const { id: policyAuditId } = policyAudit;

    dispatch(
      policyAuditActionCreators.updatePolicyAuditMoreInfo({ key, value }),
    );

    dispatch(
      policyAuditActionCreators.requestPolicyAuditPatch({
        id: policyAuditId,
        questions: { [key]: { moreInfo: { value } } },
      }),
    );
  };

  const handleErrors = (errors: PolicyAuditErrors) => {
    setState((prevState) => ({ ...prevState, errors, alertVisible: true }));
  };

  const handleCloseAlert = () => {
    setState((prevState) => ({ ...prevState, alertVisible: false }));
  };

  const renderTabBar = (props: TabsProps, DefaultTabBar: any) => {
    return (
      <div styleName="tabs-container">
        <DefaultTabBar {...props} />
      </div>
    );
  };

  const renderTab = (group: QuestionGroup) => {
    const { questions } = policyAudit;

    return (
      <div styleName={groupHasError(group, state.errors) ? 'group-error' : ''}>
        {getGroupName(group, questions)}
      </div>
    );
  };

  const renderQuestions = (keys: QuestionKey[]) => {
    const { questions, submitted } = policyAudit;

    return keys
      .filter((key) => {
        const question = questions[key];
        return (
          (!state.showUnansweredOnly || !isQuestionAnswered(question)) &&
          question.visible
        );
      })
      .map((key) => {
        const error = state.errors[key];

        return (
          <div
            key={key}
            styleName={`question-container ${error ? 'error' : ''}`}
          >
            <Question
              question={questions[key]}
              onChange={handleChange}
              onMoreInfoChange={handleMoreInfoChange}
              error={error}
              disabled={submitted}
            />
          </div>
        );
      });
  };

  const getTabItems = () => {
    const { questionGroups, questions } = policyAudit;

    const tabItems: TabsProps['items'] = [];

    for (let idx = 0; idx < Object.keys(questionGroups).length; idx++) {
      const group = questionGroups[Object.keys(questionGroups)[idx]];
      if (group && groupHasVisibleQuestions(group, questions)) {
        tabItems.push({
          key: group.key,
          label: <TabTitle>{renderTab(group)}</TabTitle>,
          children: <>{renderQuestions(group.questions)}</>,
        });
      }
    }
    return tabItems;
  };

  return (
    <>
      {loading && (
        <div styleName="spinner-container">
          <DetailSpinner />
        </div>
      )}
      {!loading && (
        <ContentLayout
          pageTitle={policyAudit.controlSystem?.name || 'Audit Detail'}
          pageSubtitle={[
            {
              label: policyAudit.organization.name,
            },
            {
              label: policyAudit.region.name,
            },
            {
              label: policyAudit.building.name,
              link: isVendorUser()
                ? ''
                : `/dashboard/buildings/${policyAudit.building.id}`,
            },
          ]}
          breadcrumbs={(breadcrumbs) => [
            ...breadcrumbs,
            {
              label: policyAudit.controlSystem?.name,
              link: isVendorUser()
                ? ''
                : `/dashboard/controlsystems/${policyAudit.controlSystem.id}`,
            },
            {
              label: 'Audit',
            },
          ]}
        >
          <>
            <div styleName="base-pane" ref={paneRef}>
              {state.alertVisible && !R.isEmpty(state.errors) && (
                <Alert
                  message="It looks like you didn't answer all the required questions. Please review your answers."
                  type="error"
                  closable
                  onClose={handleCloseAlert}
                  showIcon
                  style={styles.alert}
                />
              )}
              <PrimaryContentPane>
                <div styleName="submit-container">
                  <div styleName="show-unanswered-container">
                    <Switch
                      size="small"
                      onChange={toggleShowUnanswered}
                      checked={state.showUnansweredOnly}
                    />
                    <div styleName="show-unanswered">
                      Show Unanswered Questions Only
                    </div>
                  </div>
                  <SubmitButton onError={handleErrors} />
                </div>
                <div styleName="progress-container">
                  <div styleName="progress-label">Progress</div>
                  <Progress
                    percent={completionPercentage}
                    style={styles.progress}
                  />
                </div>
                <div>
                  <Tabs
                    activeKey={state.selectedGroup}
                    onChange={(key) =>
                      setState({ ...state, selectedGroup: key })
                    }
                    renderTabBar={renderTabBar}
                    hideAdd
                    items={getTabItems()}
                  />
                </div>
                <div styleName="bottom-button">
                  {hasNextGroup() ? (
                    <Button type="primary" onClick={handleNextGroupClick}>
                      Next
                      <ArrowRightOutlined />
                    </Button>
                  ) : (
                    <SubmitButton onError={handleErrors} hideReopen />
                  )}
                </div>
              </PrimaryContentPane>
            </div>
          </>
        </ContentLayout>
      )}
    </>
  );
};

export default PolicyAuditDetail;
