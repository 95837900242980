import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { TablePaginationConfig } from 'antd/es/table';
import { ColumnProps } from 'antd/lib/table';
import { SorterResult } from 'antd/lib/table/interface';

import Table from '@totem/components/common/table/Table';
import ActionMenu from '@totem/components/surveyV2/templates/ActionMenu';
import TemplatesContext from '@totem/components/surveyV2/templates/TemplatesContext';
import { SurveyTemplate } from '@totem/components/surveyV2/types';
import { DateStringToLocal } from '@totem/utilities/dateUtilities';
import UserProfileContext from '@totem/components/UserProfileContext';
const TemplatesTable = () => {
  const { userProfile } = useContext(UserProfileContext);
  const { data, loading, input, setInput, totalRecords } =
    useContext(TemplatesContext);

  const getTemplates = () => {
    if (typeof data !== 'undefined' && data !== null) {
      return data;
    }
    return [];
  };

  const columns: ColumnProps<SurveyTemplate>[] = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      showSorterTooltip: true,
      render: (_, template: SurveyTemplate) => (
        <>
          {userProfile.organization.id === template.organizationId && (
            <Link to={`/dashboard/surveytemplates/${template.id}`}>
              {template.name}
            </Link>
          )}
          {userProfile.organization.id !== template.organizationId && (
            <span>{template.name}</span>
          )}
        </>
      ),
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        compA.name === compB.name ? 0 : compA.name > compB.name ? 1 : -1,
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      showSorterTooltip: true,
      render: (_, template: SurveyTemplate) =>
        DateStringToLocal(template.createdAt),
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        compA.createdAt === compB.createdAt
          ? 0
          : compA.createdAt > compB.createdAt
            ? 1
            : -1,
    },
    {
      title: 'Updated At',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      showSorterTooltip: true,
      render: (_, template: SurveyTemplate) =>
        DateStringToLocal(template.updatedAt),
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        compA.updatedAt === compB.updatedAt
          ? 0
          : compA.updatedAt > compB.updatedAt
            ? 1
            : -1,
    },
    {
      title: 'Actions',
      width: 30,
      dataIndex: 'additionalOptions',
      key: 'additionalOptions',
      render: (_, template: SurveyTemplate) => (
        <div className="center-table-cell">
          {userProfile.organization.id === template.organizationId && (
            <ActionMenu template={template} />
          )}
        </div>
      ),
    },
  ];

  const handleTableChange = (
    updatedPagination: TablePaginationConfig,
    filters: SorterResult<SurveyTemplate>,
    sorter,
  ) => {
    const { ...params } = filters;

    let sortDir: string = sorter.order === 'descend' ? '-1' : '1';
    if (typeof sorter.order === 'undefined' || sorter.order === null) {
      if (
        typeof input.sortDirection !== 'undefined' &&
        input.sortDirection !== null
      ) {
        sortDir = input.sortDirection;
      }
    }

    let sortField = input.sortField ? input.sortField : 'lastOccurrence';
    if (
      typeof sorter.field !== 'undefined' &&
      typeof sorter.order !== 'undefined'
    ) {
      sortField = sorter.field;
    }

    // @ts-ignore
    setInput({
      ...input,
      ...params,
      pageSize: updatedPagination.pageSize,
      page: updatedPagination.current,
      sortField,
      sortDirection: sortDir,
    });
  };

  return (
    <Table
      showSorterTooltip
      columns={columns}
      dataSource={getTemplates()}
      loading={loading}
      onChange={handleTableChange}
      pagination={{
        current: input.page,
        pageSize: input.pageSize,
        total: totalRecords,
        showSizeChanger: true,
      }}
      rowKey={(record) => record.id}
    />
  );
};

export default TemplatesTable;
