import React, { ReactNode, useEffect, useState } from 'react';

import TemplateContext from '@totem/components/surveyV2/templateDetails/SurveyTemplateContext';
import { SurveyTemplate } from '@totem/components/surveyV2/types';
import { getToken } from '@totem/utilities/accountUtilities';
import { V2_SURVEY_ENDPOINT } from '@totem/utilities/endpoints';

type Props = {
  surveyTemplateId: string;
  children?: ReactNode;
};
const SurveyTemplateContainer = ({ surveyTemplateId, children }: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [refresh, setRefresh] = useState<boolean>(true);
  const [data, setData] = useState<SurveyTemplate>(null);

  useEffect(() => {
    if (refresh) {
      setRefresh(false);
      setIsLoading(true);

      fetch(`${V2_SURVEY_ENDPOINT}/template/${surveyTemplateId}`, {
        method: 'GET',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
      })
        .then((res) => res.json())
        .then((result: SurveyTemplate) => {
          setData(result);
        })
        .then(() => {
          setIsLoading(false);
        });
    }
  }, [surveyTemplateId, refresh]);

  return (
    <TemplateContext.Provider
      value={{
        surveyTemplateId,
        loading: isLoading,
        setIsLoading,
        data,
        setData,
      }}
    >
      {children}
    </TemplateContext.Provider>
  );
};

export default SurveyTemplateContainer;
