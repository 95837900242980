import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

interface Props {
  size?: number;
  color?: string;
}

const DetailSpinner = ({ size, color }: Props) => {
  return (
    <Spin
      indicator={
        <LoadingOutlined style={{ fontSize: size || 48, color }} spin />
      }
    />
  );
};

export default DetailSpinner;
