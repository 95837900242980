import React, { useContext, useEffect, useState } from 'react';
import { Col, Popover, Row } from 'antd';

import EventTotalsContext from '@totem/components/events/totals/EventTotalsContext';
import EventTypeDescription from '@totem/components/events/totals/EventTypeDescription';
import TotalValue from '@totem/components/events/totals/TotalValue';
import colors from '@totem/styles/colors';
import IBIcon from '@totem/styles/Icon';

import './totals.css';
const TotalsContainer = () => {
  const { data } = useContext(EventTotalsContext);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const size = windowWidth > 1600 ? 48 : 36;

  const styles = {
    borderLeft: {
      borderLeftColor: colors.neutral.silver,
      borderLeftStyle: 'solid' as 'solid',
      borderLeftWidth: 1,
    },
    borderBottom: {
      borderBottomColor: colors.neutral.silver,
      borderBottomStyle: 'solid' as 'solid',
      borderBottomWidth: 1,
    },
    container: {
      paddingTop: '20px',
      paddingBottom: '20px',
    },
    icon: {
      fontSize: `${size}px`,
    },
    popover: {
      maxWidth: `50%`,
      marginLeft: '200px',
      marginRight: '200px',
    },
  };

  const baseLink =
    '/dashboard/events?sourceType=Threat%20Management&status=ACKNOWLEDGED&status=CREATED&status=CLEARED';

  const getColor = (count: number): string => {
    if (count > 0) {
      return colors.event.criticality.background.critical;
    }
    return colors.event.criticality.background.ok;
  };

  return (
    <div>
      <div style={styles.borderBottom}>
        <Row style={styles.container}>
          <Col span={6}>
            <TotalValue
              title={
                <Popover
                  style={styles.popover}
                  content={<EventTypeDescription eventType={'Anti-Malware'} />}
                  title={'Anti-Malware'}
                >
                  Anti-Malware
                </Popover>
              }
              icon={
                <IBIcon
                  name="Malware"
                  style={{
                    fontSize: `${size}px`,
                    color: getColor(
                      data.threatManagementLast30Days.antiMalware,
                    ),
                  }}
                />
              }
              link={`${baseLink}&eventType=Anti-Malware`}
              value={data.threatManagementLast30Days.antiMalware.toString(10)}
              valueColor={getColor(data.threatManagementLast30Days.antiMalware)}
            />
          </Col>
          <Col span={6} style={styles.borderLeft}>
            <TotalValue
              title={
                <Popover
                  style={styles.popover}
                  content={
                    <EventTypeDescription
                      eventType={'Network Attack Defense'}
                    />
                  }
                  title={'Network Attacks'}
                >
                  Network Attacks
                </Popover>
              }
              icon={
                <IBIcon
                  name="Network Attack"
                  style={{
                    fontSize: `${size}px`,
                    color: getColor(
                      data.threatManagementLast30Days.networkAttacks,
                    ),
                  }}
                />
              }
              link={`${baseLink}&eventType=Network Attack Defense Event`}
              value={data.threatManagementLast30Days.networkAttacks.toString(
                10,
              )}
              valueColor={getColor(
                data.threatManagementLast30Days.networkAttacks,
              )}
            />
          </Col>
          <Col span={6} style={styles.borderLeft}>
            <TotalValue
              title={
                <Popover
                  style={styles.popover}
                  content={
                    <EventTypeDescription eventType={'Ransomware Mitigation'} />
                  }
                  title={'Ransomware Activity'}
                >
                  Ransomware Activity
                </Popover>
              }
              icon={
                <IBIcon
                  name="Ransomware"
                  style={{
                    fontSize: `${size}px`,
                    color: getColor(
                      data.threatManagementLast30Days
                        .ransomwareActivityDetected,
                    ),
                  }}
                />
              }
              link={`${baseLink}&eventType=Ransomware Activity Detection`}
              value={data.threatManagementLast30Days.ransomwareActivityDetected.toString(
                10,
              )}
              valueColor={getColor(
                data.threatManagementLast30Days.ransomwareActivityDetected,
              )}
            />
          </Col>
          <Col span={6} style={styles.borderLeft}>
            <TotalValue
              title={
                <Popover
                  style={styles.popover}
                  content={
                    <EventTypeDescription eventType={'Advanced Anti-Exploit'} />
                  }
                  title={'Anti-Exploit'}
                >
                  Anti-Exploit
                </Popover>
              }
              icon={
                <IBIcon
                  name="Exploit"
                  style={{
                    fontSize: `${size}px`,
                    color: getColor(
                      data.threatManagementLast30Days.antiExploitEvents,
                    ),
                  }}
                />
              }
              link={`${baseLink}&eventType=Anti-Exploit Event`}
              value={data.threatManagementLast30Days.antiExploitEvents.toString(
                10,
              )}
              valueColor={getColor(
                data.threatManagementLast30Days.antiExploitEvents,
              )}
            />
          </Col>
        </Row>
      </div>
      <div>
        <Row style={styles.container}>
          <Col span={6}>
            <TotalValue
              title={
                <Popover
                  style={styles.popover}
                  content={
                    <EventTypeDescription eventType={'Security for Storage'} />
                  }
                  title={'Storage Anti-Malware'}
                >
                  Storage Anti-Malware
                </Popover>
              }
              icon={
                <IBIcon
                  name="Storage Malware"
                  style={{
                    fontSize: `${size}px`,
                    color: getColor(
                      data.threatManagementLast30Days.storageAntiMalware,
                    ),
                  }}
                />
              }
              link={`${baseLink}&eventType=Storage Anti-Malware Event`}
              value={data.threatManagementLast30Days.storageAntiMalware.toString(
                10,
              )}
              valueColor={getColor(
                data.threatManagementLast30Days.storageAntiMalware,
              )}
            />
          </Col>
          <Col span={6} style={styles.borderLeft}>
            <TotalValue
              title={
                <Popover
                  style={styles.popover}
                  content={
                    <EventTypeDescription
                      eventType={'Advanced Threat Control'}
                    />
                  }
                  title={'Advanced Threat'}
                >
                  Advanced Threat
                </Popover>
              }
              icon={
                <IBIcon
                  name="Advanced Threat"
                  style={{
                    fontSize: `${size}px`,
                    color: getColor(
                      data.threatManagementLast30Days.advancedThreatControl,
                    ),
                  }}
                />
              }
              link={`${baseLink}&eventType=Advanced Threat Control`}
              value={data.threatManagementLast30Days.advancedThreatControl.toString(
                10,
              )}
              valueColor={getColor(
                data.threatManagementLast30Days.advancedThreatControl,
              )}
            />
          </Col>
          <Col span={6} style={styles.borderLeft}>
            <TotalValue
              title={
                <Popover
                  style={styles.popover}
                  content={
                    <EventTypeDescription eventType={'Security for Storage'} />
                  }
                  title={'Data Protection'}
                >
                  Data Protection
                </Popover>
              }
              icon={
                <IBIcon
                  name="Data Protection"
                  style={{
                    fontSize: `${size}px`,
                    color: getColor(
                      data.threatManagementLast30Days.dataProtection,
                    ),
                  }}
                />
              }
              link={`${baseLink}&eventType=Data Protection`}
              value={data.threatManagementLast30Days.dataProtection.toString(
                10,
              )}
              valueColor={getColor(
                data.threatManagementLast30Days.dataProtection,
              )}
            />
          </Col>
          <Col span={6} style={styles.borderLeft}>
            <TotalValue
              title={
                <Popover
                  style={styles.popover}
                  content={<EventTypeDescription eventType={'HyperDetect'} />}
                  title={'Hyper Detect'}
                >
                  Hyper Detect
                </Popover>
              }
              icon={
                <IBIcon
                  name="Hyper Detect"
                  style={{
                    fontSize: `${size}px`,
                    color: getColor(
                      data.threatManagementLast30Days.hyperDetectEvents,
                    ),
                  }}
                />
              }
              link={`${baseLink}&eventType=Hyper Detect Event`}
              value={data.threatManagementLast30Days.hyperDetectEvents.toString(
                10,
              )}
              valueColor={getColor(
                data.threatManagementLast30Days.hyperDetectEvents,
              )}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default TotalsContainer;
