import React, { useCallback, useEffect, useState } from 'react';
import { Button } from 'antd';

import RemoteAccessProvisioningModal from '@totem/components/settings/sections/services/provisioning/remote_access/RemoteAccessProvisioningModal';
import NozomiGuardianProvisioningModal from '@totem/components/settings/sections/services/provisioning/threat_detection/NozomiGuardianProvisioningModal';
import ServiceAddModal from '@totem/components/settings/sections/services/serviceAddModal';
import ServicesTable from '@totem/components/settings/sections/services/servicesTable';
import { Service } from '@totem/types/organization';
import { AvailableService } from '@totem/types/services';
import { getToken } from '@totem/utilities/accountUtilities';
import { isSystemsAdmin } from '@totem/utilities/authUtilities';
import { ORGANIZATION_SERVICES_ENDPOINT } from '@totem/utilities/endpoints';

import './services.css';

const Services = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [loadServices, setLoadServices] = useState<boolean>(true);
  const [organizationServices, setOrganizationServices] = useState<Service[]>(
    [],
  );
  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const [isSending, setIsSending] = useState(false);
  const [
    showRemoteAccessProvisioning,
    setShowRemoteAccessProvisioning,
  ] = useState<boolean>(false);
  const [
    showThreatDetectionProvisioning,
    setShowThreatDetectionProvisioning,
  ] = useState<boolean>(false);
  const [selectedService, setSelectedService] = useState<Service>(null);
  const canProvision = isSystemsAdmin();

  const filterOrgServices = (orgServices: Service[]) => {
    return orgServices.filter(svc => svc.isDeleted === false);
  };

  useEffect(() => {
    if (loadServices) {
      fetch(ORGANIZATION_SERVICES_ENDPOINT, {
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
      })
        .then(res => res.json())
        .then(result => {
          setOrganizationServices(filterOrgServices(result));
        })
        .then(() => setIsLoading(false));

      setLoadServices(false);
    }
  }, [loadServices]);

  const sendAddServiceToOrganization = useCallback(
    async (service: AvailableService) => {
      if (isSending) {
        return;
      }

      setIsSending(true);

      fetch(ORGANIZATION_SERVICES_ENDPOINT, {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getToken()}`,
        }),
        body: JSON.stringify(service),
      })
        .then(res => res.json())
        .then(result => {
          setOrganizationServices(filterOrgServices(result));
        })
        .then(() => {
          setIsSending(false);
        });
    },
    [isSending],
  );

  const sendRemoveServiceFromOrganization = useCallback(
    async (serviceId: string) => {
      if (isSending) {
        return;
      }

      setIsSending(true);

      fetch(`${ORGANIZATION_SERVICES_ENDPOINT}/${serviceId}`, {
        method: 'DELETE',
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getToken()}`,
        }),
      })
        .then(res => res.json())
        .then(result => {
          setOrganizationServices(filterOrgServices(result));
        })
        .then(() => {
          setIsSending(false);
        });
    },
    [isSending],
  );

  const handleOnRemove = (service: Service) => {
    sendRemoveServiceFromOrganization(service.id);
  };

  const handleCloseModal = () => {
    setShowAddModal(false);
  };

  const handleOnCreate = (availableService: AvailableService) => {
    sendAddServiceToOrganization(availableService);
    handleCloseModal();
  };

  const handleShowRemoteAccessProvisioning = (service: Service) => {
    setSelectedService(service);
    setShowRemoteAccessProvisioning(true);
  };

  const handleCloseRemoteAccessProvisioning = (refreshServices: boolean) => {
    setShowRemoteAccessProvisioning(false);
    if (refreshServices) {
      setLoadServices(true);
    }
  };

  const handleShowThreatDetectionProvisioning = (service: Service) => {
    setSelectedService(service);
    setShowThreatDetectionProvisioning(true);
  };

  const handleCloseThreatDetectionProvisioning = (refreshServices: Boolean) => {
    setShowThreatDetectionProvisioning(false);
    if (refreshServices) {
      setLoadServices(true);
    }
  };

  const handleShowProvisioning = (service: Service) => {
    if (service.name === 'remote_access') {
      handleShowRemoteAccessProvisioning(service);
    }
    if (service.name === 'threat_detection') {
      handleShowThreatDetectionProvisioning(service);
    }
  };

  return (
    <div styleName="container">
      <div styleName="header-container">
        <div styleName="header">Services</div>
        <Button
          type="primary"
          onClick={() => {
            setShowAddModal(true);
          }}
        >
          Add Service
        </Button>
      </div>
      <ServicesTable
        loading={isLoading}
        canProvision={canProvision}
        organizationServices={organizationServices}
        onRemove={handleOnRemove}
        onShowProvisioning={handleShowProvisioning}
      />
      {showAddModal && canProvision && (
        <ServiceAddModal
          organizationServices={organizationServices}
          visible={showAddModal}
          onSubmit={handleOnCreate}
          onClose={handleCloseModal}
        />
      )}
      {showRemoteAccessProvisioning && (
        <RemoteAccessProvisioningModal
          visible={showRemoteAccessProvisioning}
          onClose={handleCloseRemoteAccessProvisioning}
          service={selectedService}
        />
      )}
      {showThreatDetectionProvisioning && (
        <NozomiGuardianProvisioningModal
          visible={showThreatDetectionProvisioning}
          onClose={handleCloseThreatDetectionProvisioning}
          service={selectedService}
        />
      )}
    </div>
  );
};

export default Services;
