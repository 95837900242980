import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { ColumnProps } from 'antd/es/table';

import Table from '@totem/components/common/table/Table';
import TenantsContext from '@totem/components/tenants/tenantsPage/TenantsContext';
import TenantTableBuildings from '@totem/components/tenants/tenantsPage/TenantTableBuildings';
import TenantTableUsers from '@totem/components/tenants/tenantsPage/TenantTableUsers';
import { Tenant } from '@totem/components/tenants/types';
import { isNotNull } from '@totem/utilities/common';
import { sortStringAscending } from '@totem/utilities/tableUtilities';

const TenantsTable = () => {
  const { data, loading } = useContext(TenantsContext);

  const columns: ColumnProps<Tenant>[] = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      showSorterTooltip: true,
      defaultSortOrder: 'ascend',
      render: (_, record: Tenant) => (
        <Link to={`/dashboard/tenants/${record.id}`}>{record.name}</Link>
      ),
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) => sortStringAscending(compA.name, compB.name),
    },
    {
      title: 'Buildings',
      dataIndex: 'buildings',
      key: 'buildings',
      render: (_, record: Tenant) => <TenantTableBuildings tenant={record} />,
    },
    {
      title: 'Users',
      dataIndex: 'users',
      key: 'users',
      render: (_, record: Tenant) => <TenantTableUsers tenant={record} />,
    },
  ];

  return (
    <Table
      showSorterTooltip
      columns={columns}
      dataSource={isNotNull(data) ? data : []}
      loading={loading}
      rowKey={(record) => record.id}
      style={{ verticalAlign: 'top' }}
    />
  );
};

export default TenantsTable;
