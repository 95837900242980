import { all, fork } from 'redux-saga/effects';

import building from './buildingSagas';
import file from './fileSagas';
import health from './healthSagas';
import media from './mediaSagas';
import policyAudit from './policyAuditSagas';
import region from './regionSagas';
import remoteAccessUserSagas from './remoteAccessUserSagas';
import userSagas from './userSagas';
import version from './versionSagas';

const sagas = [
  ...media,
  ...building,
  ...region,
  ...version,
  ...file,
  ...health,
  ...policyAudit,
  ...remoteAccessUserSagas,
  ...userSagas,
];

export default function* root() {
  yield all(sagas.map((saga) => fork(saga)));
}
