import React, { useContext, useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { CopyOutlined } from '@ant-design/icons';
import { Button, message } from 'antd';
import { TablePaginationConfig } from 'antd/es/table';
import { ColumnProps } from 'antd/lib/table';
import { SorterResult } from 'antd/lib/table/interface';

import Table from '@totem/components/common/table/Table';
import Tooltip from '@totem/components/common/tooltip/Tooltip';
import { FindingsInput } from '@totem/components/surveyV2/building/controlSystems/systemSecurity/types';
import { getPlainTextFinding } from '@totem/components/surveyV2/building/controlSystems/systemSecurity/utilities';
import { FindingRecord } from '@totem/components/surveyV2/building/controlSystems/types';
import SurveyContext from '@totem/components/surveyV2/instanceDetail/SurveyInstanceContext';
import { LevelOfEffort } from '@totem/components/surveyV2/utilities/SurveyEnumerations';
import { Criticality } from '@totem/types/criticality';
import { isNotNull } from '@totem/utilities/common';
import { getCriticalityBadge } from '@totem/utilities/criticalityUtilities';
import { omitNilOrEmpty } from '@totem/utilities/objectUtilities';
import { stringifyArray } from '@totem/utilities/tableFilterUtilities';
import {
  sortNumberAscending,
  sortStringAscending,
} from '@totem/utilities/tableUtilities';

type FilterOption = {
  text: string;
  value: string;
};

const INCLUDED_CRITICALITIES = [
  Criticality.Info,
  Criticality.Critical,
  Criticality.High,
  Criticality.Medium,
  Criticality.Low,
  Criticality.Compliant,
];

const FindingsTable = () => {
  const { data } = useContext(SurveyContext);
  const [questions, setQuestions] = useState<FindingRecord[]>([]);
  const [filterOptionsCategory, setFilterOptionsCategory] = useState<
    FilterOption[]
  >([]);
  const [refreshData, setRefreshData] = useState<boolean>(false);
  const [input, updateInput] = useState<FindingsInput>({
    pageSize: 100,
    page: 1,
    sortField: 'category',
    sortDirection: '1',
    criticality: [],
    category: [],
    levelOfEffort: [],
  });

  const setInput = (updated: Partial<FindingsInput>) => {
    updateInput(omitNilOrEmpty({ ...input, ...updated }));
    setRefreshData(true);
  };

  useEffect(() => {
    const records: FindingRecord[] = [];
    const categoryFilterOptions: FilterOption[] = [];

    if (isNotNull(data)) {
      for (let cIdx = 0; cIdx < data.survey.categories.length; cIdx++) {
        const category = data.survey.categories[cIdx];
        if (category.visible) {
          categoryFilterOptions.push({
            text: category.name,
            value: category.name,
          });
          const filteredQuestions = category.questions.filter(
            (chk) =>
              (chk.type === 'enumsingle' || chk.type === 'scored') &&
              chk.policy !== '' &&
              chk.visible,
          );
          // eslint-disable-next-line max-depth
          for (let qIdx = 0; qIdx < filteredQuestions.length; qIdx++) {
            const question = filteredQuestions[qIdx];

            const selectedOption = question.options.filter(
              (chk) => chk.label === question.value.value,
            );

            records.push({
              category: category.name,
              question,
              selectedOption: isNotNull(selectedOption)
                ? selectedOption[0]
                : null,
            });
          }
        }
      }
    }
    setFilterOptionsCategory(categoryFilterOptions);
    setQuestions(records);
  }, [data, refreshData]);

  const columns: ColumnProps<FindingRecord>[] = [
    {
      title: 'Finding Description',
      dataIndex: 'description',
      render: (_, record: FindingRecord) =>
        record.selectedOption?.findingDescription || 'N/A',
    },
    {
      title: 'Criticality',
      dataIndex: 'criticality',
      width: '17rem',
      render: (_, record: FindingRecord) =>
        getCriticalityBadge(record.selectedOption?.criticality),
      sorter: (compA, compB) =>
        sortNumberAscending(
          compA.selectedOption?.criticality,
          compB.selectedOption?.criticality,
        ),
      filters: INCLUDED_CRITICALITIES.map((criticality) => ({
        text: getCriticalityBadge(criticality),
        value: criticality,
      })),
      filterMultiple: true,
      filteredValue: stringifyArray(input.criticality),
    },
    {
      title: 'Finding Group',
      dataIndex: 'category',
      width: '17rem',
      render: (_, record: FindingRecord) => record.category,
      sorter: (compA, compB) =>
        sortStringAscending(compA.category, compB.category),
      filters: filterOptionsCategory,
      filterMultiple: true,
      filteredValue: stringifyArray(input.category),
    },
    {
      title: 'Effort Level',
      dataIndex: 'levelOfEffort',
      width: '17rem',
      render: (_, record: FindingRecord) =>
        LevelOfEffort.find(
          (chk) => chk.value === record.question?.levelOfEffort,
        ).label,
      sorter: (compA, compB) =>
        sortNumberAscending(
          compA.question.levelOfEffort,
          compB.question.levelOfEffort,
        ),
      filters: LevelOfEffort.map((loe) => {
        return {
          text: loe.label,
          value: loe.value,
        };
      }),
      filterMultiple: true,
      filteredValue: stringifyArray(input.levelOfEffort),
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      width: '5rem',
      render: (_, record: FindingRecord) => (
        <div
          className="center-table-cell"
          onClick={(event) => event.stopPropagation()}
        >
          <CopyToClipboard
            text={getPlainTextFinding(record)}
            onCopy={() =>
              message.open({
                type: 'success',
                content: 'Audit finding copied!',
              })
            }
          >
            <Tooltip title="Copy Finding" placement="top">
              <Button shape="circle" icon={<CopyOutlined />} />
            </Tooltip>
          </CopyToClipboard>
        </div>
      ),
    },
  ];

  const handleTableChange = (
    updatedPagination: TablePaginationConfig,
    filters: SorterResult<FindingRecord>,
    sorter,
  ) => {
    const { ...params } = filters;

    let sortDir: string = sorter.order === 'descend' ? '-1' : '1';
    if (typeof sorter.order === 'undefined' || sorter.order === null) {
      if (
        typeof input.sortDirection !== 'undefined' &&
        input.sortDirection !== null
      ) {
        sortDir = input.sortDirection;
      }
    }

    let sortField = input.sortField ? input.sortField : 'lastOccurrence';
    if (
      typeof sorter.field !== 'undefined' &&
      typeof sorter.order !== 'undefined'
    ) {
      sortField = sorter.field;
    }

    // @ts-ignore
    setInput({
      ...input,
      ...params,
      pageSize: updatedPagination.pageSize,
      page: updatedPagination.current,
      sortField,
      sortDirection: sortDir,
    });
  };

  return (
    <>
      <Table
        rowKey={(record) => record.question.id}
        columns={columns}
        dataSource={questions}
        onChange={handleTableChange}
        pagination={{
          current: input.page,
          pageSize: input.pageSize,
          total: isNotNull(questions) ? questions.length : 0,
          showSizeChanger: true,
        }}
      />
    </>
  );
};

export default FindingsTable;
