import { createContext } from 'react';

import { EMPTY_ID } from '@totem/components/common/reference/ReferenceSelector';
import { SurveyTemplate} from "@totem/components/surveyV2/types";

interface Context {
  surveyTemplateId: string;
  loading: boolean;
  setIsLoading: (isLoading: boolean) => void;
  data: SurveyTemplate;
  setData: (data: SurveyTemplate) => void;
}

export default createContext<Context>({
  surveyTemplateId: EMPTY_ID,
  loading: false,
  setIsLoading: () => {},
  data: null,
  setData: () => {},
});
