import React from 'react';

import NinjaOneMappingContainer from '@totem/components/settings/sections/ninjaOneMapping/NinjaOneMappingContainer';
import NinjaOneMappingTable from '@totem/components/settings/sections/ninjaOneMapping/NinjaOneMappingTable';

import '../../settings.css';
const NinjaOneMappingPanel = () => {
  return (
    <div styleName="container">
      <div styleName="header-container">
        <div styleName="header">Building Mapping</div>
      </div>
      <NinjaOneMappingContainer>
        <NinjaOneMappingTable />
      </NinjaOneMappingContainer>
    </div>
  );
};

export default NinjaOneMappingPanel;
