import React from 'react';
import { Link } from 'react-router-dom';
import {
  DeleteOutlined,
  EditOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import { ColumnProps } from 'antd/lib/table';

import { EMPTY_ID } from '@totem/components/common/reference/ReferenceSelector';
import Table from '@totem/components/common/table/Table';
import { ContractLine } from '@totem/types/contracts';
import { formatDateOnly, getForeignKeyValue } from '@totem/utilities/common';

import '../contracts.css';

const styles = {
  editIcon: {
    color: '#1890ff',
  },
};

type Props = {
  loading: boolean;
  contractLines: ContractLine[];
  isEditMode: boolean;
  onCreate: () => void;
  onEdit: (contractLine: ContractLine) => void;
  onDelete: (contractLine: ContractLine) => void;
};

const ContractLinesTable = ({
  contractLines,
  loading,
  isEditMode,
  onCreate,
  onEdit,
  onDelete,
}: Props) => {
  const getServices = (contractLine: ContractLine) => {
    if (contractLine.services !== null) {
      return (
        <>
          {contractLine.services.map(service => {
            return (
              <span key={`SVC:${contractLine.id}:${service.id}`}>
                {service.name}
                <br />
              </span>
            );
          })}
        </>
      );
    }
    return <></>;
  };

  // const getServiceLevelAgreements = (contractLine: ContractLine) => {
  //   if (contractLine.serviceLevelAgreements !== null) {
  //     return (
  //       <>
  //         {contractLine.serviceLevelAgreements.map(sla => {
  //           return (
  //             <span key={`SLA:${contractLine.id}:${sla.id}`}>
  //               {sla.name}
  //               <br />
  //             </span>
  //           );
  //         })}
  //       </>
  //     );
  //   }
  //   return <></>;
  // };

  const getBuildingDisplay = (cl: ContractLine) => {
    if (
      typeof cl !== 'undefined' &&
      cl !== null &&
      cl.serviceAddress !== null &&
      cl.serviceAddress.id !== null &&
      cl.serviceAddress.id !== EMPTY_ID
    ) {
      return (
        <Link to={`/dashboard/buildings/${cl.serviceAddress.id}`}>
          {cl.serviceAddress.name}
        </Link>
      );
    } else if (cl.serviceAddress !== null) {
      return <span>{cl.serviceAddress.name}</span>;
    }
    return <span>-</span>;
  };

  const getDevices = (contractLine: ContractLine) => {
    if (contractLine.devices !== null) {
      return (
        <>
          {contractLine.devices.map(device => {
            return (
              <span key={`DEV:${contractLine.id}:${device.id}`}>
                <Link to={`/dashboard/devices/${device.id}`}>
                  {device.name}
                </Link>
                <br />
              </span>
            );
          })}
        </>
      );
    }
    return <></>;
  };

  const getLineNumber = (contractLine: ContractLine) => {
    const lineKey = `${getForeignKeyValue(
      contractLine.keys,
      'NetSuite',
      'NETSUITE_SALES_ORDER_NUMBER',
    )}: ${contractLine.number}`;
    return lineKey;
  };

  const columns: ColumnProps<ContractLine>[] = [
    {
      title: 'Line',
      dataIndex: 'number',
      key: 'number',
      render: (_, contractLine: ContractLine) => getLineNumber(contractLine),
      width: '110px',
    },
    {
      title: 'Start',
      dataIndex: 'contractLineStart',
      key: 'contractLineStart',
      render: (_, contractLine: ContractLine) =>
        formatDateOnly(contractLine.contractLineStart),
      width: '100px',
    },
    {
      title: 'End',
      dataIndex: 'contractLineEnd',
      key: 'contractLineEnd',
      render: (_, contractLine: ContractLine) =>
        formatDateOnly(contractLine.contractLineEnd),
      width: '100px',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '120px',
    },
    {
      title: 'Services',
      dataIndex: 'services',
      key: 'services',
      render: (_, contractLine: ContractLine) => getServices(contractLine),
      width: '250px',
    },
    {
      title: 'Qty',
      dataIndex: 'quantity',
      key: 'quantity',
      width: '30px',
    },
    {
      title: 'Building',
      dataIndex: 'building',
      key: 'building',
      render: (_, contractLine: ContractLine) => (
        <span>{getBuildingDisplay(contractLine)}</span>
      ),
      width: '25%',
    },
    {
      title: 'Devices',
      dataIndex: 'devices',
      key: 'devices',
      render: (_, contractLine: ContractLine) => getDevices(contractLine),
    },
    {
      title: (
        <div styleName="rightAlign">
          {isEditMode && (
            <PlusCircleOutlined
              title="Add Contract Line"
              onClick={onCreate}
              style={styles.editIcon}
            />
          )}
        </div>
      ),
      dataIndex: 'actions',
      width: '20px',
      key: 'actions',
      render: (_, contractLine: ContractLine) => (
        <div styleName="rightAlign">
          {isEditMode && (
            <>
              <EditOutlined
                title="Edit Contract Line"
                onClick={() => onEdit(contractLine)}
                style={styles.editIcon}
              />
              &nbsp;
              <br />
              <DeleteOutlined
                title="Delete Contract Line"
                onClick={() => onDelete(contractLine)}
                style={styles.editIcon}
              />
            </>
          )}
        </div>
      ),
      className: 'tableAlignTop',
    },
  ];

  const getContractLines = () => {
    if (contractLines !== null) {
      const sortedLines = contractLines.sort((aCL, bCL) =>
        aCL.number > bCL.number ? 1 : -1,
      );
      return sortedLines;
    }
    return [];
  };

  return (
    <Table
      style={{ verticalAlign: 'top' }}
      showSorterTooltip
      pagination={{ pageSize: 100 }}
      columns={columns}
      dataSource={getContractLines()}
      loading={loading}
      rowKey={record => record.id}
    />
  );
};

export default ContractLinesTable;
