import React, { useState } from 'react';
import { Button, Form, Modal, Select, Tabs } from 'antd';

import RichTextInput from '@totem/components/common/richText/RichTextInput';
import {
  Activity,
  ActivityConfiguration,
  ExtendedFieldValue,
  TicketQueryResult,
} from '@totem/types/ticketing';

const { TabPane } = Tabs;

import ModalFooter from '@totem/components/ModalFooter';
import ModalTitle from '@totem/components/ModalTitle';
import ExtendedFields from '@totem/components/ticketing/details/extendedFields';
import { Attribute } from '@totem/types/common';

const FormItem = Form.Item;
const { Option } = Select;

const styles = {
  form: {
    width: '100%',
  },
  formItem: {
    paddingBottom: '0',
    marginBottom: '1rem',
  },
};

type Props = {
  ticket: TicketQueryResult;
  eligibleActivities: ActivityConfiguration[];
  visible: boolean;
  onClose: () => void;
  onSubmit: (activity: Activity) => void;
};

const ActivityEditModal = ({
  eligibleActivities,
  onClose,
  onSubmit,
  visible,
}: Props) => {
  const [note, setNote] = useState<string>('');
  const [activityCode, setActivityCode] = useState<string>('');
  const [selectedActivityCode, setSelectedActivityCode] =
    useState<ActivityConfiguration>(null);
  const [selectedTab, setSelectedTab] = useState<any>('tabNotes');
  const [extendedFieldValues, setExtendedFieldValues] =
    useState<Map<string, ExtendedFieldValue>>(null);

  const getEligibleActivityById = (activityId: string) => {
    const activityConfig = eligibleActivities.find(
      (activity) => activity.id === activityId,
    );
    if (typeof activityConfig !== 'undefined') {
      return activityConfig;
    }
    return null;
  };

  const validate = () => {
    let isValid = true;
    if (selectedActivityCode === null) {
      return false;
    }
    if (
      selectedActivityCode !== null &&
      selectedActivityCode.extendedFieldGroups !== null
    ) {
      selectedActivityCode.extendedFieldGroups.forEach((group) => {
        if (group.extendedFields !== null && group.extendedFields.length > 0) {
          group.extendedFields.forEach((extFld) => {
            const foundValue = extendedFieldValues.get(
              `${group.name}:${extFld.name}`,
            );
            if (extFld.isRequired && foundValue.value.trim() === '') {
              isValid = false;
            }
            if (!foundValue.isValid) {
              isValid = false;
            }
          });
        }
      });
    }

    return isValid;
  };

  const handleActivitySubmit = () => {
    if (validate()) {
      const attributes = [] as Attribute[];
      if (extendedFieldValues !== null) {
        extendedFieldValues.forEach((val) => {
          const attribute: Attribute = {
            name: val.name,
            label: val.label,
            value: val.value,
            display: val.display,
            id: val.id,
          };
          attributes.push(attribute);
        });
      }

      const activity: Activity = {
        id: '',
        type: {
          id: selectedActivityCode.id,
          code: selectedActivityCode.code,
          display: selectedActivityCode.display,
        },
        notes: {
          isRichText: true,
          plainText: note,
          htmlText: note,
        },
        attributes,
        createdByType: 'User',
        createdByName: '',
        createdById: '',
        createdAt: new Date().toISOString(),
        isPrivate: false,
        keys: [],
      };

      onSubmit(activity);
    }
  };

  const handleActivityCodeChange = (value: string) => {
    setSelectedTab('tabNotes');
    setActivityCode(value);
    const activityConfig = getEligibleActivityById(value);
    setSelectedActivityCode(activityConfig);

    const newExtendedFieldsValues = new Map<string, ExtendedFieldValue>();
    if (
      activityConfig !== null &&
      activityConfig.extendedFieldGroups !== null &&
      activityConfig.extendedFieldGroups.length > 0
    ) {
      setSelectedTab(activityConfig.extendedFieldGroups[0].name);
      activityConfig.extendedFieldGroups.forEach((group) => {
        if (group.extendedFields !== null && group.extendedFields.length > 0) {
          group.extendedFields.forEach((extFld) => {
            const extFldValue: ExtendedFieldValue = {
              key: `${group.name}:${extFld.name}`,
              name: extFld.name,
              label: extFld.label,
              value: '',
              isValid: true,
              display: extFld.display,
              id: '',
            };
            newExtendedFieldsValues.set(
              `${group.name}:${extFld.name}`,
              extFldValue,
            );
          });
        }
      });
    }
    setExtendedFieldValues(newExtendedFieldsValues);
  };

  const handleExtendedFieldChange = (
    groupName: string,
    fieldName: string,
    value: string,
    id: string,
    isValid: boolean,
  ) => {
    const key = `${groupName}:${fieldName}`;
    const newExtendedFieldValues = new Map(extendedFieldValues);
    const originalValue = newExtendedFieldValues.get(key);
    originalValue.value = value;
    originalValue.id = id;
    originalValue.isValid = isValid;
    newExtendedFieldValues.set(key, originalValue);
    setExtendedFieldValues(newExtendedFieldValues);
  };

  return (
    <Modal
      open={visible}
      onCancel={onClose}
      title={<ModalTitle>Activity</ModalTitle>}
      confirmLoading={false}
      width={'40%'}
      footer={
        <ModalFooter>
          <Button onClick={onClose}>Close</Button>
          <Button type="primary" onClick={handleActivitySubmit}>
            Save
          </Button>
        </ModalFooter>
      }
    >
      <Form layout="vertical" style={styles.form}>
        <FormItem label="Activity Type" colon={false} style={styles.formItem}>
          <Select
            defaultValue={activityCode}
            onChange={handleActivityCodeChange}
          >
            <Option value="">--Select--</Option>
            {eligibleActivities !== null &&
              eligibleActivities.map((ea) => (
                <Option key={ea.id} value={ea.id}>
                  {ea.display}
                </Option>
              ))}
          </Select>
        </FormItem>
        <Tabs activeKey={selectedTab} onChange={(key) => setSelectedTab(key)}>
          {selectedActivityCode !== null &&
            selectedActivityCode.extendedFieldGroups !== null &&
            selectedActivityCode.extendedFieldGroups.length > 0 &&
            selectedActivityCode.extendedFieldGroups.map(
              (extendedFieldGroup) => (
                <TabPane
                  tab={<b>{extendedFieldGroup.display}</b>}
                  key={extendedFieldGroup.name}
                >
                  <ExtendedFields
                    extendedFieldsGroup={extendedFieldGroup}
                    extendedFieldValues={extendedFieldValues}
                    onChange={handleExtendedFieldChange}
                  />
                </TabPane>
              ),
            )}
          <TabPane tab={<b>Notes</b>} key="tabNotes">
            <FormItem label="Notes" colon={false} style={styles.formItem}>
              <RichTextInput
                value={note}
                onChange={(value) => setNote(value)}
              />
            </FormItem>
          </TabPane>
        </Tabs>
      </Form>
    </Modal>
  );
};

export default ActivityEditModal;
