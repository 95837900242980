import { createContext } from 'react';

import { Report } from '@totem/components/ui_components/reports/types';

interface Context {
  loading: boolean;
  reports: Report[];
}

export default createContext<Context>({
  loading: false,
  reports: [],
});
