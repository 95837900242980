import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useMutation } from '@apollo/client';
import { Button, Form, Input, InputNumber, Modal, Select } from 'antd';
import * as R from 'ramda';

import BuildingDetailContext from '@totem/components/buildingDetail/BuildingDetailContext';
import AddressEntry from '@totem/components/common/addressEntry/AddressEntry';
import { UPDATE_BUILDING } from '@totem/graph/building';
import { Address, AddressFormErrors } from '@totem/types/address';
import { BuildingTypes } from '@totem/types/building';
import { getToken } from '@totem/utilities/accountUtilities';
import { BUILDINGS_ENDPOINT } from '@totem/utilities/endpoints';

const FormItem = Form.Item;
const Option = Select.Option;

const styles = {
  form: {
    width: '100%',
  },
  formItem: {
    paddingBottom: '0',
    marginBottom: '1rem',
  },
};

export interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const FORM_FIELDS_MAP: { [key: string]: string } = {
  addressOne: 'Address',
  city: 'City',
  state: 'State',
  country: 'Country',
  zipCode: 'Zip Code',
};

const EditBuildingModal = ({ open, setOpen }: Props) => {
  const { building } = useContext(BuildingDetailContext);

  const [buildingTypes, setBuildingTypes] = useState<BuildingTypes>({
    id: '62a8d9d5134444b27707dda8',
    type: 'BuildingTypes',
    data: [
      '',
      'Commercial Office',
      'Data Center',
      'Educational',
      'Life Sciences',
      'Multi-Family High Rise',
      'Multi-Family Podium',
      'Retail',
      'Warehouse',
      'Other',
    ],
  });
  const [errors, setErrors] = useState<AddressFormErrors>({});
  const [name, setName] = useState<string>(building.name);
  const [type, setType] = useState<string>(building.type);
  const [squareFootage, setSquareFootage] = useState<number>(
    building.squareFootage,
  );
  const [address, setAddress] = useState<Address>({
    addressOne: building.addressOne,
    addressTwo: building.addressTwo,
    city: building.city,
    country: building.country,
    location: { lat: building.location.lat, lng: building.location.lng },
    state: building.state,
    zipCode: building.zipCode,
  });

  const [updateBuilding, { loading }] = useMutation(UPDATE_BUILDING);

  useEffect(() => {
    if (!loading) {
      setOpen(false);
    }
  }, [loading, setOpen]);

  useEffect(() => {
    fetch(`${BUILDINGS_ENDPOINT}/types`, {
      method: 'GET',
      headers: new Headers({
        Authorization: `Bearer ${getToken()}`,
      }),
    })
      .then(res => res.json())
      .then((result: BuildingTypes) => {
        setBuildingTypes(result);
      });
  }, []);

  const validate = () => {
    if (R.isEmpty(name) || R.isNil(name)) {
      errors.name = 'Name is required.';
    }

    const addressErrors = Object.keys(FORM_FIELDS_MAP).reduce(
      (acc: AddressFormErrors, key: string) => {
        const label = FORM_FIELDS_MAP[key];
        const value = address[key];

        if (R.isEmpty(value) || R.isNil(value)) {
          return {
            ...acc,
            [key]: `${label} is required.`,
          };
        }

        return acc;
      },
      {},
    );

    setErrors({ ...errors, ...addressErrors });
  };

  const onCancel = () => {
    setOpen(false);
    setName(building.name);
    setAddress({
      addressOne: building.addressOne,
      addressTwo: building.addressTwo,
      city: building.city,
      country: building.country,
      location: { lat: building.location.lat, lng: building.location.lng },
      state: building.state,
      zipCode: building.zipCode,
    });
    setErrors({});
  };

  const onSave = () => {
    validate();

    if (!R.isEmpty(errors)) {
      return;
    }

    updateBuilding({
      variables: {
        input: {
          id: building.id,
          name,
          type,
          squareFootage,
          ...address,
        },
      },
    });
  };

  const footer = (
    <>
      <Button onClick={onCancel}>Cancel</Button>
      <Button type="primary" onClick={onSave} loading={loading}>
        Update
      </Button>
    </>
  );

  return (
    <Modal
      title="Update Building"
      open={open}
      onCancel={onCancel}
      footer={footer}
    >
      <Form layout="vertical" style={styles.form}>
        <FormItem
          label="Building Name"
          colon={false}
          validateStatus={R.isNil(errors.name) ? 'success' : 'error'}
          help={R.isNil(errors.name) ? null : errors.name}
          style={styles.formItem}
        >
          <Input
            value={name}
            name="name"
            onChange={event => setName(event.target.value)}
          />
        </FormItem>
        <FormItem
          label="Building Type"
          colon={false}
          validateStatus="success"
          style={styles.formItem}
        >
          <Select
            value={type}
            onChange={(newValue: string) => setType(newValue)}
          >
            {buildingTypes.data.map(bt => (
              <Option key={bt} value={bt}>
                {bt}
              </Option>
            ))}
          </Select>
        </FormItem>
        <FormItem
          label="Square Footage"
          colon={false}
          validateStatus="success"
          style={styles.formItem}
        >
          <InputNumber
            value={squareFootage}
            onChange={(newValue: number) => setSquareFootage(newValue)}
            min={0}
          />
        </FormItem>
      </Form>
      <AddressEntry
        value={address}
        onChange={setAddress}
        disabled={loading}
        errors={errors}
      />
    </Modal>
  );
};

export default EditBuildingModal;
