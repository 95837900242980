import React from 'react';
import { UserOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';
import dayjs from 'dayjs';

import Tooltip from '@totem/components/common/tooltip/Tooltip';
import { Question } from '@totem/types/healthRiskSurvey';
import { getUserName } from '@totem/utilities/userUtilities';

import './healthRiskSurveyDetail.css';

type Props = {
  question: Question;
};

const styles = {
  avatar: {
    marginRight: '1.6rem',
  },
};

const getDate = (date: string): string => {
  return dayjs(date).format('MMM D, YYYY');
};

const getTime = (date: string): string => {
  return dayjs(date).format('h:mm A');
};

const HealthRiskSurveyDetailAnswer = ({ question: { answer } }: Props) => {
  if (!answer?.updatedBy) {
    return null;
  }

  return (
    <div>
      <div styleName="updated-by">Updated By</div>
      <div styleName="user">
        <Tooltip title={getUserName(answer.updatedBy)} anchored>
          <Avatar
            size="large"
            src={answer.updatedBy.imageUrl}
            style={styles.avatar}
          >
            <UserOutlined />
          </Avatar>
        </Tooltip>
        <div styleName="date">
          {`${getTime(answer.updatedAt)} ${getDate(answer.updatedAt)}`}
        </div>
      </div>
    </div>
  );
};

export default HealthRiskSurveyDetailAnswer;
