import { createContext } from 'react';

import {
  DeviceType,
  DeviceTypeInput,
} from '@totem/components/deviceTypes/types';

interface Context {
  loading: boolean;
  data: DeviceType[];
  rawData: DeviceType[];
  action: string;
  actionItem: DeviceType;
  onAction?: (action: string, item: DeviceType) => void;
  input: DeviceTypeInput;
  setInput: (input: DeviceTypeInput) => void;
  selectedDeviceTypes: DeviceType[];
  setSelectedDeviceType: (deviceType: DeviceType, selected: boolean) => void;
}

export default createContext<Context>({
  loading: false,
  data: [],
  rawData: [],
  action: '',
  actionItem: null,
  onAction: () => {},
  input: {},
  setInput: () => {},
  selectedDeviceTypes: [],
  setSelectedDeviceType: () => {},
});
