import { createContext } from 'react';

import {
  User,
  UserQueryResults,
  UsersConnectionInput,
} from '@totem/components/users/container/types';

interface Context {
  input: UsersConnectionInput;
  setInput: (input: UsersConnectionInput) => void;
  loading: boolean;
  userData: UserQueryResults;
  totalRecords: number;
  onAction?: (action: string, user: User) => void;
}

export default createContext<Context>({
  input: {},
  setInput: () => {},
  loading: false,
  userData: null,
  totalRecords: 0,
  onAction: null,
});
