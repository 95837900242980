import React, { useContext } from 'react';
import { Col, Row } from 'antd';

import HeaderLabel from '@totem/components/ticketing/details/headerLabel';
import { isNotNull, isNull } from '@totem/utilities/common';
import colors from '@totem/styles/colors';
import DeviceHealthContext from '@totem/components/devices/health/deviceHealthContext';
import EventContext from '@totem/components/events/eventsContainer/eventContainerContext';

const COLOR_RED: string = colors.event.criticality.background.critical;
const COLOR_ORANGE: string = colors.event.criticality.background.major;
const COLOR_YELLOW: string = colors.event.criticality.background.warning;
const COLOR_GREEN: string = colors.event.criticality.background.ok;

const DeviceHealth = () => {
  const { data } = useContext(DeviceHealthContext);
  const { eventData } = useContext(EventContext);

  const criticalEvents = isNull(eventData)
    ? []
    : eventData.events.filter(
      (evt) => evt.severity === 5 && evt.status !== 'CLEARED',
    );
  const majorEvents = isNull(eventData)
    ? []
    : eventData.events.filter(
      (evt) => evt.severity === 4 && evt.status !== 'CLEARED',
    );
  const minorEvents = isNull(eventData)
    ? []
    : eventData.events.filter(
      (evt) => evt.severity === 3 && evt.status !== 'CLEARED',
    );
  const warningEvents = isNull(eventData)
    ? []
    : eventData.events.filter(
      (evt) => evt.severity === 2 && evt.status !== 'CLEARED',
    );

  return (
    <div>
      {isNotNull(data) && (
        <>
          <Row>
            <Col span={8}>
              <HeaderLabel title="Status">
                <span style={{ color: data.offline ? COLOR_RED : COLOR_GREEN }}>
                  {data.offline ? 'Offline' : 'Online'}
                </span>
              </HeaderLabel>
              <HeaderLabel title="Pending Reboot">
                <span
                  style={{
                    color:
                      data.pendingRebootReason === '' ? COLOR_GREEN : COLOR_RED,
                  }}
                >
                  {data.pendingRebootReason !== '' ? 'Yes' : 'No'}
                </span>
              </HeaderLabel>
              <HeaderLabel title="Intall Issues">
                <span
                  style={{
                    color:
                      data.installationIssuesCount > 0
                        ? COLOR_RED
                        : COLOR_GREEN,
                  }}
                >
                  {String(data.installationIssuesCount)}
                </span>
              </HeaderLabel>
            </Col>
            <Col span={8}>
              <HeaderLabel title="OS Patches">
                Pending:{' '}
                <span
                  style={{
                    color:
                      data.pendingOSPatchesCount > 0
                        ? COLOR_YELLOW
                        : COLOR_GREEN,
                  }}
                >
                  {data.pendingOSPatchesCount}
                </span>
                <br />
                Failed:{' '}
                <span
                  style={{
                    color:
                      data.failedOSPatchesCount > 0 ? COLOR_RED : COLOR_GREEN,
                  }}
                >
                  {data.failedOSPatchesCount}
                </span>
              </HeaderLabel>
              <HeaderLabel title="Software Patches">
                Pending:{' '}
                <span
                  style={{
                    color:
                      data.pendingSoftwarePatchesCount > 0
                        ? COLOR_YELLOW
                        : COLOR_GREEN,
                  }}
                >
                  {data.pendingSoftwarePatchesCount}
                </span>
                <br />
                Failed:{' '}
                <span
                  style={{
                    color:
                      data.failedSoftwarePatchesCount > 0
                        ? COLOR_RED
                        : COLOR_GREEN,
                  }}
                >
                  {data.failedSoftwarePatchesCount}
                </span>
              </HeaderLabel>
            </Col>
            <Col span={8}>
              <HeaderLabel title="Events">
                Critical:{' '}
                <span
                  style={{
                    color:
                      criticalEvents.length === 0 ? COLOR_GREEN : COLOR_RED,
                  }}
                >
                  {criticalEvents.length}
                </span>
                <br />
                Major:{' '}
                <span
                  style={{
                    color:
                      majorEvents.length === 0 ? COLOR_GREEN : COLOR_ORANGE,
                  }}
                >
                  {majorEvents.length}
                </span>
                <br />
                Minor:{' '}
                <span
                  style={{
                    color:
                      minorEvents.length === 0
                        ? COLOR_GREEN
                        : colors.event.criticality.background.minor,
                  }}
                >
                  {minorEvents.length}
                </span>
                <br />
                Warning:{' '}
                <span
                  style={{
                    color:
                      warningEvents.length === 0
                        ? COLOR_GREEN
                        : colors.event.criticality.background.warning,
                  }}
                >
                  {warningEvents.length}
                </span>
              </HeaderLabel>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

export default DeviceHealth;
