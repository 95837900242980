import React, { useContext, useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { Empty } from 'antd';
import { ApexOptions } from 'apexcharts';
import dayjs from 'dayjs';

import TitledJson from '@totem/components/common/TitledJson';
import LayoutContext from '@totem/components/devices/variables/presentation/layout/LayoutContext';
import SectionContext from '@totem/components/devices/variables/presentation/layout/layoutSection/SectionContext';
import PageContext from '@totem/components/devices/variables/presentation/layout/PageContext';
import {
  ChartSeries,
  VariableDataResponseExtended,
} from '@totem/components/devices/variables/presentation/layout/types';
import { isNotNull, isNull } from '@totem/utilities/common';
import { sortStringAscending } from '@totem/utilities/tableUtilities';

type Props = {
  title: string;
};

const LineChart = ({ title }: Props) => {
  const { isDebug } = useContext(PageContext);
  const { variableData } = useContext(LayoutContext);
  const { sectionData, sectionLayout } = useContext(SectionContext);
  const [localData, setLocalData] = useState<VariableDataResponseExtended[]>(
    [],
  );
  const [seriesData, setSeriesData] = useState<ChartSeries[]>([]);
  const [maxDecimalPlaces, setMaxDecimalPlaces] = useState<number>(0);

  useEffect(() => {
    if (isNotNull(sectionLayout)) {
      let maxDecimalPoints = 0;
      for (let idx = 0; idx < sectionLayout.variables.length; idx++) {
        // eslint-disable-next-line max-depth
        if (
          sectionLayout.variables[idx].max_decimal_places > maxDecimalPoints
        ) {
          maxDecimalPoints = sectionLayout.variables[idx].max_decimal_places;
        }
      }
      setMaxDecimalPlaces(maxDecimalPoints);
    }
  }, [sectionLayout]);

  // Get a list of the variables to plot
  useEffect(() => {
    if (isNotNull(sectionData) && isNotNull(sectionData.variables)) {
      const sectionVariableIds = sectionData.variables.map(
        (elem) => elem.variableId,
      );
      const numericVariables = variableData.filter(
        (elem) => sectionVariableIds.indexOf(elem.id) > -1 && elem.isNumeric,
      );

      setLocalData(numericVariables);
    }
  }, [sectionData, variableData]);

  // Convert variable data to chart series
  useEffect(() => {
    if (isNotNull(localData)) {
      const tmpChartSeries = localData.map((variable) => {
        return {
          name: variable.metaData.purpose,
          data: variable.history
            .sort((compA, compB) =>
              sortStringAscending(compA.lastUpdated, compB.lastUpdated),
            )
            .map((point) => {
              return {
                x: dayjs(point.lastUpdated).valueOf(),
                y: point.value,
              };
            }),
        };
      });
      setSeriesData(tmpChartSeries);
    }
  }, [localData]);

  const options: ApexOptions = {
    chart: {
      type: 'line',
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: [3, 3, 3],
      curve: 'straight',
      dashArray: [0, 0, 0],
    },
    title: {
      text: title,
      align: 'left',
    },
    xaxis: {
      type: 'datetime',
    },
    yaxis: {
      labels: {
        formatter: (value) => {
          return value.toFixed(maxDecimalPlaces);
        },
      },
    },
    legend: {
      tooltipHoverFormatter(val, opts) {
        return `${val} - <strong>${opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex.toFixed(maxDecimalPlaces)]}</strong>`;
      },
    },
    markers: {
      size: 0,
      hover: {
        sizeOffset: 6,
      },
    },
  };

  return (
    <div className="app">
      {(isNull(seriesData) || seriesData.length === 0) && <Empty />}
      <div className="row">
        <div className="mixed-chart">
          <ReactApexChart
            options={options}
            series={isNotNull(seriesData) ? seriesData : []}
            type="line"
            width="100%"
            height="300px"
          />
        </div>
      </div>
      {isDebug && <TitledJson title="Chart Data:" value={localData} />}
    </div>
  );
};

export default LineChart;
