import { createContext } from 'react';

import {
  DeviceFilterOptions,
  DeviceQueryResult,
  DeviceQueryResults,
  DevicesConnectionInput,
} from '@totem/types/devices';

interface Context {
  input: DevicesConnectionInput;
  setInput: (input: DevicesConnectionInput) => void;
  loading: boolean;
  deviceData: DeviceQueryResults;
  filterOptions: DeviceFilterOptions;
  totalRecords: number;
  onAction?: (action: string, device: DeviceQueryResult) => void;
}

export default createContext<Context>({
  input: {},
  setInput: () => {},
  loading: false,
  deviceData: null,
  filterOptions: {
    type: [],
    vendor: [],
    operatingSystem: [],
    region: [],
    building: [],
    models: [],
  },
  totalRecords: 0,
  onAction: null,
});
