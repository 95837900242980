import React from 'react';
import { EditOutlined } from '@ant-design/icons';
import { Button } from 'antd';

import DetailSpinner from '@totem/components/common/DetailSpinner';

import './profile.css';

const styles = {
  cancel: { marginRight: '1rem' },
};

interface Props {
  isEdit: boolean;
  loading: boolean;
  onSave: () => void;
  toggleProfileEditing: () => void;
}

const ProfileTitleHeader = ({
  isEdit,
  loading,
  onSave,
  toggleProfileEditing,
}: Props) => {
  return (
    <div styleName="settings-group-header-container">
      <div styleName="settings-group-header-label">Personal Details</div>
      {isEdit ? (
        <span styleName="attribute-group-header-container">
          <Button
            onClick={toggleProfileEditing}
            loading={loading}
            style={styles.cancel}
          >
            Cancel
          </Button>
          <Button type="primary" onClick={onSave} loading={loading}>
            Save
          </Button>
          {loading && (
            <div styleName="control-spinner-container">
              <DetailSpinner />
            </div>
          )}
        </span>
      ) : (
        <Button
          onClick={toggleProfileEditing}
          shape="circle"
          icon={<EditOutlined />}
        />
      )}
    </div>
  );
};

export default ProfileTitleHeader;
