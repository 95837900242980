import React from 'react';

import colors from '@totem/styles/colors';
import fonts from '@totem/styles/fonts';

type CountSize = 'small' | 'large';

interface Props {
  count: React.ReactNode;
  size?: CountSize;
  style?: React.CSSProperties;
}

const styles = {
  badge: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: colors.neutral.fog,
    color: colors.opacity.black0_8,
    borderRadius: '0.7rem',
    fontFamily: 'Roboto',
  },
  small: {
    minWidth: '3.3rem',
    fontSize: fonts.lg,
    fontWeight: 500,
    padding: '0.5rem',
  },
  large: {
    minWidth: '5.2rem',
    fontSize: '2.4rem',
    fontWeight: 700,
    padding: '0.8rem',
  },
};

const Count = ({ count, size, style }: Props) => {
  return (
    <div
      style={{
        ...styles.badge,
        ...(size === 'large' ? styles.large : styles.small),
        ...style,
      }}
    >
      {count}
    </div>
  );
};

export default Count;
