import {
  EventFilterInput,
  EventFilterOptions,
  EventFilters,
} from '@totem/components/events/eventFilters/types';
import { sortStringAscending } from '@totem/components/events/eventsContainer/utilities';
import { Filter } from '@totem/types/common';
import {toPascalCase} from "@totem/utilities/common";

export const buildEventFilters = (input: EventFilterInput) => {
  const paramFilters: Filter[] = [];

  if (input.regionId !== null && input.regionId.length > 0) {
    paramFilters.push({
      field: 'regionId',
      values: input.regionId,
    });
  }
  if (input.buildingId !== null && input.buildingId.length > 0) {
    paramFilters.push({
      field: 'buildingId',
      values: input.buildingId,
    });
  }
  if (input.controlSystemId !== null && input.controlSystemId.length > 0) {
    paramFilters.push({
      field: 'controlSystemId',
      values: input.controlSystemId,
    });
  }
  if (input.deviceId !== null && input.deviceId.length > 0) {
    paramFilters.push({
      field: 'deviceId',
      values: input.deviceId,
    });
  }
  if (input.severity !== null && input.severity.length > 0) {
    paramFilters.push({
      field: 'severity',
      values: input.severity,
    });
  }
  if (input.sourceSystem !== null && input.sourceSystem.length > 0) {
    paramFilters.push({
      field: 'sourceSystem',
      values: input.sourceSystem,
    });
  }
  if (input.sourceType !== null && input.sourceType.length > 0) {
    paramFilters.push({
      field: 'sourceType',
      values: input.sourceType,
    });
  }
  if (input.eventType !== null && input.eventType.length > 0) {
    paramFilters.push({
      field: 'eventType',
      values: input.eventType,
    });
  }
  if (input.status !== null && input.status.length > 0) {
    paramFilters.push({
      field: 'status',
      values: input.status,
    });
  }
  if (input.reference !== null && input.reference.length > 0) {
    paramFilters.push({
      field: 'keys',
      values: input.reference,
    });
  }

  return paramFilters;
};

export const addEventFilters = (
  currentFilters: Filter[],
  input: EventFilters,
) => {
  if (typeof input !== 'undefined' && input !== null) {
    if (input.regionId !== null && input.regionId.length > 0) {
      currentFilters.push({
        field: 'regionId',
        values: input.regionId,
      });
    }
    if (input.buildingId !== null && input.buildingId.length > 0) {
      currentFilters.push({
        field: 'buildingId',
        values: input.buildingId,
      });
    }
    if (input.controlSystemId !== null && input.controlSystemId.length > 0) {
      currentFilters.push({
        field: 'controlSystemId',
        values: input.controlSystemId,
      });
    }
    if (input.deviceId !== null && input.deviceId.length > 0) {
      currentFilters.push({
        field: 'deviceId',
        values: input.deviceId,
      });
    }
    if (input.severity !== null && input.severity.length > 0) {
      currentFilters.push({
        field: 'severity',
        values: input.severity,
      });
    }
    if (input.sourceSystem !== null && input.sourceSystem.length > 0) {
      currentFilters.push({
        field: 'sourceSystem',
        values: input.sourceSystem,
      });
    }
    if (input.sourceType !== null && input.sourceType.length > 0) {
      currentFilters.push({
        field: 'sourceType',
        values: input.sourceType,
      });
    }
    if (input.eventType !== null && input.eventType.length > 0) {
      currentFilters.push({
        field: 'eventType',
        values: input.eventType,
      });
    }
    if (input.status !== null && input.status.length > 0) {
      currentFilters.push({
        field: 'status',
        values: input.status,
      });
    }
    if (input.reference !== null && input.reference.length > 0) {
      currentFilters.push({
        field: 'key',
        values: input.reference,
      });
    }
  }

  return currentFilters;
};

export const getSeverityFilter = () => {
  return [
    {
      text: 'Critical',
      value: '5',
    },
    {
      text: 'Major',
      value: '4',
    },
    {
      text: 'Minor',
      value: '3',
    },
    {
      text: 'Warning',
      value: '2',
    },
    {
      text: 'Information',
      value: '1',
    },
    {
      text: 'OK',
      value: '0',
    },
    {
      text: 'Unknown',
      value: '-1',
    },
  ];
};

export const getDeviceFilter = (filterOptions: EventFilterOptions) => {
  if (filterOptions === null) {
    return [];
  }

  return filterOptions.device
    .sort((compA, compB) => sortStringAscending(compA.name, compB.name))
    .map(dev => {
      return {
        text: dev.name,
        value: dev.value,
      };
    });
};

export const getBuildingFilter = (filterOptions: EventFilterOptions) => {
  if (filterOptions === null) {
    return [];
  }

  return filterOptions.building
    .sort((compA, compB) => sortStringAscending(compA.name, compB.name))
    .map(dev => {
      return {
        text: dev.name,
        value: dev.value,
      };
    });
};

export const getStatusFilter = (filterOptions: EventFilterOptions) => {
  if (filterOptions === null) {
    return [];
  }

  return filterOptions.status
    .sort((compA, compB) => sortStringAscending(compA.name, compB.name))
    .map(sts => {
      return {
        text: sts.name,
        value: sts.value,
      };
    });
};

export const getSourceTypeFilter = (filterOptions: EventFilterOptions) => {
  if (filterOptions === null) {
    return [];
  }

  return filterOptions.sourceType
    .sort((compA, compB) => sortStringAscending(compA.name, compB.name))
    .map(sts => {
      return {
        text: sts.name,
        value: sts.value,
      };
    });
};

export const getEventTypeFilter = (filterOptions: EventFilterOptions) => {
  if (filterOptions === null) {
    return [];
  }

  return filterOptions.eventType
    .sort((compA, compB) => sortStringAscending(compA.name, compB.name))
    .map(sts => {
      return {
        text: sts.name,
        value: sts.value,
      };
    });
};
