import {
  User,
  UserFilters,
  UsersConnectionInput,
} from '@totem/components/users/container/types';
import { Filter } from '@totem/types/common';

export const getRoleString = (role: number) => {
  switch (role) {
    case -1:
      return 'Vendor';
    case 0:
      return 'Collaborator';
    case 1:
      return 'Team Member';
    case 2:
      return 'Administrator';
    case 3:
      return 'System Administrator';
    default:
      return '-';
  }
};

export const getRoleFilter = () => {
  return [
    {
      text: 'Vendor',
      value: '-1',
    },
    {
      text: 'Collaborator',
      value: '0',
    },
    {
      text: 'Team Member',
      value: '1',
    },
    {
      text: 'Administrator',
      value: '2',
    },
    {
      text: 'System Administrator',
      value: '3',
    },
  ];
};

export const getSiteAccessString = (user: User) => {
  if (user.organization.deactivatedAt !== null) {
    return 'No Access (Removed)';
  }
  if (user.organization.isActive === false) {
    return 'No Access';
  }
  if (user.lastLoginDate === null) {
    return 'Access Granted';
  }

  return 'Has Access';
};

export const buildUserFilters = (input: UsersConnectionInput) => {
  const paramFilters: Filter[] = [];

  if (input.role !== null && input.role.length > 0) {
    paramFilters.push({
      field: 'organization.role',
      values: input.role,
    });
  }

  if (
    typeof input.tabGroup !== 'undefined' &&
    input.tabGroup !== null &&
    input.tabGroup !== ''
  ) {
    paramFilters.push({
      field: 'tabGroup',
      values: [input.tabGroup],
    });
  }

  if (
    typeof input.searchEmail !== 'undefined' &&
    input.searchEmail !== null &&
    input.searchEmail !== ''
  ) {
    paramFilters.push({
      field: 'email',
      operator: 'cscontains',
      values: [input.searchEmail],
    });
  }

  if (
    typeof input.searchFirstName !== 'undefined' &&
    input.searchFirstName !== null &&
    input.searchFirstName !== ''
  ) {
    paramFilters.push({
      field: 'firstName',
      operator: 'cscontains',
      values: [input.searchFirstName],
    });
  }

  if (
    typeof input.searchLastName !== 'undefined' &&
    input.searchLastName !== null &&
    input.searchLastName !== ''
  ) {
    paramFilters.push({
      field: 'lastName',
      operator: 'cscontains',
      values: [input.searchLastName],
    });
  }

  return paramFilters;
};

export const addUserFilters = (
  currentFilters: Filter[],
  input: UserFilters,
) => {
  if (typeof input !== 'undefined' && input !== null) {
    if (
      typeof input.role !== 'undefined' &&
      input.role !== null &&
      input.role.length > 0
    ) {
      currentFilters.push({
        field: 'organization.role',
        values: input.role,
      });
    }

    if (
      typeof input.tabGroup !== 'undefined' &&
      input.tabGroup !== null &&
      input.tabGroup !== ''
    ) {
      currentFilters.push({
        field: 'tabGroup',
        values: [input.tabGroup],
      });
    }

    if (
      typeof input.searchEmail !== 'undefined' &&
      input.searchEmail !== null &&
      input.searchEmail !== ''
    ) {
      currentFilters.push({
        field: 'email',
        operator: 'cscontains',
        values: [input.searchEmail],
      });
    }

    if (
      typeof input.searchFirstName !== 'undefined' &&
      input.searchFirstName !== null &&
      input.searchFirstName !== ''
    ) {
      currentFilters.push({
        field: 'firstName',
        operator: 'cscontains',
        values: [input.searchFirstName],
      });
    }

    if (
      typeof input.searchLastName !== 'undefined' &&
      input.searchLastName !== null &&
      input.searchLastName !== ''
    ) {
      currentFilters.push({
        field: 'lastName',
        operator: 'cscontains',
        values: [input.searchLastName],
      });
    }
  }

  return currentFilters;
};
