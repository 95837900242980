import { createContext } from 'react';

import { APIKeysConnectionInput } from '@totem/types/apiKey';
import { ControlSystem } from '@totem/types/controlSystem';
import { ControlSystemBackupsConnectionInput } from '@totem/types/controlSystemBackup';

export interface Context {
  refetch: () => Promise<any>;
  controlSystem: ControlSystem;
  loading: boolean;
  apiKeysInput: APIKeysConnectionInput;
  setApiKeysInput: (input: APIKeysConnectionInput) => void;
  backupsInput: ControlSystemBackupsConnectionInput;
  setBackupsInput: (input: ControlSystemBackupsConnectionInput) => void;
}

const initialContext: Context = {
  refetch: () => Promise.resolve({}),
  controlSystem: null,
  loading: false,
  apiKeysInput: { limit: 10, offset: 0 },
  setApiKeysInput: () => {},
  backupsInput: { limit: 10, offset: 0 },
  setBackupsInput: () => {},
};

export default createContext<Context>(initialContext);
