import React, { useContext, useState } from 'react';
import { SendOutlined } from '@ant-design/icons';
import { Button } from 'antd';

import LaunchDialog from '@totem/components/surveyV2/instances/LaunchDialog';
import SurveyInstanceContext from '@totem/components/surveyV2/instances/SurveyInstancesContext';
import { isNotNull } from '@totem/utilities/common';
const LaunchButton = () => {
  const [showLaunchDialog, setShowLaunchDialog] = useState<boolean>(false);
  const { selectedForLaunch } = useContext(SurveyInstanceContext);

  return (
    <>
      {isNotNull(selectedForLaunch) && selectedForLaunch.length > 0 && (
        <Button
          type="primary"
          icon={<SendOutlined />}
          onClick={() => setShowLaunchDialog(true)}
        >
          Launch
        </Button>
      )}
      {showLaunchDialog && (
        <LaunchDialog
          open={showLaunchDialog}
          onCancel={() => setShowLaunchDialog(false)}
        />
      )}
    </>
  );
};

export default LaunchButton;
