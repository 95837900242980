import React from 'react';
import { Avatar } from 'antd';
import dayjs from 'dayjs';

import Tooltip from '@totem/components/common/tooltip/Tooltip';
import { SurveyQuestion } from '@totem/components/surveyV2/types';

import '../survey.css';

const styles = {
  avatar: {
    marginRight: '1.6rem',
  },
};

interface AvatarProps {
  size: number | 'small' | 'large' | 'default';
  style: any;
  children?: any;
  src?: string;
}

type Props = {
  question: SurveyQuestion;
};

const AnsweredBy = ({ question }: Props) => {
  const { updatedAt, updatedBy } = question;

  const isDefaultImage = updatedBy
    ? !updatedBy.imageUrl || updatedBy.imageUrl.includes('cdn.auth0.com')
    : true;

  const anonymousName = 'Anonymous User';
  const name = updatedBy?.name || anonymousName;

  const firstLetter = name.split(' ')[0][0];
  const secondLetter = name.split(' ')[1][0];
  const title = `${firstLetter}${secondLetter}`;

  const avatarProps: AvatarProps = {
    size: 'large',
    style: styles.avatar,
    ...(isDefaultImage && { children: title }),
    ...(!isDefaultImage && { src: updatedBy.imageUrl }),
  };

  return (
    <div>
      <div styleName="answered-by">Answered By</div>
      <div styleName="user">
        <Tooltip title={name} anchored>
          <Avatar {...avatarProps} />
        </Tooltip>
        <div styleName="date">
          {`${dayjs(updatedAt).format('MMM D, YYYY  h:mm A')}`}
        </div>
      </div>
    </div>
  );
};

export default AnsweredBy;
