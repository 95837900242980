import React, { ReactNode, useCallback, useEffect, useState } from 'react';

import SurveyContext from '@totem/components/surveyV2/instanceDetail/SurveyInstanceContext';
import {
  QuestionValue,
  RequestSurveyInstanceQuestionAnswer,
  SurveyError,
  SurveyQueryResult,
} from '@totem/components/surveyV2/types';
import { getToken } from '@totem/utilities/accountUtilities';
import { V2_SURVEY_ENDPOINT } from '@totem/utilities/endpoints';

type Props = {
  surveyInstanceId: string;
  children?: ReactNode;
};
const SurveyInstanceContainer = ({ surveyInstanceId, children }: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [refresh, setRefresh] = useState<boolean>(true);
  const [data, setData] = useState<SurveyQueryResult>(null);
  const [errorAlertVisible, setErrorAlertVisible] = useState<boolean>(false);
  const [errors, setErrors] = useState<SurveyError[]>([]);
  const [filterAnswered, setFilterAnswered] = useState<boolean>(false);

  useEffect(() => {
    if (refresh) {
      setRefresh(false);
      setIsLoading(true);

      fetch(`${V2_SURVEY_ENDPOINT}/instance/${surveyInstanceId}`, {
        method: 'GET',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
      })
        .then((res) => res.json())
        .then((result: SurveyQueryResult) => {
          setData(result);
        })
        .then(() => {
          setIsLoading(false);
        });
    }
  }, [surveyInstanceId, refresh]);

  const sendQuestionAnswer = useCallback(
    async (
      surveyId: string,
      categoryId: string,
      questionId: string,
      newQuestionValue: QuestionValue,
      newAdditionalContext: string,
    ) => {
      const request: RequestSurveyInstanceQuestionAnswer = {
        surveyInstanceId: surveyId,
        categoryId,
        questionId,
        answer: newQuestionValue,
        additionalContext: newAdditionalContext,
      };

      const payload = JSON.stringify(request);

      fetch(`${V2_SURVEY_ENDPOINT}/instance/question`, {
        method: 'POST',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
        body: JSON.stringify(payload),
      })
        .then((res) => res.json())
        .then((result: SurveyQueryResult) => {
          setData(result);
        })
        .then(() => {
          setIsLoading(false);
        });
    },
    [],
  );

  const handleOnAction = (newAction: string) => {
    switch (newAction) {
      case 'refresh':
        setRefresh(true);
        break;
      default:
        break;
    }
  };

  const updateQuestionAnswer = (
    categoryId: string,
    questionId: string,
    newQuestionValue: QuestionValue,
    newAdditionalContext: string,
  ) => {
    sendQuestionAnswer(
      surveyInstanceId,
      categoryId,
      questionId,
      newQuestionValue,
      newAdditionalContext,
    );
  };

  return (
    <SurveyContext.Provider
      value={{
        surveyInstanceId,
        loading: isLoading,
        setIsLoading,
        data,
        setData,
        updateQuestionAnswer,
        errorAlertVisible,
        setErrorAlertVisible,
        errors,
        setErrors,
        filterAnswered,
        setFilterAnswered,
        onAction: handleOnAction,
      }}
    >
      {children}
    </SurveyContext.Provider>
  );
};

export default SurveyInstanceContainer;
