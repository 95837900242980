import React, { useContext } from 'react';

import FilterChip from '@totem/components/common/FilterChip';
import { EMPTY_ID } from '@totem/components/common/reference/ReferenceSelector';
import DeviceContext from '@totem/components/devices/devicesContainer/deviceContainerContext';
import { DevicesConnectionInput } from '@totem/types/devices';

import '../devices.css';
const styles = {
  filterChip: {
    marginLeft: '2rem',
    marginTop: '2rem',
  },
};

const DevicesFilterChips = () => {
  const { input, setInput, filterOptions } = useContext(DeviceContext);
  const handleMultiFilterDelete = (
    property: keyof DevicesConnectionInput,
    value: string,
  ) => {
    // @ts-ignore
    const updatedValue = input[property].filter((val) => val !== value);
    setInput({
      ...input,
      [property]: updatedValue.length ? updatedValue : null,
    });
  };

  const GetOptionNameFromValue = (option: string, value: string) => {
    switch (option) {
      case 'Building':
        for (let idx = 0; idx < filterOptions.building.length; idx++) {
          if (filterOptions.building[idx].value === value) {
            return filterOptions.building[idx].name;
          }
        }
        break;
      case 'Region':
        for (let idx = 0; idx < filterOptions.region.length; idx++) {
          if (filterOptions.region[idx].value === value) {
            return filterOptions.region[idx].name;
          }
        }
        break;
      default:
        return '';
    }
    return '';
  };

  return (
    <>
      {input.vendor &&
        input.vendor.map((option) => {
          return (
            <FilterChip
              key={option}
              label={`Vendor: ${option}`}
              style={styles.filterChip}
              onClose={() => handleMultiFilterDelete('vendor', option)}
            />
          );
        })}
      {input.type &&
        input.type.map((option) => {
          return (
            <FilterChip
              key={option}
              label={`Type: ${option}`}
              style={styles.filterChip}
              onClose={() => handleMultiFilterDelete('type', option)}
            />
          );
        })}
      {input.operatingSystem &&
        input.operatingSystem.map((option) => {
          return (
            <FilterChip
              key={option}
              label={`OS: ${option}`}
              style={styles.filterChip}
              onClose={() => handleMultiFilterDelete('operatingSystem', option)}
            />
          );
        })}
      {input.regionId &&
        input.regionId.map((option) => {
          return (
            <FilterChip
              key={option}
              label={`Device: ${GetOptionNameFromValue('Region', option)}`}
              style={styles.filterChip}
              onClose={() => handleMultiFilterDelete('regionId', option)}
            />
          );
        })}
      {input.buildingId &&
        input.buildingId.map((option) => {
          return option === EMPTY_ID ? (
            <FilterChip
              key={option}
              label={`Building: Unassigned`}
              style={styles.filterChip}
              onClose={() => handleMultiFilterDelete('buildingId', option)}
            />
          ) : (
            <FilterChip
              key={option}
              label={`Building: ${GetOptionNameFromValue('Building', option)}`}
              style={styles.filterChip}
              onClose={() => handleMultiFilterDelete('buildingId', option)}
            />
          );
        })}
      {input.noBackup &&
        input.noBackup.map((option) => {
          return (
            <FilterChip
              key={option}
              label={`No Backup`}
              style={styles.filterChip}
              onClose={() => handleMultiFilterDelete('noBackup', option)}
            />
          );
        })}
      {input.staleBackups &&
        input.staleBackups.map((option) => {
          return (
            <FilterChip
              key={option}
              label={`Stale Backup`}
              style={styles.filterChip}
              onClose={() => handleMultiFilterDelete('staleBackups', option)}
            />
          );
        })}
      {input.noAntiVirus &&
        input.noAntiVirus.map((option) => {
          return (
            <FilterChip
              key={option}
              label={`No Anti-Virus`}
              style={styles.filterChip}
              onClose={() => handleMultiFilterDelete('noAntiVirus', option)}
            />
          );
        })}
      {input.incompleteDeviceType &&
        input.incompleteDeviceType.map((option) => {
          return (
            <FilterChip
              key={option}
              label={`Incomplete Device Type`}
              style={styles.filterChip}
              onClose={() =>
                handleMultiFilterDelete('incompleteDeviceType', option)
              }
            />
          );
        })}

      {input.operatingSystemPastEndOfSupport &&
        input.operatingSystemPastEndOfSupport.map((option) => {
          return (
            <FilterChip
              key={option}
              label={`OS Past End-of-Support`}
              style={styles.filterChip}
              onClose={() =>
                handleMultiFilterDelete(
                  'operatingSystemPastEndOfSupport',
                  option,
                )
              }
            />
          );
        })}
      {input.operatingSystemApproachingEndOfSupport &&
        input.operatingSystemApproachingEndOfSupport.map((option) => {
          return (
            <FilterChip
              key={option}
              label={`OS Near End-of-Support`}
              style={styles.filterChip}
              onClose={() =>
                handleMultiFilterDelete(
                  'operatingSystemApproachingEndOfSupport',
                  option,
                )
              }
            />
          );
        })}
      {input.lowDiskSpace &&
        input.lowDiskSpace.map((option) => {
          return (
            <FilterChip
              key={option}
              label={`Low Disk Space`}
              style={styles.filterChip}
              onClose={() => handleMultiFilterDelete('lowDiskSpace', option)}
            />
          );
        })}
      {input.isManaged &&
        input.isManaged.map((option) => {
          if (option === 'ACTIVE') {
            return (
              <FilterChip
                key={option}
                label={`Is Managed`}
                style={styles.filterChip}
                onClose={() => handleMultiFilterDelete('isManaged', option)}
              />
            );
          }
          return (
            <FilterChip
              key={option}
              label={`Is Not Managed`}
              style={styles.filterChip}
              onClose={() => handleMultiFilterDelete('isManaged', option)}
            />
          );
        })}
    </>
  );
};

export default DevicesFilterChips;
