import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb } from 'antd';

import { Breadcrumb as BreadcrumbType } from '@totem/types/breadcrumb';
import { SubtitleLink } from '@totem/types/route';

import { useRoute } from './RouteContext';

import './contentLayout.css';

export interface ContentLayoutProps {
  /**
   * Content to render in the main content section
   */
  children: ReactNode;
  /**
   * Main title of the page. If truthy, overrides the default route page
   * title.
   */
  pageTitle?: string | ReactNode;
  /**
   * Optional subtitle to render under the main page title
   * Most pages follow the pattern of Region | Building | X
   * If the prop value is an array of SubtitleLink this will be constructed
   * Else the given ReactNode will simply be rendered as is
   */
  pageSubtitle?: SubtitleLink[] | ReactNode;
  /**
   * Content to render on the right side of the header opposite of the
   * page title
   */
  contentRight?: ReactNode;
  /**
   * Content to render as the entire page header. If truthy, pageTitle
   * and contentRight props will be ignored.
   */
  pageHeader?: ReactNode;
  /**
   * Optional function to override default route breadcrumbs.
   * The function will pass default route breadcrumbs as an argument.
   */
  breadcrumbs?: (breadcrumbs: BreadcrumbType[]) => BreadcrumbType[];
  /**
   * Optional styles to be applied to the main content container
   */
  contentStyle?: React.CSSProperties;
}

const getBreadcrumbItems = (breadcrumbs: BreadcrumbType[]) => {
  return breadcrumbs.map(({ label, link }) => {
    return {
      title: link ? <Link to={link}>{label}</Link> : label,
    };
  });
};

const renderBreadcrumbs = (breadcrumbs: BreadcrumbType[]) => {
  return breadcrumbs ? (
    <Breadcrumb
      style={{ margin: '16px 0 16px 30px' }}
      items={getBreadcrumbItems(breadcrumbs)}
    />
  ) : null;
};

const isSubtitleLinkArray = (
  subtitle: SubtitleLink[] | React.ReactNode,
): subtitle is SubtitleLink[] => {
  // @ts-ignore
  return Array.isArray(subtitle);
};

const renderSubtitle = (subtitle: SubtitleLink[] | ReactNode) => {
  return isSubtitleLinkArray(subtitle) ? (
    subtitle.map(({ label, link }, index) => (
      <React.Fragment key={index}>
        {!!index && <div styleName="subtitle separator"> | </div>}
        {link ? (
          <Link to={link}>
            <div styleName="subtitle">{label}</div>
          </Link>
        ) : (
          <div styleName="subtitle">{label}</div>
        )}
      </React.Fragment>
    ))
  ) : (
    <div styleName="subtitle">{subtitle}</div>
  );
};

const ContentLayout = ({
  children,
  pageTitle,
  pageSubtitle,
  contentRight,
  pageHeader,
  breadcrumbs,
  contentStyle,
}: ContentLayoutProps) => {
  const route = useRoute();

  return (
    <div styleName="container">
      <div>
        {renderBreadcrumbs(
          breadcrumbs ? breadcrumbs(route.breadcrumbs) : route.breadcrumbs,
        )}
      </div>
      <div>
        {pageHeader || (
          <div styleName="header">
            <div styleName="title-container">
              <div styleName="title">{pageTitle || route.pageTitle}</div>
              {pageSubtitle && (
                <div styleName="subtitle-container">
                  {renderSubtitle(pageSubtitle)}
                </div>
              )}
            </div>
            {contentRight}
          </div>
        )}
      </div>
      <div styleName="component-container" style={contentStyle}>
        {children}
      </div>
    </div>
  );
};

export default ContentLayout;
