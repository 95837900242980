import React from 'react';
import { Badge } from 'antd';

import colors from '@totem/styles/colors';

type Props = {
  count: number;
};

const RecordCountBadge = ({ count }: Props) => {
  return (
    <Badge
      title="Record Count"
      count={count}
      style={{ backgroundColor: colors.branding.accent }}
      overflowCount={1000000}
    />
  );
};

export default RecordCountBadge;
