import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { SnippetsOutlined } from '@ant-design/icons';

import DetailSpinner from '@totem/components/common/DetailSpinner';
import { EMPTY_ID } from '@totem/components/common/referencesModal/ReferencesModal';
import colors from '@totem/styles/colors';
import { PieChartData } from '@totem/types/charts';
import { Filter, Params } from '@totem/types/common';
import { EventSeverityValue } from '@totem/types/events';
import { getToken } from '@totem/utilities/accountUtilities';
import { EVENTS_ENDPOINT } from '@totem/utilities/endpoints';

import '../events/eventSeveritySummaryCard.css';

const styles = {
  card: {
    width: '100%',
  },
  cardHeader: {
    borderTop: `5px solid`,
    borderImage: `${colors.gradient.turquoise} 100% 0 0 0`,
  },
  cardBody: {
    padding: '2.4rem',
    paddingBottom: '0',
    borderTop: `1px solid ${colors.antd.borderGray}`,
  },
  icon: {
    fontSize: '4rem',
    color: colors.neutral.silver,
  },
  errorIcon: {
    color: colors.utility.error,
  },
};

type Props = {
  regionId?: string;
  buildingId?: string;
  controlSystemId?: string;
  chartOptions?: ApexCharts.ApexOptions;
};

const WidgetEventSummary = ({
  regionId,
  buildingId,
  controlSystemId,
  chartOptions,
}: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [chartData, setChartData] = useState<PieChartData[]>([]);
  const [eventTotal, setEventTotal] = useState<number>(0);
  const COLORS = [
    colors.event.criticality.background.unknown,
    colors.event.criticality.background.info,
    colors.event.criticality.background.warning,
    colors.event.criticality.background.minor,
    colors.event.criticality.background.major,
    colors.event.criticality.background.critical,
  ];

  const createChartData = (eventSummary: EventSeverityValue[]) => {
    let total = 0;
    const data: PieChartData[] = [
      { name: 'Unknown', value: 0 },
      { name: 'Information', value: 0 },
      { name: 'Warning', value: 0 },
      { name: 'Minor', value: 0 },
      { name: 'Major', value: 0 },
      { name: 'Critical', value: 0 },
    ];

    eventSummary.forEach((val) => {
      switch (val.severity) {
        case -1:
          data[0].value = val.count;
          total += val.count;
          break;
        case 0:
          break;
        default:
          data[val.severity].value = val.count;
          total += val.count;
          break;
      }
    });

    setEventTotal(total);
    setChartData(data);
  };

  useEffect(() => {
    const buildFilters = () => {
      const filters: Filter[] = [];

      if (
        typeof regionId !== 'undefined' &&
        regionId !== null &&
        regionId !== EMPTY_ID
      ) {
        filters.push({
          field: 'regionId',
          values: [regionId],
        });
      }
      if (
        typeof buildingId !== 'undefined' &&
        buildingId !== null &&
        buildingId !== EMPTY_ID
      ) {
        filters.push({
          field: 'buildingId',
          values: [buildingId],
        });
      }
      if (
        typeof controlSystemId !== 'undefined' &&
        controlSystemId !== null &&
        controlSystemId !== EMPTY_ID
      ) {
        filters.push({
          field: 'controlSystemId',
          values: [controlSystemId],
        });
      }

      return filters;
    };

    const buildParameters = () => {
      const params: Params = {
        paging: {
          page: 1,
          pageSize: 1,
        },
        sort: {
          field: 'lastOccurrence',
          direction: 1,
        },
        filters: buildFilters(),
      };

      return params;
    };

    setIsLoading(true);
    const params: Params = buildParameters();

    fetch(`${EVENTS_ENDPOINT}/severitySummary`, {
      method: 'POST',
      headers: new Headers({
        Authorization: `Bearer ${getToken()}`,
      }),
      body: JSON.stringify(params),
    })
      .then((res) => res.json())
      .then((result: EventSeverityValue[]) => {
        createChartData(result);
      })
      .then(() => setIsLoading(false));
  }, [regionId, buildingId, controlSystemId]);

  const getSeriesData = () => {
    const data = chartData.map((point) => point.value);
    return data;
  };

  const getLabels = () => {
    const data = chartData.map((point) => point.name);
    return data;
  };

  const getEventTotals = () => {
    if (typeof eventTotal !== 'undefined' && eventTotal !== null) {
      return `${eventTotal}`;
    }
    return `-`;
  };

  // eslint-disable-next-line id-length
  const handleClick = (e: any, chart?: any, options?: any) => {
    const dataPointIndex = options.dataPointIndex;
    if (dataPointIndex >= 0) {
      window.open(
        `/dashboard/events?severity=${dataPointIndex}&status=CREATED&status=ACKNOWLEDGED&startTime=2020-01-01T00:00:00.000Z`,
        `${dataPointIndex}`,
      );
    }
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
  };

  const options: ApexCharts.ApexOptions = {
    chart: {
      type: 'donut',
      toolbar: { show: true, tools: { download: true } },
      events: {
        dataPointSelection: handleClick,
      },
    },
    labels: getLabels(),
    colors: COLORS,
    legend: {
      show: false,
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              show: true,
              showAlways: true,
              fontSize: '14px',
              label: chartData.length > 0 ? 'Events' : '',
              formatter: getEventTotals,
            },
            value: {
              show: true,
              fontSize: '14px',
              fontWeight: 'bold',
              offsetY: -10,
            },
          },
        },
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 175,
          },
        },
      },
    ],
  };

  const getChartOptions = () => {
    if (typeof chartOptions !== 'undefined' && chartOptions !== null) {
      return { ...options, ...chartOptions };
    }
    return options;
  };

  return (
    <>
      {isLoading ? (
        <div styleName="spinner-container">
          <DetailSpinner />
        </div>
      ) : chartData.length === 0 ? (
        <div styleName="no-system-container">
          <SnippetsOutlined style={styles.icon} />
          <div styleName="no-system-subtext">No Events</div>
        </div>
      ) : (
        <div styleName="container">
          <div className="app">
            <div className="row">
              <div className="mixed-chart">
                {chartData.length > 0 && (
                  <ReactApexChart
                    options={getChartOptions()}
                    series={getSeriesData()}
                    labels={getLabels()}
                    type="donut"
                    width="90%"
                    height="300px"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default WidgetEventSummary;
