import React, { useState } from 'react';
import { DatePicker, DatePickerProps } from 'antd';

import {
  QuestionValue,
  SurveyQuestion,
} from '@totem/components/surveyV2/types';
import { ParseDate } from '@totem/utilities/dateUtilities';

const styles = {
  picker: {
    width: '100%',
  },
};

type Props = {
  question: SurveyQuestion;
  disabled: boolean;
  onChange: (value: QuestionValue) => void;
};

const DateQuestion = ({ question, disabled, onChange }: Props) => {
  const [value, setValue] = useState<QuestionValue>(question.value);

  const handleChange: DatePickerProps['onChange'] = (date) => {
    const newValue: QuestionValue = {
      value: date.toISOString(),
      valueInt: date ? date.valueOf() : 0,
      valueFloat: date ? date.valueOf() : 0,
      valueArray: [],
    };

    setValue(newValue);
    onChange(newValue);
  };

  return (
    <DatePicker
      // @ts-ignore
      value={ParseDate(value.valueInt)}
      onChange={handleChange}
      disabled={disabled}
      style={styles.picker}
      size="large"
    />
  );
};

export default DateQuestion;
