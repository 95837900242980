import React, { useContext, useEffect, useState } from 'react';
import { Card, Flex } from 'antd';

import Grade from '@totem/components/common/Grade';
import PercentageBar from '@totem/components/policyAuditDetail/PercentageBar';
import SurveyContext from '@totem/components/surveyV2/instanceDetail/SurveyInstanceContext';
import { SurveyQuestion } from '@totem/components/surveyV2/types';
import colors from '@totem/styles/colors';
import { isNotNull } from '@totem/utilities/common';
import { getExactPercentage } from '@totem/utilities/numberUtilities';

import './auditReport.css';

const styles = {
  card: {
    width: '100%',
    margin: '0rem 1.6rem 1rem 1.6rem',
  },
  cardHeader: {
    borderTop: `5px solid`,
    borderBottom: `1px solid ${colors.antd.borderGray}`,
    borderImage: `${colors.gradient.blue} 100% 0 0 0`,
  },
  cardBody: {
    borderTop: `1px solid ${colors.antd.borderGray}`,
  },
  center: {
    alignItems: 'center',
  },
  percentageContainer: {
    alignItems: 'center',
    width: '100%',
  },
};

interface LevelOfEffortScore {
  label: string;
  maxScore: number;
  totalScore: number;
}

const getMaxScore = (question: SurveyQuestion): number => {
  let maxScore = 0;

  for (let oIdx = 0; oIdx < question.options.length; oIdx++) {
    switch (question.type) {
      case 'enummultiple':
        maxScore += question.options[oIdx].score.valueFloat;
        break;
      default:
        if (question.options[oIdx].score.valueFloat > maxScore) {
          maxScore = question.options[oIdx].score.valueFloat;
        }
        break;
    }
  }

  return maxScore;
};

const ScoreByLevelOfEffort = () => {
  const { data } = useContext(SurveyContext);
  const [levelOfEffortScores, setLevelOfEffortScores] = useState<
    LevelOfEffortScore[]
  >([]);

  useEffect(() => {
    const loeScores: LevelOfEffortScore[] = [
      {
        label: 'N/A',
        maxScore: 0,
        totalScore: 0,
      },
      {
        label: 'Minimal',
        maxScore: 0,
        totalScore: 0,
      },
      {
        label: 'Moderate',
        maxScore: 0,
        totalScore: 0,
      },
      {
        label: 'Maximum',
        maxScore: 0,
        totalScore: 0,
      },
    ];

    if (isNotNull(data)) {
      for (let cIdx = 0; cIdx < data.survey.categories.length; cIdx++) {
        const category = data.survey.categories[cIdx];
        for (let qIdx = 0; qIdx < category.questions.length; qIdx++) {
          const question = category.questions[qIdx];
          // eslint-disable-next-line max-depth
          if (question.scored && question.visible) {
            loeScores[question.levelOfEffort].maxScore += getMaxScore(question);
            loeScores[question.levelOfEffort].totalScore +=
              question.value.valueFloat;
          }
        }
      }
    }

    setLevelOfEffortScores(loeScores.filter((chk) => chk.maxScore > 0));
  }, [data]);

  return (
    <Card
      bordered
      headStyle={styles.cardHeader}
      title={<div styleName="card-header-title">Score By Level Of Effort</div>}
      style={styles.card}
    >
      {levelOfEffortScores.map((loe) => (
        <Flex
          justify={'flex-start'}
          align={'flex-start'}
          style={styles.percentageContainer}
          key={loe.label}
        >
          <div styleName="loe-score">{loe.label}</div>
          <PercentageBar
            score={getExactPercentage(loe.maxScore, loe.totalScore)}
          />
          <Grade
            score={getExactPercentage(loe.maxScore, loe.totalScore)}
            size="small"
          />
        </Flex>
      ))}
    </Card>
  );
};

export default ScoreByLevelOfEffort;
