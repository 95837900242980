import React, { useEffect, useState } from 'react';
import { Button, Form, Modal, Select } from 'antd';

import { User } from '@totem/components/users/container/types';
import { OrganizationHierarchy } from '@totem/types/organization';
import { EligibleSecurityGroup } from '@totem/types/user';
import { getRegions } from '@totem/utilities/organization';
import { emptyId } from '@totem/utilities/userUtilities';
const { Option } = Select;

const FormItem = Form.Item;

import ModalFooter from '@totem/components/ModalFooter';
import ModalTitle from '@totem/components/ModalTitle';

export interface Props {
  user: User;
  orgHierarchy: OrganizationHierarchy;
  eligibleGroups: EligibleSecurityGroup[];
  visible: boolean;
  onClose: () => void;
  onSubmit: (
    securityGroup: EligibleSecurityGroup,
    regionId: string,
    buildingId: string,
    controlSystemId: string,
  ) => void;
}
const styles = {
  form: {
    width: '100%',
  },
  formItem: {
    paddingBottom: '0',
    marginBottom: '1rem',
  },
};

const SecurityGroupAddModal = ({
  orgHierarchy,
  eligibleGroups,
  visible,
  onClose,
  onSubmit,
}: Props) => {
  const [securityGroups, setSecurityGroups] = useState<EligibleSecurityGroup[]>(
    [],
  );
  const [regions, setRegions] = useState<OrganizationHierarchy[]>([]);
  const [buildings, setBuildings] = useState<OrganizationHierarchy[]>([]);
  const [controlSystems, setControlSystems] = useState<OrganizationHierarchy[]>(
    [],
  );

  const [selectedGroup, setSelectedGroup] = useState<string>('');
  const [selectedRegion, setSelectedRegion] =
    useState<OrganizationHierarchy>(null);
  const [selectedBuilding, setSelectedBuilding] =
    useState<OrganizationHierarchy>(null);
  const [selectedControlSystem, setSelectedControlSystem] =
    useState<OrganizationHierarchy>(null);

  useEffect(() => {
    setSecurityGroups(
      eligibleGroups.sort((option1, option2) => {
        if (option1.name < option2.name) {
          return -1;
        }
        if (option1.name > option2.name) {
          return 1;
        }
        return 0;
      }),
    );
  }, [eligibleGroups]);

  useEffect(() => {
    const regionOptions = getRegions(orgHierarchy);
    if (regionOptions !== null) {
      setRegions(
        regionOptions.sort((option1, option2) => {
          if (option1.name < option2.name) {
            return -1;
          }
          if (option1.name > option2.name) {
            return 1;
          }
          return 0;
        }),
      );
    } else {
      setRegions([]);
    }
  }, [orgHierarchy]);

  const handleSecurityGroupChanged = (securityGroup: string) => {
    setSelectedGroup(securityGroup);
  };

  const handleRegionChanged = (regionId: string) => {
    if (regionId === '') {
      setSelectedRegion(null);
      setSelectedBuilding(null);
      setSelectedControlSystem(null);
    } else {
      const foundRegion = regions.find((region) => region.id === regionId);
      setSelectedRegion(foundRegion);
      setSelectedBuilding(null);
      setSelectedControlSystem(null);
      setBuildings(
        foundRegion.children.sort((option1, option2) => {
          if (option1.name < option2.name) {
            return -1;
          }
          if (option1.name > option2.name) {
            return 1;
          }
          return 0;
        }),
      );
      setControlSystems(null);
    }
  };

  const handleBuildingChanged = (buildingId: string) => {
    if (buildingId === '') {
      setSelectedBuilding(null);
      setSelectedControlSystem(null);
    } else {
      const foundBuilding = buildings.find(
        (building) => building.id === buildingId,
      );
      setSelectedBuilding(foundBuilding);
      setSelectedControlSystem(null);
      setControlSystems(
        foundBuilding.children.sort((option1, option2) => {
          if (option1.name < option2.name) {
            return -1;
          }
          if (option1.name > option2.name) {
            return 1;
          }
          return 0;
        }),
      );
    }
  };
  const handleControlSystemChanged = (controlSystemId: string) => {
    if (controlSystemId === '') {
      setSelectedControlSystem(null);
    } else {
      const foundControlSystem = controlSystems.find(
        (controlSystem) => controlSystem.id === controlSystemId,
      );
      setSelectedControlSystem(foundControlSystem);
    }
  };

  const handleSubmit = () => {
    let regionId = emptyId;
    if (selectedRegion !== null) {
      regionId = selectedRegion.id;
    }
    let buildingId = emptyId;
    if (selectedBuilding !== null) {
      buildingId = selectedBuilding.id;
    }
    let controlSystemId = emptyId;
    if (selectedControlSystem !== null) {
      controlSystemId = selectedControlSystem.id;
    }
    const foundGroup = securityGroups.find((grp) => grp.name === selectedGroup);
    if (typeof foundGroup !== 'undefined') {
      onSubmit(foundGroup, regionId, buildingId, controlSystemId);
    }
  };

  return (
    <Modal
      open={visible}
      onCancel={onClose}
      title={<ModalTitle>Add Security Group</ModalTitle>}
      okText="Add"
      confirmLoading={false}
      footer={
        <ModalFooter>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={handleSubmit} loading={false} type="primary">
            Add
          </Button>
        </ModalFooter>
      }
    >
      <Form layout="vertical" style={styles.form}>
        <FormItem label="Security Group" colon={false} style={styles.formItem}>
          <Select
            onChange={handleSecurityGroupChanged}
            defaultValue={selectedGroup}
            style={{ width: '100%' }}
          >
            <Option key="*" value="">
              --Select--
            </Option>
            {securityGroups !== null &&
              securityGroups.map((group) => (
                <Option key={group.id} value={group.name}>
                  {group.name}
                </Option>
              ))}
          </Select>
        </FormItem>
        <FormItem label="Region" colon={false} style={styles.formItem}>
          <Select
            onChange={handleRegionChanged}
            defaultValue={selectedRegion !== null ? selectedRegion.id : ''}
            style={{ width: '100%' }}
          >
            <Option key="*" value="">
              All
            </Option>
            {regions !== null &&
              regions.map((region) => (
                <Option key={region.id} value={region.id}>
                  {region.name}
                </Option>
              ))}
          </Select>
        </FormItem>
        <FormItem label="Building" colon={false} style={styles.formItem}>
          <Select
            onChange={handleBuildingChanged}
            defaultValue={selectedBuilding !== null ? selectedBuilding.id : ''}
            style={{ width: '100%' }}
          >
            <Option key="*" value="">
              All
            </Option>
            {buildings !== null &&
              buildings.map((building) => (
                <Option key={building.id} value={building.id}>
                  {building.name}
                </Option>
              ))}
          </Select>
        </FormItem>
        <FormItem label="Control System" colon={false} style={styles.formItem}>
          <Select
            onChange={handleControlSystemChanged}
            defaultValue={
              selectedControlSystem !== null ? selectedControlSystem.id : ''
            }
            style={{ width: '100%' }}
          >
            <Option key="*" value="">
              All
            </Option>
            {controlSystems !== null &&
              controlSystems.map((controlSystem) => (
                <Option key={controlSystem.id} value={controlSystem.id}>
                  {controlSystem.name}
                </Option>
              ))}
          </Select>
        </FormItem>
      </Form>
    </Modal>
  );
};

export default SecurityGroupAddModal;
