import actionTypes from './actionTypes';

export const requestMedia = data => ({
  type: actionTypes.REQUEST_MEDIA,
  data,
});

export const getMediaSuccess = data => ({
  type: actionTypes.GET_MEDIA_SUCCESS,
  data,
});

export const getMediaFailure = () => ({
  type: actionTypes.GET_MEDIA_FAILURE,
});
