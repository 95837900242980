import { createContext } from 'react';

import {
  Finding,
  FindingsInput,
} from '@totem/components/surveyV2/findings/types';

interface Context {
  input: FindingsInput;
  setInput: (input: FindingsInput) => void;
  data: Finding[];
  filteredData: Finding[];
  loading: boolean;
  setIsLoading: (isLoading: boolean) => void;
}

export default createContext<Context>({
  input: {},
  setInput: () => {},
  data: null,
  filteredData: null,
  loading: false,
  setIsLoading: () => {},
});
