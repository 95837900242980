import React, { useCallback, useContext, useState } from 'react';
import { Button, DatePicker, Form, Input, Modal } from 'antd';
const FormItem = Form.Item;

import ModalFooter from '@totem/components/ModalFooter';
import ModalTitle from '@totem/components/ModalTitle';
import UserProfileContext from '@totem/components/UserProfileContext';
import { Contract } from '@totem/types/contracts';
import { MIN_DATE_TIME_STRING } from '@totem/types/ticketing';
import { getToken } from '@totem/utilities/accountUtilities';
import { ParseStringDate } from '@totem/utilities/dateUtilities';
import { CONTRACTS_ENDPOINT } from '@totem/utilities/endpoints';

const styles = {
  form: {
    width: '100%',
  },
  formItem: {
    paddingBottom: '0',
    marginBottom: '1rem',
  },
};

export interface Props {
  visible: boolean;
  contract?: Contract;
  onContractChanged?: (contract: Contract) => void;
  onClose: () => void;
}

const ContractEditModal = ({
  visible,
  onClose,
  contract,
  onContractChanged,
}: Props) => {
  const { userProfile } = useContext(UserProfileContext);

  const defaultContract: Contract = {
    id: '',
    number: '',
    description: '',
    organizationId: userProfile.organization.id,
    contractStart: MIN_DATE_TIME_STRING,
    contractEnd: MIN_DATE_TIME_STRING,
    status: 'Inactive',
    keys: [],
    billingContainers: [],
    attributes: [],
  };

  const [isSending, setIsSending] = useState(false);
  const [contractData, setContractData] = useState<Contract>(
    typeof contract !== 'undefined' && contract !== null
      ? contract
      : defaultContract,
  );

  const saveContract = useCallback(async (contractDataToSend) => {
    if (isSending) {
      return;
    }
    setIsSending(true);

    fetch(`${CONTRACTS_ENDPOINT}`, {
      method: 'PUT',
      headers: new Headers({
        Authorization: `Bearer ${getToken()}`,
      }),
      body: JSON.stringify(contractDataToSend),
    })
      .then((res) => res.json())
      .then((result: Contract) => {
        if (
          typeof onContractChanged !== 'undefined' &&
          onContractChanged !== null
        ) {
          onContractChanged(result);
        } else {
          onClose();
        }
      })
      .then(() => {
        setIsSending(false);
      });
  }, []);

  const handleStaticChange = (name: string, value: string) => {
    setContractData({ ...contractData, [name]: value });
  };

  const handleChange = ({
    target: { value, name },
  }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setContractData({ ...contractData, [name]: value });
  };

  const handleSubmit = () => {
    saveContract(contractData);
  };

  return (
    <Modal
      open={visible}
      onCancel={() => onClose()}
      title={<ModalTitle>Contract Header</ModalTitle>}
      confirmLoading={false}
      width={'40%'}
      footer={
        <ModalFooter>
          <Button onClick={() => onClose()}>Close</Button>
          <Button onClick={handleSubmit} type="primary">
            Submit
          </Button>
        </ModalFooter>
      }
    >
      <Form layout="vertical" style={styles.form}>
        <FormItem
          label="Contract Number"
          colon={false}
          style={styles.formItem}
          required
        >
          <Input
            name="number"
            type="text"
            value={contractData.number}
            onChange={handleChange}
            maxLength={50}
          />
        </FormItem>
        <FormItem
          label="Description"
          colon={false}
          style={styles.formItem}
          required
        >
          <Input
            name="description"
            type="text"
            value={contractData.description}
            onChange={handleChange}
            maxLength={50}
          />
        </FormItem>
        <FormItem
          label="Contract Start"
          colon={false}
          style={styles.formItem}
          required
        >
          <DatePicker
            name="contractStart"
            // @ts-ignore
            value={ParseStringDate(contractData.contractStart)}
            format="L"
            onChange={(date) =>
              handleStaticChange('contractStart', date.toISOString())
            }
          />
        </FormItem>
        <FormItem
          label="Contract End"
          colon={false}
          style={styles.formItem}
          required
        >
          <DatePicker
            name="contractEnd"
            // @ts-ignore
            value={ParseStringDate(contractData.contractEnd)}
            format="L"
            onChange={(date) =>
              handleStaticChange('contractEnd', date.toISOString())
            }
          />
        </FormItem>
      </Form>
    </Modal>
  );
};

export default ContractEditModal;
