import React from 'react';
import ReactApexChart from 'react-apexcharts';
import ReactDOMServer from 'react-dom/server';
import { ApexOptions } from 'apexcharts';
import dayjs from 'dayjs';

import { TimeDataValue } from '@totem/components/energy/types';

const styles = {
  metric: {
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: '26px',
    margin: '0px',
    color: '#6495ED',
    marginRight: '10px',
  },
  total: {
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: '26px',
    margin: '0px',
  },
  unit: {
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '12px',
    margin: 'auto 0px 0px 0.25rem',
    paddingBottom: '4px',
  },
};

type Props = {
  title: string;
  subTitle?: string;
  metric: string;
  unit?: string;
  timeSeriesData: TimeDataValue[];
  multiplier?: number;
  startDate: string;
  endDate: string;
};
const EnergyVerticalBarChart = ({
  timeSeriesData,
  title,
  subTitle,
  metric,
  unit,
  multiplier,
  startDate,
  endDate,
}: Props) => {
  const valueMultiplier =
    typeof multiplier === 'number' && multiplier !== 0 ? multiplier : 1;
  const unitText = typeof unit === 'string' && unit !== null ? unit : '';
  const getSeries = () => {
    return [
      {
        name: title,
        data: timeSeriesData.map(point => {
          return typeof point.data === 'number'
            ? { x: point.time_stamp, y: point.data * valueMultiplier }
            : { x: point.time_stamp, y: 0 };
        }),
      },
    ];
  };

  const options: ApexOptions = {
    chart: {
      type: 'bar',
      toolbar: {
        show: true,
        tools: {
          download: true,
          selection: false,
          pan: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
        },
      },
      zoom: { enabled: true },
      selection: { enabled: true },
    },
    title: {
      text: title,
      align: 'left',
      style: {
        fontSize: '14px',
        fontWeight: 600,
        color: '#1d2939',
      },
      margin: 0,
      offsetY: 0,
    },
    subtitle: {
      text: subTitle,
      align: 'left',
      style: {
        fontSize: '12px',
        fontWeight: 400,
        color: '#626b7f',
      },
      margin: 0,
      offsetY: 20,
    },
    tooltip: {
      custom: [
        ({ dataPointIndex }) => {
          const hoverValue = timeSeriesData[dataPointIndex];
          const dateTimeValue = dayjs(hoverValue.time_stamp);
          return ReactDOMServer.renderToStaticMarkup(
            <div style={{ padding: '10px' }}>
              <span>{dateTimeValue.format('MMM D YYYY @ HH:mm A')}</span>
              <br />
              <span style={styles.metric}>{metric}:</span>
              <span style={styles.total}>
                {(Number(hoverValue.data) * multiplier).toFixed(2)}
              </span>
              <span style={styles.unit}>{unitText}</span>
            </div>,
          );
        },
      ],
    },
    dataLabels: {
      enabled: false,
    },
    legend: { show: false },
    series: getSeries(),
    colors: ['#4169E1'],
    xaxis: {
      type: 'datetime',
      min: new Date(startDate).getTime(),
      max: new Date(endDate).getTime(),
      tickPlacement: 'off',
      labels: {
        hideOverlappingLabels: true,
        datetimeUTC: true,
      },
    },
    yaxis: [
      {
        labels: {
          formatter: val => val.toFixed(2),
        },
        axisTicks: {
          show: true,
        },
        tooltip: {
          enabled: true,
        },
      },
    ],
  };

  return (
    <div className="app">
      <div className="row">
        <div className="mixed-chart">
          <ReactApexChart
            options={options}
            series={getSeries()}
            type="bar"
            width="100%"
            height="400px"
          />
        </div>
      </div>
    </div>
  );
};

export default EnergyVerticalBarChart;
