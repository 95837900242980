import React, { useContext } from 'react';
import { Checkbox } from 'antd';
import { ColumnProps } from 'antd/lib/table';

import Table from '@totem/components/common/table/Table';
import ActionMenu from '@totem/components/deviceTypes/ActionMenu';
import DeviceTypeContext from '@totem/components/deviceTypes/DeviceTypeContext';
import { DeviceType } from '@totem/components/deviceTypes/types';

const DeviceTypeTable = () => {
  const { data, loading, setSelectedDeviceType, selectedDeviceTypes } =
    useContext(DeviceTypeContext);
  const getData = () => {
    if (typeof data !== 'undefined' && data !== null) {
      return data;
    }
    return [];
  };

  const handleCheckToggle = (deviceType: DeviceType, isSelected: boolean) => {
    setSelectedDeviceType(deviceType, isSelected);
  };

  const columns: ColumnProps<DeviceType>[] = [
    {
      render: (_, record: DeviceType) => (
        <Checkbox
          checked={
            selectedDeviceTypes.findIndex((chk) => chk.name === record.name) >=
            0
          }
          onChange={(evt) => handleCheckToggle(record, evt.target.checked)}
        />
      ),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (_, record: DeviceType) => <span>{record.name}</span>,
      defaultSortOrder: 'ascend',
      sorter: (compA, compB) =>
        compA.name === compB.name ? 0 : compA.name > compB.name ? 1 : -1,
    },
    {
      title: 'Aliases',
      dataIndex: 'aliases',
      key: 'aliases',
      render: (_, record: DeviceType) => (
        <span>
          {record.aliases !== null &&
            record.aliases.map((alias, idx) =>
              idx === 0 ? (
                <span key={alias}>{alias}</span>
              ) : (
                <span key={alias}>
                  <br />
                  {alias}
                </span>
              ),
            )}
        </span>
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (_, record: DeviceType) => <ActionMenu deviceType={record} />,
    },
  ];

  return (
    <Table
      showSorterTooltip
      columns={columns}
      dataSource={getData()}
      loading={loading}
      rowKey={(record) => record.name}
      pagination={{
        total: typeof data !== 'undefined' && data !== null ? data.length : 0,
        showSizeChanger: true,
      }}
    />
  );
};

export default DeviceTypeTable;
