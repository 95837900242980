export enum HealthStatusOptions {
  ON_TRACK = 'On Track',
  AT_RISK = 'At Risk',
  OFF_TRACK = 'Off Track',
}

export type HealthStatusOptionsMap = { [key in HealthStatusOptions]: number };

export const HEALTH_STATUS_ENUM: HealthStatusOptionsMap = Object.freeze({
  [HealthStatusOptions.ON_TRACK]: 0,
  [HealthStatusOptions.AT_RISK]: 1,
  [HealthStatusOptions.OFF_TRACK]: 2,
});

export interface Health {
  id: string;
  subjectId: string;
  status: number;
  comment: string;
  createdAt: string;
  createdBy: string;
}

export interface HealthStatusCounts {
  onTrack: number;
  atRisk: number;
  offTrack: number;
  needed: number;
}

export interface HealthDTO {
  subjectId: string;
  status: number;
  comment: string;
}

export interface HealthsRequest {
  subjectId: string;
}

export interface HealthInput {
  subjectId: string;
  status: number;
  comment: string;
}
