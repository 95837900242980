import React, { useContext, useMemo, useState } from 'react';
import { ColumnProps } from 'antd/lib/table';

import Table from '@totem/components/common/table/Table';
import DeviceContext from '@totem/components/devices/deviceDetails/DeviceContext';
import { Peripheral } from '@totem/types/devices';
import { getTablePagination } from '@totem/utilities/paginationUtilities';
import { sortStringAscending } from '@totem/utilities/tableUtilities';

const PeripheralsTable = () => {
  const { deviceData, loading } = useContext(DeviceContext);
  const [limit, setLimit] = useState<number>(10);
  const [offset, setOffset] = useState<number>(0);

  const handlePagination = (pagination: any) => {
    const { current, pageSize } = pagination;
    setOffset((current - 1) * limit);
    setLimit(pageSize);
  };

  const totalCount =
    typeof deviceData !== 'undefined' &&
    deviceData !== null &&
    deviceData.device.peripherals !== null
      ? deviceData.device.peripherals.length
      : 0;

  const pagination = useMemo(() => {
    return getTablePagination({ limit, offset }, totalCount);
  }, [limit, offset, totalCount]);

  const formatDateTIme = (dateIn: string) => {
    if (dateIn !== null) {
      if (!isNaN(Date.parse(dateIn))) {
        const dateVal = new Date(dateIn);
        const formattedDate = dateVal.toLocaleDateString();
        if (formattedDate === '1/1/1') {
          return <span>-</span>;
        }
        return <>{formattedDate}</>;
      }
    }
    return <span>-</span>;
  };

  const columns: ColumnProps<Peripheral>[] = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (compA, compB) =>
        compA.name === compB.name ? 0 : compA.name > compB.name ? 1 : -1,
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      sorter: (compA, compB) =>
        compA.type === compB.type ? 0 : compA.type > compB.type ? 1 : -1,
    },
    {
      title: 'Driver Name',
      dataIndex: 'driverName',
      key: 'driverName',
      sorter: (compA, compB) =>
        compA.driverName === compB.driverName
          ? 0
          : compA.driverName > compB.driverName
          ? 1
          : -1,
    },
    {
      title: 'Driver Version',
      dataIndex: 'driverVersion',
      key: 'driverVersion',
      sorter: (compA, compB) =>
        compA.driverVersion === compB.driverVersion
          ? 0
          : compA.driverVersion > compB.driverVersion
          ? 1
          : -1,
    },
    {
      title: 'Driver Date',
      dataIndex: 'driverDate',
      key: 'driverDate',
      render: date => formatDateTIme(date),
      sorter: (compA, compB) =>
        compA.driverDate === compB.driverDate
          ? 0
          : compA.driverDate > compB.driverDate
          ? 1
          : -1,
    },
    {
      title: 'Manufacturer',
      dataIndex: 'manufacturer',
      key: 'manufacturer',
      sorter: (compA, compB) =>
        compA.manufacturer === compB.manufacturer
          ? 0
          : compA.manufacturer > compB.manufacturer
          ? 1
          : -1,
    },
  ];

  return (
    <Table
      showSorterTooltip
      columns={columns}
      pagination={pagination}
      onChange={handlePagination}
      dataSource={
        typeof deviceData !== 'undefined' &&
        deviceData !== null &&
        deviceData.device !== null &&
        deviceData.device.peripherals !== null
          ? deviceData.device.peripherals.sort((compA, compB) =>
              sortStringAscending(compA.name, compB.name),
            )
          : []
      }
      loading={loading}
      rowKey={record => record.name}
    />
  );
};

export default PeripheralsTable;
