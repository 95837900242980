import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { TablePaginationConfig } from 'antd/es/table';
import { ColumnProps } from 'antd/lib/table';
import { SorterResult } from 'antd/lib/table/interface';

import Table from '@totem/components/common/table/Table';
import TableFilterDropDown from '@totem/components/TableFilterDropDown';
import TicketingContext from '@totem/components/ticketing/list/ticketingContext';
import { DeviceLastBackupRecord } from '@totem/types/devices';
import {
  TicketingFilterOptions,
  TicketQueryResult,
  TicketQueryResults,
} from '@totem/types/ticketing';
import { formatDate, isNotNull } from '@totem/utilities/common';
import { stringifyArray } from '@totem/utilities/tableFilterUtilities';
import { getFilterOptions } from '@totem/utilities/ticketing';

import '../ticketing.css';

type Props = {
  page: number;
  pageSize: number;
  loading: boolean;
  total: number;
  ticketResults: TicketQueryResults;
  filterOptions: TicketingFilterOptions;
};

const TicketsTable = ({
  ticketResults,
  filterOptions,
  loading,
  page,
  pageSize,
  total,
}: Props) => {
  const { input, setInput } = useContext(TicketingContext);

  const columns: ColumnProps<TicketQueryResult>[] = [
    {
      title: 'Number',
      dataIndex: 'ticket.number',
      key: 'type',
      render: (_, ticket: TicketQueryResult) => (
        <Link to={`/dashboard/ticket/${ticket.ticket.id}`}>
          {ticket.ticket.number}
        </Link>
      ),
      showSorterTooltip: true,
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        compA.ticket.number === compB.ticket.number
          ? 0
          : compA.ticket.number > compB.ticket.number
            ? 1
            : -1,
      filterMultiple: true,
      filteredValue: stringifyArray(input.type),
      filters: getFilterOptions(
        typeof filterOptions !== 'undefined' && filterOptions !== null
          ? filterOptions.type
          : null,
      ),
      // filterDropdown: (
      //   <TableFilterDropDown
      //     placement={'bottomRight'}
      //     value={stringifyArray(input.type)}
      //     onChange={(newOptions) => setInput({ type: newOptions })}
      //     options={getFilterOptions(
      //       isNotNull(filterOptions) ? filterOptions.type : null,
      //     )}
      //   />
      // ),
    },
    {
      title: 'Category',
      dataIndex: 'ticket.category',
      key: 'category',
      render: (_, ticket: TicketQueryResult) => ticket.ticket.category,
      showSorterTooltip: true,
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        compA.ticket.category === compB.ticket.category
          ? 0
          : compA.ticket.category > compB.ticket.category
            ? 1
            : -1,
      filterMultiple: true,
      filteredValue: stringifyArray(input.category),
      filters: getFilterOptions(
        typeof filterOptions !== 'undefined' && filterOptions !== null
          ? filterOptions.categories
          : null,
      ),
    },
    {
      title: 'Building',
      dataIndex: 'building.name',
      key: 'buildingId',
      render: (_, ticket: TicketQueryResult) => ticket.building.name,
      filterMultiple: true,
      filteredValue: stringifyArray(input.buildingId),
      filters: getFilterOptions(
        typeof filterOptions !== 'undefined' && filterOptions !== null
          ? filterOptions.building
          : null,
      ),
    },
    {
      title: 'Summary',
      dataIndex: 'ticket.summary',
      key: 'summary',
      render: (_, ticket: TicketQueryResult) => ticket.ticket.summary,
      showSorterTooltip: true,
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        compA.ticket.summary === compB.ticket.summary
          ? 0
          : compA.ticket.summary > compB.ticket.summary
            ? 1
            : -1,
    },
    {
      title: 'Priority',
      dataIndex: 'ticket.priority',
      key: 'priority',
      render: (_, ticket: TicketQueryResult) => ticket.ticket.priority,
      showSorterTooltip: true,
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        compA.ticket.priority === compB.ticket.priority
          ? 0
          : compA.ticket.priority > compB.ticket.priority
            ? 1
            : -1,
      filterMultiple: true,
      filteredValue: stringifyArray(input.priority),
      filters: getFilterOptions(
        typeof filterOptions !== 'undefined' && filterOptions !== null
          ? filterOptions.priority
          : null,
      ),
    },
    {
      title: 'Status',
      dataIndex: 'ticket.status',
      key: 'status',
      render: (_, ticket: TicketQueryResult) => ticket.ticket.status,
      showSorterTooltip: true,
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        compA.ticket.status === compB.ticket.status
          ? 0
          : compA.ticket.status > compB.ticket.status
            ? 1
            : -1,
      filterMultiple: true,
      filteredValue: stringifyArray(input.status),
      filters: getFilterOptions(
        typeof filterOptions !== 'undefined' && filterOptions !== null
          ? filterOptions.status
          : null,
      ),
    },
    {
      title: 'Created',
      dataIndex: 'ticket.createdAt',
      key: 'createdAt',
      render: (_, ticket: TicketQueryResult) =>
        formatDate(ticket.ticket.createdAt),
      sorter: (compA, compB) =>
        compA.ticket.createdAt === compB.ticket.createdAt
          ? 0
          : compA.ticket.createdAt > compB.ticket.createdAt
            ? 1
            : -1,
      showSorterTooltip: true,
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Updated',
      dataIndex: 'ticket.updatedAt',
      key: 'updatedAt',
      render: (_, ticket: TicketQueryResult) =>
        formatDate(ticket.ticket.updatedAt),
      sorter: (compA, compB) =>
        compA.ticket.updatedAt === compB.ticket.updatedAt
          ? 0
          : compA.ticket.updatedAt > compB.ticket.updatedAt
            ? 1
            : -1,
      showSorterTooltip: true,
      sortDirections: ['ascend', 'descend'],
    },
  ];

  const getTickets = () => {
    if (ticketResults !== null) {
      if (ticketResults.tickets !== null) {
        return ticketResults.tickets;
      }
    }
    return [];
  };

  const handleTableChange = (
    updatedPagination: TablePaginationConfig,
    filters: SorterResult<DeviceLastBackupRecord>,
    sorter,
  ) => {
    const { ...params } = filters;

    const sortDir: string = sorter.order === 'descend' ? '-1' : '1';
    let sortField = 'createdBy';
    if (
      typeof sorter.field !== 'undefined' &&
      typeof sorter.order !== 'undefined'
    ) {
      sortField = sorter.field;
    }

    // @ts-ignore
    setInput({
      ...input,
      ...params,
      pageSize: updatedPagination.pageSize,
      page: updatedPagination.current,
      sortField,
      sortDirection: sortDir,
    });
  };

  return (
    <Table
      showSorterTooltip
      columns={columns}
      dataSource={getTickets()}
      loading={loading}
      onChange={handleTableChange}
      pagination={{ current: page, pageSize, total, showSizeChanger: true }}
      rowKey={(record) => record.ticket.id}
    />
  );
};

export default TicketsTable;
