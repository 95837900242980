import React from 'react';

import colors from '@totem/styles/colors';
import { Criticality, CriticalityLabel } from '@totem/types/criticality';

import './criticality.css';

type CriticalityOption = {
  text: CriticalityLabel;
  value: Criticality;
  property: string;
  color: string;
  style?: React.CSSProperties;
};

export const criticalityMap: { [key: number]: CriticalityOption } = {
  [Criticality.Info]: {
    text: 'Info',
    value: Criticality.Info,
    property: 'info',
    color: colors.criticality.info,
    style: {
      border: `2px solid ${colors.criticality.info}`,
    },
  },
  [Criticality.Low]: {
    text: 'Low',
    value: Criticality.Low,
    property: 'low',
    color: colors.criticality.low,
    style: {
      border: `2px solid ${colors.criticality.low}`,
    },
  },
  [Criticality.Medium]: {
    text: 'Medium',
    value: Criticality.Medium,
    color: colors.criticality.medium,
    property: 'medium',
    style: {
      border: `2px solid ${colors.criticality.medium}`,
    },
  },
  [Criticality.High]: {
    text: 'High',
    value: Criticality.High,
    color: colors.criticality.high,
    property: 'high',
    style: {
      border: `2px solid ${colors.criticality.high}`,
    },
  },
  [Criticality.Critical]: {
    text: 'Critical',
    value: Criticality.Critical,
    color: colors.criticality.critical,
    property: 'critical',
    style: {
      background: colors.criticality.critical,
      color: colors.neutral.white,
    },
  },
  [Criticality.Compliant]: {
    text: 'Compliant',
    value: Criticality.Compliant,
    color: colors.criticality.compliant,
    property: 'compliant',
    style: {
      background: colors.criticality.compliant,
      color: colors.neutral.white,
    },
  },
};

export const criticallityFromString = (criticalityString: string) => {
  switch (criticalityString) {
    case criticalityMap[Criticality.Low].value.toString():
      return criticalityMap[Criticality.Low].value;
    case criticalityMap[Criticality.Medium].value.toString():
      return criticalityMap[Criticality.Medium].value;
    case criticalityMap[Criticality.High].value.toString():
      return criticalityMap[Criticality.High].value;
    case criticalityMap[Criticality.Critical].value.toString():
      return criticalityMap[Criticality.Critical].value;
    case criticalityMap[Criticality.Compliant].value.toString():
      return criticalityMap[Criticality.Compliant].value;
    default:
      return criticalityMap[Criticality.Info].value;
  }
};

export const criticalityOptions: CriticalityOption[] = Object.values(
  criticalityMap,
);

export const getCriticalityBadge = (criticality: number): React.ReactNode => {
  const option =
    criticalityMap[criticality] || criticalityMap[Criticality.Info];

  return (
    <div
      style={{
        ...option.style,
      }}
      styleName="criticality-container"
    >
      {option.text}
    </div>
  );
};

export const getCriticalityIndicator = (
  criticality: number,
  style?: React.CSSProperties,
): React.ReactNode => {
  const selected =
    criticalityMap[criticality] || criticalityMap[Criticality.Info];

  return (
    <div
      styleName="criticality-indicator"
      style={{
        background: selected.color,
        ...style,
      }}
    />
  );
};

const criticalityUtilities = {
  criticalityOptions,
  getCriticalityBadge,
  getCriticalityIndicator,
};

export default criticalityUtilities;
