import React from 'react';
import dayjs from 'dayjs';

import StackedBarChart from '@totem/components/charts/StackedBar';
import {
  EventCount,
  EventRange,
} from '@totem/components/controlSystemReport/dataTypes';
import { GraphMultiIntSeries } from '@totem/components/events/graphs/types';
import { isNotNull } from '@totem/utilities/common';
import { getSeverityColor, getSeverityTitle } from '@totem/utilities/events';
import { sortNumberDescending } from '@totem/utilities/tableUtilities';

type Props = {
  controlSystemId: string;
  eventSummary: EventCount[];
  eventRange: EventRange;
};
const EventChart = ({ eventSummary, controlSystemId, eventRange }: Props) => {
  const getSeries = (): GraphMultiIntSeries => {
    const gmis = {
      x: [],
      series: eventSummary
        .sort((compA, compB) =>
          sortNumberDescending(compA.severity, compB.severity),
        )
        .map((evt) => {
          return {
            name: getSeverityTitle(evt.severity),
            y: [evt.count],
          };
        }),
    };

    return gmis;
  };

  const getColors = (): string[] => {
    const colorArray = eventSummary
      .sort((compA, compB) =>
        sortNumberDescending(compA.severity, compB.severity),
      )
      .map((evt) => {
        return getSeverityColor(evt.severity);
      });
    return colorArray;
  };

  // eslint-disable-next-line id-length
  const handleClick = (dataPointIndex: number) => {
    if (dataPointIndex >= 0) {
      const eventEntry = eventSummary.sort((compA, compB) =>
        sortNumberDescending(compA.severity, compB.severity),
      )[dataPointIndex];

      const endTime = dayjs();
      let startTime = endTime.subtract(3, 'month');

      if (isNotNull(eventRange)) {
        if (isNotNull(eventRange.startDate)) {
          startTime = dayjs(eventRange.startDate);
        }
        // if (isNotNull(eventRange.endDate)) {
        //   endTime = dayjs(eventRange.endDate);
        // }
      }

      const timeComponent = `startTime=${startTime.valueOf()}&endTime=${endTime.valueOf()}`;

      window.open(
        `/dashboard/events?severity=${eventEntry.severity}&status=CREATED&status=ACKNOWLEDGED&controlSystemId=${controlSystemId}&${timeComponent}`,
        `event.${controlSystemId}.${eventEntry.severity}`,
      );
    }
  };

  return (
    <StackedBarChart
      onItemClicked={handleClick}
      height="55px"
      width="200px"
      series={getSeries()}
      chartOptions={{
        colors: getColors(),
        dataLabels: {
          style: {
            fontSize: '10px',
          },
        },
        tooltip: {
          x: {
            show: false,
          },
        },
        grid: {
          show: false,
          yaxis: {
            lines: {
              show: false,
            },
          },
          padding: {
            top: -5,
            bottom: -5,
            left: -10,
            right: 0,
          },
        },
        legend: { show: false },
        xaxis: {
          labels: {
            show: false,
          },
          offsetX: 0,
          offsetY: 0,
          axisTicks: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          tooltip: {
            enabled: false,
          },
        },
        yaxis: {
          show: false,
          labels: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
      }}
    />
  );
};

export default EventChart;
