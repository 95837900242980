import * as React from 'react';
import { useCallback, useContext, useEffect, useState } from 'react';
import { EnvironmentOutlined } from '@ant-design/icons';
import { Avatar, Button, Form, Input, InputNumber, Modal, Select } from 'antd';
import * as R from 'ramda';

import AddressEntry from '@totem/components/buildingsCommon/AddressEntry';
import {
  AddEditBuilding,
  Address,
} from '@totem/components/buildingsCommon/types';
import OrganizationContext from '@totem/components/common/organizationContext/organizationContext';
import { AddressFormErrors } from '@totem/types/address';
import { BuildingTypes } from '@totem/types/building';
import { getToken } from '@totem/utilities/accountUtilities';
import { isNotNull } from '@totem/utilities/common';
import {
  BUILDINGS_ENDPOINT,
  V2_BUILDINGS_ENDPOINT,
} from '@totem/utilities/endpoints';
import { CheckResponseShowError } from '@totem/utilities/responseUtilities';

import './buildingCommon.css';
const FormItem = Form.Item;
const { Option } = Select;

const styles = {
  form: {
    width: '100%',
  },
  formItem: {
    paddingBottom: '0',
    marginBottom: '1rem',
  },
};

type ValidationResponse = {
  isValid: boolean;
  errors: AddressFormErrors;
};

type Props = {
  open: boolean;
  loading: boolean;
  buildingData: AddEditBuilding;
  onClose: (boolean) => void;
};

const AddEditDialog = ({ open, loading, buildingData, onClose }: Props) => {
  const { regions } = useContext(OrganizationContext);
  const [buildingTypes, setBuildingTypes] = useState<BuildingTypes>({
    id: '62a8d9d5134444b27707dda8',
    type: 'BuildingTypes',
    data: [
      '',
      'Commercial Office',
      'Data Center',
      'Educational',
      'Life Sciences',
      'Multi-Family High Rise',
      'Multi-Family Podium',
      'Retail',
      'Warehouse',
      'Other',
    ],
  });

  const [step, setStep] = useState<number>(0);
  const [isSending, setIsSending] = useState<boolean>(false);
  const [errors, setErrors] = useState<AddressFormErrors>({});
  const [editData, setEditData] = useState<AddEditBuilding>(buildingData);
  const isEdit = buildingData.id !== '';
  const isFirstStep = step === 0;

  useEffect(() => {
    fetch(`${BUILDINGS_ENDPOINT}/types`, {
      method: 'GET',
      headers: new Headers({
        Authorization: `Bearer ${getToken()}`,
      }),
    })
      .then((res) => res.json())
      .then((result: BuildingTypes) => {
        setBuildingTypes(result);
      });
  }, []);

  const sendUpdateBuilding = useCallback(async (payload: AddEditBuilding) => {
    if (isSending) {
      return;
    }
    setIsSending(true);

    const endPoint =
      payload.id !== ''
        ? `${V2_BUILDINGS_ENDPOINT}/${payload.id}`
        : `${V2_BUILDINGS_ENDPOINT}/create`;

    fetch(endPoint, {
      method: 'POST',
      headers: new Headers({
        Authorization: `Bearer ${getToken()}`,
      }),
      body: JSON.stringify(payload),
    }).then((res) => {
      CheckResponseShowError(res);
      setIsSending(false);
      onClose(true);
    });
  }, []);

  const isFirstStepValid = (): ValidationResponse => {
    const newErrors: AddressFormErrors = {};

    if (R.isEmpty(editData.name) || R.isNil(editData.name)) {
      newErrors.name = 'Name is required.';
    }
    if (R.isEmpty(editData.region.id) || R.isNil(editData.region.id)) {
      newErrors.region = `Region is required.`;
    }

    setErrors(newErrors);
    return {
      isValid: R.isEmpty(newErrors),
      errors: newErrors,
    };
  };

  const isSecondStepValid = (newErrors: AddressFormErrors) => {
    if (
      R.isEmpty(editData.address.addressOne) ||
      R.isNil(editData.address.addressOne)
    ) {
      newErrors.addressOne = `Address is required.`;
    }
    if (R.isEmpty(editData.address.city) || R.isNil(editData.address.city)) {
      newErrors.city = `City is required.`;
    }
    if (R.isEmpty(editData.address.state) || R.isNil(editData.address.state)) {
      newErrors.state = `State is required.`;
    }
    if (
      R.isEmpty(editData.address.country) ||
      R.isNil(editData.address.country)
    ) {
      newErrors.country = `Country is required.`;
    }
    if (
      R.isEmpty(editData.address.zipCode) ||
      R.isNil(editData.address.zipCode)
    ) {
      newErrors.zipCode = `Postal Code is required.`;
    }

    setErrors(newErrors);
    return R.isEmpty(newErrors);
  };

  const handleBack = () => {
    if (step === 0) {
      onClose(false);
    } else {
      setStep(step - 1);
    }
  };
  const handleNext = () => {
    const validation = isFirstStepValid();
    if (!validation.isValid) {
      console.log('Not Valid');
      return;
    }

    if (step === 0) {
      setStep(step + 1);
    } else {
      if (isSecondStepValid(validation.errors)) {
        if (isEdit) {
          // Send Update
          console.log(`Update: ${JSON.stringify(editData)}`);
          sendUpdateBuilding(editData);
        } else {
          // Send Create
          console.log(`Create: ${JSON.stringify(editData)}`);
          sendUpdateBuilding(editData);
        }
      } else {
        console.log('Second Step Not Valid');
        console.log(JSON.stringify(errors));
      }
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setEditData({ ...editData, [name]: value });
  };

  const handleNumberChange = (name: string, value: number) => {
    setEditData({ ...editData, [name]: value });
  };
  const handleStringChange = (name: string, value: string) => {
    setEditData({ ...editData, [name]: value });
  };

  const handleAddressChange = (newAddress: Address) => {
    setEditData({ ...editData, address: newAddress });
  };

  const handleRegionChange = (id: string) => {
    const region = regions.find((chk) => chk.id === id);

    setEditData({
      ...editData,
      region: {
        id: isNotNull(region) ? region.id : id,
        name: isNotNull(region) ? region.name : '',
      },
    });
  };

  return (
    <div>
      <Modal
        title={buildingData.id === '' ? 'Add Building' : 'Update Building'}
        open={open}
        onCancel={() => onClose(false)}
        footer={
          <>
            <Button onClick={() => handleBack()}>
              {isFirstStep ? 'Cancel' : 'Back'}
            </Button>
            <Button type="primary" onClick={handleNext} loading={loading}>
              {isFirstStep ? 'Next' : isEdit ? 'Update' : 'Create'}
            </Button>
          </>
        }
      >
        <div styleName="modal-container">
          {isFirstStep ? (
            <>
              <div styleName="avatar-container">
                <Avatar
                  size="large"
                  icon={<EnvironmentOutlined />}
                  shape="circle"
                />
              </div>
              <Form layout="vertical" style={styles.form}>
                <FormItem
                  label="Region"
                  colon={false}
                  validateStatus={R.isNil(errors.region) ? 'success' : 'error'}
                  help={R.isNil(errors.region) ? null : errors.region}
                  style={styles.formItem}
                >
                  <Select
                    showSearch
                    filterOption
                    optionFilterProp="children"
                    onChange={handleRegionChange}
                    value={editData.region.id}
                    disabled={isEdit}
                  >
                    {regions.map(({ id, name }) => {
                      return (
                        <Option key={id} value={id}>
                          {name}
                        </Option>
                      );
                    })}
                  </Select>
                </FormItem>
                <FormItem
                  label="Building Name"
                  colon={false}
                  validateStatus={R.isNil(errors.name) ? 'success' : 'error'}
                  help={R.isNil(errors.name) ? null : errors.name}
                  style={styles.formItem}
                >
                  <Input
                    disabled={loading}
                    value={editData.name}
                    name="name"
                    onChange={handleInputChange}
                  />
                </FormItem>
                <FormItem
                  label="Building Type"
                  colon={false}
                  validateStatus="success"
                  style={styles.formItem}
                >
                  <Select
                    value={editData.type}
                    onChange={(type: string) =>
                      handleStringChange('type', type)
                    }
                  >
                    {buildingTypes.data.map((bt) => (
                      <Option key={bt} value={bt}>
                        {bt}
                      </Option>
                    ))}
                  </Select>
                </FormItem>
                <FormItem
                  label="Square Footage"
                  colon={false}
                  validateStatus="success"
                  style={styles.formItem}
                >
                  <InputNumber
                    value={editData.squareFootage}
                    onChange={(newValue: number) =>
                      handleNumberChange('squareFootage', newValue)
                    }
                    min={0}
                  />
                </FormItem>
              </Form>
            </>
          ) : (
            <AddressEntry
              address={editData.address}
              onChange={handleAddressChange}
              disabled={loading}
              errors={errors}
            />
          )}
        </div>
      </Modal>
    </div>
  );
};

export default AddEditDialog;
