import React, { ReactNode, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';

import SurveyContext from '@totem/components/surveyV2/instanceDetail/SurveyInstanceContext';
import authUtilities, { isVendorUser } from '@totem/utilities/authUtilities';
import { isNotNull } from '@totem/utilities/common';

import ContentLayout from '../../ContentLayout';

type Props = {
  children?: ReactNode;
};
const ReportContentLayout = ({ children }: Props) => {
  const navigate = useNavigate();
  const { data } = useContext(SurveyContext);

  const handleUpdateAudit = () => {
    const endpoint = authUtilities.isVendorUser()
      ? '/vendor-dashboard'
      : '/dashboard';

    navigate(`${endpoint}/surveyinstances/${data.survey.id}`);
  };

  return (
    <ContentLayout
      pageTitle={`${isNotNull(data) ? data.controlSystem.name : ''} Report`}
      pageSubtitle={[
        {
          label: isNotNull(data) ? data.organization?.name : '',
        },
        {
          label: isNotNull(data) ? data.region?.name : '',
        },
        {
          label: isNotNull(data) ? data.building?.name : '',
          link: isVendorUser()
            ? ''
            : isNotNull(data)
            ? `/dashboard/buildings/${data.building.id}`
            : '',
        },
      ]}
      contentRight={
        <Button type="primary" onClick={handleUpdateAudit}>
          Update Audit Responses
        </Button>
      }
      breadcrumbs={(breadcrumbs) => [
        ...breadcrumbs,
        {
          label: isNotNull(data) ? data.controlSystem?.name : '',
          link: isVendorUser()
            ? ''
            : isNotNull(data)
            ? `/dashboard/controlsystems/${data.controlSystem.id}`
            : '',
        },
        {
          label: 'Audit Report',
        },
      ]}
    >
      {children}
    </ContentLayout>
  );
};

export default ReportContentLayout;
