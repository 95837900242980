import React, { useState } from 'react';
import { Radio, Space } from 'antd';

import {
  QuestionOption,
  QuestionValue,
  SurveyQuestion,
} from '@totem/components/surveyV2/types';
import fonts from '@totem/styles/fonts';
const RadioGroup = Radio.Group;

type Props = {
  question: SurveyQuestion;
  disabled: boolean;
  onChange: (value: QuestionValue) => void;
};

const styles = {
  radioStyle: {
    fontSize: fonts.fontLg,
    marginBottom: '1rem',
  },
};

const EnumSingleQuestion = ({ question, disabled, onChange }: Props) => {
  const [value, setValue] = useState<QuestionValue>(question.value);
  const { options } = question;

  const handleChange = (newValue: QuestionOption) => {
    setValue(newValue.score);
    onChange(newValue.score);
  };

  return (
    <div>
      <RadioGroup size="small" value={value.value} disabled={disabled}>
        <Space direction="vertical">
          {options.map((option) => (
            <Radio
              value={option.score.value}
              style={styles.radioStyle}
              key={`${question.id}_${option.label}`}
              onClick={() => handleChange(option)}
            >
              {option.label}
            </Radio>
          ))}
        </Space>
      </RadioGroup>
    </div>
  );
};

export default EnumSingleQuestion;
