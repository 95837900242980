import React, { useCallback } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import { Input } from 'antd';
import { debounce } from '@totem/utilities/debounce';
import * as R from 'ramda';
import {
  ArrayParam,
  NumberParam,
  NumericArrayParam,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params';

import BasePane from '@totem/components/BasePane';
import PrimaryContentPane from '@totem/components/PrimaryContentPane';
import { GET_CONTROL_SYSTEMS } from '@totem/graph/controlSystem';
import { useBuildingFilter } from '@totem/hooks/useBuildingFilter';
import { useConnection } from '@totem/hooks/useConnection';
import { useErrorNotification } from '@totem/hooks/useErrorNotification';
import { useRegionFilter } from '@totem/hooks/useRegionFilter';
import colors from '@totem/styles/colors';
import { APIKeysConnectionInput } from '@totem/types/apiKey';
import {
  ControlSystemsConnection,
  ControlSystemsConnectionInput,
} from '@totem/types/controlSystem';
import { ControlSystemBackupsConnectionInput } from '@totem/types/controlSystemBackup';
import { NotesConnectionInput } from '@totem/types/note';
import { omitNilOrEmpty } from '@totem/utilities/objectUtilities';

import ContentLayout from '../ContentLayout';

import ControlSystemBackupFilterChips from './ControlSystemBackupFilterChips';
import ControlSystemBackupFilters from './ControlSystemBackupFilters';
import ControlSystemBackupsContext from './ControlSystemBackupsContext';
import ControlSystemBackupsTable from './ControlSystemBackupsTable';

import './controlSystemBackups.css';

const styles = {
  icon: {
    color: colors.neutral.gray,
  },
};

const DEBOUNCE_TIME = 500;

const ControlSystemBackups = () => {
  const [input, updateInput] = useQueryParams({
    limit: withDefault(NumberParam, 10),
    offset: withDefault(NumberParam, 0),
    name: StringParam,
    buildingId: ArrayParam,
    regionId: ArrayParam,
    systemType: NumericArrayParam,
  });

  const setInput = (updated: Partial<ControlSystemsConnectionInput>) => {
    updateInput(omitNilOrEmpty({ ...input, ...updated }), 'replace');
  };

  const buildingFilter = useBuildingFilter(input.buildingId, {
    onChange: buildingId => setInput({ ...input, buildingId }),
  });

  const regionFilter = useRegionFilter(input.regionId, {
    onChange: regionId => setInput({ ...input, regionId }),
  });

  const { data, error, loading } = useQuery<
    { controlSystems: ControlSystemsConnection },
    {
      input: ControlSystemsConnectionInput;
      apiKeysConnectionInput: APIKeysConnectionInput;
      controlSystemBackupsConnectionInput: ControlSystemBackupsConnectionInput;
      notesConnectionInput: NotesConnectionInput;
    }
  >(GET_CONTROL_SYSTEMS, {
    variables: {
      input: { ...input, isBackedUp: true },
      apiKeysConnectionInput: { limit: 1 },
      controlSystemBackupsConnectionInput: { limit: 1 },
      notesConnectionInput: {},
    },
  });

  useErrorNotification(error, 'Failed to retrieve backups. Please try again.');

  const { totalCount, controlSystems } = useConnection<
    ControlSystemsConnection
  >({
    data,
    accessor: R.path(['controlSystems']),
    initialData: { totalCount: 0, controlSystems: [] },
  });

  const handleSearch = useCallback(
    debounce(
      (name: string) => setInput({ ...input, name, offset: 0 }),
      DEBOUNCE_TIME,
    ),
    [],
  );

  return (
    <ContentLayout pageTitle="Backup Summary">
      <ControlSystemBackupsContext.Provider
        value={{
          input,
          setInput,
          totalCount,
          controlSystems,
          loading,
          buildingFilter,
          regionFilter,
        }}
      >
        <BasePane>
          <PrimaryContentPane>
            <ControlSystemBackupFilters />
            {/* <div styleName="title">Overview</div> */}
            {/* TODO: overview section with graphs */}
            <div styleName="title middle">Backups</div>
            <div styleName="search-container">
              <div styleName="search-bar">
                <Input
                  defaultValue={input.name}
                  placeholder="Search Control System"
                  prefix={<SearchOutlined style={styles.icon} />}
                  onChange={event => handleSearch(event.target.value)}
                />
              </div>
              <div styleName="table-count">
                <span>{`${Math.min(
                  totalCount - input.offset,
                  input.limit,
                )} out of ${totalCount} control systems with backups`}</span>
              </div>
            </div>
            <div styleName="table-container">
              <div styleName="table-chips-container">
                <ControlSystemBackupFilterChips />
              </div>
              <ControlSystemBackupsTable />
            </div>
          </PrimaryContentPane>
        </BasePane>
      </ControlSystemBackupsContext.Provider>
    </ContentLayout>
  );
};

export default ControlSystemBackups;
