import React, { ReactNode, useEffect, useState } from 'react';

import AnalysisReportContext from '@totem/components/surveyV2/analysisReport/analysisReportContext';
import {
  AnalysisReport,
  AnalysisReportInput,
  FilterOptions,
} from '@totem/components/surveyV2/analysisReport/types';
import { buildParameters } from '@totem/components/surveyV2/analysisReport/utilities';
import { OptionValue } from '@totem/types/common';
import { getToken } from '@totem/utilities/accountUtilities';
import { isNotNull } from '@totem/utilities/common';
import { V2_SURVEY_ENDPOINT } from '@totem/utilities/endpoints';
import { omitNilOrEmpty } from '@totem/utilities/objectUtilities';

type Props = {
  regionId?: string[];
  buildingId?: string[];
  controlSystemId?: string[];
  children?: ReactNode;
};

const AnalysisReportContainer = ({
  regionId,
  buildingId,
  controlSystemId,
  children,
}: Props) => {
  const [input, updateInput] = useState<AnalysisReportInput>({
    regionId,
    buildingId,
    controlSystemId,
  });
  const [refreshData, setRefreshData] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [data, setData] = useState<AnalysisReport>(null);
  const [action, setAction] = useState<string>('');
  const [filterOptions, setFilterOptions] = useState<FilterOptions>(null);

  const setInput = (updated: Partial<AnalysisReportInput>) => {
    updateInput(omitNilOrEmpty({ ...input, ...updated }));
    setRefreshData(true);
  };

  useEffect(() => {
    setRefreshData(true);
  }, [regionId, buildingId, controlSystemId]);

  useEffect(() => {
    // Get Filter Options
    if (isNotNull(data)) {
      const optionsPolicyName: string[] = [];
      const optionsPolicyCategory: string[] = [];

      for (let idx = 0; idx < data.auditPolicies.length; idx++) {
        const policy = data.auditPolicies[idx];
        if (
          optionsPolicyName.findIndex((chk) => chk === policy.policyName) < 0
        ) {
          optionsPolicyName.push(policy.policyName);
        }
        if (
          optionsPolicyCategory.findIndex(
            (chk) => chk === policy.policyCategory,
          ) < 0
        ) {
          optionsPolicyCategory.push(policy.policyCategory);
        }
      }

      const options: FilterOptions = {
        policyNameOptions: optionsPolicyName.map((opt): OptionValue => {
          return { label: opt, value: opt };
        }),
        policyCategoryOptions: optionsPolicyCategory.map((opt): OptionValue => {
          return { label: opt, value: opt };
        }),
        nistOptions: [
          {
            label: 'N/A',
            value: 'N/A',
          },
          {
            label: 'Protect',
            value: 'Protect',
          },
          {
            label: 'Detect',
            value: 'Detect',
          },
          {
            label: 'Identify',
            value: 'Identify',
          },
          {
            label: 'Recover',
            value: 'Recover',
          },
        ],
        levelOfEffortOptions: [
          {
            label: 'N/A',
            value: '0',
          },
          {
            label: 'Minimal',
            value: '1',
          },
          {
            label: 'Moderate',
            value: '2',
          },
          {
            label: 'Maximum',
            value: '3',
          },
        ],
      };

      setFilterOptions(options);
    }
  }, [data]);

  useEffect(() => {
    if (refreshData) {
      setRefreshData(false);
      setIsLoading(true);

      const uri = `${V2_SURVEY_ENDPOINT}/analysisReport`;

      const payload = buildParameters(input);

      fetch(uri, {
        method: 'POST',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
        body: JSON.stringify(payload),
      })
        .then((res) => res.json())
        .then((result: AnalysisReport) => {
          setData(result);
        })
        .then(() => {
          setIsLoading(false);
        });
    }
  }, [refreshData]);

  const handleAction = (newAction: string) => {
    switch (newAction) {
      case 'refresh':
        setRefreshData(true);
        setAction('');
        break;
      default:
        setAction(newAction);
    }
  };

  return (
    <AnalysisReportContext.Provider
      value={{
        input,
        setInput,
        loading: isLoading,
        data,
        filterOptions,
        action,
        onAction: handleAction,
      }}
    >
      <div>{children}</div>
    </AnalysisReportContext.Provider>
  );
};

export default AnalysisReportContainer;
