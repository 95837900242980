import React from 'react';
import {
  DownOutlined,
  ExclamationCircleOutlined,
  RightOutlined,
} from '@ant-design/icons';

import Table from '@totem/components/common/table/Table';
import colors from '@totem/styles/colors';
import { NmapDevice } from '@totem/types/nmap';
import criticalityUtilities from '@totem/utilities/criticalityUtilities';

import './nmapDetail.css';

type Props = {
  devices: NmapDevice[];
  onDeviceSelect: (device: NmapDevice) => void;
};

const styles = {
  kr: {
    backgroundColor: colors.neutral.gray,
  },
  krDanger: {
    backgroundColor: colors.brand.red,
  },
  icon: {
    cursor: 'pointer',
    color: 'rgba(0,0,0,0.45)',
    fontSize: 16,
  },
  flaggedIcon: {
    fontSize: 20,
    color: colors.brand.orange,
  },
};

const FlaggedDevicesTable = ({ devices, onDeviceSelect }: Props) => {
  const columns = [
    {
      title: 'Manufacturer',
      key: 'flagged-1',
      dataIndex: '',
      render: (text: string, device: NmapDevice) => (
        <div
          onClick={() => onDeviceSelect(device)}
          styleName="table-cell clickable"
        >
          {`${
            device.deviceManufacturer
              ? device.deviceManufacturer
              : 'Unknown Manufacturer'
          }`}
        </div>
      ),
    },

    {
      title: 'IP Address',
      key: 'flagged-2',
      dataIndex: '',
      render: (text: string, device: NmapDevice) => (
        <div
          onClick={() => onDeviceSelect(device)}
          styleName="table-cell clickable"
        >
          {device.ipAddress}
        </div>
      ),
    },
    {
      title: 'MAC Address',
      key: 'flagged-4',
      dataIndex: '',
      render: (text: string, device: NmapDevice) => (
        <div
          styleName="table-cell clickable"
          onClick={() => onDeviceSelect(device)}
        >
          {device.macAddress}
        </div>
      ),
    },
    {
      title: 'Criticality',
      key: 'flagged-3',
      dataIndex: '',
      render: (text: string, device: NmapDevice) => (
        <div
          onClick={() => onDeviceSelect(device)}
          styleName="table-cell clickable icon"
        >
          {criticalityUtilities.getCriticalityBadge(device.criticality)}
        </div>
      ),
    },
    {
      title: 'Port',
      key: 'flagged-5',
      dataIndex: '',
      render: (text: string, device: NmapDevice) =>
        device.isPortsFlagged ? (
          <div
            styleName="table-cell clickable icon"
            onClick={() => onDeviceSelect(device)}
          >
            <ExclamationCircleOutlined style={styles.flaggedIcon} />
          </div>
        ) : (
          <div />
        ),
    },
    {
      title: 'OS',
      key: 'flagged-6',
      dataIndex: '',
      render: (text: string, device: NmapDevice) =>
        device.isSoftwareVersionsFlagged ? (
          <div
            styleName="table-cell clickable icon"
            onClick={() => onDeviceSelect(device)}
          >
            <ExclamationCircleOutlined style={styles.flaggedIcon} />
          </div>
        ) : (
          <div />
        ),
    },
    {
      title: 'Manufacturer',
      key: 'flagged-7',
      dataIndex: '',
      render: (text: string, device: NmapDevice) =>
        device.isManufacturerFlagged ? (
          <div
            styleName="table-cell clickable icon"
            onClick={() => onDeviceSelect(device)}
          >
            <ExclamationCircleOutlined style={styles.flaggedIcon} />
          </div>
        ) : (
          <div />
        ),
    },
    {
      title: 'Other',
      key: 'flagged-8',
      dataIndex: '',
      render: (text: string, device: NmapDevice) =>
        device.isOtherFlagged ? (
          <div
            styleName="table-cell clickable icon"
            onClick={() => onDeviceSelect(device)}
          >
            <ExclamationCircleOutlined style={styles.flaggedIcon} />
          </div>
        ) : (
          <div />
        ),
    },
  ];

  return (
    <Table
      pagination={false}
      columns={columns}
      expandIcon={({ expanded, onExpand, record }) => {
        if (!record.notes) {
          return <div />;
        }

        return expanded ? (
          <DownOutlined
            onClick={event => onExpand(record, event)}
            style={styles.icon}
          />
        ) : (
          <RightOutlined
            onClick={event => onExpand(record, event)}
            style={styles.icon}
          />
        );
      }}
      expandedRowRender={(device: NmapDevice) => (
        <div
          styleName="expanded-notes-container"
          key={`${device.ipAddress}-${device.macAddress}-${device.deviceManufacturer}`}
        >
          <div styleName="expanded-notes-heading">Notes</div>
          <div style={{ whiteSpace: 'pre-line' }}>{device.notes}</div>
        </div>
      )}
      dataSource={devices}
      rowKey={(device: NmapDevice) =>
        `${device.ipAddress}-${device.macAddress}-${device.deviceManufacturer}`
      }
    />
  );
};

export default FlaggedDevicesTable;
