import { createContext } from 'react';

import {
  RemoteAccessSessions,
  RemoteAccessSessionsInput,
} from '@totem/components/remoteAccess/types';
import { Event } from '@totem/types/events';

interface Context {
  input: RemoteAccessSessionsInput;
  setInput: (input: RemoteAccessSessionsInput) => void;
  sessionData: RemoteAccessSessions[];
  eventData: Event[];
  groupOptions: string[];
}

export const getDefaultEndDate = () => {
  const endDate = new Date();
  return endDate;
};

export const getDefaultStartDate = () => {
  const startDate = getDefaultEndDate();
  startDate.setDate(startDate.getDate() - 30);
  return startDate;
};

export default createContext<Context>({
  input: null,
  setInput: () => {},
  sessionData: [],
  eventData: [],
  groupOptions: [],
});
