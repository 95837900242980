import React, { useCallback, useContext, useEffect, useState } from 'react';
import { CloseSquareOutlined } from '@ant-design/icons';
import { AutoComplete, Button, Col, message, Modal, Row } from 'antd';

import ModalFooter from '@totem/components/ModalFooter';
import ModalTitle from '@totem/components/ModalTitle';
import { TagUpdateRequest } from '@totem/components/software/software_package/types';
import softwareContext from '@totem/components/software/software_report/softwareContext';
import UserProfileContext from '@totem/components/UserProfileContext';
import { getToken } from '@totem/utilities/accountUtilities';
import { isNotNull } from '@totem/utilities/common';
import { DEVICES_ENDPOINT } from '@totem/utilities/endpoints';
import { isIBUser } from '@totem/utilities/security';

const styles = {
  tag: {
    marginBottom: '3px',
  },
  icon: {
    marginLeft: '5px',
  },
  input: {
    width: '100%',
  },
};

type Props = {
  open: boolean;
};

const DialogSoftwareBulkTags = ({ open }: Props) => {
  const [isSending, setIsSending] = useState<boolean>(false);
  const [isTagsLoading, setIsTagsLoading] = useState<boolean>(false);
  const [showEdit, setShowEdit] = useState<boolean>(open);
  const [knownTags, setKnownTags] = useState<string[]>([]);
  const [currentTag, setCurrentTag] = useState<string>('');
  const { userProfile } = useContext(UserProfileContext);
  const { selectedPackages, onAction, setPackageSelected } =
    useContext(softwareContext);
  const canAdmin = userProfile.organization.role === 3 && isIBUser(userProfile);

  useEffect(() => {
    if (!isTagsLoading) {
      setIsTagsLoading(true);
      const uri = `${DEVICES_ENDPOINT}/software/tags`;
      fetch(uri, {
        method: 'GET',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
      })
        .then((res) => res.json())
        .then((result: string[]) => {
          setKnownTags(result);
        })
        .then(() => {
          setIsTagsLoading(false);
        });
    }
  }, []);

  const handleClose = () => {
    setCurrentTag('');
    setShowEdit(false);
  };

  const sendUpdate = useCallback(
    async (request: TagUpdateRequest) => {
      if (isSending) {
        return;
      }

      fetch(`${DEVICES_ENDPOINT}/software/tags`, {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getToken()}`,
        }),
        body: JSON.stringify(request),
      })
        .then((response) => {
          if (response.status !== 200) {
            message.error('Tag update failed!');
          } else {
            onAction('refresh', null);
            handleClose();
          }
        })
        .then(() => {
          setIsSending(false);
        });

      setIsSending(true);
    },
    [isSending],
  );

  const handleUpdateTag = (action: string, tag: string) => {
    if (isNotNull(selectedPackages)) {
      selectedPackages.forEach((pkg) => {
        const request: TagUpdateRequest = {
          action,
          packageId: pkg.id,
          tag,
        };

        sendUpdate(request);
      });
      onAction('clear_package_selections', null);
    }
  };

  const handleSubmit = () => {
    if (currentTag !== null && currentTag !== '') {
      handleUpdateTag('Add', currentTag);
    }
  };

  return (
    <Modal
      open={showEdit}
      onCancel={() => handleClose()}
      title={<ModalTitle>Add Tag</ModalTitle>}
      confirmLoading={false}
      footer={
        <ModalFooter>
          <Button onClick={() => handleClose()}>Close</Button>
          <Button
            onClick={handleSubmit}
            type="primary"
            disabled={isSending || !canAdmin}
          >
            Submit
          </Button>
        </ModalFooter>
      }
    >
      <AutoComplete
        placeholder="Tag"
        options={
          isNotNull(knownTags)
            ? knownTags.map((chk) => {
                return { value: chk };
              })
            : []
        }
        value={currentTag}
        onChange={(newValue) => setCurrentTag(newValue)}
        onSelect={(newValue) => setCurrentTag(newValue)}
        style={styles.input}
      />
      <br />
      <br />
      {selectedPackages.map((pkg) => (
        <Row>
          <Col span={23}>{pkg.name}</Col>
          <Col span={1}>
            <CloseSquareOutlined
              onClick={() => {
                setPackageSelected(pkg, false);
              }}
            />
          </Col>
        </Row>
      ))}
    </Modal>
  );
};

export default DialogSoftwareBulkTags;
