import React, { useEffect, useState } from 'react';

import InfoWidget from '@totem/components/ui_components/info_widget/InfoWidget';
import {
  Point,
  Points,
} from '@totem/components/ui_components/info_widget/types';
import colors from '@totem/styles/colors';
import { Params } from '@totem/types/common';
import { EventSeverityValue } from '@totem/types/events';
import { getToken } from '@totem/utilities/accountUtilities';
import { EVENTS_ENDPOINT } from '@totem/utilities/endpoints';

const EventSummary = () => {
  const [chartData, setChartData] = useState<Points>([]);
  const [eventTotal, setEventTotal] = useState<number>(0);

  const COLORS = [
    colors.event.criticality.background.unknown,
    colors.event.criticality.background.info,
    colors.event.criticality.background.warning,
    colors.event.criticality.background.minor,
    colors.event.criticality.background.major,
    colors.event.criticality.background.critical,
  ];

  const createChartData = (eventSummary: EventSeverityValue[]) => {
    let total = 0;
    const data: Points = [
      { id: '0', label: 'Unknown', value: 0 },
      { id: '1', label: 'Information', value: 0 },
      { id: '2', label: 'Warning', value: 0 },
      { id: '3', label: 'Minor', value: 0 },
      { id: '4', label: 'Major', value: 0 },
      { id: '5', label: 'Critical', value: 0 },
    ];

    eventSummary.forEach((val) => {
      switch (val.severity) {
        case -1:
          data[0].value = val.count;
          total += val.count;
          break;
        case 0:
          break;
        default:
          data[val.severity].value = val.count;
          total += val.count;
          break;
      }
    });

    setEventTotal(total);
    setChartData(data);
  };

  useEffect(() => {
    const buildParameters = () => {
      const params: Params = {
        paging: {
          page: 1,
          pageSize: 1,
        },
        sort: {
          field: 'lastOccurrence',
          direction: 1,
        },
        filters: [],
      };

      return params;
    };

    const params: Params = buildParameters();

    fetch(`${EVENTS_ENDPOINT}/severitySummary`, {
      method: 'POST',
      headers: new Headers({
        Authorization: `Bearer ${getToken()}`,
      }),
      body: JSON.stringify(params),
    })
      .then((res) => res.json())
      .then((result: EventSeverityValue[]) => {
        createChartData(result);
      });
  }, []);

  const handleItemClicked = (index: number, point: Point) => {
    if (index >= 0) {
      window.open(
        `/dashboard/events?severity=${index}&status=CREATED&status=ACKNOWLEDGED&startTime=2020-01-01T00:00:00.000Z`,
        `${point.label}`,
      );
    }
  };

  return (
    <InfoWidget
      points={chartData}
      chartTitle="Events / Criticality"
      title="Events"
      total={eventTotal.toString(10)}
      chartOptions={{ colors: COLORS }}
      onPointClicked={handleItemClicked}
    />
  );
};

export default EventSummary;
