import React, { ReactNode, useContext, useEffect, useState } from 'react';

import OrganizationContext from '@totem/components/common/organizationContext/organizationContext';
import FindingsReportContext from '@totem/components/surveyV2/findingsReport/findingsReportContext';
import {
  FilterOptions,
  FindingReportInput,
  FindingReportRecord,
} from '@totem/components/surveyV2/findingsReport/types';
import { buildParameters } from '@totem/components/surveyV2/findingsReport/utilities';
import { Params } from '@totem/types/common';
import { getToken } from '@totem/utilities/accountUtilities';
import { isNotNull, isNull } from '@totem/utilities/common';
import { V2_SURVEY_ENDPOINT } from '@totem/utilities/endpoints';
import { omitNilOrEmpty } from '@totem/utilities/objectUtilities';
import { sortStringAscending } from '@totem/utilities/tableUtilities';

type Props = {
  children?: ReactNode;
  question?: string[];
  regionIds?: string[];
  buildingIds?: string[];
  controlSystemIds?: string[];
};

const FindingsReportContainer = ({
  children,
  question,
  regionIds,
  buildingIds,
  controlSystemIds,
}: Props) => {
  const { regions, buildings } = useContext(OrganizationContext);
  const [input, updateInput] = useState<FindingReportInput>({
    question: isNotNull(question) ? question : [],
    regionId: isNotNull(regionIds) ? regionIds : [],
    buildingId: isNotNull(buildingIds) ? buildingIds : [],
    controlSystemId: isNotNull(controlSystemIds) ? controlSystemIds : [],
    criticality: [],
    assignee: [],
  });
  const [refreshData, setRefreshData] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [data, setData] = useState<FindingReportRecord>(null);
  const [action, setAction] = useState<string>('');
  const [filterOptions, setFilterOptions] = useState<FilterOptions>(null);

  const setInput = (updated: Partial<FindingReportInput>) => {
    updateInput(omitNilOrEmpty({ ...input, ...updated }));
    setRefreshData(true);
  };

  useEffect(() => {
    const assignees: string[] = [];

    if (isNotNull(data)) {
      for (let idx = 0; idx < data.details.length; idx++) {
        const detail = data.details[idx];
        if (assignees.findIndex((chk) => chk === detail.assignee) < 0) {
          assignees.push(detail.assignee);
        }
      }
    }

    const options: FilterOptions = {
      criticalityOptions: [
        {
          label: 'Info',
          value: '0',
        },
        {
          label: 'Low',
          value: '1',
        },
        {
          label: 'Medium',
          value: '2',
        },
        {
          label: 'High',
          value: '3',
        },
        {
          label: 'Critical',
          value: '4',
        },
        {
          label: 'Compliant',
          value: '5',
        },
      ],
      assignee: assignees.map((chk) => {
        return { label: chk, value: chk };
      }),
      building: isNull(buildings)
        ? []
        : buildings
            .sort((compA, compB) => sortStringAscending(compA.name, compB.name))
            .map((chk) => {
              return { label: chk.name, value: chk.id };
            }),
      region: isNull(regions)
        ? []
        : regions
            .sort((compA, compB) => sortStringAscending(compA.name, compB.name))
            .map((chk) => {
              return { label: chk.name, value: chk.id };
            }),
    };
    setFilterOptions(options);
  }, [data, regions, buildings]);

  useEffect(() => {
    if (refreshData) {
      setRefreshData(false);

      setIsLoading(true);
      const params: Params = buildParameters(input);

      const uri = `${V2_SURVEY_ENDPOINT}/findingsReport`;

      fetch(`${uri}`, {
        method: 'POST',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
        body: JSON.stringify(params),
      })
        .then((res) => res.json())
        .then((result: FindingReportRecord) => {
          setData(result);
        })
        .then(() => {
          setIsLoading(false);
        });
    }
  }, [refreshData]);

  const handleAction = (newAction: string) => {
    switch (newAction) {
      case 'refresh':
        setRefreshData(true);
        setAction('');
        break;
      default:
        setAction(newAction);
    }
  };

  return (
    <FindingsReportContext.Provider
      value={{
        input,
        setInput,
        loading: isLoading,
        data,
        filterOptions,
        action,
        onAction: handleAction,
      }}
    >
      <div>{children}</div>
    </FindingsReportContext.Provider>
  );
};

export default FindingsReportContainer;
