import React, { ReactNode, useContext, useEffect, useState } from 'react';

import TenantMembershipContext from '@totem/components/tenants/context/TenantMembershipContext';
import { Tenants } from '@totem/components/tenants/types';
import UserProfileContext from '@totem/components/UserProfileContext';
import { getToken } from '@totem/utilities/accountUtilities';
import { ORGANIZATION_ENDPOINT } from '@totem/utilities/endpoints';

type Props = {
  children?: ReactNode;
};

const TenantMembershipContainer = ({ children }: Props) => {
  const { userProfile } = useContext(UserProfileContext);

  const [membership, setMembership] = useState<Tenants>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    setIsLoading(true);
    fetch(`${ORGANIZATION_ENDPOINT}/tenantMembership`, {
      method: 'GET',
      headers: new Headers({
        Authorization: `Bearer ${getToken()}`,
      }),
    })
      .then((res) => res.json())
      .then((result: Tenants) => {
        if (result.length > 0) {
          setMembership(result);
        }
      })
      .then(() => {
        setIsLoading(false);
      });
  }, [userProfile]);

  return (
    <TenantMembershipContext.Provider
      value={{
        membershipLoading: isLoading,
        membership,
      }}
    >
      {children}
    </TenantMembershipContext.Provider>
  );
};

export default TenantMembershipContainer;
