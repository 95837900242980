import actionTypes from '@totem/actions/actionTypes';

import initialState from './initialState';

export default function remoteAccessUserRolesReducer(
  state = initialState.remoteAccessUserRoles,
  action,
) {
  switch (action.type) {
    case actionTypes.REQUEST_REMOTE_ACCESS_USER_ROLES_SUCCESS: {
      return action.data ? action.data : state;
    }
    case actionTypes.REQUEST_REMOTE_ACCESS_USER_ROLES_FAILED: {
      return null;
    }
    case actionTypes.REQUEST_REMOTE_ACCESS_ROLE_REMOVE_FROM_USER_SUCCESS: {
      if (action.data !== null) {
        if (state.user.user_id === action.data.user.user_id) {
          return action.data ? action.data : state;
        }
      }

      return state;
    }
    case actionTypes.REQUEST_REMOTE_ACCESS_ROLE_ADD_TO_USER_SUCCESS: {
      if (action.data !== null) {
        if (state.user.user_id === action.data.user.user_id) {
          return action.data ? action.data : state;
        }
      }

      return state;
    }
    default: {
      return state;
    }
  }
}
