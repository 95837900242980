import * as React from 'react';

import './score.css';

const A_GRADE = '#73967c';
const B_GRADE = '#82a58b';
const C_GRADE = '#9ab19f';
const D_GRADE = 'rgba(127, 21, 10, 0.8)';
const F_GRADE = '#7f150a';

const getGradeColor = (totalScore: number, maxScore: number): string => {
  const score = totalScore / maxScore;

  if (!Number(score) || score < 0.6) {
    return F_GRADE;
  }

  if (score >= 0.6 && score < 0.7) {
    return D_GRADE;
  }

  if (score >= 0.7 && score < 0.8) {
    return C_GRADE;
  }

  if (score >= 0.8 && score < 0.9) {
    return B_GRADE;
  }

  if (score >= 0.9) {
    return A_GRADE;
  }

  return F_GRADE;
};

const getGrade = (totalScore: number, maxScore: number): string => {
  const score = totalScore / maxScore;

  if (!Number(score) || score < 0.6) {
    return 'F';
  }

  if (score >= 0.6 && score < 0.7) {
    return 'D';
  }

  if (score >= 0.7 && score < 0.8) {
    return 'C';
  }

  if (score >= 0.8 && score < 0.9) {
    return 'B';
  }

  if (score >= 0.9) {
    return 'A';
  }

  return 'F';
};

type Props = {
  totalScore: number;
  maxScore: number;
  small?: boolean;
  massive?: boolean;
};

const ResultGrade = ({ totalScore, maxScore, small, massive }: Props) => {
  const grade = getGrade(totalScore, maxScore);
  const gradeColor = getGradeColor(totalScore, maxScore);

  return (
    <div
      styleName={`result-grade-container ${small ? 'small' : ''} ${
        massive ? 'massive' : ''
      }`}
      style={{ backgroundColor: gradeColor }}
    >
      <div
        styleName={`grade ${small ? 'small' : ''} ${massive ? 'massive' : ''}`}
      >
        {grade}
      </div>
    </div>
  );
};

export default ResultGrade;
