import React, { useContext } from 'react';
import { Switch } from 'antd';

import SurveyContext from '@totem/components/surveyV2/instanceDetail/SurveyInstanceContext';
const FilteredSwitch = () => {
  const { setFilterAnswered, filterAnswered } = useContext(SurveyContext);

  return (
    <Switch
      onChange={() => setFilterAnswered(!filterAnswered)}
      checked={filterAnswered}
      size="small"
    />
  );
};

export default FilteredSwitch;
