import * as R from 'ramda';

import { SecurityStatus } from '@totem/components/common/securityIndicator/SecurityIndicator';
import colors from '@totem/styles/colors';

export const A_GRADE_COLOR = colors.criticality.compliant;
export const B_GRADE_COLOR = colors.criticality.positive;
export const C_GRADE_COLOR = colors.criticality.low;
export const D_GRADE_COLOR = colors.criticality.medium;
export const F_GRADE_COLOR = colors.criticality.high;

export const getGrade = (score: number): string => {
  if (!Number(score) || score < 60) {
    return 'F';
  }

  if (score >= 60 && score < 70) {
    return 'D';
  }

  if (score >= 70 && score < 80) {
    return 'C';
  }

  if (score >= 80 && score < 90) {
    return 'B';
  }

  if (score >= 90) {
    return 'A';
  }

  return 'F';
};

export const getGradeColor = (score?: number): string => {
  if (R.isNil(score)) {
    return colors.neutral.silver;
  }

  const grade = getGrade(score);

  switch (grade) {
    case 'A':
      return A_GRADE_COLOR;
    case 'B':
      return B_GRADE_COLOR;
    case 'C':
      return C_GRADE_COLOR;
    case 'D':
      return D_GRADE_COLOR;
    case 'F':
      return F_GRADE_COLOR;
    default:
      return F_GRADE_COLOR;
  }
};

export const getSecurityStatus = (score?: number): SecurityStatus => {
  if (R.isNil(score)) {
    return SecurityStatus.PENDING;
  }

  const grade = getGrade(score);

  switch (grade) {
    case 'A':
      return SecurityStatus.SECURE;
    case 'B':
    case 'C':
      return SecurityStatus.NEEDS_REVIEW;
    case 'D':
    case 'F':
      return SecurityStatus.NOT_SECURE;
    default:
      return SecurityStatus.PENDING;
  }
};

export const isSameLetterGrade = (
  scoreOne: number,
  scoreTwo: number,
): boolean => getGrade(scoreOne) === getGrade(scoreTwo);
