import { createContext } from 'react';

import {
  FilterOptions,
  FindingReportInput,
  FindingReportRecord,
} from '@totem/components/surveyV2/findingsReport/types';

interface Context {
  input: FindingReportInput;
  setInput: (input: FindingReportInput) => void;
  loading: boolean;
  data: FindingReportRecord;
  filterOptions: FilterOptions;
  action: string;
  onAction?: (action: string) => void;
}

export default createContext<Context>({
  input: {},
  setInput: () => {},
  loading: false,
  data: null,
  filterOptions: null,
  action: '',
  onAction: () => {},
});
