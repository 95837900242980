import React, { ReactNode } from 'react';

import './base.css';

type Props = {
  children?: ReactNode;
};
const FormFieldLabelLeft = ({ children }: Props) => {
  return <span styleName="form-field-label-left">{children}</span>;
};

export default FormFieldLabelLeft;
