import * as R from 'ramda';
import { call, fork, put, takeEvery } from 'redux-saga/effects';

import actionTypes from '@totem/actions/actionTypes';
import * as buildingActions from '@totem/actions/buildingActions';
import * as notificationActions from '@totem/actions/notificationActions';
import accountUtilities from '@totem/utilities/accountUtilities';
import api from '@totem/utilities/api';
import { BUILDINGS_ENDPOINT } from '@totem/utilities/endpoints';
import { TOTAL_COUNT_HEADER } from '@totem/utilities/requestUtilities';

export function* getBuildings(action) {
  try {
    const { page, limit, name, regionId, isEnabled } = action.data;

    const params = {
      ...(page && { page }),
      ...(limit && { limit }),
      ...(regionId && { regionId }),
      ...(name && { name }),
      ...(!R.isNil(isEnabled) && { isEnabled }),
    };

    const { data, headers } = yield call(api.get, BUILDINGS_ENDPOINT, params);
    const total = Number(headers[TOTAL_COUNT_HEADER]) || 0;

    yield put(buildingActions.getBuildingsSuccess(data));
    yield put(
      buildingActions.getBuildingsTotalSuccess({
        total: Number(total),
      }),
    );
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      buildingActions.getBuildingsFailure,
    );
  }
}

export function* updateBuilding(action) {
  try {
    const { data: body } = action;

    const endpoint = `${BUILDINGS_ENDPOINT}/${body.id}`;
    // @ts-ignore
    const { data } = yield call(api.patch, endpoint, body);

    yield put(buildingActions.buildingUpdateSuccess(data));
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      buildingActions.buildingUpdateFailure,
    );
  }
}

export function* deleteBuilding(action) {
  try {
    const { id } = action.data;
    const endpoint = `${BUILDINGS_ENDPOINT}/${id}`;
    // @ts-ignore
    yield call(api.delete, endpoint);

    yield put(buildingActions.deleteBuildingSuccess({ id }));
  } catch (error) {
    if (R.path(['response', 'status'], error) === 400) {
      yield put(
        notificationActions.createNotification({
          isError: true,
          message:
            'Building deletion is not possible. There are entities tied to this building. Please delete them first before continuing.',
        }),
      );
    } else {
      yield put(
        notificationActions.createNotification({
          isError: true,
          message: 'Building deletion failed. Please try again.',
        }),
      );
    }

    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      buildingActions.deleteBuildingFailure,
    );
  }
}

/* WATCHERS */
export function* retrieveBuildings() {
  yield takeEvery(actionTypes.REQUEST_BUILDINGS, getBuildings);
}

export function* requestBuildingUpdate() {
  yield takeEvery(actionTypes.REQUEST_BUILDING_UPDATE, updateBuilding);
}

export function* requestBuildingDelete() {
  yield takeEvery(actionTypes.REQUEST_BUILDING_DELETE, deleteBuilding);
}

export default [
  retrieveBuildings,
  requestBuildingUpdate,
  requestBuildingDelete,
];
