import React from 'react';

import BasePane from '@totem/components/BasePane';
import ContentLayout from '@totem/components/ContentLayout';
import PrimaryContentPane from '@totem/components/PrimaryContentPane';
import Policies from '@totem/components/surveyV2/policies/Policies';
import PoliciesContainer from '@totem/components/surveyV2/policies/PoliciesContainer';
const PoliciesPage = () => {
  return (
    <ContentLayout>
      <PoliciesContainer>
        <BasePane>
          <PrimaryContentPane>
            <Policies />
          </PrimaryContentPane>
        </BasePane>
      </PoliciesContainer>
    </ContentLayout>
  );
};

export default PoliciesPage;
