import React from 'react';
import { CloseCircleOutlined } from '@ant-design/icons';
import { ColumnProps } from 'antd/lib/table';

import Table from '@totem/components/common/table/Table';
import { QueueAssignment, TicketQueryResult } from '@totem/types/ticketing';
import { formatDate } from '@totem/utilities/common';

import '../ticketing.css';

type Props = {
  ticket: TicketQueryResult;
  onEndQueueAssignment: (queueAssignment: QueueAssignment) => void;
};

const QueueAssignmentTable = ({ ticket, onEndQueueAssignment }: Props) => {
  const columns: ColumnProps<QueueAssignment>[] = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      showSorterTooltip: true,
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        compA.name === compB.name ? 0 : compA.name > compB.name ? 1 : -1,
    },
    {
      title: 'Start Time',
      dataIndex: 'startTime',
      key: 'startTime',
      render: (_, queueAssignment: QueueAssignment) =>
        formatDate(queueAssignment.startTime),
      sorter: (compA, compB) =>
        compA.startTime === compB.startTime
          ? 0
          : compA.startTime > compB.startTime
          ? 1
          : -1,
      showSorterTooltip: true,
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'End Time',
      dataIndex: 'endTime',
      key: 'endTime',
      render: (_, queueAssignment: QueueAssignment) =>
        formatDate(queueAssignment.endTime),
      showSorterTooltip: true,
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        compA.endTime === compB.endTime
          ? 0
          : compA.endTime > compB.endTime
          ? 1
          : -1,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      showSorterTooltip: true,
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        compA.status === compB.status
          ? 0
          : compA.status > compB.status
          ? 1
          : -1,
    },
    {
      title: 'Actions',
      width: 30,
      dataIndex: 'additionalOptions',
      key: 'additionalOptions',
      render: (_, queueAssignment: QueueAssignment) =>
        queueAssignment.status === 'ACTIVE' && (
          <div className="center-table-cell">
            <CloseCircleOutlined
              onClick={() => onEndQueueAssignment(queueAssignment)}
            />
          </div>
        ),
    },
  ];

  const getQueueAssignments = () => {
    if (ticket !== null) {
      if (ticket.ticket !== null) {
        return ticket.ticket.queueAssignments.sort((aAssignment, bAssignment) =>
          aAssignment.startTime < bAssignment.startTime ? 1 : -1,
        );
      }
    }
    return [];
  };

  return (
    <Table
      showSorterTooltip
      columns={columns}
      dataSource={getQueueAssignments()}
      loading={false}
      rowKey="id"
    />
  );
};

export default QueueAssignmentTable;
