import { createContext } from 'react';

import { TicketingAgingConnectionInput } from '@totem/components/ticketing/agingReport/types';
import {
  TicketingFilterOptions,
  TicketingFilters,
} from '@totem/types/ticketing';
import { TICKETS_ENDPOINT } from '@totem/utilities/endpoints';

interface Context {
  input: TicketingAgingConnectionInput;
  setInput: (input: TicketingAgingConnectionInput) => void;
  setDrillDownValue: (drillDownValue: string) => void;
  loading: boolean;
  filterOptionsLoaded: boolean;
  filterOptions: TicketingFilterOptions;
  reportType: string;
  chartEndPoint: string;
  drillDownEndPoint: string;
  staticFilters: TicketingFilters;
  drillDownValue: string;
}

export default createContext<Context>({
  input: {},
  setInput: () => {},
  setDrillDownValue: () => {},
  loading: false,
  filterOptions: {
    type: [],
    priority: [],
    status: [],
    subStatus: [],
    categories: [],
    building: [],
    organization: [],
    device: [],
  },
  reportType: 'updated',
  chartEndPoint: `${TICKETS_ENDPOINT}/ticketAging`,
  drillDownEndPoint: `${TICKETS_ENDPOINT}/ticketAging/summary`,
  staticFilters: {},
  drillDownValue: null,
  filterOptionsLoaded: false,
});
