import React, { useContext, useState } from 'react';
import { EditTwoTone } from '@ant-design/icons';
import { Button, Card } from 'antd';

import BuildingDetailContext from '@totem/components/buildingDetail/BuildingDetailContext';
import SideBarTitle from '@totem/components/SideBarTitle';
import colors from '@totem/styles/colors';
import addressUtilities from '@totem/utilities/addressUtilities';

import EditBuildingModal from './EditBuildingModal';

import './sidebar.css';

const styles = {
  card: {
    width: '100%',
    marginBottom: '3.5rem',
  },
  cardHeader: {
    borderTop: `5px solid`,
    borderImage: `${colors.gradient.accent} 100% 0 0 0`,
    //borderImage: `${colors.gradient.turquoise} 100% 0 0 0`,
  },
  cardBody: {
    padding: '2.4rem',
    paddingBottom: '0',
    borderTop: `1px solid ${colors.antd.borderGray}`,
  },
};

const BuildingInfo = () => {
  const { building } = useContext(BuildingDetailContext);

  const [open, setOpen] = useState<boolean>(false);

  const address = addressUtilities.getAddress(building);

  return (
    <>
      <Card
        title={
          <SideBarTitle>
            <div>Building Detail</div>
            <Button
              shape="circle"
              icon={<EditTwoTone twoToneColor={colors.brand.blue} />}
              onClick={() => setOpen(true)}
            />
          </SideBarTitle>
        }
        style={styles.card}
        headStyle={styles.cardHeader}
        bodyStyle={styles.cardBody}
      >
        <>
          <div styleName="info-row">
            <div styleName="info-label">Building Name</div>
            <div styleName="info-content">{building.name}</div>
          </div>
          <div styleName="info-row">
            <div styleName="info-label">Address</div>
            <div styleName="info-content">{address}</div>
          </div>
          {typeof building.type !== 'undefined' &&
            building.type !== null &&
            building.type !== '' && (
              <div styleName="info-row">
                <div styleName="info-label">Type</div>
                <div styleName="info-content">{building.type}</div>
              </div>
            )}
          {typeof building.squareFootage !== 'undefined' &&
            building.squareFootage !== null &&
            building.squareFootage !== 0 && (
              <div styleName="info-row">
                <div styleName="info-label">Square Footage</div>
                <div styleName="info-content">{building.squareFootage.toLocaleString()}</div>
              </div>
            )}
        </>
      </Card>
      <EditBuildingModal open={open} setOpen={setOpen} />
    </>
  );
};

export default BuildingInfo;
