import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
// @ts-nocheck
import axios from 'axios';
import Cookies from 'universal-cookie';
var getCookies = function getCookies() {
  return new Cookies();
};
var jwtToken = getCookies().get('access_token');
axios.defaults.headers.common.Authorization = "Bearer ".concat(jwtToken);
var setAuthToken = function setAuthToken() {
  var cookies = function cookies() {
    return new Cookies();
  };
  var token = cookies().get('access_token');
  axios.defaults.headers.common.Authorization = "Bearer ".concat(token);
};
export function get(endpoint, params) {
  return axios.get(endpoint, _objectSpread({}, params && {
    params: params
  }));
}
export function head(endpoint, params) {
  return axios.head(endpoint, _objectSpread({}, params && {
    params: params
  }));
}
export function put(endpoint, data, params) {
  return axios.put(endpoint, data, _objectSpread({}, params && {
    params: params
  }));
}
export function post(endpoint, data, params) {
  return axios.post(endpoint, data, _objectSpread({}, params && {
    params: params
  }));
}
export function patch(endpoint, data, params) {
  return axios.patch(endpoint, data, _objectSpread({}, params && {
    params: params
  }));
}
export function deleteApi(endpoint, params) {
  return axios["delete"](endpoint, _objectSpread({}, params && {
    params: params
  }));
}
var api = {
  get: get,
  head: head,
  put: put,
  post: post,
  patch: patch,
  "delete": deleteApi,
  setAuthToken: setAuthToken
};
export default api;