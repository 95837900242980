import colors from '@totem/styles/colors';
import {
  Health,
  HEALTH_STATUS_ENUM,
  HealthStatusOptions,
} from '@totem/types/health';

export type BadgeStatus = 'success' | 'warning' | 'error' | 'default';

export function getHealthStatusStringFromStatus(status: number) {
  const option = Object.keys(HEALTH_STATUS_ENUM).find(
    key => HEALTH_STATUS_ENUM[key] === status,
  );

  return option || 'Needed';
}

export function getHealthStatusString(health?: Health): string {
  if (!health) {
    return 'Needed';
  }

  return getHealthStatusStringFromStatus(health.status);
}

export function getBadgeStatus(health?: Health): BadgeStatus {
  if (!health) {
    return 'default';
  }

  const status = getHealthStatusString(health);

  if (status === HealthStatusOptions.ON_TRACK) {
    return 'success';
  }

  if (status === HealthStatusOptions.AT_RISK) {
    return 'warning';
  }

  if (status === HealthStatusOptions.OFF_TRACK) {
    return 'error';
  }

  return 'default';
}

export const getHealthStatusColor = (status?: number) => {
  switch (status) {
    case 0:
      return colors.health.onTrack;
    case 1:
      return colors.health.atRisk;
    case 2:
      return colors.health.offTrack;
    default:
      return colors.health.needed;
  }
};
