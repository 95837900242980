import React from 'react';

import BasePane from '@totem/components/BasePane';
import CardBody from '@totem/components/CardBody';
import ContentLayout from '@totem/components/ContentLayout';
import EventGraphsContainer from '@totem/components/events/graphs/EventGraphsContainer';
import EventGraphsLayout from '@totem/components/events/graphs/EventGraphsLayout';
import PrimaryContentPane from '@totem/components/PrimaryContentPane';

const EventGraphsPage = () => {
  return (
    <ContentLayout>
      <EventGraphsContainer>
        <BasePane>
          <PrimaryContentPane>
            <CardBody>
              <EventGraphsLayout />
            </CardBody>
          </PrimaryContentPane>
        </BasePane>
      </EventGraphsContainer>
    </ContentLayout>
  );
};

export default EventGraphsPage;
