import { Breadcrumb } from '@totem/types/breadcrumb';
import { isNotNull } from '@totem/utilities/common';

export const getCalculatedBreadcrumb = (from: string) => {
  if (isNotNull(from)) {
    const components = from.split(':');

    if (components.length === 3) {
      const objectType = components[0];
      const objectID = components[1];
      const objectName = components[2];

      switch (objectType) {
        case 'device': {
          const breadcrumb: Breadcrumb = {
            label: objectName,
            link: `/dashboard/devices/${objectID}`,
          };

          return breadcrumb;
        }
        case 'catalog': {
          const breadcrumb: Breadcrumb = {
            label: 'Software Catalog',
            link: `/dashboard/software`,
          };

          return breadcrumb;
        }
        default:
          return null;
      }
    }
  }

  return null;
};
