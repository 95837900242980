import React, { useEffect, useState } from 'react';
import { DatePicker, DatePickerProps, Modal } from 'antd';

import { PolicyAudit, PolicyAuditUpdateInput } from '@totem/types/policyAudit';
import { ParseDate } from '@totem/utilities/dateUtilities';

import './policyAudits.css';

export enum DateModalType {
  START = 'START',
  DUE = 'DUE',
}

type Props = {
  policyAudit: PolicyAudit;
  visible: boolean;
  loading: boolean;
  type: DateModalType;
  onSubmit: (input: PolicyAuditUpdateInput) => void;
  onCancel: () => void;
};

const styles = {
  picker: {
    width: '22rem',
  },
};

const DateModal = ({
  policyAudit,
  type,
  visible,
  loading,
  onSubmit,
  onCancel,
}: Props) => {
  const { id, startDate, dueDate } = policyAudit;
  const [date, setDate] = useState<number>(0);

  useEffect(() => {
    if (!visible) {
      setDate(0);
    }
  }, [visible]);

  const typeTitle = type === DateModalType.START ? 'Start' : 'Due';

  const handleSubmit = () => {
    const property = type === DateModalType.START ? 'startDate' : 'dueDate';

    onSubmit({ id, [property]: date });
  };

  const handleChange: DatePickerProps['onChange'] = (newDate, dateString) => {
    setDate(newDate ? newDate.valueOf() : 0);
  };

  const currentlySetDate = type === DateModalType.START ? startDate : dueDate;

  return (
    <Modal
      title={`Set New ${typeTitle} Date: Policy Audit`}
      open={visible}
      onOk={handleSubmit}
      onCancel={onCancel}
      confirmLoading={loading}
      okText="Save"
      okButtonProps={{
        disabled: !date,
      }}
    >
      <div>
        {Boolean(currentlySetDate) && (
          <React.Fragment>
            <div styleName="current-date-label">{`Current ${typeTitle} Date`}</div>
            <div styleName="current-date">
              {new Date(currentlySetDate).toLocaleDateString()}
            </div>
          </React.Fragment>
        )}
        <div styleName="date-field-label">
          {` Set a new ${typeTitle.toLowerCase()} date`}
        </div>
        <DatePicker
          // @ts-ignore
          value={date ? ParseDate(date) : null}
          onChange={handleChange}
          style={styles.picker}
          placeholder="Select Date"
        />
      </div>
    </Modal>
  );
};

export default DateModal;
