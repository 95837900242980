import React, { useState } from 'react';
import { Button } from 'antd';

import TenantBuildingLocationAddDialog from '@totem/components/tenants/tenantsPage/TenantBuildingLocationAddDialog';
import { TenantBuilding } from '@totem/components/tenants/types';
import IBDIcon from '@totem/styles/Icon';

type Props = {
  tenantId: string;
  building: TenantBuilding;
};

const TenantBuildingLocationAddButton = ({ tenantId, building }: Props) => {
  const [showAdd, setShowAdd] = useState(false);

  return (
    <>
      <Button icon={<IBDIcon name="Add" />} onClick={() => setShowAdd(true)}>
        Add Location
      </Button>
      {showAdd === true && (
        <TenantBuildingLocationAddDialog
          tenantId={tenantId}
          building={building}
          open={showAdd}
          onClose={() => setShowAdd(false)}
        />
      )}
    </>
  );
};

export default TenantBuildingLocationAddButton;
