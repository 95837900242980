// enum of UI label to GQL enum value
export enum ControlSystemBackupSource {
  FILE_UPLOAD = 'FILE_UPLOAD',
  MANUAL_CONFIRMATION = 'MANUAL_CONFIRMATION',
  NIAGARA = 'NIAGARA',
  ACRONIS = 'ACRONIS',
}

// enum labels for UI
export const controlSystemBackupSourceLabels: {
  [key in ControlSystemBackupSource]: string;
} = {
  FILE_UPLOAD: 'File Upload',
  MANUAL_CONFIRMATION: 'Manually Confirmed',
  NIAGARA: 'Niagara',
  ACRONIS: 'Acronis',
};

export type ControlSystemBackup = {
  id: string;
  originalFileName: string;
  name: string;
  checksum: string;
  source: ControlSystemBackupSource;
  reporter: string;
  size?: number;
  createdAt?: string;
  updatedAt?: string;
  eventSeverity?: number;
  __typename?: 'ControlSystemBackup';
};

export type ControlSystemBackupsConnection = {
  totalCount: number;
  controlSystemBackups: ControlSystemBackup[];
};

export type ControlSystemBackupsConnectionInput = {
  limit?: number;
  offset?: number;
};
