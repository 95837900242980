import React from 'react';
import { Col, Flex, Row } from 'antd';

import BasePane from '@totem/components/BasePane';
import ContentLayout from '@totem/components/ContentLayout';
import PrimaryContentPane from '@totem/components/PrimaryContentPane';
import CountBadge from '@totem/components/vendors/CountBadge';
import MergeButton from '@totem/components/vendors/MergeButton';
import VendorContainer from '@totem/components/vendors/VendorContainer';
import VendorFilters from '@totem/components/vendors/VendorFilters';
import VendorTable from '@totem/components/vendors/VendorTable';

const styles = {
  inset: {
    marginLeft: '20px',
    marginRight: '20px',
  },
};

const VendorsPage = () => {
  return (
    <ContentLayout>
      <BasePane>
        <PrimaryContentPane>
          <VendorContainer>
            <div style={styles.inset}>
              <br />
              <VendorFilters />
              <br />
              <br />
              <Row>
                <Col span={12}>
                  <Flex justify={'flex-start'} align={'center'}>
                    <MergeButton isPrimary />
                  </Flex>
                </Col>
                <Col span={12}>
                  <Flex justify={'flex-end'} align={'center'}>
                    <CountBadge />
                  </Flex>
                </Col>
              </Row>
              <br />
              <VendorTable />
            </div>
          </VendorContainer>
        </PrimaryContentPane>
      </BasePane>
    </ContentLayout>
  );
};

export default VendorsPage;
