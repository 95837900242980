import React from 'react';
import ReactJson from 'react-json-view'

type Props = {
  value: object;
};

const Json = ({ value }: Props) => {
  return (
    <ReactJson src={value} />
    //<pre>{isNotNull(value) ? JSON.stringify(value, null, 2) : ''}</pre>
  );
};

export default Json;
