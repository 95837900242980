import * as R from 'ramda';

import { PolicyAudit } from '@totem/types/policyAudit';
import { Assignee, User } from '@totem/types/user';

export const auditAssigneesSelector = (policyAudits: PolicyAudit[]) =>
  policyAudits.reduce((acc: Assignee[], { assignee }) => {
    if (R.isNil(assignee)) {
      return acc;
    }

    const exists = acc.some(({ email }) => email === assignee.email);

    return exists ? acc : [...acc, assignee];
  }, []);

export const initialSelectedEmails = (assignees: Assignee[]) => {
  return new Set([...assignees.map(assignee => assignee.email)]);
};

export const buildMailtoLink = (
  userProfile: User,
  selectedEmails: Set<string>,
) => {
  return `mailto:${userProfile.email}?bcc=${[...selectedEmails].join(';')}`;
};
