import * as R from 'ramda';

export const removeIntegerKeys = (obj: object): object => {
  const integerKeys = R.keys(obj).filter(key => !Number.isNaN(Number(key)));
  return R.omit(integerKeys, obj);
};

export const toDropdownFilter = (
  obj: object,
  sort = true,
): { label: string; value: string | number }[] => {
  const filters = Object.entries(removeIntegerKeys(obj)).map(
    ([label, value]) => ({
      label,
      value,
    }),
  );

  // TODO: Revisit this @ts-ignore
  // @ts-ignore
  return sort ? R.sortBy(({ value }) => obj[value])(filters) : filters;
};

export const toTableFilter = (
  obj: object,
  sort = true,
): { text: string; value: string | number }[] => {
  const filters = Object.entries(removeIntegerKeys(obj)).map(
    ([text, value]) => ({
      text,
      value,
    }),
  );

  // TODO: Revisit this @ts-ignore
  // @ts-ignore
  return sort ? R.sortBy(({ value }) => obj[value])(filters) : filters;
};
