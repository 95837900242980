import React, { useContext, useEffect, useState } from 'react';
import { Button, Form, Modal, Select } from 'antd';

import ModalFooter from '@totem/components/ModalFooter';
import ModalTitle from '@totem/components/ModalTitle';
import UserProfileContext from '@totem/components/UserProfileContext';
import { Reference } from '@totem/types/common';
import { OrganizationHierarchy } from '@totem/types/organization';
import { getToken } from '@totem/utilities/accountUtilities';
import { ORGANIZATION_HIERARCHY_ENDPOINT } from '@totem/utilities/endpoints';
import { getRegions } from '@totem/utilities/organization';

const { Option } = Select;
const FormItem = Form.Item;

export const EMPTY_ID = '000000000000000000000000';

const styles = {
  form: {
    width: '100%',
  },
  formItem: {
    paddingBottom: '0',
    marginBottom: '1rem',
  },
};

type Props = {
  isVisible: boolean;
  onClose: () => void;
  onSubmit: (ref: Reference) => void;
  references?: Reference;
  showOrganization: boolean;
  showRegion: boolean;
  showBuilding: boolean;
  showControlSystem: boolean;
  title: string;
};

const ReferencesModal = ({
  isVisible,
  onClose,
  onSubmit,
  references,
  showOrganization,
  showRegion,
  showBuilding,
  showControlSystem,
  title,
}: Props) => {
  const { userProfile } = useContext(UserProfileContext);
  const [orgHierarchy, setOrgHierarchy] = useState<OrganizationHierarchy>(null);
  const [loadingCount, setLoadingCount] = useState<number>(0);
  const [organizationId, setOrganizationId] = useState<string>(EMPTY_ID);
  const [regionId, setRegionId] = useState<string>(EMPTY_ID);
  const [buildingId, setBuildingId] = useState<string>(EMPTY_ID);
  const [controlSystemId, setControlSystemId] = useState<string>(EMPTY_ID);

  const [regions, setRegions] = useState<OrganizationHierarchy[]>([]);
  const [buildings, setBuildings] = useState<OrganizationHierarchy[]>([]);
  const [controlSystems, setControlSystems] = useState<OrganizationHierarchy[]>(
    [],
  );

  const [selectedRegion, setSelectedRegion] =
    useState<OrganizationHierarchy>(null);
  const [selectedBuilding, setSelectedBuilding] =
    useState<OrganizationHierarchy>(null);
  const [selectedControlSystem, setSelectedControlSystem] =
    useState<OrganizationHierarchy>(null);

  useEffect(() => {
    if (typeof references !== 'undefined' && references !== null) {
      if (references.organizationId !== null) {
        setOrganizationId(references.organizationId);
        if (references.regionId !== null) {
          setRegionId(references.regionId);
          // eslint-disable-next-line max-depth
          if (references.buildingId !== null) {
            setBuildingId(references.buildingId);
            // eslint-disable-next-line max-depth
            if (references.controlSystemId !== null) {
              setControlSystemId(references.controlSystemId);
            }
          }
        }
      }
    }
  }, [references]);

  useEffect(() => {
    setLoadingCount(loadingCount + 1);
    fetch(`${ORGANIZATION_HIERARCHY_ENDPOINT}/${organizationId}`, {
      headers: new Headers({
        Authorization: `Bearer ${getToken()}`,
      }),
    })
      .then((res) => res.json())
      .then((result) => {
        setOrgHierarchy(result.hierarchy);
      })
      .then(() => setLoadingCount(loadingCount - 1));
  }, [organizationId]);

  useEffect(() => {
    const regionOptions = getRegions(orgHierarchy);
    if (regionOptions !== null) {
      setRegions(
        regionOptions.sort((option1, option2) => {
          if (option1.name < option2.name) {
            return -1;
          }
          if (option1.name > option2.name) {
            return 1;
          }
          return 0;
        }),
      );
    } else {
      setRegions([]);
    }
  }, [orgHierarchy]);

  const handleSubmit = () => {
    const ref: Reference = {
      organizationId,
      regionId,
      buildingId,
      controlSystemId,
    };

    onSubmit(ref);
  };

  const handleOrganizationChanged = (selectedOrganizationId: string) => {
    setOrganizationId(selectedOrganizationId);
    setRegionId(EMPTY_ID);
    setBuildingId(EMPTY_ID);
    setControlSystemId(EMPTY_ID);
    setSelectedRegion(null);
    setSelectedBuilding(null);
    setSelectedControlSystem(null);
  };

  const handleRegionChanged = (selectedRegionId: string) => {
    if (selectedRegionId === '' || selectedRegionId === EMPTY_ID) {
      setRegionId(EMPTY_ID);
      setSelectedRegion(null);
      setSelectedBuilding(null);
      setSelectedControlSystem(null);
      setBuildingId(EMPTY_ID);
      setControlSystemId(EMPTY_ID);
    } else {
      const foundRegion = regions.find(
        (region) => region.id === selectedRegionId,
      );
      setRegionId(selectedRegionId);
      setSelectedRegion(foundRegion);
      setSelectedBuilding(null);
      setSelectedControlSystem(null);
      setBuildings(
        foundRegion.children.sort((option1, option2) => {
          if (option1.name < option2.name) {
            return -1;
          }
          if (option1.name > option2.name) {
            return 1;
          }
          return 0;
        }),
      );
      setControlSystems(null);
    }
  };

  const handleBuildingChanged = (selectedBuildingId: string) => {
    if (selectedBuildingId === '' || selectedBuildingId === EMPTY_ID) {
      setBuildingId(EMPTY_ID);
      setSelectedBuilding(null);
      setSelectedControlSystem(null);
    } else {
      const foundBuilding = buildings.find(
        (building) => building.id === selectedBuildingId,
      );
      setBuildingId(selectedBuildingId);
      setSelectedBuilding(foundBuilding);
      setSelectedControlSystem(null);
      setControlSystems(
        foundBuilding.children.sort((option1, option2) => {
          if (option1.name < option2.name) {
            return -1;
          }
          if (option1.name > option2.name) {
            return 1;
          }
          return 0;
        }),
      );
    }
  };
  const handleControlSystemChanged = (selectedControlSystemId: string) => {
    if (
      selectedControlSystemId === '' ||
      selectedControlSystemId === EMPTY_ID
    ) {
      setControlSystemId(EMPTY_ID);
      setSelectedControlSystem(null);
    } else {
      const foundControlSystem = controlSystems.find(
        (controlSystem) => controlSystem.id === selectedControlSystemId,
      );
      setControlSystemId(selectedControlSystemId);
      setSelectedControlSystem(foundControlSystem);
    }
  };

  return (
    <Modal
      open={isVisible}
      onCancel={onClose}
      title={<ModalTitle>{title}</ModalTitle>}
      okText="Add"
      confirmLoading={loadingCount > 0}
      footer={
        <ModalFooter>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            onClick={handleSubmit}
            loading={loadingCount > 0}
            type="primary"
          >
            Add
          </Button>
        </ModalFooter>
      }
    >
      <Form layout="vertical" style={styles.form}>
        <FormItem
          label="Organization"
          colon={false}
          style={styles.formItem}
          hidden={!showOrganization}
        >
          <Select
            onChange={handleOrganizationChanged}
            defaultValue={organizationId !== null ? organizationId : EMPTY_ID}
            value={organizationId !== null ? organizationId : EMPTY_ID}
            style={{ width: '100%' }}
          >
            <Option key="*" value={EMPTY_ID}>
              All
            </Option>
            {userProfile.organizations !== null &&
              userProfile.organizations.map((org) => (
                <Option key={org.id} value={org.id}>
                  {org.name}
                </Option>
              ))}
          </Select>
        </FormItem>
        <FormItem
          label="Region"
          colon={false}
          style={styles.formItem}
          hidden={!showRegion}
        >
          <Select
            onChange={handleRegionChanged}
            defaultValue={
              selectedRegion !== null ? selectedRegion.id : EMPTY_ID
            }
            value={selectedRegion !== null ? selectedRegion.id : EMPTY_ID}
            style={{ width: '100%' }}
          >
            <Option key="*" value={EMPTY_ID}>
              All
            </Option>
            {regions !== null &&
              regions.map((region) => (
                <Option key={region.id} value={region.id}>
                  {region.name}
                </Option>
              ))}
          </Select>
        </FormItem>
        <FormItem
          label="Building"
          colon={false}
          style={styles.formItem}
          hidden={!showBuilding}
        >
          <Select
            onChange={handleBuildingChanged}
            defaultValue={
              selectedBuilding !== null ? selectedBuilding.id : EMPTY_ID
            }
            value={selectedBuilding !== null ? selectedBuilding.id : EMPTY_ID}
            style={{ width: '100%' }}
          >
            <Option key="*" value={EMPTY_ID}>
              All
            </Option>
            {buildings !== null &&
              buildings.map((building) => (
                <Option key={building.id} value={building.id}>
                  {building.name}
                </Option>
              ))}
          </Select>
        </FormItem>
        <FormItem
          label="Control System"
          colon={false}
          style={styles.formItem}
          hidden={!showControlSystem}
        >
          <Select
            onChange={handleControlSystemChanged}
            defaultValue={
              selectedControlSystem !== null
                ? selectedControlSystem.id
                : EMPTY_ID
            }
            value={
              selectedControlSystem !== null
                ? selectedControlSystem.id
                : EMPTY_ID
            }
            style={{ width: '100%' }}
          >
            <Option key="*" value={EMPTY_ID}>
              All
            </Option>
            {controlSystems !== null &&
              controlSystems.map((controlSystem) => (
                <Option key={controlSystem.id} value={controlSystem.id}>
                  {controlSystem.name}
                </Option>
              ))}
          </Select>
        </FormItem>
      </Form>
    </Modal>
  );
};

export default ReferencesModal;
