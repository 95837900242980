import { createContext } from 'react';

import {
  ControlSystem,
  ControlSystemsConnectionInput,
} from '@totem/types/controlSystem';
import { AutoCompleteFilter } from '@totem/types/hooks';

interface Context {
  input: ControlSystemsConnectionInput;
  setInput: (input: ControlSystemsConnectionInput) => void;
  controlSystems: ControlSystem[];
  totalCount: number;
  loading: boolean;
  buildingFilter: AutoCompleteFilter;
  regionFilter: AutoCompleteFilter;
}

export default createContext<Context>({
  input: {},
  setInput: () => {},
  controlSystems: [],
  totalCount: 0,
  loading: false,
  buildingFilter: null,
  regionFilter: null,
});
