import * as R from 'ramda';

export const stringifyArray = (value?: any[]): string[] =>
  R.isNil(value) ? [] : R.map(String, value);

export const nullifyIfEmpty = (value?: any): any => {
  return R.isNil(value) || R.isEmpty(value) ? null : value;
};

export const stringifyBooleanValue = (value?: boolean): string[] =>
  R.isNil(value) ? [] : [value ? '1' : '0'];

export const parseBooleanString = (value?: string[]): boolean =>
  R.isNil(value) || R.isEmpty(value) ? null : value[0] !== '0';
