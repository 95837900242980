import React from 'react';
import { useParams } from 'react-router-dom';
import { Tabs, TabsProps } from 'antd';

import BasePane from '@totem/components/BasePane';
import CardBody from '@totem/components/CardBody';
import CardHeader from '@totem/components/CardHeader';
import ContentLayout from '@totem/components/ContentLayout';
import PrimaryContentPane from '@totem/components/PrimaryContentPane';
import CalendarTimeline from '@totem/components/remoteAccess/userDetails/CalendarTimeline';
import HeaderAction from '@totem/components/remoteAccess/userDetails/HeaderAction';
import RemoteAccessRolesTable from '@totem/components/remoteAccess/userDetails/RemoteAccessRolesTable';
import RemoteAccessSessionsTable from '@totem/components/remoteAccess/userDetails/RemoteAccessSessionsTable';
import RemoteAccessUserDetailsContainer from '@totem/components/remoteAccess/userDetails/RemoteAccessUserDetailsContainer';
import RemoteAccessUserTitle from '@totem/components/remoteAccess/userDetails/RemoteAccessUserTitle';
import TabTitle from '@totem/components/TabTitle';

const RemoteAccessUserDetails = () => {
  const { id } = useParams();
  const tabItems: TabsProps['items'] = [
    {
      key: 'roles',
      label: <TabTitle>Roles</TabTitle>,
      children: <RemoteAccessRolesTable />,
    },
    {
      key: 'sessions',
      label: <TabTitle>Sessions</TabTitle>,
      children: (
        <>
          <RemoteAccessSessionsTable />
          <br />
          <CalendarTimeline />
        </>
      ),
    },
  ];

  return (
    <ContentLayout>
      <RemoteAccessUserDetailsContainer
        userId={id}
        refresh={false}
        onRecordTotalChanged={() => {}}
        onDataRefreshRequested={() => {}}
      >
        <BasePane>
          <PrimaryContentPane>
            <CardHeader>
              <RemoteAccessUserTitle />
              <HeaderAction />
            </CardHeader>
            <CardBody>
              <Tabs defaultActiveKey="roles" items={tabItems} />
            </CardBody>
          </PrimaryContentPane>
        </BasePane>
      </RemoteAccessUserDetailsContainer>
    </ContentLayout>
  );
};

export default RemoteAccessUserDetails;
