import React from 'react';
import { Flex } from 'antd';

import AddSurveyButton from '@totem/components/surveyV2/instances/AddSurveyButton';
import ExportButton from '@totem/components/surveyV2/instances/ExportButton';
import LaunchButton from '@totem/components/surveyV2/instances/LaunchButton';

const styles = {
  container: {
    padding: 10,
  },
};
const SurveyInstanceHeader = () => {
  return (
    <Flex
      justify={'space-between'}
      align={'flex-start'}
      style={styles.container}
    >
      <div>&nbsp;</div>
      <Flex justify={'flex-end'} align={'flex-start'} gap={16}>
        <ExportButton />
        <LaunchButton />
        <AddSurveyButton />
      </Flex>
    </Flex>
  );
};

export default SurveyInstanceHeader;
