import { createContext } from 'react';

import {
  ControlSystem,
  ControlSystemsConnectionInput,
} from '@totem/types/controlSystem';
import { AutoCompleteFilter } from '@totem/types/hooks';

interface Context {
  input: ControlSystemsConnectionInput;
  setInput: (input: ControlSystemsConnectionInput) => void;
  loading: boolean;
  controlSystems: ControlSystem[];
  totalCount: number;
  refetch: () => Promise<any>;
  buildingFilter: AutoCompleteFilter;
  regionFilter: AutoCompleteFilter;
}

export default createContext<Context>({
  input: {},
  setInput: () => {},
  loading: false,
  controlSystems: [],
  totalCount: 0,
  refetch: Promise.resolve,
  buildingFilter: null,
  regionFilter: null,
});
