import React, { useCallback, useContext, useState } from 'react';
import {
  CloseCircleFilled,
  MinusCircleOutlined,
  SendOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import { Button, Card, Modal } from 'antd';

import CardHeader from '@totem/components/CardHeader';
import CardTitle from '@totem/components/CardTitle';
import UserAvatar from '@totem/components/common/avatar/UserAvatar';
import AssigneeModal from '@totem/components/surveyV2/instanceDetail/AssigneeModal';
import CardBody from '@totem/components/surveyV2/instanceDetail/CardBody';
import SurveyContext from '@totem/components/surveyV2/instanceDetail/SurveyInstanceContext';
import { SurveyQueryResult } from '@totem/components/surveyV2/types';
import colors from '@totem/styles/colors';
import fonts from '@totem/styles/fonts';
import { User } from '@totem/types/user';
import { getToken } from '@totem/utilities/accountUtilities';
import { V2_SURVEY_ENDPOINT } from '@totem/utilities/endpoints';
import { CheckResponseShowError } from '@totem/utilities/responseUtilities';
import { getUserName, isUserDeactivated } from '@totem/utilities/userUtilities';

import '../survey.css';

const styles = {
  avatar: {
    marginRight: '2rem',
    fontSize: fonts.fontMd,
  },
  icon: {
    cursor: 'pointer',
    color: colors.neutral.gray,
    verticalAlign: 'initial',
    align: 'right',
    paddingLeft: '8px',
  },
  deleteIcon: {
    color: colors.utility.error,
  },
};

const AssigneeCard = () => {
  const [isNotificationSending, setIsNotificationSending] =
    useState<boolean>(false);
  const [isAssigneeSending, setIsAssigneeSending] = useState<boolean>(false);
  const [showAssigneeDialog, setShowAssigneeDialog] = useState<boolean>(false);
  const { data, setData } = useContext(SurveyContext);
  const { survey, assignedUser } = data;

  const isDeactivated = isUserDeactivated(assignedUser);

  const showResendNotificaitonIcon = () => {
    if (survey.isLaunched && !survey.isSubmitted) {
      return true;
    }
    return false;
  };

  const sendSurveyNotification = useCallback(async (surveyId: string) => {
    if (isNotificationSending) {
      return;
    }
    setIsNotificationSending(true);
    const payload = JSON.stringify({});

    fetch(`${V2_SURVEY_ENDPOINT}/instance/${surveyId}/notification`, {
      method: 'POST',
      headers: new Headers({
        Authorization: `Bearer ${getToken()}`,
      }),
      body: JSON.stringify(payload),
    }).then((res) => {
      setIsNotificationSending(false);
      CheckResponseShowError(res);
    });
  }, []);

  const sendRemoveAssignee = useCallback(async (surveyId: string) => {
    if (isAssigneeSending) {
      return;
    }
    setIsAssigneeSending(true);

    fetch(`${V2_SURVEY_ENDPOINT}/instance/${surveyId}/assignee`, {
      method: 'DELETE',
      headers: new Headers({
        Authorization: `Bearer ${getToken()}`,
      }),
    })
      .then((res) => res.json())
      .then((result: SurveyQueryResult) => {
        setIsAssigneeSending(false);
        setData(result);
      });
  }, []);

  const confirmRemoveAssignee = (user: User) => {
    const { confirm } = Modal;

    confirm({
      title: 'Remove Assignee',
      content: `Are you sure you would like to remove ${getUserName(
        user,
      )} from this survey?`,
      okType: 'danger',
      okText: 'Delete',
      icon: <CloseCircleFilled style={styles.deleteIcon} />,
      onOk: () => sendRemoveAssignee(survey.id),
    });
  };

  const confirmResendAssigneeNotification = (user: User) => {
    const { confirm } = Modal;

    confirm({
      title: 'Resend Notification',
      content: `Are you sure you would like to resend the notification to ${getUserName(
        user,
      )} for this survey?`,
      okType: 'primary',
      okText: 'Send',
      icon: <CloseCircleFilled style={styles.deleteIcon} />,
      onOk: () => sendSurveyNotification(data.survey.id),
    });
  };

  return (
    <>
      <Card
        title={
          <CardHeader>
            <CardTitle>Assignee</CardTitle>
            <Button
              icon={<TeamOutlined />}
              onClick={() => setShowAssigneeDialog(!showAssigneeDialog)}
            >
              Edit Assignee
            </Button>
          </CardHeader>
        }
        bordered={false}
      >
        <CardBody styleName="scroll">
          {assignedUser && (
            <div styleName="user-list-item">
              <div styleName="user-list-name">
                <UserAvatar
                  size={30}
                  style={styles.avatar}
                  user={assignedUser}
                />
                <div
                  styleName="user-name"
                  style={
                    isDeactivated ? { color: colors.neutral.inactive } : {}
                  }
                >
                  {isDeactivated
                    ? `${assignedUser.email} (Inactive)`
                    : assignedUser.email}
                </div>
              </div>
              <div>
                <MinusCircleOutlined
                  style={styles.icon}
                  onClick={() => confirmRemoveAssignee(assignedUser)}
                  title="Remove Assignee"
                />
                {showResendNotificaitonIcon() && (
                  <SendOutlined
                    style={styles.icon}
                    onClick={() =>
                      confirmResendAssigneeNotification(assignedUser)
                    }
                    title="Resend Notification"
                  />
                )}
              </div>
            </div>
          )}
        </CardBody>
      </Card>
      {showAssigneeDialog && (
        <AssigneeModal
          open={showAssigneeDialog}
          onClose={() => setShowAssigneeDialog(false)}
        />
      )}
    </>
  );
};

export default AssigneeCard;
