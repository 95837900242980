import * as R from 'ramda';
import { v4 as uuidv4 } from 'uuid';

import { Finding } from '@totem/components/surveyV2/findings/types';
import {
  Condition,
  QuestionTypes,
  QuestionValue,
  ScoredCategory,
  SurveyError,
  SurveyInstance,
  SurveyQuestion,
  SurveyTemplate,
} from '@totem/components/surveyV2/types';

export const calculateScore = (maxScore: number, score: number) => {
  return (score / maxScore) * 100;
};

export const isQuestionAnswered = (question: SurveyQuestion): boolean => {
  if (question.type === 'information') {
    return true;
  }

  return question.value.value !== '';
};

function instanceOfQuestionValue(obj: any): obj is QuestionValue {
  if (typeof obj === 'string') {
    return false;
  }
  if (typeof obj === 'object') {
    return 'value' in obj;
  }
  return false;
}

export const createSurveyValue = (value: any): QuestionValue => {
  if (instanceOfQuestionValue(value)) {
    return value;
  }

  if (typeof value === 'string') {
    let valueFloat: number = Number(value);
    let valueInt: number = 0;

    if (isNaN(valueFloat)) {
      valueFloat = 0.0;
    }

    if (Number.isInteger(valueFloat)) {
      valueInt = 0;
    }

    return {
      value,
      valueInt,
      valueFloat,
      valueArray: [],
    };
  } else if (typeof value === 'number') {
    let valueFloat: number = Number(value);
    let valueInt: number = 0;

    if (isNaN(valueFloat)) {
      valueFloat = 0.0;
    }

    let newValue = valueFloat.toString(10);
    if (Number.isInteger(valueFloat)) {
      valueInt = 0;
      newValue = valueInt.toString(10);
    }

    return {
      value: newValue,
      valueInt,
      valueFloat,
      valueArray: [],
    };
  }

  return {
    value: '',
    valueInt: 0,
    valueFloat: 0.0,
    valueArray: [],
  };
};

const validateQuestions = (category: ScoredCategory): SurveyError[] =>
  category.questions.reduce(
    (errors: SurveyError[], question: SurveyQuestion): SurveyError[] => {
      const { required, visible } = question;

      const hasError =
        required &&
        category.visible &&
        visible &&
        !isQuestionAnswered(question);

      return hasError
        ? [
            ...errors,
            {
              categoryId: category.id,
              categoryName: category.name,
              questionId: question.id,
              questionLabel: question.label,
              message: 'This question is required.',
            },
          ]
        : errors;
    },
    [],
  );

export const validateSurvey = ({ categories }: SurveyInstance): SurveyError[] =>
  categories.reduce(
    (errors: SurveyError[], category: ScoredCategory): SurveyError[] => [
      ...errors,
      ...validateQuestions(category),
    ],
    [],
  );

export const getMultiInstanceDisplayName = (
  category: ScoredCategory,
  instance: number,
): string => {
  const { multiInstanceLabel } = category;

  const questions = category.questions.filter(
    (question: SurveyQuestion): boolean =>
      R.equals(question.instance, instance) && question.instanceIdentifier,
  );

  const displayName = questions.reduce(
    (accum: string, { value }: SurveyQuestion): string => {
      const name =
        value.valueArray !== null
          ? value.valueArray
              .filter((val: QuestionValue): boolean => !R.isEmpty(val.value))
              .join(' - ')
          : value.value;

      if (!name) {
        return accum;
      }

      return accum ? `${accum} - ${name}` : name;
    },
    '',
  );

  return displayName || `${multiInstanceLabel} #${instance}`;
};

export const instanceHasAnsweredQuestions = (
  { questions }: ScoredCategory,
  instance: number,
): boolean => {
  return questions
    .filter((question) => question.instance === instance)
    .some((question) => isQuestionAnswered(question));
};

export const deleteInstance = (
  category: ScoredCategory,
  instanceToDelete: number,
): SurveyQuestion[] => {
  const { questions } = category;

  return questions
    .filter(
      ({ instance }: SurveyQuestion): boolean => instance !== instanceToDelete,
    )
    .map((question: SurveyQuestion): SurveyQuestion => {
      const instance = R.lt(question.instance, instanceToDelete)
        ? question.instance
        : R.dec(question.instance);
      return { ...question, instance };
    });
};

export const createNewInstance = (
  category: ScoredCategory,
  questionTypes: SurveyQuestion[],
): SurveyQuestion[] => {
  const { questions } = category;

  const originalQuestions = questions.filter(
    ({ instance }: SurveyQuestion): boolean => R.equals(instance, 1),
  );
  const lastInstance = Math.max(
    ...questions.map(({ instance }: SurveyQuestion): number => instance),
  );
  const instance = R.inc(lastInstance);

  const newQuestions = originalQuestions.map(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ({ updatedAt, updatedBy, ...question }: SurveyQuestion): SurveyQuestion => {
      const id = uuidv4();
      const additionalContext = '';
      const questionType = questionTypes.find(
        ({ type }: SurveyQuestion): boolean => type === question.type,
      );
      const value = questionType ? questionType.value : createSurveyValue('');

      return {
        ...question,
        id,
        instance,
        value,
        additionalContext,
      };
    },
  );

  return [...questions, ...newQuestions];
};

export const getLabelFromQuestionType = (val: string) => {
  for (let idx = 0; idx < QuestionTypes.length; idx++) {
    if (QuestionTypes[idx].value === val) {
      return QuestionTypes[idx].label;
    }
  }

  return 'Undefined';
};

const getTemplateQuestionById = (
  surveyTemplate: SurveyTemplate,
  questionId: string,
): SurveyQuestion => {
  for (const category of surveyTemplate.categories) {
    const question = category.questions.find((qu) => qu.id === questionId);
    if (question) {
      return question;
    }
  }
  return null;
};

export const getQuestions = (template: SurveyTemplate): SurveyQuestion[] => {
  let questions: SurveyQuestion[] = [];

  template.categories.forEach((cat) => {
    questions = questions.concat(cat.questions);
  });

  return questions;
};

export const getConditionDescription = (
  surveyTemplate: SurveyTemplate,
  condition: Condition,
  depth: number = 0,
): string => {
  let description = '';
  const paddingBraces = ''.padStart(depth * 18, '&nbsp;');
  const paddingContent = ''.padStart((depth + 1) * 18, '&nbsp;');

  switch (condition.operation) {
    case 'AND':
    case 'OR':
      description = `${paddingBraces}{<br/>`;
      for (let idx = 0; idx < condition.subconditions.length; idx++) {
        if (idx > 0) {
          description += `${paddingContent + condition.operation}<br/>`;
        }
        description += `${getConditionDescription(
          surveyTemplate,
          condition.subconditions[idx],
          depth + 1,
        )}<br/>`;
      }
      description += `${paddingBraces}}`;
      return description;
    case '==':
    case '!=':
      if (condition.fieldType === 'question') {
        const question = getTemplateQuestionById(
          surveyTemplate,
          condition.fieldId,
        );
        return `${paddingBraces}${question.label || 'Untitled'} 
        ${condition.operation} ${condition.value}`;
      }
      return '';
    default:
      return '';
  }
};

export const createNewCondition = (): Condition => ({
  id: uuidv4(),
  operation: 'NOP',
  fieldType: '',
  fieldId: '',
  value: '',
  subconditions: [],
});

export const createNewGroup = (): Condition => ({
  id: uuidv4(),
  operation: 'AND',
  fieldType: '',
  fieldId: '',
  value: '',
  subconditions: [],
});

export const createNewRule = (): Condition => ({
  id: uuidv4(),
  operation: '==',
  fieldType: '',
  fieldId: '',
  value: '',
  subconditions: [],
});

export const cloneCondition = (condition: Condition): Condition => {
  const newCondition = createNewCondition();
  newCondition.id = condition.id;
  newCondition.operation = condition.operation;
  newCondition.fieldType = condition.fieldType;
  newCondition.fieldId = condition.fieldId;
  newCondition.value = condition.value;

  if (condition.subconditions !== null) {
    newCondition.subconditions = [];
    for (let idx = 0; idx < condition.subconditions.length; idx++) {
      newCondition.subconditions.push(
        cloneCondition(condition.subconditions[idx]),
      );
    }
  }

  return newCondition;
};

export const getPlainTextFinding = (finding: Finding) => {
  return [
    finding.findingDescription,
    finding.moreInfo,
    finding.policy,
    finding.resolution,
  ].join('\n');
};
