import {
  RemoteAccessUser,
  RemoteAccessUserFilter,
  RemoteAccessUsersConnectionInput,
} from '@totem/components/remoteAccess/types';
import { Filter } from '@totem/types/common';
import {
  sortStringAscending,
  sortStringDescending,
} from '@totem/utilities/tableUtilities';

export const buildFilters = (input: RemoteAccessUsersConnectionInput) => {
  const paramFilters: Filter[] = [];

  if (
    typeof input.emailSearchTerm !== 'undefined' &&
    input.emailSearchTerm !== null &&
    input.emailSearchTerm !== ''
  ) {
    paramFilters.push({
      field: 'email',
      operator: 'cscontains',
      values: [input.emailSearchTerm],
    });
  }
  if (
    typeof input.nameSearchTerm !== 'undefined' &&
    input.nameSearchTerm !== null &&
    input.nameSearchTerm !== ''
  ) {
    paramFilters.push({
      field: 'name',
      operator: 'cscontains',
      values: [input.nameSearchTerm],
    });
  }

  return paramFilters;
};

export const addFilters = (
  currentFilters: Filter[],
  input: RemoteAccessUserFilter,
) => {
  if (typeof input !== 'undefined' && input !== null) {
    if (
      typeof input.emailSearchTerm !== 'undefined' &&
      input.emailSearchTerm !== null &&
      input.emailSearchTerm !== ''
    ) {
      currentFilters.push({
        field: 'email',
        operator: 'cscontains',
        values: [input.emailSearchTerm],
      });
    }
    if (
      typeof input.nameSearchTerm !== 'undefined' &&
      input.nameSearchTerm !== null &&
      input.nameSearchTerm !== ''
    ) {
      currentFilters.push({
        field: 'name',
        operator: 'cscontains',
        values: [input.nameSearchTerm],
      });
    }
  }

  return currentFilters;
};

export const sortRemoteAccess = (
  sortField: string,
  sortDirection,
  compA: RemoteAccessUser,
  compB: RemoteAccessUser,
) => {
  switch (sortDirection) {
    case -1:
      switch (sortField) {
        case 'email':
          return sortStringDescending(compA.email, compB.email);
        case 'name':
          return sortStringDescending(compA.name, compB.name);
        case 'created_at':
          return sortStringDescending(compA.created_at, compB.created_at);
        case 'updated_at':
          return sortStringDescending(compA.updated_at, compB.updated_at);
        case 'last_login':
          return sortStringDescending(compA.last_login, compB.last_login);
        case 'last_password_reset':
          return sortStringDescending(
            compA.last_password_reset,
            compB.last_password_reset,
          );
        default:
          return 0;
      }
    default:
      switch (sortField) {
        case 'email':
          return sortStringAscending(compA.email, compB.email);
        case 'name':
          return sortStringAscending(compA.name, compB.name);
        case 'created_at':
          return sortStringAscending(compA.created_at, compB.created_at);
        case 'updated_at':
          return sortStringAscending(compA.updated_at, compB.updated_at);
        case 'last_login':
          return sortStringAscending(compA.last_login, compB.last_login);
        case 'last_password_reset':
          return sortStringAscending(
            compA.last_password_reset,
            compB.last_password_reset,
          );
        default:
          return 0;
      }
  }
};
