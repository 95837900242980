import actionTypes from '@totem/actions/actionTypes';

import initialState from './initialState';

export default function mediaReducer(state = initialState.media, action) {
  switch (action.type) {
    case actionTypes.GET_MEDIA_SUCCESS: {
      return action.data ? [...action.data] : [];
    }

    case actionTypes.UPLOAD_FILES_SUCCESS: {
      return action.data ? [...state, action.data] : state;
    }

    default: {
      return state;
    }
  }
}
