import React, { useEffect, useState } from 'react';

import ContractEditModal from '@totem/components/contracts/edits/ContractEditModal';
import ContractsTable from '@totem/components/contracts/list/ContractsTable';
import { Params } from '@totem/types/common';
import { Contract } from '@totem/types/contracts';
import { getToken } from '@totem/utilities/accountUtilities';
import { CONTRACTS_ENDPOINT } from '@totem/utilities/endpoints';

import '../../settings/settings.css';

const ContractsComponent = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showNewContractModel, setShowNewContractModal] = useState<boolean>(
    false,
  );
  const [contractData, setContractData] = useState<Contract[]>(null);

  const buildParameters = () => {
    const params: Params = {
      paging: {
        page: 1,
        pageSize: 100,
      },
      sort: {
        field: 'createdAt',
        direction: 1,
      },
      filters: [],
    };

    return params;
  };

  useEffect(() => {
    if (showNewContractModel === false) {
      setIsLoading(true);
      const params: Params = buildParameters();

      fetch(`${CONTRACTS_ENDPOINT}`, {
        method: 'POST',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
        body: JSON.stringify(params),
      })
        .then(res => res.json())
        .then((result: Contract[]) => {
          setContractData(result);
        })
        .then(() => {
          setIsLoading(false);
        });
    }
  }, [showNewContractModel]);

  const handleEditModalClosed = () => {
    setShowNewContractModal(false);
  };

  return (
    <div styleName="container">
      <div styleName="header-container">
        <div styleName="header">Contracts</div>
      </div>
      <ContractsTable constractsData={contractData} loading={isLoading} />
      {showNewContractModel && (
        <ContractEditModal
          onClose={handleEditModalClosed}
          visible={showNewContractModel}
        />
      )}
    </div>
  );
};

export default ContractsComponent;
