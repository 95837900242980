import {
  backupAgingTypes,
  backupStatusType,
  backupStatusTypes,
  backupStatusTypeUnknown,
} from '@totem/components/deviceBackups/types';
import { Filter, Params } from '@totem/types/common';
import { DeviceBackupConnectionInput } from '@totem/types/devices';

export const getBackupStatusRecord = (status: string): backupStatusType => {
  for (let idx = 0; idx < backupStatusTypes.length; idx++) {
    if (backupStatusTypes[idx].label === status) {
      return backupStatusTypes[idx];
    }
  }

  return backupStatusTypeUnknown;
};

export const getBackupAging = (status: string): backupStatusType => {
  for (let idx = 0; idx < backupAgingTypes.length; idx++) {
    if (backupAgingTypes[idx].label === status) {
      return backupAgingTypes[idx];
    }
  }

  return backupStatusTypeUnknown;
};

export const buildFilters = (input: DeviceBackupConnectionInput) => {
  const paramFilters: Filter[] = [];

  if (input.regionId !== null && input.regionId.length > 0) {
    paramFilters.push({
      field: 'regionId',
      values: input.regionId,
    });
  }
  if (input.buildingId !== null && input.buildingId.length > 0) {
    paramFilters.push({
      field: 'buildingId',
      values: input.buildingId,
    });
  }
  if (input.controlSystemId !== null && input.controlSystemId.length > 0) {
    paramFilters.push({
      field: 'controlSystemId',
      values: input.controlSystemId,
    });
  }
  if (input.deviceName !== null && input.deviceName !== '') {
    paramFilters.push({
      field: 'displayName',
      values: [input.deviceName],
    });
  }
  if (input.controlSystemName !== null && input.controlSystemName !== '') {
    paramFilters.push({
      field: 'controlSystemName',
      values: [input.controlSystemName],
    });
  }
  if (input.systemType !== null && input.systemType.length > 0) {
    paramFilters.push({
      field: 'controlSystemType',
      values: input.systemType,
    });
  }
  if (input.lastBackupStatus !== null && input.lastBackupStatus.length > 0) {
    paramFilters.push({
      field: 'lastBackupStatus',
      values: input.lastBackupStatus,
    });
  }
  if (input.isBackupStale !== null && input.isBackupStale.length > 0) {
    paramFilters.push({
      field: 'isBackupStale',
      values: input.isBackupStale,
    });
  }

  return paramFilters;
};

export const buildParameters = (input: DeviceBackupConnectionInput) => {
  const params: Params = {
    paging: {
      page: input.page,
      pageSize: input.pageSize,
    },
    sort: {
      field: input.sortField,
      direction: +input.sortDirection,
    },
    filters: buildFilters(input),
  };

  return params;
};
