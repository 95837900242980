import React, { useEffect, useState } from 'react';
import { Badge, Form, Input, Modal, Select } from 'antd';
import * as R from 'ramda';

import {
  HEALTH_STATUS_ENUM,
  HealthInput,
  HealthStatusOptions,
} from '@totem/types/health';
import { BadgeStatus } from '@totem/utilities/healthUtilities';

const FormItem = Form.Item;
const { Option } = Select;

interface Props {
  subjectId: string;
  title: string;
  visible: boolean;
  loading: boolean;
  onClose: () => void;
  onSubmit: (input: HealthInput) => void;
}

const styles = {
  select: {
    width: '14rem',
  },
  formItem: {
    paddingBottom: '0',
    marginBottom: '2rem',
  },
};

const initialState = {
  status: HEALTH_STATUS_ENUM[HealthStatusOptions.ON_TRACK],
  comment: '',
};

const getBadgeStatusByHealthStatus = (status: string): BadgeStatus => {
  if (status === HealthStatusOptions.ON_TRACK) {
    return 'success';
  }

  if (status === HealthStatusOptions.AT_RISK) {
    return 'warning';
  }

  return 'error';
};

const AuditHealthModal = ({
  subjectId,
  title,
  visible,
  loading,
  onClose,
  onSubmit,
}: Props) => {
  const [comment, setComment] = useState<string>(initialState.comment);
  const [status, setStatus] = useState<number>(initialState.status);

  const resetState = () => {
    setComment(initialState.comment);
    setStatus(initialState.status);
  };

  useEffect(() => {
    if (!visible) {
      resetState();
    }
  }, [visible]);

  const handleSubmit = () => {
    onSubmit({
      subjectId,
      status,
      comment,
    });
  };

  return (
    <Modal
      title={title}
      open={visible}
      onOk={handleSubmit}
      onCancel={onClose}
      confirmLoading={loading}
      okText="Save"
      okButtonProps={{
        disabled: R.isEmpty(comment),
      }}
    >
      <Form layout="vertical">
        <FormItem
          colon={false}
          style={styles.formItem}
          label="Set current health"
        >
          <Select
            onChange={updatedStatus => setStatus(updatedStatus)}
            value={status}
            style={styles.select}
          >
            {Object.keys(HEALTH_STATUS_ENUM).map(key => {
              const value: number = HEALTH_STATUS_ENUM[key];

              return (
                <Option key={key} value={value}>
                  <div>
                    <Badge status={getBadgeStatusByHealthStatus(key)} />
                    {key}
                  </div>
                </Option>
              );
            })}
          </Select>
        </FormItem>
        <FormItem colon={false} style={styles.formItem} label="Comment">
          <Input.TextArea
            placeholder="Comment..."
            value={comment}
            onChange={({ target: { value } }) => setComment(value)}
            autoSize={{
              minRows: 1,
            }}
          />
        </FormItem>
      </Form>
    </Modal>
  );
};

export default AuditHealthModal;
