import React, { useMemo } from 'react';
import { ColumnProps, TablePaginationConfig } from 'antd/lib/table';
import * as R from 'ramda';

import Table from '@totem/components/common/table/Table';
import {
  Finding,
  FindingGroup,
  FindingsConnectionInput,
} from '@totem/types/finding';
import { LevelOfEffort } from '@totem/types/levelOfEffort';
import {
  controlSystemTypeMap,
  controlSystemTypeOptions,
} from '@totem/utilities/controlSystemsUtilities';
import {
  criticalityOptions,
  getCriticalityBadge,
} from '@totem/utilities/criticalityUtilities';
import {
  getLimitAndOffsetFromTablePagination,
  getTablePagination,
} from '@totem/utilities/paginationUtilities';

import './findings.css';

interface Props {
  input: FindingsConnectionInput;
  onChange: (input: Partial<FindingsConnectionInput>) => void;
  loading: boolean;
  findings: Finding[];
  totalCount: number;
}

const CONTROL_SYSTEM_FILTERS = controlSystemTypeOptions.map(
  ({ text, value }) => ({
    text,
    value,
  }),
);

const CRITICALITY_FILTERS = criticalityOptions.map(({ text, value }) => ({
  text,
  value,
}));

const FINDING_FILTERS = Object.keys(FindingGroup)
  .filter(key => Number.isNaN(Number(key)))
  .map(key => ({
    text: key,
    value: FindingGroup[key],
  }));

const LOE_FILTERS = Object.keys(LevelOfEffort)
  .filter(key => Number.isNaN(Number(key)))
  .map(key => ({
    text: key,
    value: LevelOfEffort[key],
  }));

const renderDescription = ({ additionalData }: Finding) => (
  <div styleName="finding-additional-data-container">
    {additionalData.map(
      ({ label, text }) =>
        text && (
          <React.Fragment key={label}>
            <div styleName="finding-additional-data-label">{label}</div>
            <div styleName="finding-additional-data-text">{text}</div>
          </React.Fragment>
        ),
    )}
  </div>
);

const FindingsTable = ({
  loading,
  findings,
  input,
  onChange,
  totalCount,
}: Props) => {
  const columns: ColumnProps<any>[] = [
    {
      title: 'Finding Description',
      dataIndex: 'description',
      render: description => description || 'N/A',
    },
    {
      title: 'Criticality',
      dataIndex: 'criticality',
      render: getCriticalityBadge,
      width: '17rem',
      filters: CRITICALITY_FILTERS,
      filterMultiple: true,
      filteredValue: input.criticality,
    },
    {
      title: 'Finding Group',
      dataIndex: 'group',
      render: (group: FindingGroup) => FindingGroup[group],
      filters: FINDING_FILTERS,
      filterMultiple: true,
      filteredValue: input.group,
    },
    {
      title: 'Level Of Effort',
      dataIndex: 'levelOfEffort',
      render: (loe: LevelOfEffort) => LevelOfEffort[loe],
      filters: LOE_FILTERS,
      filterMultiple: true,
      filteredValue: input.levelOfEffort,
    },
    {
      title: 'Building',
      dataIndex: 'building',
      render: building => building.name,
    },
    {
      title: 'Control System',
      dataIndex: 'controlSystem',
      render: controlSystem => controlSystem.name,
    },
    {
      title: 'Control System Type',
      dataIndex: 'controlSystemType',
      render: (text, { controlSystem }: Finding) =>
        controlSystemTypeMap[controlSystem.systemType].text,
      filters: CONTROL_SYSTEM_FILTERS,
      filterMultiple: true,
      filteredValue: input.controlSystemType,
    },
  ];

  const handleChange = (pagination: TablePaginationConfig, filters: object) => {
    // @ts-ignore
    const updatedFilters = R.map(
      val => (R.isEmpty(val) || R.isNil(val) ? null : val),
      filters,
    );

    const { limit, offset } = getLimitAndOffsetFromTablePagination(pagination);

    onChange({ ...updatedFilters, limit, offset });
  };

  const pagination = useMemo(() => getTablePagination(input, totalCount), [
    input,
    totalCount,
  ]);

  return (
    <Table
      rowKey="id"
      loading={loading}
      columns={columns}
      dataSource={findings}
      expandedRowRender={renderDescription}
      expandRowByClick
      pagination={pagination}
      onChange={handleChange}
    />
  );
};

export default FindingsTable;
