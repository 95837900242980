import React from 'react';
import { CloseCircleOutlined } from '@ant-design/icons';

import { ComponentInstance } from '@totem/components/home/lib/types';

const styles = {
  removeStyle: {
    right: '10px',
    top: '10px',
    cursor: 'pointer',
    position: 'absolute' as 'absolute',
    color: '#b4b4b4',
  },
};

type Props = {
  componentInstance: ComponentInstance;
  onRemoveItem: (componentId: string) => void;
};

const InfoWidgetRemoveButton = ({ componentInstance, onRemoveItem }: Props) => {
  return (
    <CloseCircleOutlined
      title="Remove Widget"
      style={styles.removeStyle}
      onClick={() => onRemoveItem(componentInstance.componentInstanceId)}
    />
  );
};

export default InfoWidgetRemoveButton;
