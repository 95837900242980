import React, { useContext } from 'react';

import DropdownFilter from '@totem/components/common/dropdownFilter/DropdownFilter';
import FiltersChipsContainer from '@totem/components/FilterChipsContainer';
import FiltersBody from '@totem/components/FiltersBody';
import FiltersContainer from '@totem/components/FiltersContainer';
import FiltersTitle from '@totem/components/FiltersTitle';
import FindingsReportContext from '@totem/components/surveyV2/findingsReport/findingsReportContext';
import FindingsReportFilterChips from '@totem/components/surveyV2/findingsReport/findingsReportFilterChips';
import { FindingReportInput } from '@totem/components/surveyV2/findingsReport/types';
import { isNotNull } from '@totem/utilities/common';

const styles = {
  dropdown: {
    marginRight: '2rem',
  },
};

type Props = {
  title?: string;
};

const FindingReportFilterPanel = ({ title }: Props) => {
  const { filterOptions, setInput, input } = useContext(FindingsReportContext);

  const handleMultiStringFilterChange = (
    property: keyof FindingReportInput,
    update: string[],
  ) => {
    setInput({
      ...input,
      [property]: update.map(String),
    });
  };

  return (
    <FiltersContainer>
      <FiltersTitle>
        {typeof title !== 'undefined' && title !== null && title !== ''
          ? title
          : 'Findings Report'}
      </FiltersTitle>
      {isNotNull(filterOptions) && (
        <FiltersBody>
          <DropdownFilter
            label="Criticality"
            options={filterOptions.criticalityOptions}
            value={input.criticality || []}
            onChange={(update) =>
              handleMultiStringFilterChange('criticality', update)
            }
            style={styles.dropdown}
          />
          <DropdownFilter
            label="Building"
            options={filterOptions.building}
            value={input.buildingId || []}
            onChange={(update) =>
              handleMultiStringFilterChange('buildingId', update)
            }
            style={styles.dropdown}
          />
          <DropdownFilter
            label="Region"
            options={filterOptions.region}
            value={input.regionId || []}
            onChange={(update) =>
              handleMultiStringFilterChange('regionId', update)
            }
            style={styles.dropdown}
          />
          <DropdownFilter
            label="Assignee"
            options={filterOptions.assignee}
            value={input.assignee || []}
            onChange={(update) =>
              handleMultiStringFilterChange('assignee', update)
            }
            style={styles.dropdown}
          />
        </FiltersBody>
      )}
      <FiltersChipsContainer>
        <FindingsReportFilterChips />
      </FiltersChipsContainer>
    </FiltersContainer>
  );
};

export default FindingReportFilterPanel;
