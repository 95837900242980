import React, { useContext } from 'react';
import { Collapse, CollapseProps } from 'antd';

import TemplateContext from '@totem/components/surveyV2/templateDetails/SurveyTemplateContext';
import SurveyTemplateLevelPane from '@totem/components/surveyV2/templateDetails/SurveyTemplateLevelPane';

type Props = {
  levelType: number;
};

const SurveyTemplateLevelsByLevelType = ({ levelType }: Props) => {
  const { data } = useContext(TemplateContext);
  const getLevelsByType = () => {
    const items: CollapseProps['items'] = [];

    if (
      typeof data !== 'undefined' &&
      data !== null &&
      typeof data.levels !== 'undefined' &&
      data.levels !== null
    ) {
      const matchingLevels = data.levels.filter(
        (lvl) => lvl.type === levelType,
      );
      return matchingLevels.map((ml) => {
        return {
          key: ml.id,
          label: ml.name,
          children: <SurveyTemplateLevelPane level={ml} />,
        };
      });
    }

    return items;
  };

  return <Collapse items={getLevelsByType()} />;
};

export default SurveyTemplateLevelsByLevelType;
