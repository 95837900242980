import { createContext } from 'react';

import { TemplateConnectionInput } from '@totem/components/surveyV2/templates/types';
import { SurveyTemplate } from '@totem/components/surveyV2/types';
interface Context {
  input: TemplateConnectionInput;
  setInput: (input: TemplateConnectionInput) => void;
  loading: boolean;
  setIsLoading: (isLoading: boolean) => void;
  data: SurveyTemplate[];
  totalRecords: number;
  action: string;
  selectedTemplate: SurveyTemplate;
  onAction: (newAction: string, item: SurveyTemplate) => void;
}

export default createContext<Context>({
  input: {},
  setInput: () => {},
  loading: false,
  setIsLoading: () => {},
  data: [],
  totalRecords: 0,
  action: '',
  selectedTemplate: null,
  onAction: () => {},
});
