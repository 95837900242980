import { Criticality } from './criticality';
import { Finding, FindingCriticalityCounts } from './finding';
import { Health, HealthStatusCounts } from './health';
import { Assignee } from './user';

const QUESTION_KEYS = <const>[
  'question1',
  'question2',
  'question3',
  'question4',
  'question5',
  'question6',
  'question7',
  'question8',
  'question9',
  'question10',
  'question11',
  'question12',
  'question13',
  'question14',
  'question15',
  'question16',
  'question17',
  'question18',
  'question19',
  'question20',
  'question21',
  'question22',
  'question23',
  'question24',
  'question25',
  'question26',
  'question27',
  'question28',
  'question29',
  'question30',
  'question31',
  'question32',
  'question33',
  'question34',
  'question35',
  'question36',
  'question37',
  'question38',
];

const GROUP_KEYS = <const>['group1', 'group2', 'group3', 'group4', 'group5'];

export type QuestionKey = typeof QUESTION_KEYS[number];
export type QuestionGroupKey = typeof GROUP_KEYS[number];

export interface User {
  name: string;
  imageUrl: string;
}

export interface QuestionGroup {
  key: string;
  name: string;
  questions: QuestionKey[];
  score: number;
}

export interface MoreInfo {
  value: string;
  updatedAt: string;
  updatedBy: User;
}

export interface Answer {
  value: string;
  updatedAt: string;
  updatedBy: User;
}

export interface Option {
  value: any;
  score: number;
  criticality: Criticality;
  moreInfoVisible: boolean;
  moreInfoLabel: string;
}

export interface Question {
  key: QuestionKey;
  type: QuestionType;
  label: string;
  required: boolean;
  visible: boolean;
  moreInfoRequired: boolean;
  moreInfoVisible: boolean;
  moreInfoLabel: string;
  moreInfo: MoreInfo | null;
  options: Option[];
  answer: Answer | null;
  policy: string;
}

export type QuestionGroups = {
  [key in QuestionGroupKey]?: QuestionGroup;
};

export interface Scores {
  summary: number;
  systemUsers: number;
  systemConfiguration: number;
  network: number;
  continuity: number;
  controlSystemHost: number;
  minimal: number;
  moderate: number;
  maximum: number;
  modeledMinimum: number;
}

export type ScoresKey = keyof Scores;

export type Questions = { [key in QuestionKey]?: Question };

export type PolicyAudit = {
  id: string;
  organization: {
    id: string;
    name: string;
  };
  region: {
    id: string;
    name: string;
  };
  building: {
    id: string;
    name: string;
  };
  controlSystem: {
    id: string;
    name: string;
    email: string;
  };
  assignee?: Assignee;
  health?: Health;
  questionGroups?: QuestionGroups;
  questions?: Questions;
  totalQuestions: number;
  completedQuestions: number;
  outstandingQuestions: number;
  scores?: Scores;
  findings?: Finding[];
  criticalFindingsCount?: number;
  startDate: number;
  dueDate: number;
  submitted: boolean;
  isLaunched: boolean;
  submittedAt?: string;
  submittedBy?: string;
  createdAt?: string;
  updatedAt?: string;
  __typename?: 'PolicyAudit';
};

export enum QuestionType {
  ShortText = 0,
  LongText = 1,
  Scoring = 2,
  EnumSingle = 3,
  EnumMultiple = 4,
  Upload = 5,
  Date = 6,
}

export interface QuestionUpdate {
  required?: boolean;
  visible?: boolean;
  moreInfoRequired?: boolean;
  moreInfoVisible?: boolean;
  moreInfoLabel?: string;
  answer?: {
    value: any;
  } | null;
  moreInfo?: {
    value: string;
  } | null;
}

export type QuestionsUpdate = { [key in QuestionType]?: QuestionUpdate };

export interface PolicyAuditPatch {
  id: string;
  questions?: QuestionsUpdate;
  submitted?: boolean;
}

export interface PolicyAuditIds {
  id: string;
  organizationId: string;
  regionId: string;
  buildingId: string;
  controlSystemId: string;
}

export interface Policy {
  id: string;
  groups: Array<{
    name: string;
    questions: string[];
  }>;
  questions: { [key in QuestionKey]: PolicyQuestion };
}

export interface PolicyQuestion {
  key: string;
  label: string;
  group: string;
  policy?: string;
  resolution?: string;
  policyName: string;
  levelOfEffort: string;
  nistFunction: string;
}

export interface PolicyAuditError {
  answer?: string;
  moreInfo?: string;
}

export type PolicyAuditErrors = {
  [key in QuestionKey]?: PolicyAuditError;
};

export interface CompletionCounts {
  complete: number;
  inProgress: number;
  notStarted: number;
  overdue: number;
}

export interface PolicyAuditsConnectionInput {
  limit: number;
  offset: number;
  regionId?: string[];
  buildingId?: string[];
  controlSystemType?: number[];
  health?: number[];
  isLaunched?: boolean;
  completionStatus?: number[];
  startDate?: boolean;
  dueDate?: boolean;
  submitted?: boolean;
}

export interface PolicyAuditsConnection {
  totalCount: number;
  averageScore?: number;
  healthStatusCounts?: HealthStatusCounts;
  completionCounts?: CompletionCounts;
  findingCriticalityCounts?: FindingCriticalityCounts;
  policyAudits: PolicyAudit[];
}

export interface PolicyAuditCreateInput {
  regionId: string;
  buildingId: string;
  controlSystemId: string;
  assignee?: string;
  startDate?: number;
  dueDate?: number;
}

export interface PolicyAuditUpdateInput {
  id: string;
  assignee?: string;
  startDate?: number;
  dueDate?: number;
  submitted?: boolean;
}

export enum PolicyAuditCompletionStatus {
  Complete,
  'In Progress',
  'Not Started',
  Overdue,
}

export enum PolicyName {
  'N/A',
  'User Authorization',
  'Administrative Users',
  'Activity Logging',
  'User Audit Policy',
  'Password Policy',
  'Guest Accounts',
  'Default Password',
  'Operating System',
  'Auto-lockout',
  'Least Privilege',
  'Malware Protection',
  'OT Server',
  'Patch Management',
  'Backup',
  'Remote Communication',
  'Asset Management',
  'Public-facing IP Address',
  'Cyber Security Roles And Responsibilities',
  'Auto-logoff',
  'Authentication Encryption',
  'Asset Inventory',
  'Disaster Recover',
  'Unknown Devices',
  'Non Application Software',
  'Server Location',
  'Internet Management',
}

export enum PolicyCategory {
  'N/A',
  'Access Control',
  'Asset Management',
  'Protective Technology',
  'Continuous Monitoring',
  'Information Protection',
  'Data Security',
  'Recovery Planning',
}

export enum NistFunction {
  'N/A',
  'Protect',
  'Detect',
  'Identify',
  'Recover',
}

export enum PolicyAuditTemplateQuestionType {
  ShortText,
  LongText,
  Scoring,
  EnumSingle,
  EnumMultiple,
  Upload,
  Date,
}

export interface PolicyAuditTemplateQuestion {
  id: string;
  label: string;
  policyName: PolicyName;
  policyCategory: PolicyCategory;
  nistFunction: NistFunction;
  compliantFindingsCount: number;
  nonCompliantFindingsCount: number;
}

export interface PolicyAuditTemplateQuestionsInput {
  policyNames?: number[];
  policyCategories?: number[];
  nistFunctions?: number[];
  types?: number[];
  levelOfEfforts?: number[];
}
