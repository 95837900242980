import { createContext } from 'react';

import {
  OrganizationContactsInput,
  PointOfContact,
} from '@totem/components/settings/sections/contacts/types';

interface Context {
  input: OrganizationContactsInput;
  setInput: (input: OrganizationContactsInput) => void;
  loading: boolean;
  data: PointOfContact[];
  filteredData: PointOfContact[];
  totalRecords: number;
  onAction?: (action: string, user: PointOfContact) => void;
}

export default createContext<Context>({
  input: {},
  setInput: () => {},
  loading: false,
  data: null,
  filteredData: [],
  totalRecords: 0,
  onAction: null,
});
