import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button, Form, Modal } from 'antd';
import * as R from 'ramda';

import EmailAutoComplete from '@totem/components/common/autoCompletes/EmailAutoComplete';
import SurveyContext from '@totem/components/surveyV2/instanceDetail/SurveyInstanceContext';
import { SurveyQueryResult } from '@totem/components/surveyV2/types';
import fonts from '@totem/styles/fonts';
import { getToken } from '@totem/utilities/accountUtilities';
import { isNotNull } from '@totem/utilities/common';
import { V2_SURVEY_ENDPOINT } from '@totem/utilities/endpoints';
import { validateEmail } from '@totem/utilities/validation';
const FormItem = Form.Item;

const styles = {
  form: {
    width: '100%',
  },
  formItem: {
    paddingBottom: '0',
    marginBottom: '2rem',
  },
  modalHeader: {
    fontWeight: 700,
    fontSize: fonts.fontXl,
  },
  modalFooter: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};

interface FormErrors {
  assignee?: string;
}

interface Props {
  open: boolean;
  onClose: () => void;
}

const AssigneeModal = ({ open, onClose }: Props) => {
  const { data, setData, loading } = useContext(SurveyContext);
  const { survey } = data;
  const [value, setValue] = useState<string>(
    isNotNull(data.assignedUser) ? data.assignedUser.email : '',
  );
  const [errors, setErrors] = useState<FormErrors>({});
  const [isAssigneeSending, setIsAssigneeSending] = useState<boolean>(false);

  useEffect(() => {
    if (!open) {
      setErrors({});
      setValue('');
    } else {
      setValue(isNotNull(data.assignedUser) ? data.assignedUser.email : '');
    }
  }, [open, data.assignedUser?.email]);

  const sendSetAssignee = useCallback(
    async (surveyId: string, assigneeEmail: string) => {
      if (isAssigneeSending) {
        return;
      }
      setIsAssigneeSending(true);

      const payload = {
        assignee: assigneeEmail,
        dueDate: 0,
      };

      fetch(`${V2_SURVEY_ENDPOINT}/instance/${surveyId}/assignee`, {
        method: 'POST',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
        body: JSON.stringify(payload),
      })
        .then((res) => res.json())
        .then((result: SurveyQueryResult) => {
          setIsAssigneeSending(false);
          setData(result);
          onClose();
        });
    },
    [],
  );

  const isValid = (): boolean => {
    const errs: FormErrors = {};

    if (value && !validateEmail(value)) {
      errs.assignee = 'Please input a valid email';
    }

    setErrors(errs);
    return R.isEmpty(errs);
  };
  const handleSubmit = () => {
    if (isValid()) {
      sendSetAssignee(survey.id, value);
    }
  };

  return (
    <Modal
      open={open}
      title={<div style={styles.modalHeader}>Update Assignee</div>}
      onCancel={onClose}
      footer={
        <div style={styles.modalFooter}>
          <Button onClick={onClose}>Cancel</Button>
          <Button type="primary" loading={loading} onClick={handleSubmit}>
            Save
          </Button>
        </div>
      }
    >
      <Form layout="vertical" style={styles.form}>
        <FormItem
          label="Assignee Email"
          colon={false}
          validateStatus={R.isNil(errors.assignee) ? 'success' : 'error'}
          help={R.isNil(errors.assignee) ? null : errors.assignee}
          style={styles.formItem}
        >
          <EmailAutoComplete
            value={value}
            onChange={(val) => setValue(val)}
            placeholder="email@example.com"
          />
        </FormItem>
      </Form>
    </Modal>
  );
};

export default AssigneeModal;
