import React from 'react';

import { getScorecardSummarySelector } from '@totem/selectors/nmapAnalysisSelectors';
import { NmapDevice } from '@totem/types/nmap';

import NmapReportScorecardRow from './NmapReportScorecardRow';

import './nmapDetail.css';

interface Props {
  devices: NmapDevice[];
}

const NmapReportScorecard = ({ devices }: Props) => {
  const scorecard = getScorecardSummarySelector(devices);

  return (
    <div styleName="scorecard-container">
      <div styleName="table-heading">Findings</div>
      <div styleName="findings-table-heading">
        <div styleName="finding-section">finding group</div>
        <div styleName="finding-section subsection">critical</div>
        <div styleName="finding-section subsection">high</div>
        <div styleName="finding-section subsection">medium</div>
        <div styleName="finding-section subsection">low</div>
      </div>
      <NmapReportScorecardRow
        label="Port"
        critical={scorecard.port.critical}
        high={scorecard.port.high}
        medium={scorecard.port.medium}
        low={scorecard.port.low}
      />
      <NmapReportScorecardRow
        label="OS"
        critical={scorecard.os.critical}
        high={scorecard.os.high}
        medium={scorecard.os.medium}
        low={scorecard.os.low}
      />
      <NmapReportScorecardRow
        label="Manufacturer"
        critical={scorecard.manufacturer.critical}
        high={scorecard.manufacturer.high}
        medium={scorecard.manufacturer.medium}
        low={scorecard.manufacturer.low}
      />
      <NmapReportScorecardRow
        label="Other"
        critical={scorecard.other.critical}
        high={scorecard.other.high}
        medium={scorecard.other.medium}
        low={scorecard.other.low}
      />
    </div>
  );
};

export default NmapReportScorecard;
