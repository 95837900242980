import { ObjectReference } from '@totem/types/common';

import { Criticality } from '../../types/criticality';
import { Health } from '../../types/health';
import { User } from '../../types/user';

export interface RequestSurveyInstanceQuestionAnswer {
  surveyInstanceId: string;
  categoryId: string;
  questionId: string;
  answer: QuestionValue;
  additionalContext: string;
}

export interface SurveyQueryResult {
  survey: SurveyInstance;
  organization: ObjectReference;
  region?: ObjectReference;
  building?: ObjectReference;
  controlSystem?: ObjectReference;
  template?: ObjectReference;
  health?: Health;
  assignedUser?: User;
}
export interface SurveyInstancesConnection {
  totalCount: number;
  surveyInstances: SurveyInstance[];
}

export interface Condition {
  id: string;
  operation: string;
  fieldType: string;
  fieldId: string;
  value: string;
  subconditions: Condition[];
}

export interface QuestionValue {
  value: string;
  valueInt: number;
  valueFloat: number;
  valueArray: QuestionValue[];
}

export interface QuestionOption {
  id: string;
  label: string;
  score: QuestionValue;
  recommendation: string;
  findingDescription: string;
  criticality: Criticality;
  moreInfoVisible: boolean;
  moreInfoRequired: boolean;
  moreInfoLabel: string;
}

export interface SurveyQuestion {
  id: string;
  type: string;
  name: string;
  label: string;
  hint: string;
  required: boolean;
  scored: boolean;
  options: QuestionOption[];
  instance: number;
  levels: string[];
  value: QuestionValue;
  index: number;

  policy: string;
  policyName: string;
  resolution: string;
  levelOfEffort: number;
  policyCategory: string;
  nistFunction: string;
  moreInfoLabel: string;

  requiresAdditionalContext: boolean;
  additionalContext: string;
  instanceIdentifier: boolean;
  updatedAt?: number;
  updatedBy?: {
    id: string;
    name: string;
    imageUrl: string;
  };
  visible: boolean;
  condition: Condition;
}

export interface ScoredInstance {
  instance: number;
  displayName: string;
  maxScore: number;
  totalScore: number;
}

export interface ScoredCategory {
  id: string;
  name: string;
  title: string;
  description: string;
  questions: SurveyQuestion[];
  multiInstance: boolean;
  multiInstanceLabel: string;
  multiInstanceScores: ScoredInstance[];
  maxScore: number;
  totalScore: number;
  totalQuestions: number;
  completedQuestions: number;
  condition: Condition;
  visible: boolean;
  index: number;
}

export interface Category {
  id: string;
  name: string;
  title: string;
  description: string;
  questions: SurveyQuestion[];
  multiInstance: boolean;
  multiInstanceLabel: string;
  visible: boolean;
  condition: Condition;
  index: number;
}

export interface Level {
  id: string;
  name: string;
  type: number;
  typeName: string;
  scoreBy: string;
  sublevels: string[];
}

export interface ScoredLevel {
  id: string;
  name: string;
  type: number;
  typeName: string;
  scoreBy: string;
  sublevels: string[];
  maxScore: number;
  totalScore: number;
}

export interface ScoredLevelType {
  type: number;
  typeName: string;
  displayName: string;
  maxScore: number;
  totalScore: number;
}

export interface LevelType {
  type: number;
  typeName: string;
  displayName: string;
  defaultScoreBy: string;
}

export interface SurveyTemplate {
  id: string;
  organizationId: string;
  name: string;
  type: string;
  title: string;
  description: string;
  helpText: string;
  levelTypes: LevelType[];
  levels: Level[];
  categories: Category[];
  version: number;
  isPublished: boolean;
  createdBy?: string;
  updatedBy?: string;
  createdAt?: string;
  updatedAt?: string;
}

export interface SurveyInstance {
  id: string;
  templateId?: string;
  organizationId?: string;
  buildingId?: string;
  controlSystemId?: string;
  name: string;
  type: string;
  title: string;
  description: string;
  helpText: string;
  levels: ScoredLevel[];
  levelTypes: ScoredLevelType[];
  categories: ScoredCategory[];
  templateVersion: number;
  maxScore: number;
  totalScore: number;
  totalQuestions: number;
  completedQuestions: number;
  criticalFindings: number;
  isSubmitted: boolean;
  isLaunched: boolean;
  assignee: string;
  startDate?: number;
  dueDate?: number;
  createdBy?: string;
  updatedBy?: string;
  createdAt?: string;
  updatedAt?: string;
  isDeleted: boolean;
}

export interface SurveyTemplateState {
  levels: Level[];
  selectedLevelId: string;
  selectedLevelIndex: number;
  selectedLevel: Level;
  categories: Category[];
  selectedCategoryId: string;
  selectedCategoryIndex: number;
  selectedCategory: Category;
  questions: SurveyQuestion[];
  selectedQuestionId: string;
  selectedQuestionIndex: number;
  selectedQuestion: SurveyQuestion;
}

export interface SurveyError {
  categoryId: string;
  categoryName: string;
  questionId: string;
  questionLabel: string;
  message: string;
}

export interface SurveyInstanceStatistics {
  total: number;
  offTrack: number;
  atRisk: number;
  onTrack: number;
  criticalFindings: number;
  inProgress: number;
  completed: number;
  notStarted: number;
  overdue: number;
}

export interface SurveyInstanceStatisticsRequest {
  organizationId?: string[];
  regionId?: string[];
  buildingId?: string[];
  templateId?: string[];
}

export interface SurveyProgress {
  totalQuestions: number;
  completedQuestions: number;
}

export interface SurveyPatch {
  id: string;
  assignee?: string;
  startDate?: number;
  dueDate?: number;
  isSubmitted?: boolean;
}

export type SurveyTemplatePanelKey =
  | 'details'
  | 'levelTypes'
  | 'levels'
  | 'categories'
  | 'questions'
  | '';

export type SurveyTemplateMenuKey =
  | 'categories'
  | 'questions'
  | 'add-question'
  | 'levels'
  | 'add-level';

export type ConditionActionMethod =
  | 'AddToGroup'
  | 'RemoveFromGroup'
  | 'Update'
  | 'Edit'
  | 'New';

export interface ConditionActionPayload {
  method: ConditionActionMethod;
  condition?: Condition;
}

export interface ConditionSearchResult {
  category?: Category;
  question?: SurveyQuestion;
  condition?: Condition;
  wasFound: boolean;
}

export const QuestionTypes = [
  {
    label: 'Short Text',
    value: 'shorttext',
  },
  {
    label: 'Long Text',
    value: 'longtext',
  },
  {
    label: 'Scoring',
    value: 'scoring',
  },
  {
    label: 'Enum Single',
    value: 'enumsingle',
  },
  {
    label: 'Enum Multiple',
    value: 'enummultiple',
  },
  {
    label: 'Date',
    value: 'date',
  },
  {
    label: 'Upload',
    value: 'upload',
  },
  {
    label: 'Information',
    value: 'information',
  },
];
