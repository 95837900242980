import React, { useContext, useEffect, useState } from 'react';
import { Button, DatePicker, DatePickerProps, Flex, notification } from 'antd';
import dayjs from 'dayjs';

import DropdownFilter from '@totem/components/common/dropdownFilter/DropdownFilter';
import EventsFilterChips from '@totem/components/events/eventFilters/EventFilterChips';
import EventFilterContext from '@totem/components/events/eventFilters/EventFilterContext';
import { EventFilterInput } from '@totem/components/events/eventFilters/types';
import { getSeverityFilter } from '@totem/components/events/eventFilters/utilities';
import FiltersChipsContainer from '@totem/components/FilterChipsContainer';
import FiltersBody from '@totem/components/FiltersBody';
import FiltersContainer from '@totem/components/FiltersContainer';
import FiltersTitle from '@totem/components/FiltersTitle';

import '../events.css';

const styles = {
  dropdown: {
    marginRight: '2rem',
  },
  rangeSelector: {
    marginRight: '2rem',
  },
  picker: {
    width: '15rem',
    marginRight: '15px',
    marginLeft: '15px',
  },
  pickerButton: {
    marginRight: '15px',
  },
  pickerButtonText: {
    fontWeight: 700,
  },
};

const EventFilters = () => {
  const { input, setInput, filterOptions } = useContext(EventFilterContext);
  const [rangeStart, setRangeStart] = useState<number>(input.startTime);
  const [rangeEnd, setRangeEnd] = useState<number>(input.endTime);

  useEffect(() => {
    setRangeStart(input.startTime);
    setRangeEnd(input.endTime);
  }, [input]);

  const getOptions = (optionType: string) => {
    switch (optionType) {
      case 'eventType':
        return filterOptions.eventType.map((option) => ({
          label: option.name,
          value: option.value,
        }));
      case 'sourceSystem':
        return filterOptions.sourceSystem.map((option) => ({
          label: option.name,
          value: option.value,
        }));
      case 'sourceType':
        return filterOptions.sourceType.map((option) => ({
          label: option.name,
          value: option.value,
        }));
      case 'status':
        return filterOptions.status.map((option) => ({
          label: option.name,
          value: option.value,
        }));
      case 'building':
        return filterOptions.building.map((option) => ({
          label: option.name,
          value: option.value,
        }));
      case 'region':
        return filterOptions.region.map((option) => ({
          label: option.name,
          value: option.value,
        }));
      case 'severity':
        return getSeverityFilter().map((option) => ({
          label: option.text,
          value: option.value,
        }));
      default:
        return [];
    }
  };

  const handleMultiStringFilterChange = (
    property: keyof EventFilterInput,
    update: string[],
  ) => {
    setInput({
      ...input,
      [property]: update.map(String),
    });
  };

  const handleApplyDateRange = () => {
    if (rangeEnd > rangeStart) {
      setInput({ ...input, startTime: rangeStart, endTime: rangeEnd });
    } else {
      notification.error({
        message: 'Range Error!',
        description: 'Start Date must be earlier than End Date.',
        duration: 5,
      });
    }
  };
  const handleStartDateChange: DatePickerProps['onChange'] = (date) => {
    const dateValue = date ? date.toDate() : new Date(0);
    setRangeStart(dateValue.valueOf());
  };

  const handleEndDateChange: DatePickerProps['onChange'] = (date) => {
    const dateValue = date ? date.toDate() : new Date(0);
    setRangeEnd(dateValue.valueOf());
  };

  const parseDate = (date: number): dayjs.Dayjs | null => {
    if (!date) {
      return null;
    }

    return dayjs(date);
  };

  return (
    <FiltersContainer>
      <FiltersTitle>
        <Flex justify={'space-between'} align={'center'}>
          <div>Event Management</div>
          <div>
            Date Range:
            <DatePicker
              // @ts-ignore
              value={parseDate(rangeStart)}
              onChange={handleStartDateChange}
              allowClear={false}
              style={styles.picker}
              placeholder="Select Start Date"
            />
            -
            <DatePicker
              // @ts-ignore
              value={parseDate(rangeEnd)}
              onChange={handleEndDateChange}
              allowClear={false}
              style={styles.picker}
              placeholder="Select End Date"
            />
            <Button
              type={'primary'}
              style={styles.pickerButton}
              onClick={handleApplyDateRange}
              disabled={
                input.startTime === rangeStart && input.endTime === rangeEnd
              }
            >
              <span style={styles.pickerButtonText}>Apply</span>
            </Button>
          </div>
        </Flex>
      </FiltersTitle>
      {filterOptions !== null && (
        <>
          <FiltersBody>
            <DropdownFilter
              label="Source Type"
              options={getOptions('sourceType')}
              value={input.sourceType || []}
              onChange={(update) =>
                handleMultiStringFilterChange('sourceType', update)
              }
              style={styles.dropdown}
            />
            <DropdownFilter
              label="Event Type"
              options={getOptions('eventType')}
              value={input.eventType || []}
              onChange={(update) =>
                handleMultiStringFilterChange('eventType', update)
              }
              style={styles.dropdown}
            />
            <DropdownFilter
              label="Status"
              options={getOptions('status')}
              value={input.status || []}
              onChange={(update) =>
                handleMultiStringFilterChange('status', update)
              }
              style={styles.dropdown}
            />
            <DropdownFilter
              label="Severity"
              options={getOptions('severity')}
              value={input.severity || []}
              onChange={(update) =>
                handleMultiStringFilterChange('severity', update)
              }
              style={styles.dropdown}
            />
            <DropdownFilter
              label="Region"
              options={getOptions('region')}
              value={input.regionId || []}
              onChange={(update) =>
                handleMultiStringFilterChange('regionId', update)
              }
              style={styles.dropdown}
            />
            <DropdownFilter
              label="Building"
              options={getOptions('building')}
              value={input.buildingId || []}
              onChange={(update) =>
                handleMultiStringFilterChange('buildingId', update)
              }
              style={styles.dropdown}
            />
          </FiltersBody>
          <FiltersChipsContainer>
            <EventsFilterChips />
          </FiltersChipsContainer>
        </>
      )}
    </FiltersContainer>
  );
};

export default EventFilters;
