import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Empty, Flex } from 'antd';

import ChartTitle from '@totem/components/ChartTitle';
import BackupSummaryContext from '@totem/components/deviceBackups/backupSummary/backupSummaryContext';
import DonutChart from '@totem/components/deviceBackups/backupSummary/donutChart';
import TotalValue from '@totem/components/deviceBackups/backupSummary/TotalValue';
import { SummaryRecord } from '@totem/components/deviceBackups/backupSummary/types';
import {
  getBackupAging,
  getBackupStatusRecord,
} from '@totem/components/deviceBackups/utilities';
import { isNotNull, isNull } from '@totem/utilities/common';
const BackupSummaryCharts = () => {
  const navigate = useNavigate();
  const { data } = useContext(BackupSummaryContext);
  const baseLink =
    '/dashboard/deviceBackups?page=1&pageSize=10&sortDirection=1&sortField=device.displayName';

  const getStatusColors = () => {
    if (isNotNull(data)) {
      return data.status.map(
        (point) => getBackupStatusRecord(point.name).color,
      );
    }
    return [];
  };

  const getAgingColors = () => {
    if (isNotNull(data)) {
      return data.currency.map((point) => getBackupAging(point.name).color);
    }
    return [];
  };

  const styles = {
    chartContainer: {
      padding: '20px',
    },
  };

  const sum = (arr: SummaryRecord[]): number => {
    let val = 0;

    for (let idx = 0; idx < arr.length; idx++) {
      val = val + arr[idx].value;
    }

    return val;
  };

  const handleStatusChartItemClicked = (point: SummaryRecord) => {
    switch (point.name) {
      case 'Stale':
        navigate(`${baseLink}&isBackupStale=true`);
        break;
      case 'Current':
        navigate(`${baseLink}&isBackupStale=false`);
        break;
      default:
        break;
    }
  };

  const handleAgingChartItemClicked = (point: SummaryRecord) => {
    navigate(`${baseLink}&lastBackupStatus=${point.name}`);
  };

  return (
    <>
      {isNull(data) && <Empty />}
      {!isNull(data) && (
        <Flex justify={'space-between'} align={'center'}>
          {data.currency !== null && data.currency.length > 0 && (
            <TotalValue
              value={sum(data.currency).toString(10)}
              title={'Total Backups'}
              link={baseLink}
            />
          )}
          {data.currency !== null && data.currency.length > 0 && (
            <div style={styles.chartContainer}>
              <ChartTitle>Backup Aging</ChartTitle>
              <DonutChart
                chartData={data.currency}
                chartOptions={{ colors: getAgingColors() }}
                onItemClicked={handleStatusChartItemClicked}
              />
            </div>
          )}
          {data.status !== null && data.status.length > 0 && (
            <div style={styles.chartContainer}>
              <ChartTitle>Backups (Last 24 Hours)</ChartTitle>
              <DonutChart
                chartData={data.status}
                chartOptions={{ colors: getStatusColors() }}
                onItemClicked={handleAgingChartItemClicked}
              />
            </div>
          )}
        </Flex>
      )}
    </>
  );
};

export default BackupSummaryCharts;
