import React from 'react';

import { NmapDevice } from '@totem/types/nmap';

import NmapReportDetailsRow from './NmapReportDetailsRow';

import './nmapDetail.css';

interface Props {
  title: string;
  devices: NmapDevice[];
  isReport?: boolean;
}

const NmapReportDetails = ({ title = 'Devices', devices, isReport }: Props) => {
  return (
    <div styleName="scorecard-container">
      <div styleName="table-heading">{title}</div>
      <div styleName="findings-table-heading">
        <div styleName="finding-section-details">manufacturer</div>
        <div styleName="finding-section-details subsection">ip address</div>
        <div styleName="finding-section-details subsection">mac address</div>
      </div>
      {devices.map((device: NmapDevice) => (
        <NmapReportDetailsRow
          key={device.id}
          device={device}
          isReport={isReport}
        />
      ))}
    </div>
  );
};

export default NmapReportDetails;
