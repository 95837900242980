import { EMPTY_ID } from '@totem/components/common/reference/ReferenceSelector';

export const getEmptyHierarcy = () => {
  return {
    id: EMPTY_ID,
    regionId: EMPTY_ID,
    regionName: '',
    buildingId: EMPTY_ID,
    buildingName: '',
    controlSystemId: EMPTY_ID,
    controlSystemName: '',
    deviceId: EMPTY_ID,
    deviceName: '',
    createdBy: '',
    createdAt: '2023-0101:00:00.000+00:00',
  };
}
