import React from 'react';
import { CheckCircleOutlined } from '@ant-design/icons';
import * as R from 'ramda';

import Table from '@totem/components/common/table/Table';
import colors from '@totem/styles/colors';
import { NmapDevice } from '@totem/types/nmap';

import './nmapDetail.css';

type Props = {
  devices: NmapDevice[];
  // eslint-disable-next-line no-unused-vars
  onDeviceSelect: (device: NmapDevice) => void;
};

const styles = {
  kr: {
    backgroundColor: colors.neutral.gray,
  },
  krDanger: {
    backgroundColor: colors.brand.red,
  },
  icon: {
    cursor: 'pointer',
    color: 'rgba(0,0,0,0.45)',
    fontSize: 16,
    marginLeft: '1rem',
  },
  reviewed: {
    fontSize: 20,
  },
};

const UnflaggedDevicesTable = ({ devices, onDeviceSelect }: Props) => {
  const columns = [
    {
      title: 'Manufacturer',
      dataIndex: '',
      render: (text: string, { deviceManufacturer }: NmapDevice) => (
        <div styleName="table-cell clickable">{`${
          deviceManufacturer ? deviceManufacturer : 'Unknown Manufacturer'
        }`}</div>
      ),
    },
    {
      title: 'IP Address',
      dataIndex: '',
      render: (text: string, { ipAddress }: NmapDevice) => (
        <div styleName="table-cell clickable">{ipAddress}</div>
      ),
    },
    {
      title: 'MAC Address',
      dataIndex: '',
      render: (text: string, { macAddress }: NmapDevice) => (
        <div styleName="table-cell clickable">{macAddress}</div>
      ),
    },
    {
      title: 'Open Ports',
      dataIndex: '',
      render: (text: string, { ports }: NmapDevice) => (
        <div styleName="table-cell clickable">{R.length(ports)}</div>
      ),
    },
    {
      title: 'OS Versions',
      dataIndex: '',
      render: (text: string, { softwareTypes }: NmapDevice) => (
        <div styleName="table-cell clickable">{R.length(softwareTypes)}</div>
      ),
    },
    {
      title: 'Reviewed',
      dataIndex: '',
      render: (text: string, { isReviewed }: NmapDevice) =>
        isReviewed ? (
          <div styleName="table-cell clickable icon">
            <CheckCircleOutlined style={styles.reviewed} />
          </div>
        ) : (
          <div />
        ),
    },
  ];

  return (
    <Table
      rowKey="id"
      pagination={false}
      columns={columns}
      onRow={(device: NmapDevice) => ({
        onClick: () => onDeviceSelect(device),
      })}
      dataSource={devices}
    />
  );
};

export default UnflaggedDevicesTable;
