import React, { useContext, useState } from 'react';
import { Button, Tabs, TabsProps } from 'antd';

import BasePane from '@totem/components/BasePane';
import CardBody from '@totem/components/CardBody';
import CardHeader from '@totem/components/CardHeader';
import ContentLayout from '@totem/components/ContentLayout';
import PrimaryContentPane from '@totem/components/PrimaryContentPane';
import TabTitle from '@totem/components/TabTitle';
import UserProfileContext from '@totem/components/UserProfileContext';
import UserActions from '@totem/components/users/container/actions/UserActions';
import { User } from '@totem/components/users/container/types';
import UsersTable from '@totem/components/users/container/userContainerTable';
import UsersContainer from '@totem/components/users/container/UsersContainer';
import UserSearch from '@totem/components/users/container/userSearch';
import UsersEmbeddedContainer from '@totem/components/users/container/UsersEmbeddedContainer';
import authUtilities from '@totem/utilities/authUtilities';
import { isIBUser } from '@totem/utilities/security';

const UserManagement = () => {
  const { userProfile } = useContext(UserProfileContext);
  const [selectedUser, setSelectedUser] = useState<User>(null);
  const [activeAction, setActiveAction] = useState<string>('');
  const [actionSource, setActionSource] = useState<string>('');
  const [refresh, setRefresh] = useState<boolean>(false);

  const [emailSearchTerm, setEmailSearchTerm] = useState<string>('');
  const [firstNameSearchTerm, setFirstNameSearchTerm] = useState<string>('');
  const [lastNameSearchTerm, setLastNameSearchTerm] = useState<string>('');

  const isIB = isIBUser(userProfile);

  const userCreationEnabled = authUtilities.minimumRequiredRole(
    authUtilities.ROLE_SETS.ORGANIZATION_ADMINISTRATOR,
  );

  const handleTabSwitch = (tabgroup: string) => {
    setActionSource(tabgroup);
    setRefresh(true);
  };

  const handleAction = (source: string, action: string, user: User) => {
    setActionSource(source);
    setSelectedUser(user);
    setActiveAction(action);
  };

  const handleActionClosed = (doRefresh: boolean) => {
    setActiveAction('');
    setSelectedUser(null);
    setRefresh(doRefresh);
  };

  const handleDataRefreshRequested = (source: string) => {
    if (source === actionSource && refresh) {
      setRefresh(false);
    }
  };

  const getTabItems = () => {
    const tabItems: TabsProps['items'] = [
      {
        key: 'customerAccounts',
        label: <TabTitle>Users</TabTitle>,
        children: (
          <UsersContainer
            staticFilters={{
              tabGroup: 'customerAccounts',
              searchEmail: emailSearchTerm,
              searchFirstName: firstNameSearchTerm,
              searchLastName: lastNameSearchTerm,
            }}
            actions={[
              {
                name: 'removeUser',
                action: (action: string, user: User) =>
                  handleAction('customerAccounts', action, user),
                replaceDefault: true,
              },
              {
                name: 'removeIBUser',
                action: (action: string, user: User) =>
                  handleAction('customerAccounts', action, user),
                replaceDefault: true,
              },
              {
                name: 'viewDetails',
                action: (action: string, user: User) =>
                  handleAction('customerAccounts', action, user),
                replaceDefault: true,
              },
              {
                name: 'editUser',
                action: (action: string, user: User) =>
                  handleAction('customerAccounts', action, user),
                replaceDefault: true,
              },
              {
                name: 'dataPermissions',
                action: (action: string, user: User) =>
                  handleAction('customerAccounts', action, user),
                replaceDefault: true,
              },
              {
                name: 'securityGroups',
                action: (action: string, user: User) =>
                  handleAction('customerAccounts', action, user),
                replaceDefault: true,
              },
              {
                name: 'activateUser',
                action: (action: string, user: User) =>
                  handleAction('customerAccounts', action, user),
                replaceDefault: true,
              },
            ]}
            onDataRefreshRequested={() =>
              handleDataRefreshRequested('customerAccounts')
            }
            refresh={actionSource === 'customerAccounts' && refresh}
          >
            <UsersTable />
          </UsersContainer>
        ),
      },
      {
        key: 'tenantAccounts',
        label: <TabTitle>Tenants</TabTitle>,
        children: (
          <UsersEmbeddedContainer
            staticFilters={{
              tabGroup: 'tenantAccounts',
              searchEmail: emailSearchTerm,
              searchFirstName: firstNameSearchTerm,
              searchLastName: lastNameSearchTerm,
            }}
            actions={[
              {
                name: 'removeUser',
                action: (action: string, user: User) =>
                  handleAction('tenantAccounts', action, user),
                replaceDefault: true,
              },
              {
                name: 'removeIBUser',
                action: (action: string, user: User) =>
                  handleAction('customerAccounts', action, user),
                replaceDefault: true,
              },
              {
                name: 'viewDetails',
                action: (action: string, user: User) =>
                  handleAction('tenantAccounts', action, user),
                replaceDefault: true,
              },
              {
                name: 'editUser',
                action: (action: string, user: User) =>
                  handleAction('tenantAccounts', action, user),
                replaceDefault: true,
              },
              {
                name: 'dataPermissions',
                action: (action: string, user: User) =>
                  handleAction('tenantAccounts', action, user),
                replaceDefault: true,
              },
              {
                name: 'securityGroups',
                action: (action: string, user: User) =>
                  handleAction('tenantAccounts', action, user),
                replaceDefault: true,
              },
              {
                name: 'activateUser',
                action: (action: string, user: User) =>
                  handleAction('tenantAccounts', action, user),
                replaceDefault: true,
              },
            ]}
            onDataRefreshRequested={() =>
              handleDataRefreshRequested('tenantAccounts')
            }
            refresh={actionSource === 'tenantAccounts' && refresh}
          >
            <UsersTable />
          </UsersEmbeddedContainer>
        ),
      },
      {
        key: 'vendorAccounts',
        label: <TabTitle>Vendors</TabTitle>,
        children: (
          <UsersEmbeddedContainer
            staticFilters={{
              tabGroup: 'vendorAccounts',
              searchEmail: emailSearchTerm,
              searchFirstName: firstNameSearchTerm,
              searchLastName: lastNameSearchTerm,
            }}
            actions={[
              {
                name: 'removeUser',
                action: (action: string, user: User) =>
                  handleAction('vendorAccounts', action, user),
                replaceDefault: true,
              },
              {
                name: 'removeIBUser',
                action: (action: string, user: User) =>
                  handleAction('customerAccounts', action, user),
                replaceDefault: true,
              },
              {
                name: 'viewDetails',
                action: (action: string, user: User) =>
                  handleAction('vendorAccounts', action, user),
                replaceDefault: true,
              },
              {
                name: 'editUser',
                action: (action: string, user: User) =>
                  handleAction('vendorAccounts', action, user),
                replaceDefault: true,
              },
              {
                name: 'dataPermissions',
                action: (action: string, user: User) =>
                  handleAction('vendorAccounts', action, user),
                replaceDefault: true,
              },
              {
                name: 'securityGroups',
                action: (action: string, user: User) =>
                  handleAction('vendorAccounts', action, user),
                replaceDefault: true,
              },
              {
                name: 'activateUser',
                action: (action: string, user: User) =>
                  handleAction('vendorAccounts', action, user),
                replaceDefault: true,
              },
            ]}
            onDataRefreshRequested={() =>
              handleDataRefreshRequested('vendorAccounts')
            }
            refresh={actionSource === 'vendorAccounts' && refresh}
          >
            <UsersTable />
          </UsersEmbeddedContainer>
        ),
      },
    ];

    if (isIB) {
      tabItems.push({
        key: 'internalAccounts',
        label: <TabTitle>IB Users</TabTitle>,
        children: (
          <UsersEmbeddedContainer
            staticFilters={{
              tabGroup: 'internalAccounts',
              searchEmail: emailSearchTerm,
              searchFirstName: firstNameSearchTerm,
              searchLastName: lastNameSearchTerm,
            }}
            actions={[
              {
                name: 'removeUser',
                action: (action: string, user: User) =>
                  handleAction('internalAccounts', action, user),
                replaceDefault: true,
              },
              {
                name: 'removeIBUser',
                action: (action: string, user: User) =>
                  handleAction('customerAccounts', action, user),
                replaceDefault: true,
              },
              {
                name: 'viewDetails',
                action: (action: string, user: User) =>
                  handleAction('internalAccounts', action, user),
                replaceDefault: true,
              },
              {
                name: 'editUser',
                action: (action: string, user: User) =>
                  handleAction('internalAccounts', action, user),
                replaceDefault: true,
              },
              {
                name: 'dataPermissions',
                action: (action: string, user: User) =>
                  handleAction('internalAccounts', action, user),
                replaceDefault: true,
              },
              {
                name: 'securityGroups',
                action: (action: string, user: User) =>
                  handleAction('internalAccounts', action, user),
                replaceDefault: true,
              },
              {
                name: 'activateUser',
                action: (action: string, user: User) =>
                  handleAction('internalAccounts', action, user),
                replaceDefault: true,
              },
            ]}
            onDataRefreshRequested={() =>
              handleDataRefreshRequested('internalAccounts')
            }
            refresh={actionSource === 'internalAccounts' && refresh}
          >
            <UsersTable />
          </UsersEmbeddedContainer>
        ),
      });
    }

    tabItems.push({
      key: 'inActiveAccounts',
      label: <TabTitle>Disabled Users</TabTitle>,
      children: (
        <UsersEmbeddedContainer
          staticFilters={{
            tabGroup: 'inActiveAccounts',
            searchEmail: emailSearchTerm,
            searchFirstName: firstNameSearchTerm,
            searchLastName: lastNameSearchTerm,
          }}
          actions={[
            {
              name: 'removeUser',
              action: (action: string, user: User) =>
                handleAction('inActiveAccounts', action, user),
              replaceDefault: true,
            },
            {
              name: 'removeIBUser',
              action: (action: string, user: User) =>
                handleAction('customerAccounts', action, user),
              replaceDefault: true,
            },
            {
              name: 'viewDetails',
              action: (action: string, user: User) =>
                handleAction('inActiveAccounts', action, user),
              replaceDefault: true,
            },
            {
              name: 'editUser',
              action: (action: string, user: User) =>
                handleAction('inActiveAccounts', action, user),
              replaceDefault: true,
            },
            {
              name: 'dataPermissions',
              action: (action: string, user: User) =>
                handleAction('inActiveAccounts', action, user),
              replaceDefault: true,
            },
            {
              name: 'securityGroups',
              action: (action: string, user: User) =>
                handleAction('inActiveAccounts', action, user),
              replaceDefault: true,
            },
            {
              name: 'activateUser',
              action: (action: string, user: User) =>
                handleAction('inActiveAccounts', action, user),
              replaceDefault: true,
            },
          ]}
          onDataRefreshRequested={() =>
            handleDataRefreshRequested('inActiveAccounts')
          }
          refresh={actionSource === 'inActiveAccounts' && refresh}
        >
          <UsersTable />
        </UsersEmbeddedContainer>
      ),
    });

    return tabItems;
  };

  return (
    <ContentLayout>
      <BasePane>
        <PrimaryContentPane>
          <CardHeader>
            <div>User Management</div>
            <Button
              onClick={() => handleAction(actionSource, 'createUser', null)}
              disabled={!userCreationEnabled}
              type="primary"
            >
              Add User
            </Button>
          </CardHeader>
          <CardBody>
            <UserSearch
              onEmailSearchTermUpdated={setEmailSearchTerm}
              onFirstNameSearchTermUpdated={setFirstNameSearchTerm}
              onLastNameSearchTermUpdated={setLastNameSearchTerm}
            />
            <br />
            <Tabs
              defaultActiveKey="customerAccounts"
              onTabClick={(key) => handleTabSwitch(key)}
              items={getTabItems()}
            />
          </CardBody>
        </PrimaryContentPane>
      </BasePane>
      {activeAction !== '' && (
        <UserActions
          user={selectedUser}
          action={activeAction}
          onClose={handleActionClosed}
        />
      )}
    </ContentLayout>
  );
};

export default UserManagement;
