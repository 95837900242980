import React from 'react';
import { Col, Row } from 'antd';

import CvssScore from '@totem/components/software/cves/cvssScore';
import { NVDMetricsV2 } from '@totem/components/software/cves/types';
import { colorUnknown } from '@totem/components/software/cves/utilities';
import ValueBlock from '@totem/components/software/cves/valueBlock';

type Props = {
  metricsV2: NVDMetricsV2;
};

const CvssMetricsV2Panel = ({ metricsV2 }: Props) => {
  return (
    <Row gutter={[20, 10]}>
      <Col span={8}>
        <CvssScore
          title="CVSS v2"
          score={metricsV2.cvssData.baseScore}
          maxScore={10}
        />
      </Col>
      <Col span={8}>
        <ValueBlock
          title={'Access Vector'}
          value={metricsV2.cvssData.accessVector}
          valueColor={colorUnknown}
        />
        <ValueBlock
          title={'Access Complexity'}
          value={metricsV2.cvssData.accessComplexity}
          valueColor={colorUnknown}
        />
        <ValueBlock
          title={'Authentication'}
          value={metricsV2.cvssData.authentication}
          valueColor={colorUnknown}
        />
      </Col>
      <Col span={8}>
        <ValueBlock
          title={'Confidentiality Impact'}
          value={metricsV2.cvssData.confidentialityImpact}
          valueColor={colorUnknown}
        />
        <ValueBlock
          title={'Integrity Impact'}
          value={metricsV2.cvssData.integrityImpact}
          valueColor={colorUnknown}
        />
        <ValueBlock
          title={'Availability Impact'}
          value={metricsV2.cvssData.availabilityImpact}
          valueColor={colorUnknown}
        />
      </Col>
    </Row>
  );
};

export default CvssMetricsV2Panel;
