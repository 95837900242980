import React, { useCallback, useContext, useState } from 'react';
import { EllipsisOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { Button, Dropdown, MenuProps } from 'antd';

import PolicyAuditReportContext from '@totem/components/surveyV2/policyAuditReport/policyAuditReportContext';
import { PolicyAuditReportRecord } from '@totem/components/surveyV2/policyAuditReport/types';
import UserProfileContext from '@totem/components/UserProfileContext';
import { getToken } from '@totem/utilities/accountUtilities';
import { V2_SURVEY_ENDPOINT } from '@totem/utilities/endpoints';

type Props = {
  record: PolicyAuditReportRecord;
};

const ActionMenu = ({ record }: Props) => {
  const { userProfile } = useContext(UserProfileContext);
  const { onAction } = useContext(PolicyAuditReportContext);
  const [isSending, setIsSending] = useState<boolean>(false);

  const isAdmin = userProfile.organization.role >= 2;

  const sendReopen = useCallback(
    async (instanceType: string, instanceId: string) => {
      if (isSending) {
        return;
      }
      setIsSending(true);

      fetch(`${V2_SURVEY_ENDPOINT}/reopen/${instanceType}/${instanceId}`, {
        method: 'PUT',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
      }).then(() => {
        setIsSending(false);
        onAction('refresh', null);
      });
    },
    [],
  );

  const handleReopenAudit = () => {
    sendReopen(record.policyAudit.source, record.policyAudit.id);
  };

  const onMenuClicked = ({ key }) => {
    // eslint-disable-next-line default-case
    switch (key) {
      case 'reopen':
        handleReopenAudit();
        break;
    }
  };

  const getActionMenuItems = (): MenuProps['items'] => {
    const menuItems: MenuProps['items'] = [];

    if (isAdmin && record.policyAudit.isSubmitted) {
      menuItems.push({
        key: 'reopen',
        label: 'Reopen Audit',
        title: 'Reopen Audit',
        icon: <MenuUnfoldOutlined />,
      });
    }

    return menuItems;
  };

  const menuItems = getActionMenuItems();

  return (
    <>
      {menuItems.length > 0 && (
        <Dropdown
          menu={{ items: menuItems, onClick: onMenuClicked }}
          trigger={['click']}
        >
          <Button shape="circle" icon={<EllipsisOutlined />} />
        </Dropdown>
      )}
    </>
  );
};

export default ActionMenu;
