import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { TablePaginationConfig } from 'antd/es/table';
import { ColumnProps } from 'antd/lib/table';
import { SorterResult } from 'antd/lib/table/interface';

import CommissionsActionsMenu from '@totem/components/commissionReport/ActionsMenu';
import CommissioningReportContext from '@totem/components/commissionReport/CommissioningReportContext';
import { CommissionContractRecord } from '@totem/components/commissionReport/types';
import Table from '@totem/components/common/table/Table';
import UserProfileContext from '@totem/components/UserProfileContext';
import { StringFilterOption } from '@totem/types/common';
import {
  DateStringToDate,
  DateStringToLocalDate,
  DaysBetweenToString,
} from '@totem/utilities/dateUtilities';
import { stringifyArray } from '@totem/utilities/tableFilterUtilities';

import './commissionReport.css';

const LinesWithoutDevicesTable = () => {
  const { userProfile } = useContext(UserProfileContext);
  const { reportData, loading, input, setInput } = useContext(
    CommissioningReportContext,
  );
  const [filteredReportData, setFilteredReportData] =
    useState<CommissionContractRecord[]>(reportData);
  const [orgFilterOptions, setOrgFilterOptions] = useState<
    { text: string; value: string }[]
  >([]);

  useEffect(() => {
    if (typeof reportData !== 'undefined' && reportData !== null) {
      let displayData = [...reportData];
      if (input.status !== null && input.status.length > 0) {
        displayData = displayData.filter(
          (rec) =>
            input.status.indexOf(rec.contract.billingContainers.lines.status) >
            -1,
        );
      }
      if (input.organization !== null && input.organization.length > 0) {
        displayData = displayData.filter(
          (rec) => input.organization.indexOf(rec.contract.organizationId) > -1,
        );
      }
      setFilteredReportData(displayData);

      if (reportData !== null) {
        const options: StringFilterOption[] = [];

        for (let idx = 0; idx < reportData.length; idx++) {
          // eslint-disable-next-line max-depth
          if (
            options.findIndex(
              (chk) => chk.value === reportData[idx].contract.organizationId,
            ) < 0
          ) {
            options.push({
              text: reportData[idx].contract.number,
              value: reportData[idx].contract.organizationId,
            });
          }
        }
        setOrgFilterOptions(options);
      }

      return;
    }
    setFilteredReportData([]);
  }, [reportData, input]);

  const getDeviceDisplay = (record: CommissionContractRecord) => {
    if (
      record.contract.billingContainers.lines.devices !== null &&
      record.contract.billingContainers.lines.devices.length > 0
    ) {
      return (
        <span>
          {record.contract.billingContainers.lines.devices.map((dev) => (
            <span key={dev.id}>
              {dev.name}
              <br />
            </span>
          ))}
        </span>
      );
    }
    return <span styleName="actionRequired">NOT ASSIGNED</span>;
  };

  const getServiceDisplay = (record: CommissionContractRecord) => {
    return (
      <span>
        {record.contract.billingContainers.lines.services.map((svc) => (
          <span key={svc.id}>
            {svc.name}
            <br />
          </span>
        ))}
      </span>
    );
  };

  const getLineNumberDisplay = (record: CommissionContractRecord) => {
    let serviceOrderNumber = '';
    for (
      let idx = 0;
      idx < record.contract.billingContainers.lines.keys.length;
      idx++
    ) {
      const lineKey = record.contract.billingContainers.lines.keys[idx];
      if (
        lineKey.system === 'NetSuite' &&
        lineKey.instance === 'NETSUITE_SALES_ORDER_NUMBER'
      ) {
        serviceOrderNumber = lineKey.key;
      }
    }

    return (
      <span>
        {serviceOrderNumber}: {record.contract.billingContainers.lines.number}
      </span>
    );
  };

  const getStartDate = (record: CommissionContractRecord) => {
    if (
      typeof record.tickets !== 'undefined' &&
      record.tickets !== null &&
      record.tickets.length > 0
    ) {
      return record.tickets.map((tick) => (
        <span key={`ts${tick.ticket.id}`}>
          {DateStringToLocalDate(tick.ticket.createdAt)}
          <br />
        </span>
      ));
    }

    for (
      let idx = 0;
      idx < record.contract.billingContainers.lines.keys.length;
      idx++
    ) {
      const key = record.contract.billingContainers.lines.keys[idx];
      if (key.instance === 'IB_CONTRACT_LINE_KEY') {
        return (
          <span key={`ts${key.instance}`}>
            {DateStringToLocalDate(key.createdAt)}
          </span>
        );
      }
    }

    return <span />;
  };

  const getDaysOutstanding = (record: CommissionContractRecord) => {
    if (
      typeof record.tickets !== 'undefined' &&
      record.tickets !== null &&
      record.tickets.length > 0
    ) {
      return record.tickets.map((tick) => (
        <span key={`do${tick.ticket.id}`}>
          {DaysBetweenToString(
            DateStringToDate(tick.ticket.createdAt),
            new Date(),
          )}
          <br />
        </span>
      ));
    }

    for (
      let idx = 0;
      idx < record.contract.billingContainers.lines.keys.length;
      idx++
    ) {
      const key = record.contract.billingContainers.lines.keys[idx];
      if (key.instance === 'IB_CONTRACT_LINE_KEY') {
        return (
          <span key={`do${key.instance}`}>
            {DaysBetweenToString(DateStringToDate(key.createdAt), new Date())}
          </span>
        );
      }
    }

    return <span />;
  };

  const getTicketNumber = (record: CommissionContractRecord) => {
    if (
      typeof record.tickets !== 'undefined' &&
      record.tickets !== null &&
      record.tickets.length > 0
    ) {
      return record.tickets.map((tick) => (
        <Link
          key={`tn${tick.ticket.id}`}
          to={`/dashboard/ticket/${tick.ticket.id}`}
          target={tick.ticket.id}
        >
          {tick.ticket.number}
          <br />
        </Link>
      ));
    }
    return <span />;
  };

  const getTicketQueues = (record: CommissionContractRecord) => {
    if (typeof record.tickets !== 'undefined' && record.tickets !== null) {
      return record.tickets.map((tick) => {
        return tick.ticket.queueAssignments.map((queue) => (
          <span key={queue.name}>
            {queue.name}
            <br />
          </span>
        ));
      });
    }
    return <span />;
  };

  const getBuildingDisplay = (record: CommissionContractRecord) => {
    if (
      record.contract.billingContainers.lines.serviceAddress.name !== null &&
      record.contract.billingContainers.lines.serviceAddress.name !== ''
    ) {
      return (
        <span>
          {record.contract.billingContainers.lines.serviceAddress.name}
        </span>
      );
    }

    if (
      record.contract.billingContainers.address.addressLine1 !== '' ||
      record.contract.billingContainers.address.city !== '' ||
      record.contract.billingContainers.address.region !== ''
    ) {
      return (
        <span styleName="actionRequired">
          BUILDING NOT ASSIGNED
          <br />
          SERVICE ADDRESS
          <br />
          {record.contract.billingContainers.address.addressLine1}
          <br />
          {record.contract.billingContainers.address.city}{' '}
          {record.contract.billingContainers.address.region}{' '}
        </span>
      );
    }
    return <span styleName="actionRequired">BUILDING NOT ASSIGNED</span>;
  };

  const getActionsDisplay = (record: CommissionContractRecord) => {
    return (
      <span>
        <span>Install</span>
        <span> / Assign Device</span>
        {(record.contract.billingContainers.lines.serviceAddress.name ===
          null ||
          record.contract.billingContainers.lines.serviceAddress.name ===
            '') && <span> / Assign Building</span>}
      </span>
    );
  };

  const statusFilterOptions = [
    { text: 'Active', value: 'Active' },
    { text: 'Pending', value: 'Pending' },
    { text: 'Pending Dates', value: 'Pending Dates' },
  ];

  const columns: ColumnProps<CommissionContractRecord>[] = [
    {
      title: 'Organization',
      dataIndex: 'contract.number',
      key: 'organization',
      render: (_, record: CommissionContractRecord) => (
        <span>{record.contract.number.replace(' / ManagedServices', '')}</span>
      ),
      filterMultiple: true,
      filteredValue: stringifyArray(input.organization),
      filters: orgFilterOptions,
    },
    {
      title: 'Building',
      dataIndex: 'contract.billingContainers[0].lines[0].serviceAddress.name',
      key: 'buildingId',
      render: (_, record: CommissionContractRecord) =>
        getBuildingDisplay(record),
    },
    {
      title: 'Devices',
      dataIndex: 'contract.billingContainers[0].lines[0].id',
      key: 'deviceLineId',
      render: (_, record: CommissionContractRecord) => getDeviceDisplay(record),
    },
    {
      title: 'Services',
      dataIndex: 'contract.billingContainers[0].lines[0].id',
      key: 'serviceLineId',
      render: (_, record: CommissionContractRecord) =>
        getServiceDisplay(record),
    },
    {
      title: 'Line Number',
      dataIndex: 'contract.billingContainers[0].lines[0].id',
      key: 'lineNumber',
      render: (_, record: CommissionContractRecord) =>
        getLineNumberDisplay(record),
    },
    {
      title: 'Status',
      dataIndex: 'contract.billingContainers[0].lines[0].status',
      key: 'status',
      render: (_, record: CommissionContractRecord) => (
        <span>{record.contract.billingContainers.lines.status}</span>
      ),
      filterMultiple: true,
      filteredValue: stringifyArray(input.status),
      filters: statusFilterOptions,
    },
    {
      title: 'Required Action',
      dataIndex: 'requiredAction',
      key: 'requiredAction',
      render: (_, record: CommissionContractRecord) =>
        getActionsDisplay(record),
    },
    {
      title: 'Start Date',
      dataIndex: 'ticket.startDate',
      key: 'ticketStartDate',
      render: (_, record: CommissionContractRecord) => getStartDate(record),
    },
    {
      title: 'Days Outstanding',
      dataIndex: 'ticket.daysOutstanding',
      key: 'ticketDaysOutstanding',
      render: (_, record: CommissionContractRecord) =>
        getDaysOutstanding(record),
    },
    {
      title: 'Ticket Number',
      dataIndex: 'ticket.number',
      key: 'ticketNumber',
      render: (_, record: CommissionContractRecord) => getTicketNumber(record),
    },
    {
      title: 'Queue Assignment',
      dataIndex: 'ticket.queueAssignment',
      key: 'queueAssignment',
      render: (_, record: CommissionContractRecord) => getTicketQueues(record),
    },
    {
      title: 'Actions',
      width: 30,
      dataIndex: 'additionalOptions',
      key: 'additionalOptions',
      render: (_, record: CommissionContractRecord) => (
        <div className="center-table-cell">
          {userProfile.organization.id === record.contract.organizationId && (
            <CommissionsActionsMenu record={record} />
          )}
        </div>
      ),
    },
  ];

  const handleTableChange = (
    updatedPagination: TablePaginationConfig,
    filters: SorterResult<CommissionContractRecord>,
    sorter,
  ) => {
    const { ...params } = filters;

    let sortDir: string = sorter.order === 'descend' ? '-1' : '1';
    if (typeof sorter.order === 'undefined' || sorter.order === null) {
      if (
        typeof input.sortDirection !== 'undefined' &&
        input.sortDirection !== null
      ) {
        sortDir = input.sortDirection;
      }
    }

    let sortField = input.sortField ? input.sortField : 'createdAt';
    if (
      typeof sorter.field !== 'undefined' &&
      typeof sorter.order !== 'undefined'
    ) {
      sortField = sorter.field;
    }

    // @ts-ignore
    setInput({
      ...input,
      ...params,
      pageSize: updatedPagination.pageSize,
      page: updatedPagination.current,
      sortField,
      sortDirection: sortDir,
    });
  };

  return (
    <Table
      showSorterTooltip
      columns={columns}
      dataSource={filteredReportData}
      loading={loading}
      rowKey={(record) => record.contract.billingContainers.lines.id}
      onChange={handleTableChange}
      pagination={{
        current: input.page,
        pageSize: input.pageSize,
        total: filteredReportData !== null ? filteredReportData.length : 0,
        showSizeChanger: true,
      }}
    />
  );
};

export default LinesWithoutDevicesTable;
