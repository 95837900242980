import React, { useCallback, useContext, useState } from 'react';
import { CloseSquareOutlined } from '@ant-design/icons';
import { Input } from 'antd';

import RemoteAccessUserContext from '@totem/components/remoteAccess/roleUsers/RemoteAccessUserContext';
import { debounce } from '@totem/utilities/debounce';

import '../remoteAccess.css';

const DEBOUNCE_TIME = 300;

const SearchEntry = () => {
  const { input, setInput } = useContext(RemoteAccessUserContext);
  const [emailSearchTerm, setEmailSearchTerm] = useState<string>('');
  const [nameSearchTerm, setNameSearchTerm] = useState<string>('');

  const sendEmailSearch = useCallback(
    debounce(
      (searchTerm: string) =>
        setInput({ ...input, emailSearchTerm: searchTerm }),
      DEBOUNCE_TIME,
    ),
    [],
  );

  const handleEmailSearch = (searchTerm: string) => {
    setEmailSearchTerm(searchTerm);
    sendEmailSearch(searchTerm);
  };

  const sendNameSearch = useCallback(
    debounce(
      (searchTerm: string) =>
        setInput({ ...input, nameSearchTerm: searchTerm }),
      DEBOUNCE_TIME,
    ),
    [],
  );

  const handleNameSearch = (searchTerm: string) => {
    setNameSearchTerm(searchTerm);
    sendNameSearch(searchTerm);
  };

  return (
    <table style={{ width: '100%' }}>
      <tbody>
        <tr>
          <td>
            <b>Email:</b> <br />
            <Input
              placeholder="Email Search"
              value={emailSearchTerm}
              onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                evt.target.value !== null
                  ? handleEmailSearch(evt.target.value)
                  : handleEmailSearch('')
              }
            />
          </td>
          <td>
            <br />
            <CloseSquareOutlined onClick={() => handleEmailSearch('')} />
          </td>
          <td>
            <b>Name:</b> <br />
            <Input
              width="100%"
              placeholder="Name Search"
              value={nameSearchTerm}
              onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                evt.target.value !== null
                  ? handleNameSearch(evt.target.value)
                  : handleNameSearch('')
              }
            />
          </td>
          <td>
            <br />
            <CloseSquareOutlined onClick={() => handleNameSearch('')} />
          </td>
          <td style={{ width: '40%' }}>&nbsp;</td>
        </tr>
      </tbody>
    </table>
  );
};

export default SearchEntry;
