import React, { useCallback, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { AutoComplete, Input } from 'antd';
import { debounce } from '@totem/utilities/debounce';

const { Search } = Input;

export interface Building {
  id: string;
  name: string;
  region: {
    id: string;
    name: string;
  };
}

export interface Props {
  value: string;
  onChange: (value: string) => void;
  onSelect: (building: Building) => void;
  placeholder?: string;
  allowClear?: boolean;
  notFoundContent?: string;
  style?: React.CSSProperties;
  disabled?: boolean;
  limit?: number;
  regionId?: string;
}

const styles = {
  input: {
    width: '100%',
  },
};

const DEBOUNCE_TIME = 500;

const getData = (buildings: Building[]) =>
  buildings.map(({ id, name }) => ({
    id,
    value: name,
  }));

const GET_BUILDINGS = gql`
  query GetBuildings($input: BuildingsConnectionInput!) {
    buildings(input: $input) {
      buildings {
        id
        name
        region {
          id
          name
        }
      }
    }
  }
`;

const BuildingAutoComplete = ({
  value,
  onChange,
  onSelect,
  style,
  disabled,
  regionId,
  allowClear,
  notFoundContent = 'No results found',
  placeholder = 'Search Buildings',
  limit = 5,
}: Props) => {
  const [name, setName] = useState<string>('');

  const { loading, error, data } = useQuery(GET_BUILDINGS, {
    variables: { input: { name, limit, regionId } },
    fetchPolicy: 'no-cache',
  });

  const buildings = data?.buildings?.buildings || [];

  const handleSearch = useCallback(debounce(setName, DEBOUNCE_TIME), []);

  const handleSelect = (_, option: any) => {
    onSelect(buildings.find((building) => building.id === option.id));
  };

  return (
    <AutoComplete
      value={value}
      onChange={onChange}
      options={getData(buildings)}
      style={{ ...styles.input, ...style }}
      onSelect={handleSelect}
      onSearch={handleSearch}
      placeholder={placeholder}
      disabled={disabled ?? false}
      notFoundContent={notFoundContent}
      children={
        <Search
          allowClear={allowClear}
          type="search"
          loading={loading || !!error}
        />
      }
    />
  );
};

export default BuildingAutoComplete;
