import React, { useEffect, useState } from 'react';

import EventDetailView from '@totem/components/events/detailView/EventDetailView';
import { Event } from '@totem/types/events';
import { getToken } from '@totem/utilities/accountUtilities';
import { EVENTS_ENDPOINT } from '@totem/utilities/endpoints';

export interface Props {
  eventId: string;
}

const Ticket_Event = ({ eventId }: Props) => {
  const [event, setEvent] = useState<Event>(null);

  useEffect(() => {
    fetch(`${EVENTS_ENDPOINT}/${eventId}`, {
      method: 'GET',
      headers: new Headers({
        Authorization: `Bearer ${getToken()}`,
      }),
    })
      .then((res) => res.json())
      .then((result: Event) => {
        setEvent(result);
      });
  }, [eventId]);

  return <>{event !== null && <EventDetailView event={event} />}</>;
};

export default Ticket_Event;
