import { createContext } from 'react';

interface Context {
  isDebug: boolean;
  pageTitle: string;
  pageSubtitle: string;
  setTitle: (title: string) => void;
  setSubtitle: (subtitle: string) => void;
}

export default createContext<Context>({
  isDebug: false,
  pageTitle: '',
  pageSubtitle: '',
  setTitle: () => {},
  setSubtitle: () => {},
});
