import { gql } from '@apollo/client';

export const GET_CUSTOMER = gql`
  query Customer($id: String!) {
    customer(id: $id) {
      id
      name
      type
      domain
    }
  }
`;

export const GET_CUSTOMERS = gql`
  query Customers($input: CustomersConnectionInput!) {
    customers(input: $input) {
      customers {
        id
        name
        type
        domain
      }
      totalCount
    }
  }
`;

export const CREATE_CUSTOMER = gql`
  mutation CustomerCreate($input: CustomerCreateInput!) {
    customerCreate(input: $input) {
      name
      domain
    }
  }
`;

export const UPDATE_CUSTOMER = gql`
  mutation CustomerUpdate($input: CustomerUpdateInput!) {
    customerUpdate(input: $input) {
      id
      name
      domain
    }
  }
`;

export const DELETE_CUSTOMER = gql`
  mutation CustomerDelete($id: ID!) {
    customerDelete(id: $id)
  }
`;
