import React from 'react';
import { Layout } from 'antd';

import colors from '@totem/styles/colors';

import OrganizationSwitcherDropdown from '../common/organizationSwitcherDropdown/OrganizationSwitcherDropdown';
import ProfileIcon from '../common/profileIcon/ProfileIcon';

import HelpDeskModal from './HelpDeskModal';

const { Header: AntDHeader } = Layout;

const styles = {
  header: {
    background: colors.neutral.white,
    padding: 0,
    display: 'flex',
    flexDirection: 'row' as any,
    justifyContent: 'flex-end',
    alignItems: 'center',
    borderBottom: `2px solid ${colors.neutral.typhoon}`,
  },
};

const Header = () => {
  return (
    <AntDHeader style={styles.header}>
      <OrganizationSwitcherDropdown />
      <HelpDeskModal />
      <ProfileIcon />
    </AntDHeader>
  );
};

export default Header;
