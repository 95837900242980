import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { Card, Col, Row } from 'antd';

import { Volume } from '@totem/components/devices/volumes/types';
import { FormatBytes } from '@totem/components/devices/volumes/utilities';

type Props = {
  volume: Volume;
};

const VolumeCard = ({ volume }: Props) => {
  const labels = ['Free', 'Used'];
  const series = [volume.freeSpace, volume.size - volume.freeSpace];

  const getFreePercent = () => {
    if (volume.size === 0) {
      return '-';
    }
    return `${((volume.freeSpace / volume.size) * 100).toFixed(2)}%`;
  };

  const options: ApexCharts.ApexOptions = {
    chart: {
      type: 'donut',
      toolbar: { show: true, tools: { download: true } },
    },
    dataLabels: {
      enabled: false,
    },
    labels,
    colors: ['#4CBB17', '#EE4B2B'],
    legend: {
      width: 100,
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              show: true,
              showAlways: true,
              fontSize: '14px',
              label: volume.size > 0 ? 'Free' : '',
              formatter: getFreePercent,
            },
            value: {
              show: true,
              fontSize: '14px',
              fontWeight: 'bold',
              offsetY: -10,
            },
          },
        },
      },
    },
    responsive: [
      {
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
  };

  return (
    <Card style={{ width: '600px', marginBottom: '15px', marginRight: '15px' }}>
      <Row>
        <Col span={12}>
          <div>
            <b>Volume Name: </b>
            {volume.volumeName}
            <br />
            <b>Letter: </b>
            {volume.letter}
            <br />
            <b>File System: </b>
            {volume.fileSystem}
            <br />
            <b>Size: </b>
            {FormatBytes(volume.size)}
            <br />
            <b>Free Space: </b>
            {FormatBytes(volume.freeSpace)}
            <br />
          </div>
        </Col>
        <Col span={12}>
          <ReactApexChart
            options={options}
            series={series}
            labels={labels}
            type="donut"
            width="300px"
            height="300px"
          />
        </Col>
      </Row>
    </Card>
  );
};

export default VolumeCard;
