import actionTypes from './actionTypes';

export const dismissNotification = () => ({
  type: actionTypes.DISMISS_NOTIFICATION,
});

export const createNotification = data => ({
  type: actionTypes.CREATE_NOTIFICATION,
  data,
});
