import React, { useContext, useRef, useState } from 'react';
import { DownloadOutlined } from '@ant-design/icons';
import { Button, Dropdown, DropdownProps } from 'antd';

import SurveyInstanceContext from '@totem/components/surveyV2/instances/SurveyInstancesContext';
import { buildSurveyInstanceFilters } from '@totem/components/surveyV2/instances/utilities';
import { Params } from '@totem/types/common';
import api from '@totem/utilities/api';
import { V2_SURVEY_ENDPOINT } from '@totem/utilities/endpoints';
import fileUtilities from '@totem/utilities/fileUtilities';

const styles = {
  anchor: {
    position: 'relative' as any,
  },
};
const ExportButton = () => {
  const { input, setIsLoading } = useContext(SurveyInstanceContext);
  const [visible, setVisible] = useState<boolean>(false);
  const anchor = useRef();
  const buildParameters = () => {
    const params: Params = {
      paging: {
        page: 1,
        pageSize: 10000,
      },
      sort: {
        field: input.sortField,
        direction: +input.sortDirection,
      },
      filters: buildSurveyInstanceFilters(input),
    };

    return params;
  };

  const exportData = async (level: string) => {
    setIsLoading(true);

    const params = buildParameters();

    const { data } = await api.post(
      `${V2_SURVEY_ENDPOINT}/export/${level}`,
      params,
    );
    fileUtilities.downloadFile(`survey_${level}`, data);
    setIsLoading(false);
  };
  const handleOpenChange: DropdownProps['onOpenChange'] = (nextOpen, info) => {
    if (info.source === 'trigger' || nextOpen) {
      setVisible(nextOpen);
    }
  };

  return (
    <Dropdown
      className="menu-item-no-wrap"
      menu={{
        items: [
          {
            key: 'summary',
            label: 'Summary',
          },
          {
            key: 'details',
            label: 'Details',
          },
        ],
        onClick: ({ key }: any) => exportData(key),
        multiple: false,
      }}
      trigger={['click']}
      open={visible}
      onOpenChange={handleOpenChange} // handleVisibleChange
      getPopupContainer={() => anchor.current}
    >
      <div style={styles.anchor} ref={anchor}>
        <Button icon={<DownloadOutlined />}>Export</Button>
      </div>
    </Dropdown>
  );
};

export default ExportButton;
