import React, { ReactNode, useCallback, useEffect, useState } from 'react';

import TemplatesContext from '@totem/components/surveyV2/templates/TemplatesContext';
import {
  TemplateConnectionInput,
  TemplateQueryResult,
} from '@totem/components/surveyV2/templates/types';
import { SurveyTemplate } from '@totem/components/surveyV2/types';
import { Params } from '@totem/types/common';
import { getToken } from '@totem/utilities/accountUtilities';
import { V2_SURVEY_ENDPOINT } from '@totem/utilities/endpoints';
import { omitNilOrEmpty } from '@totem/utilities/objectUtilities';
import { CheckResponseShowError } from '@totem/utilities/responseUtilities';

type Props = {
  children?: ReactNode;
};
const TemplatesContainer = ({ children }: Props) => {
  const [input, updateInput] = useState<TemplateConnectionInput>({
    pageSize: 10,
    page: 1,
    sortField: 'name',
    sortDirection: '1',
  });
  const [refreshData, setRefreshData] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [data, setData] = useState<SurveyTemplate[]>([]);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [action, setAction] = useState<string>('');
  const [selectedTemplate, setSelectedTemplate] =
    useState<SurveyTemplate>(null);

  const setInput = (updated: Partial<TemplateConnectionInput>) => {
    updateInput(omitNilOrEmpty({ ...input, ...updated }));
    setRefreshData(true);
  };

  const sendDuplicateTemplate = useCallback(async (templateId: string) => {
    fetch(`${V2_SURVEY_ENDPOINT}/template/${templateId}/duplicate`, {
      method: 'POST',
      headers: new Headers({
        Authorization: `Bearer ${getToken()}`,
      }),
    })
      .then((res) => {
        CheckResponseShowError(res);
      })
      .then(() => {
        setRefreshData(true);
        setIsLoading(false);
      });
  }, []);

  const sendDeleteTemplate = useCallback(async (templateId: string) => {
    fetch(`${V2_SURVEY_ENDPOINT}/template/${templateId}`, {
      method: 'DELETE',
      headers: new Headers({
        Authorization: `Bearer ${getToken()}`,
      }),
    })
      .then((res) => {
        CheckResponseShowError(res);
      })
      .then(() => {
        setRefreshData(true);
        setIsLoading(false);
      });
  }, []);

  const buildParameters = () => {
    const params: Params = {
      paging: {
        page: input.page,
        pageSize: input.pageSize,
      },
      sort: {
        field: input.sortField,
        direction: +input.sortDirection,
      },
      filters: [],
    };

    return params;
  };

  useEffect(() => {
    if (refreshData) {
      setRefreshData(false);

      setIsLoading(true);
      const params: Params = buildParameters();

      fetch(`${V2_SURVEY_ENDPOINT}/templates`, {
        method: 'POST',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
        body: JSON.stringify(params),
      })
        .then((res) => res.json())
        .then((result: TemplateQueryResult) => {
          setData(result.templates);
          setTotalRecords(result.paging.totalRecords);
        })
        .then(() => {
          setIsLoading(false);
        });
    }
  }, [refreshData]);

  const handleAction = (newAction: string, item: SurveyTemplate) => {
    switch (newAction) {
      case 'duplicate':
        sendDuplicateTemplate(item.id);
        break;
      case 'delete':
        sendDeleteTemplate(item.id);
        break;
      default:
        setAction(newAction);
        setSelectedTemplate(item);
        break;
    }
  };

  return (
    <TemplatesContext.Provider
      value={{
        input,
        setInput,
        loading: isLoading,
        setIsLoading,
        data,
        totalRecords,
        action,
        selectedTemplate,
        onAction: handleAction,
      }}
    >
      <div>{children}</div>
    </TemplatesContext.Provider>
  );
};

export default TemplatesContainer;
