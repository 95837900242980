import React, { useContext } from 'react';
import BuildingSummaryContext from '@totem/components/buildingSummary/buildingSummaryContext';
import FilterChip from '@totem/components/common/FilterChip';
import { BuildingSummaryInput } from '@totem/components/buildingSummary/types';
import { isNotNull } from '@totem/utilities/common';

const styles = {
  filterChip: {
    marginLeft: '2rem',
    marginTop: '2rem',
  },
};

const FilterChips = () => {
  const { input, setInput } = useContext(BuildingSummaryContext);

  const handleMultiFilterDelete = (
    property: keyof BuildingSummaryInput,
    value: string,
  ) => {
    // @ts-ignore
    const updatedValue = input[property].filter((val) => val !== value);
    setInput({
      ...input,
      [property]: updatedValue.length ? updatedValue : null,
    });
  };

  const hasFilters = isNotNull(input) && (
    (isNotNull(input.type) && input.type.length > 0)
  );

  return (
    <>
      {hasFilters && (
        <span style={{marginLeft: 16}}>Filters: </span>
      )}
      {input.type && input.type.map((option) => {
        return (
          <FilterChip
            key={option}
            label={`Type: ${option}`}
            style={styles.filterChip}
            onClose={() => handleMultiFilterDelete('type', option)}
          />
        );
      })}
      {hasFilters && (
        <><br /><br /></>
      )}
    </>
  );
};

export default FilterChips;
