import * as R from 'ramda';
import { v4 as uuidv4 } from 'uuid';

import { Criticality } from '@totem/types/criticality';
import {
  SurveyInstanceDetailState,
  SurveyTemplateConfigurationState,
  SurveyTemplateDetailState,
} from '@totem/types/store';
import {
  Category,
  Condition,
  ConditionActionPayload,
  Level,
  LevelType,
  QuestionOption,
  QuestionValue,
  ScoredCategory,
  SurveyError,
  SurveyInstance,
  SurveyQuestion,
  SurveyTemplate,
} from '@totem/types/survey';

export const SURVEY_TEMPLATE_DETAIL_MODEL = 'surveyTemplateDetail';
export const ADDITIONAL_CONTEXT_SUFFIX = 'additionalContext';

const LEVEL_TYPES: LevelType[] = [
  {
    type: 1,
    typeName: 'One',
    displayName: 'Level One',
    defaultScoreBy: 'questions',
  },
  {
    type: 2,
    typeName: 'Two',
    displayName: 'Level Two',
    defaultScoreBy: 'sublevels',
  },
  {
    type: 3,
    typeName: 'Three',
    displayName: 'Level Three',
    defaultScoreBy: 'sublevels',
  },
];

function instanceOfQuestionValue(obj: any): obj is QuestionValue {
  if (typeof obj === 'string') {
    return false;
  }
  if (typeof obj === 'object') {
    return 'value' in obj;
  }
  return false;
}

export const createSurveyValue = (value: any): QuestionValue => {
  if (instanceOfQuestionValue(value)) {
    return value;
  }

  if (typeof value === 'string') {
    let valueFloat: number = Number(value);
    let valueInt: number = 0;

    if (isNaN(valueFloat)) {
      valueFloat = 0.0;
    }

    if (Number.isInteger(valueFloat)) {
      valueInt = 0;
    }

    return {
      value,
      valueInt,
      valueFloat,
      valueArray: [],
    };
  } else if (typeof value === 'number') {
    let valueFloat: number = Number(value);
    let valueInt: number = 0;

    if (isNaN(valueFloat)) {
      valueFloat = 0.0;
    }

    let newValue = valueFloat.toString(10);
    if (Number.isInteger(valueFloat)) {
      valueInt = 0;
      newValue = valueInt.toString(10);
    }

    return {
      value: newValue,
      valueInt,
      valueFloat,
      valueArray: [],
    };
  }

  return {
    value: '',
    valueInt: 0,
    valueFloat: 0.0,
    valueArray: [],
  };
};

export const createSurveyUploadValue = (value: any): QuestionValue => {
  if (instanceOfQuestionValue(value)) {
    return value;
  }

  const newValue = {
    value: '',
    valueInt: 0,
    valueFloat: 0.0,
    valueArray: [],
  };

  if (Array.isArray(value)) {
    for (let idx = 0; idx < value.length; idx++) {
      const childValue = createSurveyValue(value[idx]);
      newValue.valueArray.push(childValue);
    }
    newValue.value = newValue.valueArray.map(qv => qv.value).join(', ');
  }

  return newValue;
};

export const createSurveyDateValue = (value: any): QuestionValue => {
  if (instanceOfQuestionValue(value)) {
    return value;
  }

  if (typeof value === 'number') {
    let valueFloat: number = Number(value);
    let valueInt: number = 0;

    if (isNaN(valueFloat)) {
      valueFloat = 0.0;
    }

    let newValue = valueFloat.toString(10);
    if (Number.isInteger(value)) {
      valueInt = value;
      newValue = value.toString(10);
    }

    const date = new Date(value);
    newValue = date.toUTCString();

    return {
      value: newValue,
      valueInt,
      valueFloat,
      valueArray: [],
    };
  }

  return {
    value: '',
    valueInt: 0,
    valueFloat: 0.0,
    valueArray: [],
  };
};

export const createNewLevel = (levelType: LevelType): Level => {
  const { type, typeName, defaultScoreBy } = levelType;

  return {
    id: uuidv4(),
    name: '',
    type,
    typeName,
    scoreBy: defaultScoreBy,
    sublevels: [],
  };
};

export const createNewCondition = (): Condition => ({
  id: uuidv4(),
  operation: 'NOP',
  fieldType: '',
  fieldId: '',
  value: '',
  subconditions: [],
});

export const isQuestionAnswered = (
  question: SurveyQuestion,
  questionTypes: SurveyQuestion[],
): boolean => {
  const questionType = questionTypes.find(({ type }) => type === question.type);
  if (question.type === 'information') {
    return true;
  }

  return (
    questionType && !R.equals(questionType.value.value, question.value.value)
  );
};

export const createNewQuestion = (
  type: string,
  questionTypes: SurveyQuestion[],
): SurveyQuestion => {
  const question = questionTypes.find(
    (questionType: SurveyQuestion): boolean => questionType.type === type,
  );
  return { ...question, id: uuidv4() };
};

export const createDefaultQuestionOptions = (): QuestionOption[] => {
  const yesOption: QuestionOption = {
    label: 'Yes',
    score: createSurveyValue('Yes'),
    recommendation: '',
    criticality: Criticality.Info,
  };

  const noOption: QuestionOption = {
    label: 'No',
    score: createSurveyValue('No'),
    recommendation: '',
    criticality: Criticality.Info,
  };

  return [yesOption, noOption];
};

export const createDefaultQuestion = (): SurveyQuestion => {
  return {
    id: uuidv4(),
    additionalContext: '',
    condition: createNewCondition(),
    hint: '',
    instance: 1,
    instanceIdentifier: false,
    label: 'Question',
    levels: [],
    name: 'Q1',
    options: createDefaultQuestionOptions(),
    required: false,
    requiresAdditionalContext: false,
    scored: false,
    type: 'enumsingle',
    value: createSurveyValue(''),
    visible: true,
  };
};

export const createNewQuestionOption = (): QuestionOption => ({
  label: '',
  score: createSurveyValue(''),
  recommendation: '',
  criticality: Criticality.Info,
});

export const createNewCategory = (): Category => {
  const firstQuestion = createDefaultQuestion();

  return {
    id: uuidv4(),
    name: 'C1',
    title: 'Category',
    description: '',
    questions: [firstQuestion],
    multiInstance: false,
    multiInstanceLabel: '',
    visible: true,
    condition: createNewCondition(),
  };
};

export const createNewSurveyTemplate = (): SurveyTemplate => {
  const firstCategory = createNewCategory();

  return {
    id: '',
    name: 'S1',
    title: 'Survey Name',
    description: '',
    helpText: '',
    levelTypes: LEVEL_TYPES,
    levels: [],
    categories: [firstCategory],
    version: 0,
    isPublished: false,
  };
};

// swaps two array elements without mutating the original array
export const swap = R.curry(
  (firstIndex: number, secondIndex: number, list: any[]): any[] => {
    if (
      firstIndex < 0 ||
      secondIndex < 0 ||
      firstIndex > list.length - 1 ||
      secondIndex > list.length - 1
    ) {
      return list;
    }

    const value1 = list[firstIndex];
    const value2 = list[secondIndex];

    return R.pipe(
      R.set(R.lensIndex(firstIndex), value2),
      R.set(R.lensIndex(secondIndex), value1),
    )(list);
  },
);

export const createNewInstance = (
  category: Category | ScoredCategory,
  questionTypes: SurveyQuestion[],
): SurveyQuestion[] => {
  const { questions } = category;

  const originalQuestions = questions.filter(
    ({ instance }: SurveyQuestion): boolean => R.equals(instance, 1),
  );
  const lastInstance = Math.max(
    ...questions.map(({ instance }: SurveyQuestion): number => instance),
  );
  const instance = R.inc(lastInstance);

  const newQuestions = originalQuestions.map(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ({ updatedAt, updatedBy, ...question }: SurveyQuestion): SurveyQuestion => {
      const id = uuidv4();
      const additionalContext = '';
      const questionType = questionTypes.find(
        ({ type }: SurveyQuestion): boolean => type === question.type,
      );
      const value = questionType ? questionType.value : createSurveyValue('');

      return {
        ...question,
        id,
        instance,
        value,
        additionalContext,
      };
    },
  );

  return [...questions, ...newQuestions];
};

export const deleteInstance = (
  category: Category | ScoredCategory,
  instanceToDelete: number,
): SurveyQuestion[] => {
  const { questions } = category;

  return questions
    .filter(
      ({ instance }: SurveyQuestion): boolean => instance !== instanceToDelete,
    )
    .map(
      (question: SurveyQuestion): SurveyQuestion => {
        const instance = R.lt(question.instance, instanceToDelete)
          ? question.instance
          : R.dec(question.instance);
        return { ...question, instance };
      },
    );
};

export const deleteAllInstances = (category: Category): SurveyQuestion[] => {
  const { questions } = category;

  return questions.filter(
    ({ instance }: SurveyQuestion): boolean => instance === 1,
  );
};

export const getQuestionAnswer = (question: SurveyQuestion): string => {
  const { value, type } = question;

  if (value.valueArray !== null && value.valueArray.length > 0) {
    return value.valueArray.join(', ');
  }

  if (R.equals(type, 'date')) {
    return new Date(value.value).toLocaleDateString();
  }

  return R.isEmpty(value.value) ? 'N/A' : value.value;
};

const validateQuestions = (
  category: ScoredCategory,
  questionTypes: SurveyQuestion[],
): SurveyError[] =>
  category.questions.reduce(
    (errors: SurveyError[], question: SurveyQuestion): SurveyError[] => {
      const { required, visible } = question;

      const hasError =
        required &&
        category.visible &&
        visible &&
        !isQuestionAnswered(question, questionTypes);

      return hasError
        ? [
            ...errors,
            {
              categoryId: category.id,
              categoryName: category.name,
              questionId: question.id,
              questionLabel: question.label,
              message: 'This question is required.',
            },
          ]
        : errors;
    },
    [],
  );

export const validateSurvey = (
  { categories }: SurveyInstance,
  { questionTypes }: SurveyTemplateConfigurationState,
): SurveyError[] =>
  categories.reduce(
    (errors: SurveyError[], category: ScoredCategory): SurveyError[] => [
      ...errors,
      ...validateQuestions(category, questionTypes),
    ],
    [],
  );

export const getMultiInstanceDisplayName = (
  category: Category | ScoredCategory,
  instance: number,
): string => {
  const { multiInstanceLabel } = category;

  const questions = category.questions.filter(
    (question: SurveyQuestion): boolean =>
      R.equals(question.instance, instance) && question.instanceIdentifier,
  );

  const displayName = questions.reduce(
    (accum: string, { value }: SurveyQuestion): string => {
      const name =
        value.valueArray !== null
          ? value.valueArray
              .filter((val: QuestionValue): boolean => !R.isEmpty(val.value))
              .join(' - ')
          : value.value;

      if (!name) {
        return accum;
      }

      return accum ? `${accum} - ${name}` : name;
    },
    '',
  );

  return displayName || `${multiInstanceLabel} #${instance}`;
};

export const getAllQuestions = ({
  categories,
}: SurveyInstance): SurveyQuestion[] => {
  return categories.reduce(
    (accum, { questions }): SurveyQuestion[] => [...accum, ...questions],
    [],
  );
};

export const instanceHasAnsweredQuestions = (
  { questions }: Category,
  instance: number,
  questionTypes: SurveyQuestion[],
): boolean => {
  return questions
    .filter(question => question.instance === instance)
    .some(question => isQuestionAnswered(question, questionTypes));
};

const getQuestionById = (
  instance: SurveyInstanceDetailState,
  questionId: string,
): SurveyQuestion => {
  for (const category of instance.categories) {
    const question = category.questions.find(qu => qu.id === questionId);
    if (question) {
      return question;
    }
  }
  return null;
};

const getQuestionAnswerValue = (
  instance: SurveyInstanceDetailState,
  questionId: string,
): string => {
  const question = getQuestionById(instance, questionId);
  if (question !== null) {
    if (typeof question.value === 'object') {
      return question.value.value;
    }
  }
  return 'N/A';
};

export const checkConditions = (
  instance: SurveyInstanceDetailState,
  conditions: Condition,
): boolean => {
  const operation = conditions.operation;
  const results: boolean[] = [];

  switch (operation) {
    case 'AND': {
      if (conditions.subconditions.length === 0) {
        return true;
      }

      for (let idx = 0; idx < conditions.subconditions.length; idx++) {
        results.push(checkConditions(instance, conditions.subconditions[idx]));
      }
      return results.every(rs => rs === true);
    }
    case 'OR': {
      if (conditions.subconditions.length === 0) {
        return true;
      }

      for (let idx = 0; idx < conditions.subconditions.length; idx++) {
        if (checkConditions(instance, conditions.subconditions[idx])) {
          return true;
        }
      }

      return false;
    }
    case '==': {
      if (conditions.fieldType === 'question') {
        return (
          getQuestionAnswerValue(instance, conditions.fieldId) ===
          conditions.value
        );
      }
      break;
    }
    case '!=': {
      if (conditions.fieldType === 'question') {
        return (
          getQuestionAnswerValue(instance, conditions.fieldId) !==
          conditions.value
        );
      }
      break;
    }
    default:
      return true;
  }
  return true;
};

export const processConditions = (
  questionId: string,
  instance: SurveyInstanceDetailState,
): SurveyInstanceDetailState => {
  const changedQuestion = getQuestionById(instance, questionId);
  if (
    changedQuestion.type === 'enumsingle' ||
    changedQuestion.type === 'enummultiple'
  ) {
    for (const category of instance.categories) {
      if (
        category.condition !== null &&
        category.condition.operation !== 'NOP' &&
        category.condition.operation !== ''
      ) {
        category.visible = checkConditions(instance, category.condition);
      } else {
        category.visible = true;
      }

      for (const question of category.questions) {
        // eslint-disable-next-line max-depth
        if (
          question.condition !== null &&
          question.condition.operation !== 'NOP' &&
          question.condition.operation !== ''
        ) {
          question.visible = checkConditions(instance, question.condition);
        } else {
          question.visible = true;
        }
      }

      // TOTEM-1512:  Add checks to try to keep category counts current.
      category.totalQuestions = category.questions.filter(
        quest => quest.visible === true && quest.type !== 'information',
      ).length;
      category.completedQuestions = category.questions.filter(
        quest =>
          quest.visible === true &&
          quest.value.value !== '' &&
          quest.type !== 'information',
      ).length;

      if (category.visible && category.totalQuestions === 0) {
        category.visible = false;
      }
    }
  }

  return instance;
};

const getTemplateQuestionById = (
  surveyTemplate: SurveyTemplate,
  questionId: string,
): SurveyQuestion => {
  for (const category of surveyTemplate.categories) {
    const question = category.questions.find(qu => qu.id === questionId);
    if (question) {
      return question;
    }
  }
  return null;
};

export const getConditionDescription = (
  surveyTemplate: SurveyTemplate,
  condition: Condition,
  depth: number = 0,
): string => {
  let description = '';
  const paddingBraces = ''.padStart(depth * 18, '&nbsp;');
  const paddingContent = ''.padStart((depth + 1) * 18, '&nbsp;');

  switch (condition.operation) {
    case 'AND':
    case 'OR':
      description = `${paddingBraces}{<br/>`;
      for (let idx = 0; idx < condition.subconditions.length; idx++) {
        if (idx > 0) {
          description += `${paddingContent + condition.operation}<br/>`;
        }
        description += `${getConditionDescription(
          surveyTemplate,
          condition.subconditions[idx],
          depth + 1,
        )}<br/>`;
      }
      description += `${paddingBraces}}`;
      return description;
    case '==':
    case '!=':
      if (condition.fieldType === 'question') {
        const question = getTemplateQuestionById(
          surveyTemplate,
          condition.fieldId,
        );
        return `${paddingBraces}${question.label || 'Untitled'} 
        ${condition.operation} ${condition.value}`;
      }
      return '';
    default:
      return '';
  }
};

export const cloneCondition = (condition: Condition): Condition => {
  const newCondition = createNewCondition();
  newCondition.id = condition.id;
  newCondition.operation = condition.operation;
  newCondition.fieldType = condition.fieldType;
  newCondition.fieldId = condition.fieldId;
  newCondition.value = condition.value;

  if (condition.subconditions !== null) {
    newCondition.subconditions = [];
    for (let idx = 0; idx < condition.subconditions.length; idx++) {
      newCondition.subconditions.push(
        cloneCondition(condition.subconditions[idx]),
      );
    }
  }

  return newCondition;
};

export const cloneSurveyState = (
  state: SurveyTemplateDetailState,
): SurveyTemplateDetailState => {
  return {
    ...state,
    categories: state.categories.map(category => {
      return {
        ...category,
        condition: cloneCondition(category.condition),
        questions: category.questions.map(question => {
          return {
            ...question,
            condition: cloneCondition(question.condition),
          };
        }),
      };
    }),
  };
};

export const getConditionFromConditions = (
  condition: Condition,
  conditionId: string,
): Condition => {
  if (condition.id === conditionId) {
    return condition;
  }
  if (condition.subconditions !== null) {
    for (let idx = 0; idx < condition.subconditions.length; idx++) {
      const results = getConditionFromConditions(
        condition.subconditions[idx],
        conditionId,
      );
      if (results !== null) {
        return results;
      }
    }
  }

  return null;
};

export const processConditionAction = (
  startingCondition: Condition,
  change: ConditionActionPayload,
): Condition => {
  const foundCondition = getConditionFromConditions(
    startingCondition,
    change.conditionId,
  );

  if (foundCondition !== null) {
    // eslint-disable-next-line default-case,max-depth
    switch (change.method) {
      case 'Update':
        foundCondition.id = change.condition.id;
        foundCondition.operation = change.condition.operation;
        foundCondition.fieldType = change.condition.fieldType;
        foundCondition.fieldId = change.condition.fieldId;
        foundCondition.value = change.condition.value;
        foundCondition.subconditions = change.condition.subconditions;
        break;
      case 'AddToGroup':
        if (foundCondition.subconditions === null) {
          foundCondition.subconditions = [];
        }
        foundCondition.subconditions.push(change.condition);
        break;
      case 'RemoveFromGroup':
        if (startingCondition.id === change.condition.id) {
          foundCondition.operation = 'NOP';
          foundCondition.subconditions = [];
        } else {
          foundCondition.subconditions = foundCondition.subconditions.filter(
            cond => cond.id !== change.condition.id,
          );
        }
        break;
    }
  }

  return foundCondition;
};

export const getQuestions = (
  state: SurveyTemplateDetailState,
): SurveyQuestion[] => {
  let questions: SurveyQuestion[] = [];

  state.categories.forEach(cat => {
    questions = questions.concat(cat.questions);
  });

  return questions;
};
