import React, { ReactNode, useEffect, useState } from 'react';

import ContactEditModal from '@totem/components/settings/sections/contacts/contactEditModal';
import OrganizationContactsContext from '@totem/components/settings/sections/contacts/contactsContext';
import {
  OrganizationContactsInput,
  PointOfContact,
} from '@totem/components/settings/sections/contacts/types';
import { getToken } from '@totem/utilities/accountUtilities';
import { ORGANIZATION_CONTACTS_ENDPOINT } from '@totem/utilities/endpoints';

type Props = {
  children?: ReactNode;
};

const ContactsContainer = ({ children }: Props) => {
  const [input, updateInput] = useState<OrganizationContactsInput>({});
  const [refreshData, setRefreshData] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [data, setData] = useState<PointOfContact[]>([]);
  const [filteredData, setFilteredData] = useState<PointOfContact[]>([]);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [isSending, setIsSending] = useState<boolean>(false);
  const [sendPutContact, setSendPutContact] = useState<boolean>(false);
  const [sendRemoveContact, setSendRemoveContact] = useState<boolean>(false);
  const [selectedContact, setSelectedContact] = useState<PointOfContact>(null);
  const [showEdit, setShowEdit] = useState<boolean>(false);

  const setInput = (updated: Partial<OrganizationContactsInput>) => {
    updateInput({ ...input, ...updated });
    setRefreshData(true);
  };

  useEffect(() => {
    if (refreshData) {
      if (isSending) {
        return;
      }
      const endpoint = `${ORGANIZATION_CONTACTS_ENDPOINT}`;

      setIsLoading(true);
      setIsSending(true);
      fetch(`${endpoint}`, {
        method: 'GET',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
      })
        .then(res => res.json())
        .then((result: PointOfContact[]) => {
          setData(result);
          setFilteredData(result);
          setTotalRecords(result.length);
        })
        .then(() => {
          setIsLoading(false);
          setIsSending(false);
        });
    }
  }, [refreshData, input]);

  useEffect(() => {
    if (sendPutContact && selectedContact !== null) {
      setSendPutContact(false);
      const endpoint = `${ORGANIZATION_CONTACTS_ENDPOINT}`;

      fetch(`${endpoint}`, {
        method: 'POST',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
        body: JSON.stringify(selectedContact),
      })
        .then(res => res.json())
        .then((result: PointOfContact[]) => {
          setSelectedContact(null);
          setShowEdit(false);
          setData(result);
          setFilteredData(result);
          setTotalRecords(result.length);
        });
    }
  }, [sendPutContact, selectedContact]);

  useEffect(() => {
    if (sendRemoveContact && selectedContact !== null) {
      setSendRemoveContact(false);
      const endpoint = `${ORGANIZATION_CONTACTS_ENDPOINT}/${selectedContact.id}`;

      fetch(`${endpoint}`, {
        method: 'DELETE',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
      })
        .then(res => res.json())
        .then((result: PointOfContact[]) => {
          setSelectedContact(null);
          setData(result);
          setFilteredData(result);
          setTotalRecords(result.length);
        });
    }
  }, [sendRemoveContact, selectedContact]);

  const handleAction = (action: string, contact: PointOfContact) => {
    console.log(`Action: ${action} Contact: ${JSON.stringify(contact)}`);
    // eslint-disable-next-line default-case
    switch (action) {
      case 'PutContact':
        setSelectedContact(contact);
        setSendPutContact(true);
        break;
      case 'RemoveContact':
        setSelectedContact(contact);
        setSendRemoveContact(true);
        break;
      case 'EditContact':
        setSelectedContact(contact);
        setShowEdit(true);
        break;
      case 'CancelEditContact':
        setSelectedContact(null);
        setShowEdit(false);
        break;
    }
  };

  return (
    <OrganizationContactsContext.Provider
      value={{
        input,
        setInput,
        loading: isLoading,
        data,
        filteredData,
        totalRecords,
        onAction: handleAction,
      }}
    >
      <div>{children}</div>
      {showEdit && selectedContact !== null && (
        <ContactEditModal open={showEdit} pointOfContact={selectedContact} />
      )}
    </OrganizationContactsContext.Provider>
  );
};

export default ContactsContainer;
