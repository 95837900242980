import React, { ReactNode, useContext } from 'react';

import DeviceHealthContext from '@totem/components/devices/health/deviceHealthContext';

type Props = {
  children?: ReactNode;
};

const DeviceHealthConditional = ({ children }: Props) => {
  const { data } = useContext(DeviceHealthContext);

  const showChildren = typeof data !== 'undefined' && data !== null;

  return (
    <div>
      {showChildren && children}
      {!showChildren && <span />}
    </div>
  );
};

export default DeviceHealthConditional;
