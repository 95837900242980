import React, { useContext } from 'react';

import VolumeCard from '@totem/components/devices/volumes/volumeCard';
import VolumeContext from '@totem/components/devices/volumes/volumeContext';

const VolumeCards = () => {
  const { data } = useContext(VolumeContext);

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      {typeof data !== 'undefined' &&
        data !== null &&
        data.map((volume) => (
          <VolumeCard
            key={volume.letter + volume.volumeName + volume.label}
            volume={volume}
          />
        ))}
    </div>
  );
};

export default VolumeCards;
