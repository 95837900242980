import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Auth0Lock from 'auth0-lock';

import { resetStore } from '@totem/actions/resetActions';
import LoginLogo from '@totem/components/logos/LoginLogo';
import client from '@totem/graph/client';
import configuration from '@totem/utilities/configuration';

import LoginForm from './LoginForm';

import './login.css';

const Login = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    // ensure apollo cache and redux store are in initial state
    dispatch(resetStore());
    client.resetStore();
  }, [dispatch]);

  const lock = new Auth0Lock(
    configuration.AUTH_CLIENT_ID,
    configuration.AUTH_DOMAIN,
    {
      autoclose: true,
      allowShowPassword: true,
      auth: {
        redirectUrl: configuration.AUTH_CALLBACK_URL,
        responseType: configuration.AUTH_RESPONSE_TYPE,
        responseMode: 'form_post',
        audience: configuration.AUTH_AUDIENCE,
        params: {
          scope: configuration.AUTH_SCOPE,
        },
      },
      languageDictionary: {
        signUpTerms:
          "I agree to the <a href='/terms' target='_new'>terms of service</a> and <a href='/privacy' target='_new'>privacy policy</a>.",
        title: 'IB Dashboard',
      },
      theme: {
        labeledSubmitButton: false,
        primaryColor: '#122048',
        logo:
          configuration.environment === configuration.constants.PRODUCTION
            ? 'https://app.totembuildings.com/logo'
            : configuration.environment === configuration.constants.DEV_NEW
              ? 'https://d-dashboard.ib-services.net/logo'
              : configuration.environment ===
                  configuration.constants.STAGING_NEW
                ? 'https://s-dashboard.ib-services.net/logo'
                : configuration.environment ===
                    configuration.constants.PRODUCTION_NEW
                  ? 'https://dashboard.ib-services.net/logo'
                  : 'https://d-dashboard.ib-services.net/logo',
      },
      allowSignUp: false,
    },
  );

  const handleSubmit = (event: React.MouseEvent) => {
    event.preventDefault();

    // TODO: [TOTEM-777] Toggle back on once everything has been resolved w/ customer switcher

    // if (configuration.environment === 'localhost') {
    //   window.location.href = configuration.AUTH_CALLBACK_URL;
    // }
    lock.show();
  };

  return (
    <div styleName="container">
      <div className="middle-box text-center loginscreen animate__animated animate__fadeInDown">
        <div styleName="login-header">
          <LoginLogo />
        </div>
        <h3 styleName="heading">Welcome back to the IB Dashboard!</h3>
        <p styleName="heading">Login to get started</p>
        <LoginForm onClick={handleSubmit} />
        <p className="m-t">
          <small styleName="heading">
            IB Dashboard is a registered trademark of Intelligent Buildings,
            LLC.
          </small>
        </p>
      </div>
    </div>
  );
};

export default Login;
