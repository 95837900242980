import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';
import {
  EditOutlined,
  LayoutOutlined,
  LeftSquareOutlined,
} from '@ant-design/icons';
import { Button, Drawer } from 'antd';
import { v4 as uuidv4 } from 'uuid';

import {
  Component,
  ComponentInstance,
  ComponentLayout,
  DataGridItem,
} from '@totem/components/home/lib/types';
import TitleEditModal from '@totem/components/home/TitleEditModal';
import Widget from '@totem/components/home/widgets/Widget';
import WidgetInstance from '@totem/components/home/widgets/WidgetInstance';
import { getToken } from '@totem/utilities/accountUtilities';
import {
  WIDGET_LAYOUT_ENDPOINT,
  WIDGET_PALETTE_ENDPOINT,
} from '@totem/utilities/endpoints';
import { CheckResponseShowError } from '@totem/utilities/responseUtilities';
import { sortStringAscending } from '@totem/utilities/tableUtilities';

import ContentLayout from '../ContentLayout';

import './home.css';

const styles = {
  editIcon: {
    marginRight: '1rem',
  },
  componentPalletIcon: {
    marginRight: '1rem',
  },
};

const defaultProps = {
  className: 'layout',
  breakpoints: { lg: 2560, md: 1600, sm: 1024, xs: 0 },
  cols: { lg: 24, md: 12, sm: 6, xs: 3 },
  rowHeight: 25,
  margin: [10, 20],
};

const Home = () => {
  const adaptiveLayout = true;
  const ResponsiveReactGridLayout = useMemo(
    () => WidthProvider(Responsive),
    [],
  );
  const [currentWidth, setCurrentWidth] = useState<number>(window.innerWidth);
  const GetLayoutSize = (width: number) => {
    if (width >= defaultProps.breakpoints.lg) {
      return 'lg';
    }
    if (width >= defaultProps.breakpoints.md) {
      return 'md';
    }
    if (width >= defaultProps.breakpoints.sm) {
      return 'sm';
    }
    return 'xs';
  };

  const [pageNumber, setPageNumber] = useState<number>(1);
  const [layout, setLayout] = useState<ComponentLayout>({
    id: '64f727cbc88cd4dfc96f8daf',
    pageName: 'Home',
    pageNumber: 1,
    organizationId: '5f2c6593a679f910caaa31b9',
    userId: '61dda0b7028dcf440ac0d285',
    title: 'My Alerts Page',
    components: [],
  });
  const [layoutSize, setLayoutSize] = useState<string>(
    GetLayoutSize(currentWidth),
  );

  const [componentPallet, setComponentPallet] = useState<Component[]>([]);
  const [showTitleEdit, setShowTitleEdit] = useState<boolean>(false);
  const [isSending, setIsSending] = useState(false);
  const [showWidgets, setShowWidgets] = useState(false);

  useEffect(() => {
    fetch(`${WIDGET_LAYOUT_ENDPOINT}/Home/${pageNumber}`, {
      method: 'GET',
      headers: new Headers({
        Authorization: `Bearer ${getToken()}`,
      }),
    })
      .then((res) => res.json())
      .then((result: ComponentLayout) => {
        setLayout(result);
        window.dispatchEvent(new Event('resize'));
      });
  }, [pageNumber]);

  useEffect(() => {
    fetch(`${WIDGET_PALETTE_ENDPOINT}`, {
      method: 'GET',
      headers: new Headers({
        Authorization: `Bearer ${getToken()}`,
      }),
    })
      .then((res) => res.json())
      .then((result: Component[]) => {
        if (Array.isArray(result)) {
          setComponentPallet(result);
        }
      });
  }, []);

  const saveLayoutGeometry = useCallback(async (pageNum, geometry, size) => {
    if (isSending) {
      return;
    }
    setIsSending(true);

    fetch(`${WIDGET_LAYOUT_ENDPOINT}/Home/${pageNumber}/${size}`, {
      method: 'PUT',
      headers: new Headers({
        Authorization: `Bearer ${getToken()}`,
      }),
      body: JSON.stringify(geometry),
    })
      .then((res) => res.json())
      .then((result: ComponentLayout) => {
        setIsSending(false);
        setLayout(result);
        //window.dispatchEvent(new Event('resize'));
      });
  }, []);

  const saveLayoutTitle = useCallback(async (pageNum, title) => {
    if (isSending) {
      return;
    }
    setIsSending(true);

    fetch(`${WIDGET_LAYOUT_ENDPOINT}/Home/${pageNumber}/title`, {
      method: 'PUT',
      headers: new Headers({
        Authorization: `Bearer ${getToken()}`,
      }),
      body: JSON.stringify({ title }),
    }).then((res) => {
      setIsSending(false);
      CheckResponseShowError(res);
    });
  }, []);

  const addComponentInstance = useCallback(
    async (pageNum: number, newComponentInstance: ComponentInstance) => {
      if (isSending) {
        return;
      }
      setIsSending(true);

      fetch(`${WIDGET_LAYOUT_ENDPOINT}/Home/${pageNumber}`, {
        method: 'POST',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
        body: JSON.stringify(newComponentInstance),
      })
        .then((res) => {
          setIsSending(false);
          CheckResponseShowError(res);
          return res;
        })
        .then((res) => res.json())
        .then((result: ComponentLayout) => {
          setLayout(result);
        });
    },
    [],
  );

  const removeComponentInstance = useCallback(async (pageNum, instanceId) => {
    if (isSending) {
      return;
    }
    setIsSending(true);

    fetch(`${WIDGET_LAYOUT_ENDPOINT}/Home/${pageNumber}/${instanceId}`, {
      method: 'DELETE',
      headers: new Headers({
        Authorization: `Bearer ${getToken()}`,
      }),
    }).then((res) => {
      setIsSending(false);
      CheckResponseShowError(res);
    });
  }, []);

  const handleRemoveItem = (id: string) => {
    setLayout({
      ...layout,
      components: layout.components.filter(
        (comp) => comp.componentInstanceId !== id,
      ),
    });

    removeComponentInstance(pageNumber, id);
  };

  const handleEditTitle = () => {
    setShowTitleEdit(true);
  };

  const handleTitleChange = (newTitle: string) => {
    setLayout({
      ...layout,
      title: newTitle,
    });
    saveLayoutTitle(pageNumber, newTitle);
    setShowTitleEdit(false);
  };

  const handleSave = (data: DataGridItem[]) => {
    saveLayoutGeometry(pageNumber, data, layoutSize);
  };

  const handleBreakpointChange = (data: any) => {
    setLayoutSize(data);
  };

  const handleWidthChange = (containerWidth: number) => {
    setCurrentWidth(containerWidth);
  };

  const handleAddComponent = (componentData: Component) => {
    const newComponentInstanceId = uuidv4();

    const newComponentInstance = {
      title: componentData.title,
      style: componentData.style,
      componentId: componentData.componentId,
      componentInstanceId: newComponentInstanceId,
      geometry: componentData.geometry,
      positionGeometry: [
        {
          size: layoutSize,
          x: componentData.geometry.x,
          y: componentData.geometry.y,
          // eslint-disable-next-line id-length
          w: componentData.geometry.w,
          // eslint-disable-next-line id-length
          h: componentData.geometry.h,
        },
      ],
    };

    // eslint-disable-next-line id-length
    newComponentInstance.geometry.i = newComponentInstanceId;

    addComponentInstance(pageNumber, newComponentInstance);

    setShowWidgets(false);
  };

  const GetGeometry = (componentInstance: ComponentInstance, size: string) => {
    const geometry = { ...componentInstance.geometry };
    if (
      adaptiveLayout &&
      typeof componentInstance.positionGeometry !== 'undefined' &&
      componentInstance.positionGeometry !== null
    ) {
      for (
        let idx = 0;
        idx < componentInstance.positionGeometry.length;
        idx++
      ) {
        if (componentInstance.positionGeometry[idx].size === size) {
          geometry.x = componentInstance.positionGeometry[idx].x;
          geometry.y = componentInstance.positionGeometry[idx].y;
          // eslint-disable-next-line id-length
          geometry.w = componentInstance.positionGeometry[idx].w;
          // eslint-disable-next-line id-length
          geometry.h = componentInstance.positionGeometry[idx].h;
        }
      }
    }

    if (geometry.minW !== null && geometry.w < geometry.minW) {
      // eslint-disable-next-line id-length
      geometry.w = geometry.minW;
    }
    if (geometry.maxW !== null && geometry.w > geometry.maxW) {
      // eslint-disable-next-line id-length
      geometry.w = geometry.maxW;
    }
    if (geometry.minH !== null && geometry.h < geometry.minH) {
      // eslint-disable-next-line id-length
      geometry.h = geometry.minH;
    }
    if (geometry.maxH !== null && geometry.h > geometry.maxH) {
      // eslint-disable-next-line id-length
      geometry.h = geometry.maxH;
    }
    return geometry;
  };

  const getLayoutsBySize = () => {
    const layouts = {
      lg: layout.components.map((comp) => GetGeometry(comp, 'lg')),
      md: layout.components.map((comp) => GetGeometry(comp, 'md')),
      sm: layout.components.map((comp) => GetGeometry(comp, 'sm')),
      xs: layout.components.map((comp) => GetGeometry(comp, 'xs')),
    };
    return layouts;
  };

  const CreateDashboardWidget = (componentInstance: ComponentInstance) => {
    //const geometry = GetGeometry(componentInstance, layoutSize);
    // console.log(`${layoutSize}:${geometry.i}: (${geometry.x} / ${geometry.y})`);
    return (
      <div
        key={componentInstance.geometry.i}
        styleName={
          componentInstance.style === 'Info Widget'
            ? 'info-widget-panel'
            : 'widget-panel'
        }
      >
        <Widget
          componentInstance={componentInstance}
          onRemoveItem={handleRemoveItem}
          page="Home"
          pageNumber={pageNumber.toString()}
        >
          <WidgetInstance componentInstance={componentInstance} />
        </Widget>
      </div>
    );
  };

  const CreateDashboardWidgets = () => {
    return layout.components.map((comp) => CreateDashboardWidget(comp));
  };

  return (
    <ContentLayout
      pageTitle={
        typeof layout !== 'undefined' && layout !== null ? layout.title : 'Home'
      }
      contentRight={
        <div>
          <Button
            style={styles.editIcon}
            size="large"
            shape="circle"
            title="Edit Page Title"
            icon={<EditOutlined />}
            onClick={handleEditTitle}
          />
          <Button
            style={styles.editIcon}
            size="large"
            shape="circle"
            title="Show Widget Palette"
            icon={<LayoutOutlined />}
            onClick={() => setShowWidgets(true)}
          />
        </div>
      }
    >
      <div styleName="primary-content-pane-page">
        <ResponsiveReactGridLayout
          draggableHandle={'.dashboardWidgetDraggable'}
          layouts={getLayoutsBySize()}
          onBreakpointChange={handleBreakpointChange}
          onWidthChange={handleWidthChange}
          onResizeStop={handleSave}
          onDragStop={handleSave}
          {...defaultProps}
        >
          {CreateDashboardWidgets()}
        </ResponsiveReactGridLayout>
        <Drawer
          title="Widget Pallet"
          placement="right"
          onClose={() => setShowWidgets(false)}
          open={showWidgets}
        >
          {typeof componentPallet !== 'undefined' &&
            componentPallet !== null &&
            componentPallet
              .sort((compA, compB) =>
                sortStringAscending(compA.title, compB.title),
              )
              .map((comp) => {
                return (
                  <span key={comp.componentId}>
                    <LeftSquareOutlined
                      style={styles.componentPalletIcon}
                      onClick={() => handleAddComponent(comp)}
                    />
                    {comp.title}
                    <br />
                  </span>
                );
              })}
        </Drawer>
      </div>
      {showTitleEdit && (
        <TitleEditModal
          title={layout.title}
          visible={showTitleEdit}
          onSubmit={handleTitleChange}
          onClose={() => setShowTitleEdit(false)}
        />
      )}
    </ContentLayout>
  );
};

export default Home;
