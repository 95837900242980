import { call, fork, put, takeEvery } from 'redux-saga/effects';

import actionTypes from '@totem/actions/actionTypes';
import * as userActions from '@totem/actions/userActions';
import accountUtilities from '@totem/utilities/accountUtilities';
import api from '@totem/utilities/api';
import { USERS_ENDPOINT } from '@totem/utilities/endpoints';

export function* addSecurityGroupToUser(action) {
  try {
    const { data: body } = action;
    // @ts-ignore
    const { data } = yield call(
      api.post,
      `${USERS_ENDPOINT}/${body.userId}/securityGroups`,
      body.securityGroup,
    );

    yield put(userActions.requestUserSecurityGroupAddSuccess(data));
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      userActions.requestUserSecurityGroupAddFailed,
    );
  }
}

export function* removeSecurityGroupFromUser(action) {
  try {
    const { data: body } = action;
    // @ts-ignore
    const { data } = yield call(
      api.delete,
      `${USERS_ENDPOINT}/${body.userId}/securityGroups`,
      {
        body: body.securityGroup,
      },
    );

    yield put(userActions.requestUserSecurityGroupRemoveSuccess(data));
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      userActions.requestUserSecurityGroupRemoveFailed,
    );
  }
}

// WATCHERS
export function* requestUserSecurityGroupAdd() {
  yield takeEvery(
    actionTypes.REQUEST_USER_SECURITY_GROUP_ADD,
    addSecurityGroupToUser,
  );
}

export function* requestUserSecurityGroupRemove() {
  yield takeEvery(
    actionTypes.REQUEST_USER_SECURITY_GROUP_REMOVE,
    removeSecurityGroupFromUser,
  );
}

export default [requestUserSecurityGroupAdd, requestUserSecurityGroupRemove];
