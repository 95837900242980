import React from 'react';

import colors from '@totem/styles/colors';
import fonts from '@totem/styles/fonts';

const styles = {
  information: {
    fontSize: fonts.fontSm,
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    backgroundColor: colors.badges.information.background,
    color: colors.badges.information.foreground,
    paddingLeft: '5px',
    paddingRight: '5px',
    minWidth: '1.75rem',
    //width: '1.5rem',
    height: '1.75rem',
  },
};

type Props = {
  count: number;
};

const InfoBadge = ({ count }: Props) => {
  return (
    <span
      // @ts-ignore
      style={styles.information}
    >
      {count}
    </span>
  );
};

export default InfoBadge;
