import React, { ReactNode } from 'react';
import { AppstoreOutlined } from '@ant-design/icons';
import { Card } from 'antd';

type Props = {
  title?: ReactNode;
  children?: ReactNode;
};

const InfoWidgetCard = ({ title, children }: Props) => {
  return (
    <Card
      title={
        <span>
          <AppstoreOutlined className={'dashboardWidgetDraggable'} /> {title}
        </span>
      }
      size={'small'}
    >
      {children}
    </Card>
  );
};

export default InfoWidgetCard;
