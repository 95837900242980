import React from 'react';

import { ContractAddress } from '@totem/types/contracts';

import '../contracts.css';

type Props = {
  address: ContractAddress;
  loading: boolean;
};

const Address = ({ address }: Props) => {
  return (
    <>
      {address.addressLine1}
      {typeof address.addressLine2 !== 'undefined' &&
        address.addressLine2 !== null &&
        address.addressLine2 !== '' && (
          <>
            <br />
            {address.addressLine2}
          </>
        )}
      {typeof address.addressLine3 !== 'undefined' &&
        address.addressLine3 !== null &&
        address.addressLine3 !== '' && (
          <>
            <br />
            {address.addressLine3}
          </>
        )}
      <br />
      {address.city}, {address.region}&nbsp;&nbsp;{address.postalCode}
      {typeof address.country !== 'undefined' &&
        address.country !== null &&
        address.country !== '' && (
          <>
            <br />
            {address.country}
          </>
        )}
    </>
  );
};

export default Address;
