import React from 'react';

import { staticLoginLogo } from '@totem/selectors/preferencesSelectors';

import './logos.css';

const LoginLogo = () => {
  return <img alt="Logo" src={staticLoginLogo} styleName="logo" />;
};

export default LoginLogo;
