import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import { TablePaginationConfig } from 'antd/es/table';
import { ColumnProps } from 'antd/lib/table';
import { SorterResult } from 'antd/lib/table/interface';

import { EMPTY_ID } from '@totem/components/common/reference/ReferenceSelector';
import Table from '@totem/components/common/table/Table';
import AuditGrade from '@totem/components/controlSystemReport/AuditGrade';
import ControlSystemEditModal from '@totem/components/controlSystemReport/ControlSystemEditModal';
import ReportContext from '@totem/components/controlSystemReport/ControlSystemReportContext';
import { ControlSystemReportRecord } from '@totem/components/controlSystemReport/dataTypes';
import DeleteControlSystemModal from '@totem/components/controlSystemReport/DeleteControlSystemModal';
import DevicesSubTable from '@totem/components/controlSystemReport/DevicesSubTable';
import EventChart from '@totem/components/controlSystemReport/EventChart';
import EventLegend from '@totem/components/controlSystemReport/EventLegend';
import InfoBadge from '@totem/components/controlSystemReport/InfoBadge';
import TableTitle from '@totem/components/controlSystemReport/TableTitle';
import TicketChart from '@totem/components/controlSystemReport/TicketChart';
import TicketLegend from '@totem/components/controlSystemReport/TicketLegend';
import TableFilterDropDown from '@totem/components/TableFilterDropDown';
import colors from '@totem/styles/colors';
import authUtilities from '@totem/utilities/authUtilities';
import { isNotNull } from '@totem/utilities/common';
import { stringifyArray } from '@totem/utilities/tableFilterUtilities';
import { getFilterOptions } from '@totem/utilities/ticketing';

import './controlSystemReport.css';

const styles = {
  button: {
    marginRight: '4px',
  },
};

const ReportTable = () => {
  const { reportData, loading, input, setInput, totalRecords, filterOptions } =
    useContext(ReportContext);
  const [selectedForDelete, setSelectedForDelete] =
    useState<ControlSystemReportRecord>(null);
  const [regionFilterVisible, setRegionFilterVisible] =
    useState<boolean>(false);
  const [selectedForEdit, setSelectedForEdit] =
    useState<ControlSystemReportRecord>(null);
  const [action, setAction] = useState<string>('');

  const getRegionDisplay = (record: ControlSystemReportRecord) => {
    if (record.region !== null) {
      return <span>{record.region.name}</span>;
    }
    return <span>-</span>;
  };

  const getBuildingDisplay = (record: ControlSystemReportRecord) => {
    if (
      record.building !== null &&
      record.building.id !== null &&
      record.building.id !== EMPTY_ID
    ) {
      return (
        <Link
          to={`/dashboard/buildings/${record.building.id}`}
          target={record.building.id}
        >
          {record.building.name}
        </Link>
      );
    } else if (record.building !== null) {
      return <span>{record.building.name}</span>;
    }
    return <span>-</span>;
  };

  const isDisabled = !authUtilities.minimumRequiredRole(
    authUtilities.ROLE_SETS.ORGANIZATION_ADMINISTRATOR,
  );

  const handleEditClicked = (record: ControlSystemReportRecord) => {
    setSelectedForEdit(record);
    setAction('edit');
  };

  const handleDeleteClicked = (record: ControlSystemReportRecord) => {
    setSelectedForDelete(record);
    setAction('delete');
  };

  const columns: ColumnProps<ControlSystemReportRecord>[] = [
    {
      title: <TableTitle>Region</TableTitle>,
      dataIndex: 'region.name',
      key: 'regionId',
      render: (_, record: ControlSystemReportRecord) =>
        getRegionDisplay(record),
      //className: 'tableAlignTop',
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        compA.region.name === compB.region.name
          ? 0
          : compA.region.name > compB.region.name
            ? 1
            : -1,
      filtered: isNotNull(input.regionId) ? input.regionId.length > 0 : false,
      onFilterDropdownOpenChange: () =>
        setRegionFilterVisible(!regionFilterVisible),
      filterDropdownOpen: regionFilterVisible,
      filterDropdown: (
        <TableFilterDropDown
          padding={15}
          value={stringifyArray(input.regionId)}
          filterDropdownOpen={regionFilterVisible}
          onCloseFilter={() => setRegionFilterVisible(false)}
          onChange={(newOptions) => setInput({ regionId: newOptions })}
          options={getFilterOptions(
            typeof filterOptions !== 'undefined' && filterOptions !== null
              ? filterOptions.regions
              : null,
          )}
        />
      ),
    },
    {
      title: <TableTitle>Building</TableTitle>,
      dataIndex: 'building.name',
      key: 'buildingId',
      render: (_, record: ControlSystemReportRecord) =>
        getBuildingDisplay(record),
      //className: 'tableAlignTop',
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        compA.building.name === compB.building.name
          ? 0
          : compA.building.name > compB.building.name
            ? 1
            : -1,
      filterMultiple: true,
      filteredValue: stringifyArray(input.buildingId),
      filters: getFilterOptions(
        typeof filterOptions !== 'undefined' && filterOptions !== null
          ? filterOptions.buildings
          : null,
      ),
    },
    {
      title: <TableTitle>Name</TableTitle>,
      dataIndex: 'name',
      key: 'name',
      render: (_, record: ControlSystemReportRecord) => (
        <Link to={`/dashboard/controlsystems/${record.id}`}>
          {record.name}
        </Link>
      ),
      //className: 'tableAlignTop',
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        compA.name === compB.name ? 0 : compA.name > compB.name ? 1 : -1,
      filterMultiple: true,
      filteredValue: stringifyArray(input.name),
      filters: getFilterOptions(
        typeof filterOptions !== 'undefined' && filterOptions !== null
          ? filterOptions.controlSystemNames
          : null,
      ),
    },
    {
      title: <TableTitle>Manufacturer</TableTitle>,
      dataIndex: 'manufacturer',
      key: 'manufacturer',
      render: (_, record: ControlSystemReportRecord) => (
        <span>{record.manufacturer}</span>
      ),
      //className: 'tableAlignTop',
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        compA.manufacturer === compB.manufacturer
          ? 0
          : compA.manufacturer > compB.manufacturer
            ? 1
            : -1,
    },
    {
      title: <TableTitle>Devices</TableTitle>,
      dataIndex: 'devices',
      key: 'devices',
      align: 'center',
      render: (_, record: ControlSystemReportRecord) => {
        if (record.devices !== null && record.devices.length > 0) {
          return (
            <Link
              to={`/dashboard/devices?controlSystemId=${record.id}`}
              target={record.id}
            >
              <InfoBadge count={record.devices.length} />
            </Link>
          );
        }
        return <span>-</span>;
      },
      //className: 'tableAlignTop',
    },
    {
      title: <TableTitle>Audit Grade</TableTitle>,
      dataIndex: 'grade',
      key: 'grade',
      render: (_, record: ControlSystemReportRecord) => (
        <div styleName="gradeLink">
          {isNotNull(record.policyAudit) &&
            record.policyAudit.id !== '' &&
            record.policyAudit.source !== '' && (
              <Link
                to={`/dashboard/policyaudits/${record.policyAudit.id}/report`}
                target={record.policyAudit.id}
              >
                <AuditGrade
                  score={
                    (record.policyAudit.totalScore /
                      record.policyAudit.maxScore) *
                    100
                  }
                  size="small"
                  style={{ margin: '0 auto' }}
                />
              </Link>
            )}
        </div>
      ),
      //className: 'tableAlignTop',
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        compA.name === compB.name ? 0 : compA.name > compB.name ? 1 : -1,
      filterMultiple: true,
      filteredValue: stringifyArray(input.name),
      filters: getFilterOptions(
        typeof filterOptions !== 'undefined' && filterOptions !== null
          ? filterOptions.controlSystemNames
          : null,
      ),
    },
    {
      title: (
        <TableTitle>
          <span title="Software End-of-Support">SW EOS</span>
          <br />
          <span title="Past End-of-Support / Near End-of-Support">
            Past EOS / Near EOS
          </span>
        </TableTitle>
      ),
      dataIndex: 'devices',
      key: 'software',
      align: 'center',
      render: (_, record: ControlSystemReportRecord) => {
        const past =
          isNotNull(record.software) &&
          isNotNull(record.software.pastEndOfSupport)
            ? record.software.pastEndOfSupport
            : '-';
        const approaching =
          isNotNull(record.software) &&
          isNotNull(record.software.approachingEndOfSupport)
            ? record.software.approachingEndOfSupport
            : '-';
        return (
          <span>
            {past} / {approaching}
          </span>
        );
      },
      //className: 'tableAlignTop',
    },
    {
      title: (
        <TableTitle>
          Events
          <br />
          <EventLegend />
        </TableTitle>
      ),
      dataIndex: 'eventChart',
      key: 'eventChart',
      render: (_, record: ControlSystemReportRecord) => {
        if (record.events !== null && record.events.length > 0) {
          return (
            <EventChart
              controlSystemId={record.id}
              eventSummary={record.events}
              eventRange={record.eventRange}
            />
          );
        }
        return <span style={{ color: colors.neutral.dim }}>No Events</span>;
      },
      //className: 'tableAlignTop',
    },
    {
      title: (
        <TableTitle>
          Tickets
          <br />
          <TicketLegend />
        </TableTitle>
      ),
      dataIndex: 'ticketsChart',
      key: 'ticketsChart',
      render: (_, record: ControlSystemReportRecord) => {
        if (record.tickets !== null && record.tickets.length > 0) {
          return (
            <TicketChart
              controlSystemId={record.id}
              ticketSummary={record.tickets}
            />
          );
        }
        return <span style={{ color: colors.neutral.dim }}>No Tickets</span>;
      },
      //className: 'tableAlignTop',
    },
    {
      title: <TableTitle>Actions</TableTitle>,
      dataIndex: '',
      width: '84px',
      render: (_, controlSystem) => (
        <div className="center-table-cell">
          <Tooltip title="Edit" placement="top">
            <Button
              shape="circle"
              icon={<EditOutlined />}
              disabled={isDisabled}
              style={styles.button}
              onClick={() => handleEditClicked(controlSystem)}
            />
          </Tooltip>
          <Tooltip title="Delete" placement="top">
            <Button
              shape="circle"
              icon={<DeleteOutlined color={colors.neutral.gray} />}
              disabled={isDisabled}
              onClick={() => handleDeleteClicked(controlSystem)}
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  const getData = () => {
    if (
      typeof reportData !== 'undefined' &&
      reportData !== null &&
      reportData.report !== null
    ) {
      return reportData.report;
    }
    return [];
  };

  const handleTableChange = (
    updatedPagination: TablePaginationConfig,
    filters: SorterResult<ControlSystemReportRecord>,
    sorter,
  ) => {
    const { ...params } = filters;

    let sortDir: string = sorter.order === 'descend' ? '-1' : '1';
    if (typeof sorter.order === 'undefined' || sorter.order === null) {
      if (
        typeof input.sortDirection !== 'undefined' &&
        input.sortDirection !== null
      ) {
        sortDir = input.sortDirection;
      }
    }

    let sortField = input.sortField ? input.sortField : 'lastOccurrence';
    if (
      typeof sorter.field !== 'undefined' &&
      typeof sorter.order !== 'undefined'
    ) {
      sortField = sorter.field;
    }

    // @ts-ignore
    setInput({
      ...input,
      ...params,
      pageSize: updatedPagination.pageSize,
      page: updatedPagination.current,
      sortField,
      sortDirection: sortDir,
    });
  };

  const handleCloseDialog = () => {
    setAction('');
    setSelectedForDelete(null);
    setSelectedForEdit(null);
  };

  return (
    <>
      <Table
        showSorterTooltip
        columns={columns}
        dataSource={getData()}
        onChange={handleTableChange}
        loading={loading}
        rowKey={(record) => record.id}
        pagination={{
          current: input.page,
          pageSize: input.pageSize,
          total: totalRecords,
          showSizeChanger: true,
        }}
        expandable={{
          expandedRowRender: (record) => (
            <DevicesSubTable devices={record.devices} loading={loading} />
          ),
          defaultExpandAllRows: false,
        }}
      />
      {action === 'edit' && (
        <ControlSystemEditModal
          open={action === 'edit' && isNotNull(selectedForEdit)}
          onClose={handleCloseDialog}
          controlSystem={selectedForEdit}
        />
      )}
      {action === 'delete' && (
        <DeleteControlSystemModal
          open={action === 'delete' && isNotNull(selectedForDelete)}
          onClose={handleCloseDialog}
          controlSystem={selectedForDelete}
        />
      )}
    </>
  );
};

export default ReportTable;
