import React, { useState } from 'react';
import { Input } from 'antd';

import {
  PolicyAuditError,
  Question,
  QuestionKey,
} from '@totem/types/policyAudit';

import './policyAuditDetail.css';

const { TextArea } = Input;

interface Props {
  question: Question;
  error?: PolicyAuditError;
  onChange: (key: QuestionKey, value: string) => void;
  disabled: boolean;
}

const getInitialValue = ({ moreInfo }: Question) => {
  return moreInfo ? moreInfo.value : '';
};

const getMoreInfoStyleName = (required: boolean, error: boolean) => {
  let styleName = 'more-info-label';

  if (required) {
    styleName += ' required';
  }

  if (error) {
    styleName += ' error';
  }

  return styleName;
};

const MoreInfo: React.FC<Props> = ({
  question,
  error,
  onChange,
  disabled,
}: Props) => {
  const { key, moreInfoRequired, moreInfoLabel } = question;
  const [value, setValue] = useState<string>(getInitialValue(question));

  const moreInfoError = Boolean(
    (moreInfoRequired && !value) || (error && error.moreInfo),
  );

  return (
    <div styleName="more-info-container">
      <div styleName={getMoreInfoStyleName(moreInfoRequired, moreInfoError)}>
        {moreInfoRequired ? `${moreInfoLabel}*` : moreInfoLabel}
      </div>
      <TextArea
        value={value}
        onChange={event => setValue(event.target.value)}
        onBlur={() => onChange(key, value)}
        autoSize={{ minRows: 6 }}
        disabled={disabled}
      />
    </div>
  );
};

export default MoreInfo;
