import React from 'react';
import { AutoComplete, Input } from 'antd';
import { AutoCompleteProps } from 'antd/lib/auto-complete';
import * as R from 'ramda';

import { Address } from '@totem/types/address';
import addressUtilities from '@totem/utilities/addressUtilities';

const { Search } = Input;

export interface Props {
  placeholder?: string;
  onSelect: (address: Address, text: string) => void;
  onChange?: (value: string) => void;
  value?: string;
  style?: React.CSSProperties;
  allowClear?: boolean;
  disabled?: boolean;
}

interface State {
  search: string;
  data: google.maps.places.AutocompletePrediction[];
}

const styles = {
  input: {
    width: '100%',
  },
};

class LocationAutoComplete extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      search: '',
      data: [],
    };

    // @ts-ignore
    const { google } = window;

    if (google) {
      this.geocoder = new google.maps.Geocoder();
      this.service = new google.maps.places.AutocompleteService();
    }
  }

  geocoder: google.maps.Geocoder;
  service: google.maps.places.AutocompleteService;
  TYPES = ['address'];

  handleSearch = (search: string) => {
    if (search) {
      this.setState({ search }, () => {
        this.service.getPlacePredictions(
          {
            input: search,
            types: this.TYPES,
          },
          this.updateData,
        );
      });
    } else {
      this.setState({ search: '' });
    }
  };

  handleSelect = (value: string, option: any) => {
    const { data } = this.state;
    const { onSelect } = this.props;

    const selected = data.find(({ place_id: id }) => id === option.id);
    const text = selected ? selected.description : '';

    return this.geocoder.geocode(
      {
        placeId: option.id,
      },
      ([result]) => {
        const address = addressUtilities.getAddressFromGeocodeResult(result);
        onSelect(address, text);
      },
    );
  };

  updateData = (data?: google.maps.places.AutocompletePrediction[]) => {
    this.setState({ data: data || [] });
  };

  mapData = () => {
    const { data } = this.state;

    return data.map(({ description, place_id: id }) => ({
      id,
      value: description,
      label: description
    }));
  };

  render() {
    const {
      placeholder,
      style,
      allowClear,
      disabled,
      value,
      onChange,
    } = this.props;

    const props: AutoCompleteProps = {
      placeholder,
      onChange,
      options: this.mapData(),
      style: { ...styles.input, ...style },
      onSelect: this.handleSelect,
      onSearch: this.handleSearch,
      disabled: R.isNil(disabled) ? false : disabled,
      children: <Search type="search" allowClear={allowClear} />,
    };

    /**
     * AutoComplete component seems to be controlled even when passing null or undefined
     * making this conditional render necessary
     */
    return R.isNil(value) ? (
      // @ts-ignore
      <AutoComplete {...props} />
    ) : (
      // @ts-ignore
      <AutoComplete {...props} value={value} />
    );
  }
}

export default LocationAutoComplete;
