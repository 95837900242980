import React from 'react';
import { Select } from 'antd';

import { QuestionOption } from '@totem/components/surveyV2/types';
import { Criticality } from '@totem/types/criticality';
import {
  criticalityMap,
  criticallityFromString,
} from '@totem/utilities/criticalityUtilities';

const styles = {
  selectStyle: {
    width: 200,
  },
};

type Props = {
  option: QuestionOption;
  onChange: (newValue: Criticality) => void;
};
const QuestionOptionCriticality = ({ option, onChange }: Props) => {
  const options = [
    {
      label: 'Info',
      value: criticalityMap[Criticality.Info].value.toString(),
    },
    {
      label: 'Low',
      value: criticalityMap[Criticality.Low].value.toString(),
    },
    {
      label: 'Medium',
      value: criticalityMap[Criticality.Medium].value.toString(),
    },
    {
      label: 'High',
      value: criticalityMap[Criticality.High].value.toString(),
    },
    {
      label: 'Critical',
      value: criticalityMap[Criticality.Critical].value.toString(),
    },
    {
      label: 'Compliant',
      value: criticalityMap[Criticality.Compliant].value.toString(),
    },
  ];

  return (
    <Select
      style={styles.selectStyle}
      value={criticalityMap[option.criticality].value.toString()}
      options={options}
      onChange={(newValue: string) =>
        onChange(criticallityFromString(newValue))
      }
    />
  );
};

export default QuestionOptionCriticality;
