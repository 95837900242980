import {
  FindingReportInput,
  PolicyDetail,
} from '@totem/components/surveyV2/findingsReport/types';
import { Filter, Params } from '@totem/types/common';

export const buildFindingsReportFilters = (input: FindingReportInput) => {
  const paramFilters: Filter[] = [];

  if (
    typeof input.regionId !== 'undefined' &&
    input.regionId !== null &&
    input.regionId.length > 0
  ) {
    paramFilters.push({
      field: 'controlSystem.regionId',
      values: input.regionId,
    });
  }
  if (
    typeof input.buildingId !== 'undefined' &&
    input.buildingId !== null &&
    input.buildingId.length > 0
  ) {
    paramFilters.push({
      field: 'controlSystem.buildingId',
      values: input.buildingId,
    });
  }
  if (
    typeof input.controlSystemId !== 'undefined' &&
    input.controlSystemId !== null &&
    input.controlSystemId.length > 0
  ) {
    paramFilters.push({
      field: 'controlSystem._id',
      values: input.controlSystemId,
    });
  }
  if (
    typeof input.question !== 'undefined' &&
    input.question !== null &&
    input.question.length > 0
  ) {
    paramFilters.push({
      field: 'policyAudit.findings.question',
      values: input.question,
    });
  }
  if (
    typeof input.criticality !== 'undefined' &&
    input.criticality !== null &&
    input.criticality.length > 0
  ) {
    paramFilters.push({
      field: 'policyAudit.findings.criticality',
      values: input.criticality,
    });
  }
  if (
    typeof input.assignee !== 'undefined' &&
    input.assignee !== null &&
    input.assignee.length > 0
  ) {
    paramFilters.push({
      field: 'assignee',
      values: input.assignee,
    });
  }

  return paramFilters;
};

export const buildParameters = (input: FindingReportInput) => {
  const params: Params = {
    paging: {
      page: 1,
      pageSize: 50000,
    },
    sort: {
      field: '',
      direction: 1,
    },
    filters: buildFindingsReportFilters(input),
  };

  return params;
};

export const getPlainTextFinding = (finding: PolicyDetail) => {
  return [finding.findingDescription, finding.policy, finding.resolution].join(
    '\n',
  );
};
