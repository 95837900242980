import React, { useContext } from 'react';
import { ColumnProps } from 'antd/lib/table';

import Table from '@totem/components/common/table/Table';
import ProcessorsContext from '@totem/components/devices/processors/ProcessorsContext';
import { Processor } from '@totem/components/devices/processors/types';
import { FormatBytes } from '@totem/components/devices/processors/utilities';

const ProcessorsTable = () => {
  const { data, loading } = useContext(ProcessorsContext);

  const getData = () => {
    if (typeof data !== 'undefined' && data !== null) {
      return data;
    }
    return [];
  };

  const columns: ColumnProps<Processor>[] = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (compA, compB) =>
        compA.name === compB.name ? 0 : compA.name > compB.name ? 1 : -1,
    },
    {
      title: 'Architecture',
      dataIndex: 'architecture',
      key: 'architecture',
      sorter: (compA, compB) =>
        compA.architecture === compB.architecture
          ? 0
          : compA.architecture > compB.architecture
          ? 1
          : -1,
    },
    {
      title: 'Clock Speed',
      dataIndex: 'clockSpeed',
      key: 'clockSpeed',
      render: (_, record: Processor) => FormatBytes(record.clockSpeed),
      sorter: (compA, compB) =>
        compA.clockSpeed === compB.clockSpeed
          ? 0
          : compA.clockSpeed > compB.clockSpeed
          ? 1
          : -1,
    },
    {
      title: 'Max Clock Speed',
      dataIndex: 'maxClockSpeed',
      key: 'maxClockSpeed',
      render: (_, record: Processor) => FormatBytes(record.maxClockSpeed),
      sorter: (compA, compB) =>
        compA.maxClockSpeed === compB.maxClockSpeed
          ? 0
          : compA.maxClockSpeed > compB.maxClockSpeed
          ? 1
          : -1,
    },
    {
      title: 'Cores',
      dataIndex: 'numCores',
      key: 'numCores',
      sorter: (compA, compB) =>
        compA.numCores === compB.numCores
          ? 0
          : compA.numCores > compB.numCores
          ? 1
          : -1,
    },
    {
      title: 'Logical Cores',
      dataIndex: 'numLogicalCores',
      key: 'numLogicalCores',
      sorter: (compA, compB) =>
        compA.numLogicalCores === compB.numLogicalCores
          ? 0
          : compA.numLogicalCores > compB.numLogicalCores
          ? 1
          : -1,
    },
  ];

  return (
    <Table
      showSorterTooltip
      columns={columns}
      dataSource={getData()}
      loading={loading}
      rowKey={(record: Processor, idx: number) => idx}
    />
  );
};

export default ProcessorsTable;
