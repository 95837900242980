import React, { ReactNode } from 'react';

import './survey.css';

type Props = {
  children?: ReactNode;
};

const SubTitle = ({ children }: Props) => {
  return <div styleName="subtitle-title ">{children}</div>;
};

export default SubTitle;
