import { createContext } from 'react';

import { ControlSystemDetails } from '@totem/components/controlSystemDetail/container/types';

interface Context {
  id: string;
  data: ControlSystemDetails;
  isLoading: boolean;
  requestRefresh: () => void;
}

export default createContext<Context>({
  id: null,
  data: null,
  isLoading: false,
  requestRefresh: () => {},
});
