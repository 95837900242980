import actionTypes from './actionTypes';

export const retrieveRemoteAccessUsers = data => ({
  type: actionTypes.REQUEST_REMOTE_ACCESS_USERS,
  data,
});

export const getRemoteAccessUsersSuccess = data => ({
  type: actionTypes.REQUEST_REMOTE_ACCESS_USERS_SUCCESS,
  data,
});

export const getRemoteAccessUsersFailure = () => ({
  type: actionTypes.REQUEST_REMOTE_ACCESS_USERS_FAILED,
});

export const requestRemoteAccessAddUserToRole = data => ({
  type: actionTypes.REQUEST_REMOTE_ACCESS_USER_ADD_TO_ROLE,
  data,
});

export const getRemoteAccessAddUserToRoleSuccess = data => ({
  type: actionTypes.REQUEST_REMOTE_ACCESS_USER_ADD_TO_ROLE_SUCCESS,
  data,
});

export const getRemoteAccessAddUserToRoleFailure = () => ({
  type: actionTypes.REQUEST_REMOTE_ACCESS_USER_ADD_TO_ROLE_FAILED,
});

export const requestRemoteAccessRemoveUserFromRole = data => ({
  type: actionTypes.REQUEST_REMOTE_ACCESS_USER_REMOVE_FROM_ROLE,
  data,
});

export const getRemoteAccessRemoveUserFromRoleSuccess = data => ({
  type: actionTypes.REQUEST_REMOTE_ACCESS_USER_REMOVE_FROM_ROLE_SUCCESS,
  data,
});

export const getRemoteAccessRemoveUserFromRoleFailure = () => ({
  type: actionTypes.REQUEST_REMOTE_ACCESS_USER_REMOVE_FROM_ROLE_FAILED,
});

export const requestRemoteAccessOrgRoles = () => ({
  type: actionTypes.REQUEST_REMOTE_ACCESS_ORG_ROLES,
});

export const getRemoteAccessOrgRoleSuccess = data => ({
  type: actionTypes.REQUEST_REMOTE_ACCESS_ORG_ROLES_SUCCESS,
  data,
});

export const getRemoteAccessOrgRoleFailure = () => ({
  type: actionTypes.REQUEST_REMOTE_ACCESS_ORG_ROLES_FAILED,
});

export const retrieveRemoteAccessRoleUsers = data => ({
  type: actionTypes.REQUEST_REMOTE_ACCESS_ROLE_USERS,
  data,
});

export const getRemoteAccessRoleUsersSuccess = data => ({
  type: actionTypes.REQUEST_REMOTE_ACCESS_ROLE_USERS_SUCCESS,
  data,
});

export const getRemoteAccessRoleUsersFailure = () => ({
  type: actionTypes.REQUEST_REMOTE_ACCESS_ROLE_USERS_FAILED,
});

export const retrieveRemoteAccessUserRoles = data => ({
  type: actionTypes.REQUEST_REMOTE_ACCESS_USER_ROLES,
  data,
});

export const getRemoteAccessUserRolesSuccess = data => ({
  type: actionTypes.REQUEST_REMOTE_ACCESS_USER_ROLES_SUCCESS,
  data,
});

export const getRemoteAccessUserRolesFailure = () => ({
  type: actionTypes.REQUEST_REMOTE_ACCESS_USER_ROLES_FAILED,
});

export const requestRemoteAccessRemoveRoleFromUser = data => ({
  type: actionTypes.REQUEST_REMOTE_ACCESS_ROLE_REMOVE_FROM_USER,
  data,
});

export const getRemoteAccessRemoveRoleFromUserSuccess = data => ({
  type: actionTypes.REQUEST_REMOTE_ACCESS_ROLE_REMOVE_FROM_USER_SUCCESS,
  data,
});

export const getRemoteAccessRemoveRoleFromUserFailure = () => ({
  type: actionTypes.RREQUEST_REMOTE_ACCESS_ROLE_REMOVE_FROM_USER_FAILED,
});

export const requestRemoteAccessAddRoleToUser = data => ({
  type: actionTypes.REQUEST_REMOTE_ACCESS_ROLE_ADD_TO_USER,
  data,
});

export const getRemoteAccessAddRoleToUserSuccess = data => ({
  type: actionTypes.REQUEST_REMOTE_ACCESS_ROLE_ADD_TO_USER_SUCCESS,
  data,
});

export const getRemoteAccessAddRoleToUserFailure = () => ({
  type: actionTypes.REQUEST_REMOTE_ACCESS_ROLE_ADD_TO_USER_FAILED,
});

export const requestRemoteAccessAddRoleToOrganization = data => ({
  type: actionTypes.REQUEST_REMOTE_ACCESS_ROLE_ADD,
  data,
});

export const getRemoteAccessAddRoleToOrganizationSuccess = data => ({
  type: actionTypes.REQUEST_REMOTE_ACCESS_ROLE_ADD_SUCCESS,
  data,
});

export const getRemoteAccessAddRoleToOrganizationFailure = () => ({
  type: actionTypes.REQUEST_REMOTE_ACCESS_ROLE_ADD_FAILED,
});

export const requestSendPasswordReset = data => ({
  type: actionTypes.REQUEST_REMOTE_ACCESS_PASSWORD_RESET,
  data,
});
