import React, { ReactNode, useEffect, useState } from 'react';

import RemoteAccessActionHandler from '@totem/components/remoteAccess/roleUsers/RemoteAccessActionHandler';
import RemoteAccessUserContext from '@totem/components/remoteAccess/roleUsers/RemoteAccessUserContext';
import {
  RemoteAccessOrgRole,
  RemoteAccessRoleUsers,
  RemoteAccessUser,
  RemoteAccessUserAction,
  RemoteAccessUsersConnectionInput,
} from '@totem/components/remoteAccess/types';
import { sortRemoteAccess } from '@totem/components/remoteAccess/utilities';
import { RemoteAccessMaxAccountInactivity } from '@totem/types/remoteAccess';
import { getToken } from '@totem/utilities/accountUtilities';
import {
  REMOTE_ACCESS_ORG_ROLES_ENDPOINT,
  REMOTE_ACCESS_PROVISIONING_MAX_ACCOUNT_INACTIVITY,
  REMOTE_ACCESS_USERS_ENDPOINT,
} from '@totem/utilities/endpoints';

import '../../remoteAccess.css';

const FLAG_STATUS_OK: string = '1';
const FLAG_STATUS_NEVER_LOGGED_IN: string = '2';
const FLAG_STATUS_INACTIVE: string = '4';

type Props = {
  roleId: string;
  refresh?: boolean;
  onRecordTotalChanged?: (total: number) => void;
  onDataRefreshRequested?: () => void;
  actions?: RemoteAccessUserAction[];
  children?: ReactNode;
};

const RemoteAccessRoleUserContainer = ({
  roleId,
  refresh,
  onRecordTotalChanged,
  onDataRefreshRequested,
  actions,
  children,
}: Props) => {
  const [input, updateInput] = useState<RemoteAccessUsersConnectionInput>({
    from: '',
    pageSize: 10,
    page: 1,
    sortField: 'email',
    sortDirection: '1',
    emailSearchTerm: '',
    nameSearchTerm: '',
    flags: [],
  });
  const [refreshData, setRefreshData] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [isSending, setIsSending] = useState(false);
  const [data, setData] = useState<RemoteAccessRoleUsers>(null);
  const [roleData, setRoleData] = useState<RemoteAccessOrgRole>(null);
  const [filteredData, setFilteredData] = useState<RemoteAccessUser[]>([]);
  const [maxInactivityDays, setMaxInactivityDays] = useState<number>(0);
  const [selectedUser, setSelectedUser] = useState<RemoteAccessUser>(null);
  const [selectedAction, setSelectedAction] = useState<string>('');

  const getFlagStatus = (user: RemoteAccessUser) => {
    if (user.last_login === '0001-01-01T00:00:00Z') {
      return FLAG_STATUS_NEVER_LOGGED_IN;
    }
    if (user.last_login !== null && maxInactivityDays > 0) {
      const today = new Date();
      const loginDate = new Date(user.last_login);
      const expirationDate = new Date(
        loginDate.getTime() + 1000 * 60 * 60 * 24 * maxInactivityDays,
      );
      if (expirationDate < today) {
        return FLAG_STATUS_INACTIVE;
      }
    }
    return FLAG_STATUS_OK;
  };

  const filterRemoteAccessUsers = (user: RemoteAccessUser) => {
    if (input.nameSearchTerm !== null && input.nameSearchTerm !== '') {
      if (user.name.indexOf(input.nameSearchTerm) < 0) {
        return false;
      }
    }
    if (input.emailSearchTerm !== null && input.emailSearchTerm !== '') {
      if (user.email.indexOf(input.emailSearchTerm) < 0) {
        return false;
      }
    }
    if (
      typeof input.flags !== 'undefined' &&
      input.flags !== null &&
      input.flags.length > 0
    ) {
      if (!input.flags.includes(getFlagStatus(user))) {
        return false;
      }
    }
    return true;
  };

  const getFilteredData = (dataSet: RemoteAccessRoleUsers) => {
    if (
      typeof dataSet !== 'undefined' &&
      dataSet !== null &&
      typeof dataSet.users !== 'undefined' &&
      dataSet.users !== null
    ) {
      return dataSet.users
        .filter(filterRemoteAccessUsers)
        .sort((compA, compB) =>
          sortRemoteAccess(input.sortField, input.sortDirection, compA, compB),
        );
    }
    return [];
  };

  const setInput = (updated: Partial<RemoteAccessUsersConnectionInput>) => {
    updateInput({ ...input, ...updated });
    //setFilteredData(getFilteredData(data));
    setRefreshData(true);
  };

  useEffect(() => {
    if (
      typeof onRecordTotalChanged !== 'undefined' &&
      onRecordTotalChanged !== null
    ) {
      onRecordTotalChanged(totalRecords);
    }
  }, [onRecordTotalChanged, totalRecords]);

  useEffect(() => {
    if (isSending) {
      return;
    }
    const endpoint = `${REMOTE_ACCESS_PROVISIONING_MAX_ACCOUNT_INACTIVITY}`;

    setIsSending(true);
    fetch(`${endpoint}`, {
      method: 'GET',
      headers: new Headers({
        Authorization: `Bearer ${getToken()}`,
      }),
    })
      .then(res => res.json())
      .then((result: RemoteAccessMaxAccountInactivity) => {
        setMaxInactivityDays(result.maxInactivityDays);
      })
      .then(() => {
        setIsSending(false);
      });
  }, []);

  useEffect(() => {
    if (
      refresh &&
      typeof onDataRefreshRequested !== 'undefined' &&
      onDataRefreshRequested !== null
    ) {
      setRefreshData(true);
      onDataRefreshRequested();
    }
  }, [refresh, onDataRefreshRequested]);

  useEffect(() => {
    if (refreshData) {
      setRefreshData(false);
      if (isSending) {
        return;
      }
      setIsLoading(true);

      const endpoint = `${REMOTE_ACCESS_USERS_ENDPOINT}/${roleId}`;

      fetch(`${endpoint}`, {
        method: 'GET',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
      })
        .then(res => res.json())
        .then((result: RemoteAccessRoleUsers) => {
          setData(result);
          const newFilteredData = getFilteredData(result);
          setFilteredData(newFilteredData);
          setTotalRecords(newFilteredData.length);
        })
        .then(() => {
          setIsLoading(false);
          setIsSending(false);
        });

      setIsSending(true);
    }
  }, [refreshData, input]);

  useEffect(() => {
    const endpoint = `${REMOTE_ACCESS_ORG_ROLES_ENDPOINT}/${roleId}`;

    fetch(`${endpoint}`, {
      method: 'GET',
      headers: new Headers({
        Authorization: `Bearer ${getToken()}`,
      }),
    })
      .then(res => res.json())
      .then((result: RemoteAccessOrgRole) => {
        setRoleData(result);
      });
  }, [roleId]);

  const handleAction = (action: string, user: RemoteAccessUser) => {
    let actionHandled = false;
    setSelectedUser(user);
    if (typeof actions !== 'undefined' && actions !== null) {
      for (let idx = 0; idx < actions.length; idx++) {
        if (actions[idx].name === action) {
          // eslint-disable-next-line max-depth
          if (actions[idx].replaceDefault === true) {
            actionHandled = true;
            actions[idx].action(action, user);
          }
        }
      }
    }
    if (!actionHandled) {
      setSelectedAction(action);
      setSelectedUser(user);
    }
  };

  const handleActionComplete = (doRefresh: boolean) => {
    setSelectedAction('');
    setSelectedUser(null);
    if (doRefresh) {
      setRefreshData(true);
    }
  };

  return (
    <RemoteAccessUserContext.Provider
      value={{
        input,
        setInput,
        loading: isLoading,
        data,
        filteredData,
        totalRecords,
        onAction: handleAction,
        maxInactivityDays,
        roleData,
      }}
    >
      <div>{children}</div>
      {selectedAction !== null && selectedAction !== '' && (
        <RemoteAccessActionHandler
          action={selectedAction}
          selectedRoleId={
            typeof data !== 'undefined' && data !== null ? data.orgRoleId : ''
          }
          selectedUser={selectedUser}
          onActionComplete={handleActionComplete}
        />
      )}
    </RemoteAccessUserContext.Provider>
  );
};

export default RemoteAccessRoleUserContainer;
