import React from 'react';

import Count from '@totem/components/common/Count';
import { Criticality } from '@totem/types/criticality';
import { Finding } from '@totem/types/finding';
import { criticalityMap } from '@totem/utilities/criticalityUtilities';
import { getExactPercentage } from '@totem/utilities/numberUtilities';

import './findings.css';

const INCLUDED_CRITICALITIES = [
  Criticality.Critical,
  Criticality.High,
  Criticality.Medium,
  Criticality.Low,
];

const getIncludedFindings = (findings: Finding[]) =>
  findings.filter(({ criticality }) =>
    INCLUDED_CRITICALITIES.includes(criticality),
  );

const getFindingsByCriticality = (
  findings: Finding[],
  criticality: Criticality,
): Finding[] => findings.filter(finding => finding.criticality === criticality);

interface Props {
  findings: Finding[];
}

const CriticalityFindings: React.FC<Props> = ({ findings }: Props) => {
  const includedFindings = getIncludedFindings(findings);

  return (
    <div styleName="findings-container">
      <div styleName="finding-header-container">
        <div styleName="finding-header">Findings By Criticality</div>
        <Count count={includedFindings.length} size="large" />
      </div>
      <div>
        {INCLUDED_CRITICALITIES.map(criticality => {
          const option = criticalityMap[criticality];

          const findingsWithCriticality = getFindingsByCriticality(
            includedFindings,
            criticality,
          );

          const percentage = getExactPercentage(
            includedFindings.length,
            findingsWithCriticality.length,
          );

          return (
            <div styleName="findings" key={criticality}>
              <div styleName="finding-label criticality">{option.text}</div>
              <div styleName="finding-bar-container">
                <div styleName="finding-bar">
                  <div
                    style={{
                      width: `${percentage}%`,
                      background: option.color,
                      height: '100%',
                    }}
                  />
                </div>
              </div>
              <Count count={findingsWithCriticality.length} size="small" />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CriticalityFindings;
