import { gql } from '@apollo/client';

export const GET_REGION = gql`
  query GetRegion($id: String!) {
    region(id: $id) {
      id
      name
      organization {
        id
      }
      buildings {
        id
        name
        location {
          lat
          lng
        }
        addressOne
        addressTwo
        city
        state
        zipCode
      }
    }
  }
`;

export const GET_REGIONS = gql`
  query GetRegions(
    $input: RegionsConnectionInput!
    $buildingsInput: BuildingsConnectionInput!
    $controlSystemsInput: ControlSystemsConnectionInput!
    $policyAuditsInput: PolicyAuditsConnectionInput!
  ) {
    regions(input: $input) {
      regions {
        id
        name
        buildings(input: $buildingsInput) {
          totalCount
        }
        controlSystems(input: $controlSystemsInput) {
          totalCount
        }
        policyAudits(input: $policyAuditsInput) {
          totalCount
          averageScore
          completionCounts {
            complete
          }
        }
      }
      totalCount
    }
  }
`;

export const CREATE_REGION = gql`
  mutation RegionCreate($input: RegionCreateInput!) {
    regionCreate(input: $input) {
      name
    }
  }
`;

export const UPDATE_REGION = gql`
  mutation RegionUpdate($input: RegionUpdateInput!) {
    regionUpdate(input: $input) {
      id
      name
    }
  }
`;

export const DELETE_REGION = gql`
  mutation RegionDelete($id: ID!) {
    regionDelete(id: $id)
  }
`;
