export const sortStringAscending = (compA: string, compB: string) => {
  return compA === compB ? 0 : compA > compB ? 1 : -1;
};

export const sortStringDescending = (compA: string, compB: string) => {
  return compA === compB ? 0 : compA > compB ? -1 : 1;
};

export const sortNumberAscending = (compA: number, compB: number) => {
  return compA === compB ? 0 : compA > compB ? 1 : -1;
};

export const sortNumberDescending = (compA: number, compB: number) => {
  return compA === compB ? 0 : compA > compB ? -1 : 1;
};

export const sortBooleanAscending = (compA: boolean, compB: boolean) => {
  return compA === compB ? 0 : compA > compB ? 1 : -1;
};

export const sortBooleanDescending = (compA: boolean, compB: boolean) => {
  return compA === compB ? 0 : compA > compB ? -1 : 1;
};

export const sortVersionNumbersAscending = (compA: string, compB: string) => {
  const aParts = compA.split('.').map(Number);
  const bParts = compB.split('.').map(Number);

  for (let idx = 0; idx < Math.max(aParts.length, bParts.length); idx++) {
    const aVal = aParts[idx] || 0; // Default to 0 if aParts[i] is undefined
    const bVal = bParts[idx] || 0; // Default to 0 if bParts[i] is undefined

    if (aVal < bVal) {
      return -1;
    }
    if (aVal > bVal) {
      return 1;
    }
  }

  return 0; // They are equal
};
