import React, { useCallback, useContext, useState } from 'react';
import { Button, Modal } from 'antd';

import SurveyContext from '@totem/components/surveyV2/instanceDetail/SurveyInstanceContext';
import { SurveyQueryResult } from '@totem/components/surveyV2/types';
import { validateSurvey } from '@totem/components/surveyV2/utilities/SurveyUtilities';
import UserProfileContext from '@totem/components/UserProfileContext';
import { getToken } from '@totem/utilities/accountUtilities';
import { V2_SURVEY_ENDPOINT } from '@totem/utilities/endpoints';
import { getUserRole } from '@totem/utilities/userUtilities';

export type Props = {
  disabled: boolean;
};
const SubmitButton = ({ disabled }: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const { userProfile } = useContext(UserProfileContext);
  const { data, setData, setErrors, errors } = useContext(SurveyContext);
  const { survey } = data;
  const { isSubmitted } = survey;
  const isAdmin = getUserRole(userProfile) >= 2;
  const reopenAvailable = isAdmin && isSubmitted;

  const sendSubmitReopen = useCallback(
    async (surveyId: string, action: string) => {
      if (isLoading) {
        return;
      }
      setIsLoading(true);

      const payload = {};

      fetch(`${V2_SURVEY_ENDPOINT}/instance/${surveyId}/${action}`, {
        method: 'POST',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
        body: JSON.stringify(payload),
      })
        .then((res) => res.json())
        .then((result: SurveyQueryResult) => {
          setIsLoading(false);
          setData(result);
          setIsVisible(false);
        });
    },
    [],
  );

  const getMessage = (): string => {
    return isSubmitted
      ? 'Are you sure you want to reopen this survey?'
      : 'Are you sure you want to submit this survey? Once submitted, answers cannot be changed. This action cannot be undone.';
  };

  const toggleModal = () => {
    const newErrors = validateSurvey(survey);

    if (newErrors.length) {
      setErrors(newErrors);
    }

    setIsVisible(!isVisible);
  };

  const handleSubmit = () => {
    sendSubmitReopen(data.survey.id, 'submit');
  };

  const handleReopen = () => {
    sendSubmitReopen(data.survey.id, 'reopen');
  };

  return (
    <>
      <Button onClick={toggleModal} disabled={isSubmitted && !isAdmin}>
        {reopenAvailable ? 'Reopen Survey' : 'Submit Survey'}
      </Button>
      <Modal
        open={isVisible}
        confirmLoading={isLoading || disabled}
        onOk={isSubmitted ? handleReopen : handleSubmit}
        onCancel={toggleModal}
        title="Confirm"
        okText={isSubmitted ? 'Reopen' : 'Submit'}
        okButtonProps={{ disabled: errors.length > 0 }}
      >
        {getMessage()}
      </Modal>
    </>
  );
};

export default SubmitButton;
