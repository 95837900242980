import { createContext } from 'react';

import { Vendor, VendorInput } from '@totem/components/vendors/types';

interface Context {
  loading: boolean;
  data: Vendor[];
  rawData: Vendor[];
  action: string;
  actionItem: Vendor;
  onAction?: (action: string, item: Vendor) => void;
  input: VendorInput;
  setInput: (input: VendorInput) => void;
  selectedVendors: Vendor[];
  setSelectedVendor: (vendor: Vendor, selected: boolean) => void;
}

export default createContext<Context>({
  loading: false,
  data: [],
  rawData: [],
  action: '',
  actionItem: null,
  onAction: () => {},
  input: {},
  setInput: () => {},
  selectedVendors: [],
  setSelectedVendor: () => {},
});
