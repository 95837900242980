import React, { useState } from 'react';
import { ColumnProps } from 'antd/lib/table';

import Table from '@totem/components/common/table/Table';
import ActivityNote from '@totem/components/ticketing/details/activityNote';
import { Activity, TicketQueryResult } from '@totem/types/ticketing';
import { formatDate } from '@totem/utilities/common';

import '../ticketing.css';

type Props = {
  ticket: TicketQueryResult;
};

const ActivityTable = ({ ticket }: Props) => {
  const [typeFilter, setTypeFilter] = useState<string[]>([]);
  const [createdByFilter, setCreatedByFilter] = useState<string[]>([]);
  const uniqueActivityCodes = () => {
    const activityCodes = [];

    if (ticket.ticket.activity !== null) {
      ticket.ticket.activity.filter(item => {
        const idx = activityCodes.findIndex(srch => srch.id === item.type.id);
        if (idx <= -1) {
          activityCodes.push(item.type);
        }
        return null;
      });
    }
    return activityCodes;
  };

  const getActivityTypeFilter = () => {
    const filterOptions = [];
    const activityCodes = uniqueActivityCodes();
    if (activityCodes !== null && activityCodes.length > 0) {
      activityCodes.forEach(ac => {
        filterOptions.push({
          text: ac.display,
          value: ac.id,
        });
      });
    }
    return filterOptions;
  };

  const uniqueCreatedByNames = () => {
    const createdByOptions = [];

    if (ticket.ticket.activity !== null) {
      ticket.ticket.activity.filter(item => {
        const idx = createdByOptions.findIndex(
          srch => srch === item.createdByName,
        );
        if (idx <= -1) {
          createdByOptions.push(item.createdByName);
        }
        return null;
      });
    }
    return createdByOptions;
  };

  const getCreatedByNameFilter = () => {
    const filterOptions = [];
    const createdByOptions = uniqueCreatedByNames();
    if (createdByOptions !== null && createdByOptions.length > 0) {
      createdByOptions.forEach(cb => {
        filterOptions.push({
          text: cb,
          value: cb,
        });
      });
    }
    return filterOptions;
  };

  const columns: ColumnProps<Activity>[] = [
    {
      title: 'Created',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (_, activity: Activity) => formatDate(activity.createdAt),
      sorter: (compA, compB) =>
        compA.createdAt === compB.createdAt
          ? 0
          : compA.createdAt > compB.createdAt
          ? 1
          : -1,
      showSorterTooltip: true,
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      render: (_, activity: Activity) => activity.type.display,
      showSorterTooltip: true,
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        compA.type.display === compB.type.display
          ? 0
          : compA.type.display > compB.type.display
          ? 1
          : -1,
      filters: getActivityTypeFilter(),
    },
    {
      title: 'Created By',
      dataIndex: 'createdByName',
      key: 'createdByName',
      showSorterTooltip: true,
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        compA.createdByName === compB.createdByName
          ? 0
          : compA.createdByName > compB.createdByName
          ? 1
          : -1,
      filters: getCreatedByNameFilter(),
    },
  ];

  const getActivity = () => {
    if (ticket !== null) {
      if (ticket.ticket !== null) {
        let activity = ticket.ticket.activity;
        if (typeFilter.length > 0) {
          activity = activity.filter(
            rec => typeFilter.indexOf(rec.type.id) >= 0,
          );
        }
        if (createdByFilter.length > 0) {
          activity = activity.filter(
            rec => createdByFilter.indexOf(rec.createdByName) >= 0,
          );
        }
        return activity.sort((aActivity, bActivity) =>
          aActivity.createdAt < bActivity.createdAt ? 1 : -1,
        );
      }
    }
    return [];
  };

  const handleTableChange = (pagination, filters, sorter) => {
    if (filters.type !== null) {
      setTypeFilter(filters.type);
    } else {
      setTypeFilter([]);
    }
    if (filters.createdByName !== null) {
      setCreatedByFilter(filters.createdByName);
    } else {
      setCreatedByFilter([]);
    }
  };

  // @ts-ignore
  return (
    <Table
      showSorterTooltip
      columns={columns}
      dataSource={getActivity()}
      expandable={{
        expandedRowRender: activity => (
          <ActivityNote
            note={activity.notes}
            attributes={activity.attributes}
          />
        ),
        defaultExpandAllRows: true,
      }}
      loading={false}
      onChange={handleTableChange}
      rowKey="id"
    />
  );
};

export default ActivityTable;
