import React, { useState } from 'react';
import { Form } from 'antd';

import {
  ActivityExtendedField,
  ExtendedFieldValue,
} from '@totem/types/ticketing';

const FormItem = Form.Item;

import EmailAutoComplete from '@totem/components/common/autoCompletes/EmailAutoComplete';

import '../ticketing.css';

const styles = {
  form: {
    width: '100%',
  },
  formItem: {
    paddingBottom: '0',
    marginBottom: '1rem',
  },
};

type Props = {
  extendedField: ActivityExtendedField;
  extendedFieldValue: ExtendedFieldValue;
  onChange: (name: string, value: string, isValid: boolean) => void;
};

const ExtendedFieldEmailAutoComplete = ({
  extendedField,
  extendedFieldValue,
  onChange,
}: Props) => {
  const [selectedValue, setSelectedValue] = useState<string>(
    extendedFieldValue !== null ? extendedFieldValue.value : '',
  );

  const checkIsValid = checkValue => {
    if (extendedField.isRequired && checkValue.trim() === '') {
      return false;
    }
    return true;
  };

  const handleEmailChange = (value: string) => {
    setSelectedValue(value);
    onChange(extendedField.name, value, checkIsValid(value));
  };

  return (
    <FormItem
      label={extendedField.label}
      colon={false}
      style={styles.formItem}
      required={extendedField.isRequired}
      validateStatus={extendedFieldValue.isValid ? 'success' : 'error'}
    >
      <EmailAutoComplete
        value={selectedValue}
        onChange={handleEmailChange}
        placeholder="email@example.com"
      />
    </FormItem>
  );
};

export default ExtendedFieldEmailAutoComplete;
