import React, { useEffect, useState } from 'react';
import {
  FileTextOutlined,
  VerticalAlignBottomOutlined,
} from '@ant-design/icons';
import { Button, Checkbox, Modal, Select } from 'antd';

import colors from '@totem/styles/colors';
import fonts from '@totem/styles/fonts';
import { Nmap, NmapDevice } from '@totem/types/nmap';
import accountUtilities from '@totem/utilities/accountUtilities';
import api from '@totem/utilities/api';
import { NMAP_ENDPOINT, REPORT_ENDPOINT } from '@totem/utilities/endpoints';
import fileUtilities from '@totem/utilities/fileUtilities';

import NmapReportDetails from './NmapReportDetails';
import NmapReportScorecard from './NmapReportScorecard';

import './nmapDetail.css';

const { Option } = Select;

interface Props {
  nmap?: Nmap;
  flaggedDevices?: NmapDevice[];
}

const EXPORT_TYPES = [
  {
    label: 'Scorecard',
    value: 'scorecard',
  },
  {
    label: 'Details',
    value: 'details',
  },
  {
    label: '.csv file',
    value: 'csv',
  },
];

const styles = {
  uploadReadyIconStyle: {
    backgroundColor: colors.brand.blue,
  },
  input: {
    display: 'none',
  },
  icon: {
    color: colors.brand.blue,
    marginBottom: '3px',
  },
  floatingLabelStyle: {
    color: colors.neutral.dim,
    fontSize: fonts.md,
    fontWeight: '500',
  },
  textFieldStyle: {
    fontSize: fonts.md,
    color: colors.neutral.dark,
  },
  selectFieldStyle: {
    input: {
      fontSize: fonts.md,
      color: colors.neutral.black,
      whiteSpace: 'normal',
      padding: '0.25em 0',
    },
    selected: {
      fontSize: fonts.md,
      color: colors.brand.blue,
      whiteSpace: 'normal',
      padding: '0.25em 0',
    },
    iconStyle: {
      fill: colors.neutral.dim,
    },
  },
  predefinedMenuOption: {
    fontWeight: 600,
  },
  headingIcon: {
    position: 'absolute',
    paddingRight: '0.5rem',
    bottom: '1px',
    left: '-20px',
    fontSize: 16,
  },
};

const NmapReportModal = ({ nmap, flaggedDevices }: Props) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [exportType, setExportType] = useState<string>('scorecard');
  const [flaggedOnly, setFlaggedOnly] = useState<boolean>(false);

  const token = accountUtilities.getToken();
  const encodedId = encodeURIComponent(nmap.id);
  const encodedExportType = encodeURIComponent(exportType);
  const encodedToken = encodeURIComponent(token);

  useEffect(() => {
    setExportType('scorecard');
    setFlaggedOnly(false);
  }, [visible]);

  const downloadCSVFile = async () => {
    const { data } = await api.get(
      `${NMAP_ENDPOINT}/${nmap.id}/export?flaggedOnly=${flaggedOnly}`,
    );

    const name = nmap.name || 'NMAP';
    const fileName = nmap.building?.name
      ? `${nmap.building.name} - ${name}`
      : name;

    fileUtilities.downloadFile(fileName, data);
  };

  const downloadHref = `${REPORT_ENDPOINT}/report.pdf?token=${encodedToken}&url=report/nmap/${encodedId}/${encodedExportType}/${flaggedOnly}`;

  const content =
    exportType === 'scorecard' ? (
      <NmapReportScorecard
        devices={flaggedOnly ? flaggedDevices : nmap.devices}
      />
    ) : exportType === 'details' ? (
      <NmapReportDetails
        title={flaggedOnly ? 'Flagged Devices' : 'All Devices'}
        devices={flaggedOnly ? flaggedDevices : nmap.devices}
      />
    ) : (
      <div styleName="csv-container">Export the results as a .csv file.</div>
    );

  const exportComponent =
    exportType === 'csv' ? (
      <Button
        onClick={() => downloadCSVFile()}
        type="primary"
        icon={<FileTextOutlined />}
      >
        Export
      </Button>
    ) : (
      <a href={downloadHref} target="_blank">
        <Button type="primary" icon={<FileTextOutlined />}>
          Export
        </Button>
      </a>
    );

  return (
    <div>
      <div styleName="export-modal-label" onClick={() => setVisible(true)}>
        <>
          {
            // @ts-ignore
            <VerticalAlignBottomOutlined style={styles.headingIcon} />
          }
        </>
        Export Options
      </div>
      <Modal
        width={700}
        style={{
          top: '20vh',
          border: 'none',
        }}
        bodyStyle={{
          border: 'none',
          padding: '0',
        }}
        title="Flagged Devices Export - Findings Report"
        open={visible}
        footer={<div styleName="modal-footer">{exportComponent}</div>}
        onCancel={() => setVisible(false)}
      >
        <div styleName="modal-container">
          <div styleName="modal-export-option-text">
            Select your export format and preview below
          </div>
          <div styleName="modal-export-options-container">
            <div>
              <Select
                style={{ width: 180 }}
                onChange={(value: string) => setExportType(value)}
                value={exportType}
              >
                {EXPORT_TYPES.map((type: any) => (
                  <Option key={type.value} value={type.value}>
                    {type.label}
                  </Option>
                ))}
              </Select>
              <div style={{ marginTop: '1rem' }}>
                <Checkbox
                  checked={flaggedOnly}
                  onChange={() => setFlaggedOnly(current => !current)}
                  style={{ marginRight: '1rem' }}
                />
                <span>Flagged Devices Only</span>
              </div>
            </div>
            <div>{exportComponent}</div>
          </div>
          <div styleName="modal-content">{content}</div>
        </div>
      </Modal>
    </div>
  );
};

export default NmapReportModal;
