import React, { useCallback, useContext, useEffect, useState } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import { Button, Upload } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import { extname } from 'path';

import SurveyContext from '@totem/components/surveyV2/instanceDetail/SurveyInstanceContext';
import {
  QuestionValue,
  SurveyQuestion,
} from '@totem/components/surveyV2/types';
import {
  FileUploadRequest,
  MediaItem,
  MediaItems,
} from '@totem/types/fileUpload';
import accountUtilities, { getToken } from '@totem/utilities/accountUtilities';
import { FILES_ENDPOINT, MEDIA_ENDPOINT } from '@totem/utilities/endpoints';
import { QUESTIONNAIRE_SUBJECT_TYPE } from '@totem/utilities/mediaUtilities';
const FormData = require('form-data');
import {
  appendFormData,
  sanitizeFile,
} from '@totem/utilities/formDataUtilities';

type Props = {
  question: SurveyQuestion;
  disabled: boolean;
  onChange: (value: QuestionValue) => void;
};

const UploadQuestion = ({ question, disabled, onChange }: Props) => {
  const { data } = useContext(SurveyContext);
  const [refreshMedia, setRefreshMedia] = useState<boolean>(true);
  const [media, setMedia] = useState<MediaItems>([]);
  const { survey } = data;

  useEffect(() => {
    if (refreshMedia) {
      setRefreshMedia(false);
    }

    const params = `?subjectId=${question.id}&subjectType=${QUESTIONNAIRE_SUBJECT_TYPE}&questionnaireId=${survey.id}`;

    fetch(`${MEDIA_ENDPOINT}${params}`, {
      method: 'GET',
      headers: new Headers({
        Authorization: `Bearer ${getToken()}`,
      }),
    })
      .then((res) => res.json())
      .then((result: MediaItems) => {
        setMedia(result);
      });
  }, [question.id, refreshMedia]);

  const uploadFile = useCallback(async (uploadData: FileUploadRequest) => {
    const formData = new FormData();

    appendFormData(formData, 'file', sanitizeFile(uploadData.file));
    appendFormData(formData, 'organizationId', uploadData.organizationId);
    appendFormData(formData, 'buildingId', uploadData.buildingId);
    appendFormData(formData, 'controlSystemId', uploadData.controlSystemId);
    appendFormData(formData, 'deviceId', uploadData.deviceId);
    appendFormData(formData, 'userId', uploadData.userId);
    appendFormData(formData, 'questionnaireId', uploadData.questionnaireId);
    appendFormData(formData, 'policyAuditId', uploadData.policyAuditId);
    appendFormData(formData, 'subjectId', uploadData.subjectId);
    appendFormData(formData, 'subjectType', uploadData.subjectType);

    fetch(`${FILES_ENDPOINT}`, {
      method: 'POST',
      headers: new Headers({
        Authorization: `Bearer ${getToken()}`,
      }),
      body: formData,
    })
      .then((res) => res.json())
      .then((insertedMedia: MediaItem) => {
        const newValue: QuestionValue = {
          value:
            question.value.value === ''
              ? insertedMedia.name
              : `${question.value.value}, ${insertedMedia.name}`,
          valueInt: question.value.valueInt,
          valueFloat: question.value.valueFloat,
          valueArray: [
            ...question.value.valueArray,
            {
              value: insertedMedia.name,
              valueInt: 0,
              valueFloat: 0,
              valueArray: [],
            },
          ],
        };
        onChange(newValue);
      });
  }, []);

  const mapFileList = (): UploadFile[] => {
    return media.map(
      (item): UploadFile => ({
        uid: item.id,
        name: item.originalFileName,
        url: `${FILES_ENDPOINT}/${item.name}?originalFileName=${
          item.originalFileName
        }&token=${accountUtilities.getToken()}`,
        size: 0,
        type: extname(item.originalFileName),
      }),
    );
  };

  const handleUpload = (file: File): boolean => {
    const uploadData: FileUploadRequest = {
      file,
      organizationId: survey.organizationId,
      buildingId: survey.buildingId,
      questionnaireId: survey.id,
      subjectId: question.id,
      subjectType: QUESTIONNAIRE_SUBJECT_TYPE,
    };

    uploadFile(uploadData);

    return false;
  };

  return (
    <Upload
      beforeUpload={handleUpload}
      fileList={mapFileList()}
      disabled={disabled}
      showUploadList={{
        showRemoveIcon: false,
      }}
    >
      <Button disabled={disabled} icon={<UploadOutlined />}>
        Upload
      </Button>
    </Upload>
  );
};

export default UploadQuestion;
