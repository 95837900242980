import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Avatar, Dropdown, MenuProps } from 'antd';

import UserProfileContext from '@totem/components/UserProfileContext';
import colors from '@totem/styles/colors';
import accountUtilities from '@totem/utilities/accountUtilities';

import './profileIcon.css';

interface AvatarProps {
  size: number | 'small' | 'large' | 'default';
  style: any;
  children?: any;
  src?: string;
}

const styles = {
  avatar: {
    backgroundColor: colors.neutral.dim,
  },
};

const ProfileIcon = () => {
  const navigate = useNavigate();
  const { userProfile } = useContext(UserProfileContext);

  const navigateToLogin = () => {
    accountUtilities.logout();
    navigate('/login');
  };

  const navigateToUserProfile = () => {
    if (userProfile.organization.role === -2) {
      navigate('/tenant-dashboard/userProfile');
      return;
    }
    if (userProfile.organization.role === -1) {
      navigate('/vendor-dashboard/userProfile');
      return;
    }
    navigate('/dashboard/userProfile');
  };

  const isDefaultImage = userProfile
    ? userProfile.imageUrl.includes('cdn.auth0.com')
    : true;

  const initials = userProfile
    ? `${userProfile.firstName[0]}${userProfile.lastName[0]}`
    : 'NU';

  const avatarProps: AvatarProps = {
    size: 'large',
    style: styles.avatar,
    ...(isDefaultImage && { children: initials }),
    ...(!isDefaultImage && { src: userProfile.imageUrl }),
  };

  const items: MenuProps['items'] = [
    {
      key: 'user_profile',
      title: 'User Profile',
      label: 'User Profile',
    },
    {
      key: 'sign_out',
      title: 'Sign Out',
      label: 'Sign Out',
    },
  ];

  const onMenuClicked = ({ key }) => {
    // eslint-disable-next-line default-case
    switch (key) {
      case 'user_profile':
        navigateToUserProfile();
        break;
      case 'sign_out':
        navigateToLogin();
        break;
    }
  };

  return (
    <div styleName="container">
      <div styleName="avatar">
        <Dropdown
          menu={{ items, onClick: onMenuClicked }}
          trigger={['hover', 'click']}
          placement="bottomRight"
        >
          <Avatar {...avatarProps} />
        </Dropdown>
      </div>
    </div>
  );
};

export default ProfileIcon;
