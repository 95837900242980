import actionTypes from '@totem/actions/actionTypes';

const initialState = [];

function healthReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.CREATE_HEALTH_SUCCESS: {
      return action.data ? [...state, action.data] : state;
    }
    case actionTypes.GET_HEALTH_SUCCESS: {
      return action.data ? action.data : state;
    }
    default: {
      return state;
    }
  }
}

export default healthReducer;
