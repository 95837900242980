import React, { useCallback, useContext, useState } from 'react';
import { Button, DatePicker, Flex, message, Modal, Switch } from 'antd';
import dayjs from 'dayjs';

import FormFieldLabelLeft from '@totem/components/FormFieldLabelLeft';
import ModalFooter from '@totem/components/ModalFooter';
import ModalTitle from '@totem/components/ModalTitle';
import PackageContext from '@totem/components/software/software_package/packageContext';
import {
  EndOfSupportRequest,
  SoftwarePackageResponse,
} from '@totem/components/software/software_package/types';
import HeaderLabel from '@totem/components/ticketing/details/headerLabel';
import UserProfileContext from '@totem/components/UserProfileContext';
import IBDIcon from '@totem/styles/Icon';
import { getToken } from '@totem/utilities/accountUtilities';
import { isNotNull } from '@totem/utilities/common';
import { ParseDate } from '@totem/utilities/dateUtilities';
import { DEVICES_ENDPOINT } from '@totem/utilities/endpoints';
import { isIBUser } from '@totem/utilities/security';

const calcIsEndOfSupport = (data: SoftwarePackageResponse) => {
  if (
    isNotNull(data) &&
    isNotNull(data.package) &&
    isNotNull(data.package.endOfSupport)
  ) {
    if (data.package.endOfSupport.isEndOfSupport) {
      return true;
    }

    const eosDate = dayjs(data.package.endOfSupport.endOfSupportDate);
    if (eosDate.year() > 1970) {
      if (eosDate.valueOf() < dayjs().valueOf()) {
        return true;
      }
    }
  }

  return false;
};

const getndOfSupportDateDisplay = (data: SoftwarePackageResponse) => {
  if (
    isNotNull(data) &&
    isNotNull(data.package) &&
    isNotNull(data.package.endOfSupport)
  ) {
    const eosDate = dayjs(data.package.endOfSupport.endOfSupportDate);
    if (eosDate.year() > 1970) {
      return eosDate.format('YYYY-MM-DD');
    }
  }

  return '';
};

const SoftwareEndOfSupport = () => {
  const { data, onAction } = useContext(PackageContext);
  const isEndOfSupport = calcIsEndOfSupport(data);
  const endOfSupportDisplayDate = getndOfSupportDateDisplay(data);
  const { userProfile } = useContext(UserProfileContext);
  const canAdmin = userProfile.organization.role === 3 && isIBUser(userProfile);
  const [showEdit, setShowEdit] = useState<boolean>(false);
  const [isSending, setIsSending] = useState<boolean>(false);

  const [isEndOfSupportUI, setIsEndOfSupportUI] =
    useState<boolean>(isEndOfSupport);
  const [endOfSupportDateUI, setEndOfSupportDateUI] = useState<number>(
    endOfSupportDisplayDate === ''
      ? null
      : dayjs(endOfSupportDisplayDate, 'YYYY-MM-DD').valueOf(),
  );

  const sendUpdate = useCallback(
    async (request: EndOfSupportRequest) => {
      if (isSending) {
        return;
      }

      fetch(`${DEVICES_ENDPOINT}/software/endOfSupport`, {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getToken()}`,
        }),
        body: JSON.stringify(request),
      })
        .then((response) => {
          if (response.status !== 200) {
            message.error('End of support update failed!');
          } else {
            onAction('refresh');
            setShowEdit(false);
          }
        })
        .then(() => {
          setIsSending(false);
        });

      setIsSending(true);
    },
    [isSending],
  );

  const handleSubmit = () => {
    const request: EndOfSupportRequest = {
      packageId: data.package.id,
      endOfSupport: {
        isEndOfSupport: isEndOfSupportUI,
        endOfSupportDate:
          isNotNull(endOfSupportDateUI) && endOfSupportDateUI > 0
            ? dayjs(endOfSupportDateUI).toISOString()
            : null,
      },
    };

    sendUpdate(request);
  };

  return (
    <HeaderLabel
      title={
        <Flex justify={'space-between'} align={'center'}>
          <div>End of Support</div>
          <div>
            {canAdmin && !showEdit && (
              <IBDIcon
                name="Edit"
                title="Edit End of Support"
                onClick={() => setShowEdit(true)}
              />
            )}
          </div>
        </Flex>
      }
    >
      Has Reached End of Support: {isEndOfSupport ? 'Yes' : 'No'}
      {endOfSupportDisplayDate !== '' && (
        <div>End of Support Date: {endOfSupportDisplayDate}</div>
      )}
      {showEdit && (
        <Modal
          open={showEdit}
          onCancel={() => setShowEdit(false)}
          title={<ModalTitle>Edit End of Support</ModalTitle>}
          confirmLoading={false}
          footer={
            <ModalFooter>
              <Button onClick={() => setShowEdit(false)}>Close</Button>
              <Button onClick={handleSubmit} type="primary">
                Submit
              </Button>
            </ModalFooter>
          }
        >
          <Flex justify={'space-between'} align={'center'}>
            <div>
              <FormFieldLabelLeft>
                Has Reached End of Support:
              </FormFieldLabelLeft>
            </div>
            <div>
              {!isEndOfSupportUI ? <b>No</b> : <span>No</span>}{' '}
              <Switch
                defaultValue={isEndOfSupportUI}
                value={isEndOfSupportUI}
                onChange={(checked: boolean) => setIsEndOfSupportUI(checked)}
              />{' '}
              {isEndOfSupportUI ? <b>Yes</b> : <span>Yes</span>}
            </div>
          </Flex>
          <Flex justify={'space-between'} align={'center'}>
            <div>
              <FormFieldLabelLeft>End of Support Date:</FormFieldLabelLeft>
            </div>
            <div>
              <DatePicker
                value={
                  endOfSupportDateUI ? ParseDate(endOfSupportDateUI) : null
                }
                onChange={(date) => setEndOfSupportDateUI(date.valueOf())}
              />
              <IBDIcon
                name={'Delete'}
                onClick={() => setEndOfSupportDateUI(null)}
              />
            </div>
          </Flex>
        </Modal>
      )}
    </HeaderLabel>
  );
};

export default SoftwareEndOfSupport;
