import { algo, enc } from 'crypto-js';

type ChunkCallback = (chunk: string, offset: number, size: number) => void;
type EndCallback = (err?: Error) => void;

const readChunked = (
  file: File,
  chunkCallback: ChunkCallback,
  endCallback: EndCallback,
) => {
  const { size } = file;
  // read in 32MB chunks
  const chunkSize = 32 * 1024 * 1024;

  let offset = 0;

  const reader = new FileReader();

  const readNext = () => {
    const fileSlice = file.slice(offset, offset + chunkSize);
    reader.readAsBinaryString(fileSlice);
  };

  reader.onload = () => {
    if (reader.error) {
      endCallback(reader.error);
      return;
    }

    // @ts-ignore
    offset += reader.result.length;

    // @ts-ignore
    chunkCallback(reader.result, offset, size);

    if (offset >= size) {
      endCallback(null);
      return;
    }

    readNext();
  };

  reader.onerror = err => {
    // @ts-ignore
    endCallback(err);
  };

  readNext();
};

export const getFileChecksum = (file: File) =>
  new Promise((resolve, reject) => {
    const md5 = algo.MD5.create();

    readChunked(
      file,
      chunk => {
        md5.update(enc.Latin1.parse(chunk));
      },
      err => {
        if (err) {
          return reject(err);
        }

        return resolve(md5.finalize().toString(enc.Hex));
      },
    );
  });

export const downloadFile = (name, data: any) => {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = url;

  const fileName = `${name}.csv`;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);

  link.click();
  link.remove();
};

export const createLink = (href: string) => {
  const link = document.createElement('a');
  link.href = href;
  link.target = '_blank';

  document.body.appendChild(link);

  link.click();
  link.remove();
};

const fileUtilities = {
  downloadFile,
  getFileChecksum,
};

export default fileUtilities;
