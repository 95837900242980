import React, { ReactNode, useEffect, useState } from 'react';

import AnalysisContext from '@totem/components/surveyV2/analysis/AnalysisContext';
import {
  Analysis,
  AnalysisInput,
  AnalysisPolicy,
} from '@totem/components/surveyV2/analysis/types';
import { getToken } from '@totem/utilities/accountUtilities';
import { isNotNull } from '@totem/utilities/common';
import { V2_SURVEY_ENDPOINT } from '@totem/utilities/endpoints';
import { omitNilOrEmpty } from '@totem/utilities/objectUtilities';

type Props = {
  children?: ReactNode;
};

const AnalysisContainer = ({ children }: Props) => {
  const [refreshData, setRefreshData] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [data, setData] = useState<Analysis>(null);
  const [filteredData, setFilteredData] = useState<AnalysisPolicy[]>([]);
  const [input, updateInput] = useState<AnalysisInput>({
    pageSize: 100,
    page: 1,
    sortField: 'policyName',
    sortDirection: '1',
    policyName: [],
    category: [],
    nist: [],
    levelOfEffort: [],
  });

  const setInput = (updated: Partial<AnalysisInput>) => {
    updateInput(omitNilOrEmpty({ ...input, ...updated }));
  };

  useEffect(() => {
    if (isNotNull(data)) {
      let tmpData = [...data.auditPolicies];

      if (isNotNull(input.policyName) && input.policyName.length > 0) {
        tmpData = tmpData.filter((chk) =>
          input.policyName.includes(chk.policyName),
        );
      }
      if (isNotNull(input.category) && input.category.length > 0) {
        tmpData = tmpData.filter((chk) =>
          input.category.includes(chk.category),
        );
      }
      if (isNotNull(input.nist) && input.nist.length > 0) {
        tmpData = tmpData.filter((chk) => input.nist.includes(chk.nist));
      }
      if (isNotNull(input.levelOfEffort) && input.levelOfEffort.length > 0) {
        tmpData = tmpData.filter((chk) =>
          input.levelOfEffort.includes(chk.levelOfEffort),
        );
      }

      setFilteredData(tmpData);
    }
  }, [data, input]);

  useEffect(() => {
    if (refreshData) {
      setRefreshData(false);

      setIsLoading(true);

      fetch(`${V2_SURVEY_ENDPOINT}/analysis`, {
        method: 'GET',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
      })
        .then((res) => res.json())
        .then((result: Analysis) => {
          setData(result);
        })
        .then(() => {
          setIsLoading(false);
        });
    }
  }, [refreshData]);

  return (
    <AnalysisContext.Provider
      value={{
        input,
        setInput,
        filteredData,
        data,
        loading: isLoading,
        setIsLoading,
      }}
    >
      <div>{children}</div>
    </AnalysisContext.Provider>
  );
};

export default AnalysisContainer;
