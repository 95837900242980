import React from 'react';
import ReactApexChart from 'react-apexcharts';

import {
  Point,
  Points,
} from '@totem/components/ui_components/info_widget/types';
import { defaultChartColors } from '@totem/utilities/charts';

type Props = {
  points: Points;
  chartOptions?: ApexCharts.ApexOptions;
  onPointClicked?: (index: number, point: Point) => void;
};

const DonutChart = ({ points, chartOptions, onPointClicked }: Props) => {
  const getSeriesData = () => {
    const data = points.map((point) => point.value);
    return data;
  };

  const getLabels = () => {
    const data = points.map((point) =>
      point.label !== '' ? point.label : 'Unknown',
    );
    return data;
  };

  // eslint-disable-next-line id-length
  const handleClick = (e: any, chart?: any, options?: any) => {
    const dataPointIndex = options.dataPointIndex;
    if (dataPointIndex >= 0 && typeof onPointClicked === 'function') {
      const point = points[dataPointIndex];
      onPointClicked(dataPointIndex, point);
    }
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
  };

  const options: ApexCharts.ApexOptions = {
    chart: {
      type: 'donut',
      toolbar: { show: false },
      events: {
        dataPointSelection: handleClick,
      },
    },
    stroke: {
      show: false,
    },
    dataLabels: {
      enabled: false,
      background: {
        borderWidth: 0,
      },
    },
    tooltip: {
      x: {
        show: false,
      },
      y: {
        formatter: (val: number): string => {
          return `${val.toLocaleString()}`;
        },
      },
    },
    grid: {
      show: false,
      padding: {
        left: 20,
        right: -0,
      },
    },
    plotOptions: {
      pie: {
        donut: {
          size: '70%',
          labels: {
            show: false,
            name: {
              show: false,
            },
            value: {
              show: false,
            },
            total: {
              show: false,
            },
          },
        },
      },
    },
    labels: getLabels(),
    colors: defaultChartColors,
    legend: {
      show: false,
    },
  };

  const getChartOptions = () => {
    if (typeof chartOptions !== 'undefined' && chartOptions !== null) {
      return { ...options, ...chartOptions };
    }
    return options;
  };

  return (
    <div className="app">
      <div className="row">
        <div className="mixed-chart">
          {typeof points !== 'undefined' &&
            points !== null &&
            points.length > 0 && (
              <ReactApexChart
                options={getChartOptions()}
                series={getSeriesData()}
                labels={getLabels()}
                type="donut"
                width="195px"
                height="185px"
              />
            )}
        </div>
      </div>
    </div>
  );
};

export default DonutChart;
