import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';

import SquareFootageContext from '@totem/components/buildings/squareFootage/SquareFootageContext';
import BuildingSummaryContext from '@totem/components/buildingSummary/buildingSummaryContext';
import { defaultChartColors, shuffle } from '@totem/utilities/charts';
import { isNotNull } from '@totem/utilities/common';
import { sortNumberDescending } from '@totem/utilities/tableUtilities';
import { Item } from '@totem/components/buildings/squareFootage/types';

type Props = {
  chartOptions?: ApexOptions;
};

const TreeMapChart = ({ chartOptions }: Props) => {
  const showLegend = false;
  const { data, colors } = useContext(SquareFootageContext);
  const { setInput } = useContext(BuildingSummaryContext);
  const [seriesData, setSeriesData] = useState<Item[]>([]);
  const seriesDataRef = useRef<Item[]>([]);
  const currentColors = [];

  useEffect(() => {
    if (isNotNull(data)) {
      const sortedData = data
        .sort((compA, compB) =>
          sortNumberDescending(
            compA.totalSquareFootage,
            compB.totalSquareFootage,
          ),
        );

      setSeriesData(sortedData);
      seriesDataRef.current = sortedData;
    }
  }, [data]);

  const getSeriesData = () => {
    if (isNotNull(seriesData)) {
      return [
        {
          data: seriesData
            .map((chk, index) => {
              const color = colors.find((chk2) => chk2.type === chk.type);
              const useColor = isNotNull(color) ? color.color : defaultChartColors[index % defaultChartColors.length]

              currentColors.push(useColor);

              return {
                x: chk.type,
                y: chk.totalSquareFootage,
                color: useColor, // Assign a color
              };
            }),
        },
      ];
    }
    return [];
  };

  const handleClick = useCallback(
    (e: any, chart?: any, options?: any) => {
      const dataPointIndex = options.dataPointIndex;
      const localData = seriesDataRef.current;
      if (localData.length === 1) {
        setInput({ type: [] });
        return;
      }

      if (dataPointIndex >= 0) {
        const point = localData[dataPointIndex];
        setInput({ type: [point.type] });
      }
      e.stopPropagation();
      try {
        e.nativeEvent.stopImmediatePropagation();
      } catch (ex) {
        /* empty */
      }
    },
    [seriesData] // Recompute handleClick whenever seriesData changes
  );

  const options: ApexOptions = {
    chart: {
      type: 'treemap',
      toolbar: { show: true, tools: { download: true } },
      events: {
        dataPointSelection: handleClick,
      },
    },
    dataLabels: {
      enabled: true,
    },
    stroke: {
      show: false,
    },
    plotOptions: {
      treemap: {
        distributed: true,
        enableShades: false,
      },
    },
    colors: currentColors,
    legend: {
      show: true,
      position: 'right',
    },
    title: {
      text: 'Square Footage by Building Type',
      align: 'center',
    },
    tooltip: {
      y: {
        formatter: (value) => {
          // Format the value with commas
          return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ' sq. ft.';
        },
      },
    },
  };

  const getChartOptions = () => {
    if (typeof chartOptions !== 'undefined' && chartOptions !== null) {
      return { ...options, ...chartOptions };
    }
    return options;
  };

  const chartData = getSeriesData();

  return (
    <div className="app">
      <div className="row">
        <div className="mixed-chart">
          {isNotNull(data) && data.length > 0 && (
            <>
              <ReactApexChart
                options={getChartOptions()}
                series={chartData}
                type="treemap"
                width="100%"
                height="400px"
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default TreeMapChart;
