import React from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';

import Tooltip from '@totem/components/common/tooltip/Tooltip';
import colors from '@totem/styles/colors';

interface Props {
  style?: React.CSSProperties;
}

const styles = {
  icon: {
    fontSize: 16,
    lineHeight: 'initial',
    color: colors.neutral.gray,
    cursor: 'pointer',
  },
};

const TITLE =
  '"Critical Findings" are created when the chosen answer to a question has a recommendation that has been flagged as critical in order to surface critical findings.';

const CriticalFindingsTooltip = ({ style }: Props) => (
  <Tooltip title={TITLE}>
    <InfoCircleOutlined style={{ ...styles.icon, ...style }} />
  </Tooltip>
);

export default CriticalFindingsTooltip;
