import React, { useContext, useEffect, useState } from 'react';

import DropdownFilter from '@totem/components/common/dropdownFilter/DropdownFilter';
import FiltersChipsContainer from '@totem/components/FilterChipsContainer';
import FiltersBody from '@totem/components/FiltersBody';
import FiltersContainer from '@totem/components/FiltersContainer';
import FiltersTitle from '@totem/components/FiltersTitle';
import RemoteAccessFilterChips from '@totem/components/remoteAccess/roleUsers/rootRole/RemoteAccessFilterChips';
import RemoteAccessSessionContext from '@totem/components/remoteAccess/sessions/RemoteAccessSessionContext';
import { RemoteAccessSessionsInput } from '@totem/components/remoteAccess/types';

import '../../remoteAccess.css';
const styles = {
  dropdown: {
    marginRight: '2rem',
  },
};

const RemoteAccessFilters = () => {
  const { input, setInput, groupOptions } = useContext(
    RemoteAccessSessionContext,
  );
  const [groups, setGroups] = useState<any>([]);

  useEffect(() => {
    const newGroups = groupOptions.map((grp) => ({
      label: grp,
      value: grp,
    }));
    setGroups(newGroups);
  }, [groupOptions]);

  const handleMultiNumberFilterChange = (
    property: keyof RemoteAccessSessionsInput,
    update: string[],
  ) => {
    setInput({
      ...input,
      [property]: update.map(String),
    });
  };

  return (
    <FiltersContainer>
      <FiltersTitle>Remote Access Sessions</FiltersTitle>
      <FiltersBody>
        <DropdownFilter
          label="Access Group"
          options={groups}
          value={input.group || []}
          onChange={(update) => handleMultiNumberFilterChange('group', update)}
          style={styles.dropdown}
        />
      </FiltersBody>
      <FiltersChipsContainer>
        <RemoteAccessFilterChips />
      </FiltersChipsContainer>
    </FiltersContainer>
  );
};

export default RemoteAccessFilters;
