import React, { useContext } from 'react';
import { Button } from 'antd';

import RecordCountBadge from '@totem/components/RecordCountBadge';
import RemoteAccessUserContext from '@totem/components/remoteAccess/roleUsers/RemoteAccessUserContext';
import UserProfileContext from '@totem/components/UserProfileContext';
import {
  isMemberOf,
  securityGroupCheckConstraintEmpty,
} from '@totem/utilities/userUtilities';

import '../../remoteAccess.css';

const HeaderAction = () => {
  const { userProfile } = useContext(UserProfileContext);
  const { totalRecords, onAction, roleData } = useContext(
    RemoteAccessUserContext,
  );

  const remoteAccessAdmin =
    typeof roleData !== 'undefined' && roleData !== null
      ? isMemberOf(userProfile, 'remote_access_admin', {
          regionId: roleData.regionId,
          buildingId: roleData.buildingId,
          controlSystemId: roleData.controlSystemId,
        })
      : isMemberOf(
          userProfile,
          'remote_access_admin',
          securityGroupCheckConstraintEmpty,
        );

  return (
    <div>
      <RecordCountBadge
        count={
          typeof totalRecords !== 'undefined' && totalRecords !== null
            ? totalRecords
            : 0
        }
      />
      &nbsp;&nbsp;&nbsp;
      {remoteAccessAdmin && (
        <Button
          type="primary"
          onClick={() => onAction('addUser', null)}
          style={{ marginLeft: '25px' }}
        >
          Add User
        </Button>
      )}
    </div>
  );
};

export default HeaderAction;
