import React from 'react';
import { Badge, Card } from 'antd';

import colors from '@totem/styles/colors';

import './nmapDetail.css';

interface Props {
  numberOfFlaggedDevices: number;
}

const styles = {
  badgeColor: colors.finding,
};

const FlaggedDevicesOverview = ({ numberOfFlaggedDevices }: Props) => {
  return (
    <Card
      title={
        <div styleName="overview-header">
          <div>Flagged Devices</div>
        </div>
      }
      bordered={false}
    >
      <div styleName="overview-card-body">
        <div styleName="overview-card">
          <div styleName="overview-title">Total</div>
          <div styleName="stat">
            <span styleName="findings-badge-container">
              <Badge
                color={styles.badgeColor}
                className="health-status-badge-lg"
              />
              {numberOfFlaggedDevices}
            </span>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default FlaggedDevicesOverview;
