import React, { useContext } from 'react';
import { Button } from 'antd';

import OrganizationContactsContext from '@totem/components/settings/sections/contacts/contactsContext';
import authUtilities from '@totem/utilities/authUtilities';

const ContactsHeaderActions = () => {
  const { onAction } = useContext(OrganizationContactsContext);

  const isSysAdmin = authUtilities.minimumRequiredRole(
    authUtilities.ROLE_SETS.SYSTEMS_ADMINISTRATOR,
  );

  return (
    <>
      {isSysAdmin && (
        <Button
          type="primary"
          onClick={() =>
            onAction('EditContact', {
              id: '',
              name: '',
              email: '',
              phone: '',
              labels: [],
            })
          }
        >
          Add Contact
        </Button>
      )}
    </>
  );
};

export default ContactsHeaderActions;
