export const PolicyNames = [
  {
    label: 'N/A',
    value: 0,
  },
  {
    label: 'User Authorization',
    value: 1,
  },
  {
    label: 'Administrative Users',
    value: 2,
  },
  {
    label: 'Activity Logging',
    value: 3,
  },
  {
    label: 'User Audit Policy',
    value: 4,
  },
  {
    label: 'Password Policy',
    value: 5,
  },
  {
    label: 'Guest Accounts',
    value: 6,
  },
  {
    label: 'Default Password',
    value: 7,
  },
  {
    label: 'Operating System',
    value: 8,
  },
  {
    label: 'Auto-lockout',
    value: 9,
  },
  {
    label: 'Least Privilege',
    value: 10,
  },
  {
    label: 'Malware Protection',
    value: 11,
  },
  {
    label: 'OT Server',
    value: 12,
  },
  {
    label: 'Patch Management',
    value: 13,
  },
  {
    label: 'Backup',
    value: 14,
  },
  {
    label: 'Remote Communication',
    value: 15,
  },
  {
    label: 'Asset Management',
    value: 16,
  },
  {
    label: 'Public-facing IP Address',
    value: 17,
  },
  {
    label: 'Cyber Security Roles And Responsibilities',
    value: 18,
  },
  {
    label: 'Auto-logoff',
    value: 19,
  },
  {
    label: 'Authentication Encryption',
    value: 20,
  },
  {
    label: 'Asset Inventory',
    value: 21,
  },
  {
    label: 'Disaster Recover',
    value: 22,
  },
  {
    label: 'Unknown Devices',
    value: 23,
  },
  {
    label: 'Non Application Software',
    value: 24,
  },
  {
    label: 'Server Location',
    value: 25,
  },
  {
    label: 'Internet Management',
    value: 26,
  },
];

export const PolicyCategory = [
  {
    label: 'N/A',
    value: 0,
  },
  {
    label: 'Access Control',
    value: 1,
  },
  {
    label: 'Asset Management',
    value: 2,
  },
  {
    label: 'Protective Technology',
    value: 3,
  },
  {
    label: 'Continuous Monitoring',
    value: 4,
  },
  {
    label: 'Information Protection',
    value: 5,
  },
  {
    label: 'Data Security',
    value: 6,
  },
  {
    label: 'Recovery Planning',
    value: 7,
  },
];

export const NistFunction = [
  {
    label: 'N/A',
    value: 0,
  },
  {
    label: 'Protect',
    value: 1,
  },
  {
    label: 'Detect',
    value: 2,
  },
  {
    label: 'Identify',
    value: 3,
  },
  {
    label: 'Recover',
    value: 4,
  },
];

export const LevelOfEffort = [
  {
    label: 'N/A',
    value: 0,
  },
  {
    label: 'Minimal',
    value: 1,
  },
  {
    label: 'Moderate',
    value: 2,
  },
  {
    label: 'Maximum',
    value: 3,
  },
];

export const LevelOfEffortToString = (loeVal: number) => {
  for (let idx = 0; idx < LevelOfEffort.length; idx++) {
    if (LevelOfEffort[idx].value === loeVal) {
      return LevelOfEffort[idx].label;
    }
  }
  return '';
}
