import React, { useContext } from 'react';
import { Tabs, TabsProps } from 'antd';

import DeviceEmbeddedContainer from '@totem/components/devices/devicesContainer/DeviceEmbeddedContainer';
import DeviceTable from '@totem/components/software/software_package/deviceTable';
import PackageContext from '@totem/components/software/software_package/packageContext';
import PackageVulnerabilities from '@totem/components/software/software_package/packageVulnerabilities';
import VersionDetails from '@totem/components/software/software_package/version/VersionDetails';
import TabTitle from '@totem/components/TabTitle';
import colors from '@totem/styles/colors';
import { DEVICES_PAGINATION_ENDPOINT } from '@totem/utilities/endpoints';

const styles = {
  container: {
    fontSize: '36px',
    backgroundColor: colors.branding.primaryDark,
    color: colors.neutral.white,
    padding: '20px',
    marginBottom: '15px',
  },
};

const VersionContent = () => {
  const { data, selectedVersion } = useContext(PackageContext);

  const getVersionTabItems = () => {
    const tabItems: TabsProps['items'] = [
      {
        key: 'details',
        label: <TabTitle>Details</TabTitle>,
        children: <VersionDetails />,
      },
      {
        key: 'vulnerabilities',
        label: <TabTitle>Vulnerabilities</TabTitle>,
        children: <PackageVulnerabilities version={selectedVersion} />,
      },
      {
        key: 'devices',
        label: <TabTitle>Devices</TabTitle>,
        children: (
          <DeviceEmbeddedContainer
            deviceEndPoint={`${DEVICES_PAGINATION_ENDPOINT}/software/${data.package.id}/${selectedVersion}`}
          >
            <DeviceTable />
          </DeviceEmbeddedContainer>
        ),
      },
    ];

    return tabItems;
  };

  return (
    <>
      <div style={styles.container}>Version: {selectedVersion}</div>
      <Tabs defaultActiveKey="details" items={getVersionTabItems()} />
    </>
  );
};

export default VersionContent;
