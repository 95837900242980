import React from 'react';

import CoveDataProtectionMappingContainer from '@totem/components/settings/sections/coveDataProtectionMapping/CoveDataProtectionContainer';
import CoveDataProtectionMappingTable from '@totem/components/settings/sections/coveDataProtectionMapping/CoveDataProtectionTable';

import '../../settings.css';
const CoveDataProtectionMappingPanel = () => {
  return (
    <div styleName="container">
      <div styleName="header-container">
        <div styleName="header">Building Mapping</div>
      </div>
      <CoveDataProtectionMappingContainer>
        <CoveDataProtectionMappingTable />
      </CoveDataProtectionMappingContainer>
    </div>
  );
};

export default CoveDataProtectionMappingPanel;
