import React from 'react';

import AuvikMappingContainer from '@totem/components/settings/sections/auvikMapping/auvikMappingContainer';
import AuvikMappingTable from '@totem/components/settings/sections/auvikMapping/AuvikMappingTable';

import '../../settings.css';
const AuvikMappingPanel = () => {
  return (
    <div styleName="container">
      <div styleName="header-container">
        <div styleName="header">Building Mapping</div>
      </div>
      <AuvikMappingContainer>
        <AuvikMappingTable />
      </AuvikMappingContainer>
    </div>
  );
};

export default AuvikMappingPanel;
