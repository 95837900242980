import actionTypes from '@totem/actions/actionTypes';

import initialState from './initialState';

export default function remoteAccessUsersReducer(
  state = initialState.remoteAccessUsers,
  action,
) {
  switch (action.type) {
    case actionTypes.REQUEST_REMOTE_ACCESS_USERS_SUCCESS: {
      return action.data ? [...action.data] : [];
    }
    case actionTypes.REQUEST_REMOTE_ACCESS_USER_ADD_TO_ROLE_SUCCESS: {
      if (action.data !== null) {
        if (action.data.length > 0) {
          // eslint-disable-next-line max-depth
          if (state[0].orgRoleId === action.data[0].orgRoleId) {
            return action.data ? action.data : state;
          }
        }
      }

      return state;

      //return action.data ? action.data : state;
    }
    case actionTypes.REQUEST_REMOTE_ACCESS_USER_REMOVE_FROM_ROLE_SUCCESS: {
      if (action.data !== null) {
        if (action.data.length > 0) {
          // eslint-disable-next-line max-depth
          if (state[0].orgRoleId === action.data[0].orgRoleId) {
            return action.data ? action.data : state;
          }
        }
      }

      return state;
      //return action.data ? action.data : state;
    }
    default: {
      return state;
    }
  }
}
