import React, { useContext, useEffect } from 'react';
import { Col, Row } from 'antd';

import EnergyContext from '@totem/components/energy/EnergyContext';
import EnergyEndUseChart from '@totem/components/energy/EnergyEndUseChart';
import EnergyVerticalBarChart from '@totem/components/energy/EnergyVerticalBarChart';
import {
  calculateAggregate,
  getMetricDisplay,
} from '@totem/components/energy/utilities';
import { sortStringAscending } from '@totem/utilities/tableUtilities';

const EnergyChartsContainer = () => {
  const {
    portfolioChartData,
    portfolioEndUses,
    input,
    setApiUsage,
  } = useContext(EnergyContext);

  useEffect(() => {
    setApiUsage({
      portfolioChart: true,
      portfolioEndUses: true,
    });
  }, []);

  const metricInfo = getMetricDisplay(input.metric);
  const aggregateInfo = calculateAggregate({
    dateRangeType: input.dateRangeType,
    startDate: input.startDate,
    endDate: input.endDate,
  });
  return (
    <>
      <Row>
        <Col span={12}>
          {typeof portfolioEndUses !== 'undefined' &&
            portfolioEndUses !== null &&
            portfolioEndUses.data !== null && (
              <EnergyEndUseChart
                title={'Energy Consumption by End Use'}
                subTitle={'Totals in kWh'}
                metric={metricInfo.name}
                unit={metricInfo.unit}
                multiplier={0.001}
                startDate={input.startDate}
                endDate={input.endDate}
                useData={portfolioEndUses.data}
              />
            )}
        </Col>
        <Col span={12}>
          {typeof portfolioChartData !== 'undefined' &&
            portfolioChartData !== null && (
              <EnergyVerticalBarChart
                title={'Total Energy Consumption'}
                subTitle={`${aggregateInfo.aggregateLabel} Energy Consumption (kWh)`}
                metric={metricInfo.display}
                unit={metricInfo.unit}
                multiplier={0.001}
                startDate={input.startDate}
                endDate={input.endDate}
                timeSeriesData={portfolioChartData.sort(
                  (compA, compB) =>
                  sortStringAscending(compA.time_stamp, compB.time_stamp),
                )}
              />
            )}
        </Col>
      </Row>
    </>
  );
};

export default EnergyChartsContainer;
