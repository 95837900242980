import React from 'react';

import BasePane from '@totem/components/BasePane';
import ComponentHeader from '@totem/components/ComponentHeader';
import ContentLayout from '@totem/components/ContentLayout';
import PageSectionBody from '@totem/components/PageSectionBody';
import PrimaryContentPane from '@totem/components/PrimaryContentPane';
import AnalysisReportContainer from '@totem/components/surveyV2/analysisReport/analysisReportContainer';
import AnalysisReportFilterPanel from '@totem/components/surveyV2/analysisReport/analysisReportFilterPanel';
import AuditPolicies from '@totem/components/surveyV2/analysisReport/auditPolicies/auditPolicies';
import Overview from '@totem/components/surveyV2/analysisReport/overview';

const AnalysisReportPage = () => {
  return (
    <ContentLayout>
      <BasePane>
        <PrimaryContentPane>
          <AnalysisReportContainer>
            <AnalysisReportFilterPanel title={'Analysis Report'} />
            <ComponentHeader title={'Overview'} />
            <PageSectionBody>
              <Overview />
            </PageSectionBody>
            <ComponentHeader title={'Policies'} />
            <PageSectionBody>
              <AuditPolicies />
            </PageSectionBody>
          </AnalysisReportContainer>
        </PrimaryContentPane>
      </BasePane>
    </ContentLayout>
  );
};

export default AnalysisReportPage;
