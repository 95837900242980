/* eslint-disable id-length */
import React, { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CloseOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Checkbox, Form, Input, Modal, Select, Spin } from 'antd';
import * as R from 'ramda';

import { createNotification } from '@totem/actions/notificationActions';
import Notification from '@totem/components/common/Notification';
import UserProfileContext from '@totem/components/UserProfileContext';
import client from '@totem/graph/client';
import {
  CREATE_ORGANIZATION,
  GET_ORGANIZATION_USERS_AND_QUESTIONNAIRE_TEMPLATES,
  SWITCH_ORGANIZATION,
} from '@totem/graph/organization';
import { useErrorNotification } from '@totem/hooks/useErrorNotification';
import colors from '@totem/styles/colors';
import {
  OrganizationCreateInput,
  OrganizationType,
} from '@totem/types/organization';
import { UserAccess } from '@totem/types/user';
import accountUtilities from '@totem/utilities/accountUtilities';
import { validateURL } from '@totem/utilities/validation';

import './registration.css';

const FormItem = Form.Item;
const { Option } = Select;

enum View {
  MAIN,
  REGISTRATION,
}

interface Errors {
  name?: string;
  domain?: string;
}

const styles = {
  footer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  form: {
    width: '100%',
  },
  formItem: {
    paddingBottom: '0',
    marginBottom: '2rem',
  },
  icon: {
    marginRight: '1.5rem',
    fontSize: '24px',
    color: colors.opacity.black0_4,
  },
};

const initialOrganization: OrganizationCreateInput = {
  name: '',
  type: OrganizationType.Customer,
  domain: '',
  userIds: [],
  questionnaireTemplateIds: [],
};

const SYSTEMS_ADMINS_ROLE = 3;

const getURLDomain = (url: string): string => {
  const domain = url.startsWith('http') ? url : `http://${url}`;
  return new URL(domain).hostname;
};

const SwitchOrganizationsModal = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [view, setView] = useState<View>(View.MAIN);
  const [loadingToken, setLoadingToken] = useState<boolean>(false);
  const [errors, setErrors] = useState<Errors>({});
  const [nmap, setNmap] = useState<boolean>(false);
  const [input, setInput] =
    useState<OrganizationCreateInput>(initialOrganization);

  const { userProfile, loading } = useContext(UserProfileContext);
  const { organizations } = userProfile;

  const [switchOrganization] = useMutation(SWITCH_ORGANIZATION);
  const [createOrganization, { loading: loadingCreate, error: createError }] =
    useMutation(CREATE_ORGANIZATION);

  useErrorNotification(
    createError,
    'An error occurred. Ensure that an organization with that domain does not already exist.',
  );

  const [getUsersAndTemplates, { data, loading: loadingUsersAndTemplates }] =
    useLazyQuery(GET_ORGANIZATION_USERS_AND_QUESTIONNAIRE_TEMPLATES);

  const getOrganizationToken = async (organizationId: string) => {
    return switchOrganization({ variables: { organizationId } }).then(
      (res) => res.data.organizationSwitch.token,
    );
  };

  const handleInputChange = (update: Partial<OrganizationCreateInput>) => {
    setInput((current) => ({ ...current, ...update }));
  };

  const isValidForm = () => {
    const { name, domain } = input;

    const formErrors: Errors = {};

    if (!name) {
      formErrors.name = 'Name is required.';
    }

    if (!domain) {
      formErrors.domain = 'Domain is required.';
    } else if (!validateURL(domain)) {
      formErrors.domain = 'Domain must be a valid web domain.';
    }

    setErrors(formErrors);
    return R.isEmpty(formErrors);
  };

  const handleTemplateOrgChange = async (organizationId: string) => {
    try {
      handleInputChange({ userIds: [], questionnaireTemplateIds: [] });

      setLoadingToken(true);

      const token = await getOrganizationToken(organizationId);

      accountUtilities.setToken(token);

      await client.clearStore();

      setLoadingToken(false);

      await getUsersAndTemplates({
        variables: {
          usersInput: {
            accesses: [UserAccess['Has Access'], UserAccess['Access Granted']],
          },
          questionnaireTemplatesInput: {},
        },
      });
    } catch (error) {
      createNotification({
        message: 'Could not fetch template organization data',
        isError: true,
      });
    }
  };

  const navigateToOrganization = async (id: string) => {
    try {
      const token = await getOrganizationToken(id);
      accountUtilities.setToken(token);
      await client.clearStore();

      navigate('/');
    } catch (err) {
      dispatch(
        createNotification({
          message: `Could not switch to the organization`,
          isError: true,
        }),
      );
    }
  };

  const handleSubmit = async () => {
    if (isValidForm()) {
      await createOrganization({
        variables: {
          input: {
            ...input,
            domain: getURLDomain(input.domain),
            features: nmap ? [{ feature: 'nmap', enabled: true }] : [],
          },
        },
      });

      navigate('/login');
    }
  };

  const users = [...R.pathOr([], ['users', 'users'], data)];
  const templates = [
    ...R.pathOr([], ['questionnaireTemplates', 'questionnaireTemplates'], data),
  ];
  const isSystemsAdmin = userProfile.organizations.some(
    (org) => org.role === SYSTEMS_ADMINS_ROLE && org.isActive,
  );

  const handleCancel = () => {
    setView(View.MAIN);
    setLoadingToken(false);
    setErrors({});
    setNmap(false);
    setInput(initialOrganization);
  };

  return (
    <div>
      <Modal
        open
        style={{ top: '20vh', border: 'none' }}
        styles={{ body: { border: 'none' } }}
        title={
          <div styleName="organization-switcher-modal-title">
            {view === View.MAIN ? 'Select an Account' : 'Create New Account'}
          </div>
        }
        confirmLoading={loadingCreate}
        footer={
          view === View.REGISTRATION && (
            // @ts-ignore
            <div style={styles.footer}>
              <Button onClick={handleCancel}>Cancel</Button>
              <Button
                type="primary"
                onClick={handleSubmit}
                loading={loadingCreate}
              >
                Create
              </Button>
            </div>
          )
        }
        closable={false}
      >
        {view === View.MAIN ? (
          <Spin spinning={loading}>
            <div styleName="organization-switcher-modal-content">
              <div styleName="organization-switcher-content-heading">
                Select an organization you would like to log into.
              </div>
              <div styleName="organization-switcher-list-container">
                {isSystemsAdmin && (
                  <div
                    styleName="organization-selection-container"
                    onClick={() => setView(View.REGISTRATION)}
                  >
                    <PlusCircleOutlined style={styles.icon} />
                    <div styleName="organization-selection-name">
                      Create a new account
                    </div>
                  </div>
                )}
                {organizations
                  .filter((org) => org.isActive)
                  .map((org) => (
                    <div
                      styleName="organization-selection-container"
                      onClick={() => navigateToOrganization(org.id)}
                      key={org.id}
                    >
                      <div styleName="organization-selection-name">
                        {org.name}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </Spin>
        ) : (
          <Form layout="vertical" style={styles.form}>
            <FormItem
              label="Organization Name"
              colon={false}
              validateStatus={R.isNil(errors.name) ? 'success' : 'error'}
              help={R.isNil(errors.name) ? null : errors.name}
              style={styles.formItem}
            >
              <Input
                placeholder="Enter Organization Name"
                value={input.name}
                onChange={(event) =>
                  handleInputChange({ name: event.target.value })
                }
                autoComplete="off"
              />
            </FormItem>
            <FormItem
              label="Organization Domain"
              colon={false}
              validateStatus={R.isNil(errors.domain) ? 'success' : 'error'}
              help={R.isNil(errors.domain) ? null : errors.domain}
              style={styles.formItem}
            >
              <Input
                name="domain"
                placeholder="Organization Domain"
                onChange={(event) =>
                  handleInputChange({ domain: event.target.value })
                }
                value={input.domain}
                autoComplete="off"
              />
            </FormItem>
            <FormItem
              label="Duplicate Template Account"
              colon={false}
              style={styles.formItem}
            >
              <Select
                showSearch
                filterOption
                optionFilterProp="children"
                onChange={handleTemplateOrgChange}
              >
                {userProfile.organizations
                  .filter(
                    (org) => org.isActive && org.role === SYSTEMS_ADMINS_ROLE,
                  )
                  .map(({ id, name }) => {
                    return (
                      <Option key={id} value={id}>
                        {name}
                      </Option>
                    );
                  })}
              </Select>
            </FormItem>
            <FormItem
              label="Add existing team members"
              colon={false}
              style={{ padding: 0, margin: 0 }}
            >
              <Select
                showSearch
                filterOption
                mode="multiple"
                optionFilterProp="children"
                value={input.userIds}
                maxTagCount={0}
                maxTagPlaceholder={
                  <div>
                    {`${input.userIds.length} team members selected`}
                    <CloseOutlined
                      style={{ marginLeft: '0.5rem', cursor: 'pointer' }}
                      onClick={() => handleInputChange({ userIds: [] })}
                    />
                  </div>
                }
                onSelect={(userId) =>
                  handleInputChange({ userIds: [...input.userIds, userId] })
                }
                onDeselect={(userId) =>
                  handleInputChange({
                    userIds: input.userIds.filter((id) => id !== userId),
                  })
                }
                disabled={!data || loadingToken || loadingUsersAndTemplates}
              >
                {users
                  .sort((a, b) => (a.email < b.email ? -1 : 1))
                  .map(({ id, email }) => {
                    return (
                      <Option key={id} value={id}>
                        {email}
                      </Option>
                    );
                  })}
              </Select>
            </FormItem>
            <div
              onClick={() =>
                handleInputChange({
                  userIds: users.map((user) => user.id),
                })
              }
              styleName={`select-all-button ${
                !users.length || users.length === input.userIds.length
                  ? 'disabled'
                  : ''
              }`}
            >
              Select All
            </div>
            <FormItem
              label="Add survey templates from an existing account"
              colon={false}
              style={{ padding: 0, margin: 0 }}
            >
              <Select
                showSearch
                filterOption
                mode="multiple"
                optionFilterProp="children"
                value={input.questionnaireTemplateIds}
                maxTagCount={0}
                maxTagPlaceholder={
                  <div>
                    {`${input.questionnaireTemplateIds.length} surveys selected`}
                    <CloseOutlined
                      style={{ marginLeft: '0.5rem', cursor: 'pointer' }}
                      onClick={() =>
                        handleInputChange({ questionnaireTemplateIds: [] })
                      }
                    />
                  </div>
                }
                onSelect={(questionnaireTemplateId) =>
                  handleInputChange({
                    // @ts-ignore
                    questionnaireTemplateIds: [
                      ...input.questionnaireTemplateIds,
                      questionnaireTemplateId,
                    ],
                  })
                }
                onDeselect={(questionnaireTemplate) =>
                  handleInputChange({
                    // @ts-ignore
                    questionnaireTemplateIds:
                      input.questionnaireTemplateIds.filter(
                        (id) => id !== questionnaireTemplate,
                      ),
                  })
                }
                disabled={!data || loadingToken || loadingUsersAndTemplates}
              >
                {templates.map(({ id, name }) => {
                  return (
                    <Option key={id} value={id}>
                      {name}
                    </Option>
                  );
                })}
              </Select>
            </FormItem>
            <div
              onClick={() =>
                handleInputChange({
                  // @ts-ignore
                  questionnaireTemplateIds: templates.map(
                    (template) => template.id,
                  ),
                })
              }
              styleName={`select-all-button ${
                !templates.length ||
                templates.length === input.questionnaireTemplateIds.length
                  ? 'disabled'
                  : ''
              }`}
            >
              Select All
            </div>
            <Checkbox
              style={{ marginRight: '0.5rem' }}
              checked={nmap}
              onChange={(event) => setNmap(event.target.checked)}
            />
            <span> Check to enable NMAP's for buildings.</span>
          </Form>
        )}
      </Modal>
      <Notification />
    </div>
  );
};

export default SwitchOrganizationsModal;
