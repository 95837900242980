import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import SnackbarProvider from 'react-simple-snackbar';
import { ApolloProvider } from '@apollo/client';
import { ConfigProvider } from 'antd';
import querystring from 'query-string';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';

import Application from '@totem/components/Application';
import { themeData } from '@totem/styles/theme';

const initializeApplication = (store: any, client: any) => {
  const root = createRoot(document.getElementById('application'));
  root.render(
    <Provider store={store}>
      <BrowserRouter>
        <QueryParamProvider
          adapter={ReactRouter6Adapter}
          options={{
            searchStringToObject: querystring.parse,
            objectToSearchString: querystring.stringify,
          }}
        >
          <ApolloProvider client={client}>
            <SnackbarProvider>
              <ConfigProvider theme={themeData}>
                <Application />
              </ConfigProvider>
            </SnackbarProvider>
          </ApolloProvider>
        </QueryParamProvider>
      </BrowserRouter>
    </Provider>,
  );
};

const Root = {
  initializeApplication,
};

export default Root;
