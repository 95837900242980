import React, { useContext, useEffect, useState } from 'react';
import Timeline from 'react-calendar-timeline';

import RemoteAccessSessionContext from '@totem/components/remoteAccess/sessions/RemoteAccessSessionContext';

// make sure you include the timeline stylesheet or the timeline will not be styled
import 'react-calendar-timeline/lib/Timeline.css';

type chartGroup = {
  id: string;
  title: string;
  items: chartItem[];
};

type chartItem = {
  title: string;
  startTime: Date;
  endTime: Date;
};

const colors = [
  '#B0E0E6',
  '#ADD8E6',
  '#87CEFA',
  '#87CEEB',
  '#00BFFF',
  '#B0C4DE',
  '#1E90FF',
  '#6495ED',
  '#5F9EA0',
  '#4169E1',
];

const getDateKey = (val: Date) => {
  const month = `${val.getMonth() + 1}`.padStart(2, '0');
  const day = `${val.getDate()}`.padStart(2, '0');
  return `${val.getFullYear()}-${month}-${day}`;
};

const addItemToGroup = (
  groups: chartGroup[],
  user: string,
  group: string,
  point: chartItem,
) => {
  let found = false;
  const id = `${user}:${group}`;
  for (let idx = 0; idx < groups.length; idx++) {
    if (groups[idx].id === id) {
      groups[idx].items.push(point);
      found = true;
    }
  }
  if (!found) {
    groups.push({ id, title: user, items: [point] });
  }

  return groups;
};

const CalendarTimeline = () => {
  const onlyDrillDowns: boolean = false;
  const { sessionData, input } = useContext(RemoteAccessSessionContext);
  const [chartGroups, setChartGroups] = useState<any>([]);
  const [chartItems, setChartItems] = useState<any>([]);

  useEffect(() => {
    let data: chartGroup[] = [];
    for (let userIdx = 0; userIdx < sessionData.length; userIdx++) {
      for (
        let sessionIdx = 0;
        sessionIdx < sessionData[userIdx].sessions.length;
        sessionIdx++
      ) {
        if (sessionData[userIdx].sessions[sessionIdx].endTime !== null) {
          const startTime = new Date(
            sessionData[userIdx].sessions[sessionIdx].startTime,
          );
          const endTime = new Date(
            sessionData[userIdx].sessions[sessionIdx].endTime,
          );

          const point = {
            title: sessionData[userIdx].sessions[sessionIdx].groupDescription,
            startTime,
            endTime,
          };

          // eslint-disable-next-line max-depth
          if (!onlyDrillDowns || input.selectedDate === getDateKey(startTime)) {
            data = addItemToGroup(
              data,
              sessionData[userIdx].personEmail,
              sessionData[userIdx].sessions[sessionIdx].groupDescription,
              point,
            );
          }
        }
      }
    }

    const chartGroupsData = [];
    const chartItemsData = [];
    let itemCount = 1;
    let colorIndex = 0;
    for (let grpIdx = 0; grpIdx < data.length; grpIdx++) {
      chartGroupsData.push({ id: grpIdx + 1, title: data[grpIdx].title });
      for (let itemIdx = 0; itemIdx < data[grpIdx].items.length; itemIdx++) {
        chartItemsData.push({
          id: itemCount,
          group: grpIdx + 1,
          title: data[grpIdx].items[itemIdx].title,
          start_time: data[grpIdx].items[itemIdx].startTime,
          end_time: data[grpIdx].items[itemIdx].endTime,
          itemProps: {
            style: {
              background: colors[colorIndex],
            },
          },
        });
        itemCount = itemCount + 1;
        colorIndex = colorIndex + 1;
        if (colorIndex > 9) {
          colorIndex = 0;
        }
      }
    }

    setChartGroups(chartGroupsData);
    setChartItems(chartItemsData);
  }, [sessionData, input]);

  return (
    <div>
      {chartGroups.length > 0 && chartItems.length > 0 && (
        <Timeline
          stackItems
          sidebarWidth={250}
          groups={chartGroups}
          items={chartItems}
          defaultTimeStart={
            input.selectedDate !== ''
              ? new Date(`${input.selectedDate} 00:00:00`)
              : new Date(input.startTime)
          }
          defaultTimeEnd={
            input.selectedDate !== ''
              ? new Date(`${input.selectedDate} 23:59:59`)
              : new Date(input.endTime)
          }
        />
      )}
    </div>
  );
};

export default CalendarTimeline;
