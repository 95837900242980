import React from 'react';
import { useParams } from 'react-router-dom';

import NetworkContainer from '@totem/components/buildingDetail/network/networkContainer';
import ContentLayout from '@totem/components/ContentLayout';

import './network.css';
import NetworkGraphJSX from '@totem/components/buildingDetail/network/NetworkGraphJSX';

const NetworkPage = () => {
  const { id } = useParams();

  return (
    <ContentLayout pageTitle={'Network'} contentStyle={{ padding: 0 }}>
      <NetworkContainer buildingId={id}>
        <div styleName="content">
          <div styleName="content-container">
            <div styleName="main-pane">
              <NetworkGraphJSX graphType={'combo-combined'} />
            </div>
          </div>
        </div>
      </NetworkContainer>
    </ContentLayout>
  );
};

export default NetworkPage;
