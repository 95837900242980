import React, { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { Flex } from 'antd';

import { isNotNull } from '@totem/utilities/common';

import './totals.css';

type Props = {
  title: ReactNode;
  icon: ReactNode;
  value: string;
  valueColor?: string;
  backgroundColor?: string;
  link?: string;
};

const TotalValue = ({
  title,
  icon,
  value,
  valueColor,
  backgroundColor,
  link,
}: Props) => {
  const navigate = useNavigate();

  const styles = {
    title: {
      color: valueColor,
    },
    value: {
      color: valueColor,
    },
    container: {
      backgroundColor,
    },
  };

  const handleClick = () => {
    if (isNotNull(link)) {
      navigate(link);
    }
  };

  return (
    <Flex
      justify={'space-around'}
      align={'center'}
      style={styles.container}
      styleName="container"
    >
      <Flex vertical justify={'center'} align={'center'}>
        {icon}
      </Flex>
      <Flex vertical justify={'space-around'} align={'center'}>
        <div style={styles.title} styleName="title">
          {title}
        </div>
        <div style={styles.value} styleName="value" onClick={handleClick}>
          {value}
        </div>
      </Flex>
    </Flex>
  );
};

export default TotalValue;
