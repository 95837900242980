import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware, { END } from 'redux-saga';
import thunk from 'redux-thunk';

import rootReducer from '../reducers/rootReducer';

export function configureStore(initialState) {
  const sagaMiddleware = createSagaMiddleware();
  const middleware = [sagaMiddleware, thunk];

  const store = createStore(
    rootReducer,
    initialState,
    compose(applyMiddleware(...middleware)),
  );

  // @ts-ignore
  store.runSaga = sagaMiddleware.run;
  // @ts-ignore
  store.close = () => store.dispatch(END);

  return store;
}
