import React, { useContext } from 'react';
import { TablePaginationConfig } from 'antd/es/table';
import { ColumnProps } from 'antd/lib/table';
import { SorterResult } from 'antd/lib/table/interface';

import Table from '@totem/components/common/table/Table';
import { sortStringAscending } from '@totem/components/events/eventsContainer/utilities';
import { UserSession } from '@totem/components/remoteAccess/types';
import RemoteAccessUserDetailsContext from '@totem/components/remoteAccess/userDetails/RemoteAccessUserDetailsContext';
import { RemoteAccessUser } from '@totem/types/remoteAccessUsers';
import { formatDate } from '@totem/utilities/common';

import '../remoteAccess.css';
import { toDuration } from '@totem/utilities/timeUtilities';

const RemoteAccessSessionsTable = () => {
  const { input, setInput, sessionData } = useContext(
    RemoteAccessUserDetailsContext,
  );

  const columns: ColumnProps<UserSession>[] = [
    // {
    //   title: 'Group Name',
    //   dataIndex: 'groupName',
    //   key: 'groupName',
    //   sorter: (compA, compB) =>
    //     sortStringAscending(compA.groupName, compB.groupName),
    // },
    {
      title: 'Group',
      dataIndex: 'groupDescription',
      key: 'groupDescription',
      sorter: (compA, compB) =>
        sortStringAscending(compA.groupDescription, compB.groupDescription),
    },
    {
      title: 'End Point Title',
      dataIndex: 'endPointTitle',
      key: 'endPointTitle',
      sorter: (compA, compB) =>
        sortStringAscending(compA.endPointTitle, compB.endPointTitle),
    },
    {
      title: 'End Point Model',
      dataIndex: 'endPointModel',
      key: 'endPointModel',
      sorter: (compA, compB) =>
        sortStringAscending(compA.endPointModel, compB.endPointModel),
    },
    {
      title: 'End Point Version',
      dataIndex: 'endPointVersion',
      key: 'endPointVersion',
      sorter: (compA, compB) =>
        sortStringAscending(compA.endPointVersion, compB.endPointVersion),
    },
    {
      title: 'Start Time',
      dataIndex: 'startTime',
      key: 'startTime',
      render: date => formatDate(date),
      sorter: (compA, compB) =>
        sortStringAscending(compA.startTime, compB.startTime),
    },
    {
      title: 'End Time',
      dataIndex: 'endTime',
      key: 'endTime',
      render: date => formatDate(date),
      sorter: (compA, compB) =>
        sortStringAscending(compA.endTime, compB.endTime),
    },
    {
      title: 'Duration',
      dataIndex: 'duration',
      key: 'duration',
      render: (text: string, session: UserSession) => {
        if (
          session.startTime !== null &&
          session.endTime !== null &&
          session.startTime !== '' &&
          session.endTime !== ''
        ) {
          return toDuration(
            new Date(session.startTime),
            new Date(session.endTime),
          );
        }
        return '-';
      },
    },
  ];

  const getData = () => {
    if (
      typeof sessionData !== 'undefined' &&
      sessionData !== null &&
      typeof sessionData.sessions !== 'undefined' &&
      sessionData.sessions !== null
    ) {
      return sessionData.sessions;
    }
    return [];
  };

  const handleTableChange = (
    updatedPagination: TablePaginationConfig,
    filters: SorterResult<RemoteAccessUser>,
    sorter,
  ) => {
    const { ...params } = filters;

    let sortDir: string = sorter.order === 'descend' ? '-1' : '1';
    if (typeof sorter.order === 'undefined' || sorter.order === null) {
      if (
        typeof input.sessionSortDirection !== 'undefined' &&
        input.sessionSortDirection !== null
      ) {
        sortDir = input.sessionSortDirection;
      }
    }

    let sortField = input.sessionSortField
      ? input.sessionSortField
      : 'startTime';
    if (
      typeof sorter.field !== 'undefined' &&
      typeof sorter.order !== 'undefined'
    ) {
      sortField = sorter.field;
    }

    // @ts-ignore
    setInput({
      ...input,
      ...params,
      sessionPageSize: updatedPagination.pageSize,
      sessionPage: updatedPagination.current,
      sessionSortField: sortField,
      sessionSortDirection: sortDir,
    });
  };

  return (
    <Table
      showSorterTooltip
      columns={columns}
      dataSource={getData()}
      loading={input.loading}
      onChange={handleTableChange}
      pagination={{
        current: input.sessionPage,
        pageSize: input.sessionPageSize,
        total: getData().length,
        showSizeChanger: true,
      }}
      rowKey="startTime"
    />
  );
};

export default RemoteAccessSessionsTable;
