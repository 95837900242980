import React from 'react';
import { RightOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Collapse } from 'antd';
import * as R from 'ramda';

import { UPDATE_POLICY } from '@totem/graph/policies';
import { PolicyQuestion } from '@totem/types/policyAudit';

import PolicyUpdate from './PolicyUpdate';

import './policies.css';

const { Panel } = Collapse;

interface Props {
  id: string;
  question: PolicyQuestion;
}
const styles = {
  icon: {
    top: '2.8rem',
  },
};

const Question = ({ id, question }: Props) => {
  const { key, policy, resolution } = question;

  const [updatePolicy, { loading }] = useMutation(UPDATE_POLICY);

  const handleUpdate = (property: string, value: string) => {
    updatePolicy({
      variables: {
        policy: {
          id,
          questions: {
            [key]: { [property]: value },
          },
        },
      },
    });
  };

  return (
    <div styleName="policy-container">
      <Collapse
        bordered={false}
        expandIcon={({ isActive }) => (
          <RightOutlined rotate={isActive ? 90 : 0} style={styles.icon} />
        )}
      >
        <Panel
          header={
            <>
              <div styleName="policy-question-label">{question.label}</div>{' '}
              <div styleName="policy-data-container">
                <div styleName="policy-data">
                  <div styleName="policy-data-label">Nist Function</div>
                  <div styleName="policy-data-value">
                    {question.nistFunction}
                  </div>
                </div>
                <div styleName="policy-data">
                  <div styleName="policy-data-label">Level Of Effort</div>
                  <div styleName="policy-data-value">
                    {question.levelOfEffort}
                  </div>
                </div>
              </div>
            </>
          }
          key={question.key}
        >
          <div styleName="collapse-container">
            {!R.isNil(policy) && (
              <PolicyUpdate
                label="Policy"
                onChange={value => handleUpdate('policy', value)}
                loading={loading}
                defaultValue={policy}
              />
            )}
            {!R.isNil(resolution) && (
              <PolicyUpdate
                label="Resolution"
                onChange={value => handleUpdate('resolution', value)}
                loading={loading}
                defaultValue={resolution}
              />
            )}
          </div>
        </Panel>
      </Collapse>
    </div>
  );
};

export default Question;
