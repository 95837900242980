import React, { useCallback, useContext, useEffect, useState } from 'react';
import { PlusCircleOutlined } from '@ant-design/icons';
import { Button, Card, Checkbox, Input, Select, Tabs, TabsProps } from 'antd';
import { v4 as uuidv4 } from 'uuid';

import FormFieldLabel from '@totem/components/FormFieldLabel';
import SurveyTemplateConditionDetails from '@totem/components/surveyV2/templateDetails/SurveyTemplateConditionDetails';
import TemplateContext from '@totem/components/surveyV2/templateDetails/SurveyTemplateContext';
import SurveyTemplateQuestionOptions from '@totem/components/surveyV2/templateDetails/SurveyTemplateQuestionOptions';
import {
  Category,
  SurveyQuestion,
  SurveyTemplate,
} from '@totem/components/surveyV2/types';
import {
  LevelOfEffort,
  NistFunction,
  PolicyCategory,
  PolicyNames,
} from '@totem/components/surveyV2/utilities/SurveyEnumerations';
import TabTitle from '@totem/components/TabTitle';
import { Criticality } from '@totem/types/criticality';
import { getToken } from '@totem/utilities/accountUtilities';
import { criticalityMap } from '@totem/utilities/criticalityUtilities';
import { debounce } from '@totem/utilities/debounce';
import { V2_SURVEY_ENDPOINT } from '@totem/utilities/endpoints';

const styles = {
  selectInput: {
    width: 200,
  },
};

const DEBOUNCE_TIME = 750;

const isQuestionTypeScoreable = (question: SurveyQuestion): boolean => {
  switch (question.type) {
    case 'enumsingle':
    case 'enummultiple':
    case 'scoring':
      return true;
    default:
      return false;
  }
};

const isMultiInstanceCapable = (question: SurveyQuestion): boolean => {
  switch (question.type) {
    case 'date':
    case 'upload':
    case 'information':
      return false;
    default:
      return true;
  }
};

const canBeSetToRequired = (question: SurveyQuestion): boolean => {
  switch (question.type) {
    case 'information':
      return false;
    default:
      return true;
  }
};

const canHaveAdditionalContextOption = (question: SurveyQuestion): boolean => {
  switch (question.type) {
    case 'information':
      return false;
    default:
      return true;
  }
};

const hasOptions = (question: SurveyQuestion): boolean => {
  switch (question.type) {
    case 'enumsingle':
    case 'enummultiple':
    case 'scoring':
      return true;
    default:
      return false;
  }
};

type Props = {
  category: Category;
  question: SurveyQuestion;
  previous: SurveyQuestion;
  next: SurveyQuestion;
};

const SurveyTemplateQuestion = ({ category, question }: Props) => {
  const { surveyTemplateId, data, setData, setIsLoading } =
    useContext(TemplateContext);
  const [localState, setLocalState] = useState<SurveyQuestion>(question);
  const [questionUpdated, setQuestionUpdated] = useState<boolean>(false);

  const sendQuestionChanged = useCallback(
    debounce(
      (templateId: string, categoryId: string, update: SurveyQuestion) => {
        setIsLoading(true);

        const payload = {
          name: update.name,
          label: update.label,
          hintText: update.hint,
          isRequired: update.required,
          isScored: update.scored,
          requireAdditionalContext: update.requiresAdditionalContext,
          levels: update.levels,
          policy: update.policy,
          policyName: update.policyName,
          resolution: update.resolution,
          levelOfEffort: update.levelOfEffort,
          policyCategory: update.policyCategory,
          nistFunction: update.nistFunction,
          moreInfoLabel: update.moreInfoLabel,
        };

        fetch(
          `${V2_SURVEY_ENDPOINT}/template/${templateId}/categories/${categoryId}/questions/${update.id}`,
          {
            method: 'POST',
            headers: new Headers({
              Authorization: `Bearer ${getToken()}`,
            }),
            body: JSON.stringify(payload),
          },
        )
          .then((res) => res.json())
          .then((result: SurveyTemplate) => {
            setData(result);
          })
          .then(() => {
            setIsLoading(false);
          });
      },
      DEBOUNCE_TIME,
    ),
    [],
  );

  const sendQuestionOptionCreate = useCallback(
    async (templateId: string, categoryId: string, questionId: string) => {
      const payload = {
        action: 'add',
        option: {
          id: uuidv4(),
          label: 'New Option',
          score: {
            value: '',
            valueInt: 0,
            valueFloat: 0.0,
            valueArray: [],
          },
          recommendation: '',
          criticality: criticalityMap[Criticality.Info].value,
        },
      };

      fetch(
        `${V2_SURVEY_ENDPOINT}/template/${templateId}/categories/${categoryId}/questions/${questionId}/options`,
        {
          method: 'POST',
          headers: new Headers({
            Authorization: `Bearer ${getToken()}`,
          }),
          body: JSON.stringify(payload),
        },
      )
        .then((res) => res.json())
        .then((result: SurveyTemplate) => {
          setData(result);
        })
        .then(() => {
          setIsLoading(false);
        });
    },
    [],
  );

  useEffect(() => {
    if (questionUpdated) {
      setQuestionUpdated(false);
      sendQuestionChanged(surveyTemplateId, category.id, localState);
    }
  }, [localState]);

  const handleNewOption = () => {
    sendQuestionOptionCreate(surveyTemplateId, category.id, question.id);
  };

  const isScoreable = isQuestionTypeScoreable(question);
  const supportsOptions = hasOptions(question);
  const canBeRequired = canBeSetToRequired(question);
  const canHaveAdditionalContext = canHaveAdditionalContextOption(question);
  const isMultiInstance =
    category.multiInstance && isMultiInstanceCapable(question);

  const getQuestionTabItems = () => {
    const tabItems: TabsProps['items'] = [];

    if (supportsOptions) {
      tabItems.push({
        key: 'q_options',
        label: <TabTitle>Options</TabTitle>,
        children: (
          <>
            <Button icon={<PlusCircleOutlined />} onClick={handleNewOption}>
              New Option
            </Button>
            <br />
            <SurveyTemplateQuestionOptions
              category={category}
              question={question}
            />
          </>
        ),
      });
    }

    tabItems.push({
      key: 'q_conditions',
      label: <TabTitle>Conditions</TabTitle>,
      children: (
        <SurveyTemplateConditionDetails
          templateId={surveyTemplateId}
          categoryId={category.id}
          questionId={question.id}
          condition={question.condition}
        />
      ),
    });

    if (isScoreable) {
      tabItems.push({
        key: 'q_levelTypes',
        label: <TabTitle>Level Types</TabTitle>,
        children: <span>Level Types</span>,
      });
    }

    return tabItems;
  };

  const getPolicyOptions = () => {
    return PolicyNames.map((option) => {
      return {
        label: option.label,
        value: option.label,
      };
    });
  };

  const getPolicyCategoryOptions = () => {
    return PolicyCategory.map((option) => {
      return {
        label: option.label,
        value: option.label,
      };
    });
  };

  const getNistFunctionOptions = () => {
    return NistFunction.map((option) => {
      return {
        label: option.label,
        value: option.label,
      };
    });
  };

  return (
    <Card>
      <FormFieldLabel>Label</FormFieldLabel>
      <Input
        placeholder="Label"
        value={localState.label}
        onChange={({
          target: { value },
        }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
          setQuestionUpdated(true);
          setLocalState({ ...localState, label: value });
        }}
      />

      <FormFieldLabel>Hint Text</FormFieldLabel>
      <Input
        placeholder="Hint Text"
        value={localState.hint}
        onChange={({
          target: { value },
        }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
          setQuestionUpdated(true);
          setLocalState({ ...localState, hint: value });
        }}
      />

      {data.type === 'policyAudit' && (
        <>
          <FormFieldLabel>Policy</FormFieldLabel>
          <Input
            placeholder="Policy"
            value={localState.policy}
            onChange={({
              target: { value },
            }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
              setQuestionUpdated(true);
              setLocalState({ ...localState, policy: value });
            }}
          />

          <FormFieldLabel>Resolution</FormFieldLabel>
          <Input
            placeholder="Resolution"
            value={localState.resolution}
            onChange={({
              target: { value },
            }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
              setQuestionUpdated(true);
              setLocalState({ ...localState, resolution: value });
            }}
          />

          <FormFieldLabel>Policy Name</FormFieldLabel>
          <Select
            style={styles.selectInput}
            value={localState.policyName}
            options={getPolicyOptions()}
            onChange={(value) => {
              setQuestionUpdated(true);
              setLocalState({ ...localState, policyName: value });
            }}
          />

          <FormFieldLabel>Policy Category</FormFieldLabel>
          <Select
            style={styles.selectInput}
            value={localState.policyCategory}
            options={getPolicyCategoryOptions()}
            onChange={(value) => {
              setQuestionUpdated(true);
              setLocalState({ ...localState, policyCategory: value });
            }}
          />

          <FormFieldLabel>NIST Function</FormFieldLabel>
          <Select
            style={styles.selectInput}
            value={localState.nistFunction}
            options={getNistFunctionOptions()}
            onChange={(value) => {
              setQuestionUpdated(true);
              setLocalState({ ...localState, nistFunction: value });
            }}
          />

          <FormFieldLabel>Level of Effort</FormFieldLabel>
          <Select
            style={styles.selectInput}
            value={localState.levelOfEffort}
            options={LevelOfEffort}
            onChange={(value) => {
              setQuestionUpdated(true);
              setLocalState({ ...localState, levelOfEffort: value });
            }}
          />
        </>
      )}

      <br />
      <br />

      {canBeRequired && (
        <Checkbox
          checked={localState.required}
          onChange={() => {
            setQuestionUpdated(true);
            setLocalState({ ...localState, required: !localState.required });
          }}
        >
          Make Required
        </Checkbox>
      )}

      {isScoreable && (
        <Checkbox
          checked={localState.scored}
          onChange={() => {
            setQuestionUpdated(true);
            setLocalState({ ...localState, scored: !localState.scored });
          }}
        >
          Is Scored
        </Checkbox>
      )}

      {canHaveAdditionalContext && (
        <Checkbox
          checked={localState.requiresAdditionalContext}
          onChange={() => {
            setQuestionUpdated(true);
            setLocalState({
              ...localState,
              requiresAdditionalContext: !localState.requiresAdditionalContext,
            });
          }}
        >
          Requires Additional Context
        </Checkbox>
      )}

      {isMultiInstance && (
        <Checkbox
          checked={localState.instanceIdentifier}
          onChange={() => {
            setQuestionUpdated(true);
            setLocalState({
              ...localState,
              instanceIdentifier: !localState.instanceIdentifier,
            });
          }}
        >
          Instance Identifier
        </Checkbox>
      )}

      <br />
      <br />

      <Tabs tabPosition="left" items={getQuestionTabItems()} />
    </Card>
  );
};

export default SurveyTemplateQuestion;
