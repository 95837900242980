import * as R from 'ramda';

import {
  PolicyAudit,
  PolicyAuditError,
  PolicyAuditErrors,
  PolicyAuditTemplateQuestion,
  PolicyName,
  Question,
  QuestionGroup,
  QuestionKey,
  Questions,
  QuestionsUpdate,
  QuestionType,
  QuestionUpdate,
} from '@totem/types/policyAudit';
import { User } from '@totem/types/user';
import { getUserName } from '@totem/utilities/userUtilities';

// common audit question answers
const NO = 'No';
const YES = 'Yes';
const WINDOWS = 'Windows';
const LINUX = 'Linux';
const EMBEDDED = 'Embedded';
const UNKNOWN = 'Unknown';

const DEFAULT_MORE_INFO_LABEL = 'Provide further context.';

type DynamicQuestionProperties = {
  required?: boolean;
  visible?: boolean;
  moreInfoRequired?: boolean;
  moreInfoVisible?: boolean;
  moreInfoLabel?: string;
  moreInfo?: any;
  answer?: any;
};

type DynamicQuestionPropertiesMap = {
  [key in QuestionKey]?: (questions: Questions) => DynamicQuestionProperties;
};

export const getPolicyNameCounts = (
  questions: PolicyAuditTemplateQuestion[],
): { [key in PolicyName]?: number } =>
  questions.reduce((acc, { policyName }) => {
    const count = acc[policyName] ? acc[policyName] + 1 : 1;

    return {
      ...acc,
      [policyName]: count,
    };
  }, {});

export const getPolicyAuditStatus = (policyAudit: any): string => {
  if (R.isNil(policyAudit)) {
    return '';
  }

  const { submitted, completedQuestions, dueDate } = policyAudit;
  if (submitted) {
    return 'Submitted';
  }

  if (!submitted && dueDate && dueDate < Date.now()) {
    return 'Overdue';
  }

  if (!submitted && completedQuestions) {
    return 'In Progress';
  }

  if (!completedQuestions) {
    return 'Not Started';
  }

  return '';
};

export const isQuestionAnswered = ({
  type,
  answer,
  moreInfoRequired,
  moreInfo,
}: Question): boolean => {
  if (moreInfoRequired && (!moreInfo || !moreInfo?.value)) {
    return false;
  }

  switch (type) {
    case QuestionType.ShortText:
    case QuestionType.LongText:
    case QuestionType.Scoring:
    case QuestionType.EnumSingle: {
      return !R.isNil(answer) && !R.isEmpty(answer.value);
    }
    case QuestionType.EnumMultiple:
    case QuestionType.Upload: {
      return !R.isNil(answer) && !R.isEmpty(answer.value);
    }
    case QuestionType.Date: {
      // @ts-ignore
      return !R.equals(answer, 0);
    }
    default: {
      return false;
    }
  }
};

export const getGroupName = (group: QuestionGroup, questions: Questions) => {
  const { name } = group;

  const totalQuestions = group.questions.filter(
    key => questions[key]?.visible && questions[key]?.required,
  ).length;

  const completedQuestions = group.questions
    .filter(key => questions[key]?.visible && questions[key].required)
    .reduce((acc, id) => {
      return isQuestionAnswered(questions[id]) ? acc + 1 : acc;
    }, 0);

  return `${name} ${completedQuestions}/${totalQuestions}`;
};

export const groupHasError = (
  { questions }: QuestionGroup,
  errors: PolicyAuditErrors,
) => questions.some(key => Boolean(errors[key]));

export const validatePolicyAudit = ({
  questions,
}: PolicyAudit): PolicyAuditErrors => {
  return Object.keys(questions).reduce((acc, key) => {
    const error: PolicyAuditError = {};
    const question = questions[key];

    if (question.required && !isQuestionAnswered(question)) {
      error.answer = 'This question is required.';
    }

    if (question.moreInfoRequired && !question.moreInfo?.value) {
      error.moreInfo = 'More info is required.';
    }

    return R.isEmpty(error) ? acc : { ...acc, [key]: error };
  }, {});
};

export const isWindowsOrLinux = (questions: Questions): boolean => {
  const answer = questions.question36.answer?.value;
  return answer === WINDOWS || answer === LINUX;
};

export const isEmbedded = (questions: Questions): boolean => {
  const answer = questions.question36.answer?.value;
  return answer === EMBEDDED;
};

const dynamicQuestionPropertiesMap: DynamicQuestionPropertiesMap = {
  question3: ({ question2, question3 }) => {
    const isCurrentlyVisible = question3.visible;

    return question2.answer?.value === YES
      ? {
          required: true,
          visible: true,
          moreInfoVisible: true,
          moreInfoRequired: false,
          answer: isCurrentlyVisible ? question3.answer : null,
          moreInfo: isCurrentlyVisible ? question3.moreInfo : null,
        }
      : {
          required: false,
          visible: false,
          moreInfoVisible: false,
          moreInfoRequired: false,
          answer: { value: NO },
        };
  },
  question4: ({ question4 }) => {
    if (question4.answer?.value === YES) {
      return {
        moreInfoRequired: true,
        moreInfoLabel:
          'Please explain any hurdles to implementing and/or any timelines to addressing.',
      };
    }

    if (
      question4.answer?.value ===
      'Business operational needs require. Passwords are shared securely, tracked, and managed.'
    ) {
      return {
        moreInfoRequired: true,
        moreInfoLabel:
          'Please explain the business needs driving the use of shared passwords.',
      };
    }

    return { moreInfoRequired: false, moreInfoLabel: DEFAULT_MORE_INFO_LABEL };
  },
  question5: ({ question5 }) => {
    return question5.answer?.value === NO
      ? {
          moreInfoRequired: true,
          moreInfoLabel: 'Please explain details of how user list is managed.',
        }
      : {
          moreInfoRequired: false,
          moreInfoLabel: DEFAULT_MORE_INFO_LABEL,
        };
  },
  question6: ({ question6 }) => {
    return question6.answer?.value === NO
      ? {
          moreInfoRequired: true,
          moreInfoLabel:
            'Please describe how administrator access rights are being used in the space below.',
        }
      : {
          moreInfoRequired: false,
          moreInfoLabel: DEFAULT_MORE_INFO_LABEL,
        };
  },
  question8: ({ question8 }) => {
    return question8.answer?.value === NO
      ? {
          moreInfoRequired: true,
          moreInfoLabel:
            'Please explain where and why the password policy is not being followed.',
        }
      : {
          moreInfoRequired: false,
          moreInfoLabel: DEFAULT_MORE_INFO_LABEL,
        };
  },
  question9: ({ question9 }) => {
    return question9.answer?.value === NO
      ? {
          moreInfoRequired: true,
          moreInfoLabel: 'Please explain why there are active guest accounts.',
        }
      : {
          moreInfoRequired: false,
          moreInfoLabel: DEFAULT_MORE_INFO_LABEL,
        };
  },
  question10: ({ question10 }) => {
    return question10.answer?.value === NO
      ? {
          moreInfoRequired: true,
          moreInfoLabel:
            'Please explain why default passwords have not been changed.',
        }
      : {
          moreInfoRequired: false,
          moreInfoLabel: DEFAULT_MORE_INFO_LABEL,
        };
  },
  question11: ({ question11 }) => {
    return question11.answer?.value === NO
      ? {
          moreInfoRequired: true,
          moreInfoLabel:
            'Please explain why the manufacturer default account has not been removed.',
        }
      : {
          moreInfoRequired: false,
          moreInfoLabel: DEFAULT_MORE_INFO_LABEL,
        };
  },
  question12: ({ question12 }) => {
    return question12.answer?.value === NO
      ? {
          moreInfoRequired: true,
          moreInfoLabel:
            'Please explain why auto-lockout has not been configured.',
        }
      : {
          moreInfoRequired: false,
          moreInfoLabel: DEFAULT_MORE_INFO_LABEL,
        };
  },
  question14: ({ question14 }) => {
    return question14.answer?.value === NO
      ? {
          moreInfoRequired: true,
          moreInfoLabel:
            'Please explain why user activity logging policy is not being followed.',
        }
      : {
          moreInfoRequired: false,
          moreInfoLabel: DEFAULT_MORE_INFO_LABEL,
        };
  },
  question15: ({ question15 }) => {
    return question15.answer?.value === NO
      ? {
          moreInfoRequired: true,
          moreInfoLabel:
            'Please explain which protocols are not secure and why.',
        }
      : {
          moreInfoRequired: false,
          moreInfoLabel: DEFAULT_MORE_INFO_LABEL,
        };
  },
  question17: ({ question17 }) => {
    return question17.answer?.value === UNKNOWN
      ? {
          moreInfoRequired: true,
        }
      : {
          moreInfoRequired: false,
        };
  },
  question20: ({ question19 }) => {
    return question19.answer?.value === YES
      ? {
          visible: true,
          required: true,
          moreInfoVisible: true,
        }
      : {
          visible: false,
          required: false,
          moreInfoVisible: false,
          answer: null,
          moreInfo: null,
        };
  },
  question21: ({ question19, question21 }) => {
    const moreInfoRequired =
      question21.answer?.value === 'Other Remote Management Solution';

    return question19.answer?.value === NO
      ? {
          visible: true,
          required: true,
          moreInfoVisible: true,
          moreInfoLabel: moreInfoRequired
            ? 'Please enter solution name or describe.'
            : DEFAULT_MORE_INFO_LABEL,
          moreInfoRequired,
        }
      : {
          visible: false,
          required: false,
          moreInfoVisible: false,
          moreInfoLabel: DEFAULT_MORE_INFO_LABEL,
          moreInfoRequired: false,
          answer: null,
          moreInfo: null,
        };
  },
  question22: ({ question19, question23 }) => {
    const show = question19.answer?.value === NO;

    return {
      visible: show,
      moreInfoVisible: show,
      required: show && !Boolean(question23.answer?.value),
      ...(!show && { answer: null }),
      ...(!show && { moreInfo: null }),
    };
  },
  question23: ({ question19, question22 }) => {
    const show = question19.answer?.value === NO;

    return {
      visible: show,
      moreInfoVisible: show,
      required: show && !Boolean(question22.answer?.value),
      ...(!show && { answer: null }),
      ...(!show && { moreInfo: null }),
    };
  },
  question24: questions => {
    const show = isEmbedded(questions);

    return {
      visible: show,
      required: show,
      moreInfoVisible: show,
      ...(!show && { answer: null }),
      ...(!show && { moreInfo: null }),
    };
  },
  question25: questions => {
    const show = isWindowsOrLinux(questions);

    return {
      visible: show,
      required: show,
      moreInfoVisible: show,
      ...(!show && { answer: null }),
      ...(!show && { moreInfo: null }),
    };
  },
  question27: questions => {
    const { question27 } = questions;
    const show = isWindowsOrLinux(questions);

    const moreInfoRequired =
      question27.answer?.value === 'Responsibility is not clear';

    return {
      visible: show,
      required: show,
      moreInfoVisible: show,
      moreInfoRequired: show && moreInfoRequired,
      moreInfoLabel: moreInfoRequired
        ? 'Please provide any business context that contributes to the unclear responsibility.'
        : DEFAULT_MORE_INFO_LABEL,
      ...(!show && { answer: null }),
      ...(!show && { moreInfo: null }),
    };
  },
  question28: questions => {
    const { question28 } = questions;
    const show = isWindowsOrLinux(questions);

    const moreInfoRequired = question28.answer?.value === NO;

    return {
      visible: show,
      required: show,
      moreInfoVisible: show,
      moreInfoRequired: show && moreInfoRequired,
      moreInfoLabel: moreInfoRequired
        ? 'Please explain any hurdles to implementing and/or any timelines to addressing.'
        : DEFAULT_MORE_INFO_LABEL,
      ...(!show && { answer: null }),
      ...(!show && { moreInfo: null }),
    };
  },
  question29: questions => {
    const { question29 } = questions;
    const show = isWindowsOrLinux(questions);

    const moreInfoRequired = question29.answer?.value === YES;

    return {
      visible: show,
      required: show,
      moreInfoVisible: show,
      moreInfoRequired: show && moreInfoRequired,
      moreInfoLabel: moreInfoRequired
        ? 'Please describe what other functions operating system access is used for.'
        : DEFAULT_MORE_INFO_LABEL,
      ...(!show && { answer: null }),
      ...(!show && { moreInfo: null }),
    };
  },
  question30: questions => {
    const show = isWindowsOrLinux(questions);

    return {
      visible: show,
      required: show,
      moreInfoVisible: show,
      ...(!show && { answer: null }),
      ...(!show && { moreInfo: null }),
    };
  },
  question31: questions => {
    const { question31 } = questions;
    const show = isWindowsOrLinux(questions);

    const moreInfoRequired = question31.answer?.value === YES;

    return {
      visible: show,
      required: show,
      moreInfoVisible: show,
      moreInfoRequired: show && moreInfoRequired,
      moreInfoLabel: moreInfoRequired
        ? 'Please explain any hurdles to implementing and/or any timelines to addressing.'
        : DEFAULT_MORE_INFO_LABEL,
      ...(!show && { answer: null }),
      ...(!show && { moreInfo: null }),
    };
  },
  question32: questions => {
    const { question32 } = questions;
    const show = isWindowsOrLinux(questions);

    const moreInfoRequired = question32.answer?.value === YES;

    return {
      visible: show,
      required: show,
      moreInfoVisible: show,
      moreInfoRequired: show && moreInfoRequired,
      moreInfoLabel: moreInfoRequired
        ? 'Please provide any business context that contributes to why system is not in a secure environment.'
        : DEFAULT_MORE_INFO_LABEL,
      ...(!show && { answer: null }),
      ...(!show && { moreInfo: null }),
    };
  },
  question37: questions => {
    const show = isWindowsOrLinux(questions);

    return {
      visible: show,
      required: show,
    };
  },
  question38: ({ question24 }) => {
    const show = question24.answer?.value === YES;

    return {
      visible: show,
      required: show,
      moreInfoVisible: show,
      ...(!show && { answer: null }),
      ...(!show && { moreInfo: null }),
    };
  },
};

export const updateDynamicQuestionFields = (questions: Questions) =>
  Object.keys(questions).reduce((acc, key) => {
    const question = questions[key];

    return {
      ...acc,
      [key]: dynamicQuestionPropertiesMap[key]
        ? {
            ...question,
            ...dynamicQuestionPropertiesMap[key](questions),
          }
        : question,
    };
  }, {});

export const updateQuestionsWithAnswer = (
  questions: Questions,
  key: QuestionKey,
  value: any,
  user: User,
): Questions => {
  const question = questions[key];

  if (!question) {
    return { ...questions };
  }

  const withUpdatedAnswer = {
    ...questions,
    [key]: {
      ...question,
      answer: {
        value,
        updatedAt: new Date(),
        updatedBy: {
          name: getUserName(user),
          imageUrl: user.imageUrl,
        },
      },
    },
  };

  return updateDynamicQuestionFields(withUpdatedAnswer);
};

export const updateQuestionsWithMoreInfo = (
  questions: Questions,
  key: QuestionKey,
  value: string,
): Questions => {
  const question = questions[key];

  if (!question) {
    return { ...questions };
  }

  const moreInfo = question.moreInfo || {};

  return {
    ...questions,
    [key]: {
      ...question,
      moreInfo: {
        ...moreInfo,
        value,
      },
    },
  };
};

export const getPatchFromQuestionsUpdate = (
  prevQuestions: Questions,
  questions: Questions,
): QuestionsUpdate => {
  return Object.keys(prevQuestions).reduce((acc, key) => {
    const prevQuestion: Question = prevQuestions[key];
    const question: Question = questions[key];
    const patch: QuestionUpdate = {};

    if (prevQuestion.required !== question.required) {
      patch.required = question.required;
    }

    if (prevQuestion.visible !== question.visible) {
      patch.visible = question.visible;
    }

    if (prevQuestion.moreInfoRequired !== question.moreInfoRequired) {
      patch.moreInfoRequired = question.moreInfoRequired;
    }

    if (prevQuestion.moreInfoVisible !== question.moreInfoVisible) {
      patch.moreInfoVisible = question.moreInfoVisible;
    }

    if (prevQuestion.moreInfoLabel !== question.moreInfoLabel) {
      patch.moreInfoLabel = question.moreInfoLabel;
    }

    if (
      !R.equals(prevQuestion.answer, question.answer) &&
      (!R.isNil(prevQuestion.answer) || !R.isNil(question.answer))
    ) {
      patch.answer = R.isNil(question.answer)
        ? null
        : { value: question.answer.value };
    }

    if (
      !R.equals(prevQuestion.moreInfo, question.moreInfo) &&
      (!R.isNil(prevQuestion.moreInfo) || !R.isNil(prevQuestion.moreInfo))
    ) {
      patch.moreInfo = R.isNil(question.moreInfo)
        ? null
        : { value: question.moreInfo.value };
    }

    return {
      ...acc,
      ...(!R.isEmpty(patch) && {
        [key]: patch,
      }),
    };
  }, {});
};
