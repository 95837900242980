import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Modal } from 'antd';
import * as R from 'ramda';

import ModalFooter from '@totem/components/ModalFooter';
import ModalTitle from '@totem/components/ModalTitle';
import { RemoteAccessAuthTenantRole } from '@totem/types/remoteAccess';

const FormItem = Form.Item;

interface FormErrors {
  roleName?: string;
  roleDescription?: string;
}

export interface Props {
  title: string;
  onClose: () => void;
  onSubmit: (role: RemoteAccessAuthTenantRole) => void;
  visible: boolean;
  loading: boolean;
}

const styles = {
  form: {
    width: '100%',
  },
  formItem: {
    paddingBottom: '0',
    marginBottom: '1rem',
  },
};

const roleInitialState: RemoteAccessAuthTenantRole = {
  roleId: '',
  name: '',
  description: '',
  buildingId: '',
  regionId: '',
  controlSystemId: '',
};

export const isValidForm = (role: RemoteAccessAuthTenantRole): FormErrors => {
  const formErrors: FormErrors = {};

  if (R.isEmpty(role.name)) {
    formErrors.roleName = 'Please enter a name fo the new role.';
  }

  return formErrors;
};

const RemoteAccessRoleAddModal = (props: Props) => {
  const { title, visible, loading, onClose, onSubmit } = props;

  const [role, setRole] =
    useState<RemoteAccessAuthTenantRole>(roleInitialState);
  const [errors, setErrors] = useState<FormErrors>({});

  useEffect(() => {
    if (!visible) {
      setErrors({});
      setRole(roleInitialState);
    }
  }, [visible]);

  const handleChange = ({
    target: { value, name },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setRole({ ...role, [name]: value });
  };

  const handleSubmit = () => {
    const formErrors = isValidForm(role);

    if (R.isEmpty(formErrors)) {
      onSubmit(role);
    }

    setErrors(formErrors);
  };

  return (
    <Modal
      open={visible}
      onCancel={onClose}
      title={<ModalTitle>{title}</ModalTitle>}
      okText="Add"
      confirmLoading={loading}
      footer={
        <ModalFooter>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={handleSubmit} loading={loading} type="primary">
            Add
          </Button>
        </ModalFooter>
      }
    >
      <Form layout="vertical" style={styles.form}>
        <FormItem
          label="Role Name"
          colon={false}
          style={styles.formItem}
          validateStatus={R.isNil(errors.roleName) ? 'success' : 'error'}
          help={R.isNil(errors.roleName) ? null : errors.roleName}
        >
          <Input
            name="name"
            value={role.name}
            onChange={handleChange}
            type="search"
          />
        </FormItem>
        <FormItem
          label="Role Description"
          colon={false}
          style={styles.formItem}
          validateStatus={R.isNil(errors.roleDescription) ? 'success' : 'error'}
          help={R.isNil(errors.roleDescription) ? null : errors.roleDescription}
        >
          <Input
            name="description"
            value={role.description}
            onChange={handleChange}
            type="search"
          />
        </FormItem>
      </Form>
    </Modal>
  );
};

export default RemoteAccessRoleAddModal;
