import { AcronisMachine } from '@totem/types/acronis';

import { APIKeysConnection } from './apiKey';
import { ControlSystemBackupsConnection } from './controlSystemBackup';
import { NotesConnection } from './note';
import { PolicyAudit } from './policyAudit';
import { User } from './user';

export enum ControlSystemType {
  Unset = 0,
  BuildingAutomation = 1,
  LightingControl = 2,
  Elevator = 3,
  PowerManagement = 4,
  AccessControl = 5,
  VideoSurveillance = 6,
  IntrusionDetection = 7,
  Metering = 8,
  Irrigation = 9,
  FuelManagement = 10,
  LeakDetection = 11,
  Parking = 12,
  Other = 13,
  SecuritySystem = 14,
  VendorSystemNetwork = 15,
  BuildingNetwork = 16,
  IncomingServiceISP = 17,
}

export enum BackupStatus {
  BACKED_UP = 'BACKED_UP',
  BACKED_UP_EXPIRED = 'BACKED_UP_EXPIRED',
  NOT_BACKED_UP = 'NOT_BACKED_UP',
  UNKNOWN = 'UNKNOWN',
}

export enum ConnectionType {
  NIAGARA = 'NIAGARA',
}

export type ControlSystem = {
  id?: string;
  organization?: {
    id: string;
    name: string;
  };
  region?: {
    id: string;
    name: string;
  };
  building?: {
    id: string;
    name: string;
  };
  name: string;
  acronisMachines?: AcronisMachine[];
  systemType: ControlSystemType;
  manufacturer?: string;
  model?: string;
  backupStatus: BackupStatus;
  backups: ControlSystemBackupsConnection;
  notes: NotesConnection;
  createdBy: User;
  updatedBy: User;
  createdAt: string;
  updatedAt: string;
  policyAudit?: PolicyAudit;
  apiKeys?: APIKeysConnection;
  __typename?: 'ControlSystem';
};

export type ControlSystemsConnection = {
  totalCount: number;
  controlSystems: ControlSystem[];
};

export type ControlSystemsConnectionInput = {
  limit?: number;
  offset?: number;
  name?: string;
  regionId?: string[];
  buildingId?: string[];
  systemType?: number[];
  isBackedUp?: boolean;
};

export type ControlSystemCreateInput = {
  regionId: string;
  buildingId: string;
  systemType: ControlSystemType;
  name: string;
  model?: string;
  manufacturer?: string;
  dueDate?: number;
  createAudit?: boolean;
};

export type ControlSystemUpdateInput = {
  id: string;
  systemType: ControlSystemType;
  name: string;
  model?: string;
  manufacturer?: string;
  acronisMachineIds?: string[];
  dueDate?: number;
  createAudit?: boolean;
};

export type ControlSystemApiKeyCreateInput = {
  controlSystemId: string;
  name: string;
  connectionType: ConnectionType;
};
