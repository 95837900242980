import React from 'react';

import WorkPanel from '@totem/components/surveyV2/work/WorkPanel';

const WidgetAuditsAndSurveys = () => {
  return (
    <WorkPanel />
  );
};

export default WidgetAuditsAndSurveys;
