import dayjs from 'dayjs';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';

import {AggregateInfo, DateRange, MetricInfo} from '@totem/components/energy/types';

dayjs.extend(quarterOfYear);

export const calculateDatesFromRange = (rangeIn: DateRange): DateRange => {
  switch (rangeIn.dateRangeType) {
    case 'Today': {
      const start = dayjs()
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0);
      const end = dayjs();

      return {
        dateRangeType: rangeIn.dateRangeType,
        startDate: start.toISOString(),
        endDate: end.toISOString(),
      };
    }
    case 'Last 7 Days': {
      const start = dayjs().add(-6, 'day');
      const end = dayjs();

      return {
        dateRangeType: rangeIn.dateRangeType,
        startDate: start.toISOString(),
        endDate: end.toISOString(),
      };
    }
    case 'Last 4 Weeks': {
      const start = dayjs();
      const end = dayjs().add(-4, 'week');

      return {
        dateRangeType: rangeIn.dateRangeType,
        startDate: start.toISOString(),
        endDate: end.toISOString(),
      };
    }
    case 'Last 3 Months': {
      const start = dayjs().add(-3, 'month');
      const end = dayjs();

      return {
        dateRangeType: rangeIn.dateRangeType,
        startDate: start.toISOString(),
        endDate: end.toISOString(),
      };
    }
    case 'Last 12 Months': {
      const start = dayjs().add(-12, 'month');
      const end = dayjs();

      return {
        dateRangeType: rangeIn.dateRangeType,
        startDate: start.toISOString(),
        endDate: end.toISOString(),
      };
    }
    case 'Month to Date': {
      const start = dayjs()
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0)
        .day(0);
      const end = dayjs();

      return {
        dateRangeType: rangeIn.dateRangeType,
        startDate: start.toISOString(),
        endDate: end.toISOString(),
      };
    }
    case 'Quarter to Date': {
      const start = dayjs().startOf('quarter');
      const end = dayjs();

      return {
        dateRangeType: rangeIn.dateRangeType,
        startDate: start.toISOString(),
        endDate: end.toISOString(),
      };
    }
    case 'Year to Date': {
      const start = dayjs()
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0)
        .month(0)
        .day(0);
      const end = dayjs();

      return {
        dateRangeType: rangeIn.dateRangeType,
        startDate: start.toISOString(),
        endDate: end.toISOString(),
      };
    }
    default:
      return {
        dateRangeType: 'Custom',
        startDate: rangeIn.startDate,
        endDate: rangeIn.endDate,
      };
  }
};

export const calculateAggregate = (rangeIn: DateRange): AggregateInfo => {
  switch (rangeIn.dateRangeType) {
    case 'Today':
      return { aggregate: 'hour', aggregateLabel: 'Hourly' };
    case 'Last 7 Days': {
      return { aggregate: 'hour', aggregateLabel: 'Hourly' };
    }
    case 'Last 4 Weeks': {
      return { aggregate: 'hour', aggregateLabel: 'Hourly' };
    }
    case 'Last 3 Months': {
      return { aggregate: 'day', aggregateLabel: 'Daily' };
    }
    case 'Last 12 Months': {
      return { aggregate: 'month', aggregateLabel: 'Monthly' };
    }
    case 'Month to Date': {
      return { aggregate: 'hour', aggregateLabel: 'Hourly' };
    }
    case 'Quarter to Date': {
      return { aggregate: 'hour', aggregateLabel: 'Hourly' };
    }
    case 'Year to Date': {
      return { aggregate: 'month', aggregateLabel: 'Monthly' };
    }
    default: {
      const start = dayjs(rangeIn.startDate);
      const end = dayjs(rangeIn.endDate);
      const diff = end.diff(start, 'day');
      if (diff <= 30) {
        return { aggregate: 'hour', aggregateLabel: 'Hourly' };
      } else if (diff <= 120) {
        return { aggregate: 'day', aggregateLabel: 'Daily' };
      }
      return { aggregate: 'month', aggregateLabel: 'Monthly' };
    }
  }
};

export const getMetricDisplay = (metric: string): MetricInfo => {
  switch (metric) {
    case 'generated_carbon_rate':
      return { name: metric, display: 'Generated Carbon Rate', unit: '' };
    case 'consumed_carbon_rate':
      return { name: metric, display: 'Consumed Carbon Rate', unit: '' };
    case 'carbon_emissions':
      return { name: metric, display: 'Carbon Emissions', unit: 'lbs' };
    case 'marginal_carbon_rate':
      return { name: metric, display: 'Marginal Carbon Rate', unit: '' };
    case 'energy':
      return { name: metric, display: 'Energy', unit: 'kWh' };
    case 'power':
      return { name: metric, display: 'Power', unit: 'mW' };
    case 'voltage':
      return { name: metric, display: 'Voltage', unit: 'V' };
    case 'current':
      return { name: metric, display: 'Current', unit: '' };
    case 'apparent_power':
      return { name: metric, display: 'Apparent Power', unit: 'kVA' };
    case 'reactive_power':
      return { name: metric, display: 'Reactive Power', unit: 'kVAR' };
    case 'frequency':
      return { name: metric, display: 'Frequency', unit: 'Hz' };
    case 'power_factor':
      return { name: metric, display: 'Power Factor', unit: '' };
    default:
      return { name: metric, display: '', unit: '' };
  }
};

export const getDefaultDateRange = (rangeIn: DateRange) => {
  if (
    rangeIn.dateRangeType === 'Custom' &&
    dayjs(rangeIn.startDate).isValid() &&
    dayjs(rangeIn.endDate).isValid()
  ) {
    return rangeIn;
  }

  return calculateDatesFromRange({
    dateRangeType: 'Last 7 Days',
    startDate: '',
    endDate: '',
  });
};
