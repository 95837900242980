import React, { useContext, useEffect, useState } from 'react';
import { Button, Input, Modal } from 'antd';
import * as R from 'ramda';

import DetailSpinner from '@totem/components/common/DetailSpinner';
import UserProfileContext from '@totem/components/UserProfileContext';
import {
  buildMailtoLink,
  initialSelectedEmails,
} from '@totem/selectors/auditAssigneesSelectors';
import { Assignee } from '@totem/types/user';

import AuditAssigneeRow from './AuditAssigneeRow';

import './index.css';

const { Search } = Input;

const styles = {
  bodyStyle: {
    padding: 0,
  },
  okButton: {
    marginLeft: '10px',
  },
  input: {
    width: 200,
  },
};

type Props = {
  assignees: Assignee[];
  loading: boolean;
};

const AuditEmailModal = ({ assignees, loading }: Props) => {
  const { userProfile } = useContext(UserProfileContext);

  const [search, setSearch] = useState<string>('');
  const [visible, setVisible] = useState<boolean>(false);
  const [selectedEmails, setSelectedEmails] = useState<Set<string>>(
    initialSelectedEmails(assignees),
  );
  const [filteredAssignees, setFilteredAssignees] = useState<Assignee[]>(
    assignees,
  );

  useEffect(() => {
    setFilteredAssignees(assignees);
    setSelectedEmails(initialSelectedEmails(assignees));
    setSearch('');
  }, [assignees, visible]);

  useEffect(() => {
    setFilteredAssignees(
      assignees.filter(
        assignee =>
          !R.isEmpty(R.match(new RegExp(search, 'i'), assignee.email)),
      ),
    );
  }, [search, assignees]);

  const toggleEmail = (email: string) => {
    if (selectedEmails.has(email)) {
      selectedEmails.delete(email);
      setSelectedEmails(new Set([...selectedEmails]));
    } else {
      setSelectedEmails(new Set([...selectedEmails.add(email)]));
    }
  };

  return (
    <>
      <Button onClick={() => setVisible(true)}>Send Email</Button>
      <Modal
        title="Select Assignees"
        open={visible}
        onOk={() => setVisible(true)}
        onCancel={() => setVisible(false)}
        confirmLoading={loading}
        okText="Go to Email"
        bodyStyle={styles.bodyStyle}
        okButtonProps={{
          href: buildMailtoLink(userProfile, selectedEmails),
          target: '_blank',
          style: styles.okButton,
        }}
      >
        {loading ? (
          <DetailSpinner />
        ) : (
          <div>
            <div styleName="search-bar">
              <Search
                placeholder="Search Email"
                value={search}
                onChange={event => setSearch(event.target.value)}
                style={styles.input}
              />
              <div
                styleName="clear-button"
                onClick={() => setSelectedEmails(new Set([]))}
              >{`Clear Selected (${selectedEmails.size})`}</div>
            </div>
            <div styleName="email-list">
              {filteredAssignees.map(assignee => (
                <AuditAssigneeRow
                  key={assignee.email}
                  assignee={assignee}
                  selectedEmails={selectedEmails}
                  toggleEmail={toggleEmail}
                />
              ))}
            </div>
          </div>
        )}
      </Modal>
    </>
  );
};

export default AuditEmailModal;
