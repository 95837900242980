import React, { useMemo } from 'react';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { ColumnProps, TablePaginationConfig } from 'antd/lib/table';
import { SorterResult } from 'antd/lib/table/interface';

import Table from '@totem/components/common/table/Table';
import Tooltip from '@totem/components/common/tooltip/Tooltip';
import {
  AddEditCustomer,
  Customer,
  CustomersConnectionInput,
  DeleteCustomer,
} from '@totem/types/customer';

import './customers.css';

export interface Props {
  customers: Customer[];
  pagination: TablePaginationConfig;
  loading: boolean;
  input: CustomersConnectionInput;
  onEditCustomerClick: (customer: AddEditCustomer) => void;
  onDeleteCustomerClick: (customer: DeleteCustomer) => void;
  onChange: (
    pagination: TablePaginationConfig,
    sort: SorterResult<Customer>,
  ) => void;
}

const CustomersTable = ({
  customers,
  pagination,
  loading,
  input,
  onEditCustomerClick,
  onDeleteCustomerClick,
  onChange,
}: Props) => {
  // eslint-disable-next-line no-shadow
  const handleChange = (pagination, filter, sorter) => {
    onChange(pagination, sorter);
  };

  const dataSource = useMemo(
    () =>
      customers.map((customer) => {
        return {
          key: customer.id,
          ...customer,
        };
      }),
    [customers],
  );

  const columns: ColumnProps<Customer>[] = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 200,
      sorter: true,
      // @ts-ignore
      sortOrder: input.sortBy === 'name' && input.sortOrder,
      render: (name) => {
        return <div styleName="table-cell">{name}</div>;
      },
    },
    {
      title: 'Company',
      dataIndex: 'domain',
      key: 'domain',
      width: 200,
      render: (domain) => {
        return <div styleName="table-cell">{domain}</div>;
      },
    },
    // {
    //   title: 'Buildings',
    //   dataIndex: 'buildings',
    //   key: 'buildings',
    //   width: 100,
    // render: buildings => {
    //   return <div styleName="table-cell">{buildings.totalCount}</div>;
    // },
    // },
    // {
    //   title: 'Control Systems',
    //   dataIndex: 'controlSystems',
    //   key: 'controlSystems',
    //   width: 100,
    // render: controlSystems => {
    //   return <div styleName="table-cell">{controlSystems.totalCount}</div>;
    // },
    // },
    {
      title: 'Actions',
      width: 50,
      dataIndex: 'additionalOptions',
      key: 'additionalOptions',
      align: 'center',
      render: (value, record) => {
        // todo: specify access
        // const disabled = !authUtilities.minimumRequiredRole(
        //   authUtilities.ROLE_SETS.TEAM_MEMBER,
        // );

        return (
          <div styleName="table-cell-icons">
            <Tooltip title="Edit" placement="left">
              <Button
                styleName="edit-button"
                // disabled={disabled}
                type="primary"
                shape="circle"
                icon={<EditOutlined />}
                onClick={() => onEditCustomerClick(record)}
              />
            </Tooltip>
            <Tooltip title="Delete" placement="right">
              <Button
                // disabled={disabled}
                type="default"
                shape="circle"
                icon={<DeleteOutlined />}
                onClick={() => onDeleteCustomerClick(record)}
              />
            </Tooltip>
          </div>
        );
      },
    },
  ];

  return (
    <Table
      columns={columns}
      loading={loading}
      dataSource={dataSource}
      pagination={pagination}
      onChange={handleChange}
    />
  );
};

export default CustomersTable;
