import React from 'react';
import { Tabs, TabsProps } from 'antd';

import WorkTableCompleted from '@totem/components/surveyV2/work/WorkTableCompleted';
import WorkTableIncomplete from '@totem/components/surveyV2/work/WorkTableIncomplete';
import TabTitle from '@totem/components/TabTitle';

const styles = {
  tabContainer: {
    paddingRight: '2rem',
    paddingLeft: '2rem',
  },
};

const WorkTabs = () => {
  const tabItems: TabsProps['items'] = [
    {
      key: 'incomplete',
      label: <TabTitle>Incomplete</TabTitle>,
      children: <WorkTableIncomplete />,
    },
    {
      key: 'complete',
      label: <TabTitle>Complete</TabTitle>,
      children: <WorkTableCompleted />,
    },
  ];

  return (
    <Tabs
      defaultActiveKey="incomplete"
      items={tabItems}
      style={styles.tabContainer}
    />
  );
};

export default WorkTabs;
