import { SummaryInput } from '@totem/components/portfolio/summary/types';
import { Filter, Params } from '@totem/types/common';

export const buildRegionBuildingSummaryFilters = (input: SummaryInput) => {
  const paramFilters: Filter[] = [];

  if (
    typeof input.regionId !== 'undefined' &&
    input.regionId !== null &&
    input.regionId.length > 0
  ) {
    paramFilters.push({
      field: '_id',
      values: input.regionId,
    });
  }
  if (
    typeof input.buildingId !== 'undefined' &&
    input.buildingId !== null &&
    input.buildingId.length > 0
  ) {
    paramFilters.push({
      field: 'buildings._id',
      values: input.buildingId,
    });
  }

  return paramFilters;
};

export const buildParameters = (input: SummaryInput) => {
  const params: Params = {
    paging: {
      page: 1,
      pageSize: 50000,
    },
    sort: {
      field: '',
      direction: 1,
    },
    filters: buildRegionBuildingSummaryFilters(input),
  };

  return params;
};
