import * as React from 'react';

import './score.css';

type Props = {
  totalScore: number;
  maxScore: number;
};

const A_GRADE = '#73967c';
const B_GRADE = '#82a58b';
const C_GRADE = '#9ab19f';
const D_GRADE = 'rgba(127, 21, 10, 0.8)';
const F_GRADE = '#7f150a';
const DEFAULT_GRADE = '#F5F5F5';

const getGradeColor = (totalScore: number, maxScore: number): string => {
  if (maxScore === 0 || !Number(maxScore)) {
    return DEFAULT_GRADE;
  }

  const score = totalScore / maxScore;

  if (score < 0.6) {
    return F_GRADE;
  }

  if (score >= 0.6 && score < 0.7) {
    return D_GRADE;
  }

  if (score >= 0.7 && score < 0.8) {
    return C_GRADE;
  }

  if (score >= 0.8 && score < 0.9) {
    return B_GRADE;
  }

  if (score >= 0.9) {
    return A_GRADE;
  }

  return DEFAULT_GRADE;
};

const ResultsBar = ({ totalScore, maxScore }: Props) => {
  const width = ((totalScore / maxScore) * 100).toFixed(0);
  const color = getGradeColor(totalScore, maxScore);

  return (
    <div styleName="results-bar-container">
      <div
        style={{
          // @ts-ignore
          width: `${!isNaN(width) ? width : 0}%`,
          backgroundColor: color,
          height: '100%',
        }}
      />
    </div>
  );
};

export default ResultsBar;
