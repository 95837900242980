import { gql } from '@apollo/client';

export const NOTE_FRAGMENT = gql`
  fragment NoteDetail on Note {
    id
    title
    body
    createdAt
    createdBy {
      id
      email
      firstName
      lastName
    }
    updatedAt
    updatedBy {
      id
      email
      firstName
      lastName
    }
  }
`;

export const GET_NOTES = gql`
  query GetNotes($input: NotesConnectionInput!) {
    notes(input: $input) {
      totalCount
      notes {
        ...NoteDetail
      }
    }
  }
  ${NOTE_FRAGMENT}
`;

export const CREATE_NOTE = gql`
  mutation CreateNote($input: NoteCreateInput!) {
    noteCreate(input: $input) {
      ...NoteDetail
    }
  }
  ${NOTE_FRAGMENT}
`;

export const UPDATE_NOTE = gql`
  mutation UpdateNote($input: NoteUpdateInput!) {
    noteUpdate(input: $input) {
      ...NoteDetail
    }
  }
  ${NOTE_FRAGMENT}
`;

export const DELETE_NOTE = gql`
  mutation DeleteNote($id: ID!) {
    noteDelete(id: $id)
  }
`;
