export const TOTAL_COUNT_HEADER = 'x-totem-total';

export const DEFAULT_HEADERS = {
  'content-type': 'application/json',
  accept: 'application/json',
  'x-totem-device': 'desktop',
};

export const DEFAULT_OPTIONS = {
  headers: DEFAULT_HEADERS,
};
