import React, { useState } from 'react';
import { DownloadOutlined } from '@ant-design/icons';
import { Button } from 'antd';

import { Params } from '@totem/types/common';
import api from '@totem/utilities/api';
import { V2_SURVEY_ENDPOINT } from '@totem/utilities/endpoints';
import fileUtilities from '@totem/utilities/fileUtilities';

interface Props {
  surveyId: string;
}

const ExportSurveyButton = ({ surveyId }: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const buildParameters = () => {
    const params: Params = {
      paging: {
        page: 1,
        pageSize: 10000,
      },
      sort: {
        field: 'name',
        direction: 1,
      },
      filters: [
        {
          field: '_id',
          values: [surveyId],
        },
      ],
    };

    return params;
  };

  const exportData = async (level: string) => {
    setIsLoading(true);

    const params = buildParameters();

    const { data } = await api.post(
      `${V2_SURVEY_ENDPOINT}/export/${level}`,
      params,
    );
    fileUtilities.downloadFile(`survey_${level}_${surveyId}`, data);
    setIsLoading(false);
  };

  return (
    <Button
      loading={isLoading}
      icon={<DownloadOutlined />}
      onClick={() => exportData('details')}
    >
      Export
    </Button>
  );
};

export default ExportSurveyButton;
