import React from 'react';

import StackedBarChart from '@totem/components/charts/StackedBar';
import { TicketCount } from '@totem/components/controlSystemReport/dataTypes';
import { GraphMultiIntSeries } from '@totem/components/events/graphs/types';
import { sortStringAscending } from '@totem/utilities/tableUtilities';
import { getTicketColor } from '@totem/utilities/ticketing';

type Props = {
  controlSystemId: string;
  ticketSummary: TicketCount[];
};
const TicketChart = ({ ticketSummary, controlSystemId }: Props) => {
  const getSeries = (): GraphMultiIntSeries => {
    const gmis = {
      x: [],
      series: ticketSummary
        .sort((compA, compB) => sortStringAscending(compA.type, compB.type))
        .map((evt) => {
          return {
            name: evt.type,
            y: [evt.count],
          };
        }),
    };

    return gmis;
  };

  const getColors = (): string[] => {
    const colorArray = ticketSummary
      .sort((compA, compB) => sortStringAscending(compA.type, compB.type))
      .map((evt) => {
        return getTicketColor(evt.type);
      });
    return colorArray;
  };

  // eslint-disable-next-line id-length
  const handleClick = (dataPointIndex: number) => {
    if (dataPointIndex >= 0) {
      const ticketEntry = ticketSummary.sort((compA, compB) =>
        sortStringAscending(compA.type, compB.type),
      )[dataPointIndex];

      window.open(
        `/dashboard/ticketing?status=OPEN&status=RESOLVED&type=${ticketEntry.type}&controlSystemId=${controlSystemId}`,
        `tickets.${controlSystemId}.${ticketEntry.type}`,
      );
    }
  };

  return (
    <StackedBarChart
      onItemClicked={handleClick}
      height="55px"
      width="200px"
      series={getSeries()}
      chartOptions={{
        colors: getColors(),
        dataLabels: {
          style: {
            fontSize: '10px',
          },
        },
        tooltip: {
          x: {
            show: false,
          },
        },
        grid: {
          show: false,
          yaxis: {
            lines: {
              show: false,
            },
          },
          padding: {
            top: -5,
            bottom: -5,
            left: -0,
            right: -0,
          },
        },
        legend: { show: false },
        xaxis: {
          labels: {
            show: false,
          },
          offsetX: 0,
          offsetY: 0,
          axisTicks: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          tooltip: {
            enabled: false,
          },
        },
        yaxis: {
          show: false,
          labels: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
      }}
    />
  );
};

export default TicketChart;
