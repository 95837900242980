import React, { useContext } from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { TablePaginationConfig } from 'antd/es/table';
import { ColumnProps } from 'antd/lib/table';
import { SorterResult } from 'antd/lib/table/interface';

import Table from '@totem/components/common/table/Table';
import Tooltip from '@totem/components/common/tooltip/Tooltip';
import { sortStringAscending } from '@totem/components/events/eventsContainer/utilities';
import { RemoteAccessRole } from '@totem/components/remoteAccess/types';
import RemoteAccessUserDetailsContext from '@totem/components/remoteAccess/userDetails/RemoteAccessUserDetailsContext';
import UserProfileContext from '@totem/components/UserProfileContext';
import { RemoteAccessUser } from '@totem/types/remoteAccessUsers';
import {
  isMemberOf,
  securityGroupCheckConstraintEmpty,
} from '@totem/utilities/userUtilities';

import '../remoteAccess.css';

const styles = {
  iconButton: {
    margin: '0 2px',
  },
};

const RemoteAccessRolesTable = () => {
  const { userProfile } = useContext(UserProfileContext);
  const { input, setInput, roleData, onAction } = useContext(
    RemoteAccessUserDetailsContext,
  );

  const remoteAccessAdmin = isMemberOf(
    userProfile,
    'remote_access_admin',
    securityGroupCheckConstraintEmpty,
  );

  const columns: ColumnProps<RemoteAccessRole>[] = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (compA, compB) => sortStringAscending(compA.name, compB.name),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      sorter: (compA, compB) =>
        sortStringAscending(compA.description, compB.description),
    },
    {
      title: 'Actions',
      width: 30,
      dataIndex: 'additionalOptions',
      render: (_, role: RemoteAccessRole) => (
        <div className="center-table-cell">
          {remoteAccessAdmin && (
            <Tooltip title="Remove user from role" placement="top">
              <Button
                type="primary"
                icon={<DeleteOutlined />}
                shape="circle"
                onClick={(): void => onAction('removeUser', role)}
                style={styles.iconButton}
              />
            </Tooltip>
          )}
        </div>
      ),
    },
  ];

  const getData = () => {
    if (
      typeof roleData !== 'undefined' &&
      roleData !== null &&
      typeof roleData.roles !== 'undefined' &&
      roleData.roles !== null
    ) {
      return roleData.roles;
    }
    return [];
  };

  const handleTableChange = (
    updatedPagination: TablePaginationConfig,
    filters: SorterResult<RemoteAccessUser>,
    sorter,
  ) => {
    const { ...params } = filters;

    let sortDir: string = sorter.order === 'descend' ? '-1' : '1';
    if (typeof sorter.order === 'undefined' || sorter.order === null) {
      if (
        typeof input.roleSortDirection !== 'undefined' &&
        input.roleSortDirection !== null
      ) {
        sortDir = input.roleSortDirection;
      }
    }

    let sortField = input.roleSortField ? input.roleSortField : 'name';
    if (
      typeof sorter.field !== 'undefined' &&
      typeof sorter.order !== 'undefined'
    ) {
      sortField = sorter.field;
    }

    // @ts-ignore
    setInput({
      ...input,
      ...params,
      rolePageSize: updatedPagination.pageSize,
      rolePage: updatedPagination.current,
      roleSortField: sortField,
      roleSortDirection: sortDir,
    });
  };

  return (
    <Table
      showSorterTooltip
      columns={columns}
      dataSource={getData()}
      loading={input.loading}
      onChange={handleTableChange}
      pagination={{
        current: input.rolePage,
        pageSize: input.rolePageSize,
        total: getData().length,
        showSizeChanger: true,
      }}
      rowKey="id"
    />
  );
};

export default RemoteAccessRolesTable;
