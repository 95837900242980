import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  DeleteOutlined,
  EllipsisOutlined,
  LockOutlined,
  SendOutlined,
} from '@ant-design/icons';
import { Button, Dropdown, MenuProps, Modal } from 'antd';

import RemoteAccessUserContext from '@totem/components/remoteAccess/roleUsers/RemoteAccessUserContext';
import { RemoteAccessUser } from '@totem/components/remoteAccess/types';
import UserProfileContext from '@totem/components/UserProfileContext';
import {
  isMemberOf,
  securityGroupCheckConstraintEmpty,
} from '@totem/utilities/userUtilities';

import '../../remoteAccess.css';

export interface Props {
  user: RemoteAccessUser;
}

const RemoteAccessUserActionsMenu = ({ user }: Props) => {
  const navigate = useNavigate();
  const { userProfile } = useContext(UserProfileContext);
  const { onAction, roleData } = useContext(RemoteAccessUserContext);

  const remoteAccessAdmin =
    typeof roleData !== 'undefined' && roleData !== null
      ? isMemberOf(userProfile, 'remote_access_admin', {
          regionId: roleData.regionId,
          buildingId: roleData.buildingId,
          controlSystemId: roleData.controlSystemId,
        })
      : isMemberOf(
          userProfile,
          'remote_access_admin',
          securityGroupCheckConstraintEmpty,
        );

  const handleRemoveUser = () => {
    Modal.confirm({
      title: `Do you want to remove this user: ${user.email}?`,
      content: '',
      okText: 'Delete',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk: () => onAction('removeUser', user),
      onCancel: () => {},
    });
  };

  const getActionMenuItems = (): MenuProps['items'] => {
    const menuItems: MenuProps['items'] = [
      {
        key: 'roles',
        label: 'Roles',
        title: 'Roles',
        icon: <LockOutlined />,
      },
    ];

    if (remoteAccessAdmin) {
      menuItems.push({
        key: 'send_password_reset',
        label: 'Send Password Reset',
        title: 'Send Password Reset',
        icon: <SendOutlined />,
      });

      menuItems.push({
        key: 'remove_user',
        label: 'Remove User',
        title: 'Remove User',
        icon: <DeleteOutlined />,
      });
    }

    return menuItems;
  };

  const onMenuClicked = ({ key }) => {
    // eslint-disable-next-line default-case
    switch (key) {
      case 'roles':
        navigate(`/dashboard/remoteAccessUsers/user/${user.user_id}`);
        break;
      case 'send_password_reset':
        onAction('sendPasswordReset', user);
        break;
      case 'remove_user':
        handleRemoveUser();
        break;
    }
  };

  return (
    <Dropdown
      menu={{ items: getActionMenuItems(), onClick: onMenuClicked }}
      trigger={['click']}
    >
      <Button shape="circle" icon={<EllipsisOutlined />} />
    </Dropdown>
  );
};

export default RemoteAccessUserActionsMenu;
