import React, { useContext, useState } from 'react';
import { Button, Modal, Select } from 'antd';
const { Option } = Select;
import { v4 as uuidv4 } from 'uuid';

import TemplateContext from '@totem/components/surveyV2/templateDetails/SurveyTemplateContext';
import {
  Condition,
  ConditionActionPayload,
  QuestionOption,
} from '@totem/components/surveyV2/types';
import { getQuestions } from '@totem/components/surveyV2/utilities/SurveyUtilities';

import '../survey.css';

type Props = {
  condition?: Condition;
  questionId: string;
  onConditionChange: (change: ConditionActionPayload) => void;
  onClose: () => void;
  open: boolean;
};
const ConditionBuilderModal = ({
  condition,
  questionId,
  onConditionChange,
  onClose,
  open,
}: Props) => {
  const { data } = useContext(TemplateContext);
  const [selectedOption, setSelectedOption] = useState<string>('');
  const [selectedOperation, setSelectedOperation] = useState<string>('==');
  const [selectedQuestionId, setSelectedQuestionId] = useState<string>('');

  const canSubmit =
    selectedQuestionId !== '' &&
    selectedOperation !== '' &&
    selectedOption !== '';

  const questions = getQuestions(data).filter(
    (qu) =>
      qu.type === 'enumsingle' ||
      qu.type === 'enummultiple' ||
      qu.type === 'scoring',
  );

  const questionSelect = (
    <Select
      defaultValue={selectedQuestionId}
      onSelect={(value) => setSelectedQuestionId(value)}
      styleName="conditionQuestionSelect"
    >
      {questions.map((qu) => {
        let displayText = qu.label.substr(0, 50);
        if (displayText !== qu.label) {
          displayText += '...';
        }
        return (
          <Option key={qu.id} value={qu.id} title={qu.label}>
            {displayText}
          </Option>
        );
      })}
    </Select>
  );

  const operatorSelect = (
    <Select
      defaultValue={selectedOperation}
      onSelect={(value) => setSelectedOperation(value)}
    >
      <Option key="==" value="==">
        ==
      </Option>
      <Option key="!=" value="!=">
        !=
      </Option>
    </Select>
  );

  const getOptions = (): QuestionOption[] => {
    if (selectedQuestionId === '') {
      return [];
    }
    return questions.find((qu) => qu.id === selectedQuestionId).options;
  };

  let defaultOption = selectedOption;
  if (questionId !== selectedQuestionId) {
    defaultOption = '';
  }

  const optionSelect = (
    <Select
      defaultValue={defaultOption}
      styleName="conditionOptionSelect"
      onSelect={(value) => setSelectedOption(value)}
    >
      {getOptions().map((op) => (
        <Option key={op.label} value={op.label}>
          {op.label}
        </Option>
      ))}
    </Select>
  );

  const onSubmitHandler = () => {
    let fieldType = '';
    if (selectedOperation === '==' || selectedOperation === '!=') {
      fieldType = 'question';
    }

    const newCondition = {
      id: uuidv4(),
      operation: selectedOperation,
      fieldType,
      fieldId: selectedQuestionId,
      value: selectedOption,
      subconditions: [],
    };

    onConditionChange({
      method: 'New',
      condition: newCondition,
    });

    onClose();
  };

  return (
    <Modal
      open={open}
      width="720px"
      title="Rule"
      onOk={onClose}
      onCancel={onClose}
      footer={
        <>
          {canSubmit && (
            <Button key="ok" type="primary" onClick={onSubmitHandler}>
              Ok
            </Button>
          )}
        </>
      }
    >
      {questionSelect}
      {selectedQuestionId !== '' && operatorSelect}
      {selectedQuestionId !== '' && optionSelect}
    </Modal>
  );
};

export default ConditionBuilderModal;
