import {
  RemoteAccessSessionFilters,
  RemoteAccessSessionsInput,
} from '@totem/components/remoteAccess/types';
import { Filter } from '@totem/types/common';

export const buildFilters = (input: RemoteAccessSessionsInput) => {
  const paramFilters: Filter[] = [];

  if (
    typeof input.regionId !== 'undefined' &&
    input.regionId !== null &&
    input.regionId.length > 0
  ) {
    paramFilters.push({
      field: 'regionId',
      values: input.regionId,
    });
  }
  if (
    typeof input.buildingId !== 'undefined' &&
    input.buildingId !== null &&
    input.buildingId.length > 0
  ) {
    paramFilters.push({
      field: 'buildingId',
      values: input.buildingId,
    });
  }
  if (
    typeof input.controlSystemId !== 'undefined' &&
    input.controlSystemId !== null &&
    input.controlSystemId.length > 0
  ) {
    paramFilters.push({
      field: 'controlSystemId',
      values: input.controlSystemId,
    });
  }
  if (
    typeof input.group !== 'undefined' &&
    input.group !== null &&
    input.group.length > 0
  ) {
    const filter = {
      field: 'attributes',
      values: input.group,
    };

    for (let idx = 0; idx < filter.values.length; idx++) {
      filter.values[idx] = `Group Description:${filter.values[idx]}`;
    }

    paramFilters.push(filter);
  }

  if (
    typeof input.airwall !== 'undefined' &&
    input.airwall !== null &&
    input.airwall.length > 0
  ) {
    const filter = {
      field: 'attributes',
      values: input.airwall,
    };

    for (let idx = 0; idx < filter.values.length; idx++) {
      filter.values[idx] = `Endpoint Title:${filter.values[idx]}`;
    }

    paramFilters.push(filter);
  }

  if (
    typeof input.email !== 'undefined' &&
    input.email !== null &&
    input.email !== ''
  ) {
    const filter = {
      field: 'attributes',
      operator: 'cscontains',
      values: [`Person EMail:${input.airwall}`],
    };

    paramFilters.push(filter);
  }

  return paramFilters;
};

export const addFilters = (
  currentFilters: Filter[],
  input: RemoteAccessSessionFilters,
) => {
  if (typeof input !== 'undefined' && input !== null) {
    if (
      typeof input.regionId !== 'undefined' &&
      input.regionId !== null &&
      input.regionId.length > 0
    ) {
      currentFilters.push({
        field: 'regionId',
        values: input.regionId,
      });
    }
    if (
      typeof input.buildingId !== 'undefined' &&
      input.buildingId !== null &&
      input.buildingId.length > 0
    ) {
      currentFilters.push({
        field: 'buildingId',
        values: input.buildingId,
      });
    }
    if (
      typeof input.controlSystemId !== 'undefined' &&
      input.controlSystemId !== null &&
      input.controlSystemId.length > 0
    ) {
      currentFilters.push({
        field: 'controlSystemId',
        values: input.controlSystemId,
      });
    }
    if (input.group !== null && input.group.length > 0) {
      const filter = {
        field: 'attributes',
        values: input.group,
      };

      for (let idx = 0; idx < filter.values.length; idx++) {
        filter.values[idx] = `Group Description:${filter.values[idx]}`;
      }

      currentFilters.push(filter);
    }

    if (input.airwall !== null && input.airwall.length > 0) {
      const filter = {
        field: 'attributes',
        values: input.airwall,
      };

      for (let idx = 0; idx < filter.values.length; idx++) {
        filter.values[idx] = `Endpoint Title:${filter.values[idx]}`;
      }

      currentFilters.push(filter);
    }

    if (
      typeof input.email !== 'undefined' &&
      input.email !== null &&
      input.email !== ''
    ) {
      const filter = {
        field: 'attributes',
        operator: 'cscontains',
        values: [`Person EMail:${input.airwall}`],
      };

      currentFilters.push(filter);
    }
  }

  return currentFilters;
};
