// @ts-nocheck
import axios from 'axios';
import Cookies from 'universal-cookie';

const getCookies = () => new Cookies();
const jwtToken = getCookies().get('access_token');

axios.defaults.headers.common.Authorization = `Bearer ${jwtToken}`;

const setAuthToken = () => {
  const cookies = () => new Cookies();
  const token = cookies().get('access_token');
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
};

export function get(endpoint, params) {
  return axios.get(endpoint, {
    ...(params && { params }),
  });
}

export function head(endpoint, params) {
  return axios.head(endpoint, {
    ...(params && { params }),
  });
}

export function put(endpoint, data, params) {
  return axios.put(endpoint, data, {
    ...(params && { params }),
  });
}

export function post(endpoint, data, params) {
  return axios.post(endpoint, data, {
    ...(params && { params }),
  });
}

export function patch(endpoint, data, params) {
  return axios.patch(endpoint, data, {
    ...(params && { params }),
  });
}

export function deleteApi(endpoint, params) {
  return axios.delete(endpoint, {
    ...(params && { params }),
  });
}

const api = {
  get,
  head,
  put,
  post,
  patch,
  delete: deleteApi,
  setAuthToken,
};

export default api;
