import React, { useContext } from 'react';
import { Badge, Card, Divider } from 'antd';

import SurveyInstanceContext from '@totem/components/surveyV2/instances/SurveyInstancesContext';
import { isFiltered } from '@totem/components/surveyV2/instances/utilities';

import '../survey.css';

const styles = {
  divider: {
    height: '6rem',
  },
};

const SurveyHealthPane = () => {
  const { statistics, input } = useContext(SurveyInstanceContext);
  const filtered = isFiltered(input);

  return (
    <Card
      title={
        <div styleName="health-overview-header">
          {`Surveys Health ${filtered ? '(filtered)' : ''}`}
        </div>
      }
      bordered={false}
    >
      {typeof input !== 'undefined' &&
        input !== null &&
        typeof statistics !== 'undefined' &&
        statistics !== null && (
          <div styleName="overview-card-body">
            <div>
              <div styleName="health-status-title-container">
                <Badge status="error" className="health-status-badge-lg" />
                <div styleName="overview-title">Off Track</div>
              </div>
              <div styleName="stat health-stat">{statistics.offTrack}</div>
            </div>
            <Divider type="vertical" style={styles.divider} />
            <div>
              <div styleName="health-status-title-container">
                <Badge status="warning" className="health-status-badge-lg" />
                <div styleName="overview-title">At Risk</div>
              </div>
              <div styleName="stat health-stat">{statistics.atRisk}</div>
            </div>
            <Divider type="vertical" style={styles.divider} />
            <div>
              <div styleName="health-status-title-container">
                <Badge status="success" className="health-status-badge-lg" />
                <div styleName="overview-title">On Track</div>
              </div>
              <div styleName="stat health-stat">{statistics.onTrack}</div>
            </div>
          </div>
        )}
    </Card>
  );
};

export default SurveyHealthPane;
