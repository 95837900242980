import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Checkbox, Input } from 'antd';
import { debounce } from '@totem/utilities/debounce';

import FormFieldLabel from '@totem/components/FormFieldLabel';
import TemplateContext from '@totem/components/surveyV2/templateDetails/SurveyTemplateContext';
import { Category, SurveyTemplate } from '@totem/components/surveyV2/types';
import { getToken } from '@totem/utilities/accountUtilities';
import { V2_SURVEY_ENDPOINT } from '@totem/utilities/endpoints';

const DEBOUNCE_TIME = 750;

type Props = {
  category: Category;
};

const SurveyTemplateCategoryDetails = ({ category }: Props) => {
  const { surveyTemplateId, setData, setIsLoading } =
    useContext(TemplateContext);
  const [categoryUpdated, setCategoryUpdated] = useState<boolean>(false);
  const [localState, setLocalState] = useState<Category>(category);

  const sendCategoryChanged = useCallback(
    debounce((templateId: string, categoryId: string, update: Category) => {
      setIsLoading(true);

      const payload = {
        name: update.name,
        title: update.title,
        description: update.description,
        multiInstance: update.multiInstance,
        multiInstanceLabel: update.multiInstanceLabel,
      };

      fetch(
        `${V2_SURVEY_ENDPOINT}/template/${templateId}/categories/${categoryId}`,
        {
          method: 'POST',
          headers: new Headers({
            Authorization: `Bearer ${getToken()}`,
          }),
          body: JSON.stringify(payload),
        },
      )
        .then((res) => res.json())
        .then((result: SurveyTemplate) => {
          setData(result);
        })
        .then(() => {
          setIsLoading(false);
        });
    }, DEBOUNCE_TIME),
    [],
  );

  useEffect(() => {
    if (categoryUpdated) {
      setCategoryUpdated(false);
      sendCategoryChanged(surveyTemplateId, category.id, localState);
    }
  }, [localState]);

  return (
    <>
      <FormFieldLabel>Name</FormFieldLabel>
      <Input
        placeholder="Name"
        value={localState.name}
        onChange={({
          target: { value },
        }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
          setCategoryUpdated(true);
          setLocalState({ ...localState, name: value });
        }}
      />

      <FormFieldLabel>Title</FormFieldLabel>
      <Input
        placeholder="Title"
        value={localState.title}
        onChange={({
          target: { value },
        }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
          setCategoryUpdated(true);
          setLocalState({ ...localState, title: value });
        }}
      />

      <FormFieldLabel>Description</FormFieldLabel>
      <Input
        placeholder="Title"
        value={localState.description}
        onChange={({
          target: { value },
        }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
          setCategoryUpdated(true);
          setLocalState({ ...localState, description: value });
        }}
      />

      <br />
      <br />

      <Checkbox
        checked={localState.multiInstance}
        onChange={() => {
          setCategoryUpdated(true);
          setLocalState({
            ...localState,
            multiInstance: !localState.multiInstance,
          });
        }}
      >
        Multi-Instance
      </Checkbox>

      {localState.multiInstance && (
        <>
          <FormFieldLabel>Multi-Instance Label</FormFieldLabel>
          <Input
            placeholder="Instance Label"
            value={localState.multiInstanceLabel}
            onChange={({
              target: { value },
            }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
              setCategoryUpdated(true);
              setLocalState({ ...localState, multiInstanceLabel: value });
            }}
          />
        </>
      )}
    </>
  );
};

export default SurveyTemplateCategoryDetails;
