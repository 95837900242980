import React from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { ColumnProps } from 'antd/lib/table';

import Table from '@totem/components/common/table/Table';
import Tooltip from '@totem/components/common/tooltip/Tooltip';
import { OrganizationHierarchy } from '@totem/types/organization';
import { EligibleSecurityGroup, SecurityGroup } from '@totem/types/user';
import authUtilities from '@totem/utilities/authUtilities';
import {
  getBuildingName,
  getControlSystemName,
  getRegionName,
} from '@totem/utilities/organization';

const styles = {
  iconButton: {
    margin: '0 2px',
  },
};

export interface Props {
  loading: boolean;
  orgHierarchy: OrganizationHierarchy;
  eligibleGroups: EligibleSecurityGroup[];
  securityGroups: SecurityGroup[];
  onRemoveGroup: (SecurityGroup) => void;
}

const SecurityGroupsTable = ({
  securityGroups,
  loading,
  orgHierarchy,
  onRemoveGroup,
}: Props) => {
  const mapSecurityGroups = (groups: SecurityGroup[]) =>
    groups
      .map(grp => ({
        ...grp,
        key: grp.id + grp.regionId + grp.buildingId + grp.controlSystemId,
        regionName: getRegionName(orgHierarchy, grp.regionId),
        buildingName: getBuildingName(
          orgHierarchy,
          grp.regionId,
          grp.buildingId,
        ),
        controlSystemName: getControlSystemName(
          orgHierarchy,
          grp.regionId,
          grp.buildingId,
          grp.controlSystemId,
        ),
      }))
      .sort((option1, option2) => {
        if (option1.name < option2.name) {
          return -1;
        }
        if (option1.name > option2.name) {
          return 1;
        }
        return 0;
      });

  const userAdminEnabled = authUtilities.minimumRequiredRole(
    authUtilities.ROLE_SETS.ORGANIZATION_ADMINISTRATOR,
  );

  const columns: ColumnProps<SecurityGroup>[] = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Region',
      dataIndex: 'regionName',
      key: 'regionName',
    },
    {
      title: 'Building',
      dataIndex: 'buildingName',
      key: 'buildingName',
    },
    {
      title: 'Control System',
      dataIndex: 'controlSystemName',
      key: 'controlSystemName',
    },
    {
      title: 'Actions',
      width: 30,
      dataIndex: 'additionalOptions',
      render: (_, group: SecurityGroup) => (
        <div className="center-table-cell">
          {userAdminEnabled && (
            <Tooltip title="Remove Security Group" placement="top">
              <Button
                type="primary"
                icon={<DeleteOutlined />}
                shape="circle"
                onClick={(): void => onRemoveGroup(group)}
                style={styles.iconButton}
              />
            </Tooltip>
          )}
        </div>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      loading={loading}
      dataSource={mapSecurityGroups(securityGroups)}
      rowKey="key"
    />
  );
};

export default SecurityGroupsTable;
