import React from 'react';
import { useParams } from 'react-router-dom';

import BasePane from '@totem/components/BasePane';
import CardBody from '@totem/components/CardBody';
import ContentLayout from '@totem/components/ContentLayout';
import LayoutContainer from '@totem/components/devices/variables/presentation/layout/LayoutContainer';
import PageContainer from '@totem/components/devices/variables/presentation/layout/PageContainer';
import PageSubTitle from '@totem/components/devices/variables/presentation/layout/PageSubTitle';
import PageTitle from '@totem/components/devices/variables/presentation/layout/PageTitle';
import LayoutTemplateSelector from '@totem/components/devices/variables/presentation/layout/templates/LayoutTemplateSelector';
import PrimaryContentPane from '@totem/components/PrimaryContentPane';

const LayoutPage = () => {
  const { id } = useParams();

  return (
    <PageContainer>
      <ContentLayout pageTitle={<PageTitle />} pageSubtitle={<PageSubTitle />}>
        <BasePane>
          <PrimaryContentPane>
            <CardBody>
              <LayoutContainer layoutId={id}>
                <LayoutTemplateSelector />
              </LayoutContainer>
            </CardBody>
          </PrimaryContentPane>
        </BasePane>
      </ContentLayout>
    </PageContainer>
  );
};

export default LayoutPage;
