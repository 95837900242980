import React from 'react';

import './stylesTest.css';
const StylesTest = () => {
  return (
    <>
      <span styleName="f-xxl">Font XXL</span>
      <br />
      <span styleName="f-xl">Font XL</span>
      <br />
      <span styleName="f-lg">Font LG</span>
      <br />
      <span styleName="f-md">Font MD</span>
      <br />
      <span>Font Unspecified</span>
      <br />
      <span styleName="f-sm">Font SM</span>
      <br />
      <span styleName="f-xs">Font XS</span>
      <br />
      <span styleName="f-xxs">Font XXS</span>
      <br />
    </>
  );
};

export default StylesTest;
