import React, { ReactNode, useContext, useEffect, useState } from 'react';
import dayjs from 'dayjs';

import EventFilterContext from '@totem/components/events/eventFilters/EventFilterContext';
import {
  addEventFilters,
  buildEventFilters,
} from '@totem/components/events/eventFilters/utilities';
import EventGraphContext from '@totem/components/events/graphs/EventGraphContext';
import {
  EventGraphData,
  GraphParameters,
} from '@totem/components/events/graphs/types';
import { getToken } from '@totem/utilities/accountUtilities';
import { EVENTS_ENDPOINT } from '@totem/utilities/endpoints';

import '../events.css';

type Props = {
  children?: ReactNode;
};

const EventGraphsContainer = ({ children }: Props) => {
  const [refreshData, setRefreshData] = useState<boolean>(true);
  const [graphData, setGraphData] = useState<EventGraphData>(null);
  const [isSending, setIsSending] = useState(false);
  const { input, staticFilters } = useContext(EventFilterContext);
  useEffect(() => {
    setRefreshData(true);
  }, [input, staticFilters]);

  useEffect(() => {
    if (refreshData) {
      setRefreshData(false);
      if (isSending) {
        return;
      }
      const endpoint = `${EVENTS_ENDPOINT}/graphData`;

      const payload: GraphParameters = {
        startTime: dayjs(input.startTime).toISOString(),
        endTime: dayjs(input.endTime).toISOString(),
        filters: buildEventFilters(input),
      };
      payload.filters = addEventFilters(payload.filters, staticFilters);

      fetch(`${endpoint}`, {
        method: 'POST',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
        body: JSON.stringify(payload),
      })
        .then((res) => res.json())
        .then((result: EventGraphData) => {
          if (result.eventSeverity !== null) {
            for (let idx = 0; idx < result.eventSeverity.length; idx++) {
              // eslint-disable-next-line default-case
              switch (result.eventSeverity[idx].x) {
                case '0':
                  result.eventSeverity[idx].x = 'OK';
                  break;
                case '1':
                  result.eventSeverity[idx].x = 'Information';
                  break;
                case '2':
                  result.eventSeverity[idx].x = 'Warning';
                  break;
                case '3':
                  result.eventSeverity[idx].x = 'Minor';
                  break;
                case '4':
                  result.eventSeverity[idx].x = 'Major';
                  break;
                case '5':
                  result.eventSeverity[idx].x = 'Critical';
                  break;
              }
            }
          }
          if (result.eventTypeSeries !== null) {
            for (let idx = 0; idx < result.eventTypeSeries.x.length; idx++) {
              result.eventTypeSeries.x[idx] = result.eventTypeSeries.x[
                idx
              ].slice(0, 10);
            }
          }

          setGraphData(result);
        })
        .then(() => {
          setIsSending(false);
        });

      setIsSending(true);
    }
  }, [refreshData]);

  return (
    <EventGraphContext.Provider
      value={{
        graphData,
      }}
    >
      <div>{children}</div>
    </EventGraphContext.Provider>
  );
};

export default EventGraphsContainer;
