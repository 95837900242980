import React, { useContext } from 'react';
import { EditOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { ColumnProps } from 'antd/lib/table';

import Table from '@totem/components/common/table/Table';
import Tooltip from '@totem/components/common/tooltip/Tooltip';
import { sortStringAscending } from '@totem/components/events/eventsContainer/utilities';
import AuvikContext from '@totem/components/settings/sections/auvikMapping/AuvikContext';
import { TenantReportRecord } from '@totem/components/settings/sections/auvikMapping/types';
import authUtilities from '@totem/utilities/authUtilities';

const styles = {
  iconButton: {
    margin: '0 2px',
  },
};
const AuvikMappingTable = () => {
  const { data, loading, onAction } = useContext(AuvikContext);
  const columns: ColumnProps<TenantReportRecord>[] = [
    {
      title: 'Name',
      dataIndex: 'tenant.tenantName',
      key: 'tenant.tenantName',
      sorter: (compA, compB) =>
        sortStringAscending(compA.tenant.tenantName, compB.tenant.tenantName),
      render: (text: string, item: TenantReportRecord) => {
        return item.tenant.tenantName;
      },
    },
    {
      title: 'Domain Prefix',
      dataIndex: 'tenant.tenantDomainPrefix',
      key: 'tenant.tenantDomainPrefix',
      sorter: (compA, compB) =>
        sortStringAscending(
          compA.tenant.tenantDomainPrefix,
          compB.tenant.tenantDomainPrefix,
        ),
      render: (text: string, item: TenantReportRecord) => {
        return item.tenant.tenantDomainPrefix;
      },
    },
    {
      title: 'Building',
      dataIndex: 'building.id',
      key: 'building.id',
      sorter: (compA, compB) =>
        sortStringAscending(compA.building.name, compB.building.name),
      render: (text: string, item: TenantReportRecord) => {
        return typeof item.building !== 'undefined' && item.building !== null
          ? item.building.name
          : '';
      },
    },
    {
      title: 'Actions',
      width: 30,
      dataIndex: 'additionalOptions',
      key: 'action',
      render: (_, item: TenantReportRecord) => (
        <div className="center-table-cell">
          {authUtilities.minimumRequiredRole(
            authUtilities.ROLE_SETS.SYSTEMS_ADMINISTRATOR,
          ) && (
            <Tooltip title="Update Building Mapping" placement="top">
              <Button
                icon={<EditOutlined />}
                shape="circle"
                onClick={(): void => onAction('showMappingDialog', item)}
                style={styles.iconButton}
              />
            </Tooltip>
          )}
        </div>
      ),
    },
  ];

  return (
    <Table
      showSorterTooltip
      columns={columns}
      dataSource={typeof data !== 'undefined' && data !== null ? data : []}
      loading={loading}
      rowKey="id"
    />
  );
};

export default AuvikMappingTable;
