import React, { useContext } from 'react';
import { Flex } from 'antd';

import Grade from '@totem/components/common/Grade';
import PageSectionFilled from '@totem/components/PageSectionFilled';
import PageSectionFilledTagline from '@totem/components/PageSectionFilledTagline';
import PageSectionFilledTitle from '@totem/components/PageSectionFilledTitle';
import ComplianceContext from '@totem/components/surveyV2/complianceSummary/complianceContext';
import colors from '@totem/styles/colors';
import IBDIcon from '@totem/styles/Icon';
import { isNotNull } from '@totem/utilities/common';

import '../complianceSummary.css';

const styles = {
  grade: {
    color: colors.neutral.black,
    border: `15px solid ${colors.neutral.white}`,
  },
  arrowIcon: {
    marginLeft: '0.5rem',
  },
  alertIcon: {
    fontSize: '4rem',
    color: colors.neutral.white,
  },
};

interface Props {
  title?: string;
}

const PortfolioHealthComplianceSummary = ({ title }: Props) => {
  const { data } = useContext(ComplianceContext);

  const completedAudits = isNotNull(data) ? data.auditedControlSystems : 0;
  const averageScore = isNotNull(data)
    ? (data.totalCombinedScore / data.maxCombinedScore) * 100
    : 0;

  return (
    <PageSectionFilled>
      <PageSectionFilledTitle>
        {isNotNull(title) ? title : 'Portfolio Health Audit Summary'}
      </PageSectionFilledTitle>
      {completedAudits ? (
        <>
          <Grade
            score={averageScore > 0 ? averageScore : null}
            size="massive"
            style={styles.grade}
          />
          <PageSectionFilledTagline>
            {completedAudits} Control Systems Reporting
          </PageSectionFilledTagline>
        </>
      ) : (
        <div styleName="no-audits-container">
          <Flex justify={'center'} align={'flex-start'}>
            <IBDIcon name="Policy Audit" style={styles.alertIcon} />
          </Flex>
          <div styleName="no-audits">
            Looks like there are no policy audits attached...yet.
          </div>
        </div>
      )}
    </PageSectionFilled>
  );
};

export default PortfolioHealthComplianceSummary;
