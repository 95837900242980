import React from 'react';

import UserActivateModal from '@totem/components/users/container/actions/activate/UserActivateModal';
import UserCreateModal from '@totem/components/users/container/actions/createUser/UserCreateModal';
import DataAccessPermissionsTreeModal from '@totem/components/users/container/actions/dataPermissions/dataAccessPermissionsTree';
import UserRemoveIBModal from '@totem/components/users/container/actions/remove/UserRemoveIBModal';
import UserRemoveModal from '@totem/components/users/container/actions/remove/UserRemoveModal';
import UserViewDetailsModal from '@totem/components/users/container/actions/remove/UserViewDetailsModal';
import SecurityGroupsModal from '@totem/components/users/container/actions/securityGroups/SecurityGroupsModal';
import UserUpdateModal from '@totem/components/users/container/actions/update/UserUpdateModal';
import { User } from '@totem/components/users/container/types';

import '../../users.css';

export interface Props {
  user: User;
  action: string;
  onClose: (refresh: boolean) => void;
}

const UserActions = ({ user, action, onClose }: Props) => {
  return (
    <>
      {action === 'removeUser' && (
        <UserRemoveModal
          user={user}
          open={action === 'removeUser'}
          onClose={() => onClose(true)}
        />
      )}
      {action === 'removeIBUser' && (
        <UserRemoveIBModal
          user={user}
          open={action === 'removeIBUser'}
          onClose={() => onClose(true)}
        />
      )}
      {action === 'viewDetails' && (
        <UserViewDetailsModal
          user={user}
          open={action === 'viewDetails'}
          onClose={() => onClose(false)}
        />
      )}
      {action === 'editUser' && (
        <UserUpdateModal
          user={user}
          open={action === 'editUser'}
          onClose={() => onClose(true)}
        />
      )}
      {action === 'dataPermissions' && (
        <DataAccessPermissionsTreeModal
          user={user}
          visible={action === 'dataPermissions'}
          onClose={() => onClose(false)}
        />
      )}
      {action === 'securityGroups' && (
        <SecurityGroupsModal
          user={user}
          open={action === 'securityGroups'}
          onClose={() => onClose(false)}
        />
      )}
      {action === 'createUser' && (
        <UserCreateModal
          visible={action === 'createUser'}
          loading={false}
          onClose={() => onClose(true)}
        />
      )}
      {action === 'activateUser' && (
        <UserActivateModal
          user={user}
          open={action === 'activateUser'}
          onClose={() => onClose(true)}
        />
      )}
    </>
  );
};

export default UserActions;
