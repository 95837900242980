import React from 'react';

import CveCardTitle from '@totem/components/software/cves/cveCardTitle';
import { CVERecord } from '@totem/components/software/cves/types';

type Props = {
  cve: CVERecord;
};

type VendorRecord = {
  vendor: string;
  products: string[];
};

const CveProductsAffected = ({ cve }: Props) => {
  const getVendorRecords = (): VendorRecord[] => {
    const vendors: VendorRecord[] = [];
    if (
      typeof cve !== 'undefined' &&
      cve !== null &&
      cve.cve.vendors !== null
    ) {
      for (const [vendor, products] of Object.entries(cve.cve.vendors)) {
        vendors.push({
          vendor,
          products,
        });
      }
    }
    return vendors;
  };

  const vendorRecords = getVendorRecords();

  return (
    <>
      <CveCardTitle>Products Affected</CveCardTitle>
      <ul>
        {vendorRecords.map((vendor) => {
          return (
            <li key={vendor.vendor}>
              <b>{vendor.vendor}</b>
              <ul>
                {vendor.products.map((product) => (
                  <li key={vendor.vendor + product}>{product}</li>
                ))}
              </ul>
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default CveProductsAffected;
