import { TablePaginationConfig } from 'antd/lib/table';

export const getPageFromLimitAndOffset = (
  limit: number,
  offset: number,
): number => Math.floor(offset / limit + 1);

export const getOffsetFromLimitAndPage = (
  limit: number,
  page: number,
): number => (page - 1) * limit;

export const getTablePagination = (
  { limit = 0, offset = 0 }: { limit?: number; offset?: number },
  total: number,
): TablePaginationConfig => ({
  total,
  current: getPageFromLimitAndOffset(limit, offset),
  pageSize: limit,
  showSizeChanger: true,
});

export const getLimitAndOffsetFromTablePagination = ({
  current: page,
  pageSize: limit,
}: TablePaginationConfig): { limit: number; offset: number } => {
  return {
    limit,
    offset: getOffsetFromLimitAndPage(limit, page),
  };
};
