import React, { useContext } from 'react';
import { ColumnProps } from 'antd/lib/table';

import Table from '@totem/components/common/table/Table';
import DeviceContext from '@totem/components/devices/deviceDetails/DeviceContext';
import { DeviceContractLine } from '@totem/types/devices';

const ContractLinesTable = () => {
  const { deviceData, loading } = useContext(DeviceContext);

  const columns: ColumnProps<DeviceContractLine>[] = [
    {
      title: 'Order Number',
      dataIndex: 'orderNumber',
      key: 'orderNumber',
      sorter: (compA, compB) =>
        compA.orderNumber === compB.orderNumber
          ? 0
          : compA.orderNumber > compB.orderNumber
          ? 1
          : -1,
    },
    {
      title: 'Line Number',
      dataIndex: 'lineNumber',
      key: 'lineNumber',
      sorter: (compA, compB) =>
        compA.lineNumber === compB.lineNumber
          ? 0
          : compA.lineNumber > compB.lineNumber
          ? 1
          : -1,
    },
    {
      title: 'Service',
      dataIndex: 'service',
      key: 'service',
      sorter: (compA, compB) =>
        compA.service === compB.service
          ? 0
          : compA.service > compB.service
          ? 1
          : -1,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: (compA, compB) =>
        compA.status === compB.status
          ? 0
          : compA.status > compB.status
          ? 1
          : -1,
    },
  ];

  return (
    <Table
      showSorterTooltip
      columns={columns}
      dataSource={
        typeof deviceData !== 'undefined' &&
        deviceData !== null &&
        deviceData.device !== null &&
        deviceData.device.contractLines !== null
          ? deviceData.device.contractLines
          : []
      }
      loading={loading}
      rowKey={record => record.lineId + record.service + record.status}
    />
  );
};

export default ContractLinesTable;
