import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AutoComplete, Input } from 'antd';

import { retrieveRemoteAccessUsers } from '@totem/actions/remoteAccessUserActions';
import { usePreviousValue } from '@totem/hooks/usePreviousValue';
import colors from '@totem/styles/colors';
import fonts from '@totem/styles/fonts';
import { ReduxStore, RemoteAccessRoleUsersState } from '@totem/types/store';
import { validateEmail } from '@totem/utilities/validation';

export interface Props {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  style?: React.CSSProperties;
  allowClear?: boolean;
  disabled?: boolean;
  limit?: number;
}

const styles = {
  input: {
    width: '100%',
  },
  notFound: {
    color: colors.neutral.gray,
    fontSize: fonts.fontMd,
  },
};

const getData = (emails: string[]) =>
  emails.map(email => ({
    id: email,
    value: email,
  }));

const EmailAutoComplete = ({
  value,
  onChange,
  style,
  allowClear,
  disabled,
  placeholder = '',
}: Props) => {
  const dispatch = useDispatch();
  const remoteAccessUsers = useSelector<ReduxStore, RemoteAccessRoleUsersState>(
    state => state.remoteAccessUsers,
  );

  const [open, setOpen] = useState<boolean>(false);
  const [email, setEmail] = useState<string>(value || '');
  const [results, setResults] = useState<string[]>([]);

  const previousValue = usePreviousValue<string>(value);

  const getAvailableEmails = () => {
    let emailOptions = [];

    if (remoteAccessUsers !== null || remoteAccessUsers.length === 0) {
      for (let idx = 0; idx < remoteAccessUsers.length; idx++) {
        emailOptions = emailOptions.concat(
          remoteAccessUsers[idx].users.map(user => {
            return user.email;
          }),
        );
      }
      return emailOptions;
    }

    return [];
  };

  const handleSearch = (search: string) => {
    const newOptions = getAvailableEmails().filter(
      (emailIn: string) => emailIn.indexOf(search) >= 0,
    );
    setResults(newOptions);
    setEmail(search);
  };

  const handleSelect = (selection: string) => {
    onChange(selection);
    setEmail(selection);
  };

  useEffect(() => {
    if (remoteAccessUsers === null || remoteAccessUsers.length === 0) {
      dispatch(retrieveRemoteAccessUsers({}));
    }

    if (!value) {
      setEmail('');
    }
  }, [dispatch, remoteAccessUsers, value]);

  useEffect(() => {
    if (!previousValue && value) {
      setEmail(value);
    }
  }, [value, previousValue]);

  const getNotFoundContent = () => {
    return email && validateEmail(email) ? (
      <div
        style={styles.notFound}
        onClick={() => {
          setOpen(false);
          onChange(email);
        }}
      >
        Looks like this person isn't a remote access user yet.
      </div>
    ) : null;
  };

  return (
    <AutoComplete
      open={open}
      options={getData(results)}
      style={{ ...styles.input, ...style }}
      value={value || ''}
      onChange={onChange}
      onSelect={handleSelect}
      onSearch={handleSearch}
      placeholder={placeholder}
      allowClear={allowClear ?? false}
      disabled={disabled ?? false}
      notFoundContent={getNotFoundContent()}
      onDropdownVisibleChange={setOpen}
    >
      <Input.Search size="large" enterButton />
    </AutoComplete>
  );
};

export default EmailAutoComplete;
