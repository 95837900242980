import React, { useEffect, useState } from 'react';

import InfoWidget from '@totem/components/ui_components/info_widget/InfoWidget';
import {
  Point,
  Points,
} from '@totem/components/ui_components/info_widget/types';
import { AggregateGraphPoint, AggregateRequest } from '@totem/types/charts';
import { getToken } from '@totem/utilities/accountUtilities';
import { defaultChartColors, shuffle } from '@totem/utilities/charts';
import { BUILDINGS_ENDPOINT } from '@totem/utilities/endpoints';

const BuildingsSquareFootage = () => {
  const [chartData, setChartData] = useState<Points>([]);
  const [recordTotal, setRecordTotal] = useState<number>(0);
  const [colors, setColors] = useState<string[]>(defaultChartColors);

  const createChartData = (chartDataResults: AggregateGraphPoint[]) => {
    let total = 0;
    const data: Points = chartDataResults.map((graphPoint) => {
      return { id: graphPoint.id, label: graphPoint.x, value: graphPoint.y };
    });

    for (let idx = 0; idx < chartDataResults.length; idx++) {
      total += chartDataResults[idx].s;
    }

    setRecordTotal(total);
    setChartData(data);
    setColors(shuffle(defaultChartColors));
  };

  useEffect(() => {
    const params: AggregateRequest = {
      idFieldName: 'type',
      labelFieldName: 'type',
      aggregateFieldName: 'squareFootage',
      filters: [],
    };

    fetch(`${BUILDINGS_ENDPOINT}/charts/aggregate`, {
      method: 'POST',
      headers: new Headers({
        Authorization: `Bearer ${getToken()}`,
      }),
      body: JSON.stringify(params),
    })
      .then(res => res.json())
      .then((result: AggregateGraphPoint[]) => {
        createChartData(result);
      });
  }, []);

  const handleItemClicked = (index: number, point: Point) => {
    if (index >= 0) {
      console.log(JSON.stringify(point));
    }
  };

  return (
    <InfoWidget
      points={chartData}
      chartTitle="Square Footage / Type"
      title="Buildings"
      total={recordTotal.toString(10)}
      onPointClicked={handleItemClicked}
      chartOptions={{ colors }}
    />
  );
};

export default BuildingsSquareFootage;
