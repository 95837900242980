import React from 'react';

import { Building } from '@totem/types/building';

export interface Context {
  refetch: () => Promise<any>;
  loading: boolean;
  building: Building;
}

export const initialState: Context = {
  refetch: () => Promise.resolve({}),
  loading: true,
  // @ts-ignore
  building: {},
};

const BuildingDetailContext = React.createContext<Context>(initialState);

export default BuildingDetailContext;
