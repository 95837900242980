import React from 'react';
import { CloseCircleOutlined } from '@ant-design/icons';

import colors from '@totem/styles/colors';
import fonts from '@totem/styles/fonts';

interface Props {
  label: string;
  onClose: () => void;
  style?: React.CSSProperties;
}

const styles = {
  container: {
    display: 'inline-flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0.6rem',
    border: `1px solid ${colors.neutral.black}`,
    background: colors.neutral.lightGray,
    borderRadius: 16,
    color: colors.neutral.dark,
    fontSize: fonts.fontSm,
  },
  icon: {
    fontSize: 14,
    marginLeft: 6,
  },
};

const FilterChip = ({ label, onClose, style }: Props) => {
  return (
    <div
      // @ts-ignore
      style={{ ...styles.container, ...style }}
    >
      {label}
      <CloseCircleOutlined
        style={styles.icon}
        twoToneColor={colors.neutral.dim}
        onClick={onClose}
      />
    </div>
  );
};

export default FilterChip;
