import React, { useContext } from 'react';
import { ColumnProps } from 'antd/lib/table';

import Table from '@totem/components/common/table/Table';
import { sortStringAscending } from '@totem/components/events/eventsContainer/utilities';
import PendingActionMenu from '@totem/components/internalOnly/netSuitePending/PendingActionMenu';
import PendingContext from '@totem/components/internalOnly/netSuitePending/PendingContext';
import { PendingImportRecord } from '@totem/components/internalOnly/netSuitePending/types';

const PendingTable = () => {
  const { data, loading } = useContext(PendingContext);

  const columns: ColumnProps<PendingImportRecord>[] = [
    {
      title: 'Customer',
      dataIndex: 'customer.companyName',
      key: 'customer.companyName',
      sorter: (compA, compB) =>
        sortStringAscending(
          compA.customer.companyName,
          compB.customer.companyName,
        ),
      render: (text: string, item: PendingImportRecord) => {
        return item.customer.companyName;
      },
    },
    {
      title: 'Address',
      dataIndex: 'address.shippingAddress',
      key: 'address.shippingAddress',
      sorter: (compA, compB) =>
        sortStringAscending(
          compA.address.shippingAddress.addrText,
          compB.address.shippingAddress.addrText,
        ),
      render: (text: string, item: PendingImportRecord) => {
        return <pre>{item.address.shippingAddress.addrText}</pre>;
      },
    },
    {
      title: 'Order Number',
      dataIndex: 'salesOrder.tranId',
      key: 'salesOrder.tranId',
      sorter: (compA, compB) =>
        sortStringAscending(compA.salesOrder.tranId, compB.salesOrder.tranId),
      render: (text: string, item: PendingImportRecord) => {
        return item.salesOrder.tranId;
      },
    },
    {
      title: 'Services',
      dataIndex: 'services',
      key: 'services',
      render: (text: string, item: PendingImportRecord) => {
        return item.services.map((svc) => (
          <span key={svc}>
            {svc}
            <br />
          </span>
        ));
      },
    },
    {
      title: 'Actions',
      width: 30,
      dataIndex: 'additionalOptions',
      key: 'action',
      render: (_, item: PendingImportRecord) => (
        <PendingActionMenu record={item} />
      ),
    },
  ];

  return (
    <Table
      showSorterTooltip
      columns={columns}
      dataSource={typeof data !== 'undefined' && data !== null ? data : []}
      loading={loading}
      rowKey="id"
    />
  );
};

export default PendingTable;
