import React, { useCallback, useState } from 'react';
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Tabs,
  TabsProps,
} from 'antd';
const FormItem = Form.Item;
const { Option } = Select;

import { CloseCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';

import ModalFooter from '@totem/components/ModalFooter';
import ModalTitle from '@totem/components/ModalTitle';
import TabTitle from '@totem/components/TabTitle';
import {
  Contract,
  ContractBillingContainer,
  EMailAddress,
  PhoneNumber,
} from '@totem/types/contracts';
import { getToken } from '@totem/utilities/accountUtilities';
import { CONTRACTS_ENDPOINT } from '@totem/utilities/endpoints';

import '../contracts.css';
const styles = {
  form: {
    width: '100%',
  },
  formItem: {
    paddingBottom: '0',
    marginBottom: '1rem',
  },
  indent: {
    marginLeft: '20px',
  },
  addIcon: {
    marginLeft: '15px',
    color: '#1890ff',
  },
  removeIcon: {
    color: '#1890ff',
  },
  shortInput: {
    width: '200px',
  },
};

const defaultBillingContainer: ContractBillingContainer = {
  id: '',
  address: {
    id: '',
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    city: '',
    region: '',
    postalCode: '',
    country: '',
  },
  contact: {
    id: '',
    name: '',
    phone: [],
    email: [],
  },
  lines: [],
  billingFrequency: 'Annual',
  terms: 'Net30',
  keys: [],
  attributes: [],
};

export interface Props {
  visible: boolean;
  contractId: string;
  billingContainer?: ContractBillingContainer;
  onBillingContainerChanged?: (
    billingContainer: ContractBillingContainer,
  ) => void;
  onContractChanged?: (contract: Contract) => void;
  onClose: (updated: boolean) => void;
}

const BillingContainerEditModal = ({
  contractId,
  visible,
  onClose,
  billingContainer,
  onBillingContainerChanged,
  onContractChanged,
}: Props) => {
  const [isSending, setIsSending] = useState(false);
  const [billingContainerData, setBillingContainerData] =
    useState<ContractBillingContainer>(
      typeof billingContainer !== 'undefined' && billingContainer !== null
        ? billingContainer
        : defaultBillingContainer,
    );
  const [selectedContactMethod, setSelectedContactMethod] =
    useState<string>('Phone');
  const [selectedContactType, setSelectedContactType] =
    useState<string>('Work');
  const [selectedContactAddress, setSelectedContactAddress] =
    useState<string>('');

  const saveBillingContainer = useCallback(
    async (billingContainerDataToSend) => {
      if (isSending) {
        return;
      }
      setIsSending(true);

      fetch(`${CONTRACTS_ENDPOINT}/${contractId}/billingContainers`, {
        method: 'PUT',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
        body: JSON.stringify(billingContainerDataToSend),
      })
        .then((res) => res.json())
        .then((result: Contract) => {
          if (
            typeof onBillingContainerChanged !== 'undefined' &&
            onBillingContainerChanged !== null
          ) {
            onBillingContainerChanged(billingContainerData);
          }

          if (
            typeof onContractChanged !== 'undefined' &&
            onContractChanged !== null
          ) {
            onContractChanged(result);
          }

          onClose(true);
        })
        .then(() => {
          setIsSending(false);
        });
    },
    [],
  );

  const handleSubmit = () => {
    saveBillingContainer(billingContainerData);
  };

  const handleAddressComponentChange = ({
    target: { value, name },
  }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setBillingContainerData({
      ...billingContainerData,
      address: {
        ...billingContainerData.address,
        [name]: value,
      },
    });
  };

  const handlePhoneRemove = (phone: PhoneNumber) => {
    const phoneNumbers = billingContainerData.contact.phone.filter(
      (checkPhone) =>
        checkPhone.id !== phone.id &&
        checkPhone.type !== phone.type &&
        checkPhone.number !== phone.number,
    );
    setBillingContainerData({
      ...billingContainerData,
      contact: { ...billingContainerData.contact, phone: phoneNumbers },
    });
  };

  const handleEmailRemove = (email: EMailAddress) => {
    const emailAddresses = billingContainerData.contact.email.filter(
      (checkEmail) =>
        checkEmail.id !== email.id &&
        checkEmail.type !== email.type &&
        checkEmail.address !== email.address,
    );
    setBillingContainerData({
      ...billingContainerData,
      contact: { ...billingContainerData.contact, email: emailAddresses },
    });
  };

  const handleContactMethodAdd = () => {
    if (selectedContactMethod === 'Phone') {
      const newPhone: PhoneNumber = {
        id: '',
        type: selectedContactType,
        number: selectedContactAddress,
      };
      const phoneNumbers = [...billingContainerData.contact.phone, newPhone];
      setBillingContainerData({
        ...billingContainerData,
        contact: { ...billingContainerData.contact, phone: phoneNumbers },
      });
      setSelectedContactAddress('');
    } else if (selectedContactMethod === 'Email') {
      const newEmail: EMailAddress = {
        id: '',
        type: selectedContactType,
        address: selectedContactAddress,
      };
      const emailAddresses = [...billingContainerData.contact.email, newEmail];
      setBillingContainerData({
        ...billingContainerData,
        contact: { ...billingContainerData.contact, email: emailAddresses },
      });
      setSelectedContactAddress('');
    }
  };

  const tabItems: TabsProps['items'] = [
    {
      key: 'address',
      label: <TabTitle>Address</TabTitle>,
      children: (
        <>
          <FormItem
            label="Street Address"
            colon={false}
            style={styles.formItem}
          >
            <Input
              name="addressLine1"
              type="text"
              value={billingContainerData.address.addressLine1}
              onChange={handleAddressComponentChange}
            />
            <Input
              name="addressLine2"
              type="text"
              value={billingContainerData.address.addressLine2}
              onChange={handleAddressComponentChange}
            />
            <Input
              name="addressLine3"
              type="text"
              value={billingContainerData.address.addressLine3}
              onChange={handleAddressComponentChange}
            />
          </FormItem>
          <Row>
            <Col span={6}>
              <FormItem label="City" colon={false} style={styles.formItem}>
                <Input
                  name="city"
                  type="text"
                  value={billingContainerData.address.city}
                  onChange={handleAddressComponentChange}
                />
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem
                label="State / Province / Region"
                colon={false}
                style={styles.formItem}
              >
                <Input
                  name="region"
                  type="text"
                  value={billingContainerData.address.region}
                  onChange={handleAddressComponentChange}
                />
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem
                label="Postal Code"
                colon={false}
                style={styles.formItem}
              >
                <Input
                  name="postalCode"
                  type="text"
                  value={billingContainerData.address.postalCode}
                  onChange={handleAddressComponentChange}
                />
              </FormItem>
            </Col>
            <Col span={6} />
          </Row>
          <FormItem label="Country" colon={false} style={styles.formItem}>
            <Input
              name="country"
              type="text"
              value={billingContainerData.address.country}
              onChange={handleAddressComponentChange}
            />
          </FormItem>
        </>
      ),
    },
    {
      key: 'contact',
      label: <TabTitle>Contact</TabTitle>,
      children: (
        <>
          <FormItem label="Name" colon={false} style={styles.formItem}>
            <Input
              name="name"
              type="text"
              value={billingContainerData.contact.name}
              onChange={({
                target: { value, name },
              }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                setBillingContainerData({
                  ...billingContainerData,
                  contact: {
                    ...billingContainerData.contact,
                    [name]: value,
                  },
                });
              }}
            />
          </FormItem>
          {typeof billingContainerData.contact !== 'undefined' &&
            billingContainerData.contact !== null &&
            billingContainerData.contact.phone !== null &&
            billingContainerData.contact.phone.map((phone) => (
              <span key={`${phone.type}:${phone.number}`}>
                <CloseCircleOutlined
                  title="Remove Phone"
                  onClick={() => handlePhoneRemove(phone)}
                  style={styles.removeIcon}
                />
                &nbsp;
                {`${phone.type} Phone: ${phone.number}`}
                <br />
              </span>
            ))}
          {typeof billingContainerData.contact !== 'undefined' &&
            billingContainerData.contact !== null &&
            billingContainerData.contact.email !== null &&
            billingContainerData.contact.email.map((email) => (
              <span key={`${email.type}:${email.address}`}>
                <CloseCircleOutlined
                  title="Remove Email"
                  onClick={() => handleEmailRemove(email)}
                  style={styles.removeIcon}
                />
                &nbsp;
                {`${email.type} Email: ${email.address}`}
                <br />
              </span>
            ))}
          <br />
          <Select
            defaultValue={selectedContactMethod}
            onChange={(value) => setSelectedContactMethod(value)}
          >
            <Option value="Phone">Phone</Option>
            <Option value="Email">Email</Option>
          </Select>
          <Select
            defaultValue={selectedContactType}
            onChange={(value) => setSelectedContactType(value)}
          >
            <Option value="Work">Work</Option>
            <Option value="Mobile">Mobile</Option>
            <Option value="Home">Home</Option>
          </Select>
          <Input
            style={styles.shortInput}
            value={selectedContactAddress}
            onChange={(
              evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
            ) => setSelectedContactAddress(evt.target.value)}
          />
          <PlusCircleOutlined
            title="Add Service"
            onClick={handleContactMethodAdd}
            style={styles.addIcon}
          />
        </>
      ),
    },
  ];

  return (
    <Modal
      open={visible}
      onCancel={() => onClose(false)}
      title={<ModalTitle>Billing Container</ModalTitle>}
      confirmLoading={false}
      width={'40%'}
      footer={
        <ModalFooter>
          <Button onClick={() => onClose(false)}>Close</Button>
          <Button onClick={handleSubmit} type="primary">
            Submit
          </Button>
        </ModalFooter>
      }
    >
      <Form layout="vertical" style={styles.form}>
        <Row>
          <Col span={8}>
            <FormItem
              label="Billing Frequency"
              colon={false}
              style={styles.formItem}
            >
              <Select defaultValue={billingContainerData.billingFrequency}>
                <Option value="Annual">Annual</Option>
                <Option value="Quarterly">Quarterly</Option>
                <Option value="Monthly">Monthly</Option>
                <Option value="Once">Once</Option>
              </Select>
            </FormItem>
          </Col>
          <Col span={8}>
            <FormItem label="Terms" colon={false} style={styles.formItem}>
              <Select defaultValue={billingContainerData.terms}>
                <Option value="Net10">Net10</Option>
                <Option value="Net30">Net30</Option>
                <Option value="Net45">Net45</Option>
                <Option value="Net60">Net60</Option>
              </Select>
            </FormItem>
          </Col>
          <Col span={8} />
        </Row>
        <Tabs defaultActiveKey="address" items={tabItems} />
      </Form>
    </Modal>
  );
};

export default BillingContainerEditModal;
