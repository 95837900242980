import React, { useEffect, useState } from 'react';
import { EditOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Input } from 'antd';

import './policies.css';

const { TextArea } = Input;

interface Props {
  label: string;
  loading: boolean;
  defaultValue?: string;
  onChange: (value: string) => void;
}

const styles = {
  button: {
    marginLeft: '1rem',
  },
};

const PolicyUpdate = ({ label, onChange, loading, defaultValue }: Props) => {
  const [edit, setEdit] = useState<boolean>(false);
  const [value, setValue] = useState<string>(defaultValue || '');

  useEffect(() => {
    if (!loading) {
      setEdit(false);
    }
  }, [loading]);

  return (
    <>
      <div styleName="input-label">{label}</div>
      {edit ? (
        <TextArea
          value={value}
          onChange={event => setValue(event.target.value)}
          autoSize={{
            minRows: 4,
          }}
        />
      ) : (
        <div styleName="policy-text">{defaultValue}</div>
      )}
      <div styleName="buttons-container">
        {edit ? (
          <>
            <Button style={styles.button} onClick={() => setEdit(false)}>
              Cancel
            </Button>
            <Button
              style={styles.button}
              icon={<SaveOutlined />}
              type="primary"
              loading={loading}
              onClick={() => onChange(value)}
            >
              Save
            </Button>
          </>
        ) : (
          <Button
            icon={<EditOutlined />}
            onClick={() => {
              setValue(defaultValue);
              setEdit(true);
            }}
          >
            {`Edit ${label}`}
          </Button>
        )}
      </div>
    </>
  );
};

export default PolicyUpdate;
