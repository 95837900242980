import React, { ReactNode } from 'react';

import './base.css';

type Props = {
  children?: ReactNode;
};

const PageSection = ({ children }: Props) => {
  return <div styleName="page-section">{children}</div>;
};

export default PageSection;
