import React, { useContext } from 'react';
import {
  CopyOutlined,
  DeleteOutlined,
  EllipsisOutlined,
} from '@ant-design/icons';
import { Button, Dropdown, MenuProps } from 'antd';

import TemplatesContext from '@totem/components/surveyV2/templates/TemplatesContext';
import { SurveyTemplate } from '@totem/components/surveyV2/types';

type Props = {
  template: SurveyTemplate;
};

const ActionMenu = ({ template }: Props) => {
  const { onAction } = useContext(TemplatesContext);
  const getActionMenuItems = (): MenuProps['items'] => {
    const menuItems: MenuProps['items'] = [
      {
        key: 'template_duplicate',
        label: 'Duplicate Template',
        title: 'Duplicate Template',
        icon: <CopyOutlined />,
      },
      {
        key: 'template_delete',
        label: 'Delete Template',
        title: 'Delete Template',
        icon: <DeleteOutlined />,
      },
    ];

    return menuItems;
  };

  const onMenuClicked = ({ key }) => {
    // eslint-disable-next-line default-case
    switch (key) {
      case 'template_duplicate':
        // Duplicate
        onAction('duplicate', template);
        break;
      case 'template_delete':
        // Delete
        onAction('delete', template);
        break;
    }
  };

  return (
    <Dropdown
      menu={{ items: getActionMenuItems(), onClick: onMenuClicked }}
      trigger={['click']}
    >
      <Button shape="circle" icon={<EllipsisOutlined />} />
    </Dropdown>
  );
};

export default ActionMenu;
