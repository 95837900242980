import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  FileProtectOutlined,
} from '@ant-design/icons';
import { TablePaginationConfig } from 'antd/es/table';
import { ColumnProps } from 'antd/lib/table';
import { SorterResult } from 'antd/lib/table/interface';
import dayjs from 'dayjs';
import { filesize } from 'filesize';

import FilterAutoComplete from '@totem/components/common/filterAutoComplete/FilterAutoComplete';
import { getFilterKeys } from '@totem/components/common/filterAutoComplete/FilterAutoCompleteTypes';
import OrganizationContext from '@totem/components/common/organizationContext/organizationContext';
import { EMPTY_ID } from '@totem/components/common/referencesModal/ReferencesModal';
import Table from '@totem/components/common/table/Table';
import DeviceBackupContext from '@totem/components/deviceBackups/deviceBackupContext';
import {
  BackupRecord,
  BackupReportResponse,
} from '@totem/components/deviceBackups/types';
import UserProfileContext from '@totem/components/UserProfileContext';
import colors from '@totem/styles/colors';
import { DeviceLastBackupFilterOptions } from '@totem/types/devices';
import { getDashboardPath } from '@totem/utilities/authUtilities';
import controlSystemUtilities from '@totem/utilities/controlSystemsUtilities';
import {
  nullifyIfEmpty,
  stringifyArray,
} from '@totem/utilities/tableFilterUtilities';
import { getFilterOptions } from '@totem/utilities/ticketing';
import {
  isMemberOfAny,
  securityGroupCheckConstraintEmpty,
} from '@totem/utilities/userUtilities';

type Props = {
  page: number;
  pageSize: number;
  total: number;
  backupResults: BackupReportResponse;
  filterOptions: DeviceLastBackupFilterOptions;
  loading: boolean;
};

const DeviceBackupTable = ({
  backupResults,
  filterOptions,
  loading,
  page,
  pageSize,
  total,
}: Props) => {
  const { input, setInput, deviceFilter } = useContext(DeviceBackupContext);
  const { regions, buildings } = useContext(OrganizationContext);
  const { userProfile } = useContext(UserProfileContext);

  const [deviceFilterVisible, setDeviceFilterVisible] =
    useState<boolean>(false);

  const hasContractViewer = isMemberOfAny(
    userProfile,
    ['contract_creator', 'contract_viewer'],
    securityGroupCheckConstraintEmpty,
  );

  const getFileSize = (size: number): string => {
    if (!size) {
      return '-';
    }

    return filesize(size, { standard: 'jedec' });
  };

  const getContractLine = (device: BackupRecord) => {
    if (
      device.contractNumber !== null &&
      device.contractNumber !== '' &&
      device.contractLineNumber !== null
    ) {
      return (
        <span>
          {device.contractNumber}:{device.contractLineNumber}
        </span>
      );
    }
    return <span>-</span>;
  };

  const getBuildingDisplay = (device: BackupRecord) => {
    if (device.buildingId !== null && device.buildingId !== EMPTY_ID) {
      return (
        <Link to={`/dashboard/buildings/${device.buildingId}`}>
          {device.buildingName}
        </Link>
      );
    } else if (device.buildingName !== null) {
      return <span>{device.buildingName}</span>;
    }
    return <span>-</span>;
  };

  const getStatusIcon = (record: BackupRecord) => {
    if (record.contractService === 'Backup Archival') {
      return (
        <FileProtectOutlined
          title="Backup Archived Only"
          style={{
            color: colors.event.criticality.background.ok,
            fontSize: '20px',
          }}
        />
      );
    }

    if (
      typeof record.lastBackedUpAt === 'undefined' ||
      record.lastBackedUpAt === null ||
      dayjs(record.lastBackedUpAt).year() < 2000
    ) {
      return (
        <CloseCircleOutlined
          title="Not Backed Up"
          style={{
            color: colors.event.criticality.background.critical,
            fontSize: '20px',
          }}
        />
      );
    }

    if (record.isBackupStale && record.backupAgeDays >= 3) {
      return (
        <ExclamationCircleOutlined
          title="Backup Aging"
          style={{
            color: colors.event.criticality.background.critical,
            fontSize: '20px',
          }}
        />
      );
    } else if (record.isBackupStale && record.backupAgeDays >= 2) {
      return (
        <ExclamationCircleOutlined
          title="Backup Aging"
          style={{
            color: colors.event.criticality.background.major,
            fontSize: '20px',
          }}
        />
      );
    } else if (record.isBackupStale) {
      return (
        <CheckCircleOutlined
          title="Backup Aging"
          style={{
            color: colors.event.criticality.background.warning,
            fontSize: '20px',
          }}
        />
      );
    }
    return (
      <CheckCircleOutlined
        title="Backup Current"
        style={{
          color: colors.event.criticality.background.ok,
          fontSize: '20px',
        }}
      />
    );
  };

  const columns: ColumnProps<BackupRecord>[] = [
    {
      title: 'Device',
      dataIndex: 'device.displayName',
      key: 'device.displayName',
      render: (_, record: BackupRecord) => (
        <Link to={`/dashboard/devices/${record.deviceId}`}>
          {record.displayName}
        </Link>
      ),
      showSorterTooltip: true,
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        compA.displayName === compB.displayName
          ? 0
          : compA.displayName > compB.displayName
            ? 1
            : -1,
      filterDropdown: () => (
        <FilterAutoComplete
          label="Device"
          visible={deviceFilterVisible}
          onClose={() => setDeviceFilterVisible(false)}
          {...deviceFilter}
        />
      ),
      filterDropdownOpen: deviceFilterVisible,
      onFilterDropdownOpenChange: setDeviceFilterVisible,
      filteredValue: getFilterKeys(deviceFilter.applied),
    },
    {
      title: 'Control System',
      dataIndex: 'controlSystem.name',
      key: 'controlSystemId',
      render: (_, record: BackupRecord) =>
        record.controlSystemId !== EMPTY_ID && (
          <Link
            to={`${getDashboardPath()}/controlsystems/${
              record.controlSystemId
            }`}
          >
            {record.controlSystemName}
          </Link>
        ),
      showSorterTooltip: true,
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        compA.controlSystemName === compB.controlSystemName
          ? 0
          : compA.controlSystemName > compB.controlSystemName
            ? 1
            : -1,
    },
    {
      title: 'Control System Type',
      dataIndex: 'controlSystem.systemType',
      key: 'systemType',
      render: (_, record: BackupRecord) => {
        const option =
          controlSystemUtilities.controlSystemTypeMap[record.controlSystemType];
        return option ? option.text : '';
      },
      showSorterTooltip: true,
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        compA.controlSystemType === compB.controlSystemType
          ? 0
          : compA.controlSystemType > compB.controlSystemType
            ? 1
            : -1,
      filterMultiple: true,
      filteredValue: stringifyArray(input.systemType),
      filters: getFilterOptions(
        typeof filterOptions !== 'undefined' && filterOptions !== null
          ? filterOptions.controlSystemType
          : null,
      ),
    },
    {
      title: 'Date',
      dataIndex: 'date',
      render: (_, record: BackupRecord) => {
        return record.lastBackedUpAt !== null &&
          dayjs(record.lastBackedUpAt).year() > 2000
          ? new Date(record.lastBackedUpAt).toLocaleDateString()
          : '-';
      },
    },
    {
      title: 'Time',
      dataIndex: 'time',
      render: (_, record: BackupRecord) => {
        return record.lastBackedUpAt !== null &&
          dayjs(record.lastBackedUpAt).year() > 2000
          ? new Date(record.lastBackedUpAt).toLocaleTimeString()
          : '-';
      },
    },
    {
      title: 'Backup Size',
      dataIndex: 'size',
      render: (_, record: BackupRecord) => {
        return record.lastBackupSize !== null
          ? getFileSize(record.lastBackupSize)
          : '-';
      },
    },
    {
      title: 'Backup Status',
      dataIndex: 'device.lastBackup.status',
      key: 'isBackupStale',
      render: (_, record: BackupRecord) => {
        return getStatusIcon(record);
      },
      filterMultiple: false,
      filteredValue: nullifyIfEmpty(input.isBackupStale),
      filters: [
        {
          text: (
            <span>
              <CheckCircleOutlined
                title="Current"
                style={{
                  color: colors.event.criticality.background.ok,
                  fontSize: '20px',
                }}
              />
              &nbsp;Current
            </span>
          ),
          value: 'false',
        },
        {
          text: (
            <span>
              <ExclamationCircleOutlined
                title="Stale"
                style={{
                  color: colors.event.criticality.background.critical,
                  fontSize: '20px',
                }}
              />
              &nbsp;Stale
            </span>
          ),
          value: 'true',
        },
      ],
    },
    {
      title: 'Building',
      dataIndex: 'building.name',
      key: 'buildingId',
      render: (_, record: BackupRecord) => getBuildingDisplay(record),
      showSorterTooltip: true,
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        compA.buildingName === compB.buildingName
          ? 0
          : compA.buildingName > compB.buildingName
            ? 1
            : -1,
      filterMultiple: true,
      filteredValue: stringifyArray(input.buildingId),
      filters:
        typeof buildings !== 'undefined' && buildings !== null
          ? buildings.map((building) => {
              return { text: building.name, value: building.id };
            })
          : [],
    },
    {
      title: 'Region',
      dataIndex: 'region.name',
      key: 'regionId',
      render: (_, record: BackupRecord) => record.regionName,
      showSorterTooltip: true,
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        compA.regionName === compB.regionName
          ? 0
          : compA.regionName > compB.regionName
            ? 1
            : -1,
      filterMultiple: true,
      filteredValue: stringifyArray(input.regionId),
      filters:
        typeof regions !== 'undefined' && regions !== null
          ? regions.map((region) => {
              return { text: region.name, value: region.id };
            })
          : [],
    },
  ];

  const getColumns = () => {
    if (hasContractViewer) {
      const contractLineCol = {
        title: 'Contract Line',
        dataIndex: 'device.contractLine.orderNumber',
        key: 'backupStatus',
        render: (_, record: BackupRecord) => {
          return getContractLine(record);
        },
      };

      columns.splice(7, 0, contractLineCol);
    }

    return columns;
  };

  const getBackups = () => {
    if (backupResults !== null) {
      if (backupResults.items !== null) {
        return backupResults.items;
      }
    }
    return [];
  };

  const handleTableChange = (
    updatedPagination: TablePaginationConfig,
    filters: SorterResult<BackupRecord>,
    sorter,
  ) => {
    console.log(`Filters: ${JSON.stringify(filters)}`);

    const { ...params } = filters;

    const sortDir: string = sorter.order === 'descend' ? '-1' : '1';
    let sortField = 'createdBy';
    if (
      typeof sorter.field !== 'undefined' &&
      typeof sorter.order !== 'undefined'
    ) {
      sortField = sorter.field;
    }

    // @ts-ignore
    setInput({
      ...input,
      ...params,
      pageSize: updatedPagination.pageSize,
      page: updatedPagination.current,
      sortField,
      sortDirection: sortDir,
    });
  };

  return (
    <Table
      showSorterTooltip
      columns={getColumns()}
      dataSource={getBackups()}
      loading={loading}
      onChange={handleTableChange}
      pagination={{ current: page, pageSize, total, showSizeChanger: true }}
      rowKey={(record) => record.deviceId}
    />
  );
};

export default DeviceBackupTable;
