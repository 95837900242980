import React, { useContext } from 'react';

import FilterChip from '@totem/components/common/FilterChip';
import FindingsReportContext from '@totem/components/surveyV2/findingsReport/findingsReportContext';
import { FindingReportInput } from '@totem/components/surveyV2/findingsReport/types';

const styles = {
  filterChip: {
    marginLeft: '2rem',
    marginTop: '2rem',
  },
};

const FindingsReportFilterChips = () => {
  const { filterOptions, setInput, input } = useContext(FindingsReportContext);
  const handleMultiFilterDelete = (
    property: keyof FindingReportInput,
    value: string,
  ) => {
    // @ts-ignore
    const updatedValue = input[property].filter((val) => val !== value);
    setInput({
      ...input,
      [property]: updatedValue.length ? updatedValue : null,
    });
  };

  const getBuildingDisplay = (buildingId: string) => {
    for (let idx = 0; idx < filterOptions.building.length; idx++) {
      if (filterOptions.building[idx].value === buildingId) {
        return filterOptions.building[idx].label;
      }
    }
    return buildingId;
  };

  const getRegionDisplay = (regionId: string) => {
    for (let idx = 0; idx < filterOptions.region.length; idx++) {
      if (filterOptions.region[idx].value === regionId) {
        return filterOptions.region[idx].label;
      }
    }
    return regionId;
  };

  return (
    <>
      {input.criticality &&
        input.criticality.map((option) => {
          return (
            <FilterChip
              key={option}
              label={`Criticality: ${option}`}
              style={styles.filterChip}
              onClose={() => handleMultiFilterDelete('criticality', option)}
            />
          );
        })}

      {input.buildingId &&
        input.buildingId.map((option) => {
          return (
            <FilterChip
              key={option}
              label={`Building: ${getBuildingDisplay(option)}`}
              style={styles.filterChip}
              onClose={() => handleMultiFilterDelete('buildingId', option)}
            />
          );
        })}

      {input.regionId &&
        input.regionId.map((option) => {
          return (
            <FilterChip
              key={option}
              label={`Region: ${getRegionDisplay(option)}`}
              style={styles.filterChip}
              onClose={() => handleMultiFilterDelete('regionId', option)}
            />
          );
        })}

      {input.assignee &&
        input.assignee.map((option) => {
          return (
            <FilterChip
              key={option}
              label={`Assignee: ${option}`}
              style={styles.filterChip}
              onClose={() => handleMultiFilterDelete('assignee', option)}
            />
          );
        })}
    </>
  );
};

export default FindingsReportFilterChips;
