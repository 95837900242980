import React, { useContext, useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { CopyOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { TablePaginationConfig } from 'antd/es/table';
import { ColumnProps } from 'antd/lib/table';
import { SorterResult } from 'antd/lib/table/interface';

import OrganizationContext from '@totem/components/common/organizationContext/organizationContext';
import Table from '@totem/components/common/table/Table';
import Tooltip from '@totem/components/common/tooltip/Tooltip';
import FindingsContext from '@totem/components/surveyV2/findings/FindingsContext';
import { Finding } from '@totem/components/surveyV2/findings/types';
import { SurveyQueryResult } from '@totem/components/surveyV2/types';
import { getPlainTextFinding } from '@totem/components/surveyV2/utilities/SurveyUtilities';
import { StringFilterOption } from '@totem/types/common';
import { Criticality } from '@totem/types/criticality';
import { isNotNull } from '@totem/utilities/common';
import { getCriticalityBadge } from '@totem/utilities/criticalityUtilities';
import { toTableFilter } from '@totem/utilities/enumUtilities';
import { sortStringAscending } from '@totem/utilities/tableUtilities';

import '../survey.css';

const FindingsTable = () => {
  const { data, filteredData, loading, input, setInput } =
    useContext(FindingsContext);
  const { regions, buildings } = useContext(OrganizationContext);
  const [assigneeFilterOptions, setAssigneeFilterOptions] = useState<
    StringFilterOption[]
  >([]);

  useEffect(() => {
    if (isNotNull(data)) {
      const optionsAssignee = [];
      for (let idx = 0; idx < data.length; idx++) {
        if (
          optionsAssignee.findIndex(
            (chk) => chk.text === data[idx].assignedUser.email,
          ) === -1
        ) {
          optionsAssignee.push({
            text: data[idx].assignedUser.email,
            value: data[idx].assignedUser.email,
          });
        }
      }
      setAssigneeFilterOptions(
        optionsAssignee.sort((compA, compB) =>
          sortStringAscending(compA.text, compB.text),
        ),
      );
    }
  }, [data]);

  const getRegionFilterOptions = () => {
    if (typeof regions !== 'undefined' && regions !== null) {
      return regions.map((region) => {
        return {
          text: region.name,
          value: region.id,
        };
      });
    }
    return [];
  };

  const getBuildingFilterOptions = () => {
    if (typeof buildings !== 'undefined' && buildings !== null) {
      return buildings.map((building) => {
        return {
          text: building.name,
          value: building.id,
        };
      });
    }
    return [];
  };

  const columns: ColumnProps<Finding>[] = [
    {
      title: 'Findings Description',
      dataIndex: 'description',
      render: (_, finding) => finding.findingDescription || 'N//A',
    },
    {
      title: 'Criticality',
      dataIndex: 'criticality',
      render: (_, finding) => getCriticalityBadge(finding.criticality),
      width: '17rem',
      filters: toTableFilter(Criticality),
      filterMultiple: true,
      filteredValue:
        input.criticality &&
        input.criticality.map((criticality) => criticality.toString()),
    },
    {
      title: 'Control System',
      dataIndex: 'controlSystemName',
      render: (_, finding) =>
        isNotNull(finding.controlSystem) ? finding.controlSystem.name : '',
      sorter: true,
      // @ts-ignore
      sortOrder: input.sortBy === 'controlSystemName' && input.sortOrder,
    },
    {
      title: 'Assignee',
      dataIndex: 'assignee',
      render: (_, finding) =>
        isNotNull(finding.assignedUser) ? (
          <div>{finding.assignedUser?.email}</div>
        ) : (
          ''
        ),
      sorter: true,
      // @ts-ignore
      sortOrder: input.sortBy === 'assignee' && input.sortOrder,
      filters: assigneeFilterOptions,
      filteredValue: input.assignee,
    },
    {
      title: 'Building',
      dataIndex: 'buildingName',
      render: (_, finding) =>
        isNotNull(finding.building) ? finding.building.name : '',
      sorter: true,
      // @ts-ignore
      sortOrder: input.sortBy === 'buildingName' && input.sortOrder,
      filters: getBuildingFilterOptions(),
      filteredValue: input.building,
    },
    {
      title: 'Region',
      dataIndex: 'regionName',
      render: (_, finding) =>
        isNotNull(finding.region) ? finding.region.name : '',
      sorter: true,
      // @ts-ignore
      sortOrder: input.sortBy === 'regionName' && input.sortOrder,
      filters: getRegionFilterOptions(),
      filteredValue: input.region,
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      render: (_, finding: Finding) => (
        <div
          className="center-table-cell"
          onClick={(event) => event.stopPropagation()}
        >
          <CopyToClipboard text={getPlainTextFinding(finding)}>
            <Tooltip title="Copy Finding" placement="left">
              <Button shape="circle" icon={<CopyOutlined />} />
            </Tooltip>
          </CopyToClipboard>
        </div>
      ),
    },
  ];

  const renderDescription = (finding: Finding) => (
    <div styleName="finding-additional-data-container">
      <div styleName="finding-additional-data-label">Source Question</div>
      <div styleName="finding-additional-data-text">{finding.question}</div>

      <div styleName="finding-additional-data-label">Answer</div>
      <div styleName="finding-additional-data-text">
        {finding.findingAnswer}
      </div>

      {isNotNull(finding.moreInfo) && finding.moreInfo !== '' && (
        <>
          <div styleName="finding-additional-data-label">
            Additional Information
          </div>
          <div styleName="finding-additional-data-text">{finding.moreInfo}</div>
        </>
      )}

      <div styleName="finding-additional-data-label">Policy</div>
      <div styleName="finding-additional-data-text">{finding.policy}</div>

      <div styleName="finding-additional-data-label">Resolution</div>
      <div styleName="finding-additional-data-text">{finding.resolution}</div>
    </div>
  );

  const handleTableChange = (
    updatedPagination: TablePaginationConfig,
    filters: SorterResult<SurveyQueryResult>,
    sorter,
  ) => {
    const { ...params } = filters;

    let sortDir: string = sorter.order === 'descend' ? '-1' : '1';
    if (typeof sorter.order === 'undefined' || sorter.order === null) {
      if (
        typeof input.sortDirection !== 'undefined' &&
        input.sortDirection !== null
      ) {
        sortDir = input.sortDirection;
      }
    }

    let sortField = input.sortField ? input.sortField : 'lastOccurrence';
    if (
      typeof sorter.field !== 'undefined' &&
      typeof sorter.order !== 'undefined'
    ) {
      sortField = sorter.field;
    }

    // @ts-ignore
    setInput({
      ...input,
      ...params,
      pageSize: updatedPagination.pageSize,
      page: updatedPagination.current,
      sortField,
      sortDirection: sortDir,
    });
  };

  return (
    <Table
      showSorterTooltip
      columns={columns}
      dataSource={isNotNull(filteredData) ? filteredData : []}
      loading={loading}
      rowKey={(record) => record.surveyId}
      onChange={handleTableChange}
      pagination={{
        current: input.page,
        pageSize: input.pageSize,
        total: isNotNull(filteredData) ? filteredData.length : 0,
        showSizeChanger: true,
      }}
      expandable={{
        expandedRowRender: renderDescription,
      }}
    />
  );
};

export default FindingsTable;
