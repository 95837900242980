import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Col, Row } from 'antd';

import RemoteAccessTotalsContext from '@totem/components/remoteAccess/totals/RemoteAccessTotalsContext';
import TotalValue from '@totem/components/remoteAccess/totals/TotalValue';
import UserProfileContext from '@totem/components/UserProfileContext';
import colors from '@totem/styles/colors';
import { isNotNull } from '@totem/utilities/common';
import {
  isMemberOfAny,
  securityGroupCheckConstraintEmpty,
} from '@totem/utilities/userUtilities';

import './totals.css';

const baseUrl = '/dashboard/remoteAccessUsers';
const TotalsContainer = () => {
  const { data } = useContext(RemoteAccessTotalsContext);
  const { userProfile } = useContext(UserProfileContext);

  const activeAccounts = isNotNull(data)
    ? data.total - (data.neverLoggedIn + data.stale)
    : 0;

  const userCanSeeRemoteAccess = isMemberOfAny(
    userProfile,
    [
      'remote_access_viewer',
      'remote_access_admin',
      'remote_access_configuration_admin',
    ],
    securityGroupCheckConstraintEmpty,
  );

  const styles = {
    borderLeft: {
      borderLeftColor: colors.neutral.silver,
      borderLeftStyle: 'solid' as 'solid',
      borderLeftWidth: 1,
    },
    container: {
      paddingTop: '20px',
      paddingBottom: '20px',
    },
  };

  return (
    <Row style={styles.container}>
      <Col span={6}>
        <TotalValue
          title={'Total Accounts'}
          value={
            !userCanSeeRemoteAccess ? (
              <span>{isNotNull(data) ? data.total.toString(10) : '0'}</span>
            ) : (
              <Link to={`${baseUrl}`}>
                {isNotNull(data) ? data.total.toString(10) : '0'}
              </Link>
            )
          }
        />
      </Col>
      <Col span={6} style={styles.borderLeft}>
        <TotalValue
          title={'Active Accounts'}
          value={
            !userCanSeeRemoteAccess ? (
              <span>{activeAccounts.toString(10)}</span>
            ) : (
              <Link
                to={`${baseUrl}?flags=1`}
                color={colors.event.criticality.background.ok}
              >
                {activeAccounts.toString(10)}
              </Link>
            )
          }
          iconColor={colors.event.criticality.background.ok}
          valueColor={colors.event.criticality.background.ok}
        />
      </Col>
      <Col span={6} style={styles.borderLeft}>
        <TotalValue
          title={'Stale Accounts'}
          value={
            !userCanSeeRemoteAccess ? (
              <span>{isNotNull(data) ? data.stale.toString(10) : '0'}</span>
            ) : (
              <Link to={`${baseUrl}?flags=4`}>
                {isNotNull(data) ? data.stale.toString(10) : '0'}
              </Link>
            )
          }
          iconColor={colors.event.criticality.background.critical}
          valueColor={colors.event.criticality.background.critical}
        />
      </Col>
      <Col span={6} style={styles.borderLeft}>
        <TotalValue
          title={'Never Used'}
          value={
            !userCanSeeRemoteAccess ? (
              <span>
                {isNotNull(data) ? data.neverLoggedIn.toString(10) : '0'}
              </span>
            ) : (
              <Link to={`${baseUrl}?flags=2`}>
                {isNotNull(data) ? data.neverLoggedIn.toString(10) : '0'}
              </Link>
            )
          }
          iconColor={colors.event.criticality.background.minor}
          valueColor={colors.event.criticality.background.minor}
        />
      </Col>
    </Row>
  );
};

export default TotalsContainer;
