import React, { ReactNode, useEffect, useState } from 'react';

import RemoteAccessSessionContext, {
  getDefaultEndDate,
  getDefaultStartDate,
} from '@totem/components/remoteAccess/sessions/RemoteAccessSessionContext';
import { buildFilters } from '@totem/components/remoteAccess/sessions/utilities';
import {
  RemoteAccessSessions,
  RemoteAccessSessionsInput,
  RemoteAccessSessionsRequest,
} from '@totem/components/remoteAccess/types';
import { Event } from '@totem/types/events';
import { getToken } from '@totem/utilities/accountUtilities';
import { REMOTE_ACCESS_SESSIONS_ENDPOINT } from '@totem/utilities/endpoints';

type Props = {
  children?: ReactNode;
  refresh;
  onDataRefreshRequested;
};

const RemoteAccessSessionsContainer = ({ children }: Props) => {
  const [input, updateInput] = useState<RemoteAccessSessionsInput>({
    from: '',
    loading: false,
    regionId: [],
    buildingId: [],
    controlSystemId: [],
    email: '',
    group: [],
    airwall: [],
    startTime: getDefaultStartDate().toISOString(),
    endTime: getDefaultEndDate().toISOString(),
    sessionPageSize: 20,
    sessionPage: 1,
    sessionSortDirection: '1',
    sessionSortField: 'eventTime',
    selectedDate: '',
  });
  const [refreshData, setRefreshData] = useState<boolean>(true);
  const [isSending, setIsSending] = useState(false);
  const [isEventSending, setEventIsSending] = useState(false);
  const [sessionData, setSessionData] = useState<RemoteAccessSessions[]>([]);
  const [eventData, setEventData] = useState<Event[]>([]);
  const [groupOptions, setGroupOptions] = useState<string[]>([]);

  const setInput = (updated: Partial<RemoteAccessSessionsInput>) => {
    updateInput({ ...input, ...updated });
    setRefreshData(true);
  };

  useEffect(() => {
    const groups: string[] = [];
    if (typeof sessionData !== 'undefined' && sessionData !== null) {
      for (let userIdx = 0; userIdx < sessionData.length; userIdx++) {
        for (
          let sessionIdx = 0;
          sessionIdx < sessionData[userIdx].sessions.length;
          sessionIdx++
        ) {
          const group =
            sessionData[userIdx].sessions[sessionIdx].groupDescription;
          // eslint-disable-next-line max-depth
          if (!groups.includes(group)) {
            groups.push(group);
          }
        }
      }
    }
    setGroupOptions(groups.sort());
  }, [sessionData]);

  useEffect(() => {
    if (refreshData) {
      setRefreshData(false);
      if (isSending) {
        return;
      }

      const endpoint = `${REMOTE_ACCESS_SESSIONS_ENDPOINT}`;

      const payload: RemoteAccessSessionsRequest = {
        filters: buildFilters(input),
        startTime: input.startTime,
        endTime: input.endTime,
      };

      fetch(`${endpoint}`, {
        method: 'POST',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
        body: JSON.stringify(payload),
      })
        .then(res => res.json())
        .then((result: RemoteAccessSessions[]) => {
          setSessionData(result);
        })
        .then(() => {
          setIsSending(false);
        });

      setIsSending(true);
    }
  }, [refreshData, input]);

  useEffect(() => {
    if (refreshData) {
      setRefreshData(false);
      if (isEventSending) {
        return;
      }

      const endpoint = `${REMOTE_ACCESS_SESSIONS_ENDPOINT}/events`;

      const payload: RemoteAccessSessionsRequest = {
        filters: buildFilters(input),
        startTime: input.startTime,
        endTime: input.endTime,
      };

      fetch(`${endpoint}`, {
        method: 'POST',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
        body: JSON.stringify(payload),
      })
        .then(res => res.json())
        .then((result: Event[]) => {
          setEventData(result);
        })
        .then(() => {
          setEventIsSending(false);
        });

      setEventIsSending(true);
    }
  }, [refreshData, input]);

  return (
    <RemoteAccessSessionContext.Provider
      value={{
        input,
        setInput,
        sessionData,
        eventData,
        groupOptions,
      }}
    >
      <div>{children}</div>
    </RemoteAccessSessionContext.Provider>
  );
};

export default RemoteAccessSessionsContainer;
