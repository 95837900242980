import React, { useContext, useEffect, useState } from 'react';
import { List, Switch } from 'antd';

import RemoteAccessSettings from '@totem/components/settings/sections/account/remoteAccess/remoteAccessSettings';
import UserProfileContext from '@totem/components/UserProfileContext';
import {
  hasActiveService,
  isMemberOfAny,
  securityGroupCheckConstraintEmpty,
} from '@totem/utilities/userUtilities';

import configuration, { AccountSettingConfiguration } from './configuration';

import './account.css';

const Account = () => {
  const [settings, setSettings] = useState<AccountSettingConfiguration[]>(
    configuration,
  );
  const { userProfile, refetch } = useContext(UserProfileContext);
  const { features } = userProfile?.organization.preferences;

  const showRemoteAccess = hasActiveService(userProfile, 'remote_access');
  const remoteAccessAdmin = isMemberOfAny(
    userProfile,
    ['remote_access_admin', 'remote_access_configuration_admin'],
    securityGroupCheckConstraintEmpty,
  );

  useEffect(() => {
    const _newSettings = configuration.map(config => ({
      ...config,
      status: features[config.key],
    }));
    setSettings(_newSettings);
  }, [features]);

  //#region Templates
  const Item = (_config: AccountSettingConfiguration) => {
    const { status, image, title, subtitle, update } = _config;

    return (
      <React.Fragment>
        <List.Item>
          <List.Item.Meta
            style={{ alignItems: 'inherit' }}
            avatar={image}
            title={<div styleName="item-title">{title}</div>}
            description={subtitle}
          />
          <div>
            <Switch
              checked={status}
              onChange={(checked: boolean) => update(checked, refetch)}
            />
          </div>
        </List.Item>
      </React.Fragment>
    );
  };

  const Content = (
    <List
      itemLayout="horizontal"
      dataSource={settings}
      renderItem={item => <Item {...item} />}
    />
  );

  const Header = (
    <div styleName="header-container">
      <div styleName="header">
        {' '}
        Select the features you want to enable on your Intelligent Buildings
        account.{' '}
      </div>

      <div styleName="toggle"> Off / On </div>
    </div>
  );
  //#endregion

  return (
    <div styleName="container">
      {Header}
      {Content}
      {remoteAccessAdmin && showRemoteAccess && <RemoteAccessSettings />}
    </div>
  );
};

export default Account;
