import React, { ReactNode, useContext, useEffect, useState } from 'react';
import dayjs from 'dayjs';

import TitledJson from '@totem/components/common/TitledJson';
import LayoutContext from '@totem/components/devices/variables/presentation/layout/LayoutContext';
import PageContext from '@totem/components/devices/variables/presentation/layout/PageContext';
import {
  FilterOptions,
  LayoutDataRequest,
  LayoutDataResponse,
  VariableDataRequest,
  VariableDataResponse,
  VariableDataResponseExtended,
  VariableMetaData,
} from '@totem/components/devices/variables/presentation/layout/types';
import { StringFilterOption } from '@totem/types/common';
import { getToken } from '@totem/utilities/accountUtilities';
import { isNotNull } from '@totem/utilities/common';
import { DEVICES_ENDPOINT } from '@totem/utilities/endpoints';

type Props = {
  layoutId: string;
  children?: ReactNode;
};

const LayoutContainer = ({ children, layoutId }: Props) => {
  const { isDebug, setTitle } = useContext(PageContext);
  const [changeCount, setChangeCount] = useState<number>(0);
  const [data, setData] = useState<LayoutDataResponse>(null);
  const [variableData, setVariableData] = useState<
    VariableDataResponseExtended[]
  >([]);
  const [refreshData, setRefreshData] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [filterOptions, setFilterOptions] = useState<FilterOptions>({
    location: {
      filterable: false,
      options: [],
      filter: [],
    },
  });

  // Get Layout
  useEffect(() => {
    if (refreshData) {
      setRefreshData(false);
      setIsLoading(true);

      const request: LayoutDataRequest = {
        layoutId,
        params: {
          paging: {
            page: 1,
            pageSize: 10000,
          },
          sort: {
            field: '',
            direction: 1,
          },
          filters: [],
        },
        metaDataFilters: [],
        startTime: dayjs().toISOString(),
        endTime: dayjs().toISOString(),
      };

      fetch(`${DEVICES_ENDPOINT}/variable/layout`, {
        method: 'POST',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
        body: JSON.stringify(request),
      })
        .then((res) => res.json())
        .then((result: LayoutDataResponse) => {
          setData(result);

          if (isNotNull(result) && isNotNull(result.layout)) {
            if (result.layout.name !== '') {
              setTitle(result.layout.name);
            }
          }

          if (isNotNull(result) && isNotNull(result.uniqueVariables)) {
            setVariableData(
              result.uniqueVariables.map((element) => {
                return {
                  id: element.id,
                  name: '',
                  label: '',
                  type: '',
                  scale: '',
                  isNumeric: true,
                  value: 0,
                  stringValue: '',
                  minValue: '',
                  maxValue: '',
                  lastUpdateTime: '',
                  history: [],
                  status: 'Pending',
                  metaData: {
                    label: '',
                    purpose: '',
                    location: '',
                  },
                };
              }),
            );
          }
        })
        .then(() => {
          setIsLoading(false);
        });
    }
  }, [refreshData]);

  // Create Filter Options
  useEffect(() => {
    if (
      isNotNull(data) &&
      isNotNull(data.sectionResponses) &&
      isNotNull(data.uniqueVariables)
    ) {
      const locations: StringFilterOption[] = [];
      let isLocationFiltered = false;

      for (
        let sectionIdx = 0;
        sectionIdx < data.layout.sections.length;
        sectionIdx++
      ) {
        const currentSection = data.layout.sections[sectionIdx];
        if (
          currentSection.filter.findIndex((elem) => elem === 'location') >= 0
        ) {
          isLocationFiltered = true;
        }
      }

      if (isLocationFiltered) {
        for (let varIdx = 0; varIdx < data.uniqueVariables.length; varIdx++) {
          const currentLocation =
            data.uniqueVariables[varIdx].variable.metaData.location;
          // eslint-disable-next-line max-depth
          if (
            locations.findIndex((elem) => elem.text === currentLocation) < 0
          ) {
            locations.push({ text: currentLocation, value: currentLocation });
          }
        }
      }
      setFilterOptions({
        ...filterOptions,
        location: {
          ...filterOptions.location,
          filterable: isLocationFiltered,
          options: locations,
        },
      });
    }
  }, [data]);

  // Get Variable Data
  const fetchVariableDetails = async (
    request: VariableDataRequest,
    metaData: VariableMetaData,
  ): Promise<VariableDataResponseExtended> => {
    return fetch(`${DEVICES_ENDPOINT}/variable/${request.variableID}`, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      }),
      body: JSON.stringify(request),
    })
      .then((res) => res.json())
      .then((result: VariableDataResponse) => {
        return {
          ...result,
          status: 'Fetched',
          metaData,
        };
      });
  };

  useEffect(() => {
    if (
      isNotNull(data) &&
      isNotNull(data.uniqueVariables) &&
      data.uniqueVariables.length > 0
    ) {
      setIsLoading(true);
      setChangeCount(changeCount + 1);

      const fetchData = async () => {
        const variableDetails = await Promise.all(
          data.uniqueVariables.map((variableElement) =>
            fetchVariableDetails(
              {
                variableID: variableElement.id,
                configID: variableElement.variable.configId,
                purpose: variableElement.variable.metaData.purpose,
                include_history: variableElement.include_history,
                start_time: dayjs().subtract(15, 'day').toISOString(),
                end_time: dayjs().toISOString(),
              },
              variableElement.variable.metaData,
            ),
          ),
        );
        setVariableData(variableDetails);
        setIsLoading(false);
      };

      fetchData();
    }
  }, [data]);

  // END: Get Variable Data

  const handleAction = (action: string) => {
    switch (action) {
      case 'refresh':
        setRefreshData(true);
        break;
      default:
        break;
    }
  };

  return (
    <LayoutContext.Provider
      value={{
        loading: isLoading,
        changeCount,
        layoutData: data,
        variableData,
        onAction: handleAction,
        filterOptions,
        setFilterOptions,
      }}
    >
      {children}
      {isDebug && <TitledJson title="Layout data:" value={data} />}
      {isDebug && (
        <TitledJson title="Layout variableData" value={variableData} />
      )}
      {isDebug && (
        <TitledJson title="Layout filterOptions" value={filterOptions} />
      )}
    </LayoutContext.Provider>
  );
};

export default LayoutContainer;
