import { sortStringAscending } from '@totem/components/events/eventsContainer/utilities';
import colors from '@totem/styles/colors';
import { Filter } from '@totem/types/common';
import { EventFilterOptions } from '@totem/types/events';

export const getSeverityColor = (severity: number) => {
  switch (severity) {
    case 5:
      return colors.event.criticality.background.critical;
    case 4:
      return colors.event.criticality.background.major;
    case 3:
      return colors.event.criticality.background.minor;
    case 2:
      return colors.event.criticality.background.warning;
    case 1:
      return colors.event.criticality.background.info;
    case 0:
      return colors.event.criticality.background.ok;
    default:
      return colors.event.criticality.background.unknown;
  }
};
export const getSeverityTitle = (severity: number) => {
  switch (severity) {
    case 5:
      return 'Critical';
    case 4:
      return 'Major';
    case 3:
      return 'Minor';
    case 2:
      return 'Warning';
    case 1:
      return 'Information';
    case 0:
      return 'Ok';
    default:
      return 'Unknown';
  }
};

export const getSeverityAbbr = (severity: number) => {
  switch (severity) {
    case 5:
      return 'Critical';
    case 4:
      return 'Major';
    case 3:
      return 'Minor';
    case 2:
      return 'Warn';
    case 1:
      return 'Info';
    case 0:
      return 'Ok';
    default:
      return 'Unkn';
  }
};

export const getSeverityFilter = () => {
  return [
    {
      text: 'Critical',
      value: '5',
    },
    {
      text: 'Major',
      value: '4',
    },
    {
      text: 'Minor',
      value: '3',
    },
    {
      text: 'Warning',
      value: '2',
    },
    {
      text: 'Information',
      value: '1',
    },
    {
      text: 'OK',
      value: '0',
    },
    {
      text: 'Unknown',
      value: '-1',
    },
  ];
};

export const getDeviceFilter = (filterOptions: EventFilterOptions) => {
  if (filterOptions === null) {
    return [];
  }

  return filterOptions.device
    .sort((compA, compB) => sortStringAscending(compA.name, compB.name))
    .map((dev) => {
      return {
        text: dev.name,
        value: dev.value,
      };
    });
};

export const getBuildingFilter = (filterOptions: EventFilterOptions) => {
  if (filterOptions === null) {
    return [];
  }

  return filterOptions.building
    .sort((compA, compB) => sortStringAscending(compA.name, compB.name))
    .map((dev) => {
      return {
        text: dev.name,
        value: dev.value,
      };
    });
};

export const getStatusFilter = (filterOptions: EventFilterOptions) => {
  if (filterOptions === null) {
    return [];
  }

  return filterOptions.status
    .sort((compA, compB) => sortStringAscending(compA.name, compB.name))
    .map((sts) => {
      return {
        text: sts.name,
        value: sts.value,
      };
    });
};

export const getSourceTypeFilter = (filterOptions: EventFilterOptions) => {
  if (filterOptions === null) {
    return [];
  }

  return filterOptions.sourceType
    .sort((compA, compB) => sortStringAscending(compA.name, compB.name))
    .map((sts) => {
      return {
        text: sts.name,
        value: sts.value,
      };
    });
};

export const getEventTypeFilter = (filterOptions: EventFilterOptions) => {
  if (filterOptions === null) {
    return [];
  }

  return filterOptions.eventType
    .sort((compA, compB) => sortStringAscending(compA.name, compB.name))
    .map((sts) => {
      return {
        text: sts.name,
        value: sts.value,
      };
    });
};

export const buildFilter = (filters) => {
  const paramFilters: Filter[] = [];

  if (filters.severity !== null) {
    paramFilters.push({
      field: 'severity',
      values: filters.severity,
    });
  }
  if (filters.sourceType !== null) {
    paramFilters.push({
      field: 'sourceType',
      values: filters.sourceType,
    });
  }
  if (filters.eventType !== null) {
    paramFilters.push({
      field: 'eventType',
      values: filters.eventType,
    });
  }
  if (filters.status !== null) {
    paramFilters.push({
      field: 'status',
      values: filters.status,
    });
  }
  if (filters.deviceId !== null) {
    paramFilters.push({
      field: 'deviceId',
      values: filters.deviceId,
    });
  }
  if (filters.buildingId !== null) {
    paramFilters.push({
      field: 'buildingId',
      values: filters.buildingId,
    });
  }

  return paramFilters;
};
