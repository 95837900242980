import React, { useContext } from 'react';

import BuildingContext from '@totem/components/buildingDetail/container/BuildingContext';
import PointOfContacts from '@totem/components/common/pointOfContact/PointOfContacts';
import { PointOfContact } from '@totem/components/common/pointOfContact/types';
import {
  BUILDINGS_ENDPOINT,
  CONFIGURATION_ENDPOINT,
} from '@totem/utilities/endpoints';

const Contacts = () => {
  const { id, data, isLoading, requestRefresh } = useContext(BuildingContext);

  const handleAction = (action: string, contact: PointOfContact) => {
    if (contact.id !== null) {
      // eslint-disable-next-line default-case
      switch (action) {
        case 'Update':
          requestRefresh();
          break;
        case 'Remove':
          requestRefresh();
          break;
      }
    }
  };

  return (
    <PointOfContacts
      objectType="Building"
      objectId={id}
      loading={isLoading}
      title="Building Contacts"
      data={
        typeof data !== 'undefined' &&
        data !== null &&
        data.pointsOfContact !== null
          ? data.pointsOfContact
          : []
      }
      canEdit
      onAction={handleAction}
      contactsBaseUrl={`${BUILDINGS_ENDPOINT}/contacts`}
      contactTypesUrl={`${CONFIGURATION_ENDPOINT}/buildingContactTypes`}
    />
  );
};

export default Contacts;
