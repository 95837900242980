import React, { useContext } from 'react';

import CvePanelSmall from '@totem/components/software/cves/cvePanelSmall';
import CvesContext from '@totem/components/software/cves/cvesContext';
import { isNotNull } from '@totem/utilities/common';
import { sortStringDescending } from '@totem/utilities/tableUtilities';
const CveListSmall = () => {
  const { data } = useContext(CvesContext);

  return (
    <>
      {isNotNull(data) &&
        data
          .sort((compA, compB) => sortStringDescending(compA.id, compB.id))
          .map((cve) => <CvePanelSmall key={cve.id} cve={cve} />)}
    </>
  );
};

export default CveListSmall;
