import { createContext } from 'react';

import { ContractInput } from '@totem/components/contracts/details/types';
import { TableFilterOption } from '@totem/types/common';

interface Context {
  input: ContractInput;
  setInput: (input: ContractInput) => void;
  buildings: TableFilterOption[];
  services: TableFilterOption[];
}

export default createContext<Context>({
  input: {},
  setInput: () => {},
  buildings: [],
  services: [],
});
