import * as R from 'ramda';
import { createSelector } from 'reselect';

import { Criticality } from '@totem/types/criticality';
import {
  Nmap,
  NmapDevice,
  NmapPort,
  NmapSoftwareType,
} from '@totem/types/nmap';

export const deviceOverviewSelector = (data: Nmap) => {
  if (!data) {
    return {
      total: 0,
      reviewed: 0,
      flagged: 0,
      ports: 0,
      os: 0,
      manufacturer: 0,
      other: 0,
    };
  }

  const { devices } = data;

  const total = R.length(devices);

  const flagged = R.pipe(
    // @ts-ignore
    R.countBy((device: NmapDevice) => device.isFlagged),
    R.pathOr(0, ['true']),
    // @ts-ignore
  )(devices);

  const reviewed = R.pipe(
    // @ts-ignore
    R.countBy((device: NmapDevice) => device.isReviewed),
    R.pathOr(0, ['true']),
    // @ts-ignore
  )(devices);

  const ports = R.pipe(
    // @ts-ignore
    R.countBy((device: NmapDevice) => device.isPortsFlagged),
    R.pathOr(0, ['true']),
    // @ts-ignore
  )(devices);

  const os = R.pipe(
    // @ts-ignore
    R.countBy((device: NmapDevice) => device.isSoftwareVersionsFlagged),
    R.pathOr(0, ['true']),
    // @ts-ignore
  )(devices);

  const manufacturer = R.pipe(
    // @ts-ignore
    R.countBy((device: NmapDevice) => device.isManufacturerFlagged),
    R.pathOr(0, ['true']),
    // @ts-ignore
  )(devices);

  const other = R.pipe(
    // @ts-ignore
    R.countBy((device: NmapDevice) => device.isOtherFlagged),
    R.pathOr(0, ['true']),
    // @ts-ignore
  )(devices);

  return {
    total,
    reviewed,
    flagged,
    ports,
    os,
    manufacturer,
    other,
  };
};

export const getSoftwareTypeDisplayFormat = (
  softwareTypes: NmapSoftwareType[],
) => {
  if (!softwareTypes) {
    return [];
  }

  return softwareTypes.map(
    (softwareType: NmapSoftwareType): string =>
      `(${softwareType.accuracy}) ${softwareType.name} `,
  );
};

export const getPortsDisplayFormat = (ports: NmapPort[]) => {
  if (!ports) {
    return [];
  }

  return ports.map(
    (port: NmapPort) =>
      `Port ${port.value} ${port.description ? `: ${port.description}` : ''}`,
  );
};

// export const getFlaggedDevices = (devices: NmapDevice[]): NmapDevice[] =>
//   R.pipe(
//     // @ts-ignore
//     R.filter((device: NmapDevice) => device.isFlagged),
//     R.sortWith([R.descend(R.prop('criticality'))]),
//   )((devices);

export const getFlaggedDevices = (devices: NmapDevice[]): NmapDevice[] =>
  devices.filter((device: NmapDevice) => device.isFlagged);


export const getUnflaggedDevices = (devices: NmapDevice[]): NmapDevice[] =>
  devices.filter((device: NmapDevice) => !device.isFlagged);

export const getScorecardSummarySelector = createSelector(
  devices => devices,
  (devices: NmapDevice[]) => {
    const initialState = {
      port: {
        critical: 0,
        high: 0,
        medium: 0,
        low: 0,
      },
      os: {
        critical: 0,
        high: 0,
        medium: 0,
        low: 0,
      },
      manufacturer: {
        critical: 0,
        high: 0,
        medium: 0,
        low: 0,
      },
      other: {
        critical: 0,
        high: 0,
        medium: 0,
        low: 0,
      },
    };

    if (!devices) {
      return initialState;
    }

    const flaggedDevices = getFlaggedDevices(devices);

    return flaggedDevices.reduce((acc: any, device: NmapDevice) => {
      const accumulator = { ...acc };

      // Port Flags

      if (device.isPortsFlagged && device.criticality === Criticality.Low) {
        accumulator.port.low = accumulator.port.low + 1;
      }

      if (device.isPortsFlagged && device.criticality === Criticality.Medium) {
        accumulator.port.medium = accumulator.port.medium + 1;
      }

      if (device.isPortsFlagged && device.criticality === Criticality.High) {
        accumulator.port.high = accumulator.port.high + 1;
      }

      if (
        device.isPortsFlagged &&
        device.criticality === Criticality.Critical
      ) {
        accumulator.port.critical = accumulator.port.critical + 1;
      }

      // OS Flags

      if (
        device.isSoftwareVersionsFlagged &&
        device.criticality === Criticality.Low
      ) {
        accumulator.os.low = accumulator.os.low + 1;
      }

      if (
        device.isSoftwareVersionsFlagged &&
        device.criticality === Criticality.Medium
      ) {
        accumulator.os.medium = accumulator.os.medium + 1;
      }

      if (
        device.isSoftwareVersionsFlagged &&
        device.criticality === Criticality.High
      ) {
        accumulator.os.high = accumulator.os.high + 1;
      }

      if (
        device.isSoftwareVersionsFlagged &&
        device.criticality === Criticality.Critical
      ) {
        accumulator.os.critical = accumulator.os.critical + 1;
      }

      // Manufacturer Flags

      if (
        device.isManufacturerFlagged &&
        device.criticality === Criticality.Low
      ) {
        accumulator.manufacturer.low = accumulator.manufacturer.low + 1;
      }

      if (
        device.isManufacturerFlagged &&
        device.criticality === Criticality.Medium
      ) {
        accumulator.manufacturer.medium = accumulator.manufacturer.medium + 1;
      }

      if (
        device.isManufacturerFlagged &&
        device.criticality === Criticality.High
      ) {
        accumulator.manufacturer.high = accumulator.manufacturer.high + 1;
      }

      if (
        device.isManufacturerFlagged &&
        device.criticality === Criticality.Critical
      ) {
        accumulator.manufacturer.critical =
          accumulator.manufacturer.critical + 1;
      }

      // Other Flags

      if (device.isOtherFlagged && device.criticality === Criticality.Low) {
        accumulator.other.low = accumulator.other.low + 1;
      }

      if (device.isOtherFlagged && device.criticality === Criticality.Medium) {
        accumulator.other.medium = accumulator.other.medium + 1;
      }

      if (device.isOtherFlagged && device.criticality === Criticality.High) {
        accumulator.other.high = accumulator.other.high + 1;
      }

      if (
        device.isOtherFlagged &&
        device.criticality === Criticality.Critical
      ) {
        accumulator.other.critical = accumulator.other.critical + 1;
      }

      return accumulator;
    }, initialState);
  },
);
