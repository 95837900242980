import actionTypes from './actionTypes';

export const retrieveBuildings = data => ({
  type: actionTypes.REQUEST_BUILDINGS,
  data,
});

export const getBuildingsSuccess = data => ({
  type: actionTypes.BUILDINGS_GET_SUCCESS,
  data,
});

export const getBuildingsFailure = () => ({
  type: actionTypes.BUILDINGS_GET_FAILURE,
});

export const getBuildingsTotalSuccess = data => ({
  type: actionTypes.BUILDINGS_TOTAL_GET_SUCCESS,
  data,
});

export const requestBuildingUpdate = data => ({
  type: actionTypes.REQUEST_BUILDING_UPDATE,
  data,
});

export const buildingUpdateSuccess = data => ({
  type: actionTypes.BUILDING_UPDATE_SUCCESS,
  data,
});

export const buildingUpdateFailure = () => ({
  type: actionTypes.BUILDING_UPDATE_FAILURE,
});

export const requestBuildingDelete = data => ({
  type: actionTypes.REQUEST_BUILDING_DELETE,
  data,
});

export const deleteBuildingSuccess = data => ({
  type: actionTypes.BUILDING_DELETE_SUCCESS,
  data,
});

export const deleteBuildingFailure = () => ({
  type: actionTypes.BUILDING_DELETE_FAILURE,
});
