import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';

import logo from '../../images/logo.ib.svg';

import './registration.css';

const styles = {
  button: {
    width: '100%',
  },
};

const Unverified = () => {
  const navigate = useNavigate();
  return (
    <div styleName="root-container">
      <div className="middle-box text-center loginscreen animate__animated animate__fadeInDown">
        <div>
          <div>
            <div styleName="login-header">
              <img src={logo} styleName="logo" />
            </div>
          </div>
          <div>
            <h3 styleName="heading" style={{ paddingTop: '3em' }}>
              Unverified Account
            </h3>
            <p
              styleName="heading"
              style={{
                paddingTop: '3em',
                paddingBottom: '3em',
                textAlign: 'center',
              }}
            >
              Your account has not been verified. Please contact your
              administrator to gain access.
            </p>
            <Button
              type="primary"
              size="large"
              style={styles.button}
              onClick={() => navigate('/login')}
            >
              Back To Login
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Unverified;
