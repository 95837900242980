import React from 'react';
import { useParams } from 'react-router-dom';
import { Col, Row } from 'antd';

import BasePane from '@totem/components/BasePane';
import ComponentHeader from '@totem/components/ComponentHeader';
import ContentLayout from '@totem/components/ContentLayout';
import PageSectionBody from '@totem/components/PageSectionBody';
import PrimaryContentPane from '@totem/components/PrimaryContentPane';
import FindingReportFilterPanel from '@totem/components/surveyV2/findingsReport/findingReportFilterPanel';
import FindingsReportContainer from '@totem/components/surveyV2/findingsReport/findingsReportContainer';
import FindingsTable from '@totem/components/surveyV2/findingsReport/FindingsTable';
import LeastCompliantByCategory from '@totem/components/surveyV2/findingsReport/leastCompliantByCategory';
import PolicyResults from '@totem/components/surveyV2/findingsReport/PolicyResults';
import SourceQuestion from '@totem/components/surveyV2/findingsReport/SourceQuestion';

const FindingReportPage = () => {
  const { question } = useParams();

  return (
    <ContentLayout>
      <BasePane>
        <PrimaryContentPane>
          <FindingsReportContainer question={[question]}>
            <FindingReportFilterPanel />
            <ComponentHeader title={'Findings Overview'} />
            <SourceQuestion />
            <PageSectionBody>
              <Row>
                <Col span={6}>
                  <PolicyResults />
                </Col>
                <Col span={18}>
                  <LeastCompliantByCategory />
                </Col>
              </Row>
            </PageSectionBody>
            <ComponentHeader title={'Findings'} />
            <FindingsTable />
          </FindingsReportContainer>
        </PrimaryContentPane>
      </BasePane>
    </ContentLayout>
  );
};

export default FindingReportPage;
