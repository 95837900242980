import React, { ReactNode, useEffect, useState } from 'react';

import { ReportInput } from '@totem/components/controlSystemReport/parameterTypes';
import EditModal from '@totem/components/endoflife/EditModal';
import EndOfLifeContext from '@totem/components/endoflife/EndOfLifeContext';
import {
  FilterOptions,
  Product,
  ProductReport,
  ProductReportFilters,
  ProductReportInput,
} from '@totem/components/endoflife/types';
import {
  addFilters,
  buildFilters,
} from '@totem/components/endoflife/utilities';
import { Params } from '@totem/types/common';
import { getToken } from '@totem/utilities/accountUtilities';
import { END_OF_LIFE_ENDPOINT } from '@totem/utilities/endpoints';
import { omitNilOrEmpty } from '@totem/utilities/objectUtilities';

type Props = {
  refresh?: boolean;
  onRecordTotalChanged?: (total: number) => void;
  onDataRefreshRequested?: () => void;
  children?: ReactNode;
  defaultFilters?: ProductReportFilters;
  staticFilters?: ProductReportFilters;
};

const EndOfLifeContainer = ({
  children,
  defaultFilters,
  staticFilters,
  refresh,
  onRecordTotalChanged,
}: Props) => {
  const [input, updateInput] = useState<ProductReportInput>({
    pageSize: 10,
    page: 1,
    sortField: 'productType',
    sortDirection: '1',
    productType:
      typeof defaultFilters !== 'undefined' &&
      defaultFilters !== null &&
      typeof defaultFilters.productType !== 'undefined' &&
      defaultFilters.productType !== null
        ? defaultFilters.productType
        : [],
    product:
      typeof defaultFilters !== 'undefined' &&
      defaultFilters !== null &&
      typeof defaultFilters.product !== 'undefined' &&
      defaultFilters.product !== null
        ? defaultFilters.product
        : [],
    name:
      typeof defaultFilters !== 'undefined' &&
      defaultFilters !== null &&
      typeof defaultFilters.name !== 'undefined' &&
      defaultFilters.name !== null
        ? defaultFilters.name
        : [],
    cycle:
      typeof defaultFilters !== 'undefined' &&
      defaultFilters !== null &&
      typeof defaultFilters.cycle !== 'undefined' &&
      defaultFilters.cycle !== null
        ? defaultFilters.cycle
        : [],
    latestVersion:
      typeof defaultFilters !== 'undefined' &&
      defaultFilters !== null &&
      typeof defaultFilters.latestVersion !== 'undefined' &&
      defaultFilters.latestVersion !== null
        ? defaultFilters.latestVersion
        : [],
    releaseDate:
      typeof defaultFilters !== 'undefined' &&
      defaultFilters !== null &&
      typeof defaultFilters.releaseDate !== 'undefined' &&
      defaultFilters.releaseDate !== null
        ? defaultFilters.releaseDate
        : [],
    endOfSupportDate:
      typeof defaultFilters !== 'undefined' &&
      defaultFilters !== null &&
      typeof defaultFilters.endOfSupportDate !== 'undefined' &&
      defaultFilters.endOfSupportDate !== null
        ? defaultFilters.endOfSupportDate
        : [],
    endOfLifeDate:
      typeof defaultFilters !== 'undefined' &&
      defaultFilters !== null &&
      typeof defaultFilters.endOfLifeDate !== 'undefined' &&
      defaultFilters.endOfLifeDate !== null
        ? defaultFilters.endOfLifeDate
        : [],
    discontinuedDate:
      typeof defaultFilters !== 'undefined' &&
      defaultFilters !== null &&
      typeof defaultFilters.discontinuedDate !== 'undefined' &&
      defaultFilters.discontinuedDate !== null
        ? defaultFilters.discontinuedDate
        : [],
  });
  const [refreshData, setRefreshData] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [reportData, setReportData] = useState<ProductReport>(null);
  const [filterOptions, setFilterOptions] = useState<FilterOptions>({
    productType: [],
  });
  const [selectedProduct, setSelectedProduct] = useState<Product>(null);
  const [selectedAction, setSelectedAction] = useState<string>('');

  const setInput = (updated: Partial<ReportInput>) => {
    updateInput(omitNilOrEmpty({ ...input, ...updated }));
    setRefreshData(true);
  };

  const buildParameters = () => {
    const params: Params = {
      paging: {
        page: input.page,
        pageSize: input.pageSize,
      },
      sort: {
        field: input.sortField,
        direction: +input.sortDirection,
      },
      filters: buildFilters(input),
    };

    params.filters = addFilters(params.filters, staticFilters);

    return params;
  };

  useEffect(() => {
    if (
      typeof onRecordTotalChanged !== 'undefined' &&
      onRecordTotalChanged !== null
    ) {
      onRecordTotalChanged(totalRecords);
    }
  }, [onRecordTotalChanged, totalRecords]);

  useEffect(() => {
    setFilterOptions({
      productType: [],
    });
    return;

    // fetch(`${END_OF_LIFE_ENDPOINT}/filterOptions`, {
    //   method: 'GET',
    //   headers: new Headers({
    //     Authorization: `Bearer ${getToken()}`,
    //   }),
    // })
    //   .then(res => res.json())
    //   .then((result: FilterOptions) => {
    //     setFilterOptions(result);
    //   });
  }, []);

  useEffect(() => {
    if (refreshData) {
      setRefreshData(false);

      setIsLoading(true);
      const params: Params = buildParameters();

      fetch(`${END_OF_LIFE_ENDPOINT}`, {
        method: 'POST',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
        body: JSON.stringify(params),
      })
        .then(res => res.json())
        .then((result: ProductReport) => {
          setReportData(result);
          setTotalRecords(result.paging.totalRecords);
        })
        .then(() => {
          setIsLoading(false);
        });
    }
  }, [refreshData, refresh]);

  const handleAction = (action: string, product: Product) => {
    switch (action) {
      case 'closeEdit_NoRefresh':
        setSelectedAction('');
        setSelectedProduct(null);
        break;
      case 'closeEdit_Refresh':
        setSelectedAction('');
        setSelectedProduct(null);
        setRefreshData(true);
        break;
      default:
        setSelectedAction(action);
        setSelectedProduct(product);
    }
  };

  return (
    <EndOfLifeContext.Provider
      value={{
        input,
        setInput,
        loading: isLoading,
        reportData,
        filterOptions,
        totalRecords,
        onAction: handleAction,
      }}
    >
      <div>{children}</div>
      {selectedAction === 'edit' && (
        <EditModal
          product={selectedProduct}
          visible={selectedAction === 'edit'}
        />
      )}
    </EndOfLifeContext.Provider>
  );
};

export default EndOfLifeContainer;
