import React, { useContext } from 'react';
import {
  DeleteOutlined,
  QuestionCircleOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { Button, Col, Modal, Row } from 'antd';
import { ColumnProps } from 'antd/lib/table';

import Table from '@totem/components/common/table/Table';
import Tooltip from '@totem/components/common/tooltip/Tooltip';
import UserProfileContext from '@totem/components/UserProfileContext';
import { Service } from '@totem/types/organization';
import {
  isMemberOf,
  securityGroupCheckConstraintEmpty,
} from '@totem/utilities/userUtilities';

const styles = {
  iconButton: {
    margin: '0 2px',
  },
};

type Props = {
  organizationServices: Service[];
  onRemove: (service: Service) => void;
  onShowProvisioning: (service: Service) => void;
  loading: boolean;
  canProvision: boolean;
};

const ServicesTable = ({
  loading,
  organizationServices,
  onRemove,
  onShowProvisioning,
  canProvision,
}: Props) => {
  const { userProfile } = useContext(UserProfileContext);
  const handleOnDelete = (service: Service) => {
    Modal.confirm({
      title: 'Remove service from organization?',
      icon: <QuestionCircleOutlined />,
      content: `Are you sure you want to remove the service ${service.displayName}?`,
      cancelText: 'No',
      okText: 'Yes, Remove',
      onOk: () => onRemove(service),
    });
  };

  const isRemoteAccessConfigAdmin = isMemberOf(
    userProfile,
    'remote_access_configuration_admin',
    securityGroupCheckConstraintEmpty,
  );

  const isThreatDetectionConfigAdmin = isMemberOf(
    userProfile,
    'threat_detection_configuration_admin',
    securityGroupCheckConstraintEmpty,
  );

  const columns: ColumnProps<any>[] = [
    {
      title: 'Name',
      dataIndex: 'displayName',
      key: 'displayName',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (_, service: Service) => (
        <Row>
          <Col span={18}>
            <div>{service.status}</div>
          </Col>
          <Col span={6}>
            {service.name === 'remote_access' &&
              canProvision &&
              isRemoteAccessConfigAdmin && (
                <Tooltip title="Provision Service" placement="top">
                  <Button
                    icon={<SettingOutlined />}
                    shape="circle"
                    onClick={(): void => onShowProvisioning(service)}
                    style={styles.iconButton}
                  />
                </Tooltip>
              )}
            {service.name === 'threat_detection' &&
              canProvision &&
              isThreatDetectionConfigAdmin && (
                <Tooltip title="Provision Service" placement="top">
                  <Button
                    icon={<SettingOutlined />}
                    shape="circle"
                    onClick={(): void => onShowProvisioning(service)}
                    style={styles.iconButton}
                  />
                </Tooltip>
              )}
          </Col>
        </Row>
      ),
    },
    {
      title: 'Created',
      dataIndex: 'created_at',
      key: 'created_at',
      render: date => (date ? new Date(date).toLocaleDateString() : '-'),
    },
    {
      title: 'Last Updated',
      dataIndex: 'updated_at',
      key: 'updated_at',
      render: date => (date ? new Date(date).toLocaleDateString() : '-'),
    },
    {
      title: 'Actions',
      width: 30,
      dataIndex: 'additionalOptions',
      key: 'additionalOptions',
      render: (_, service: Service) => (
        <div className="center-table-cell">
          {canProvision && (
            <Tooltip title="Remove Service" placement="top">
              <Button
                icon={<DeleteOutlined />}
                shape="circle"
                onClick={(): void => handleOnDelete(service)}
                style={styles.iconButton}
              />
            </Tooltip>
          )}
        </div>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={organizationServices}
      loading={loading}
      rowKey="id"
    />
  );
};

export default ServicesTable;
