import React from 'react';
import ReactApexChart from 'react-apexcharts';

import { GraphPoint } from '@totem/components/events/graphs/types';
import { defaultChartColors } from '@totem/utilities/charts';

import '../events.css';

type Props = {
  series: GraphPoint[];
  chartOptions?: ApexCharts.ApexOptions;
};

const PieChart = ({ series, chartOptions }: Props) => {
  const getSeriesData = () => {
    const data = series.map((point) => point.y);
    return data;
  };

  const getLabels = () => {
    const data = series.map((point) => point.x);
    return data;
  };

  const options: ApexCharts.ApexOptions = {
    chart: {
      type: 'donut',
      toolbar: { show: true, tools: { download: true } },
    },
    labels: getLabels(),
    colors: defaultChartColors,
    // colors: [
    //   '#B0E0E6',
    //   '#ADD8E6',
    //   '#87CEFA',
    //   '#87CEEB',
    //   '#00BFFF',
    //   '#B0C4DE',
    //   '#1E90FF',
    //   '#6495ED',
    //   '#5F9EA0',
    //   '#4169E1',
    // ],
    legend: {
      width: 200,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
  };

  const getChartOptions = () => {
    if (typeof chartOptions !== 'undefined' && chartOptions !== null) {
      return { ...options, ...chartOptions };
    }
    return options;
  };

  return (
    <div className="app">
      <div className="row">
        <div className="mixed-chart">
          {series.length > 0 && (
            <ReactApexChart
              options={getChartOptions()}
              series={getSeriesData()}
              labels={getLabels()}
              type="donut"
              width="100%"
              height="400px"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default PieChart;
