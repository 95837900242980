import { createContext } from 'react';

import {
  SoftwareCatalogInput,
  SoftwarePackage,
} from '@totem/components/software/software_report/types';

interface Context {
  loading: boolean;
  data: SoftwarePackage[];
  rawData: SoftwarePackage[];
  action: string;
  actionItem: SoftwarePackage;
  onAction?: (action: string, item: SoftwarePackage) => void;
  input: SoftwareCatalogInput;
  setInput: (input: SoftwareCatalogInput) => void;
  selectedPackages: SoftwarePackage[];
  setPackageSelected: (
    softwarePackage: SoftwarePackage,
    selected: boolean,
  ) => void;
}

export default createContext<Context>({
  loading: false,
  data: null,
  rawData: null,
  action: '',
  actionItem: null,
  onAction: () => {},
  input: {},
  setInput: () => {},
  selectedPackages: [],
  setPackageSelected: () => {},
});
