import { createContext } from 'react';

import { BuildingsMap } from '@totem/components/settings/sections/sapientBuildingMapping/types';

interface Context {
  data: BuildingsMap[];
  loading: boolean;
  onAction?: (action: string, buildingMap: BuildingsMap) => void;
  onActionComplete?: (refresh: boolean) => void;
}

export default createContext<Context>({
  data: [],
  loading: false,
  onAction: null,
  onActionComplete: null,
});
