import React from 'react';

import WorkContainer from '@totem/components/surveyV2/work/WorkContainer';
import WorkTabs from '@totem/components/surveyV2/work/WorkTabs';

const WorkPanel = () => {
  return (
    <WorkContainer>
      <WorkTabs />
    </WorkContainer>
  );
};

export default WorkPanel;
