import { call, fork, put, takeEvery } from 'redux-saga/effects';

import actionTypes from '@totem/actions/actionTypes';
import * as mediaActions from '@totem/actions/mediaActions';
import accountUtilities from '@totem/utilities/accountUtilities';
import api from '@totem/utilities/api';
import { MEDIA_ENDPOINT } from '@totem/utilities/endpoints';

export function* getMedia(action) {
  try {
    const {
      subjectId,
      subjectType,
      controlSystemId,
      deviceId,
      userId,
      questionnaireId,
      policyAuditId,
    } = action.data;

    const params = {
      ...(subjectId && { subjectId }),
      ...(subjectType && { subjectType }),
      ...(controlSystemId && { controlSystemId }),
      ...(deviceId && { deviceId }),
      ...(userId && { userId }),
      ...(questionnaireId && { questionnaireId }),
      ...(policyAuditId && { policyAuditId }),
    };

    const endpoint = MEDIA_ENDPOINT;

    const { data } = yield call(api.get, endpoint, params);

    yield put(mediaActions.getMediaSuccess(data));
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      mediaActions.getMediaFailure,
    );
  }
}

/* WATCHERS */
export function* retrieveMedia() {
  yield takeEvery(actionTypes.REQUEST_MEDIA, getMedia);
}

export default [retrieveMedia];
