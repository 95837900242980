import {
  OrganizationHierarchy,
  OrganizationPreferences,
  OrganizationType,
} from './organization';
import { PolicyAuditsConnection } from './policyAudit';
import { QuestionnaireInstancesConnection } from './questionnaire';

export interface SecurityGroup {
  id: string;
  name: string;
  description: string;
  regionId: string;
  buildingId: string;
  controlSystemId: string;
}

export interface Service {
  id: string;
  serviceId: string;
  name: string;
  status: string;
}

export interface EligibleSecurityGroup {
  id: string;
  name: string;
  description: string;
  displayName: string;
  serviceId: string;
  assignableLevels: number;
  assignerRole: number;
  assignerSecurityGroups: string[];
}

export interface SecurityGroupCheckConstraints {
  regionId: string;
  buildingId: string;
  controlSystemId: string;
}

export interface UserOrganization {
  id: string;
  name: string;
  role: number;
  isActive: boolean;
  type: OrganizationType;
  preferences: OrganizationPreferences;
  deactivatedBy: User;
  deactivatedAt: string;
  deactivatedFor: string;
  securityGroups: SecurityGroup[];
  services: Service[];
}

export enum UserStatus {
  Pending,
  'Email Bounced',
  'Delivery Error',
  Invited,
  'Email Clicked',
  Joined,
}

export enum UserAccess {
  'No Access',
  'Access Granted',
  'Has Access',
  'No Access (Removed)',
}

export interface User {
  id: string;
  authId: string;
  email: string;
  firstName: string;
  lastName: string;
  imageUrl: string;
  phoneNumber: string;
  organization?: UserOrganization;
  organizations: UserOrganization[];
  isEmailVerified?: boolean;
  lastLoginDate: string;
  status?: UserStatus;
  createdAt?: string;
  updatedAt?: string;
}

export type UserProfile = User & {
  organization?: UserOrganization;
};

export interface UsersConnection {
  totalCount: number;
  users: User[];
}

export interface UsersConnectionInput {
  ids?: string[];
  statuses?: number[];
  accesses?: number[];
  limit: number;
  offset: number;
  emailSearch?: string;
}

export interface AddEditUser {
  id?: string;
  role: number;
  email: string;
  firstName: string;
  lastName: string;
  lastLoginDate?: string;
}

export interface UserUpdateInput {
  id: string;
  role?: number;
  email?: string;
  firstName?: string;
  lastName?: string;
}

export interface Assignee {
  firstName: string;
  lastName: string;
  imageUrl: string;
  email: string;
  organization?: UserOrganization;
  organizations: UserOrganization[];
}

export interface UserAssignedWork {
  id: string;
  email: string;
  assignedPolicyAudits: PolicyAuditsConnection;
  assignedQuestionnaireInstances: QuestionnaireInstancesConnection;
}

export enum UsersInviteType {
  HEALTH_RISK_SURVEY = 'HEALTH_RISK_SURVEY',
  VENDOR_ORGANIZATION = 'VENDOR_ORGANIZATION',
}

export interface UsersInviteInput {
  type: UsersInviteType;
  emails: string[];
}

export interface DataAccessGroup {
  name: string;
  accessType?: string;
  restrictedTo?: DataAccessItem[];
}

export interface DataAccessItem {
  id: string;
  name: string;
  children?: DataAccessGroup[];
}

export interface UserRestrictions {
  restrictions?: DataAccessGroup[];
  hierarchy: OrganizationHierarchy;
}

export interface Parent {
  name: string;
  id: string;
}

export interface DataAccessPermissionUpdate {
  userId: string;
  updateType: string;
  action: string;
  data: DataAccessPermissionUpdateData;
}

export interface DataAccessPermissionUpdateData {
  organizationId: string;
  regionOption: string;
  regionId: string;
  regionName: string;
  buildingOption: string;
  buildingId: string;
  buildingName: string;
  controlSystemOption: string;
  controlSystemId: string;
  controlSystemName: string;
}
