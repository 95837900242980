import React, { useState } from 'react';
import { RightOutlined } from '@ant-design/icons';

import {
  PolicyAuditError,
  Question as QuestionModel,
  QuestionKey,
  QuestionType,
} from '@totem/types/policyAudit';

import EnumSingleQuestion from './EnumSingleQuestion';
import MoreInfo from './MoreInfo';
import TextQuestion from './TextQuestion';
import UpdatedBy from './UpdatedBy';
import UploadQuestion from './UploadQuestion';

import './policyAuditDetail.css';

interface Props {
  question: QuestionModel;
  onChange: (key: QuestionKey, value: any) => void;
  onMoreInfoChange: (key: QuestionKey, value: string) => void;
  error?: PolicyAuditError;
  disabled: boolean;
}

interface QuestionTypeProps {
  question: QuestionModel;
  onChange: (key: QuestionKey, value: any) => void;
  disabled: boolean;
}

const styles = {
  icon: {
    lineHeight: '2.5rem',
    marginRight: '0.5rem',
  },
};

const getQuestion = (props: QuestionTypeProps) => {
  const { question } = props;
  const { type } = question;

  switch (type) {
    case QuestionType.ShortText: {
      return <TextQuestion {...props} />;
    }
    case QuestionType.Scoring:
    case QuestionType.EnumSingle: {
      return <EnumSingleQuestion {...props} />;
    }
    case QuestionType.Upload: {
      return <UploadQuestion {...props} />;
    }
    default: {
      return <div />;
    }
  }
};

const Question: React.FC<Props> = ({
  question,
  onChange,
  onMoreInfoChange,
  error,
  disabled,
}: Props) => {
  const {
    label,
    required,
    moreInfoVisible,

    answer,
    policy,
  } = question;

  const [expanded, setExpanded] = useState<boolean>(false);

  return (
    <div styleName="question-user-container">
      <div styleName="question">
        <div styleName="question-label">{required ? `${label}*` : label}</div>
        {policy && (
          <div styleName="policy" onClick={() => setExpanded(!expanded)}>
            <div styleName="policy-container">
              <RightOutlined rotate={expanded ? 90 : 0} style={styles.icon} />
              <div styleName={`policy-text ${expanded ? 'expanded' : ''}`}>
                <span styleName="policy-label">Policy: </span>
                {policy}
              </div>
            </div>
          </div>
        )}
        <div styleName="question-type">
          {getQuestion({ question, onChange, disabled })}
        </div>
        {moreInfoVisible && (
          <MoreInfo
            question={question}
            error={error}
            onChange={onMoreInfoChange}
            disabled={disabled}
          />
        )}
      </div>
      {answer && <UpdatedBy question={question} />}
    </div>
  );
};

export default Question;
