import React, { ReactNode } from 'react';

import '../home.css';

type Props = {
  children?: ReactNode;
};

const WidgetCardBody = ({ children }: Props) => {
  return <div styleName="widget-card-body">{children}</div>;
};

export default WidgetCardBody;
