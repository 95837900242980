import React from 'react';

import TicketEmbeddedContainer from '@totem/components/ticketing/listContainer/TicketEmbeddedContainer';
import TicketingListContainerFilterChips from '@totem/components/ticketing/listContainer/TicketingListContainerFilterChips';
import TicketsListContainerTable from '@totem/components/ticketing/listContainer/TicketListContainerTable';
import colors from '@totem/styles/colors';

const styles = {
  container: {
    fontSize: '36px',
    backgroundColor: colors.branding.primaryDark,
    color: colors.neutral.white,
    padding: '20px',
    marginBottom: '15px',
  },
};

type Props = {
  queueId: string;
  queueName: string;
};
const QueueDetails = ({ queueId, queueName }: Props) => {
  return (
    <TicketEmbeddedContainer
      staticFilters={{ queueId: [queueId], status: ['OPEN', 'RESOLVED'] }}
    >
      <div style={styles.container}>Queue: {queueName}</div>
      <TicketingListContainerFilterChips />
      <TicketsListContainerTable />
    </TicketEmbeddedContainer>
  );
};

export default QueueDetails;
