import React, { useCallback, useContext } from 'react';
import {
  CloseCircleFilled,
  DeleteOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { Button, Collapse, Modal } from 'antd';

import Question from '@totem/components/surveyV2/instance/Question';
import SurveyContext from '@totem/components/surveyV2/instanceDetail/SurveyInstanceContext';
const { Panel } = Collapse;
import * as R from 'ramda';

import {
  ScoredCategory,
  SurveyQueryResult,
  SurveyQuestion,
} from '@totem/components/surveyV2/types';
import {
  getMultiInstanceDisplayName,
  instanceHasAnsweredQuestions,
  isQuestionAnswered,
} from '@totem/components/surveyV2/utilities/SurveyUtilities';
import colors from '@totem/styles/colors';
import { getToken } from '@totem/utilities/accountUtilities';
import { V2_SURVEY_ENDPOINT } from '@totem/utilities/endpoints';
import numberUtilities from '@totem/utilities/numberUtilities';
import { sortNumberAscending } from '@totem/utilities/tableUtilities';

import '../survey.css';

const styles = {
  collapseStyle: {
    border: 0,
    borderRadius: 0,
  },
  panelStyle: {
    borderRadius: 0,
  },
  deleteIconStyle: {
    marginRight: '3rem',
    fontSize: 20,
  },
  errorIconStyle: {
    color: colors.utility.error,
  },
};

type HandleDeleteInstance = (event: React.MouseEvent) => void;

export type Props = {
  category: ScoredCategory;
  disabled: boolean;
  filterAnswered: boolean;
};

const Category = ({ category, disabled, filterAnswered }: Props) => {
  const { questions, multiInstanceLabel, multiInstance } = category;
  const { data, errors, setData, setIsLoading } = useContext(SurveyContext);

  const sendNewCategoryInstanceRequest = useCallback(
    async (surveyId: string, categoryId: string, displayName: string) => {
      const payload = { displayName };

      fetch(
        `${V2_SURVEY_ENDPOINT}/instance/${surveyId}/categories/${categoryId}/instance`,
        {
          method: 'POST',
          headers: new Headers({
            Authorization: `Bearer ${getToken()}`,
          }),
          body: JSON.stringify(payload),
        },
      )
        .then((res) => res.json())
        .then((result: SurveyQueryResult) => {
          setData(result);
        })
        .then(() => {
          setIsLoading(false);
        });
    },
    [],
  );

  const sendDeleteCategoryInstanceRequest = useCallback(
    async (surveyId: string, categoryId: string, instanceNumber: number) => {
      fetch(
        `${V2_SURVEY_ENDPOINT}/instance/${surveyId}/categories/${categoryId}/instance/${instanceNumber}`,
        {
          method: 'DELETE',
          headers: new Headers({
            Authorization: `Bearer ${getToken()}`,
          }),
        },
      )
        .then((res) => res.json())
        .then((result: SurveyQueryResult) => {
          setData(result);
        })
        .then(() => {
          setIsLoading(false);
        });
    },
    [],
  );

  const questionHasError = ({ id }: SurveyQuestion): boolean => {
    return Boolean(errors.find(({ questionId }) => questionId === id));
  };

  const getQuestionStyleName = (
    question: SurveyQuestion,
    index: number,
  ): string => {
    let styleName = 'question-container';

    const isEven = numberUtilities.isEven(index);

    if (isEven) {
      styleName += ' even';
    }

    if (questionHasError(question)) {
      styleName += ' error';
    }

    return styleName;
  };

  const renderQuestions = (surveyQuestions: SurveyQuestion[]) => {
    return surveyQuestions
      .filter(
        (question) =>
          (!filterAnswered || !isQuestionAnswered(question)) &&
          question.visible,
      )
      .sort((compA, compB) => sortNumberAscending(compA.index, compB.index))
      .map((question, index) => (
        <div
          key={question.id}
          styleName={getQuestionStyleName(question, index)}
        >
          <Question
            category={category}
            question={question}
            disabled={disabled}
          />
        </div>
      ));
  };

  const handleAddInstance = () => {
    sendNewCategoryInstanceRequest(data.survey.id, category.id, '');
  };

  const handleDeleteInstance =
    (instance: number): HandleDeleteInstance =>
    (event: React.MouseEvent) => {
      event.stopPropagation();

      const hasAnsweredQuestions = instanceHasAnsweredQuestions(
        category,
        instance,
      );

      const content = `You are about to delete a question group${
        hasAnsweredQuestions ? ' with answered questions' : ''
      }. Are you sure you want to continue?`;

      Modal.confirm({
        content,
        title: 'Delete',
        onOk() {
          sendDeleteCategoryInstanceRequest(
            data.survey.id,
            category.id,
            instance,
          );
        },
        okText: 'Delete',
        okType: 'danger',
        icon: <CloseCircleFilled style={styles.errorIconStyle} />,
        cancelText: 'No',
      });
    };

  const renderMultiInstanceCategory = () => {
    const grouped = R.groupWith(R.eqProps('instance'), questions);
    const singleInstance = questions.every(
      (question) => question.instance === 1,
    );

    return (
      <React.Fragment>
        <div styleName="multi-instance-button-container">
          <Button
            type="primary"
            icon={<PlusOutlined />}
            disabled={disabled}
            onClick={handleAddInstance}
          >
            {`Add ${multiInstanceLabel}`}
          </Button>
        </div>
        <Collapse defaultActiveKey={['1']} style={styles.collapseStyle}>
          {grouped.map((quest) => {
            const instance = R.pathOr(1, [0, 'instance'], quest);

            return (
              <Panel
                // @ts-ignore
                key={instance}
                header={getMultiInstanceDisplayName(category, instance)}
                style={styles.panelStyle}
                className="category-panel"
                extra={
                  !singleInstance && (
                    <DeleteOutlined
                      onClick={handleDeleteInstance(instance)}
                      style={styles.deleteIconStyle}
                    />
                  )
                }
              >
                {renderQuestions(quest)}
              </Panel>
            );
          })}
        </Collapse>
      </React.Fragment>
    );
  };

  const renderCategory = () => {
    return renderQuestions(questions);
  };

  return (
    <>
      {multiInstance && renderMultiInstanceCategory()}
      {!multiInstance && renderCategory()}
    </>
  );
};

export default Category;
