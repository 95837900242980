import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FolderOpenOutlined } from '@ant-design/icons';
import { Badge } from 'antd';
import { TablePaginationConfig } from 'antd/es/table';
import { ColumnProps } from 'antd/lib/table';
import { SorterResult } from 'antd/lib/table/interface';

import Table from '@totem/components/common/table/Table';
import AnalysisReportContext from '@totem/components/surveyV2/analysisReport/analysisReportContext';
import { AnalysisReportPolicies } from '@totem/components/surveyV2/analysisReport/types';
import { SurveyQueryResult } from '@totem/components/surveyV2/types';
import { LevelOfEffortToString } from '@totem/components/surveyV2/utilities/SurveyEnumerations';
import colors from '@totem/styles/colors';
import { NumbericFilterOption, StringFilterOption } from '@totem/types/common';
import { LevelOfEffort } from '@totem/types/levelOfEffort';
import { isNotNull } from '@totem/utilities/common';
import { stringifyArray } from '@totem/utilities/tableFilterUtilities';
import {
  sortNumberAscending,
  sortStringAscending,
} from '@totem/utilities/tableUtilities';

const OVERFLOW_COUNT = 9999;

const styles = {
  compliantBadge: {
    background: colors.antd.blue,
  },
  nonCompliantBadge: {
    background: colors.criticality.high,
  },
  link: {
    color: colors.antd.blue,
  },
};

const AuditPolicies = () => {
  const { data, input, loading } = useContext(AnalysisReportContext);
  const [policyNameFilterOptions, setPolicyNameFilterOptions] = useState<
    StringFilterOption[]
  >([]);
  const [categoryFilterOptions, setCategoryFilterOptions] = useState<
    StringFilterOption[]
  >([]);
  const [nistFilterOptions, setNistFilterOptions] = useState<
    StringFilterOption[]
  >([]);
  const [levelOfEffortFilterOptions, setLevelOfEffortFilterOptions] = useState<
    NumbericFilterOption[]
  >([]);

  useEffect(() => {
    if (isNotNull(data)) {
      const optionsPolicyName = [];
      const optionsCategories = [];
      const optionsNist = [];
      const optionsLevelOfEffort = [];
      for (let idx = 0; idx < data.auditPolicies.length; idx++) {
        if (
          optionsPolicyName.findIndex(
            (chk) => chk.text === data.auditPolicies[idx].policyName,
          ) === -1
        ) {
          optionsPolicyName.push({
            text: data.auditPolicies[idx].policyName,
            value: data.auditPolicies[idx].policyName,
          });
        }
        if (
          optionsCategories.findIndex(
            (chk) => chk.text === data.auditPolicies[idx].policyCategory,
          ) === -1
        ) {
          optionsCategories.push({
            text: data.auditPolicies[idx].policyCategory,
            value: data.auditPolicies[idx].policyCategory,
          });
        }
        if (
          optionsNist.findIndex(
            (chk) => chk.text === data.auditPolicies[idx].nistFunction,
          ) === -1
        ) {
          optionsNist.push({
            text: data.auditPolicies[idx].nistFunction,
            value: data.auditPolicies[idx].nistFunction,
          });
        }
        if (
          optionsLevelOfEffort.findIndex(
            (chk) => chk.value === data.auditPolicies[idx].levelOfEffort,
          ) === -1
        ) {
          optionsLevelOfEffort.push({
            text: LevelOfEffortToString(data.auditPolicies[idx].levelOfEffort),
            value: data.auditPolicies[idx].levelOfEffort,
          });
        }
      }
      setPolicyNameFilterOptions(
        optionsPolicyName.sort((compA, compB) =>
          sortStringAscending(compA.text, compB.text),
        ),
      );
      setCategoryFilterOptions(
        optionsCategories.sort((compA, compB) =>
          sortStringAscending(compA.text, compB.text),
        ),
      );
      setNistFilterOptions(
        optionsNist.sort((compA, compB) =>
          sortStringAscending(compA.text, compB.text),
        ),
      );
      setLevelOfEffortFilterOptions(
        optionsLevelOfEffort.sort((compA, compB) =>
          sortNumberAscending(compA.value, compB.value),
        ),
      );
    }
  }, [data]);

  const getData = () => {
    if (isNotNull(data)) {
      return data.auditPolicies;
    }
    return [];
  };

  const columns: ColumnProps<AnalysisReportPolicies>[] = [
    {
      title: 'Audit Policies',
      dataIndex: 'question',
      key: 'question',
      showSorterTooltip: true,
      render: (_, record: AnalysisReportPolicies) => (
        <Link
          to={`/dashboard/findings/policyaudits/v3/${encodeURIComponent(record.question)}`}
        >
          {record.question}
        </Link>
      ),
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        compA.question === compB.question
          ? 0
          : compA.question > compB.question
            ? 1
            : -1,
    },
    {
      title: 'Policy Name',
      dataIndex: 'policyName',
      key: 'policyName',
      showSorterTooltip: true,
      render: (_, record: AnalysisReportPolicies) => record.policyName,
      defaultSortOrder: 'ascend',
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        compA.policyName === compB.policyName
          ? 0
          : compA.policyName > compB.policyName
            ? 1
            : -1,
      filterMultiple: true,
      filteredValue: stringifyArray(input.policyName),
      filters: policyNameFilterOptions,
    },
    {
      title: 'Non-Compliant',
      dataIndex: 'noncompliant',
      // eslint-disable-next-line id-length
      sorter: (compA, compB) => compA.nonCompliant - compB.nonCompliant,
      render: (_, record: AnalysisReportPolicies) => (
        <Badge
          count={record.nonCompliant}
          showZero
          overflowCount={OVERFLOW_COUNT}
          style={styles.nonCompliantBadge}
        />
      ),
    },
    {
      title: 'Compliant',
      dataIndex: 'compliant',
      // eslint-disable-next-line id-length
      sorter: (compA, compB) => compA.compliant - compB.compliant,
      render: (_, record: AnalysisReportPolicies) => (
        <Badge
          count={record.compliant}
          style={styles.compliantBadge}
          showZero
          overflowCount={OVERFLOW_COUNT}
        />
      ),
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
      showSorterTooltip: true,
      render: (_, record: AnalysisReportPolicies) => record.policyCategory,
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        compA.policyCategory === compB.policyCategory
          ? 0
          : compA.policyCategory > compB.policyCategory
            ? 1
            : -1,
      filterMultiple: true,
      filteredValue: stringifyArray(input.category),
      filters: categoryFilterOptions,
    },
    {
      title: 'NIST',
      dataIndex: 'nist',
      key: 'nist',
      showSorterTooltip: true,
      render: (_, record: AnalysisReportPolicies) => record.nistFunction,
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        compA.nistFunction === compB.nistFunction
          ? 0
          : compA.nistFunction > compB.nistFunction
            ? 1
            : -1,
      filterMultiple: true,
      filteredValue: stringifyArray(input.nist),
      filters: nistFilterOptions,
    },
    {
      title: 'Level of Effort',
      dataIndex: 'levelOfEffort',
      render: (value: number) => LevelOfEffort[value],
      sorter: (compA, compB) => compA.levelOfEffort - compB.levelOfEffort,
      filterMultiple: true,
      filteredValue: stringifyArray(input.levelOfEffort),
      filters: levelOfEffortFilterOptions,
    },
  ];

  const handleTableChange = (
    updatedPagination: TablePaginationConfig,
    filters: SorterResult<SurveyQueryResult>,
  ) => {
    const { ...params } = filters;

    // @ts-ignore
    setInput({
      ...input,
      ...params,
    });
  };

  return (
    <Table
      showSorterTooltip
      columns={columns}
      dataSource={getData()}
      loading={loading}
      rowKey={(record) => record.policy}
      onChange={handleTableChange}
      pagination={{
        showSizeChanger: true,
      }}
      expandable={{
        expandedRowRender: () => <span />,
        expandIcon: ({ record }) => (
          <Link
            to={`/dashboard/findings/policyaudits/v3/${encodeURIComponent(record.question)}`}
            state={{ fromPolicyAuditFindings: true }}
          >
            <FolderOpenOutlined
              style={{ cursor: 'pointer', color: colors.opacity.black0_8 }}
            />
          </Link>
        ),
      }}
    />
  );
};

export default AuditPolicies;
