import React, { useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { Alert, Button } from 'antd';
import Modal from 'antd/lib/modal/Modal';

import DetailSpinner from '@totem/components/common/DetailSpinner';
import { GET_USER_ASSIGNED_WORK } from '@totem/graph/user';
import colors from '@totem/styles/colors';
import { User, UserAssignedWork } from '@totem/types/user';
import { ParseStringDate } from '@totem/utilities/dateUtilities';

import AssignedWork from './AssignedWork';

import './users.css';
import ModalTitle from "@totem/components/ModalTitle";
import ModalFooter from "@totem/components/ModalFooter";

interface Props {
  user: User;
  open: boolean;
  onClose: () => void;
}

const styles = {
  button: {
    background: colors.criticality.compliant,
    color: colors.neutral.white,
  },
};

const UserViewDetailsModal = ({ user, open, onClose }: Props) => {
  const [getAssignedWork, { loading, error, data }] = useLazyQuery<{
    user: UserAssignedWork;
  }>(GET_USER_ASSIGNED_WORK);

  useEffect(() => {
    if (open) {
      getAssignedWork({
        variables: {
          id: user.id,
          policyAuditsInput: {},
          questionnaireInstancesInput: {},
        },
      });
    }
  }, [user.id, open, getAssignedWork]);

  const userHasWork =
    data?.user?.assignedPolicyAudits?.totalCount ||
    data?.user?.assignedQuestionnaireInstances?.totalCount;

  return (
    <Modal
      title={<ModalTitle>User Details</ModalTitle>}
      open={open}
      onCancel={onClose}
      footer={
        <ModalFooter>
          <Button onClick={onClose}>Cancel</Button>
          <Button style={styles.button} onClick={onClose}>
            Okay
          </Button>
        </ModalFooter>
      }
    >
      <Alert
        message="This user has been removed from your account."
        type="error"
        showIcon
      />
      <div styleName="modal-content">
        <div styleName="modal-content-subtitle">Date Removed:</div>
        <div>
          {ParseStringDate(user.organization?.deactivatedAt).format('L')}
        </div>
      </div>
      <div styleName="modal-content">
        <div styleName="modal-content-subtitle">Reason for Removal:</div>
        <div>{user.organization?.deactivatedFor}</div>
      </div>
      {loading || error ? (
        <div styleName="modal-spinner-container">
          <DetailSpinner />
        </div>
      ) : (
        <div styleName="modal-content">
          {!!userHasWork && <AssignedWork userAssignedWork={data.user} />}
        </div>
      )}
    </Modal>
  );
};

export default UserViewDetailsModal;
