import { createContext } from 'react';

import { BuildingDetails } from '@totem/components/buildingDetail/container/types';

interface Context {
  id: string;
  data: BuildingDetails;
  isLoading: boolean;
  requestRefresh: () => void;
}

export default createContext<Context>({
  id: null,
  data: null,
  isLoading: false,
  requestRefresh: () => {},
});
