import { createContext } from 'react';

import {
  RemoteAccessOrgRole,
  RemoteAccessRoleUsers,
  RemoteAccessUser,
  RemoteAccessUsersConnectionInput,
} from '@totem/components/remoteAccess/types';

import '../remoteAccess.css';

interface Context {
  input: RemoteAccessUsersConnectionInput;
  setInput: (input: RemoteAccessUsersConnectionInput) => void;
  loading: boolean;
  data: RemoteAccessRoleUsers;
  roleData: RemoteAccessOrgRole;
  filteredData: RemoteAccessUser[];
  totalRecords: number;
  onAction?: (action: string, user: RemoteAccessUser) => void;
  maxInactivityDays: number;
}

export default createContext<Context>({
  input: {},
  setInput: () => {},
  loading: false,
  data: null,
  roleData: null,
  filteredData: [],
  totalRecords: 0,
  onAction: null,
  maxInactivityDays: 0,
});
