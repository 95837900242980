import { call, fork, put, takeEvery } from 'redux-saga/effects';

import actionTypes from '@totem/actions/actionTypes';
import * as healthActions from '@totem/actions/healthActions';
import accountUtilities from '@totem/utilities/accountUtilities';
import api from '@totem/utilities/api';
import { HEALTH_ENDPOINT } from '@totem/utilities/endpoints';

export function* createHealth(action) {
  try {
    const { data } = yield call(
      // @ts-ignore
      api.post,
      HEALTH_ENDPOINT,
      action.data,
    );

    yield put(healthActions.createHealthSuccess(data));
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      healthActions.createHealthFailure,
    );
  }
}

export function* getHealth(action) {
  try {
    const { subjectId } = action.data;

    const params = {
      ...(subjectId && { subjectId }),
    };

    const { data } = yield call(api.get, HEALTH_ENDPOINT, params);

    yield put(healthActions.getHealthSuccess(data));
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      healthActions.getHealthFailure,
    );
  }
}

export function* requestCreateHealth() {
  yield takeEvery(actionTypes.REQUEST_CREATE_HEALTH, createHealth);
}

export function* requestHealth() {
  yield takeEvery(actionTypes.REQUEST_HEALTH, getHealth);
}

export default [requestCreateHealth, requestHealth];
