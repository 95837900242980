import React from 'react';
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import { Card } from 'antd';
import dayjs from 'dayjs';

import colors from '@totem/styles/colors';
import { HealthRiskSurvey } from '@totem/types/healthRiskSurvey';

import './healthRiskSurveyDetail.css';

interface Props {
  survey: HealthRiskSurvey;
}

const styles = {
  card: {
    flex: 1,
    margin: '0 1rem',
  },
  icon: {
    fontSize: 42,
  },
  errorIcon: {
    color: colors.criticality.critical,
  },
  successIcon: {
    color: colors.criticality.positive,
  },
  indicator: {
    height: '1.6rem',
    width: '1.6rem',
    borderRadius: '50%',
  },
};

const DATE_TIME_FORMAT = 'MM/DD/YYYY hh:mm A';

const formatTime = (timestamp: string) => {
  return dayjs(timestamp).format(DATE_TIME_FORMAT);
};

const getDetailMessage = (survey: HealthRiskSurvey) => {
  const { building, isPassing, expiresAt } = survey;
  const { name } = building;

  return isPassing
    ? `You've passed the Health Risk Survey. You are cleared to go onsite to ${name} until ${formatTime(
        expiresAt,
      )}.`
    : `You've failed the Health Risk Survey. You will not be able to go onsite to a building until ${formatTime(
        expiresAt,
      )}. Please speak with your manager to make other arrangements.`;
};

const HealthRiskSurveyDetailResults = ({ survey }: Props) => {
  const { isPassing, submittedAt, expiresAt } = survey;

  return (
    <div styleName="results-container">
      <Card title="Survey Results" style={styles.card}>
        <div styleName="results-title-container">
          {isPassing ? (
            <CheckCircleFilled
              style={{
                ...styles.icon,
                ...(isPassing ? styles.successIcon : styles.errorIcon),
              }}
            />
          ) : (
            <CloseCircleFilled
              style={{
                ...styles.icon,
                ...(isPassing ? styles.successIcon : styles.errorIcon),
              }}
            />
          )}
          <div styleName="results-title">
            {isPassing ? 'Passed Survey' : 'Failed Survey'}
          </div>
        </div>
        <div styleName="results-detail-container">
          <div styleName="results-detail">
            <div styleName="results-detail-label">
              {isPassing ? 'Date Passed:' : 'Date Failed:'}
            </div>
            <div styleName="results-detail-value">
              {dayjs(submittedAt).format(DATE_TIME_FORMAT)}
            </div>
          </div>
          <div styleName="results-detail">
            <div styleName="results-detail-label">
              {isPassing ? 'Valid Until:' : 'Earliest Retake Day'}
            </div>
            <div styleName="results-detail-value">{formatTime(expiresAt)}</div>
          </div>
        </div>
      </Card>
      <Card title="Details" style={styles.card}>
        <div styleName="details-title-container">
          <div
            style={{
              ...styles.indicator,
              background: isPassing
                ? colors.criticality.positive
                : colors.criticality.critical,
            }}
          />
          <div styleName="details-title">
            {isPassing ? 'Cleared To Go Onsite' : 'Building Access Denied'}
          </div>
        </div>
        <div styleName="details-message">{getDetailMessage(survey)}</div>
      </Card>
    </div>
  );
};

export default HealthRiskSurveyDetailResults;
