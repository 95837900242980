import React from 'react';

import BasePane from '@totem/components/BasePane';
import CardBody from '@totem/components/CardBody';
import CardHeader from '@totem/components/CardHeader';
import ContentLayout from '@totem/components/ContentLayout';
import PrimaryContentPane from '@totem/components/PrimaryContentPane';
import { Profile } from '@totem/components/settings/sections/profile/Profile';

const UserProfilePage = () => {
  return (
    <ContentLayout>
      <BasePane>
        <PrimaryContentPane>
          <CardHeader>
            <div>User Profile</div>
          </CardHeader>
          <CardBody>
            <Profile />
          </CardBody>
        </PrimaryContentPane>
      </BasePane>
    </ContentLayout>
  );
};

export default UserProfilePage;
