import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Col, Row } from 'antd';

import ComponentHeader from '@totem/components/ComponentHeader';
import PageSection from '@totem/components/PageSection';
import PageSectionBody from '@totem/components/PageSectionBody';
import TotalValue from '@totem/components/software/vulnerabilitySummary/TotalValue';
import VulnerabilitySummaryContext from '@totem/components/software/vulnerabilitySummary/vulnerabilitySummaryContext';
import colors from '@totem/styles/colors';
import IBDIcon from '@totem/styles/Icon';
import { isNotNull } from '@totem/utilities/common';

const styles = {
  borderLeft: {
    borderLeftColor: colors.neutral.silver,
    borderLeftStyle: 'solid' as 'solid',
    borderLeftWidth: 1,
  },
  container: {
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  selectable: {
    cursor: 'pointer',
  },
  valueSmaller: {
    fontSize: '20px',
    paddingBottom: '5px',
  },
  valueEmphasis: {
    fontSize: '36px',
    fontWeight: 700,
  },
  icon: {
    fontSize: '36px',
  },
};
const VulnerabilitySummaryLayout = () => {
  const { data } = useContext(VulnerabilitySummaryContext);

  return (
    <>
      {isNotNull(data) && data.totalVulnerabilities > 0 && (
        <PageSection>
          <ComponentHeader title={'Software Vulnerabilities'} />
          <PageSectionBody>
            <Row style={styles.container}>
              <Col span={6}>
                <TotalValue
                  title={'Total Vulnerabilities'}
                  icon={<IBDIcon name="CVE" style={styles.icon} />}
                  value={
                    <Link to={'/dashboard/software/vulnerabilityReport'}>
                      <span style={styles.valueEmphasis}>
                        {data.totalVulnerabilities}
                      </span>
                    </Link>
                  }
                />
              </Col>
              <Col span={6} style={styles.borderLeft}>
                {isNotNull(data.buildingsWithVulnerabilities) && (
                  <TotalValue
                    title={`${data.buildingsWithVulnerabilities.total} Buildings`}
                    icon={<IBDIcon name="Building" style={styles.icon} />}
                    value={
                      <span style={styles.valueSmaller}>
                        <Link to={'/dashboard/software/vulnerabilityReport'}>
                          <span style={styles.valueEmphasis}>
                            {
                              data.buildingsWithVulnerabilities
                                .vulnerabilityCount
                            }
                          </span>
                        </Link>{' '}
                        total{' '}
                        <span title={'Common Vulnerabilities and Exposures'}>
                          CVE
                        </span>
                        s
                      </span>
                    }
                  />
                )}
              </Col>
              <Col span={6} style={styles.borderLeft}>
                {isNotNull(data.controlSystemsWithVulnerabilities) && (
                  <TotalValue
                    title={`${data.controlSystemsWithVulnerabilities.total} Control Systems`}
                    icon={<IBDIcon name="Control System" style={styles.icon} />}
                    value={
                      <span style={styles.valueSmaller}>
                        {
                          <Link to={'/dashboard/software/vulnerabilityReport'}>
                            <span style={styles.valueEmphasis}>
                              {
                                data.controlSystemsWithVulnerabilities
                                  .vulnerabilityCount
                              }
                            </span>
                          </Link>
                        }{' '}
                        total{' '}
                        <span title={'Common Vulnerabilities and Exposures'}>
                          CVE
                        </span>
                        s
                      </span>
                    }
                  />
                )}
              </Col>
              <Col span={6} style={styles.borderLeft}>
                {isNotNull(data.softwarePackagesWithVulnerabilities) && (
                  <TotalValue
                    title={`${data.softwarePackagesWithVulnerabilities.total} Software Packages`}
                    icon={<IBDIcon name="Software" style={styles.icon} />}
                    value={
                      <span style={styles.valueSmaller}>
                        {
                          <Link to={'/dashboard/software/vulnerabilityReport'}>
                            <span style={styles.valueEmphasis}>
                              {
                                data.softwarePackagesWithVulnerabilities
                                  .vulnerabilityCount
                              }
                            </span>
                          </Link>
                        }{' '}
                        total{' '}
                        <span title={'Common Vulnerabilities and Exposures'}>
                          CVE
                        </span>
                        s
                      </span>
                    }
                  />
                )}
              </Col>
            </Row>
          </PageSectionBody>
        </PageSection>
      )}
    </>
  );
};

export default VulnerabilitySummaryLayout;
