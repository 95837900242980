import React, { ReactNode } from 'react';

const styles = {
  container: {
    maxWidth: '800px',
    textWrap: 'wrap' as 'wrap',
  },
};

type Props = {
  eventType: string;
};
const EventTypeDescription = ({ eventType }: Props) => {
  const getDetailsDescription = (type: string): ReactNode => {
    switch (type) {
      case 'Anti-Malware':
        return (
          <div style={styles.container}>
            The Antimalware protection layer is based on security content
            content scanning and heuristic analysis (B-HAVE, ATC) against:
            against: viruses, worms, Trojans, spyware, adware, keyloggers,
            keyloggers, rootkits and other types of malicious software.
            <br />
            <br />
            The antimalware scanning technology relies on the following
            following technologies:
            <br />
            <br />
            First, a traditional scanning method is employed where scanned
            scanned content is matched against the security content database.
            database. The security content database contains byte patterns
            patterns specific to known threats and is regularly updated. This
            scanning method is effective against confirmed threats that have
            been researched and documented. However, no matter how promptly the
            security content database is updated, there is always a
            vulnerability window between the time when a new threat is
            discovered and when a fix is released.
            <br />
            <br />
            Against brand-new, undocumented threats, a second layer of
            protection is provided by B-HAVE, the tool's heuristic engine.
            Heuristic algorithms detect malware based on behavioral
            characteristics. B-HAVE runs suspicious files in a virtual
            environment to test their impact on the system and ensure they pose
            no threat. If a threat is detected, the program is prevented from
            running.
          </div>
        );
      case 'Network Attack Defense':
        return (
          <div style={styles.container}>
            The Network Attack Defense module relies on a technology that
            focuses on detecting network attacks designed to gain access on
            endpoints through specific techniques, such as: brute-force attacks,
            network exploits, password stealers, drive-by-download infection
            vectors, bots, and Trojans.
          </div>
        );
      case 'Ransomware Mitigation':
        return (
          <div style={styles.container}>
            Ransomware Mitigation is a feature designed to mitigate the impact
            of an active ransomware attack.
            <br />
            <br />
            When a file is encrypted the randomness (or entropy) of the file
            goes up significantly. Ransomware Mitigation monitors for this
            increase in entropy of files on the disk, and during write attempts.
            When a request is made to encrypt a file (an increase of randomness
            over a certain limit), a temporary backup is created in memory and
            the original file is restored after the file changes are done.
            Importantly this method does not rely on the Volume Shadow Copy
            service or other static backup solutions, as this backup data is
            almost always deleted by threat actors. Requests to delete shadow
            copies are one of the triggers for an EDR incident. Using this
            approach, we provide ransomware mitigation even against previously
            unseen ransomware
            <br />
            <br />
            Ransomware Mitigation uses detection and remediation technologies to
            keep your data safe from ransomware attacks. Whether the ransomware
            is known or new, the tool detects abnormal encryption attempts and
            blocks the process. Afterwards, it recovers the files from backup
            copies and restores them to their original location.
          </div>
        );
      case 'Advanced Anti-Exploit':
        return (
          <div style={styles.container}>
            Powered by machine learning, Advanced Anti-Exploit is a proactive
            technology that stops zero-day attacks carried out through evasive
            exploits.
            <br />
            <br />
            Advanced Anti-Exploit catches the latest exploits in real-time and
            mitigates memory corruption vulnerabilities that can evade other
            security solutions. It protects the most commonly used applications,
            such as browsers, Microsoft Office or Adobe Reader, as well as
            others that you may think of. It watches over system processes and
            protects against security breaches and hijacking existing processes.
          </div>
        );
      case 'Security for Storage':
        return (
          <div style={styles.container}>
            Security for Storage delivers real-time protection for leading
            file-sharing and network-storage systems. System and
            threat-detection algorithm upgrades happen automatically - without
            requiring any efforts from you or creating disruptions for
            end-users.
            <br />
            <br />
            Important
            <br />
            The Security for Storage solution has been validated by the Nutanix
            Ready team on Nutanix Files 4.0.2.
            <br />
            <br />
            Two or more Security Servers Multi-Platform perform the role of ICAP
            server providing antimalware services to Network-Attached Storage
            (NAS) devices and file-sharing systems compliant with the Internet
            Content Adaptation Protocol (ICAP, as defined in RFC 3507).
            <br />
            <br />
            When a user requests to open, read, write, or close a file from a
            laptop, workstation, mobile, or other device, the ICAP client (a NAS
            or file-sharing system) sends a scan request to Security Server and
            receives a verdict regarding the file. Depending on the result, the
            ICAP client allows access, denies access or deletes the file.
          </div>
        );
      case 'Advanced Threat Control':
        return (
          <div style={styles.container}>
            For threats that elude even the heuristic engine, another layer of
            protection is present in the form of Advanced Threat Control (ATC).
            <br />
            <br />
            Advanced Threat Control continuously monitors running processes and
            grades suspicious behaviors such as attempts to: disguise the type
            of process, execute code in another process's space (hijack process
            memory for privilege escalation), replicate, drop files, hide from
            process enumeration applications, etc. Each suspicious behavior
            raises the process rating. When a threshold is reached, an alarm is
            triggered.
            <br />
            <br />
            Full Disk Encryption
            <br />
            This protection layer allows you to provide full disk encryption on
            endpoints, by managing BitLocker on Windows, and FileVault and
            diskutil on macOS. You can encrypt and decrypt boot and non-boot
            volumes, with just a few clicks, while the tool handles the entire
            process, with minimal intervention from the users. Additionally, the
            tool stores the recovery keys needed to unlock volumes when the
            users forget their passwords.
          </div>
        );
      case 'HyperDetect':
        return (
          <div style={styles.container}>
            HyperDetect is an additional layer of security specifically designed
            to detect advanced attacks and suspicious activities in the
            pre-execution stage.
            <br />
            <br />
            HyperDetect enhances the security measures by incorporating an
            additional layer of protection to the current scanning technologies
            such as On-Access, On-Demand, and Traffic Scan. This added layer is
            specifically designed to combat the latest forms of cyber-attacks,
            including advanced persistent threats. HyperDetect significantly
            improves the effectiveness of the Antimalware and Content Control
            protection modules by incorporating advanced heuristics that are
            based on artificial intelligence and machine learning techniques.
            <br />
            <br />
            HyperDetect is a powerful tool that can accurately anticipate and
            identify specific attacks, as well as effectively identify advanced
            malware before it is executed. This advanced technology enables
            HyperDetect to swiftly identify threats, surpassing the capabilities
            of traditional signature-based or behavioural scanning methods.
          </div>
        );
      default:
        return '';
    }
  };

  return getDetailsDescription(eventType);
};

export default EventTypeDescription;
