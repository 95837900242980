import {
  SurveyInstanceConnectionInput,
  SurveyInstanceFilters,
} from '@totem/components/surveyV2/instances/types';
import { Filter } from '@totem/types/common';

export const isFiltered = (input: SurveyInstanceConnectionInput) => {
  if (typeof input === 'undefined' || input === null) {
    return false;
  }
  if (
    (typeof input.region !== 'undefined' &&
      input.region !== null &&
      input.region.length > 0) ||
    (typeof input.building !== 'undefined' &&
      input.building !== null &&
      input.building.length > 0) ||
    (typeof input.template !== 'undefined' &&
      input.template !== null &&
      input.template.length > 0) ||
    (typeof input.startDate !== 'undefined' &&
      input.startDate !== null &&
      input.startDate.length > 0) ||
    (typeof input.dueDate !== 'undefined' &&
      input.dueDate !== null &&
      input.dueDate.length > 0) ||
    (typeof input.submitted !== 'undefined' &&
      input.submitted !== null &&
      input.submitted.length > 0) ||
    (typeof input.isLaunched !== 'undefined' &&
      input.isLaunched !== null &&
      input.isLaunched.length > 0) ||
    (typeof input.health !== 'undefined' &&
      input.health !== null &&
      input.health.length > 0)
  ) {
    return true;
  }
  return false;
};

export const buildSurveyInstanceFilters = (
  input: SurveyInstanceConnectionInput,
) => {
  const paramFilters: Filter[] = [];

  if (
    typeof input.region !== 'undefined' &&
    input.region !== null &&
    input.region.length > 0
  ) {
    paramFilters.push({
      field: 'regionId',
      values: input.region,
    });
  }
  if (
    typeof input.building !== 'undefined' &&
    input.building !== null &&
    input.building.length > 0
  ) {
    paramFilters.push({
      field: 'buildingId',
      values: input.building,
    });
  }
  if (
    typeof input.template !== 'undefined' &&
    input.template !== null &&
    input.template.length > 0
  ) {
    paramFilters.push({
      field: 'templateId',
      values: input.template,
    });
  }
  if (
    typeof input.startDate !== 'undefined' &&
    input.startDate !== null &&
    input.startDate.length > 0
  ) {
    if (input.startDate[0] === 'false') {
      paramFilters.push({
        field: 'startDate',
        values: ['0'],
        operator: '$ne',
      });
    } else {
      paramFilters.push({
        field: 'startDate',
        values: ['0'],
        operator: '$gt',
      });
    }
  }
  if (
    typeof input.dueDate !== 'undefined' &&
    input.dueDate !== null &&
    input.dueDate.length > 0
  ) {
    if (input.dueDate[0] === 'false') {
      paramFilters.push({
        field: 'dueDate',
        values: ['0'],
        operator: '$ne',
      });
    } else {
      paramFilters.push({
        field: 'dueDate',
        values: ['0'],
        operator: '$gt',
      });
    }
  }
  if (
    typeof input.submitted !== 'undefined' &&
    input.submitted !== null &&
    input.submitted.length > 0
  ) {
    paramFilters.push({
      field: 'isSubmitted',
      values: input.submitted,
    });
  }
  if (
    typeof input.isLaunched !== 'undefined' &&
    input.isLaunched !== null &&
    input.isLaunched.length > 0
  ) {
    paramFilters.push({
      field: 'isLaunched',
      values: input.isLaunched,
    });
  }
  if (
    typeof input.health !== 'undefined' &&
    input.health !== null &&
    input.health.length > 0
  ) {
    paramFilters.push({
      field: 'health',
      values: input.health,
    });
  }

  return paramFilters;
};

export const addSurveyInstanceFilters = (
  currentFilters: Filter[],
  input: SurveyInstanceFilters,
) => {
  if (
    typeof input.region !== 'undefined' &&
    input.region !== null &&
    input.region.length > 0
  ) {
    currentFilters.push({
      field: 'regionId',
      values: input.region,
    });
  }
  if (
    typeof input.building !== 'undefined' &&
    input.building !== null &&
    input.building.length > 0
  ) {
    currentFilters.push({
      field: 'buildingId',
      values: input.building,
    });
  }
  if (
    typeof input.template !== 'undefined' &&
    input.template !== null &&
    input.template.length > 0
  ) {
    currentFilters.push({
      field: 'templateId',
      values: input.template,
    });
  }
  if (
    typeof input.startDate !== 'undefined' &&
    input.startDate !== null &&
    input.startDate.length > 0
  ) {
    if (input.startDate[0] === 'false') {
      currentFilters.push({
        field: 'startDate',
        values: ['0'],
        operator: '$ne',
      });
    } else {
      currentFilters.push({
        field: 'startDate',
        values: ['0'],
        operator: '$gt',
      });
    }
  }
  if (
    typeof input.dueDate !== 'undefined' &&
    input.dueDate !== null &&
    input.dueDate.length > 0
  ) {
    if (input.dueDate[0] === 'false') {
      currentFilters.push({
        field: 'dueDate',
        values: ['0'],
        operator: '$ne',
      });
    } else {
      currentFilters.push({
        field: 'dueDate',
        values: ['0'],
        operator: '$gt',
      });
    }
  }
  if (
    typeof input.submitted !== 'undefined' &&
    input.submitted !== null &&
    input.submitted.length > 0
  ) {
    currentFilters.push({
      field: 'isSubmitted',
      values: input.submitted,
    });
  }
  if (
    typeof input.isLaunched !== 'undefined' &&
    input.isLaunched !== null &&
    input.isLaunched.length > 0
  ) {
    currentFilters.push({
      field: 'isLaunched',
      values: input.isLaunched,
    });
  }
  if (
    typeof input.health !== 'undefined' &&
    input.health !== null &&
    input.health.length > 0
  ) {
    currentFilters.push({
      field: 'health',
      values: input.health,
    });
  }

  return currentFilters;
};
