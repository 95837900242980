import React, { useContext, useState } from 'react';

import TicketAgingContext from '@totem/components/ticketing/agingReport/TicketAgingContext';
import TicketEmbeddedContainer from '@totem/components/ticketing/listContainer/TicketEmbeddedContainer';
import TicketingListContainerFilterChips from '@totem/components/ticketing/listContainer/TicketingListContainerFilterChips';
import TicketListContainerTable from '@totem/components/ticketing/listContainer/TicketListContainerTable';

import '../ticketing.css';

const TicketingDrillDownReport = () => {
  const { drillDownValue, drillDownEndPoint, input, reportType } = useContext(
    TicketAgingContext,
  );
  const [refreshRequested, setRefreshRequested] = useState<boolean>(false);

  const refreshAcknowledged = () => {
    setRefreshRequested(false);
  };

  const getLabelSingular = () => {
    switch (reportType) {
      case 'updated':
        return 'Day Without Update';
      case 'created':
        return 'Day Outstanding';
      default:
        return '';
    }
  };

  const getLabelPlural = () => {
    switch (reportType) {
      case 'updated':
        return 'Days Without Update';
      case 'created':
        return 'Days Outstanding';
      default:
        return '';
    }
  };

  return (
    <>
      {drillDownValue ? (
        <div>
          <b style={{ fontSize: 'larger', marginLeft: '25px' }}>
            Tickets:{' '}
            {drillDownValue === '01'
              ? `${drillDownValue} ${getLabelSingular()}:`
              : `${drillDownValue} ${getLabelPlural()}:`}
          </b>
          <TicketEmbeddedContainer
            onDataRefreshRequested={refreshAcknowledged}
            refresh={refreshRequested}
            ticketEndPoint={`${drillDownEndPoint}/${drillDownValue}`}
            staticFilters={input}
          >
            <div styleName="card-body">
              <div styleName="table-chips-container">
                <TicketingListContainerFilterChips />
              </div>
              <TicketListContainerTable />
            </div>
          </TicketEmbeddedContainer>
        </div>
      ) : null}
    </>
  );
};

export default TicketingDrillDownReport;
