import React, { useCallback, useState } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import { Button, Input } from 'antd';
import * as R from 'ramda';
import {
  ArrayParam,
  NumberParam,
  NumericArrayParam,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params';

import BasePane from '@totem/components/BasePane';
import ComponentHeader from '@totem/components/ComponentHeader';
import PrimaryContentPane from '@totem/components/PrimaryContentPane';
import { GET_CONTROL_SYSTEMS } from '@totem/graph/controlSystem';
import { useBuildingFilter } from '@totem/hooks/useBuildingFilter';
import { useConnection } from '@totem/hooks/useConnection';
import { useErrorNotification } from '@totem/hooks/useErrorNotification';
import { useRegionFilter } from '@totem/hooks/useRegionFilter';
import colors from '@totem/styles/colors';
import {
  ControlSystemsConnection,
  ControlSystemsConnectionInput,
} from '@totem/types/controlSystem';
import { debounce } from '@totem/utilities/debounce';
import { omitNilOrEmpty } from '@totem/utilities/objectUtilities';

import ContentLayout from '../ContentLayout';

import ControlSystemCreateModal from './ControlSystemCreateModal';
import ControlSystemsContext from './ControlSystemsContext';
import ControlSystemsTable from './ControlSystemsTable';

import './controlSystems.css';

const styles = {
  icon: {
    color: colors.neutral.gray,
  },
};

const DEBOUNCE_TIME = 500;

const ControlSystems = () => {
  const [open, setOpen] = useState<boolean>(false);

  const [input, updateInput] = useQueryParams({
    limit: withDefault(NumberParam, 10),
    offset: withDefault(NumberParam, 0),
    name: StringParam,
    buildingId: ArrayParam,
    regionId: ArrayParam,
    systemType: NumericArrayParam,
  });

  const setInput = (updated: Partial<ControlSystemsConnectionInput>) =>
    updateInput(omitNilOrEmpty({ ...input, ...updated }), 'replace');

  const { refetch, data, loading, error } = useQuery(GET_CONTROL_SYSTEMS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      input,
      apiKeysConnectionInput: { limit: 1 },
      controlSystemBackupsConnectionInput: { limit: 1 },
      notesConnectionInput: {},
    },
  });

  useErrorNotification(
    error,
    'Failed to retrieve control systems. Please try again.',
  );

  const { totalCount, controlSystems } =
    useConnection<ControlSystemsConnection>({
      data,
      accessor: R.path(['controlSystems']),
      initialData: { totalCount: 0, controlSystems: [] },
    });

  const handleSearch = useCallback(
    debounce(
      (name: string) => setInput({ ...input, name, offset: 0 }),
      DEBOUNCE_TIME,
    ),
    [],
  );

  const buildingFilter = useBuildingFilter(input.buildingId, {
    onChange: (buildingId) => setInput({ ...input, buildingId }),
  });

  const regionFilter = useRegionFilter(input.regionId, {
    onChange: (regionId) => setInput({ ...input, regionId }),
  });

  return (
    <ContentLayout>
      <ControlSystemsContext.Provider
        value={{
          input,
          setInput,
          loading,
          controlSystems,
          totalCount,
          refetch,
          buildingFilter,
          regionFilter,
        }}
      >
        <BasePane>
          <PrimaryContentPane>
            <ComponentHeader
              title={'Control System Management'}
              options={
                <Button type="primary" onClick={() => setOpen(true)}>
                  Add Control System
                </Button>
              }
            />
            <div styleName="search-container">
              <div styleName="search-bar">
                <Input
                  placeholder="Search Control System"
                  prefix={<SearchOutlined style={styles.icon} />}
                  onChange={(event) => handleSearch(event.target.value)}
                />
              </div>
              <div styleName="table-count">
                <span>{`${Math.min(
                  totalCount - input.offset,
                  input.limit,
                )} out of ${totalCount} control systems`}</span>
              </div>
            </div>
            <div styleName="table-container">
              <ControlSystemsTable />
            </div>
          </PrimaryContentPane>
        </BasePane>
        <ControlSystemCreateModal open={open} onClose={() => setOpen(false)} />
      </ControlSystemsContext.Provider>
    </ContentLayout>
  );
};

export default ControlSystems;
