import { gql } from '@apollo/client';

export const API_KEY_FRAGMENT = gql`
  fragment APIKeyDetail on APIKey {
    id
    name
    token
    status
    isRevoked
    connectionType
    createdAt
    updatedAt
  }
`;

export const UPDATE_API_KEY = gql`
  mutation UpdateControlSystem($input: APIKeyUpdateInput!) {
    apiKeyUpdate(input: $input) {
      ...APIKeyDetail
    }
  }
  ${API_KEY_FRAGMENT}
`;
