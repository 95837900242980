import * as React from 'react';
import { useParams } from 'react-router-dom';

import BasePane from '@totem/components/BasePane';
import CardBody from '@totem/components/CardBody';
import ContentLayout from '@totem/components/ContentLayout';
import PrimaryContentPane from '@totem/components/PrimaryContentPane';
import ScoringSection from '@totem/components/surveyV2/instanceDetail/score/ScoringSection';
import SurveyInstanceContainer from '@totem/components/surveyV2/instanceDetail/SurveyInstanceContainer';
const ScoringPage = () => {
  const { id } = useParams();

  return (
    <ContentLayout>
      <SurveyInstanceContainer surveyInstanceId={id}>
        <BasePane>
          <PrimaryContentPane>
            <CardBody>
              <ScoringSection />
            </CardBody>
          </PrimaryContentPane>
        </BasePane>
      </SurveyInstanceContainer>
    </ContentLayout>
  );
};

export default ScoringPage;
