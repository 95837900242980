import React from 'react';
import { Col, Row } from 'antd';

import ScoreByLevelOfEffort from '@totem/components/surveyV2/auditReport/ScoreByLevelOfEffort';
import ScoreSummary from '@totem/components/surveyV2/auditReport/ScoreSummary';

import './auditReport.css';

const AuditScoreOverview = () => {
  return (
    <Row gutter={32}>
      <Col span={12}>
        <ScoreSummary />
      </Col>
      <Col span={12}>
        <ScoreByLevelOfEffort />
      </Col>
    </Row>
  );
};

export default AuditScoreOverview;
