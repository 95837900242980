import React from 'react';
import {
  CheckCircleFilled,
  CloseCircleFilled,
  ExclamationCircleFilled,
} from '@ant-design/icons';

import colors from '@totem/styles/colors';

import './securityIndicator.css';

export enum SecurityStatus {
  SECURE = 'Secure',
  NEEDS_REVIEW = 'Needs Review',
  NOT_SECURE = 'Not Secure',
  PENDING = 'Pending',
}

type Props = {
  status: SecurityStatus;
  style?: React.CSSProperties;
};

const styles = {
  icon: {
    marginRight: '0.8rem',
    fontSize: '14px',
  },
};

const getStatusStyleName = (status: SecurityStatus) => {
  switch (status) {
    case 'Secure':
      return 'secure';
    case 'Needs Review':
      return 'needs-review';
    case 'Not Secure':
      return 'not-secure';
    default:
      return 'pending';
  }
};

const getStatusIcon = (status: SecurityStatus) => {
  switch (status) {
    case 'Secure':
      return (
        <CheckCircleFilled
          style={{ ...styles.icon, color: colors.criticality.positive }}
        />
      );
    case 'Needs Review':
      return (
        <ExclamationCircleFilled
          style={{ ...styles.icon, color: colors.criticality.low }}
        />
      );
    case 'Not Secure':
      return (
        <CloseCircleFilled
          style={{ ...styles.icon, color: colors.criticality.critical }}
        />
      );
    default:
      return (
        <ExclamationCircleFilled
          style={{
            ...styles.icon,
            color: colors.criticality.info,
          }}
        />
      );
  }
};

const SecurityIndicator = ({ status, style }: Props) => {
  return (
    <div styleName={`container ${getStatusStyleName(status)}`} style={style}>
      {getStatusIcon(status)}
      {status}
    </div>
  );
};

export default SecurityIndicator;
