import React from 'react';

import InfoWidget from '@totem/components/ui_components/info_widget/InfoWidget';

const TestWidget = () => {
  return (
    <InfoWidget
      points={[
        {
          id: 'Building Automation System',
          label: 'Building Automation System',
          value: 8,
        },
        {
          id: 'Access Control',
          label: 'Access Control',
          value: 3,
        },
        {
          id: 'Digital Signage',
          label: 'Digital Signage',
          value: 1,
        },
      ]}
      chartTitle="Systems by Type"
      total="106"
      title="Systems"
    />
  );
};

export default TestWidget;
