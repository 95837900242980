import colors from '@totem/styles/colors';
import { PagingResults } from '@totem/types/common';

export interface BackupRecord {
  deviceId: string;
  displayName: string;
  type: string;
  controlSystemId: string;
  controlSystemName: string;
  controlSystemType: number;
  buildingId: string;
  buildingName: string;
  regionId: string;
  regionName: string;
  isBackupStale: boolean;
  lastBackedUpAt: string;
  lastBackupSize: number;
  lastBackupSource: string;
  lastBackupStatus: string;
  backupAgeDays: number;
  contractId: string;
  contractBillingContainerId: string;
  contractLineId: string;
  contractNumber: string;
  contractLineNumber: number;
  contractService: string;
}

export interface BackupReportResponse {
  items: BackupRecord[];
  paging: PagingResults;
}

export interface backupStatusType {
  label: string;
  color: string;
}

export const backupStatusTypeUnknown: backupStatusType = {
  label: 'Unknown',
  color: colors.event.criticality.background.unknown,
};

export const backupStatusTypes: backupStatusType[] = [
  {
    label: 'In Process',
    color: colors.backupStatusDetailed.inProcess,
  },
  {
    label: 'Failed',
    color: colors.backupStatusDetailed.failed,
  },
  {
    label: 'Aborted',
    color: colors.backupStatusDetailed.aborted,
  },
  {
    label: 'Completed',
    color: colors.backupStatusDetailed.completed,
  },
  {
    label: 'Interrupted',
    color: colors.backupStatusDetailed.interupted,
  },
  {
    label: 'Not Started',
    color: colors.backupStatusDetailed.notStarted,
  },
  {
    label: 'Completed With Errors',
    color: colors.backupStatusDetailed.completedWithErrors,
  },
  {
    label: 'In Progress with Faults',
    color: colors.backupStatusDetailed.inProgressWithFaults,
  },
  {
    label: 'Over Quota',
    color: colors.backupStatusDetailed.overQuota,
  },
  {
    label: 'No Selection',
    color: colors.backupStatusDetailed.noSelection,
  },
  {
    label: 'Restarted',
    color: colors.backupStatusDetailed.restarted,
  },
  backupStatusTypeUnknown,
];

export const backupAgingTypes: backupStatusType[] = [
  {
    label: 'Current',
    color: colors.backupStatusDetailed.completed,
  },
  {
    label: 'Stale',
    color: colors.backupStatusDetailed.failed,
  },
  backupStatusTypeUnknown,
];
