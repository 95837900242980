import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';

import notFound from '../../images/not-found.svg';

import './notFound.css';

const NotFoundPage = () => {
  const navigate = useNavigate();
  return (
    <div styleName="container">
      <div styleName="content">
        <img styleName="image" src={notFound} alt="not-found" />
        <div styleName="title">Page not found</div>
        <div styleName="text">
          Hmm, we can't seem to find the page you're looking for. Go back to
          your work dashboard or email Intelligent Buildings support at
          <a styleName="text" href="mailto:support@intelligentbuildings.com">
            {' '}
            support@intelligentbuildings.com
          </a>
        </div>
        <Button type="primary" onClick={() => navigate('/')}>
          Return to Dashboard
        </Button>
      </div>
    </div>
  );
};

export default NotFoundPage;
