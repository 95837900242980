import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import accountUtilities from '@totem/utilities/accountUtilities';

import logo from '../../images/logo.ib.svg';

import './registration.css';

const NoAccess = () => {
  const navigate = useNavigate();
  useEffect(() => {
    accountUtilities.clearToken();
  }, []);

  setTimeout(() => {
    navigate('/login');
  }, 5000);

  return (
    <div styleName="root-container">
      <div className="middle-box text-center loginscreen animate__animated animate__fadeInDown">
        <div>
          <div styleName="login-header">
            <img src={logo} styleName="logo" />
          </div>
          <div>
            <h3
              styleName="heading"
              style={{ paddingTop: '3em', textAlign: 'center' }}
            >
              Your Account Requires a Organization
            </h3>
            <p styleName="heading" style={{ textAlign: 'center' }}>
              Your account is not associated to an organization. Please check
              your email or ask your administrator for support.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoAccess;
