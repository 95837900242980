import React, { useContext } from 'react';
import { Card, Divider, Space } from 'antd';

import CardHeader from '@totem/components/CardHeader';
import CardTitle from '@totem/components/CardTitle';
import CriticalFindingsTooltip from '@totem/components/common/findings/CriticalFindingsTooltip';
import CardBody from '@totem/components/surveyV2/instanceDetail/CardBody';
import SurveyContext from '@totem/components/surveyV2/instanceDetail/SurveyInstanceContext';

import '../survey.css';

const styles = {
  divider: {
    height: '12rem',
  },
};

const OverviewCard = () => {
  const { data } = useContext(SurveyContext);
  const { survey } = data;
  const { totalQuestions, completedQuestions, criticalFindings } = survey;

  const percentage = totalQuestions
    ? `${Math.round((completedQuestions / totalQuestions) * 100)}%`
    : '0%';

  return (
    <Card
      title={
        <CardHeader>
          <CardTitle>Overview</CardTitle>
        </CardHeader>
      }
      bordered={false}
    >
      <CardBody styleName="overview-body">
        <Space align="center" direction="horizontal" size="small">
          <div styleName="progress-container">
            <div styleName="progress-title">Progress</div>
            <div styleName="progress">
              <div styleName="percentage">{percentage}</div>
              <div styleName="ratio">{`${completedQuestions} / ${totalQuestions}`}</div>
            </div>
          </div>
          <Divider type="vertical" style={styles.divider} />
          <div styleName="critical-findings-container">
            <div styleName="critical-findings-title-container">
              <div styleName="critical-findings-title">CFs</div>
              <CriticalFindingsTooltip />
            </div>
            <div styleName="critical-findings">{criticalFindings}</div>
          </div>
          {/* TODO: Implement CSV Export */}
          {/*<div styleName="csv-export">*/}
          {/*  <Button onClick={onCSVExport}>Export CSV</Button>*/}
          {/*</div>*/}
        </Space>
      </CardBody>
    </Card>
  );
};

export default OverviewCard;
