import React, { useState } from 'react';
import { UpOutlined } from '@ant-design/icons';

import colors from '@totem/styles/colors';
import { isNotNull } from '@totem/utilities/common';

import './sidebar.css';

export interface Props {
  children: React.ReactNode;
  tabLabel: string;
  backgroundColor?: string;
  defaultOpen?: boolean;
}

const styles = {
  sidebar: {
    background: colors.neutral.antd,
    borderRight: `2px solid ${colors.branding.accent}`,
    position: 'relative' as any,
    overflow: 'visible',
    transition: 'min-width 0.5s',
  },
  tabLabel: {
    paddingLeft: '5px',
  },
  open: {
    width: '40rem',
    minWidth: '40rem',
    transition: 'width 0.5s, min-width 0.5s',
  },
  closed: {
    width: 0,
    minWidth: 0,
    transition: 'width 0.5s, min-width 0.5s',
  },
  hidden: {
    visibility: 'hidden' as any,
    transition: 'visibility 0.2s',
  },
  visible: {
    visibility: 'visible' as any,
    transition: 'visibility 0.2s',
  },
  icon: {
    fontSize: '1rem',
    marginLeft: '0.5rem',
  },
};

const getRootStyle = (open: boolean, background?: string) => {
  const root = background ? { ...styles.sidebar, background } : styles.sidebar;
  return open ? { ...root, ...styles.open } : { ...root, ...styles.closed };
};

const Sidebar = ({
  children,
  tabLabel,
  backgroundColor,
  defaultOpen,
}: Props) => {
  const [open, setOpen] = useState<boolean>(
    isNotNull(defaultOpen) ? defaultOpen : true,
  );

  return (
    <div style={getRootStyle(open, backgroundColor)}>
      <div style={open ? styles.visible : styles.hidden}>{children}</div>
      <div
        styleName={`collapse-button ${open ? '' : 'collapsed'}`}
        onClick={() => setOpen(!open)}
      >
        <span style={styles.tabLabel}>{tabLabel}</span>
        <UpOutlined style={styles.icon} rotate={open ? 0 : 180} />
      </div>
    </div>
  );
};

export default Sidebar;
