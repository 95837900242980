import React, { useCallback, useContext, useState } from 'react';
import { Collapse, CollapseProps, Flex } from 'antd';

import TenantsContext from '@totem/components/tenants/tenantsPage/TenantsContext';
import TenantUserAddButton from '@totem/components/tenants/tenantsPage/TenantUserAddButton';
import { Tenant, TenantUser } from '@totem/components/tenants/types';
import IBDIcon from '@totem/styles/Icon';
import { getToken } from '@totem/utilities/accountUtilities';
import { TENANTS_ENDPOINT } from '@totem/utilities/endpoints';

type Props = {
  tenant: Tenant;
};

const TenantTableUsers = ({ tenant }: Props) => {
  const [isSending, setIsSending] = useState<boolean>(false);
  const { onAction } = useContext(TenantsContext);

  const sendUserDelete = useCallback(
    async (tenantId: string, user: TenantUser) => {
      if (isSending) {
        return;
      }
      setIsSending(true);
      fetch(`${TENANTS_ENDPOINT}/${tenantId}/users/${user.id}`, {
        method: 'DELETE',
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getToken()}`,
        }),
      }).then(() => {
        setIsSending(false);
        onAction('refresh', null);
      });
    },
    [isSending],
  );

  const handleDelete = (user: TenantUser) => {
    sendUserDelete(tenant.id, user);
  };

  const getItems = () => {
    const items: CollapseProps['items'] = tenant.users.map((user) => {
      return {
        key: user.id,
        showArrow: false,
        collapsible: 'icon',
        label: (
          <Flex justify="space-between">
            <div>{user.email}</div>
            <div>
              <IBDIcon
                name={'Delete'}
                title={`Delete ${user.email}`}
                onClick={() => handleDelete(user)}
              />
            </div>
          </Flex>
        ),
      };
    });
    return items;
  };

  return (
    <>
      <Collapse items={getItems()} />
      <br />
      <TenantUserAddButton tenant={tenant} />
    </>
  );
};

export default TenantTableUsers;
