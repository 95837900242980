import React, { ReactNode, useEffect, useState } from 'react';

import {
  SoftwareVulnerabilityReportResponse,
  VulnerabilityReportFilterOptions,
  VulnerabilityReportInput,
} from '@totem/components/software/vulnerabilityReport/types';
import { buildParameters } from '@totem/components/software/vulnerabilityReport/utilities';
import VulnerabilityReportContext from '@totem/components/software/vulnerabilityReport/vulnerabilityReportContext';
import { getToken } from '@totem/utilities/accountUtilities';
import { isNotNull } from '@totem/utilities/common';
import { DEVICES_ENDPOINT } from '@totem/utilities/endpoints';
import { omitNilOrEmpty } from '@totem/utilities/objectUtilities';

type Props = {
  regionId?: string[];
  buildingId?: string[];
  controlSystemId?: string[];
  deviceId?: string[];
  children?: ReactNode;
};

const VulnerabilityReportContainer = ({
  regionId,
  buildingId,
  controlSystemId,
  deviceId,
  children,
}: Props) => {
  const [input, updateInput] = useState<VulnerabilityReportInput>({
    page: 1,
    pageSize: 50,
    sortField: 'packageName',
    sortDirection: '1',
    regionId,
    buildingId,
    controlSystemId,
    deviceId,
  });
  const [refreshData, setRefreshData] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isFiltersLoading, setIsFiltersLoading] = useState<boolean>(false);
  const [data, setData] = useState<SoftwareVulnerabilityReportResponse>(null);
  const [filterOptions, setFilterOptions] =
    useState<VulnerabilityReportFilterOptions>(null);
  const [action, setAction] = useState<string>('');

  const setInput = (updated: Partial<VulnerabilityReportInput>) => {
    updateInput(omitNilOrEmpty({ ...input, ...updated }));
    setRefreshData(true);
  };

  useEffect(() => {
    setRefreshData(true);
  }, [regionId, buildingId, controlSystemId]);

  useEffect(() => {
    if (refreshData) {
      setRefreshData(false);
      setIsLoading(true);

      const uri = `${DEVICES_ENDPOINT}/software/vulnerabilityReport`;

      const payload = buildParameters(input);

      fetch(uri, {
        method: 'POST',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
        body: JSON.stringify(payload),
      })
        .then((res) => res.json())
        .then((result: SoftwareVulnerabilityReportResponse) => {
          if (
            isNotNull(result) &&
            isNotNull(result.data) &&
            isNotNull(result.paging)
          ) {
            setData(result);
          } else {
            setData(null);
          }
        })
        .then(() => {
          setIsLoading(false);
        });
    }
  }, [refreshData]);

  useEffect(() => {
    if (!isFiltersLoading) {
      setIsFiltersLoading(true);

      const uri = `${DEVICES_ENDPOINT}/software/vulnerabilityReport/filterOptions`;

      fetch(uri, {
        method: 'GET',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
      })
        .then((res) => res.json())
        .then((result: VulnerabilityReportFilterOptions) => {
          setFilterOptions(result);
        })
        .then(() => {
          setIsFiltersLoading(false);
        });
    }
  }, [refreshData]);

  const handleAction = (newAction: string) => {
    switch (newAction) {
      case 'refresh':
        setRefreshData(true);
        setAction('');
        break;
      default:
        setAction(newAction);
    }
  };

  return (
    <VulnerabilityReportContext.Provider
      value={{
        input,
        setInput,
        loading: isLoading,
        data,
        filterOptions,
        action,
        onAction: handleAction,
      }}
    >
      <div>{children}</div>
    </VulnerabilityReportContext.Provider>
  );
};

export default VulnerabilityReportContainer;
