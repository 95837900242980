import React, { useEffect, useState } from 'react';
import { Form } from 'antd';

import GenericAutoComplete from '@totem/components/common/autoCompletes/GenericAutoComplete';
import GenericSelect from '@totem/components/common/autoCompletes/GenericSelect';
import { AutoCompleteValue, HierarchyValue } from '@totem/types/autoComplete';
import { Filter } from '@totem/types/common';
const FormItem = Form.Item;

const styles = {
  form: {
    width: '100%',
  },
  formItem: {
    paddingBottom: '0',
    marginBottom: '1rem',
  },
};

type Props = {
  showOrganization: boolean;
  showRegion: boolean;
  showBuilding: boolean;
  showControlSystem: boolean;
  showDevice: boolean;
  isRegionAutoComplete?: boolean;
  isBuildingAutoComplete?: boolean;
  isControlSystemAutoComplete?: boolean;
  isDeviceAutoComplete?: boolean;
  selectedOrganization?: AutoCompleteValue;
  selectedRegion?: AutoCompleteValue;
  selectedBuilding?: AutoCompleteValue;
  selectedControlSystem?: AutoCompleteValue;
  selectedDevice?: AutoCompleteValue;
  onHierarchyChanged?: (hierarchy: HierarchyValue) => void;
};

const HierarchySelect = ({
  showOrganization,
  showRegion,
  showBuilding,
  showControlSystem,
  showDevice,
  isRegionAutoComplete,
  isBuildingAutoComplete,
  isControlSystemAutoComplete,
  isDeviceAutoComplete,
  onHierarchyChanged,
}: Props) => {
  const [selectedOrganization, setSelectedOrganization] =
    useState<AutoCompleteValue>({
      value: '',
      display: '',
    });
  const [selectedRegion, setSelectedRegion] = useState<AutoCompleteValue>({
    value: '',
    display: '',
  });
  const [selectedBuilding, setSelectedBuilding] = useState<AutoCompleteValue>({
    value: '',
    display: '',
  });
  const [selectedControlSystem, setSelectedControlSystem] =
    useState<AutoCompleteValue>({
      value: '',
      display: '',
    });
  const [selectedDevice, setSelectedDevice] = useState<AutoCompleteValue>({
    value: '',
    display: '',
  });
  const [hasUserInteracted, setHasUserInteracted] = useState<boolean>(false);
  const [regionFilters, setRegionFilters] = useState<Filter[]>([]);
  const [buildingFilters, setBuildingFilters] = useState<Filter[]>([]);
  const [controlSystemFilters, setControlSystemFilters] = useState<Filter[]>(
    [],
  );
  const [deviceFilters, setDeviceFilters] = useState<Filter[]>([]);

  // Region Filters
  useEffect(() => {
    const acFilters: Filter[] = [];

    if (
      showOrganization &&
      selectedOrganization !== null &&
      selectedOrganization.value !== ''
    ) {
      acFilters.push({
        field: 'organizationId',
        values: [selectedOrganization.value],
      });
    }

    setRegionFilters(acFilters);
  }, [showOrganization, selectedOrganization]);

  // Building Filters
  useEffect(() => {
    const acFilters: Filter[] = [];

    if (
      showOrganization &&
      selectedOrganization !== null &&
      selectedOrganization.value !== ''
    ) {
      acFilters.push({
        field: 'organizationId',
        values: [selectedOrganization.value],
      });
    }

    if (showRegion && selectedRegion !== null && selectedRegion.value !== '') {
      acFilters.push({
        field: 'regionId',
        values: [selectedRegion.value],
      });
    }

    setBuildingFilters(acFilters);
  }, [showOrganization, selectedOrganization, showRegion, selectedRegion]);

  // Control System Filters
  useEffect(() => {
    const acFilters: Filter[] = [];

    if (
      showOrganization &&
      selectedOrganization !== null &&
      selectedOrganization.value !== ''
    ) {
      acFilters.push({
        field: 'organizationId',
        values: [selectedOrganization.value],
      });
    }

    if (showRegion && selectedRegion !== null && selectedRegion.value !== '') {
      acFilters.push({
        field: 'regionId',
        values: [selectedRegion.value],
      });
    }

    if (
      showBuilding &&
      selectedBuilding !== null &&
      selectedBuilding.value !== ''
    ) {
      acFilters.push({
        field: 'buildingId',
        values: [selectedBuilding.value],
      });
    }

    setControlSystemFilters(acFilters);
  }, [
    showOrganization,
    selectedOrganization,
    showRegion,
    selectedRegion,
    showBuilding,
    selectedBuilding,
  ]);

  // Device Filters
  useEffect(() => {
    const acFilters: Filter[] = [];

    if (
      showOrganization &&
      selectedOrganization !== null &&
      selectedOrganization.value !== ''
    ) {
      acFilters.push({
        field: 'organizationId',
        values: [selectedOrganization.value],
      });
    }

    if (showRegion && selectedRegion !== null && selectedRegion.value !== '') {
      acFilters.push({
        field: 'regionId',
        values: [selectedRegion.value],
      });
    }

    if (
      showBuilding &&
      selectedBuilding !== null &&
      selectedBuilding.value !== ''
    ) {
      acFilters.push({
        field: 'buildingId',
        values: [selectedBuilding.value],
      });
    }

    if (
      showControlSystem &&
      selectedControlSystem !== null &&
      selectedControlSystem.value !== ''
    ) {
      acFilters.push({
        field: 'controlSystemId',
        values: [selectedControlSystem.value],
      });
    }

    setDeviceFilters(acFilters);
  }, [
    showOrganization,
    selectedOrganization,
    showRegion,
    selectedRegion,
    showBuilding,
    selectedBuilding,
    showControlSystem,
    selectedControlSystem,
  ]);

  useEffect(() => {
    if (hasUserInteracted && typeof onHierarchyChanged === 'function') {
      onHierarchyChanged({
        organization: selectedOrganization,
        region: selectedRegion,
        building: selectedBuilding,
        controlSystem: selectedControlSystem,
        device: selectedDevice,
      });
    }
  }, [
    selectedOrganization,
    selectedRegion,
    selectedBuilding,
    selectedControlSystem,
    selectedDevice,
  ]);

  const setUserInteracted = () => {
    if (!hasUserInteracted) {
      setHasUserInteracted(true);
    }
  };

  // ======================================================================================
  // Organizations

  const handleOrganizationSelected = (selectedValue: AutoCompleteValue) => {
    if (selectedValue.value !== selectedOrganization.value) {
      setSelectedOrganization(selectedValue);
      setUserInteracted();
    }
  };

  // ======================================================================================
  // Regions

  const handleRegionSelected = (selectedValue: AutoCompleteValue) => {
    if (selectedValue.value !== selectedRegion.value) {
      setSelectedRegion(selectedValue);
      setUserInteracted();
    }
  };

  const handleRegionAutoCompleteChanged = () => {
    setSelectedRegion({
      value: '',
      display: '',
    });
    setUserInteracted();
  };

  // ======================================================================================
  // Buildings

  const handleBuildingSelected = (selectedValue: AutoCompleteValue) => {
    if (selectedValue.value !== selectedBuilding.value) {
      setSelectedBuilding(selectedValue);
      setUserInteracted();
    }
  };

  const handleBuildingAutoCompleteChanged = () => {
    setSelectedBuilding({
      value: '',
      display: '',
    });
    setUserInteracted();
  };

  // ======================================================================================
  // Control Systems

  const handleControlSystemSelected = (selectedValue: AutoCompleteValue) => {
    if (selectedValue.value !== selectedControlSystem.value) {
      setSelectedControlSystem(selectedValue);
      setUserInteracted();
    }
  };

  const handleControlSystemAutoCompleteChanged = () => {
    setSelectedControlSystem({
      value: '',
      display: '',
    });
    setUserInteracted();
  };

  // ======================================================================================
  // Devices

  const handleDeviceSelected = (selectedValue: AutoCompleteValue) => {
    if (selectedValue.value !== selectedDevice.value) {
      setSelectedDevice(selectedValue);
      setUserInteracted();
    }
  };

  const handleDeviceAutoCompleteChanged = () => {
    setSelectedDevice({
      value: '',
      display: '',
    });
    setUserInteracted();
  };

  // ======================================================================================

  return (
    <>
      {typeof showOrganization !== 'undefined' &&
        showOrganization !== null &&
        showOrganization === true && (
          <FormItem
            label="Organization"
            colon={false}
            style={styles.formItem}
            hidden={!showOrganization}
          >
            <GenericSelect
              label="Organization"
              type="Organization"
              limit={250}
              onSelect={handleOrganizationSelected}
              unselectedValue=""
            />
          </FormItem>
        )}
      {typeof showRegion !== 'undefined' &&
        showRegion !== null &&
        showRegion === true && (
          <FormItem
            label="Region"
            colon={false}
            style={styles.formItem}
            hidden={!showRegion}
          >
            {!isRegionAutoComplete && (
              <GenericSelect
                label="Region"
                type="Region"
                limit={250}
                onSelect={handleRegionSelected}
                selectedValue={selectedRegion}
                filters={regionFilters}
                unselectedValue=""
              />
            )}
            {isRegionAutoComplete && (
              <GenericAutoComplete
                label="Region"
                type="Region"
                limit={15}
                onSelect={handleRegionSelected}
                onChange={handleRegionAutoCompleteChanged}
                filters={regionFilters}
                selectedValue={selectedRegion}
              />
            )}
          </FormItem>
        )}
      {typeof showBuilding !== 'undefined' &&
        showBuilding !== null &&
        showBuilding === true && (
          <FormItem
            label="Building"
            colon={false}
            style={styles.formItem}
            hidden={!showBuilding}
          >
            {!isBuildingAutoComplete && (
              <GenericSelect
                label="Building"
                type="Building"
                limit={250}
                onSelect={handleBuildingSelected}
                selectedValue={selectedBuilding}
                filters={buildingFilters}
                unselectedValue=""
              />
            )}
            {isBuildingAutoComplete && (
              <GenericAutoComplete
                label="Building"
                type="Building"
                limit={15}
                onSelect={handleBuildingSelected}
                onChange={handleBuildingAutoCompleteChanged}
                filters={buildingFilters}
                selectedValue={selectedBuilding}
              />
            )}
          </FormItem>
        )}
      {typeof showControlSystem !== 'undefined' &&
        showControlSystem !== null &&
        showControlSystem === true && (
          <FormItem
            label="Control System"
            colon={false}
            style={styles.formItem}
            hidden={!showControlSystem}
          >
            {!isControlSystemAutoComplete && (
              <GenericSelect
                label="Control System"
                type="Control System"
                limit={250}
                onSelect={handleControlSystemSelected}
                selectedValue={selectedControlSystem}
                filters={controlSystemFilters}
                unselectedValue=""
              />
            )}
            {isControlSystemAutoComplete && (
              <GenericAutoComplete
                label="Control System"
                type="Control System"
                limit={15}
                onSelect={handleControlSystemSelected}
                onChange={handleControlSystemAutoCompleteChanged}
                filters={controlSystemFilters}
                selectedValue={selectedControlSystem}
              />
            )}
          </FormItem>
        )}
      {typeof showDevice !== 'undefined' &&
        showDevice !== null &&
        showDevice === true && (
          <FormItem
            label="Device"
            colon={false}
            style={styles.formItem}
            hidden={!showDevice}
          >
            {!isDeviceAutoComplete && (
              <GenericSelect
                label="Device"
                type="Device"
                limit={250}
                onSelect={handleDeviceSelected}
                selectedValue={selectedDevice}
                filters={deviceFilters}
                unselectedValue=""
              />
            )}
            {isDeviceAutoComplete && (
              <GenericAutoComplete
                label="Device"
                type="Device"
                limit={15}
                onSelect={handleDeviceSelected}
                onChange={handleDeviceAutoCompleteChanged}
                filters={deviceFilters}
                selectedValue={selectedDevice}
              />
            )}
          </FormItem>
        )}
    </>
  );
};

export default HierarchySelect;
