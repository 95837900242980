export enum Feature {
  QUESTIONNAIRE_BCC = 'questionnaireBcc',
  POLICYAUDIT_BCC = 'policyAuditBcc',
  NMAP = 'nmap',
}

export interface FeatureUpdateInput {
  feature: Feature;
  enabled: boolean;
}
