import React, { useContext } from 'react';
import { Col, Row } from 'antd';

import RemoteAccessUserContext from '@totem/components/remoteAccess/roleUsers/RemoteAccessUserContext';
import TotalValue from '@totem/components/remoteAccess/roleUsers/rootRole/TotalValue';
import RemoteAccessTotalsContext from '@totem/components/remoteAccess/totals/RemoteAccessTotalsContext';
import UserProfileContext from '@totem/components/UserProfileContext';
import colors from '@totem/styles/colors';
import { isNotNull } from '@totem/utilities/common';
import {
  isMemberOfAny,
  securityGroupCheckConstraintEmpty,
} from '@totem/utilities/userUtilities';

import './totals.css';

const TotalsContainer = () => {
  const { data } = useContext(RemoteAccessTotalsContext);
  const { input, setInput } = useContext(RemoteAccessUserContext);
  const { userProfile } = useContext(UserProfileContext);

  const activeAccounts = isNotNull(data)
    ? data.total - (data.neverLoggedIn + data.stale)
    : 0;

  const userCanSeeRemoteAccess = isMemberOfAny(
    userProfile,
    [
      'remote_access_viewer',
      'remote_access_admin',
      'remote_access_configuration_admin',
    ],
    securityGroupCheckConstraintEmpty,
  );

  const styles = {
    borderLeft: {
      borderLeftColor: colors.neutral.silver,
      borderLeftStyle: 'solid' as 'solid',
      borderLeftWidth: 1,
    },
    container: {
      paddingTop: '10px',
      paddingBottom: '10px',
    },
    selectable: {
      cursor: 'pointer',
    },
  };

  const handleClick = (flag: string) => {
    if (flag === '') {
      setInput({ ...input, flags: [] });
    } else {
      setInput({ ...input, flags: [flag] });
    }
  };

  return (
    <Row style={styles.container}>
      <Col span={6}>
        <TotalValue
          title={'Total Accounts'}
          value={
            !userCanSeeRemoteAccess ? (
              <span>${isNotNull(data) ? data.total.toString(10) : '0'}</span>
            ) : (
              <span style={styles.selectable} onClick={() => handleClick('')}>
                {isNotNull(data) ? data.total.toString(10) : '0'}
              </span>
            )
          }
        />
      </Col>
      <Col span={6} style={styles.borderLeft}>
        <TotalValue
          title={'Active Accounts'}
          value={
            !userCanSeeRemoteAccess ? (
              <span>${activeAccounts.toString(10)}</span>
            ) : (
              <span
                style={styles.selectable}
                onClick={() => handleClick('1')}
                color={colors.event.criticality.background.ok}
              >
                {activeAccounts.toString(10)}
              </span>
            )
          }
          iconColor={colors.event.criticality.background.ok}
          valueColor={colors.event.criticality.background.ok}
        />
      </Col>
      <Col span={6} style={styles.borderLeft}>
        <TotalValue
          title={'Stale Accounts'}
          value={
            !userCanSeeRemoteAccess ? (
              <span>${isNotNull(data) ? data.stale.toString(10) : '0'}</span>
            ) : (
              <span style={styles.selectable} onClick={() => handleClick('4')}>
                {isNotNull(data) ? data.stale.toString(10) : '0'}
              </span>
            )
          }
          iconColor={colors.event.criticality.background.critical}
          valueColor={colors.event.criticality.background.critical}
        />
      </Col>
      <Col span={6} style={styles.borderLeft}>
        <TotalValue
          title={'Never Used'}
          value={
            !userCanSeeRemoteAccess ? (
              <span>
                ${isNotNull(data) ? data.neverLoggedIn.toString(10) : '0'}
              </span>
            ) : (
              <span style={styles.selectable} onClick={() => handleClick('2')}>
                {isNotNull(data) ? data.neverLoggedIn.toString(10) : '0'}
              </span>
            )
          }
          iconColor={colors.event.criticality.background.minor}
          valueColor={colors.event.criticality.background.minor}
        />
      </Col>
    </Row>
  );
};

export default TotalsContainer;
