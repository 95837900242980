// Use AntD Theme Editor @  https://ant.design/theme-editor#component-color

export const themeData = {
  token: {
    colorPrimary: '#122048',
    colorInfo: '#122048',
    colorLink: '#19a6e0',
    colorSuccess: '#3fa142',
    colorError: '#f44336',
    wireframe: false,
  },
  components: {
    Button: {
      colorPrimary: 'rgb(240, 75, 36)',
    },
    Menu: {
      groupTitleColor: 'rgb(25, 166, 224)',
      itemColor: 'rgb(25, 166, 224)',
      itemHoverColor: 'rgb(25, 166, 224)',
      itemSelectedBg: 'rgb(245, 245, 245)',
      itemSelectedColor: 'rgb(25, 166, 224)',
      subMenuItemBg: 'rgba(255, 255, 255, 0.04)',
    },
    Select: {
      colorPrimaryHover: 'rgb(245, 245, 245)',
      multipleItemBg: 'rgba(245, 245, 245, 0)',
      optionSelectedColor: 'rgb(18, 32, 72)',
      optionSelectedBg: 'rgb(245, 245, 245)',
    },
    Tabs: {
      itemSelectedColor: 'rgb(240, 75, 36)',
      inkBarColor: 'rgb(240, 75, 36)',
    },
    Dropdown: {
      controlItemBgActive: 'rgb(197, 203, 209)',
      controlItemBgActiveHover: 'rgb(192, 198, 204)',
    },
  },
};
