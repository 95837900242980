import React, { useContext } from 'react';
import { EditOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { ColumnProps } from 'antd/lib/table';

import Table from '@totem/components/common/table/Table';
import Tooltip from '@totem/components/common/tooltip/Tooltip';
import { sortStringAscending } from '@totem/components/events/eventsContainer/utilities';
import BuildingMapperContext from '@totem/components/settings/sections/sapientBuildingMapping/BuildingMapContext';
import { BuildingsMap } from '@totem/components/settings/sections/sapientBuildingMapping/types';
import authUtilities from '@totem/utilities/authUtilities';

const styles = {
  iconButton: {
    margin: '0 2px',
  },
};

const BuildingMappingTable = () => {
  const { data, loading, onAction } = useContext(BuildingMapperContext);
  const columns: ColumnProps<BuildingsMap>[] = [
    {
      title: 'Name',
      dataIndex: 'sapient_building.building_id',
      key: 'sapient_building.building_id',
      sorter: (compA, compB) =>
        sortStringAscending(
          compA.sapient_building.building_name,
          compB.sapient_building.building_name,
        ),
      render: (text: string, item: BuildingsMap) => {
        return item.sapient_building.building_name;
      },
    },
    {
      title: 'Street Address',
      dataIndex: 'sapient_building.street_address',
      key: 'sapient_building.street_address',
      sorter: (compA, compB) =>
        sortStringAscending(
          compA.sapient_building.street_address,
          compB.sapient_building.street_address,
        ),
      render: (text: string, item: BuildingsMap) => {
        return item.sapient_building.street_address;
      },
    },
    {
      title: 'City',
      dataIndex: 'sapient_building.city',
      key: 'sapient_building.city',
      sorter: (compA, compB) =>
        sortStringAscending(
          compA.sapient_building.city,
          compB.sapient_building.city,
        ),
      render: (text: string, item: BuildingsMap) => {
        return item.sapient_building.city;
      },
    },
    {
      title: 'State',
      dataIndex: 'sapient_building.state',
      key: 'sapient_building.state',
      sorter: (compA, compB) =>
        sortStringAscending(
          compA.sapient_building.state,
          compB.sapient_building.state,
        ),
      render: (text: string, item: BuildingsMap) => {
        return item.sapient_building.state;
      },
    },
    {
      title: 'Building',
      dataIndex: 'ibd_building.id',
      key: 'ibd_building.id',
      sorter: (compA, compB) =>
        sortStringAscending(compA.ibd_building.name, compB.ibd_building.name),
      render: (text: string, item: BuildingsMap) => {
        return typeof item.ibd_building !== 'undefined' &&
          item.ibd_building !== null
          ? item.ibd_building.name
          : '';
      },
    },
    {
      title: 'Actions',
      width: 30,
      dataIndex: 'additionalOptions',
      key: 'action',
      render: (_, item: BuildingsMap) => (
        <div className="center-table-cell">
          {authUtilities.minimumRequiredRole(
            authUtilities.ROLE_SETS.SYSTEMS_ADMINISTRATOR,
          ) && (
            <Tooltip title="Update Building Mapping" placement="top">
              <Button
                icon={<EditOutlined />}
                shape="circle"
                onClick={(): void => onAction('showMappingDialog', item)}
                style={styles.iconButton}
              />
            </Tooltip>
          )}
        </div>
      ),
    },
  ];

  return (
    <Table
      showSorterTooltip
      columns={columns}
      dataSource={typeof data !== 'undefined' && data !== null ? data : []}
      loading={loading}
      rowKey="id"
    />
  );
};

export default BuildingMappingTable;
