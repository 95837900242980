import React from 'react';

import CveCardTitle from '@totem/components/software/cves/cveCardTitle';
import CveInformationValue from '@totem/components/software/cves/cveInformationValue';
import { CVERecord } from '@totem/components/software/cves/types';
import { DateStringToLocal } from '@totem/utilities/dateUtilities';

type Props = {
  cve: CVERecord;
};
const CveInformation = ({ cve }: Props) => {
  return (
    <>
      <CveCardTitle>Information</CveCardTitle>
      <CveInformationValue title="Published">
        {DateStringToLocal(cve.cve.raw_nvd_data.published)}
      </CveInformationValue>
      <CveInformationValue title="Updated">
        {DateStringToLocal(cve.cve.raw_nvd_data.lastModified)}
      </CveInformationValue>
      <hr />
      <CveInformationValue title="NVD link">
        <a href={`https://nvd.nist.gov/vuln/detail/${cve.id}`}>{cve.id}</a>
      </CveInformationValue>
      <CveInformationValue title="Mitre link">
        <a href={`https://cve.mitre.org/cgi-bin/cvename.cgi?name=${cve.id}`}>
          {cve.id}
        </a>
      </CveInformationValue>
      <CveInformationValue title="CVE.ORG link">
        <a href={`https://www.cve.org/CVERecord?id=${cve.id}`}>{cve.id}</a>
      </CveInformationValue>
    </>
  );
};

export default CveInformation;
