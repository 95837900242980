import React, { useContext } from 'react';

import BasePane from '@totem/components/BasePane';
import PrimaryContentPane from '@totem/components/PrimaryContentPane';
import WorkPanel from '@totem/components/surveyV2/work/WorkPanel';
import AffiliatedTickets from '@totem/components/ticketing/affiliatedList/AffiliatedTickets';
import UserProfileContext from '@totem/components/UserProfileContext';

import ContentLayout from '../../ContentLayout';

const WorkSummaryPage = () => {
  const { userProfile } = useContext(UserProfileContext);
  const showMyTickets = userProfile.organization.role > -1;

  return (
    <ContentLayout
      pageTitle={`${userProfile.organization.name} - Assigned Work`}
    >
      <BasePane>
        <PrimaryContentPane>
          {showMyTickets && <AffiliatedTickets />}
          <WorkPanel />
        </PrimaryContentPane>
      </BasePane>
    </ContentLayout>
  );
};

export default WorkSummaryPage;
