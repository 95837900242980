import { gql } from '@apollo/client';

export const GET_POLICY = gql`
  query GetPolicy {
    policy {
      id
      groups {
        name
        questions
      }
      questions
    }
  }
`;

export const UPDATE_POLICY = gql`
  mutation UpdatePolicy($policy: PolicyUpdateInput!) {
    policyUpdate(policy: $policy) {
      id
      questions
    }
  }
`;
