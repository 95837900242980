import React, { ReactNode, useEffect, useState } from 'react';

import DeviceContext from '@totem/components/devices/deviceDetails/DeviceContext';
import { DeviceQueryResult } from '@totem/types/devices';
import { getToken } from '@totem/utilities/accountUtilities';
import { DEVICES_ENDPOINT } from '@totem/utilities/endpoints';

type Props = {
  deviceId: string;
  children?: ReactNode;
};

const DeviceContainer = ({ deviceId, children }: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [deviceData, setDeviceData] = useState<DeviceQueryResult>(null);
  const [action, setAction] = useState<string>('');
  const [refreshData, setRefreshData] = useState<boolean>(true);

  useEffect(() => {
    if (refreshData) {
      setRefreshData(false);
      setIsLoading(true);

      fetch(`${DEVICES_ENDPOINT}/${deviceId}`, {
        method: 'GET',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
      })
        .then((res) => res.json())
        .then((result: DeviceQueryResult) => {
          setDeviceData(result);
        })
        .then(() => {
          setIsLoading(false);
        });
    }
  }, [deviceId, refreshData]);

  const handleAction = (newAction: string) => {
    switch (newAction) {
      case 'refresh':
        setRefreshData(true);
        break;
      default:
        setAction(newAction);
        break;
    }
  };

  return (
    <DeviceContext.Provider
      value={{
        loading: isLoading,
        deviceData,
        action,
        onAction: handleAction,
      }}
    >
      <div>{children}</div>
    </DeviceContext.Provider>
  );
};

export default DeviceContainer;
