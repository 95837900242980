import React, { useEffect, useState } from 'react';
import { Button, Form, Select } from 'antd';
import Modal from 'antd/lib/modal/Modal';

import colors from '@totem/styles/colors';
import { AvailableService } from '@totem/types/services';
import { getToken } from '@totem/utilities/accountUtilities';
import { SERVICES_ENDPOINT } from '@totem/utilities/endpoints';
const { Option } = Select;
const FormItem = Form.Item;

import ModalFooter from '@totem/components/ModalFooter';
import ModalTitle from '@totem/components/ModalTitle';
import { Service } from '@totem/types/organization';

const styles = {
  button: {
    background: colors.utility.error,
    color: colors.neutral.white,
  },
  errorIcon: {
    color: colors.utility.error,
    marginRight: '1rem',
    fontSize: '22px',
  },
  form: {
    width: '100%',
  },
  formItem: {
    paddingBottom: '0',
    marginBottom: '1rem',
  },
};

export interface Props {
  organizationServices: Service[];
  visible: boolean;
  onClose: () => void;
  onSubmit: (AvailableService) => void;
}

const ServiceAddModal = ({
  visible,
  onClose,
  onSubmit,
  organizationServices,
}: Props) => {
  const [availableServices, setAvailableServices] = useState<
    AvailableService[]
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [selectedService, setSelectedService] =
    useState<AvailableService>(null);

  useEffect(() => {
    const filterServices = (available: AvailableService[]) => {
      const filtered = available
        .filter(
          (svc) =>
            typeof organizationServices.find(
              (orgSvc) => orgSvc.id === svc.id,
            ) === 'undefined',
        )
        .sort((option1, option2) => {
          if (option1.displayName < option2.displayName) {
            return -1;
          }
          if (option1.displayName > option2.displayName) {
            return 1;
          }
          return 0;
        });
      setAvailableServices(filtered);
    };

    fetch(SERVICES_ENDPOINT, {
      headers: new Headers({
        Authorization: `Bearer ${getToken()}`,
      }),
    })
      .then((res) => res.json())
      .then((result) => {
        filterServices(result);
      })
      .then(() => setIsLoading(false));
  }, [organizationServices]);

  const handleServiceChanged = (serviceId: string) => {
    const foundService = availableServices.find(svc => svc.id === serviceId);
    if (typeof foundService !== 'undefined') {
      setSelectedService(foundService);
    } else {
      setSelectedService(null);
    }
  };

  const handleSubmit = () => {
    if (selectedService !== null) {
      onSubmit(selectedService);
    }
  };

  return (
    <Modal
      title={<ModalTitle>Add Service</ModalTitle>}
      onCancel={onClose}
      open={visible}
      footer={
        <ModalFooter>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={handleSubmit} loading={isLoading} type="primary">
            Add
          </Button>
        </ModalFooter>
      }
    >
      <Form layout="vertical" style={styles.form}>
        <FormItem label="Service" colon={false} style={styles.formItem}>
          <Select
            onChange={handleServiceChanged}
            defaultValue={selectedService !== null ? selectedService.id : ''}
            style={{ width: '100%' }}
          >
            {availableServices !== null &&
              availableServices.map((svc) => (
                <Option key={svc.id} value={svc.id}>
                  {svc.displayName}
                </Option>
              ))}
          </Select>
        </FormItem>
      </Form>
    </Modal>
  );
};

export default ServiceAddModal;
