import React from 'react';

import BasePane from '@totem/components/BasePane';
import ContentLayout from '@totem/components/ContentLayout';
import PrimaryContentPane from '@totem/components/PrimaryContentPane';
import AnalysisContainer from '@totem/components/surveyV2/analysis/AnalysisContainer';
import AnalysisFilters from '@totem/components/surveyV2/analysis/AnalysisFilters';
import AnalysisTable from '@totem/components/surveyV2/analysis/AnalysisTable';
import OverviewSection from '@totem/components/surveyV2/analysis/OverviewSection';

import '../survey.css';

const styles = {
  title: {
    fontWeight: 700,
    padding: '2rem',
  },
};

const AnalysisPage = () => {
  return (
    <ContentLayout>
      <AnalysisContainer>
        <BasePane>
          <PrimaryContentPane>
            <AnalysisFilters />
            <div style={styles.title} styleName="title">
              Overview
            </div>
            <OverviewSection />
            <div style={styles.title} styleName="title middle">
              Policies
            </div>
            <AnalysisTable />
          </PrimaryContentPane>
        </BasePane>
      </AnalysisContainer>
    </ContentLayout>
  );
};

export default AnalysisPage;
