import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { EMPTY_ID } from '@totem/components/common/reference/ReferenceSelector';
import ContentLayout from '@totem/components/ContentLayout';
import { Component, ComponentInstance } from '@totem/components/home/lib/types';
import Widget from '@totem/components/home/widgets/Widget';
import WidgetInstance from '@totem/components/home/widgets/WidgetInstance';
import { getToken } from '@totem/utilities/accountUtilities';
import { WIDGET_COMPONENT_ENDPOINT } from '@totem/utilities/endpoints';

import './instanceOverrides.css';

const ComponentPage = () => {
  const { id } = useParams();
  const [component, setComponent] = useState<Component>(null);
  const [componentInstance, setComponentInstance] =
    useState<ComponentInstance>(null);

  useEffect(() => {
    fetch(`${WIDGET_COMPONENT_ENDPOINT}/${id}`, {
      method: 'GET',
      headers: new Headers({
        Authorization: `Bearer ${getToken()}`,
      }),
    })
      .then((res) => res.json())
      .then((result: Component) => {
        const instance: ComponentInstance = {
          title: result.title,
          style: result.style,
          componentId: result.componentId,
          componentInstanceId: EMPTY_ID,
          geometry: result.geometry,
          positionGeometry: [],
        };

        instance.geometry.y = 0;

        setComponent(result);
        setComponentInstance(instance);

        window.dispatchEvent(new Event('resize'));
      });
  }, []);

  const CreateDashboardWidget = () => {
    return (
      <div key={componentInstance.geometry.i} styleName="widget-static">
        <Widget componentInstance={componentInstance}>
          <WidgetInstance componentInstance={componentInstance} />
        </Widget>
      </div>
    );
  };

  return (
    <ContentLayout
      pageTitle={
        typeof component !== 'undefined' && component !== null
          ? component.title
          : ''
      }
    >
      {typeof componentInstance !== 'undefined' &&
        componentInstance !== null && (
          <div styleName="primary-content-pane-page">
            {CreateDashboardWidget()}
          </div>
        )}
    </ContentLayout>
  );
};

export default ComponentPage;
