export enum PolicyMediaType {
  Unknown,
  EmbeddedVideo,
}

export interface HealthRiskPolicyAcknowledgment {
  id: string;
  organization: {
    id: string;
    name: string;
  };
  link: string;
  mediaType: PolicyMediaType;
  agreement: string;
  agreeText: string;
  agreed: boolean;
}
