import React, { useContext, useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';

import TicketAgingContext from '@totem/components/ticketing/agingReport/TicketAgingContext';
import {
  addFilters,
  buildFilters,
} from '@totem/components/ticketing/utilities';
import { Params } from '@totem/types/common';
import { TicketAgingValue } from '@totem/types/ticketing';
import { getToken } from '@totem/utilities/accountUtilities';

type Props = {
  chartOptions?: ApexOptions;
};

const AgingChartApex = ({ chartOptions }: Props) => {
  const {
    input,
    chartEndPoint,
    staticFilters,
    reportType,
    setDrillDownValue,
  } = useContext(TicketAgingContext);
  const [agingData, setAgingData] = useState<TicketAgingValue[]>([]);
  const [key, setKey] = useState<number>(0);

  const getXAxisLabel = () => {
    switch (reportType) {
      case 'updated':
        return 'Days Without Update';
      case 'created':
        return 'Days Outstanding';
      default:
        return '';
    }
  };

  const buildParameters = () => {
    const params: Params = {
      paging: {
        page: 1,
        pageSize: 1000,
      },
      sort: {
        field: 'createdAt',
        direction: 1,
      },
      filters: buildFilters(input),
    };

    params.filters = addFilters(params.filters, staticFilters);

    return params;
  };

  useEffect(() => {
    const params = buildParameters();

    fetch(chartEndPoint, {
      method: 'POST',
      headers: new Headers({
        Authorization: `Bearer ${getToken()}`,
      }),
      body: JSON.stringify(params),
    })
      .then(res => res.json())
      .then((result: TicketAgingValue[]) => {
        setAgingData(result);
        setKey(key + 1);
      });
  }, [input, staticFilters, reportType, chartEndPoint]);

  const getCategories = () => {
    return agingData.map(ser => ser.label);
  };

  const getSeries = () => {
    const name = getXAxisLabel();
    const values = agingData.map(ser => ser.value);

    return [
      {
        name,
        data: values,
      },
    ];
  };

  // eslint-disable-next-line id-length
  const handleClick = (e: any, chart?: any, options?: any) => {
    const dataPointIndex = options.dataPointIndex;
    const label = agingData[dataPointIndex].label;
    setDrillDownValue(label);
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
  };

  const options: ApexOptions = {
    chart: {
      type: 'bar',
      stacked: true,
      toolbar: {
        show: true,
        tools: {
          download: true,
          selection: false,
          pan: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
        },
      },
      events: {
        click: handleClick,
      },
      zoom: { enabled: true },
      selection: { enabled: true },
    },
    title: { text: `Ticket Aging: ${getXAxisLabel()}`, align: 'center' },
    legend: {
      show: false,
      showForSingleSeries: false,
      showForZeroSeries: false,
    },
    series: getSeries(),
    colors: [
      '#87CEFA',
      '#B0E0E6',
      '#ADD8E6',
      '#87CEEB',
      '#00BFFF',
      '#B0C4DE',
      '#1E90FF',
      '#6495ED',
      '#5F9EA0',
      '#4169E1',
    ],
    xaxis: {
      categories: getCategories(),
      tickPlacement: 'on',
      title: {
        text: getXAxisLabel(),
        style: {
          fontSize: '14px',
          fontWeight: 'normal',
        },
      },
    },
    yaxis: [
      {
        axisTicks: {
          show: true,
        },
        tooltip: {
          enabled: true,
        },
        title: {
          text: 'Ticket Count',
          rotate: 270,
          style: {
            fontSize: '14px',
            fontWeight: 'normal',
          },
        },
      },
    ],
    tooltip: {
      fixed: {
        enabled: true,
        position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
        offsetY: 30,
        offsetX: 60,
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
  };

  const getChartOptions = () => {
    if (typeof chartOptions !== 'undefined' && chartOptions !== null) {
      return { ...options, ...chartOptions };
    }
    return options;
  };

  return (
    <div className="app">
      <div className="row">
        <div className="mixed-chart">
          <ReactApexChart
            options={getChartOptions()}
            series={getSeries()}
            type="bar"
            width="100%"
            height="300px"
          />
        </div>
      </div>
    </div>
  );
};

export default AgingChartApex;
