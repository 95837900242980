import React, { useContext } from 'react';

import DropdownFilter from '@totem/components/common/dropdownFilter/DropdownFilter';
import FiltersChipsContainer from '@totem/components/FilterChipsContainer';
import FiltersBody from '@totem/components/FiltersBody';
import FiltersContainer from '@totem/components/FiltersContainer';
import FiltersTitle from '@totem/components/FiltersTitle';
import TicketAgingContext from '@totem/components/ticketing/agingReport/TicketAgingContext';
import { TicketingAgingConnectionInput } from '@totem/components/ticketing/agingReport/types';
import TicketingListContainerFilterChips from '@totem/components/ticketing/listContainer/TicketingListContainerFilterChips';
import { isNotNull } from '@totem/utilities/common';

import '../ticketing.css';

const styles = {
  dropdown: {
    marginRight: '2rem',
  },
};

const AgingFilters = () => {
  const { input, setInput, filterOptions } = useContext(TicketAgingContext);

  const getBuildingFilterOptions = () => {
    return isNotNull(filterOptions)
      ? filterOptions.building.map((option) => ({
          label: option.name,
          value: option.value,
        }))
      : null;
  };

  const getTicketTypeFilterOptions = () => {
    return isNotNull(filterOptions)
      ? filterOptions.type.map((option) => ({
          label: option.name,
          value: option.value,
        }))
      : null;
  };

  const getCategoryFilterOptions = () => {
    return isNotNull(filterOptions)
      ? filterOptions.categories.map((option) => ({
          label: option.name,
          value: option.value,
        }))
      : null;
  };

  const getPriorityFilterOptions = () => {
    return isNotNull(filterOptions)
      ? filterOptions.priority.map((option) => ({
          label: option.name,
          value: option.value,
        }))
      : null;
  };

  const handleMultiFilterChange = (
    property: keyof TicketingAgingConnectionInput,
    update: string[],
  ) => {
    setInput({
      ...input,
      [property]: update,
    });
  };

  return (
    <FiltersContainer>
      <FiltersTitle>Aging Report</FiltersTitle>
      <FiltersBody>
        <DropdownFilter
          label="Building"
          options={getBuildingFilterOptions()}
          value={input.buildingId || []}
          onChange={(update) => handleMultiFilterChange('buildingId', update)}
          style={styles.dropdown}
        />
        <DropdownFilter
          label="Ticket Type"
          options={getTicketTypeFilterOptions()}
          value={input.type || []}
          onChange={(update) => handleMultiFilterChange('type', update)}
          style={styles.dropdown}
        />
        <DropdownFilter
          label="Category"
          options={getCategoryFilterOptions()}
          value={input.category || []}
          onChange={(update) => handleMultiFilterChange('category', update)}
          style={styles.dropdown}
        />
        <DropdownFilter
          label="Priority"
          options={getPriorityFilterOptions()}
          value={input.priority || []}
          onChange={(update) => handleMultiFilterChange('priority', update)}
          style={styles.dropdown}
        />
      </FiltersBody>
      <FiltersChipsContainer>
        <TicketingListContainerFilterChips />
      </FiltersChipsContainer>
    </FiltersContainer>
  );
};

export default AgingFilters;
