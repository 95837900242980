import { gql } from '@apollo/client';

export const CREATE_HEALTH = gql`
  mutation CreateHealth($health: HealthInput!) {
    healthCreate(health: $health) {
      id
      subjectId
      status
      comment
      createdBy {
        firstName
        lastName
      }
      createdAt
    }
  }
`;
