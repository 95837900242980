import React, { useState } from 'react';
import { Button, Form, Input, Modal } from 'antd';
const FormItem = Form.Item;
import ModalFooter from '@totem/components/ModalFooter';
import ModalTitle from '@totem/components/ModalTitle';

const styles = {
  form: {
    width: '100%',
  },
  formItem: {
    paddingBottom: '0',
    marginBottom: '1rem',
  },
  iconButton: {
    margin: '0 2px',
  },
};

type Props = {
  title: string;
  visible: boolean;
  onSubmit: (newTitle: string) => void;
  onClose: () => void;
};

const TitleEditModal = ({ title, visible, onSubmit, onClose }: Props) => {
  const [editTitle, setEditTitle] = useState<string>(title);

  const handleChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setEditTitle(value);
  };

  return (
    <Modal
      open={visible}
      onCancel={onClose}
      title={<ModalTitle>Edit Page Title</ModalTitle>}
      confirmLoading={false}
      width={'40%'}
      footer={
        <ModalFooter>
          <Button onClick={onClose}>Close</Button>
          <Button type="primary" onClick={() => onSubmit(editTitle)}>
            Save
          </Button>
        </ModalFooter>
      }
    >
      <Form layout="vertical" style={styles.form}>
        <FormItem label="Title" colon={false} style={styles.formItem}>
          <Input
            type="text"
            value={editTitle}
            onChange={handleChange}
            maxLength={50}
          />
        </FormItem>
      </Form>
    </Modal>
  );
};

export default TitleEditModal;
