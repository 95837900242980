import React, { useCallback, useMemo, useState } from 'react';
import {
  CloseCircleFilled,
  ContactsOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { PageHeader } from '@ant-design/pro-layout';
import { useMutation, useQuery } from '@apollo/client';
import { Button, Input, Modal, notification } from 'antd';
import { TablePaginationConfig } from 'antd/lib/table';
import { SorterResult } from 'antd/lib/table/interface';
import * as R from 'ramda';
import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params';

import {
  CREATE_CUSTOMER,
  DELETE_CUSTOMER,
  GET_CUSTOMERS,
  UPDATE_CUSTOMER,
} from '@totem/graph/customers';
import { useConnection } from '@totem/hooks/useConnection';
import { useErrorNotification } from '@totem/hooks/useErrorNotification';
import colors from '@totem/styles/colors';
import {
  AddEditCustomer,
  Customer,
  CustomerAction,
  CustomersConnection,
} from '@totem/types/customer';
import { debounce } from '@totem/utilities/debounce';
import { getTablePagination } from '@totem/utilities/paginationUtilities';

import ContentLayout from '../ContentLayout';

import AddEditCustomerModal from './AddEditCustomerModal';
import CustomersTable from './CustomersTable';

import './customers.css';

const styles = {
  icon: {
    color: colors.neutral.gray,
  },
  errorIcon: {
    color: colors.utility.error,
  },
  deleteButton: {
    background: colors.utility.error,
    color: colors.neutral.white,
  },
};

const TABLE_LIMIT = 10;
const DEBOUNCE_TIME = 500;

const Customers = () => {
  const [input, setInput] = useQueryParams({
    offset: withDefault(NumberParam, 0),
    limit: withDefault(NumberParam, TABLE_LIMIT),
    name: StringParam,
    domain: StringParam,
    sortOrder: StringParam,
    sortBy: StringParam,
  });

  const [customerSelected, setCustomerSelected] = useState<AddEditCustomer>({
    id: '',
    name: '',
    domain: '',
  });
  const [customerAction, setCustomerAction] = useState<CustomerAction>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(null);

  const { data, loading, refetch } = useQuery<{
    customers: CustomersConnection;
  }>(GET_CUSTOMERS, {
    variables: {
      input,
    },
  });

  const { customers, totalCount } = useConnection<CustomersConnection>({
    data,
    accessor: R.path(['customers']),
    initialData: { totalCount: 0, customers: [] },
  });

  const [addCustomer, { error: addCustomerError }] =
    useMutation(CREATE_CUSTOMER);

  useErrorNotification(addCustomerError, 'Error Creating Customer');

  const [editCustomer, { error: editCustomerError }] =
    useMutation(UPDATE_CUSTOMER);

  useErrorNotification(editCustomerError, 'Error Updating Customer');

  const [deleteCustomer, { error: deleteCustomerError }] =
    useMutation(DELETE_CUSTOMER);

  useErrorNotification(deleteCustomerError, 'Error Deleting Customer');

  const handlePagination = (
    { current, pageSize: limit }: TablePaginationConfig,
    { field, order }: SorterResult<Customer>,
  ) => {
    const offset = current ? (current - 1) * limit : 1;
    const sortBy = !order ? null : field;
    const sortOrder = !order ? null : order;
    // @ts-ignore
    setInput({ ...input, limit, offset, sortBy, sortOrder });
  };

  const handleSearchByName = useCallback(
    debounce((name: string) => setInput({ ...input, name }), DEBOUNCE_TIME),
    [],
  );

  const handleAddCustomerClick = () => {
    setCustomerSelected({ name: '', domain: '' });
    setCustomerAction(CustomerAction.Add);
    setIsModalOpen(true);
  };

  const handleEditCustomerClick = ({ id, name, domain }: AddEditCustomer) => {
    setCustomerSelected({ id, name, domain });
    setCustomerAction(CustomerAction.Edit);
    setIsModalOpen(true);
  };

  const handleDeleteCustomerClick = (customer) => {
    Modal.confirm({
      content: `Are you sure you want to remove ${customer.domain.trim()}?`,
      title: `Remove ${customer.domain}?`,
      cancelText: 'No',
      okText: 'Yes, Delete',
      okType: 'danger',
      okButtonProps: { style: styles.deleteButton },
      icon: <CloseCircleFilled style={styles.errorIcon} />,
      onOk: () =>
        deleteCustomer({ variables: { id: customer.id } }).then(() =>
          refetch(),
        ),
    });
  };

  const handleSubmit = async ({ id, name, domain }) => {
    if (customerAction === CustomerAction.Add) {
      await addCustomer({
        variables: { input: { name, domain } },
      });

      notification.success({
        message: 'Customer Created',
        description: 'Your new customer has successfully been created.',
      });

      refetch();
      setIsModalOpen(false);
    } else {
      await editCustomer({
        variables: { input: { id, name } },
      });

      setIsModalOpen(false);
    }
  };

  const pagination = useMemo(
    () => getTablePagination(input, totalCount),
    [input, totalCount],
  );

  return (
    <ContentLayout>
      <>
        <div styleName="customer-list-background">
          <div styleName="customer-list-container">
            <div styleName="customer-list-header">
              <PageHeader
                title={
                  <div styleName="customer-header-text">
                    Customer Management
                  </div>
                }
                extra={
                  !!customers.length && [
                    <Button
                      key="1"
                      type="primary"
                      // todo: access requirements
                      // disabled={customerCreationEnabled}
                      onClick={handleAddCustomerClick}
                    >
                      Add Customer
                    </Button>,
                  ]
                }
              />
            </div>
            {!!customers.length ? (
              <div styleName="table-container">
                <div styleName="search-count-row">
                  <div styleName="search-bar">
                    <Input
                      placeholder="Search"
                      onChange={(event) =>
                        handleSearchByName(event.target.value)
                      }
                      prefix={<SearchOutlined style={styles.icon} />}
                    />
                  </div>
                  <div styleName="table-count">
                    <span>{`${Math.min(
                      totalCount - input.offset,
                      input.limit,
                    )} out of ${totalCount} customers`}</span>
                  </div>
                </div>
                <div>
                  <CustomersTable
                    customers={customers}
                    pagination={pagination}
                    input={input}
                    loading={loading}
                    onEditCustomerClick={handleEditCustomerClick}
                    onDeleteCustomerClick={handleDeleteCustomerClick}
                    onChange={handlePagination}
                  />
                </div>
              </div>
            ) : (
              <div styleName="add-customer-container">
                <div>
                  <ContactsOutlined style={{ fontSize: '36px' }} />
                </div>
                <div styleName="add-customer-text">
                  Add your Customers to get started
                </div>
                <div>
                  <Button type="primary" onClick={handleAddCustomerClick}>
                    Add Customer
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
        <AddEditCustomerModal
          customer={customerSelected}
          customerAction={customerAction}
          visible={isModalOpen}
          onCancel={() => setIsModalOpen(false)}
          onSubmit={handleSubmit}
        />
      </>
    </ContentLayout>
  );
};

export default Customers;
