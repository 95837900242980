import React, { useCallback, useContext, useState } from 'react';
import { CloseCircleFilled } from '@ant-design/icons';
import { Button, Form, Input, Modal } from 'antd';

import ReportContext from '@totem/components/controlSystemReport/ControlSystemReportContext';
import { ControlSystemReportRecord } from '@totem/components/controlSystemReport/dataTypes';
import colors from '@totem/styles/colors';
import { ControlSystemUpdateInput } from '@totem/types/controlSystem';
import { getToken } from '@totem/utilities/accountUtilities';
import { CONTROL_SYSTEMS_ENDPOINT } from '@totem/utilities/endpoints';
import { CheckResponseShowError } from '@totem/utilities/responseUtilities';

import DetailSpinner from '../../components/common/DetailSpinner';

import '../common/deleteControlSystemModal/deleteControlSystemModal.css';

const FormItem = Form.Item;

const styles = {
  form: {
    width: '100%',
  },
  errorIcon: {
    color: colors.utility.error,
  },
  details: {
    fontSize: '1.2rem',
  },
};

export interface Props {
  open: boolean;
  onClose: () => void;
  controlSystem: ControlSystemReportRecord;
  loading?: boolean;
  onComplete?: Function;
}

const DeleteControlSystemModal = ({
  open,
  onClose,
  controlSystem,
  loading,
  onComplete,
}: Props) => {
  const [confirm, setConfirm] = useState<string>('');
  const [isSending, setIsSending] = useState<boolean>(false);
  const { onAction } = useContext(ReportContext);

  const sendDeleteControlSystem = useCallback(
    async (payload: ControlSystemUpdateInput) => {
      if (isSending) {
        return;
      }
      setIsSending(true);

      fetch(`${CONTROL_SYSTEMS_ENDPOINT}/${payload.id}`, {
        method: 'DELETE',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
      }).then((res) => {
        CheckResponseShowError(res);
        setIsSending(false);
        onAction('refresh');
      });
    },
    [],
  );

  const onCancel = () => {
    onClose();
    setConfirm('');
  };

  const onOk = () => {
    sendDeleteControlSystem(controlSystem);

    setConfirm('');
    onClose();

    if (onComplete) {
      onComplete();
    }
  };

  const isDeleteDisabled = confirm !== 'DELETE';

  return (
    <Modal
      title={
        <div>
          <CloseCircleFilled style={styles.errorIcon} />
          <span styleName="title">Delete Control System</span>
        </div>
      }
      open={open}
      footer={
        <div styleName="footer">
          <Button onClick={onCancel}>Cancel</Button>
          <Button
            type="primary"
            danger
            loading={loading}
            disabled={isDeleteDisabled}
            onClick={onOk}
          >
            Yes, Delete
          </Button>
        </div>
      }
      onCancel={onCancel}
      /*
       * used for the purposes of unmounting the AssignedContentCollapse
       * component to render collapse panel inactive onClose
       */
      destroyOnClose
    >
      {loading ? (
        <div styleName="spinner-container">
          <DetailSpinner color={colors.utility.error} />
        </div>
      ) : (
        <>
          <div styleName="description">
            Deleting this control system will remove all audit responses, and
            and notes attached to the system.
          </div>
          <div styleName="collapse-title">
            This content will be removed with the control system:
          </div>
          <Form layout="vertical" style={styles.form}>
            <FormItem
              label={
                <span>
                  Type <strong>"DELETE"</strong> to confirm
                </span>
              }
              colon={false}
            >
              <Input
                value={confirm}
                onChange={(event) => setConfirm(event.target.value)}
              />
            </FormItem>
          </Form>
        </>
      )}
    </Modal>
  );
};

export default DeleteControlSystemModal;
