import React, { useContext } from 'react';
import { ColumnProps } from 'antd/lib/table';
import dayjs from 'dayjs';

import Table from '@totem/components/common/table/Table';
import SoftwarePatchInstallContext from '@totem/components/devices/softrware-patch-installs/softwarePatchInstallContext';
import { SoftwarePatchInstall } from '@totem/components/devices/softrware-patch-installs/types';
import { formatDateTimeDisplay } from '@totem/utilities/timeUtilities';

const SoftwarePatchInstallsTable = () => {
  const { data, loading } = useContext(SoftwarePatchInstallContext);

  const getData = () => {
    if (typeof data !== 'undefined' && data !== null) {
      return data;
    }
    return [];
  };

  const columns: ColumnProps<SoftwarePatchInstall>[] = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      defaultSortOrder: 'ascend',
      sorter: (compA, compB) =>
        compA.title === compB.title ? 0 : compA.title > compB.title ? 1 : -1,
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      sorter: (compA, compB) =>
        compA.type === compB.type ? 0 : compA.type > compB.type ? 1 : -1,
    },
    {
      title: 'Impact',
      dataIndex: 'impact',
      key: 'impact',
      sorter: (compA, compB) =>
        compA.impact === compB.impact
          ? 0
          : compA.impact > compB.impact
          ? 1
          : -1,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: (compA, compB) =>
        compA.status === compB.status
          ? 0
          : compA.status > compB.status
          ? 1
          : -1,
    },
    {
      title: 'Installed At',
      dataIndex: 'installedAt',
      key: 'installedAt',
      defaultSortOrder: 'descend',
      render: (_, record: SoftwarePatchInstall) =>
        formatDateTimeDisplay(dayjs.unix(record.installedAt).toISOString()),
      sorter: (compA, compB) =>
        compA.installedAt === compB.installedAt
          ? 0
          : compA.installedAt > compB.installedAt
          ? 1
          : -1,
    },
  ];

  return (
    <Table
      showSorterTooltip
      columns={columns}
      dataSource={getData()}
      loading={loading}
      rowKey={(record) => record.id}
    />
  );
};

export default SoftwarePatchInstallsTable;
