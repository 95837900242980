import React, { useContext, useEffect, useState } from 'react';
import { Empty } from 'antd';

import LayoutContext from '@totem/components/devices/variables/presentation/layout/LayoutContext';
import SectionContext from '@totem/components/devices/variables/presentation/layout/layoutSection/SectionContext';
import CurrentValue from '@totem/components/devices/variables/presentation/layout/templates/components/CurrentValue';
import {
  LayoutSectionVariable,
  VariableDataResponseExtended,
} from '@totem/components/devices/variables/presentation/layout/types';
import { isNotNull, isNull } from '@totem/utilities/common';

interface LocalRecord {
  variableDataResponseExtended: VariableDataResponseExtended;
  max_decimal_places: number;
}

const CurrentValues = () => {
  const { sectionData, sectionLayout } = useContext(SectionContext);
  const { variableData } = useContext(LayoutContext);

  const [sectionVariables, setSectionVariables] =
    useState<LayoutSectionVariable[]>();
  const [localData, setLocalData] = useState<LocalRecord[]>([]);

  useEffect(() => {
    if (isNotNull(sectionLayout)) {
      setSectionVariables(sectionLayout.variables);
    }
  }, [sectionLayout]);

  useEffect(() => {
    const newLocalData: LocalRecord[] = [];
    if (
      isNotNull(sectionData) &&
      isNotNull(sectionData.variables) &&
      isNotNull(variableData) &&
      isNotNull(sectionVariables)
    ) {
      sectionData.variables.forEach((sectionVariable) => {
        const variableDataValue = variableData.find(
          (elem) => elem.id === sectionVariable.variableId,
        );
        if (typeof variableDataValue !== 'undefined') {
          const sectionVariableDefinition = sectionVariables.find(
            (elem) =>
              (elem.type === 'variablePurpose' &&
                elem.id === variableDataValue.metaData.purpose) ||
              (elem.type === 'variable' && elem.id === variableDataValue.id),
          );

          if (typeof sectionVariableDefinition !== 'undefined') {
            newLocalData.push({
              variableDataResponseExtended: variableDataValue,
              max_decimal_places: sectionVariableDefinition.max_decimal_places,
            });
          }
        }
      });
      setLocalData(newLocalData);
    }
  }, [sectionData, variableData, sectionVariables]);

  return (
    <>
      {isNotNull(localData) &&
        localData.map((record) => {
          return (
            <CurrentValue
              key={record.variableDataResponseExtended.id}
              value={record.variableDataResponseExtended}
              maxDecimalPlaces={record.max_decimal_places}
            />
          );
        })}
      {(isNull(localData) || localData.length === 0) && <Empty />}
    </>
  );
};

export default CurrentValues;
