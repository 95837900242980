import React, { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { UploadOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, Upload } from 'antd';
import * as R from 'ramda';

import * as notificationActions from '@totem/actions/notificationActions';
import FileTile from '@totem/components/common/fileTile/FileTile';
import ModalTitle from '@totem/components/ModalTitle';
import api from '@totem/utilities/api';
import { NMAP_ENDPOINT } from '@totem/utilities/endpoints';

import BuildingDetailContext from '../BuildingDetailContext';

import NmapContext from './NmapContext';

import './nmap.css';

const FormItem = Form.Item;

const styles = {
  predefinedMenuOption: {
    fontWeight: 600,
  },
  uploadButton: {
    width: '412px',
  },
  form: {
    width: '100%',
    marginTop: '1rem',
  },
  formItem: {
    paddingBottom: '0',
    marginBottom: '1rem',
    width: '100%',
  },
};

interface FormErrors {
  name?: string;
  file?: string;
}

interface Props {
  open: boolean;
  setOpen: (data: boolean) => void;
}

const AddNmapFileModal = ({ open, setOpen }: Props) => {
  const dispatch = useDispatch();
  const { building } = useContext(BuildingDetailContext);
  const { refetch } = useContext(NmapContext);

  const [name, setName] = useState<string>('');
  const [errors, setErrors] = useState<FormErrors>({ name: '', file: '' });
  const [file, setFile] = useState<File>();
  const [loading, setLoading] = useState<boolean>(false);

  const isValidForm = (): boolean => {
    let isValid = true;
    if (R.isNil(file) || R.isEmpty(file)) {
      setErrors({ ...errors, file: 'Please select a file' });
      isValid = false;
    }

    if (R.isEmpty(name)) {
      setErrors({ ...errors, name: 'Please input a name' });
      isValid = false;
    }

    return isValid;
  };

  const closeModal = () => {
    setName('');
    setFile(null);
    setErrors({ name: '', file: '' });
    setOpen(false);
  };

  const onOk = async () => {
    if (isValidForm()) {
      setLoading(true);

      try {
        dispatch(
          notificationActions.createNotification({
            message: 'Uploading NMAP Analysis...',
          }),
        );

        const formData = new FormData();

        formData.append('file', file);
        formData.append('name', name);
        formData.append('regionId', building.region.id);
        formData.append('buildingId', building.id);

        await api.post(NMAP_ENDPOINT, formData);

        dispatch(
          notificationActions.createNotification({
            message: 'Uploaded NMAP Analysis successfully',
          }),
        );

        refetch();
      } catch (error) {
        dispatch(
          notificationActions.createNotification({
            message: 'NMAP Analysis Failed to Upload',
          }),
        );
      }

      closeModal();
      setLoading(false);
    }
  };

  const removeFile = () => {
    setFile(null);
  };

  const handleUpload = (newFile: File): boolean => {
    setFile(newFile);
    return false;
  };

  const fileComponent =
    R.isEmpty(file) || R.isNil(file) ? (
      <Upload
        beforeUpload={(newFile: File) => handleUpload(newFile)}
        accept=".xml"
        name="file"
      >
        <Button
          type="primary"
          icon={<UploadOutlined />}
          style={styles.uploadButton}
        >
          Select File
        </Button>
      </Upload>
    ) : (
      <FileTile name={file.name} removeFile={removeFile} />
    );

  return (
    <Modal
      title={<ModalTitle>Upload NMAP File</ModalTitle>}
      open={open}
      onOk={() => onOk()}
      okText="Add"
      confirmLoading={loading}
      onCancel={closeModal}
    >
      <div styleName="modal-container">
        <div styleName="file-upload-control">{fileComponent}</div>
        {errors.file && <div styleName="file-error-text">{errors.file}</div>}
        <Form layout="vertical" style={styles.form} autoComplete="off">
          <FormItem
            label="Name"
            validateStatus={!errors.name ? 'success' : 'error'}
            help={!errors.name ? null : errors.name}
            colon={false}
            style={styles.formItem}
          >
            <Input
              disabled={loading}
              value={name}
              name="name"
              autoComplete="off"
              onChange={(event) => setName(event.target.value)}
            />
          </FormItem>
        </Form>
      </div>
    </Modal>
  );
};

export default AddNmapFileModal;
