import React, { ReactNode, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import PageContext from '@totem/components/devices/variables/presentation/layout/PageContext';
import { useRoute } from '@totem/components/RouteContext';

type Props = {
  children?: ReactNode;
};

const PageContainer = ({ children }: Props) => {
  const route = useRoute();
  const [title, setTitle] = useState<string>(route.pageTitle);
  const [subTitle, setSubTitle] = useState<string>('');
  const [searchParams] = useSearchParams();
  const isDebugString = searchParams.get('debug');

  const handleSetPageTitle = (newTitle: string) => {
    setTitle(newTitle);
  };

  const handleSubTitleChange = (newSubTitle: string) => {
    setSubTitle(newSubTitle);
  };

  return (
    <PageContext.Provider
      value={{
        isDebug: isDebugString === 'true',
        pageTitle: title,
        setTitle: handleSetPageTitle,
        pageSubtitle: subTitle,
        setSubtitle: handleSubTitleChange,
      }}
    >
      {children}
    </PageContext.Provider>
  );
};

export default PageContainer;
