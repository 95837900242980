import React, { useState } from 'react';
import { Form, Input } from 'antd';

import {
  ActivityExtendedField,
  ExtendedFieldValue,
} from '@totem/types/ticketing';

const FormItem = Form.Item;

import '../ticketing.css';

const styles = {
  form: {
    width: '100%',
  },
  formItem: {
    paddingBottom: '0',
    marginBottom: '1rem',
  },
};

type Props = {
  extendedField: ActivityExtendedField;
  extendedFieldValue: ExtendedFieldValue;
  onChange: (name: string, value: string, isValid: boolean) => void;
};

const ExtendedFieldString = ({
  extendedField,
  extendedFieldValue,
  onChange,
}: Props) => {
  const [selectedValue, setSelectedValue] = useState<string>(
    extendedFieldValue !== null ? extendedFieldValue.value : '',
  );

  const checkIsValid = checkValue => {
    if (extendedField.isRequired && checkValue.trim() === '') {
      return false;
    }
    return true;
  };

  const handleChange = ({
    target: { value, name },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(value);
    if (extendedFieldValue.isValid !== checkIsValid(value)) {
      onChange(name, value, checkIsValid(value));
    }
  };

  const handleBlur = ({
    target: { value, name },
  }: React.ChangeEvent<HTMLInputElement>) => {
    onChange(name, value, checkIsValid(value));
  };

  return (
    <FormItem
      label={extendedField.label}
      colon={false}
      style={styles.formItem}
      required={extendedField.isRequired}
      validateStatus={extendedFieldValue.isValid ? 'success' : 'error'}
    >
      <Input
        name={extendedField.name}
        defaultValue={selectedValue}
        onChange={handleChange}
        onBlur={handleBlur}
        type="text"
      />
    </FormItem>
  );
};

export default ExtendedFieldString;
