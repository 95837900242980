import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Card, Input, Select } from 'antd';

import FormFieldLabel from '@totem/components/FormFieldLabel';
import TemplateContext from '@totem/components/surveyV2/templateDetails/SurveyTemplateContext';
import { Level, SurveyTemplate } from '@totem/components/surveyV2/types';
import { getToken } from '@totem/utilities/accountUtilities';
import { debounce } from '@totem/utilities/debounce';
import { V2_SURVEY_ENDPOINT } from '@totem/utilities/endpoints';

const DEBOUNCE_TIME = 750;

const styles = {
  selectStyle: {
    width: 200,
  },
};

type Props = {
  level: Level;
};

const SurveyTemplateLevelPane = ({ level }: Props) => {
  const { surveyTemplateId, data, setData, setIsLoading } =
    useContext(TemplateContext);
  const [localState, setLocalState] = useState<Level>(level);
  const [levelUpdated, setLevelUpdated] = useState<boolean>(false);

  const sendLevelChanged = useCallback(
    debounce((templateId: string, update: Level) => {
      setIsLoading(true);

      const payload = {
        name: update.name,
        scoreBy: update.scoreBy,
        subLevels: update.sublevels,
      };

      fetch(
        `${V2_SURVEY_ENDPOINT}/template/${templateId}/levels/${update.id}`,
        {
          method: 'POST',
          headers: new Headers({
            Authorization: `Bearer ${getToken()}`,
          }),
          body: JSON.stringify(payload),
        },
      )
        .then((res) => res.json())
        .then((result: SurveyTemplate) => {
          setData(result);
        })
        .then(() => {
          setIsLoading(false);
        });
    }, DEBOUNCE_TIME),
    [],
  );

  useEffect(() => {
    if (levelUpdated) {
      setLevelUpdated(false);
      sendLevelChanged(surveyTemplateId, localState);
    }
  }, [localState]);

  const getSubLevelOptions = () => {
    const availableLevels = data.levels.filter((lvl) => lvl.type < level.type);
    return availableLevels.map((lvl) => {
      return {
        value: lvl.id,
        label: lvl.name,
      };
    });
  };

  return (
    <Card>
      <FormFieldLabel>Name</FormFieldLabel>
      <Input
        placeholder="Name"
        value={localState.name}
        onChange={({
          target: { value },
        }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
          setLevelUpdated(true);
          setLocalState({ ...localState, name: value });
        }}
      />

      <FormFieldLabel>Score By</FormFieldLabel>
      <Select
        style={styles.selectStyle}
        value={level.scoreBy}
        options={[
          {
            value: 'questions',
            label: 'Questions',
          },
          {
            value: 'sublevels',
            label: 'Sub Levels',
          },
        ]}
        onChange={(value) => {
          setLevelUpdated(true);
          setLocalState({ ...localState, scoreBy: value });
        }}
      />

      {level.scoreBy === 'sublevels' && (
        <>
          <FormFieldLabel>Sub Levels</FormFieldLabel>
          <Select
            style={styles.selectStyle}
            mode={'multiple'}
            allowClear
            value={level.sublevels}
            options={getSubLevelOptions()}
            onChange={(value) => {
              setLevelUpdated(true);
              setLocalState({ ...localState, sublevels: value });
            }}
          />
        </>
      )}
    </Card>
  );
};

export default SurveyTemplateLevelPane;
