import React from 'react';

import { CVERecord } from '@totem/components/software/cves/types';

import './cve.css';

type Props = {
  cve: CVERecord;
};
const CveTitle = ({ cve }: Props) => {
  return <div styleName="summaryText">{cve.cve.summary}</div>;
};

export default CveTitle;
