import { RemoteAccessUser } from '@totem/components/remoteAccess/types';

export const FLAG_STATUS_OK: string = '1';
export const FLAG_STATUS_NEVER_LOGGED_IN: string = '2';
export const FLAG_STATUS_INACTIVE: string = '4';

export const getFlagStatus = (user: RemoteAccessUser) => {
  if (user.logins_count === 0) {
    return FLAG_STATUS_NEVER_LOGGED_IN;
  }
  if (user.logins_count > 0 && user.isStale) {
    return FLAG_STATUS_INACTIVE;
  }
  return FLAG_STATUS_OK;
};
