import React from 'react';

import BasePane from '@totem/components/BasePane';
import CardBody from '@totem/components/CardBody';
import CardHeader from '@totem/components/CardHeader';
import ContentLayout from '@totem/components/ContentLayout';
import EndOfLifeContainer from '@totem/components/endoflife/EndOfLifeContainer';
import EndOfLifeHeader from '@totem/components/endoflife/EndOfLifeHeader';
import EndOfLifeTable from '@totem/components/endoflife/EndOfLifeTable';
import PrimaryContentPane from '@totem/components/PrimaryContentPane';

import './endOfLife.css';

const EndOfLifePage = () => {
  return (
    <ContentLayout>
      <EndOfLifeContainer>
        <div styleName="widget-panel">
          <BasePane>
            <PrimaryContentPane>
              <CardHeader>
                <div>Product End-of-Life</div>
                <EndOfLifeHeader />
              </CardHeader>
              <CardBody>
                <EndOfLifeTable />
              </CardBody>
            </PrimaryContentPane>
          </BasePane>
        </div>
      </EndOfLifeContainer>
    </ContentLayout>
  );
};

export default EndOfLifePage;
