import colors from '@totem/styles/colors';
import { Criticality } from '@totem/types/criticality';
import {
  Finding,
  FindingAdditionalData,
  FindingCriticalityCounts,
} from '@totem/types/finding';

const getSeverityColor = severity => {
  const grade = severity.toLowerCase();

  const gradeColors = {
    info: colors.findings.info,
    low: colors.findings.low,
    medium: colors.findings.medium,
    high: colors.findings.high,
    'very high': colors.findings.very_high,
    critical: colors.findings.critical,
    'extremely critical': colors.findings.extremely_critical,
  };

  return gradeColors[grade] || colors.findings.info;
};

const getSeverityLevel = severity => {
  const severities = [
    'info',
    'low',
    'medium',
    'high',
    'very high',
    'critical',
    'extremely critical',
  ];

  if (!severity) {
    return 0;
  }

  const level = severities.indexOf(severity.toLowerCase());
  return level || 0;
};

export const NON_COMPLIANT_CRITICALITIES = [
  Criticality.Low,
  Criticality.Medium,
  Criticality.High,
  Criticality.Critical,
];

export const getNonCompliantCount = (findings: Finding[]) => {
  return findings.reduce(
    (acc, { criticality }) =>
      NON_COMPLIANT_CRITICALITIES.includes(criticality) ? acc + 1 : acc,
    0,
  );
};

export const sumNonCompliantCounts = ({
  low,
  medium,
  high,
  critical,
}: FindingCriticalityCounts): number => {
  return low + medium + high + critical;
};

export const stripNewlines = (text: string): string => {
  // eslint-disable-next-line no-control-regex
  const regex = new RegExp('\n', 'g');
  return text.replace(regex, '');
};

export const getFindingAdditionalData = (
  { additionalData }: Finding,
  type: FindingAdditionalData,
) => {
  const data = additionalData.find(({ label }) => label === type);
  return data ? stripNewlines(data.text) : '';
};

export const getPlainTextFinding = (finding: Finding): string => {
  const { description } = finding;

  const context = getFindingAdditionalData(
    finding,
    FindingAdditionalData.AdditionalContext,
  );

  const policy = getFindingAdditionalData(
    finding,
    FindingAdditionalData.Policy,
  );

  const resolution = getFindingAdditionalData(
    finding,
    FindingAdditionalData.Resolution,
  );

  return [description, context, policy, resolution].join('\n');
};

const findingsUtilities = {
  getSeverityColor,
  getSeverityLevel,
};

export default findingsUtilities;
