import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { ColumnProps } from 'antd/lib/table';
import dayjs from 'dayjs';

import Table from '@totem/components/common/table/Table';
import DeviceSoftwareEndOfSupportContext from '@totem/components/devices/softwareEndOfLife/summary/DeviceSoftwareEndOfSupportContext';
import { DeviceSoftwareEndOfSupportRecord } from '@totem/components/devices/softwareEndOfLife/summary/types';
import { isNotNull } from '@totem/utilities/common';
import { sortStringAscending } from '@totem/utilities/tableUtilities';

const localizedFormat = require('dayjs/plugin/localizedFormat');
dayjs.extend(localizedFormat);

const PastEndOfLifeReport = () => {
  const { loading, data } = useContext(DeviceSoftwareEndOfSupportContext);

  const columns: ColumnProps<DeviceSoftwareEndOfSupportRecord>[] = [
    {
      title: 'Device Name',
      dataIndex: 'displayName',
      key: 'deviceId',
      render: (_, record: DeviceSoftwareEndOfSupportRecord) => {
        return (
          <Link to={`/dashboard/devices/${record.deviceId}`}>
            {record.displayName}
          </Link>
        );
      },
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        sortStringAscending(compA.displayName, compB.displayName),
    },
    {
      title: 'Region',
      dataIndex: 'regionName',
      key: 'regionId',
      render: (_, record: DeviceSoftwareEndOfSupportRecord) => {
        return <span>{record.regionName}</span>;
      },
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        sortStringAscending(compA.regionName, compB.regionName),
    },
    {
      title: 'Building',
      dataIndex: 'buildingName',
      key: 'buildingId',
      render: (_, record: DeviceSoftwareEndOfSupportRecord) => {
        return (
          <Link to={`/dashboard/buildings/${record.buildingId}`}>
            {record.buildingName}
          </Link>
        );
      },
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        sortStringAscending(compA.buildingName, compB.buildingName),
    },
    {
      title: 'Control System',
      dataIndex: 'controlSystemName',
      key: 'controlSystemId',
      render: (_, record: DeviceSoftwareEndOfSupportRecord) => {
        return (
          <Link to={`/dashboard/controlsystems/${record.controlSystemId}`}>
            {record.controlSystemName}
          </Link>
        );
      },
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        sortStringAscending(compA.controlSystemName, compB.controlSystemName),
    },
    {
      title: 'Software Package',
      key: 'softwarePackageName',
      dataIndex: 'softwarePackageName',
      render: (_, record: DeviceSoftwareEndOfSupportRecord) => {
        return (
          <Link to={`/dashboard/software/${record.softwarePackageId}`}>
            {record.softwarePackageName}
          </Link>
        );
      },
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        sortStringAscending(
          compA.softwarePackageName,
          compB.softwarePackageName,
        ),
    },
    {
      title: 'Version',
      dataIndex: 'softwareVersion',
      key: 'softwareVersion',
      render: (_, record: DeviceSoftwareEndOfSupportRecord) => {
        return record.softwareVersion;
      },
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        sortStringAscending(compA.softwareVersion, compB.softwareVersion),
    },
    {
      title: 'Publisher',
      dataIndex: 'softwarePublisher',
      key: 'softwarePublisher',
      render: (_, record: DeviceSoftwareEndOfSupportRecord) => {
        return record.softwarePublisher;
      },
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        sortStringAscending(compA.softwarePublisher, compB.softwarePublisher),
    },
    {
      title: 'End-of-Support Date',
      dataIndex: 'softwareEndOfSupportDate',
      key: 'softwareEndOfSupportDate',
      render: (_, record: DeviceSoftwareEndOfSupportRecord) => {
        return record.softwareEndOfSupportDate === '0001-01-01T00:00:00Z'
          ? 'Date Not Available'
          : dayjs(record.softwareEndOfSupportDate).format('L');
      },
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        sortStringAscending(
          compA.softwareEndOfSupportDate,
          compB.softwareEndOfSupportDate,
        ),
    },
  ];

  const getData = () => {
    if (isNotNull(data) && isNotNull(data.pastEndOfSupport)) {
      return data.pastEndOfSupport;
    }
    return [];
  };

  return (
    <Table
      showSorterTooltip
      columns={columns}
      dataSource={getData()}
      loading={loading}
      rowKey={(record) =>
        record.deviceId + record.softwarePackageId + record.softwareVersion
      }
    />
  );
};

export default PastEndOfLifeReport;
