import React, { useCallback, useContext, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import {
  BankOutlined,
  ClockCircleOutlined,
  FileOutlined,
  FileProtectOutlined,
  FolderViewOutlined,
  GroupOutlined,
  ImportOutlined,
  LogoutOutlined,
  SafetyOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { Layout, Menu, MenuProps } from 'antd';

import APIHeartBeat from '@totem/components/APIHeartBeat';
import LeftNavLogoLarge from '@totem/components/logos/LeftNavLogoLarge';
import LeftNavLogoSmall from '@totem/components/logos/LeftNavLogoSmall';
import ReportsContext from '@totem/components/ui_components/reports/ReportsContext';
import UserProfileContext from '@totem/components/UserProfileContext';
import { staticBranding } from '@totem/selectors/preferencesSelectors';
import colors from '@totem/styles/colors';
import IBDIcon from '@totem/styles/Icon';
import { BrandingPreference } from '@totem/types/organization';
import accountUtilities from '@totem/utilities/accountUtilities';
import authUtilities from '@totem/utilities/authUtilities';
import { isNotNull } from '@totem/utilities/common';
import { isIBUser } from '@totem/utilities/security';
import {
  getUserRole,
  hasActiveService,
  hasEventManagement,
  isMemberOfAny,
  securityGroupCheckConstraintEmpty,
} from '@totem/utilities/userUtilities';

import './base.css';

const { Sider } = Layout;

const styles = {
  sider: {
    background: colors.neutral.white,
    borderRight: `2px solid ${colors.neutral.typhoon}`,
  },
  fontAwesomeIcon: {
    paddingRight: '10px',
  },
};

const LeftNav = () => {
  const { userProfile } = useContext(UserProfileContext);
  const { reports } = useContext(ReportsContext);
  const navigate = useNavigate();
  const { questionnaire: showQuestionnaire } =
    userProfile.organization?.preferences.features;

  const isIB = isIBUser(userProfile);
  const isIBOrg =
    userProfile.organization.id === '5b0cf2f679e1b20fd9bb4993' ||
    userProfile.organization.id === '5b2f1be53911bd000175043a';

  const userCanSeeContractManagement = isMemberOfAny(
    userProfile,
    ['contract_creator'],
    securityGroupCheckConstraintEmpty,
  );

  const userCanSeeRemoteAccess = isMemberOfAny(
    userProfile,
    [
      'remote_access_viewer',
      'remote_access_admin',
      'remote_access_configuration_admin',
    ],
    securityGroupCheckConstraintEmpty,
  );

  const userCanSeeTicketing =
    isMemberOfAny(
      userProfile,
      [
        'ticket_viewer',
        'ticket_updater',
        'ticket_creator',
        'ticket_technician',
        'ticket_administrator',
      ],
      securityGroupCheckConstraintEmpty,
    ) || getUserRole(userProfile) === 3;

  const hasReports = isNotNull(reports) && reports.length > 0;

  const showAssetInventory =
    hasActiveService(userProfile, 'asset_inventory') &&
    getUserRole(userProfile) > 0;
  const showRemoteAccess = hasActiveService(userProfile, 'remote_access');
  const userCanSeeEvents = hasEventManagement(userProfile);
  const hasContinuity = hasActiveService(userProfile, 'backup');

  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [showTokenExpirationMenuItem, setShowTokenExpirationMenuItem] =
    useState<boolean>(false);
  const [showTokenExpirationDialog, setShowTokenExpirationDialog] =
    useState<boolean>(false);

  const handleLogout = useCallback(() => {
    accountUtilities.logout();
    navigate('/login');
  }, []);

  const handleCollapse = useCallback(() => {
    setCollapsed(!collapsed);
  }, [collapsed]);

  const getSubMenuPortfolio = () => {
    const items: MenuProps['items'] = [
      {
        key: 'portfolio',
        title: 'Overview',
        label: <NavLink to="/dashboard/portfolio">Overview</NavLink>,
        icon: <IBDIcon name="Portfolio" />,
      },
      {
        key: 'dashboard',
        title: 'My Dashboard',
        label: <NavLink to="/dashboard/home">My Dashboard</NavLink>,
        icon: <IBDIcon name="Dashboard" />,
      },
    ];

    // if (showRegion) {
    //   items.push({
    //     key: 'regions',
    //     title: `${regionLabel}s`,
    //     label: <NavLink to="/dashboard/regions">{`${regionLabel}s`}</NavLink>,
    //     icon: <IBDIcon name="Region" />,
    //   });
    // }

    items.push({
      key: 'buildings',
      title: 'Buildings',
      label: <NavLink to="/dashboard/buildings">Buildings</NavLink>,
      icon: <IBDIcon name="Building" />,
    });
    items.push({
      key: 'controlSystems',
      title: 'Control Systems',
      label: (
        <NavLink to="/dashboard/controlSystemReport">Control Systems</NavLink>
      ),
      icon: <IBDIcon name="Control System" />,
    });

    if (userCanSeeEvents) {
      items.push({
        key: 'devices',
        title: 'Devices',
        label: <NavLink to="/dashboard/devices">Devices</NavLink>,
        icon: <IBDIcon name="Device" />,
      });
    }

    return items;
  };

  // const getSubMenuAudits = () => {
  //   const items: MenuProps['items'] = [
  //     {
  //       key: 'policyaudits',
  //       title: 'Management',
  //       label: <NavLink to="/dashboard/policyaudits">Management</NavLink>,
  //       icon: <IBDIcon name="Policy Audit" />,
  //     },
  //   ];
  //
  //   if (showPolicy) {
  //     items.push({
  //       key: 'policies',
  //       title: 'Policies',
  //       label: <NavLink to="/dashboard/policies">Policies</NavLink>,
  //       icon: <IBDIcon name="Policy" />,
  //     });
  //   }
  //
  //   items.push({
  //     key: 'findings',
  //     title: 'Analysis',
  //     label: <NavLink to="/dashboard/findings/policyaudits">Analysis</NavLink>,
  //     icon: <IBDIcon name="Finding" />,
  //   });
  //
  //   return items;
  // };

  const getSubMenuServices = () => {
    const items: MenuProps['items'] = [];

    if (userCanSeeEvents) {
      items.push({
        key: 'events',
        title: 'Events',
        label: (
          <NavLink to="/dashboard/events?status=CREATED&status=ACKNOWLEDGED">
            Events
          </NavLink>
        ),
        icon: <IBDIcon name="Event" />,
      });
    }

    if (showAssetInventory) {
      items.push({
        key: 'assetInventory',
        title: 'Asset Inventory',
        label: (
          <NavLink to="/dashboard/assetInventory">Asset Inventory</NavLink>
        ),
        icon: <IBDIcon name="Asset Inventory" />,
      });
    }

    if (hasContinuity) {
      items.push({
        key: 'backups',
        title: 'Backups',
        label: <NavLink to="/dashboard/deviceBackups">Backups</NavLink>,
        icon: <IBDIcon name="Backups" />,
      });
    }

    if (showRemoteAccess && userCanSeeRemoteAccess) {
      items.push({
        key: 'remoteAccessUsers',
        title: 'Remote Access',
        label: (
          <NavLink to="/dashboard/remoteAccessUsers">Remote Access</NavLink>
        ),
        icon: <IBDIcon name="Remote Access" />,
      });
    }

    return items;
  };

  const getSubMenuTicketing = () => {
    const items: MenuProps['items'] = [
      {
        key: 'ticketing',
        title: 'Open Tickets',
        label: <NavLink to="/dashboard/ticketing">Open Tickets</NavLink>,
        icon: <IBDIcon name="Ticket" />,
      },
    ];

    if (isIB) {
      items.push({
        key: 'ticket_aging',
        title: 'Aging',
        label: <NavLink to="/dashboard/ticketAging">Aging</NavLink>,
        icon: <ClockCircleOutlined />,
      });
      items.push({
        key: 'ticket_queues',
        title: 'Aging',
        label: <NavLink to="/dashboard/ticketingQueues">Queues</NavLink>,
        icon: <FolderViewOutlined />,
      });
    }

    return items;
  };

  const getSubMenuSurveys = () => {
    const items: MenuProps['items'] = [
      {
        key: 'surveyinstances',
        title: 'Management',
        label: <NavLink to="/dashboard/surveyinstances">Management</NavLink>,
        icon: <IBDIcon name="Survey" />,
      },
      {
        key: 'templates',
        title: 'Templates',
        label: <NavLink to="/dashboard/surveytemplates">Templates</NavLink>,
        icon: <IBDIcon name="Survey Template" />,
      },
      {
        key: 'auditsv2',
        title: 'Audits',
        label: <NavLink to="/dashboard/policyaudits/v2">Audits</NavLink>,
        icon: <IBDIcon name="Policy" />,
      },
      {
        key: 'policiesv2',
        title: 'Policies',
        label: <NavLink to="/dashboard/policies/v2">Policies</NavLink>,
        icon: <IBDIcon name="Policy" />,
      },
      {
        key: 'findingsv2',
        title: 'Analysis',
        label: (
          <NavLink to="/dashboard/findings/policyaudits/v2">Analysis</NavLink>
        ),
        icon: <IBDIcon name="Finding" />,
      },
    ];

    return items;
  };

  const getSubMenuReports = () => {
    const items: MenuProps['items'] = [];

    if (isIB && isIBOrg) {
      items.push({
        key: 'crossorgdevices',
        title: 'Devices',
        label: <NavLink to="/dashboard/adminDevices">Devices</NavLink>,
        icon: <IBDIcon name="Device" />,
      });
      items.push({
        key: 'crossorggateways',
        title: 'Gateways',
        label: <NavLink to="/dashboard/adminGateways">Gateways</NavLink>,
        icon: <IBDIcon name="Device" />,
      });
    }

    if (isIB && isIBOrg && userCanSeeContractManagement) {
      items.push({
        key: 'netsuite_pending',
        title: 'NetSuite Pending',
        label: (
          <NavLink to="/dashboard/pendingImports">NetSuite Pending</NavLink>
        ),
        icon: <ImportOutlined />,
      });
    }

    if (hasReports) {
      for (let idx = 0; idx < reports.length; idx++) {
        items.push({
          key: reports[idx].id,
          title: reports[idx].name,
          label: (
            <NavLink to={`/dashboard/${reports[idx].link}`}>
              {reports[idx].name}
            </NavLink>
          ),
          icon: <FileOutlined />,
        });
      }
    }

    return items;
  };

  const getSubMenuAdmin = () => {
    const items: MenuProps['items'] = [];

    if (
      authUtilities.minimumRequiredRole(
        authUtilities.ROLE_SETS.SYSTEMS_ADMINISTRATOR,
      )
    ) {
      items.push({
        key: 'account',
        title: 'Account',
        label: <NavLink to="/dashboard/settings">Account</NavLink>,
        icon: <SettingOutlined />,
      });

      if (isIB) {
        items.push({
          key: 'mappings',
          title: 'Mappings',
          label: <NavLink to="/dashboard/admin/mappings">Mappings</NavLink>,
          icon: <ImportOutlined />,
        });

        items.push({
          key: 'deviceTypes',
          title: 'Device Types',
          label: <NavLink to="/dashboard/deviceTypes">Device Types</NavLink>,
          icon: <ImportOutlined />,
        });

        items.push({
          key: 'software',
          title: 'Software',
          label: <NavLink to="/dashboard/software">Software</NavLink>,
          icon: <ImportOutlined />,
        });

        items.push({
          key: 'vendors',
          title: 'Vendors',
          label: <NavLink to="/dashboard/vendors">Vendors</NavLink>,
          icon: <ImportOutlined />,
        });
      }
    }

    if (
      authUtilities.minimumRequiredRole(
        authUtilities.ROLE_SETS.ORGANIZATION_ADMINISTRATOR,
      )
    ) {
      items.push({
        key: 'tenants',
        title: 'Tenants',
        label: <NavLink to="/dashboard/tenants">Tenants</NavLink>,
        icon: <GroupOutlined />,
      });
    }

    if (
      isIB &&
      authUtilities.minimumRequiredRole(
        authUtilities.ROLE_SETS.ORGANIZATION_ADMINISTRATOR,
      )
    ) {
      items.push({
        key: 'commissioning',
        title: 'Commissioning',
        label: <NavLink to="/dashboard/admin/commissioning">Commissioning</NavLink>,
        icon: <GroupOutlined />,
      });
    }

    return items;
  };

  const getMenuItems = () => {
    const items: MenuProps['items'] = [
      // {
      //   key: 'dashboard',
      //   title: 'Home',
      //   label: <NavLink to="/dashboard">Home</NavLink>,
      //   icon: <HomeOutlined />,
      // },
      {
        key: 'sub_portfolio',
        title: 'Portfolio',
        label: 'Portfolio',
        icon: <BankOutlined />,
        children: getSubMenuPortfolio(),
      },
      // {
      //   key: 'audits',
      //   title: 'Audits',
      //   label: 'Audits',
      //   icon: <FontAwsome icon={faListCheck} />,
      //   children: getSubMenuAudits(),
      // },
      {
        key: 'services',
        title: 'Services',
        label: 'Services',
        icon: <SafetyOutlined />,
        children: getSubMenuServices(),
      },
    ];

    if (userCanSeeTicketing) {
      items.push({
        key: 'ticketing_main',
        title: 'Ticketing',
        label: 'Ticketing',
        icon: <IBDIcon name="Ticket" />,
        children: getSubMenuTicketing(),
      });
    }

    if (showQuestionnaire) {
      items.push({
        key: 'surveys',
        title: 'Surveys',
        label: 'Surveys',
        icon: <IBDIcon name="Survey" />,
        children: getSubMenuSurveys(),
      });
    }

    if (
      authUtilities.minimumRequiredRole(
        authUtilities.ROLE_SETS.ORGANIZATION_ADMINISTRATOR,
      )
    ) {
      items.push({
        key: 'users',
        title: 'Users',
        label: <NavLink to="/dashboard/usermanagement">Users</NavLink>,
        icon: <IBDIcon name="Users" />,
      });
    }

    // TODO: Need to open the permissions up to ORGANIZATIONAL_ADMINISTSRATORS for Tenant Management
    if (
      authUtilities.minimumRequiredRole(
        authUtilities.ROLE_SETS.SYSTEMS_ADMINISTRATOR,
      )
    ) {
      items.push({
        key: 'admin',
        title: 'Admin',
        label: 'Admin',
        icon: <SettingOutlined />,
        children: getSubMenuAdmin(),
      });
    }

    if ((isIB && isIBOrg) || hasReports) {
      items.push({
        key: 'reports',
        title: 'Reports',
        label: 'Reports',
        icon: <FileProtectOutlined />,
        children: getSubMenuReports(),
      });
    }

    if (showTokenExpirationMenuItem) {
      items.push({
        key: 'session_expiring',
        title: 'Settings',
        label: <span style={{ color: 'red' }}>Session Expiring</span>,
        icon: <SafetyOutlined />,
      });
    }

    items.push({
      key: 'logout',
      title: 'Sign Out',
      label: <span>Sign Out</span>,
      icon: <LogoutOutlined />,
    });

    return items;
  };

  const onMenuClicked = ({ key }) => {
    // eslint-disable-next-line default-case
    switch (key) {
      case 'session_expiring':
        setShowTokenExpirationDialog(true);
        break;
      case 'logout':
        handleLogout();
        break;
    }
  };

  return (
    <Sider
      collapsible
      style={styles.sider}
      collapsed={collapsed}
      onCollapse={handleCollapse}
    >
      <div
        styleName="logo"
        onClick={() => navigate('/dashboard')}
        style={
          !collapsed &&
          staticBranding === BrandingPreference.INTELLIGENT_BUILDINGS
            ? { margin: '0 0 4rem 0' }
            : {}
        }
      >
        {collapsed && <LeftNavLogoSmall />}
        {!collapsed && <LeftNavLogoLarge />}
      </div>
      <Menu
        theme="light"
        mode="inline"
        onClick={onMenuClicked}
        items={getMenuItems()}
      />
      <APIHeartBeat
        onShowMenuItem={() => setShowTokenExpirationMenuItem(true)}
        onHideMenuItem={() => setShowTokenExpirationMenuItem(false)}
        showDialog={showTokenExpirationDialog}
        onCloseDialog={() => setShowTokenExpirationDialog(false)}
        onShowDialog={() => setShowTokenExpirationDialog(true)}
      />
    </Sider>
  );
};

export default LeftNav;
