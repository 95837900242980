import React from 'react';
import { Col, Row } from 'antd';

import PageSection from '@totem/components/PageSection';
import PortfolioCounts from '@totem/components/portfolio/summary/PortfolioCounts';
import Regions from '@totem/components/portfolio/summary/Regions';
import SummaryMap from '@totem/components/portfolio/summary/SummaryMap';

const SummaryLayout = () => {
  return (
    <PageSection>
      <Row>
        <Col span={8}>
          <SummaryMap />
        </Col>
        <Col span={8}>
          <PortfolioCounts />
        </Col>
        <Col span={8}>
          <Regions />
        </Col>
      </Row>
    </PageSection>
  );
};

export default SummaryLayout;
