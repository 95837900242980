import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import ExtendTokenModal from '@totem/components/ExtendTokenModal';
import { getToken } from '@totem/utilities/accountUtilities';
import { TOKEN_HEARTBEAT_ENDPOINT } from '@totem/utilities/endpoints';

import './base.css';

interface TokenHeartbeatResponse {
  isValid: boolean;
  expiration: string;
  ttl_seconds: number;
  refreshCount: number;
}

interface Props {
  onShowMenuItem: () => void;
  onHideMenuItem: () => void;
  showDialog: boolean;
  onShowDialog: () => void;
  onCloseDialog: () => void;
}
const APIHeartBeat = ({
  onShowMenuItem,
  onShowDialog,
  onHideMenuItem,
  showDialog,
  onCloseDialog,
}: Props) => {
  const long_time_between_checks_milliseconds = 5 * 60 * 1000; // 5 Minutes
  const short_time_between_checks_milliseconds = 1 * 60 * 1000; // 5 Minutes
  const navigate = useNavigate();
  const [isSending, setIsSending] = useState<boolean>(false);
  const [delay, setDelay] = useState<number>(
    long_time_between_checks_milliseconds,
  );
  const [intervalId, setIntervalId] = useState<number>(0);
  const [HeartbeatResponse, SetHeartBeatResponse] =
    useState<TokenHeartbeatResponse>({
      isValid: true,
      expiration: '',
      ttl_seconds: -1,
      refreshCount: 0,
    });

  const checkToken = useCallback(async () => {
    if (isSending) {
      return;
    }
    setIsSending(true);

    fetch(`${TOKEN_HEARTBEAT_ENDPOINT}`, {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      }),
    })
      .then((res) => {
        setIsSending(false);
        if (res.status === 401) {
          SetHeartBeatResponse({
            isValid: false,
            expiration: '',
            ttl_seconds: 0,
            refreshCount: 0,
          });
        } else if (res.status >= 400) {
          throw Error('Bad Response');
        }
        return res;
      })
      .then((res) => res.json())
      .then((result: TokenHeartbeatResponse) => {
        SetHeartBeatResponse(result);
      });
  }, []);

  useEffect(() => {
    if (intervalId !== null) {
      window.clearInterval(intervalId);
    }
    try {
      const id = window.setInterval(checkToken, delay);
      setIntervalId(id);
    } catch (exep) {
      console.log(exep);
    }
  }, [delay]);

  const handleRefreshComplete = () => {
    onHideMenuItem();
    onCloseDialog();
  };

  useEffect(() => {
    if (
      HeartbeatResponse.isValid === false ||
      HeartbeatResponse.ttl_seconds === 0
    ) {
      navigate('/login');
    } else {
      if (
        HeartbeatResponse.ttl_seconds < 300 &&
        delay !== short_time_between_checks_milliseconds
      ) {
        setDelay(short_time_between_checks_milliseconds);
      }

      if (
        HeartbeatResponse.isValid &&
        HeartbeatResponse.ttl_seconds < 300 &&
        HeartbeatResponse.ttl_seconds > 0 &&
        HeartbeatResponse.refreshCount < 5
      ) {
        onShowMenuItem();
      }

      if (
        HeartbeatResponse.isValid &&
        HeartbeatResponse.ttl_seconds > 0 &&
        HeartbeatResponse.ttl_seconds < 120 &&
        HeartbeatResponse.refreshCount < 5 &&
        showDialog === false
      ) {
        onShowDialog();
      }
    }
  }, [HeartbeatResponse]);

  return (
    <>
      {showDialog && (
        <ExtendTokenModal
          ttl_seconds={HeartbeatResponse.ttl_seconds}
          open={showDialog}
          onClose={() => onCloseDialog()}
          onRefreshed={handleRefreshComplete}
        />
      )}
    </>
  );
};

export default APIHeartBeat;
