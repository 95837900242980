import React, { useContext } from 'react';

import BackupSummaryContainer from '@totem/components/deviceBackups/backupSummary/backupSummaryContainer';
import DeviceSoftwareEndOfLifeSummaryLayout from '@totem/components/devices/softwareEndOfLife/summary/DeviceSoftwareEndOfLifeSummaryLayout';
import DeviceSoftwareEndOfSupportContainer from '@totem/components/devices/softwareEndOfLife/summary/DeviceSoftwareEndOfSupportContainer';
import EventTotalsContainer from '@totem/components/events/totals/EventTotalsContainer';
import ComplianceSection from '@totem/components/portfolio/compliance/ComplianceSection';
import ContinuitySection from '@totem/components/portfolio/continuity/ContinuitySection';
import RemoteAccessSection from '@totem/components/portfolio/remoteAccess/RemoteAccessSection';
import SummaryContext from '@totem/components/portfolio/summary/summaryContext';
import SummaryLayout from '@totem/components/portfolio/summary/SummaryLayout';
import ThreatManagementSection from '@totem/components/portfolio/threatManagement/ThreatManagementSection';
import RemoteAccessTotalsContainer from '@totem/components/remoteAccess/totals/RemoteAccessTotalsContainer';
import VulnerabilitySummaryLayout from '@totem/components/software/vulnerabilitySummary/SummaryLayout';
import VulnerabilitySummaryContainer from '@totem/components/software/vulnerabilitySummary/vulnerabilitySummaryContainer';
import ComplianceSummaryContainer from '@totem/components/surveyV2/complianceSummary/complianceSummaryContainer';
import UserProfileContext from '@totem/components/UserProfileContext';
import { hasActiveService } from '@totem/utilities/userUtilities';

import './portfolio.css';
const PortfolioLayout = () => {
  const { userProfile } = useContext(UserProfileContext);
  const { selectedRegionIds, selectedBuildingIds } = useContext(SummaryContext);

  let hasCompliance = hasActiveService(userProfile, 'cybersecurity_assessment');
  const hasContinuity = hasActiveService(userProfile, 'backup');
  const hasRemoteAccess = hasActiveService(userProfile, 'remote_access');
  const hasThreatManagement = hasActiveService(
    userProfile,
    'threat_management',
  );

  const noServices =
    !hasCompliance &&
    !hasContinuity &&
    !hasRemoteAccess &&
    !hasThreatManagement;

  hasCompliance = hasCompliance || noServices;

  return (
    <div styleName="main">
      <SummaryLayout />
      {hasCompliance && (
        <ComplianceSummaryContainer
          regionId={selectedRegionIds}
          buildingId={selectedBuildingIds}
        >
          <ComplianceSection />
        </ComplianceSummaryContainer>
      )}
      {hasCompliance && <ComplianceSection />}
      {hasRemoteAccess && (
        <RemoteAccessTotalsContainer
          regionId={selectedRegionIds}
          buildingId={selectedBuildingIds}
        >
          <RemoteAccessSection />
        </RemoteAccessTotalsContainer>
      )}
      {hasThreatManagement && (
        <EventTotalsContainer
          regionId={selectedRegionIds}
          buildingId={selectedBuildingIds}
        >
          <ThreatManagementSection />
        </EventTotalsContainer>
      )}
      <VulnerabilitySummaryContainer
        regionId={selectedRegionIds}
        buildingId={selectedBuildingIds}
      >
        <VulnerabilitySummaryLayout />
      </VulnerabilitySummaryContainer>
      <DeviceSoftwareEndOfSupportContainer
        regionId={selectedRegionIds}
        buildingId={selectedBuildingIds}
      >
        <DeviceSoftwareEndOfLifeSummaryLayout />
      </DeviceSoftwareEndOfSupportContainer>
      {hasContinuity && (
        <BackupSummaryContainer
          regionId={selectedRegionIds}
          buildingId={selectedBuildingIds}
        >
          <ContinuitySection />
        </BackupSummaryContainer>
      )}
    </div>
  );
};

export default PortfolioLayout;
