import React from 'react';

import { getTicketAbbr, getTicketColor } from '@totem/utilities/ticketing';

import './controlSystemReport.css';

type Props = {
  type: string;
};
const TicketLegendEntry = ({ type }: Props) => {
  return (
    <span title={type}>
      <span style={{ color: getTicketColor(type), fontSize: 'larger' }}>
        &#9632;
      </span>
      <span>{getTicketAbbr(type)}</span>
    </span>
  );
};
const TicketLegend = () => {
  return (
    <span styleName="legendText">
      <TicketLegendEntry type="Change Request" />
      {'  '}
      <TicketLegendEntry type={'Incident'} />
      {'  '}
      <TicketLegendEntry type="Problem" />
      {'  '}
      <TicketLegendEntry type="Service Request" />
      {'  '}
      <TicketLegendEntry type="Site Project" />
    </span>
  );
};

export default TicketLegend;
