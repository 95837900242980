import React, { useContext } from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { TablePaginationConfig } from 'antd/es/table';
import { ColumnProps } from 'antd/lib/table';
import { SorterResult } from 'antd/lib/table/interface';
import * as R from 'ramda';

import UserAvatar from '@totem/components/common/avatar/UserAvatar';
import Table from '@totem/components/common/table/Table';
import Tooltip from '@totem/components/common/tooltip/Tooltip';
import UserStatus from '@totem/components/common/userStatus/UserStatus';
import {
  sortNumberAscending,
  sortStringAscending,
} from '@totem/components/events/eventsContainer/utilities';
import { User } from '@totem/components/users/container/types';
import UserActionMenu from '@totem/components/users/container/UserActionMenu';
import UserContext from '@totem/components/users/container/usersContainerContext';
import {
  getRoleFilter,
  getRoleString,
  getSiteAccessString,
} from '@totem/components/users/container/utilities';
import colors from '@totem/styles/colors';
import { UserStatus as UserStatusEnum } from '@totem/types/user';
import {
  USER_ACCESS_TOOLTIP,
  USER_STATUS_TOOLTIP,
} from '@totem/utilities/tooltipUtilities';

import '../users.css';

const styles = {
  infoIcon: {
    marginLeft: '0.5rem',
    color: colors.neutral.gray,
    cursor: 'pointer',
  },
};

const UserContainerTable = () => {
  const {
    input,
    setInput,
    userData,
    loading,
    totalRecords,
    onAction,
  } = useContext(UserContext);

  const onDoAction = (action: string, user: User) => {
    if (typeof onAction === 'function') {
      onAction(action, user);
    }
  };

  const columns: ColumnProps<User>[] = [
    {
      title: '',
      dataIndex: 'imageUrl',
      key: 'imageUrl',
      width: 5,
      render: (text, record) => {
        return (
          <div className="center-table-cell">
            <UserAvatar size="large" user={record} />
          </div>
        );
      },
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      showSorterTooltip: true,
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) => sortStringAscending(compA.email, compB.email),
    },
    {
      title: 'First',
      dataIndex: 'firstName',
      key: 'firstName',
      showSorterTooltip: true,
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        sortStringAscending(compA.firstName, compB.firstName),
    },
    {
      title: 'Last',
      dataIndex: 'lastName',
      key: 'lastName',
      showSorterTooltip: true,
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        sortStringAscending(compA.lastName, compB.lastName),
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      render: (text: string, user: User) => {
        const roleString = getRoleString(user.organization.role);
        return <div>{roleString}</div>;
      },
      filters: getRoleFilter(),
    },
    {
      title: 'Active',
      dataIndex: 'active',
      key: 'active',
      render: (text: string, user: User) => {
        return user.organization.isActive ? 'Active' : 'Inactive';
      },
    },
    {
      title: () => (
        <div className="tooltip-table-header">
          <div>Site Access</div>
          <Tooltip title={USER_ACCESS_TOOLTIP}>
            <InfoCircleOutlined style={styles.infoIcon} />
          </Tooltip>
        </div>
      ),
      dataIndex: 'siteAccess',
      key: 'siteAccess',
      render: (text: string, user: User) => {
        return getSiteAccessString(user);
      },
    },
    {
      title: () => (
        <div className="tooltip-table-header">
          <div>Status</div>
          <Tooltip title={USER_STATUS_TOOLTIP}>
            <InfoCircleOutlined style={styles.infoIcon} />
          </Tooltip>
        </div>
      ),
      dataIndex: 'status',
      key: 'status',
      render: (status?: UserStatusEnum) =>
        !R.isNil(status) && <UserStatus status={status} />,
      showSorterTooltip: true,
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) => sortNumberAscending(compA.status, compB.status),
    },
    {
      title: 'Last Login',
      dataIndex: 'lastLoginDate',
      render: date => (date ? new Date(date).toLocaleDateString() : '-'),
      showSorterTooltip: true,
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        sortStringAscending(compA.lastLoginDate, compB.lastLoginDate),
    },
    {
      title: 'Actions',
      width: 30,
      dataIndex: 'additionalOptions',
      key: 'additionalOptions',
      render: (value, user: User) => (
        <div className="center-table-cell">
          <UserActionMenu user={user} onAction={onDoAction} />
        </div>
      ),
    },
  ];

  const getUsers = () => {
    if (userData !== null) {
      if (userData.users !== null) {
        return userData.users;
      }
    }
    return [];
  };

  const handleTableChange = (
    updatedPagination: TablePaginationConfig,
    filters: SorterResult<User>,
    sorter,
  ) => {
    const { ...params } = filters;

    let sortDir: string = sorter.order === 'descend' ? '-1' : '1';
    if (typeof sorter.order === 'undefined' || sorter.order === null) {
      if (
        typeof input.sortDirection !== 'undefined' &&
        input.sortDirection !== null
      ) {
        sortDir = input.sortDirection;
      }
    }

    let sortField = input.sortField ? input.sortField : 'lastOccurrence';
    if (
      typeof sorter.field !== 'undefined' &&
      typeof sorter.order !== 'undefined'
    ) {
      sortField = sorter.field;
    }

    // @ts-ignore
    setInput({
      ...input,
      ...params,
      pageSize: updatedPagination.pageSize,
      page: updatedPagination.current,
      sortField,
      sortDirection: sortDir,
    });
  };

  return (
    <Table
      showSorterTooltip
      columns={columns}
      dataSource={getUsers()}
      loading={loading}
      onChange={handleTableChange}
      pagination={{
        current: input.page,
        pageSize: input.pageSize,
        total: totalRecords,
        showSizeChanger: true,
      }}
      rowKey="id"
    />
  );
};

export default UserContainerTable;
