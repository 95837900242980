import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Modal } from 'antd';
import queryString from 'query-string';

import { EMPTY_ID } from '@totem/components/common/reference/ReferenceSelector';
import ContentLayout from '@totem/components/ContentLayout';
import BillingContainerTable from '@totem/components/contracts/details/BillingContainerTable';
import ContractContext from '@totem/components/contracts/details/ContractContext';
import ContractHeader from '@totem/components/contracts/details/ContractHeader';
import BillingContainerEditModal from '@totem/components/contracts/edits/BillingContainerEditModal';
import ContractEditModal from '@totem/components/contracts/edits/ContractEditModal';
import ContractLineEditModal from '@totem/components/contracts/edits/ContractLineEditModal';
import { Breadcrumb } from '@totem/types/breadcrumb';
import {
  Contract,
  ContractBillingContainer,
  ContractLine,
} from '@totem/types/contracts';
import { MIN_DATE_TIME_STRING } from '@totem/types/ticketing';
import { getToken } from '@totem/utilities/accountUtilities';
import { CONTRACTS_ENDPOINT } from '@totem/utilities/endpoints';
const confirm = Modal.confirm;

import { ArrayParam, useQueryParams, withDefault } from 'use-query-params';

import BasePane from '@totem/components/BasePane';
import CardBody from '@totem/components/CardBody';
import ContractFilters from '@totem/components/contracts/details/ContractFilters';
import { ContractInput } from '@totem/components/contracts/details/types';
import PrimaryContentPane from '@totem/components/PrimaryContentPane';
import UserProfileContext from '@totem/components/UserProfileContext';
import { TableFilterOption } from '@totem/types/common';
import { omitNilOrEmpty } from '@totem/utilities/objectUtilities';
import {
  isMemberOfAny,
  securityGroupCheckConstraintEmpty,
} from '@totem/utilities/userUtilities';

const ContractDetails = () => {
  const { id } = useParams();
  const [input, updateInput] = useQueryParams({
    buildingId: withDefault(ArrayParam, []),
    status: withDefault(ArrayParam, []),
    service: withDefault(ArrayParam, []),
  });
  const { userProfile } = useContext(UserProfileContext);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSending, setIsSending] = useState(false);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [uniqueBuildings, setUniqueBuildings] = useState<TableFilterOption[]>(
    [],
  );
  const [uniqueServices, setUniqueServices] = useState<TableFilterOption[]>([]);
  const [contractData, setContractData] = useState<Contract>(null);
  const [showEditContractHeaderModel, setShowEditContractHeaderModel] =
    useState<boolean>(false);
  const [showEditBillingContainerModel, setShowEditBillingContainerModel] =
    useState<boolean>(false);
  const [showEditContractLineModel, setShowEditContractLineModel] =
    useState<boolean>(false);
  const [editContractLine, setEditContractLine] = useState<ContractLine>(null);
  const [editBillingContainer, setEditBillingContainer] =
    useState<ContractBillingContainer>(null);
  const [
    editContractLineBillingContainer,
    setEditContractLineBillingContainer,
  ] = useState<string>(EMPTY_ID);

  const userCanEditContracts = isMemberOfAny(
    userProfile,
    ['contract_creator'],
    securityGroupCheckConstraintEmpty,
  );

  const setInput = (updated: Partial<ContractInput>) => {
    updateInput(omitNilOrEmpty({ ...input, ...updated }), 'replace');
  };

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    const editMode = parsed.edit;
    if (typeof editMode !== 'undefined' && editMode !== null) {
      if (editMode === 'true') {
        setIsEditMode(true);
      }
    }
  }, []);

  useEffect(() => {
    fetch(`${CONTRACTS_ENDPOINT}/${id}`, {
      method: 'GET',
      headers: new Headers({
        Authorization: `Bearer ${getToken()}`,
      }),
    })
      .then((res) => res.json())
      .then((result: Contract) => {
        setContractData(result);
      })
      .then(() => setIsLoading(false));
  }, [id]);

  const deleteBillingContainer = useCallback(
    async (contractId, billingContainerId) => {
      if (isSending) {
        return;
      }
      setIsSending(true);

      fetch(
        `${CONTRACTS_ENDPOINT}/${contractId}/billingContainer/${billingContainerId}`,
        {
          method: 'DELETE',
          headers: new Headers({
            Authorization: `Bearer ${getToken()}`,
          }),
        },
      )
        .then((res) => res.json())
        .then((result: Contract) => {
          setContractData(result);
        })
        .then(() => {
          setIsSending(false);
        });
    },
    [],
  );

  const deleteContractLine = useCallback(
    async (contractId, billingContainerId, contractLineId) => {
      if (isSending) {
        return;
      }
      setIsSending(true);

      fetch(
        `${CONTRACTS_ENDPOINT}/${contractId}/billingContainer/${billingContainerId}/lines/${contractLineId}`,
        {
          method: 'DELETE',
          headers: new Headers({
            Authorization: `Bearer ${getToken()}`,
          }),
        },
      )
        .then((res) => res.json())
        .then((result: Contract) => {
          setContractData(result);
        })
        .then(() => {
          setIsSending(false);
        });
    },
    [],
  );

  const buildUniqueValues = () => {
    const buildings: TableFilterOption[] = [];
    const services: TableFilterOption[] = [];

    if (
      typeof contractData !== 'undefined' &&
      contractData !== null &&
      contractData.billingContainers !== null
    ) {
      contractData.billingContainers.forEach((billingContainer) => {
        if (billingContainer.lines !== null) {
          billingContainer.lines.forEach((line) => {
            if (
              line.serviceAddress !== null &&
              line.serviceAddress.id !== EMPTY_ID
            ) {
              const foundBuilding = buildings.find(
                (elem) => elem.value === line.serviceAddress.id,
              );
              if (typeof foundBuilding === 'undefined') {
                buildings.push({
                  label: line.serviceAddress.name,
                  value: line.serviceAddress.id,
                });
              }
            }

            line.services.forEach((svc) => {
              const foundSerivce = services.find(
                (elem) => elem.value === svc.name,
              );
              if (typeof foundSerivce === 'undefined') {
                services.push({ label: svc.name, value: svc.name });
              }
            });
          });
        }
      });
    }

    setUniqueBuildings(buildings);
    setUniqueServices(services);
  };

  useEffect(() => {
    buildUniqueValues();
  }, [contractData]);

  const getBreadcrumbLabel = () => {
    if (contractData !== null) {
      if (contractData.number !== null) {
        return contractData.number;
      }
    }
    return '';
  };

  const getBreadcrumbs = (breadcrumbsIn: Breadcrumb) => {
    const breadcrumbs = [breadcrumbsIn];

    breadcrumbs.push({
      label: 'Contracts',
      link: '/dashboard/contracts',
    });

    breadcrumbs.push({
      label: `${getBreadcrumbLabel()}`,
    });

    return breadcrumbs;
  };

  const handleContractHeaderEdit = () => {
    setShowEditContractHeaderModel(true);
  };

  const handleBillingContainerCreate = () => {
    const newBillingContainer: ContractBillingContainer = {
      id: '',
      address: {
        id: '',
        addressLine1: '',
        addressLine2: '',
        addressLine3: '',
        city: '',
        region: '',
        postalCode: '',
        country: '',
      },
      contact: {
        id: '',
        name: '',
        phone: [],
        email: [],
      },
      lines: [],
      billingFrequency: 'Annual',
      terms: 'Net30',
      keys: [],
      attributes: [],
    };
    setEditBillingContainer(newBillingContainer);
    setShowEditBillingContainerModel(true);
  };

  const handleBillingContainerEdit = (
    billingContainer: ContractBillingContainer,
  ) => {
    setEditBillingContainer(billingContainer);
    setShowEditBillingContainerModel(true);
  };

  const handleBillingContainerDelete = (
    billingContainer: ContractBillingContainer,
  ) => {
    confirm({
      title: 'Are you sure?',
      content:
        'Please confirm that you would like to delete this Billing Container and ALL associated Contract Lines.',
      okText: 'Delete',
      okType: 'danger',
      cancelText: 'Cancel',
      visible: false,
      onOk: () => deleteBillingContainer(contractData.id, billingContainer.id),
    });
  };

  const handleContractLineCreate = (billingContainerId: string) => {
    setEditContractLineBillingContainer(billingContainerId);
    const newContractLine: ContractLine = {
      id: '',
      number: 0,
      services: [],
      serviceLevelAgreements: [],
      contractLineStart: MIN_DATE_TIME_STRING,
      contractLineEnd: MIN_DATE_TIME_STRING,
      renewable: false,
      serviceAddress: null,
      quantity: 1,
      status: 'Active',
      devices: [],
      keys: [],
      billingAmount: 0,
      attributes: [],
    };
    setEditContractLine(newContractLine);
    setShowEditContractLineModel(true);
  };

  const handleContractChanged = (contract: Contract) => {
    setContractData(contract);
  };

  const handleContractLineEdit = (
    billingContainerId: string,
    contractLine: ContractLine,
  ) => {
    setEditContractLineBillingContainer(billingContainerId);
    setEditContractLine(contractLine);
    setShowEditContractLineModel(true);
  };

  const handleContractLineDelete = (
    billingContainerId: string,
    contractLine: ContractLine,
  ) => {
    confirm({
      title: 'Are you sure?',
      content:
        'Please confirm that you would like to delete this Contract Line.',
      okText: 'Delete',
      okType: 'danger',
      cancelText: 'Cancel',
      visible: false,
      onOk: () =>
        deleteContractLine(
          contractData.id,
          billingContainerId,
          contractLine.id,
        ),
    });
  };

  return (
    <ContentLayout
      breadcrumbs={(breadcrumbs) => getBreadcrumbs(breadcrumbs[0])}
    >
      <ContractContext.Provider
        value={{
          input,
          setInput,
          buildings: uniqueBuildings,
          services: uniqueServices,
        }}
      >
        <BasePane>
          <PrimaryContentPane>
            {contractData !== null && (
              <ContractFilters
                userCanEdit={userCanEditContracts}
                title={contractData.number}
                isEditMode={isEditMode}
                setIsEditMode={setIsEditMode}
              />
            )}
            <CardBody>
              {contractData !== null && (
                <>
                  <ContractHeader
                    contract={contractData}
                    loading={isLoading}
                    isEditMode={isEditMode}
                    onEdit={handleContractHeaderEdit}
                  />
                  <br />
                  {typeof contractData.billingContainers !== 'undefined' &&
                    contractData.billingContainers !== null && (
                      <BillingContainerTable
                        billingContainers={contractData.billingContainers}
                        loading={isLoading}
                        isEditMode={isEditMode}
                        onCreate={handleBillingContainerCreate}
                        onEdit={handleBillingContainerEdit}
                        onDelete={handleBillingContainerDelete}
                        onContractLineCreate={handleContractLineCreate}
                        onContractLineEdit={handleContractLineEdit}
                        onContractLineDelete={handleContractLineDelete}
                      />
                    )}
                </>
              )}
            </CardBody>
          </PrimaryContentPane>
        </BasePane>
        {showEditContractHeaderModel && (
          <ContractEditModal
            contract={contractData}
            onContractChanged={(updatedContractData: Contract) => {
              setContractData(updatedContractData);
              setShowEditContractHeaderModel(false);
            }}
            onClose={() => setShowEditContractHeaderModel(false)}
            visible={showEditContractHeaderModel}
          />
        )}
        {showEditContractLineModel && (
          <ContractLineEditModal
            visible={showEditContractLineModel}
            contractLine={editContractLine}
            billingContainerId={editContractLineBillingContainer}
            contractId={contractData.id}
            onClose={() => setShowEditContractLineModel(false)}
            onContractChanged={handleContractChanged}
          />
        )}
        {showEditBillingContainerModel && (
          <BillingContainerEditModal
            visible={showEditBillingContainerModel}
            contractId={contractData.id}
            onContractChanged={handleContractChanged}
            onClose={() => setShowEditBillingContainerModel(false)}
            billingContainer={editBillingContainer}
          />
        )}
      </ContractContext.Provider>
    </ContentLayout>
  );
};

export default ContractDetails;
