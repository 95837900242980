import React, { ReactNode, useEffect, useState } from 'react';

import FindingsContext from '@totem/components/surveyV2/findings/FindingsContext';
import {
  Finding,
  FindingsInput,
} from '@totem/components/surveyV2/findings/types';
import { getToken } from '@totem/utilities/accountUtilities';
import { isNotNull } from '@totem/utilities/common';
import { V2_SURVEY_ENDPOINT } from '@totem/utilities/endpoints';
import { omitNilOrEmpty } from '@totem/utilities/objectUtilities';

type Props = {
  questionId: string;
  children?: ReactNode;
};

const FindingsContainer = ({ children, questionId }: Props) => {
  const [refreshData, setRefreshData] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [data, setData] = useState<Finding[]>(null);
  const [filteredData, setFilteredData] = useState<Finding[]>([]);
  const [input, updateInput] = useState<FindingsInput>({
    pageSize: 100,
    page: 1,
    sortField: 'policyName',
    sortDirection: '1',
    criticality: [],
    building: [],
    region: [],
    assignee: [],
  });

  const setInput = (updated: Partial<FindingsInput>) => {
    updateInput(omitNilOrEmpty({ ...input, ...updated }));
  };

  useEffect(() => {
    if (isNotNull(data)) {
      let tmpData = [...data];

      if (isNotNull(input.criticality) && input.criticality.length > 0) {
        tmpData = tmpData.filter((chk) =>
          input.criticality.includes(chk.criticality),
        );
      }
      if (isNotNull(input.building) && input.building.length > 0) {
        tmpData = tmpData.filter((chk) =>
          input.building.includes(chk.building.id),
        );
      }
      if (isNotNull(input.region) && input.region.length > 0) {
        tmpData = tmpData.filter((chk) => input.region.includes(chk.region.id));
      }
      if (isNotNull(input.assignee) && input.assignee.length > 0) {
        tmpData = tmpData.filter((chk) =>
          input.assignee.includes(chk.assignedUser.email),
        );
      }

      setFilteredData(tmpData);
    }
  }, [data, input]);

  useEffect(() => {
    if (refreshData) {
      setRefreshData(false);

      setIsLoading(true);

      fetch(`${V2_SURVEY_ENDPOINT}/findings/${questionId}`, {
        method: 'GET',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
      })
        .then((res) => res.json())
        .then((result: Finding[]) => {
          setData(result);
        })
        .then(() => {
          setIsLoading(false);
        });
    }
  }, [questionId, refreshData]);

  return (
    <FindingsContext.Provider
      value={{
        input,
        setInput,
        filteredData,
        data,
        loading: isLoading,
        setIsLoading,
      }}
    >
      <div>{children}</div>
    </FindingsContext.Provider>
  );
};

export default FindingsContainer;
