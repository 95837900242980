import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import {
  CheckCircleOutlined,
  EditOutlined,
  EllipsisOutlined,
  FileTextOutlined,
  SolutionOutlined,
} from '@ant-design/icons';
import { Badge, Checkbox } from 'antd';
import { TablePaginationConfig } from 'antd/es/table';
import { ColumnProps } from 'antd/lib/table';
import { SorterResult } from 'antd/lib/table/interface';

import CriticalFindingsTooltip from '@totem/components/common/findings/CriticalFindingsTooltip';
import OrganizationContext from '@totem/components/common/organizationContext/organizationContext';
import Table from '@totem/components/common/table/Table';
import Tooltip from '@totem/components/common/tooltip/Tooltip';
import SurveyInstanceContext from '@totem/components/surveyV2/instances/SurveyInstancesContext';
import TemplatesContext from '@totem/components/surveyV2/templates/TemplatesContext';
import { SurveyQueryResult } from '@totem/components/surveyV2/types';
import UserProfileContext from '@totem/components/UserProfileContext';
import colors from '@totem/styles/colors';
import {
  getBadgeStatus,
  getHealthStatusString,
} from '@totem/utilities/healthUtilities';
import { isIBUser } from '@totem/utilities/security';
import { stringifyArray } from '@totem/utilities/tableFilterUtilities';
import {
  sortBooleanAscending,
  sortNumberAscending,
  sortStringAscending,
} from '@totem/utilities/tableUtilities';
import { isUserDeactivated } from '@totem/utilities/userUtilities';

import '../survey.css';

type TableFilter = {
  text: string;
  value: string | boolean;
};

export const START_DATE_FILTERS: TableFilter[] = [
  { text: 'Has Start Date', value: true },
  { text: 'No Start Date', value: false },
];

export const DUE_DATE_FILTERS: TableFilter[] = [
  { text: 'Has Due Date', value: true },
  { text: 'No Due Date', value: false },
];

export const SUBMITTED_FILTERS: TableFilter[] = [
  { text: 'Submitted', value: true },
  { text: 'In Progress', value: false },
];

const IS_LAUNCHED_FILTERS = [
  {
    text: 'Launched',
    value: true,
  },
  {
    text: 'Not Launched',
    value: false,
  },
];

const styles = {
  pastDueDate: {
    background: 'rgba(237, 54, 32, 0.25)',
  },
  kr: {
    backgroundColor: colors.neutral.gray,
  },
  krDanger: {
    backgroundColor: colors.brand.red,
  },
  icon: {
    cursor: 'pointer',
    color: 'rgba(0,0,0,0.45)',
    fontSize: 16,
    marginLeft: '1rem',
  },
  avatar: {
    margin: 2,
  },
  submittedIcon: {
    color: colors.brand.green,
    marginRight: '0.5rem',
    fontSize: '20px',
  },
};

const SurveyInstanceTable = () => {
  const {
    data,
    loading,
    input,
    setInput,
    totalRecords,
    onAction,
    selectedForLaunch,
  } = useContext(SurveyInstanceContext);

  const { regions, buildings } = useContext(OrganizationContext);
  const { data: templates } = useContext(TemplatesContext);
  const { userProfile } = useContext(UserProfileContext);
  const showLaunchSelection =
    isIBUser(userProfile) || userProfile.organization.role === 3;

  const getRegionFilterOptions = () => {
    if (typeof regions !== 'undefined' && regions !== null) {
      return regions.map((region) => {
        return {
          text: region.name,
          value: region.id,
        };
      });
    }
    return [];
  };

  const getBuildingFilterOptions = () => {
    if (typeof buildings !== 'undefined' && buildings !== null) {
      return buildings.map((building) => {
        return {
          text: building.name,
          value: building.id,
        };
      });
    }
    return [];
  };

  const getTemplateFilterOptions = () => {
    if (typeof templates !== 'undefined' && templates !== null) {
      return templates.map((item) => {
        return {
          text: item.name,
          value: item.id,
        };
      });
    }
    return [];
  };

  const parseDate = (date: number): string => {
    return new Date(date).toLocaleDateString();
  };

  const isPastToday = (date: number): boolean => {
    if (date) {
      return new Date().getTime() > date;
    }

    return false;
  };

  const getInstances = () => {
    if (typeof data !== 'undefined' && data !== null) {
      return data;
    }
    return [];
  };

  const isSelectedForLaunch = (id: string): boolean => {
    if (
      typeof selectedForLaunch === 'undefined' &&
      selectedForLaunch === null
    ) {
      return false;
    }
    return selectedForLaunch.includes(id);
  };

  const getPercentage = (max: number, value: number): string => {
    return max ? `${Math.round((value / max) * 100)}%` : '0%';
  };

  const columns: ColumnProps<SurveyQueryResult>[] = [
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'survey.type',
      showSorterTooltip: true,
      render: (_, instance: SurveyQueryResult) => (
        <span>
          {instance.survey.type === 'policyAudit' && (
            <SolutionOutlined styleName={'table-icon'} title={'Policy Audit'} />
          )}
          {instance.survey.type !== 'policyAudit' && (
            <FileTextOutlined styleName={'table-icon'} title={'Survey'} />
          )}
        </span>
      ),
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        sortStringAscending(compA.survey.type, compB.survey.type),
    },
    {
      title: 'Health',
      dataIndex: 'health',
      key: 'health',
      // filters: HEALTH_FILTERS,
      // filteredValue: stringifyArray(input.health),
      // filterMultiple: true,
      render: (_, instance: SurveyQueryResult) => {
        return (
          <div
            styleName="health-cell"
            onClick={() => onAction('show_health_update_dialog', instance)}
          >
            <div styleName="table-health-status-container">
              <Badge
                status={getBadgeStatus(instance.health)}
                className="health-status-badge-sm"
              />
              <div styleName="table-health-status">
                {getHealthStatusString(instance.health)}
              </div>
            </div>
            <EllipsisOutlined style={styles.icon} />
          </div>
        );
      },
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) => {
        const aVal =
          typeof compA.health !== 'undefined' && compA.health !== null
            ? compA.health.status
            : 0;
        const bVal =
          typeof compB.health !== 'undefined' && compB.health !== null
            ? compB.health.status
            : 0;
        return sortNumberAscending(aVal, bVal);
      },
    },
    {
      title: 'Launched',
      key: 'isLaunched',
      filters: IS_LAUNCHED_FILTERS,
      filteredValue: stringifyArray(input.isLaunched),
      filterMultiple: false,
      render: (_, instance: SurveyQueryResult) => {
        return (
          <div
            styleName={instance.survey.isLaunched ? 'submitted' : ''}
            className="center-table-cell"
          >
            {instance.survey.isLaunched && (
              <Tooltip title="Launched">
                <CheckCircleOutlined style={styles.submittedIcon} />
              </Tooltip>
            )}
            {showLaunchSelection &&
              !instance.survey.isLaunched &&
              typeof instance.assignedUser !== 'undefined' &&
              instance.assignedUser !== null &&
              instance.assignedUser.email !== '' && (
                <Checkbox
                  checked={isSelectedForLaunch(instance.survey.id)}
                  onChange={() =>
                    onAction('toggle_select_for_launch', instance)
                  }
                />
              )}
          </div>
        );
      },
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        sortBooleanAscending(compA.survey.isLaunched, compB.survey.isLaunched),
    },
    {
      title: 'Progress',
      dataIndex: 'submitted',
      key: 'submitted',
      filters: SUBMITTED_FILTERS,
      filteredValue: stringifyArray(input.submitted),
      filterMultiple: false,
      render: (_, instance: SurveyQueryResult): React.ReactNode => {
        const { totalQuestions, completedQuestions, isSubmitted } =
          instance.survey;

        return (
          <div styleName={isSubmitted ? 'submitted' : ''}>
            {isSubmitted && (
              <Tooltip title="Submitted">
                <CheckCircleOutlined style={styles.submittedIcon} />
              </Tooltip>
            )}
            {getPercentage(totalQuestions, completedQuestions)}
          </div>
        );
      },
    },
    {
      title: 'Score',
      key: 'survey.totalScore',
      dataIndex: '',
      render: (_, instance: SurveyQueryResult): React.ReactNode =>
        getPercentage(instance.survey.maxScore, instance.survey.totalScore),
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        sortNumberAscending(compA.survey.totalScore, compB.survey.totalScore),
    },
    {
      title: (): React.ReactNode => (
        <div className="tooltip-table-header">
          <div>CFs</div>
          <div styleName="critical-findings-icon">
            <CriticalFindingsTooltip />
          </div>
        </div>
      ),
      key: 'survey.criticalFindings',
      dataIndex: '',
      render: (_, instance: SurveyQueryResult): React.ReactNode => {
        return (
          <Badge
            count={instance.survey.criticalFindings}
            style={
              instance.survey.criticalFindings ? styles.krDanger : styles.kr
            }
            showZero
          />
        );
      },
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        sortNumberAscending(
          compA.survey.criticalFindings,
          compB.survey.criticalFindings,
        ),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'survey.name',
      showSorterTooltip: true,
      render: (_, instance: SurveyQueryResult) => (
        <Link to={`/dashboard/surveyinstances/${instance.survey.id}`}>
          {instance.survey.name}
        </Link>
      ),
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        sortStringAscending(compA.survey.name, compB.survey.name),
    },
    {
      title: 'Assignee',
      dataIndex: 'assignee',
      key: 'assignedUser.email',
      render: (_, instance: SurveyQueryResult) => {
        const isDeactivated = isUserDeactivated(instance.assignedUser);

        return (
          <div
            styleName="assignees-table-cell"
            style={isDeactivated ? { color: colors.neutral.inactive } : {}}
            onClick={() => onAction('show_assignment_dialog', instance)}
          >
            <div styleName="assignees">
              {isDeactivated
                ? `${
                    typeof instance.assignedUser !== 'undefined' &&
                    instance.assignedUser !== null
                      ? instance.assignedUser.email
                      : ''
                  } (Inactive)`
                : typeof instance.assignedUser !== 'undefined' &&
                  instance.assignedUser !== null
                ? instance.assignedUser.email
                : ''}
            </div>
            <EditOutlined style={styles.icon} />
          </div>
        );
      },
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        sortStringAscending(
          typeof compA.assignedUser !== 'undefined' &&
            compA.assignedUser !== null
            ? compA.assignedUser.email
            : '',
          typeof compB.assignedUser !== 'undefined' &&
            compB.assignedUser !== null
            ? compB.assignedUser.email
            : '',
        ),
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      key: 'startDate',
      filters: START_DATE_FILTERS,
      filteredValue: stringifyArray(input.startDate),
      filterMultiple: false,
      render: (_, instance: SurveyQueryResult) => {
        return (
          <div
            styleName="date-table-cell"
            onClick={() => onAction('show_start_date_dialog', instance)}
          >
            <div>
              {instance.survey.startDate
                ? parseDate(instance.survey.startDate)
                : ''}
            </div>
            <EditOutlined style={styles.icon} />
          </div>
        );
      },
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        sortNumberAscending(compA.survey.startDate, compB.survey.startDate),
    },
    {
      title: 'Due Date',
      dataIndex: 'dueDate',
      filters: DUE_DATE_FILTERS,
      filteredValue: stringifyArray(input.dueDate),
      filterMultiple: false,
      render: (_, instance: SurveyQueryResult): Object => {
        return {
          props: {
            style:
              isPastToday(instance.survey.dueDate) &&
              !instance.survey.isSubmitted
                ? styles.pastDueDate
                : {},
          },
          children: (
            <div
              styleName="date-table-cell"
              onClick={() => onAction('show_due_date_dialog', instance)}
            >
              <div>
                {instance.survey.dueDate
                  ? parseDate(instance.survey.dueDate)
                  : ''}
              </div>
              <EditOutlined style={styles.icon} />
            </div>
          ),
        };
      },
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        sortNumberAscending(compA.survey.dueDate, compB.survey.dueDate),
    },
    {
      title: `Region`,
      dataIndex: 'region',
      key: 'region',
      filters: getRegionFilterOptions(),
      filteredValue: input.region,
      render: (_, instance: SurveyQueryResult) => instance.region?.name,
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        sortStringAscending(
          typeof compA !== 'undefined' && compA !== null
            ? compA.region.name
            : '',
          typeof compB !== 'undefined' && compB !== null
            ? compB.region.name
            : '',
        ),
    },
    {
      title: 'Building',
      dataIndex: 'building',
      key: 'building',
      filters: getBuildingFilterOptions(),
      filteredValue: input.building,
      render: (_, instance: SurveyQueryResult) => instance.building?.name,
      sorter: (compA, compB) =>
        sortStringAscending(
          typeof compA !== 'undefined' && compA !== null
            ? compA.building.name
            : '',
          typeof compB !== 'undefined' && compB !== null
            ? compB.building.name
            : '',
        ),
    },
    {
      title: 'Template',
      dataIndex: 'template',
      key: 'template',
      filters: getTemplateFilterOptions(),
      filteredValue: input.template,
      render: (_, instance: SurveyQueryResult) => instance.template?.name,
      sorter: (compA, compB) =>
        sortStringAscending(
          typeof compA !== 'undefined' && compA !== null
            ? compA.template.name
            : '',
          typeof compB !== 'undefined' && compB !== null
            ? compB.template.name
            : '',
        ),
    },
  ];

  const handleTableChange = (
    updatedPagination: TablePaginationConfig,
    filters: SorterResult<SurveyQueryResult>,
    sorter,
  ) => {
    const { ...params } = filters;

    let sortDir: string = sorter.order === 'descend' ? '-1' : '1';
    if (typeof sorter.order === 'undefined' || sorter.order === null) {
      if (
        typeof input.sortDirection !== 'undefined' &&
        input.sortDirection !== null
      ) {
        sortDir = input.sortDirection;
      }
    }

    let sortField = input.sortField ? input.sortField : 'lastOccurrence';
    if (
      typeof sorter.field !== 'undefined' &&
      typeof sorter.order !== 'undefined'
    ) {
      sortField = sorter.field;
    }

    // @ts-ignore
    setInput({
      ...input,
      ...params,
      pageSize: updatedPagination.pageSize,
      page: updatedPagination.current,
      sortField,
      sortDirection: sortDir,
    });
  };

  return (
    <Table
      showSorterTooltip
      columns={columns}
      dataSource={getInstances()}
      loading={loading}
      onChange={handleTableChange}
      pagination={{
        current: input.page,
        pageSize: input.pageSize,
        total: totalRecords,
        showSizeChanger: true,
      }}
      rowKey={(record) => record.survey.id}
    />
  );
};

export default SurveyInstanceTable;
