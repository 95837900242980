import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Modal } from 'antd';
import * as R from 'ramda';

import * as policyAuditActionCreators from '@totem/actions/policyAuditActions';
import { policyAuditSelector } from '@totem/selectors/policyAuditSelectors';
import { PolicyAuditErrors } from '@totem/types/policyAudit';
import { ReduxStore } from '@totem/types/store';
import authUtilities from '@totem/utilities/authUtilities';
import { validatePolicyAudit } from '@totem/utilities/policyAuditUtilities';

interface PassedProps {
  onError: (errors: PolicyAuditErrors) => void;
  hideReopen?: boolean;
}

type Props = PassedProps;

interface State {
  loading: boolean;
  visible: boolean;
  submitted: boolean;
  patched: boolean;
}

const initialState: State = {
  loading: false,
  visible: false,
  submitted: false,
  patched: false,
};

const SubmitButton = ({ onError, hideReopen }: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [state, setState] = useState<State>(initialState);
  const policyAudit = useSelector((stateVal: ReduxStore) =>
    policyAuditSelector(stateVal),
  );
  const loading = useSelector(
    (stateVal: ReduxStore) => stateVal.loading.loadingPolicyAuditDetailPatch,
  );

  useEffect(() => {
    if (loading && !state.loading) {
      setState((prevState) => ({ ...prevState, loading: true }));
      return;
    }

    if (!loading && state.loading) {
      if (!state.patched) {
        setState((prevState) => ({ ...prevState, loading: false }));
        return;
      }

      const basePath = authUtilities.isVendorUser()
        ? 'vendor-dashboard'
        : 'dashboard';

      if (state.submitted) {
        navigate(`/${basePath}/policyaudits/${policyAudit.id}/report`);
      }

      setState({ ...initialState });
    }
  }, [loading, state.loading, state.submitted]);

  useEffect(() => {
    if (state.patched) {
      dispatch(
        policyAuditActionCreators.requestPolicyAuditPatch({
          id: policyAudit.id,
          submitted: state.submitted,
        }),
      );
    }
  }, [state.patched, state.submitted]);

  const toggleModal = () => {
    setState((prevState) => ({ ...prevState, visible: !state.visible }));
  };

  const handleSubmitClick = () => {
    const { submitted } = policyAudit;

    if (submitted) {
      setState((prevState) => ({ ...prevState, visible: true }));
      return;
    }

    const errors = validatePolicyAudit(policyAudit);

    if (R.isEmpty(errors)) {
      setState((prevState) => ({ ...prevState, visible: true }));
    } else {
      onError(errors);
    }
  };

  const handleSubmitToggle = () => {
    const { submitted } = policyAudit;

    setState((prevState) => ({
      ...prevState,
      patched: true,
      submitted: !submitted,
    }));
  };

  const showButton = hideReopen ? !policyAudit.submitted : true;

  return (
    <>
      {showButton && (
        <Button type="primary" onClick={handleSubmitClick}>
          {policyAudit.submitted ? 'Reopen Audit' : 'Submit Audit'}
        </Button>
      )}
      <Modal
        open={state.visible}
        confirmLoading={state.loading}
        onOk={handleSubmitToggle}
        onCancel={toggleModal}
        title="Confirm"
        okText="Submit"
      >
        {policyAudit.submitted
          ? 'Are you sure you want to reopen this audit?'
          : 'Are you sure you want to submit this audit for scoring?'}
      </Modal>
    </>
  );
};

export default SubmitButton;
