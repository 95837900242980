import React, { useContext } from 'react';

import OrganizationContext from '@totem/components/common/organizationContext/organizationContext';
import DeviceBackupContext from '@totem/components/deviceBackups/deviceBackupContext';
import FiltersChipsContainer from '@totem/components/FilterChipsContainer';
import FiltersBody from '@totem/components/FiltersBody';
import FiltersContainer from '@totem/components/FiltersContainer';
import FiltersTitle from '@totem/components/FiltersTitle';
import { ControlSystemType } from '@totem/types/controlSystem';
import { DeviceBackupConnectionInput } from '@totem/types/devices';
import { controlSystemTypeMap } from '@totem/utilities/controlSystemsUtilities';
import { removeIntegerKeys } from '@totem/utilities/enumUtilities';

import DropdownFilter from '../common/dropdownFilter/DropdownFilter';

import DeviceBackupFilterChips from './DeviceBackupFilterChips';

import './deviceBackups.css';

const CONTROL_SYSTEM_TYPE_FILTERS = Object.values(
  removeIntegerKeys(ControlSystemType),
).map((type) => ({
  label: controlSystemTypeMap[type]?.text,
  value: type,
}));

const styles = {
  dropdown: {
    marginRight: '2rem',
  },
};

const DeviceBackupFilters = () => {
  const { input, setInput } = useContext(DeviceBackupContext);
  const { regions, buildings } = useContext(OrganizationContext);

  const handleMultiStringFilterChange = (
    property: keyof DeviceBackupConnectionInput,
    update: string[],
  ) => {
    setInput({
      ...input,
      [property]: update.map(String),
    });
  };

  const handleMultiNumberFilterChange = (
    property: keyof DeviceBackupConnectionInput,
    update: string[],
  ) => {
    setInput({
      ...input,
      [property]: update.map(Number),
    });
  };

  return (
    <FiltersContainer>
      <FiltersTitle>Backup Report</FiltersTitle>
      <FiltersBody>
        <DropdownFilter
          label="Control System Type"
          options={CONTROL_SYSTEM_TYPE_FILTERS}
          value={input.systemType || []}
          onChange={(update) =>
            handleMultiNumberFilterChange('systemType', update)
          }
          style={styles.dropdown}
        />
        <DropdownFilter
          // @ts-ignore
          options={buildings.map((building) => ({
            label: building.name,
            value: building.id,
          }))}
          value={input.buildingId || []}
          onChange={(update) =>
            handleMultiStringFilterChange('buildingId', update)
          }
          style={styles.dropdown}
          label="Building"
        />
        <DropdownFilter
          style={styles.dropdown}
          label="Region"
          // @ts-ignore
          options={regions.map((region) => ({
            label: region.name,
            value: region.id,
          }))}
          value={input.regionId || []}
          onChange={(update) =>
            handleMultiStringFilterChange('regionId', update)
          }
        />
      </FiltersBody>
      <FiltersChipsContainer>
        <DeviceBackupFilterChips />
      </FiltersChipsContainer>
    </FiltersContainer>
  );
};

export default DeviceBackupFilters;
