import { createForms } from 'react-redux-form';
import { routerReducer } from 'react-router-redux';
import { combineReducers } from 'redux';

import actionTypes from '@totem/actions/actionTypes';

import buildings from './buildingsReducer';
import buildingsTotal from './buildingsTotalReducer';
import health from './healthReducer';
import initialState from './initialState';
import loading from './loadingReducer';
import media from './mediaReducer';
import notification from './notificationReducer';
import policyAuditDetail from './policyAuditDetailReducer';
import regions from './regionsReducer';
import regionsTotal from './regionsTotalReducer';
import remoteAccessOrgRoles from './remoteAccessOrgRolesReducer';
import remoteAccessRoleUsers from './remoteAccessRoleUsersReducer';
import remoteAccessUserRoles from './remoteAccessUserRolesReducer';
import remoteAccessUsers from './remoteAccessUsersReducer';
import versions from './versionsReducer';

const appReducer = combineReducers({
  routing: routerReducer,
  media,
  loading,
  notification,
  regions,
  buildings,
  regionsTotal,
  buildingsTotal,
  versions,
  health,
  policyAuditDetail,
  remoteAccessUsers,
  remoteAccessOrgRoles,
  remoteAccessRoleUsers,
  remoteAccessUserRoles,
  ...createForms({
    surveyTemplateDetail: initialState.surveyTemplateDetail,
  }),
});

const rootReducer = (state, action) => {
  // reset state on organization switch or logout
  if (action.type === actionTypes.RESET_STORE) {
    // @ts-ignore
    return appReducer(initialState, action);
  }

  // @ts-ignore
  return appReducer(state, action);
};

export default rootReducer;
