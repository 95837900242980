import React from 'react';
import { DeleteOutlined, EditOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Collapse } from 'antd';
import dayjs from 'dayjs';

import Tooltip from '@totem/components/common/tooltip/Tooltip';
import colors from '@totem/styles/colors';
import { Note as NoteType } from '@totem/types/note';

import './notes.css';

const { Panel } = Collapse;

export interface Props {
  note: NoteType;
  onEditClick: (note: NoteType) => void;
  onDeleteClick: (note: NoteType) => void;
}

const styles = {
  collapse: {
    paddingBottom: '1rem',
    borderBottom: `1px solid ${colors.antd.borderGray}`,
  },
  icon: {
    top: '2.4rem',
    color: colors.neutral.gray,
  },
  iconButton: {
    marginRight: '0.5rem',
  },
  panel: {
    paddingRight: 0,
  },
};

const DATE_FORMAT = 'MM/DD/YYYY';

const Note = ({ note, onEditClick, onDeleteClick }: Props) => {
  const { title, body, updatedBy, updatedAt } = note;

  const handleEdit = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    onEditClick(note);
  };

  const handleDelete = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    onDeleteClick(note);
  };

  const buttons = (
    <div styleName="controls">
      <Tooltip title="Edit" placement="top" anchored>
        <Button
          shape="circle"
          size="small"
          icon={<EditOutlined />}
          type="primary"
          style={styles.iconButton}
          onClick={handleEdit}
        />
      </Tooltip>
      <Tooltip title="Delete" placement="top" anchored>
        <Button
          shape="circle"
          size="small"
          icon={<DeleteOutlined />}
          onClick={handleDelete}
        />
      </Tooltip>
    </div>
  );

  const header = (
    <div styleName="title">
      <div>{title}</div>
      <div styleName="subtitle">Edited By: {updatedBy.email}</div>
      <div styleName="subtitle">
        Last Edited: {dayjs(updatedAt).format(DATE_FORMAT)}
      </div>
    </div>
  );

  return (
    <Collapse
      ghost
      bordered={false}
      expandIcon={({ isActive }) => (
        <RightOutlined rotate={isActive ? 90 : 0} style={styles.icon} />
      )}
      style={styles.collapse}
    >
      <Panel key="1" header={header} extra={buttons}>
        <div styleName="body">{body}</div>
      </Panel>
    </Collapse>
  );
};

export default Note;
