import React from 'react';
import { Button, Modal } from 'antd';

import EventDetailView from '@totem/components/events/detailView/EventDetailView';
import ModalFooter from '@totem/components/ModalFooter';
import ModalTitle from '@totem/components/ModalTitle';
import { Event } from '@totem/types/events';

const styles = {
  dialog: {
    maxHeight: '80%',
    maxWidth: '850px',
  },
};

export interface Props {
  event: Event;
  visible: boolean;
  onClose: () => void;
}

const EventDetailsModal = ({ event, visible, onClose }: Props) => {
  return (
    <Modal
      open={visible}
      onCancel={onClose}
      title={<ModalTitle>Event Details</ModalTitle>}
      confirmLoading={false}
      width={'75%'}
      style={styles.dialog}
      footer={
        <ModalFooter>
          <Button onClick={onClose}>Close</Button>
        </ModalFooter>
      }
    >
      <EventDetailView event={event} />
    </Modal>
  );
};

export default EventDetailsModal;
