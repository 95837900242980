import React, { useCallback, useState } from 'react';
import { Tooltip as AntdToolip } from 'antd';
import { TooltipProps } from 'antd/es/tooltip';

type Props = TooltipProps & {
  /**
   * Denotes if the tooltip should be "anchored" to its immediate parent
   * in order to prevent sticking to the page on scroll. This should be
   * used in all contexts except when a Tooltip is rendered inside a
   * Table. Table has its own ConfigProvider to handle popup containers.
   */
  anchored?: boolean;
};

const styles = {
  anchor: {
    position: 'relative',
  },
};

const Tooltip = (props: Props) => {
  const [anchor, setAnchor] = useState<HTMLSpanElement>(null);

  const ref = useCallback((node: HTMLSpanElement) => {
    setAnchor(node);
  }, []);

  const getAnchor = useCallback(() => {
    return anchor || document.body;
  }, [anchor]);

  const getPopupContainer = props.getPopupContainer
    ? props.getPopupContainer
    : props.anchored
    ? getAnchor
    : null;

  /**
   * conditionally rendered as passing getPopupContainer as null or
   * undefined does not seem to have the same effect
   */
  return (
    <div ref={ref} style={styles.anchor as any}>
      {getPopupContainer ? (
        <AntdToolip {...props} getPopupContainer={getPopupContainer} />
      ) : (
        <AntdToolip {...props} />
      )}
    </div>
  );
};

export default Tooltip;
