import React, { ReactNode, useEffect, useState } from 'react';

import OrganizationContext from '@totem/components/common/organizationContext/organizationContext';
import { OrganizationHierarchy } from '@totem/types/organization';
import { UserRestrictions } from '@totem/types/user';
import { getToken } from '@totem/utilities/accountUtilities';
import { ORGANIZATION_HIERARCHY_ENDPOINT } from '@totem/utilities/endpoints';
import { sortStringAscending } from '@totem/utilities/tableUtilities';

type Props = {
  children?: ReactNode;
};

const OrganizationContainer = ({ children }: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [refreshData, setRefreshData] = useState<boolean>(true);
  const [data, setData] = useState<OrganizationHierarchy>(null);
  const [regions, setRegions] = useState<OrganizationHierarchy[]>([]);
  const [buildings, setBuildings] = useState<OrganizationHierarchy[]>([]);
  const [controlSystems, setControlSystems] = useState<OrganizationHierarchy[]>(
    [],
  );
  const [selectedRegion, setSelectedRegion] =
    useState<OrganizationHierarchy>(null);
  const [selectedBuilding, setSelectedBuilding] =
    useState<OrganizationHierarchy>(null);
  const [selectedControlSystem, setSelectedControlSystem] =
    useState<OrganizationHierarchy>(null);

  const getBuildings = (orgHierarchy: OrganizationHierarchy) => {
    let buildingArray: OrganizationHierarchy[] = [];
    if (
      typeof orgHierarchy !== 'undefined' &&
      orgHierarchy !== null &&
      typeof orgHierarchy.children !== 'undefined' &&
      orgHierarchy.children !== null
    ) {
      for (let rIdx = 0; rIdx < orgHierarchy.children.length; rIdx++) {
        buildingArray = buildingArray.concat(
          orgHierarchy.children[rIdx].children,
        );
      }
    }
    return buildingArray.sort((compA, compB) =>
      sortStringAscending(compA.name, compB.name),
    );
  };

  const getControlSystems = (orgHierarchy: OrganizationHierarchy) => {
    let controlSystemArray: OrganizationHierarchy[] = [];
    if (
      typeof orgHierarchy !== 'undefined' &&
      orgHierarchy !== null &&
      typeof orgHierarchy.children !== 'undefined' &&
      orgHierarchy.children !== null
    ) {
      for (let rIdx = 0; rIdx < orgHierarchy.children.length; rIdx++) {
        if (
          typeof orgHierarchy.children[rIdx].children !== 'undefined' &&
          orgHierarchy.children[rIdx].children !== null
        ) {
          // eslint-disable-next-line max-depth
          for (
            let bIdx = 0;
            bIdx < orgHierarchy.children[rIdx].children.length;
            bIdx++
          ) {
            // eslint-disable-next-line max-depth
            if (
              typeof orgHierarchy.children[rIdx].children[bIdx].children !==
              'undefined'
            ) {
              controlSystemArray = controlSystemArray.concat(
                orgHierarchy.children[rIdx].children[bIdx].children,
              );
            }
          }
        }
      }
    }
    return controlSystemArray.sort((compA, compB) =>
      sortStringAscending(compA.name, compB.name),
    );
  };

  const handleRefresh = () => {
    setRefreshData(true);
  };

  useEffect(() => {
    if (refreshData) {
      setRefreshData(false);
      const orgUrl = ORGANIZATION_HIERARCHY_ENDPOINT;

      fetch(`${orgUrl}`, {
        method: 'GET',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
      })
        .then((res) => res.json())
        .then((result: UserRestrictions) => {
          setData(result.hierarchy);
          setRegions(
            result.hierarchy.children.sort((compA, compB) =>
              sortStringAscending(compA.name, compB.name),
            ),
          );
          setBuildings(getBuildings(result.hierarchy));
          setControlSystems(getControlSystems(result.hierarchy));
        })
        .then(() => {
          setIsLoading(false);
        });
    }
  }, [refreshData]);

  return (
    <OrganizationContext.Provider
      value={{
        loading: isLoading,
        data,
        regions,
        buildings,
        controlSystems,
        selectedRegion,
        selectedBuilding,
        selectedControlSystem,
        setSelectedRegion,
        setSelectedBuilding,
        setSelectedControlSystem,
        doRefresh: handleRefresh,
      }}
    >
      <div>{children}</div>
    </OrganizationContext.Provider>
  );
};

export default OrganizationContainer;
