import React from 'react';
import { Collapse } from 'antd';

import Json from '@totem/components/common/Json';

type Props = {
  title: string;
  value: object;
};

const TitledJson = ({ title, value }: Props) => {
  return (
    <Collapse
      items={[
        {
          key: 1,
          label: title,
          children: <Json value={value} />,
        },
      ]}
    />
  );
};

export default TitledJson;
