import React from 'react';

import EventSeverityIndicatorContainer from '@totem/components/events/EventSeverityIndicatorContainer';
import colors from '@totem/styles/colors';

type Props = {
  severity: number;
};

const EventSeverityIndicator = ({ severity }: Props) => {
  const getSeverity = () => {
    switch (severity) {
      case 5:
        return (
          <EventSeverityIndicatorContainer
            style={{
              backgroundColor: colors.event.criticality.background.critical,
              color: colors.event.criticality.foreground.critical,
            }}
          >
            <b>Critical</b>
          </EventSeverityIndicatorContainer>
        );
      case 4:
        return (
          <EventSeverityIndicatorContainer
            style={{
              backgroundColor: colors.event.criticality.background.major,
              color: colors.event.criticality.foreground.major,
            }}
          >
            <b>Major</b>
          </EventSeverityIndicatorContainer>
        );
      case 3:
        return (
          <EventSeverityIndicatorContainer
            style={{
              backgroundColor: colors.event.criticality.background.minor,
              color: colors.event.criticality.foreground.minor,
            }}
          >
            <b>Minor</b>
          </EventSeverityIndicatorContainer>
        );
      case 2:
        return (
          <EventSeverityIndicatorContainer
            style={{
              backgroundColor: colors.event.criticality.background.warning,
              color: colors.event.criticality.foreground.warning,
            }}
          >
            <b>Warning</b>
          </EventSeverityIndicatorContainer>
        );
      case 1:
        return (
          <EventSeverityIndicatorContainer
            style={{
              backgroundColor: colors.event.criticality.background.info,
              color: colors.event.criticality.foreground.info,
            }}
          >
            <b>Information</b>
          </EventSeverityIndicatorContainer>
        );
      case 0:
        return (
          <EventSeverityIndicatorContainer
            style={{
              backgroundColor: colors.event.criticality.background.ok,
              color: colors.event.criticality.foreground.ok,
            }}
          >
            <b>OK</b>
          </EventSeverityIndicatorContainer>
        );
      default:
        return (
          <EventSeverityIndicatorContainer
            style={{
              backgroundColor: colors.event.criticality.background.unknown,
              color: colors.event.criticality.foreground.unknown,
            }}
          >
            <b>Unknown</b>
          </EventSeverityIndicatorContainer>
        );
    }
  };

  return getSeverity();
};

export default EventSeverityIndicator;
