import React from 'react';
import { EditOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';

import { Contract } from '@totem/types/contracts';
import { formatDateOnly } from '@totem/utilities/common';

import '../contracts.css';

const styles = {
  editIcon: {
    color: '#1890ff',
  },
};

type Props = {
  contract: Contract;
  loading: boolean;
  isEditMode: boolean;
  onEdit: () => void;
};

const ContractHeader = ({ contract, isEditMode, onEdit }: Props) => {
  return (
    <Row>
      <Col span={6}>
        <b>Description</b>
        <br />
        {contract.description}
      </Col>
      <Col span={6}>
        <b>Start</b>
        <br />
        {formatDateOnly(contract.contractStart)}
      </Col>
      <Col span={6}>
        <b>End</b>
        <br />
        {formatDateOnly(contract.contractEnd)}
      </Col>
      <Col span={6}>
        <div styleName="rightAlign">
          {isEditMode && (
            <EditOutlined
              onClick={onEdit}
              title="Edit Contract"
              style={styles.editIcon}
            />
          )}
        </div>
      </Col>
    </Row>
  );
};

export default ContractHeader;
