import React, { useContext, useEffect, useState } from 'react';
import { Button } from 'antd';
import {
  ArrayParam,
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params';

import BasePane from '@totem/components/BasePane';
import CardBody from '@totem/components/CardBody';
import CardHeader from '@totem/components/CardHeader';
import CardHeaderSmaller from '@totem/components/CardHeaderSmaller';
import useTableAutoCompleteFilter from '@totem/components/common/filterAutoComplete/TableFilter';
import ContentLayout from '@totem/components/ContentLayout';
import PrimaryContentPane from '@totem/components/PrimaryContentPane';
import TicketCreateModal from '@totem/components/ticketing/create/TicketCreateModal';
import TicketingContext from '@totem/components/ticketing/list/ticketingContext';
import TicketingFilterChips from '@totem/components/ticketing/list/TicketingFilterChips';
import TicketsTable from '@totem/components/ticketing/list/ticketsTable';
import { buildFilters } from '@totem/components/ticketing/utilities';
import UserProfileContext from '@totem/components/UserProfileContext';
import { Params } from '@totem/types/common';
import {
  TicketingConnectionInput,
  TicketingFilterOptions,
  TicketQueryResults,
} from '@totem/types/ticketing';
import { getToken } from '@totem/utilities/accountUtilities';
import { TICKETS_ENDPOINT } from '@totem/utilities/endpoints';
import { omitNilOrEmpty } from '@totem/utilities/objectUtilities';
import {
  getUserRole,
  isMemberOfAny,
  securityGroupCheckConstraintEmpty,
} from '@totem/utilities/userUtilities';

import '../ticketing.css';

const Tickets = () => {
  const [input, updateInput] = useQueryParams({
    from: withDefault(StringParam, ''),
    pageSize: withDefault(NumberParam, 10),
    page: withDefault(NumberParam, 1),
    sortField: withDefault(StringParam, 'createdAt'),
    sortDirection: withDefault(StringParam, '1'),
    buildingId: withDefault(ArrayParam, []),
    regionId: withDefault(ArrayParam, []),
    controlSystemId: withDefault(ArrayParam, []),
    deviceId: withDefault(ArrayParam, []),
    type: withDefault(ArrayParam, []),
    status: withDefault(ArrayParam, ['OPEN', 'RESOLVED']),
    category: withDefault(ArrayParam, []),
    priority: withDefault(ArrayParam, []),
  });
  const [refreshData, setRefreshData] = useState<boolean>(true);
  const { userProfile } = useContext(UserProfileContext);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [isSending, setIsSending] = useState(false);
  const [isFilterOptionsLoaded, setIsFilterOptionsLoaded] = useState<boolean>(
    false,
  );
  const [filterOptions, setFilterOptions] = useState<TicketingFilterOptions>({
    type: [],
    priority: [],
    status: [],
    subStatus: [],
    categories: [],
    building: [],
    organization: [],
    device: [],
  });
  const [ticketData, setTicketData] = useState<TicketQueryResults>(null);
  const [showNewTicketModal, setShowNewTicketModal] = useState<boolean>(false);

  const setInput = (updated: Partial<TicketingConnectionInput>) => {
    updateInput(omitNilOrEmpty({ ...input, ...updated }), 'replace');
    setRefreshData(true);
  };

  const buildingFilter = useTableAutoCompleteFilter(
    input.buildingId,
    filterOptions.building,
    {
      onChange: buildingId => {
        setInput({ ...input, buildingId });
      },
    },
  );

  const userCanCreateTicket =
    isMemberOfAny(
      userProfile,
      ['ticket_creator', 'ticket_administrator'],
      securityGroupCheckConstraintEmpty,
    ) || getUserRole(userProfile) === 3;

  const buildParameters = () => {
    const params: Params = {
      paging: {
        page: input.page,
        pageSize: input.pageSize,
      },
      sort: {
        field: input.sortField,
        direction: +input.sortDirection,
      },
      filters: buildFilters(input),
    };

    return params;
  };

  useEffect(() => {
    if (!isFilterOptionsLoaded) {
      fetch(`${TICKETS_ENDPOINT}/filterOptions`, {
        method: 'GET',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
      })
        .then(res => res.json())
        .then((result: TicketingFilterOptions) => {
          setFilterOptions(result);
        })
        .then(() => {
          setIsFilterOptionsLoaded(true);
        });
    }
  }, [isFilterOptionsLoaded]);

  useEffect(() => {
    if (refreshData) {
      if (isSending) {
        return;
      }
      setIsLoading(true);
      const params: Params = buildParameters();

      fetch(`${TICKETS_ENDPOINT}`, {
        method: 'POST',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
        body: JSON.stringify(params),
      })
        .then(res => res.json())
        .then((result: TicketQueryResults) => {
          setTicketData(result);
          setTotalRecords(result.paging.totalRecords);
        })
        .then(() => {
          setIsLoading(false);
          setIsSending(false);
        });

      setIsSending(true);
      setRefreshData(false);
    }
  }, [refreshData]);

  const handleTicketCreateClosed = (ticketCreated: boolean) => {
    setShowNewTicketModal(false);
    if (ticketCreated) {
      setRefreshData(true);
    }
  };

  return (
    <ContentLayout>
      <TicketingContext.Provider
        value={{
          input,
          setInput,
          loading: isLoading,
          buildingFilter,
        }}
      >
        <BasePane>
          <PrimaryContentPane>
            <CardHeader>
              <div>Ticket Management</div>
              <CardHeaderSmaller>{totalRecords} Tickets</CardHeaderSmaller>
              {userCanCreateTicket && (
                <Button
                  type="primary"
                  onClick={() => setShowNewTicketModal(true)}
                >
                  New Ticket
                </Button>
              )}
            </CardHeader>
            <CardBody>
              <div styleName="table-chips-container">
                <TicketingFilterChips filterOptions={filterOptions} />
              </div>
              <TicketsTable
                page={input.page}
                pageSize={input.pageSize}
                total={totalRecords}
                ticketResults={ticketData}
                filterOptions={filterOptions}
                loading={isLoading}
              />
            </CardBody>
          </PrimaryContentPane>
        </BasePane>
        {showNewTicketModal && (
          <TicketCreateModal
            visible={showNewTicketModal}
            onClose={handleTicketCreateClosed}
          />
        )}
      </TicketingContext.Provider>
    </ContentLayout>
  );
};

export default Tickets;
