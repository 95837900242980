import {
  PolicyAuditFilters,
  PolicyAuditReportInput,
} from '@totem/components/surveyV2/policyAuditReport/types';
import { Filter, Params } from '@totem/types/common';
import { isNotNull } from '@totem/utilities/common';

export const buildPolicyAuditReportFilters = (
  input: PolicyAuditReportInput,
) => {
  const paramFilters: Filter[] = [];

  if (
    typeof input.regionId !== 'undefined' &&
    input.regionId !== null &&
    input.regionId.length > 0
  ) {
    paramFilters.push({
      field: 'region.id',
      values: input.regionId,
    });
  }
  if (
    typeof input.buildingId !== 'undefined' &&
    input.buildingId !== null &&
    input.buildingId.length > 0
  ) {
    paramFilters.push({
      field: 'building.id',
      values: input.buildingId,
    });
  }
  if (
    typeof input.controlSystemId !== 'undefined' &&
    input.controlSystemId !== null &&
    input.controlSystemId.length > 0
  ) {
    paramFilters.push({
      field: 'controlSystem.id',
      values: input.controlSystemId,
    });
  }

  return paramFilters;
};

export const addPolicyAuditReportFilters = (
  currentFilters: Filter[],
  input: PolicyAuditFilters,
) => {
  if (isNotNull(input)) {
    if (isNotNull(input.regionId) && input.regionId.length > 0) {
      currentFilters.push({
        field: 'region.id',
        values: input.regionId,
      });
    }
    if (isNotNull(input.buildingId) && input.buildingId.length > 0) {
      currentFilters.push({
        field: 'building.id',
        values: input.buildingId,
      });
    }
    if (isNotNull(input.controlSystemId) && input.controlSystemId.length > 0) {
      currentFilters.push({
        field: 'controlSystem.id',
        values: input.controlSystemId,
      });
    }
  }

  return currentFilters;
};

export const buildParameters = (
  input: PolicyAuditReportInput,
  staticFilters: PolicyAuditFilters,
) => {
  const params: Params = {
    paging: {
      page: input.page,
      pageSize: input.pageSize,
    },
    sort: {
      field: input.sortField,
      direction: +input.sortDirection,
    },
    filters: buildPolicyAuditReportFilters(input),
  };

  params.filters = addPolicyAuditReportFilters(params.filters, staticFilters);

  return params;
};
