import React, { useContext } from 'react';

import OpenCveKeyModal from '@totem/components/software/software_package/dialogOpenCVEKey';
import KeysTable from '@totem/components/software/software_package/keysTable';
import PackageContext from '@totem/components/software/software_package/packageContext';

const KeysPane = () => {
  const { action } = useContext(PackageContext);

  return (
    <>
      <KeysTable />
      {action === 'key_add' && <OpenCveKeyModal />}
    </>
  );
};

export default KeysPane;
