import React from 'react';
import { useParams } from 'react-router-dom';

import BasePane from '@totem/components/BasePane';
import AuditFindingsOverview from '@totem/components/surveyV2/auditReport/AuditFindingsOverview';
import AuditScoreOverview from '@totem/components/surveyV2/auditReport/AuditScoreOverview';
import ReportContentLayout from '@totem/components/surveyV2/auditReport/ReportContentLayout';
import SurveyInstanceContainer from '@totem/components/surveyV2/instanceDetail/SurveyInstanceContainer';

import './auditReport.css';

const styles = {
  cardContainer: {
    justifyContent: 'center',
    margin: '0 2rem 2rem -2rem',
  },
};

const AuditReportPage = () => {
  const { id } = useParams();

  return (
    <SurveyInstanceContainer surveyInstanceId={id}>
      <ReportContentLayout>
        <BasePane>
          <div style={styles.cardContainer}>
            <AuditScoreOverview />
            <br />
            <AuditFindingsOverview />
          </div>
        </BasePane>
      </ReportContentLayout>
    </SurveyInstanceContainer>
  );
};

export default AuditReportPage;
