import React from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import colors from '@totem/styles/colors';
import { NmapDevice } from '@totem/types/nmap';
import criticalityUtilities from '@totem/utilities/criticalityUtilities';

import './nmapDetail.css';

interface Props {
  device: NmapDevice;
  isReport?: boolean;
}

const styles = {
  flaggedIcon: {
    fontSize: 20,
    color: colors.brand.orange,
    marginRight: '1rem',
  },
};

const NmapReportDetailsRow = ({ device, isReport }: Props) => {
  const manufacturer = device.deviceManufacturer
    ? device.deviceManufacturer
    : 'Unknown Manufacturer';

  return (
    <div styleName={isReport ? 'report' : ''}>
      <div styleName={`findings-table-heading row ${isReport ? 'report' : ''}`}>
        <div
          styleName={`finding-section-details row ${isReport ? 'report' : ''}`}
        >
          {manufacturer}
        </div>
        <div
          styleName={`finding-section-details row subsection ${
            isReport ? 'report' : ''
          }`}
        >
          {device.ipAddress}
        </div>
        <div
          styleName={`finding-section-details row subsection ${
            isReport ? 'report' : ''
          }`}
        >
          {device.macAddress}
        </div>
      </div>
      <div styleName="findings-section-details-container">
        <div styleName="findings-section-details-flag-heading">flag(s)</div>
        {device.isFlagged ? (
          <>
            <div styleName="findings-section-details-criticality">
              {criticalityUtilities.getCriticalityBadge(device.criticality)}
            </div>
            <div styleName="findings-section-details-flags-container">
              {device.isPortsFlagged && (
                <div styleName="findings-section-details-flag-badge-container">
                  <ExclamationCircleOutlined style={styles.flaggedIcon} />
                  <div styleName="findings-section-details-flag-badge-label">
                    Port
                  </div>
                </div>
              )}
              {device.isSoftwareVersionsFlagged && (
                <div styleName="findings-section-details-flag-badge-container">
                  <ExclamationCircleOutlined style={styles.flaggedIcon} />
                  <div styleName="findings-section-details-flag-badge-label">
                    OS
                  </div>
                </div>
              )}
              {device.isManufacturerFlagged && (
                <div styleName="findings-section-details-flag-badge-container">
                  <ExclamationCircleOutlined style={styles.flaggedIcon} />
                  <div styleName="findings-section-details-flag-badge-label">
                    Manufacturer
                  </div>
                </div>
              )}
              {device.isOtherFlagged && (
                <div styleName="findings-section-details-flag-badge-container">
                  <ExclamationCircleOutlined style={styles.flaggedIcon} />
                  <div styleName="findings-section-details-flag-badge-label">
                    Other
                  </div>
                </div>
              )}
            </div>
            {device.notes && (
              <div styleName="findings-section-notes-container">
                <div styleName="findings-section-details-flag-heading">
                  notes
                </div>
                <div styleName="findings-section-details-flag-notes">
                  {device.notes}
                </div>
              </div>
            )}
          </>
        ) : (
          <div styleName="findings-section-not-flagged">-</div>
        )}
      </div>
    </div>
  );
};

export default NmapReportDetailsRow;
