import { createContext } from 'react';

import {
  RemoteAccessTotals,
  RemoteAccessTotalsInput,
} from '@totem/components/remoteAccess/totals/types';

interface Context {
  input: RemoteAccessTotalsInput,
  setInput: (input: RemoteAccessTotalsInput) => void;
  loading: boolean;
  data: RemoteAccessTotals;
  action: string;
  onAction?: (action: string) => void;
}

export default createContext<Context>({
  input: {},
  setInput: () => {},
  loading: false,
  data: null,
  action: '',
  onAction: () => {},
});
