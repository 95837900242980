import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';

import FindingsContext from '@totem/components/surveyV2/findings/FindingsContext';
import SubTitle from '@totem/components/surveyV2/SubTitle';
import colors from '@totem/styles/colors';
import { isNotNull } from '@totem/utilities/common';

import '../survey.css';

const styles = {
  icon: {
    marginRight: '0.5rem',
  },
};
const SourceQuestion = () => {
  const { data } = useContext(FindingsContext);

  const question = isNotNull(data) && data.length > 0 ? data[0].question : '';

  const navigate = useNavigate();
  const navigateBack = () => {
    navigate(-1);
  };

  return (
    <div styleName="source-question-container">
      <span styleName="source-question-back" onClick={navigateBack}>
        <ArrowLeftOutlined style={styles.icon} color={colors.antd.blue} />
        Back to Policies
      </span>
      <div styleName="source-question-content">
        <SubTitle>Selected Policy:</SubTitle>
        <span styleName="source-question">{question}</span>
      </div>
    </div>
  );
};

export default SourceQuestion;
