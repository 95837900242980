import React, { useContext } from 'react';

import ComponentHeader from '@totem/components/ComponentHeader';
import BackupSummaryCharts from '@totem/components/deviceBackups/backupSummary/backupSummaryCharts';
import BackupSummaryContext from '@totem/components/deviceBackups/backupSummary/backupSummaryContext';
import PageSection from '@totem/components/PageSection';
import PageSectionBody from '@totem/components/PageSectionBody';

const ContinuitySection = () => {
  const { data } = useContext(BackupSummaryContext);

  const hasBackupSummary = typeof data !== 'undefined' && data !== null;

  const showContinuity = hasBackupSummary;

  return (
    <>
      {showContinuity && (
        <PageSection>
          <ComponentHeader title={'Continuity'} />
          <PageSectionBody>
            <BackupSummaryCharts />
          </PageSectionBody>
        </PageSection>
      )}
    </>
  );
};

export default ContinuitySection;
