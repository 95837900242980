import React, { useContext, useEffect, useState } from 'react';
import { ToolOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import * as R from 'ramda';

import BuildingDetailContext from '@totem/components/buildingDetail/BuildingDetailContext';
import DetailSpinner from '@totem/components/common/DetailSpinner';
import ComponentHeader from '@totem/components/ComponentHeader';
import colors from '@totem/styles/colors';
import { Nmap } from '@totem/types/nmap';
import api from '@totem/utilities/api';
import { NMAP_ENDPOINT } from '@totem/utilities/endpoints';
import { TOTAL_COUNT_HEADER } from '@totem/utilities/requestUtilities';

import AddNmapFileModal from './AddNmapFileModal';
import NmapContext from './NmapContext';
import NmapTable from './NmapTable';

import './nmap.css';

const styles = {
  icon: {
    fontSize: '4rem',
    color: colors.neutral.silver,
  },
};

const Nmaps = () => {
  const { building } = useContext(BuildingDetailContext);

  const [open, setOpen] = useState<boolean>(false);
  const [limit, setLimit] = useState<number>(10);
  const [page, setPage] = useState<number>(1);

  const [nmaps, setNmaps] = useState<Nmap[]>();
  const [total, setTotal] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const getNmaps = async () => {
      setLoading(true);

      const params = {
        buildingId: building.id,
        page,
        limit,
      };

      const { data, headers } = await api.get(NMAP_ENDPOINT, params);

      setNmaps(data);
      setTotal(Number(headers[TOTAL_COUNT_HEADER]) || 0);

      setLoading(false);
    };

    getNmaps();
  }, [building.id, page, limit]);

  const refetch = async () => {
    setLoading(true);

    const params = {
      buildingId: building.id,
      page,
      limit,
    };

    const { data, headers } = await api.get(NMAP_ENDPOINT, params);

    setNmaps(data);
    setTotal(Number(headers[TOTAL_COUNT_HEADER]) || 0);

    setLoading(false);
  };

  const handlePagination = (pagination: any) => {
    const { current, pageSize } = pagination;

    setPage(current);
    setLimit(pageSize);
  };

  return (
    <NmapContext.Provider
      value={{
        refetch,
        loading,
        nmaps,
        limit,
        page,
        handlePagination,
        total,
      }}
    >
      {loading && R.isEmpty(nmaps) ? (
        <div styleName="spinner-container">
          <DetailSpinner />
        </div>
      ) : (
        <>
          <ComponentHeader
            title={'NMAP Analysis'}
            options={
              !R.isEmpty(nmaps) && (
                <Button type="primary" onClick={() => setOpen(true)}>
                  Upload NMAP File
                </Button>
              )
            }
          />
          <div styleName="table-container">
            {R.isEmpty(nmaps) ? (
              <div styleName="no-system-container">
                <ToolOutlined style={styles.icon} />
                <div styleName="no-system-subtext">
                  Add a NMAP file to this building to get started.
                </div>
                <Button onClick={() => setOpen(true)} type="primary">
                  Upload NMAP File
                </Button>
              </div>
            ) : (
              <NmapTable />
            )}
          </div>
          <AddNmapFileModal open={open} setOpen={setOpen} />
        </>
      )}
    </NmapContext.Provider>
  );
};

export default Nmaps;
