import React from 'react';

import ContactSearchEmbeddedContainer from '@totem/components/contactSearch/contactSearchEmbeddedContainer';
import ContactSearchTinyPanels from '@totem/components/contactSearch/ContactSearchTinyPanels';

const ContactSearchTiny = () => {
  return (
    <ContactSearchEmbeddedContainer>
      <ContactSearchTinyPanels />
    </ContactSearchEmbeddedContainer>
  );
};

export default ContactSearchTiny;
