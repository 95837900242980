import React, { useContext } from 'react';

import Notes from '@totem/components/common/notes/Notes';
import CommonSidebar from '@totem/components/common/sidebar/Sidebar';
import Contacts from '@totem/components/controlSystemDetail/container/components/Contacts';
import client from '@totem/graph/client';
import { Note } from '@totem/types/note';

import ControlSystemDetailContext from '../ControlSystemDetailContext';

import ControlSystemInfo from './ControlSystemInfo';

import './controlSystemSidebar.css';

const ControlSystemSidebar = () => {
  const { controlSystem } = useContext(ControlSystemDetailContext);

  const handleNoteCreate = (note: Note) => {
    client.cache.modify({
      id: client.cache.identify(controlSystem),
      fields: {
        notes: (existing, { toReference }) => ({
          ...existing,
          totalCount: existing.totalCount + 1,
          notes: [...existing.notes, toReference(note)],
        }),
      },
    });
  };

  const handleNoteDelete = (id: string) => {
    client.cache.modify({
      id: client.cache.identify(controlSystem),
      fields: {
        notes: (existing, { readField }) => ({
          ...existing,
          totalCount: existing.totalCount - 1,
          notes: existing.notes.filter((ref) => readField('id', ref) !== id),
        }),
      },
    });
  };

  return (
    <CommonSidebar tabLabel="Details">
      <div styleName="container">
        <ControlSystemInfo />
        <Contacts />
        <Notes
          subjectId={controlSystem.id}
          title="System Notes"
          noContentMessage="Attach a note to your control system to keep track of system specific information."
          onCreate={handleNoteCreate}
          onDelete={handleNoteDelete}
        />
      </div>
    </CommonSidebar>
  );
};

export default ControlSystemSidebar;
