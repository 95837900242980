import React, { ReactNode, useEffect, useState } from 'react';

import { Volume } from '@totem/components/devices/volumes/types';
import VolumeContext from '@totem/components/devices/volumes/volumeContext';
import { getToken } from '@totem/utilities/accountUtilities';
import { DEVICES_ENDPOINT } from '@totem/utilities/endpoints';

type Props = {
  id: string;
  children?: ReactNode;
};

const VolumesContainer = ({ id, children }: Props) => {
  const [refreshData, setRefreshData] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [data, setData] = useState<Volume[]>([]);
  const [action, setAction] = useState<string>('');
  const [actionItem, setActionItem] = useState<Volume>(null);

  useEffect(() => {
    if (refreshData) {
      setRefreshData(false);
      setIsLoading(true);

      const uri = `${DEVICES_ENDPOINT}/${id}/volumes`;

      fetch(uri, {
        method: 'GET',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
      })
        .then((res) => res.json())
        .then((result: Volume[]) => {
          setData(result);
        })
        .then(() => {
          setIsLoading(false);
        });
    }
  }, [id, refreshData]);

  const handleAction = (newAction: string, item: Volume) => {
    switch (newAction) {
      default:
        setAction(newAction);
        setActionItem(item);
    }
  };

  return (
    <VolumeContext.Provider
      value={{
        deviceId: id,
        loading: isLoading,
        data,
        action,
        actionItem,
        onAction: handleAction,
      }}
    >
      <div>{children}</div>
    </VolumeContext.Provider>
  );
};

export default VolumesContainer;
