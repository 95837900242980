import React, { useCallback, useContext, useState } from 'react';
import { message, Tag } from 'antd';

import CveCardTitle from '@totem/components/software/cves/cveCardTitle';
import CveInformationValue from '@totem/components/software/cves/cveInformationValue';
import CvesContext from '@totem/components/software/cves/cvesContext';
import {
  AffectedVersionUpdate,
  CVERecord,
} from '@totem/components/software/cves/types';
import UserProfileContext from '@totem/components/UserProfileContext';
import IBDIcon from '@totem/styles/Icon';
import { getToken } from '@totem/utilities/accountUtilities';
import { DEVICES_ENDPOINT } from '@totem/utilities/endpoints';
import { isIBUser } from '@totem/utilities/security';

const styles = {
  tag: {
    marginBottom: '3px',
  },
};

type Props = {
  cve: CVERecord;
};

const CveVersions = ({ cve }: Props) => {
  const { userProfile } = useContext(UserProfileContext);
  const { onAction } = useContext(CvesContext);
  const [isSending, setIsSending] = useState<boolean>(false);
  const canEdit = userProfile.organization.role === 3 && isIBUser(userProfile);

  const sendUpdate = useCallback(
    async (request: AffectedVersionUpdate) => {
      if (isSending) {
        return;
      }

      fetch(`${DEVICES_ENDPOINT}/software/cves/affectedVersions`, {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getToken()}`,
        }),
        body: JSON.stringify(request),
      })
        .then((response) => {
          if (response.status !== 200) {
            message.error('CVE update failed!');
          } else {
            onAction('refresh', null);
          }
        })
        .then(() => {
          setIsSending(false);
        });

      setIsSending(true);
    },
    [isSending],
  );

  const handleUpdateAffectedVersions = (action: string, version: string) => {
    const request: AffectedVersionUpdate = {
      action,
      cve: cve.id,
      version,
    };

    sendUpdate(request);
  };

  return (
    <>
      <CveCardTitle>Versions</CveCardTitle>
      {typeof cve !== 'undefined' &&
        cve !== null &&
        cve.metaData !== null &&
        cve.metaData.fixedVersion !== '' && (
          <CveInformationValue title="Fixed Version">
            {cve.metaData.fixedVersion}
          </CveInformationValue>
        )}
      {typeof cve !== 'undefined' &&
        cve !== null &&
        cve.metaData !== null &&
        cve.metaData.affectedVersions !== null &&
        cve.metaData.affectedVersions.length > 0 && (
          <CveInformationValue title="Affected Versions">
            {cve.metaData.affectedVersions.map((version) => (
              <Tag key={version} color="error" style={styles.tag}>
                <span>
                  {version}
                  {canEdit && (
                    <>
                      {' '}
                      <IBDIcon
                        name="Delete"
                        title="Remove Affected Version"
                        onClick={() => {
                          handleUpdateAffectedVersions('Remove', version);
                        }}
                      />
                    </>
                  )}
                </span>
              </Tag>
            ))}
          </CveInformationValue>
        )}
      {typeof cve !== 'undefined' &&
        cve !== null &&
        cve.metaData !== null &&
        cve.metaData.checkedVersions !== null &&
        cve.metaData.checkedVersions.length > 0 && (
          <CveInformationValue title="Checked Versions">
            {cve.metaData.checkedVersions.map((version) => (
              <Tag key={version} color="success" style={styles.tag}>
                <span>
                  {version}
                  {canEdit && (
                    <>
                      {' '}
                      <IBDIcon
                        name="Add"
                        title="Add to Affected Versions"
                        onClick={() => {
                          handleUpdateAffectedVersions('Add', version);
                        }}
                      />
                    </>
                  )}
                </span>
              </Tag>
            ))}
          </CveInformationValue>
        )}
    </>
  );
};

export default CveVersions;
