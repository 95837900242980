import { createContext } from 'react';

import { DeviceQueryResult } from '@totem/types/devices';

interface Context {
  loading: boolean;
  deviceData: DeviceQueryResult;
  action: string;
  onAction: (string) => void;
}

export default createContext<Context>({
  loading: false,
  deviceData: null,
  action: '',
  onAction: () => {},
});
