import React from 'react';
import { Card, Layout } from 'antd';
const { Content } = Layout;
import intellinetLogo from '../../images/intellinet_ib.svg';

const styles = {
  header: {
    backgroundColor: 'white',
    height: '100px',
  },
  h1: {
    fontWeight: 'bold',
  },
  h2: {
    fontWeight: 650,
  },
  content: {
    fontFamily: 'Roboto',
    fontSize: 14,
  },
  logo: {
    height: '95px',
    marginTop: '3rem',
    marginBottom: '4rem',
  },
};

const PrivacyPolicy = () => {
  return (
    <Layout style={{ backgroundColor: 'white' }}>
      <img alt="Logo" src={intellinetLogo} style={styles.logo} />
      <Content style={styles.content}>
        <Card title={<h1 style={styles.h1}>Privacy Policy</h1>}>
          Privacy Policy for IntelliNet Mobile and IB Dashboard
          <br />
          <br />
          Last Updated: 11/01/2024
          <br />
          <br />
          Intelligent Buildings realizes that your privacy is important to you.
          This Policy outlines how we collect, use, store, and protect
          information through our mobile application (IntelliNet) and our web
          portal (IB Dashboard) (collectively, the “Services”). These Services
          are provided exclusively to existing clients to help better understand
          and also manage various aspects of their Operational Data with their
          organizations.
          <br />
          <h2 style={styles.h2}>1. Data Collection and Usage</h2>
          <p>
            <strong>Organizational Data</strong>
            <br />
            Our Services allow you to access and manage certain organizational
            and operational data provided by your company, data such as user
            accounts, settings, and operational information. This data is stored
            on our secure servers and accessed only for the purpose of
            delivering these Services. We do not collect or use this data for
            any other purpose.
          </p>
          <p>
            <strong>Cookies and Session Management on the IB Dashboard</strong>
            <br />
            The IB Dashboard uses cookies to manage user sessions and ensure a
            seamless experience. These cookies are essential for the operation
            and user experience of the the portal. They allow us to recognize
            you as you navigate through different sections. We do not use
            cookies for tracking purposes or collect personal information beyond
            session management.
          </p>
          <h2 style={styles.h2}>2. Data Storage and Security</h2>
          <p>
            <strong>Data Storage</strong>
            <br />
            All data displayed within the Services is stored on secure, managed
            servers, and pertains solely to your organization’s managed
            services. Any data transmission between your device or browser and
            our servers is encrypted to ensure data security.
          </p>
          <p>
            <strong>Security Measures</strong>
            <br />
            We implement a combination of technical, administrative, and
            physical security measures to protect against unauthorized access,
            data loss, or tampering. However, you should also take personal
            security precautions such as using strong passwords and securing
            your device or browser.
          </p>
          <h2 style={styles.h2}>3. Cookies and Tracking Technologies</h2>
          <p>
            <strong>Session Cookies</strong>
            <br />
            Our web portal uses session cookies to facilitate login and session
            management. These cookies are temporary and expire once you log out
            or close your browser.
          </p>
          <p>
            <strong>No Third-Party Tracking</strong>
            <br />
            We do not use third-party tracking or analytics services on our web
            portal. Our cookies are solely for authentication and session
            continuity, ensuring a seamless and secure user experience.
          </p>
          <h2 style={styles.h2}>4. Third-Party Services</h2>
          <p>
            Our Services may connect to or interact with other systems necessary
            for managed services. Any such third-party connections are strictly
            controlled, and we do not share any data with external parties for
            advertising or tracking purposes. These interactions are solely to
            support the functionality and purpose of the Services.
          </p>
          <h2 style={styles.h2}>5. User Rights and Access</h2>
          <p>
            As our client, your organization retains control over the data
            within the Services. For any questions regarding the information
            displayed or managed within the Services, please reach out directly
            to your IB representative. If you have concerns about our privacy
            practices, you can also contact our support team at the email
            address below.
          </p>
          <h2 style={styles.h2}>6. Changes to the Privacy Policy</h2>
          <p>
            Intelligent Buildings may update this Privacy Policy as necessary to
            reflect changes in our practices or the Services we offer. We
            recommend users review this Privacy Policy periodically for any
            updates. Continued use of the Services after changes indicates
            acceptance of the revised Privacy Policy.
          </p>
          <h2 style={styles.h2}>7. Contact Us</h2>
          <p>
            If you have questions, comments, or concerns about this Privacy
            Policy, please contact us at:
            <br />
            Email:{' '}
            <a href="mailto:support@intelligentbuildings.com">
              support@intelligentbuildings.com
            </a>
            <br />
          </p>
          <br />
          <p>
            We are committed to protecting your privacy and ensuring that your
            data is handled responsibly. Thank you for trusting us with your
            organization’s information and using our Services.
          </p>
        </Card>
      </Content>
    </Layout>
  );
};

export default PrivacyPolicy;
