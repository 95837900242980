import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import Modal from 'antd/lib/modal/Modal';

import { EMPTY_ID } from '@totem/components/common/reference/ReferenceSelector';
import ModalFooter from '@totem/components/ModalFooter';
import ModalTitle from '@totem/components/ModalTitle';
import NozomiApiTable from '@totem/components/settings/sections/services/provisioning/threat_detection/NozomiApiTable';
import NozomiEditForm from '@totem/components/settings/sections/services/provisioning/threat_detection/NozomiEditForm';
import { APIConfiguration } from '@totem/types/nozomi';
import { Service } from '@totem/types/organization';
import { getToken } from '@totem/utilities/accountUtilities';
import { NOZOMI_ENDPOINT } from '@totem/utilities/endpoints';

export interface Props {
  visible: boolean;
  onClose: (boolean) => void;
  service: Service;
}

const NozomiGuardianProvisioningModal = ({ visible, onClose }: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isRefreshing, setIsRefreshing] = useState<boolean>(true);
  const [apiConfig, setApiConfig] = useState<APIConfiguration[]>([]);
  const [editApiConfig, setEditApiConfig] = useState<APIConfiguration>(null);
  const [apiConfigSaving, setApiConfigSaving] = useState<boolean>(false);
  const [apiConfigDeleting, setApiConfigDeleting] = useState<boolean>(false);
  const [deletingApiId, setDeletingApiId] = useState<string>('');

  useEffect(() => {
    if (isRefreshing && !isLoading) {
      setIsLoading(true);
      fetch(NOZOMI_ENDPOINT, {
        method: 'GET',
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getToken()}`,
        }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('HTTP Failed');
          }
          return response.json();
        })
        .then((result: APIConfiguration[]) => {
          setApiConfig(result);
        })
        .then(() => {
          setIsLoading(false);
          setIsRefreshing(false);
        });
    }
  }, [isRefreshing, isLoading]);

  useEffect(() => {
    if (apiConfigDeleting === true && deletingApiId !== '') {
      fetch(`${NOZOMI_ENDPOINT}/${deletingApiId}`, {
        method: 'DELETE',
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getToken()}`,
        }),
      })
        .then((response) => {
          setApiConfigDeleting(false);
          setDeletingApiId('');
          if (!response.ok) {
            throw new Error('HTTP Failed');
          }
          return response.json();
        })
        .then((result: APIConfiguration[]) => {
          setApiConfig(result);
        });
    }
  }, [apiConfigDeleting, deletingApiId]);

  useEffect(() => {
    if (apiConfigSaving === true && editApiConfig !== null) {
      fetch(`${NOZOMI_ENDPOINT}/building/${editApiConfig.data.building.id}`, {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getToken()}`,
        }),
        body: JSON.stringify(editApiConfig),
      })
        .then((response) => {
          setApiConfigSaving(false);
          if (!response.ok) {
            throw new Error('HTTP Failed');
          }
          return response.json();
        })
        .then((result: APIConfiguration[]) => {
          setApiConfig(result);
          setEditApiConfig(null);
        });
    }
  }, [apiConfigSaving, editApiConfig]);

  const handleEdit = (config: APIConfiguration) => {
    setEditApiConfig(config);
  };

  const handleCopy = (config: APIConfiguration) => {
    const newConfig = {
      ...config,
      id: EMPTY_ID,
      data: {
        ...config.data,
        syncTimes: [],
        connection: { ...config.data.connection, confirmed: false },
      },
    };
    setEditApiConfig(newConfig);
  };

  const handleDelete = (config: APIConfiguration) => {
    setDeletingApiId(config.id);
    setApiConfigDeleting(true);
  };

  const handleCancelForm = () => {
    setEditApiConfig(null);
  };

  const handleSubmitForm = (newConfig: APIConfiguration) => {
    setEditApiConfig(newConfig);
    setApiConfigSaving(true);
  };

  return (
    <Modal
      title={<ModalTitle>Nozomi Guardian Service Configuration</ModalTitle>}
      onCancel={onClose}
      open={visible}
      footer={
        <ModalFooter>
          <Button onClick={() => onClose(false)}>Cancel</Button>
        </ModalFooter>
      }
    >
      {editApiConfig !== null && (
        <NozomiEditForm
          apiConfig={editApiConfig}
          onCancel={handleCancelForm}
          onSubmit={handleSubmitForm}
        />
      )}
      {editApiConfig === null && (
        <NozomiApiTable
          configs={apiConfig}
          loading={isLoading}
          onEdit={handleEdit}
          onCopy={handleCopy}
          onDelete={handleDelete}
        />
      )}
    </Modal>
  );
};

export default NozomiGuardianProvisioningModal;
