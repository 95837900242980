import { createContext } from 'react';

import {
  ContactSearchInput,
  ContactSearchResultEntry,
} from '@totem/components/contactSearch/types';

interface Context {
  input: ContactSearchInput;
  setInput: (input: ContactSearchInput) => void;
  loading: boolean;
  data: ContactSearchResultEntry[];
  selectedAction: string;
  selectedContact: ContactSearchResultEntry;
  totalRecords: number;
  onAction?: (action: string, contact: ContactSearchResultEntry) => void;
}

export default createContext<Context>({
  input: { search_term: '' },
  setInput: () => {},
  loading: false,
  data: null,
  selectedAction: '',
  selectedContact: null,
  totalRecords: 0,
  onAction: null,
});
