import React, { useContext } from 'react';

import Contacts from '@totem/components/buildingDetail/container/Contacts';
import Notes from '@totem/components/common/notes/Notes';
import CommonSidebar from '@totem/components/common/sidebar/Sidebar';
import UserProfileContext from '@totem/components/UserProfileContext';

import BuildingDetailContext from '../BuildingDetailContext';

import BuildingInfo from './BuildingInfo';

import './sidebar.css';

const Sidebar = () => {
  const { userProfile } = useContext(UserProfileContext);
  const { building } = useContext(BuildingDetailContext);

  const {
    buildingContact: showBuildingContact,
    buildingNote: showBuildingNote,
    // remoteAccess: showRemoteAccess,
  } = userProfile.organization?.preferences?.features;

  return (
    <CommonSidebar tabLabel="Details">
      <div styleName="sidebar-content">
        <BuildingInfo />
        {showBuildingContact && <Contacts />}
        {showBuildingNote && (
          <Notes
            title="Building Notes"
            noContentMessage="Attach a note to your building to keep track if site specific information."
            subjectId={building.id}
          />
        )}
      </div>
    </CommonSidebar>
  );
};

export default Sidebar;
