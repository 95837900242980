export interface FilterAutoCompleteProps {
  search: string;
  onSearch: (search: string) => void;
  options: FilterOptions;
  foundOptions: FilterOptions;
  availableOptions: FilterOptions;
  selected: FilterOptions;
  applied: FilterOptions;
  onSelect: (filterOption: FilterOption) => void;
  onRemove: (id: string) => void;
  onApply: () => void;
  onReset: () => void;
  loading: boolean;
}

export type FilterOptions = FilterOption[];

export interface FilterOption {
  name: string;
  value: string;
}

export const containsValue = (options: FilterOptions, value: string) => {
  for (let idx = 0; idx < options.length; idx++) {
    if (options[idx].value === value) {
      return true;
    }
  }
  return false;
};

export const getFilterKeys = (options: FilterOptions) => {
  return options.map((filterOption) => filterOption.value);
};

export interface AutoCompleteFilterOptions {
  limit?: number;
  debounce?: number;
  onChange?: (ids: string[]) => void;
}
