import { createContext } from 'react';

import { Software } from '@totem/components/devices/software/types';

interface Context {
  deviceId: string;
  loading: boolean;
  data: Software[];
  action: string;
  actionItem: Software;
  onAction?: (action: string, item: Software) => void;
}

export default createContext<Context>({
  deviceId: '',
  loading: false,
  data: null,
  action: '',
  actionItem: null,
  onAction: () => {},
});
