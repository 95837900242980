import React, { useContext, useEffect, useState } from 'react';

import DropdownFilter from '@totem/components/common/dropdownFilter/DropdownFilter';
import FiltersChipsContainer from '@totem/components/FilterChipsContainer';
import FiltersBody from '@totem/components/FiltersBody';
import FiltersContainer from '@totem/components/FiltersContainer';
import FiltersTitle from '@totem/components/FiltersTitle';
import AnalysisContext from '@totem/components/surveyV2/analysis/AnalysisContext';
import AnalysisFilterChips from '@totem/components/surveyV2/analysis/AnalysisFilterChips';
import { AnalysisInput } from '@totem/components/surveyV2/analysis/types';
import { LevelOfEffortToString } from '@totem/components/surveyV2/utilities/SurveyEnumerations';
import {
  NumericDropDownFilterOption,
  StringDropDownFilterOption,
} from '@totem/types/common';
import { isNotNull } from '@totem/utilities/common';
import {
  sortNumberAscending,
  sortStringAscending,
} from '@totem/utilities/tableUtilities';

const styles = {
  dropdown: {
    marginRight: '2rem',
  },
};

const AnalysisFilters = () => {
  const { data, input, setInput } = useContext(AnalysisContext);
  const [policyNameFilterOptions, setPolicyNameFilterOptions] = useState<
    StringDropDownFilterOption[]
  >([]);
  const [categoryFilterOptions, setCategoryFilterOptions] = useState<
    StringDropDownFilterOption[]
  >([]);
  const [nistFilterOptions, setNistFilterOptions] = useState<
    StringDropDownFilterOption[]
  >([]);
  const [levelOfEffortFilterOptions, setLevelOfEffortFilterOptions] = useState<
    NumericDropDownFilterOption[]
  >([]);

  useEffect(() => {
    if (isNotNull(data)) {
      const optionsPolicyName = [];
      const optionsCategories = [];
      const optionsNist = [];
      const optionsLevelOfEffort = [];
      for (let idx = 0; idx < data.auditPolicies.length; idx++) {
        if (
          optionsPolicyName.findIndex(
            (chk) => chk.label === data.auditPolicies[idx].policyName,
          ) === -1
        ) {
          optionsPolicyName.push({
            label: data.auditPolicies[idx].policyName,
            value: data.auditPolicies[idx].policyName,
          });
        }
        if (
          optionsCategories.findIndex(
            (chk) => chk.label === data.auditPolicies[idx].category,
          ) === -1
        ) {
          optionsCategories.push({
            label: data.auditPolicies[idx].category,
            value: data.auditPolicies[idx].category,
          });
        }
        if (
          optionsNist.findIndex(
            (chk) => chk.label === data.auditPolicies[idx].nist,
          ) === -1
        ) {
          optionsNist.push({
            label: data.auditPolicies[idx].nist,
            value: data.auditPolicies[idx].nist,
          });
        }
        if (
          optionsLevelOfEffort.findIndex(
            (chk) => chk.value === data.auditPolicies[idx].levelOfEffort,
          ) === -1
        ) {
          optionsLevelOfEffort.push({
            label: LevelOfEffortToString(data.auditPolicies[idx].levelOfEffort),
            value: data.auditPolicies[idx].levelOfEffort,
          });
        }
      }
      setPolicyNameFilterOptions(
        optionsPolicyName.sort((compA, compB) =>
          sortStringAscending(compA.label, compB.label),
        ),
      );
      setCategoryFilterOptions(
        optionsCategories.sort((compA, compB) =>
          sortStringAscending(compA.label, compB.label),
        ),
      );
      setNistFilterOptions(
        optionsNist.sort((compA, compB) =>
          sortStringAscending(compA.label, compB.label),
        ),
      );
      setLevelOfEffortFilterOptions(
        optionsLevelOfEffort.sort((compA, compB) =>
          sortNumberAscending(compA.label, compB.label),
        ),
      );
    }
  }, [data]);

  const handleMultiStringFilterChange = (
    property: keyof AnalysisInput,
    update: string[],
  ) => {
    setInput({
      ...input,
      [property]: update.map(String),
    });
  };

  const handleMultiNumericFilterChange = (
    property: keyof AnalysisInput,
    update: string[],
  ) => {
    setInput({
      ...input,
      [property]: update.map(Number),
    });
  };

  return (
    <FiltersContainer>
      <FiltersTitle>{'Analysis Report'}</FiltersTitle>
      <FiltersBody>
        <DropdownFilter
          label="Policy Name"
          options={policyNameFilterOptions}
          value={input.policyName || []}
          onChange={(update) =>
            handleMultiStringFilterChange('policyName', update)
          }
          style={styles.dropdown}
        />
        <DropdownFilter
          label="Category"
          options={categoryFilterOptions}
          value={input.category || []}
          onChange={(update) =>
            handleMultiStringFilterChange('category', update)
          }
          style={styles.dropdown}
        />
        <DropdownFilter
          label="NIST"
          options={nistFilterOptions}
          value={input.nist || []}
          onChange={(update) => handleMultiStringFilterChange('nist', update)}
          style={styles.dropdown}
        />
        <DropdownFilter
          label="Level of Effort"
          options={levelOfEffortFilterOptions}
          value={input.levelOfEffort || []}
          onChange={(update) =>
            handleMultiNumericFilterChange('levelOfEffort', update)
          }
          style={styles.dropdown}
        />
      </FiltersBody>
      <FiltersChipsContainer>
        <AnalysisFilterChips />
      </FiltersChipsContainer>
    </FiltersContainer>
  );
};

export default AnalysisFilters;
