import React from 'react';
import { Link } from 'react-router-dom';
import { Tag } from 'antd';
import { ColumnProps } from 'antd/lib/table';

import Table from '@totem/components/common/table/Table';
import { CVERecord, NVDReference } from '@totem/components/software/cves/types';
import { isNotNull } from '@totem/utilities/common';

type Props = {
  cve: CVERecord;
};
const CveReferencesTable = ({ cve }: Props) => {
  const columns: ColumnProps<NVDReference>[] = [
    {
      title: 'Link',
      dataIndex: 'url',
      key: 'url',
      render: (_, record: NVDReference) => (
        <Link to={record.url} target={'_blank'}>
          {record.url}
        </Link>
      ),
    },
    {
      title: 'Resources',
      dataIndex: 'tags',
      key: 'tags',
      render: (_, record: NVDReference) => (
        <>
          {isNotNull(record.tags) &&
            record.tags.map((tag) => (
              <Tag key={tag} color="#999999">
                {tag}
              </Tag>
            ))}
        </>
      ),
    },
  ];

  const getData = () => {
    if (
      typeof cve !== 'undefined' &&
      cve !== null &&
      cve.cve.raw_nvd_data !== null &&
      cve.cve.raw_nvd_data.references !== null
    ) {
      return cve.cve.raw_nvd_data.references;
    }
    return [];
  };

  return (
    <Table
      showSorterTooltip
      columns={columns}
      dataSource={getData()}
      loading={false}
      rowKey={(record) => record.url}
    />
  );
};

export default CveReferencesTable;
