import React, { ReactNode, useEffect, useState } from 'react';

import AssigneeDialog from '@totem/components/surveyV2/instances/AssigneeDialog';
import DateDialog from '@totem/components/surveyV2/instances/DateDialog';
import HealthDialog from '@totem/components/surveyV2/instances/HealthDialog';
import SurveyInstanceContext from '@totem/components/surveyV2/instances/SurveyInstancesContext';
import {
  SurveyInstanceConnectionInput,
  SurveyInstanceQueryResult,
} from '@totem/components/surveyV2/instances/types';
import { buildSurveyInstanceFilters } from '@totem/components/surveyV2/instances/utilities';
import {
  SurveyInstanceStatistics,
  SurveyQueryResult,
} from '@totem/components/surveyV2/types';
import { Params } from '@totem/types/common';
import { getToken } from '@totem/utilities/accountUtilities';
import { V2_SURVEY_ENDPOINT } from '@totem/utilities/endpoints';
import { omitNilOrEmpty } from '@totem/utilities/objectUtilities';

type Props = {
  children?: ReactNode;
  region?: string[];
  building?: string[];
};

const SurveyInstanceContainer = ({ children, region, building }: Props) => {
  const [input, updateInput] = useState<SurveyInstanceConnectionInput>({
    pageSize: 10,
    page: 1,
    sortField: 'name',
    sortDirection: '1',
    region: typeof region !== 'undefined' && region !== null ? region : [],
    building: typeof building !== 'undefined' && building !== null ? building : [],
    template: [],
    startDate: [],
    dueDate: [],
    submitted: [],
    isLaunched: [],
    health: [],
  });
  const [refreshData, setRefreshData] = useState<boolean>(true);
  const [refreshStatistics, setRefreshStatistics] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [data, setData] = useState<SurveyQueryResult[]>([]);
  const [statistics, setStatistics] = useState<SurveyInstanceStatistics>(null);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [action, setAction] = useState<string>('');
  const [selectedInstance, setSelectedInstance] =
    useState<SurveyQueryResult>(null);
  const [selectedForLaunch, setSelectedForLaunch] = useState<string[]>([]);

  const setInput = (updated: Partial<SurveyInstanceConnectionInput>) => {
    updateInput(omitNilOrEmpty({ ...input, ...updated }));
    setRefreshData(true);
  };

  const buildParameters = () => {
    const params: Params = {
      paging: {
        page: input.page,
        pageSize: input.pageSize,
      },
      sort: {
        field: input.sortField,
        direction: +input.sortDirection,
      },
      filters: buildSurveyInstanceFilters(input),
    };

    return params;
  };

  useEffect(() => {
    if (refreshData) {
      setRefreshData(false);

      setIsLoading(true);
      const params: Params = buildParameters();

      fetch(`${V2_SURVEY_ENDPOINT}`, {
        method: 'POST',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
        body: JSON.stringify(params),
      })
        .then((res) => res.json())
        .then((result: SurveyInstanceQueryResult) => {
          setData(result.surveys);
          setTotalRecords(result.paging.totalRecords);
          setRefreshStatistics(true);
        })
        .then(() => {
          setIsLoading(false);
        });
    }
  }, [refreshData]);

  useEffect(() => {
    if (refreshStatistics) {
      setRefreshStatistics(false);

      const params: Params = buildParameters();

      fetch(`${V2_SURVEY_ENDPOINT}/statistics`, {
        method: 'POST',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
        body: JSON.stringify(params),
      })
        .then((res) => res.json())
        .then((result: SurveyInstanceStatistics) => {
          setStatistics(result);
        });
    }
  }, [refreshStatistics]);

  const handleAction = (newAction: string, item: SurveyQueryResult) => {
    switch (newAction) {
      case 'show_health_update_dialog':
        setAction(newAction);
        setSelectedInstance(item);
        break;
      case 'show_launch_dialog':
        setAction(newAction);
        setSelectedInstance(item);
        break;
      case 'toggle_select_for_launch':
        if (!selectedForLaunch.includes(item.survey.id)) {
          setSelectedForLaunch([...selectedForLaunch, item.survey.id]);
        } else {
          const filteredList = selectedForLaunch.filter(
            (chk) => chk !== item.survey.id,
          );
          setSelectedForLaunch(filteredList);
        }
        break;
      case 'show_assignment_dialog':
        setAction(newAction);
        setSelectedInstance(item);
        break;
      case 'show_start_date_dialog':
        setAction(newAction);
        setSelectedInstance(item);
        break;
      case 'show_due_date_dialog':
        setAction(newAction);
        setSelectedInstance(item);
        break;
      case 'refresh':
        setAction('');
        setSelectedInstance(null);
        setRefreshData(true);
        break;
      default:
        setAction(newAction);
        setSelectedInstance(item);
    }
  };

  return (
    <SurveyInstanceContext.Provider
      value={{
        input,
        setInput,
        loading: isLoading,
        setIsLoading,
        data,
        totalRecords,
        action,
        selectedInstance,
        onAction: handleAction,
        selectedForLaunch,
        setSelectedForLaunch,
        statistics,
        setStatistics,
      }}
    >
      <div>{children}</div>
      {action === 'show_health_update_dialog' && selectedInstance !== null && (
        <HealthDialog />
      )}
      {action === 'show_assignment_dialog' && selectedInstance !== null && (
        <AssigneeDialog />
      )}
      {(action === 'show_start_date_dialog' ||
        action === 'show_due_date_dialog') &&
        selectedInstance !== null && <DateDialog />}
    </SurveyInstanceContext.Provider>
  );
};

export default SurveyInstanceContainer;
