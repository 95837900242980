import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { Card, Col, Row } from 'antd';

import { Drive } from '@totem/types/devices';

type Props = {
  drive: Drive;
};

const DriveCard = ({ drive }: Props) => {
  const labels = ['Free', 'Used'];
  const series = [drive.freeSpace, drive.size - drive.freeSpace];

  const getFreePercent = () => {
    if (drive.size === 0) {
      return '-';
    }
    return `${((drive.freeSpace / drive.size) * 100).toFixed(2)}%`;
  };

  const options: ApexCharts.ApexOptions = {
    chart: {
      type: 'donut',
      toolbar: { show: true, tools: { download: true } },
    },
    dataLabels: {
      enabled: false,
    },
    labels,
    colors: ['#4CBB17', '#EE4B2B'],
    legend: {
      width: 100,
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              show: true,
              showAlways: true,
              fontSize: '14px',
              label: drive.size > 0 ? 'Free' : '',
              formatter: getFreePercent,
            },
            value: {
              show: true,
              fontSize: '14px',
              fontWeight: 'bold',
              offsetY: -10,
            },
          },
        },
      },
    },
    responsive: [
      {
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
  };

  return (
    <Card style={{ width: '600px', marginBottom: '15px', marginRight: '15px' }}>
      <Row>
        <Col span={12}>
          <div>
            <b>Model: </b>
            {drive.model}
            <br />
            <b>Letter: </b>
            {drive.letter}
            <br />
            <b>File System: </b>
            {drive.fileSystem}
            <br />
            <b>Volume Name: </b>
            {drive.volumeName}
            <br />
            <b>Size: </b>
            {drive.size}
            <br />
            <b>Free Space: </b>
            {drive.freeSpace}
            <br />
          </div>
        </Col>
        <Col span={12}>
          <ReactApexChart
            options={options}
            series={series}
            labels={labels}
            type="donut"
            width="300px"
            height="300px"
          />
        </Col>
      </Row>
    </Card>
  );
};

export default DriveCard;
