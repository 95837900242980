import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import DetailSpinner from '@totem/components/common/DetailSpinner';
import PrimaryContentPane from '@totem/components/PrimaryContentPane';
import { getFlaggedDevices } from '@totem/selectors/nmapAnalysisSelectors';
import { Nmap, NmapDevice } from '@totem/types/nmap';
import api from '@totem/utilities/api';
import { NMAP_ENDPOINT } from '@totem/utilities/endpoints';

import NmapReportDetails from './NmapReportDetails';
import NmapReportScorecard from './NmapReportScorecard';

import './nmapDetail.css';

const NmapPrintedReportPage = () => {
  const { id, exportType, flaggedOnly } = useParams();
  // @ts-ignore
  const [nmap, setNmap] = useState<Nmap>({
    devices: [],
  });
  const [flaggedDevices, setFlaggedDevices] = useState<NmapDevice[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const exportTypeDecoded = decodeURIComponent(exportType);
  const flaggedOnlyDecoded = decodeURIComponent(flaggedOnly) === 'true';

  useEffect(() => {
    const getNmap = async () => {
      const { data } = await api.get(`${NMAP_ENDPOINT}/${id}`);

      setNmap(data);
      setFlaggedDevices(getFlaggedDevices(data.devices));
      setLoading(false);
    };

    getNmap();
  }, [id]);

  if (loading) {
    return (
      <PrimaryContentPane>
        <div styleName="spinner-container">
          <DetailSpinner />
        </div>
      </PrimaryContentPane>
    );
  }

  if (exportTypeDecoded === 'scorecard') {
    return (
      <NmapReportScorecard
        devices={flaggedOnlyDecoded ? flaggedDevices : nmap.devices}
      />
    );
  }

  if (exportTypeDecoded === 'details') {
    return (
      <NmapReportDetails
        title={flaggedOnlyDecoded ? 'Flagged Devices' : 'All Devices'}
        devices={flaggedOnlyDecoded ? flaggedDevices : nmap.devices}
        isReport
      />
    );
  }

  return <div />;
};

export default NmapPrintedReportPage;
