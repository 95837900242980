import React, { useContext } from 'react';

import FilterChip from '@totem/components/common/FilterChip';
import DeviceBackupContext from '@totem/components/deviceBackups/deviceBackupContext';
import { DeviceBackupConnectionInput } from '@totem/types/devices';
import { controlSystemTypeMap } from '@totem/utilities/controlSystemsUtilities';

const styles = {
  filterChip: {
    marginLeft: '2rem',
    marginTop: '2rem',
  },
};

const DeviceBackupFilterChips = () => {
  const { input, setInput, regionFilter, buildingFilter } =
    useContext(DeviceBackupContext);

  const { systemType } = input;

  const handleMultiFilterDelete = (
    property: keyof DeviceBackupConnectionInput,
    value: string,
  ) => {
    // @ts-ignore
    const updatedValue = input[property].filter((val) => val !== value);
    setInput({
      ...input,
      [property]: updatedValue.length ? updatedValue : null,
    });
  };

  const getRegionDisplay = (regionId: string) => {
    for (let idx = 0; idx < regionFilter.availableOptions.length; idx++) {
      if (regionFilter.availableOptions[idx].value === regionId) {
        return regionFilter.availableOptions[idx].name;
      }
    }
    return regionId;
  };

  const getBuildingDisplay = (buildingId: string) => {
    for (let idx = 0; idx < buildingFilter.availableOptions.length; idx++) {
      if (buildingFilter.availableOptions[idx].value === buildingId) {
        return buildingFilter.availableOptions[idx].name;
      }
    }
    return buildingId;
  };

  return (
    <>
      {systemType &&
        systemType.map((type) => {
          const label = controlSystemTypeMap[type]?.text;

          return (
            <FilterChip
              key={type}
              label={`Control System Type: ${label}`}
              style={styles.filterChip}
              onClose={() => handleMultiFilterDelete('systemType', type)}
            />
          );
        })}
      {!buildingFilter.loading &&
        typeof input !== 'undefined' &&
        typeof input.buildingId !== 'undefined' &&
        input.buildingId !== null &&
        input.buildingId.length > 0 &&
        input.buildingId.map((buildingValue) => (
          <FilterChip
            key={buildingValue}
            label={`Building: ${getBuildingDisplay(buildingValue)}`}
            style={styles.filterChip}
            onClose={() => buildingFilter.onRemove(buildingValue)}
          />
        ))}
      {!regionFilter.loading &&
        typeof input !== 'undefined' &&
        typeof input.regionId !== 'undefined' &&
        input.regionId !== null &&
        input.regionId.length > 0 &&
        input.regionId.map((regionValue) => (
          <FilterChip
            key={regionValue}
            label={`Region: ${getRegionDisplay(regionValue)}`}
            style={styles.filterChip}
            onClose={() => regionFilter.onRemove(regionValue)}
          />
        ))}
      {input.isBackupStale !== null &&
        input.isBackupStale.length > 0 &&
        input.isBackupStale.map((isStale) => (
          <FilterChip
            key={`isStale${input.isBackupStale}`}
            label={`Backup Status: ${isStale === 'true' ? 'Stale' : 'Current'}`}
            style={styles.filterChip}
            onClose={() => handleMultiFilterDelete('isBackupStale', isStale)}
          />
        ))}
      {input.lastBackupStatus !== null &&
        input.lastBackupStatus.length > 0 &&
        input.lastBackupStatus.map((lastStatus) => (
          <FilterChip
            key={lastStatus}
            label={`Last Backup Status: ${lastStatus}`}
            style={styles.filterChip}
            onClose={() =>
              handleMultiFilterDelete('lastBackupStatus', lastStatus)
            }
          />
        ))}
    </>
  );
};

export default DeviceBackupFilterChips;
