import React from 'react';
import { Link } from 'react-router-dom';
import { FileTextOutlined, SolutionOutlined } from '@ant-design/icons';
import { ColumnProps } from 'antd/lib/table';

import Table from '@totem/components/common/table/Table';
import { SurveyQueryResult } from '@totem/components/surveyV2/types';
import colors from '@totem/styles/colors';
import { isNotNull } from '@totem/utilities/common';
import { sortStringAscending } from '@totem/utilities/tableUtilities';
import { isUserDeactivated } from '@totem/utilities/userUtilities';

import '../survey.css';

type Props = {
  data: SurveyQueryResult[];
  loading: boolean;
};
const LaunchTable = ({ data, loading }: Props) => {
  const columns: ColumnProps<SurveyQueryResult>[] = [
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'survey.type',
      showSorterTooltip: true,
      render: (_, instance: SurveyQueryResult) => (
        <Link to={`/dashboard/surveyinstances/${instance.survey.id}`}>
          {instance.survey.type === 'policyAudit' && (
            <SolutionOutlined styleName={'table-icon'} title={'Policy Audit'} />
          )}
          {instance.survey.type !== 'policyAudit' && (
            <FileTextOutlined styleName={'table-icon'} title={'Survey'} />
          )}
        </Link>
      ),
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        sortStringAscending(compA.survey.type, compB.survey.type),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'survey.name',
      showSorterTooltip: true,
      render: (_, instance: SurveyQueryResult) => (
        <span>{instance.survey.name}</span>
      ),
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        sortStringAscending(compA.survey.name, compB.survey.name),
    },
    {
      title: 'Assignee',
      dataIndex: 'assignee',
      key: 'assignedUser.email',
      render: (_, instance: SurveyQueryResult) => {
        const isDeactivated = isUserDeactivated(instance.assignedUser);

        return (
          <div
            styleName="assignees-table-cell"
            style={isDeactivated ? { color: colors.neutral.inactive } : {}}
          >
            <div styleName="assignees">
              {isDeactivated
                ? `${instance.assignedUser.email} (Inactive)`
                : instance.assignedUser.email}
            </div>
          </div>
        );
      },
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        sortStringAscending(
          typeof compA.assignedUser !== 'undefined' &&
            compA.assignedUser !== null
            ? compA.assignedUser.email
            : '',
          typeof compB.assignedUser !== 'undefined' &&
            compB.assignedUser !== null
            ? compB.assignedUser.email
            : '',
        ),
    },
    {
      title: `Region`,
      dataIndex: 'region',
      key: 'region',
      render: (_, instance: SurveyQueryResult) => instance.region?.name,
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        sortStringAscending(
          typeof compA !== 'undefined' && compA !== null
            ? compA.region.name
            : '',
          typeof compB !== 'undefined' && compB !== null
            ? compB.region.name
            : '',
        ),
    },
    {
      title: 'Building',
      dataIndex: 'building',
      key: 'building',
      render: (_, instance: SurveyQueryResult) => instance.building?.name,
      sorter: (compA, compB) =>
        sortStringAscending(
          typeof compA !== 'undefined' && compA !== null
            ? compA.building.name
            : '',
          typeof compB !== 'undefined' && compB !== null
            ? compB.building.name
            : '',
        ),
    },
    {
      title: 'Template',
      dataIndex: 'template',
      key: 'template',
      render: (_, instance: SurveyQueryResult) => instance.template?.name,
      sorter: (compA, compB) =>
        sortStringAscending(
          typeof compA !== 'undefined' && compA !== null
            ? compA.template.name
            : '',
          typeof compB !== 'undefined' && compB !== null
            ? compB.template.name
            : '',
        ),
    },
  ];

  return (
    <Table
      showSorterTooltip
      columns={columns}
      dataSource={isNotNull(data) ? data : []}
      loading={loading}
      rowKey={(record) => record.survey.id}
    />
  );
};

export default LaunchTable;
