import React, { useContext, useState } from 'react';
import { DatePicker, Flex, Select } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
const { RangePicker } = DatePicker;
import EnergyContext from '@totem/components/energy/EnergyContext';
import {
  calculateAggregate,
  calculateDatesFromRange,
  getDefaultDateRange,
} from '@totem/components/energy/utilities';
const DateRangeInput = () => {
  const { input, setInput } = useContext(EnergyContext);
  const defaultRange = getDefaultDateRange({
    dateRangeType: input.dateRangeType,
    startDate: input.startDate,
    endDate: input.endDate,
  });
  const [dateRangeType, setDateRangeType] = useState<string>(
    input.dateRangeType,
  );
  const [dateRangeStartDate, setDateRangeStartDate] = useState<string>(
    defaultRange.startDate,
  );
  const [dateRangeEndDate, setDateRangeEndDate] = useState<string>(
    defaultRange.endDate,
  );
  const handleRangeTypeChange = (newValue: string) => {
    setDateRangeType(newValue);
    if (newValue !== 'Custom') {
      const newRange = calculateDatesFromRange({
        dateRangeType: newValue,
        startDate: dateRangeStartDate,
        endDate: dateRangeEndDate,
      });

      setDateRangeStartDate(newRange.startDate);
      setDateRangeEndDate(newRange.endDate);
      const aggregateInfo = calculateAggregate(newRange);

      setInput({
        ...input,
        startDate: newRange.startDate,
        endDate: newRange.endDate,
        dateRangeType: newValue,
        aggregate: aggregateInfo.aggregate,
      });
    }
  };

  const handleDateRangeChange = (dates: null | (Dayjs | null)[]) => {
    setDateRangeStartDate(dates[0].toISOString());
    setDateRangeEndDate(dates[1].toISOString());
    setDateRangeType('Custom');
  };

  const handleOpenChanged = (isOpen: boolean) => {
    if (!isOpen) {
      const aggregateInfo = calculateAggregate({
        startDate: dateRangeStartDate,
        endDate: dateRangeEndDate,
        dateRangeType: 'Custom',
      });
      setInput({
        ...input,
        startDate: dateRangeStartDate,
        endDate: dateRangeEndDate,
        dateRangeType: 'Custom',
        aggregate: aggregateInfo.aggregate,
      });
    }
  };

  const dateRangeTypes = [
    {
      label: 'Today',
      value: 'Today',
    },
    {
      label: 'Last 7 Days',
      value: 'Last 7 Days',
    },
    {
      label: 'Last 4 Weeks',
      value: 'Last 4 Weeks',
    },
    {
      label: 'Last 3 Months',
      value: 'Last 3 Months',
    },
    {
      label: 'Last 12 Months',
      value: 'Last 12 Months',
    },
    {
      label: 'Month to Date',
      value: 'Month to Date',
    },
    {
      label: 'Quarter to Date',
      value: 'Quarter to Date',
    },
    {
      label: 'Year to Date',
      value: 'Year to Date',
    },
    {
      label: 'Custom',
      value: 'Custom',
    },
  ];

  return (
    <Flex vertical={false}>
      <Select
        defaultValue="Today"
        value={dateRangeType}
        options={dateRangeTypes}
        onChange={handleRangeTypeChange}
        style={{ width: '140px' }}
      />
      <RangePicker
        value={[dayjs(dateRangeStartDate), dayjs(dateRangeEndDate)]}
        onChange={handleDateRangeChange}
        onOpenChange={handleOpenChanged}
      />
    </Flex>
  );
};

export default DateRangeInput;
