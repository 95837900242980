import React, { useContext, useEffect, useState } from 'react';
import Timeline from 'react-calendar-timeline';

import RemoteAccessUserDetailsContext from '@totem/components/remoteAccess/userDetails/RemoteAccessUserDetailsContext';

// make sure you include the timeline stylesheet or the timeline will not be styled
import 'react-calendar-timeline/lib/Timeline.css';

type chartGroup = {
  title: string;
  items: chartItem[];
};

type chartItem = {
  title: string;
  startTime: Date;
  endTime: Date;
};

const colors = [
  '#4682B4',
  '#ADD8E6',
  '#87CEFA',
  '#6495ED',
  '#00BFFF',
  '#1E90FF',
  '#4169E1',
  '#0000FF',
  '#0000CD',
  '#191970',
];

const addItemToGroup = (
  groups: chartGroup[],
  group: string,
  point: chartItem,
) => {
  let found = false;
  for (let idx = 0; idx < groups.length; idx++) {
    if (groups[idx].title === group) {
      groups[idx].items.push(point);
      found = true;
    }
  }
  if (!found) {
    groups.push({ title: group, items: [point] });
  }

  return groups;
};

const CalendarTimeline = () => {
  const { sessionData, input } = useContext(RemoteAccessUserDetailsContext);
  const [chartGroups, setChartGroups] = useState<any>([]);
  const [chartItems, setChartItems] = useState<any>([]);

  useEffect(() => {
    let data: chartGroup[] = [];
    for (
      let sessionIdx = 0;
      sessionIdx < sessionData.sessions.length;
      sessionIdx++
    ) {
      // eslint-disable-next-line max-depth
      if (sessionData.sessions[sessionIdx].endTime !== null) {
        const group = `${sessionData.sessions[sessionIdx].groupDescription}`;
        const startTime = new Date(sessionData.sessions[sessionIdx].startTime);
        const endTime = new Date(sessionData.sessions[sessionIdx].endTime);

        const point = {
          title: group,
          startTime,
          endTime,
        };
        data = addItemToGroup(data, group, point);
      }
    }

    const chartGroupsData = [];
    const chartItemsData = [];
    let itemCount = 1;
    let colorIndex = 0;
    for (let grpIdx = 0; grpIdx < data.length; grpIdx++) {
      chartGroupsData.push({ id: grpIdx + 1, title: data[grpIdx].title });
      for (let itemIdx = 0; itemIdx < data[grpIdx].items.length; itemIdx++) {
        chartItemsData.push({
          id: itemCount,
          group: grpIdx + 1,
          title: `${data[grpIdx].items[
            itemIdx
          ].startTime.toLocaleString()} - ${data[grpIdx].items[
            itemIdx
          ].endTime.toLocaleString()}`,
          start_time: data[grpIdx].items[itemIdx].startTime,
          end_time: data[grpIdx].items[itemIdx].endTime,
          itemProps: {
            style: {
              background: colors[colorIndex],
            },
          },
        });
        itemCount = itemCount + 1;
        colorIndex = colorIndex + 1;
        if (colorIndex > 9) {
          colorIndex = 0;
        }
      }
    }

    setChartGroups(chartGroupsData);
    setChartItems(chartItemsData);
  }, [sessionData]);

  return (
    <div>
      {chartGroups.length > 0 && chartItems.length > 0 && (
        <Timeline
          sidebarWidth={250}
          groups={chartGroups}
          items={chartItems}
          defaultTimeStart={new Date(input.startTime)}
          defaultTimeEnd={new Date(input.endTime)}
        />
      )}
    </div>
  );
};

export default CalendarTimeline;
