import { VulnerabilityReportInput } from '@totem/components/software/vulnerabilityReport/types';
import { Filter, Params } from '@totem/types/common';

export const buildVulnerabilityReportFilters = (
  input: VulnerabilityReportInput,
) => {
  const paramFilters: Filter[] = [];

  if (
    typeof input.regionId !== 'undefined' &&
    input.regionId !== null &&
    input.regionId.length > 0
  ) {
    paramFilters.push({
      field: 'regionId',
      values: input.regionId,
    });
  }
  if (
    typeof input.buildingId !== 'undefined' &&
    input.buildingId !== null &&
    input.buildingId.length > 0
  ) {
    paramFilters.push({
      field: 'buildingId',
      values: input.buildingId,
    });
  }
  if (
    typeof input.controlSystemId !== 'undefined' &&
    input.controlSystemId !== null &&
    input.controlSystemId.length > 0
  ) {
    paramFilters.push({
      field: '_id',
      values: input.controlSystemId,
    });
  }
  if (
    typeof input.deviceId !== 'undefined' &&
    input.deviceId !== null &&
    input.deviceId.length > 0
  ) {
    paramFilters.push({
      field: 'deviceId',
      values: input.deviceId,
    });
  }

  if (
    typeof input.packageName !== 'undefined' &&
    input.packageName !== null &&
    input.packageName.length > 0
  ) {
    paramFilters.push({
      field: 'packageName',
      values: input.packageName,
    });
  }
  if (
    typeof input.packageVersion !== 'undefined' &&
    input.packageVersion !== null &&
    input.packageVersion.length > 0
  ) {
    paramFilters.push({
      field: 'packageVersion',
      values: input.packageVersion,
    });
  }
  if (
    typeof input.packagePublisher !== 'undefined' &&
    input.packagePublisher !== null &&
    input.packagePublisher.length > 0
  ) {
    paramFilters.push({
      field: 'packagePublisher',
      values: input.packagePublisher,
    });
  }
  if (
    typeof input.vulnerability !== 'undefined' &&
    input.vulnerability !== null &&
    input.vulnerability.length > 0
  ) {
    paramFilters.push({
      field: 'vulnerability',
      values: input.vulnerability,
    });
  }

  return paramFilters;
};

export const buildParameters = (input: VulnerabilityReportInput) => {
  const params: Params = {
    paging: {
      page: input.page,
      pageSize: input.pageSize,
    },
    sort: {
      field: input.sortField,
      direction: +input.sortDirection,
    },
    filters: buildVulnerabilityReportFilters(input),
  };

  if (
    typeof params.sort.field === 'undefined' ||
    params.sort.field === null ||
    params.sort.field === ''
  ) {
    params.sort.field = 'packageName';
  }

  return params;
};
