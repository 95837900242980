import * as React from 'react';
import * as R from 'ramda';

import { Criticality } from '@totem/types/criticality';
import { QuestionOption, SurveyQuestion } from '@totem/types/survey';
import criticalityUtilities from '@totem/utilities/criticalityUtilities';

import './score.css';

type Props = {
  question: SurveyQuestion;
  showRecommendations: boolean;
  report?: boolean;
};
const ResponseQuestionRow = ({
  question,
  showRecommendations,
  report,
}: Props) => {
  const answeredOption = question.options.find(
    (option: QuestionOption): boolean => option.label === question.value.value,
  );

  const criticality = answeredOption
    ? answeredOption.criticality
    : Criticality.Info;

  return (
    <div
      styleName={`response-questions-row-container ${report ? 'report' : ''}`}
    >
      <div
        styleName={`assessment-section question ${
          showRecommendations ? '' : 'full'
        } ${report ? 'report' : ''}`}
      >
        <div
          styleName={`criticality-badge-container ${report ? 'report' : ''}`}
        >
          {criticalityUtilities.getCriticalityBadge(criticality)}
        </div>
        <div styleName="response-question-label">{question.label}</div>
        <div styleName="response-question-value">{question.value.value}</div>
        <div>{question.additionalContext}</div>
      </div>
      {showRecommendations && !R.isNil(answeredOption) && (
        <div
          styleName={`recommendations-section question ${
            report ? 'report' : ''
          }`}
        >
          {answeredOption.recommendation}
        </div>
      )}
    </div>
  );
};

export default ResponseQuestionRow;
