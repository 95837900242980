import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AutoComplete, Input } from 'antd';

import { requestRemoteAccessOrgRoles } from '@totem/actions/remoteAccessUserActions';
import UserProfileContext from '@totem/components/UserProfileContext';
import { usePreviousValue } from '@totem/hooks/usePreviousValue';
import colors from '@totem/styles/colors';
import fonts from '@totem/styles/fonts';
import { ReduxStore, RemoteAccessOrgRolesState } from '@totem/types/store';
import { isMemberOf } from '@totem/utilities/userUtilities';

export interface Props {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  style?: React.CSSProperties;
  allowClear?: boolean;
  disabled?: boolean;
  limit?: number;
}

const styles = {
  input: {
    width: '100%',
  },
  notFound: {
    color: colors.neutral.gray,
    fontSize: fonts.fontMd,
  },
};

const getData = (emails: string[]) =>
  emails.map((email) => ({
    id: email,
    value: email,
  }));

const OrgRoleAutoComplete = ({
  value,
  onChange,
  style,
  allowClear,
  disabled,
  placeholder = '',
}: Props) => {
  const dispatch = useDispatch();
  const remoteAccessOrgRoles = useSelector<
    ReduxStore,
    RemoteAccessOrgRolesState
  >((state) => state.remoteAccessOrgRoles);
  const { userProfile } = useContext(UserProfileContext);

  const [open, setOpen] = useState<boolean>(false);
  const [roleName, setRoleName] = useState<string>(value || '');
  const [results, setResults] = useState<string[]>([]);
  const [availableRoles, setAvailableRoles] = useState<string[]>([]);

  const previousValue = usePreviousValue<string>(value);

  const validateRole = (roleNameInput: string): boolean => {
    return roleNameInput !== '';
  };

  const handleSearch = (search: string) => {
    const newOptions = availableRoles.filter(
      (roleSearch: string) => roleSearch.indexOf(search) >= 0,
    );
    setResults(newOptions);
    setRoleName(search);
  };

  const handleSelect = (selection: string) => {
    onChange(selection);
    setRoleName(selection);
  };

  useEffect(() => {
    const getAvailableRoles = () => {
      let roleOptions = [];

      if (remoteAccessOrgRoles !== null) {
        if (remoteAccessOrgRoles.authTenant !== null) {
          roleOptions = remoteAccessOrgRoles.authTenant.roles
            .filter((role) => {
              return isMemberOf(userProfile, 'remote_access_admin', {
                regionId: role.regionId,
                buildingId: role.buildingId,
                controlSystemId: role.controlSystemId,
              });
            })
            .map((role) => {
              return role.name;
            });
        }
        return roleOptions;
      }

      return [];
    };

    if (remoteAccessOrgRoles === null) {
      dispatch(requestRemoteAccessOrgRoles());
    }

    setAvailableRoles(getAvailableRoles());

    if (!value) {
      setRoleName('');
    }
  }, [dispatch, remoteAccessOrgRoles, userProfile, value]);

  useEffect(() => {
    if (!previousValue && value) {
      setRoleName(value);
    }
  }, [value, previousValue]);

  const getNotFoundContent = () => {
    return roleName && validateRole(roleName) ? (
      <div
        style={styles.notFound}
        onClick={() => {
          setOpen(false);
          onChange(roleName);
        }}
      >
        Looks like this person isn't a remote access user yet.
      </div>
    ) : null;
  };

  return (
    <AutoComplete
      open={open}
      options={getData(results)}
      style={{ ...styles.input, ...style }}
      value={value || ''}
      onChange={onChange}
      onSelect={handleSelect}
      onSearch={handleSearch}
      placeholder={placeholder}
      allowClear={allowClear ?? false}
      disabled={disabled ?? false}
      notFoundContent={getNotFoundContent()}
      onDropdownVisibleChange={setOpen}
    >
      <Input.Search size="large" placeholder="input here" enterButton />
    </AutoComplete>
  );
};

export default OrgRoleAutoComplete;
