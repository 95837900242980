import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import BuildingContainer from '@totem/components/buildingDetail/container/BuildingContainer';
import BuildingTabs from '@totem/components/buildingDetail/container/BuildingTabs';
import DetailSpinner from '@totem/components/common/DetailSpinner';
import ControlSystemReportContainer from '@totem/components/controlSystemReport/ControlSystemReportContainer';
import { GET_BUILDING } from '@totem/graph/building';
import { Building } from '@totem/types/building';
import addressUtilities from '@totem/utilities/addressUtilities';

import ContentLayout from '../ContentLayout';

import Sidebar from './sidebar/Sidebar';
import BuildingDetailContext from './BuildingDetailContext';

import './buildingDetail.css';

const BuildingDetail = () => {
  const { id } = useParams();

  const { loading, refetch, error, data } = useQuery<{ building: Building }>(
    GET_BUILDING,
    {
      variables: {
        buildingId: id,
        policyAuditsInput: { buildingId: [id] },
      },
    },
  );

  if (loading || error || !data) {
    return (
      <div styleName="spinner-container">
        <DetailSpinner />
      </div>
    );
  }

  const address = addressUtilities.getAddress(data.building);

  return (
    <ContentLayout
      pageTitle={data.building.name}
      pageSubtitle={address}
      contentStyle={{ padding: 0 }}
    >
      <BuildingDetailContext.Provider
        value={{ refetch, loading, building: data.building }}
      >
        <BuildingContainer id={id} onDataRefreshRequested={() => {}}>
          <div styleName="content">
            <div styleName="content-container">
              <Sidebar />
              <div styleName="main-pane">
                <ControlSystemReportContainer
                  staticFilters={{ buildingId: [id] }}
                >
                  <BuildingTabs />
                </ControlSystemReportContainer>
              </div>
            </div>
          </div>
        </BuildingContainer>
      </BuildingDetailContext.Provider>
    </ContentLayout>
  );
};

export default BuildingDetail;
