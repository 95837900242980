import React from 'react';

import AgreementContainer from '@totem/components/agreements/AgreementReport/AgreementContainer';
import AgreementTable from '@totem/components/agreements/AgreementReport/AgreementTable';
import BasePane from '@totem/components/BasePane';
import ContentLayout from '@totem/components/ContentLayout';
import PrimaryContentPane from '@totem/components/PrimaryContentPane';
const AgreementsPage = () => {
  return (
    <ContentLayout>
      <BasePane>
        <PrimaryContentPane>
          <AgreementContainer>
            <AgreementTable />
          </AgreementContainer>
        </PrimaryContentPane>
      </BasePane>
    </ContentLayout>
  );
};

export default AgreementsPage;
