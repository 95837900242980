import { createContext } from 'react';

import { StatisticsItem } from '@totem/components/assetInventory/statistics/types';

interface Context {
  loading: boolean;
  statistics: StatisticsItem;
}

export default createContext<Context>({
  loading: false,
  statistics: null,
});
