import React, { useCallback, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Button, Form, Input, Modal } from 'antd';

const FormItem = Form.Item;

import ModalFooter from '@totem/components/ModalFooter';
import ModalTitle from '@totem/components/ModalTitle';
import { GET_USER, UPDATE_USER } from '@totem/graph/user';
import accountUtilities from '@totem/utilities/accountUtilities';

import './profile.css';

const formItemLayout = {
  labelCol: {
    xs: { span: 16 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const ProfileUpdateModal = () => {
  const token = accountUtilities.getAccountPayloadSync();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [errors, setErrors] = useState({
    firstName: '',
    lastName: '',
  });
  const [visible, setVisible] = useState(false);

  useQuery(GET_USER, {
    variables: {
      // TODO: Remove @ts-ignore : Token
      // @ts-ignore
      id: token.id,
    },
    onCompleted: (data) => {
      const fname = data?.user?.firstName;
      const lname = data?.user?.lastName;

      if (!fname || fname === 'New' || !lname || lname === 'User') {
        setVisible(true);
        setFirstName(fname);
        setLastName(lname);
      }
    },
  });

  const [updateUser, { loading: updateLoading }] = useMutation(UPDATE_USER);

  const isValidForm = useCallback(() => {
    let isValid = true;
    const updatedErrors = {
      ...errors,
    };
    if (!firstName || firstName === 'New') {
      updatedErrors.firstName = 'Please enter a first name other than "New"';
      isValid = false;
    } else {
      updatedErrors.firstName = '';
    }

    if (!lastName || lastName === 'User') {
      updatedErrors.lastName = 'Please enter a last name other than "User';
      isValid = false;
    } else {
      updatedErrors.lastName = '';
    }

    setErrors(updatedErrors);
    return isValid;
  }, [errors, firstName, lastName]);

  const onOk = useCallback(async () => {
    if (isValidForm()) {
      await updateUser({
        variables: {
          input: {
            // TODO: Remove @ts-ignore : Token
            // @ts-ignore
            id: token.id,
            firstName,
            lastName,
          },
        },
      });

      setVisible(false);
    }
    // TODO: Remove @ts-ignore : Token
    // @ts-ignore
  }, [firstName, isValidForm, lastName, token.id, updateUser]);

  return (
    <Modal
      title={<ModalTitle>Update Your Name</ModalTitle>}
      open={visible}
      confirmLoading={false}
      onCancel={() => setVisible(false)}
      footer={
        <ModalFooter>
          <Button onClick={() => setVisible(false)} loading={updateLoading}>
            Cancel
          </Button>
          <Button type="primary" loading={updateLoading} onClick={onOk}>
            Update
          </Button>
        </ModalFooter>
      }
    >
      <div styleName="modal-content-container">
        <div styleName="modal-heading">
          Either your account was created on your behalf or you never had a
          chance to update your name with us. Please update your name before
          proceeding.
        </div>
        <div styleName="modal-form-container">
          <Form {...formItemLayout} layout="horizontal" autoComplete="off">
            <FormItem
              label="First Name"
              colon={false}
              validateStatus={!errors.firstName ? 'success' : 'error'}
              help={!errors.firstName ? null : errors.firstName}
            >
              <Input
                value={firstName}
                onChange={(event) => setFirstName(event.target.value)}
              />
            </FormItem>
            <FormItem
              label="Last Name"
              colon={false}
              validateStatus={!errors.lastName ? 'success' : 'error'}
              help={!errors.lastName ? null : errors.lastName}
            >
              <Input
                value={lastName}
                onChange={(event) => setLastName(event.target.value)}
              />
            </FormItem>
          </Form>
        </div>
      </div>
    </Modal>
  );
};

export default ProfileUpdateModal;
