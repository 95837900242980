import React, { useCallback, useContext, useState } from 'react';
import { Input, Space } from 'antd';

import { debounce } from '@totem/utilities/debounce';
const { Search } = Input;

import ContactSearchContext from '@totem/components/contactSearch/contactSearchContext';

const DEBOUNCE_TIME = 500;

const SearchInput = () => {
  const { input, setInput } = useContext(ContactSearchContext);
  const [searchTerm, setSearchTerm] = useState<string>('');

  // const handleChange = (newSearchTerm: string) => {
  //   setInput({ search_term: newSearchTerm });
  // };

  const sendChange = useCallback(
    debounce(
      (newSearchTerm: string) =>
        setInput({ ...input, search_term: newSearchTerm }),
      DEBOUNCE_TIME,
    ),
    [],
  );

  const handleChange = (newSearchTerm: string) => {
    setSearchTerm(newSearchTerm);
    sendChange(newSearchTerm);
  };

  return (
    <Space direction={'horizontal'}>
      <Search
        placeholder="name / email"
        value={searchTerm}
        onChange={evt =>
          evt.target.value !== null && evt.target.value.trim() !== ''
            ? handleChange(evt.target.value)
            : handleChange('')
        }
        style={{ width: 200 }}
      />
    </Space>
  );
};

export default SearchInput;
