import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Col, Row } from 'antd';

import ComponentHeader from '@totem/components/ComponentHeader';
import DeviceSoftwareEndOfSupportContext from '@totem/components/devices/softwareEndOfLife/summary/DeviceSoftwareEndOfSupportContext';
import EndOfSupportChart from '@totem/components/devices/softwareEndOfLife/summary/EndOfSupportChart';
import TotalValue from '@totem/components/devices/softwareEndOfLife/summary/TotalValue';
import PageSection from '@totem/components/PageSection';
import PageSectionBody from '@totem/components/PageSectionBody';
import colors from '@totem/styles/colors';
import IBDIcon from '@totem/styles/Icon';
import { isNotNull } from '@totem/utilities/common';

const styles = {
  borderLeft: {
    borderLeftColor: colors.neutral.silver,
    borderLeftStyle: 'solid' as 'solid',
    borderLeftWidth: 1,
  },
  container: {
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  selectable: {
    cursor: 'pointer',
  },
  valueSmaller: {
    fontSize: '20px',
    paddingBottom: '5px',
  },
  valueEmphasis: {
    fontSize: '36px',
    fontWeight: 700,
  },
  icon: {
    fontSize: '36px',
  },
};

const DeviceSoftwareEndOfLifeSummaryLayout = () => {
  const { data } = useContext(DeviceSoftwareEndOfSupportContext);

  return (
    <>
      {isNotNull(data) &&
        (data.endOfSupport > 0 || data.futureEndOfSupportTotals > 0) && (
          <PageSection>
            <ComponentHeader title={'Software End of Support'} />
            <PageSectionBody>
              <Row style={styles.container}>
                <Col span={6}>
                  <TotalValue
                    title={
                      <span>
                        Past
                        <br />
                        End-of-Support
                      </span>
                    }
                    icon={<IBDIcon name="EndOfSupport" style={styles.icon} />}
                    value={
                      <Link to={'/dashboard/devices/software/endOfSupport'}>
                        <span style={styles.valueEmphasis}>
                          {data.endOfSupport}
                        </span>
                      </Link>
                    }
                  />
                </Col>
                <Col span={6} style={styles.borderLeft}>
                  <TotalValue
                    title={
                      <span>
                        Near
                        <br />
                        End-of-Support
                      </span>
                    }
                    icon={<IBDIcon name="EndOfSupport" style={styles.icon} />}
                    value={
                      <Link to={'/dashboard/devices/software/endOfSupport'}>
                        <span style={styles.valueEmphasis}>
                          {data.futureEndOfSupportTotals}
                        </span>
                      </Link>
                    }
                  />
                </Col>
                <Col span={12} style={styles.borderLeft}>
                  <EndOfSupportChart />
                </Col>
              </Row>
            </PageSectionBody>
          </PageSection>
        )}
    </>
  );
};

export default DeviceSoftwareEndOfLifeSummaryLayout;
