import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { FileTextOutlined, SolutionOutlined } from '@ant-design/icons';
import { ColumnProps } from 'antd/lib/table';

import Table from '@totem/components/common/table/Table';
import { WorkItem } from '@totem/components/surveyV2/work/types';
import WorkContext from '@totem/components/surveyV2/work/WorkContext';
import { getDashboardPath } from '@totem/utilities/authUtilities';
import { isNotNull } from '@totem/utilities/common';
import {
  sortNumberAscending,
  sortStringAscending,
} from '@totem/utilities/tableUtilities';

import '../survey.css';

const WorkTableIncomplete = () => {
  const { data, loading } = useContext(WorkContext);
  const basePath = getDashboardPath();

  const getData = (): WorkItem[] => {
    if (isNotNull(data)) {
      const inProgress = data.filter((chk) => chk.isSubmitted === false);
      return inProgress;
    }
    return [];
  };

  const columns: ColumnProps<WorkItem>[] = [
    {
      title: '',
      dataIndex: 'type',
      key: 'type',
      showSorterTooltip: true,
      render: (_, record: WorkItem) => (
        <span>
          {record.type === 'policyAudit' && (
            <SolutionOutlined styleName={'table-icon'} title={'Policy Audit'} />
          )}
          {record.type !== 'policyAudit' && (
            <FileTextOutlined styleName={'table-icon'} title={'Survey'} />
          )}
        </span>
      ),
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) => sortStringAscending(compA.type, compB.type),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      showSorterTooltip: true,
      render: (_, record: WorkItem) => (
        <span>
          {record.source === 'policyAudit' && (
            <Link to={`${basePath}/policyaudits/${record.id}`}>
              {record.name}
            </Link>
          )}
          {record.source !== 'policyAudit' && (
            <Link to={`${basePath}/surveyinstances/${record.id}`}>
              {record.name}
            </Link>
          )}
        </span>
      ),
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) => sortStringAscending(compA.name, compB.name),
    },
    {
      title: 'Building',
      dataIndex: 'building',
      key: 'building',
      render: (_, record: WorkItem) => record.building?.name,
      sorter: (compA, compB) =>
        sortStringAscending(
          typeof compA !== 'undefined' && compA !== null
            ? compA.building.name
            : '',
          typeof compB !== 'undefined' && compB !== null
            ? compB.building.name
            : '',
        ),
    },
    {
      title: 'Control System',
      dataIndex: 'controlSystem',
      key: 'controlSystem',
      render: (_, record: WorkItem) => record.controlSystem?.name,
      sorter: (compA, compB) =>
        sortStringAscending(
          typeof compA !== 'undefined' && compA !== null
            ? compA.controlSystem.name
            : '',
          typeof compB !== 'undefined' && compB !== null
            ? compB.controlSystem.name
            : '',
        ),
    },
    {
      title: 'Total Questions',
      dataIndex: 'totalQuestions',
      key: 'totalQuestions',
      render: (_, record: WorkItem) => record.totalQuestions,
      sorter: (compA, compB) =>
        sortNumberAscending(
          typeof compA !== 'undefined' && compA !== null
            ? compA.totalQuestions
            : 0,
          typeof compB !== 'undefined' && compB !== null
            ? compB.totalQuestions
            : 0,
        ),
    },
    {
      title: 'Completed Questions',
      dataIndex: 'completedQuestions',
      key: 'completedQuestions',
      render: (_, record: WorkItem) => record.completedQuestions,
      sorter: (compA, compB) =>
        sortNumberAscending(
          typeof compA !== 'undefined' && compA !== null
            ? compA.completedQuestions
            : 0,
          typeof compB !== 'undefined' && compB !== null
            ? compB.completedQuestions
            : 0,
        ),
    },
  ];

  return (
    <Table
      showSorterTooltip
      columns={columns}
      dataSource={getData()}
      loading={loading}
      rowKey={(record) => record.id}
    />
  );
};

export default WorkTableIncomplete;
