import React, { useState } from 'react';
import { Select } from 'antd';

import BasePane from '@totem/components/BasePane';
import ContentLayout from '@totem/components/ContentLayout';
import PrimaryContentPane from '@totem/components/PrimaryContentPane';
import AgingChartApex from '@totem/components/ticketing/agingReport/AgingChartApex';
import AgingFilters from '@totem/components/ticketing/agingReport/AgingFilters';
import TicketAgingFilterChips from '@totem/components/ticketing/agingReport/FilterChips';
import TicketAgingContainer from '@totem/components/ticketing/agingReport/TicketAgingContainer';
import TicketingDrillDownReport from '@totem/components/ticketing/agingReport/TicketingDrillDownReport';
import { TICKETS_ENDPOINT } from '@totem/utilities/endpoints';

const AgingReportPage = () => {
  const [reportType, setReportType] = useState<string>('updated');

  return (
    <ContentLayout
      pageTitle="Ticket Aging"
      contentRight={
        <Select
          value={reportType}
          onChange={(newValue: string) => setReportType(newValue)}
        >
          <Select.Option value="updated">Days without update</Select.Option>
          <Select.Option value="created">Days outstanding</Select.Option>
        </Select>
      }
    >
      <BasePane>
        <PrimaryContentPane>
          <TicketAgingContainer
            reportType={reportType}
            ticketChartEndPoint={`${TICKETS_ENDPOINT}/ticketAging/${reportType}`}
            ticketReportEndPoint={`${TICKETS_ENDPOINT}/ticketAging/summary/${reportType}`}
          >
            <AgingFilters />
            <TicketAgingFilterChips />
            <br />
            <br />
            <AgingChartApex />
            <TicketingDrillDownReport />
          </TicketAgingContainer>
        </PrimaryContentPane>
      </BasePane>
    </ContentLayout>
  );
};

export default AgingReportPage;
