import React, { useCallback, useContext, useState } from 'react';
import { Button, Form, Input, Modal } from 'antd';

import { EMPTY_ID } from '@totem/components/common/reference/ReferenceSelector';
import ModalFooter from '@totem/components/ModalFooter';
import ModalTitle from '@totem/components/ModalTitle';
import TenantsContext from '@totem/components/tenants/tenantsPage/TenantsContext';
import { Tenant } from '@totem/components/tenants/types';
import UserProfileContext from '@totem/components/UserProfileContext';
import { getToken } from '@totem/utilities/accountUtilities';
import { TENANTS_ENDPOINT } from '@totem/utilities/endpoints';
const FormItem = Form.Item;

const styles = {
  form: {
    width: '100%',
  },
  formItem: {
    paddingBottom: '0',
    marginBottom: '1rem',
  },
};

type Props = {
  open: boolean;
  onClose: () => void;
};

const TenantAddDialog = ({ open, onClose }: Props) => {
  const { userProfile } = useContext(UserProfileContext);
  const [isSending, setIsSending] = useState<boolean>(false);
  const [tenantName, setTenantName] = useState<string>('');

  const { onAction } = useContext(TenantsContext);

  const sendTenantCreate = useCallback(
    async (payload: Tenant) => {
      if (isSending) {
        return;
      }
      setIsSending(true);

      fetch(`${TENANTS_ENDPOINT}`, {
        method: 'PUT',
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getToken()}`,
        }),
        body: JSON.stringify(payload),
      }).then(() => {
        onClose();
        setIsSending(false);
        onAction('refresh', null);
      });
    },
    [isSending],
  );

  const handleSubmit = () => {
    const payload: Tenant = {
      id: EMPTY_ID,
      name: tenantName,
      organizationId: userProfile.organization.id,
      buildings: [],
      users: [],
    };

    sendTenantCreate(payload);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={<ModalTitle>Add Tenant</ModalTitle>}
      width={'40%'}
      footer={
        <ModalFooter>
          <Button onClick={onClose}>Close</Button>
          <Button type="primary" onClick={handleSubmit}>
            Save
          </Button>
        </ModalFooter>
      }
    >
      <Form layout="vertical" style={styles.form}>
        <FormItem label="Name" colon={false} style={styles.formItem}>
          <Input
            placeholder="Name"
            value={tenantName}
            onChange={(evt) => setTenantName(evt.target.value)}
          />
        </FormItem>
      </Form>
    </Modal>
  );
};

export default TenantAddDialog;
