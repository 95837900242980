import React, { useState } from 'react';
import { DownloadOutlined } from '@ant-design/icons';
import { Button } from 'antd';

import { UsersConnectionInput } from '@totem/types/user';
import api from '@totem/utilities/api';
import { USERS_ENDPOINT } from '@totem/utilities/endpoints';
import fileUtilities from '@totem/utilities/fileUtilities';

interface Props {
  input: UsersConnectionInput;
}

const UsersExport = ({ input }: Props) => {
  const [loading, setLoading] = useState<boolean>(false);

  const exportUsers = async () => {
    setLoading(true);
    const { data } = await api.post(`${USERS_ENDPOINT}/export`, input);
    fileUtilities.downloadFile('users', data);
    setLoading(false);
  };

  return (
    <Button
      type="default"
      icon={<DownloadOutlined />}
      onClick={exportUsers}
      loading={loading}
    >
      Export CSV
    </Button>
  );
};

export default UsersExport;
