import React from 'react';
import { Link } from 'react-router-dom';
import { Card, Col, Row } from 'antd';

import CveCard from '@totem/components/software/cves/cveCard';
import CveInformation from '@totem/components/software/cves/cveInformation';
import CveTitle from '@totem/components/software/cves/cveTitle';
import CveVersions from '@totem/components/software/cves/cveVersions';
import CvssTabs from '@totem/components/software/cves/cvssTabs';
import { CVERecord } from '@totem/components/software/cves/types';

import './cve.css';

type Props = {
  cve: CVERecord;
};

const CvePanelSmall = ({ cve }: Props) => {
  return (
    <Card
      styleName="cve-card-row"
      title={
        <Link to={`/dashboard/software/cve/${cve.id}`} target={'_blank'}>
          {cve.id}
        </Link>
      }
    >
      <Row gutter={[20, 0]}>
        <Col span={18}>
          <CveCard>
            <CveTitle cve={cve} />
          </CveCard>

          <CveCard>
            <CvssTabs cve={cve} />
          </CveCard>
        </Col>
        <Col span={6}>
          <CveCard>
            <CveInformation cve={cve} />
          </CveCard>

          <CveCard>
            <CveVersions cve={cve} />
          </CveCard>
        </Col>
      </Row>
    </Card>
  );
};

export default CvePanelSmall;
