import React, { useCallback, useContext, useState } from 'react';
import { PlusCircleOutlined } from '@ant-design/icons';
import { Button, Collapse, Flex, Select } from 'antd';

import { EMPTY_ID } from '@totem/components/common/reference/ReferenceSelector';
import FormFieldLabelLeft from '@totem/components/FormFieldLabelLeft';
import SurveyTemplateCategoryDetails from '@totem/components/surveyV2/templateDetails/SurveyTemplateCategoryDetails';
import SurveyTemplateCategoryQuestions from '@totem/components/surveyV2/templateDetails/SurveyTemplateCategoryQuestions';
import SurveyTemplateConditionDetails from '@totem/components/surveyV2/templateDetails/SurveyTemplateConditionDetails';
import TemplateContext from '@totem/components/surveyV2/templateDetails/SurveyTemplateContext';
import {
  Category,
  QuestionTypes,
  SurveyTemplate,
} from '@totem/components/surveyV2/types';
import { getLabelFromQuestionType } from '@totem/components/surveyV2/utilities/SurveyUtilities';
import { getToken } from '@totem/utilities/accountUtilities';
import { V2_SURVEY_ENDPOINT } from '@totem/utilities/endpoints';

const styles = {
  selectStyle: {
    width: 200,
  },
  pagingIcon: {
    fontsize: '24px',
  },
};

type Props = {
  category: Category;
};

const SurveyTemplateCategory = ({ category }: Props) => {
  const { surveyTemplateId, setData, setIsLoading } =
    useContext(TemplateContext);
  const [questionType, setQuestionType] = useState<string>('shorttext');

  const sendQuestionCreate = useCallback(
    async (
      templateId: string,
      categoryId: string,
      selectedQuestionType: string,
    ) => {
      const questionTypeName = getLabelFromQuestionType(selectedQuestionType);
      const payload = {
        name: questionTypeName,
        type: selectedQuestionType,
      };

      fetch(
        `${V2_SURVEY_ENDPOINT}/template/${templateId}/categories/${categoryId}/questions/${EMPTY_ID}`,
        {
          method: 'PUT',
          headers: new Headers({
            Authorization: `Bearer ${getToken()}`,
          }),
          body: JSON.stringify(payload),
        },
      )
        .then((res) => res.json())
        .then((result: SurveyTemplate) => {
          setData(result);
        })
        .then(() => {
          setIsLoading(false);
        });
    },
    [],
  );

  return (
    <div>
      <div>
        <Collapse
          items={[
            {
              key: 'category_details_form',
              label: 'Category Details',
              children: <SurveyTemplateCategoryDetails category={category} />,
            },
            {
              key: 'category_conditions',
              label: 'Conditions',
              children: (
                <SurveyTemplateConditionDetails
                  templateId={surveyTemplateId}
                  categoryId={category.id}
                  questionId={EMPTY_ID}
                  condition={category.condition}
                />
              ),
            },
          ]}
        />
      </div>
      <br />
      <Flex justify={'space-between'} align={'center'}>
        <div>
          <FormFieldLabelLeft>Questions: </FormFieldLabelLeft>
        </div>
        <div>
          <span>
            <Select
              style={styles.selectStyle}
              value={questionType}
              onChange={setQuestionType}
              options={QuestionTypes}
            />
            {'  '}
            <Button
              icon={<PlusCircleOutlined />}
              title={'Add Question'}
              onClick={() =>
                sendQuestionCreate(surveyTemplateId, category.id, questionType)
              }
            >
              Add Question
            </Button>
          </span>
        </div>
      </Flex>
      <SurveyTemplateCategoryQuestions category={category} />
    </div>
  );
};

export default SurveyTemplateCategory;
