import React from 'react';
import { Col, Flex, Row } from 'antd';

import BasePane from '@totem/components/BasePane';
import ContentLayout from '@totem/components/ContentLayout';
import CountBadge from '@totem/components/deviceTypes/CountBadge';
import DeviceTypeContainer from '@totem/components/deviceTypes/DeviceTypeContainer';
import DeviceTypeFilters from '@totem/components/deviceTypes/DeviceTypeFilter';
import DeviceTypeTable from '@totem/components/deviceTypes/DeviceTypeTable';
import MergeButton from '@totem/components/deviceTypes/MergeButton';
import PrimaryContentPane from '@totem/components/PrimaryContentPane';

const styles = {
  inset: {
    marginLeft: '20px',
    marginRight: '20px',
  },
};

const DeviceTypesPage = () => {
  return (
    <ContentLayout>
      <BasePane>
        <PrimaryContentPane>
          <DeviceTypeContainer>
            <div style={styles.inset}>
              <br />
              <DeviceTypeFilters />
              <br />
              <br />
              <Row>
                <Col span={12}>
                  <Flex justify={'flex-start'} align={'center'}>
                    <MergeButton isPrimary />
                  </Flex>
                </Col>
                <Col span={12}>
                  <Flex justify={'flex-end'} align={'center'}>
                    <CountBadge />
                  </Flex>
                </Col>
              </Row>
              <br />
              <DeviceTypeTable />
            </div>
          </DeviceTypeContainer>
        </PrimaryContentPane>
      </BasePane>
    </ContentLayout>
  );
};

export default DeviceTypesPage;
