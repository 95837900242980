import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Radio } from 'antd';
import { TablePaginationConfig } from 'antd/es/table';
import { ColumnProps } from 'antd/lib/table';
import { SorterResult } from 'antd/lib/table/interface';

import { EMPTY_ID } from '@totem/components/common/referencesModal/ReferencesModal';
import Table from '@totem/components/common/table/Table';
import TicketingContext from '@totem/components/ticketing/listContainer/ticketingContainerContext';
import { DeviceLastBackupRecord } from '@totem/types/devices';
import { TicketQueryResult } from '@totem/types/ticketing';
import { formatDate } from '@totem/utilities/common';
import { stringifyArray } from '@totem/utilities/tableFilterUtilities';
import { getFilterOptions } from '@totem/utilities/ticketing';

import '../events.css';

export interface Props {
  selectedTicketId?: string;
}

const TicketTable = ({ selectedTicketId }: Props) => {
  const {
    input,
    setInput,
    ticketData,
    filterOptions,
    loading,
    totalRecords,
    onTicketSelected,
  } = useContext(TicketingContext);

  const callTicketAction = (ticket: TicketQueryResult) => {
    if (typeof onTicketSelected === 'function') {
      onTicketSelected(ticket.ticket.id, ticket);
    }
  };

  const getTicketClickAction = (ticket: TicketQueryResult) => {
    return (
      <Radio value={ticket.ticket.id} onClick={() => callTicketAction(ticket)}>
        <Link
          to={`/dashboard/ticket/${ticket.ticket.id}`}
          target="eventTicketDetails"
        >
          {ticket.ticket.number}
        </Link>
      </Radio>
    );
  };

  const columns: ColumnProps<TicketQueryResult>[] = [
    {
      title: 'Number',
      dataIndex: 'ticket.number',
      key: 'type',
      render: (_, ticket: TicketQueryResult) => getTicketClickAction(ticket),
      showSorterTooltip: true,
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        compA.ticket.number === compB.ticket.number
          ? 0
          : compA.ticket.number > compB.ticket.number
          ? 1
          : -1,
      filterMultiple: true,
      filteredValue: stringifyArray(input.type),
      filters: getFilterOptions(
        typeof filterOptions !== 'undefined' && filterOptions !== null
          ? filterOptions.type
          : null,
      ),
    },
    {
      title: 'Category',
      dataIndex: 'ticket.category',
      key: 'category',
      render: (_, ticket: TicketQueryResult) => ticket.ticket.category,
      showSorterTooltip: true,
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        compA.ticket.category === compB.ticket.category
          ? 0
          : compA.ticket.category > compB.ticket.category
          ? 1
          : -1,
      filterMultiple: true,
      filteredValue: stringifyArray(input.category),
      filters: getFilterOptions(
        typeof filterOptions !== 'undefined' && filterOptions !== null
          ? filterOptions.categories
          : null,
      ),
    },
    {
      title: 'Building',
      dataIndex: 'building.name',
      key: 'buildingId',
      render: (_, ticket: TicketQueryResult) => ticket.building.name,
      filterMultiple: true,
      filteredValue: stringifyArray(input.buildingId),
      filters: getFilterOptions(
        typeof filterOptions !== 'undefined' && filterOptions !== null
          ? filterOptions.building
          : null,
      ),
    },
    {
      title: 'Summary',
      dataIndex: 'ticket.summary',
      key: 'ticket.summary',
      render: (_, ticket: TicketQueryResult) => ticket.ticket.summary,
      showSorterTooltip: true,
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        compA.ticket.summary === compB.ticket.summary
          ? 0
          : compA.ticket.summary > compB.ticket.summary
          ? 1
          : -1,
    },
    {
      title: 'Updated',
      dataIndex: 'ticket.updatedAt',
      key: 'ticket.updatedAt',
      render: (_, ticket: TicketQueryResult) =>
        formatDate(ticket.ticket.updatedAt),
      sorter: (compA, compB) =>
        compA.ticket.updatedAt === compB.ticket.updatedAt
          ? 0
          : compA.ticket.updatedAt > compB.ticket.updatedAt
          ? 1
          : -1,
      showSorterTooltip: true,
      sortDirections: ['ascend', 'descend'],
    },
  ];

  const getTickets = () => {
    if (ticketData !== null) {
      if (ticketData.tickets !== null) {
        return ticketData.tickets;
      }
    }
    return [];
  };

  const handleTableChange = (
    updatedPagination: TablePaginationConfig,
    filters: SorterResult<DeviceLastBackupRecord>,
    sorter,
  ) => {
    const { ...params } = filters;

    let sortDir: string = sorter.order === 'descend' ? '-1' : '1';
    if (typeof sorter.order === 'undefined' || sorter.order === null) {
      if (
        typeof input.sortDirection !== 'undefined' &&
        input.sortDirection !== null
      ) {
        sortDir = input.sortDirection;
      }
    }

    let sortField = input.sortField ? input.sortField : 'createdAt';
    if (
      typeof sorter.field !== 'undefined' &&
      typeof sorter.order !== 'undefined'
    ) {
      sortField = sorter.field;
    }

    // @ts-ignore
    setInput({
      ...input,
      ...params,
      pageSize: updatedPagination.pageSize,
      page: updatedPagination.current,
      sortField,
      sortDirection: sortDir,
    });
  };

  return (
    <Radio.Group
      value={
        typeof selectedTicketId !== 'undefined' && selectedTicketId !== null
          ? selectedTicketId
          : EMPTY_ID
      }
    >
      <Table
        showSorterTooltip
        columns={columns}
        dataSource={getTickets()}
        loading={loading}
        onChange={handleTableChange}
        pagination={{
          current: input.page,
          pageSize: input.pageSize,
          total: totalRecords,
          showSizeChanger: true,
        }}
        rowKey={record => record.ticket.id}
      />
    </Radio.Group>
  );
};

export default TicketTable;
