import React, { ReactNode } from 'react';

import './base.css';

type Props = {
  children?: ReactNode;
};

const TabTitle = ({ children }: Props) => {
  return <span styleName="tab-title">{children}</span>;
};

export default TabTitle;
