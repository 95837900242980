import React, { useContext } from 'react';
import { ColumnProps } from 'antd/lib/table';

import Table from '@totem/components/common/table/Table';
import PackageContext from '@totem/components/software/software_package/packageContext';
import { Alias } from '@totem/components/software/software_report/types';
import { sortStringAscending } from '@totem/utilities/tableUtilities';

const AliasesTable = () => {
  const { data, loading } = useContext(PackageContext);

  const getData = () => {
    if (typeof data !== 'undefined' && data !== null) {
      return data.package.aliases;
    }
    return [];
  };

  const columns: ColumnProps<Alias>[] = [
    {
      title: 'Criteria',
      dataIndex: 'criteria',
      key: 'criteria',
      sorter: (compA, compB) =>
        sortStringAscending(compA.criteria, compB.criteria),
    },
    {
      title: 'Match',
      dataIndex: 'match',
      key: 'match',
      sorter: (compA, compB) => sortStringAscending(compA.match, compB.match),
    },
  ];

  return (
    <Table
      showSorterTooltip
      columns={columns}
      dataSource={getData()}
      loading={loading}
      rowKey={(record) => `${record.criteria}_${record.match}`}
    />
  );
};

export default AliasesTable;
