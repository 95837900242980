import React, { ReactNode } from 'react';
import { Flex } from 'antd';

import './totals.css';

type Props = {
  title: ReactNode;
  icon: ReactNode;
  value: ReactNode;
  valueColor?: string;
  backgroundColor?: string;
};

const TotalValue = ({
  title,
  icon,
  value,
  valueColor,
  backgroundColor,
}: Props) => {
  const styles = {
    title: {
      color: valueColor,
    },
    value: {
      color: valueColor,
    },
    container: {
      backgroundColor,
    },
  };

  return (
    <Flex
      justify={'space-around'}
      align={'center'}
      style={styles.container}
      styleName="container"
    >
      <Flex vertical justify={'center'} align={'center'}>
        {icon}
      </Flex>
      <Flex vertical justify={'space-around'} align={'center'}>
        <div style={styles.title} styleName="title">
          {title}
        </div>
        <div style={styles.value}>{value}</div>
      </Flex>
    </Flex>
  );
};

export default TotalValue;
