import React, { ReactNode, useEffect, useState } from 'react';

import ContactSearchContext from '@totem/components/contactSearch/contactSearchContext';
import {
  ContactSearchInput,
  ContactSearchRequest,
  ContactSearchResultEntry,
} from '@totem/components/contactSearch/types';
import { getToken } from '@totem/utilities/accountUtilities';
import { CONTACT_SEARCH_ENDPOINT } from '@totem/utilities/endpoints';

type Props = {
  refresh?: boolean;
  onRecordTotalChanged?: (total: number) => void;
  onDataRefreshRequested?: () => void;
  children?: ReactNode;
  endPointUrl?: string;
};

const ContactSearchEmbeddedContainer = ({
  children,
  onRecordTotalChanged,
  refresh,
  onDataRefreshRequested,
  endPointUrl,
}: Props) => {
  const [refreshData, setRefreshData] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [data, setData] = useState<ContactSearchResultEntry[]>(null);
  const [selectedContact, setSelectedContact] = useState<
    ContactSearchResultEntry
  >(null);
  const [selectedAction, setSelectedAction] = useState<string>('');
  const [input, updateInput] = useState<ContactSearchInput>({
    search_term: '',
  });

  const setInput = (updated: Partial<ContactSearchInput>) => {
    updateInput({ ...input, ...updated });
    setRefreshData(true);
  };

  useEffect(() => {
    if (
      typeof onRecordTotalChanged !== 'undefined' &&
      onRecordTotalChanged !== null
    ) {
      onRecordTotalChanged(totalRecords);
    }
  }, [onRecordTotalChanged, totalRecords]);

  useEffect(() => {
    if (
      refresh &&
      typeof onDataRefreshRequested !== 'undefined' &&
      onDataRefreshRequested !== null
    ) {
      setRefreshData(true);
      onDataRefreshRequested();
    }
  }, [refresh, onDataRefreshRequested]);

  useEffect(() => {
    setRefreshData(true);
  }, [endPointUrl]);

  useEffect(() => {
    if (refreshData) {
      setRefreshData(false);

      if (input.search_term.trim() !== '') {
        setIsLoading(true);
        const params: ContactSearchRequest = {
          search_term: input.search_term,
        };

        const url =
          typeof endPointUrl !== 'undefined' && endPointUrl !== null
            ? endPointUrl
            : CONTACT_SEARCH_ENDPOINT;

        fetch(`${url}`, {
          method: 'POST',
          headers: new Headers({
            Authorization: `Bearer ${getToken()}`,
          }),
          body: JSON.stringify(params),
        })
          .then(res => res.json())
          .then((result: ContactSearchResultEntry[]) => {
            setData(result);
            setTotalRecords(result.length);
          })
          .then(() => {
            setIsLoading(false);
          });
      } else {
        setData([]);
      }
    }
  }, [refreshData, refresh]);

  const handleAction = (
    newAction: string,
    contact: ContactSearchResultEntry,
  ) => {
    setSelectedAction(newAction);
    setSelectedContact(contact);
  };

  return (
    <ContactSearchContext.Provider
      value={{
        input,
        setInput,
        loading: isLoading,
        data,
        selectedAction,
        selectedContact,
        totalRecords,
        onAction: handleAction,
      }}
    >
      <div>{children}</div>
    </ContactSearchContext.Provider>
  );
};

export default ContactSearchEmbeddedContainer;
