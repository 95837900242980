import React, { ReactNode, useEffect, useState } from 'react';

import ComplianceContext from '@totem/components/surveyV2/complianceSummary/complianceContext';
import {
  ComplianceSummary,
  ComplianceSummaryInput,
} from '@totem/components/surveyV2/complianceSummary/types';
import { buildParameters } from '@totem/components/surveyV2/complianceSummary/utilities';
import { getToken } from '@totem/utilities/accountUtilities';
import { V2_SURVEY_ENDPOINT } from '@totem/utilities/endpoints';
import { omitNilOrEmpty } from '@totem/utilities/objectUtilities';

type Props = {
  regionId?: string[];
  buildingId?: string[];
  controlSystemId?: string[];
  children?: ReactNode;
};

const ComplianceSummaryContainer = ({
  regionId,
  buildingId,
  controlSystemId,
  children,
}: Props) => {
  const [input, updateInput] = useState<ComplianceSummaryInput>({
    regionId,
    buildingId,
    controlSystemId,
  });
  const [refreshData, setRefreshData] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [data, setData] = useState<ComplianceSummary>(null);
  const [action, setAction] = useState<string>('');

  const setInput = (updated: Partial<ComplianceSummaryInput>) => {
    updateInput(omitNilOrEmpty({ ...input, ...updated }));
    setRefreshData(true);
  };

  useEffect(() => {
    setRefreshData(true);
  }, [regionId, buildingId, controlSystemId]);

  useEffect(() => {
    if (refreshData) {
      setRefreshData(false);
      setIsLoading(true);

      const uri = `${V2_SURVEY_ENDPOINT}/complianceSummary`;

      const payload = buildParameters(input);

      fetch(uri, {
        method: 'POST',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
        body: JSON.stringify(payload),
      })
        .then((res) => res.json())
        .then((result: ComplianceSummary) => {
          setData(result);
        })
        .then(() => {
          setIsLoading(false);
        });
    }
  }, [refreshData]);

  const handleAction = (newAction: string) => {
    switch (newAction) {
      case 'refresh':
        setRefreshData(true);
        setAction('');
        break;
      default:
        setAction(newAction);
    }
  };

  return (
    <ComplianceContext.Provider
      value={{
        input,
        setInput,
        loading: isLoading,
        data,
        action,
        onAction: handleAction,
      }}
    >
      <div>{children}</div>
    </ComplianceContext.Provider>
  );
};

export default ComplianceSummaryContainer;
