import React, { useContext } from 'react';
import { Select } from 'antd';

import LayoutContext from '@totem/components/devices/variables/presentation/layout/LayoutContext';
import FormFieldLabel from '@totem/components/FormFieldLabel';
import { isNotNull } from '@totem/utilities/common';

const FilterPanel = () => {
  const { filterOptions, setFilterOptions } = useContext(LayoutContext);
  return (
    <div>
      {isNotNull(filterOptions) && filterOptions.location.filterable && (
        <>
          <FormFieldLabel>Location: </FormFieldLabel>
          <Select
            style={{ width: '200px' }}
            options={filterOptions.location.options.map((option) => {
              return { value: option.value, label: option.text };
            })}
            value={
              filterOptions.location.filter.length === 1
                ? filterOptions.location.filter[0]
                : ''
            }
            onChange={(newValue) => {
              setFilterOptions({
                ...filterOptions,
                location: { ...filterOptions.location, filter: [newValue] },
              });
            }}
          />
        </>
      )}
    </div>
  );
};

export default FilterPanel;
