import React from 'react';

import configuration from '@totem/utilities/configuration';

import config from '../../../server/utilities/configuration';

const ConfigDump = () => {
  const env = config.environment;
  const port = config.port;

  return (
    <div>
      <div>AUTH_DOMAIN: {configuration.AUTH_DOMAIN}</div>
      <div>AUTH_AUDIENCE: {configuration.AUTH_AUDIENCE}</div>
      <div>AUTH_RESPONSE_TYPE: {configuration.AUTH_RESPONSE_TYPE}</div>
      <div>AUTH_CALLBACK_URL: {configuration.AUTH_CALLBACK_URL}</div>
      <div>API_DOMAIN: {configuration.API_DOMAIN}</div>
      <div>vendorOrgsEnabled: {configuration.flags.vendorOrgsEnabled}</div>
      <div>useFullStory: {configuration.flags.useFullStory}</div>
      <div>environment: {configuration.environment}</div>
      <div>window.location.hostname: {window.location.hostname}</div>
      <div>server.environment: {env}</div>
      <div>PORT: {port}</div>
    </div>
  );
};

export default ConfigDump;
