import { gql } from '@apollo/client';

export const CONTROL_SYSTEM_BACKUP_FRAGMENT = gql`
  fragment ControlSystemBackupDetail on ControlSystemBackup {
    id
    name
    originalFileName
    source
    reporter
    size
    createdAt
    updatedAt
    eventSeverity
  }
`;

export const CREATE_CONTROL_SYSTEM_BACKUP = gql`
  mutation BackupControlSystem(
    $input: ControlSystemBackupCreateInput!
    $file: Upload
  ) {
    controlSystemBackupCreate(input: $input, file: $file) {
      ...ControlSystemBackupDetail
    }
  }
  ${CONTROL_SYSTEM_BACKUP_FRAGMENT}
`;

export const DELETE_CONTROL_SYSTEM_BACKUP = gql`
  mutation DeleteControlSystemBackup($id: ID!) {
    controlSystemBackupDelete(id: $id)
  }
`;
