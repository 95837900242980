import React from 'react';

import './ras.css';

const headerStyle = {
  width: '100%',
  repeat: true,
  fontWeight: 700,
};
const PageHeader = () => {
  return <div style={headerStyle} styleName="headerText">Remote Access Services</div>;
};

export default PageHeader;
