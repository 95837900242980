import React from 'react';
import { Badge } from 'antd';

import colors from '@totem/styles/colors';
import { UserStatus as UserStatusEnum } from '@totem/types/user';

type UserStatusColorMap = {
  [key in UserStatusEnum]: string;
};

export interface Props {
  status: UserStatusEnum;
}

const statusColorMap: UserStatusColorMap = {
  [UserStatusEnum.Pending]: colors.utility.neutral,
  [UserStatusEnum.Invited]: colors.utility.neutral,
  [UserStatusEnum['Email Bounced']]: colors.utility.error,
  [UserStatusEnum['Delivery Error']]: colors.utility.error,
  [UserStatusEnum['Email Clicked']]: colors.utility.warning,
  [UserStatusEnum.Joined]: colors.utility.positive,
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  badge: {
    marginRight: '0.5rem',
  },
};

const UserStatus = ({ status }: Props) => {
  const color = statusColorMap[status] || colors.utility.neutral;

  return (
    // @ts-ignore
    <div style={styles.container} className="totem-user-status-badge">
      <Badge style={styles.badge} color={color} />
      <div>{UserStatusEnum[status]}</div>
    </div>
  );
};

export default UserStatus;
