import React, { useContext } from 'react';
import { EditOutlined } from '@ant-design/icons';
import { Button } from 'antd';

import DropdownFilter from '@totem/components/common/dropdownFilter/DropdownFilter';
import ContractContext from '@totem/components/contracts/details/ContractContext';
import ContractFilterChips from '@totem/components/contracts/details/ContractFilterChips';
import { ContractInput } from '@totem/components/contracts/details/types';
import { sortStringAscending } from '@totem/utilities/tableUtilities';

import '../contracts.css';

const CONTRACT_LINE_STATUS_FILTER_OPTIONS = [
  {
    label: 'Active',
    value: 'Active',
  },
  {
    label: 'Expired',
    value: 'Expired',
  },
  {
    label: 'Pending',
    value: 'Pending',
  },
  {
    label: 'Pending Dates',
    value: 'Pending Dates',
  },
];

const styles = {
  dropdown: {
    marginRight: '2rem',
  },
  editIcon: {
    color: '#1890ff',
  },
  iconButton: {
    margin: '0 2px',
  },
};

type Props = {
  title: string;
  userCanEdit: boolean;
  isEditMode: boolean;
  setIsEditMode: (boolean) => void;
};

const ContractFilters = ({
  title,
  userCanEdit,
  setIsEditMode,
  isEditMode,
}: Props) => {
  const { input, setInput, buildings, services } = useContext(ContractContext);

  const handleMultiNumberFilterChange = (
    property: keyof ContractInput,
    update: string[],
  ) => {
    setInput({
      ...input,
      [property]: update.map(String),
    });
  };

  return (
    <div styleName="filters">
      <div styleName="filters-title">
        <div>{title}</div>
        <div styleName="rightAlign">
          {userCanEdit && (
            <Button
              icon={<EditOutlined />}
              title="Toggle Edit Mode"
              shape="circle"
              style={styles.iconButton}
              onClick={() => setIsEditMode(!isEditMode)}
            />
          )}
        </div>
      </div>
      <div styleName="filters-body">
        <DropdownFilter
          label="Contract Line Status"
          options={CONTRACT_LINE_STATUS_FILTER_OPTIONS}
          value={input.status || []}
          onChange={(update) => handleMultiNumberFilterChange('status', update)}
          style={styles.dropdown}
        />
        <DropdownFilter
          label="Building"
          options={
            typeof buildings !== 'undefined' && buildings !== null
              ? buildings.sort((compA, compB) =>
                  sortStringAscending(compA.label, compB.label),
                )
              : []
          }
          value={input.buildingId || []}
          onChange={(update) =>
            handleMultiNumberFilterChange('buildingId', update)
          }
          style={styles.dropdown}
        />
        <DropdownFilter
          label="Services"
          options={
            typeof services !== 'undefined' && services !== null
              ? services.sort((compA, compB) =>
                  sortStringAscending(compA.label, compB.label),
                )
              : []
          }
          value={input.service || []}
          onChange={(update) =>
            handleMultiNumberFilterChange('service', update)
          }
          style={styles.dropdown}
        />
      </div>
      <div styleName="filter-chips-container">
        <ContractFilterChips />
      </div>
    </div>
  );
};

export default ContractFilters;
