import React, { useCallback, useContext, useMemo, useState } from 'react';
import { CloseCircleFilled, SearchOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import { Button, Input, Modal, notification } from 'antd';
import { TablePaginationConfig } from 'antd/lib/table';
import { SorterResult } from 'antd/lib/table/interface';
import * as R from 'ramda';
import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params';

import BasePane from '@totem/components/BasePane';
import ComponentHeader from '@totem/components/ComponentHeader';
import PrimaryContentPane from '@totem/components/PrimaryContentPane';
import UserProfileContext from '@totem/components/UserProfileContext';
import {
  CREATE_REGION,
  DELETE_REGION,
  GET_REGIONS,
  UPDATE_REGION,
} from '@totem/graph/region';
import { useConnection } from '@totem/hooks/useConnection';
import { useErrorNotification } from '@totem/hooks/useErrorNotification';
import { userProfileRegionLabelSelector } from '@totem/selectors/preferencesSelectors';
import colors from '@totem/styles/colors';
import {
  AddEditRegion,
  RegionAction,
  RegionsConnection,
} from '@totem/types/region';
import authUtilities from '@totem/utilities/authUtilities';
import { debounce } from '@totem/utilities/debounce';
import { getTablePagination } from '@totem/utilities/paginationUtilities';

import ContentLayout from '../ContentLayout';

import AddEditRegionModal from './AddEditRegionModal';
import RegionsTable from './RegionsTable';

import './regions.css';

const styles = {
  icon: {
    color: colors.neutral.gray,
  },
  errorIcon: {
    color: colors.utility.error,
  },
  deleteButton: {
    background: colors.utility.error,
    color: colors.neutral.white,
  },
};

const TABLE_LIMIT = 10;
const DEBOUNCE_TIME = 300;

const Regions = () => {
  const [input, setInput] = useQueryParams({
    offset: withDefault(NumberParam, 0),
    limit: withDefault(NumberParam, TABLE_LIMIT),
    name: StringParam,
    sortOrder: StringParam,
    sortBy: StringParam,
  });

  const [regionName, setRegionName] = useState<AddEditRegion>({
    name: '',
  });
  const [regionAction, setRegionAction] = useState<RegionAction>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const { data, loading, refetch } = useQuery<{
    regions: RegionsConnection;
  }>(GET_REGIONS, {
    variables: {
      input,
      buildingsInput: {},
      controlSystemsInput: {},
      policyAuditsInput: {},
    },
  });

  const { regions, totalCount } = useConnection<RegionsConnection>({
    data,
    accessor: R.path(['regions']),
    initialData: { totalCount: 0, regions: [] },
  });

  const [addRegion, { error: createRegionError }] = useMutation(CREATE_REGION);

  useErrorNotification(createRegionError, 'Error Creating Region');

  const [editRegion, { loading: loadingEdit, error: updateRegionError }] =
    useMutation(UPDATE_REGION);

  useErrorNotification(updateRegionError, 'Error Updating Region');

  const [deleteRegion, { error: deleteRegionError }] =
    useMutation(DELETE_REGION);

  useErrorNotification(deleteRegionError, 'Error Deleting Region');

  const handlePagination = (
    { current, pageSize: limit }: TablePaginationConfig,
    { field, order }: SorterResult<AddEditRegion>,
  ) => {
    const offset = (current - 1) * limit;
    const sortBy = !order ? null : field;
    const sortOrder = !order ? null : order;
    // @ts-ignore
    setInput({ ...input, limit, offset, sortBy, sortOrder });
  };

  const handleSearch = useCallback(
    debounce(
      (name: string) => setInput({ ...input, name, offset: 0 }),
      DEBOUNCE_TIME,
    ),
    [],
  );

  const handleAddRegionClick = () => {
    setRegionAction(RegionAction.ADD);
    setRegionName({ name: '' });
    setIsModalOpen(true);
  };

  const handleEditRegionClick = (region: AddEditRegion) => {
    setRegionAction(RegionAction.EDIT);
    setRegionName(region);
    setIsModalOpen(true);
  };

  const handleDeleteRegionClick = async (region) => {
    Modal.confirm({
      content: `Are you sure you want to delete the region ${region.name.trim()}?`,
      title: 'Delete this region?',
      cancelText: 'No',
      okText: 'Yes, Delete',
      okType: 'danger',
      okButtonProps: { style: styles.deleteButton },
      icon: <CloseCircleFilled style={styles.errorIcon} />,
      onOk: () =>
        deleteRegion({ variables: { id: region.id } }).then(() => refetch()),
    });
  };

  const handleSubmit = async (region) => {
    if (regionAction === RegionAction.ADD) {
      await addRegion({ variables: { input: region } });
      notification.success({
        message: 'Region Created',
        description: 'Your new region has successfully been created.',
      });
      refetch();
      setIsModalOpen(false);
    } else {
      await editRegion({
        variables: { input: { id: region.id, name: region.name } },
      });
      setIsModalOpen(false);
    }
  };

  const pagination = useMemo(
    () => getTablePagination(input, totalCount),
    [[input, totalCount]],
  );

  const regionCreationEnabled = !authUtilities.minimumRequiredRole(
    authUtilities.ROLE_SETS.TEAM_MEMBER,
  );

  const { userProfile } = useContext(UserProfileContext);
  const regionLabel = userProfileRegionLabelSelector(userProfile);

  return (
    <ContentLayout>
      <BasePane>
        <PrimaryContentPane>
          <ComponentHeader
            title={'Region Management'}
            options={
              <Button
                key="1"
                type="primary"
                disabled={regionCreationEnabled}
                onClick={handleAddRegionClick}
              >
                Add Region
              </Button>
            }
          />
          <div styleName="table-container">
            <div styleName="search-count-row">
              <div styleName="search-bar">
                <Input
                  placeholder="Search Region"
                  onChange={(event) => handleSearch(event.target.value)}
                  prefix={<SearchOutlined style={styles.icon} />}
                  defaultValue={input.name}
                />
              </div>
              <div styleName="table-count">
                <span>{`${Math.min(
                  totalCount - input.offset,
                  input.limit,
                )} out of ${totalCount} regions`}</span>
              </div>
            </div>
            <div>
              <RegionsTable
                regions={regions}
                pagination={pagination}
                input={input}
                setInput={setInput}
                loading={loading || loadingEdit}
                onEditRegionClick={handleEditRegionClick}
                onDeleteRegionClick={handleDeleteRegionClick}
                onChange={handlePagination}
              />
            </div>
          </div>
        </PrimaryContentPane>
        <AddEditRegionModal
          onSubmit={handleSubmit}
          regionLabel={regionLabel}
          onCancel={() => setIsModalOpen(false)}
          loading={loading}
          region={regionName}
          action={regionAction}
          visible={isModalOpen}
        />
      </BasePane>
    </ContentLayout>
  );
};

export default Regions;
