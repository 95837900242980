import React, { ReactNode, useEffect, useState } from 'react';

import ReportsContext from '@totem/components/ui_components/reports/ReportsContext';
import { Report } from '@totem/components/ui_components/reports/types';
import { getToken } from '@totem/utilities/accountUtilities';
import { UI_ENDPOINT } from '@totem/utilities/endpoints';

type Props = {
  children?: ReactNode;
};

const ReportsContainer = ({ children }: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [data, setData] = useState<Report[]>([]);

  useEffect(() => {
    fetch(`${UI_ENDPOINT}/reports`, {
      method: 'GET',
      headers: new Headers({
        Authorization: `Bearer ${getToken()}`,
      }),
    })
      .then((res) => res.json())
      .then((result: Report[]) => {
        setData(result);
      })
      .then(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <ReportsContext.Provider
      value={{
        loading: isLoading,
        reports: data,
      }}
    >
      {children}
    </ReportsContext.Provider>
  );
};

export default ReportsContainer;
