import React, { ReactNode } from 'react';

import './base.css';

type Props = {
  children?: ReactNode;
};
const PageSectionBody = ({ children }: Props) => {
  return <div styleName="page-section-body">{children}</div>;
};

export default PageSectionBody;
