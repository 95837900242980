import React, { useCallback, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { AutoComplete } from 'antd';
import { debounce } from '@totem/utilities/debounce';

interface OrganizationSearchResult {
  id: string;
  name: string;
  domain: string;
}

export interface Props {
  value: string;
  onChange: (searchValue: string) => void;
  onSelect: (organization: OrganizationSearchResult) => void;
  placeholder?: string;
  notFoundContent?: string;
  style?: React.CSSProperties;
  disabled?: boolean;
  limit?: number;
  offset?: number;
  orgId?: string;
}

const styles = {
  input: {
    width: '100%',
  },
};

const DEBOUNCE_TIME = 500;

const getData = (organization: OrganizationSearchResult[]) =>
  organization.map(({ id, domain }) => ({
    id,
    value: domain,
  }));

const SEARCH_ORGANIZATION = gql`
  query SearchOrganization($input: OrganizationSearchInput!) {
    organizationSearch(input: $input) {
      id
      name
      domain
    }
  }
`;

const OrganizationDomainAutoComplete = ({
  value,
  onChange,
  onSelect,
  style,
  disabled,
  notFoundContent = 'No results found',
  placeholder,
  limit = 5,
  offset = 0,
}: Props) => {
  const [searchDomain, setSearchDomain] = useState<string>(null);

  const { data } = useQuery(SEARCH_ORGANIZATION, {
    variables: { input: { searchDomain, limit, offset } },
    fetchPolicy: 'no-cache',
  });

  const organizations = data?.organizationSearch || [];

  const handleSearch = useCallback(
    debounce(setSearchDomain, DEBOUNCE_TIME),
    [],
  );

  const handleSelect = (_, option: any) => {
    const selectedOrganization = organizations.find(
      organization => organization.id === option.id,
    );

    onSelect(selectedOrganization.domain);
  };

  return (
    <AutoComplete
      value={value}
      onChange={onChange}
      options={getData(organizations)}
      style={{ ...styles.input, ...style }}
      onSelect={handleSelect}
      onSearch={handleSearch}
      placeholder={placeholder}
      disabled={disabled ?? false}
      notFoundContent={notFoundContent}
    />
  );
};

export default OrganizationDomainAutoComplete;
