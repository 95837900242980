import React, { useState } from 'react';
import { Button } from 'antd';

import TenantUserAddDialog from '@totem/components/tenants/tenantsPage/TenantUserAddDialog';
import { Tenant } from '@totem/components/tenants/types';
import IBDIcon from '@totem/styles/Icon';

type Props = {
  tenant: Tenant;
};

const TenantUserAddButton = ({ tenant }: Props) => {
  const [showAdd, setShowAdd] = useState(false);

  return (
    <>
      <Button icon={<IBDIcon name="Add" />} onClick={() => setShowAdd(true)}>
        Add User
      </Button>
      {showAdd === true && (
        <TenantUserAddDialog
          open={showAdd}
          onClose={() => setShowAdd(false)}
          tenant={tenant}
        />
      )}
    </>
  );
};

export default TenantUserAddButton;
