import React from 'react';

import colors from '@totem/styles/colors';
import fonts from '@totem/styles/fonts';

const styles = {
  critical: {
    fontSize: fonts.fontSm,
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    paddingLeft: '5px',
    paddingRight: '5px',
    minWidth: '1.75rem',
    height: '1.75rem',
    backgroundColor: colors.event.criticality.background.critical,
    color: colors.event.criticality.foreground.critical,
  },
  major: {
    fontSize: fonts.fontSm,
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    paddingLeft: '5px',
    paddingRight: '5px',
    minWidth: '1.75rem',
    height: '1.75rem',
    backgroundColor: colors.event.criticality.background.major,
    color: colors.event.criticality.foreground.major,
  },
  minor: {
    fontSize: fonts.fontSm,
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    paddingLeft: '5px',
    paddingRight: '5px',
    minWidth: '1.75rem',
    height: '1.75rem',
    backgroundColor: colors.event.criticality.background.minor,
    color: colors.event.criticality.foreground.minor,
  },
  warning: {
    fontSize: fonts.fontSm,
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    paddingLeft: '5px',
    paddingRight: '5px',
    minWidth: '1.75rem',
    height: '1.75rem',
    backgroundColor: colors.event.criticality.background.warning,
    color: colors.event.criticality.foreground.warning,
  },
  information: {
    fontSize: fonts.fontSm,
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    paddingLeft: '5px',
    paddingRight: '5px',
    minWidth: '1.75rem',
    height: '1.75rem',
    backgroundColor: colors.event.criticality.background.info,
    color: colors.event.criticality.foreground.info,
  },
  ok: {
    fontSize: fonts.fontSm,
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    paddingLeft: '5px',
    paddingRight: '5px',
    minWidth: '1.75rem',
    height: '1.75rem',
    backgroundColor: colors.event.criticality.background.ok,
    color: colors.event.criticality.foreground.ok,
  },
  unknown: {
    fontSize: fonts.fontSm,
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    paddingLeft: '5px',
    paddingRight: '5px',
    minWidth: '1.75rem',
    height: '1.75rem',
    backgroundColor: colors.event.criticality.background.unknown,
    color: colors.event.criticality.foreground.unknown,
  },
};

type Props = {
  severity: number;
  count: number;
};

const EventSeverityCount = ({ severity, count }: Props) => {
  const getSeverity = (total: number) => {
    switch (severity) {
      case 5:
        return (
          <span
            // @ts-ignore
            style={styles.critical}
          >
            <b>{total}</b>
          </span>
        );
      case 4:
        return (
          <span
            // @ts-ignore
            style={styles.major}
          >
            <b>{total}</b>
          </span>
        );
      case 3:
        return (
          <span
            // @ts-ignore
            style={styles.minor}
          >
            <b>{total}</b>
          </span>
        );
      case 2:
        return (
          <span
            // @ts-ignore
            style={styles.warning}
          >
            <b>{total}</b>
          </span>
        );
      case 1:
        return (
          <span
            // @ts-ignore
            style={styles.information}
          >
            <b>{total}</b>
          </span>
        );
      case 0:
        return (
          <span
            // @ts-ignore
            style={styles.ok}
          >
            <b>{total}</b>
          </span>
        );
      default:
        return (
          <span
            // @ts-ignore
            style={styles.unknown}
          >
            <b>{total}</b>
          </span>
        );
    }
  };

  return getSeverity(count);
};

export default EventSeverityCount;
