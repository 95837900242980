import React from 'react';

import Count from '@totem/components/common/Count';
import colors from '@totem/styles/colors';
import { CompletionCounts as CompletionCountsType } from '@totem/types/policyAudit';
import { getExactPercentage } from '@totem/utilities/numberUtilities';

import './policyAudits.css';

interface Props {
  completionCounts: CompletionCountsType;
}

const getTotal = (counts: CompletionCountsType) => {
  return (
    counts.complete + counts.inProgress + counts.notStarted + counts.overdue
  );
};

const PolicyAuditCompletionCounts = ({ completionCounts }: Props) => {
  const { complete, inProgress, notStarted, overdue } = completionCounts;
  const total = getTotal(completionCounts);

  return (
    <>
      <div styleName="graph-header">Completion Stages</div>
      <div styleName="graph-container">
        <div styleName="graph-bar-container">
          <div styleName="graph-label completion">Complete</div>
          <div styleName="graph-bar">
            <div
              style={{
                width: `${getExactPercentage(total, complete)}%`,
                background: colors.criticality.positive,
                height: '100%',
              }}
            />
          </div>
          <Count count={complete} size="small" />
        </div>
        <div styleName="graph-bar-container">
          <div styleName="graph-label completion">In Progress</div>
          <div styleName="graph-bar">
            <div
              style={{
                width: `${getExactPercentage(total, inProgress)}%`,
                background: colors.criticality.info,
                height: '100%',
              }}
            />
          </div>
          <Count count={inProgress} size="small" />
        </div>
        <div styleName="graph-bar-container">
          <div styleName="graph-label completion">Not Started</div>
          <div styleName="graph-bar">
            <div
              style={{
                width: `${getExactPercentage(total, notStarted)}%`,
                background: colors.criticality.medium,
                height: '100%',
              }}
            />
          </div>
          <Count count={notStarted} size="small" />
        </div>
        <div styleName="graph-bar-container">
          <div styleName="graph-label completion">Overdue</div>
          <div styleName="graph-bar">
            <div
              style={{
                width: `${getExactPercentage(total, overdue)}%`,
                background: colors.criticality.critical,
                height: '100%',
              }}
            />
          </div>
          <Count count={overdue} size="small" />
        </div>
      </div>
    </>
  );
};

export default PolicyAuditCompletionCounts;
