import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Modal } from 'antd';
import * as R from 'ramda';

import OrganizationDomainAutoComplete from '@totem/components/common/autoCompletes/OrganizationDomainAutoComplete';
import { AddEditCustomer, CustomerAction } from '@totem/types/customer';

const FormItem = Form.Item;

const styles = {
  content: {
    display: 'flex',
    justifyContent: 'center',
  },
  form: {
    width: '100%',
  },
  formItem: {
    paddingBottom: '0',
    marginBottom: '2rem',
  },
  modal: {
    title: {
      fontSize: '1.8rem',
      fontWeight: 700,
    },
    footer: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
};

interface Errors {
  name?: string;
  domain?: string;
}

export interface Props {
  customer: AddEditCustomer;
  customerAction: CustomerAction;
  visible: boolean;
  onCancel: () => void;
  onSubmit: (customer: AddEditCustomer) => void;
}

const AddEditCustomerModal = ({
  customer,
  customerAction,
  visible,
  onCancel,
  onSubmit,
}: Props) => {
  const [organization, setOrganization] = useState<AddEditCustomer>({
    id: customer.id,
    name: customer.name,
    domain: customer.domain,
  });

  const [errors, setErrors] = useState<Errors>({});

  useEffect(() => {
    if (!visible) {
      setOrganization({ name: '', domain: '' });
      setErrors({});
    } else {
      setOrganization({
        id: customer.id,
        name: customer.name,
        domain: customer.domain,
      });
    }
  }, [visible, customer]);

  const isInputValid = (): boolean => {
    const err: Errors = {};

    if (!organization.name) {
      err.name = 'Name is required.';
    }

    if (!organization.domain) {
      err.domain = 'Domain is required.';
    }

    setErrors(err);
    return R.isEmpty(err);
  };

  const handleSubmit = () => {
    if (isInputValid()) {
      onSubmit(organization);
    }
  };

  const handleDomainSelect = domain => {
    setOrganization({ ...organization, domain });
  };

  return (
    <Modal
      title={
        <div style={styles.modal.title}>
          {customerAction === CustomerAction.Edit
            ? 'Update Customer'
            : 'Add Customer'}
        </div>
      }
      open={visible}
      onCancel={onCancel}
      footer={
        <div style={styles.modal.footer}>
          <Button onClick={onCancel}>Cancel</Button>
          <Button type="primary" onClick={handleSubmit}>
            {customerAction === CustomerAction.Edit ? 'Update' : 'Add'}
          </Button>
        </div>
      }
    >
      <div style={styles.content}>
        <Form layout="vertical" style={styles.form}>
          <FormItem
            label="Name"
            colon={false}
            validateStatus={R.isNil(errors.name) ? 'success' : 'error'}
            help={R.isNil(errors.name) ? null : errors.name}
            style={styles.formItem}
          >
            <Input
              placeholder="Enter Name"
              value={organization.name}
              onChange={event =>
                setOrganization({ ...organization, name: event.target.value })
              }
            />
          </FormItem>
          {customerAction === CustomerAction.Add && (
            <FormItem
              label="Company Domain"
              colon={false}
              validateStatus={R.isNil(errors.domain) ? 'success' : 'error'}
              help={R.isNil(errors.domain) ? null : errors.domain}
              style={styles.formItem}
            >
              <OrganizationDomainAutoComplete
                value={organization.domain}
                onChange={domain =>
                  setOrganization({ ...organization, domain })
                }
                onSelect={handleDomainSelect}
                placeholder="example.com"
              />
            </FormItem>
          )}
        </Form>
      </div>
    </Modal>
  );
};

export default AddEditCustomerModal;
