import React, { useContext, useState } from 'react';

import SurveyContext from '@totem/components/surveyV2/instanceDetail/SurveyInstanceContext';
import criticalityUtilities from '@totem/utilities/criticalityUtilities';
const { criticalityOptions } = criticalityUtilities;
import {
  FileTextOutlined,
  VerticalAlignBottomOutlined,
} from '@ant-design/icons';
import { Button, Modal, Select } from 'antd';
const { Option } = Select;
import * as R from 'ramda';

import MultiSelect from '@totem/components/common/MultiSelect';
import ResponseQuestionRow from '@totem/components/surveyV2/instanceDetail/score/ResponseQuestionRow';
import ResultGrade from '@totem/components/surveyV2/instanceDetail/score/ResultGrade';
import ResultsBar from '@totem/components/surveyV2/instanceDetail/score/ResultsBar';
import { SelectedItem } from '@totem/components/surveyV2/instanceDetail/score/types';
import colors from '@totem/styles/colors';
import fonts from '@totem/styles/fonts';
import { OptionValue } from '@totem/types/common';
import { Criticality } from '@totem/types/criticality';
import {
  QuestionOption,
  ScoredCategory,
  ScoredLevel,
  ScoredLevelType,
  SurveyQuestion,
} from '@totem/types/survey';
import accountUtilities from '@totem/utilities/accountUtilities';
import { REPORT_ENDPOINT } from '@totem/utilities/endpoints';

import './score.css';
import {isNull} from "@totem/utilities/common";

const styles = {
  uploadReadyIconStyle: {
    backgroundColor: colors.brand.blue,
  },
  input: {
    display: 'none',
  },
  icon: {
    color: colors.brand.blue,
    marginBottom: '3px',
  },
  floatingLabelStyle: {
    color: colors.neutral.dim,
    fontSize: fonts.md,
    fontWeight: '500',
  },
  textFieldStyle: {
    fontSize: fonts.md,
    color: colors.neutral.dark,
  },
  selectFieldStyle: {
    input: {
      fontSize: fonts.md,
      color: colors.neutral.black,
      whiteSpace: 'normal',
      padding: '0.25em 0',
    },
    selected: {
      fontSize: fonts.md,
      color: colors.brand.blue,
      whiteSpace: 'normal',
      padding: '0.25em 0',
    },
    iconStyle: {
      fill: colors.neutral.dim,
    },
  },
  predefinedMenuOption: {
    fontWeight: 600,
  },
  criticalityIndicator: {
    height: 12,
    width: 12,
  },
};

const EXPORT_TYPES: OptionValue[] = [
  {
    label: 'Report Component',
    value: 'report',
  },
  {
    label: 'Responses Table',
    value: 'responses',
  },
  {
    label: '.csv file',
    value: 'csv',
  },
];

type Props = {
  selectedId: string;
  selectedType: string;
  selectedItem: SelectedItem;
  title: string;
};

const criticalityMultiSelectOptions = criticalityOptions.map(
  ({ text: label, value }) => ({
    label: (
      <span styleName="criticality-dropdown-label">
        {criticalityUtilities.getCriticalityIndicator(
          value,
          styles.criticalityIndicator,
        )}
        <span styleName="criticality-dropdown-label-text">{label}</span>
      </span>
    ),
    value: value.toString(),
  }),
);

const criticalityDefaultSelected = criticalityOptions.map(({ value }) =>
  value.toString(),
);

const ExportModal = ({
  selectedId,
  selectedType,
  selectedItem,
  title,
}: Props) => {
  const { data } = useContext(SurveyContext);
  const [exportType, setExportType] = useState<string>('report');
  const [visible, setVisible] = useState<boolean>(false);
  const [criticality, setCriticality] = useState<number[]>(
    criticalityOptions.map(({ value }) => value),
  );

  const getPrintUrl = (): string => {
    const token = accountUtilities.getToken();

    const includedCriticalities = criticality.length
      ? criticality.join(',')
      : '-1';

    const encodedId = encodeURIComponent(data.survey.id);
    const encodedTitle = encodeURIComponent(title);
    const encodedSelectedId = encodeURIComponent(selectedId);
    const encodedSelectedType = encodeURIComponent(selectedType);
    const encodedExportType = encodeURIComponent(exportType);
    const encodedToken = encodeURIComponent(token);
    const encodedCriticality = encodeURIComponent(includedCriticalities);

    return `${REPORT_ENDPOINT}/report.pdf?token=${encodedToken}&url=report/surveyinstances/${encodedId}/${encodedTitle}/${encodedSelectedId}/${encodedSelectedType}/${encodedExportType}/${encodedCriticality}`;
  };

  const onDataTypeChange = (newExportType: 'report' | 'responses' | 'csv') => {
    setExportType(newExportType);
  };

  const onCriticalityChange = (newCriticality: string[]) => {
    setCriticality(newCriticality.map((val) => Number(val)));
  };

  const filterQuestionsByCriticality = (
    questions: SurveyQuestion[],
  ): SurveyQuestion[] => {
    return questions.filter((question) => {
      const selectedOption = question.options.find(
        (option) => option.label === question.value.value,
      );

      return criticality.includes(
        selectedOption ? selectedOption.criticality : Criticality.Info,
      );
    });
  };

  const toggleVisibility = () => {
    setVisible(!visible);
  };

  // @ts-ignore
  const closeModal = () => {
    setVisible(false);
  };

  const getAllQuestions = (): Array<SurveyQuestion> => {
    const { categories } = data.survey;

    if (R.isNil(categories) || R.isEmpty(categories)) {
      return [];
    }

    return R.reduce(
      (
        acc: SurveyQuestion[],
        { questions }: ScoredCategory,
      ): SurveyQuestion[] => [...acc, ...questions],
      [],
      categories,
    );
  };

  const getSummaryQuestions = (): Array<SurveyQuestion> => {
    const questions = getAllQuestions();
    return filterQuestionsByCriticality(questions);
  };

  const getCategoryQuestions = (categoryId: string): Array<SurveyQuestion> => {
    const questions = data.survey.categories.find(
      (category: ScoredCategory): boolean => category.id === categoryId,
    ).questions;

    return filterQuestionsByCriticality(questions);
  };

  const getCategoryInstanceQuestions = (
    lclSelectedId: string,
  ): Array<SurveyQuestion> => {
    let categoryId;
    const categoryInstance = data.survey.categories.reduce(
      (acc: any, category: ScoredCategory): Object => {
        if (!category.multiInstance) {
          return acc;
        }

        const matchingInstance = category.multiInstanceScores.find(
          (instance: any): boolean =>
            `${instance.instance}-${instance.displayName}` === lclSelectedId,
        );

        if (matchingInstance) {
          categoryId = category.id;
        }

        return matchingInstance ? matchingInstance : acc;
      },
      {},
    );

    const questions = data.survey.categories
      .find((category: ScoredCategory): boolean => category.id === categoryId)
      .questions.filter(
        (question: any): boolean =>
          question.instance === categoryInstance.instance,
      );

    return filterQuestionsByCriticality(questions);
  };

  const getQuestionsByLevel = (levelId: string): Array<SurveyQuestion> => {
    const questions = getAllQuestions().filter(
      (question: SurveyQuestion): boolean => question.levels.includes(levelId),
    );

    return filterQuestionsByCriticality(questions);
  };

  const getQuestionsByLevelType = (
    levelTypeId: string,
  ): Array<SurveyQuestion> => {
    const levelType = data.survey.levelTypes.find(
      (type: any): boolean =>
        `${type.typeName}-${type.displayName}` === levelTypeId,
    );

    const levels = data.survey.levels
      .filter((level: ScoredLevel): boolean => level.type === levelType.type)
      .map((level: ScoredLevel): string => level.id);

    const questions = getAllQuestions().filter(
      (question: SurveyQuestion): boolean =>
        !R.isEmpty(R.intersection(question.levels)(levels)),
    );

    return filterQuestionsByCriticality(questions);
  };

  const getLevelTypeName = (levelId: string): string => {
    const lvl = data.survey.levels.find(
      (level: ScoredLevel): boolean => level.id === levelId,
    );

    if (!lvl) {
      return '';
    }

    const lvlType = data.survey.levelTypes.find(
      (levelType: ScoredLevelType): boolean => levelType.type === lvl.type,
    );

    if (!lvlType) {
      return '';
    }

    return lvlType.displayName;
  };

  const showRecommendations = (questions: Array<SurveyQuestion>): boolean => {
    const everyRecommendationIsEmpty = questions
      .map((question: SurveyQuestion): any => {
        const option = question.options.find(
          (opt: QuestionOption): boolean => opt.label === question.value.value,
        );

        if (R.isNil(option) || R.isEmpty(option)) {
          return '';
        }

        return option ? option.recommendation : '';
      })
      .every((recommendation: string): boolean => recommendation === '');

    return !everyRecommendationIsEmpty;
  };

  const getNumericalScore = (totalScore: number, maxScore: number): number =>
    Number(((totalScore / maxScore) * 100).toFixed(0));

  const getGrade = (score: number): string => {
    if (!Number(score) || score < 60) {
      return 'F';
    }

    if (score >= 60 && score < 70) {
      return 'D';
    }

    if (score >= 70 && score < 80) {
      return 'C';
    }

    if (score >= 80 && score < 90) {
      return 'B';
    }

    if (score >= 90) {
      return 'A';
    }

    return 'F';
  };

  const getChildren = () => {
    if (isNull(selectedItem) || isNull(selectedItem.children)) {
      return <div />;
    }

    let allSubChildrenEmpty = true;
    selectedItem.children.map((next: SelectedItem): any => {
      if (!R.isNil(next.children) && R.isEmpty(next.children)) {
        allSubChildrenEmpty = false;
      }
      return {};
    });

    if (allSubChildrenEmpty) {
      return (
        <React.Fragment>
          {selectedItem.children.map((item: SelectedItem) => {
            const score = getNumericalScore(item.totalScore, item.maxScore);
            const grade = getGrade(score);

            return (
              <div styleName="line-results-container modal">
                <div styleName="line-results-top-row">
                  <div styleName="line-results-label">{item.label}</div>
                  {!isNaN(score) && (
                    <div styleName="line-results-top-end-container">
                      <div styleName="line-results-top-end-score">{score}%</div>
                      <div styleName="line-results-top-end-grade">{grade}</div>
                    </div>
                  )}
                </div>
                <ResultsBar
                  totalScore={item.totalScore}
                  maxScore={item.maxScore}
                />
              </div>
            );
          })}
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        {selectedItem.children.map((item: SelectedItem, index: number) => {
          const score = getNumericalScore(item.totalScore, item.maxScore);

          return (
            <React.Fragment key={`${item.label}-${index}`}>
              <div styleName="results-title-row">
                <div styleName="results-title sub-title">{item.label}</div>
                {!isNaN(score) && (
                  <div styleName="results-title-score-container">
                    <div styleName="results-title-subtext">{score}%</div>
                    <ResultGrade
                      small
                      totalScore={item.totalScore}
                      maxScore={item.maxScore}
                    />
                  </div>
                )}
              </div>
              <div styleName="results-line-break" />
              {!R.isEmpty(item.children) && (
                <div styleName="line-results-container-offset">
                  {item.children.map((subItem: SelectedItem, idx: number) => {
                    const subItemScore = getNumericalScore(
                      subItem.totalScore,
                      subItem.maxScore,
                    );
                    const grade = getGrade(subItemScore);

                    return (
                      <div
                        key={`${subItem.label}-${idx}`}
                        styleName="line-results-container"
                      >
                        <div styleName="line-results-top-row">
                          <div styleName="line-results-label">
                            {subItem.label}
                          </div>
                          {!isNaN(subItemScore) && (
                            <div styleName="line-results-top-end-container">
                              <div styleName="line-results-top-end-score">
                                {subItemScore}%
                              </div>
                              <div styleName="line-results-top-end-grade">
                                {grade}
                              </div>
                            </div>
                          )}
                        </div>
                        <ResultsBar
                          totalScore={subItem.totalScore}
                          maxScore={subItem.maxScore}
                        />
                      </div>
                    );
                  })}
                </div>
              )}
            </React.Fragment>
          );
        })}
      </React.Fragment>
    );
  };

  const getContent = () => {
    if (exportType === 'csv') {
      return (
        <div styleName="report-results-container csv">
          Export the results as a .csv file.
        </div>
      );
    }

    if (exportType === 'report') {
      const children = getChildren();
      const topLevelScore = getNumericalScore(
        selectedItem.totalScore,
        selectedItem.maxScore,
      );

      return (
        <div styleName="report-results-container">
          <div styleName="report-results-inner-content">
            <div styleName="results-title-row">
              <div styleName="results-title">{selectedItem.label}</div>
              {!isNaN(topLevelScore) && (
                <div styleName="results-title-score-container">
                  <div styleName="results-title-subtext">
                    overall: {topLevelScore}%
                  </div>
                  <ResultGrade
                    totalScore={selectedItem.totalScore}
                    maxScore={selectedItem.maxScore}
                  />
                </div>
              )}
            </div>
            <div styleName="results-line-break" />
            {children}
          </div>
        </div>
      );
    }

    if (exportType === 'responses' && selectedType === 'level') {
      const questions = getQuestionsByLevel(selectedId);
      const levelTypeLabel = getLevelTypeName(selectedId);
      const topLevelScore = getNumericalScore(
        selectedItem.totalScore,
        selectedItem.maxScore,
      );

      return (
        <React.Fragment>
          <div styleName="responses-heading-container">
            <div styleName="responses-heading-label-container">
              <div styleName="responses-heading-top">{levelTypeLabel}</div>
              <div styleName="responses-heading-bottom">{title}</div>
            </div>
            {!isNaN(topLevelScore) && (
              <div styleName="results-title-score-container responses">
                <div styleName="results-title-subtext">
                  overall: {topLevelScore}%
                </div>
                <ResultGrade
                  totalScore={selectedItem.totalScore}
                  maxScore={selectedItem.maxScore}
                />
              </div>
            )}
          </div>
          <div styleName="responses-table-heading">
            <div
              styleName={`assessment-section ${
                showRecommendations ? '' : 'full'
              }`}
            >
              question and response
            </div>
            {showRecommendations && (
              <div styleName="recommendations-section">recommendations</div>
            )}
          </div>
          {questions.map((question: SurveyQuestion) => (
            <ResponseQuestionRow
              showRecommendations={showRecommendations(questions)}
              key={question.id}
              question={question}
            />
          ))}
        </React.Fragment>
      );
    }

    if (exportType === 'responses' && selectedType === 'level-type') {
      const questions = getQuestionsByLevelType(selectedId);

      const topLevelScore = getNumericalScore(
        selectedItem.totalScore,
        selectedItem.maxScore,
      );

      return (
        <React.Fragment>
          <div styleName="responses-heading-container">
            <div styleName="responses-heading-label-container">
              <div styleName="responses-heading-top">All Questions</div>
              <div styleName="responses-heading-bottom">
                {selectedItem.label}
              </div>
            </div>
            {!isNaN(topLevelScore) && (
              <div styleName="results-title-score-container responses">
                <div styleName="results-title-subtext">
                  overall: {topLevelScore}%
                </div>
                <ResultGrade
                  totalScore={selectedItem.totalScore}
                  maxScore={selectedItem.maxScore}
                />
              </div>
            )}
          </div>
          <div styleName="responses-table-heading">
            <div
              styleName={`assessment-section ${
                showRecommendations ? '' : 'full'
              }`}
            >
              question and response
            </div>
            {showRecommendations && (
              <div styleName="recommendations-section">recommendations</div>
            )}
          </div>
          {questions.map((question: SurveyQuestion) => (
            <ResponseQuestionRow
              showRecommendations={showRecommendations(questions)}
              key={question.id}
              question={question}
            />
          ))}
        </React.Fragment>
      );
    }

    if (exportType === 'responses' && selectedType === 'summary') {
      const questions = getSummaryQuestions();
      const topLevelScore = getNumericalScore(
        selectedItem.totalScore,
        selectedItem.maxScore,
      );

      return (
        <React.Fragment>
          <div styleName="responses-heading-container">
            <div styleName="responses-heading-label-container">
              <div styleName="responses-heading-top">All Questions</div>
              <div styleName="responses-heading-bottom">
                {selectedItem.label}
              </div>
            </div>
            {!isNaN(topLevelScore) && (
              <div styleName="results-title-score-container responses">
                <div styleName="results-title-subtext">
                  overall: {topLevelScore}%
                </div>
                <ResultGrade
                  totalScore={selectedItem.totalScore}
                  maxScore={selectedItem.maxScore}
                />
              </div>
            )}
          </div>
          <div styleName="responses-table-heading">
            <div
              styleName={`assessment-section ${
                showRecommendations ? '' : 'full'
              }`}
            >
              question and response
            </div>
            {showRecommendations && (
              <div styleName="recommendations-section">recommendations</div>
            )}
          </div>
          {questions.map((question: SurveyQuestion) => (
            <ResponseQuestionRow
              showRecommendations={showRecommendations(questions)}
              key={question.id}
              question={question}
            />
          ))}
        </React.Fragment>
      );
    }

    if (exportType === 'responses' && selectedType === 'category') {
      const questions = getCategoryQuestions(selectedId);

      const topLevelScore = getNumericalScore(
        selectedItem.totalScore,
        selectedItem.maxScore,
      );

      return (
        <React.Fragment>
          <div styleName="responses-heading-container">
            <div styleName="responses-heading-label-container">
              <div styleName="responses-heading-top">All Questions</div>
              <div styleName="responses-heading-bottom">
                {selectedItem.label}
              </div>
            </div>
            {!isNaN(topLevelScore) && (
              <div styleName="results-title-score-container responses">
                <div styleName="results-title-subtext">
                  overall: {topLevelScore}%
                </div>
                <ResultGrade
                  totalScore={selectedItem.totalScore}
                  maxScore={selectedItem.maxScore}
                />
              </div>
            )}
          </div>
          <div styleName="responses-table-heading">
            <div
              styleName={`assessment-section ${
                showRecommendations ? '' : 'full'
              }`}
            >
              question and response
            </div>
            {showRecommendations && (
              <div styleName="recommendations-section">recommendations</div>
            )}
          </div>
          {questions.map((question: SurveyQuestion) => (
            <ResponseQuestionRow
              showRecommendations={showRecommendations(questions)}
              key={question.id}
              question={question}
            />
          ))}
        </React.Fragment>
      );
    }

    if (exportType === 'responses' && selectedType === 'category-instance') {
      const questions = getCategoryInstanceQuestions(selectedId);

      const topLevelScore = getNumericalScore(
        selectedItem.totalScore,
        selectedItem.maxScore,
      );

      return (
        <React.Fragment>
          <div styleName="responses-heading-container">
            <div styleName="responses-heading-label-container">
              <div styleName="responses-heading-top">All Questions</div>
              <div styleName="responses-heading-bottom">
                {selectedItem.label}
              </div>
            </div>
            {!isNaN(topLevelScore) && (
              <div styleName="results-title-score-container responses">
                <div styleName="results-title-subtext">
                  overall: {topLevelScore}%
                </div>
                <ResultGrade
                  totalScore={selectedItem.totalScore}
                  maxScore={selectedItem.maxScore}
                />
              </div>
            )}
          </div>
          <div styleName="responses-table-heading">
            <div
              styleName={`assessment-section ${
                showRecommendations ? '' : 'full'
              }`}
            >
              question and response
            </div>
            {showRecommendations && (
              <div styleName="recommendations-section">recommendations</div>
            )}
          </div>
          {questions.map((question: SurveyQuestion) => (
            <ResponseQuestionRow
              showRecommendations={showRecommendations(questions)}
              key={question.id}
              question={question}
            />
          ))}
        </React.Fragment>
      );
    }

    return <div />;
  };

  const exportButtonLabel = exportType === 'csv' ? '.csv' : '.pdf';
  const content = getContent();
  const downloadHref = getPrintUrl();

  return (
    <div>
      <div onClick={toggleVisibility}>
        <Button type="link">
          <VerticalAlignBottomOutlined />
          Export Options
        </Button>
      </div>
      <Modal
        width={700}
        style={{
          top: '20vh',
          border: 'none',
        }}
        bodyStyle={{
          border: 'none',
          padding: '0',
        }}
        title={`Export Options - ${title}`}
        open={visible}
        footer={
          <div styleName="modal-footer">
            <a href={downloadHref} target="_blank">
              <Button type="primary" icon={<FileTextOutlined />}>
                {`Export as ${exportButtonLabel}`}
              </Button>
            </a>
          </div>
        }
        onCancel={closeModal}
      >
        <div styleName="modal-container">
          <div styleName="modal-export-option-text">
            Select your export format and preview below
          </div>
          <div styleName="modal-export-type-select-container">
            <Select
              style={{ width: '100%' }}
              onChange={onDataTypeChange}
              value={exportType}
            >
              {EXPORT_TYPES.map((type: OptionValue) => (
                <Option key={type.value} value={type.value}>
                  {type.label}
                </Option>
              ))}
            </Select>
          </div>
          <div styleName="modal-export-top-button-container">
            <div styleName="criticality-label">Filter responses</div>
            <a href={downloadHref} target="_blank">
              <Button type="primary" icon={<FileTextOutlined />}>
                {`Export as ${exportButtonLabel}`}
              </Button>
            </a>
          </div>
          {exportType === 'responses' && (
            <div styleName="criticality-select-container">
              <MultiSelect
                style={{ width: '90%' }}
                options={criticalityMultiSelectOptions}
                onChange={onCriticalityChange}
                defaultSelected={criticalityDefaultSelected}
                size="default"
              />
            </div>
          )}
          <div styleName="modal-horizontal-break" />
          <div styleName="modal-content">{content}</div>
        </div>
      </Modal>
    </div>
  );
};

export default ExportModal;
