import { Filter } from '@totem/types/common';
import { FilterStringValue } from '@totem/types/events';

export const buildFilter = (filters) => {
  const paramFilters: Filter[] = [];

  if (filters['ticket.status'] !== null) {
    paramFilters.push({
      field: 'status',
      values: filters['ticket.status'],
    });
  }
  if (filters['ticket.priority'] !== null) {
    paramFilters.push({
      field: 'priority',
      values: filters['ticket.priority'],
    });
  }
  if (filters['ticket.number'] !== null) {
    paramFilters.push({
      field: 'type',
      values: filters['ticket.number'],
    });
  }
  if (filters['ticket.category'] !== null) {
    paramFilters.push({
      field: 'category',
      values: filters['ticket.category'],
    });
  }
  if (filters['building.name'] !== null) {
    paramFilters.push({
      field: 'buildingId',
      values: filters['building.name'],
    });
  }

  return paramFilters;
};

export const buildAffiliatedFilter = (filters) => {
  const paramFilters: Filter[] = [];

  if (filters['ticket.status'] !== null) {
    paramFilters.push({
      field: 'status',
      values: filters['ticket.status'],
    });
  }
  if (filters['ticket.priority'] !== null) {
    paramFilters.push({
      field: 'priority',
      values: filters['ticket.priority'],
    });
  }
  if (filters['ticket.category'] !== null) {
    paramFilters.push({
      field: 'category',
      values: filters['ticket.category'],
    });
  }
  if (filters['ticket.number'] !== null) {
    paramFilters.push({
      field: 'type',
      values: filters['ticket.number'],
    });
  }
  if (filters['building.name'] !== null) {
    paramFilters.push({
      field: 'buildingId',
      values: filters['building.name'],
    });
  }
  if (filters['organization.name'] !== null) {
    paramFilters.push({
      field: 'organizationId',
      values: filters['organization.name'],
    });
  }

  return paramFilters;
};

export const getTicketColor = (ticketType: string) => {
  switch (ticketType) {
    case 'Incident':
      return '#F07800';
    case 'Service Request':
      return '#F0A400';
    case 'Change Request':
      return '#F0CB00';
    case 'Problem':
      return '#D90000';
    case 'Site Project':
      return '#378FEF';
    default:
      return '#606060';
  }
};

export const getTicketAbbr = (ticketType: string) => {
  switch (ticketType) {
    case 'Incident':
      return 'Incident';
    case 'Service Request':
      return 'Service';
    case 'Change Request':
      return 'Change';
    case 'Problem':
      return 'Problem';
    case 'Site Project':
      return 'Project';
    default:
      return ticketType;
  }
};

export const getTicketTypeFilter = () => {
  return [
    {
      text: 'Incident',
      value: 'Incident',
    },
    {
      text: 'Problem',
      value: 'Problem',
    },
    {
      text: 'Change Request',
      value: 'Change Request',
    },
    {
      text: 'Service Request',
      value: 'Service Request',
    },
    {
      text: 'Site Project',
      value: 'Site Project',
    },
  ];
};

export const getPriorityFilter = () => {
  return [
    {
      text: 'P1',
      value: 'P1',
    },
    {
      text: 'P2',
      value: 'P2',
    },
    {
      text: 'P3',
      value: 'P3',
    },
  ];
};

export const getStatusFilter = () => {
  return [
    {
      text: 'OPEN',
      value: 'OPEN',
    },
    {
      text: 'RESOLVED',
      value: 'RESOLVED',
    },
    {
      text: 'CLOSED',
      value: 'CLOSED',
    },
  ];
};

export const getFilterOptions = (options: FilterStringValue[]) => {
  if (typeof options !== 'undefined' && options !== null) {
    return options
      .map((option) => {
        return {
          text: option.name,
          value: option.value,
        };
      })
      .sort((compA, compB) =>
        compA.text === compB.text ? 0 : compA.text > compB.text ? 1 : -1,
      );
  }
  return [];
};
