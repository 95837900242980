import React, { useContext, useState } from 'react';
import { Tabs, TabsProps } from 'antd';

import BasePane from '@totem/components/BasePane';
import CardBody from '@totem/components/CardBody';
import ComponentHeader from '@totem/components/ComponentHeader';
import ContentLayout from '@totem/components/ContentLayout';
import DeviceEmbeddedContainer from '@totem/components/devices/devicesContainer/DeviceEmbeddedContainer';
import PrimaryContentPane from '@totem/components/PrimaryContentPane';
import GatewayDeviceTable from '@totem/components/remoteAccess/roleUsers/rootRole/GatewayDeviceTable';
import GatewayHeader from '@totem/components/remoteAccess/roleUsers/rootRole/GatewayHeader';
import HeaderAction from '@totem/components/remoteAccess/roleUsers/rootRole/HeaderAction';
import RemoteAccessFilters from '@totem/components/remoteAccess/roleUsers/rootRole/RemoteAccessFilters';
import RemoteAccessUserContainer from '@totem/components/remoteAccess/roleUsers/rootRole/RemoteAccessUserContainer';
import RemoteAccessUsersTable from '@totem/components/remoteAccess/roleUsers/rootRole/RemoteAccessUserTable';
import TotalsContainer from '@totem/components/remoteAccess/roleUsers/rootRole/TotalsContainer';
import SearchEntry from '@totem/components/remoteAccess/roleUsers/SearchEntry';
import CalendarTimeline from '@totem/components/remoteAccess/sessions/CalendarTimelineChart';
import RemoteAccessSessionsContainer from '@totem/components/remoteAccess/sessions/RemoteAccessSessionsContainer';
import SessionEventsTable from '@totem/components/remoteAccess/sessions/SessionActivityTable';
import SessionComboChart from '@totem/components/remoteAccess/sessions/SessionComboChart';
import RemoteAccessTotalsContainer from '@totem/components/remoteAccess/totals/RemoteAccessTotalsContainer';
import TabTitle from '@totem/components/TabTitle';
import UserProfileContext from '@totem/components/UserProfileContext';
import authUtilities from '@totem/utilities/authUtilities';
import { GATEWAY_PAGINATION_ENDPOINT } from '@totem/utilities/endpoints';
import { isIBUser } from '@totem/utilities/security';

const RemoteAccessUserPage = () => {
  const { userProfile } = useContext(UserProfileContext);
  const [activeTab, setActiveTab] = useState<string>('users');
  const isIB = isIBUser(userProfile);
  const isAdmin = authUtilities.minimumRequiredRole(
    authUtilities.ROLE_SETS.ORGANIZATION_ADMINISTRATOR,
  );

  const getTabItems = () => {
    const tabItems: TabsProps['items'] = [
      {
        key: 'users',
        label: <TabTitle>Users</TabTitle>,
        children: (
          <>
            <RemoteAccessTotalsContainer>
              <TotalsContainer />
            </RemoteAccessTotalsContainer>
            <SearchEntry />
            <br />
            <RemoteAccessUsersTable />
          </>
        ),
      },
      {
        key: 'sessions',
        label: <TabTitle>Sessions</TabTitle>,
        children: (
          <>
            <RemoteAccessFilters />
            <br />
            <SessionComboChart />
            <br />
            <CalendarTimeline />
          </>
        ),
      },
      {
        key: 'activity',
        label: <TabTitle>Activity</TabTitle>,
        children: <SessionEventsTable />,
      },
    ];

    if (isIB && isAdmin) {
      tabItems.push({
        key: 'gateways',
        label: <TabTitle>Gateways</TabTitle>,
        children: <GatewayDeviceTable />,
      });
    }

    return tabItems;
  };

  return (
    <ContentLayout>
      <RemoteAccessUserContainer>
        <DeviceEmbeddedContainer
          deviceEndPoint={GATEWAY_PAGINATION_ENDPOINT}
          staticFilters={{ type: ['IB Gateway', 'Airwall'] }}
        >
          <BasePane>
            <PrimaryContentPane>
              <ComponentHeader
                title={'Remote Access'}
                options={
                  <>
                    {activeTab === 'users' && <HeaderAction />}
                    {activeTab === 'gateways' && <GatewayHeader />}
                  </>
                }
              />
              <CardBody>
                <RemoteAccessSessionsContainer
                  refresh={false}
                  onDataRefreshRequested={() => {}}
                >
                  <Tabs
                    defaultActiveKey="users"
                    onChange={(newTab) => setActiveTab(newTab)}
                    items={getTabItems()}
                  />
                </RemoteAccessSessionsContainer>
              </CardBody>
            </PrimaryContentPane>
          </BasePane>
        </DeviceEmbeddedContainer>
      </RemoteAccessUserContainer>
    </ContentLayout>
  );
};

export default RemoteAccessUserPage;
