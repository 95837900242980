import React, { ReactNode, useEffect, useState } from 'react';
import {
  ArrayParam, NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params';

import EventFilterContext from '@totem/components/events/eventFilters/EventFilterContext';
import {
  EventFilterInput,
  EventFilterOptions,
  EventFilters,
} from '@totem/components/events/eventFilters/types';
import {
  getDefaultEndDate,
  getDefaultStartDate,
} from '@totem/components/events/graphs/utilities';
import { getToken } from '@totem/utilities/accountUtilities';
import { EVENTS_ENDPOINT } from '@totem/utilities/endpoints';
import { omitNilOrEmpty } from '@totem/utilities/objectUtilities';

type Props = {
  children?: ReactNode;
  defaultFilters?: EventFilters;
  staticFilters?: EventFilters;
};

const EventFilterContainer = ({
  children,
  defaultFilters,
  staticFilters,
}: Props) => {
  const [filterOptions, setFilterOptions] = useState<EventFilterOptions>(null);
  const [isSending, setIsSending] = useState(false);
  const [input, updateInput] = useQueryParams({
    startTime: withDefault(NumberParam, getDefaultStartDate().valueOf()),
    endTime: withDefault(NumberParam, getDefaultEndDate().valueOf()),
    eventType: withDefault(
      ArrayParam,
      typeof defaultFilters !== 'undefined' &&
        defaultFilters !== null &&
        typeof defaultFilters.eventType !== 'undefined' &&
        defaultFilters.eventType !== null
        ? defaultFilters.eventType
        : [],
    ),
    sourceSystem: withDefault(
      ArrayParam,
      typeof defaultFilters !== 'undefined' &&
        defaultFilters !== null &&
        typeof defaultFilters.sourceSystem !== 'undefined' &&
        defaultFilters.sourceSystem !== null
        ? defaultFilters.sourceSystem
        : [],
    ),
    sourceType: withDefault(
      ArrayParam,
      typeof defaultFilters !== 'undefined' &&
        defaultFilters !== null &&
        typeof defaultFilters.sourceType !== 'undefined' &&
        defaultFilters.sourceType !== null
        ? defaultFilters.sourceType
        : [],
    ),
    status: withDefault(
      ArrayParam,
      typeof defaultFilters !== 'undefined' &&
        defaultFilters !== null &&
        typeof defaultFilters.status !== 'undefined' &&
        defaultFilters.status !== null
        ? defaultFilters.status
        : [],
    ),
    severity: withDefault(
      ArrayParam,
      typeof defaultFilters !== 'undefined' &&
        defaultFilters !== null &&
        typeof defaultFilters.severity !== 'undefined' &&
        defaultFilters.severity !== null
        ? defaultFilters.severity
        : [],
    ),
    buildingId: withDefault(
      ArrayParam,
      typeof defaultFilters !== 'undefined' &&
        defaultFilters !== null &&
        typeof defaultFilters.buildingId !== 'undefined' &&
        defaultFilters.buildingId !== null
        ? defaultFilters.buildingId
        : [],
    ),
    regionId: withDefault(
      ArrayParam,
      typeof defaultFilters !== 'undefined' &&
        defaultFilters !== null &&
        typeof defaultFilters.regionId !== 'undefined' &&
        defaultFilters.regionId !== null
        ? defaultFilters.regionId
        : [],
    ),
    deviceId: withDefault(
      ArrayParam,
      typeof defaultFilters !== 'undefined' &&
        defaultFilters !== null &&
        typeof defaultFilters.deviceId !== 'undefined' &&
        defaultFilters.deviceId !== null
        ? defaultFilters.deviceId
        : [],
    ),
    controlSystemId: withDefault(
      ArrayParam,
      typeof defaultFilters !== 'undefined' &&
        defaultFilters !== null &&
        typeof defaultFilters.controlSystemId !== 'undefined' &&
        defaultFilters.controlSystemId !== null
        ? defaultFilters.controlSystemId
        : [],
    ),
    reference: withDefault(
      ArrayParam,
      typeof defaultFilters !== 'undefined' &&
        defaultFilters !== null &&
        typeof defaultFilters.reference !== 'undefined' &&
        defaultFilters.reference !== null
        ? defaultFilters.reference
        : [],
    ),
    searchTerm: withDefault(
      StringParam,
      typeof defaultFilters !== 'undefined' &&
        defaultFilters !== null &&
        typeof defaultFilters.searchTerm !== 'undefined' &&
        defaultFilters.searchTerm !== null
        ? defaultFilters.searchTerm
        : '',
    ),
  });

  const setInput = (updated: Partial<EventFilterInput>) => {
    updateInput(omitNilOrEmpty({ ...input, ...updated }), 'replace');
  };

  useEffect(() => {
    if (isSending) {
      return;
    }
    const endpoint = `${EVENTS_ENDPOINT}/filterOptions`;

    setIsSending(true);

    fetch(`${endpoint}`, {
      method: 'GET',
      headers: new Headers({
        Authorization: `Bearer ${getToken()}`,
      }),
    })
      .then((res) => res.json())
      .then((result: EventFilterOptions) => {
        setFilterOptions(result);
      })
      .then(() => {
        setIsSending(false);
      });
  }, []);

  return (
    <EventFilterContext.Provider
      value={{
        input,
        setInput,
        filterOptions,
        staticFilters,
      }}
    >
      <div>{children}</div>
    </EventFilterContext.Provider>
  );
};

export default EventFilterContainer;
