import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowRightOutlined } from '@ant-design/icons';
import { Progress } from 'antd';

import Count from '@totem/components/common/Count';
import ComplianceContext from '@totem/components/surveyV2/complianceSummary/complianceContext';
import colors from '@totem/styles/colors';
import { isNotNull } from '@totem/utilities/common';

import '../complianceSummary.css';

const styles = {
  card: {
    width: '100%',
    marginTop: '3rem',
  },
  cardBody: {
    padding: '2.4rem',
    borderTop: `1px solid ${colors.antd.borderGray}`,
  },
  count: {
    marginBottom: '1rem',
  },
  nonCompliant: {
    background: colors.neutral.white,
    border: `2px solid ${colors.brand.red}`,
  },
  icon: {
    color: colors.antd.blue,
    marginLeft: '0.5rem',
  },
  progress: {
    marginTop: '1rem',
  },
};

const PolicyCompliance = () => {
  const navigate = useNavigate();
  const { data } = useContext(ComplianceContext);

  const compliantFindings = isNotNull(data) ? data.compliant : 0;
  const nonCompliantFindings = isNotNull(data) ? data.nonCompliant : 0;
  const totalFindings = compliantFindings + nonCompliantFindings;
  const findingsPercentage =
    totalFindings > 0 ? (nonCompliantFindings / totalFindings) * 100 : 0;

  return (
    <div styleName="audit-overview-section">
      <div styleName="audit-overview-title">Policy Compliance</div>
      <div styleName="policy-compliance">
        <div styleName="audit-overview-subtitle">Non-Compliant</div>
        <div styleName="audit-overview-subtitle">Compliant</div>
      </div>
      <div styleName="spaced">
        <Count
          count={nonCompliantFindings}
          size="small"
          style={styles.nonCompliant}
        />
        <Count count={compliantFindings} size="small" />
      </div>
      <Progress
        percent={findingsPercentage}
        showInfo={false}
        size={['100%', 10]}
        strokeColor={colors.brand.red}
        style={styles.progress}
      />
      <div
        styleName="audit-overview-link"
        onClick={() => navigate('/dashboard/findings/policyaudits')}
      >
        <span>Compliance Report</span>
        <ArrowRightOutlined style={styles.icon} />
      </div>
    </div>
  );
};

export default PolicyCompliance;
