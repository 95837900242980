import { createContext } from 'react';

import {
  DeviceMapping,
  FilterOptions,
  ZoneMappingInput,
} from '@totem/components/administration/zoneMappings/types';
import { OrganizationHierarchy } from '@totem/types/organization';

interface Context {
  loading: boolean;
  data: DeviceMapping[];
  totalRecords: number;
  onAction?: (action: string, mapping: DeviceMapping) => void;
  input: ZoneMappingInput;
  setInput: (input: ZoneMappingInput) => void;
  filterOptions: FilterOptions;
  organizationHierarchy: OrganizationHierarchy;
}
export default createContext<Context>({
  loading: false,
  data: null,
  totalRecords: 0,
  onAction: null,
  input: {},
  setInput: () => {},
  filterOptions: {
    sensor: [],
    zone: [],
  },
  organizationHierarchy: null,
});
