import { createContext } from 'react';

import { AutoCompleteFilter } from '@totem/types/hooks';
import {
  PolicyAudit,
  PolicyAuditsConnectionInput,
} from '@totem/types/policyAudit';

export interface Context {
  input: PolicyAuditsConnectionInput;
  setInput: (input: PolicyAuditsConnectionInput) => void;
  policyAudits: PolicyAudit[];
  totalCount: number;
  loading: boolean;
  buildingFilter: AutoCompleteFilter;
  regionFilter: AutoCompleteFilter;
  showSelectOptions: boolean;
  selectedAudits: string[];
  setShowSelectOptions: (show: boolean) => void;
  setSelectedAudits: (selected: string[]) => void;
}

const initialContext: Context = {
  input: {
    limit: 50,
    offset: 0,
  },
  setInput: () => {},
  policyAudits: [],
  totalCount: 0,
  loading: false,
  buildingFilter: null,
  regionFilter: null,
  showSelectOptions: false,
  selectedAudits: [],
  setShowSelectOptions: () => {},
  setSelectedAudits: () => {},
};

export default createContext<Context>(initialContext);
