import React, { ReactNode } from 'react';
import { MailOutlined, TableOutlined } from '@ant-design/icons';
import gql from 'graphql-tag';

import client from '@totem/graph/client';
import colors from '@totem/styles/colors';
import { Features } from '@totem/types/organization';
import { Feature, FeatureUpdateInput } from '@totem/types/preferences';

type UpdateCallbackFunction = (feature: Features) => void;
type ErrorCallbackFunction = (error: string) => void;

const ERROR_PREFIX = 'AccountSettingConfiguration:';

const UPDATE_FEATURE = gql`
  mutation FeatureUpdate($input: FeatureUpdateInput!) {
    featureUpdate(input: $input) {
      nmap
      healthRisk
      questionnaire
      questionnaireBcc
      policyAuditBcc
      buildingNote
      buildingContact
    }
  }
`;

export interface AccountSettingConfiguration {
  title: string;
  subtitle: string;
  image: string | ReactNode;
  status: boolean; // Default
  key: Feature;
  update: (
    flag: boolean,
    onFinish?: UpdateCallbackFunction,
    onError?: ErrorCallbackFunction,
  ) => Promise<Features>;
}

const updatePreference = async (
  feature: Feature,
  enabled: boolean,
  onFinish?: UpdateCallbackFunction,
  onError?: ErrorCallbackFunction,
): Promise<Features> => {
  try {
    const { data, errors } = await client.mutate({
      mutation: UPDATE_FEATURE,
      variables: {
        input: {
          feature,
          enabled,
        } as FeatureUpdateInput,
      },
    });

    if (errors) {
      if (onError) {
        onError(
          `${ERROR_PREFIX}:updatePreference: Failed to update ${feature}`,
        );
      }
    }

    if (onFinish) {
      onFinish(data);
    }

    return data;
  } catch (error) {
    onError(`${ERROR_PREFIX}:updatePreference: Failed to update ${feature}`);
    return null;
  }
};

const IconStyle = {
  fontSize: '24px',
  color: colors.antd.blue,
};

const configuration: AccountSettingConfiguration[] = [
  {
    title: 'Enable BCC emails to audit assignees',
    subtitle:
      'Allows you to send personalized BCC’ed emails to assignees from the Audit Management page.',
    image: <MailOutlined style={IconStyle} />,
    status: false,
    key: Feature.POLICYAUDIT_BCC,
    update: async (flag: boolean, callback?: UpdateCallbackFunction) => {
      return updatePreference(Feature.POLICYAUDIT_BCC, flag, callback);
    },
  },
  {
    title: 'Enable NMAP Analysis',
    subtitle: 'Enables NMAP table on building detail page.',
    image: <TableOutlined style={IconStyle} />,
    status: false,
    key: Feature.NMAP,
    update: async (flag: boolean, callback?: UpdateCallbackFunction) => {
      return updatePreference(Feature.NMAP, flag, callback);
    },
  },
];

export default configuration;
