import { createContext } from 'react';

import { Finding, FindingsConnectionInput } from '@totem/types/finding';
import { AutoCompleteFilter } from '@totem/types/hooks';

interface Context {
  input: FindingsConnectionInput;
  setInput: (input: FindingsConnectionInput) => void;
  totalCount: number;
  findings: Finding[];
  loading: boolean;
  buildingFilter: AutoCompleteFilter;
  regionFilter: AutoCompleteFilter;
  assigneeFilter: AutoCompleteFilter;
}

// @ts-ignore
const PolicyAuditFindingDetailContext = createContext<Context>({});

export default PolicyAuditFindingDetailContext;
