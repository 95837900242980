import React, { useContext } from 'react';

import PageContext from '@totem/components/devices/variables/presentation/layout/PageContext';

const PageSubTitle = () => {
  const { pageSubtitle } = useContext(PageContext);

  return <span>{pageSubtitle}</span>;
};

export default PageSubTitle;
