import React, { useState } from 'react';
import { Button } from 'antd';

import BasePane from '@totem/components/BasePane';
import ComponentHeader from '@totem/components/ComponentHeader';
import ContentLayout from '@totem/components/ContentLayout';
import ControlSystemCreateModal from '@totem/components/controlSystemReport/ControlSystemCreateModal';
import ControlSystemReportContainer from '@totem/components/controlSystemReport/ControlSystemReportContainer';
import NameSearch from '@totem/components/controlSystemReport/NameSearch';
import ReportTable from '@totem/components/controlSystemReport/ReportTable';
import PrimaryContentPane from '@totem/components/PrimaryContentPane';
import RecordCountBadge from '@totem/components/RecordCountBadge';

import './controlSystemReport.css';

const ControlSystemReportPage = () => {
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [open, setOpen] = useState<boolean>(false);

  return (
    <ContentLayout>
      <ControlSystemReportContainer onRecordTotalChanged={setTotalRecords}>
        <BasePane>
          <PrimaryContentPane>
            <ComponentHeader
              title={'Control System Management'}
              options={
                <>
                  <Button type="primary" onClick={() => setOpen(true)}>
                    Add Control System
                  </Button>
                </>
              }
            />
            <div styleName="search-container">
              <NameSearch />
              <div styleName="table-count">
                <RecordCountBadge count={totalRecords} />
              </div>
            </div>
            <div styleName="table-container">
              <ReportTable />
            </div>
            {open && (
              <ControlSystemCreateModal
                open={open}
                onClose={() => setOpen(false)}
              />
            )}
          </PrimaryContentPane>
        </BasePane>
      </ControlSystemReportContainer>
    </ContentLayout>
  );
};

export default ControlSystemReportPage;
