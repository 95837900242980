import React, { useCallback, useContext, useState } from 'react';
import { Button, Form, Input, Modal } from 'antd';
import dayjs from 'dayjs';
import * as R from 'ramda';

import PendingContext from '@totem/components/internalOnly/netSuitePending/PendingContext';
import { RequestOrganizationCreate } from '@totem/components/internalOnly/netSuitePending/types';
import UserProfileContext from '@totem/components/UserProfileContext';
import { getToken } from '@totem/utilities/accountUtilities';
import { ORGANIZATION_ENDPOINT } from '@totem/utilities/endpoints';
import { CheckResponseShowError } from '@totem/utilities/responseUtilities';
const FormItem = Form.Item;

import ModalFooter from '@totem/components/ModalFooter';
import ModalTitle from '@totem/components/ModalTitle';

const styles = {
  dialog: {
    maxHeight: '80%',
    maxWidth: '850px',
  },
  form: {
    width: '100%',
  },
  formItem: {
    paddingBottom: '0',
    marginBottom: '1rem',
  },
};

interface FormErrors {
  name?: string;
  domain?: string;
}

const CreateOrganizationModal = () => {
  const { userProfile } = useContext(UserProfileContext);
  const { onAction, action, selectedRecord } = useContext(PendingContext);
  const [isSending, setIsSending] = useState<boolean>(false);
  const [errors, setErrors] = useState<FormErrors>({});

  const [orgData, setOrgData] = useState<RequestOrganizationCreate>({
    name: selectedRecord.customer.companyName,
    domain: '',
    type: 'CUSTOMER_OWNED',
    keys: [
      {
        system: 'NetSuite',
        instance: 'IB',
        key: selectedRecord.customer.id,
        uid: '',
        createdByType: 'USER',
        createdByName: userProfile.email,
        createdById: userProfile.id,
        createdAt: dayjs().toISOString(),
      },
    ],
    attributes: [
      {
        name: 'BUILDING_AUTO_CREATE',
        value: 'true',
      },
    ],
  });

  const createOrganization = useCallback(
    async (orgCreate: RequestOrganizationCreate) => {
      if (isSending) {
        return;
      }

      setIsSending(true);

      fetch(`${ORGANIZATION_ENDPOINT}/create`, {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getToken()}`,
        }),
        body: JSON.stringify(orgCreate),
      }).then((res) => {
        const success = CheckResponseShowError(res);
        if (success) {
          onAction('process_organization', { ...selectedRecord });
        }
      });
    },
    [isSending],
  );

  const isValid = (): FormErrors => {
    const formErrors: FormErrors = {};

    if (R.isEmpty(orgData.name)) {
      formErrors.name = 'Please enter an organization name';
    }

    if (R.isEmpty(orgData.domain)) {
      formErrors.domain = 'Please enter a domain name';
    }

    return formErrors;
  };

  const handleSubmit = () => {
    const formErrors = isValid();

    if (R.isEmpty(formErrors)) {
      createOrganization(orgData);
    }

    setErrors(formErrors);
  };

  return (
    <Modal
      open={action === 'create_organization' && selectedRecord !== null}
      onCancel={() => onAction('', null)}
      title={<ModalTitle>Create New Organization</ModalTitle>}
      confirmLoading={false}
      width={'75%'}
      style={styles.dialog}
      footer={
        <ModalFooter>
          <Button onClick={handleSubmit} type={'primary'}>
            Submit
          </Button>
          <Button onClick={() => onAction('', null)}>Close</Button>
        </ModalFooter>
      }
    >
      <Form layout="vertical" style={styles.form}>
        <FormItem
          label="Organization Name"
          colon={false}
          style={styles.formItem}
          validateStatus={R.isNil(errors.name) ? 'success' : 'error'}
          help={R.isNil(errors.name) ? null : errors.name}
        >
          <Input
            name="organizationName"
            value={orgData.name}
            onChange={({
              target: { value },
            }: React.ChangeEvent<HTMLInputElement>) =>
              setOrgData({ ...orgData, name: value })
            }
            type="search"
          />
        </FormItem>

        <FormItem
          label="Domain Name"
          colon={false}
          style={styles.formItem}
          validateStatus={R.isNil(errors.domain) ? 'success' : 'error'}
          help={R.isNil(errors.domain) ? null : errors.domain}
        >
          <Input
            name="domainName"
            value={orgData.domain}
            onChange={({
              target: { value },
            }: React.ChangeEvent<HTMLInputElement>) =>
              setOrgData({ ...orgData, domain: value })
            }
            type="search"
          />
        </FormItem>
      </Form>
    </Modal>
  );
};

export default CreateOrganizationModal;
