import { ApolloClient, InMemoryCache } from '@apollo/client';
import { setContext } from 'apollo-link-context';
import { createUploadLink } from 'apollo-upload-client';
import fetch from 'node-fetch';

import accountUtilities from '@totem/utilities/accountUtilities';
import config from '@totem/utilities/configuration';
import { GRAPHQL_ENDPOINT } from '@totem/utilities/endpoints';

const uploadLink = createUploadLink({ fetch, uri: GRAPHQL_ENDPOINT });

// eslint-disable-next-line id-length
const authLink = setContext((_, { headers }) => {
  const jwtToken = accountUtilities.getToken();
  return {
    headers: {
      ...headers,
      authorization: jwtToken ? `Bearer ${jwtToken}` : '',
    },
  };
});

const client = new ApolloClient({
  cache: new InMemoryCache({
    resultCaching: true,
    typePolicies: {
      UserOrganization: {
        keyFields: false,
      },
    },
  }),
  // @ts-ignore
  link: authLink
    // @ts-ignore
    .concat(uploadLink),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
    },
    query: {
      fetchPolicy: 'network-only',
    },
  },
  connectToDevTools: config.environment !== config.constants.PRODUCTION,
});

export default client;
