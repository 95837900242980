import React, { useCallback, useContext, useState } from 'react';
import { Button, Form, Modal, notification } from 'antd';

import BuildingAutoComplete from '@totem/components/common/autoCompletes/BuildingAutoComplete';
import { EMPTY_ID } from '@totem/components/common/reference/ReferenceSelector';
import AuvikContext from '@totem/components/settings/sections/auvikMapping/AuvikContext';
import {
  RequestAuvikTenantUpdate,
  TenantReportRecord,
} from '@totem/components/settings/sections/auvikMapping/types';
import { LookupReference } from '@totem/components/settings/sections/buildingMapping/types';
import { getToken } from '@totem/utilities/accountUtilities';
import { AUVIK_ENDPOINT } from '@totem/utilities/endpoints';
const FormItem = Form.Item;

import ModalFooter from '@totem/components/ModalFooter';
import ModalTitle from '@totem/components/ModalTitle';
import UserProfileContext from '@totem/components/UserProfileContext';

const styles = {
  form: {
    width: '100%',
  },
  formItem: {
    paddingBottom: '0',
    marginBottom: '1rem',
  },
  indent: {
    marginLeft: '20px',
  },
};

interface Building {
  id: string;
  name: string;
  region: {
    id: string;
    name: string;
  };
}

export interface Props {
  visible: boolean;
  tenantRecord: TenantReportRecord;
}

const AuvikBuildingSelectModal = ({ visible, tenantRecord }: Props) => {
  const { userProfile } = useContext(UserProfileContext);
  const { onActionComplete } = useContext(AuvikContext);
  const [isSending, setIsSending] = useState(false);
  const [selectedBuildingDetails, setSelectedBuildingDetails] =
    useState<Building>(null);
  const [selectedBuilding, setSelectedBuilding] = useState<LookupReference>(
    typeof tenantRecord !== 'undefined' &&
      tenantRecord !== null &&
      typeof tenantRecord.tenant !== 'undefined' &&
      tenantRecord.tenant !== null
      ? { id: tenantRecord.building.id, name: tenantRecord.building.name }
      : { id: EMPTY_ID, name: '' },
  );

  const sendBuildingUpdate = useCallback(
    async (
      orgId: string,
      tenantData: TenantReportRecord,
      building: Building,
    ) => {
      if (isSending) {
        return;
      }

      setIsSending(true);

      const updatedTenant = { ...tenantData.tenant };
      updatedTenant.buildingId = building.id;
      updatedTenant.regionId = building.region.id;
      updatedTenant.organizationId = orgId;

      const payload: RequestAuvikTenantUpdate = {
        apiConfigID: tenantRecord.id,
        tenant: updatedTenant,
      };

      fetch(`${AUVIK_ENDPOINT}/tenants`, {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getToken()}`,
        }),
        body: JSON.stringify(payload),
      })
        .then((res) => {
          if (res.status < 400) {
            onActionComplete(true);
          } else {
            notification.error({
              message: 'Error building mapping!',
              description: 'An error occurred while updating building mapping.',
              duration: 0,
            });
            onActionComplete(false);
          }
        })
        .then(() => {
          setIsSending(false);
        });
    },
    [isSending],
  );

  const handleSubmit = () => {
    if (selectedBuilding.id === EMPTY_ID && selectedBuilding.name !== '') {
      // ERROR - Building Not Selected
      return;
    }
    sendBuildingUpdate(
      userProfile.organization.id,
      tenantRecord,
      selectedBuildingDetails,
    ).then(() => onActionComplete(true));
  };

  const handleBuildingChange = (building: string) => {
    const newBuilding: LookupReference = {
      id: EMPTY_ID,
      name: building,
    };

    setSelectedBuilding(newBuilding);
  };

  const handleBuildingSelect = (buildingData: Building) => {
    const newBuilding: LookupReference = {
      id: buildingData.id,
      name: buildingData.name,
    };
    setSelectedBuildingDetails(buildingData);
    setSelectedBuilding(newBuilding);
  };

  return (
    <Modal
      open={visible}
      onCancel={() => onActionComplete(false)}
      title={<ModalTitle>Map to Building</ModalTitle>}
      confirmLoading={false}
      width={'40%'}
      footer={
        <ModalFooter>
          <Button onClick={() => onActionComplete(false)}>Close</Button>
          <Button onClick={handleSubmit} type="primary">
            Submit
          </Button>
        </ModalFooter>
      }
    >
      <div>
        <b>Name: {tenantRecord.tenant.tenantName}</b>
      </div>
      <div>
        <b>Domain Prefix: {tenantRecord.tenant.tenantDomainPrefix}</b>
      </div>
      <br />
      <br />
      <Form layout="vertical" style={styles.form}>
        <FormItem
          label="Building"
          colon={false}
          style={styles.formItem}
          required
        >
          <BuildingAutoComplete
            value={
              typeof selectedBuilding !== 'undefined' &&
              selectedBuilding !== null &&
              selectedBuilding.name !== null
                ? selectedBuilding.name
                : ''
            }
            onChange={handleBuildingChange}
            onSelect={handleBuildingSelect}
          />
        </FormItem>
      </Form>
    </Modal>
  );
};

export default AuvikBuildingSelectModal;
