import React from 'react';

import Count from '@totem/components/common/Count';
import colors from '@totem/styles/colors';
import { FindingCriticalityCounts as FindingCriticalityCountsType } from '@totem/types/finding';
import { getExactPercentage } from '@totem/utilities/numberUtilities';

import './policyAudits.css';

interface Props {
  findingCriticalityCounts: FindingCriticalityCountsType;
}

const getTotal = (counts: FindingCriticalityCountsType) => {
  return counts.critical + counts.high + counts.medium + counts.low;
};

const PolicyAuditFindingCriticalityCounts = ({
  findingCriticalityCounts,
}: Props) => {
  const { critical, high, medium, low } = findingCriticalityCounts;
  const total = getTotal(findingCriticalityCounts);

  return (
    <>
      <div styleName="graph-header">Findings By Criticality</div>
      <div styleName="graph-container">
        <div styleName="graph-bar-container">
          <div styleName="graph-label health">Critical</div>
          <div styleName="graph-bar">
            <div
              style={{
                width: `${getExactPercentage(total, critical)}%`,
                background: colors.criticality.critical,
                height: '100%',
              }}
            />
          </div>
          <Count count={critical} size="small" />
        </div>
        <div styleName="graph-bar-container">
          <div styleName="graph-label criticality">High</div>
          <div styleName="graph-bar">
            <div
              style={{
                width: `${getExactPercentage(total, high)}%`,
                background: colors.criticality.high,
                height: '100%',
              }}
            />
          </div>
          <Count count={high} size="small" />
        </div>
        <div styleName="graph-bar-container">
          <div styleName="graph-label criticality">Medium</div>
          <div styleName="graph-bar">
            <div
              style={{
                width: `${getExactPercentage(total, medium)}%`,
                background: colors.criticality.medium,
                height: '100%',
              }}
            />
          </div>
          <Count count={medium} size="small" />
        </div>
        <div styleName="graph-bar-container">
          <div styleName="graph-label criticality">Low</div>
          <div styleName="graph-bar">
            <div
              style={{
                width: `${getExactPercentage(total, low)}%`,
                background: colors.criticality.info,
                height: '100%',
              }}
            />
          </div>
          <Count count={low} size="small" />
        </div>
      </div>
    </>
  );
};

export default PolicyAuditFindingCriticalityCounts;
