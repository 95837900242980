import React, { ReactNode, useEffect, useState } from 'react';

import PackageContext from '@totem/components/software/software_package/packageContext';
import { SoftwarePackageResponse } from '@totem/components/software/software_package/types';
import { getToken } from '@totem/utilities/accountUtilities';
import { DEVICES_ENDPOINT } from '@totem/utilities/endpoints';

type Props = {
  packageName: string;
  children?: ReactNode;
};

const SoftwareContainer = ({ packageName, children }: Props) => {
  const [refreshData, setRefreshData] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [data, setData] = useState<SoftwarePackageResponse>(null);
  const [action, setAction] = useState<string>('');
  const [selectedVersion, setSelectedVersion] = useState<string>('');

  useEffect(() => {
    if (refreshData) {
      setRefreshData(false);
      setIsLoading(true);

      const uri = `${DEVICES_ENDPOINT}/software/catalog/${packageName}`;

      fetch(uri, {
        method: 'GET',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
      })
        .then((res) => res.json())
        .then((result: SoftwarePackageResponse) => {
          setData(result);
        })
        .then(() => {
          setIsLoading(false);
        });
    }
  }, [refreshData]);

  const handleAction = (newAction: string) => {
    switch (newAction) {
      case 'key_add':
        setAction(newAction);
        break;
      case 'refresh':
        setRefreshData(true);
        setAction('');
        break;
      default:
        setAction(newAction);
    }
  };

  return (
    <PackageContext.Provider
      value={{
        loading: isLoading,
        data,
        selectedVersion,
        setSelectedVersion,
        action,
        onAction: handleAction,
      }}
    >
      <div>{children}</div>
    </PackageContext.Provider>
  );
};

export default SoftwareContainer;
