import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import * as R from 'ramda';
import {
  ArrayParam,
  DelimitedNumericArrayParam,
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params';

import BasePane from '@totem/components/BasePane';
import DetailSpinner from '@totem/components/common/DetailSpinner';
import PrimaryContentPane from '@totem/components/PrimaryContentPane';
import { GET_FINDINGS } from '@totem/graph/finding';
import { useAssigneeFilter } from '@totem/hooks/useAssigneeFilter';
import { useBuildingFilter } from '@totem/hooks/useBuildingFilter';
import { useConnection } from '@totem/hooks/useConnection';
import { useRegionFilter } from '@totem/hooks/useRegionFilter';
import { FindingsConnection } from '@totem/types/finding';

import ContentLayout from '../ContentLayout';

import PolicyAuditFindingDetailContext from './PolicyAuditFindingDetailContext';
import PolicyAuditFindingDetailExport from './PolicyAuditFindingDetailExport';
import PolicyAuditFindingDetailFilterChips from './PolicyAuditFindingDetailFilterChips';
import PolicyAuditFindingDetailFilters from './PolicyAuditFindingDetailFilters';
import PolicyAuditFindingDetailHeader from './PolicyAuditFindingDetailHeader';
import PolicyAuditFindingDetailTable from './PolicyAuditFindingDetailTable';

import './policyAuditFindingDetail.css';

interface Props {
  location: { search: string; state: any };
}

const TABLE_LIMIT = 50;

const isEmptyOrNil = (value: any): boolean =>
  R.or(R.isEmpty(value), R.isNil(value));

const PolicyAuditFindingDetail = ({ location }: Props) => {
  const { subjectId } = useParams();

  const [input, setInput] = useQueryParams({
    limit: withDefault(NumberParam, TABLE_LIMIT),
    offset: withDefault(NumberParam, 0),
    subjectId: withDefault(ArrayParam, [subjectId]),
    sortBy: StringParam,
    sortOrder: StringParam,
    criticality: DelimitedNumericArrayParam,
    buildingId: ArrayParam,
    regionId: ArrayParam,
    assignee: ArrayParam,
  });

  const handleInput = (update) => {
    setInput(update, 'replace');
  };

  const buildingFilter = useBuildingFilter(input.buildingId);
  const regionFilter = useRegionFilter(input.regionId);
  const assigneeFilter = useAssigneeFilter(input.assignee);

  const { data, loading, error } = useQuery<{ findings: FindingsConnection }>(
    GET_FINDINGS,
    {
      variables: { input },
    },
  );

  const connection = useConnection<FindingsConnection>({
    data,
    accessor: R.path(['findings']),
  });

  useEffect(() => {
    const update = R.reject(isEmptyOrNil, {
      ...input,
      buildingId: R.keys(buildingFilter.applied),
      regionId: R.keys(regionFilter.applied),
      assignee: R.keys(assigneeFilter.applied),
    });

    // TODO: Revisit this @ts-ignore
    // @ts-ignore
    setInput(update, 'replace');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buildingFilter.applied, regionFilter.applied, assigneeFilter.applied]);

  if (!connection || error) {
    return (
      <div styleName="spinner-container">
        <DetailSpinner />
      </div>
    );
  }

  const { totalCount, findings, leastCompliantCounts, criticalityCounts } =
    connection;

  return (
    <ContentLayout pageTitle="Audit Findings">
      <PolicyAuditFindingDetailContext.Provider
        value={{
          input,
          setInput: handleInput,
          totalCount,
          findings,
          loading,
          buildingFilter,
          regionFilter,
          assigneeFilter,
        }}
      >
        <BasePane>
          <PrimaryContentPane>
            <PolicyAuditFindingDetailFilters />
            <div styleName="title">Findings Overview</div>
            <PolicyAuditFindingDetailHeader
              id={subjectId}
              criticalityCounts={criticalityCounts}
              leastCompliantCounts={leastCompliantCounts}
              location={location}
            />
            <div styleName="title middle">Findings</div>
            <div styleName="table-container">
              <div styleName="table-chips-container">
                <PolicyAuditFindingDetailFilterChips />
              </div>
              <div styleName="export-button-container">
                <PolicyAuditFindingDetailExport input={input} />
              </div>
              <PolicyAuditFindingDetailTable />
            </div>
          </PrimaryContentPane>
        </BasePane>
      </PolicyAuditFindingDetailContext.Provider>
    </ContentLayout>
  );
};

export default PolicyAuditFindingDetail;
