import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Button, Form, Input, Modal, notification, Select } from 'antd';
import * as R from 'ramda';

import ModalFooter from '@totem/components/ModalFooter';
import ModalTitle from '@totem/components/ModalTitle';
import { User } from '@totem/components/users/container/types';
import { UPDATE_USER } from '@totem/graph/user';
import { useErrorNotification } from '@totem/hooks/useErrorNotification';
import { UserUpdateInput } from '@totem/types/user';
import {
  getAccountPayloadSync,
  USER_ROLES,
} from '@totem/utilities/accountUtilities';
import authUtilities from '@totem/utilities/authUtilities';
import { removeEqualProperties } from '@totem/utilities/graphqlUtilities';
import { validateEmail } from '@totem/utilities/validation';

const FormItem = Form.Item;
const { Option } = Select;

interface FormErrors {
  email?: string;
  firstName?: string;
  lastName?: string;
}

export interface Props {
  user: User;
  open: boolean;
  onClose: () => void;
}

const styles = {
  form: {
    width: '100%',
  },
  formItem: {
    paddingBottom: '0',
    marginBottom: '1rem',
  },
};

export const validateForm = (input: UserUpdateInput): FormErrors => {
  const formErrors: FormErrors = {};

  if (R.isEmpty(input.email)) {
    formErrors.email = 'Please enter an email';
  }

  if (input.email && !validateEmail(input.email)) {
    formErrors.email = 'Please enter a valid email';
  }

  if (R.isEmpty(input.firstName)) {
    formErrors.firstName = 'Please enter a first name';
  }

  if (R.isEmpty(input.lastName)) {
    formErrors.lastName = 'Please enter a last name';
  }

  return formErrors;
};

export const mapUserToInput = (user: User): UserUpdateInput => ({
  id: user.id,
  role: user.organization.role,
  email: user.email,
  firstName: user.firstName,
  lastName: user.lastName,
});

const UserUpdateModal = ({ user, open, onClose }: Props) => {
  const [input, setInput] = useState<UserUpdateInput>(mapUserToInput(user));
  const [errors, setErrors] = useState<FormErrors>({});

  useEffect(() => {
    if (!open) {
      setInput(mapUserToInput(user));
      setErrors({});
    }
  }, [open, user]);

  const [updateUser, { loading, error }] = useMutation(UPDATE_USER);

  useErrorNotification(error, 'Error Updating User');

  const handleChange = ({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setInput({ ...input, [name]: value });
  };

  const handleSubmit = async () => {
    const formErrors = validateForm(input);

    if (R.isEmpty(formErrors)) {
      await updateUser({
        variables: {
          input: removeEqualProperties(input, user, ['id']),
        },
      }).then(() => onClose());
    } else {
      setErrors(formErrors);
    }
  };

  const userProfile = getAccountPayloadSync();
  const userHasLoggedIn = Boolean(user.lastLoginDate);
  // TODO: Remove @ts-ignore : Token
  // @ts-ignore
  const isOwnUser = user.id === userProfile.id;

  const isAdmin = authUtilities.minimumRequiredRole(
    authUtilities.ROLE_SETS.ORGANIZATION_ADMINISTRATOR,
  );
  const isSysAdmin = authUtilities.minimumRequiredRole(
    authUtilities.ROLE_SETS.SYSTEMS_ADMINISTRATOR,
  );

  const handleRoleSelected = (role: number) => {
    if (role > 2 && isAdmin && !isSysAdmin) {
      notification.error({
        message: 'Permissions Error!',
        description:
          'Administrators cannot grant the System Administrator role!',
        duration: 300,
      });
    } else {
      setInput({ ...input, role });
    }
  };

  return (
    <Modal
      open={open}
      onCancel={onClose}
      title={<ModalTitle>Update User</ModalTitle>}
      confirmLoading={loading}
      footer={
        <ModalFooter>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={handleSubmit} type="primary" loading={loading}>
            Update
          </Button>
        </ModalFooter>
      }
    >
      <Form layout="vertical" style={styles.form}>
        <FormItem label="Role" colon={false} style={styles.formItem}>
          <Select
            onChange={(role) => handleRoleSelected(role)}
            value={input.role}
            disabled={!isAdmin || isOwnUser}
          >
            {USER_ROLES.filter((role) => {
              // TODO: Remove @ts-ignore : Token
              // @ts-ignore
              return role.value <= userProfile.role;
            }).map((role) => (
              <Option key={role.value} value={role.value}>
                {role.label}
              </Option>
            ))}
          </Select>
        </FormItem>
        <FormItem
          label="Email"
          colon={false}
          style={styles.formItem}
          validateStatus={R.isNil(errors.email) ? 'success' : 'error'}
          help={R.isNil(errors.email) ? null : errors.email}
        >
          <Input
            disabled={!isAdmin || userHasLoggedIn}
            name="email"
            value={input.email}
            onChange={handleChange}
            type="search"
          />
        </FormItem>
        <FormItem
          label="First Name"
          colon={false}
          style={styles.formItem}
          validateStatus={R.isNil(errors.firstName) ? 'success' : 'error'}
          help={R.isNil(errors.firstName) ? null : errors.firstName}
        >
          <Input
            name="firstName"
            value={input.firstName}
            onChange={handleChange}
            disabled={!isOwnUser && userHasLoggedIn}
            type="search"
          />
        </FormItem>
        <FormItem
          label="Last Name"
          colon={false}
          style={styles.formItem}
          validateStatus={R.isNil(errors.lastName) ? 'success' : 'error'}
          help={R.isNil(errors.lastName) ? null : errors.lastName}
        >
          <Input
            name="lastName"
            value={input.lastName}
            onChange={handleChange}
            disabled={!isOwnUser && userHasLoggedIn}
            type="search"
          />
        </FormItem>
      </Form>
    </Modal>
  );
};

export default UserUpdateModal;
