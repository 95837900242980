import React, { ReactNode } from 'react';

import { globalStyles } from '@totem/styles/styles';

type Props = {
  children?: ReactNode;
};

const FiltersContainer = ({ children }: Props) => {
  return <div style={globalStyles.filters}>{children}</div>;
};

export default FiltersContainer;
