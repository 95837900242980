import { OrganizationHierarchy } from '@totem/types/organization';
import { emptyId } from '@totem/utilities/userUtilities';

export const getRegion = (
  orgHierarchy: OrganizationHierarchy,
  regionId: string,
) => {
  if (orgHierarchy === null || orgHierarchy.children === null) {
    return null;
  }

  const foundRegion = orgHierarchy.children.find(
    region => region.id === regionId,
  );
  if (typeof foundRegion !== 'undefined') {
    return foundRegion;
  }

  return null;
};

export const getRegions = (orgHierarchy: OrganizationHierarchy) => {
  if (orgHierarchy === null || orgHierarchy.children === null) {
    return null;
  }
  return orgHierarchy.children;
};

export const getBuilding = (
  orgHierarchy: OrganizationHierarchy,
  regionId: string,
  buildingId: string,
) => {
  const region = getRegion(orgHierarchy, regionId);
  if (region !== null && region.children !== null) {
    const foundBuilding = region.children.find(
      building => building.id === buildingId,
    );
    if (typeof foundBuilding !== 'undefined') {
      return foundBuilding;
    }
  }
  return null;
};

export const getControlSystem = (
  orgHierarchy: OrganizationHierarchy,
  regionId: string,
  buildingId: string,
  controlSystemId: string,
) => {
  const building = getBuilding(orgHierarchy, regionId, buildingId);
  if (building !== null && building.children !== null) {
    const foundControlSystem = building.children.find(
      controlSystem => controlSystem.id === controlSystemId,
    );
    if (typeof foundControlSystem !== 'undefined') {
      return foundControlSystem;
    }
  }
  return null;
};

export const getRegionName = (
  orgHierarchy: OrganizationHierarchy,
  regionId: string,
) => {
  if (regionId === emptyId) {
    return 'All';
  }

  const region = getRegion(orgHierarchy, regionId);
  if (region !== null) {
    return region.name;
  }

  return '---';
};

export const getBuildingName = (
  orgHierarchy: OrganizationHierarchy,
  regionId: string,
  buildingId: string,
) => {
  if (buildingId === emptyId) {
    return 'All';
  }

  const building = getBuilding(orgHierarchy, regionId, buildingId);
  if (building !== null) {
    return building.name;
  }

  return '---';
};

export const getControlSystemName = (
  orgHierarchy: OrganizationHierarchy,
  regionId: string,
  buildingId: string,
  controlSystemId: string,
) => {
  if (controlSystemId === emptyId) {
    return 'All';
  }

  const controlSystem = getControlSystem(
    orgHierarchy,
    regionId,
    buildingId,
    controlSystemId,
  );
  if (controlSystem !== null) {
    return controlSystem.name;
  }

  return '---';
};

export const getRegionById = (
  orgHierarchy: OrganizationHierarchy,
  regionId: string,
) => {
  if (
    regionId === emptyId ||
    orgHierarchy === null ||
    typeof orgHierarchy.children === 'undefined' ||
    orgHierarchy.children === null
  ) {
    return null;
  }

  for (
    let regionIdx = 0;
    regionIdx < orgHierarchy.children.length;
    regionIdx++
  ) {
    if (orgHierarchy.children[regionIdx].id === regionId) {
      return orgHierarchy.children[regionIdx];
    }
  }

  return null;
};

export const getRegionNameById = (
  orgHierarchy: OrganizationHierarchy,
  regionId: string,
  defaultValue: string,
) => {
  const region = getRegionById(orgHierarchy, regionId);
  if (region === null) {
    return defaultValue;
  }
  return region.name;
};

export const getBuildingById = (
  orgHierarchy: OrganizationHierarchy,
  buildingId: string,
) => {
  if (
    buildingId === emptyId ||
    orgHierarchy === null ||
    typeof orgHierarchy.children === 'undefined' ||
    orgHierarchy.children === null
  ) {
    return null;
  }

  for (
    let regionIdx = 0;
    regionIdx < orgHierarchy.children.length;
    regionIdx++
  ) {
    const region = orgHierarchy.children[regionIdx];
    if (typeof region.children !== 'undefined' && region.children !== null) {
      for (
        let buildingIdx = 0;
        buildingIdx < region.children.length;
        buildingIdx++
      ) {
        // eslint-disable-next-line max-depth
        if (region.children[buildingIdx].id === buildingId) {
          return region.children[buildingIdx];
        }
      }
    }
  }

  return null;
};

export const getBuildingNameById = (
  orgHierarchy: OrganizationHierarchy,
  buildingId: string,
  defaultValue: string,
) => {
  const building = getBuildingById(orgHierarchy, buildingId);
  if (building === null) {
    return defaultValue;
  }
  return building.name;
};

export const getControlSystemById = (
  orgHierarchy: OrganizationHierarchy,
  controlSystemId: string,
) => {
  if (
    controlSystemId === emptyId ||
    orgHierarchy === null ||
    typeof orgHierarchy.children === 'undefined' ||
    orgHierarchy.children === null
  ) {
    return null;
  }

  for (
    let regionIdx = 0;
    regionIdx < orgHierarchy.children.length;
    regionIdx++
  ) {
    const region = orgHierarchy.children[regionIdx];
    if (typeof region.children !== 'undefined' && region.children !== null) {
      for (
        let buildingIdx = 0;
        buildingIdx < region.children.length;
        buildingIdx++
      ) {
        const building = region.children[buildingIdx];
        // eslint-disable-next-line max-depth
        if (
          typeof building.children !== 'undefined' &&
          building.children !== null
        ) {
          // eslint-disable-next-line max-depth
          for (
            let controlSystemIdx = 0;
            controlSystemIdx < building.children.length;
            controlSystemIdx++
          ) {
            // eslint-disable-next-line max-depth
            if (building.children[controlSystemIdx].id === controlSystemId) {
              return building.children[controlSystemIdx];
            }
          }
        }
      }
    }
  }

  return null;
};

export const getControlSystemNameById = (
  orgHierarchy: OrganizationHierarchy,
  controlSystemId: string,
  defaultValue: string,
) => {
  const controlSystem = getControlSystemById(orgHierarchy, controlSystemId);
  if (controlSystem === null) {
    return defaultValue;
  }
  return controlSystem.name;
};
