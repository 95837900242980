import { ForeignKey } from '@totem/types/common';
import { MIN_DATE_TIME_STRING } from '@totem/types/ticketing';

export const formatBytes = (bytes: number, decimals: number = 2) => {
  if (!+bytes) {
    return '0 Bytes';
  }

  const kb = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const index: number = Math.floor(Math.log(bytes) / Math.log(kb));

  return `${parseFloat((bytes / Math.pow(kb, index)).toFixed(dm))} ${
    sizes[index]
  }`;
};

export const formatDate = (dateIn: string) => {
  if (dateIn === MIN_DATE_TIME_STRING || dateIn === '0001-01-01T00:00:00Z') {
    return '-';
  }

  return dateIn ? new Date(dateIn).toLocaleString() : '-';
};

export const formatDateOnly = (dateIn: string) => {
  if (dateIn === MIN_DATE_TIME_STRING || dateIn === '0001-01-01T00:00:00Z') {
    return '-';
  }

  return dateIn ? new Date(dateIn).toLocaleDateString() : '-';
};

export const getForeignKeyValue = (
  foreignKeys: ForeignKey[],
  system: string,
  instance: string,
) => {
  if (typeof foreignKeys === 'undefined' || foreignKeys === null) {
    return '';
  }

  for (let idx = 0; idx < foreignKeys.length; idx++) {
    if (
      foreignKeys[idx].system === system &&
      foreignKeys[idx].instance === instance
    ) {
      return foreignKeys[idx].key;
    }
  }
  return '';
};

export const toPascalCase = (strIn: string) => {
  return strIn.replace(/(\w)(\w*)/g, (g0, g1, g2) => {
    return g1.toUpperCase() + g2.toLowerCase();
  });
};

export const isNull = (val: any): boolean => {
  if (typeof val === 'undefined') {
    return true;
  }
  if (val === null) {
    return true;
  }
  return false;
};

export const isNotNull = (val: any): boolean => {
  return !isNull(val);
};

export function numberArrayToStringArray(numbers: number[]): string[] {
  // Create a new string array to store the converted values
  const stringArray: string[] = [];

  if (isNotNull(numbers)) {
    // Loop through each number in the original array
    numbers.forEach((number) => {
      // Convert the number to a string and push it to the new array
      stringArray.push(number.toString());
    });
  }

  // Return the string array
  return stringArray;
}
