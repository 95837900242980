import React, { useCallback, useState } from 'react';

import QueueAssignmentTable from '@totem/components/ticketing/details/queueAssignmentTable';
import { QueueAssignment, TicketQueryResult } from '@totem/types/ticketing';
import { getToken } from '@totem/utilities/accountUtilities';
import { TICKET_ENDPOINT } from '@totem/utilities/endpoints';

import '../ticketing.css';

type Props = {
  ticket: TicketQueryResult;
  onTicketUpdated: (TicketQueryResult) => void;
};

const QueueAssignments = ({ ticket, onTicketUpdated }: Props) => {
  const [isSending, setIsSending] = useState(false);

  const sendEndQueueAssignment = useCallback(
    async (queueAssignmentId: string) => {
      if (isSending) {
        return;
      }

      fetch(
        `${TICKET_ENDPOINT}/${ticket.ticket.id}/queueAssignment/${queueAssignmentId}`,
        {
          method: 'DELETE',
          headers: new Headers({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
          }),
        },
      )
        .then(res => res.json())
        .then(result => {
          onTicketUpdated(result);
        })
        .then(() => {
          setIsSending(false);
        });

      setIsSending(true);
    },
    [isSending],
  );

  const handleEndQueueAssignment = (queueAssignment: QueueAssignment) => {
    sendEndQueueAssignment(queueAssignment.id);
  };

  return (
    ticket !== null &&
    ticket.ticket !== null &&
    ticket.ticket.queueAssignments !== null && (
      <QueueAssignmentTable
        ticket={ticket}
        onEndQueueAssignment={handleEndQueueAssignment}
      />
    )
  );
};

export default QueueAssignments;
