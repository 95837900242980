import React, { useContext } from 'react';
import * as R from 'ramda';

import FilterChip from '@totem/components/common/FilterChip';
import {
  PolicyAuditCompletionStatus,
  PolicyAuditsConnectionInput,
} from '@totem/types/policyAudit';
import { controlSystemTypeMap } from '@totem/utilities/controlSystemsUtilities';
import { getHealthStatusStringFromStatus } from '@totem/utilities/healthUtilities';

import PolicyAuditsContext from './PolicyAuditsContext';

import './policyAudits.css';

interface Props {
  input: PolicyAuditsConnectionInput;
  onChange: (input: Partial<PolicyAuditsConnectionInput>) => void;
}

const styles = {
  filterChip: {
    marginLeft: '2rem',
    marginTop: '2rem',
  },
};

const PolicyAuditFilterChips = ({ input, onChange }: Props) => {
  const {
    health,
    completionStatus,
    isLaunched,
    startDate,
    dueDate,
    controlSystemType,
  } = input;

  const { regionFilter, buildingFilter } = useContext(PolicyAuditsContext);

  const handleMultiFilterDelete = (property: string, value: number) => {
    const updatedValue = input[property].filter(val => val !== value);
    onChange({ [property]: updatedValue.length ? updatedValue : null });
  };

  const handleSingleFilterDelete = (property: string) => {
    onChange({ [property]: null });
  };

  return (
    <>
      {health &&
        health.map(status => {
          const label = getHealthStatusStringFromStatus(status);

          return (
            <FilterChip
              key={status}
              label={`Health: ${label}`}
              style={styles.filterChip}
              onClose={() => handleMultiFilterDelete('health', status)}
            />
          );
        })}
      {completionStatus &&
        completionStatus.map(status => {
          const label = PolicyAuditCompletionStatus[status];

          return (
            <FilterChip
              key={status}
              label={`Progress: ${label}`}
              style={styles.filterChip}
              onClose={() =>
                handleMultiFilterDelete('completionStatus', status)
              }
            />
          );
        })}
      {!R.isNil(isLaunched) && (
        <FilterChip
          label={`Launched: ${isLaunched ? 'Is Launched' : 'Not Launched'}`}
          style={styles.filterChip}
          onClose={() => handleSingleFilterDelete('isLaunched')}
        />
      )}
      {!R.isNil(startDate) && (
        <FilterChip
          label={`Start Date: ${
            startDate ? 'Has Start Date' : 'No Start Date'
          }`}
          style={styles.filterChip}
          onClose={() => handleSingleFilterDelete('startDate')}
        />
      )}
      {!R.isNil(dueDate) && (
        <FilterChip
          label={`Start Date: ${dueDate ? 'Has Due Date' : 'No Due Date'}`}
          style={styles.filterChip}
          onClose={() => handleSingleFilterDelete('dueDate')}
        />
      )}
      {!regionFilter.loading &&
        Object.entries(regionFilter.applied).map(([id, name]) => (
          <FilterChip
            key={id}
            label={`Region: ${name}`}
            style={styles.filterChip}
            onClose={() => regionFilter.onRemove(id)}
          />
        ))}
      {!buildingFilter.loading &&
        Object.entries(buildingFilter.applied).map(([id, name]) => (
          <FilterChip
            key={id}
            label={`Building: ${name}`}
            style={styles.filterChip}
            onClose={() => buildingFilter.onRemove(id)}
          />
        ))}
      {controlSystemType &&
        controlSystemType.map(type => {
          const label = controlSystemTypeMap[type]?.text;

          return (
            <FilterChip
              key={type}
              label={`Control System Type: ${label}`}
              style={styles.filterChip}
              onClose={() => handleMultiFilterDelete('controlSystemType', type)}
            />
          );
        })}
    </>
  );
};

export default PolicyAuditFilterChips;
