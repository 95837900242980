import React from 'react';

import BuildingMappingContainer from '@totem/components/settings/sections/buildingMapping/BuildingMappingContainer';
import BuildingMappingTable from '@totem/components/settings/sections/buildingMapping/BuildingMappingTable';

import '../../settings.css';

const BuildingMappingPanel = () => {
  return (
    <div styleName="container">
      <div styleName="header-container">
        <div styleName="header">Building Mapping</div>
      </div>
      <BuildingMappingContainer>
        <BuildingMappingTable />
      </BuildingMappingContainer>
    </div>
  );
};

export default BuildingMappingPanel;
