import React, { useContext } from 'react';

import {
  ControlSystemsConnectionInput,
  ControlSystemType,
} from '@totem/types/controlSystem';
import { controlSystemTypeMap } from '@totem/utilities/controlSystemsUtilities';
import { removeIntegerKeys } from '@totem/utilities/enumUtilities';

import DropdownAutoCompleteFilter from '../common/autoCompleteFilter/DropdownAutoCompleteFilter';
import DropdownFilter from '../common/dropdownFilter/DropdownFilter';

import ControlSystemBackupFilterChips from './ControlSystemBackupFilterChips';
import ControlSystemBackupsContext from './ControlSystemBackupsContext';

import './controlSystemBackups.css';

const CONTROL_SYSTEM_TYPE_FILTERS = Object.values(
  removeIntegerKeys(ControlSystemType),
).map(type => ({
  label: controlSystemTypeMap[type]?.text,
  value: type,
}));

const styles = {
  dropdown: {
    marginRight: '2rem',
  },
};

const ControlSystemBackupFilters = () => {
  const { input, setInput, buildingFilter, regionFilter } = useContext(
    ControlSystemBackupsContext,
  );

  const handleMultiNumberFilterChange = (
    property: keyof ControlSystemsConnectionInput,
    update: string[],
  ) => {
    setInput({
      ...input,
      [property]: update.map(Number),
    });
  };

  return (
    <div styleName="filters">
      <div styleName="filters-title">Backup Report</div>
      <div styleName="filters-body">
        <DropdownFilter
          label="Control System Type"
          options={CONTROL_SYSTEM_TYPE_FILTERS}
          value={input.systemType || []}
          onChange={update =>
            handleMultiNumberFilterChange('systemType', update)
          }
          style={styles.dropdown}
        />
        <DropdownAutoCompleteFilter
          style={styles.dropdown}
          label="Building"
          {...buildingFilter}
        />
        <DropdownAutoCompleteFilter
          style={styles.dropdown}
          label="Region"
          {...regionFilter}
        />
      </div>
      <div styleName="filter-chips-container">
        <ControlSystemBackupFilterChips />
      </div>
    </div>
  );
};

export default ControlSystemBackupFilters;
