import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Form, Modal } from 'antd';
import * as R from 'ramda';

import OrgRoleAutoComplete from '@totem/components/common/remoteAccess/autoCompletes/OrgRoleAutoComplete';
import ModalFooter from '@totem/components/ModalFooter';
import ModalTitle from '@totem/components/ModalTitle';
import { RemoteAccessAuthTenantRole } from '@totem/types/remoteAccess';
import { ReduxStore, RemoteAccessOrgRolesState } from '@totem/types/store';

const FormItem = Form.Item;

interface FormErrors {
  roleName?: string;
}

export interface Props {
  visible: boolean;
  loading: boolean;
  onClose: () => void;
  onSubmit: (role: RemoteAccessAuthTenantRole) => void;
}

const initialRole: RemoteAccessAuthTenantRole = {
  roleId: '',
  name: '',
  description: '',
  buildingId: '',
  regionId: '',
  controlSystemId: '',
};

const styles = {
  form: {
    width: '100%',
  },
  formItem: {
    paddingBottom: '0',
    marginBottom: '1rem',
  },
};

// export const isValidForm = (role: RemoteAccessAuthTenantRole): FormErrors => {
//   const formErrors: FormErrors = {};
//
//   if (R.isEmpty(role.name)) {
//     formErrors.email = 'Please enter a role';
//   }
//
//   return formErrors;
// };

const RemoteAccessUserRoleAddModal = (props: Props) => {
  const remoteAccessOrgRoles = useSelector<
    ReduxStore,
    RemoteAccessOrgRolesState
  >(state => state.remoteAccessOrgRoles);

  const { visible, loading, onClose, onSubmit } = props;

  const [role, setRole] = useState<RemoteAccessAuthTenantRole>(initialRole);
  const [errors, setErrors] = useState<FormErrors>({});

  const getRoleByName = (roleName) => {
    if (remoteAccessOrgRoles !== null) {
      if (remoteAccessOrgRoles.authTenant !== null) {
        const foundRole = remoteAccessOrgRoles.authTenant.roles.find(
          (rol) =>
            rol.name.toLocaleLowerCase() === roleName.toLocaleLowerCase(),
        );
        if (typeof foundRole !== 'undefined') {
          return foundRole;
        }
      }
    }

    return null;
  };

  const isValidForm = (checkRole: RemoteAccessAuthTenantRole): FormErrors => {
    const formErrors: FormErrors = {};

    if (R.isEmpty(checkRole.name)) {
      formErrors.roleName = 'Please enter a role';
    }

    const foundRole = getRoleByName(checkRole.name);
    if (foundRole === null) {
      formErrors.roleName =
        'Please select an existing role.  Selected role was not found for this organization.';
    }

    return formErrors;
  };

  useEffect(() => {
    if (!visible) {
      setErrors({});
      setRole(initialRole);
    }
  }, [visible]);

  const handleSubmit = () => {
    const formErrors = isValidForm(role);

    if (R.isEmpty(formErrors)) {
      onSubmit(role);
    }

    setErrors(formErrors);
  };

  const handleRoleNameChange = (name: string) => {
    const foundRole = getRoleByName(name);
    if (foundRole !== null) {
      setRole(foundRole);
      setErrors({});
    } else {
      setRole({ ...role, name });
    }
  };

  return (
    <Modal
      open={visible}
      onCancel={onClose}
      title={<ModalTitle>Add Role</ModalTitle>}
      okText="Add"
      confirmLoading={loading}
      footer={
        <ModalFooter>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={handleSubmit} loading={loading} type="primary">
            Add
          </Button>
        </ModalFooter>
      }
    >
      <Form layout="vertical" style={styles.form}>
        <FormItem
          label=" Role"
          colon={false}
          style={styles.formItem}
          validateStatus={R.isNil(errors.roleName) ? 'success' : 'error'}
          help={R.isNil(errors.roleName) ? null : errors.roleName}
        >
          <OrgRoleAutoComplete
            value={role.name}
            onChange={handleRoleNameChange}
          />
        </FormItem>
      </Form>
    </Modal>
  );
};

export default RemoteAccessUserRoleAddModal;
