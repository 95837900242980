import React, { useState } from 'react';
import { QuestionOutlined } from '@ant-design/icons';
import * as R from 'ramda';

import Tooltip from '@totem/components/common/tooltip/Tooltip';
import colors from '@totem/styles/colors';
import fonts from '@totem/styles/fonts';
import { getGrade, getGradeColor } from '@totem/utilities/gradeUtilities';

type GradeSize = 'small' | 'large' | 'massive';

type Props = {
  score?: number;
  size?: GradeSize;
  style?: React.CSSProperties;
  tooltip?: string;
};

const styles = {
  grade: {
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: fonts.fontXl,
    fontWeight: 'bold',
  },
  icon: {
    color: colors.neutral.dim,
  },
  small: {
    width: '2.4rem',
    height: '2.4rem',
  },
  large: {
    width: '3.2rem',
    height: '3.2rem',
  },
  massive: {
    width: '13rem',
    height: '13rem',
    fontSize: '7rem',
  },
};

const Grade = ({ score, size, style, tooltip }: Props) => {
  const [hovered, setHovered] = useState<boolean>(false);

  const grade = R.isNil(score) ? (
    <QuestionOutlined style={styles.icon} />
  ) : (
    getGrade(score)
  );

  const gradeColor = getGradeColor(score);

  const content = (
    <div
      // @ts-ignore
      style={{
        ...styles.grade,
        ...(styles[size] ? styles[size] : styles.small),
        backgroundColor: gradeColor,
        ...style,
        ...(tooltip && {
          cursor: 'pointer',
        }),
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {grade}
    </div>
  );

  return tooltip ? (
    <Tooltip title={tooltip} placement="right" open={tooltip && hovered}>
      {content}
    </Tooltip>
  ) : (
    content
  );
};

export default Grade;
