import React, { useContext } from 'react';
import { PlusCircleOutlined } from '@ant-design/icons';
import { Button } from 'antd';

import EndOfLifeContext from '@totem/components/endoflife/EndOfLifeContext';

const styles = {
  iconButton: {
    margin: '0 2px',
  },
};

import { getEmptyRecord } from '@totem/components/endoflife/utilities';

import './endOfLife.css';

const EndOfLifeHeader = () => {
  const { onAction, totalRecords } = useContext(EndOfLifeContext);
  return (
    <div styleName="card-header-smaller">
      {totalRecords} Products &nbsp;&nbsp;
      <Button
        type="primary"
        icon={<PlusCircleOutlined />}
        shape="circle"
        onClick={() => onAction('edit', getEmptyRecord())}
        style={styles.iconButton}
      />
    </div>
  );
};

export default EndOfLifeHeader;
