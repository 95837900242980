import React, { useContext, useState } from 'react';
import { Tabs } from 'antd';

import BasePane from '@totem/components/BasePane';
import ContentLayout from '@totem/components/ContentLayout';
import DeviceContainer from '@totem/components/devices/devicesContainer/DeviceContainer';
import DeviceExport from '@totem/components/devices/devicesContainer/DeviceExport';
import DeviceSearch from '@totem/components/devices/devicesContainer/DeviceSearch';
import PrimaryContentPane from '@totem/components/PrimaryContentPane';
import GatewayDeviceOrgTable from '@totem/components/remoteAccess/roleUsers/rootRole/GatewayDeviceOrgTable';
import TabTitle from '@totem/components/TabTitle';
import UserProfileContext from '@totem/components/UserProfileContext';
import {
  DEVICES_CROSS_ORG_PAGINATION_ENDPOINT,
  GATEWAY_CROSS_ORG_PAGINATION_ENDPOINT,
} from '@totem/utilities/endpoints';
import { getUserRole } from '@totem/utilities/userUtilities';

const styles = {
  totals: {
    fontSize: '16px',
    marginRight: '20px',
  },
  tabContainer: {
    paddingRight: '2rem',
    paddingLeft: '2rem',
  },
};

const CrossOrgRemoteAccessGatewaysPage = () => {
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const { userProfile } = useContext(UserProfileContext);
  const isSysAdmin = getUserRole(userProfile) === 3;

  return (
    <ContentLayout>
      <BasePane>
        <PrimaryContentPane>
          <DeviceContainer
            deviceEndPoint={GATEWAY_CROSS_ORG_PAGINATION_ENDPOINT}
            deviceFiltersEndPoint={`${DEVICES_CROSS_ORG_PAGINATION_ENDPOINT}/filters`}
            onRecordTotalChanged={setTotalRecords}
          >
            <Tabs
              defaultActiveKey="Report"
              tabBarExtraContent={
                <span>
                  <b style={styles.totals}>{totalRecords} Gateways</b>
                  {isSysAdmin && (
                    <DeviceExport
                      deviceExportEndPoint={
                        GATEWAY_CROSS_ORG_PAGINATION_ENDPOINT
                      }
                    />
                  )}
                </span>
              }
              style={styles.tabContainer}
            >
              <Tabs.TabPane tab={<TabTitle>Gateways</TabTitle>} key="details">
                <DeviceSearch />
                <br />
                <GatewayDeviceOrgTable />
              </Tabs.TabPane>
            </Tabs>
          </DeviceContainer>
        </PrimaryContentPane>
      </BasePane>
    </ContentLayout>
  );
};

export default CrossOrgRemoteAccessGatewaysPage;
