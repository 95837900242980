import React, { ReactNode, useState } from 'react';
import { Link } from 'react-router-dom';
import { AppstoreOutlined } from '@ant-design/icons';
import { Flex } from 'antd';

import { EMPTY_ID } from '@totem/components/common/reference/ReferenceSelector';
import { ComponentInstance } from '@totem/components/home/lib/types';
import InfoWidgetContentPane from '@totem/components/home/widgets/InfoWidgetContentPane';
import InfoWidgetRemoveButton from '@totem/components/home/widgets/InfoWidgetRemoveButton';
import WidgetCardBody from '@totem/components/home/widgets/WidgetCardBody';
import WidgetCardHeader from '@totem/components/home/widgets/WidgetCardHeader';
import WidgetContentPane from '@totem/components/home/widgets/WidgetContentPane';
import WidgetContentPaneNoTitle from '@totem/components/home/widgets/WidgetContentPaneNoTitle';
import WidgetContext from '@totem/components/home/widgets/WidgetContext';
import WidgetRemoveButton from '@totem/components/home/widgets/WidgetRemoveButton';

import '../home.css';
import InfoWidgetCard from "@totem/components/home/widgets/InfoWidgetCard";

type Props = {
  children?: ReactNode;
  page?: string;
  pageNumber?: string;
  componentInstance: ComponentInstance;
  onRemoveItem?: (componentId: string) => void;
};

const Widget = ({
  children,
  componentInstance,
  onRemoveItem,
  page,
  pageNumber,
}: Props) => {
  const [title, setTitle] = useState<ReactNode>(<span />);

  const getTitle = (instance: ComponentInstance) => {
    if (
      instance.componentInstanceId !== EMPTY_ID &&
      typeof page !== 'undefined' &&
      page !== null &&
      typeof pageNumber !== 'undefined' &&
      pageNumber !== null
    ) {
      return (
        <Flex justify={'flex-start'} align={'center'} gap={'middle'}>
          <AppstoreOutlined className={'dashboardWidgetDraggable'} />
          <Link
            to={`/dashboard/componentInstance/${page}/${pageNumber}/${instance.componentInstanceId}`}
            target={instance.componentInstanceId}
          >
            {instance.title}
          </Link>
        </Flex>
      );
    }
    return <span>{instance.title}</span>;
  };

  const getWidgetContainer = (compInst: ComponentInstance) => {
    switch (compInst.style) {
      case 'Info Widget':
        return (
          <InfoWidgetCard title={compInst.title}>
            <WidgetContext.Provider
              value={{
                componentInstance,
                onSetTitle: setTitle,
              }}
            >
              {typeof onRemoveItem === 'function' && (
                <InfoWidgetRemoveButton
                  onRemoveItem={onRemoveItem}
                  componentInstance={componentInstance}
                />
              )}
              {children}
            </WidgetContext.Provider>
          </InfoWidgetCard>
        );
      case 'No Format':
        return (
          <WidgetContentPaneNoTitle>
            <WidgetContext.Provider
              value={{
                componentInstance,
                onSetTitle: setTitle,
              }}
            >
              {typeof onRemoveItem === 'function' && (
                <WidgetRemoveButton
                  onRemoveItem={onRemoveItem}
                  componentInstance={componentInstance}
                />
              )}
              {children}
            </WidgetContext.Provider>
          </WidgetContentPaneNoTitle>
        );
      default:
        return (
          <WidgetContentPane>
            <WidgetContext.Provider
              value={{
                componentInstance,
                onSetTitle: setTitle,
              }}
            >
              <WidgetCardHeader
                title={getTitle(componentInstance)}
                options={title !== null ? title : <span />}
                onRemoveItem={onRemoveItem}
                componentInstance={componentInstance}
              />
              <WidgetCardBody>{children}</WidgetCardBody>
            </WidgetContext.Provider>
          </WidgetContentPane>
        );
    }
  };

  return getWidgetContainer(componentInstance);
};

export default Widget;
