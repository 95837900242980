import React, { ReactNode } from 'react';

import './cve.css';

type Props = {
  title: string;
  children?: ReactNode;
};

const CveInformationValue = ({ title, children }: Props) => {
  return (
    <div styleName="cve-information-value-container">
      <span styleName="cve-information-value-title">{title}: </span>
      <span styleName="cve-information-value-value">{children}</span>
    </div>
  );
};

export default CveInformationValue;
