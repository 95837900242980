import React, { useContext } from 'react';
import { EditOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { TablePaginationConfig } from 'antd/es/table';
import { ColumnProps } from 'antd/lib/table';
import { SorterResult } from 'antd/lib/table/interface';

import { DeviceMapping } from '@totem/components/administration/zoneMappings/types';
import ZoneMappingContext from '@totem/components/administration/zoneMappings/ZoneMappingContext';
import Table from '@totem/components/common/table/Table';
import Tooltip from '@totem/components/common/tooltip/Tooltip';
import UserProfileContext from '@totem/components/UserProfileContext';
import {
  getBuildingNameById,
  getControlSystemNameById,
  getRegionNameById,
} from '@totem/utilities/organization';
import { stringifyArray } from '@totem/utilities/tableFilterUtilities';

const ZoneMappingTable = () => {
  const { userProfile } = useContext(UserProfileContext);
  const {
    data,
    loading,
    input,
    setInput,
    totalRecords,
    filterOptions,
    organizationHierarchy,
    onAction,
  } = useContext(ZoneMappingContext);

  const canEdit = userProfile.organization.role >= 3;

  const stringsToFilterOptions = (vals: string[]) => {
    if (vals === null) {
      return [];
    }

    return vals.sort().map(val => {
      return {
        text: val,
        value: val,
      };
    });
  };

  const columns: ColumnProps<DeviceMapping>[] = [
    {
      title: 'Sensor',
      dataIndex: 'match.sensor',
      key: 'sensor',
      showSorterTooltip: true,
      render: (_, record: DeviceMapping) => <>{record.match.sensor}</>,
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        compA.match.sensor === compB.match.sensor
          ? 0
          : compA.match.sensor > compB.match.sensor
          ? 1
          : -1,
      filterMultiple: true,
      filteredValue: stringifyArray(input.sensor),
      filters: stringsToFilterOptions(filterOptions.sensor),
    },
    {
      title: 'Zone',
      dataIndex: 'match.zone',
      key: 'zone',
      showSorterTooltip: true,
      render: (_, record: DeviceMapping) => <>{record.match.zone}</>,
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        compA.match.zone === compB.match.zone
          ? 0
          : compA.match.zone > compB.match.zone
          ? 1
          : -1,
      filterMultiple: true,
      filteredValue: stringifyArray(input.zone),
      filters: stringsToFilterOptions(filterOptions.zone),
    },
    {
      title: 'Region',
      dataIndex: 'data.regionId',
      key: 'regionId',
      showSorterTooltip: true,
      render: (_, record: DeviceMapping) => (
        <>
          {getRegionNameById(organizationHierarchy, record.data.regionId, '')}
        </>
      ),
    },
    {
      title: 'Building',
      dataIndex: 'data.buildingId',
      key: 'buildingId',
      showSorterTooltip: true,
      render: (_, record: DeviceMapping) => (
        <>
          {getBuildingNameById(
            organizationHierarchy,
            record.data.buildingId,
            '',
          )}
        </>
      ),
    },
    {
      title: 'Control System',
      dataIndex: 'data.controlSystemId',
      key: 'controlSystemId',
      showSorterTooltip: true,
      render: (_, record: DeviceMapping) => (
        <>
          {getControlSystemNameById(
            organizationHierarchy,
            record.data.controlSystemId,
            '',
          )}
        </>
      ),
    },
    {
      title: 'Is Monitored',
      dataIndex: 'data.isMonitored',
      key: 'isMonitored',
      showSorterTooltip: true,
      render: (_, record: DeviceMapping) => (
        <>{record.data.isMonitored ? 'Yes' : 'No'}</>
      ),
    },
    {
      title: 'Ticket for New Device',
      dataIndex: 'data.createTicketOnNewDevice',
      key: 'createTicketOnNewDevice',
      showSorterTooltip: true,
      render: (_, record: DeviceMapping) => (
        <>{record.data.createTicketOnNewDevice ? 'Yes' : 'No'}</>
      ),
    },
    {
      title: 'Actions',
      width: 80,
      align: 'center',
      dataIndex: 'additionalOptions',
      key: 'additionalOptions',
      render: (_, record: DeviceMapping) => (
        <div className="center-table-cell">
          <Tooltip title="Edit" placement="top">
            <Button
              disabled={!canEdit}
              type="primary"
              shape="circle"
              icon={<EditOutlined />}
              onClick={() => onAction('showEditMapping', record)}
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  const handleTableChange = (
    updatedPagination: TablePaginationConfig,
    filters: SorterResult<DeviceMapping>,
    sorter,
  ) => {
    const { ...params } = filters;

    let sortDir: string = sorter.order === 'descend' ? '-1' : '1';
    if (typeof sorter.order === 'undefined' || sorter.order === null) {
      if (
        typeof input.sortDirection !== 'undefined' &&
        input.sortDirection !== null
      ) {
        sortDir = input.sortDirection;
      }
    }

    let sortField = input.sortField ? input.sortField : 'match.sensor';
    if (
      typeof sorter.field !== 'undefined' &&
      typeof sorter.order !== 'undefined'
    ) {
      sortField = sorter.field;
    }

    // @ts-ignore
    setInput({
      ...input,
      ...params,
      pageSize: updatedPagination.pageSize,
      page: updatedPagination.current,
      sortField,
      sortDirection: sortDir,
    });
  };

  return (
    <Table
      showSorterTooltip
      columns={columns}
      dataSource={data}
      loading={loading}
      onChange={handleTableChange}
      pagination={{
        current: input.page,
        pageSize: input.pageSize,
        total: totalRecords,
        showSizeChanger: true,
      }}
      rowKey={record => record.id}
    />
  );
};

export default ZoneMappingTable;
