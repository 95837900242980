import React, { useContext, useEffect, useState } from 'react';
import { Progress } from 'antd';

import Count from '@totem/components/common/Count';
import AnalysisContext from '@totem/components/surveyV2/analysis/AnalysisContext';
import SubTitle from '@totem/components/surveyV2/SubTitle';
import colors from '@totem/styles/colors';
import { isNotNull } from '@totem/utilities/common';

import '../survey.css';

const styles = {
  progress: {
    marginTop: '1rem',
  },
  compliantCount: {
    marginTop: '0.5rem',
    background: colors.neutral.ash,
  },
  nonCompliantCount: {
    marginTop: '0.5rem',
    background: colors.neutral.white,
    border: `2px solid ${colors.criticality.high}`,
  },
};
const LevelOfCompliance = () => {
  const { filteredData } = useContext(AnalysisContext);
  const [compiantTotal, setCompliantTotal] = useState<number>(0);
  const [noncompiantTotal, setNonCompliantTotal] = useState<number>(0);
  const total = compiantTotal + noncompiantTotal;

  useEffect(() => {
    if (isNotNull(filteredData)) {
      let totalCompiant = 0;
      let totalNonCompliant = 0;

      for (let idx = 0; idx < filteredData.length; idx++) {
        totalCompiant += filteredData[idx].compliant;
        totalNonCompliant += filteredData[idx].noncompliant;
      }

      setCompliantTotal(totalCompiant);
      setNonCompliantTotal(totalNonCompliant);
    }
  }, [filteredData]);

  return (
    <div styleName="overview-section compliance">
      <SubTitle>Level of Compliance</SubTitle>
      <div styleName="overview-content">
        <div styleName="overview-content-container">
          <div styleName="graph-title">Compliant vs Non-Compliant Policies</div>
          <div styleName="policy-counts-container">
            <div>
              <div>Compliant</div>
              <Count count={compiantTotal} style={styles.compliantCount} />
            </div>
            <div styleName="justify-right">
              <div>Non-Compliant</div>
              <Count
                count={noncompiantTotal}
                style={styles.nonCompliantCount}
              />
            </div>
          </div>
          <Progress
            percent={(compiantTotal / total) * 100}
            showInfo={false}
            strokeColor={colors.neutral.ash}
            size={['100%', 25]}
            trailColor={
              !noncompiantTotal ? colors.neutral.ash : colors.criticality.high
            }
            style={styles.progress}
          />
        </div>
      </div>
    </div>
  );
};

export default LevelOfCompliance;
