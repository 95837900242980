import actionTypes from '@totem/actions/actionTypes';

import initialState from './initialState';

export default function remoteAccessOrgRolesReducer(
  state = initialState.remoteAccessOrgRoles,
  action,
) {
  switch (action.type) {
    case actionTypes.REQUEST_REMOTE_ACCESS_ORG_ROLES_SUCCESS: {
      return action.data ? action.data : state;
    }
    case actionTypes.REQUEST_REMOTE_ACCESS_ROLE_ADD_SUCCESS: {
      return action.data ? action.data : state;
    }
    case actionTypes.REQUEST_REMOTE_ACCESS_ORG_ROLES_FAILED: {
      return initialState.remoteAccessOrgRoles;
    }
    default: {
      return state;
    }
  }
}
