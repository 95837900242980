import React, { useContext } from 'react';

import FilterChip from '@totem/components/common/FilterChip';
import TicketingContext from '@totem/components/ticketing/listContainer/ticketingContainerContext';
import {
  TicketingConnectionInput,
  TicketingFilterOptions,
} from '@totem/types/ticketing';

import '../ticketing.css';
const styles = {
  filterChip: {
    marginLeft: '2rem',
    marginTop: '2rem',
  },
};
type Props = {
  filterOptions: TicketingFilterOptions;
};

const TicketingListContainerFilterChips = () => {
  const { input, setInput, filterOptions } = useContext(TicketingContext);

  const { type, status, category, priority, buildingId } = input;

  const handleMultiFilterDelete = (
    property: keyof TicketingConnectionInput,
    value: string,
  ) => {
    // @ts-ignore
    const updatedValue = input[property].filter(val => val !== value);
    setInput({
      ...input,
      [property]: updatedValue.length ? updatedValue : null,
    });
  };

  const GetOptionNameFromValue = (option: string, value: string) => {
    switch (option) {
      case 'Building':
        for (let idx = 0; idx < filterOptions.building.length; idx++) {
          if (filterOptions.building[idx].value === value) {
            return filterOptions.building[idx].name;
          }
        }
        break;
      default:
        return '';
    }
    return '';
  };

  return (
    <>
      {type &&
        type.map(option => {
          return (
            <FilterChip
              key={option}
              label={`Ticket Type: ${option}`}
              style={styles.filterChip}
              onClose={() => handleMultiFilterDelete('type', option)}
            />
          );
        })}
      {category &&
        category.map(option => {
          return (
            <FilterChip
              key={option}
              label={`Category: ${option}`}
              style={styles.filterChip}
              onClose={() => handleMultiFilterDelete('category', option)}
            />
          );
        })}
      {buildingId &&
        buildingId.map(option => {
          return (
            <FilterChip
              key={option}
              label={`Building: ${GetOptionNameFromValue('Building', option)}`}
              style={styles.filterChip}
              onClose={() => handleMultiFilterDelete('buildingId', option)}
            />
          );
        })}
      {priority &&
        priority.map(option => {
          return (
            <FilterChip
              key={option}
              label={`Priority: ${option}`}
              style={styles.filterChip}
              onClose={() => handleMultiFilterDelete('priority', option)}
            />
          );
        })}
      {status &&
        status.map(option => {
          return (
            <FilterChip
              key={option}
              label={`Status: ${option}`}
              style={styles.filterChip}
              onClose={() => handleMultiFilterDelete('status', option)}
            />
          );
        })}
    </>
  );
};

export default TicketingListContainerFilterChips;
