// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --font-xxl: 2rem; /* 20px */
  --font-xl: 1.8rem; /* 18px */
  --font-lg: 1.6rem; /* 16px */
  --font-md: 1.4rem; /* 14px */
  --font-sm: 1.2rem; /* 12px */
  --font-xs: 1rem; /* 10px */
  --font-xxs: 0.9rem;
}
`, "",{"version":3,"sources":["webpack://./client/styles/fonts.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB,EAAE,SAAS;EAC3B,iBAAiB,EAAE,SAAS;EAC5B,iBAAiB,EAAE,SAAS;EAC5B,iBAAiB,EAAE,SAAS;EAC5B,iBAAiB,EAAE,SAAS;EAC5B,eAAe,EAAE,SAAS;EAC1B,kBAAkB;AACpB","sourcesContent":[":root {\n  --font-xxl: 2rem; /* 20px */\n  --font-xl: 1.8rem; /* 18px */\n  --font-lg: 1.6rem; /* 16px */\n  --font-md: 1.4rem; /* 14px */\n  --font-sm: 1.2rem; /* 12px */\n  --font-xs: 1rem; /* 10px */\n  --font-xxs: 0.9rem;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
