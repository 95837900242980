import { createContext } from 'react';

import { NetworkInterface } from '@totem/components/devices/network/types';

interface Context {
  deviceId: string;
  loading: boolean;
  data: NetworkInterface[];
  action: string;
  actionItem: NetworkInterface;
  onAction?: (action: string, item: NetworkInterface) => void;
}

export default createContext<Context>({
  deviceId: '',
  loading: false,
  data: null,
  action: '',
  actionItem: null,
  onAction: () => {},
});
