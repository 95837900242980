import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { EMPTY_ID } from '@totem/components/common/reference/ReferenceSelector';
import ContentLayout from '@totem/components/ContentLayout';
import {
  ComponentInstance,
  ComponentLayout,
} from '@totem/components/home/lib/types';
import Widget from '@totem/components/home/widgets/Widget';
import WidgetInstance from '@totem/components/home/widgets/WidgetInstance';
import { getToken } from '@totem/utilities/accountUtilities';
import { WIDGET_LAYOUT_ENDPOINT } from '@totem/utilities/endpoints';

import './instanceOverrides.css';

const ComponentInstancePage = () => {
  const { page, pageNumber, componentInstanceId } = useParams();
  const [componentInstance, setComponentInstance] =
    useState<ComponentInstance>(null);

  useEffect(() => {
    fetch(`${WIDGET_LAYOUT_ENDPOINT}/${page}/${pageNumber}`, {
      method: 'GET',
      headers: new Headers({
        Authorization: `Bearer ${getToken()}`,
      }),
    })
      .then((res) => res.json())
      .then((result: ComponentLayout) => {
        for (let idx = 0; idx < result.components.length; idx++) {
          if (
            result.components[idx].componentInstanceId === componentInstanceId
          ) {
            const instance = result.components[idx];
            instance.componentInstanceId = EMPTY_ID;
            instance.geometry.y = 0;
            setComponentInstance(instance);

            window.dispatchEvent(new Event('resize'));
          }
        }
      });
  }, [page, pageNumber, componentInstanceId]);

  const CreateDashboardWidget = () => {
    return (
      <div key={componentInstance.geometry.i} styleName="widget-static">
        <Widget componentInstance={componentInstance}>
          <WidgetInstance componentInstance={componentInstance} />
        </Widget>
      </div>
    );
  };

  return (
    <ContentLayout
      pageTitle={
        typeof componentInstance !== 'undefined' && componentInstance !== null
          ? componentInstance.title
          : ''
      }
    >
      {typeof componentInstance !== 'undefined' &&
        componentInstance !== null && (
          <div styleName="primary-content-pane-page">
            {CreateDashboardWidget()}
          </div>
        )}
    </ContentLayout>
  );
};

export default ComponentInstancePage;
