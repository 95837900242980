import actionTypes from './actionTypes';

export const requestUploadFiles = data => ({
  type: actionTypes.REQUEST_UPLOAD_FILES,
  data,
});

export const uploadFilesSuccess = data => ({
  type: actionTypes.UPLOAD_FILES_SUCCESS,
  data,
});

export const uploadFilesFailure = () => ({
  type: actionTypes.UPLOAD_FILES_FAILURE,
});
