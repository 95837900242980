import React, { ReactNode, useEffect, useState } from 'react';

import { WorkItem } from '@totem/components/surveyV2/work/types';
import WorkContext from '@totem/components/surveyV2/work/WorkContext';
import { getToken } from '@totem/utilities/accountUtilities';
import { V2_SURVEY_ENDPOINT } from '@totem/utilities/endpoints';

type Props = {
  children?: ReactNode;
};

const WorkContainer = ({ children }: Props) => {
  const [refreshData, setRefreshData] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [data, setData] = useState<WorkItem[]>([]);

  useEffect(() => {
    if (refreshData) {
      setRefreshData(false);

      setIsLoading(true);

      fetch(`${V2_SURVEY_ENDPOINT}/work`, {
        method: 'GET',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
      })
        .then((res) => res.json())
        .then((result: WorkItem[]) => {
          setData(result);
        })
        .then(() => {
          setIsLoading(false);
        });
    }
  }, [refreshData]);

  return (
    <WorkContext.Provider
      value={{
        data,
        setData,
        loading: isLoading,
        setIsLoading,
      }}
    >
      <div>{children}</div>
    </WorkContext.Provider>
  );
};

export default WorkContainer;
