import { Region } from '@totem/components/buildingSummary/types';

export interface Address {
  addressOne: string;
  addressTwo: string;
  city: string;
  state: string;
  zipCode: string;
  country: string;
  location: {
    lat: number;
    lng: number;
  };
}

export interface AddEditBuilding {
  id: string;
  name: string;
  type: string;
  squareFootage: number;
  address: Address;
  region: Region;
}

export const getNewBuildingData = (): AddEditBuilding => {
  return {
    id: '',
    name: '',
    type: '',
    squareFootage: 0,
    address: {
      addressOne: '',
      addressTwo: '',
      city: '',
      state: '',
      zipCode: '',
      country: '',
      location: {
        lat: 0,
        lng: 0,
      },
    },
    region: {
      id: '',
      name: '',
    },
  };
};
