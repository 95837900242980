import React, { useCallback, useEffect, useState } from 'react';
import { Button, Modal, Tree } from 'antd';

import { OrganizationHierarchy } from '@totem/types/organization';
import {
  DataAccessGroup,
  DataAccessItem,
  DataAccessPermissionUpdate,
  User,
} from '@totem/types/user';
import { getToken } from '@totem/utilities/accountUtilities';
import { USERS_ENDPOINT } from '@totem/utilities/endpoints';
import {
  emptyId,
  GetNextPermissionsGroup,
  hasDataAccessPermissionsTo,
} from '@totem/utilities/userUtilities';

type Props = {
  onClose: () => void;
  visible: boolean;
  user: User;
};

const DataAccessPermissionsTreeModal = ({ user, visible, onClose }: Props) => {
  const [treeExpandedKeys, setTreeExpandedKeys] = useState<any>([]);
  const [isSending, setIsSending] = useState(false);
  const [organizationHierarchy, setOrganizationHierarchy] = useState<
    OrganizationHierarchy
  >(null);
  const [userRestrictions, setUserRestrictions] = useState<DataAccessGroup[]>(
    [],
  );
  const [currentRestrictions, setCurrentRestrictions] = useState<
    DataAccessGroup[]
  >([]);

  useEffect(() => {
    fetch(`${USERS_ENDPOINT}/${user.id}/dataAccessPermissions`, {
      method: 'GET',
      headers: new Headers({
        Authorization: `Bearer ${getToken()}`,
      }),
    })
      .then(res => res.json())
      .then((result: DataAccessGroup[]) => {
        setUserRestrictions(result);
      });
  }, []);

  useEffect(() => {
    fetch(`${USERS_ENDPOINT}/dataAccessPermissions`, {
      method: 'GET',
      headers: new Headers({
        Authorization: `Bearer ${getToken()}`,
      }),
    })
      .then(res => res.json())
      .then((result: DataAccessGroup[]) => {
        setCurrentRestrictions(result);
      });
  }, []);

  useEffect(() => {
    fetch(`${USERS_ENDPOINT}/organizationHierarchy`, {
      method: 'GET',
      headers: new Headers({
        Authorization: `Bearer ${getToken()}`,
      }),
    })
      .then(res => res.json())
      .then((result: OrganizationHierarchy) => {
        setOrganizationHierarchy(result);
      });
  }, []);

  const sendDataPermissionsUpdate = useCallback(
    async (request: DataAccessPermissionUpdate) => {
      if (isSending) {
        return;
      }

      fetch(`${USERS_ENDPOINT}/${user.id}/dataAccessPermissions`, {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getToken()}`,
        }),
        body: JSON.stringify(request),
      })
        .then(res => res.json())
        .then(result => {
          setUserRestrictions(result);
        })
        .then(() => {
          setIsSending(false);
        });

      setIsSending(true);
    },
    [isSending],
  );

  const footerContent = (
    <Button key="ok" type="primary" onClick={onClose}>
      Ok
    </Button>
  );

  const title = `Data Permissions: ${user.email}`;

  const onTreeItemExpanded = (expandedKeys: any[]) => {
    setTreeExpandedKeys(expandedKeys);
  };

  const buildControlSystems = (
    parentKey: string,
    controlSystemRestrictions: DataAccessItem[],
    controlSystemHierarcy: OrganizationHierarchy[],
    regionId: string,
    buildingId: string,
  ) => {
    const controlSystemsTree = controlSystemHierarcy.map(controlSystem => {
      const canManage = hasDataAccessPermissionsTo(currentRestrictions, {
        regionId,
        buildingId,
        controlSystemId: controlSystem.id,
      });

      return {
        title: controlSystem.name,
        key: `${parentKey}-C:${controlSystem.id}`,
        checkable: true,
        disabled: !canManage,
      };
    });

    return controlSystemsTree;
  };

  const buildStaticControlSystemOptions = (
    parentKey: string,
    building: DataAccessItem,
    buildingHierarcy: OrganizationHierarchy,
    regionId: string,
  ) => {
    let controlSystemGroup;
    if (typeof building !== 'undefined') {
      controlSystemGroup = building.children.find(
        gr => gr.name === 'Control System' || gr.name === 'ControlSystem',
      );

      const canManage = hasDataAccessPermissionsTo(currentRestrictions, {
        regionId,
        buildingId: building.id,
        controlSystemId: emptyId,
      });

      let controlSystems = [];

      if (typeof controlSystemGroup !== 'undefined') {
        if (
          controlSystemGroup.accessType !== 'All' &&
          controlSystemGroup.accessType !== 'None'
        ) {
          controlSystems = buildControlSystems(
            parentKey,
            controlSystemGroup.restrictedTo,
            buildingHierarcy.children,
            regionId,
            building.id,
          );
        }

        return [
          {
            title: 'All Control Systems',
            key: `${parentKey}:C:All`,
            checkable: true,
            disabled: !canManage,
          },
          {
            title: 'No Control Systems',
            key: `${parentKey}:C:None`,
            checkable: true,
            disabled: !canManage,
          },
          {
            title: 'Selected Control Systems',
            key: `${parentKey}:C:Partial`,
            checkable: true,
            children: controlSystems,
            disabled: false,
          },
        ];
      }
    }

    return [];
  };

  const buildBuildings = (
    parentKey: string,
    buildingRestrictions: DataAccessItem[],
    buildingHierarcy: OrganizationHierarchy[],
    regionId: string,
  ) => {
    const buildingTree = buildingHierarcy.map(buildingHierarcyItem => {
      const buildingPermission = buildingRestrictions.find(
        reg => reg.id === buildingHierarcyItem.id,
      );

      const canManage = hasDataAccessPermissionsTo(currentRestrictions, {
        regionId,
        buildingId: buildingHierarcyItem.id,
        controlSystemId: emptyId,
      });

      // console.log(
      //   `Can Manage: ${canManage} @ region:${regionId} & building: ${buildingHierarcyItem.id}`,
      // );

      return {
        title: buildingHierarcyItem.name,
        key: `${parentKey}-B:${buildingHierarcyItem.id}`,
        checkable: true,
        disabled: !canManage,
        children: buildStaticControlSystemOptions(
          `${parentKey}-B:${buildingHierarcyItem.id}`,
          buildingPermission,
          buildingHierarcyItem,
          regionId,
        ),
      };
    });

    return buildingTree;
  };

  const buildStaticRegionOptions = (
    parentKey: string,
    region: DataAccessItem,
    regionHierarcy: OrganizationHierarchy,
  ) => {
    let buildingGroup;
    if (typeof region !== 'undefined' && region !== null) {
      buildingGroup = region.children.find(gr => gr.name === 'Building');

      const canManage = hasDataAccessPermissionsTo(currentRestrictions, {
        regionId: region.id,
        buildingId: emptyId,
        controlSystemId: emptyId,
      });

      let buildings = [];
      if (typeof buildingGroup !== 'undefined') {
        if (
          buildingGroup.accessType !== 'All' &&
          buildingGroup.accessType !== 'None'
        ) {
          buildings = buildBuildings(
            parentKey,
            buildingGroup.restrictedTo,
            regionHierarcy.children,
            region.id,
          );
        }

        return [
          {
            title: 'All Buildings',
            key: `${parentKey}:B:All`,
            checkable: true,
            disabled: !canManage,
          },
          {
            title: 'No Buildings',
            key: `${parentKey}:B:None`,
            checkable: true,
            disabled: !canManage,
          },
          {
            title: 'Selected Buildings',
            key: `${parentKey}:B:Partial`,
            checkable: true,
            children: buildings,
            disabled: false,
          },
        ];
      }
    }

    return [];
  };

  const buildRegions = (
    parentKey: string,
    regionRestrictions: DataAccessItem[],
    regionHierarcy: OrganizationHierarchy[],
  ) => {
    const regionTree = regionHierarcy.map(regionHierarcyItem => {
      const regionPermission = regionRestrictions.find(
        reg => reg.id === regionHierarcyItem.id,
      );

      const canManage = hasDataAccessPermissionsTo(currentRestrictions, {
        regionId: regionHierarcyItem.id,
        buildingId: emptyId,
        controlSystemId: emptyId,
      });

      // console.log(
      //   `Can Manage: ${canManage} @ region:${regionHierarcyItem.id} & building: ${emptyId}`,
      // );

      return {
        title: regionHierarcyItem.name,
        key: `${parentKey}-R:${regionHierarcyItem.id}`,
        checkable: true,
        disabled: !canManage,
        children: buildStaticRegionOptions(
          `${parentKey}-R:${regionHierarcyItem.id}`,
          regionPermission,
          regionHierarcyItem,
        ),
      };
    });

    return regionTree;
  };

  const buildStaticOrganizationOptions = (
    parentKey: string,
    regions: OrganizationHierarchy[],
  ) => {
    let regionGroup;
    if (typeof userRestrictions !== 'undefined' && userRestrictions !== null) {
      regionGroup = userRestrictions.find(gr => gr.name === 'Region');
    }

    const canManage = hasDataAccessPermissionsTo(currentRestrictions, {
      regionId: emptyId,
      buildingId: emptyId,
      controlSystemId: emptyId,
    });

    let regionList = [];

    if (typeof regionGroup !== 'undefined') {
      if (
        regionGroup.accessType !== 'All' &&
        regionGroup.accessType !== 'None'
      ) {
        regionList = buildRegions(parentKey, regionGroup.restrictedTo, regions);
      }

      return [
        {
          title: 'All Regions',
          key: `${parentKey}:R:All`,
          checkable: true,
          disabled: !canManage,
        },
        {
          title: 'No Regions',
          key: `${parentKey}:R:None`,
          checkable: true,
          disabled: !canManage,
        },
        {
          title: 'Selected Regions',
          key: `${parentKey}:R:Partial`,
          checkable: true,
          disabled: false,
          children: regionList,
        },
      ];
    }

    return [
      {
        title: 'All Regions',
        key: `${parentKey}:R:All`,
        checkable: true,
        disabled: !canManage,
      },
      {
        title: 'No Regions',
        key: `${parentKey}:R:None`,
        checkable: true,
        disabled: !canManage,
      },
      {
        title: 'Selected Regions',
        key: `${parentKey}:R:Partial`,
        checkable: true,
        disabled: false,
      },
    ];
  };

  const buildTreeData = () => {
    if (
      typeof organizationHierarchy !== 'undefined' &&
      organizationHierarchy !== null
    ) {
      const treeData = [
        {
          title: organizationHierarchy.name,
          key: `O:${organizationHierarchy.id}`,
          checkable: false,
          children: buildStaticOrganizationOptions(
            `O:${organizationHierarchy.id}`,
            organizationHierarchy.children,
          ),
        },
      ];
      return treeData;
    }

    return [];
  };

  const buildSelectedKeysForGroup = (
    keys: string[],
    parentKey: string,
    currentGroup: string,
    group: DataAccessGroup,
  ) => {
    const keyPrefix = group.name.charAt(0);

    switch (group.accessType) {
      case 'All':
        keys.push(`${parentKey}:${keyPrefix}:All`);
        break;
      case 'None':
        keys.push(`${parentKey}:${keyPrefix}:None`);
        break;
      default:
        keys.push(`${parentKey}:${keyPrefix}:Partial`);
        break;
    }

    group.restrictedTo.forEach(pi => {
      keys.push(`${parentKey}-${keyPrefix}:${pi.id}`);

      const nextGroupAttributes = GetNextPermissionsGroup(currentGroup);
      const nextGroup = pi.children.find(
        gr => gr.name === nextGroupAttributes.key,
      );
      if (typeof nextGroup !== 'undefined') {
        buildSelectedKeysForGroup(
          keys,
          `${parentKey}-${keyPrefix}:${pi.id}`,
          nextGroupAttributes.key,
          nextGroup,
        );
      }
    });
  };

  const bulidSelectedKeys = () => {
    const keys = [];

    if (
      typeof userRestrictions !== 'undefined' &&
      userRestrictions !== null &&
      typeof organizationHierarchy !== 'undefined' &&
      organizationHierarchy !== null
    ) {
      const regionGroup = userRestrictions.find(gr => gr.name === 'Region');

      if (typeof regionGroup !== 'undefined') {
        buildSelectedKeysForGroup(
          keys,
          `O:${organizationHierarchy.id}`,
          'Region',
          regionGroup,
        );
      } else {
        keys.push(`O:${organizationHierarchy.id}:R:All`);
      }
    }
    return keys;
  };

  const buildUpdateFromTreeData = (info: any) => {
    const nodeInfo = {
      key: info.node.key,
      name: info.node.title,
      isChecked: info.checked,
    };

    const dataUpdate: DataAccessPermissionUpdate = {
      userId: user.id,
      updateType: '',
      action: '',
      data: {
        organizationId: '',
        regionOption: '',
        regionId: '',
        regionName: '',
        buildingOption: '',
        buildingId: '',
        buildingName: '',
        controlSystemOption: '',
        controlSystemId: '',
        controlSystemName: '',
      },
    };

    const keyParts = nodeInfo.key.split('-');
    const keysArray = [];
    keyParts.forEach(key => {
      keysArray.push(key.split(':'));
    });

    const lastKey = keysArray[keysArray.length - 1];
    if (lastKey.length === 4) {
      // Is a group section
      dataUpdate.action = 'UPDATE';
      // eslint-disable-next-line default-case
      switch (lastKey[0]) {
        case 'O':
          // Organization
          dataUpdate.updateType = 'REGION_OPTION_UPDATE';
          dataUpdate.data.organizationId = lastKey[1];
          dataUpdate.data.regionOption = lastKey[3];
          break;
        case 'R':
          // Region
          dataUpdate.updateType = 'BUILDING_OPTION_UPDATE';
          dataUpdate.data.organizationId = keysArray[0][1];
          dataUpdate.data.regionId = lastKey[1];
          dataUpdate.data.buildingOption = lastKey[3];
          break;
        case 'B':
          // Building
          dataUpdate.updateType = 'CONTROL_SYSTEM_OPTION_UPDATE';
          dataUpdate.data.organizationId = keysArray[0][1];
          dataUpdate.data.regionId = keysArray[1][1];
          dataUpdate.data.buildingId = lastKey[1];
          dataUpdate.data.controlSystemOption = lastKey[3];
          break;
      }
    } else if (lastKey.length === 2) {
      // Is an item section
      if (nodeInfo.isChecked) {
        dataUpdate.action = 'ADD';
      } else {
        dataUpdate.action = 'DELETE';
      }
      // eslint-disable-next-line default-case
      switch (lastKey[0]) {
        case 'R':
          dataUpdate.updateType = 'REGION_UPDATE';
          dataUpdate.data.organizationId = keysArray[0][1];
          dataUpdate.data.regionId = lastKey[1];
          dataUpdate.data.regionName = nodeInfo.name;
          break;
        case 'B':
          dataUpdate.updateType = 'BUILDING_UPDATE';
          dataUpdate.data.organizationId = keysArray[0][1];
          dataUpdate.data.regionId = keysArray[1][1];
          dataUpdate.data.buildingId = lastKey[1];
          dataUpdate.data.buildingName = nodeInfo.name;
          break;
        case 'C':
          dataUpdate.updateType = 'CONTROL_SYSTEM_UPDATE';
          dataUpdate.data.organizationId = keysArray[0][1];
          dataUpdate.data.regionId = keysArray[1][1];
          dataUpdate.data.buildingId = keysArray[2][1];
          dataUpdate.data.controlSystemId = lastKey[1];
          dataUpdate.data.controlSystemName = nodeInfo.name;
          break;
      }
    }

    return dataUpdate;
  };

  const onTreeItemChecked = (checkedKeys: React.Key[], info: any) => {
    const update = buildUpdateFromTreeData(info);

    if (update.updateType !== '') {
      sendDataPermissionsUpdate(update);
    }
  };

  return (
    <Modal
      open={visible}
      centered
      width="50%"
      title={title}
      onOk={onClose}
      onCancel={onClose}
      footer={[footerContent]}
    >
      <Tree
        checkable
        checkStrictly
        height={500}
        defaultExpandParent
        expandedKeys={treeExpandedKeys}
        checkedKeys={bulidSelectedKeys()}
        treeData={buildTreeData()}
        onCheck={onTreeItemChecked}
        onExpand={onTreeItemExpanded}
      />
    </Modal>
  );
};

export default DataAccessPermissionsTreeModal;
