import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { TablePaginationConfig } from 'antd/es/table';
import { ColumnProps } from 'antd/lib/table';
import { SorterResult } from 'antd/lib/table/interface';

import Table from '@totem/components/common/table/Table';
import { getCvssColorFromScore } from '@totem/components/software/cves/utilities';
import { SoftwareVulnerabilityReportRecord } from '@totem/components/software/vulnerabilityReport/types';
import VulnerabilityReportContext from '@totem/components/software/vulnerabilityReport/vulnerabilityReportContext';
import { isNotNull } from '@totem/utilities/common';

type Props = {
  deviceId: string;
  deviceName: string;
};

const VulnerabilityReportTable = ({ deviceId, deviceName }: Props) => {
  const { data, loading, input } = useContext(VulnerabilityReportContext);
  const totalRecords =
    isNotNull(data) && isNotNull(data.paging) ? data.paging.totalRecords : 0;

  const getData = () => {
    if (isNotNull(data) && isNotNull(data.data)) {
      return data.data;
    }
    return [];
  };

  const getCvss = (rec: SoftwareVulnerabilityReportRecord) => {
    if (
      typeof rec.cveV3BaseScore !== 'undefined' &&
      rec.cveV2BaseScore !== null &&
      rec.cveV3BaseScore > 0
    ) {
      return 'CVSS V3';
    }
    if (
      typeof rec.cveV2BaseScore !== 'undefined' &&
      rec.cveV2BaseScore !== null &&
      rec.cveV2BaseScore > 0
    ) {
      return 'CVSS V3';
    }
    return '';
  };

  const getCvssBaseScore = (rec: SoftwareVulnerabilityReportRecord) => {
    if (
      typeof rec.cveV3BaseScore !== 'undefined' &&
      rec.cveV2BaseScore !== null &&
      rec.cveV3BaseScore > 0
    ) {
      return rec.cveV3BaseScore;
    }
    if (
      typeof rec.cveV2BaseScore !== 'undefined' &&
      rec.cveV2BaseScore !== null &&
      rec.cveV2BaseScore > 0
    ) {
      return rec.cveV2BaseScore;
    }
    return '';
  };

  const getCvssBaseScoreNumeric = (rec: SoftwareVulnerabilityReportRecord) => {
    if (
      typeof rec.cveV3BaseScore !== 'undefined' &&
      rec.cveV2BaseScore !== null &&
      rec.cveV3BaseScore > 0
    ) {
      return rec.cveV3BaseScore;
    }
    if (
      typeof rec.cveV2BaseScore !== 'undefined' &&
      rec.cveV2BaseScore !== null &&
      rec.cveV2BaseScore > 0
    ) {
      return rec.cveV2BaseScore;
    }
    return 0;
  };

  const getCvssBaseSeverity = (rec: SoftwareVulnerabilityReportRecord) => {
    if (
      typeof rec.cveV3BaseScore !== 'undefined' &&
      rec.cveV2BaseScore !== null &&
      rec.cveV3BaseScore > 0
    ) {
      return rec.cveV3BaseSeverity;
    }
    if (
      typeof rec.cveV2BaseScore !== 'undefined' &&
      rec.cveV2BaseScore !== null &&
      rec.cveV2BaseScore > 0
    ) {
      return rec.cveV2BaseSeverity;
    }
    return '';
  };

  const columns: ColumnProps<SoftwareVulnerabilityReportRecord>[] = [
    {
      title: 'Software Package',
      dataIndex: 'packageName',
      render: (_, record: SoftwareVulnerabilityReportRecord) => {
        return (
          <Link
            to={`/dashboard/software/${record.packageId}?from=device:${deviceId}:${deviceName}`}
          >
            {record.packageName}
          </Link>
        );
      },
    },
    {
      title: 'Version',
      dataIndex: 'packageVersion',
      render: (_, record: SoftwareVulnerabilityReportRecord) => {
        return record.packageVersion;
      },
    },
    {
      title: 'Publisher',
      dataIndex: 'packagePublisher',
      render: (_, record: SoftwareVulnerabilityReportRecord) => {
        return record.packagePublisher;
      },
    },
    {
      title: 'Vulnerability',
      dataIndex: 'vulnerability',
      render: (_, record: SoftwareVulnerabilityReportRecord) => {
        return (
          <Link to={`/dashboard/software/cve/${record.vulnerability}`}>
            {record.vulnerability}
          </Link>
        );
      },
    },
    {
      title: 'CVSS',
      dataIndex: 'cvss',
      render: (_, record: SoftwareVulnerabilityReportRecord) => {
        return getCvss(record);
      },
    },
    {
      title: 'Base Score',
      dataIndex: 'score',
      render: (_, record: SoftwareVulnerabilityReportRecord) => {
        return (
          <span
            style={{
              color: getCvssColorFromScore(getCvssBaseScoreNumeric(record)),
            }}
          >
            {getCvssBaseScore(record)}
          </span>
        );
      },
    },
    {
      title: 'Severity',
      dataIndex: 'severity',
      render: (_, record: SoftwareVulnerabilityReportRecord) => {
        return (
          <span
            style={{
              color: getCvssColorFromScore(getCvssBaseScoreNumeric(record)),
            }}
          >
            {getCvssBaseSeverity(record)}
          </span>
        );
      },
    },
  ];

  const handleTableChange = (
    updatedPagination: TablePaginationConfig,
    filters: SorterResult<SoftwareVulnerabilityReportRecord>,
    sorter,
  ) => {
    const { ...params } = filters;

    const sortDir: string = sorter.order === 'descend' ? '-1' : '1';
    let sortField = 'createdBy';
    if (
      typeof sorter.field !== 'undefined' &&
      typeof sorter.order !== 'undefined'
    ) {
      sortField = sorter.field;
    }

    // @ts-ignore
    setInput({
      ...input,
      ...params,
      pageSize: updatedPagination.pageSize,
      page: updatedPagination.current,
      sortField,
      sortDirection: sortDir,
    });
  };

  return (
    <Table
      showSorterTooltip
      columns={columns}
      dataSource={getData()}
      loading={loading}
      onChange={handleTableChange}
      pagination={{
        current: input.page,
        pageSize: input.pageSize,
        total: totalRecords,
        showSizeChanger: true,
      }}
      rowKey={(record) => record.deviceId}
    />
  );
};

export default VulnerabilityReportTable;
