import React, { useState } from 'react';
import { Input } from 'antd';
const { TextArea } = Input;
import {
  QuestionValue,
  SurveyQuestion,
} from '@totem/components/surveyV2/types';

type Props = {
  question: SurveyQuestion;
  disabled: boolean;
  onChange: (value: QuestionValue) => void;
};

const TextQuestion = ({ question, disabled, onChange }: Props) => {
  const [value, setValue] = useState<string>(question.value.value);
  const { type } = question;

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const newValue = event.target.value;
    setValue(newValue);
  };

  const handleBlur = () => {
    if (question.value.value !== value) {
      const newValue: QuestionValue = {
        value,
        valueArray: [],
        valueFloat: 0,
        valueInt: 0,
      };
      onChange(newValue);
    }
  };

  return (
    <>
      {type === 'shorttext' && (
        <Input
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={disabled}
          spellCheck
        />
      )}
      {type !== 'shorttext' && (
        <TextArea
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={disabled}
          spellCheck
          autoSize={{ minRows: 6 }}
        />
      )}
    </>
  );
};

export default TextQuestion;
