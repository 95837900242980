import React, { useContext } from 'react';
import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Button, Modal, Tooltip } from 'antd';
import { ColumnProps } from 'antd/lib/table';
const confirm = Modal.confirm;
import AgreementContext from '@totem/components/agreements/AgreementReport/AgreementReportContext';
import { Agreement, Hierarchy } from '@totem/components/agreements/models';
import Table from '@totem/components/common/table/Table';

type Props = {
  data: Agreement;
};

const HierarcyTable = ({ data }: Props) => {
  const { loading, onAction } = useContext(AgreementContext);

  const handleAddHierarchyClicked = () => {
    onAction('addHierarchy', data, null);
  };

  const columns: ColumnProps<Hierarchy>[] = [
    {
      title: 'Region',
      dataIndex: 'regionName',
      key: 'regionName',
      className: 'tableAlignTop',
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        compA.regionName === compB.regionName
          ? 0
          : compA.regionName > compB.regionName
          ? 1
          : -1,
    },
    {
      title: 'Building',
      dataIndex: 'buildingName',
      key: 'buildingName',
      className: 'tableAlignTop',
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        compA.buildingName === compB.buildingName
          ? 0
          : compA.buildingName > compB.buildingName
          ? 1
          : -1,
    },
    {
      title: 'Control System',
      dataIndex: 'controlSystemName',
      key: 'controlSystemName',
      className: 'tableAlignTop',
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        compA.controlSystemName === compB.controlSystemName
          ? 0
          : compA.controlSystemName > compB.controlSystemName
          ? 1
          : -1,
    },
    {
      title: 'Device',
      dataIndex: 'deviceName',
      key: 'deviceName',
      className: 'tableAlignTop',
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        compA.deviceName === compB.deviceName
          ? 0
          : compA.deviceName > compB.deviceName
          ? 1
          : -1,
    },
    {
      title: (
        <Tooltip title="Add new item to agreement">
          <Button
            type="primary"
            shape="circle"
            onClick={handleAddHierarchyClicked}
            icon={<PlusCircleOutlined />}
          />
        </Tooltip>
      ),
      dataIndex: 'add',
      key: 'add',
      className: 'tableAlignTop',
      render: (_, hierarchy: Hierarchy) => (
        <div className="center-table-cell">
          <DeleteOutlined
            title="Remove item from agreement"
            onClick={() =>
              confirm({
                title: 'Are you sure?',
                content: `Please confirm that you would like to remove this item from the agreement.`,
                okText: 'Delete',
                okType: 'danger',
                cancelText: 'Cancel',
                visible: false,
                onOk: () => onAction('removeHierarchy', data, hierarchy),
              })
            }
          />
        </div>
      ),
    },
  ];
  //
  const getData = () => {
    if (
      typeof data !== 'undefined' &&
      data !== null &&
      typeof data.hierarchy !== 'undefined' &&
      data.hierarchy !== null
    ) {
      return data.hierarchy;
    }
    return [];
  };

  return (
    <Table
      showSorterTooltip
      columns={columns}
      dataSource={getData()}
      loading={loading}
      rowKey={(record) =>
        record.regionId +
        record.buildingId +
        record.controlSystemId +
        record.deviceId +
        record.createdAt
      }
    />
  );
};

export default HierarcyTable;
