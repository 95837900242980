import colors from '@totem/styles/colors';

export const globalStyles = {
  borderTop: {
    borderTop: `5px solid`,
    borderImage: `${colors.gradient.primary} 100% 0 0 0`,
    //borderTopColor: colors.branding.primaryDark,
  },
  filters: {
    width: '100%',
    backgroundColor: colors.branding.primaryDark,
    //background: colors.gradient.primary,
  },
  dropShadow: {
    boxShadow: '3px 3px 3px',
    lineHeight: 1,
  },
};
