import React, { useContext } from 'react';

import ZoneMappingContainer from '@totem/components/administration/zoneMappings/ZoneMappingContainer';
import ZoneMappingTable from '@totem/components/administration/zoneMappings/ZoneMappingTable';
import BasePane from '@totem/components/BasePane';
import ContentLayout from '@totem/components/ContentLayout';
import PrimaryContentPane from '@totem/components/PrimaryContentPane';
import UserProfileContext from '@totem/components/UserProfileContext';
import { getUserRole } from '@totem/utilities/userUtilities';

const ZoneMappingPage = () => {
  const { userProfile } = useContext(UserProfileContext);
  const isSysAdmin = getUserRole(userProfile) === 3;

  return (
    <ContentLayout>
      <BasePane>
        <PrimaryContentPane>
          {isSysAdmin && (
            <ZoneMappingContainer>
              <ZoneMappingTable />
            </ZoneMappingContainer>
          )}
        </PrimaryContentPane>
      </BasePane>
    </ContentLayout>
  );
};

export default ZoneMappingPage;
