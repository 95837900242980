import React from 'react';

import BasePane from '@totem/components/BasePane';
import PortfolioLayout from '@totem/components/portfolio/PortfolioLayout';
import SummaryContainer from '@totem/components/portfolio/summary/SummaryContainer';

import ContentLayout from '../ContentLayout';

import './portfolio.css';

const Portfolio = () => {
  return (
    <ContentLayout contentStyle={{ padding: 0 }}>
      <SummaryContainer>
        <div styleName="content">
          <BasePane>
            <PortfolioLayout />
          </BasePane>
        </div>
      </SummaryContainer>
    </ContentLayout>
  );
};

export default Portfolio;
