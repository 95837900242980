import React from 'react';
import { Col, Row } from 'antd';

import OverviewPane from '@totem/components/surveyV2/instances/OverviewPane';
import SurveyHealthPane from '@totem/components/surveyV2/instances/SurveyHealthPane';
import SurveyInstanceContainer from '@totem/components/surveyV2/instances/SurveyInstanceContainer';
import SurveyInstanceTable from '@totem/components/surveyV2/instances/SurveyInstanceTable';

type Props = {
  buildingId: string;
};

const SurveyOverview = ({ buildingId }: Props) => {
  return (
    <SurveyInstanceContainer building={[buildingId]}>
      <Row gutter={[16, 16]}>
        <Col span={8}>
          <SurveyHealthPane />
        </Col>
        <Col span={16}>
          <OverviewPane />
        </Col>
      </Row>
      <br />
      <SurveyInstanceTable />
    </SurveyInstanceContainer>
  );
};

export default SurveyOverview;
