import React, { useState } from 'react';

import mapUtilities from '@totem/utilities/mapUtilities';

import Configuration from './Configuration';

import './map.css';

interface Props {
  center?: object;
  zoom?: number;
  onMapClick?: () => void;
  markers: any[];
  onBoundsChanged?: () => void;
  displayClusters?: boolean;
}

const GoogleMap = ({
  center,
  zoom,
  onMapClick,
  markers,
  onBoundsChanged,
  displayClusters,
}: Props) => {
  const [mapTypeId, setMapTypeId] = useState('roadmap');
  const [traffic, setTraffic] = useState(false);
  const [map, setMap] = useState(null);
  const [googleMapURL] = useState(mapUtilities.getMapsRequestUrl());
  const defaultViewPort = mapUtilities.viewPort;

  const onMapLoad = (loadedMap) => {
    const mapInstance = loadedMap ? loadedMap : map;
    setMap(mapInstance);
  };

  const mapStyle = <div styleName="map" />;
  const loadingElement = <div />;

  if (!global.google) {
    return <div />;
  }

  return (
    <div styleName="map-container">
      <Configuration
        containerElement={mapStyle}
        mapElement={mapStyle}
        // @ts-ignore
        defaultViewPort={defaultViewPort}
        mapTypeId={mapTypeId}
        googleMapURL={googleMapURL}
        loadingElement={loadingElement}
        traffic={traffic}
        center={center}
        zoom={zoom}
        onMapClick={onMapClick}
        onMapLoad={onMapLoad}
        displayClusters={displayClusters}
        markers={markers}
      />
    </div>
  );
};

export default GoogleMap;
