import React, { ReactNode } from 'react';
import { PageHeader } from '@ant-design/pro-layout';

import './base.css';

type Props = {
  title?: string | ReactNode;
  options?: ReactNode;
};

const ComponentHeader = ({ title, options }: Props) => {
  return (
    <div styleName="component-header-container">
      <PageHeader
        title={<div styleName="component-header-text">{title}</div>}
        extra={options}
      />
    </div>
  );
};

export default ComponentHeader;
