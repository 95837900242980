import React, { useContext, useState } from 'react';
import { DownloadOutlined } from '@ant-design/icons';
import { Button } from 'antd';

import DeviceContext from '@totem/components/devices/devicesContainer/deviceContainerContext';
import {
  addDeviceFilters,
  buildDeviceFilters,
} from '@totem/components/devices/devicesContainer/utilities';
import { Params } from '@totem/types/common';
import { DeviceFilters } from '@totem/types/devices';
import api from '@totem/utilities/api';
import { DEVICES_PAGINATION_ENDPOINT } from '@totem/utilities/endpoints';
import fileUtilities from '@totem/utilities/fileUtilities';

type Props = {
  staticFilters?: DeviceFilters;
  deviceExportEndPoint?: string;
};
const DeviceExport = ({ staticFilters, deviceExportEndPoint }: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { input } = useContext(DeviceContext);

  const buildParameters = () => {
    const params: Params = {
      paging: {
        page: input.page,
        pageSize: input.pageSize,
      },
      sort: {
        field: input.sortField,
        direction: +input.sortDirection,
      },
      filters: buildDeviceFilters(input),
    };

    params.filters = addDeviceFilters(params.filters, staticFilters);

    return params;
  };

  const exportData = async () => {
    setLoading(true);

    const params = buildParameters();

    const deviceUrl =
      typeof deviceExportEndPoint !== 'undefined' &&
      deviceExportEndPoint !== null
        ? deviceExportEndPoint
        : DEVICES_PAGINATION_ENDPOINT;

    const { data } = await api.post(`${deviceUrl}/export`, params);
    fileUtilities.downloadFile('devices', data);
    setLoading(false);
  };

  return (
    <Button
      type="default"
      icon={<DownloadOutlined />}
      onClick={exportData}
      loading={loading}
    >
      Export CSV
    </Button>
  );
};

export default DeviceExport;
