import React, { ReactNode, useEffect, useState } from 'react';
import { message } from 'antd';

import DialogSoftwareBulkTags from '@totem/components/software/software_report/dialogSoftwareBulkTags';
import MergeDialog from '@totem/components/software/software_report/mergeDialog';
import SoftwareContext from '@totem/components/software/software_report/softwareContext';
import {
  SoftwareCatalogInput,
  SoftwarePackage,
} from '@totem/components/software/software_report/types';
import { getToken } from '@totem/utilities/accountUtilities';
import { isNotNull } from '@totem/utilities/common';
import { DEVICES_ENDPOINT } from '@totem/utilities/endpoints';
import { omitNilOrEmpty } from '@totem/utilities/objectUtilities';

type Props = {
  children?: ReactNode;
};

const SoftwareContainer = ({ children }: Props) => {
  const [messageApi, contextHolder] = message.useMessage();
  const [refreshData, setRefreshData] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [data, setData] = useState<SoftwarePackage[]>([]);
  const [rawData, setRawData] = useState<SoftwarePackage[]>([]);
  const [action, setAction] = useState<string>('');
  const [actionItem, setActionItem] = useState<SoftwarePackage>(null);
  const [selectedPackages, setSelectedPackages] = useState<SoftwarePackage[]>(
    [],
  );
  const [input, updateInput] = useState<SoftwareCatalogInput>({
    pageSize: 20,
    page: 1,
    sortField: 'name',
    sortDirection: '1',
    name: '',
    publisher: '',
    tag: '',
  });

  const filterData = (swData: SoftwarePackage[]) => {
    let filtered = [...swData];
    let clearSelections = false;

    if (typeof input.name !== 'undefined' && input.name !== '') {
      filtered = filtered.filter((chk) =>
        chk.name.toLowerCase().startsWith(input.name.toLowerCase()),
      );
      clearSelections = true;
    }
    if (typeof input.publisher !== 'undefined' && input.publisher !== '') {
      filtered = filtered.filter((chk) =>
        chk.publisher.toLowerCase().startsWith(input.publisher.toLowerCase()),
      );
      clearSelections = true;
    }
    if (typeof input.tag !== 'undefined' && input.tag !== '') {
      filtered = filtered.filter((chk) => {
        if (isNotNull(chk.tags) && chk.tags.length > 0) {
          for (let idx = 0; idx < chk.tags.length; idx++) {
            if (
              chk.tags[idx].toLowerCase().startsWith(input.tag.toLowerCase())
            ) {
              return true;
            }
          }
        }
        return false;
      });
      clearSelections = true;
    }

    if (clearSelections) {
      setSelectedPackages([]);
    }

    setData(filtered);
  };

  const setPackageSelected = (
    softwarePackage: SoftwarePackage,
    selected: boolean,
  ) => {
    if (selected) {
      if (
        selectedPackages.findIndex((chk) => chk.id === softwarePackage.id) <= -1
      ) {
        setSelectedPackages([...selectedPackages, softwarePackage]);
      }
    } else {
      const filtered = selectedPackages.filter(
        (chk) => chk.id !== softwarePackage.id,
      );
      setSelectedPackages(filtered);
    }
  };

  const setInput = (updated: Partial<SoftwareCatalogInput>) => {
    updateInput(omitNilOrEmpty({ ...input, ...updated }));
    //setRefreshData(true);
  };

  useEffect(() => {
    filterData(rawData);
  }, [input]);

  useEffect(() => {
    if (refreshData) {
      setRefreshData(false);
      setIsLoading(true);

      const uri = `${DEVICES_ENDPOINT}/software/catalog`;

      fetch(uri, {
        method: 'GET',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
      })
        .then((res) => res.json())
        .then((result: SoftwarePackage[]) => {
          setRawData(result);
          filterData(result);
        })
        .then(() => {
          setIsLoading(false);
        });
    }
  }, [refreshData]);

  const handleAction = (newAction: string, item: SoftwarePackage) => {
    switch (newAction) {
      case 'merge':
        if (selectedPackages.length > 1) {
          setAction(newAction);
          setActionItem(item);
        } else {
          messageApi.error('Select two or more packages before merging!');
        }
        break;
      case 'bulk-tag':
        if (selectedPackages.length > 1) {
          setAction(newAction);
          setActionItem(item);
        } else {
          messageApi.error('Select two or more packages before tagging!');
        }
        break;
      case 'clear_package_selections':
        setSelectedPackages([]);
        break;
      case 'refresh':
        setRefreshData(true);
        setAction('');
        setActionItem(null);
        break;
      default:
        setAction(newAction);
        setActionItem(item);
    }
  };

  return (
    <SoftwareContext.Provider
      value={{
        loading: isLoading,
        data,
        rawData,
        action,
        actionItem,
        onAction: handleAction,
        input,
        setInput,
        selectedPackages,
        setPackageSelected,
      }}
    >
      {contextHolder}
      <div>{children}</div>
      {action === 'merge' && <MergeDialog />}
      {action === 'bulk-tag' && (
        <DialogSoftwareBulkTags open={action === 'bulk-tag'} />
      )}
    </SoftwareContext.Provider>
  );
};

export default SoftwareContainer;
