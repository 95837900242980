import { useCallback, useEffect, useState } from 'react';
import { gql } from '@apollo/client';
import * as R from 'ramda';

import client from '@totem/graph/client';
import {
  AutoCompleteApplied,
  AutoCompleteFilter,
  AutoCompleteFilterOptions,
  AutoCompleteOptions,
  AutoCompleteSelected,
} from '@totem/types/hooks';
import { debounce } from '@totem/utilities/debounce';

const GET_REGIONS = gql`
  query GetRegions($input: RegionsConnectionInput!) {
    regions(input: $input) {
      regions {
        id
        name
      }
    }
  }
`;

export const useRegionFilter = (
  initialSelected: string[] = [],
  filterOptions?: AutoCompleteFilterOptions,
): AutoCompleteFilter => {
  const LIMIT = filterOptions?.limit || 5;
  const DEBOUNCE_TIME = filterOptions?.debounce || 500;

  const [search, onSearch] = useState<string>('');
  const [loadingSearch, setLoadingSearch] = useState<boolean>(false);
  const [selected, setSelected] = useState<AutoCompleteSelected>({});
  const [applied, setApplied] = useState<AutoCompleteApplied>({});
  const [options, setOptions] = useState<AutoCompleteOptions>({});
  const [loading, setLoading] = useState<boolean>(false);

  const onSelect = (id: string, name: string) => {
    if (R.has(id, selected)) {
      setSelected(R.dissoc(id, selected));
    } else {
      setSelected({ ...(selected as AutoCompleteSelected), [id]: name });
    }
  };

  const onApply = () => {
    setApplied(selected);
  };

  const onReset = () => {
    setOptions({});
    setSelected({});
    setApplied({});
    onSearch('');
  };

  const onRemove = (id: string) => {
    const update = R.dissoc(id, selected);
    setSelected(update);
    setApplied(update);
  };

  const searchRegions = async (
    name: string,
    currentlySelected: AutoCompleteSelected,
  ) => {
    if (!name) {
      setOptions({});
      setLoadingSearch(false);
      return;
    }

    const { data } = await client.query({
      query: GET_REGIONS,
      variables: {
        input: {
          name,
          limit: LIMIT + Object.keys(currentlySelected).length,
        },
      },
      fetchPolicy: 'no-cache',
    });

    const regions = data?.regions?.regions || [];

    setOptions(
      regions
        .filter(region => !R.has(region.id, currentlySelected))
        .slice(0, LIMIT)
        .reduce((acc, region) => ({ ...acc, [region.id]: region.name }), {}),
    );

    setLoadingSearch(false);
  };

  const handleSearch = useCallback(debounce(searchRegions, DEBOUNCE_TIME), []);

  useEffect(() => {
    setLoadingSearch(true);
    handleSearch(search, selected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    const getRegions = async () => {
      setLoading(true);

      const { data } = await client.query({
        query: GET_REGIONS,
        variables: {
          input: { id: initialSelected },
        },
      });

      const regions = (data?.regions?.regions || []).reduce(
        (acc, region) => ({ ...acc, [region.id]: region.name }),
        {},
      );

      setSelected(regions);
      setApplied(regions);
      setLoading(false);
    };

    if (initialSelected.length) {
      getRegions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (filterOptions?.onChange) {
      // @ts-ignore
      filterOptions.onChange(R.keys(applied));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applied]);

  return {
    search,
    onSearch,
    loadingSearch,
    options,
    selected,
    onSelect,
    applied,
    onRemove,
    onApply,
    onReset,
    loading,
  };
};
