import { createContext } from 'react';

import { PendingImportRecord } from '@totem/components/internalOnly/netSuitePending/types';

interface Context {
  data: PendingImportRecord[];
  selectedRecord: PendingImportRecord;
  action: string;
  loading: boolean;
  onAction?: (action: string, record: PendingImportRecord) => void;
  onActionComplete?: (refresh: boolean) => void;
}

export default createContext<Context>({
  data: [],
  selectedRecord: null,
  action: '',
  loading: false,
  onAction: null,
  onActionComplete: null,
});
