import React, { useEffect, useState } from 'react';
import { Tabs, TabsProps } from 'antd';

import Category from '@totem/components/surveyV2/policies/Category';
import {
  Category as SurveyCategory,
  SurveyTemplate,
} from '@totem/components/surveyV2/types';
import TabTitle from '@totem/components/TabTitle';
import { isNotNull } from '@totem/utilities/common';

type Props = {
  template: SurveyTemplate;
};
const PolicyCategories = ({ template }: Props) => {
  const [categories, setCategories] = useState<SurveyCategory[]>([]);

  useEffect(() => {
    const eligibleCategories: SurveyCategory[] = [];

    if (isNotNull(template) && isNotNull(template.categories)) {
      for (let idx = 0; idx < template.categories.length; idx++) {
        const category = template.categories[idx];
        if (isNotNull(category.questions)) {
          // eslint-disable-next-line max-depth
          for (let qIdx = 0; qIdx < category.questions.length; qIdx++) {
            // eslint-disable-next-line max-depth
            if (
              (category.questions[qIdx].type === 'scoring' ||
                category.questions[qIdx].type === 'enumsingle') &&
              category.questions[qIdx].policy !== ''
            ) {
              eligibleCategories.push(category);
              break;
            }
          }
        }
      }
    }

    setCategories(eligibleCategories);
  }, [template]);

  const items: TabsProps['items'] = isNotNull(categories)
    ? categories.map((chk) => {
        return {
          key: chk.id,
          label: <TabTitle>{chk.name}</TabTitle>,
          children: <Category category={chk} />,
        };
      })
    : [];

  return <Tabs items={items} />;
};

export default PolicyCategories;
