import * as R from 'ramda';
import { call, fork, put, takeEvery } from 'redux-saga/effects';

import actionTypes from '@totem/actions/actionTypes';
import * as policyAuditActions from '@totem/actions/policyAuditActions';
import { PolicyAuditPatch } from '@totem/types/policyAudit';
import accountUtilities from '@totem/utilities/accountUtilities';
import api from '@totem/utilities/api';
import { POLICY_AUDITS_ENDPOINT } from '@totem/utilities/endpoints';

export function* getPolicyAuditDetail(action) {
  try {
    const { id } = action.data;
    // @ts-ignore
    const { data } = yield call(api.get, `${POLICY_AUDITS_ENDPOINT}/${id}`);
    yield put(policyAuditActions.getPolicyAuditSuccess(data));
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      policyAuditActions.getPolicyAuditFailure,
    );
  }
}

export function* patchPolicyAuditDetail(action) {
  try {
    const { id, questions, submitted } = action.data;

    const patch: PolicyAuditPatch = {
      id,
      ...(questions && { questions }),
      ...(!R.isNil(submitted) && { submitted }),
    };

    const { data } = yield call(
      api.patch,
      `${POLICY_AUDITS_ENDPOINT}/${id}`,
      patch,
    );

    yield put(policyAuditActions.policyAuditPatchSuccess(data));
  } catch (error) {
    yield fork(
      accountUtilities.apiErrorHandler,
      error,
      policyAuditActions.policyAuditPatchFailure,
    );
  }
}

export function* requestPolicyAuditDetail() {
  yield takeEvery(actionTypes.REQUEST_POLICY_AUDIT, getPolicyAuditDetail);
}

export function* requestPolicyAuditPatch() {
  yield takeEvery(
    actionTypes.REQUEST_POLICY_AUDIT_PATCH,
    patchPolicyAuditDetail,
  );
}

export default [requestPolicyAuditDetail, requestPolicyAuditPatch];
