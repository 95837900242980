import React from 'react';

import ExtendedFieldDate from '@totem/components/ticketing/details/extendedFieldDate';
import ExtendedFieldEmailAutoComplete from '@totem/components/ticketing/details/extendedFieldEmailAutoComplete';
import ExtendedFieldSelect from '@totem/components/ticketing/details/extendedFieldSelect';
import ExtendedFieldString from '@totem/components/ticketing/details/extendedFieldString';
import {
  ActivityExtendedField,
  ActivityExtendedFieldGroup,
  ExtendedFieldValue,
} from '@totem/types/ticketing';

import '../ticketing.css';

type Props = {
  extendedFieldsGroup: ActivityExtendedFieldGroup;
  extendedFieldValues: Map<string, ExtendedFieldValue>;
  onChange: (
    groupName: string,
    FieldName: string,
    value: string,
    id: string,
    isValid: boolean,
  ) => void;
};

const ExtendedFields = ({
  extendedFieldsGroup,
  extendedFieldValues,
  onChange,
}: Props) => {
  const getExtendedFieldValue = (field: ActivityExtendedField) => {
    const foundValue = extendedFieldValues.get(
      `${extendedFieldsGroup.name}:${field.name}`,
    );
    return foundValue;
  };

  const getExtendedFieldUI = (
    group: ActivityExtendedFieldGroup,
    extendedField: ActivityExtendedField,
  ) => {
    switch (extendedField.dataType) {
      case 'date':
        return (
          <ExtendedFieldDate
            extendedField={extendedField}
            extendedFieldValue={getExtendedFieldValue(extendedField)}
            key={extendedField.name}
            onChange={(
              fieldName: string,
              fieldValue: string,
              isValid: boolean,
            ) => onChange(group.name, fieldName, fieldValue, '', isValid)}
          />
        );
      case 'select':
        return (
          <ExtendedFieldSelect
            extendedField={extendedField}
            extendedFieldValue={getExtendedFieldValue(extendedField)}
            key={extendedField.name}
            onChange={(
              fieldName: string,
              fieldValue: string,
              fieldId: string,
              isValid: boolean,
            ) => onChange(group.name, fieldName, fieldValue, fieldId, isValid)}
          />
        );
      case 'emailAutoComplete':
        return (
          <ExtendedFieldEmailAutoComplete
            extendedField={extendedField}
            extendedFieldValue={getExtendedFieldValue(extendedField)}
            key={extendedField.name}
            onChange={(
              fieldName: string,
              fieldValue: string,
              isValid: boolean,
            ) => onChange(group.name, fieldName, fieldValue, '', isValid)}
          />
        );
      default:
        return (
          <ExtendedFieldString
            extendedField={extendedField}
            extendedFieldValue={getExtendedFieldValue(extendedField)}
            key={extendedField.name}
            onChange={(
              fieldName: string,
              fieldValue: string,
              isValid: boolean,
            ) => onChange(group.name, fieldName, fieldValue, '', isValid)}
          />
        );
    }
  };

  return (
    <>
      {extendedFieldsGroup.extendedFields !== null &&
        extendedFieldsGroup.extendedFields.length > 0 &&
        extendedFieldsGroup.extendedFields.map(extendedField => {
          return getExtendedFieldUI(extendedFieldsGroup, extendedField);
        })}
    </>
  );
};

export default ExtendedFields;
