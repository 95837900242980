import { createContext, useContext } from 'react';

import { Route } from '@totem/types/route';

type Context = Route;

const initialContext: Context = {
  path: '',
  pageTitle: '',
  component: () => null,
  exact: true,
  render: false,
  breadcrumbs: [],
};

export const RouteContext = createContext<Context>(initialContext);
export const RouteContextProvider = RouteContext.Provider;

export const useRoute = () => useContext(RouteContext);
