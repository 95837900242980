import { AnalysisReportInput } from '@totem/components/surveyV2/analysisReport/types';
import { Filter, Params } from '@totem/types/common';

export const buildAnalysisReportFilters = (input: AnalysisReportInput) => {
  const paramFilters: Filter[] = [];

  if (
    typeof input.regionId !== 'undefined' &&
    input.regionId !== null &&
    input.regionId.length > 0
  ) {
    paramFilters.push({
      field: 'controlSystem.regionId',
      values: input.regionId,
    });
  }
  if (
    typeof input.buildingId !== 'undefined' &&
    input.buildingId !== null &&
    input.buildingId.length > 0
  ) {
    paramFilters.push({
      field: 'controlSystem.buildingId',
      values: input.buildingId,
    });
  }
  if (
    typeof input.controlSystemId !== 'undefined' &&
    input.controlSystemId !== null &&
    input.controlSystemId.length > 0
  ) {
    paramFilters.push({
      field: 'controlSystem._id',
      values: input.controlSystemId,
    });
  }
  if (
    typeof input.policyName !== 'undefined' &&
    input.policyName !== null &&
    input.policyName.length > 0
  ) {
    paramFilters.push({
      field: 'policyAudit.findings.policyName',
      values: input.policyName,
    });
  }
  if (
    typeof input.policyCategory !== 'undefined' &&
    input.policyCategory !== null &&
    input.policyCategory.length > 0
  ) {
    paramFilters.push({
      field: 'policyAudit.findings.policyCategory',
      values: input.policyCategory,
    });
  }
  if (
    typeof input.category !== 'undefined' &&
    input.category !== null &&
    input.category.length > 0
  ) {
    paramFilters.push({
      field: 'policyAudit.findings.category',
      values: input.category,
    });
  }
  if (
    typeof input.nist !== 'undefined' &&
    input.nist !== null &&
    input.nist.length > 0
  ) {
    paramFilters.push({
      field: 'policyAudit.findings.nistFunction',
      values: input.nist,
    });
  }
  if (
    typeof input.levelOfEffort !== 'undefined' &&
    input.levelOfEffort !== null &&
    input.levelOfEffort.length > 0
  ) {
    paramFilters.push({
      field: 'policyAudit.findings.levelOfEffort',
      values: input.levelOfEffort,
    });
  }
  if (
    typeof input.criticality !== 'undefined' &&
    input.criticality !== null &&
    input.criticality.length > 0
  ) {
    paramFilters.push({
      field: 'policyAudit.findings.criticality',
      values: input.criticality,
    });
  }
  if (
    typeof input.assignee !== 'undefined' &&
    input.assignee !== null &&
    input.assignee.length > 0
  ) {
    paramFilters.push({
      field: 'assignee',
      values: input.assignee,
    });
  }

  return paramFilters;
};

export const buildParameters = (input: AnalysisReportInput) => {
  const params: Params = {
    paging: {
      page: 1,
      pageSize: 50000,
    },
    sort: {
      field: '',
      direction: 1,
    },
    filters: buildAnalysisReportFilters(input),
  };

  return params;
};
