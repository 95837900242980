import { createSelector } from 'reselect';

import { PolicyAudit, Question } from '@totem/types/policyAudit';
import { MediaItem, ReduxStore } from '@totem/types/store';

import { mediaSelector } from './mediaSelectors';

type PolicyAuditQuestionProps = {
  question: Question;
};

export const policyAuditSelector = (state: ReduxStore) =>
  state.policyAuditDetail;

export const completionPercentageSelector = createSelector(
  policyAuditSelector,
  ({ totalQuestions, completedQuestions }: PolicyAudit) => {
    return Math.round((completedQuestions / totalQuestions) * 100);
  },
);

export const getPolicyAuditMediaSelector = ({
  question: { key },
}: PolicyAuditQuestionProps) =>
  createSelector(mediaSelector, (media: MediaItem[]) =>
    media.filter(({ subjectId }) => subjectId === key),
  );

export const policyAuditIdsSelector = createSelector(
  policyAuditSelector,
  ({ id, organization, building, controlSystem, region }) => ({
    id,
    organizationId: organization.id,
    regionId: region.id,
    buildingId: building.id,
    controlSystemId: controlSystem.id,
  }),
);
