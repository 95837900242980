import { redirect } from 'react-router-dom';
import { notification } from 'antd';

import { ResponseError } from '@totem/types/error';

export const CheckResponse = (response: Response) => {
  if (response.status === 401) {
    redirect('/login');
  }
  return response;
};

export const ShowErrorMessage = async (response: Response) => {
  try {
    const errResponse: ResponseError = await response.json();

    notification.error({
      message: errResponse.message,
      description: errResponse.developerMessage,
      duration: 5,
    });
  } catch (ex) {
    /* empty */
  }
};

export const CheckResponseShowError = (response: Response) => {
  if (response.status < 400) {
    return true;
  }

  ShowErrorMessage(response);

  return false;
};
