import React, { ReactNode, useEffect, useState } from 'react';

import DeviceHealthContext from '@totem/components/devices/health/deviceHealthContext';
import { DeviceHealth } from '@totem/components/devices/health/types';
import { getToken } from '@totem/utilities/accountUtilities';
import { DEVICES_ENDPOINT } from '@totem/utilities/endpoints';

type Props = {
  id: string;
  children?: ReactNode;
};

const DeviceHealthContainer = ({ id, children }: Props) => {
  const [refreshData, setRefreshData] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [data, setData] = useState<DeviceHealth>(null);
  const [action, setAction] = useState<string>('');

  useEffect(() => {
    if (refreshData) {
      setRefreshData(false);
      setIsLoading(true);

      const uri = `${DEVICES_ENDPOINT}/${id}/health`;

      fetch(uri, {
        method: 'GET',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
      })
        .then((res) => res.json())
        .then((result: DeviceHealth) => {
          setData(result);
        })
        .then(() => {
          setIsLoading(false);
        });
    }
  }, [id, refreshData]);

  const handleAction = (newAction: string) => {
    switch (newAction) {
      default:
        setAction(newAction);
    }
  };

  return (
    <DeviceHealthContext.Provider
      value={{
        deviceId: id,
        loading: isLoading,
        data,
        action,
        onAction: handleAction,
      }}
    >
      <div>{children}</div>
    </DeviceHealthContext.Provider>
  );
};

export default DeviceHealthContainer;
