import { gql } from '@apollo/client';

export const GET_HEALTH_RISK_POLICY_ACKNOWLEDGMENT = gql`
  query healthRiskPolicyAcknowledgment {
    healthRiskPolicyAcknowledgment {
      id
      organization {
        id
        name
      }
      link
      mediaType
      agreement
      agreeText
      agreed
    }
  }
`;

export const UPDATE_HEALTH_RISK_POLICY_ACKNOWLEDGMENT = gql`
  mutation healthRiskPolicyAcknowledgment(
    $input: HealthRiskPolicyAcknowledgmentUpdateInput!
  ) {
    healthRiskPolicyAcknowledgmentUpdate(input: $input) {
      id
      organization {
        id
        name
      }
      link
      mediaType
      agreement
      agreeText
      agreed
    }
  }
`;
