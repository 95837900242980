import React, { useContext, useEffect, useState } from 'react';
import { ColumnProps } from 'antd/lib/table';

import Table from '@totem/components/common/table/Table';
import LayoutContext from '@totem/components/devices/variables/presentation/layout/LayoutContext';
import SectionContext from '@totem/components/devices/variables/presentation/layout/layoutSection/SectionContext';
import { VariableDataResponseExtended } from '@totem/components/devices/variables/presentation/layout/types';
import { isNotNull } from '@totem/utilities/common';
import {
  sortNumberAscending,
  sortStringAscending,
} from '@totem/utilities/tableUtilities';
import dayjs from 'dayjs';

type Props = {
  purpose: string;
  maxDecimalPlaces: number;
};

const CurrentValuesWithLastUpdateTable = ({ purpose, maxDecimalPlaces }: Props) => {
  const [localData, setLocalData] = useState<VariableDataResponseExtended[]>(
    [],
  );
  const { variableData, loading } = useContext(LayoutContext);
  const { sectionData } = useContext(SectionContext);

  useEffect(() => {
    if (isNotNull(sectionData) && isNotNull(sectionData.variables)) {
      const localVariables = sectionData.variables
        .filter((element) => element.metaData.purpose === purpose)
        .map((element) => element.variableId);

      if (isNotNull(variableData)) {
        const localVariableData = variableData.filter(
          (element) =>
            localVariables.findIndex((chk) => chk === element.id) >= 0,
        );
        setLocalData(localVariableData);
      }
    }
  }, [sectionData, variableData]);

  const columns: ColumnProps<VariableDataResponseExtended>[] = [
    {
      title: 'Location',
      dataIndex: 'metaData.location',
      key: 'displayName',
      showSorterTooltip: true,
      width: '25%',
      render: (_, record: VariableDataResponseExtended) => (
        <span>{record.metaData.location}</span>
      ),
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        sortStringAscending(compA.metaData.location, compB.metaData.location),
    },
    {
      title: purpose,
      dataIndex: 'value',
      key: 'value',
      showSorterTooltip: true,
      width: '35%',
      render: (_, record: VariableDataResponseExtended) => (
        <span>
          {record.isNumeric
            ? record.value.toFixed(maxDecimalPlaces)
            : record.stringValue}
        </span>
      ),
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) => {
        if (compA.isNumeric) {
          return sortNumberAscending(compA.value, compB.value);
        }
        return sortStringAscending(compA.stringValue, compB.stringValue);
      },
    },
    {
      title: 'Last Update',
      dataIndex: 'id',
      key: 'id',
      width: '160px',
      render: (_, record: VariableDataResponseExtended) => (
        <span>{dayjs(+record.lastUpdateTime).format('L LT')}</span>
      ),
    },
    {
      title: 'BAS Point Name',
      dataIndex: 'label',
      key: 'label',
      render: (_, record: VariableDataResponseExtended) => (
        <span>{record.label}</span>
      ),
    },
  ];

  return (
    <div style={{ marginBottom: '16px' }}>
      {isNotNull(localData) && localData.length > 0 && (
        <Table
          showSorterTooltip
          columns={columns}
          dataSource={localData}
          loading={loading}
          pagination={{ hideOnSinglePage: true }}
          rowKey={(record) => record.id}
        />
      )}
    </div>
  );
};

export default CurrentValuesWithLastUpdateTable;
