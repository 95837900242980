import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button, Modal, Select } from 'antd';
import dayjs from 'dayjs';

import ModalFooter from '@totem/components/ModalFooter';
import ModalTitle from '@totem/components/ModalTitle';
import PackageContext from '@totem/components/software/software_package/packageContext';
import { OpenCVEItemReference } from '@totem/components/software/software_package/types';
import UserProfileContext from '@totem/components/UserProfileContext';
import { ForeignKey } from '@totem/types/common';
import { getToken } from '@totem/utilities/accountUtilities';
import { debounce } from '@totem/utilities/debounce';
import { DEVICES_ENDPOINT } from '@totem/utilities/endpoints';
import { CheckResponseShowError } from '@totem/utilities/responseUtilities';

const styles = {
  dialog: {
    maxHeight: '80%',
    maxWidth: '850px',
  },
  label: {
    fontSize: '12px',
    fontWeight: 600,
  },
  select: {
    width: '100%',
  },
};

const DEBOUNCE_TIME = 1500;

const OpenCveKeyModal = () => {
  const { userProfile } = useContext(UserProfileContext);
  const { data, action, onAction } = useContext(PackageContext);
  const [isSending, setIsSending] = useState<boolean>(false);
  const [vendorSearch, setVendorSearch] = useState<string>('');
  const [vendorOptions, setVendorOptions] = useState<OpenCVEItemReference[]>(
    [],
  );
  const [vendorKey, setVendorKey] = useState<string>('');
  const [productSearch, setProductSearch] = useState<string>('');
  const [productOptions, setProductOptions] = useState<OpenCVEItemReference[]>(
    [],
  );
  const [productKey, setProductKey] = useState<string>('');
  const [isSendingVendorKey, setIsSendingVendorKey] = useState<boolean>(false);
  const [isSendingProductKey, setIsSendingProductKey] =
    useState<boolean>(false);

  const sendCreateVendorKey = useCallback(async (payload: any) => {
    if (isSendingVendorKey) {
      return;
    }
    setIsSendingVendorKey(true);

    fetch(`${DEVICES_ENDPOINT}/software/catalog/${data.package.id}/keys`, {
      method: 'POST',
      headers: new Headers({
        Authorization: `Bearer ${getToken()}`,
      }),
      body: JSON.stringify(payload),
    }).then((res) => {
      CheckResponseShowError(res);
      setIsSendingVendorKey(false);
      onAction('refresh');
    });
  }, []);

  const sendCreateProductKey = useCallback(async (payload: any) => {
    if (isSendingProductKey) {
      return;
    }
    setIsSendingProductKey(true);

    fetch(`${DEVICES_ENDPOINT}/software/catalog/${data.package.id}/keys`, {
      method: 'POST',
      headers: new Headers({
        Authorization: `Bearer ${getToken()}`,
      }),
      body: JSON.stringify(payload),
    }).then((res) => {
      CheckResponseShowError(res);
      setIsSendingProductKey(false);
      onAction('refresh');
    });
  }, []);

  const getVendorOptions = useCallback(async (searchTerm: string) => {
    if (isSending) {
      return;
    }
    setIsSending(true);

    const uri = `${DEVICES_ENDPOINT}/software/cves/open-cve/vendors?search=${searchTerm}`;

    fetch(uri, {
      method: 'GET',
      headers: new Headers({
        Authorization: `Bearer ${getToken()}`,
      }),
    })
      .then((res) => res.json())
      .then((result: OpenCVEItemReference[]) => {
        if (result !== null) {
          setVendorOptions(result);
        } else {
          setVendorOptions([]);
        }
      })
      .then(() => {
        setIsSending(false);
      });
  }, []);

  const getProductOptions = useCallback(
    async (searchVendorKey: string, searchTerm: string) => {
      if (isSending || searchVendorKey === '') {
        return;
      }
      setIsSending(true);

      const uri = `${DEVICES_ENDPOINT}/software/cves/open-cve/vendors/${searchVendorKey}/products?search=${searchTerm}`;

      fetch(uri, {
        method: 'GET',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
      })
        .then((res) => res.json())
        .then((result: OpenCVEItemReference[]) => {
          if (result !== null) {
            setProductOptions(result);
          } else {
            setProductOptions([]);
          }
        })
        .then(() => {
          setIsSending(false);
        });
    },
    [],
  );

  useEffect(() => {
    getProductOptions(vendorKey, '');
  }, [vendorKey]);

  const handleSubmit = () => {
    if (vendorKey !== '' && productKey !== '') {
      const payloadVendor: ForeignKey = {
        system: 'OpenCVE',
        instance: 'vendor',
        key: vendorKey,
        uid: '',
        createdByType: 'user',
        createdByName: userProfile.email,
        createdById: userProfile.id,
        createdAt: dayjs().toISOString(),
      };

      const payloadProduct: ForeignKey = {
        system: 'OpenCVE',
        instance: 'product',
        key: productKey,
        uid: '',
        createdByType: 'user',
        createdByName: userProfile.email,
        createdById: userProfile.id,
        createdAt: dayjs().toISOString(),
      };

      sendCreateVendorKey(payloadVendor);
      sendCreateProductKey(payloadProduct);
    }
  };

  return (
    <Modal
      open={action === 'key_add'}
      onCancel={() => onAction('')}
      title={<ModalTitle>Add Foreign Key</ModalTitle>}
      confirmLoading={false}
      style={styles.dialog}
      footer={
        <ModalFooter>
          <Button onClick={() => onAction('')}>Close</Button>
          <Button onClick={handleSubmit} type="primary">
            Submit
          </Button>
        </ModalFooter>
      }
    >
      <div style={styles.label}>Vendor:</div>
      <Select
        style={styles.select}
        showSearch
        value={vendorSearch}
        onSearch={(selectedSystemName: string) => {
          setVendorSearch(selectedSystemName);
          debounce(getVendorOptions(selectedSystemName), DEBOUNCE_TIME);
        }}
        onSelect={(newVendor) => {
          setVendorKey(newVendor);
          setVendorSearch(newVendor);
        }}
        options={vendorOptions.map((option) => {
          return { value: option.name, label: option.human_name };
        })}
      />

      <div style={styles.label}>Product:</div>
      <Select
        style={styles.select}
        showSearch
        value={productSearch}
        onSearch={(selectedProductName: string) => {
          setProductSearch(selectedProductName);
          debounce(
            getProductOptions(vendorKey, selectedProductName),
            DEBOUNCE_TIME,
          );
        }}
        onSelect={(newProduct) => {
          setProductKey(newProduct);
          setProductSearch(newProduct);
        }}
        options={productOptions.map((option) => {
          return { value: option.name, label: option.human_name };
        })}
      />
    </Modal>
  );
};

export default OpenCveKeyModal;
