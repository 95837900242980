import React, { useContext } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Empty } from 'antd';

import ReportsContext from '@totem/components/ui_components/reports/ReportsContext';
import authUtilities from '@totem/utilities/authUtilities';
import { isNotNull } from '@totem/utilities/common';

const ReportsPanel = () => {
  const navigate = useNavigate();
  const { reports } = useContext(ReportsContext);

  const hasReports = isNotNull(reports) && reports.length > 0;

  if (hasReports && reports.length === 1) {
    navigate(reports[0].link);
  }

  const endpoint = authUtilities.isTenantUser()
    ? '/tenant-dashboard'
    : authUtilities.isVendorUser()
      ? '/vendor-dashboard'
      : '/dashboard';

  return (
    <>
      {hasReports && (
        <ul>
          {reports.map((report) => {
            return (
              <li>
                <NavLink to={`${endpoint}/${report.link}`}>
                  {report.name}
                </NavLink>
              </li>
            );
          })}
        </ul>
      )}
      {!hasReports && <Empty />}
    </>
  );
};

export default ReportsPanel;
