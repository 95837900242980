import React, { useContext } from 'react';

import ComponentHeader from '@totem/components/ComponentHeader';
import PageSection from '@totem/components/PageSection';
import PageSectionBody from '@totem/components/PageSectionBody';
import RemoteAccessTotalsContext from '@totem/components/remoteAccess/totals/RemoteAccessTotalsContext';
import TotalsContainer from '@totem/components/remoteAccess/totals/TotalsContainer';

const RemoteAccessSection = () => {
  const { data } = useContext(RemoteAccessTotalsContext);
  const hasRemoteAccessTotals = typeof data !== 'undefined' && data !== null;
  const showSection = hasRemoteAccessTotals;

  return (
    <>
      {showSection && (
        <PageSection>
          <ComponentHeader title={'Remote Access'} />
          <PageSectionBody>
            <TotalsContainer />
          </PageSectionBody>
        </PageSection>
      )}
    </>
  );
};

export default RemoteAccessSection;
