import React, { useCallback, useState } from 'react';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import Modal from 'antd/lib/modal/Modal';

import ModalFooter from '@totem/components/ModalFooter';
import ModalTitle from '@totem/components/ModalTitle';
import { getToken } from '@totem/utilities/accountUtilities';
import { TOKEN_REFRESH_ENDPOINT } from '@totem/utilities/endpoints';
import {
  jsonDateToDate,
  secondsToDuration,
} from '@totem/utilities/timeUtilities';

interface TokenRefreshResponse {
  token: string;
  expiration: string;
}
interface Props {
  ttl_seconds: number;
  open: boolean;
  onClose: () => void;
  onRefreshed: () => void;
}

const ExtendTokenModal = ({
  ttl_seconds,
  onClose,
  onRefreshed,
  open,
}: Props) => {
  const [isSending, setIsSending] = useState<boolean>(false);

  const refreshToken = useCallback(async () => {
    if (isSending) {
      return;
    }
    setIsSending(true);

    fetch(`${TOKEN_REFRESH_ENDPOINT}`, {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      }),
    })
      .then((res) => {
        setIsSending(false);
        if (res.status >= 400) {
          throw Error('Bad Response');
        }
        return res;
      })
      .then((res) => res.json())
      .then((result: TokenRefreshResponse) => {
        if (result.token !== '') {
          const expiration = jsonDateToDate(result.expiration);
          document.cookie = `access_token=${
            result.token
          };expires=${expiration.toUTCString()};path=/`;
          onRefreshed();
        }
      });
  }, []);

  const handleExtendToken = () => {
    refreshToken();
  };

  const getTimeToExpire = () => {
    let ttl = secondsToDuration(ttl_seconds, true, false);
    if (ttl === '') {
      ttl = 'less than a minute';
    }
    return ttl;
  };

  return (
    <Modal
      title={
        <ModalTitle>
          <QuestionCircleOutlined />
          &nbsp;&nbsp;Extend Session?
        </ModalTitle>
      }
      onCancel={onClose}
      open={open}
      footer={
        <ModalFooter>
          <Button onClick={onClose}>No</Button>
          <Button type="primary" loading={false} onClick={handleExtendToken}>
            Yes, extend session.
          </Button>
        </ModalFooter>
      }
    >
      <div>Your authentication token will expire in {getTimeToExpire()}!</div>
    </Modal>
  );
};

export default ExtendTokenModal;
