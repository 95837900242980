import React, { useEffect, useState } from 'react';
import { CloseCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Col, Form, Row, Select } from 'antd';

import { ExtendedObjectReference } from '@totem/types/common';
import { ContractService } from '@totem/types/contracts';

const FormItem = Form.Item;
const { Option } = Select;

import { getToken } from '@totem/utilities/accountUtilities';
import { CONTRACTS_ENDPOINT } from '@totem/utilities/endpoints';

import '../contracts.css';

const styles = {
  form: {
    width: '100%',
  },
  formItem: {
    paddingBottom: '0',
    marginBottom: '1rem',
  },
  addIcon: {
    marginLeft: '15px',
    color: '#1890ff',
  },
  removeIcon: {
    color: '#1890ff',
  },
};

export interface Props {
  contractLineId: string;
  services: ContractService[];
  onServicesChanged: (services: ContractService[]) => void;
}

const ContractLineServicesEditPanel = ({
  contractLineId,
  services,
  onServicesChanged,
}: Props) => {
  const [serviceData, setServiceData] = useState<ContractService[]>(
    typeof services !== 'undefined' && services !== null ? services : [],
  );
  const [availableServices, setAvailableServices] = useState<
    ExtendedObjectReference[]
  >([]);
  const [selectedService, setSelectedService] = useState<
    ExtendedObjectReference
  >({
    id: '',
    name: '',
    description: '',
    displayName: '',
  });

  useEffect(() => {
    fetch(`${CONTRACTS_ENDPOINT}/services`, {
      method: 'GET',
      headers: new Headers({
        Authorization: `Bearer ${getToken()}`,
      }),
    })
      .then(res => res.json())
      .then((result: ExtendedObjectReference[]) => {
        setAvailableServices(result);
      });
  }, []);

  useEffect(() => {
    onServicesChanged(serviceData);
  }, [serviceData]);

  const getAvailableServices = () => {
    return availableServices.filter(
      av => serviceData.findIndex(sd => sd.id === av.id) === -1,
    );
  };

  const handleServiceSelectChange = serviceId => {
    const foundService = availableServices.find(svc => svc.id === serviceId);
    if (typeof foundService !== 'undefined') {
      setSelectedService(foundService);
    } else {
      setSelectedService({
        id: '',
        name: '',
        description: '',
        displayName: '',
      });
    }
  };

  const handleServiceAdd = () => {
    if (selectedService.id !== '') {
      const newServiceItem = {
        id: selectedService.id,
        name: selectedService.displayName,
      };

      setServiceData(current => [...current, newServiceItem]);
      setSelectedService({
        id: '',
        name: '',
        description: '',
        displayName: '',
      });
    }
  };

  const handleServiceRemove = (serviceToRemove: ContractService) => {
    setServiceData(
      serviceData.filter(service => service.id !== serviceToRemove.id),
    );
  };

  return (
    <Row>
      <Col span={12}>
        <b>Assigned Services:</b>
        <br />
        {serviceData.map(service => (
          <span key={`${contractLineId}:${service.id}`}>
            <CloseCircleOutlined
              title="Remove Service"
              onClick={() => handleServiceRemove(service)}
              style={styles.removeIcon}
            />
            &nbsp;
            {service.name}
            <br />
          </span>
        ))}
      </Col>
      <Col span={12}>
        <FormItem
          label="Available Services"
          colon={false}
          style={styles.formItem}
        >
          <Row>
            <Col span={20}>
              <Select
                onChange={handleServiceSelectChange}
                defaultValue={selectedService.id}
                value={selectedService.id}
              >
                <Option value="">-- Select --</Option>
                {getAvailableServices().map(svc => {
                  return (
                    <Option key={svc.id} value={svc.id}>
                      {svc.displayName}
                    </Option>
                  );
                })}
              </Select>
            </Col>
            <Col span={4}>
              <PlusCircleOutlined
                title="Add Service"
                onClick={handleServiceAdd}
                style={styles.addIcon}
              />
            </Col>
          </Row>
        </FormItem>
      </Col>
    </Row>
  );
};

export default ContractLineServicesEditPanel;
