import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { FlagOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';
import { TablePaginationConfig } from 'antd/es/table';
import { ColumnProps } from 'antd/lib/table';
import { SorterResult } from 'antd/lib/table/interface';

import Table from '@totem/components/common/table/Table';
import { sortStringAscending } from '@totem/components/events/eventsContainer/utilities';
import RemoteAccessUserContext from '@totem/components/remoteAccess/roleUsers/RemoteAccessUserContext';
import RemoteAccessUserActionsMenu from '@totem/components/remoteAccess/roleUsers/rootRole/RemoteAccessUserActionMenu';
import {
  FLAG_STATUS_INACTIVE,
  FLAG_STATUS_NEVER_LOGGED_IN,
  getFlagStatus,
} from '@totem/components/remoteAccess/roleUsers/rootRole/utilities';
import colors from '@totem/styles/colors';
import { RemoteAccessUser } from '@totem/types/remoteAccessUsers';

import '../../remoteAccess.css';

const RemoteAccessUsersTable = () => {
  const {
    input,
    setInput,
    filteredData,
    loading,
    totalRecords,
    maxInactivityDays,
  } = useContext(RemoteAccessUserContext);

  const getDateString = (dateIn: string) => {
    if (dateIn === '0001-01-01T00:00:00Z') {
      return '-';
    }
    return dateIn ? new Date(dateIn).toLocaleDateString() : '-';
  };

  const getFlags = (user: RemoteAccessUser) => {
    const flagStatus = getFlagStatus(user);
    switch (flagStatus) {
      case FLAG_STATUS_NEVER_LOGGED_IN:
        return (
          <FlagOutlined
            title={`User has never logged in!`}
            style={{
              color: colors.event.criticality.background.warning,
              fontSize: '20px',
            }}
          />
        );
      case FLAG_STATUS_INACTIVE:
        return (
          <FlagOutlined
            title={`No login in the last ${maxInactivityDays} days!`}
            style={{
              color: colors.event.criticality.background.critical,
              fontSize: '20px',
            }}
          />
        );
      default:
        return <></>;
    }
  };

  const columns: ColumnProps<RemoteAccessUser>[] = [
    {
      title: '',
      dataIndex: 'picture',
      key: 'picture',
      width: 5,
      render: (text, record) => {
        return (
          <div className="center-table-cell">
            <Avatar size="large" shape="circle" src={record.picture} />
          </div>
        );
      },
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: (_, user: RemoteAccessUser) => (
        <Link to={`/dashboard/remoteAccessUsers/user/${user.user_id}`}>
          {user.email}
        </Link>
      ),
      sorter: (compA, compB) => sortStringAscending(compA.email, compB.email),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (compA, compB) => sortStringAscending(compA.name, compB.name),
    },
    {
      title: 'Created',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (date) => (date ? new Date(date).toLocaleDateString() : '-'),
      sorter: (compA, compB) =>
        sortStringAscending(compA.created_at, compB.created_at),
    },
    {
      title: 'Last Updated',
      dataIndex: 'updated_at',
      key: 'updated_at',
      render: (date) => (date ? new Date(date).toLocaleDateString() : '-'),
      sorter: (compA, compB) =>
        sortStringAscending(compA.updated_at, compB.updated_at),
    },
    {
      title: 'Last Login',
      dataIndex: 'last_login',
      key: 'last_login',
      render: (date) => getDateString(date),
      sorter: (compA, compB) =>
        sortStringAscending(compA.last_login, compB.last_login),
    },
    {
      title: 'Status',
      dataIndex: 'flags',
      key: 'flags',
      render: (_, user: RemoteAccessUser) => getFlags(user),
      filterMultiple: true,
      filteredValue: input.flags,
      filters: [
        {
          text: (
            <span>
              <FlagOutlined
                style={{
                  color: 'white',
                  fontSize: '20px',
                  marginRight: '15px',
                }}
              />
              Active
            </span>
          ),
          value: '1',
        },
        {
          text: (
            <span>
              <FlagOutlined
                title={`User has never logged in!`}
                style={{
                  color: colors.event.criticality.background.warning,
                  fontSize: '20px',
                  marginRight: '15px',
                }}
              />
              Never Used
            </span>
          ),
          value: '2',
        },
        {
          text: (
            <span>
              <FlagOutlined
                title={`No login in the last ${maxInactivityDays} days!`}
                style={{
                  color: colors.event.criticality.background.critical,
                  fontSize: '20px',
                  marginRight: '15px',
                }}
              />
              Stale Accounts
            </span>
          ),
          value: '4',
        },
      ],
    },
    {
      title: 'Last Pwd Reset',
      dataIndex: 'last_password_reset',
      key: 'last_password_reset',
      render: (date) => getDateString(date),
      sorter: (compA, compB) =>
        sortStringAscending(
          compA.last_password_reset,
          compB.last_password_reset,
        ),
    },
    {
      title: 'Actions',
      width: 30,
      dataIndex: 'additionalOptions',
      key: 'additionalOptions',
      render: (_, user: RemoteAccessUser) => (
        <div className="center-table-cell">
          <RemoteAccessUserActionsMenu user={user} />
        </div>
      ),
    },
  ];

  const handleTableChange = (
    updatedPagination: TablePaginationConfig,
    filters: SorterResult<RemoteAccessUser>,
    sorter,
  ) => {
    const { ...params } = filters;

    let sortDir: string = sorter.order === 'descend' ? '-1' : '1';
    if (typeof sorter.order === 'undefined' || sorter.order === null) {
      if (
        typeof input.sortDirection !== 'undefined' &&
        input.sortDirection !== null
      ) {
        sortDir = input.sortDirection;
      }
    }

    let sortField = input.sortField ? input.sortField : 'lastOccurrence';
    if (
      typeof sorter.field !== 'undefined' &&
      typeof sorter.order !== 'undefined'
    ) {
      sortField = sorter.field;
    }

    // @ts-ignore
    setInput({
      ...input,
      ...params,
      pageSize: updatedPagination.pageSize,
      page: updatedPagination.current,
      sortField,
      sortDirection: sortDir,
    });
  };

  return (
    <Table
      showSorterTooltip
      columns={columns}
      dataSource={filteredData}
      loading={loading}
      onChange={handleTableChange}
      pagination={{
        current: input.page,
        pageSize: input.pageSize,
        total: totalRecords,
        showSizeChanger: true,
      }}
      rowKey="user_id"
    />
  );
};

export default RemoteAccessUsersTable;
