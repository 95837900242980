import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';

import FindingsTable from '@totem/components/surveyV2/building/controlSystems/systemSecurity/FindingsTable';
import SurveyContext from '@totem/components/surveyV2/instanceDetail/SurveyInstanceContext';

import './auditReport.css';
const AuditReport = () => {
  const navigate = useNavigate();
  const { data } = useContext(SurveyContext);

  return (
    <>
      <div styleName="page-break">
        Findings
        {data?.survey?.isSubmitted && (
          <Button
            type="primary"
            onClick={() =>
              navigate(`/dashboard/surveyinstances/${data?.survey?.id}`)
            }
          >
            Go to Audit
          </Button>
        )}
      </div>
      <div styleName="section">
        <FindingsTable />
      </div>
    </>
  );
};

export default AuditReport;
