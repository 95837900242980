import React from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';

import colors from '@totem/styles/colors';
import { Assignee } from '@totem/types/user';

import AuditEmailModal from '../auditEmailModal/AuditEmailModal';

import './index.css';

interface ContactAssigneeBannerProps {
  /**
   * Assignees to pre-populate auto Email Prompt.
   */
  assignees: Assignee[];
  /**
   * Whether or not retrieving Audits is loading.
   */
  loading: boolean;
}

const ContactAssigneeBanner = ({
  loading = false,
  assignees = [],
}: ContactAssigneeBannerProps) => {
  return (
    <div styleName="tile-container">
      <div styleName="left-content">
        <InfoCircleOutlined
          style={{
            color: colors.brand.blue,
            fontSize: 32,
            marginRight: '20px',
          }}
        />
        <div>
          <div styleName="tile-title">Contact Assignees</div>
          <div styleName="tile-subtext">
            Send a personalized email to specific assignees. Filter the
            dashboard below to select assignees.
          </div>
        </div>
      </div>
      <AuditEmailModal loading={loading} assignees={assignees} />
    </div>
  );
};

export default ContactAssigneeBanner;
