import { gql } from '@apollo/client';

const FINDING_CRITICALITY_COUNTS_FRAGMENT = gql`
  fragment CriticalityCounts on FindingCriticalityCounts {
    critical
    high
    medium
    low
    compliant
  }
`;

const FINDING_COMPLIANT_COUNTS_FRAGMENT = gql`
  fragment CompliantCounts on FindingCompliantCounts {
    name
    compliant
    nonCompliant
  }
`;

export const GET_FINDINGS = gql`
  query GetFindings($input: FindingsConnectionInput!) {
    findings(input: $input) {
      totalCount
      leastCompliantCounts {
        assignee {
          ...CompliantCounts
        }
        region {
          ...CompliantCounts
        }
        building {
          ...CompliantCounts
        }
      }
      criticalityCounts {
        ...CriticalityCounts
      }
      findings {
        id
        region {
          id
          name
        }
        building {
          id
          name
        }
        controlSystem {
          id
          name
          systemType
        }
        assignee {
          email
          organizations {
            id
            name
            deactivatedAt
          }
        }
        criticality
        group
        levelOfEffort
        description
        additionalData {
          label
          text
        }
      }
    }
  }
  ${FINDING_COMPLIANT_COUNTS_FRAGMENT}
  ${FINDING_CRITICALITY_COUNTS_FRAGMENT}
`;

export const GET_FINDING_COUNTS = gql`
  query GetFindingCounts($input: FindingsConnectionInput!) {
    findings(input: $input) {
      criticalityCounts {
        ...CriticalityCounts
      }
      groupCounts {
        systemUsers {
          ...CriticalityCounts
        }
        systemConfiguration {
          ...CriticalityCounts
        }
        network {
          ...CriticalityCounts
        }
        continuity {
          ...CriticalityCounts
        }
        controlSystemHost {
          ...CriticalityCounts
        }
      }
      levelOfEffortCounts {
        minimal
        moderate
        maximum
      }
    }
  }
  ${FINDING_CRITICALITY_COUNTS_FRAGMENT}
`;
