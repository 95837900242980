import React, { useState } from 'react';
import { Form, Select } from 'antd';

import {
  ActivityExtendedField,
  ExtendedFieldValue,
} from '@totem/types/ticketing';

const FormItem = Form.Item;
const { Option } = Select;

import '../ticketing.css';

const styles = {
  form: {
    width: '100%',
  },
  formItem: {
    paddingBottom: '0',
    marginBottom: '1rem',
  },
};

type Props = {
  extendedField: ActivityExtendedField;
  extendedFieldValue: ExtendedFieldValue;
  onChange: (name: string, value: string, id: string, isValid: boolean) => void;
};

const ExtendedFieldSelect = ({
  extendedField,
  extendedFieldValue,
  onChange,
}: Props) => {
  const [selectedValue, setSelectedValue] = useState<string>(
    extendedFieldValue !== null ? extendedFieldValue.value : '',
  );

  const getOptionDisplay = (value: string) => {
    let displayValue = '';
    if (extendedField.options !== null) {
      extendedField.options.forEach(option => {
        if (option.value === value) {
          displayValue = option.display;
        }
      });
    }
    return displayValue;
  };

  const checkIsValid = checkValue => {
    if (extendedField.isRequired && checkValue.trim() === '') {
      return false;
    }
    return true;
  };

  const handleChange = (value: string) => {
    setSelectedValue(value);
    onChange(
      extendedField.name,
      getOptionDisplay(value),
      value,
      checkIsValid(value),
    );
  };

  return (
    <FormItem
      label={extendedField.label}
      colon={false}
      style={styles.formItem}
      required={extendedField.isRequired}
      validateStatus={extendedFieldValue.isValid ? 'success' : 'error'}
    >
      <Select defaultValue={selectedValue} onChange={handleChange}>
        {extendedField.options !== null &&
          extendedField.options.map(option => {
            return (
              <Option key={option.value} value={option.value}>
                {option.display}
              </Option>
            );
          })}
      </Select>
    </FormItem>
  );
};

export default ExtendedFieldSelect;
