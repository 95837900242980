import React, { useCallback, useEffect, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { AutoComplete, Input } from 'antd';

import { usePreviousValue } from '@totem/hooks/usePreviousValue';
import colors from '@totem/styles/colors';
import fonts from '@totem/styles/fonts';
import { debounce } from '@totem/utilities/debounce';
import { validateEmail } from '@totem/utilities/validation';

const { Search } = Input;

const SEARCH_USERS = gql`
  query SearchUsers($input: UsersSearchInput!) {
    usersSearch(input: $input)
  }
`;

export interface Props {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  style?: React.CSSProperties;
  allowClear?: boolean;
  disabled?: boolean;
  limit?: number;
}

const styles = {
  input: {
    width: '100%',
  },
  notFound: {
    color: colors.neutral.gray,
    fontSize: fonts.fontMd,
  },
};

const DEBOUNCE_TIME = 500;

const getData = (emails: string[]) =>
  emails.map(email => ({
    id: email,
    value: email,
  }));

const EmailAutoComplete = ({
  value,
  onChange,
  style,
  allowClear,
  disabled,
  placeholder = '',
  limit = 5,
}: Props) => {
  const [open, setOpen] = useState<boolean>(false);
  const [emails, setEmails] = useState<string[]>([]);
  const [email, setEmail] = useState<string>(value || '');

  const previousValue = usePreviousValue<string>(value);

  const { loading, error } = useQuery(SEARCH_USERS, {
    variables: { input: { email, limit } },
    fetchPolicy: 'no-cache',
    onCompleted: (res) => setEmails(res?.usersSearch || []),
  });

  const handleSearch = useCallback(
    debounce((search: string) => setEmail(search), DEBOUNCE_TIME),
    [],
  );

  const handleSelect = (selection: string) => {
    onChange(selection);
    setEmail(selection);
  };

  useEffect(() => {
    if (!value) {
      setEmails([]);
      setEmail('');
    }
  }, [value]);

  useEffect(() => {
    if (!previousValue && value) {
      setEmail(value);
    }
  }, [value, previousValue]);

  const getNotFoundContent = () => {
    return email && validateEmail(email) ? (
      <div
        style={styles.notFound}
        onClick={() => {
          setOpen(false);
          onChange(email);
        }}
      >
        Looks like this person isn't a Intelligent Buildings user yet. Add their
        email address to invite them.
      </div>
    ) : null;
  };

  return (
    <AutoComplete
      open={open}
      options={getData(emails)}
      style={{ ...styles.input, ...style }}
      value={value || ''}
      onChange={onChange}
      onSelect={handleSelect}
      onSearch={handleSearch}
      placeholder={placeholder}
      allowClear={allowClear ?? false}
      disabled={disabled ?? false}
      notFoundContent={getNotFoundContent()}
      children={<Search type="search" loading={loading || !!error} />}
      onDropdownVisibleChange={setOpen}
    />
  );
};

export default EmailAutoComplete;
