import { createContext } from 'react';

import { OrganizationHierarchy } from '@totem/types/organization';

interface Context {
  loading: boolean;
  data: OrganizationHierarchy;
  regions: OrganizationHierarchy[];
  buildings: OrganizationHierarchy[];
  controlSystems: OrganizationHierarchy[];
  selectedRegion: OrganizationHierarchy;
  selectedBuilding: OrganizationHierarchy;
  selectedControlSystem: OrganizationHierarchy;
  setSelectedRegion: (region: OrganizationHierarchy) => void;
  setSelectedBuilding: (building: OrganizationHierarchy) => void;
  setSelectedControlSystem: (controlSystem: OrganizationHierarchy) => void;
  doRefresh: () => void;
}

export default createContext<Context>({
  loading: false,
  data: null,
  regions: [],
  buildings: [],
  controlSystems: [],
  selectedRegion: null,
  selectedBuilding: null,
  selectedControlSystem: null,
  setSelectedRegion: () => {},
  setSelectedBuilding: () => {},
  setSelectedControlSystem: () => {},
  doRefresh: () => {},
});
