import React, { useState } from 'react';
import { Col, Row } from 'antd';

import BasePane from '@totem/components/BasePane';
import SquareFootageWrapper from '@totem/components/buildings/squareFootage/SquareFootageWrapper';
import TreeMapChart from '@totem/components/buildings/squareFootage/TreeMapChart';
import AddBuildingButton from '@totem/components/buildingSummary/AddBuildingButton';
import BuildingsMap from '@totem/components/buildingSummary/BuildingsMap';
import BuildingSummaryContainer from '@totem/components/buildingSummary/BuildingSummaryContainer';
import BuildingSummaryTable from '@totem/components/buildingSummary/BuildingSummaryTable';
import NameSearch from '@totem/components/buildingSummary/NameSearch';
import ComponentHeader from '@totem/components/ComponentHeader';
import ContentLayout from '@totem/components/ContentLayout';
import PrimaryContentPane from '@totem/components/PrimaryContentPane';
import RecordCountBadge from '@totem/components/RecordCountBadge';

import './buildingSummary.css';
import FilterChips from '@totem/components/buildingSummary/FilterChips';
const BuildingSummaryPage = () => {
  const [totalRecords, setTotalRecords] = useState<number>(0);

  return (
    <ContentLayout>
      <BuildingSummaryContainer onRecordTotalChanged={setTotalRecords}>
        <BasePane>
          <PrimaryContentPane>
            <ComponentHeader
              title={'Building Management'}
              options={
                <>
                  <AddBuildingButton />
                </>
              }
            />
            <FilterChips />
            <Row>
              <Col span={12}>
                <BuildingsMap />
              </Col>
              <Col span={12}>
                <SquareFootageWrapper>
                  <TreeMapChart />
                </SquareFootageWrapper>
              </Col>
            </Row>
            <div styleName="search-container">
              <NameSearch />
              <div styleName="table-count">
                <RecordCountBadge count={totalRecords} />
              </div>
            </div>
            <div styleName="table-container">
              <BuildingSummaryTable />
            </div>
          </PrimaryContentPane>
        </BasePane>
      </BuildingSummaryContainer>
    </ContentLayout>
  );
};

export default BuildingSummaryPage;
