import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DownOutlined, RightOutlined } from '@ant-design/icons';

import BasePane from '@totem/components/BasePane';
import DetailSpinner from '@totem/components/common/DetailSpinner';
import PrimaryContentPane from '@totem/components/PrimaryContentPane';
import {
  deviceOverviewSelector,
  getFlaggedDevices,
  getUnflaggedDevices,
} from '@totem/selectors/nmapAnalysisSelectors';
import { Nmap, NmapDevice } from '@totem/types/nmap';
import api from '@totem/utilities/api';
import { NMAP_ENDPOINT } from '@totem/utilities/endpoints';

import ContentLayout from '../ContentLayout';

import EditDeviceModal from './EditDeviceModal';
import FindingsOverview from './FindingsOverview';
import FlaggedDevicesOverview from './FlaggedDevicesOverview';
import FlaggedDevicesTable from './FlaggedDevicesTable';
import ScannedDevicesOverview from './ScannedDevicesOverview';
import UnflaggedDevicesTable from './UnflaggedDevicesTable';

import './nmapDetail.css';

const styles = {
  collapsedIcon: {
    cursor: 'pointer',
    marginRight: '1rem',
  },
};

const NmapDetail = () => {
  const { id } = useParams();
  // @ts-ignore
  const [nmap, setNmap] = useState<Nmap>({
    devices: [],
  });
  const [flaggedDevices, setFlaggedDevices] = useState<NmapDevice[]>([]);
  const [unFlaggedDevices, setUnFlaggedDevices] = useState<NmapDevice[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [scannedDevicesTableCollapsed, setScannedDevicesTableCollapsed] =
    useState<boolean>(false);

  // @ts-ignore
  const [device, setDevice] = useState<NmapDevice>({});
  const [visible, setVisible] = useState<boolean>(false);

  useEffect(() => {
    const getNmap = async () => {
      const { data } = await api.get(`${NMAP_ENDPOINT}/${id}`);

      setNmap(data);
      setLoading(false);
      setFlaggedDevices(getFlaggedDevices(data.devices));
      setUnFlaggedDevices(getUnflaggedDevices(data.devices));
    };

    getNmap();
  }, [id]);

  // eslint-disable-next-line no-shadow
  const updateDevice = async (device: NmapDevice) => {
    const { data } = await api.patch(
      `${NMAP_ENDPOINT}/${id}/devices/${device.id}`,
      device,
    );

    setNmap(data);
    setDevice(device);
    setFlaggedDevices(getFlaggedDevices(data.devices));
    setUnFlaggedDevices(getUnflaggedDevices(data.devices));
  };

  // eslint-disable-next-line no-shadow
  const handleSetDevice = (device: NmapDevice) => {
    setDevice(device);
    setVisible(true);

    if (!device.isReviewed) {
      updateDevice({
        ...device,
        isReviewed: true,
      });
    }
  };

  if (loading) {
    return (
      <PrimaryContentPane>
        <div styleName="spinner-container">
          <DetailSpinner />
        </div>
      </PrimaryContentPane>
    );
  }

  const overview = deviceOverviewSelector(nmap);

  return (
    <ContentLayout
      pageTitle={nmap.name || 'Nmap Details'}
      breadcrumbs={(breadcrumbs) => [
        ...breadcrumbs,
        {
          label: nmap.building.name,
          link: `/dashboard/buildings/${nmap.building.id}`,
        },
        {
          label: 'Nmap Details',
        },
      ]}
    >
      <>
        <BasePane>
          <div styleName="overviews-container">
            <div styleName="scanned-devices-overview-container">
              <ScannedDevicesOverview
                nmap={nmap}
                flaggedDevices={flaggedDevices}
                totalDevices={overview.total}
                reviewedDevices={overview.reviewed}
              />
            </div>
            <div styleName="overview-container">
              <FlaggedDevicesOverview
                numberOfFlaggedDevices={overview.flagged}
              />
            </div>
            <div styleName="flagged-devices-overview-container">
              <FindingsOverview
                nmap={nmap}
                flaggedDevices={flaggedDevices}
                portFindingsCount={overview.ports}
                osFindingsCount={overview.os}
                manufacturerFindingsCount={overview.manufacturer}
                otherFindingsCount={overview.other}
              />
            </div>
            {!!unFlaggedDevices.length && (
              <PrimaryContentPane>
                <div styleName="table-heading">
                  <div>
                    {scannedDevicesTableCollapsed ? (
                      <RightOutlined
                        style={styles.collapsedIcon}
                        onClick={() =>
                          setScannedDevicesTableCollapsed(
                            !scannedDevicesTableCollapsed,
                          )
                        }
                      />
                    ) : (
                      <DownOutlined
                        style={styles.collapsedIcon}
                        onClick={() =>
                          setScannedDevicesTableCollapsed(
                            !scannedDevicesTableCollapsed,
                          )
                        }
                      />
                    )}
                  </div>
                  <div>Scanned Devices</div>
                </div>
                {!scannedDevicesTableCollapsed && (
                  <UnflaggedDevicesTable
                    devices={unFlaggedDevices}
                    onDeviceSelect={handleSetDevice}
                  />
                )}
              </PrimaryContentPane>
            )}
            {!!flaggedDevices.length && (
              <PrimaryContentPane>
                <div styleName="table-heading split">
                  <div styleName="table-heading-label">Flagged Devices</div>
                </div>
                <FlaggedDevicesTable
                  devices={flaggedDevices}
                  onDeviceSelect={handleSetDevice}
                />
              </PrimaryContentPane>
            )}
          </div>
        </BasePane>
        <EditDeviceModal
          visible={visible}
          setVisible={setVisible}
          updateDevice={updateDevice}
          device={device}
          loading={loading}
        />
      </>
    </ContentLayout>
  );
};

export default NmapDetail;
