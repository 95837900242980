import React, { useContext, useEffect, useState } from 'react';
import { Card, Flex } from 'antd';

import Grade from '@totem/components/common/Grade';
import PercentageBar from '@totem/components/policyAuditDetail/PercentageBar';
import SurveyContext from '@totem/components/surveyV2/instanceDetail/SurveyInstanceContext';
import colors from '@totem/styles/colors';
import { isNotNull } from '@totem/utilities/common';
import { getExactPercentage } from '@totem/utilities/numberUtilities';

import './auditReport.css';

const styles = {
  card: {
    width: '100%',
    margin: '0rem 1.6rem 1rem 1.6rem',
  },
  cardHeader: {
    borderTop: `5px solid`,
    borderBottom: `1px solid ${colors.antd.borderGray}`,
    borderImage: `${colors.gradient.blue} 100% 0 0 0`,
  },
  cardBody: {
    borderTop: `1px solid ${colors.antd.borderGray}`,
  },
  center: {
    alignItems: 'center',
  },
  percentageContainer: {
    alignItems: 'center',
    width: '100%',
  },
};

interface CategoryScore {
  category: string;
  maxScore: number;
  totalScore: number;
}

const ScoreSummary = () => {
  const { data } = useContext(SurveyContext);
  const [categoryScores, setCategoryScores] = useState<CategoryScore[]>([]);

  useEffect(() => {
    const catScores: CategoryScore[] = [];

    if (isNotNull(data)) {
      for (let cIdx = 0; cIdx < data.survey.categories.length; cIdx++) {
        const category = data.survey.categories[cIdx];
        if (category.visible) {
          const catScore = {
            category: category.name,
            maxScore: category.maxScore,
            totalScore: category.totalScore,
          };
          // eslint-disable-next-line max-depth
          if (catScore.maxScore > 0) {
            catScores.push(catScore);
          }
        }
      }
    }

    setCategoryScores(catScores);
  }, [data]);

  return (
    <Card
      bordered
      headStyle={styles.cardHeader}
      bodyStyle={styles.cardBody}
      title={
        <Flex justify={'space-between'} align={'flex-start'}>
          <div styleName="card-header-title">Score Summary</div>
          <Flex
            justify={'flex-start'}
            align={'flex-start'}
            style={styles.center}
          >
            <div styleName="overall-grade">{`overall - ${
              isNotNull(data) && data.survey.maxScore > 0
                ? getExactPercentage(
                    data.survey.maxScore,
                    data.survey.totalScore,
                  ).toFixed(0)
                : 0
            }%`}</div>
            <Grade
              score={
                isNotNull(data) && data.survey.maxScore > 0
                  ? getExactPercentage(
                      data.survey.maxScore,
                      data.survey.totalScore,
                    )
                  : 0
              }
            />
          </Flex>
        </Flex>
      }
      style={styles.card}
    >
      {isNotNull(categoryScores) &&
        categoryScores.map((categoryScore) => (
          <Flex
            justify={'flex-start'}
            align={'flex-start'}
            style={styles.percentageContainer}
            key={categoryScore.category}
          >
            <div styleName="group-score">{categoryScore.category}</div>
            <PercentageBar
              score={getExactPercentage(
                categoryScore.maxScore,
                categoryScore.totalScore,
              )}
            />
            <Grade
              score={getExactPercentage(
                categoryScore.maxScore,
                categoryScore.totalScore,
              )}
              size="small"
            />
          </Flex>
        ))}
    </Card>
  );
};

export default ScoreSummary;
