import React, { ReactNode } from 'react';

import ComponentHeader from '@totem/components/ComponentHeader';
import { ComponentInstance } from '@totem/components/home/lib/types';
import WidgetRemoveButton from '@totem/components/home/widgets/WidgetRemoveButton';

import '../home.css';

type Props = {
  title?: string | ReactNode;
  options?: ReactNode;
  componentInstance: ComponentInstance;
  onRemoveItem: (componentId: string) => void;
};

const WidgetCardHeader = ({
  title,
  options,
  onRemoveItem,
  componentInstance,
}: Props) => {
  return (
    <div styleName="widget-header-wrapper">
      <ComponentHeader title={title} options={options} />
      {typeof onRemoveItem === 'function' && (
        <WidgetRemoveButton
          onRemoveItem={onRemoveItem}
          componentInstance={componentInstance}
        />
      )}
    </div>
  );
  //return <div styleName="widget-card-header">{children}</div>;
};

export default WidgetCardHeader;
