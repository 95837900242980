import React, { useContext, useMemo, useState } from 'react';
import { ColumnProps } from 'antd/lib/table';

import Table from '@totem/components/common/table/Table';
import DeviceContext from '@totem/components/devices/deviceDetails/DeviceContext';
import { MemorySlot } from '@totem/types/devices';
import { getTablePagination } from '@totem/utilities/paginationUtilities';

const MemorySlotsTable = () => {
  const { deviceData, loading } = useContext(DeviceContext);
  const [limit, setLimit] = useState<number>(10);
  const [offset, setOffset] = useState<number>(0);

  const handlePagination = (pagination: any) => {
    const { current, pageSize } = pagination;
    setOffset((current - 1) * limit);
    setLimit(pageSize);
  };

  const totalCount =
    typeof deviceData !== 'undefined' &&
    deviceData !== null &&
    deviceData.device.memorySlots !== null
      ? deviceData.device.memorySlots.length
      : 0;

  const pagination = useMemo(() => {
    return getTablePagination({ limit, offset }, totalCount);
  }, [limit, offset, totalCount]);

  const columns: ColumnProps<MemorySlot>[] = [
    {
      title: 'Slot Name',
      dataIndex: 'slotName',
      key: 'slotName',
      sorter: (compA, compB) =>
        compA.slotName === compB.slotName
          ? 0
          : compA.slotName > compB.slotName
          ? 1
          : -1,
    },
    {
      title: 'Form Factor',
      dataIndex: 'formFactor',
      key: 'formFactor',
      sorter: (compA, compB) =>
        compA.formFactor === compB.formFactor
          ? 0
          : compA.formFactor > compB.formFactor
          ? 1
          : -1,
      render: (_, memSlot) => (memSlot.size > 0 ? memSlot.size : ''),
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      sorter: (compA, compB) =>
        compA.type === compB.type ? 0 : compA.type > compB.type ? 1 : -1,
      render: (_, memSlot) => (memSlot.size > 0 ? memSlot.type : ''),
    },
    {
      title: 'Data Width',
      dataIndex: 'dataWidth',
      key: 'dataWidth',
      sorter: (compA, compB) =>
        compA.dataWidth === compB.dataWidth
          ? 0
          : compA.dataWidth > compB.dataWidth
          ? 1
          : -1,
      render: (_, memSlot) => (memSlot.size > 0 ? memSlot.dataWidth : ''),
    },
    {
      title: 'Size',
      dataIndex: 'size',
      key: 'size',
      sorter: (compA, compB) =>
        compA.size === compB.size ? 0 : compA.size > compB.size ? 1 : -1,
      render: (_, memSlot) => (memSlot.size > 0 ? memSlot.size : ''),
    },
    {
      title: 'Speed',
      dataIndex: 'speed',
      key: 'speed',
      sorter: (compA, compB) =>
        compA.speed === compB.speed ? 0 : compA.speed > compB.speed ? 1 : -1,
      render: (_, memSlot) => (memSlot.size > 0 ? memSlot.speed : ''),
    },
    {
      title: 'Manufacturer',
      dataIndex: 'manufacturer',
      key: 'manufacturer',
      sorter: (compA, compB) =>
        compA.manufacturer === compB.manufacturer
          ? 0
          : compA.manufacturer > compB.manufacturer
          ? 1
          : -1,
      render: (_, memSlot) => (memSlot.size > 0 ? memSlot.manufacturer : ''),
    },
  ];

  return (
    <Table
      showSorterTooltip
      columns={columns}
      pagination={pagination}
      onChange={handlePagination}
      dataSource={
        typeof deviceData !== 'undefined' &&
        deviceData !== null &&
        deviceData.device !== null &&
        deviceData.device.memorySlots !== null
          ? deviceData.device.memorySlots
          : []
      }
      loading={loading}
      rowKey={record => record.slotName}
    />
  );
};

export default MemorySlotsTable;
