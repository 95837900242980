import dayjs from 'dayjs';

import { MIN_DATE_TIME_STRING } from '@totem/types/ticketing';

export const ParseDate = (date: number): dayjs.Dayjs | null => {
  if (!date) {
    return null;
  }

  return dayjs(date);
};

export const ParseStringDate = (strDate: string): dayjs.Dayjs | null => {
  if (strDate === MIN_DATE_TIME_STRING) {
    return null;
  }
  const date = new Date(strDate);
  if (!date) {
    return null;
  }
  if (date.getFullYear() < 1900) {
    return null;
  }

  return dayjs(date);
};

export const DateStringToLocal = (dateStringIn: string) => {
  if (dateStringIn !== null) {
    if (dateStringIn !== MIN_DATE_TIME_STRING) {
      if (!isNaN(Date.parse(dateStringIn))) {
        return new Date(dateStringIn).toLocaleString();
      }
    }
  }
  return '-';
};

export const DateStringToLocalDate = (dateStringIn: string) => {
  if (dateStringIn !== null) {
    if (dateStringIn !== MIN_DATE_TIME_STRING) {
      if (!isNaN(Date.parse(dateStringIn))) {
        const dateVal = new Date(dateStringIn);
        // eslint-disable-next-line max-depth
        if (dateVal.getFullYear() > 1900) {
          return dateVal.toLocaleDateString();
        }
      }
    }
  }
  return '-';
};

export const DateStringToDate = (dateStringIn: string): Date | null => {
  if (dateStringIn !== null) {
    if (dateStringIn !== MIN_DATE_TIME_STRING) {
      if (!isNaN(Date.parse(dateStringIn))) {
        return new Date(dateStringIn);
      }
    }
  }
  return null;
};

export const DaysBetween = (date1: Date | null, date2: Date | null) => {
  if (date1 !== null && date2 !== null) {
    const diff = Math.abs(date1.getTime() - date2.getTime());
    const diffDays = Math.ceil(diff / (1000 * 3600 * 24));

    return diffDays;
  }
  return null;
};

export const DaysBetweenToString = (date1: Date | null, date2: Date | null) => {
  if (date1 !== null && date2 !== null) {
    const diff = Math.abs(date1.getTime() - date2.getTime());
    const diffDays = Math.ceil(diff / (1000 * 3600 * 24));

    return diffDays.toString(10);
  }
  return '-';
};
