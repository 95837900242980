import React from 'react';
import { Tabs, TabsProps } from 'antd';

import CvssMetricsV2Panel from '@totem/components/software/cves/cvssMetricsV2Panel';
import CvssMetricsV3Panel from '@totem/components/software/cves/cvssMetricsV3Panel';
import { CVERecord } from '@totem/components/software/cves/types';

type Props = {
  cve: CVERecord;
};

const CvssTabs = ({ cve }: Props) => {
  const getTabItems = () => {
    const items: TabsProps['items'] = [];

    if (
      cve.cve.raw_nvd_data.metrics.cvssMetricV30 !== null &&
      cve.cve.raw_nvd_data.metrics.cvssMetricV30.length > 0
    ) {
      items.push({
        key: 'v3',
        label: 'CVSS v3',
        children: (
          <>
            {cve.cve.raw_nvd_data.metrics.cvssMetricV30.map((metric, idx) => (
              <div key={idx}>
                <CvssMetricsV3Panel metricsV3={metric} />
              </div>
            ))}
          </>
        ),
      });
    }

    if (
      cve.cve.raw_nvd_data.metrics.cvssMetricV2 !== null &&
      cve.cve.raw_nvd_data.metrics.cvssMetricV2.length > 0
    ) {
      items.push({
        key: 'v2',
        label: 'CVSS v2',
        children: (
          <>
            {cve.cve.raw_nvd_data.metrics.cvssMetricV2.map((metric, idx) => (
              <div key={idx}>
                <CvssMetricsV2Panel metricsV2={metric} />
              </div>
            ))}
          </>
        ),
      });
    }

    return items;
  };

  return <Tabs items={getTabItems()} />;
};

export default CvssTabs;
