import React from 'react';
import { DatePicker, Form } from 'antd';

import {
  ActivityExtendedField,
  ExtendedFieldValue,
} from '@totem/types/ticketing';

const FormItem = Form.Item;

import '../ticketing.css';

const styles = {
  form: {
    width: '100%',
  },
  formItem: {
    paddingBottom: '0',
    marginBottom: '1rem',
  },
};

type Props = {
  extendedField: ActivityExtendedField;
  extendedFieldValue: ExtendedFieldValue;
  onChange: (name: string, value: string, isValid: boolean) => void;
};

const ExtendedFieldDate = ({
  extendedField,
  extendedFieldValue,
  onChange,
}: Props) => {
  const checkIsValid = checkValue => {
    if (extendedField.isRequired && checkValue.trim() === '') {
      return false;
    }
    return true;
  };

  const handleChange = (date, dateString) => {
    onChange(extendedField.name, dateString, checkIsValid(dateString));
  };

  return (
    <FormItem
      label={extendedField.label}
      colon={false}
      style={styles.formItem}
      required={extendedField.isRequired}
      validateStatus={extendedFieldValue.isValid ? 'success' : 'error'}
    >
      <DatePicker name={extendedField.name} onChange={handleChange} />
    </FormItem>
  );
};

export default ExtendedFieldDate;
