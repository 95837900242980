import * as R from 'ramda';

const _isObject = value => R.is(Object, value);

const _isNilOrEmpty = val => R.isNil(val) || R.isEmpty(val);

const _omitDeep = (value: Object, keys: string[]) => {
  if (typeof value === 'undefined') {
    return {};
  }

  if (Array.isArray(value)) {
    for (let idx = 0; idx < value.length; idx++) {
      value[idx] = _omitDeep(value[idx], keys);
    }
    return value;
  }

  if (!_isObject(value)) {
    return value;
  }

  if (!Array.isArray(keys)) {
    return value;
  }

  for (let idx = 0; idx < keys.length; idx++) {
    delete value[keys[idx]];
  }

  for (const key in value) {
    if (value.hasOwnProperty(key)) {
      value[key] = _omitDeep(value[key], keys);
    }
  }

  return value;
};

/**
 * Recursively omits keys from object
 * @param {Object} value - Object to alter.
 * @param {Array} keys - List of keys to omit from value.
 * @returns {Object} - Returns a new object
 */
export const omitDeep = (value: Object, keys: string[]) => {
  const copy = R.clone(value);
  return _omitDeep(copy, keys);
};

/**
 * Removes nil or empty values from the given object
 * @param {Object} value - Object to alter.
 * @returns {Object} - Returns a new object
 */
export const omitNilOrEmpty = (value: Object) => {
  return R.reject(_isNilOrEmpty, value);
};

export default {
  omitDeep,
};
