import React from 'react';

import DonutChart from '@totem/components/ui_components/info_widget/DonutChart';
import {
  Point,
  Points,
} from '@totem/components/ui_components/info_widget/types';

import '../ui_components.css';

type Props = {
  points: Points;
  chartTitle: string;
  title: string;
  total: string;
  chartOptions?: ApexCharts.ApexOptions;
  onPointClicked?: (index: number, point: Point) => void;
};

const InfoWidget = ({
  title,
  total,
  points,
  chartTitle,
  chartOptions,
  onPointClicked,
}: Props) => {
  const getChartSection = () => {
    // eslint-disable-next-line react/prop-types
    if (typeof points !== 'undefined' && points !== null && points.length > 0) {
      return (
        <DonutChart
          points={points}
          chartOptions={chartOptions}
          onPointClicked={onPointClicked}
        />
      );
    }
    return (
      <div
        style={{
          height: '158px',
          width: '200px',
          textAlign: 'center',
          verticalAlign: 'middle',
          display: 'table-cell',
          fontSize: '20px',
          fontWeight: 700,
        }}
      >
        No Data
      </div>
    );
  };

  return (
    <div>
      <table style={{ width: '100%', textAlign: 'center' }}>
        <tbody>
          <tr>
            <td
              style={{
                width: '38%',
                textAlign: 'center',
                verticalAlign: 'top',
                lineHeight: 1,
              }}
            >
              <span
                style={{ fontSize: '24px', marginTop: '15px', fontWeight: 700 }}
              >
                {title}
              </span>
              <br />
              <div
                style={{
                  fontSize: '52px',
                  fontWeight: 700,
                  marginBottom: '15px',
                  marginTop: '5px',
                }}
              >
                {total}
              </div>
            </td>
            <td
              style={{
                width: '62%',
                textAlign: 'center',
                justifyItems: 'center',
                verticalAlign: 'center',
              }}
            >
              {getChartSection()}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default InfoWidget;
