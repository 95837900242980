import React, { useCallback, useContext, useState } from 'react';
import { Flex } from 'antd';

import TenantBuildingLocationAddButton from '@totem/components/tenants/tenantsPage/TenantBuildingLocationAddButton';
import TenantsContext from '@totem/components/tenants/tenantsPage/TenantsContext';
import { TenantBuilding } from '@totem/components/tenants/types';
import IBDIcon from '@totem/styles/Icon';
import { getToken } from '@totem/utilities/accountUtilities';
import { TENANTS_ENDPOINT } from '@totem/utilities/endpoints';

type Props = {
  tenantId: string;
  building: TenantBuilding;
};

const TenantTableBuildingLocations = ({ tenantId, building }: Props) => {
  const [isSending, setIsSending] = useState<boolean>(false);
  const { onAction } = useContext(TenantsContext);

  const sendBuildingLocationDelete = useCallback(
    async (
      paramTenantId: string,
      paramBuilding: TenantBuilding,
      location: string,
    ) => {
      if (isSending) {
        return;
      }
      setIsSending(true);
      fetch(
        `${TENANTS_ENDPOINT}/${paramTenantId}/buildings/${paramBuilding.id}/locations/${location}`,
        {
          method: 'DELETE',
          headers: new Headers({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
          }),
        },
      ).then(() => {
        setIsSending(false);
        onAction('refresh', null);
      });
    },
    [isSending],
  );

  const handleDelete = (location: string) => {
    sendBuildingLocationDelete(tenantId, building, location);
  };

  return (
    <>
      <div>
        <b>Locations:</b>
      </div>
      {building.locations.map((loc) => (
        <Flex key={loc} justify={'space-between'}>
          <div>{loc}</div>
          <div>
            <IBDIcon
              name={'Delete'}
              title={`Delete ${loc}`}
              onClick={() => handleDelete(loc)}
            />
          </div>
        </Flex>
      ))}
      <br />
      <TenantBuildingLocationAddButton
        tenantId={tenantId}
        building={building}
      />
    </>
  );
};

export default TenantTableBuildingLocations;
