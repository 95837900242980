import React, { useState } from 'react';
import { Tabs, TabsProps } from 'antd';

import BasePane from '@totem/components/BasePane';
import ContentLayout from '@totem/components/ContentLayout';
import EventFilterContainer from '@totem/components/events/eventFilters/EventFilterContainer';
import EventFilters from '@totem/components/events/eventFilters/EventFilters';
import EventContainerTable from '@totem/components/events/eventsContainer/EventContainerTable';
import EventEmbeddedContainer from '@totem/components/events/eventsContainer/EventEmbeddedContainer';
import EventGraphsContainer from '@totem/components/events/graphs/EventGraphsContainer';
import EventGraphsLayout from '@totem/components/events/graphs/EventGraphsLayout';
import PrimaryContentPane from '@totem/components/PrimaryContentPane';
import RecordCountBadge from '@totem/components/RecordCountBadge';
import TabTitle from '@totem/components/TabTitle';

const styles = {
  totals: {
    fontSize: '16px',
  },
  tabContainer: {
    paddingRight: '2rem',
    paddingLeft: '2rem',
  },
};

const EventsPage = () => {
  const [totalRecords, setTotalRecords] = useState<number>(0);

  const tabItems: TabsProps['items'] = [
    {
      key: 'summary',
      label: <TabTitle>Summary</TabTitle>,
      children: (
        <EventGraphsContainer>
          <EventGraphsLayout />
        </EventGraphsContainer>
      ),
    },
    {
      key: 'details',
      label: <TabTitle>Details</TabTitle>,
      children: (
        <EventEmbeddedContainer
          onRecordTotalChanged={setTotalRecords}
          defaultFilters={{ status: ['CREATED', 'ACKNOWLEDGED'] }}
        >
          <EventContainerTable />
        </EventEmbeddedContainer>
      ),
    },
  ];

  return (
    <ContentLayout>
      <BasePane>
        <PrimaryContentPane>
          <EventFilterContainer>
            <EventFilters />
            <Tabs
              defaultActiveKey="summary"
              tabBarExtraContent={<RecordCountBadge count={totalRecords} />}
              style={styles.tabContainer}
              items={tabItems}
            />
          </EventFilterContainer>
        </PrimaryContentPane>
      </BasePane>
    </ContentLayout>
  );
};

export default EventsPage;
