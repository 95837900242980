import React, { ReactNode, useEffect, useState } from 'react';

import {
  DeviceMapping,
  FilterOptions,
  ZoneMappingInput,
} from '@totem/components/administration/zoneMappings/types';
import ZoneDataEditModal from '@totem/components/administration/zoneMappings/zoneDataEditModal';
import ZoneMappingContext from '@totem/components/administration/zoneMappings/ZoneMappingContext';
import { OrganizationHierarchy } from '@totem/types/organization';
import { getToken } from '@totem/utilities/accountUtilities';
import {
  NOZOMI_ENDPOINT,
  ORGANIZATION_HIERARCHY_ENDPOINT,
} from '@totem/utilities/endpoints';
import { omitNilOrEmpty } from '@totem/utilities/objectUtilities';

type Props = {
  children?: ReactNode;
};

const ZoneMappingContainer = ({ children }: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [refreshData, setRefreshData] = useState<boolean>(true);
  const [zoneMappingData, setZoneMappingData] = useState<DeviceMapping[]>([]);
  const [selectedAction, setSelectedAction] = useState<string>('');
  const [selectedMapping, setSelectedMapping] = useState<DeviceMapping>(null);
  const [filteredData, setFilteredData] = useState<DeviceMapping[]>([]);
  const [filterOptions, setFilterOptions] = useState<FilterOptions>({
    sensor: [],
    zone: [],
  });
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [orgHierarchy, setOrgHierarchy] = useState<OrganizationHierarchy>(null);
  const [input, updateInput] = useState<ZoneMappingInput>({
    page: 1,
    pageSize: 10,
    sortField: 'match.sensor',
    sortDirection: '1',
    sensor: [],
    zone: [],
  });

  const setInput = (updated: Partial<ZoneMappingInput>) => {
    updateInput(omitNilOrEmpty({ ...input, ...updated }));
    setRefreshData(true);
  };

  const filterData = (data: DeviceMapping[]) => {
    let newFilteredData = data.filter(() => true);

    if (typeof input !== 'undefined' && input !== null) {
      if (
        typeof input.sensor !== 'undefined' &&
        input.sensor !== null &&
        input.sensor.length > 0
      ) {
        newFilteredData = data.filter(rec =>
          input.sensor.includes(rec.match.sensor),
        );
      }
      if (
        typeof input.zone !== 'undefined' &&
        input.zone !== null &&
        input.zone.length > 0
      ) {
        newFilteredData = data.filter(rec =>
          input.zone.includes(rec.match.zone),
        );
      }
    }
    return newFilteredData;
  };

  useEffect(() => {
    const newFilteredData = filterData(zoneMappingData);
    setFilteredData(newFilteredData);
    setTotalRecords(newFilteredData.length);
  }, [zoneMappingData, input]);

  useEffect(() => {
    fetch(`${ORGANIZATION_HIERARCHY_ENDPOINT}`, {
      headers: new Headers({
        Authorization: `Bearer ${getToken()}`,
      }),
    })
      .then(res => res.json())
      .then(result => {
        setOrgHierarchy(result.hierarchy);
      });
  }, []);

  useEffect(() => {
    if (refreshData) {
      setRefreshData(false);

      setIsLoading(true);

      fetch(`${NOZOMI_ENDPOINT}/zone_mappings`, {
        method: 'GET',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
      })
        .then(res => res.json())
        .then((result: DeviceMapping[]) => {
          if (result !== null) {
            setZoneMappingData(result);

            const newFilterOptions = {
              sensor: [],
              zone: [],
            };
            for (let idx = 0; idx < result.length; idx++) {
              if (
                newFilterOptions.sensor.findIndex(
                  elem => elem === result[idx].match.sensor,
                ) < 0
              ) {
                newFilterOptions.sensor.push(result[idx].match.sensor);
              }
              if (
                newFilterOptions.zone.findIndex(
                  elem => elem === result[idx].match.zone,
                ) < 0
              ) {
                newFilterOptions.zone.push(result[idx].match.zone);
              }
            }

            setFilterOptions(newFilterOptions);
          }
        })
        .then(() => {
          setIsLoading(false);
        });
    }
  }, [refreshData]);

  const handleAction = (action: string, zoneMapping: DeviceMapping) => {
    switch (action) {
      default:
        setSelectedAction(action);
        setSelectedMapping(zoneMapping);
    }
  };

  const handleEditMappingComplete = (refresh: boolean) => {
    setSelectedAction('');
    setSelectedMapping(null);
    if (refresh) {
      setRefreshData(refresh);
    }
  };

  return (
    <ZoneMappingContext.Provider
      value={{
        loading: isLoading,
        data: filteredData,
        filterOptions,
        organizationHierarchy: orgHierarchy,
        totalRecords,
        onAction: handleAction,
        input,
        setInput,
      }}
    >
      <div>{children}</div>
      {selectedAction === 'showEditMapping' && selectedMapping !== null && (
        <ZoneDataEditModal
          visible={
            selectedAction === 'showEditMapping' && selectedMapping !== null
          }
          mapping={selectedMapping}
          onActionComplete={handleEditMappingComplete}
          organizationHierarchy={orgHierarchy}
        />
      )}
    </ZoneMappingContext.Provider>
  );
};

export default ZoneMappingContainer;
