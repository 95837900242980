import React, { useCallback, useContext, useState } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
// eslint-disable-next-line no-duplicate-imports,prettier/prettier
import { Button, Input, Tabs, TabsProps } from 'antd';
import * as R from 'ramda';
import {
  DelimitedNumericArrayParam,
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params';

import BasePane from '@totem/components/BasePane';
import CardBody from '@totem/components/CardBody';
import CardHeader from '@totem/components/CardHeader';
import PrimaryContentPane from '@totem/components/PrimaryContentPane';
import TabTitle from '@totem/components/TabTitle';
import IBUsersTable from '@totem/components/users/IBUsersTable';
import InactiveUsersTable from '@totem/components/users/InactiveUsersTable';
import VendorsTable from '@totem/components/users/VendorsTable';
import { CREATE_USER, GET_USERS, INVITE_USERS } from '@totem/graph/user';
import { useConnection } from '@totem/hooks/useConnection';
import { useErrorNotification } from '@totem/hooks/useErrorNotification';
import colors from '@totem/styles/colors';
import {
  AddEditUser,
  UsersConnection,
  UsersInviteType,
} from '@totem/types/user';
import authUtilities, {
  minimumRequiredRole,
  ROLE_SETS,
} from '@totem/utilities/authUtilities';
import configuration from '@totem/utilities/configuration';
import { debounce } from '@totem/utilities/debounce';
import { userOrganizationIsVendorOrg } from '@totem/utilities/userUtilities';

import InviteModal from '../common/inviteModal/InviteModal';
import ContentLayout from '../ContentLayout';
import UserProfileContext from '../UserProfileContext';

import UserAddModal from './UserAddModal';
import UsersContext from './UsersContext';
import UsersExport from './UsersExport';
import UsersTable from './UsersTable';

import './users.css';

const TABLE_LIMIT = 100;
const DEBOUNCE_TIME = 500;

const styles = {
  input: {
    width: '26rem',
    margin: '2rem 0',
  },
  inputIcon: {
    color: colors.opacity.black0_2,
    fontSize: '15px',
    height: '15px',
    width: '15px',
  },
};

const Users = () => {
  const { userProfile } = useContext(UserProfileContext);

  const [input, setInput] = useQueryParams({
    statuses: DelimitedNumericArrayParam,
    accesses: DelimitedNumericArrayParam,
    limit: withDefault(NumberParam, TABLE_LIMIT),
    offset: withDefault(NumberParam, 0),
    emailSearch: StringParam,
  });

  const [open, setOpen] = useState<boolean>(false);

  const { data, loading } = useQuery<{
    users: UsersConnection;
  }>(GET_USERS, {
    variables: { input },
  });

  const isIB = userProfile.email
    .toLowerCase()
    .endsWith('@intelligentbuildings.com');

  const { users, totalCount } = useConnection<UsersConnection>({
    data,
    accessor: R.path(['users']),
    initialData: { totalCount: 0, users: [] },
  });

  const [createUser, { loading: loadingCreate, error: createUserError }] =
    useMutation(CREATE_USER);

  useErrorNotification(createUserError, 'Error Creating User');

  const [inviteUsers, { loading: loadingInvite, error: inviteUsersError }] =
    useMutation(INVITE_USERS);

  useErrorNotification(inviteUsersError, 'Error Inviting Users');

  const handleSearch = useCallback(
    debounce(
      (emailSearch: string) => setInput({ ...input, emailSearch }, 'replace'),
      DEBOUNCE_TIME,
    ),
    [],
  );

  const handleAddEditUser = async (user: AddEditUser) => {
    await createUser({ variables: { input: user } });
    setInput({ limit: TABLE_LIMIT, offset: 0 }, 'replace');
    setOpen(false);
  };

  const handleInviteUsers = async (emails: string[]) => {
    await inviteUsers({
      variables: {
        input: { type: UsersInviteType.VENDOR_ORGANIZATION, emails },
      },
    });
    setOpen(false);
  };

  const userCreationEnabled = authUtilities.minimumRequiredRole(
    authUtilities.ROLE_SETS.ORGANIZATION_ADMINISTRATOR,
  );

  const { emailSearch } = input;

  const getTabItems = () => {
    const tabItems: TabsProps['items'] = [
      {
        key: 'Users',
        label: <TabTitle>Users</TabTitle>,
        children: <UsersTable />,
      },
      {
        key: 'Vendors',
        label: <TabTitle>Vendors</TabTitle>,
        children: <VendorsTable />,
      },
    ];

    if (isIB) {
      tabItems.push({
        key: 'IBUsers',
        label: <TabTitle>IB Users</TabTitle>,
        children: <IBUsersTable />,
      });
    }

    tabItems.push({
      key: 'Disabled',
      label: <TabTitle>Disabled Users</TabTitle>,
      children: <InactiveUsersTable />,
    });

    return tabItems;
  };

  return (
    <ContentLayout>
      <UsersContext.Provider
        value={{
          totalCount,
          users,
          loading,
          input,
          setInput,
        }}
      >
        <BasePane>
          <PrimaryContentPane>
            <CardHeader>
              <div>User Management</div>
              <Button
                onClick={() => setOpen(true)}
                disabled={!userCreationEnabled || loading}
                type="primary"
              >
                Add User
              </Button>
            </CardHeader>
            <CardBody>
              <div styleName="export-button-container">
                <Input
                  onChange={(event) => handleSearch(event.target.value)}
                  size="large"
                  placeholder="Search Email"
                  prefix={<SearchOutlined style={styles.inputIcon} />}
                  style={styles.input}
                  defaultValue={emailSearch}
                />
                {minimumRequiredRole(ROLE_SETS.SYSTEMS_ADMINISTRATOR) && (
                  <UsersExport input={input} />
                )}
              </div>
              <Tabs defaultActiveKey="Users" items={getTabItems()} />
            </CardBody>
          </PrimaryContentPane>
        </BasePane>
        {configuration.flags.vendorOrgsEnabled &&
        userOrganizationIsVendorOrg(userProfile) ? (
          <InviteModal
            visible={open}
            onCancel={() => setOpen(false)}
            loading={loading || loadingInvite}
            onConfirm={handleInviteUsers}
            title="Invite People"
            message="Invite new team members to join your Intelligent Buildings account."
          />
        ) : (
          <UserAddModal
            visible={open}
            onClose={() => setOpen(false)}
            loading={loading || loadingCreate}
            onSubmit={handleAddEditUser}
          />
        )}
      </UsersContext.Provider>
    </ContentLayout>
  );
};

export default Users;
