import React from 'react';
import { Flex } from 'antd';

import LevelOfCompliance from '@totem/components/surveyV2/analysis/LevelOfCompliance';
import MostCriticalPolicies from '@totem/components/surveyV2/analysis/MostCriticalPolicies';
import PolicyResponses from '@totem/components/surveyV2/analysis/PolicyResponses';

import '../survey.css';

const OverviewSection = () => {
  return (
    <div styleName="overview-container">
      <Flex justify={'flex-start'} align={'flex-start'}>
        <PolicyResponses />
        <LevelOfCompliance />
        <MostCriticalPolicies />
      </Flex>
    </div>
  );
};

export default OverviewSection;
