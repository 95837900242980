import { createContext } from 'react';

import {
  FilterOptions, Product,
  ProductReport,
  ProductReportInput,
} from '@totem/components/endoflife/types';

interface Context {
  input: ProductReportInput;
  setInput: (input: ProductReportInput) => void;
  loading: boolean;
  reportData: ProductReport;
  filterOptions: FilterOptions;
  totalRecords: number;
  onAction: (action: string, product: Product) => void;
}

export default createContext<Context>({
  input: {},
  setInput: () => {},
  loading: false,
  reportData: null,
  filterOptions: {
    productType: [],
  },
  totalRecords: 0,
  onAction: () => {},
});
