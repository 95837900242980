import React from 'react';

import ComponentHeader from '@totem/components/ComponentHeader';
import BackupSummaryCharts from '@totem/components/deviceBackups/backupSummary/backupSummaryCharts';
import BackupSummaryContainer from '@totem/components/deviceBackups/backupSummary/backupSummaryContainer';
import PageSectionBody from '@totem/components/PageSectionBody';

const SummaryContainer = () => {
  return (
    <BackupSummaryContainer>
      <ComponentHeader title={'Continuity'} />
      <PageSectionBody>
        <BackupSummaryCharts />
      </PageSectionBody>
    </BackupSummaryContainer>
  );
};

export default SummaryContainer;
