import React, { useContext } from 'react';
import { Card, Divider, Flex } from 'antd';

import CriticalFindingsTooltip from '@totem/components/common/findings/CriticalFindingsTooltip';
import SurveyInstanceContext from '@totem/components/surveyV2/instances/SurveyInstancesContext';
import { isFiltered } from '@totem/components/surveyV2/instances/utilities';

import '../survey.css';

const styles = {
  divider: {
    height: '6rem',
  },
  tooltip: {
    marginLeft: '0.5rem',
  },
};
const OverviewPane = () => {
  const { statistics, totalRecords, input } = useContext(SurveyInstanceContext);
  const filtered = isFiltered(input);

  return (
    <Card
      title={
        <div styleName="overview-header">
          <div>{`Overview ${filtered ? '(filtered)' : ''}`}</div>
          <div styleName="overview-total-container">
            <span styleName="overview-total">{totalRecords}</span>
            <span styleName="overview-total-title">total</span>
          </div>
        </div>
      }
      bordered={false}
    >
      {typeof input !== 'undefined' &&
        input !== null &&
        typeof statistics !== 'undefined' &&
        statistics !== null && (
          <div styleName="overview-card-body">
            <div>
              <div styleName="critical-findings-container">
                <Flex justify={'center'} align={'flex-start'}>
                  <div styleName="overview-title">CFs</div>
                  <CriticalFindingsTooltip style={styles.tooltip} />
                </Flex>
              </div>
              <div styleName="stat">{statistics.criticalFindings}</div>
            </div>
            <Divider type="vertical" style={styles.divider} />
            <div>
              <div styleName="overview-title">In-Progress</div>
              <div styleName="stat">{statistics.inProgress}</div>
            </div>
            <Divider type="vertical" style={styles.divider} />
            <div>
              <div styleName="overview-title">Completed</div>
              <div styleName="stat">{statistics.completed}</div>
            </div>
            <Divider type="vertical" style={styles.divider} />
            <div>
              <div styleName="overview-title">Not Started</div>
              <div styleName="stat">{statistics.notStarted}</div>
            </div>
            <Divider type="vertical" style={styles.divider} />
            <div>
              <div styleName="overview-title">Overdue</div>
              <div styleName="stat">{statistics.overdue}</div>
            </div>
          </div>
        )}
    </Card>
  );
};

export default OverviewPane;
