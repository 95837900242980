import React, { useContext, useState } from 'react';
import { Card, Input } from 'antd';

import FormFieldLabel from '@totem/components/FormFieldLabel';
import SurveyTemplateContext from '@totem/components/surveyV2/templateDetails/SurveyTemplateContext';

interface localStateType {
  name: string;
  title: string;
  description: string;
  helpText: string;
}

const SurveyTemplateDetailsPane = () => {
  const { data } = useContext(SurveyTemplateContext);
  const [localState, setLocalState] = useState<localStateType>(
    typeof data !== 'undefined' && data !== null
      ? {
          name: data.name,
          title: data.title,
          description: data.description,
          helpText: data.helpText,
        }
      : {
          name: '',
          title: '',
          description: '',
          helpText: '',
        },
  );

  return (
    <Card>
      <FormFieldLabel>Name</FormFieldLabel>
      <Input
        placeholder="Name"
        value={localState.name}
        onChange={({
          target: { value },
        }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
          setLocalState({ ...localState, name: value })
        }
      />

      <FormFieldLabel>Title</FormFieldLabel>
      <Input
        placeholder="Title"
        value={localState.title}
        onChange={({
          target: { value },
        }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
          setLocalState({ ...localState, title: value })
        }
      />

      <FormFieldLabel>Description</FormFieldLabel>
      <Input
        placeholder="Description"
        value={localState.description}
        onChange={({
          target: { value },
        }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
          setLocalState({ ...localState, description: value })
        }
      />

      <FormFieldLabel>Help Text</FormFieldLabel>
      <Input
        placeholder="Help Text"
        value={localState.helpText}
        onChange={({
          target: { value },
        }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
          setLocalState({ ...localState, helpText: value })
        }
      />
    </Card>
  );
};

export default SurveyTemplateDetailsPane;
