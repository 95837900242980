import React from 'react';

import BasePane from '@totem/components/BasePane';
import ComponentHeader from '@totem/components/ComponentHeader';
import ContentLayout from '@totem/components/ContentLayout';
import DateRangeInput from '@totem/components/energy/DateRangeInput';
import EnergyChartsContainer from '@totem/components/energy/EnergyChartsContainer';
import EnergyContainer from '@totem/components/energy/EnergyContainer';
import PortfolioKPIs from '@totem/components/energy/PortfolioKPIs';
import PrimaryContentPane from '@totem/components/PrimaryContentPane';

const EnergyPage = () => {
  return (
    <ContentLayout>
      <BasePane>
        <PrimaryContentPane>
          <EnergyContainer
            dateRangeType="Last 7 Days"
            selfRegisterApis={false}
            apis={{
              portfolioKpi: true,
              portfolioCarbonKpi: true,
              portfolioChart: true,
              portfolioEndUses: true,
              buildings: true,
            }}
          >
            <ComponentHeader
              title="Energy Overview"
              options={<DateRangeInput />}
            />
            <PortfolioKPIs />
            <br />
            <br />
            <EnergyChartsContainer />
          </EnergyContainer>
        </PrimaryContentPane>
      </BasePane>
    </ContentLayout>
  );
};

export default EnergyPage;
