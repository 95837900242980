import React, { useCallback, useContext, useState } from 'react';
import { Button, Form, Modal, notification } from 'antd';

import BuildingAutoComplete from '@totem/components/common/autoCompletes/BuildingAutoComplete';
import { EMPTY_ID } from '@totem/components/common/reference/ReferenceSelector';
import ModalFooter from '@totem/components/ModalFooter';
import ModalTitle from '@totem/components/ModalTitle';
import BuildingMapperContext from '@totem/components/settings/sections/buildingMapping/BuildingMapperContext';
import {
  LookupReference,
  SensorBuildingUpdateRequest,
  SensorQueryResultItem,
} from '@totem/components/settings/sections/buildingMapping/types';
import { getToken } from '@totem/utilities/accountUtilities';
import { NOZOMI_ENDPOINT } from '@totem/utilities/endpoints';
const FormItem = Form.Item;

const styles = {
  form: {
    width: '100%',
  },
  formItem: {
    paddingBottom: '0',
    marginBottom: '1rem',
  },
  indent: {
    marginLeft: '20px',
  },
};

interface Building {
  id: string;
  name: string;
  region: {
    id: string;
    name: string;
  };
}

export interface Props {
  visible: boolean;
  sensor: SensorQueryResultItem;
  defaultBuilding: LookupReference;
}

const BuildingSelectModal = ({ visible, defaultBuilding, sensor }: Props) => {
  const { onActionComplete } = useContext(BuildingMapperContext);
  const [isSending, setIsSending] = useState(false);
  const [selectedBuilding, setSelectedBuilding] = useState<LookupReference>(
    typeof defaultBuilding !== 'undefined' && defaultBuilding !== null
      ? defaultBuilding
      : { id: EMPTY_ID, name: '' },
  );
  const [selectedRegion, setSelectedRegion] = useState<LookupReference>({
    id: EMPTY_ID,
    name: '',
  });

  const sendSensorBuildingUpdate = useCallback(
    async (
      sensorData: SensorQueryResultItem,
      region: LookupReference,
      building: LookupReference,
    ) => {
      if (isSending) {
        return;
      }

      setIsSending(true);

      const payload: SensorBuildingUpdateRequest = {
        apiConfigId: sensorData.id,
        tenantId: sensorData.tenantId,
        siteId: sensorData.siteId,
        sensorId: sensorData.sensor.id,
        regionId: region.id,
        buildingId: building.id,
      };

      fetch(`${NOZOMI_ENDPOINT}/sensors`, {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getToken()}`,
        }),
        body: JSON.stringify(payload),
      })
        .then((res) => {
          if (res.status < 400) {
            onActionComplete(true);
          } else {
            notification.error({
              message: 'Error building mapping!',
              description: 'An error occurred while updating building mapping.',
              duration: 0,
            });
            onActionComplete(false);
          }
        })
        .then(() => {
          setIsSending(false);
        });
    },
    [isSending],
  );

  const handleSubmit = () => {
    if (selectedBuilding.id === EMPTY_ID && selectedBuilding.name !== '') {
      // ERROR - Building Not Selected
      return;
    }
    sendSensorBuildingUpdate(sensor, selectedRegion, selectedBuilding).then(
      () => onActionComplete(true),
    );
  };

  const handleBuildingChange = (building: string) => {
    const newBuilding: LookupReference = {
      id: EMPTY_ID,
      name: building,
    };

    setSelectedBuilding(newBuilding);
  };

  const handleBuildingSelect = (buildingData: Building) => {
    const newBuilding: LookupReference = {
      id: buildingData.id,
      name: buildingData.name,
    };

    const newRegion: LookupReference = {
      id:
        typeof buildingData.region !== 'undefined' &&
        buildingData.region !== null
          ? buildingData.region.id
          : EMPTY_ID,
      name:
        typeof buildingData.region !== 'undefined' &&
        buildingData.region !== null
          ? buildingData.region.name
          : '',
    };

    setSelectedBuilding(newBuilding);
    setSelectedRegion(newRegion);
  };

  return (
    <Modal
      open={visible}
      onCancel={() => onActionComplete(false)}
      title={<ModalTitle>Map to Building</ModalTitle>}
      confirmLoading={false}
      width={'40%'}
      footer={
        <ModalFooter>
          <Button onClick={() => onActionComplete(false)}>Close</Button>
          <Button onClick={handleSubmit} type="primary">
            Submit
          </Button>
        </ModalFooter>
      }
    >
      <div>
        <b>Site: {sensor.siteName}</b>
      </div>
      <div>
        <b>Host: {sensor.sensor.host}</b>
      </div>
      <br />
      <br />
      <Form layout="vertical" style={styles.form}>
        <FormItem
          label="Building"
          colon={false}
          style={styles.formItem}
          required
        >
          <BuildingAutoComplete
            value={
              typeof selectedBuilding !== 'undefined' &&
              selectedBuilding !== null &&
              selectedBuilding.name !== null
                ? selectedBuilding.name
                : ''
            }
            onChange={handleBuildingChange}
            onSelect={handleBuildingSelect}
          />
        </FormItem>
      </Form>
    </Modal>
  );
};

export default BuildingSelectModal;
