import React, { ReactNode, useEffect, useState } from 'react';

import {
  VulnerabilitySummary,
  VulnerabilitySummaryInput,
} from '@totem/components/software/vulnerabilitySummary/types';
import { buildParameters } from '@totem/components/software/vulnerabilitySummary/utilities';
import VulnerabilitySummaryContext from '@totem/components/software/vulnerabilitySummary/vulnerabilitySummaryContext';
import { getToken } from '@totem/utilities/accountUtilities';
import { DEVICES_ENDPOINT } from '@totem/utilities/endpoints';
import { omitNilOrEmpty } from '@totem/utilities/objectUtilities';

type Props = {
  regionId?: string[];
  buildingId?: string[];
  controlSystemId?: string[];
  children?: ReactNode;
};

const VulnerabilitySummaryContainer = ({
  regionId,
  buildingId,
  controlSystemId,
  children,
}: Props) => {
  const [input, updateInput] = useState<VulnerabilitySummaryInput>({
    regionId,
    buildingId,
    controlSystemId,
  });
  const [refreshData, setRefreshData] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [data, setData] = useState<VulnerabilitySummary>(null);
  const [action, setAction] = useState<string>('');

  const setInput = (updated: Partial<VulnerabilitySummaryInput>) => {
    updateInput(omitNilOrEmpty({ ...input, ...updated }));
    setRefreshData(true);
  };

  useEffect(() => {
    setRefreshData(true);
  }, [regionId, buildingId, controlSystemId]);

  useEffect(() => {
    if (refreshData) {
      setRefreshData(false);
      setIsLoading(true);

      const uri = `${DEVICES_ENDPOINT}/software/vulnerabilitySummary`;

      const payload = buildParameters(input);

      fetch(uri, {
        method: 'POST',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
        body: JSON.stringify(payload),
      })
        .then((res) => res.json())
        .then((result: VulnerabilitySummary) => {
          setData(result);
        })
        .then(() => {
          setIsLoading(false);
        });
    }
  }, [refreshData]);

  const handleAction = (newAction: string) => {
    switch (newAction) {
      case 'refresh':
        setRefreshData(true);
        setAction('');
        break;
      default:
        setAction(newAction);
    }
  };

  return (
    <VulnerabilitySummaryContext.Provider
      value={{
        input,
        setInput,
        loading: isLoading,
        data,
        action,
        onAction: handleAction,
      }}
    >
      <div>{children}</div>
    </VulnerabilitySummaryContext.Provider>
  );
};

export default VulnerabilitySummaryContainer;
