import React from 'react';
import { useQuery } from '@apollo/client';
import * as R from 'ramda';

import { GET_POLICY_AUDIT_COUNTS } from '@totem/graph/policyAudit';
import { useConnection } from '@totem/hooks/useConnection';
import { FindingCriticalityCounts } from '@totem/types/finding';
import { HealthStatusCounts } from '@totem/types/health';
import {
  CompletionCounts,
  PolicyAuditsConnection,
  PolicyAuditsConnectionInput,
} from '@totem/types/policyAudit';

import PolicyAuditCompletionCounts from './PolicyAuditCompletionCounts';
import PolicyAuditFindingCriticalityCounts from './PolicyAuditFindingCriticalityCounts';
import PolicyAuditHealthStatusCounts from './PolicyAuditHealthStatusCounts';

import './policyAudits.css';

interface Props {
  input: PolicyAuditsConnectionInput;
}

const initialHealthStatusCounts: HealthStatusCounts = {
  onTrack: 0,
  atRisk: 0,
  offTrack: 0,
  needed: 0,
};

const initialCompletionCounts: CompletionCounts = {
  complete: 0,
  inProgress: 0,
  notStarted: 0,
  overdue: 0,
};

const initialFindingCriticalityCounts: FindingCriticalityCounts = {
  critical: 0,
  high: 0,
  medium: 0,
  low: 0,
  compliant: 0,
  info: 0,
};

const PolicyAuditsOverview = ({ input }: Props) => {
  const { data } = useQuery(GET_POLICY_AUDIT_COUNTS, {
    fetchPolicy: 'no-cache',
    variables: { input },
  });

  const {
    healthStatusCounts,
    completionCounts,
    findingCriticalityCounts,
  } = useConnection<PolicyAuditsConnection>({
    data,
    accessor: R.path(['policyAudits']),
    initialData: {
      totalCount: 0,
      policyAudits: [],
      healthStatusCounts: initialHealthStatusCounts,
      completionCounts: initialCompletionCounts,
      findingCriticalityCounts: initialFindingCriticalityCounts,
    },
  });

  return (
    <div styleName="overview-container">
      <div styleName="overview-section">
        <PolicyAuditHealthStatusCounts
          healthStatusCounts={healthStatusCounts}
        />
      </div>
      <div styleName="overview-section">
        <PolicyAuditCompletionCounts completionCounts={completionCounts} />
      </div>
      <div styleName="overview-section">
        <PolicyAuditFindingCriticalityCounts
          findingCriticalityCounts={findingCriticalityCounts}
        />
      </div>
    </div>
  );
};

export default PolicyAuditsOverview;
