/* eslint-disable no-shadow */
import * as R from 'ramda';

const TYPENAME = '__typename';

export const getValuesWithoutTypename = (obj: Object): any[] => {
  return Object.keys(obj)
    .filter(key => key !== TYPENAME)
    .map(key => obj[key]);
};

// strips all properties from input object that are equal to
// the corresponding property value in the value object
// ignoring properties listed in ignoredProperties
// this is useful for parsing mutation inputs to ensure
// unchanged properties are not sent to the API
export const removeEqualProperties = (
  input: Object,
  value: Object,
  ignoredProperties?: string[],
): Object => {
  return R.pipe(
    // @ts-ignore - this is wrapped in order to be typed (ts-ignore won't work otherwise)
    (obj: Object): { [key: string]: any } => R.toPairs(obj),
    R.reject(([key, val]) => {
      return !ignoredProperties.includes(key) && R.equals(value[key], val);
    }),
    R.fromPairs,
    // @ts-ignore
  )(input);
};
