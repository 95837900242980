import React, { useCallback, useContext, useState } from 'react';
import { Button, Form, Input, Modal } from 'antd';

import ModalFooter from '@totem/components/ModalFooter';
import ModalTitle from '@totem/components/ModalTitle';
import TenantsContext from '@totem/components/tenants/tenantsPage/TenantsContext';
import { TenantBuilding } from '@totem/components/tenants/types';
import { getToken } from '@totem/utilities/accountUtilities';
import { TENANTS_ENDPOINT } from '@totem/utilities/endpoints';
const FormItem = Form.Item;

const styles = {
  form: {
    width: '100%',
  },
  formItem: {
    paddingBottom: '0',
    marginBottom: '1rem',
  },
};

type Props = {
  open: boolean;
  onClose: () => void;
  tenantId: string;
  building: TenantBuilding;
};

const TenantBuildingLocationAddDialog = ({
  open,
  onClose,
  tenantId,
  building,
}: Props) => {
  const [isSending, setIsSending] = useState<boolean>(false);
  const { onAction } = useContext(TenantsContext);
  const [selectedLocation, setSelectedLocation] = useState<string>('');

  const sendTenantBuildingLocationAdd = useCallback(
    async (
      paramTenantId: string,
      paramBuilding: TenantBuilding,
      paramLocation: string,
    ) => {
      if (isSending) {
        return;
      }
      setIsSending(true);

      const payload = {
        location: paramLocation,
      };

      fetch(
        `${TENANTS_ENDPOINT}/${paramTenantId}/buildings/${paramBuilding.id}/locations`,
        {
          method: 'PUT',
          headers: new Headers({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
          }),
          body: JSON.stringify(payload),
        },
      ).then(() => {
        onClose();
        setIsSending(false);
        onAction('refresh', null);
      });
    },
    [isSending],
  );

  const handleSubmit = () => {
    sendTenantBuildingLocationAdd(tenantId, building, selectedLocation);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={<ModalTitle>Add Location to {building.name}</ModalTitle>}
      width={'40%'}
      footer={
        <ModalFooter>
          <Button onClick={onClose}>Close</Button>
          <Button
            type="primary"
            onClick={handleSubmit}
            disabled={selectedLocation === ''}
          >
            Save
          </Button>
        </ModalFooter>
      }
    >
      <Form layout="vertical" style={styles.form}>
        <FormItem label="Location" colon={false} style={styles.formItem}>
          <Input
            placeholder="Location"
            onChange={(evt) => setSelectedLocation(evt.target.value)}
            value={selectedLocation}
          />
        </FormItem>
      </Form>
    </Modal>
  );
};

export default TenantBuildingLocationAddDialog;
