import React, { useContext } from 'react';
import { Popover } from 'antd';
import { TablePaginationConfig } from 'antd/es/table';
import { ColumnProps } from 'antd/lib/table';
import { SorterResult } from 'antd/lib/table/interface';

import Table from '@totem/components/common/table/Table';
import { sortStringAscending } from '@totem/components/events/eventsContainer/utilities';
import EventSeverityIndicator from '@totem/components/events/EventSeverityIndicator';
import RemoteAccessSessionContext from '@totem/components/remoteAccess/sessions/RemoteAccessSessionContext';
import { Event } from '@totem/types/events';

const SessionEventsTable = () => {
  const { input, setInput, eventData } = useContext(RemoteAccessSessionContext);

  const formatDateTIme = (dateIn: string) => {
    if (dateIn !== null) {
      if (!isNaN(Date.parse(dateIn))) {
        const dateVal = new Date(dateIn);
        return (
          <>
            {dateVal.toLocaleDateString()}
            <br />
            {dateVal.toLocaleTimeString()}
          </>
        );
      }
    }
    return <span>-</span>;
  };

  const getDeviceName = (event: Event) => {
    let deviceName = event.device.displayName;

    if (
      typeof event.controlSystem !== 'undefined' &&
      event.controlSystem !== null &&
      event.controlSystem.name !== null &&
      event.controlSystem.name !== '' &&
      event.controlSystem.name !== deviceName
    ) {
      deviceName = `${deviceName} (${event.controlSystem.name})`;
    }

    return deviceName;
  };

  const columns: ColumnProps<Event>[] = [
    {
      title: 'Severity',
      dataIndex: 'severity',
      key: 'severity',
      className: 'severity-column',
      width: '95px',
      showSorterTooltip: true,
      sortDirections: ['ascend', 'descend'],
      render: (_, event: Event) => (
        <div style={{ cursor: 'pointer' }}>
          <EventSeverityIndicator severity={event.severity} />
        </div>
      ),
    },
    {
      title: 'Source',
      dataIndex: 'sourceType',
      key: 'sourceType',
      showSorterTooltip: true,
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Type',
      dataIndex: 'eventType',
      key: 'eventType',
      showSorterTooltip: true,
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Device',
      dataIndex: 'deviceId',
      key: 'deviceId',
      render: (_, event: Event) => (
        <Popover
          content={
            <>
              {event.region !== null && event.region.name !== '' && (
                <>
                  <b>Region:</b>
                  <br />
                  {event.region.name}
                  <br />
                </>
              )}
              {event.building !== null && event.building.name !== '' && (
                <>
                  <b>Building:</b>
                  <br />
                  {event.building.name}
                  <br />
                </>
              )}
              {event.controlSystem !== null &&
                event.controlSystem.name !== '' && (
                  <>
                    <b>Control System:</b>
                    <br />
                    {event.controlSystem.name}
                    <br />
                  </>
                )}
              {(event.region === null || event.region.name === '') &&
                (event.building === null || event.building.name === '') &&
                (event.controlSystem === null ||
                  event.controlSystem.name === '') && <b>Unassigned</b>}
            </>
          }
        >
          <div>{getDeviceName(event)}</div>
        </Popover>
      ),
      showSorterTooltip: true,
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        sortStringAscending(compA.device.displayName, compB.device.displayName),
    },
    {
      title: 'Building',
      dataIndex: 'buildingId',
      key: 'buildingId',
      render: (_, event: Event) => (
        <div>{event.building !== null ? event.building.name : '-'}</div>
      ),
      showSorterTooltip: true,
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        sortStringAscending(compA.building.name, compB.building.name),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Event Time',
      dataIndex: 'eventTime',
      key: 'eventTime',
      render: (date) => formatDateTIme(date),
      sorter: (compA, compB) =>
        sortStringAscending(compA.eventTime, compB.eventTime),
      showSorterTooltip: true,
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      showSorterTooltip: true,
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) => sortStringAscending(compA.status, compB.status),
      render: (_, event: Event) => (
        <span>
          {event.status.charAt(0).toUpperCase() +
            event.status.toLowerCase().slice(1)}
        </span>
      ),
    },
  ];

  const getEvents = () => {
    if (eventData !== null) {
      return eventData;
    }
    return [];
  };

  const handleTableChange = (
    updatedPagination: TablePaginationConfig,
    filters: SorterResult<Event>,
    sorter,
  ) => {
    const { ...params } = filters;

    let sortDir: string = sorter.order === 'descend' ? '-1' : '1';
    if (typeof sorter.order === 'undefined' || sorter.order === null) {
      if (
        typeof input.sessionSortDirection !== 'undefined' &&
        input.sessionSortDirection !== null
      ) {
        sortDir = input.sessionSortDirection;
      }
    }

    let sortField = input.sessionSortField
      ? input.sessionSortField
      : 'lastOccurrence';
    if (
      typeof sorter.field !== 'undefined' &&
      typeof sorter.order !== 'undefined'
    ) {
      sortField = sorter.field;
    }

    // @ts-ignore
    setInput({
      ...input,
      ...params,
      sessionPageSize: updatedPagination.pageSize,
      sessionPage: updatedPagination.current,
      sessionSortField: sortField,
      sessionSortDirection: sortDir,
    });
  };

  return (
    <Table
      showSorterTooltip
      columns={columns}
      dataSource={getEvents()}
      loading={false}
      onChange={handleTableChange}
      pagination={{
        current: input.sessionPage,
        pageSize: input.sessionPageSize,
        total: eventData !== null ? eventData.length : 0,
        showSizeChanger: true,
      }}
      rowKey="id"
    />
  );
};

export default SessionEventsTable;
