import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import dayjs from 'dayjs';

import EventDetailsModal from '@totem/components/events/EventDetailsModal';
import EventFilterContext from '@totem/components/events/eventFilters/EventFilterContext';
import { EventReportInput } from '@totem/components/events/eventFilters/types';
import { EventStatusUpdateRequest } from '@totem/components/events/Events';
import EventContext from '@totem/components/events/eventsContainer/eventContainerContext';
import {
  addEventFilters,
  buildEventFilters,
} from '@totem/components/events/eventsContainer/utilities';
import TicketAssociateModal from '@totem/components/events/ticketLinkModal/TicketAssociateModal';
import { Params } from '@totem/types/common';
import {
  EventReportParameters,
  EventTicketAssociationPayload,
} from '@totem/types/event';
import {
  Event,
  EventAction,
  EventFilters,
  EventQueryResults,
} from '@totem/types/events';
import { TicketQueryResult } from '@totem/types/ticketing';
import { getToken } from '@totem/utilities/accountUtilities';
import { EVENTS_ENDPOINT } from '@totem/utilities/endpoints';
import { omitNilOrEmpty } from '@totem/utilities/objectUtilities';

import '../events.css';

type Props = {
  refresh?: boolean;
  onRecordTotalChanged?: (total: number) => void;
  onDataRefreshRequested?: () => void;
  actions?: EventAction[];
  children?: ReactNode;
  eventEndPoint?: string;
  defaultFilters?: EventFilters;
  staticFilters?: EventFilters;
};

const EventEmbeddedContainer = ({
  refresh,
  onRecordTotalChanged,
  onDataRefreshRequested,
  actions,
  children,
  eventEndPoint,
}: Props) => {
  const { input, staticFilters } = useContext(EventFilterContext);
  const [reportInput, updateReportInput] = useState<EventReportInput>({
    pageSize: 10,
    page: 1,
    sortField: 'lastOccurrence',
    sortDirection: '-1',
  });

  const [refreshData, setRefreshData] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [isSending, setIsSending] = useState(false);
  const [eventData, setEventData] = useState<EventQueryResults>(null);
  const [selectedEvent, setSelectedEvent] = useState<Event>(null);
  const [showEventDetails, setShowEventDetails] = useState<boolean>(false);
  const [showTicketAssociation, setShowTicketAssociation] =
    useState<boolean>(false);
  const setReportInput = (updated: Partial<EventReportInput>) => {
    updateReportInput(omitNilOrEmpty({ ...input, ...updated }));
    setRefreshData(true);
  };

  useEffect(() => {
    if (
      typeof onRecordTotalChanged !== 'undefined' &&
      onRecordTotalChanged !== null
    ) {
      onRecordTotalChanged(totalRecords);
    }
  }, [onRecordTotalChanged, totalRecords]);

  useEffect(() => {
    if (
      refresh &&
      typeof onDataRefreshRequested !== 'undefined' &&
      onDataRefreshRequested !== null
    ) {
      setRefreshData(true);
      onDataRefreshRequested();
    }
  }, [refresh, onDataRefreshRequested]);

  useEffect(() => {
    setRefreshData(true);
  }, [eventEndPoint, input, reportInput]);

  const buildParameters = () => {
    const params: Params = {
      paging: {
        page: reportInput.page,
        pageSize: reportInput.pageSize,
      },
      sort: {
        field: reportInput.sortField,
        direction: +reportInput.sortDirection,
      },
      filters: buildEventFilters(input),
    };

    params.filters = addEventFilters(params.filters, staticFilters);

    return params;
  };

  useEffect(() => {
    if (refreshData) {
      setRefreshData(false);
      if (isSending) {
        return;
      }
      setIsLoading(true);
      const params: Params = buildParameters();
      const payload: EventReportParameters = {
        startTime: dayjs(input.startTime).toISOString(),
        endTime: dayjs(input.endTime).toISOString(),
        params,
      };

      const eventUrl =
        typeof eventEndPoint !== 'undefined' && eventEndPoint !== null
          ? eventEndPoint
          : EVENTS_ENDPOINT;

      fetch(`${eventUrl}`, {
        method: 'POST',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
        body: JSON.stringify(payload),
      })
        .then((res) => res.json())
        .then((result: EventQueryResults) => {
          setEventData(result);
          setTotalRecords(result.paging.totalRecords);
        })
        .then(() => {
          setIsLoading(false);
          setIsSending(false);
        });

      setIsSending(true);
      setRefreshData(false);
    }
  }, [refreshData, refresh]);

  const sendEventStatusUpdate = useCallback(
    async (events: Event[]) => {
      if (isSending) {
        return;
      }

      setIsSending(true);
      setIsLoading(true);

      const params: Params = buildParameters();
      const request: EventStatusUpdateRequest = {
        params,
        events,
      };

      fetch(`${EVENTS_ENDPOINT}/status`, {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getToken()}`,
        }),
        body: JSON.stringify(request),
      })
        .then((res) => res.json())
        .then((result: EventQueryResults) => {
          setEventData(result);
          setTotalRecords(result.paging.totalRecords);
        })
        .then(() => {
          setIsSending(false);
          setIsLoading(false);
        });
    },
    [isSending],
  );

  const sendTicketCreate = useCallback(
    async (event: Event) => {
      if (isSending) {
        return;
      }

      setIsSending(true);
      setIsLoading(true);

      const params: Params = buildParameters();

      fetch(`${EVENTS_ENDPOINT}/${event.id}/createTicket`, {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getToken()}`,
        }),
        body: JSON.stringify(params),
      })
        .then((res) => res.json())
        .then((result: EventQueryResults) => {
          setEventData(result);
          setTotalRecords(result.paging.totalRecords);
        })
        .then(() => {
          setIsSending(false);
          setIsLoading(false);
        });
    },
    [buildParameters, isSending],
  );

  const sendTicketAssociate = useCallback(
    async (eventId: string, ticketId: string, ticketNumber: string) => {
      if (isSending) {
        return;
      }

      const params: Params = buildParameters();
      const payload: EventTicketAssociationPayload = {
        ticketID: ticketId,
        ticketNumber,
        query: params,
      };

      setIsSending(true);
      setIsLoading(true);

      fetch(`${EVENTS_ENDPOINT}/${eventId}/associateTicket`, {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getToken()}`,
        }),
        body: JSON.stringify(payload),
      })
        .then((res) => res.json())
        .then((result: EventQueryResults) => {
          setEventData(result);
          setTotalRecords(result.paging.totalRecords);
        })
        .then(() => {
          setIsSending(false);
          setIsLoading(false);
        });
    },
    [isSending],
  );

  const handleAssociateTicket = (
    eventId: string,
    ticket: TicketQueryResult,
  ) => {
    sendTicketAssociate(eventId, ticket.ticket.id, ticket.ticket.number);
    setShowTicketAssociation(false);
  };

  const handleAction = (action: string, event: Event) => {
    let actionHandled = false;
    if (typeof actions !== 'undefined' && actions !== null) {
      for (let idx = 0; idx < actions.length; idx++) {
        if (actions[idx].name === action) {
          // eslint-disable-next-line max-depth
          if (actions[idx].replaceDefault === true) {
            actionHandled = true;
            actions[idx].action(action, event);
          }
        }
      }
    }
    if (!actionHandled) {
      // eslint-disable-next-line default-case
      switch (action) {
        case 'showEventDetails':
          setSelectedEvent(event);
          setShowEventDetails(true);
          break;
        case 'acknowledgeEvent':
          // eslint-disable-next-line no-case-declarations
          const eventAcknowledgeUpdate = {
            ...event,
            status: 'ACKNOWLEDGED',
          };
          sendEventStatusUpdate([eventAcknowledgeUpdate]);
          break;
        case 'clearEvent':
          // eslint-disable-next-line no-case-declarations
          const eventClearUpdate = {
            ...event,
            status: 'CLEARED',
          };
          sendEventStatusUpdate([eventClearUpdate]);
          break;
        case 'createTicket':
          sendTicketCreate(event);
          break;
        case 'showAssociateTicketDialog':
          setSelectedEvent(event);
          setShowTicketAssociation(true);
          break;
      }
    }
  };

  return (
    <EventContext.Provider
      value={{
        reportInput,
        setReportInput,
        loading: isLoading,
        eventData,
        totalRecords,
        onAction: handleAction,
      }}
    >
      <div>{children}</div>
      {showEventDetails && selectedEvent !== null && (
        <EventDetailsModal
          event={selectedEvent}
          visible={showEventDetails}
          onClose={() => setShowEventDetails(false)}
        />
      )}
      {showTicketAssociation && selectedEvent !== null && (
        <TicketAssociateModal
          event={selectedEvent}
          visible={showTicketAssociation}
          onAssociateTicket={handleAssociateTicket}
          onClose={() => setShowTicketAssociation(false)}
        />
      )}
    </EventContext.Provider>
  );
};

export default EventEmbeddedContainer;
