import React, { useCallback, useEffect, useState } from 'react';
import { Button, Form, Modal, Select, Switch } from 'antd';
const { Option } = Select;
import { DeviceMapping } from '@totem/components/administration/zoneMappings/types';
import { EMPTY_ID } from '@totem/components/common/reference/ReferenceSelector';
import { LookupReference } from '@totem/components/settings/sections/buildingMapping/types';
import { OrganizationHierarchy } from '@totem/types/organization';
import {
  getBuildingById,
  getBuildingNameById,
} from '@totem/utilities/organization';
const FormItem = Form.Item;

const styles = {
  form: {
    width: '100%',
  },
  formItem: {
    paddingBottom: '0',
    marginBottom: '1rem',
  },
  indent: {
    marginLeft: '20px',
  },
};

import BuildingAutoComplete from '@totem/components/common/autoCompletes/BuildingAutoComplete';
import ModalFooter from '@totem/components/ModalFooter';
import ModalTitle from '@totem/components/ModalTitle';
import { getToken } from '@totem/utilities/accountUtilities';
import { NOZOMI_ENDPOINT } from '@totem/utilities/endpoints';
import { CheckResponseShowError } from '@totem/utilities/responseUtilities';

interface Building {
  id: string;
  name: string;
  region: {
    id: string;
    name: string;
  };
}

type Props = {
  visible: boolean;
  mapping: DeviceMapping;
  organizationHierarchy: OrganizationHierarchy;
  onActionComplete: (refresh: boolean) => void;
};

const ZoneDataEditModal = ({
  visible,
  mapping,
  onActionComplete,
  organizationHierarchy,
}: Props) => {
  const [isSendingUpdate, setIsSendingUpdate] = useState(false);

  const [zoneMapping, setZoneMapping] = useState<DeviceMapping>(mapping);
  const [controlSystemOptions, setControlSystemOptions] = useState<
    LookupReference[]
  >([]);

  const [selectedBuilding, setSelectedBuilding] = useState<LookupReference>(
    typeof mapping.data !== 'undefined' &&
      mapping.data !== null &&
      mapping.data.buildingId !== null &&
      mapping.data.buildingId !== EMPTY_ID
      ? {
          id: mapping.data.buildingId,
          name: getBuildingNameById(
            organizationHierarchy,
            mapping.data.buildingId,
            '',
          ),
        }
      : { id: EMPTY_ID, name: '' },
  );

  useEffect(() => {
    if (selectedBuilding.id === EMPTY_ID) {
      setControlSystemOptions([]);
    } else {
      const building = getBuildingById(
        organizationHierarchy,
        selectedBuilding.id,
      );
      if (building === null) {
        setControlSystemOptions([]);
      } else {
        const options = building.children.map((cs) => {
          return {
            id: cs.id,
            name: cs.name,
          };
        });
        setControlSystemOptions(options);
      }
    }
  }, [organizationHierarchy, selectedBuilding]);

  const handleBuildingSelect = (buildingData: Building) => {
    const newBuilding: LookupReference = {
      id: buildingData.id,
      name: buildingData.name,
    };

    const newRegion: LookupReference = {
      id:
        typeof buildingData.region !== 'undefined' &&
        buildingData.region !== null
          ? buildingData.region.id
          : EMPTY_ID,
      name:
        typeof buildingData.region !== 'undefined' &&
        buildingData.region !== null
          ? buildingData.region.name
          : '',
    };

    setSelectedBuilding(newBuilding);
    setZoneMapping({
      ...zoneMapping,
      data: {
        ...zoneMapping.data,
        buildingId: newBuilding.id,
        regionId: newRegion.id,
      },
    });
  };

  const handleBuildingChange = (building: string) => {
    const newBuilding: LookupReference = {
      id: EMPTY_ID,
      name: building,
    };

    setSelectedBuilding(newBuilding);
    setZoneMapping({
      ...zoneMapping,
      data: { ...zoneMapping.data, buildingId: EMPTY_ID, regionId: EMPTY_ID },
    });
  };

  const handleControlSystemSelectChanged = (newValue: string) => {
    for (let csIdx = 0; csIdx < controlSystemOptions.length; csIdx++) {
      if (controlSystemOptions[csIdx].id === newValue) {
        setZoneMapping({
          ...zoneMapping,
          data: {
            ...zoneMapping.data,
            controlSystemId: controlSystemOptions[csIdx].id,
          },
        });
      }
    }
  };

  const sendMappingUpdate = useCallback((payload: DeviceMapping) => {
    if (isSendingUpdate) {
      return;
    }
    setIsSendingUpdate(true);

    fetch(`${NOZOMI_ENDPOINT}/zone_mappings`, {
      method: 'POST',
      headers: new Headers({
        Authorization: `Bearer ${getToken()}`,
      }),
      body: JSON.stringify(payload),
    }).then((res) => {
      setIsSendingUpdate(false);
      if (CheckResponseShowError(res)) {
        onActionComplete(true);
      }
      return res;
    });
  }, []);
  const handleSubmit = () => {
    sendMappingUpdate(zoneMapping);
  };

  return (
    <Modal
      open={visible}
      onCancel={() => onActionComplete(false)}
      title={<ModalTitle>Zone Mapping</ModalTitle>}
      confirmLoading={false}
      width={'40%'}
      footer={
        <ModalFooter>
          <Button onClick={() => onActionComplete(false)}>Close</Button>
          <Button onClick={handleSubmit} type="primary">
            Submit
          </Button>
        </ModalFooter>
      }
    >
      <Form layout="vertical" style={styles.form}>
        <FormItem label="Building" colon={false} style={styles.formItem}>
          <BuildingAutoComplete
            value={
              typeof selectedBuilding !== 'undefined' &&
              selectedBuilding !== null &&
              selectedBuilding.name !== null
                ? selectedBuilding.name
                : ''
            }
            onChange={handleBuildingChange}
            onSelect={handleBuildingSelect}
          />
        </FormItem>
        <FormItem label="Control System" colon={false} style={styles.formItem}>
          <Select
            onChange={handleControlSystemSelectChanged}
            disabled={
              typeof controlSystemOptions === 'undefined' ||
              controlSystemOptions === null ||
              controlSystemOptions.length === 0
            }
          >
            {typeof controlSystemOptions !== 'undefined' &&
              controlSystemOptions !== null &&
              controlSystemOptions.map((controlSystem) => (
                <Option key={controlSystem.id} value={controlSystem.id}>
                  {controlSystem.name}
                </Option>
              ))}
          </Select>
        </FormItem>
        <FormItem
          label="Set Is Monitored"
          colon={false}
          style={styles.formItem}
        >
          <Switch
            checked={
              typeof zoneMapping !== 'undefined' &&
              zoneMapping !== null &&
              typeof zoneMapping.data !== 'undefined' &&
              zoneMapping.data !== null
                ? zoneMapping.data.isMonitored
                : false
            }
            onChange={(checked: boolean) =>
              setZoneMapping({
                ...zoneMapping,
                data: { ...zoneMapping.data, isMonitored: checked },
              })
            }
          />
        </FormItem>
        <FormItem
          label="Create ticket when new device found"
          colon={false}
          style={styles.formItem}
        >
          <Switch
            checked={
              typeof zoneMapping !== 'undefined' &&
              zoneMapping !== null &&
              typeof zoneMapping.data !== 'undefined' &&
              zoneMapping.data !== null
                ? zoneMapping.data.createTicketOnNewDevice
                : false
            }
            onChange={(checked: boolean) =>
              setZoneMapping({
                ...zoneMapping,
                data: { ...zoneMapping.data, createTicketOnNewDevice: checked },
              })
            }
          />
        </FormItem>
      </Form>
    </Modal>
  );
};

export default ZoneDataEditModal;
