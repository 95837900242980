import React, { useCallback, useContext } from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { Collapse, CollapseProps, Flex } from 'antd';

import TemplateContext from '@totem/components/surveyV2/templateDetails/SurveyTemplateContext';
import SurveyTemplateQuestionOption from '@totem/components/surveyV2/templateDetails/SurveyTemplateQuestionOption';
import {
  Category,
  QuestionOption,
  SurveyQuestion,
  SurveyTemplate,
} from '@totem/components/surveyV2/types';
import { getToken } from '@totem/utilities/accountUtilities';
import { V2_SURVEY_ENDPOINT } from '@totem/utilities/endpoints';

type Props = {
  category: Category;
  question: SurveyQuestion;
};

const SurveyTemplateQuestionOptions = ({ category, question }: Props) => {
  const { surveyTemplateId, setData, setIsLoading } =
    useContext(TemplateContext);

  const sendQuestionOptionDelete = useCallback(
    async (templateId: string, categoryId: string, optionId: string) => {
      fetch(
        `${V2_SURVEY_ENDPOINT}/template/${templateId}/categories/${categoryId}/questions/${question.id}/options/${optionId}`,
        {
          method: 'DELETE',
          headers: new Headers({
            Authorization: `Bearer ${getToken()}`,
          }),
        },
      )
        .then((res) => res.json())
        .then((result: SurveyTemplate) => {
          setData(result);
        })
        .then(() => {
          setIsLoading(false);
        });
    },
    [],
  );

  const handleDeleteOption = (item: QuestionOption) => {
    sendQuestionOptionDelete(surveyTemplateId, category.id, item.id);
  };

  const getQuestionOptionItems = () => {
    const items: CollapseProps['items'] = [];

    if (
      typeof question !== 'undefined' &&
      question !== null &&
      typeof question.options !== 'undefined' &&
      question.options !== null
    ) {
      const questionOptions = question.options.map((option) => {
        return {
          key: option.id,
          label: (
            <Flex justify={'space-between'} align={'center'}>
              <div>{option.label}</div>
              <DeleteOutlined
                title={'Delete Option'}
                onClick={() => handleDeleteOption(option)}
              />
            </Flex>
          ),
          children: (
            <SurveyTemplateQuestionOption
              question={question}
              option={option}
              category={category}
            />
          ),
        };
      });

      return questionOptions;
    }

    return items;
  };

  return <Collapse items={getQuestionOptionItems()} />;
};

export default SurveyTemplateQuestionOptions;
