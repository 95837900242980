import { gql } from '@apollo/client';

export const GET_HEALTH_RISK_SURVEY = gql`
  query GetHealthRiskSurvey($id: ID!) {
    healthRiskSurvey(id: $id) {
      id
      organization {
        id
        name
      }
      region {
        id
        name
      }
      building {
        id
        name
      }
      questionsOrder
      questions
      totalQuestions
      completedQuestions
      isPassing
      isSubmitted
      submittedAt
      expiresAt
    }
  }
`;

export const UPDATE_HEALTH_RISK_SURVEY = gql`
  mutation UpdateHealthRiskSurvey($input: HealthRiskSurveyUpdateInput!) {
    healthRiskSurveyUpdate(input: $input) {
      id
      questions
      totalQuestions
      completedQuestions
      isSubmitted
      isPassing
      submittedAt
      expiresAt
    }
  }
`;
