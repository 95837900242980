import React, { useContext } from 'react';
import { Alert, Divider } from 'antd';

import BasePane from '@totem/components/BasePane';
import PrimaryContentPane from '@totem/components/PrimaryContentPane';
import AssigneeCard from '@totem/components/surveyV2/instanceDetail/AssigneeCard';
import ExportSurveyButton from '@totem/components/surveyV2/instanceDetail/ExportSurveyButton';
import FilteredSwitch from '@totem/components/surveyV2/instanceDetail/FilteredSwitch';
import HealthCard from '@totem/components/surveyV2/instanceDetail/HealthCard';
import OverviewCard from '@totem/components/surveyV2/instanceDetail/OverviewCard';
import SubmitButton from '@totem/components/surveyV2/instanceDetail/SubmitButton';
import SurveyInstance from '@totem/components/surveyV2/instanceDetail/SurveyInstance';
import SurveyContext from '@totem/components/surveyV2/instanceDetail/SurveyInstanceContext';
import colors from '@totem/styles/colors';
import { isVendorUser } from '@totem/utilities/authUtilities';
import { isNotNull } from '@totem/utilities/common';

import ContentLayout from '../../ContentLayout';

import '../survey.css';

const styles = {
  divider: {
    background: colors.neutral.gray,
  },
  dateDivider: {
    height: '5rem',
    margin: '0 2rem',
  },
  alert: {
    margin: '1rem 0 2rem 0',
  },
  iframeContainer: {
    position: 'relative',
    paddingBottom: '56.25%',
    paddingTop: '2.5rem',
    height: 0,
    marginBottom: '3rem',
  },
  iframe: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
};
const parseDate = (date: number): string => {
  if (date) {
    return new Date(date).toLocaleDateString();
  }
  return '';
};

const SurveyInstanceDetail = () => {
  const { data, loading, errorAlertVisible, setErrorAlertVisible, errors } =
    useContext(SurveyContext);

  const getHelpTextClass = () => {
    return data.survey.helpText && !data.survey.isSubmitted ? 'help-text' : '';
  };
  const getHeaderContentRight = () => {
    return data.survey.startDate && data.survey.dueDate ? (
      <div styleName="dates-container">
        <div>
          <div styleName="date-title">Start Date</div>
          <div styleName="date">
            {data.survey.startDate ? parseDate(data.survey.startDate) : ''}
          </div>
        </div>
        <Divider type="vertical" style={styles.dateDivider} />
        <div>
          <div styleName="date-title">Due Date</div>
          <div styleName="date">
            {data.survey.dueDate ? parseDate(data.survey.dueDate) : ''}
          </div>
        </div>
      </div>
    ) : null;
  };

  return (
    <>
      {typeof data !== 'undefined' &&
        data !== null &&
        typeof data.survey !== 'undefined' &&
        data.survey !== null && (
          <ContentLayout
            pageTitle={data.survey.name}
            pageSubtitle={[
              { label: data.organization.name },
              { label: data.region.name },
              {
                label: data.building.name,
                link: isVendorUser()
                  ? ''
                  : `/dashboard/buildings/${data.building.id}`,
              },
            ]}
            breadcrumbs={(breadcrumbs) => [
              ...breadcrumbs,
              { label: data.survey.name },
            ]}
            contentRight={getHeaderContentRight()}
          >
            <React.Fragment>
              <BasePane>
                <div styleName="survey-details">
                  <div styleName="health-container">
                    <HealthCard />
                  </div>
                  <div styleName="overview-container">
                    <OverviewCard />
                  </div>
                  <div styleName="assignees-container">
                    <AssigneeCard />
                  </div>
                </div>
                {errorAlertVisible && !!errors.length && (
                  <Alert
                    message="It looks like you didn't answer all the required questions. Please review your answers."
                    type="error"
                    closable
                    onClose={() => setErrorAlertVisible(false)}
                    showIcon
                    style={styles.alert}
                  />
                )}
                <div>
                  <PrimaryContentPane>
                    <React.Fragment>
                      <div
                        styleName={`header-container ${getHelpTextClass()}`}
                      />
                      <div styleName="submit-container">
                        <div styleName="show-unanswered-container">
                          <FilteredSwitch />
                          <div styleName="show-unanswered">
                            Show Unanswered Questions Only
                          </div>
                        </div>
                        <div>
                          {isNotNull(data) && (
                            <span>
                              <ExportSurveyButton surveyId={data.survey.id} />
                              &nbsp;
                            </span>
                          )}
                          <SubmitButton disabled={loading} />
                        </div>
                      </div>
                      <SurveyInstance />
                    </React.Fragment>
                  </PrimaryContentPane>
                </div>
              </BasePane>
            </React.Fragment>
          </ContentLayout>
        )}
    </>
  );
};

export default SurveyInstanceDetail;
