import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useMutation } from '@apollo/client';
import { Select } from 'antd';

import { createNotification } from '@totem/actions/notificationActions';
import { resetStore } from '@totem/actions/resetActions';
import UserProfileContext from '@totem/components/UserProfileContext';
import client from '@totem/graph/client';
import { SWITCH_ORGANIZATION } from '@totem/graph/organization';
import accountUtilities from '@totem/utilities/accountUtilities';

import './organizationSwitcherDropdown.css';
import { useNavigate } from 'react-router-dom';

const { Option } = Select;

const OrganizationSwitcherDropdown = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { userProfile, loading } = useContext(UserProfileContext);
  const { organizations, organization } = userProfile;

  const [switchOrganization] = useMutation(SWITCH_ORGANIZATION);

  const navigateToOrganization = async (id: string) => {
    try {
      const {
        data: {
          organizationSwitch: { token: newToken },
        },
      } = await switchOrganization({
        variables: {
          organizationId: id,
        },
      });

      dispatch(resetStore());
      accountUtilities.setToken(newToken);
      await client.clearStore();

      navigate('/');
    } catch (err) {
      dispatch(
        createNotification({
          message: `Could not switch to the organization`,
          isError: true,
        }),
      );
    }
  };

  return (
    <div styleName="organization-switcher-dropdown-container">
      {organizations && (
        <Select
          defaultValue=""
          popupMatchSelectWidth={false}
          style={{ width: 200 }}
          value={organization?.id}
          onChange={navigateToOrganization}
          loading={loading}
        >
          {organizations
            .filter((org) => org.isActive)
            .map((org) => (
              <Option key={org.id} value={org.id}>
                {org.name}
              </Option>
            ))}
        </Select>
      )}
    </div>
  );
};

export default OrganizationSwitcherDropdown;
