import React, { useContext } from 'react';

import Count from '@totem/components/common/Count';
import AnalysisReportContext from '@totem/components/surveyV2/analysisReport/analysisReportContext';
import SubTitle from '@totem/components/surveyV2/SubTitle';
import colors from '@totem/styles/colors';
import { isNotNull } from '@totem/utilities/common';

import '../analysisReport.css';

const styles = {
  count: {
    background: colors.neutral.ash,
  },
};

const MostCriticalPolicies = () => {
  const { data, input, setInput } = useContext(AnalysisReportContext);
  const topCriticalPolicies = isNotNull(data)
    ? data.mostCriticalPolicies.slice(0, 5)
    : [];

  const handlePolicyNameSelected = (policyName: string) => {
    setInput({
      ...input,
      policyName: [policyName],
    });
  };

  return (
    <div styleName="overview-section">
      <SubTitle>Most Critical Policies</SubTitle>
      <div styleName="overview-content">
        <div styleName="overview-content-container">
          <div styleName="findings">
            <div styleName="finding-labels">
              <div styleName="graph-title finding">By Policy Name</div>
              {topCriticalPolicies.map((topPolicy, index) => (
                <div key={index} styleName="finding">
                  <div>
                    <div
                      styleName="finding-label"
                      onClick={() =>
                        handlePolicyNameSelected(topPolicy.policyName)
                      }
                    >
                      {topPolicy.policyName}
                    </div>
                    <div styleName="finding-label-subtext">
                      {topPolicy.policyCount} Policies
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div>
              <div styleName="graph-title no-bold finding">
                Number of Non-Compliant
              </div>
              {topCriticalPolicies.map((topPolicy, index) => (
                <div key={index} styleName="finding">
                  <Count
                    count={topPolicy.nonCompliant}
                    size="small"
                    style={styles.count}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MostCriticalPolicies;
