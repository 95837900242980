import React, { useContext } from 'react';

import RemoteAccessUserDetailsContext from '@totem/components/remoteAccess/userDetails/RemoteAccessUserDetailsContext';

const RemoteAccessUserTitle = () => {
  const { roleData } = useContext(RemoteAccessUserDetailsContext);

  return (
    <div>
      {typeof roleData !== 'undefined' &&
      roleData !== null &&
      roleData.user !== null
        ? roleData.user.email
        : ''}
    </div>
  );
};

export default RemoteAccessUserTitle;
