import React from 'react';
import { Flex } from 'antd';

import './cve.css';

type Props = {
  title: string;
  value: string;
  valueColor: string;
};

const ValueBlock = ({ title, value, valueColor }: Props) => {
  const valueStyle = {
    backgroundColor: valueColor,
  };

  return (
    <Flex justify={'space-between'} align={'center'} styleName="valueBlock">
      <div styleName="valueBlockLabel">{title}</div>
      <div style={valueStyle} styleName="valueBlockValue">
        {value}
      </div>
    </Flex>
  );
};

export default ValueBlock;
