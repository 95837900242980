import React, { useContext } from 'react';
import { Tabs, TabsProps } from 'antd';

import TemplateContext from '@totem/components/surveyV2/templateDetails/SurveyTemplateContext';
import SurveyTemplateLevelsByLevelType from '@totem/components/surveyV2/templateDetails/SurveyTemplateLevelsByLevelType';
const SurveyTemplateLevels = () => {
  const { data } = useContext(TemplateContext);

  const getTabItems = () => {
    const tabItems: TabsProps['items'] = [];

    if (typeof data.levelTypes !== 'undefined' && data.levelTypes !== null) {
      return data.levelTypes.map((levelType) => {
        return {
          key: `LevelType:${levelType.type}`,
          label: levelType.displayName,
          children: (
            <SurveyTemplateLevelsByLevelType levelType={levelType.type} />
          ),
        };
      });
    }

    return tabItems;
  };

  return <Tabs tabPosition="left" items={getTabItems()} />;
};

export default SurveyTemplateLevels;
