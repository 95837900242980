import { BuildingsConnection } from './building';
import { ControlSystemsConnection } from './controlSystem';
import { PolicyAuditsConnection } from './policyAudit';

export interface Region {
  id: string;
  name: string;
  buildings?: BuildingsConnection;
  controlSystems?: ControlSystemsConnection;
  policyAudits?: PolicyAuditsConnection;
}

export interface AddEditRegion {
  id?: string;
  name: string;
}

export interface DeleteRegion {
  id: string;
  name?: string;
}

export enum RegionAction {
  ADD = 'ADD',
  EDIT = 'EDIT',
  DELETE = 'DELETE',
}

export interface RegionsConnection {
  totalCount: number;
  regions: Region[];
}

export interface RegionsConnectionInput {
  limit?: number;
  offset?: number;
  id?: string[];
  name?: string;
  sortOrder?: 'ascend' | 'descend' | string;
  sortBy?: 'name' | string;
}
