import React, { useState } from 'react';
import { EditTwoTone, UsergroupAddOutlined } from '@ant-design/icons';
import { Button, Card, Tag } from 'antd';
import * as R from 'ramda';

import EditList from '@totem/components/common/pointOfContact/EditList';
import { PointOfContact } from '@totem/components/common/pointOfContact/types';
import colors from '@totem/styles/colors';

import './pointsOfContact.css';
import SideBarTitle from '@totem/components/SideBarTitle';

const styles = {
  card: {
    width: '100%',
    marginBottom: '3.5rem',
  },
  cardHeader: {
    borderTop: `5px solid`,
    borderImage: `${colors.gradient.accent} 100% 0 0 0`,
    //borderImage: `${colors.gradient.turquoise} 100% 0 0 0`,
  },
  cardBody: {
    padding: '2.4rem',
    paddingBottom: '0',
    borderTop: `1px solid ${colors.antd.borderGray}`,
  },
  icon: {
    fontSize: '4rem',
    color: colors.neutral.silver,
  },
};

type Props = {
  objectId: string;
  objectType: string;
  title: string;
  loading: boolean;
  contactsBaseUrl: string;
  contactTypesUrl: string;
  data: PointOfContact[];
  onAction?: (action: string, contact: PointOfContact) => void;
  canEdit: boolean;
};

const PointOfContacts = ({
  objectId,
  objectType,
  title,
  data,
  canEdit,
  loading,
  contactsBaseUrl,
  contactTypesUrl,
  onAction,
}: Props) => {
  const [showContactList, setShowContactList] = useState<boolean>(false);

  const handleActionComplete = (action: string, contact: PointOfContact) => {
    // eslint-disable-next-line default-case
    switch (action) {
      case 'Close':
        setShowContactList(false);
        break;
      case 'Update':
        if (typeof onAction === 'function') {
          onAction('Update', contact);
        }
        break;
      case 'Remove':
        if (typeof onAction === 'function') {
          onAction('Remove', contact);
        }
        break;
    }
  };

  return (
    <>
      <Card
        title={
          <SideBarTitle>
            <div>{title !== null ? title : `${objectType} Contacts`}</div>
            {canEdit && (
              <Button
                shape="circle"
                icon={<EditTwoTone twoToneColor={colors.brand.blue} />}
                onClick={() => setShowContactList(true)}
              />
            )}
          </SideBarTitle>
        }
        style={styles.card}
        headStyle={styles.cardHeader}
        bodyStyle={styles.cardBody}
      >
        <>
          {typeof data !== 'undefined' && data !== null && !R.isEmpty(data) ? (
            data.map((contact: PointOfContact, index: number) => (
              <div styleName="contact-block" key={contact.name + index}>
                <div styleName="contact-title">{contact.name}</div>
                <div>{contact.email}</div>
                <div>{contact.phone}</div>
                {contact.labels !== null && (
                  <div>
                    {contact.labels.map(label => (
                      <Tag key={label}>{label}</Tag>
                    ))}
                  </div>
                )}
              </div>
            ))
          ) : (
            <div styleName="no-system-container">
              <UsergroupAddOutlined style={styles.icon} />
              <div styleName="no-system-subtext">
                Assign or invite users to be the {objectType} contact.
              </div>
              <Button onClick={() => setShowContactList(true)} type="primary">
                Assign Contact
              </Button>
            </div>
          )}
        </>
      </Card>
      <EditList
        open={showContactList}
        onActionComplete={handleActionComplete}
        loading={loading}
        objectId={objectId}
        objectType={objectType}
        data={data}
        baseUrl={contactsBaseUrl}
        optionsUrl={contactTypesUrl}
      />
    </>
  );
};

export default PointOfContacts;
