import React, { useEffect, useState } from 'react';
import { QuestionOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Form, Input, Modal, notification, Tooltip } from 'antd';
import * as R from 'ramda';

import { REQUEST_HELP } from '@totem/graph/help';
import { useErrorNotification } from '@totem/hooks/useErrorNotification';
import fonts from '@totem/styles/fonts';
import { getAccountPayloadSync } from '@totem/utilities/accountUtilities';
import { validateEmail } from '@totem/utilities/validation';

const FormItem = Form.Item;

interface Errors {
  email?: string;
  name?: string;
  message?: string;
}

const styles = {
  button: {
    marginRight: '1rem',
  },
  form: {
    width: '100%',
  },
  formItem: {
    paddingBottom: '0',
    marginBottom: '1rem',
  },
  title: {
    fontSize: fonts.fontXl,
    fontWeight: 700,
  },
};

const getUserEmail = () => {
  const payload = getAccountPayloadSync();
  // TODO: Remove @ts-ignore : Token
  // @ts-ignore
  return payload.email;
};

const getUserName = () => {
  const payload = getAccountPayloadSync();
  // TODO: Remove @ts-ignore : Token
  // @ts-ignore
  return payload.name;
};

const HelpDeskModal = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [email, setEmail] = useState<string>(getUserEmail());
  const [phone, setPhone] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [errors, setErrors] = useState<Errors>({});

  const [requestHelp, { loading, error }] = useMutation(REQUEST_HELP);

  useErrorNotification(error, 'Error Submitting Request');

  useEffect(() => {
    if (!open) {
      setEmail(getUserEmail());
      setPhone('');
      setMessage('');
      setName(getUserName());
      setErrors({});
    }
  }, [open]);

  const isValid = () => {
    const formErrors: Errors = {};

    if (R.isEmpty(email)) {
      formErrors.email = 'Please enter an email';
    } else if (!validateEmail(email)) {
      formErrors.email = 'Please enter a valid email';
    }

    if (R.isEmpty(name)) {
      formErrors.name = 'Please enter a name';
    }

    if (R.isEmpty(message)) {
      formErrors.message = 'Please enter a message';
    }

    setErrors(formErrors);

    return R.isEmpty(formErrors);
  };

  const handleSubmit = async () => {
    if (isValid()) {
      await requestHelp({
        variables: {
          input: {
            email,
            phone,
            message,
            name,
          },
        },
      });

      setOpen(false);

      notification.success({
        message: 'Request Submitted',
        description: 'Successfully submitted help desk request.',
      });
    }
  };

  return (
    <>
      <Tooltip title="Help">
        <Button
          style={styles.button}
          size="large"
          shape="circle"
          icon={<QuestionOutlined />}
          onClick={() => setOpen(true)}
        />
      </Tooltip>
      <Modal
        title={<div style={styles.title}>Help Desk Request</div>}
        open={open}
        onCancel={() => setOpen(false)}
        onOk={handleSubmit}
        okText="Submit"
        confirmLoading={loading}
      >
        <div style={{ marginBottom: '2rem' }}>
          Enter a question or comment and we’ll get back to you as soon as we
          can.
        </div>
        <Form layout="vertical" style={styles.form}>
          <FormItem
            label="Email"
            colon={false}
            style={styles.formItem}
            validateStatus={R.isNil(errors.email) ? 'success' : 'error'}
            help={R.isNil(errors.email) ? null : errors.email}
            required
          >
            <Input
              name="email"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              autoComplete="off"
              disabled
            />
          </FormItem>
          <FormItem
            label="Name"
            colon={false}
            style={styles.formItem}
            validateStatus={R.isNil(errors.name) ? 'success' : 'error'}
            help={R.isNil(errors.name) ? null : errors.name}
            required
          >
            <Input
              name="name"
              value={name}
              onChange={(event) => setName(event.target.value)}
              autoComplete="off"
            />
          </FormItem>
          <FormItem label="Phone Number" colon={false} style={styles.formItem}>
            <Input
              name="phone"
              value={phone}
              onChange={(event) => setPhone(event.target.value)}
              autoComplete="off"
            />
          </FormItem>
          <FormItem
            label="Message"
            colon={false}
            style={styles.formItem}
            validateStatus={R.isNil(errors.message) ? 'success' : 'error'}
            help={R.isNil(errors.message) ? null : errors.message}
            required
          >
            <Input.TextArea
              name="message"
              value={message}
              onChange={(event) => setMessage(event.target.value)}
              autoComplete="off"
            />
          </FormItem>
        </Form>
      </Modal>
    </>
  );
};

export default HelpDeskModal;
