import actionTypes from '@totem/actions/actionTypes';

import initialState from './initialState';

const replace = (predicate, value, collection) => {
  return collection.map(item => (predicate(item, value) ? value : item));
};

export default function buildingsReducer(
  state = initialState.buildings,
  action,
) {
  switch (action.type) {
    case actionTypes.BUILDINGS_GET_SUCCESS: {
      return action.data ? [...action.data] : [];
    }
    case actionTypes.BUILDING_UPDATE_SUCCESS: {
      return replace(user => user.id === action.data.id, action.data, state);
    }
    case actionTypes.BUILDING_DELETE_SUCCESS: {
      return state.filter(building => building.id !== action.data.id);
    }
    default: {
      return state;
    }
  }
}
