import { PolicyAuditPatch } from '@totem/types/policyAudit';

import actionTypes from './actionTypes';

export const requestPolicyAudit = (data: { id: string }) => ({
  type: actionTypes.REQUEST_POLICY_AUDIT,
  data,
});

export const getPolicyAuditSuccess = (data: object) => ({
  type: actionTypes.GET_POLICY_AUDIT_SUCCESS,
  data,
});

export const getPolicyAuditFailure = () => ({
  type: actionTypes.GET_POLICY_AUDIT_FAILURE,
});

export const requestPolicyAuditPatch = (data: PolicyAuditPatch) => ({
  type: actionTypes.REQUEST_POLICY_AUDIT_PATCH,
  data,
});

export const policyAuditPatchSuccess = (data: object) => ({
  type: actionTypes.POLICY_AUDIT_PATCH_SUCCESS,
  data,
});

export const policyAuditPatchFailure = () => ({
  type: actionTypes.POLICY_AUDIT_PATCH_FAILURE,
});

export const updatePolicyAuditQuestions = (data: object) => ({
  type: actionTypes.UPDATE_POLICY_AUDIT_QUESTIONS,
  data,
});

export const updatePolicyAuditAnswer = (data: object) => ({
  type: actionTypes.UPDATE_POLICY_AUDIT_ANSWER,
  data,
});

export const updatePolicyAuditMoreInfo = (data: object) => ({
  type: actionTypes.UPDATE_POLICY_AUDIT_MORE_INFO,
  data,
});
