import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { TablePaginationConfig } from 'antd/es/table';
import { ColumnProps } from 'antd/lib/table';
import { SorterResult } from 'antd/lib/table/interface';

import { EMPTY_ID } from '@totem/components/common/reference/ReferenceSelector';
import Table from '@totem/components/common/table/Table';
import DeviceActionMenu from '@totem/components/devices/DeviceActionMenu';
import DeviceContext from '@totem/components/devices/devicesContainer/deviceContainerContext';
import { Device, DeviceQueryResult } from '@totem/types/devices';
import { stringifyArray } from '@totem/utilities/tableFilterUtilities';
import { getFilterOptions } from '@totem/utilities/ticketing';

import '../../../devices/devices.css';

const GatewayDeviceOrgTable = () => {
  const { input, setInput, loading, deviceData, filterOptions, totalRecords } =
    useContext(DeviceContext);

  const getBuildingDisplay = (device: DeviceQueryResult) => {
    if (
      device.building !== null &&
      device.building.id !== null &&
      device.building.id !== EMPTY_ID
    ) {
      return (
        <Link to={`/dashboard/buildings/${device.building.id}`}>
          {device.building.name}
        </Link>
      );
    } else if (device.building !== null) {
      return <span>{device.building.name}</span>;
    }
    return <span>-</span>;
  };

  const getContractLine = (device: Device) => {
    if (device.contractLines !== null && device.contractLines.length > 0) {
      const activeLines = device.contractLines.filter(
        (cl) => cl.status === 'Active',
      );
      return (
        <span>
          {activeLines.map((cl) => {
            if (cl.service === 'Remote Access') {
              return (
                <span key={cl.service + cl.lineId} title={cl.service}>
                  {cl.orderNumber}:{cl.lineNumber}
                </span>
              );
            }
            return (
              <span
                key={cl.service + cl.lineId}
                style={{ color: '#D39600' }}
                title={cl.service}
              >
                {cl.orderNumber}:{cl.lineNumber}
              </span>
            );
          })}
        </span>
      );
    }
    return <span>-</span>;
  };

  const getAttributeValue = (
    device: DeviceQueryResult,
    attributeName: string,
  ) => {
    if (
      device.device !== null &&
      device.device.attributes !== null &&
      device.device.attributes.length > 0
    ) {
      for (let idx = 0; idx < device.device.attributes.length; idx++) {
        if (device.device.attributes[idx].name === attributeName) {
          return <span>{device.device.attributes[idx].value}</span>;
        }
      }
    }
    return <span>-</span>;
  };

  const columns: ColumnProps<DeviceQueryResult>[] = [
    {
      title: 'Organization',
      dataIndex: 'organization.name',
      key: 'organization.name',
      showSorterTooltip: true,
      render: (_, device: DeviceQueryResult) => (
        <span>
          {device.organization.name}
        </span>
      ),
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        compA.organization.name === compB.organization.name
          ? 0
          : compA.organization.name > compB.organization.name
          ? 1
          : -1,
    },
    {
      title: 'Name',
      dataIndex: 'device.displayName',
      key: 'displayName',
      showSorterTooltip: true,
      render: (_, device: DeviceQueryResult) => (
        <Link to={`/dashboard/devices/${device.device.id}`}>
          {device.device.displayName}
        </Link>
      ),
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        compA.device.displayName === compB.device.displayName
          ? 0
          : compA.device.displayName > compB.device.displayName
          ? 1
          : -1,
    },
    {
      title: 'Host Name',
      dataIndex: 'device.attributes.host',
      key: 'host',
      render: (_, device: DeviceQueryResult) =>
        getAttributeValue(device, 'Hostname'),
    },
    {
      title: 'Model',
      dataIndex: 'device.attributes.model',
      key: 'model',
      render: (_, device: DeviceQueryResult) =>
        getAttributeValue(device, 'Model'),
      filterMultiple: true,
      filteredValue: stringifyArray(input.model),
      filters: getFilterOptions(
        typeof filterOptions !== 'undefined' && filterOptions !== null
          ? filterOptions.models
          : null,
      ),
    },
    {
      title: 'Version',
      dataIndex: 'device.attributes.version',
      key: 'version',
      render: (_, device: DeviceQueryResult) =>
        getAttributeValue(device, 'Version'),
    },
    {
      title: 'Serial Number',
      dataIndex: 'device.attributes.serialNumber',
      key: 'serialNumber',
      render: (_, device: DeviceQueryResult) =>
        getAttributeValue(device, 'Serial Number'),
    },
    {
      title: 'Asset Tag',
      dataIndex: 'device.attributes.assetTag',
      key: 'assetTag',
      render: (_, device: DeviceQueryResult) =>
        getAttributeValue(device, 'Asset Tag'),
    },
    {
      title: 'Firmware Revision',
      dataIndex: 'device.attributes.firmware',
      key: 'firmware',
      render: (_, device: DeviceQueryResult) =>
        getAttributeValue(device, 'Firmware Revision'),
    },
    {
      title: 'Contract Line',
      dataIndex: 'device.contractLine.orderNumber',
      key: 'backupStatus',
      render: (_, device: DeviceQueryResult) => {
        return getContractLine(device.device);
      },
    },
    {
      title: 'Building',
      dataIndex: 'building.name',
      key: 'buildingId',
      showSorterTooltip: true,
      render: (_, device: DeviceQueryResult) => getBuildingDisplay(device),
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        compA.building.name === compB.building.name
          ? 0
          : compA.building.name > compB.building.name
          ? 1
          : -1,
      filterMultiple: true,
      filteredValue: stringifyArray(input.buildingId),
      filters: getFilterOptions(
        typeof filterOptions !== 'undefined' && filterOptions !== null
          ? filterOptions.building
          : null,
      ),
    },
    {
      title: 'Region',
      dataIndex: 'region.name',
      key: 'regionId',
      showSorterTooltip: true,
      render: (_, device: DeviceQueryResult) => device.region.name,
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        compA.region.name === compB.region.name
          ? 0
          : compA.region.name > compB.region.name
          ? 1
          : -1,
      filterMultiple: true,
      filteredValue: stringifyArray(input.regionId),
      filters: getFilterOptions(
        typeof filterOptions !== 'undefined' && filterOptions !== null
          ? filterOptions.region
          : null,
      ),
    },
    {
      title: 'Actions',
      width: 30,
      dataIndex: 'additionalOptions',
      key: 'additionalOptions',
      render: (_, device: DeviceQueryResult) => (
        <div className="center-table-cell">
          <DeviceActionMenu device={device} />
        </div>
      ),
    },
  ];

  const getDevices = () => {
    if (deviceData !== null) {
      if (deviceData.devices !== null) {
        return deviceData.devices;
      }
    }
    return [];
  };

  const handleTableChange = (
    updatedPagination: TablePaginationConfig,
    filters: SorterResult<DeviceQueryResult>,
    sorter,
  ) => {
    const { ...params } = filters;

    let sortDir: string = sorter.order === 'descend' ? '-1' : '1';
    if (typeof sorter.order === 'undefined' || sorter.order === null) {
      if (
        typeof input.sortDirection !== 'undefined' &&
        input.sortDirection !== null
      ) {
        sortDir = input.sortDirection;
      }
    }

    let sortField = input.sortField ? input.sortField : 'lastOccurrence';
    if (
      typeof sorter.field !== 'undefined' &&
      typeof sorter.order !== 'undefined'
    ) {
      sortField = sorter.field;
    }

    // @ts-ignore
    setInput({
      ...input,
      ...params,
      pageSize: updatedPagination.pageSize,
      page: updatedPagination.current,
      sortField,
      sortDirection: sortDir,
    });
  };

  return (
    <Table
      showSorterTooltip
      columns={columns}
      dataSource={getDevices()}
      loading={loading}
      onChange={handleTableChange}
      pagination={{
        current: input.page,
        pageSize: input.pageSize,
        total: totalRecords,
        showSizeChanger: true,
      }}
      rowKey={(record) => record.device.id}
    />
  );
};

export default GatewayDeviceOrgTable;
