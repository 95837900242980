import React, { useState } from 'react';
import { DownloadOutlined } from '@ant-design/icons';
import { Button } from 'antd';

import { FindingsConnectionInput } from '@totem/types/finding';
import api from '@totem/utilities/api';
import { FINDINGS_ENDPOINT } from '@totem/utilities/endpoints';
import fileUtilities from '@totem/utilities/fileUtilities';

interface Props {
  input: FindingsConnectionInput;
}

const FindingsExport = ({ input }: Props) => {
  const [loading, setLoading] = useState<boolean>(false);

  const exportAudits = async () => {
    setLoading(true);
    const { data } = await api.post(`${FINDINGS_ENDPOINT}/export`, input);
    fileUtilities.downloadFile('findings', data);
    setLoading(false);
  };

  return (
    <Button
      type="default"
      icon={<DownloadOutlined />}
      onClick={exportAudits}
      loading={loading}
    >
      Export CSV
    </Button>
  );
};

export default FindingsExport;
