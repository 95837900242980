import { createContext } from 'react';

import { SurveyTemplate} from '@totem/components/surveyV2/types';

interface Context {
  data: SurveyTemplate[];
  setData: (update: SurveyTemplate[]) => void;
  loading: boolean;
  setIsLoading: (isLoading: boolean) => void;
}

export default createContext<Context>({
  data: [],
  setData: () => {},
  loading: false,
  setIsLoading: () => {},
});
