import actionTypes from '@totem/actions/actionTypes';

import initialState from './initialState';

export default function remoteAccessRoleUsersReducer(
  state = initialState.remoteAccessRoleUsers,
  action,
) {
  switch (action.type) {
    case actionTypes.REQUEST_REMOTE_ACCESS_ROLE_USERS_SUCCESS: {
      return action.data ? action.data : state;
    }
    case actionTypes.REQUEST_REMOTE_ACCESS_ROLE_USERS_FAILED: {
      return null;
    }

    case actionTypes.REQUEST_REMOTE_ACCESS_USER_ADD_TO_ROLE_SUCCESS: {
      if (action.data !== null) {
        if (action.data.length > 0) {
          // eslint-disable-next-line max-depth
          if (state === null || state.orgRoleId === action.data[0].orgRoleId) {
            return action.data[0] ? action.data[0] : state;
          }
        }
      }

      return state;
    }
    case actionTypes.REQUEST_REMOTE_ACCESS_USER_REMOVE_FROM_ROLE_SUCCESS: {
      if (action.data !== null) {
        if (action.data.length > 0) {
          // eslint-disable-next-line max-depth
          if (state === null || state.orgRoleId === action.data[0].orgRoleId) {
            return action.data[0] ? action.data[0] : state;
          }
        }
      }

      return state;
    }

    default: {
      return state;
    }
  }
}
