import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button, Form, Input, Modal, notification } from 'antd';
import * as R from 'ramda';

import EmailAutoComplete from '@totem/components/common/remoteAccess/autoCompletes/EmailAutoComplete';
import { RemoteAccessUserAddRequest } from '@totem/components/remoteAccess/dialogs/types';
import { isValidForm } from '@totem/components/remoteAccessUsers/RemoteAccessUserAddModal';
import { AddEditRemoteAccessUser } from '@totem/types/remoteAccessUsers';
import { getToken } from '@totem/utilities/accountUtilities';
import { REMOTE_ACCESS_ROLES_ENDPOINT } from '@totem/utilities/endpoints';
const FormItem = Form.Item;
import ModalFooter from '@totem/components/ModalFooter';
import ModalTitle from '@totem/components/ModalTitle';
import RemoteAccessUserContext from '@totem/components/remoteAccess/roleUsers/RemoteAccessUserContext';
import { isNotNull } from '@totem/utilities/common';

const styles = {
  form: {
    width: '100%',
  },
  formItem: {
    paddingBottom: '0',
    marginBottom: '1rem',
  },
};

interface FormErrors {
  email?: string;
  givenName?: string;
  familyName?: string;
}

export interface Props {
  visible: boolean;
  onActionComplete: (refresh: boolean) => void;
}

const initialUser: AddEditRemoteAccessUser = {
  // eslint-disable-next-line camelcase
  user_id: '',
  email: '',
  givenName: '',
  familyName: '',
};

const AddUserToRoleModal = (props: Props) => {
  const { data, loading } = useContext(RemoteAccessUserContext);
  const { visible, onActionComplete } = props;

  const [user, setUser] = useState<AddEditRemoteAccessUser>(initialUser);
  const [isSending, setIsSending] = useState(false);
  const [errors, setErrors] = useState<FormErrors>({});

  useEffect(() => {
    if (!visible) {
      setErrors({});
      setUser(initialUser);
    }
  }, [visible]);

  const handleChange = ({
    target: { value, name },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setUser({ ...user, [name]: value });
  };

  const sendAddUserToRootRole = useCallback(
    async (payload: RemoteAccessUserAddRequest) => {
      if (isSending) {
        return;
      }

      setIsSending(true);

      fetch(`${REMOTE_ACCESS_ROLES_ENDPOINT}`, {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getToken()}`,
        }),
        body: JSON.stringify(payload),
      })
        .then((res) => {
          if (res.status < 400) {
            onActionComplete(true);
          } else {
            let errorMessage = 'An error occurred while adding user to role.';

            const contentType = res.headers.get('content-type');
            if (contentType && contentType.includes('application/json')) {
              res.json().then((errorData) => {
                errorMessage = isNotNull(errorData)
                  ? errorData.developerMessage
                  : errorMessage;

                notification.error({
                  message: 'Error adding user to role!',
                  description: errorMessage,
                  duration: 0,
                });
                onActionComplete(false);
              });
            } else {
              notification.error({
                message: 'Error adding user to role!',
                description: errorMessage,
                duration: 0,
              });
              onActionComplete(false);
            }
          }
        })
        .then(() => {
          setIsSending(false);
        });
    },
    [isSending],
  );

  const handleSubmit = () => {
    const formErrors = isValidForm(user);

    if (R.isEmpty(formErrors)) {
      const payload: RemoteAccessUserAddRequest = {
        email: user.email,
        givenName: user.givenName,
        familyName: user.familyName,
        roleId: data.orgRoleId,
        roleName: data.orgRole,
      };

      sendAddUserToRootRole(payload);
    }

    setErrors(formErrors);
  };

  const handleEmailChange = (email: string) => {
    setUser({ ...user, email });
  };

  return (
    <Modal
      open={visible}
      onCancel={() => onActionComplete(false)}
      title={<ModalTitle>Add User</ModalTitle>}
      okText="Add"
      confirmLoading={loading}
      footer={
        <ModalFooter>
          <Button onClick={() => onActionComplete(false)}>Cancel</Button>
          <Button
            onClick={handleSubmit}
            loading={loading}
            type="primary"
            disabled={isSending}
          >
            Add
          </Button>
        </ModalFooter>
      }
    >
      <Form layout="vertical" style={styles.form}>
        <FormItem
          label="Email"
          colon={false}
          style={styles.formItem}
          validateStatus={R.isNil(errors.email) ? 'success' : 'error'}
          help={R.isNil(errors.email) ? null : errors.email}
        >
          <EmailAutoComplete
            value={user.email}
            onChange={handleEmailChange}
            placeholder="email@example.com"
          />
        </FormItem>
        <FormItem
          label="First Name"
          colon={false}
          style={styles.formItem}
          validateStatus={R.isNil(errors.givenName) ? 'success' : 'error'}
          help={R.isNil(errors.givenName) ? null : errors.givenName}
        >
          <Input
            name="givenName"
            value={user.givenName}
            onChange={handleChange}
            type="search"
          />
        </FormItem>
        <FormItem
          label="Last Name"
          colon={false}
          style={styles.formItem}
          validateStatus={R.isNil(errors.familyName) ? 'success' : 'error'}
          help={R.isNil(errors.familyName) ? null : errors.familyName}
        >
          <Input
            name="familyName"
            value={user.familyName}
            onChange={handleChange}
            type="search"
          />
        </FormItem>
      </Form>
    </Modal>
  );
};

export default AddUserToRoleModal;
