import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { CheckCircleOutlined } from '@ant-design/icons';
import { Badge, Flex } from 'antd';
import { TablePaginationConfig } from 'antd/es/table';
import { ColumnProps } from 'antd/lib/table';
import { SorterResult } from 'antd/lib/table/interface';

import CriticalFindingsTooltip from '@totem/components/common/findings/CriticalFindingsTooltip';
import Grade from '@totem/components/common/Grade';
import OrganizationContext from '@totem/components/common/organizationContext/organizationContext';
import Table from '@totem/components/common/table/Table';
import Tooltip from '@totem/components/common/tooltip/Tooltip';
import ActionMenu from '@totem/components/surveyV2/policyAuditReport/ActionMenu';
import PolicyAuditReportContext from '@totem/components/surveyV2/policyAuditReport/policyAuditReportContext';
import { PolicyAuditReportRecord } from '@totem/components/surveyV2/policyAuditReport/types';
import colors from '@totem/styles/colors';
import fonts from '@totem/styles/fonts';
import { isNotNull } from '@totem/utilities/common';
import { stringifyArray } from '@totem/utilities/tableFilterUtilities';
import {
  sortBooleanAscending,
  sortNumberAscending,
  sortStringAscending,
} from '@totem/utilities/tableUtilities';

import './policyAuditReport.css';

const styles = {
  criticalFindings: {
    backgroundColor: colors.neutral.gray,
  },
  criticalFindingsDanger: {
    backgroundColor: colors.brand.red,
  },
  submittedIcon: {
    color: colors.brand.green,
    marginRight: '0.5rem',
    fontSize: '20px',
  },
  sourceUnknown: {
    color: colors.brand.gray,
    borderColor: colors.brand.gray,
    backgroundColor: 'rgba(0, 0, 0, 0)',
  },
  sourceHistory: {
    color: colors.brand.red,
    borderColor: colors.brand.red,
    backgroundColor: 'rgba(0, 0, 0, 0)',
  },
  sourceActive: {
    color: colors.brand.green,
    borderColor: colors.brand.green,
    backgroundColor: 'rgba(0, 0, 0, 0)',
  },
  icon: {
    cursor: 'pointer',
    color: colors.opacity.black0_4,
    fontSize: fonts.fontLg,
    marginLeft: '1rem',
  },
  pastDueDate: {
    background: 'rgba(237, 54, 32, 0.25)',
  },
  inactive: {
    color: colors.neutral.inactive,
  },
};

const ReportTable = () => {
  const { data, loading, input, setInput } = useContext(
    PolicyAuditReportContext,
  );
  const { regions, buildings } = useContext(OrganizationContext);

  const getData = () => {
    if (isNotNull(data) && isNotNull(data.data)) {
      return data.data;
    }

    return [];
  };

  const columns: ColumnProps<PolicyAuditReportRecord>[] = [
    {
      title: 'Name',
      dataIndex: 'policyAudit.name',
      key: 'policyAudit.name',
      showSorterTooltip: true,
      render: (_, record: PolicyAuditReportRecord) => {
        if (record.policyAudit.source === 'surveyInstances') {
          return (
            <Link to={`dashboard/surveyinstances/${record.policyAudit.id}`}>
              {record.policyAudit.name}
            </Link>
          );
        }
        if (record.policyAudit.source === 'policyAudit') {
          return (
            <Link to={`/dashboard/policyaudits/${record.policyAudit.id}`}>
              {record.policyAudit.name}
            </Link>
          );
        }

        return <span>{record.policyAudit.name}</span>;
      },
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        sortStringAscending(compA.policyAudit.name, compB.policyAudit.name),
    },
    {
      title: 'Source',
      key: 'policyAudit.source',
      dataIndex: 'policyAudit.source',
      render: (_, record: PolicyAuditReportRecord) => {
        switch (record.policyAudit.source) {
          case 'surveyInstances':
            return (
              <Badge count={'S'} style={styles.sourceActive} title={'Survey'} />
            );
          case 'policyAudit':
            return (
              <Badge
                count={'A'}
                style={styles.sourceActive}
                title={'Policy Audit'}
              />
            );
          case 'policyAuditsHistory':
            return (
              <Badge
                count={'H'}
                style={styles.sourceHistory}
                title={'Archived Policy Audit'}
              />
            );
          default:
            return (
              <Badge
                count={'-'}
                style={styles.sourceUnknown}
                title={'Unknown'}
              />
            );
        }
      },
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        sortStringAscending(compA.policyAudit.source, compB.policyAudit.source),
    },
    {
      title: 'Launched',
      dataIndex: 'policyAudit.isLaunched',
      key: 'policyAudit.isLaunched',
      showSorterTooltip: true,
      render: (_, record: PolicyAuditReportRecord) => {
        return (
          <div
            styleName={record.policyAudit.isLaunched ? 'submitted' : ''}
            className="center-table-cell"
          >
            {record.policyAudit.isLaunched && (
              <Tooltip title="Launched">
                <CheckCircleOutlined style={styles.submittedIcon} />
              </Tooltip>
            )}
          </div>
        );
      },
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        sortBooleanAscending(
          compA.policyAudit.isLaunched,
          compB.policyAudit.isLaunched,
        ),
    },
    {
      title: 'Score',
      dataIndex: 'policyAudit.totalScore',
      render: (_, record: PolicyAuditReportRecord) => {
        const score =
          record.policyAudit.maxScore > 0
            ? (record.policyAudit.totalScore / record.policyAudit.maxScore) *
              100
            : 0;

        return (
          <Flex justify={'space-between'} align={'center'}>
            <div>
              <Grade
                score={score}
                size="small"
                style={{ margin: '0 auto', color: '#FFFFFF' }}
              />
            </div>
            <div>{Math.floor(score)}%</div>
          </Flex>
        );
      },
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        sortNumberAscending(
          compA.policyAudit.totalScore,
          compB.policyAudit.totalScore,
        ),
    },
    {
      title: 'Progress',
      key: 'policyAudit.completedQuestions',
      render: (_, record: PolicyAuditReportRecord) => {
        const progress = Math.floor(
          isNotNull(record.policyAudit) && record.policyAudit.totalQuestions > 0
            ? (record.policyAudit.completedQuestions /
                record.policyAudit.totalQuestions) *
                100
            : 0,
        );

        return (
          <Flex justify={'space-between'} align={'center'}>
            <div>
              {record.policyAudit.isSubmitted && (
                <Tooltip title="Submitted">
                  <CheckCircleOutlined style={styles.submittedIcon} />
                </Tooltip>
              )}
            </div>
            <div>{progress}%</div>
          </Flex>
        );
      },
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        sortNumberAscending(
          compA.policyAudit.completedQuestions,
          compB.policyAudit.completedQuestions,
        ),
    },
    {
      key: 'policyAudit.criticalFindings',
      dataIndex: 'criticalFindingsCount',
      title: (): React.ReactNode => (
        <div styleName="critical-findings-title">
          <div>CFs</div>
          <div styleName="critical-findings-icon">
            <CriticalFindingsTooltip />
          </div>
        </div>
      ),
      render: (_, record: PolicyAuditReportRecord) => (
        <Badge
          count={record.policyAudit.criticalFindings}
          style={
            record.policyAudit.criticalFindings
              ? styles.criticalFindingsDanger
              : styles.criticalFindings
          }
          showZero
        />
      ),
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        sortNumberAscending(
          compA.policyAudit.criticalFindings,
          compB.policyAudit.criticalFindings,
        ),
    },
    {
      title: 'Assignee',
      key: 'policyAudit.assignee',
      dataIndex: 'policyAudit.assignee',
      render: (_, record: PolicyAuditReportRecord) => (
        <div>{record.policyAudit.assignee}</div>
      ),
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        sortStringAscending(
          compA.policyAudit.assignee,
          compB.policyAudit.assignee,
        ),
    },
    {
      title: 'Start Date',
      key: 'policyAudit.startDate',
      dataIndex: 'policyAudit.startDate',
      render: (_, record: PolicyAuditReportRecord) => (
        <div>
          {record.policyAudit.startDate > 0
            ? new Date(record.policyAudit.startDate).toLocaleDateString()
            : ''}
        </div>
      ),
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        sortNumberAscending(
          compA.policyAudit.startDate,
          compB.policyAudit.startDate,
        ),
    },
    {
      title: 'Due Date',
      key: 'policyAudit.dueDate',
      dataIndex: 'policyAudit.dueDate',
      render: (_, record: PolicyAuditReportRecord) => (
        <div>
          {record.policyAudit.dueDate > 0
            ? new Date(record.policyAudit.dueDate).toLocaleDateString()
            : ''}
        </div>
      ),
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        sortNumberAscending(
          compA.policyAudit.dueDate,
          compB.policyAudit.dueDate,
        ),
    },
    {
      title: 'Region',
      key: 'regionId',
      dataIndex: 'region.name',
      render: (_, record: PolicyAuditReportRecord) => (
        <div>{isNotNull(record.region) ? record.region.name : ''}</div>
      ),
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        sortStringAscending(compA.region.name, compB.region.name),
      filterMultiple: true,
      filteredValue: stringifyArray(input.regionId),
      filters:
        typeof regions !== 'undefined' && regions !== null
          ? regions.map((region) => {
              return { text: region.name, value: region.id };
            })
          : [],
    },
    {
      title: 'Building',
      key: 'buildingId',
      dataIndex: 'building.name',
      render: (_, record: PolicyAuditReportRecord) => (
        <div>{isNotNull(record.building) ? record.building.name : ''}</div>
      ),
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        sortStringAscending(compA.building.name, compB.building.name),
      filterMultiple: true,
      filteredValue: stringifyArray(input.buildingId),
      filters:
        typeof buildings !== 'undefined' && buildings !== null
          ? buildings.map((building) => {
              return { text: building.name, value: building.id };
            })
          : [],
    },
    {
      title: 'Control System',
      key: 'controlSystemId',
      dataIndex: 'controlSystem.name',
      render: (_, record: PolicyAuditReportRecord) => (
        <div>
          {isNotNull(record.controlSystem) ? record.controlSystem.name : ''}
        </div>
      ),
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        sortStringAscending(compA.controlSystem.name, compB.controlSystem.name),
    },
    {
      title: 'Actions',
      width: 30,
      dataIndex: 'additionalOptions',
      key: 'additionalOptions',
      render: (_, record: PolicyAuditReportRecord) => (
        <div className="center-table-cell">
          <ActionMenu record={record} />
        </div>
      ),
    },
  ];

  const handleTableChange = (
    updatedPagination: TablePaginationConfig,
    filters: SorterResult<PolicyAuditReportRecord>,
    sorter,
  ) => {
    const { ...params } = filters;

    let sortDir: string = sorter.order === 'descend' ? '-1' : '1';
    if (typeof sorter.order === 'undefined' || sorter.order === null) {
      if (
        typeof input.sortDirection !== 'undefined' &&
        input.sortDirection !== null
      ) {
        sortDir = input.sortDirection;
      }
    }

    let sortField = input.sortField ? input.sortField : 'lastOccurrence';
    if (
      typeof sorter.field !== 'undefined' &&
      typeof sorter.order !== 'undefined'
    ) {
      sortField = sorter.field;
    }

    // @ts-ignore
    setInput({
      ...input,
      ...params,
      pageSize: updatedPagination.pageSize,
      page: updatedPagination.current,
      sortField,
      sortDirection: sortDir,
    });
  };

  return (
    <Table
      showSorterTooltip
      columns={columns}
      dataSource={getData()}
      loading={loading}
      rowKey={(record) => record.policyAudit.id}
      onChange={handleTableChange}
      pagination={{
        current: input.page,
        pageSize: input.pageSize,
        total: isNotNull(data) ? data.count : 0,
        showSizeChanger: true,
      }}
    />
  );
};

export default ReportTable;
