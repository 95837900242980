import React, { useContext } from 'react';
import { EllipsisOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { Button, Dropdown, MenuProps } from 'antd';

import CommissioningReportContext from '@totem/components/commissionReport/CommissioningReportContext';
import { CommissionContractRecord } from '@totem/components/commissionReport/types';
import UserProfileContext from '@totem/components/UserProfileContext';
import {
  isMemberOfAny,
  securityGroupCheckConstraintEmpty,
} from '@totem/utilities/userUtilities';

type Props = {
  record: CommissionContractRecord;
};

const CommissionsActionsMenu = ({ record }: Props) => {
  const { userProfile } = useContext(UserProfileContext);
  const { onAction } = useContext(CommissioningReportContext);

  const userCanEditContracts = isMemberOfAny(
    userProfile,
    ['contract_creator'],
    securityGroupCheckConstraintEmpty,
  );

  const onMenuClicked = ({ key }) => {
    // eslint-disable-next-line default-case
    switch (key) {
      case 'editLine':
        onAction('EditLine', record);
        break;
      case 'createTicket':
        onAction('CreateTicket', record);
    }
  };

  const getMenuItems = () => {
    const items: MenuProps['items'] = [];

    if (userCanEditContracts) {
      items.push({
        key: 'editLine',
        title: 'Edit Contract Line',
        label: 'Edit Contract Line',
        icon: <MenuUnfoldOutlined />,
      });
    }

    if (record.tickets.length === 0) {
      items.push({
        key: 'createTicket',
        title: 'Create Ticket',
        label: 'Create Ticket',
        icon: <MenuUnfoldOutlined />,
      });
    }

    return items;
  };

  return (
    <Dropdown
      menu={{ items: getMenuItems(), onClick: onMenuClicked }}
      trigger={['click']}
    >
      <Button shape="circle" icon={<EllipsisOutlined />} />
    </Dropdown>
  );
};

export default CommissionsActionsMenu;
