import React, { useContext } from 'react';
import { EditOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { TablePaginationConfig } from 'antd/es/table';
import { ColumnProps } from 'antd/lib/table';
import { SorterResult } from 'antd/lib/table/interface';

import Table from '@totem/components/common/table/Table';
import Tooltip from '@totem/components/common/tooltip/Tooltip';
import EndOfLifeContext from '@totem/components/endoflife/EndOfLifeContext';
import { Product } from '@totem/components/endoflife/types';
import authUtilities from '@totem/utilities/authUtilities';

const styles = {
  iconButton: {
    margin: '0 2px',
  },
};

const EndOfLifeTable = () => {
  const {
    input,
    setInput,
    loading,
    reportData,
    totalRecords,
    onAction,
  } = useContext(EndOfLifeContext);

  const getData = () => {
    if (reportData !== null) {
      if (reportData.products !== null) {
        return reportData.products;
      }
    }
    return [];
  };

  const startEdit = (product: Product) => {
    onAction('edit', product);
  };

  const columns: ColumnProps<Product>[] = [
    {
      title: 'Product Type',
      dataIndex: 'productType',
      key: 'productType',
      showSorterTooltip: true,
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        compA.productType === compB.productType
          ? 0
          : compA.productType > compB.productType
          ? 1
          : -1,
    },
    {
      title: 'Product',
      dataIndex: 'product',
      key: 'product',
      showSorterTooltip: true,
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        compA.product === compB.product
          ? 0
          : compA.product > compB.product
          ? 1
          : -1,
    },
    {
      title: 'Cycle',
      dataIndex: 'cycle',
      key: 'cycle',
      showSorterTooltip: true,
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        compA.cycle === compB.cycle ? 0 : compA.cycle > compB.cycle ? 1 : -1,
    },
    {
      title: 'Latest Version',
      dataIndex: 'latestVersion',
      key: 'latestVersion',
      showSorterTooltip: true,
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        compA.latestVersion === compB.latestVersion
          ? 0
          : compA.latestVersion > compB.latestVersion
          ? 1
          : -1,
    },
    {
      title: 'End of Support',
      dataIndex: 'endOfSupportDate',
      key: 'endOfSupportDate',
      showSorterTooltip: true,
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        compA.endOfSupportDate === compB.endOfSupportDate
          ? 0
          : compA.endOfSupportDate > compB.endOfSupportDate
          ? 1
          : -1,
    },
    {
      title: 'End of Life',
      dataIndex: 'endOfLifeDate',
      key: 'endOfLifeDate',
      showSorterTooltip: true,
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        compA.endOfLifeDate === compB.endOfLifeDate
          ? 0
          : compA.endOfLifeDate > compB.endOfLifeDate
          ? 1
          : -1,
    },
    {
      title: 'Actions',
      width: 30,
      dataIndex: 'additionalOptions',
      key: 'action',
      render: (_, item: Product) => (
        <div className="center-table-cell">
          {authUtilities.minimumRequiredRole(
            authUtilities.ROLE_SETS.SYSTEMS_ADMINISTRATOR,
          ) && (
            <Tooltip title="Update Building Mapping" placement="top">
              <Button
                type="default"
                icon={<EditOutlined />}
                shape="circle"
                onClick={() => startEdit(item)}
                style={styles.iconButton}
              />
            </Tooltip>
          )}
        </div>
      ),
    },
  ];

  const handleTableChange = (
    updatedPagination: TablePaginationConfig,
    filters: SorterResult<Product>,
    sorter,
  ) => {
    const { ...params } = filters;

    let sortDir: string = sorter.order === 'descend' ? '-1' : '1';
    if (typeof sorter.order === 'undefined' || sorter.order === null) {
      if (
        typeof input.sortDirection !== 'undefined' &&
        input.sortDirection !== null
      ) {
        sortDir = input.sortDirection;
      }
    }

    let sortField = input.sortField ? input.sortField : 'lastOccurrence';
    if (
      typeof sorter.field !== 'undefined' &&
      typeof sorter.order !== 'undefined'
    ) {
      sortField = sorter.field;
    }

    // @ts-ignore
    setInput({
      ...input,
      ...params,
      pageSize: updatedPagination.pageSize,
      page: updatedPagination.current,
      sortField,
      sortDirection: sortDir,
    });
  };

  return (
    <Table
      showSorterTooltip
      columns={columns}
      dataSource={getData()}
      loading={loading}
      onChange={handleTableChange}
      pagination={{
        current: input.page,
        pageSize: input.pageSize,
        total: totalRecords,
        showSizeChanger: true,
      }}
      rowKey={record => record.id}
    />
  );
};

export default EndOfLifeTable;
