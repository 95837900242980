import React, { useEffect, useRef, useState } from 'react';
import {
  Affix,
  Button,
  Checkbox,
  Divider,
  Dropdown,
  DropdownProps,
  MenuProps,
  Radio,
  theme,
} from 'antd';
import * as R from 'ramda';

import colors from '@totem/styles/colors';
import { isNotNull } from '@totem/utilities/common';

import './dropdownFilter.css';

export type DropdownFilterOption = {
  label: React.ReactNode;
  value: string | number;
};

export type Props = {
  label: string;
  options: DropdownFilterOption[];
  value?: Array<string | number>;
  onChange: (selected: string[]) => void;
  singleSelect?: boolean;
  defaultSelected?: Array<string | number>;
  style?: React.CSSProperties;
  open?: boolean;
  placement?:
    | 'topLeft'
    | 'topCenter'
    | 'topRight'
    | 'bottomLeft'
    | 'bottomCenter'
    | 'bottomRight'
    | 'top'
    | 'bottom';
};

const styles = {
  anchor: {
    position: 'relative' as any,
  },
  menu: {
    boxShadow: 'none',
    maxHeight: '25rem',
    overflow: 'scroll',
  },
  checkbox: {
    marginRight: '0.5rem',
  },
  radio: {
    marginRight: '0.5rem',
  },
  buttonSelected: {
    background: '#141238',
    color: colors.neutral.white,
    fontWeight: 700,
  },
};

const DropdownFilter = ({
  label,
  options,
  value,
  defaultSelected,
  singleSelect,
  onChange,
  style,
  open,
  placement,
}: Props) => {
  const [container, setContainer] = React.useState<HTMLDivElement | null>(null);
  const anchor = useRef();
  const [visible, setVisible] = useState<boolean>(
    typeof open !== 'undefined' && open !== null ? open : false,
  );
  const [selected, setSelected] = useState<any[]>(defaultSelected || []);

  const [numberSelected, setNumberSelected] = useState<number>(
    defaultSelected ? defaultSelected.length : 0,
  );

  const { useToken } = theme;
  const { token } = useToken();

  const handleClick = ({ key }: any) => {
    if (singleSelect) {
      setSelected([key]);
    } else {
      setSelected(
        selected.includes(key)
          ? selected.filter((val) => val !== key)
          : [...selected, key],
      );
    }
  };

  const handleSubmit = () => {
    onChange(selected);
    setVisible(false);
  };

  const handleReset = () => {
    setSelected([]);
    setVisible(false);
    onChange([]);
  };

  useEffect(() => {
    if (!visible) {
      setNumberSelected(selected.length);
    }
  }, [visible, selected]);

  useEffect(() => {
    if (!R.isNil(value)) {
      setSelected(value.map((val) => val.toString()));
    }
  }, [value]);

  const getMenuItems = () => {
    const menuItems: MenuProps['items'] = options.map((option) => {
      const optionValue = option.value.toString();

      const isSelected = selected
        .map((val) => val.toString())
        .includes(optionValue);

      return {
        key: optionValue,
        label: (
          <div styleName="menu-item">
            {singleSelect ? (
              <Radio style={styles.radio} checked={isSelected} />
            ) : (
              <Checkbox style={styles.checkbox} checked={isSelected} />
            )}
            {option.label}
          </div>
        ),
      };
    });

    return menuItems;
  };

  const contentStyle: React.CSSProperties = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
  };

  const menuStyle: React.CSSProperties = {
    boxShadow: 'none',
  };

  const handleOpenChange: DropdownProps['onOpenChange'] = (nextOpen, info) => {
    if (info.source === 'trigger' || nextOpen) {
      setVisible(nextOpen);
    }
  };

  return (
    <Dropdown
      placement={isNotNull(placement) ? placement : 'bottomLeft'}
      menu={{
        items: getMenuItems(),
        onClick: handleClick,
        multiple: true,
        selectedKeys: selected.map((val) => val.toString()),
      }}
      className="menu-item-no-wrap"
      //overlayClassName="dropdown-fixed-height"
      trigger={['click']}
      open={visible}
      onOpenChange={handleOpenChange} // handleVisibleChange
      getPopupContainer={() => anchor.current}
      dropdownRender={(menu) => (
        <div style={contentStyle} ref={setContainer}>
          <div className={'dropdown-fixed-height'}>
            {React.cloneElement(menu as React.ReactElement, {
              style: menuStyle,
            })}
          </div>
          <Divider style={{ margin: 0 }} />
          <Affix offsetBottom={0} target={() => container}>
            <div styleName="buttons">
              <a onClick={handleReset}>Reset</a>
              <a styleName="ok-button" onClick={handleSubmit}>
                OK
              </a>
            </div>
          </Affix>
        </div>
      )}
    >
      <div style={styles.anchor} ref={anchor}>
        <Button
          style={{
            ...style,
            ...(numberSelected ? styles.buttonSelected : {}),
          }}
        >
          {numberSelected ? `${label} - ${numberSelected} items` : label}
        </Button>
      </div>
    </Dropdown>
  );
};

export default DropdownFilter;
