import { gql } from '@apollo/client';

export const POLICY_AUDIT_DETAIL_FRAGMENT = gql`
  fragment PolicyAuditDetail on PolicyAudit {
    id
    name
    organization {
      id
      name
    }
    region {
      id
      name
    }
    building {
      id
      name
    }
    controlSystem {
      id
      name
    }
    assignee {
      email
      organizations {
        id
        name
        deactivatedAt
      }
    }
    health {
      id
      status
      comment
    }
    scores {
      summary
      systemUsers
      systemConfiguration
      network
      continuity
      controlSystemHost
      minimal
      moderate
      maximum
      modeledMinimum
    }
    findings {
      id
      criticality
      levelOfEffort
      group
      description
      additionalData {
        label
        text
      }
    }
    criticalFindingsCount
    totalQuestions
    completedQuestions
    outstandingQuestions
    dueDate
    startDate
    submitted
    isLaunched
    createdAt
    updatedAt
  }
`;

export const GET_POLICY_AUDIT = gql`
  query GetPolicyAudit($id: ID!) {
    policyAudit(id: $id) {
      ...PolicyAuditDetail
    }
  }
  ${POLICY_AUDIT_DETAIL_FRAGMENT}
`;

export const GET_POLICY_AUDIT_COUNTS = gql`
  query GetPolicyAudits($input: PolicyAuditsConnectionInput!) {
    policyAudits(input: $input) {
      totalCount
      healthStatusCounts {
        onTrack
        atRisk
        offTrack
        needed
      }
      completionCounts {
        complete
        inProgress
        notStarted
        overdue
      }
      findingCriticalityCounts {
        critical
        high
        medium
        low
      }
    }
  }
`;

export const GET_POLICY_AUDIT_COMPLETION_COUNTS = gql`
  query GetPolicyAudits($input: PolicyAuditsConnectionInput!) {
    policyAudits(input: $input) {
      completionCounts {
        complete
        inProgress
        notStarted
        overdue
      }
    }
  }
`;

export const GET_POLICY_AUDITS = gql`
  query GetPolicyAudits($input: PolicyAuditsConnectionInput!) {
    policyAudits(input: $input) {
      totalCount
      policyAudits {
        id
        region {
          id
          name
        }
        building {
          id
          name
        }
        assignee {
          email
          organizations {
            id
            name
            deactivatedAt
          }
        }
        controlSystem {
          id
          name
          systemType
          manufacturer
          model
        }
        health {
          id
          status
          comment
        }
        criticalFindingsCount
        totalQuestions
        completedQuestions
        scores {
          summary
        }
        startDate
        dueDate
        submitted
        isLaunched
      }
    }
  }
`;

export const GET_POLICY_AUDIT_ASSIGNEES = gql`
  query getPolicyAuditAssignees($input: PolicyAuditsConnectionInput!) {
    policyAudits(input: $input) {
      totalCount
      policyAudits {
        assignee {
          firstName
          lastName
          imageUrl
          email
        }
      }
    }
  }
`;

export const GET_VENDOR_POLICY_AUDITS = gql`
  query GetVendorPolicyAudits($input: PolicyAuditsConnectionInput!) {
    policyAudits(input: $input) {
      totalCount
      policyAudits {
        id
        building {
          name
        }
        controlSystem {
          name
          systemType
        }
        findings {
          id
          criticality
          levelOfEffort
        }
        scores {
          summary
        }
        totalQuestions
        startDate
        dueDate
        submitted
        submittedAt
      }
    }
  }
`;

export const UPDATE_POLICY_AUDIT = gql`
  mutation UpdatePolicyAudit($policyAudit: PolicyAuditUpdateInput!) {
    policyAuditUpdate(policyAudit: $policyAudit) {
      ...PolicyAuditDetail
    }
  }
  ${POLICY_AUDIT_DETAIL_FRAGMENT}
`;

export const POLICY_AUDITS_LAUNCH = gql`
  mutation PolicyAuditsLaunch($policyAuditsBulk: PolicyAuditsBulkInput!) {
    policyAuditsLaunch(policyAuditsBulk: $policyAuditsBulk) {
      total
    }
  }
`;

export const CREATE_POLICY_AUDIT = gql`
  mutation CreatePolicyAudit($input: PolicyAuditCreateInput!) {
    policyAuditCreate(input: $input) {
      ...PolicyAuditDetail
    }
  }
  ${POLICY_AUDIT_DETAIL_FRAGMENT}
`;

export const DELETE_POLICY_AUDIT = gql`
  mutation DeletePolicyAudit($id: ID!) {
    policyAuditDelete(id: $id)
  }
`;
