export const debounce = (func, wait, leading = false) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    if (leading && !timeout) {
      func.apply(this, args);
    }
    timeout = setTimeout(() => {
      timeout = null;
      if (!leading) {
        func.apply(this, args);
      }
    }, wait);
  };
};
