export const getMapsRequestUrl = () => {
  const baseEndpoint = 'https://maps.googleapis.com/maps/api/js';
  const version = '3.33';
  const libraries = 'places,geometry';
  const apiKey = 'AIzaSyDjpjcyXILqewTdrWAkHxcor3uxpFf6flE';

  return `${baseEndpoint}?v=${version}&libraries=${libraries}&key=${apiKey}`;
};

const center = { lat: 39.8282, lng: -98.5795 };
const zoom = 5;

const viewPort = { center, zoom };
const mapTypeId = 'roadmap';

// https://developers.google.com/maps/documentation/javascript/3.exp/reference#MapTypeStyle
const mapStyles = {
  featureType: 'poi',
  elementType: 'labels',
  stylers: [{ visibility: 'off' }],
};

const draggableCursor = 'default';
const draggingCursor = 'move';

const mapUtilities = {
  getMapsRequestUrl,
  draggableCursor,
  draggingCursor,
  mapStyles,
  mapTypeId,
  viewPort,
};

export default mapUtilities;
