import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';

import { SummaryRecord } from '@totem/components/deviceBackups/backupSummary/types';
import {
  defaultChartColors,
  defaultDonutChartTotalColor,
} from '@totem/utilities/charts';

type Props = {
  chartData: SummaryRecord[];
  chartOptions?: ApexOptions;
  centerLabel?: string;
  onItemClicked?: (point: SummaryRecord) => void;
  onClick?: (field: string, label: string) => void;
};

const DonutChart = ({
  chartData,
  centerLabel,
  chartOptions,
  onItemClicked,
}: Props) => {
  const getSeriesData = () => {
    const data = chartData.map((point) => point.value);
    return data;
  };

  const getLabels = () => {
    const data = chartData.map((point) =>
      point.name !== '' ? point.name : 'Unknown',
    );
    return data;
  };

  const handleClick = (e: any, chart?: any, options?: any) => {
    if (typeof onItemClicked !== 'undefined' && onItemClicked !== null) {
      const dataPointIndex = options.dataPointIndex;
      if (dataPointIndex >= 0) {
        const point = chartData[dataPointIndex];
        onItemClicked(point);
      }
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();
    }
  };

  const options: ApexOptions = {
    chart: {
      type: 'donut',
      toolbar: { show: true, tools: { download: true } },
      width: 800,
      height: 400,
      events: {
        dataPointSelection: handleClick,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: false,
    },
    plotOptions: {
      pie: {
        donut: {
          size: '75%',
          labels: {
            show: true,
            total: {
              show: true,
              showAlways: true,
              fontSize: '20px',
              fontWeight: 'bold',
              color: defaultDonutChartTotalColor,
              label:
                typeof centerLabel !== 'undefined' && centerLabel !== null
                  ? centerLabel
                  : '',
              formatter: () => '',
            },
          },
        },
      },
    },
    labels: getLabels(),
    colors: defaultChartColors,
    legend: {
      show: true,
      position: 'right',
      width: 150,
    },
    responsive: [
      {
        breakpoint: 1600,
        options: {
          chart: {
            width: 200,
            height: 200,
          },
          legend: {
            show: false,
          },
        },
      },
    ],
  };

  const getChartOptions = () => {
    if (typeof chartOptions !== 'undefined' && chartOptions !== null) {
      return { ...options, ...chartOptions };
    }
    return options;
  };

  return (
    <div className="app">
      <div className="row">
        <div className="mixed-chart">
          {typeof chartData !== 'undefined' &&
            chartData !== null &&
            chartData.length > 0 && (
              <>
                <ReactApexChart
                  options={getChartOptions()}
                  series={getSeriesData()}
                  labels={getLabels()}
                  type="donut"
                  width="100%"
                  height="800px"
                />
              </>
            )}
        </div>
      </div>
    </div>
  );
};

export default DonutChart;
