import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { FileTextOutlined, SolutionOutlined } from '@ant-design/icons';
import { ColumnProps } from 'antd/lib/table';
import dayjs from 'dayjs';

import Grade from '@totem/components/common/Grade';
import Table from '@totem/components/common/table/Table';
import { calculateScore } from '@totem/components/surveyV2/utilities/SurveyUtilities';
import { WorkItem } from '@totem/components/surveyV2/work/types';
import WorkContext from '@totem/components/surveyV2/work/WorkContext';
import { getDashboardPath } from '@totem/utilities/authUtilities';
import { isNotNull } from '@totem/utilities/common';
import { sortStringAscending } from '@totem/utilities/tableUtilities';

import '../survey.css';
const WorkTableCompleted = () => {
  const { data, loading } = useContext(WorkContext);
  const basePath = getDashboardPath();

  const getData = (): WorkItem[] => {
    if (isNotNull(data)) {
      const inProgress = data.filter((chk) => chk.isSubmitted === true);
      return inProgress;
    }
    return [];
  };

  const columns: ColumnProps<WorkItem>[] = [
    {
      title: '',
      dataIndex: 'type',
      key: 'type',
      showSorterTooltip: true,
      render: (_, record: WorkItem) => (
        <span>
          {record.type === 'policyAudit' && (
            <SolutionOutlined styleName={'table-icon'} title={'Policy Audit'} />
          )}
          {record.type !== 'policyAudit' && (
            <FileTextOutlined styleName={'table-icon'} title={'Survey'} />
          )}
        </span>
      ),
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) => sortStringAscending(compA.type, compB.type),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      showSorterTooltip: true,
      render: (_, record: WorkItem) => (
        <span>
          {record.source === 'policyAudit' && (
            <Link to={`${basePath}/policyaudits/${record.id}`}>
              {record.name}
            </Link>
          )}
          {record.source !== 'policyAudit' && (
            <Link to={`${basePath}/surveyinstances/${record.id}`}>
              {record.name}
            </Link>
          )}
        </span>
      ),
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) => sortStringAscending(compA.name, compB.name),
    },
    {
      title: 'Building',
      dataIndex: 'building',
      key: 'building',
      render: (_, record: WorkItem) => record.building?.name,
      sorter: (compA, compB) =>
        sortStringAscending(
          typeof compA !== 'undefined' && compA !== null
            ? compA.building.name
            : '',
          typeof compB !== 'undefined' && compB !== null
            ? compB.building.name
            : '',
        ),
    },
    {
      title: 'Control System',
      dataIndex: 'controlSystem',
      key: 'controlSystem',
      render: (_, record: WorkItem) => record.controlSystem?.name,
      sorter: (compA, compB) =>
        sortStringAscending(
          typeof compA !== 'undefined' && compA !== null
            ? compA.controlSystem.name
            : '',
          typeof compB !== 'undefined' && compB !== null
            ? compB.controlSystem.name
            : '',
        ),
    },
    {
      title: 'Grade',
      key: 'scores',
      render: (_, record: WorkItem) => {
        return (
          <div styleName="centered-cell">
            <Grade score={calculateScore(record.maxScore, record.totalScore)} />
          </div>
        );
      },
    },
    {
      title: 'Completed',
      key: 'updatedAt',
      render: (_, record: WorkItem) => {
        return <div>{dayjs(record.updatedAt).format('MM/DD/YYYY')}</div>;
      },
    },
  ];

  return (
    <Table
      showSorterTooltip
      columns={columns}
      dataSource={getData()}
      loading={loading}
      rowKey={(record) => record.id}
    />
  );
};

export default WorkTableCompleted;
