import React from 'react';

import './eventSeverityIndicatorContainer.css';

type Props = {
  children?: React.ReactNode;
  style?: React.CSSProperties;
};

const EventSeverityIndicatorContainer = (props: Props) => {
  return (
    <div styleName="eventIndicatorContainer" style={props.style}>
      <b>{props.children}</b>
    </div>
  );
};

export default EventSeverityIndicatorContainer;
