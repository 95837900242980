import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';

import api from '@totem/utilities/api';
import { EMAIL_VERIFICATION_ENDPOINT } from '@totem/utilities/endpoints';

import logo from '../../images/logo.ib.svg';

import './registration.css';

const styles = {
  button: {
    width: '100%',
  },
};

const EmailUnverified = () => {
  const navigate = useNavigate();
  const handleResendVerificationEmail = async () => {
    await api.post(EMAIL_VERIFICATION_ENDPOINT);
  };

  return (
    <div styleName="root-container">
      <div className="middle-box text-center loginscreen animate__animated animate__fadeInDown">
        <div>
          <div styleName="login-header">
            <img src={logo} styleName="logo" />
          </div>
          <div>
            <h3 styleName="heading" style={{ paddingTop: '3em' }}>
              Unverified Email
            </h3>
            <p
              styleName="heading"
              style={{
                paddingTop: '3em',
                paddingBottom: '3em',
                textAlign: 'center',
              }}
            >
              Your email account has not been verified. Please check your email
              or ask your administrator for support.
            </p>

            <div styleName="verify-email-container">
              <p styleName="heading">
                Didn't receive an email verification link?
              </p>
              <Button
                size="large"
                onClick={handleResendVerificationEmail}
                style={styles.button}
              >
                Resend Verification Link
              </Button>
            </div>
            <p styleName="heading">Already verified your email? Login</p>
            <Button
              size="large"
              type="primary"
              style={styles.button}
              onClick={() => navigate('/login')}
            >
              Back To Login
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailUnverified;
