import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Col, Row } from 'antd';

import { EMPTY_ID } from '@totem/components/common/reference/ReferenceSelector';
import HeaderLabel from '@totem/components/ticketing/details/headerLabel';
import UserProfileContext from '@totem/components/UserProfileContext';
import { TicketQueryResult } from '@totem/types/ticketing';
import { formatDate } from '@totem/utilities/common';

import '../ticketing.css';

type Props = {
  ticket: TicketQueryResult;
};

const TicketHeader = ({ ticket }: Props) => {
  const { userProfile } = useContext(UserProfileContext);

  const getBuildingDisplay = (ticketData: TicketQueryResult) => {
    if (
      typeof ticketData !== 'undefined' &&
      ticketData !== null &&
      ticketData.building !== null &&
      ticketData.building.id !== null &&
      ticketData.building.id !== EMPTY_ID &&
      ticketData.organization.id === userProfile.organization.id
    ) {
      return (
        <Link to={`/dashboard/buildings/${ticketData.building.id}`}>
          {ticketData.building.name}
        </Link>
      );
    } else if (ticketData.building !== null) {
      return <span>{ticketData.building.name}</span>;
    }
    return <span>-</span>;
  };

  const getControlSystemAlternate = (ticketData: TicketQueryResult) => {
    if (
      typeof ticketData !== 'undefined' &&
      ticketData !== null &&
      ticketData.ticket.attributes !== null
    ) {
      for (let idx = 0; idx < ticketData.ticket.attributes.length; idx++) {
        const attribute = ticketData.ticket.attributes[idx];
        if (attribute.name === 'ControlSystemType') {
          return attribute.value;
        }
      }
    }
    return '';
  };

  const getControlSystemDisplay = (ticketData: TicketQueryResult) => {
    if (
      typeof ticketData !== 'undefined' &&
      ticketData !== null &&
      ticketData.controlSystem !== null &&
      ticketData.controlSystem.id !== null &&
      ticketData.controlSystem.id !== EMPTY_ID &&
      ticketData.organization.id === userProfile.organization.id
    ) {
      return (
        <Link to={`/dashboard/controlsystems/${ticketData.controlSystem.id}`}>
          {ticketData.controlSystem.name}
        </Link>
      );
    } else if (
      ticketData.controlSystem !== null &&
      ticketData.controlSystem.name !== ''
    ) {
      return <span>{ticketData.controlSystem.name}</span>;
    }

    return <span>{getControlSystemAlternate(ticketData)}</span>;
  };

  const getDeviceDisplay = (ticketData: TicketQueryResult) => {
    if (
      typeof ticketData !== 'undefined' &&
      ticketData !== null &&
      ticketData.device !== null &&
      ticketData.device.id !== null &&
      ticketData.device.id !== EMPTY_ID &&
      ticketData.organization.id === userProfile.organization.id
    ) {
      return (
        <Link to={`/dashboard/devices/${ticketData.device.id}`}>
          {ticketData.device.name}
        </Link>
      );
    } else if (ticketData.device !== null) {
      return <span>{ticketData.device.name}</span>;
    }
    return <span>-</span>;
  };

  return (
    <div>
      <Row>
        <Col span={6}>
          <HeaderLabel title="Summary">{ticket.ticket.summary}</HeaderLabel>
          <HeaderLabel title="Status">
            {ticket.ticket.status}
            {ticket.ticket.subStatus !== '' && ` / ${ticket.ticket.subStatus}`}
          </HeaderLabel>
          <HeaderLabel title="Priority">{ticket.ticket.priority}</HeaderLabel>
        </Col>
        <Col span={6}>
          {typeof ticket.ticket.category !== 'undefined' &&
            ticket.ticket.category !== null &&
            ticket.ticket.category !== '' && (
              <HeaderLabel title="Category">
                {ticket.ticket.category}
              </HeaderLabel>
            )}
          {typeof ticket.ticket.subCategory !== 'undefined' &&
            ticket.ticket.subCategory !== null &&
            ticket.ticket.subCategory !== '' && (
              <HeaderLabel title="SubCategory">
                {ticket.ticket.subCategory}
              </HeaderLabel>
            )}
        </Col>
        <Col span={6}>
          <HeaderLabel title="Organization">
            {ticket.organization.name}
          </HeaderLabel>
          {ticket.region.name !== '' && (
            <HeaderLabel title="Region">{ticket.region.name}</HeaderLabel>
          )}
          {ticket.building.name !== '' && (
            <HeaderLabel title="Building">
              {getBuildingDisplay(ticket)}
            </HeaderLabel>
          )}
          {(ticket.controlSystem.name !== '' ||
            getControlSystemAlternate(ticket) !== '') && (
            <HeaderLabel title="Control System">
              {getControlSystemDisplay(ticket)}
            </HeaderLabel>
          )}
          {ticket.device.name !== '' && (
            <HeaderLabel title="Device">{getDeviceDisplay(ticket)}</HeaderLabel>
          )}
        </Col>
        <Col span={6}>
          <HeaderLabel title="Created By">
            {ticket.ticket.createdByName}
          </HeaderLabel>
          <HeaderLabel title="Created At">
            {formatDate(ticket.ticket.createdAt)}
          </HeaderLabel>
          <HeaderLabel title="Last Updated At">
            {formatDate(ticket.ticket.updatedAt)}
          </HeaderLabel>
          <HeaderLabel title="Closed At">
            {formatDate(ticket.ticket.closedAt)}
          </HeaderLabel>
        </Col>
      </Row>
      <HeaderLabel title="Description">{ticket.ticket.description}</HeaderLabel>
    </div>
  );
};

export default TicketHeader;
