import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Flex } from 'antd';

import { isNotNull } from '@totem/utilities/common';

import icon from '../../../images/backup-restore-svgrepo-com.svg';

type Props = {
  title: string;
  value: string;
  iconColor?: string;
  valueColor?: string;
  backgroundColor?: string;
  link?: string;
};

const TotalValue = ({
  title,
  value,
  iconColor,
  valueColor,
  backgroundColor,
  link,
}: Props) => {
  const navigate = useNavigate();

  const styles = {
    icon: {
      color: iconColor,
      width: '72px',
      height: '72px',
      fontSize: '72px',
    },
    title: {
      color: valueColor,
      fontSize: '24px',
      padding: '20px',
      textAlign: 'center' as 'center',
      lineHeight: '1.25em',
    },
    value: {
      color: valueColor,
      fontSize: '36px',
      fontWeight: 700,
      paddingBottom: '20px',
      cursor: 'pointer',
    },
    container: {
      backgroundColor,
      padding: '40px',
    },
  };

  const handleOnClick = () => {
    if (isNotNull(link) && link !== '') {
      navigate(link);
    }
  };

  return (
    <Flex justify={'space-between'} align={'center'} style={styles.container}>
      <Flex vertical justify={'center'} align={'center'}>
        <img alt="icon" src={icon} style={styles.icon} />
      </Flex>
      <Flex vertical justify={'space-around'} align={'center'}>
        <div style={styles.title}>{title}</div>
        <div style={styles.value} onClick={handleOnClick}>
          {value}
        </div>
      </Flex>
    </Flex>
  );
};

export default TotalValue;
