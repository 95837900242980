import React, { useContext } from 'react';

import FilterChip from '@totem/components/common/FilterChip';
import OrganizationContext from '@totem/components/common/organizationContext/organizationContext';
import FindingsContext from '@totem/components/surveyV2/findings/FindingsContext';
import { FindingsInput } from '@totem/components/surveyV2/findings/types';
import { Criticality } from '@totem/types/criticality';

const styles = {
  filterChip: {
    marginLeft: '2rem',
    marginTop: '2rem',
  },
};

const FindingsFilterChips = () => {
  const { input, setInput } = useContext(FindingsContext);
  const { regions, buildings } = useContext(OrganizationContext);

  const getBuildingName = (buildingId: string) => {
    const buildingIdx = buildings.findIndex((chk) => chk.id === buildingId);
    if (buildingIdx >= 0) {
      return buildings[buildingIdx].name;
    }
    return buildingId;
  };

  const getRegionName = (regionId: string) => {
    const regionIdx = regions.findIndex((chk) => chk.id === regionId);
    if (regionIdx >= 0) {
      return regions[regionIdx].name;
    }
    return regionId;
  };

  const handleMultiFilterDelete = (
    property: keyof FindingsInput,
    value: string,
  ) => {
    // @ts-ignore
    const updatedValue = input[property].filter((val) => val !== value);
    setInput({
      ...input,
      [property]: updatedValue.length ? updatedValue : null,
    });
  };

  const handleNumericMultiFilterDelete = (
    property: keyof FindingsInput,
    value: number,
  ) => {
    // @ts-ignore
    const updatedValue = input[property].filter((val) => val !== value);
    setInput({
      ...input,
      [property]: updatedValue.length ? updatedValue : null,
    });
  };

  return (
    <>
      {input.criticality &&
        input.criticality.map((option) => {
          return (
            <FilterChip
              key={option}
              label={`Criticality: ${Criticality[option]}`}
              style={styles.filterChip}
              onClose={() =>
                handleNumericMultiFilterDelete('criticality', option)
              }
            />
          );
        })}
      {input.building &&
        input.building.map((option) => {
          return (
            <FilterChip
              key={option}
              label={`Building: ${getBuildingName(option)}`}
              style={styles.filterChip}
              onClose={() => handleMultiFilterDelete('building', option)}
            />
          );
        })}
      {input.region &&
        input.region.map((option) => {
          return (
            <FilterChip
              key={option}
              label={`Region: ${getRegionName(option)}`}
              style={styles.filterChip}
              onClose={() => handleMultiFilterDelete('region', option)}
            />
          );
        })}
      {input.assignee &&
        input.assignee.map((option) => {
          return (
            <FilterChip
              key={option}
              label={`Assignee: ${option}`}
              style={styles.filterChip}
              onClose={() => handleMultiFilterDelete('assignee', option)}
            />
          );
        })}
    </>
  );
};

export default FindingsFilterChips;
