import React, { useContext } from 'react';
import { ColumnProps } from 'antd/lib/table';

import Table from '@totem/components/common/table/Table';
import NetworkContext from '@totem/components/devices/network/networkContext';
import { NetworkInterface } from '@totem/components/devices/network/types';
import { FormatBytes } from '@totem/components/devices/network/utilities';

import ethernet_down from './ethernet-down.svg';
import ethernet_unknown from './ethernet-unknown.svg';
import ethernet_up from './ethernet-up.svg';
import generic_down from './generic-down.svg';
import generic_unknown from './generic-unknown.svg';
import generic_up from './generic-up.svg';
import wifi_down from './wifi-down.svg';
import wifi_unknown from './wifi-unknown.svg';
import wifi_up from './wifi-up.svg';

const NetworkTable = () => {
  const { data, loading } = useContext(NetworkContext);

  const getData = () => {
    if (typeof data !== 'undefined' && data !== null) {
      return data;
    }
    return [];
  };

  const getStatus = (record: NetworkInterface) => {
    if (
      record.interfaceType.toLowerCase().indexOf('ethernet') >= 0 ||
      record.interfaceType.toLowerCase().indexOf('802.3') >= 0
    ) {
      // Ethernet
      switch (record.status.toLowerCase()) {
        case 'up':
          return (
            <img
              width={16}
              src={ethernet_up}
              alt="Ethernet"
              title="Ethernet - Up"
            />
          );
        case 'down':
          return (
            <img
              width={16}
              src={ethernet_down}
              alt="Ethernet"
              title="Ethernet - Down"
            />
          );
        default:
          return <img width={16} src={ethernet_unknown} alt="Ethernet" />;
      }
    }
    if (
      record.interfaceType.toLowerCase().indexOf('wifi') >= 0 ||
      record.interfaceType.toLowerCase().indexOf('802.11') >= 0
    ) {
      // Wifi
      switch (record.status.toLowerCase()) {
        case 'up':
          return <img width={16} src={wifi_up} alt="Wifi" title="Wifi - Up" />;
        case 'down':
          return (
            <img width={16} src={wifi_down} alt="Wifi" title="Wifi - Down" />
          );
        default:
          return <img width={16} src={wifi_unknown} alt="Wifi" />;
      }
    }

    switch (record.status.toLowerCase()) {
      case 'up':
        return (
          <img width={16} src={generic_up} alt="Adapter" title="Adapter - Up" />
        );
      case 'down':
        return (
          <img
            width={16}
            src={generic_down}
            alt="Adapter"
            title="Adapter - Down"
          />
        );
      default:
        return <img width={16} src={generic_unknown} alt="Adapter" />;
    }
  };

  const columns: ColumnProps<NetworkInterface>[] = [
    {
      title: 'Interface Name',
      dataIndex: 'interfaceName',
      key: 'interfaceName',
      defaultSortOrder: 'ascend',
      sorter: (compA, compB) =>
        compA.interfaceName === compB.interfaceName
          ? 0
          : compA.interfaceName > compB.interfaceName
          ? 1
          : -1,
    },
    {
      title: 'Interface Type',
      dataIndex: 'interfaceType',
      key: 'interfaceType',
      sorter: (compA, compB) =>
        compA.interfaceType === compB.interfaceType
          ? 0
          : compA.interfaceType > compB.interfaceType
          ? 1
          : -1,
    },
    {
      title: 'DNS Hostname',
      dataIndex: 'dnsHostName',
      key: 'dnsHostName',
      sorter: (compA, compB) =>
        compA.dnsHostName === compB.dnsHostName
          ? 0
          : compA.dnsHostName > compB.dnsHostName
          ? 1
          : -1,
    },
    {
      title: 'DNS Servers',
      dataIndex: 'dnsServers',
      key: 'dnsServers',
      sorter: (compA, compB) =>
        compA.dnsServers === compB.dnsServers
          ? 0
          : compA.dnsServers > compB.dnsServers
          ? 1
          : -1,
    },
    {
      title: 'Link Speed',
      dataIndex: 'linkSpeed',
      key: 'linkSpeed',
      render: (_, record: NetworkInterface) => FormatBytes(+record.linkSpeed),
      sorter: (compA, compB) =>
        compA.linkSpeed === compB.linkSpeed
          ? 0
          : compA.linkSpeed > compB.linkSpeed
          ? 1
          : -1,
    },
    {
      title: 'MAC Addresses',
      dataIndex: 'macAddress',
      key: 'macAddress',
      render: (_, record: NetworkInterface) => {
        return record.macAddress.map((rec) => <div key={rec}>{rec}</div>);
      },
    },
    {
      title: 'IP Addresses',
      dataIndex: 'ipAddress',
      key: 'ipAddress',
      render: (_, record: NetworkInterface) => {
        return record.ipAddress.map((rec) => <div key={rec}>{rec}</div>);
      },
    },
    {
      title: 'Subnet Mask',
      dataIndex: 'subnetMask',
      key: 'subnetMask',
      sorter: (compA, compB) =>
        compA.subnetMask === compB.subnetMask
          ? 0
          : compA.subnetMask > compB.subnetMask
          ? 1
          : -1,
    },
    {
      title: 'Default Gateway',
      dataIndex: 'defaultGateway',
      key: 'defaultGateway',
      sorter: (compA, compB) =>
        compA.defaultGateway === compB.defaultGateway
          ? 0
          : compA.defaultGateway > compB.defaultGateway
          ? 1
          : -1,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (_, record: NetworkInterface) => getStatus(record),
      sorter: (compA, compB) =>
        compA.status === compB.status
          ? 0
          : compA.status > compB.status
          ? 1
          : -1,
    },
  ];

  return (
    <Table
      showSorterTooltip
      columns={columns}
      dataSource={getData()}
      loading={loading}
      rowKey={(record) => record.interfaceName + record.interfaceIndex}
    />
  );
};

export default NetworkTable;
