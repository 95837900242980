/* eslint-disable no-shadow */
import React, { useContext, useMemo, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { CopyOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { SorterResult, TablePaginationConfig } from 'antd/lib/table/interface';
import * as R from 'ramda';

import AutoCompleteFilter from '@totem/components/common/autoCompleteFilter/AutoCompleteFilter';
import Table from '@totem/components/common/table/Table';
import Tooltip from '@totem/components/common/tooltip/Tooltip';
import colors from '@totem/styles/colors';
import { Criticality } from '@totem/types/criticality';
import { Finding } from '@totem/types/finding';
import { getCriticalityBadge } from '@totem/utilities/criticalityUtilities';
import { toTableFilter } from '@totem/utilities/enumUtilities';
import { getPlainTextFinding } from '@totem/utilities/findingsUtilities';
import {
  getLimitAndOffsetFromTablePagination,
  getTablePagination,
} from '@totem/utilities/paginationUtilities';
import { isUserDeactivated } from '@totem/utilities/userUtilities';

import PolicyAuditFindingDetailContext from './PolicyAuditFindingDetailContext';

import './policyAuditFindingDetail.css';

const styles = {
  inactive: {
    color: colors.neutral.inactive,
  },
};

const renderDescription = ({ additionalData }: Finding) => (
  <div styleName="finding-additional-data-container">
    {additionalData.map(
      ({ label, text }) =>
        text && (
          <React.Fragment key={label}>
            <div styleName="finding-additional-data-label">{label}</div>
            <div styleName="finding-additional-data-text">{text}</div>
          </React.Fragment>
        ),
    )}
  </div>
);

const PolicyAuditFindingDetailTable = () => {
  const [buildingFilterVisible, setBuildingFilterVisible] =
    useState<boolean>(false);

  const [regionFilterVisible, setRegionFilterVisible] =
    useState<boolean>(false);

  const [assigneeFilterVisible, setAssigneeFilterVisible] =
    useState<boolean>(false);

  const {
    findings,
    totalCount,
    loading,
    input,
    setInput,
    buildingFilter,
    regionFilter,
    assigneeFilter,
  } = useContext(PolicyAuditFindingDetailContext);

  const pagination = useMemo(
    () => getTablePagination(input, totalCount),
    [input, totalCount],
  );

  const columns: ColumnProps<Finding>[] = [
    {
      title: 'Findings Description',
      dataIndex: 'description',
      render: (description) => description || 'N//A',
    },
    {
      title: 'Criticality',
      dataIndex: 'criticality',
      render: getCriticalityBadge,
      width: '17rem',
      filters: toTableFilter(Criticality),
      filterMultiple: true,
      filteredValue:
        input.criticality &&
        input.criticality.map((criticality) => criticality.toString()),
    },
    {
      title: 'Control System',
      dataIndex: 'controlSystemName',
      render: (_, { controlSystem }) => controlSystem.name,
      sorter: true,
      // @ts-ignore
      sortOrder: input.sortBy === 'controlSystemName' && input.sortOrder,
    },
    {
      title: 'Assignee',
      dataIndex: 'assignee',
      render: (assignee) =>
        isUserDeactivated(assignee) ? (
          <div style={styles.inactive}>{assignee?.email} (Inactive)</div>
        ) : (
          <div>{assignee?.email}</div>
        ),
      sorter: true,
      // @ts-ignore
      sortOrder: input.sortBy === 'assignee' && input.sortOrder,
      filterDropdown: () => {
        return (
          <AutoCompleteFilter
            label="Assignee"
            visible={assigneeFilterVisible}
            onClose={() => setAssigneeFilterVisible(false)}
            {...assigneeFilter}
          />
        );
      },
      filterDropdownOpen: assigneeFilterVisible,
      onFilterDropdownOpenChange: setAssigneeFilterVisible,
      filteredValue: Object.keys(assigneeFilter.applied),
    },
    {
      title: 'Building',
      dataIndex: 'buildingName',
      render: (_, { building }) => building.name,
      sorter: true,
      // @ts-ignore
      sortOrder: input.sortBy === 'buildingName' && input.sortOrder,
      filterDropdown: () => {
        return (
          <AutoCompleteFilter
            label="Building"
            visible={buildingFilterVisible}
            onClose={() => setBuildingFilterVisible(false)}
            {...buildingFilter}
          />
        );
      },
      filterDropdownOpen: buildingFilterVisible,
      onFilterDropdownOpenChange: setBuildingFilterVisible,
      filteredValue: Object.keys(buildingFilter.applied),
    },
    {
      title: 'Region',
      dataIndex: 'regionName',
      render: (_, { region }) => region.name,
      sorter: true,
      // @ts-ignore
      sortOrder: input.sortBy === 'regionName' && input.sortOrder,
      filterDropdown: () => {
        return (
          <AutoCompleteFilter
            label="Region"
            visible={regionFilterVisible}
            onClose={() => setRegionFilterVisible(false)}
            {...regionFilter}
          />
        );
      },
      filterDropdownOpen: regionFilterVisible,
      onFilterDropdownOpenChange: setRegionFilterVisible,
      filteredValue: Object.keys(regionFilter.applied),
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      render: (_, finding: Finding) => (
        <div
          className="center-table-cell"
          onClick={(event) => event.stopPropagation()}
        >
          <CopyToClipboard text={getPlainTextFinding(finding)}>
            <Tooltip title="Copy Finding" placement="left">
              <Button shape="circle" icon={<CopyOutlined />} />
            </Tooltip>
          </CopyToClipboard>
        </div>
      ),
    },
  ];

  const handleChange = (
    pagination: TablePaginationConfig,
    filters,
    { field, order }: SorterResult<Finding>,
  ) => {
    const update = !order
      ? R.omit(['sortBy', 'sortOrder'], input)
      : {
          ...input,
          sortBy: field,
          sortOrder: order,
        };

    const criticality =
      R.isNil(filters.criticality) || R.isEmpty(filters.criticality)
        ? null
        : filters.criticality.map(Number);

    const { limit, offset } = getLimitAndOffsetFromTablePagination(pagination);

    // @ts-ignore
    setInput({ ...update, limit, offset, criticality });
  };

  return (
    <Table
      rowKey="id"
      loading={loading}
      columns={columns}
      dataSource={findings}
      pagination={pagination}
      onChange={handleChange}
      expandedRowRender={renderDescription}
      expandRowByClick
    />
  );
};

export default PolicyAuditFindingDetailTable;
