import dayjs from 'dayjs';

import { MIN_DATE_TIME_STRING } from '@totem/types/ticketing';

const DATE_FORMAT = 'MMM D, YYYY';
const TIME_FORMAT = 'h:mm A';
const DATE_TIME_FORMAT = 'MMM D, YYYY h:mm A';

// Convert Date to 12:00:00pm GMT in milliseconds
export const convertDateToMillisGMT = (date: string) => {
  const SECONDS_TO_MILLISECONDS = 60000;
  const TWELVE_HOURS_IN_MILLISECONDS = 4.32e7;

  const localDay = dayjs(date).startOf('day');

  const gmtOffset = localDay.utcOffset() * SECONDS_TO_MILLISECONDS;

  const today = localDay.valueOf() - gmtOffset + TWELVE_HOURS_IN_MILLISECONDS;
  return today;
};

export const formatDate = (date: string): string => {
  return dayjs(date).format(DATE_FORMAT);
};

export const formatTime = (date: string): string => {
  return dayjs(date).format(TIME_FORMAT);
};

export const formatDateTime = (date: string): string => {
  return dayjs(date).format(DATE_TIME_FORMAT);
};

export const formatDateTimeDisplay = (date: string): string => {
  const dateVal = dayjs(date);

  if (dateVal.year() <= 1970) {
    return '-';
  }

  return dateVal.toLocaleString();
};

export const isFutureDate = (date: number) => {
  if (date) {
    return new Date().getTime() > date;
  }

  return false;
};

export const getDayDiff = (startDate: Date, endDate: Date) => {
  const msInDay = 24 * 60 * 60 * 1000;

  return Math.round(Math.abs(Number(endDate) - Number(startDate)) / msInDay);
};

export const getHoursDiff = (startDate: Date, endDate: Date) => {
  const msInDay = 60 * 60 * 1000;

  return Math.round(Math.abs(Number(endDate) - Number(startDate)) / msInDay);
};

export const toDuration = (startTime: Date, endTime: Date) => {
  const secondsPerDay = 86400;
  const secondsPerHour = 3600;
  const secondsPerMinute = 60;

  let duration = '';

  if (startTime !== null && endTime !== null) {
    const seconds = Math.floor(
      (endTime.getTime() - startTime.getTime()) / 1000,
    );
    const days = Math.floor(seconds / secondsPerDay);
    let remainingSeconds = seconds % secondsPerDay;
    const hours = Math.floor(remainingSeconds / secondsPerHour);
    remainingSeconds = remainingSeconds % secondsPerHour;
    const minutes = Math.floor(remainingSeconds / secondsPerMinute);
    remainingSeconds = remainingSeconds % secondsPerMinute;
    if (days > 0) {
      duration = `${duration}${days}d `;
    }
    if (hours > 0) {
      duration = `${duration}${hours}h `;
    }
    if (minutes > 0) {
      duration = `${duration}${minutes}m `;
    }
    if (remainingSeconds > 0) {
      duration = `${duration}${remainingSeconds}s `;
    }
  } else {
    return '-';
  }
  return duration.trim();
};

export const secondsToDuration = (
  seconds: number,
  includeMinutes: boolean,
  includeSeconds: boolean,
) => {
  const secondsPerDay = 86400;
  const secondsPerHour = 3600;
  const secondsPerMinute = 60;

  let duration = '';

  if (seconds !== null) {
    const days = Math.floor(seconds / secondsPerDay);
    let remainingSeconds = seconds % secondsPerDay;
    const hours = Math.floor(remainingSeconds / secondsPerHour);
    remainingSeconds = remainingSeconds % secondsPerHour;
    const minutes = Math.floor(remainingSeconds / secondsPerMinute);
    remainingSeconds = remainingSeconds % secondsPerMinute;
    if (days > 0) {
      duration = `${duration}${days}d `;
    }
    if (hours > 0) {
      duration = `${duration}${hours}h `;
    }
    if (minutes > 0 && includeMinutes) {
      duration = `${duration}${minutes}m `;
    }
    if (remainingSeconds > 0 && includeMinutes && includeSeconds) {
      duration = `${duration}${remainingSeconds}s `;
    }
  } else {
    return '-';
  }
  return duration.trim();
};

export const jsonDateToDate = (strDate: string): Date | null => {
  if (strDate === MIN_DATE_TIME_STRING) {
    return null;
  }
  const date = new Date(strDate);
  if (!date) {
    return null;
  }

  return dayjs(date).toDate();
};

export default {
  convertDateToMillisGMT,
  formatDate,
  formatTime,
  formatDateTime,
  isFutureDate,
  getDayDiff,
  getHoursDiff,
  jsonDateToDate,
};
