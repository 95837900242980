import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AlertOutlined, CheckCircleTwoTone } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import { Button, Card, Checkbox, Modal } from 'antd';

import DetailSpinner from '@totem/components/common/DetailSpinner';
import ModalTitle from '@totem/components/ModalTitle';
import PrimaryContentPane from '@totem/components/PrimaryContentPane';
import {
  GET_HEALTH_RISK_POLICY_ACKNOWLEDGMENT,
  UPDATE_HEALTH_RISK_POLICY_ACKNOWLEDGMENT,
} from '@totem/graph/healthrRiskAcknowledgment';
import colors from '@totem/styles/colors';
import fonts from '@totem/styles/fonts';
import {
  HealthRiskPolicyAcknowledgment,
  PolicyMediaType,
} from '@totem/types/healthRiskAcknowledgment';
import { getDashboardPath } from '@totem/utilities/authUtilities';

import ContentLayout from '../ContentLayout';

import './healthRiskAcknowledgmentDetail.css';

interface Data {
  healthRiskPolicyAcknowledgment: HealthRiskPolicyAcknowledgment;
}

const styles = {
  card: {
    width: '100%',
    marginTop: '3rem',
  },
  cardHeader: {
    borderTop: `5px solid`,
    borderImage: `${colors.gradient.blue} 100% 0 0 0`,
    fontSize: fonts.fontXl,
    fontWeight: 700,
  },
  cardBody: {
    padding: 0,
    borderTop: `1px solid ${colors.antd.borderGray}`,
  },
  icon: {
    fontSize: '4rem',
    color: colors.neutral.silver,
    marginBottom: '4rem',
  },
  iframeContainer: {
    position: 'relative',
    paddingBottom: '56.25%',
    paddingTop: '2.5rem',
    height: 0,
    marginBottom: '3rem',
  },
  iframe: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
};

const HealthRiskAcknowledgmentDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [agreed, setAgreed] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);

  const { loading, data } = useQuery<Data>(
    GET_HEALTH_RISK_POLICY_ACKNOWLEDGMENT,
    {
      variables: { id },
      onCompleted: (res) =>
        setAgreed(res?.healthRiskPolicyAcknowledgment?.agreed || false),
    },
  );

  const [updateHealthRiskAcknowledgment, { loading: loadingUpdate }] =
    useMutation(UPDATE_HEALTH_RISK_POLICY_ACKNOWLEDGMENT);

  if (loading || !data) {
    return (
      <div styleName="spinner-container">
        <DetailSpinner />
      </div>
    );
  }

  const { healthRiskPolicyAcknowledgment } = data;
  const { organization, agreement, agreeText, link, mediaType } =
    healthRiskPolicyAcknowledgment;

  const handleSubmit = async () => {
    await updateHealthRiskAcknowledgment({
      variables: {
        input: {
          id,
          agreed,
        },
      },
    });

    Modal.confirm({
      title: 'Acknowledgment Complete',
      content: `You can now take a Health Risk Survey for ${organization.name}. Download the Totem mobile application to get started or visit the "Your Work" section.`,
      cancelText: 'Go to Your Work',
      okText: 'Go to App Store',
      onCancel: () => navigate(getDashboardPath()),
      icon: <CheckCircleTwoTone twoToneColor={colors.brand.green} />,
    });
  };

  const handleReviewPolicy = () => {
    if (mediaType === PolicyMediaType.EmbeddedVideo) {
      return setOpen(true);
    }

    return window.open(link, '_blank');
  };

  return (
    <ContentLayout>
      <>
        <PrimaryContentPane>
          <Card
            title="Review Policy"
            headStyle={styles.cardHeader}
            bodyStyle={styles.cardBody}
          >
            <div styleName="policy-review-container">
              <AlertOutlined style={styles.icon} />
              <div styleName="policy-text">
                Before taking a Health Risk Survey {organization.name} asks that
                you acknowledge their Health Risk Policy. Click "Review Policy"
                to learn more about the company health risk policy and then
                return here to confirm that you understand.
              </div>
              <Button type="default" onClick={handleReviewPolicy}>
                Review Policy
              </Button>
            </div>
            <div styleName="certify-container">
              <div styleName="agreement-text">{agreement}</div>
              <div styleName="checkbox-container">
                <Checkbox
                  checked={agreed}
                  onChange={(event) => setAgreed(event.target.checked)}
                  disabled={healthRiskPolicyAcknowledgment.agreed}
                />
                <div styleName="agree-text">{agreeText}</div>
              </div>
              <Button
                type="primary"
                disabled={!agreed || healthRiskPolicyAcknowledgment.agreed}
                loading={loadingUpdate}
                onClick={handleSubmit}
              >
                Confirm
              </Button>
            </div>
          </Card>
        </PrimaryContentPane>
        <Modal
          title={<ModalTitle>Health Risk Policy</ModalTitle>}
          onCancel={() => setOpen(false)}
          okText="Done"
          onOk={() => setOpen(false)}
          open={open}
          width="50vw"
        >
          {open && (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <div style={{ width: '100%' }}>
                <div
                  // @ts-ignore
                  style={styles.iframeContainer}
                >
                  <iframe
                    // @ts-ignore
                    style={styles.iframe}
                    src={link}
                    allowFullScreen
                    frameBorder={0}
                  />
                </div>
              </div>
            </div>
          )}
        </Modal>
      </>
    </ContentLayout>
  );
};

export default HealthRiskAcknowledgmentDetail;
