import React, { useEffect, useRef, useState } from 'react';
import { Button, Checkbox, Dropdown, Input, MenuProps, theme } from 'antd';
const { Search } = Input;
import * as R from 'ramda';

import DetailSpinner from '@totem/components/common/DetailSpinner';
import colors from '@totem/styles/colors';
import {
  AutoCompleteFilter as AutoCompleteFilterType,
  AutoCompleteOptions,
  AutoCompleteSelected,
} from '@totem/types/hooks';

import './dropdownAutoCompleteFilter.css';

type Props = {
  label: string;
  style?: React.CSSProperties;
} & AutoCompleteFilterType;

const styles = {
  anchor: {
    position: 'relative' as any,
  },
  buttonSelected: {
    background: '#141238',
    color: colors.neutral.white,
    fontWeight: 700,
  },
  checkbox: {
    marginRight: '1rem',
  },
  input: {
    width: '100%',
  },
  menu: {
    width: '24rem',
  },
};

const getPreviouslySelected = (
  selected: AutoCompleteSelected,
  options: AutoCompleteOptions,
): AutoCompleteSelected => {
  // @ts-ignore
  return R.omit(R.intersection(R.keys(selected), R.keys(options)), selected);
};

const DropdownAutoCompleteFilter = (props: Props) => {
  const { useToken } = theme;
  const { token } = useToken();
  const { style, label, applied } = props;

  const anchor = useRef();
  const [visible, setVisible] = useState<boolean>(false);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.onSearch(event.target.value);
  };

  const handleOk = () => {
    if (props.loading) {
      return;
    }

    setVisible(false);
    props.onApply();
  };

  const handleReset = () => {
    if (props.loading) {
      return;
    }

    setVisible(false);
    props.onReset();
  };

  useEffect(() => {
    if (!visible) {
      props.onSearch('');
      props.onApply();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const numberSelected = Object.keys(applied).length;
  const previouslySelected = getPreviouslySelected(
    props.selected,
    props.options,
  );
  const numberPreviouslySelected = Object.keys(previouslySelected).length;
  const selectedKeys = Object.keys(props.selected);
  const numberOptions = Object.keys(props.options).length;

  const getMenuItems = () => {
    const menuItems: MenuProps['items'] = [];

    if (!props.loading) {
      const previousSelectedItems = Object.entries(previouslySelected).map(
        ([key, value]) => {
          return {
            key,
            label: (
              <div
                styleName="menu-item"
                onClick={() => props.onSelect(key, value)}
              >
                <Checkbox
                  disabled={props.loading}
                  style={styles.checkbox}
                  checked
                />
                <div styleName="menu-item-text">{value}</div>
              </div>
            ),
          };
        });

      menuItems.push(...previousSelectedItems);
    }

    if (Boolean(numberPreviouslySelected)) {
      menuItems.push({
        key: 'divider',
        type: 'divider',
      });
    }

    const optionItems = Object.entries(props.options).map(([key, value]) => {
      return {
        key,
        label: (
          <div styleName="menu-item" onClick={() => props.onSelect(key, value)}>
            <Checkbox
              style={styles.checkbox}
              checked={R.has(key, props.selected)}
            />
            <div styleName="menu-item-text">{value}</div>
          </div>
        ),
      };
    });

    menuItems.push(...optionItems);

    return menuItems;
  };

  const contentStyle: React.CSSProperties = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
    width: '24rem',
  };

  const menuStyle: React.CSSProperties = {
    boxShadow: 'none',
  };

  return (
    <Dropdown
      open={visible}
      onOpenChange={setVisible}
      trigger={['click']}
      getPopupContainer={() => anchor.current}
      menu={{
        items: getMenuItems(),
        selectedKeys,
        multiple: true,
        style: styles.menu,
      }}
      dropdownRender={menu => (
        <div style={contentStyle}>
          <div styleName="number-selected-container">
            {`${numberSelected} Selected`}
          </div>
          <div styleName="input-container">
            <Search
              value={props.search}
              onChange={handleSearch}
              loading={props.loadingSearch}
              style={styles.input}
              disabled={props.loading}
            />
          </div>
          {props.loading && (
            <div styleName="spinner-container">
              <DetailSpinner size={40} />
            </div>
          )}
          {React.cloneElement(menu as React.ReactElement, { style: menuStyle })}
          {!Boolean(numberOptions) && !props.loading && (
            <div styleName="dropdown-text">
              {props.search
                ? `Nothing was found for "${props.search}". Try searching something else.`
                : `Search ${label}`}
            </div>
          )}
          <div styleName="buttons">
            <a onClick={handleReset}>Reset</a>
            <a styleName="ok-button" onClick={handleOk}>
              OK
            </a>
          </div>
        </div>
      )}
    >
      <div ref={anchor} style={styles.anchor}>
        <Button
          style={{
            ...style,
            ...(numberSelected ? styles.buttonSelected : {}),
          }}
        >
          {numberSelected ? `${label} - ${numberSelected} items` : label}
        </Button>
      </div>
    </Dropdown>
  );
};

export default DropdownAutoCompleteFilter;
