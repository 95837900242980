export const isEven = (num: number): boolean => {
  return num % 2 === 0;
};

export const getExactPercentage = (total: number, portion: number): number => {
  if (!total || !portion) {
    return 0;
  }

  return (portion / total) * 100;
};

export const parseIntOrReturnValue = (val: any) => {
  return !Number.isNaN(Number(val)) ? Number(val) : val;
};

export default {
  isEven,
  getExactPercentage,
  parseIntOrReturnValue,
};
