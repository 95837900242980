import React, { useContext } from 'react';

import FilterChip from '@totem/components/common/FilterChip';
import { ControlSystemsConnectionInput } from '@totem/types/controlSystem';
import { controlSystemTypeMap } from '@totem/utilities/controlSystemsUtilities';

import ControlSystemBackupsContext from './ControlSystemBackupsContext';

const styles = {
  filterChip: {
    marginLeft: '2rem',
    marginTop: '2rem',
  },
};

const ControlSystemBackupFilterChips = () => {
  const { input, setInput, regionFilter, buildingFilter } = useContext(
    ControlSystemBackupsContext,
  );

  const { systemType } = input;

  const handleMultiFilterDelete = (
    property: keyof ControlSystemsConnectionInput,
    value: number,
  ) => {
    // @ts-ignore
    const updatedValue = input[property].filter(val => val !== value);
    setInput({
      ...input,
      [property]: updatedValue.length ? updatedValue : null,
    });
  };

  return (
    <>
      {systemType &&
        systemType.map(type => {
          const label = controlSystemTypeMap[type]?.text;

          return (
            <FilterChip
              key={type}
              label={`Control System Type: ${label}`}
              style={styles.filterChip}
              onClose={() => handleMultiFilterDelete('systemType', type)}
            />
          );
        })}
      {!buildingFilter.loading &&
        Object.entries(buildingFilter.applied).map(([id, name]) => (
          <FilterChip
            key={id}
            label={`Building: ${name}`}
            style={styles.filterChip}
            onClose={() => buildingFilter.onRemove(id)}
          />
        ))}
      {!regionFilter.loading &&
        Object.entries(regionFilter.applied).map(([id, name]) => (
          <FilterChip
            key={id}
            label={`Region: ${name}`}
            style={styles.filterChip}
            onClose={() => regionFilter.onRemove(id)}
          />
        ))}
    </>
  );
};

export default ControlSystemBackupFilterChips;
