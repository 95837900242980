import React, { useCallback, useState } from 'react';
import { ConfigProvider, Table as AntdTable } from 'antd';
import * as AntdTableInterface from 'antd/es/table';

import { isEven } from '@totem/utilities/numberUtilities';

export interface TableProps<T extends object>
  extends AntdTableInterface.TableProps<T> {}

const styles = {
  container: {
    position: 'relative',
  },
};

function Table<T extends object>(props: TableProps<T>) {
  const {
    dataSource,
    columns,
    pagination,
    loading,
    onChange,
    rowClassName = '',
  } = props;

  const [container, setContainer] = useState<HTMLDivElement>(null);

  const ref = useCallback(node => {
    setContainer(node);
  }, []);

  const getPopupContainer = useCallback(() => {
    return container || document.body;
  }, [container]);

  const getRowClassName = useCallback(
    (record: T, index: number, indent: number) => {
      const className = isEven(index)
        ? 'totem-table-row-even'
        : 'totem-table-row-odd';

      const additionalClassName =
        typeof rowClassName === 'string'
          ? rowClassName
          : rowClassName(record, index, indent);

      return `${className} ${additionalClassName}`;
    },
    [rowClassName],
  );

  return (
    <ConfigProvider getPopupContainer={getPopupContainer}>
      <div ref={ref} style={styles.container as any}>
        {container && (
          <AntdTable
            {...props}
            bordered
            columns={columns}
            className="totem-table"
            size="small"
            scroll={{ x: '100%' }}
            rowClassName={getRowClassName}
            loading={loading}
            dataSource={dataSource}
            pagination={pagination}
            onChange={onChange}
          />
        )}
      </div>
    </ConfigProvider>
  )
}

export default Table;
