import React from 'react';

import Count from '@totem/components/common/Count';
import { Criticality } from '@totem/types/criticality';
import { Finding, FindingGroup } from '@totem/types/finding';
import { criticalityMap } from '@totem/utilities/criticalityUtilities';
import { getExactPercentage } from '@totem/utilities/numberUtilities';

import './findings.css';

interface Props {
  findings: Finding[];
}

const INCLUDED_GROUPS = [
  FindingGroup['System Users'],
  FindingGroup['System Configuration'],
  FindingGroup.Network,
  FindingGroup.Continuity,
  FindingGroup['Control System Host'],
];

const INCLUDED_CRITICALITIES = [
  Criticality.Critical,
  Criticality.High,
  Criticality.Medium,
  Criticality.Low,
  Criticality.Compliant,
];

const getIncludedFindings = (findings: Finding[]) =>
  findings.filter(({ criticality }) => criticality !== Criticality.Info);

const getFindingsByGroup = (findings: Finding[], group: FindingGroup) =>
  findings.filter((finding) => finding.group === group);

const getFindingsByCriticality = (
  findings: Finding[],
  criticality: Criticality,
) => findings.filter((finding) => finding.criticality === criticality);

const GroupFindings: React.FC<Props> = ({ findings }: Props) => {
  const includedFindings = getIncludedFindings(findings);

  return (
    <div styleName="findings-container">
      <div styleName="finding-header-container">
        <div styleName="finding-header">All Findings Breakdown</div>
        <Count count={includedFindings.length} size="large" />
      </div>
      <div>
        {INCLUDED_GROUPS.map(group => {
          const name = FindingGroup[group];
          const findingsWithGroup = getFindingsByGroup(includedFindings, group);

          return (
            <div styleName="findings" key={name}>
              <div styleName="finding-label group">{name}</div>
              <div styleName="finding-bar-container">
                <div styleName="finding-bar">
                  {INCLUDED_CRITICALITIES.map(criticality => {
                    const option = criticalityMap[criticality];

                    const groupFindingsWithCriticality = getFindingsByCriticality(
                      findingsWithGroup,
                      criticality,
                    );

                    const percentage = getExactPercentage(
                      findingsWithGroup.length,
                      groupFindingsWithCriticality.length,
                    );

                    return (
                      <div
                        key={criticality}
                        style={{
                          width: `${percentage}%`,
                          background: option.color,
                          height: '100%',
                        }}
                      />
                    );
                  })}
                </div>
              </div>
              <Count count={findingsWithGroup.length} size="small" />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default GroupFindings;
