import React, { useContext } from 'react';
import { DeleteOutlined } from '@ant-design/icons';

import SurveyTemplateContext from '@totem/components/surveyV2/templateDetails/SurveyTemplateContext';
import { Condition } from '@totem/components/surveyV2/types';
import { getConditionDescription } from '@totem/utilities/surveyUtilities';

type Props = {
  condition: Condition;
  onRemoveRule: (item: Condition) => void;
};
const SurveyTemplateConditionRule = ({ condition, onRemoveRule }: Props) => {
  const { data } = useContext(SurveyTemplateContext);
  const rule = getConditionDescription(data, condition, 0);
  const ruleDeleteHandler = () => {
    onRemoveRule(condition);
  };

  return (
    <div>
      {rule}
      {'  '}
      <DeleteOutlined title="Delete Rule" onClick={ruleDeleteHandler} />
    </div>
  );
};

export default SurveyTemplateConditionRule;
