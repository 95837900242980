import React, { useState } from 'react';
import { CloseCircleFilled } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Form, Input, Modal } from 'antd';
import dayjs from 'dayjs';
import * as R from 'ramda';

import AssignedContentCollapse from '@totem/components/common/assignedContentCollapse/AssignedContentCollapse';
import { DELETE_CONTROL_SYSTEM } from '@totem/graph/controlSystem';
import { useErrorNotification } from '@totem/hooks/useErrorNotification';
import colors from '@totem/styles/colors';
import { ControlSystem } from '@totem/types/controlSystem';
import { controlSystemBackupSourceLabels } from '@totem/types/controlSystemBackup';

import DetailSpinner from '../DetailSpinner';

import './deleteControlSystemModal.css';

const FormItem = Form.Item;

const styles = {
  form: {
    width: '100%',
  },
  errorIcon: {
    color: colors.utility.error,
  },
  details: {
    fontSize: '1.2rem',
  },
};

export interface Props {
  open: boolean;
  setOpen: (data: boolean) => void;
  controlSystem: ControlSystem;
  loading?: boolean;
  onComplete?: Function;
}

const DeleteControlSystemModal = ({
  open,
  setOpen,
  controlSystem,
  loading,
  onComplete,
}: Props) => {
  const [confirm, setConfirm] = useState<string>('');

  const [deleteControlSystem, { loading: loadingDelete, error }] = useMutation(
    DELETE_CONTROL_SYSTEM,
  );

  useErrorNotification(error, 'Error Deleting Control System');

  const onCancel = () => {
    setOpen(false);
    setConfirm('');
  };

  const onOk = async () => {
    await deleteControlSystem({
      variables: {
        id: controlSystem.id,
      },
    });

    setConfirm('');
    setOpen(false);

    if (onComplete) {
      onComplete();
    }
  };

  function lastUpdated<T>(group: T[], dateProp: string): T | null {
    if (R.isNil(group) || !group.length) {
      return null;
    }

    return group.reduce((acc, next) => {
      if (new Date(acc[dateProp]) > new Date(next[dateProp])) {
        return acc;
      }
      return next;
    });
  }

  const assignedContents = [
    {
      disabled: !controlSystem.policyAudit?.completedQuestions,
      header: `${
        controlSystem.policyAudit?.completedQuestions || 0
      } Audit Responses`,
      subheader: 'Audit Details',
      details: (
        <div style={styles.details}>
          <div>
            Last Updated:{' '}
            {dayjs(controlSystem.policyAudit?.updatedAt).format('MM/DD/YYYY')}
          </div>
          <div>
            Completed Responses: {controlSystem.policyAudit?.completedQuestions}
          </div>
          <div>Assigned to: {controlSystem.policyAudit?.assignee?.email}</div>
        </div>
      ),
    },
    {
      disabled: !controlSystem.backups?.totalCount,
      header: `${controlSystem.backups?.totalCount} Backups`,
      subheader: 'Backup Details',
      details: (
        <div style={styles.details}>
          <div>
            Last backup:{' '}
            {dayjs(
              lastUpdated(
                controlSystem.backups?.controlSystemBackups,
                'updatedAt',
              )?.updatedAt,
            ).format('MM/DD/YYYY')}
          </div>
          <div>
            Source:{' '}
            {
              controlSystemBackupSourceLabels[
                lastUpdated(
                  controlSystem.backups?.controlSystemBackups,
                  'updatedAt',
                )?.source
              ]
            }
          </div>
        </div>
      ),
    },
    {
      disabled: !controlSystem.notes?.totalCount,
      header: `${controlSystem.notes?.totalCount} Notes`,
      subheader: lastUpdated(controlSystem.notes?.notes, 'updatedAt')?.title,
      details: (
        <div style={styles.details}>
          <div>
            Latest Edit:{' '}
            {dayjs(
              lastUpdated(controlSystem.notes?.notes, 'updatedAt')?.updatedAt,
            ).format('MM/DD/YYYY')}
          </div>
          <div>
            Edited by:{' '}
            {
              lastUpdated(controlSystem.notes?.notes, 'updatedAt')?.updatedBy
                ?.email
            }
          </div>
        </div>
      ),
    },
  ];

  const isDeleteDisabled = confirm !== 'DELETE';

  return (
    <Modal
      title={
        <div>
          <CloseCircleFilled style={styles.errorIcon} />
          <span styleName="title">Delete Control System</span>
        </div>
      }
      open={open}
      footer={
        <div styleName="footer">
          <Button onClick={onCancel}>Cancel</Button>
          <Button
            type="primary"
            danger
            loading={loadingDelete}
            disabled={isDeleteDisabled}
            onClick={onOk}
          >
            Yes, Delete
          </Button>
        </div>
      }
      onCancel={onCancel}
      /*
       * used for the purposes of unmounting the AssignedContentCollapse
       * component to render collapse panel inactive onClose
       */
      destroyOnClose
    >
      {loading ? (
        <div styleName="spinner-container">
          <DetailSpinner color={colors.utility.error} />
        </div>
      ) : (
        <>
          <div styleName="description">
            Deleting this control system will remove all audit responses,
            backups, and notes attached to the system.
          </div>
          <div styleName="collapse-title">
            This content will be removed with the control system:
          </div>
          <AssignedContentCollapse assignedContents={assignedContents} />
          <Form layout="vertical" style={styles.form}>
            <FormItem
              label={
                <span>
                  Type <strong>"DELETE"</strong> to confirm
                </span>
              }
              colon={false}
            >
              <Input
                value={confirm}
                onChange={(event) => setConfirm(event.target.value)}
              />
            </FormItem>
          </Form>
        </>
      )}
    </Modal>
  );
};

export default DeleteControlSystemModal;
