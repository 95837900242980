import React, { useContext, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Button, Form, Input, Modal, Select } from 'antd';
import * as R from 'ramda';

import BuildingAutoComplete from '@totem/components/common/autoCompletes/BuildingAutoComplete';
import ModalFooter from '@totem/components/ModalFooter';
import ModalTitle from '@totem/components/ModalTitle';
import { CREATE_CONTROL_SYSTEM } from '@totem/graph/controlSystem';
import { Building } from '@totem/types/building';
import {
  ControlSystem,
  ControlSystemCreateInput,
  ControlSystemType,
} from '@totem/types/controlSystem';
import {
  controlSystemTypeMap,
  controlSystemTypeOptions,
} from '@totem/utilities/controlSystemsUtilities';

import ControlSystemsContext from './ControlSystemsContext';

const FormItem = Form.Item;
const { Option } = Select;

interface FormErrors {
  building?: string;
  name?: string;
  systemType?: string;
  email?: string;
}

interface Props {
  open: boolean;
  onClose: () => void;
}

const initialState = {
  input: {
    regionId: '',
    buildingId: '',
    name: '',
    systemType: 0,
  },
};

const styles = {
  form: {
    width: '100%',
  },
  formItem: {
    paddingBottom: '0',
    marginBottom: '1rem',
    width: '100%',
  },
  checkbox: {
    fontSize: '12px',
    padding: '1rem 0',
  },
  datePicker: {
    width: '100%',
  },
};

const ControlSystemCreateModal = ({ open, onClose }: Props) => {
  const { refetch } = useContext(ControlSystemsContext);

  const [errors, setErrors] = useState<FormErrors>({});
  const [buildingSearch, setBuildingSearch] = useState<string>('');
  const [input, setInput] = useState<ControlSystemCreateInput>(
    initialState.input,
  );

  const [createControlSystem, { loading }] = useMutation<
    { controlSystemCreate: ControlSystem },
    { input: ControlSystemCreateInput }
  >(CREATE_CONTROL_SYSTEM);

  useEffect(() => {
    if (!open) {
      setErrors({});
      setBuildingSearch('');
      setInput(initialState.input);
    }
  }, [open]);

  const isValidForm = (): boolean => {
    const formErrors: FormErrors = {};

    if (R.isEmpty(input.buildingId)) {
      errors.building = 'Please select a building';
    }

    if (!input.name) {
      formErrors.name = 'Please enter a name';
    }

    if (R.isNil(input.systemType)) {
      formErrors.systemType = 'Please select a control system type';
    }

    setErrors(formErrors);
    return R.isEmpty(formErrors);
  };

  const handleChange = ({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setInput({ ...input, [name]: value });
  };

  const handleTypeChange = (systemType: ControlSystemType) => {
    const type = controlSystemTypeMap[systemType];
    setInput({ ...input, name: type.text, systemType });
  };

  const handleBuildingChange = (building: Building) => {
    setInput({
      ...input,
      buildingId: building.id,
      regionId: building.region.id,
    });
  };

  const handleSubmit = async () => {
    if (!isValidForm()) {
      return;
    }

    await createControlSystem({
      variables: { input },
    });

    onClose();
    refetch();
  };

  return (
    <Modal
      open={open}
      onCancel={onClose}
      title={<ModalTitle>Add a Control System</ModalTitle>}
      footer={
        <ModalFooter>
          <Button onClick={onClose}>Cancel</Button>
          <Button type="primary" loading={loading} onClick={handleSubmit}>
            Add Now
          </Button>
        </ModalFooter>
      }
      confirmLoading={loading}
    >
      <Form layout="vertical" style={styles.form}>
        <FormItem
          label="Building"
          colon={false}
          validateStatus={R.isNil(errors.building) ? 'success' : 'error'}
          help={R.isNil(errors.building) ? null : errors.building}
          style={styles.formItem}
        >
          <BuildingAutoComplete
            value={buildingSearch}
            onChange={val => setBuildingSearch(val)}
            onSelect={handleBuildingChange}
          />
        </FormItem>
        <FormItem
          label="System Type"
          validateStatus={!errors.systemType ? 'success' : 'error'}
          help={!errors.systemType ? null : errors.systemType}
          colon={false}
          style={styles.formItem}
        >
          <Select
            showSearch
            filterOption
            onChange={handleTypeChange}
            value={input.systemType}
            optionFilterProp="children"
            disabled={loading}
          >
            {controlSystemTypeOptions.map(option => {
              return (
                <Option key={option.value} value={option.value}>
                  {option.text}
                </Option>
              );
            })}
          </Select>
        </FormItem>
        <FormItem
          label="System Name"
          colon={false}
          validateStatus={!errors.name ? 'success' : 'error'}
          help={!errors.name ? null : errors.name}
          style={styles.formItem}
        >
          <Input
            disabled={loading}
            value={input.name}
            name="name"
            onChange={handleChange}
            type="search"
          />
        </FormItem>
        <FormItem label="Manufacturer" colon={false} style={styles.formItem}>
          <Input
            disabled={loading}
            value={input.manufacturer}
            name="manufacturer"
            onChange={handleChange}
            type="search"
          />
        </FormItem>
        <FormItem label="Model" colon={false} style={styles.formItem}>
          <Input
            disabled={loading}
            value={input.model}
            name="model"
            onChange={handleChange}
            type="search"
          />
        </FormItem>
      </Form>
    </Modal>
  );
};

export default ControlSystemCreateModal;
