import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Button, Modal } from 'antd';
import * as R from 'ramda';

import { UPDATE_HEALTH_RISK_SURVEY } from '@totem/graph/healthRiskSurvey';
import {
  HealthRiskSurvey,
  HealthRiskSurveyErrors,
} from '@totem/types/healthRiskSurvey';
import { validateHealthRiskSurvey } from '@totem/utilities/healthRiskSurveyUtilities';

interface Props {
  survey: HealthRiskSurvey;
  loading: boolean;
  onError: (errors: HealthRiskSurveyErrors) => void;
}

const HealthRiskSurveyDetailSubmit = ({ survey, loading, onError }: Props) => {
  const { id, isSubmitted } = survey;
  const [visible, setVisible] = useState<boolean>(false);

  const [updateHealthRiskSurvey, { loading: loadingUpdate }] = useMutation(
    UPDATE_HEALTH_RISK_SURVEY,
  );

  useEffect(() => {
    if (!loadingUpdate) {
      setVisible(false);
    }
  }, [loadingUpdate]);

  const handleClick = () => {
    const errors = validateHealthRiskSurvey(survey);

    if (R.isEmpty(errors)) {
      setVisible(true);
    } else {
      onError(errors);
    }
  };

  const handleSubmit = () => {
    updateHealthRiskSurvey({
      variables: {
        input: { id, isSubmitted: true },
      },
    });
  };

  return (
    <>
      <Button type="primary" disabled={isSubmitted} onClick={handleClick}>
        Submit Survey
      </Button>
      <Modal
        open={visible}
        confirmLoading={loading || loadingUpdate}
        onOk={handleSubmit}
        onCancel={() => setVisible(false)}
        title="Ready to Submit"
        okText="Yes"
        cancelText="No"
      >
        Are you sure you are ready to submit the survey?
      </Modal>
    </>
  );
};

export default HealthRiskSurveyDetailSubmit;
