import React from 'react';

import Count from '@totem/components/common/Count';
import { Criticality } from '@totem/types/criticality';
import {
  FindingCriticalityCounts,
  FindingGroupCounts,
} from '@totem/types/finding';
import { criticalityMap } from '@totem/utilities/criticalityUtilities';
import { getExactPercentage } from '@totem/utilities/numberUtilities';
import { toTitleCase } from '@totem/utilities/stringUtilities';

import './findings.css';

interface Props {
  counts: FindingGroupCounts;
}

const getTotalCriticalityCounts = ({
  critical,
  high,
  medium,
  low,
  compliant,
}: FindingCriticalityCounts): number => {
  return critical + high + medium + low + compliant;
};

const getTotalGroupCounts = ({
  systemUsers,
  systemConfiguration,
  network,
  continuity,
  controlSystemHost,
}: FindingGroupCounts) => {
  return (
    getTotalCriticalityCounts(systemUsers) +
    getTotalCriticalityCounts(systemConfiguration) +
    getTotalCriticalityCounts(network) +
    getTotalCriticalityCounts(continuity) +
    getTotalCriticalityCounts(controlSystemHost)
  );
};

const renderBar = (counts: FindingCriticalityCounts) => {
  const total = getTotalCriticalityCounts(counts);

  return (
    <>
      <div
        style={{
          width: `${getExactPercentage(total, counts.critical)}%`,
          background: criticalityMap[Criticality.Critical].color,
          height: '100%',
        }}
      />
      <div
        style={{
          width: `${getExactPercentage(total, counts.high)}%`,
          background: criticalityMap[Criticality.High].color,
          height: '100%',
        }}
      />
      <div
        style={{
          width: `${getExactPercentage(total, counts.medium)}%`,
          background: criticalityMap[Criticality.Medium].color,
          height: '100%',
        }}
      />
      <div
        style={{
          width: `${getExactPercentage(total, counts.low)}%`,
          background: criticalityMap[Criticality.Low].color,
          height: '100%',
        }}
      />
      <div
        style={{
          width: `${getExactPercentage(total, counts.compliant)}%`,
          background: criticalityMap[Criticality.Compliant].color,
          height: '100%',
        }}
      />
    </>
  );
};

const renderGroup = (group: string, counts: FindingGroupCounts) => {
  const criticalityCounts: FindingCriticalityCounts = counts[group];

  return (
    <div styleName="findings">
      <div styleName="finding-label group">{toTitleCase(group)}</div>
      <div styleName="finding-bar-container">
        <div styleName="finding-bar">{renderBar(criticalityCounts)}</div>
      </div>
      <Count
        count={getTotalCriticalityCounts(criticalityCounts)}
        size="small"
      />
    </div>
  );
};

const FindingsGroupCounts = ({ counts }: Props) => {
  return (
    <div styleName="findings-container">
      <div styleName="finding-header-container">
        <div styleName="finding-header">All Findings Breakdown</div>
        <Count count={getTotalGroupCounts(counts)} size="large" />
      </div>
      <div>{renderGroup('systemUsers', counts)}</div>
      <div>{renderGroup('systemConfiguration', counts)}</div>
      <div>{renderGroup('network', counts)}</div>
      <div>{renderGroup('continuity', counts)}</div>
      <div>{renderGroup('controlSystemHost', counts)}</div>
    </div>
  );
};

export default FindingsGroupCounts;
