import { createContext } from 'react';

import { Tenants } from '@totem/components/tenants/types';

interface Context {
  membershipLoading: boolean;
  membership: Tenants;
}

export default createContext<Context>({
  membershipLoading: false,
  membership: null,
});
