import React from 'react';
import { Badge, Card, Divider } from 'antd';

import colors from '@totem/styles/colors';
import { Nmap, NmapDevice } from '@totem/types/nmap';

import './nmapDetail.css';

interface Props {
  nmap: Nmap;
  flaggedDevices: NmapDevice[];
  portFindingsCount: number;
  osFindingsCount: number;
  manufacturerFindingsCount: number;
  otherFindingsCount: number;
}

const styles = {
  divider: {
    height: '6rem',
  },
  badgeColor: colors.finding,
};

const FindingsOverview = ({
  portFindingsCount,
  osFindingsCount,
  manufacturerFindingsCount,
  otherFindingsCount,
}: Props) => {
  return (
    <Card
      title={
        <div styleName="overview-header">
          <div>Device Findings</div>
        </div>
      }
      bordered={false}
    >
      <div styleName="overview-card-body">
        <div styleName="overview-card">
          <div styleName="overview-title">Port Issues</div>
          <div styleName="stat">
            <span styleName="findings-badge-container">
              <Badge
                color={styles.badgeColor}
                className="health-status-badge-lg"
              />
              {portFindingsCount}
            </span>
          </div>
        </div>
        <Divider type="vertical" style={styles.divider} />
        <div styleName="overview-card">
          <div styleName="overview-title">OS Issues</div>
          <div styleName="stat">
            <span styleName="findings-badge-container">
              <Badge
                color={styles.badgeColor}
                className="health-status-badge-lg"
              />
              {osFindingsCount}
            </span>
          </div>
        </div>
        <Divider type="vertical" style={styles.divider} />
        <div styleName="overview-card">
          <div styleName="overview-title">Manufacturer Issues</div>
          <div styleName="stat">
            <span styleName="findings-badge-container">
              <Badge
                color={styles.badgeColor}
                className="health-status-badge-lg"
              />
              {manufacturerFindingsCount}
            </span>
          </div>
        </div>
        <Divider type="vertical" style={styles.divider} />
        <div styleName="overview-card">
          <div styleName="overview-title">Other Issues</div>
          <div styleName="stat">
            <span styleName="findings-badge-container">
              <Badge
                color={styles.badgeColor}
                className="health-status-badge-lg"
              />
              {otherFindingsCount}
            </span>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default FindingsOverview;
