export const getDefaultEndDate = () => {
  const endDate = new Date();
  return endDate;
};

export const getDefaultStartDate = () => {
  const startDate = getDefaultEndDate();
  startDate.setDate(startDate.getDate() - 30);
  return startDate;
};
