import {
  TenantsFilter,
  TenantsInput,
} from '@totem/components/tenants/tenantsPage/types';
import { Filter } from '@totem/types/common';
import { isNotNull } from '@totem/utilities/common';

export const buildTenantsFilters = (input: TenantsInput) => {
  const paramFilters: Filter[] = [];

  if (isNotNull(input.searchTerm) && input.searchTerm !== '') {
    paramFilters.push({
      field: 'name',
      operator: 'cscontains',
      values: [input.searchTerm],
    });
  }

  return paramFilters;
};

export const addTenantsFilters = (
  currentFilters: Filter[],
  input: TenantsFilter,
) => {
  if (isNotNull(input)) {
    if (isNotNull(input.searchTerm) && input.searchTerm !== '') {
      currentFilters.push({
        field: 'name',
        operator: 'cscontains',
        values: [input.searchTerm],
      });
    }

    if (isNotNull(input.buildingId) && input.buildingId !== '') {
      currentFilters.push({
        field: 'buildings.id',
        values: [input.buildingId],
      });
    }
  }

  return currentFilters;
};
