import React, { useContext, useEffect, useState } from 'react';
import { Tabs, TabsProps } from 'antd';
import dayjs from 'dayjs';

import PackageContext from '@totem/components/software/software_package/packageContext';
import VersionContent from '@totem/components/software/software_package/version/VersionContent';
import { SoftwareVersion } from '@totem/components/software/software_report/types';
import TabTitle from '@totem/components/TabTitle';
import colors from '@totem/styles/colors';
import { isNotNull } from '@totem/utilities/common';
import { sortVersionNumbersAscending } from '@totem/utilities/tableUtilities';

const PackageVersions = () => {
  const { data, setSelectedVersion } = useContext(PackageContext);
  const [sortedVersions, setSortedVersions] = useState<SoftwareVersion[]>([]);
  const [activeTabKey, setActiveTabKey] = useState<string>('');

  useEffect(() => {
    const sortedVersionsWorking = isNotNull(data)
      ? data.package.versions.sort((compA, compB) =>
          sortVersionNumbersAscending(compA.number, compB.number),
        )
      : [];
    setSortedVersions(sortedVersionsWorking);

    if (activeTabKey === '') {
      const activeKey =
        sortedVersionsWorking.length > 0 ? sortedVersionsWorking[0].number : '';
      setActiveTabKey(activeKey);
      setSelectedVersion(activeKey);
    }
  }, [data]);

  const getEndOfSupportIndicator = (version: SoftwareVersion) => {
    if (isNotNull(version) && isNotNull(version.endOfSupport)) {
      if (version.endOfSupport.isEndOfSupport) {
        return (
          <span
            style={{ color: colors.criticality.critical }}
            title="Past End-of-Support"
          >
            &nbsp;(EOS)
          </span>
        );
      }
      if (
        isNotNull(version.endOfSupport.endOfSupportDate) &&
        version.endOfSupport.endOfSupportDate !== ''
      ) {
        const eosDate = dayjs(version.endOfSupport.endOfSupportDate);
        if (eosDate.year() > 2000) {
          // eslint-disable-next-line max-depth
          if (eosDate.isBefore(dayjs())) {
            return (
              <span
                style={{ color: colors.event.criticality.background.critical }}
                title="Past End-of-Support"
              >
                &nbsp;(EOS)
              </span>
            );
          }
          // eslint-disable-next-line max-depth
          if (eosDate.isBefore(dayjs().add(12, 'month'))) {
            return (
              <span
                style={{ color: colors.event.criticality.background.minor }}
                title="Near End-of-Support"
              >
                &nbsp;(EOS)
              </span>
            );
          }
        }
      }
    }

    return <span />;
  };

  const getTabItems = () => {
    if (isNotNull(data)) {
      const tabItems: TabsProps['items'] = sortedVersions.map((version) => {
        return {
          key: version.number,
          label: (
            <TabTitle>
              {version.number}
              {getEndOfSupportIndicator(version)}
            </TabTitle>
          ),
          children: <VersionContent />,
        };
      });
      return tabItems;
    }

    const tabItems: TabsProps['items'] = [];
    return tabItems;
  };

  return (
    <Tabs
      items={getTabItems()}
      activeKey={activeTabKey}
      tabPosition={'left'}
      onChange={(version) => {
        setActiveTabKey(version);
        setSelectedVersion(version);
      }}
    />
  );
};

export default PackageVersions;
