import React, { useContext } from 'react';

import ComponentHeader from '@totem/components/ComponentHeader';
import EventTotalsContext from '@totem/components/events/totals/EventTotalsContext';
import TotalsContainer from '@totem/components/events/totals/TotalsContainer';
import PageSection from '@totem/components/PageSection';
import PageSectionBody from '@totem/components/PageSectionBody';

const ThreatManagementSection = () => {
  const { data } = useContext(EventTotalsContext);
  const hasThreatManagement =
    typeof data !== 'undefined' &&
    data !== null &&
    typeof data.threatManagementLast30Days !== 'undefined' &&
    data.threatManagementLast30Days !== null;
  const showSection = hasThreatManagement;

  return (
    <>
      {showSection && (
        <PageSection>
          <ComponentHeader title={'Threat Management - Last 30 days'} />
          <PageSectionBody>
            <TotalsContainer />
          </PageSectionBody>
        </PageSection>
      )}
    </>
  );
};

export default ThreatManagementSection;
