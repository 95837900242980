import React, { ReactNode, useEffect, useState } from 'react';

import SummaryContext from '@totem/components/portfolio/summary/summaryContext';
import {
  Region,
  SummaryInput,
} from '@totem/components/portfolio/summary/types';
import { buildParameters } from '@totem/components/portfolio/summary/utilities';
import { getToken } from '@totem/utilities/accountUtilities';
import { isNull } from '@totem/utilities/common';
import { ORGANIZATION_ENDPOINT } from '@totem/utilities/endpoints';
import { omitNilOrEmpty } from '@totem/utilities/objectUtilities';

type Props = {
  children?: ReactNode;
};

const SummaryContainer = ({ children }: Props) => {
  const [input, updateInput] = useState<SummaryInput>({
    regionId: [],
    buildingId: [],
  });
  const [refreshData, setRefreshData] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [data, setData] = useState<Region[]>([]);
  const [filteredRegions, setFilteredRegions] = useState<Region[]>([]);
  const [action, setAction] = useState<string>('');
  const [selectedRegionIds, setSelectedRegionIds] = useState<string[]>([]);
  const [selectedBuildingIds, setSelectedBuildingIds] = useState<string[]>([]);

  const setInput = (updated: Partial<SummaryInput>) => {
    updateInput(omitNilOrEmpty({ ...input, ...updated }));
    setRefreshData(true);
  };

  useEffect(() => {
    if (isNull(selectedRegionIds) || selectedRegionIds.length === 0) {
      setFilteredRegions(data);
      return;
    }

    const filteredObjects = data.filter(
      (chk) => selectedRegionIds.findIndex((val) => val === chk.id) >= 0,
    );
    setFilteredRegions(filteredObjects);
  }, [data, selectedRegionIds]);

  useEffect(() => {
    if (refreshData) {
      setRefreshData(false);
      setIsLoading(true);

      const uri = `${ORGANIZATION_ENDPOINT}/regionBuildingSummary`;

      const payload = buildParameters(input);

      fetch(uri, {
        method: 'POST',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
        body: JSON.stringify(payload),
      })
        .then((res) => res.json())
        .then((result: Region[]) => {
          setData(result);
        })
        .then(() => {
          setIsLoading(false);
        });
    }
  }, [refreshData]);

  const handleAction = (newAction: string) => {
    switch (newAction) {
      case 'refresh':
        setRefreshData(true);
        setAction('');
        break;
      default:
        setAction(newAction);
    }
  };

  return (
    <SummaryContext.Provider
      value={{
        input,
        setInput,
        loading: isLoading,
        data,
        filteredRegions,
        action,
        onAction: handleAction,
        selectedRegionIds,
        selectedBuildingIds,
        setSelectedRegionIds,
        setSelectedBuildingIds,
      }}
    >
      <div>{children}</div>
    </SummaryContext.Provider>
  );
};

export default SummaryContainer;
