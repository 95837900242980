import React from 'react';
import { Flex } from 'antd';

import Title from '@totem/components/surveyV2/policies/Title';
import { SurveyQuestion } from '@totem/components/surveyV2/types';
import { LevelOfEffort } from '@totem/types/levelOfEffort';

import '../survey.css';

type Props = {
  question: SurveyQuestion;
};

const styles = {
  attributeTitle: {
    fontWeight: 400,
    fontSize: 14,
  },
  attributeValue: {
    fontWeight: 700,
  },
};

const QuestionDetail = ({ question }: Props) => {
  return (
    <div styleName="policy-question">
      <Flex justify={'flex-start'} align={'flex-start'} gap={32}>
        <div>
          <span style={styles.attributeTitle}>Nist Function</span>
          <br />
          <span style={styles.attributeValue}>{question.nistFunction}</span>
        </div>
        <div>
          <span style={styles.attributeTitle}>Level of Effort</span>
          <br />
          <span style={styles.attributeValue}>
            {LevelOfEffort[question.levelOfEffort]}
          </span>
        </div>
      </Flex>
      <br />
      <br />
      <Title>Policy:</Title>
      {question.policy}
      <br />
      <br />
      <Title>Resolution:</Title>
      {question.resolution}
    </div>
  );
};

export default QuestionDetail;
