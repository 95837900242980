import React, { useCallback, useContext } from 'react';
import { LeftSquareOutlined, RightSquareOutlined } from '@ant-design/icons';
import { Card, Modal, Tabs, TabsProps } from 'antd';
import { v4 as uuidv4 } from 'uuid';

import SurveyTemplateCategory from '@totem/components/surveyV2/templateDetails/SurveyTemplateCategory';
import SurveyTemplateContext from '@totem/components/surveyV2/templateDetails/SurveyTemplateContext';
import { Category, SurveyTemplate } from '@totem/components/surveyV2/types';
import TabTitle from '@totem/components/TabTitle';
import { getToken } from '@totem/utilities/accountUtilities';
import { V2_SURVEY_ENDPOINT } from '@totem/utilities/endpoints';
import { sortNumberAscending } from '@totem/utilities/tableUtilities';

const Styles = {
  RightIcon: {
    marginLeft: '12px',
  },
};

const SurveyTemplateCategories = () => {
  const { surveyTemplateId, data, setData, setIsLoading } = useContext(
    SurveyTemplateContext,
  );

  const sendCategoryCreate = useCallback(
    async (templateId: string, categoryId: string) => {
      fetch(
        `${V2_SURVEY_ENDPOINT}/template/${templateId}/categories/${categoryId}`,
        {
          method: 'PUT',
          headers: new Headers({
            Authorization: `Bearer ${getToken()}`,
          }),
        },
      )
        .then((res) => res.json())
        .then((result: SurveyTemplate) => {
          setData(result);
        })
        .then(() => {
          setIsLoading(false);
        });
    },
    [],
  );

  const sendCategoryDelete = useCallback(
    async (templateId: string, categoryId: string) => {
      fetch(
        `${V2_SURVEY_ENDPOINT}/template/${templateId}/categories/${categoryId}`,
        {
          method: 'DELETE',
          headers: new Headers({
            Authorization: `Bearer ${getToken()}`,
          }),
        },
      )
        .then((res) => res.json())
        .then((result: SurveyTemplate) => {
          setData(result);
        })
        .then(() => {
          setIsLoading(false);
        });
    },
    [],
  );

  const sendCategoryReorder = useCallback(
    async (
      templateId: string,
      currentCategory: Category,
      swapCategory: Category,
    ) => {
      const payload = {
        templateId,
        updates: [
          {
            categoryId: currentCategory.id,
            newIndex: swapCategory.index,
          },
          {
            categoryId: swapCategory.id,
            newIndex: currentCategory.index,
          },
        ],
      };

      fetch(`${V2_SURVEY_ENDPOINT}/templates/reorder/categories`, {
        method: 'POST',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
        body: JSON.stringify(payload),
      })
        .then((res) => res.json())
        .then((result: SurveyTemplate) => {
          setData(result);
        })
        .then(() => {
          setIsLoading(false);
        });
    },
    [],
  );

  const handleCategoryReorder = (current: Category, swap: Category) => {
    if (swap !== null) {
      sendCategoryReorder(surveyTemplateId, current, swap);
    }
  };

  const getCategoryTabItems = () => {
    const tabItems: TabsProps['items'] = [];

    if (
      typeof data !== 'undefined' &&
      data !== null &&
      typeof data.categories !== 'undefined' &&
      data.categories !== null
    ) {
      const sortedCategories = data.categories.sort((compA, compB) =>
        sortNumberAscending(compA.index, compB.index),
      );
      const categoryTabItems = sortedCategories.map(
        (category, categoryIndex) => {
          return {
            key: category.id,
            label: (
              <span>
                {categoryIndex !== 0 && (
                  <LeftSquareOutlined
                    title={'Move Category Left'}
                    disabled={categoryIndex === 0}
                    onClick={(event) => {
                      event.stopPropagation();
                      handleCategoryReorder(
                        category,
                        sortedCategories[categoryIndex - 1],
                      );
                    }}
                  />
                )}
                <TabTitle>{`${category.name}`}</TabTitle>
                {categoryIndex < sortedCategories.length - 1 && (
                  <RightSquareOutlined
                    style={Styles.RightIcon}
                    title={'Move Category Right'}
                    disabled={categoryIndex === sortedCategories.length - 1}
                    onClick={(event) => {
                      event.stopPropagation();
                      handleCategoryReorder(
                        category,
                        sortedCategories[categoryIndex + 1],
                      );
                    }}
                  />
                )}
              </span>
            ),
            children: <SurveyTemplateCategory category={category} />,
          };
        },
      );

      return categoryTabItems;
    }

    return tabItems;
  };

  const handleDeleteCategory = (categoryId: string) => {
    let categoryName = '';
    for (let idx = 0; idx < data.categories.length; idx++) {
      if (data.categories[idx].id === categoryId) {
        categoryName = data.categories[idx].name;
        break;
      }
    }

    Modal.confirm({
      title: 'Are you sure?',
      content: `Please confirm that you would like to delete the category "${categoryName}" and all questions in that category.`,
      okText: 'Delete',
      okType: 'danger',
      cancelText: 'Cancel',
      open: false,
      onOk: () => sendCategoryDelete(surveyTemplateId, categoryId),
    });
  };

  const onEdit = (
    targetKey: React.MouseEvent | React.KeyboardEvent | string,
    action: 'add' | 'remove',
  ) => {
    if (action === 'add') {
      const categoryId = uuidv4();
      sendCategoryCreate(surveyTemplateId, categoryId);
    } else {
      handleDeleteCategory(targetKey.toString());
    }
  };

  return (
    <Card>
      <Tabs
        type="editable-card"
        items={getCategoryTabItems()}
        onEdit={onEdit}
      />
    </Card>
  );
};

export default SurveyTemplateCategories;
