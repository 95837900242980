import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button, DatePicker, DatePickerProps, Form, Modal } from 'antd';

import SurveyInstanceContext from '@totem/components/surveyV2/instances/SurveyInstancesContext';
const FormItem = Form.Item;
import dayjs from 'dayjs';

import ModalFooter from '@totem/components/ModalFooter';
import LaunchTable from '@totem/components/surveyV2/instances/LaunchTable';
import { SurveyQueryResult } from '@totem/components/surveyV2/types';
import { getToken } from '@totem/utilities/accountUtilities';
import { V2_SURVEY_ENDPOINT } from '@totem/utilities/endpoints';
import { CheckResponseShowError } from '@totem/utilities/responseUtilities';

import '../survey.css';

const DATE_FORMAT = 'MM-DD-YYYY';

const styles = {
  datePicker: {
    width: '25rem',
  },
};

interface Props {
  open: boolean;
  onCancel: () => void;
}

const LaunchDialog = ({ open, onCancel }: Props) => {
  const {
    selectedForLaunch,
    setSelectedForLaunch,
    data,
    onAction,
    setIsLoading,
    loading,
  } = useContext(SurveyInstanceContext);
  const [launchData, setLaunchData] = useState<SurveyQueryResult[]>([]);
  const [localLoading, setLocalLoading] = useState<boolean>(true);
  const [dueDate, setDueDate] = useState(0);

  useEffect(() => {
    const selectedSurveys = data.filter(
      (rec: SurveyQueryResult) => selectedForLaunch.indexOf(rec.survey.id) >= 0,
    );
    setLaunchData(selectedSurveys);
    setLocalLoading(false);
  }, [selectedForLaunch, data]);

  useEffect(() => {
    setDueDate(0);
  }, [open]);

  const sendLaunchRequest = useCallback(async (payload) => {
    setIsLoading(true);
    fetch(`${V2_SURVEY_ENDPOINT}/launch`, {
      method: 'POST',
      headers: new Headers({
        Authorization: `Bearer ${getToken()}`,
      }),
      body: JSON.stringify(payload),
    }).then((res) => {
      setIsLoading(false);
      const success = CheckResponseShowError(res);
      if (success) {
        setSelectedForLaunch([]);
      }
      onAction('refresh', null);
      onCancel();
    });
  }, []);

  const parseDate = (date: number): dayjs.Dayjs | null => {
    if (!date) {
      return null;
    }

    return dayjs(date);
  };

  const dueDateValue = parseDate(dueDate);

  const handleDateChange: DatePickerProps['onChange'] = (value) => {
    const date = value ? value.valueOf() : 0;
    setDueDate(date);
  };

  const handleSubmit = () => {
    const launchRequest = {
      surveyId: selectedForLaunch,
      dueDate,
    };
    sendLaunchRequest(launchRequest);
  };

  return (
    <Modal
      title={`Launch Selected Surveys / Audits (${launchData.length})`}
      width={800}
      open={open}
      onCancel={onCancel}
      confirmLoading={false}
      footer={
        <ModalFooter>
          <Button onClick={onCancel}>Cancel</Button>
          <Button
            type="primary"
            loading={loading}
            disabled={launchData.length <= 0 || loading}
            onClick={handleSubmit}
          >
            {`(${launchData.length}) Launch`}
          </Button>
        </ModalFooter>
      }
    >
      <Form layout="vertical" autoComplete="off">
        <FormItem>
          <div styleName="launch-modal-row">
            <div styleName="launch-modal-label">Due Date</div>
            <DatePicker
              placeholder="(Optional) Select Due Date"
              format={DATE_FORMAT}
              style={styles.datePicker}
              onChange={handleDateChange}
              // @ts-ignore
              value={dueDateValue}
            />
          </div>
        </FormItem>
      </Form>
      <LaunchTable data={launchData} loading={localLoading} />
    </Modal>
  );
};

export default LaunchDialog;
