import React, { useCallback, useContext, useState } from 'react';
import { Button, Form, Modal } from 'antd';

import GenericAutoComplete from '@totem/components/common/autoCompletes/GenericAutoComplete';
import ModalFooter from '@totem/components/ModalFooter';
import ModalTitle from '@totem/components/ModalTitle';
import TenantsContext from '@totem/components/tenants/tenantsPage/TenantsContext';
import { Tenant, TenantBuilding } from '@totem/components/tenants/types';
import { AutoCompleteValue } from '@totem/types/autoComplete';
import { getToken } from '@totem/utilities/accountUtilities';
import { TENANTS_ENDPOINT } from '@totem/utilities/endpoints';
const FormItem = Form.Item;

const styles = {
  form: {
    width: '100%',
  },
  formItem: {
    paddingBottom: '0',
    marginBottom: '1rem',
  },
};

type Props = {
  open: boolean;
  onClose: () => void;
  tenant: Tenant;
};

const TenantBuildingAddDialog = ({ open, onClose, tenant }: Props) => {
  const [isSending, setIsSending] = useState<boolean>(false);
  const { onAction } = useContext(TenantsContext);
  const [selectedBuilding, setSelectedBuilding] = useState<AutoCompleteValue>({
    value: '',
    display: '',
  });

  const sendTenantBuildingCreate = useCallback(
    async (tenantId: string, payload: TenantBuilding) => {
      if (isSending) {
        return;
      }
      setIsSending(true);

      fetch(`${TENANTS_ENDPOINT}/${tenantId}/buildings`, {
        method: 'PUT',
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getToken()}`,
        }),
        body: JSON.stringify(payload),
      }).then(() => {
        onClose();
        setIsSending(false);
        onAction('refresh', null);
      });
    },
    [isSending],
  );

  const handleSubmit = () => {
    const payload: TenantBuilding = {
      id: selectedBuilding.value,
      name: selectedBuilding.display,
      locations: [],
    };
    sendTenantBuildingCreate(tenant.id, payload);
  };

  const handleBuildingSelected = (selectedValue: AutoCompleteValue) => {
    if (selectedValue.value !== selectedBuilding.value) {
      setSelectedBuilding(selectedValue);
    }
  };

  const handleBuildingAutoCompleteChanged = () => {
    setSelectedBuilding({
      value: '',
      display: '',
    });
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={<ModalTitle>Add Building to {tenant.name}</ModalTitle>}
      width={'40%'}
      footer={
        <ModalFooter>
          <Button onClick={onClose}>Close</Button>
          <Button
            type="primary"
            onClick={handleSubmit}
            disabled={selectedBuilding.value === ''}
          >
            Save
          </Button>
        </ModalFooter>
      }
    >
      <Form layout="vertical" style={styles.form}>
        <FormItem label="Name" colon={false} style={styles.formItem}>
          <GenericAutoComplete
            label="Building"
            type="Building"
            limit={15}
            onSelect={handleBuildingSelected}
            onChange={handleBuildingAutoCompleteChanged}
            selectedValue={selectedBuilding}
          />
        </FormItem>
      </Form>
    </Modal>
  );
};

export default TenantBuildingAddDialog;
