import React, { useEffect, useState } from 'react';
import { CloseCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Col, Form, Row, Select } from 'antd';

import { ExtendedObjectReference } from '@totem/types/common';
import { ContractServiceLevelAgreement } from '@totem/types/contracts';

const FormItem = Form.Item;
const { Option } = Select;

import { getToken } from '@totem/utilities/accountUtilities';
import { CONTRACTS_ENDPOINT } from '@totem/utilities/endpoints';

import '../contracts.css';

const styles = {
  form: {
    width: '100%',
  },
  formItem: {
    paddingBottom: '0',
    marginBottom: '1rem',
  },
  addIcon: {
    marginLeft: '15px',
    color: '#1890ff',
  },
  removeIcon: {
    color: '#1890ff',
  },
};

export interface Props {
  contractLineId: string;
  serviceLevelAgreements: ContractServiceLevelAgreement[];
  onServiceLevelAgreementsChanged: (
    serviceLevelAgreements: ContractServiceLevelAgreement[],
  ) => void;
}

const ContractLineServiceLevelAgreementsEditPanel = ({
  contractLineId,
  serviceLevelAgreements,
  onServiceLevelAgreementsChanged,
}: Props) => {
  const [serviceLevelAgreementData, setServiceLevelAgreementData] = useState<
    ContractServiceLevelAgreement[]
  >(
    typeof serviceLevelAgreements !== 'undefined' &&
      serviceLevelAgreements !== null
      ? serviceLevelAgreements
      : [],
  );
  const [
    availableServiceLevelAgreements,
    setAvailableServiceLevelAgreements,
  ] = useState<ExtendedObjectReference[]>([]);
  const [
    selectedServiceLevelAgreement,
    setSelectedServiceLevelAgreement,
  ] = useState<ExtendedObjectReference>({
    id: '',
    name: '',
    description: '',
    displayName: '',
  });

  useEffect(() => {
    fetch(`${CONTRACTS_ENDPOINT}/serviceLevelAgreements`, {
      method: 'GET',
      headers: new Headers({
        Authorization: `Bearer ${getToken()}`,
      }),
    })
      .then(res => res.json())
      .then((result: ExtendedObjectReference[]) => {
        setAvailableServiceLevelAgreements(result);
      });
  }, []);

  useEffect(() => {
    onServiceLevelAgreementsChanged(serviceLevelAgreementData);
  }, [serviceLevelAgreementData]);

  const getAvailableServiceLevelAgreements = () => {
    return availableServiceLevelAgreements.filter(
      av => serviceLevelAgreementData.findIndex(sd => sd.id === av.id) === -1,
    );
  };

  const handleServiceLevelAgreementSelectChange = serviceLevelAgreementId => {
    const foundServiceLevelAgreement = availableServiceLevelAgreements.find(
      svc => svc.id === serviceLevelAgreementId,
    );
    if (typeof foundServiceLevelAgreement !== 'undefined') {
      setSelectedServiceLevelAgreement(foundServiceLevelAgreement);
    } else {
      setSelectedServiceLevelAgreement({
        id: '',
        name: '',
        description: '',
        displayName: '',
      });
    }
  };

  const handleServiceLevelAgreementAdd = () => {
    if (selectedServiceLevelAgreement.id !== '') {
      const newServiceLevelAgreementItem = {
        id: selectedServiceLevelAgreement.id,
        name: selectedServiceLevelAgreement.displayName,
      };

      setServiceLevelAgreementData(current => [
        ...current,
        newServiceLevelAgreementItem,
      ]);
      setSelectedServiceLevelAgreement({
        id: '',
        name: '',
        description: '',
        displayName: '',
      });
    }
  };

  const handleServiceLevelAgreementRemove = (
    sla: ContractServiceLevelAgreement,
  ) => {
    setServiceLevelAgreementData(
      serviceLevelAgreementData.filter(
        serviceLevelAgreement => serviceLevelAgreement.id !== sla.id,
      ),
    );
  };

  return (
    <Row>
      <Col span={12}>
        <b>Assigned Service Level Agreements:</b>
        <br />
        {serviceLevelAgreementData.map(sla => (
          <span key={`${contractLineId}:${sla.id}`}>
            <CloseCircleOutlined
              title="Remove Service Level Agreement"
              onClick={() => handleServiceLevelAgreementRemove(sla)}
              style={styles.removeIcon}
            />
            &nbsp;
            {sla.name}
            <br />
          </span>
        ))}
      </Col>
      <Col span={12}>
        <FormItem
          label="Available Service Level Agreements"
          colon={false}
          style={styles.formItem}
        >
          <Row>
            <Col span={20}>
              <Select
                onChange={handleServiceLevelAgreementSelectChange}
                defaultValue={selectedServiceLevelAgreement.id}
                value={selectedServiceLevelAgreement.id}
              >
                <Option value="">-- Select --</Option>
                {getAvailableServiceLevelAgreements().map(svc => {
                  return (
                    <Option key={svc.id} value={svc.id}>
                      {svc.displayName}
                    </Option>
                  );
                })}
              </Select>
            </Col>
            <Col span={4}>
              <PlusCircleOutlined
                title="Add Service Level Agreement"
                onClick={handleServiceLevelAgreementAdd}
                style={styles.addIcon}
              />
            </Col>
          </Row>
        </FormItem>
      </Col>
    </Row>
  );
};

export default ContractLineServiceLevelAgreementsEditPanel;
