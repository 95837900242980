import React, { useContext } from 'react';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  EllipsisOutlined,
  EyeOutlined,
  ReconciliationOutlined,
} from '@ant-design/icons';
import { Button, Dropdown, MenuProps } from 'antd';

import UserProfileContext from '@totem/components/UserProfileContext';
import { Event } from '@totem/types/events';
import authUtilities from '@totem/utilities/authUtilities';
import { isMemberOfAny } from '@totem/utilities/userUtilities';

type Props = {
  event: Event;
  onView: (event: Event) => void;
  onAcknowledge: (event: Event) => void;
  onClear: (event: Event) => void;
  onCreateTicket: (event: Event) => void;
  onShowAssociateTicket: (event: Event) => void;
};

const EventActionMenu = (props: Props) => {
  const { userProfile } = useContext(UserProfileContext);
  const eligibleToAcknowledge =
    props.event.status !== 'ACKNOWLEDGED' && props.event.status !== 'CLEARED';

  const eligibleToClear = props.event.status !== 'CLEARED';

  const isEventManager =
    isMemberOfAny(userProfile, ['event_manager'], {
      regionId: props.event.regionId,
      buildingId: props.event.buildingId,
      controlSystemId: props.event.controlSystemId,
    }) || authUtilities.isSystemsAdmin();

  const isTicketCreator =
    isMemberOfAny(userProfile, ['ticket_creator', 'ticket_administrator'], {
      regionId: props.event.regionId,
      buildingId: props.event.buildingId,
      controlSystemId: props.event.controlSystemId,
    }) || authUtilities.isSystemsAdmin();

  const eligibleForTicketCreation = () => {
    if (typeof props.event.keys !== 'undefined' && props.event.keys !== null) {
      for (let idx = 0; idx < props.event.keys.length; idx++) {
        if (
          props.event.keys[idx].system === 'Ticketing' &&
          props.event.keys[idx].instance === 'IBD'
        ) {
          return false;
        }
      }
    }
    return true;
  };

  const getActionMenuItems = (): MenuProps['items'] => {
    const menuItems: MenuProps['items'] = [
      {
        key: 'event_details',
        label: 'Event Details',
        title: 'Event Details',
        icon: <EyeOutlined />,
      },
    ];

    if (eligibleToAcknowledge && isEventManager) {
      menuItems.push({
        key: 'acknowledge_event',
        label: 'Acknowledge Event',
        title: 'Acknowledge Event',
        icon: <CheckCircleOutlined />,
      });
    }

    if (eligibleToClear && isEventManager) {
      menuItems.push({
        key: 'clear_event',
        label: 'Clear Event',
        title: 'Clear Event',
        icon: <CloseCircleOutlined />,
      });
    }

    if (isTicketCreator && eligibleForTicketCreation()) {
      menuItems.push({
        key: 'create_ticket',
        label: 'Create Ticket',
        title: 'Create Ticket',
        icon: <ReconciliationOutlined />,
      });

      menuItems.push({
        key: 'associate_ticket',
        label: 'Associate Ticket',
        title: 'Associate Ticket',
        icon: <ReconciliationOutlined />,
      });
    }

    return menuItems;
  };

  const onMenuClicked = ({ key }) => {
    // eslint-disable-next-line default-case
    switch (key) {
      case 'event_details':
        props.onView(props.event);
        break;
      case 'acknowledge_event':
        props.onAcknowledge(props.event);
        break;
      case 'clear_event':
        props.onClear(props.event);
        break;
      case 'create_ticket':
        props.onCreateTicket(props.event);
        break;
      case 'associate_ticket':
        props.onShowAssociateTicket(props.event);
        break;
    }
  };

  return (
    <Dropdown
      menu={{ items: getActionMenuItems(), onClick: onMenuClicked }}
      trigger={['click']}
    >
      <Button shape="circle" icon={<EllipsisOutlined />} />
    </Dropdown>
  );
};

export default EventActionMenu;
