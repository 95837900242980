import { createContext } from 'react';

import {
  BuildingMapperInput,
  SensorQueryResultItem,
} from '@totem/components/settings/sections/buildingMapping/types';

interface Context {
  input: BuildingMapperInput;
  setInput: (input: BuildingMapperInput) => void;
  data: SensorQueryResultItem[];
  loading: boolean;
  onAction?: (action: string, role: SensorQueryResultItem) => void;
  onActionComplete?: (refresh: boolean) => void;
}

export default createContext<Context>({
  input: null,
  setInput: () => {},
  data: [],
  loading: false,
  onAction: null,
  onActionComplete: null,
});
