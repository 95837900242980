import React from 'react';

import BasePane from '@totem/components/BasePane';
import ContentLayout from '@totem/components/ContentLayout';
import PrimaryContentPane from '@totem/components/PrimaryContentPane';
import ReportsContainer from '@totem/components/ui_components/reports/ReportsContainer';
import ReportsPanel from '@totem/components/ui_components/reports/ReportsPanel';

const ReportPage = () => {
  return (
    <ReportsContainer>
      <ContentLayout pageTitle="Reports">
        <BasePane>
          <PrimaryContentPane>
            <ReportsPanel />
          </PrimaryContentPane>
        </BasePane>
      </ContentLayout>
    </ReportsContainer>
  );
};

export default ReportPage;
