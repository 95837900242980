import React, { useContext } from 'react';

import PageContext from '@totem/components/devices/variables/presentation/layout/PageContext';

const PageTitle = () => {
  const { pageTitle } = useContext(PageContext);

  return <span>{pageTitle}</span>;
};

export default PageTitle;
