import React, { useContext } from 'react';

import DeviceContext from '@totem/components/devices/devicesContainer/deviceContainerContext';
import RecordCountBadge from '@totem/components/RecordCountBadge';
import GatewayExport from '@totem/components/remoteAccess/roleUsers/rootRole/GatewayExport';
const GatewayHeader = () => {
  const { totalRecords } = useContext(DeviceContext);
  return (
    <span>
      <RecordCountBadge
        count={
          typeof totalRecords !== 'undefined' && totalRecords !== null
            ? totalRecords
            : 0
        }
      />
      &nbsp;&nbsp;&nbsp;
      <GatewayExport />
    </span>
  );
};

export default GatewayHeader;
