import React from 'react';
import { Divider } from 'antd';

import colors from '@totem/styles/colors';
import { HealthRiskSurvey } from '@totem/types/healthRiskSurvey';

import './healthRiskSurveyDetail.css';

interface Props {
  survey: HealthRiskSurvey;
}

const styles = {
  divider: {
    background: colors.neutral.gray,
    top: 0,
    margin: '0 1rem',
  },
};

const HealthRiskSurveyDetailHeader = ({ survey }: Props) => {
  const { organization, region, building } = survey;

  return (
    <div styleName="header">
      <div>
        <div styleName="header-title">Health Risk Survey</div>
        <div styleName="header-subtitle-container">
          <div styleName="header-subtitle">{organization.name}</div>
          <Divider type="vertical" style={styles.divider} />
          <div styleName="header-subtitle">{region.name}</div>
          <Divider type="vertical" style={styles.divider} />
          <div styleName="header-subtitle">{building.name}</div>
        </div>
      </div>
    </div>
  );
};

export default HealthRiskSurveyDetailHeader;
