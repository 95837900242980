import React, { ReactNode, useContext, useEffect, useState } from 'react';

import AssetInventoryContext from '@totem/components/assetInventory/statistics/AssetInventoryContext';
import { StatisticsItem } from '@totem/components/assetInventory/statistics/types';
import DeviceContext from '@totem/components/devices/devicesContainer/deviceContainerContext';
import {
  addDeviceFilters,
  buildDeviceFilters,
} from '@totem/components/devices/devicesContainer/utilities';
import { Params } from '@totem/types/common';
import { getToken } from '@totem/utilities/accountUtilities';
import { ASSET_INVENTORY_ENDPOINT } from '@totem/utilities/endpoints';

type Props = {
  children?: ReactNode;
};

const AssetInventoryContainer = ({ children }: Props) => {
  const [statistics, setStatistics] = useState<StatisticsItem>(null);
  const [refreshData, setRefreshData] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { input } = useContext(DeviceContext);

  const buildParameters = () => {
    const params: Params = {
      paging: {
        page: input.page,
        pageSize: input.pageSize,
      },
      sort: {
        field: input.sortField,
        direction: +input.sortDirection,
      },
      filters: buildDeviceFilters(input),
    };

    params.filters = addDeviceFilters(params.filters, null);

    return params;
  };

  useEffect(() => {
    setIsLoading(true);

    const params: Params = buildParameters();

    fetch(`${ASSET_INVENTORY_ENDPOINT}/statistics`, {
      method: 'POST',
      headers: new Headers({
        Authorization: `Bearer ${getToken()}`,
      }),
      body: JSON.stringify(params),
    })
      .then((res) => res.json())
      .then((result: StatisticsItem) => {
        setStatistics(result);
      })
      .then(() => {
        setIsLoading(false);
      });
  }, [input, refreshData]);

  return (
    <AssetInventoryContext.Provider
      value={{
        loading: isLoading,
        statistics,
      }}
    >
      <div>{children}</div>
    </AssetInventoryContext.Provider>
  );
};

export default AssetInventoryContainer;
