export interface Address {
  addressOne: string;
  addressTwo?: string;
  city: string;
  state: string;
  country: string;
  zipCode: string;
  location: {
    lat: number;
    lng: number;
  };
}

export interface AddressFormErrors {
  name?: string;
  region?: string;
  addressOne?: string;
  addressTwo?: string;
  city?: string;
  state?: string;
  country?: string;
  zipCode?: string;
  location?: string;
}

export type GoogleGeocodeComponentName = 'short_name' | 'long_name';

export enum GoogleGeocodeComponentType {
  STREET_NUMBER = 'street_number',
  STREET_NAME = 'route',
  CITY = 'locality',
  STATE = 'administrative_area_level_1',
  COUNTRY = 'country',
  ZIP_CODE = 'postal_code',
}
