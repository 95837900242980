import React from 'react';

import BasePane from '@totem/components/BasePane';
import CardBody from '@totem/components/CardBody';
import ContentLayout from '@totem/components/ContentLayout';
import PendingContainer from '@totem/components/internalOnly/netSuitePending/PendingContainer';
import PendingTable from '@totem/components/internalOnly/netSuitePending/PendingTable';
import PrimaryContentPane from '@totem/components/PrimaryContentPane';

const PendingPage = () => {
  return (
    <ContentLayout>
      <BasePane>
        <PrimaryContentPane>
          <CardBody>
            <PendingContainer>
              <PendingTable />
            </PendingContainer>
          </CardBody>
        </PrimaryContentPane>
      </BasePane>
    </ContentLayout>
  );
};

export default PendingPage;
