import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Modal } from 'antd';
import * as R from 'ramda';

import EmailAutoComplete from '@totem/components/common/autoCompletes/EmailAutoComplete';
import ModalFooter from '@totem/components/ModalFooter';
import ModalTitle from '@totem/components/ModalTitle';
import { AddEditRemoteAccessUser } from '@totem/types/remoteAccessUsers';
import { validateEmail } from '@totem/utilities/validation';

const FormItem = Form.Item;

interface FormErrors {
  email?: string;
  givenName?: string;
  familyName?: string;
}

export interface Props {
  roleId: string;
  visible: boolean;
  loading: boolean;
  onClose: () => void;
  onSubmit: (user: AddEditRemoteAccessUser) => void;
}

const initialUser: AddEditRemoteAccessUser = {
  // eslint-disable-next-line camelcase
  user_id: '',
  email: '',
  givenName: '',
  familyName: '',
};

const styles = {
  form: {
    width: '100%',
  },
  formItem: {
    paddingBottom: '0',
    marginBottom: '1rem',
  },
};

export const isValidForm = (user: AddEditRemoteAccessUser): FormErrors => {
  const formErrors: FormErrors = {};

  if (R.isEmpty(user.email)) {
    formErrors.email = 'Please enter an email';
  }

  if (user.email && !validateEmail(user.email)) {
    formErrors.email = 'Please enter a valid email';
  }

  if (R.isEmpty(user.givenName)) {
    formErrors.givenName = 'Please enter a first name';
  }

  if (R.isEmpty(user.familyName)) {
    formErrors.familyName = 'Please enter a last name';
  }

  return formErrors;
};

const RemoteAccessUserAddModal = (props: Props) => {
  const { visible, loading, onClose, onSubmit } = props;

  const [user, setUser] = useState<AddEditRemoteAccessUser>(initialUser);
  const [errors, setErrors] = useState<FormErrors>({});

  useEffect(() => {
    if (!visible) {
      setErrors({});
      setUser(initialUser);
    }
  }, [visible]);

  const handleChange = ({
    target: { value, name },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setUser({ ...user, [name]: value });
  };

  const handleSubmit = () => {
    const formErrors = isValidForm(user);

    if (R.isEmpty(formErrors)) {
      onSubmit(user);
    }

    setErrors(formErrors);
  };

  const handleEmailChange = (email: string) => {
    setUser({ ...user, email });
  };

  return (
    <Modal
      open={visible}
      onCancel={onClose}
      title={<ModalTitle>Add User</ModalTitle>}
      okText="Add"
      confirmLoading={loading}
      footer={
        <ModalFooter>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={handleSubmit} loading={loading} type="primary">
            Add
          </Button>
        </ModalFooter>
      }
    >
      <Form layout="vertical" style={styles.form}>
        <FormItem
          label="Email"
          colon={false}
          style={styles.formItem}
          validateStatus={R.isNil(errors.email) ? 'success' : 'error'}
          help={R.isNil(errors.email) ? null : errors.email}
        >
          <EmailAutoComplete
            value={user.email}
            onChange={handleEmailChange}
            placeholder="email@example.com"
          />
        </FormItem>
        <FormItem
          label="First Name"
          colon={false}
          style={styles.formItem}
          validateStatus={R.isNil(errors.givenName) ? 'success' : 'error'}
          help={R.isNil(errors.givenName) ? null : errors.givenName}
        >
          <Input
            name="givenName"
            value={user.givenName}
            onChange={handleChange}
            type="search"
          />
        </FormItem>
        <FormItem
          label="Last Name"
          colon={false}
          style={styles.formItem}
          validateStatus={R.isNil(errors.familyName) ? 'success' : 'error'}
          help={R.isNil(errors.familyName) ? null : errors.familyName}
        >
          <Input
            name="familyName"
            value={user.familyName}
            onChange={handleChange}
            type="search"
          />
        </FormItem>
      </Form>
    </Modal>
  );
};

export default RemoteAccessUserAddModal;
