import React, { useContext } from 'react';
import { Badge } from 'antd';

import SoftwareContext from '@totem/components/software/software_report/softwareContext';
import { isNotNull } from '@totem/utilities/common';
const CountBadge = () => {
  const { data } = useContext(SoftwareContext);

  return (
    <Badge overflowCount={99999} count={isNotNull(data) ? data.length : 0} />
  );
};

export default CountBadge;
