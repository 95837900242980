import actionTypes from '@totem/actions/actionTypes';

import initialState from './initialState';

export default function regionsTotalReducer(
  state = initialState.regionsTotal,
  action,
) {
  switch (action.type) {
    case actionTypes.REGIONS_TOTAL_GET_SUCCESS: {
      return action.data.total ? action.data.total : 0;
    }
    default: {
      return state;
    }
  }
}
