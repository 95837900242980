import React, { useCallback, useContext, useEffect, useState } from 'react';
import { ColumnProps } from 'antd/lib/table';

import Table from '@totem/components/common/table/Table';
import DeviceContext from '@totem/components/devices/deviceDetails/DeviceContext';
import UserProfileContext from '@totem/components/UserProfileContext';
import colors from '@totem/styles/colors';
import IBDIcon from '@totem/styles/Icon';
import { DeviceKey } from '@totem/types/devices';
import { getToken } from '@totem/utilities/accountUtilities';
import { isNotNull } from '@totem/utilities/common';
import { DEVICES_ENDPOINT } from '@totem/utilities/endpoints';
const DeviceKeysTable = () => {
  const { userProfile } = useContext(UserProfileContext);
  const { deviceData, loading, onAction } = useContext(DeviceContext);
  const [keys, setKeys] = useState<DeviceKey[]>([]);
  const [isSendingUpdate, setIsSendingUpdate] = useState(false);

  useEffect(() => {
    if (
      isNotNull(deviceData) &&
      isNotNull(deviceData.device) &&
      isNotNull(deviceData.device.keys)
    ) {
      setKeys(deviceData.device.keys);
    } else {
      setKeys([]);
    }
  }, [deviceData]);

  const sendDeviceKeyUpdate = useCallback(
    async (deviceId: string, update: DeviceKey) => {
      if (isSendingUpdate) {
        return;
      }

      setIsSendingUpdate(true);

      fetch(`${DEVICES_ENDPOINT}/${deviceId}/keys/createdBy`, {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getToken()}`,
        }),
        body: JSON.stringify(update),
      }).then(() => {
        setIsSendingUpdate(false);
        onAction('refresh');
      });
    },
    [isSendingUpdate],
  );

  const handleKeyUpdate = (newState: string, key: DeviceKey) => {
    key.createdBy = newState;
    sendDeviceKeyUpdate(deviceData.device.id, key);
  };

  const columns: ColumnProps<DeviceKey>[] = [
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      defaultSortOrder: 'ascend',
      sorter: (compA, compB) =>
        compA.type === compB.type ? 0 : compA.type > compB.type ? 1 : -1,
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value',
      sorter: (compA, compB) =>
        compA.value === compB.value ? 0 : compA.value > compB.value ? 1 : -1,
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: (compA, compB) =>
        compA.createdAt === compB.createdAt
          ? 0
          : compA.createdAt > compB.createdAt
            ? 1
            : -1,
    },
    {
      title: 'Created By',
      dataIndex: 'createdBy',
      key: 'createdBy',
      sorter: (compA, compB) =>
        compA.createdBy === compB.createdBy
          ? 0
          : compA.createdBy > compB.createdBy
            ? 1
            : -1,
      render: (_, rec: DeviceKey) => {
        if (rec.createdBy === 'PERMANENT DELETE') {
          return (
            <b style={{ color: colors.criticality.critical }}>
              {rec.createdBy}
            </b>
          );
        }
        return <span>{rec.createdBy}</span>;
      },
    },
    {
      title: 'Last Sync Date',
      dataIndex: 'lastSync',
      key: 'lastSync',
      sorter: (compA, compB) =>
        compA.lastSync === compB.lastSync
          ? 0
          : compA.lastSync > compB.lastSync
            ? 1
            : -1,
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (_, rec: DeviceKey) => {
        if (rec.createdBy === 'PERMANENT DELETE') {
          return (
            <IBDIcon
              name={'Add'}
              title={'Reinstate Key'}
              onClick={() => handleKeyUpdate(userProfile.email, rec)}
            />
          );
        }
        return (
          <IBDIcon
            name={'Delete'}
            title={'Remove Key'}
            onClick={() => handleKeyUpdate('PERMANENT DELETE', rec)}
          />
        );
      },
    },
  ];

  return (
    <Table
      showSorterTooltip
      columns={columns}
      dataSource={keys}
      loading={loading}
      rowKey={(record) => record.type + record.value}
    />
  );
};

export default DeviceKeysTable;
