import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  Button,
  Col,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Tabs,
} from 'antd';
const FormItem = Form.Item;
const { Option } = Select;
const { TextArea } = Input;

const styles = {
  form: {
    width: '100%',
  },
  formItem: {
    paddingBottom: '0',
    marginBottom: '1rem',
  },
  summaryInput: {
    width: '425px',
  },
};
import ReferencesSelector, {
  EMPTY_ID,
} from '@totem/components/common/reference/ReferenceSelector';
import ModalFooter from '@totem/components/ModalFooter';
import ModalTitle from '@totem/components/ModalTitle';
import UserProfileContext from '@totem/components/UserProfileContext';
import { ForeignKey, Reference } from '@totem/types/common';
import {
  MIN_DATE_TIME_STRING,
  Ticket,
  TicketCategory,
} from '@totem/types/ticketing';
import { getToken } from '@totem/utilities/accountUtilities';
import { isNotNull } from '@totem/utilities/common';
import { controlSystemTypeOptions } from '@totem/utilities/controlSystemsUtilities';
import { TICKET_ENDPOINT } from '@totem/utilities/endpoints';
import { isIBUser } from '@totem/utilities/security';
import { emptyId } from '@totem/utilities/userUtilities';

export interface Props {
  regionId?: string;
  buildingId?: string;
  controlSystemId?: string;
  deviceId?: string;
  keys?: ForeignKey[];
  visible: boolean;
  onClose: (ticketCreated: boolean) => void;
  defaultSummary?: string;
  defaultDescription?: string;
  defaultCategory?: string;
  defaultSubCategory?: string;
}

const defaultTicketType: string = 'Service Request';
const TicketCreateModal = ({
  visible,
  onClose,
  regionId,
  buildingId,
  controlSystemId,
  deviceId,
  defaultSummary,
  defaultDescription,
  defaultCategory,
  defaultSubCategory,
  keys,
}: Props) => {
  const { userProfile } = useContext(UserProfileContext);
  const [projectControlSystemType, setProjectControlSystemType] =
    useState<string>('');
  const [isSending, setIsSending] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [ticketCategories, setTicketCategories] = useState<TicketCategory[]>(
    [],
  );
  const [ticketCategoriesByType, setTicketCategoriesByType] = useState<
    TicketCategory[]
  >([]);

  const [ticketType, setTicketType] = useState<string>(defaultTicketType);
  const [ticketData, setTicketData] = useState<Ticket>({
    activity: [],
    assignments: [],
    buildingId: buildingId ? buildingId : emptyId,
    closedAt: MIN_DATE_TIME_STRING,
    controlSystemId: controlSystemId ? controlSystemId : emptyId,
    createdAt: MIN_DATE_TIME_STRING,
    createdById: emptyId,
    createdByName: '',
    createdByType: '',
    description:
      typeof defaultDescription !== 'undefined' && defaultDescription !== null
        ? defaultDescription
        : '',
    deviceId: deviceId ? deviceId : emptyId,
    keys: isNotNull(keys) ? keys : [],
    partiesInvolved: [
      {
        id: '',
        type: 'Requester',
        status: 'ACTIVE',
        name: `${userProfile.lastName}, ${userProfile.firstName}`,
        email: userProfile.email,
        phone: userProfile.phoneNumber,
        involvementStart: MIN_DATE_TIME_STRING,
        involvementEnd: MIN_DATE_TIME_STRING,
        createdByType: 'USER',
        createdByName: userProfile.email,
        createdById: userProfile.id,
        keys: [],
      },
    ],
    priority: 'P3',
    queueAssignments: [],
    regionId: regionId ? regionId : emptyId,
    status: 'OPEN',
    subStatus: '',
    summary:
      typeof defaultSummary !== 'undefined' && defaultSummary !== null
        ? defaultSummary
        : '',
    type: defaultTicketType,
    category:
      typeof defaultCategory !== 'undefined' && defaultCategory !== null
        ? defaultCategory
        : '',
    subCategory:
      typeof defaultSubCategory !== 'undefined' && defaultSubCategory !== null
        ? defaultSubCategory
        : '',
    updatedAt: MIN_DATE_TIME_STRING,
    id: emptyId,
    number: '',
    organizationId: userProfile.organization.id,
    attributes: [],
  });

  const isIB = isIBUser(userProfile);

  useEffect(() => {
    if (ticketCategoriesByType !== null) {
      let defaultCategories = [];
      let matchFound = false;
      for (let idx = 0; idx < ticketCategoriesByType.length; idx++) {
        if (ticketCategoriesByType[idx].name === ticketType) {
          matchFound = true;
          setTicketCategories(ticketCategoriesByType[idx].children);
          setTicketData({
            ...ticketData,
            category:
              typeof defaultCategory !== 'undefined' && defaultCategory !== null
                ? defaultCategory
                : '',
            subCategory:
              typeof defaultSubCategory !== 'undefined' &&
              defaultSubCategory !== null
                ? defaultSubCategory
                : '',
          });
          break;
        }
        if (ticketCategoriesByType[idx].name === 'Incident') {
          defaultCategories = ticketCategoriesByType[idx].children;
        }
      }
      if (!matchFound) {
        setTicketCategories(defaultCategories);
        setTicketData({
          ...ticketData,
          category:
            typeof defaultCategory !== 'undefined' && defaultCategory !== null
              ? defaultCategory
              : '',
          subCategory:
            typeof defaultSubCategory !== 'undefined' &&
            defaultSubCategory !== null
              ? defaultSubCategory
              : '',
        });
      }
    }
  }, [ticketCategoriesByType, ticketType]);

  const sendTicket = useCallback(
    async (request: Ticket, siteProjectControlSystemType: string) => {
      if (isSending) {
        return;
      }

      // ONLY FOR "Site Project" TICKETS
      if (
        typeof request !== 'undefined' &&
        request !== null &&
        request.type === 'Site Project' &&
        request.controlSystemId === EMPTY_ID &&
        siteProjectControlSystemType !== ''
      ) {
        request.attributes.push({
          source: 'SiteProject',
          name: 'ControlSystemType',
          dataType: 'string',
          value: siteProjectControlSystemType,
        });
      }

      fetch(`${TICKET_ENDPOINT}`, {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getToken()}`,
        }),
        body: JSON.stringify(request),
      })
        .then((response) => {
          if (response.status === 200) {
            onClose(true);
          } else {
            message.error('Ticket creation failed!');
          }
        })
        .then(() => {
          setIsSending(false);
        });

      setIsSending(true);
    },
    [isSending],
  );

  const isValid = () => {
    return true;
  };

  const handleSubmit = () => {
    setFormSubmitted(true);
    if (isValid()) {
      sendTicket(ticketData, projectControlSystemType);
    }
  };

  const handleSelectChange = (name: string, value: string) => {
    if (name === 'type') {
      setTicketType(value);
    }

    if (name === 'category') {
      setTicketData({ ...ticketData, [name]: value, subCategory: '' });
    } else {
      setTicketData({ ...ticketData, [name]: value });
    }
  };

  const handleChange = ({
    target: { value, name },
  }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setTicketData({ ...ticketData, [name]: value });
  };

  const handleReferenceChange = (ref: Reference) => {
    setTicketData({
      ...ticketData,
      regionId: ref.regionId,
      buildingId: ref.buildingId,
      controlSystemId: ref.controlSystemId,
    });
  };

  useEffect(() => {
    fetch(`${TICKET_ENDPOINT}/categoriesByType`, {
      method: 'GET',
      headers: new Headers({
        Authorization: `Bearer ${getToken()}`,
      }),
    })
      .then((res) => res.json())
      .then((result: TicketCategory[]) => {
        setTicketCategoriesByType(result);
      });
  }, []);

  const getAvailableSubCategories = () => {
    let subCategories = [];
    if (ticketCategories !== null && ticketCategories.length > 0) {
      ticketCategories.forEach((category) => {
        if (category.name === ticketData.category) {
          subCategories = category.children;
        }
      });
    }
    return subCategories;
  };

  return (
    <Modal
      open={visible}
      onCancel={() => onClose(false)}
      title={<ModalTitle>Create Ticket</ModalTitle>}
      confirmLoading={false}
      width={'40%'}
      footer={
        <ModalFooter>
          <Button onClick={() => onClose(false)}>Close</Button>
          <Button onClick={handleSubmit} type="primary">
            Submit
          </Button>
        </ModalFooter>
      }
    >
      <Form layout="vertical" style={styles.form}>
        <Row gutter={[16, 16]}>
          <Col span={8}>
            <FormItem
              label="Ticket Type"
              colon={false}
              style={styles.formItem}
              required
            >
              <Select
                defaultValue={ticketData.type}
                onChange={(newValue: string) =>
                  handleSelectChange('type', newValue)
                }
              >
                <Option value="Service Request">Service Request</Option>
                <Option value="Change Request">Change Request</Option>
                <Option value="Incident">Incident</Option>
                <Option value="Problem">Problem</Option>
                {isIB && <Option value="Site Project">Site Project</Option>}
              </Select>
            </FormItem>
            <FormItem
              label="Priority"
              colon={false}
              style={styles.formItem}
              required
            >
              <Select
                defaultValue={ticketData.priority}
                onChange={(newValue: string) =>
                  handleSelectChange('priority', newValue)
                }
              >
                <Option value="P3">P3</Option>
                <Option value="P2">P2</Option>
                <Option value="P1">P1</Option>
              </Select>
            </FormItem>
          </Col>
          <Col span={8}>
            <FormItem
              label="Category"
              colon={false}
              style={styles.formItem}
              required
              validateStatus={
                formSubmitted === false || ticketData.category !== ''
                  ? 'success'
                  : 'error'
              }
            >
              <Select
                defaultValue={ticketData.category}
                value={ticketData.category}
                onChange={(newValue: string) => {
                  handleSelectChange('category', newValue);
                }}
              >
                <Option value="">--Select--</Option>
                {ticketCategories !== null &&
                  ticketCategories.map((category) => (
                    <Option key={category.name} value={category.name}>
                      {category.name}
                    </Option>
                  ))}
              </Select>
            </FormItem>
            <FormItem
              label="SubCategory"
              colon={false}
              style={styles.formItem}
              required
              validateStatus={
                formSubmitted === false || ticketData.subCategory !== ''
                  ? 'success'
                  : 'error'
              }
            >
              <Select
                defaultValue={ticketData.subCategory}
                value={ticketData.subCategory}
                onChange={(newValue: string) =>
                  handleSelectChange('subCategory', newValue)
                }
              >
                <Option value="">--Select--</Option>
                {ticketCategories !== null &&
                  getAvailableSubCategories().map((subCategory) => (
                    <Option key={subCategory.name} value={subCategory.name}>
                      {subCategory.name}
                    </Option>
                  ))}
              </Select>
            </FormItem>
          </Col>
          <Col span={8}>
            <ReferencesSelector
              onChanged={handleReferenceChange}
              showRegion={
                typeof regionId === 'undefined' ||
                regionId === null ||
                regionId === emptyId
              }
              showBuilding={
                typeof buildingId === 'undefined' ||
                buildingId === null ||
                buildingId === emptyId
              }
              showControlSystem={
                typeof controlSystemId === 'undefined' ||
                controlSystemId === null ||
                controlSystemId === emptyId
              }
              showOrganization={false}
              references={{
                organizationId: userProfile.organization.id,
                regionId: regionId ? regionId : emptyId,
                buildingId: buildingId ? buildingId : emptyId,
                controlSystemId: controlSystemId ? controlSystemId : emptyId,
              }}
            />
          </Col>
        </Row>
        <Tabs defaultActiveKey="ProblemDescription">
          <Tabs.TabPane
            tab={<b>Problem Description</b>}
            key="ProblemDescription"
          >
            <FormItem
              label="Summary"
              colon={false}
              style={styles.formItem}
              required
              validateStatus={
                formSubmitted === false || ticketData.summary.trim() !== ''
                  ? 'success'
                  : 'error'
              }
            >
              <Input
                name="summary"
                type="text"
                style={styles.summaryInput}
                value={ticketData.summary}
                onChange={handleChange}
                maxLength={50}
              />
            </FormItem>
            <FormItem label="Description" colon={false} style={styles.formItem}>
              <TextArea
                name="description"
                value={ticketData.description}
                onChange={handleChange}
              />
            </FormItem>
          </Tabs.TabPane>
          {ticketType === 'Site Project' &&
            typeof ticketData !== 'undefined' &&
            ticketData.controlSystemId === EMPTY_ID && (
              <Tabs.TabPane
                tab={<b>Control System</b>}
                key="SiteProject.ControlSystem"
              >
                <FormItem
                  label="Control System Type"
                  colon={false}
                  style={styles.formItem}
                  validateStatus={
                    formSubmitted === false || ticketData.summary.trim() !== ''
                      ? 'success'
                      : 'error'
                  }
                >
                  <Select
                    defaultValue={projectControlSystemType}
                    value={projectControlSystemType}
                    onChange={(newValue: string) =>
                      setProjectControlSystemType(newValue)
                    }
                  >
                    {controlSystemTypeOptions.map((option) => {
                      return (
                        <Option key={option.value} value={option.text}>
                          {option.text}
                        </Option>
                      );
                    })}
                  </Select>
                </FormItem>
              </Tabs.TabPane>
            )}
        </Tabs>
      </Form>
    </Modal>
  );
};

export default TicketCreateModal;
