import React, { useCallback, useContext } from 'react';
import { Card } from 'antd';
import { v4 as uuidv4 } from 'uuid';

import { EMPTY_ID } from '@totem/components/common/reference/ReferenceSelector';
import SurveyTemplateConditionCard from '@totem/components/surveyV2/templateDetails/SurveyTemplateConditionCard';
import SurveyTemplateContext from '@totem/components/surveyV2/templateDetails/SurveyTemplateContext';
import {
  ConditionActionPayload,
  SurveyTemplate,
} from '@totem/components/surveyV2/types';
import { cloneCondition } from '@totem/components/surveyV2/utilities/SurveyUtilities';
import { Condition } from '@totem/types/survey';
import { getToken } from '@totem/utilities/accountUtilities';
import { V2_SURVEY_ENDPOINT } from '@totem/utilities/endpoints';

type Props = {
  templateId: string;
  categoryId: string;
  questionId: string;
  condition: Condition;
};

const SurveyTemplateConditionDetails = ({
  condition,
  categoryId,
  questionId,
}: Props) => {
  const { data, setData, setIsLoading } = useContext(SurveyTemplateContext);

  const sendConditionUpdate = useCallback(
    async (
      updateTemplateId: string,
      updateCategoryId: string,
      updateQuestionId: string,
      update: Condition,
    ) => {
      let path = `${V2_SURVEY_ENDPOINT}/template/${updateTemplateId}/categories/${updateCategoryId}/condition`;
      if (questionId !== EMPTY_ID) {
        path = `${V2_SURVEY_ENDPOINT}/template/${updateTemplateId}/categories/${updateCategoryId}/questions/${updateQuestionId}/condition`;
      }

      fetch(path, {
        method: 'POST',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
        body: JSON.stringify(update),
      })
        .then((res) => res.json())
        .then((result: SurveyTemplate) => {
          setData(result);
        })
        .then(() => {
          setIsLoading(false);
        });
    },
    [],
  );

  const handleConditionChange = (update: Condition) => {
    sendConditionUpdate(data.id, categoryId, questionId, update);
  };

  const conditionChangeHandler = (change: ConditionActionPayload) => {
    let updatedCondition = cloneCondition(condition);
    if (updatedCondition.operation === 'NOP') {
      updatedCondition.operation = 'AND';
    }
    switch (change.method) {
      case 'New':
        updatedCondition.subconditions.push(change.condition);
        break;
      default:
        if (change.condition.id === updatedCondition.id) {
          updatedCondition = change.condition;
        } else {
          for (
            let idx = 0;
            idx < updatedCondition.subconditions.length;
            idx++
          ) {
            // eslint-disable-next-line max-depth
            if (
              updatedCondition.subconditions[idx].id === change.condition.id
            ) {
              updatedCondition.subconditions[idx] = change.condition;
            }
          }
        }
        break;
    }
    handleConditionChange(updatedCondition);
  };

  const handleRemove = () => {
    // Remove All Items as this is the root node
    const updatedCondition: Condition = {
      id: uuidv4(),
      operation: 'NOP',
      fieldId: '',
      fieldType: '',
      value: '',
      subconditions: [],
    };

    sendConditionUpdate(data.id, categoryId, questionId, updatedCondition);
  };

  return (
    <Card>
      <div>Display Conditions:</div>
      <SurveyTemplateConditionCard
        condition={condition}
        questionId={questionId}
        onRemoveItem={handleRemove}
        onConditionChange={conditionChangeHandler}
      />
    </Card>
  );
};

export default SurveyTemplateConditionDetails;
