import { createContext } from 'react';

import { TenantsInput } from '@totem/components/tenants/tenantsPage/types';
import { Tenant, Tenants } from '@totem/components/tenants/types';

interface Context {
  input: TenantsInput;
  setInput: (input: TenantsInput) => void;
  loading: boolean;
  data: Tenants;
  action: string;
  actionItem: Tenant;
  onAction?: (action: string, item: Tenant) => void;
}

export default createContext<Context>({
  input: {},
  setInput: () => {},
  loading: false,
  data: [],
  action: '',
  actionItem: null,
  onAction: () => {},
});
