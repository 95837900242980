import React, { useContext, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { DeleteOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import dayjs from 'dayjs';

import Table from '@totem/components/common/table/Table';
import Tooltip from '@totem/components/common/tooltip/Tooltip';
import { Nmap } from '@totem/types/nmap';
import api from '@totem/utilities/api';
import { NMAP_ENDPOINT } from '@totem/utilities/endpoints';

import NmapContext from './NmapContext';

import './nmap.css';

const confirm = Modal.confirm;

const styles = {
  iconButton: {
    margin: '0 2px',
  },
};

const ControlSystemTable = () => {
  const { nmaps, loading, limit, page, total, handlePagination, refetch } =
    useContext(NmapContext);

  const [loadingDeleteNmap, setLoadingDeleteNmap] = useState<boolean>(false);

  const deleteNmap = async (id: string) => {
    setLoadingDeleteNmap(true);

    await api.delete(`${NMAP_ENDPOINT}/${id}`);

    setLoadingDeleteNmap(false);
    refetch();
  };

  const pagination = useMemo(
    () => ({
      total,
      current: page,
      pageSize: limit,
    }),
    [total, page, limit],
  );

  const getColumns = (): Object[] => {
    return [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        render: (name: string, nmap: Nmap) => (
          <Link to={`/dashboard/nmap/${nmap.id}`}>{name}</Link>
        ),
      },
      {
        title: 'Devices',
        dataIndex: 'devicesCount',
        key: 'devicesCount',
      },
      {
        title: 'Pending Approvals',
        dataIndex: 'pendingApprovalsCount',
        key: 'pendingApprovalsCount',
      },
      {
        title: 'Created Date',
        dataIndex: 'createdAt',
        key: 'createdAt',
        width: '115px',
        render: (createdAt: number) => dayjs(createdAt).format('MM/DD/YYYY'),
      },
      {
        title: 'Updated Date',
        dataIndex: 'updatedAt',
        key: 'updatedAt',
        width: '115px',
        render: (updatedAt: number) => dayjs(updatedAt).format('MM/DD/YYYY'),
      },
      {
        title: 'Actions',
        dataIndex: '',
        width: '45px',
        render: ({ id, name }: Nmap) => (
          <div className="center-table-cell">
            <Tooltip title="Delete" placement="top">
              <Button
                shape="circle"
                icon={<DeleteOutlined />}
                disabled={loading || loadingDeleteNmap}
                loading={loading || loadingDeleteNmap}
                onClick={() =>
                  confirm({
                    title: 'Are you sure?',
                    content: `Please confirm that you would like to delete the NMAP analysis "${name}".`,
                    okText: 'Delete',
                    okType: 'danger',
                    cancelText: 'Cancel',
                    visible: false,
                    onOk: () => deleteNmap(id),
                  })
                }
                style={styles.iconButton}
              />
            </Tooltip>
          </div>
        ),
      },
    ];
  };

  return (
    <Table
      rowKey="id"
      columns={getColumns()}
      loading={loading}
      dataSource={nmaps}
      onChange={handlePagination}
      pagination={pagination}
    />
  );
};

export default ControlSystemTable;
