import React, { ReactNode } from 'react';

import './base.css';

type Props = {
  children?: ReactNode;
};

const CardHeaderSmaller = ({ children }: Props) => {
  return <div styleName="card-header-smaller">{children}</div>;
};

export default CardHeaderSmaller;
