import React from 'react';
import ReactApexChart from 'react-apexcharts';

import { GraphMultiIntSeries } from '@totem/components/events/graphs/types';

import '../events/events.css';

type Props = {
  series: GraphMultiIntSeries;
  chartOptions?: ApexCharts.ApexOptions;
  width?: string | number;
  height?: string | number;
  onItemClicked?: (index: number) => void;
};

const StackedBarChart = ({
  series,
  chartOptions,
  width,
  height,
  onItemClicked,
}: Props) => {
  const getSeries = () => {
    return series.series.map(ser => {
      return { name: ser.name, data: ser.y };
    });
  };

  // eslint-disable-next-line id-length
  const handleClick = (e: any, chart?: any, options?: any) => {
    const dataPointIndex = options.seriesIndex;
    if (dataPointIndex >= 0) {
      if (typeof onItemClicked === 'function') {
        onItemClicked(dataPointIndex);
      }
    }
  };

  const options: ApexCharts.ApexOptions = {
    chart: {
      type: 'bar',
      stacked: true,
      stackType: '100%',
      toolbar: {
        show: false,
      },
      events: {
        dataPointSelection: handleClick,
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 2,
        borderRadiusApplication: 'around',
        borderRadiusWhenStacked: 'all',
        horizontal: true,
      },
    },
    legend: { show: true, showForSingleSeries: true, showForZeroSeries: true },
    series: getSeries(),
    colors: [
      '#B0E0E6',
      '#ADD8E6',
      '#87CEFA',
      '#87CEEB',
      '#00BFFF',
      '#B0C4DE',
      '#1E90FF',
      '#6495ED',
      '#5F9EA0',
      '#4169E1',
    ],
  };

  const getChartOptions = () => {
    if (typeof chartOptions !== 'undefined' && chartOptions !== null) {
      return { ...options, ...chartOptions };
    }
    return options;
  };

  return (
    <div className="app">
      <div className="row">
        <div className="mixed-chart">
          <ReactApexChart
            options={getChartOptions()}
            series={getSeries()}
            type="bar"
            width={width}
            height={height}
          />
        </div>
      </div>
    </div>
  );
};

export default StackedBarChart;
