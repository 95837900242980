import React, { useEffect, useState } from 'react';
import { Avatar, Button, Form, Input, Modal, notification, Select } from 'antd';
const FormItem = Form.Item;
const { Option } = Select;

import {
  CompassOutlined,
  DeleteOutlined,
  EditOutlined,
} from '@ant-design/icons';
import * as R from 'ramda';

import { PointOfContact } from '@totem/components/common/pointOfContact/types';
import { EMPTY_ID } from '@totem/components/common/reference/ReferenceSelector';
import Tooltip from '@totem/components/common/tooltip/Tooltip';
import { getToken } from '@totem/utilities/accountUtilities';

import './pointsOfContact.css';

const styles = {
  form: {
    width: '100%',
  },
  formItem: {
    paddingBottom: '0',
    marginBottom: '1rem',
  },
  avatar: {
    marginRight: '1rem',
  },
  icon: {
    marginRight: '0.5rem',
  },
};

enum View {
  ADD_EDIT,
  LIST,
}

type Errors = {
  name?: string;
  email?: string;
  phone?: string;
};

export interface Props {
  objectId: string;
  objectType: string;
  data: PointOfContact[];
  loading: boolean;
  baseUrl: string;
  optionsUrl: string;
  open: boolean;
  onActionComplete: (action: string, pointOfContact: PointOfContact) => void;
}

const EditList = ({
  loading,
  data,
  open,
  objectId,
  objectType,
  onActionComplete,
  baseUrl,
  optionsUrl,
}: Props) => {
  const [view, setView] = useState<View>(View.LIST);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [save, setSave] = useState<boolean>(false);
  const [remove, setRemove] = useState<boolean>(false);
  const [errors, setErrors] = useState<Errors>({});
  const [optionsLoading, setOptionsLoading] = useState<boolean>(false);
  const [labelOptions, setLabelOptions] = useState<string[]>([]);
  const [currentContact, setCurrentContact] = useState<PointOfContact>({
    id: EMPTY_ID,
    name: '',
    email: '',
    phone: '',
    labels: [],
  });

  const handleClose = () => {
    setCurrentContact({
      id: EMPTY_ID,
      name: '',
      email: '',
      phone: '',
      labels: [],
    });
    onActionComplete('Close', null);
  };

  useEffect(() => {
    if (save) {
      setSave(false);

      fetch(`${baseUrl}/${objectId}`, {
        method: 'POST',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
        body: JSON.stringify(currentContact),
      }).then((res) => {
        if (res.status < 400) {
          setCurrentContact({
            id: EMPTY_ID,
            name: '',
            email: '',
            phone: '',
            labels: [],
          });

          onActionComplete('Update', currentContact);

          setView(View.LIST);
        } else {
          notification.error({
            message: 'Error creating/updating contact!!',
            description:
              'An error occurred while creating/updating the contact.',
            duration: 0,
          });
        }
      });
    }
  }, [save, currentContact]);

  useEffect(() => {
    if (remove) {
      setRemove(false);

      fetch(`${baseUrl}/${objectId}/${currentContact.id}`, {
        method: 'DELETE',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
      }).then((res) => {
        if (res.status < 400) {
          setCurrentContact({
            id: EMPTY_ID,
            name: '',
            email: '',
            phone: '',
            labels: [],
          });

          onActionComplete('Remove', currentContact);

          setView(View.LIST);
        } else {
          notification.error({
            message: 'Error removing contact!!',
            description: 'An error occurred while removing the contact.',
            duration: 0,
          });
        }
      });
    }
  }, [remove, currentContact]);

  useEffect(() => {
    if (optionsLoading) {
      return;
    }
    setOptionsLoading(true);

    fetch(`${optionsUrl}`, {
      method: 'GET',
      headers: new Headers({
        Authorization: `Bearer ${getToken()}`,
      }),
    })
      .then((res) => res.json())
      .then((result: string[]) => {
        setLabelOptions(result);
        setOptionsLoading(false);
      });
  }, []);

  const handleAdd = () => {
    setView(View.ADD_EDIT);
    setIsEdit(false);
    setCurrentContact({
      id: EMPTY_ID,
      name: '',
      email: '',
      phone: '',
      labels: [],
    });
  };

  const handleFormCancel = () => {
    setView(View.LIST);
    setCurrentContact({
      id: EMPTY_ID,
      name: '',
      email: '',
      phone: '',
      labels: [],
    });
  };

  const handleListCancel = () => {
    setCurrentContact({
      id: EMPTY_ID,
      name: '',
      email: '',
      phone: '',
      labels: [],
    });
    onActionComplete('Close', null);
  };

  const isValid = (): boolean => {
    const { name, email } = currentContact;

    const err: Errors = {};

    if (!name) {
      err.name = 'Please enter a name';
    }

    if (!email) {
      err.email = 'Please enter an email address';
    }

    // if (!phone) {
    //   err.phone = 'Please enter a phone number';
    // }

    setErrors(err);
    return R.isEmpty(err);
  };

  const handleEdit = (contact: PointOfContact) => {
    setCurrentContact(contact);
    setView(View.ADD_EDIT);
  };

  const handleRemove = (contact: PointOfContact) => {
    setCurrentContact(contact);
    setRemove(true);
  };

  const handleSave = () => {
    if (isValid()) {
      setSave(true);
    }
  };

  const footer =
    view === View.LIST ? (
      <div styleName="footer">
        <Button disabled={loading} onClick={handleListCancel}>
          Cancel
        </Button>
        <div>
          <Button disabled={loading} onClick={handleAdd}>
            Add New Contact
          </Button>
          <Button
            loading={loading}
            type="primary"
            onClick={() => handleClose()}
          >
            Close
          </Button>
        </div>
      </div>
    ) : (
      <div styleName="footer form">
        <Button onClick={handleFormCancel}>Cancel</Button>
        <Button onClick={handleSave} type="primary">
          Save
        </Button>
      </div>
    );

  const title = () => {
    if (view === View.ADD_EDIT && isEdit) {
      return 'Update Contact';
    }

    if (view === View.ADD_EDIT && !isEdit) {
      return 'Create Contact';
    }

    return `${objectType} Contacts`;
  };

  return (
    <Modal
      open={open}
      title={<div styleName="title">{title()}</div>}
      onCancel={handleListCancel}
      footer={footer}
    >
      {view === View.ADD_EDIT ? (
        <Form layout="vertical" style={styles.form}>
          <FormItem
            label="Contact Name"
            colon={false}
            style={styles.formItem}
            validateStatus={R.isNil(errors.name) ? 'success' : 'error'}
            help={R.isNil(errors.name) ? null : errors.name}
          >
            <Input
              placeholder="Enter Contact Name"
              value={currentContact.name}
              onChange={(event) =>
                setCurrentContact({
                  ...currentContact,
                  name: event.target.value,
                })
              }
            />
          </FormItem>
          <FormItem
            label="Contact Email"
            colon={false}
            style={styles.formItem}
            validateStatus={R.isNil(errors.email) ? 'success' : 'error'}
            help={R.isNil(errors.email) ? null : errors.email}
          >
            <Input
              placeholder="Enter Contact Email"
              value={currentContact.email}
              onChange={(event) =>
                setCurrentContact({
                  ...currentContact,
                  email: event.target.value,
                })
              }
            />
          </FormItem>
          <FormItem
            label="Contact Phone"
            colon={false}
            style={styles.formItem}
            validateStatus={R.isNil(errors.phone) ? 'success' : 'error'}
            help={R.isNil(errors.phone) ? null : errors.phone}
          >
            <Input
              placeholder="Enter Contact Phone"
              value={currentContact.phone}
              onChange={(event) =>
                setCurrentContact({
                  ...currentContact,
                  phone: event.target.value,
                })
              }
            />
          </FormItem>
          <FormItem
            label="Labels"
            colon={false}
            style={styles.formItem}
            validateStatus={R.isNil(errors.phone) ? 'success' : 'error'}
            help={R.isNil(errors.phone) ? null : errors.phone}
          >
            <Select
              mode="multiple"
              allowClear
              placeholder="labels"
              value={currentContact.labels}
              onChange={(labels) =>
                setCurrentContact({
                  ...currentContact,
                  labels,
                })
              }
            >
              {labelOptions.map((label) => (
                <Option key={label} value={label}>
                  {label}
                </Option>
              ))}
            </Select>
          </FormItem>
        </Form>
      ) : (
        <>
          {typeof data !== 'undefined' &&
            data !== null &&
            data.map((contact: PointOfContact, index: number) => {
              return (
                <div key={index} styleName="list-item-container">
                  <div styleName="list-item-icon">
                    <Avatar icon={<CompassOutlined />} style={styles.avatar} />
                    <div styleName="list-item-detail">
                      <div styleName="list-item-title">{contact.name}</div>
                      <div>{`${contact.email} | ${contact.phone}`}</div>
                    </div>
                  </div>
                  <div styleName="list-item-buttons">
                    <Tooltip title="Edit">
                      <Button
                        type="primary"
                        shape="circle"
                        icon={<EditOutlined />}
                        style={styles.icon}
                        onClick={() => handleEdit(contact)}
                      />
                    </Tooltip>
                    <Tooltip title="Delete">
                      <Button
                        shape="circle"
                        icon={<DeleteOutlined />}
                        onClick={() => handleRemove(contact)}
                      />
                    </Tooltip>
                  </div>
                </div>
              );
            })}
        </>
      )}
    </Modal>
  );
};

export default EditList;
