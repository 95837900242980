import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Tabs, TabsProps } from 'antd';
import queryString from 'query-string';

import BasePane from '@totem/components/BasePane';
import CardBody from '@totem/components/CardBody';
import ContentLayout from '@totem/components/ContentLayout';
import DeviceEmbeddedContainer from '@totem/components/devices/devicesContainer/DeviceEmbeddedContainer';
import PrimaryContentPane from '@totem/components/PrimaryContentPane';
import AliasesTable from '@totem/components/software/software_package/aliasesTable';
import DeviceTable from '@totem/components/software/software_package/deviceTable';
import KeysPane from '@totem/components/software/software_package/keysPane';
import SoftwareContainer from '@totem/components/software/software_package/packageContainer';
import PackageHeader from '@totem/components/software/software_package/packageHeader';
import PackageVersions from '@totem/components/software/software_package/packageVersions';
import PackageVulnerabilities from '@totem/components/software/software_package/packageVulnerabilities';
import TabTitle from '@totem/components/TabTitle';
import UserProfileContext from '@totem/components/UserProfileContext';
import { Breadcrumb } from '@totem/types/breadcrumb';
import { getCalculatedBreadcrumb } from '@totem/utilities/breadcrumbs';
import { isNotNull } from '@totem/utilities/common';
import { DEVICES_PAGINATION_ENDPOINT } from '@totem/utilities/endpoints';
import { isIBUser } from '@totem/utilities/security';

const styles = {
  totals: {
    fontSize: '16px',
    marginRight: '20px',
  },
  tabContainer: {},
};

const PackagePage = () => {
  const { id } = useParams();
  const { userProfile } = useContext(UserProfileContext);
  const canAdmin = userProfile.organization.role === 3 && isIBUser(userProfile);

  const getBreadcrumbs = (breadcrumbsIn: Breadcrumb) => {
    const breadcrumbs = [breadcrumbsIn];

    const parsed = queryString.parse(location.search);

    if (isNotNull(parsed.from)) {
      if (typeof parsed.from === 'string') {
        const fromBreadcrumb = parsed.from;
        const newBreadcrumb = getCalculatedBreadcrumb(fromBreadcrumb);

        if (newBreadcrumb !== null) {
          breadcrumbs.push(newBreadcrumb);
        }
      }
    }

    breadcrumbs.push({
      label: `Software Package`,
    });

    return breadcrumbs;
  };

  const getTabItems = () => {
    const tabItems: TabsProps['items'] = [
      {
        key: 'versions',
        label: <TabTitle>Versions</TabTitle>,
        children: <PackageVersions />,
      },
      {
        key: 'vulnerabilities',
        label: <TabTitle>Vulnerabilities</TabTitle>,
        children: <PackageVulnerabilities />,
      },
    ];

    if (canAdmin) {
      tabItems.push({
        key: 'aliases',
        label: <TabTitle>Aliases</TabTitle>,
        children: <AliasesTable />,
      });
      tabItems.push({
        key: 'keys',
        label: <TabTitle>Keys</TabTitle>,
        children: <KeysPane />,
      });
    }

    tabItems.push({
      key: 'devices',
      label: <TabTitle>Devices</TabTitle>,
      children: (
        <DeviceEmbeddedContainer
          deviceEndPoint={`${DEVICES_PAGINATION_ENDPOINT}/software/${id}`}
        >
          <DeviceTable />
        </DeviceEmbeddedContainer>
      ),
    });

    return tabItems;
  };

  return (
    <ContentLayout
      breadcrumbs={(breadcrumbs) => getBreadcrumbs(breadcrumbs[0])}
    >
      <SoftwareContainer packageName={id}>
        <BasePane>
          <PrimaryContentPane>
            <PrimaryContentPane>
              <CardBody>
                <PackageHeader />
                <Tabs
                  defaultActiveKey="versions"
                  style={styles.tabContainer}
                  items={getTabItems()}
                />
              </CardBody>
            </PrimaryContentPane>
          </PrimaryContentPane>
        </BasePane>
      </SoftwareContainer>
    </ContentLayout>
  );
};

export default PackagePage;
