import React, { useCallback, useState } from 'react';
import { Button, Checkbox, Form, Input, Modal } from 'antd';

import ModalFooter from '@totem/components/ModalFooter';
import ModalTitle from '@totem/components/ModalTitle';
import { getToken } from '@totem/utilities/accountUtilities';
import { isNotNull } from '@totem/utilities/common';
import { DEVICES_ENDPOINT } from '@totem/utilities/endpoints';
import { CheckResponseShowError } from '@totem/utilities/responseUtilities';

const FormItem = Form.Item;

const styles = {
  form: {
    width: '100%',
  },
  formItem: {
    paddingBottom: '0',
    marginBottom: '1rem',
  },
  iconButton: {
    margin: '0 2px',
  },
  removeIcon: {
    color: '#1890ff',
  },
};

interface SoftwarePackageUpdate {
  packageId: string;
  name: string;
  publisher: string;
  createAlias: boolean;
}

type Props = {
  visible: boolean;
  packageId: string;
  packageName: string;
  packagePublisher: string;
  onClose: (refresh: boolean) => void;
};

const SoftwarePackageEditDialog = ({
  visible,
  onClose,
  packageId,
  packageName,
  packagePublisher,
}: Props) => {
  const [isSending, setIsSending] = useState(false);
  const [name, setName] = useState<string>(packageName);
  const [publisher, setPublisher] = useState<string>(packagePublisher);
  const [createAlias, setCreateAlias] = useState<boolean>(true);
  const isFormValid = () => {
    if (name.trim() === '') {
      return false;
    }
    return true;
  };

  const sendSoftwareEdit = useCallback(
    async (update: SoftwarePackageUpdate) => {
      if (isSending) {
        return;
      }
      setIsSending(true);

      fetch(`${DEVICES_ENDPOINT}/software/catalog/${update.packageId}`, {
        method: 'PUT',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
        body: JSON.stringify(update),
      }).then((res) => {
        setIsSending(false);
        const success = CheckResponseShowError(res);
        if (success) {
          onClose(true);
        }
      });
    },
    [],
  );
  const handleSubmit = () => {
    if (name.trim() !== '') {
      sendSoftwareEdit({
        packageId,
        name,
        publisher,
        createAlias,
      });
    }
  };

  return (
    <Modal
      open={visible}
      onCancel={() => onClose(false)}
      title={<ModalTitle>Software Package Edit</ModalTitle>}
      confirmLoading={false}
      width={'40%'}
      footer={
        <ModalFooter>
          <Button onClick={() => onClose(false)}>Close</Button>
          <Button
            type="primary"
            onClick={handleSubmit}
            disabled={!isFormValid()}
          >
            Save
          </Button>
        </ModalFooter>
      }
    >
      <Form layout="vertical" style={styles.form}>
        <FormItem label="Name" colon={false} style={styles.formItem}>
          <Input
            name="name"
            type="text"
            value={isNotNull(name) ? name : ''}
            onChange={({
              target: { value },
            }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
              setName(value)
            }
            maxLength={50}
          />
        </FormItem>
        <FormItem label="Publisher" colon={false} style={styles.formItem}>
          <Input
            name="publisher"
            type="text"
            value={isNotNull(publisher) ? publisher : ''}
            onChange={({
              target: { value },
            }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
              setPublisher(value)
            }
            maxLength={50}
          />
        </FormItem>
        <FormItem label="Create Alias" colon={false} style={styles.formItem}>
          <Checkbox
            checked={createAlias}
            onChange={(evt) => setCreateAlias(evt.target.checked)}
          >
            Add "{packageName}" as an alias? (Recommended)
          </Checkbox>
        </FormItem>
      </Form>
    </Modal>
  );
};

export default SoftwarePackageEditDialog;
