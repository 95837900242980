import React, { useContext, useEffect, useState } from 'react';
import { Flex } from 'antd';

import Count from '@totem/components/common/Count';
import SurveyContext from '@totem/components/surveyV2/instanceDetail/SurveyInstanceContext';
import { Criticality } from '@totem/types/criticality';
import { isNotNull } from '@totem/utilities/common';
import { criticalityMap } from '@totem/utilities/criticalityUtilities';
import { getExactPercentage } from '@totem/utilities/numberUtilities';

import './auditReport.css';

const INCLUDED_CRITICALITIES = [
  Criticality.Critical,
  Criticality.High,
  Criticality.Medium,
  Criticality.Low,
  Criticality.Compliant,
];

const styles = {
  fullWidthCentered: {
    width: '100%',
    alignItems: 'center',
  },
  findingHeaderContainer: {
    marginBottom: '16px',
  },
};

interface Group {
  category: string;
  criticalityCounts: number[];
}

const FindingsByCategory = () => {
  const { data } = useContext(SurveyContext);
  const [groups, setGroups] = useState<Group[]>([]);
  const [totalFindings, setTotalFindings] = useState<number>(0);

  useEffect(() => {
    const groupData: Group[] = [];
    let total = 0;

    if (isNotNull(data)) {
      for (let cIdx = 0; cIdx < data.survey.categories.length; cIdx++) {
        const category = data.survey.categories[cIdx];
        if (category.visible) {
          const newGroup: Group = {
            category: category.name,
            criticalityCounts: [0, 0, 0, 0, 0, 0],
          };
          const questionsWithFindings = category.questions.filter(
            (chk) =>
              (chk.type === 'enumsingle' || chk.type === 'scored') &&
              chk.policy !== '' &&
              chk.visible,
          );

          // eslint-disable-next-line max-depth
          for (let qIdx = 0; qIdx < questionsWithFindings.length; qIdx++) {
            const question = questionsWithFindings[qIdx];
            const optionIdx = question.options.findIndex(
              (chk) => chk.label === question.value.value,
            );
            // eslint-disable-next-line max-depth
            if (optionIdx >= 0) {
              const criticality = question.options[optionIdx].criticality;
              // eslint-disable-next-line max-depth
              if (criticality > 0) {
                newGroup.criticalityCounts[criticality] += 1;
                newGroup.criticalityCounts[0] += 1;
              }
            }
          }
          // eslint-disable-next-line max-depth
          if (questionsWithFindings.length > 0) {
            total += newGroup.criticalityCounts[0];
            groupData.push(newGroup);
          }
        }
      }
    }

    setTotalFindings(total);
    setGroups(groupData);
  }, [data]);

  return (
    <div>
      <Flex
        justify={'space-between'}
        align={'flex-start'}
        style={styles.findingHeaderContainer}
      >
        <div styleName="finding-header">All Findings Breakdown</div>
        <Count count={totalFindings} size="large" />
      </Flex>
      <div>
        {groups.map((group) => {
          const name = group.category;

          return (
            <Flex justify={'flex-start'} align={'flex-start'} key={name}>
              <div styleName="finding-label group">{name}</div>
              <Flex style={styles.fullWidthCentered}>
                <Flex styleName="finding-bar-clean">
                  {INCLUDED_CRITICALITIES.map((criticality) => {
                    const option = criticalityMap[criticality];

                    const percentage = getExactPercentage(
                      group.criticalityCounts[0],
                      group.criticalityCounts[criticality],
                    );

                    return (
                      <div
                        key={criticality}
                        style={{
                          width: `${percentage}%`,
                          background: option.color,
                          height: '100%',
                        }}
                      />
                    );
                  })}
                </Flex>
              </Flex>
              <Count count={group.criticalityCounts[0]} size="small" />
            </Flex>
          );
        })}
      </div>
    </div>
  );
};

export default FindingsByCategory;
