import React from 'react';
import { useQuery } from '@apollo/client';
import * as R from 'ramda';
import {
  DelimitedNumericArrayParam,
  NumberParam,
  useQueryParams,
  withDefault,
} from 'use-query-params';

import BasePane from '@totem/components/BasePane';
import PrimaryContentPane from '@totem/components/PrimaryContentPane';
import { GET_FINDINGS } from '@totem/graph/finding';
import { useConnection } from '@totem/hooks/useConnection';
import {
  FindingsConnection,
  FindingsConnectionInput,
} from '@totem/types/finding';

import ContentLayout from '../ContentLayout';

import FindingFilterChips from './FindingFilterChips';
import FindingFilters from './FindingFilters';
import FindingsExport from './FindingsExport';
import FindingsOverview from './FindingsOverview';
import FindingsTable from './FindingsTable';

import './findings.css';

const TABLE_LIMIT = 50;

const Findings = () => {
  const [input, setInput] = useQueryParams({
    limit: withDefault(NumberParam, TABLE_LIMIT),
    offset: withDefault(NumberParam, 0),
    controlSystemType: DelimitedNumericArrayParam,
    criticality: DelimitedNumericArrayParam,
    group: DelimitedNumericArrayParam,
    levelOfEffort: DelimitedNumericArrayParam,
  });

  const { data, loading, error } = useQuery(GET_FINDINGS, {
    variables: { input },
  });

  const { totalCount, findings } = useConnection<FindingsConnection>({
    data,
    accessor: R.path(['findings']),
    initialData: { totalCount: 0, findings: [] },
  });

  const handleTableChange = (updatedInput: FindingsConnectionInput) => {
    setInput({ ...input, ...updatedInput }, 'replace');
  };

  return (
    <ContentLayout>
      <>
        <BasePane>
          <PrimaryContentPane>
            <FindingFilters input={input} onChange={handleTableChange} />
            <div styleName="title">Findings Overview</div>
            <FindingsOverview input={input} />
            <div styleName="title middle">Findings</div>
            <div styleName="table-container">
              <div styleName="table-chips-container">
                <FindingFilterChips
                  input={input}
                  onChange={handleTableChange}
                />
              </div>
              <div styleName="export-button-container">
                <FindingsExport input={input} />
              </div>
              <FindingsTable
                input={input}
                onChange={handleTableChange}
                loading={loading || !!error}
                findings={findings}
                totalCount={totalCount}
              />
            </div>
          </PrimaryContentPane>
        </BasePane>
      </>
    </ContentLayout>
  );
};

export default Findings;
