import React from 'react';
import { useParams } from 'react-router-dom';

import ContentLayout from '@totem/components/ContentLayout';
import CveList from '@totem/components/software/cves/cveList';
import CvesContainer from '@totem/components/software/cves/cvesContainer';

const CvePage = () => {
  const { id } = useParams();

  return (
    <ContentLayout pageTitle={id}>
      <CvesContainer ids={[id]}>
        <CveList />
      </CvesContainer>
    </ContentLayout>
  );
};

export default CvePage;
