import React, { useContext } from 'react';
import { Badge, Tabs } from 'antd';

import TabTitle from '@totem/components/TabTitle';
import QueueDetails from '@totem/components/ticketing/queueTotals/QueueDetails';
import QueueTotalsContext from '@totem/components/ticketing/queueTotals/QueueTotalsContext';
import { isNull } from '@totem/utilities/common';

const styles = {
  badgeContent: {
    paddingRight: '15px',
  },
};

const QueueTabs = () => {
  const { data } = useContext(QueueTotalsContext);

  return (
    <Tabs
      tabPosition={'left'}
      items={
        isNull(data)
          ? []
          : data.map((queue) => {
              return {
                key: queue.queueID,
                label: (
                  <Badge count={queue.ticketCount} overflowCount={9999}>
                    <span style={styles.badgeContent}>
                      <TabTitle>{queue.queueName}</TabTitle>
                    </span>
                  </Badge>
                ),
                children: (
                  <QueueDetails
                    queueId={queue.queueID}
                    queueName={queue.queueName}
                  />
                ),
              };
            })
      }
    />
  );
};

export default QueueTabs;
