import React from 'react';
import { Col, Row } from 'antd';

import CvssScore from '@totem/components/software/cves/cvssScore';
import { NVDMetricsV3 } from '@totem/components/software/cves/types';
import { colorUnknown } from '@totem/components/software/cves/utilities';
import ValueBlock from '@totem/components/software/cves/valueBlock';

type Props = {
  metricsV3: NVDMetricsV3;
};

const CvssMetricsV3Panel = ({ metricsV3 }: Props) => {
  return (
    <Row gutter={[20, 10]}>
      <Col span={8}>
        <CvssScore
          title="CVSS v3"
          score={metricsV3.cvssData.baseScore}
          maxScore={10}
        />
      </Col>
      <Col span={8}>
        <ValueBlock
          title={'Attack Vector'}
          value={metricsV3.cvssData.attackVector}
          valueColor={colorUnknown}
        />
        <ValueBlock
          title={'Attack Complexity'}
          value={metricsV3.cvssData.attackComplexity}
          valueColor={colorUnknown}
        />
        <ValueBlock
          title={'Privileges Required'}
          value={metricsV3.cvssData.privilegesRequired}
          valueColor={colorUnknown}
        />
        <ValueBlock
          title={'User Interaction'}
          value={metricsV3.cvssData.userInteraction}
          valueColor={colorUnknown}
        />
      </Col>
      <Col span={8}>
        <ValueBlock
          title={'Confidentiality Impact'}
          value={metricsV3.cvssData.confidentialityImpact}
          valueColor={colorUnknown}
        />
        <ValueBlock
          title={'Integrity Impact'}
          value={metricsV3.cvssData.integrityImpact}
          valueColor={colorUnknown}
        />
        <ValueBlock
          title={'Availability Impact'}
          value={metricsV3.cvssData.availabilityImpact}
          valueColor={colorUnknown}
        />
        <ValueBlock
          title={'Scope'}
          value={metricsV3.cvssData.scope}
          valueColor={colorUnknown}
        />
      </Col>
    </Row>
  );
};

export default CvssMetricsV3Panel;
